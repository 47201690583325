import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class OrdenesTrabajosService {

  constructor(private http: HttpClient) { }

  public obtenerListaOrdenesTrabajo(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"ordenes-trabajo/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+((paginator.filter.cliente=="" || paginator.filter.cliente==undefined)?0:paginator.filter.cliente)+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.usuario.id+"/observacion/"+(paginator.filter.observacion==null?0:paginator.filter.observacion), GlobalVariable.httpOptions);
  }

  public guardarOrdenTrabajo(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"orden-trabajo",datos,GlobalVariable.httpOptions);
  }

  public obtenerOrdenTrabajo(id_orden_trabajo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"orden-trabajo/"+id_orden_trabajo, GlobalVariable.httpOptions);
  }

  public guardarProductosOrdenTrabajo(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"productos-orden-trabajo",datos,GlobalVariable.httpOptions);
  }
}
