import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../base/services/general/general.service';
import { PersistenciaService } from '../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends BaseComponent implements OnInit {

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
  super(persistenciaService,modalService,generalService,usuarioService);
}

  ngOnInit(): void {
    this.establecerDatosApp();
  }

}
