import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { CalificacionesService } from '../../services//calificaciones/calificaciones.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { GlobalVariable } from 'src/app/global';

declare const $ :any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-registro-calificacion-inicial',
  templateUrl: './registro-calificacion-inicial.component.html',
  styleUrls: ['./registro-calificacion-inicial.component.css']
})
export class RegistroCalificacionInicialComponent extends BaseComponent implements OnInit {

  estudiantes:any[]=[];
  cabecera_planilla:any=[];
  valor_contador_dimension:number=0;
  valor_inicial_dimension:number=0;
  escuela:any;
  rangos_calificaciones_literal:any[]=[];
  descargar_excel:string="";
  cabecera_registro:any;
  tipo_calificacion_inicial:any={clases:[]};

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  descripcion_cabecera:NgbModalRef;
  @ViewChild('descripcion_cabecera')
  private descripcion_cabecera_ref: TemplateRef<any>;

  configuracion_planilla_modal:NgbModalRef;
  @ViewChild('configuracion_planilla_modal')
  private configuracion_planilla_modal_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private orderPipe: OrderPipe,
    public calificacionesService:CalificacionesService,
    public toastr: ToastrService,
    public cdr: ChangeDetectorRef,
	public usuarioService:UsuarioService,
  public pdfService:PdfService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
	this.obtenerTipoCalculoCalificacion();
  }

  ngAfterViewChecked() {
	this.cdr.detectChanges();
  }

  obtenerTipoCalculoCalificacion(){
	  this.generalService.obtenerClases("TIPCALFINI").subscribe((entidad:any)=>{
		this.tipo_calificacion_inicial=entidad;
	  });
  }
  
  guardarCalificaciones(){
		this.blockUI.start();
      this.calificacionesService.guardarPlanillaCalificacion({estudiantes:this.estudiantes,id_usuario:this.usuario.id}).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.cerrarRegistroCalificaciones();
        this.blockUI.stop();
      });
  }
  
  cerrarRegistroCalificaciones(){
    this.alTerminar.emit({confirm:false});
  }

  abrirPopupConfirmacionReinicializacion(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de reinicializar la planilla de notas? Todas la información de esta planilla será borrada!";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reiniciarPlanillaCalificaciones();
			}
			this.popupConfirmacion.close();
        });
	}
  
  reiniciarPlanillaCalificaciones(){
    this.blockUI.start();
    this.calificacionesService.reiniciarPlanillaCalificacion({
      id_registro_profesor:this.filter.registro_profesor.id,
      estudiantes:this.estudiantes,
      id_materia:this.filter.registro_profesor.materia.id,
      id_intervalo_escolar:this.filter.intervalo_escolar.id
    }).subscribe((res:any)=>{
      this.toastr.info(res.mensaje);
			if(!res.tiene_error){
				this.cerrarRegistroCalificaciones();
			}
			this.blockUI.stop();
    });
  }

  async descargarPdfCalificaciones(){
		let titulo="CALIFICACIONES";
		var fecha_reporte=new Date();				
		var papel=[612, 792];
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		var itemsPorPagina = 10;

		doc.font('Helvetica', 8);
		var y = 135, items = 0, pagina = 1, totalPaginas = Math.ceil(this.estudiantes.length / itemsPorPagina);
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,"",this.usuario.empresa.direccion,
			(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
		(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
		(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});
		this.dibujarCabeceraRegistroCalificacionesCartaOficio(doc);
		for(let i=0;i<this.estudiantes.length;i++){
			doc.font('Helvetica', 7);
			doc.text((i+1), 52, y);
			doc.text(this.estudiantes[i].persona.nombre_completo.toUpperCase(), 65, y);
			for(let j=0;j<this.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
				let calificacion=this.estudiantes[i].inscripciones_colegio[0].calificaciones[j].descripcion?this.estudiantes[i].inscripciones_colegio[0].calificaciones[j].descripcion:"";
				doc.text(calificacion, 282, y-7,{width:300,align:'left'});
			  doc.rect(280,y-10,0,60).fillOpacity(0.8).stroke();
			}
			doc.roundedRect(50, y - 10, 540, 60,5).stroke();
			y = y + 60;
			items++;

			if (items == itemsPorPagina && (i+1) < this.estudiantes.length) {
				doc.addPage({ size: papel, margin: 10 });
				y = 135;
				items = 0;
				pagina = pagina + 1;
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
					(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
				(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
				(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:false});
				this.dibujarCabeceraRegistroCalificacionesCartaOficio(doc);
			}
		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	  }

	dibujarCabeceraRegistroCalificacionesCartaOficio(doc) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,48,5).stroke();
		doc.text("U.E. : ",355,55);
		doc.text("GESTIÓN : ",355,65);
		doc.text("TRIMESTRE : ",450,65);
		doc.text("CURSO : ",355,75);
		doc.text("MATERIA : ",355,85);
		doc.font('Helvetica', 8);
		doc.text(this.escuela.nombre.toUpperCase(),380,55);
		doc.text(this.filter.gestion.nombre,400,65);
		doc.text(this.filter.intervalo_escolar.nombre,510,65);
		doc.text(this.filter.registro_profesor.curso.nombre,400,75);
		doc.text(this.filter.registro_profesor.materia.nombre,400,85);
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,20,5).fillOpacity(0.8).stroke();

		doc.text("Nº", 52, 113);
		doc.text("ESTUDIANTE", 110, 113);

		doc.font('Helvetica', 7);
	}

}
