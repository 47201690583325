<div class="modal-header">
    <div class="col-8 col-xs-6">
        <h4 class="widget-title">Vencimiento de Productos</h4>
    </div>
    <div class="col-4 col-xs-6">
        <button class="btn btn-danger btn-lg" type="button" (click)="imprimirListaVencimientoProductos()">
            <i class="ace-icon fa fa-file-pdf align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body p-0">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover ">
            <thead class="bgc-primary-d1 text-white sticky-nav">
                <tr class="sticky-nav">
                    <th>Nº</th>
                    <th>Código</th>
                    <th>Producto
                        <input name="filtro-ident" class="form-control text-center" type="text" [(ngModel)]="filtro_inventario.nombre" (keyup)="filtrarProductos(filtro_inventario.nombre)">
                    </th>
                    <th>Vencimiento</th>
                    <th>Unidad de Medida</th>
                    <th>Sucursal</th>
                    <th>Almacen</th>
                    <th>lote</th>
                    <th>Cantidad</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let inventario of vencimiento_productos_filtrados; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{inventario.producto.codigo}}</td>
                    <td>{{inventario.producto.nombre}}</td>
                    <td>{{inventario.fecha_vencimiento | date:"dd/MM/yyyy"}}</td>
                    <td>{{inventario.producto.unidad_medida}}</td>
                    <td>{{inventario.almacen.sucursal.nombre}}</td>
                    <td>{{inventario.almacen.nombre}}</td>
                    <td>{{inventario.lote}}</td>
                    <td>{{inventario.cantidad}}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>