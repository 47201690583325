import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LlantasService {

  constructor(private http: HttpClient) { }

  public obtenerListaLlantas(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"llantas/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/grupo/"+paginator.filter.grupo.id+"/subgrupo/"+paginator.filter.subgrupo.id,GlobalVariable.httpOptions);
  }

  public guardarLlantas(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"llantas/empresa",datos,GlobalVariable.httpOptions);
  }
}
