<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Ordenes de Trabajo
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Lista de</span>
          Ordenes de Trabajo
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button (click)="crearNuevaOrdenTrabajo()" title="Nuevo" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                  <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
                  <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                  <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Cliente </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Observación </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.observacion">
                  </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="ordenes_trabajo.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th>Cliente</th>
                    <th>Vehiculo</th>
                    <th>Num. Doc.</th>
                    <th>Fecha Creacion</th>
                    <th>Fecha Ingreso</th>
                    <th>Total</th>
                    <th>Observaciones</th>
                    <th>Usuario</th>
                    <th>Estado</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let orden_trabajo of ordenes_trabajo; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{orden_trabajo.propietario}}</td>
                    <td>{{orden_trabajo.vehiculo}}</td>
                    <td>{{orden_trabajo.numero_documento}}</td>
                    <td>{{orden_trabajo.createdAt | date:'dd/MM/yyyy - HH:mm'}}</td>
                    <td>{{orden_trabajo.fecha_ingreso | date:'dd/MM/yyyy'}}</td>
                    <td>{{orden_trabajo.total}}</td>
                    <td><div style="width: 150px">{{orden_trabajo.observacion}}</div></td>
                    <td>{{orden_trabajo.usuario}}</td>
                    <td *ngIf="orden_trabajo.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!orden_trabajo.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" >
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" >
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a (click)="abrirModalRegistroRepuestos(orden_trabajo)" title="Repuestos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" >
                              <i class="ace-icon fas fa-dolly bigger-130"></i>
                            </a>
                            <a title="Mano de obra" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" >
                              <i class="ace-icon fas fa-people-carry bigger-130"></i>
                            </a>
                            <a title="Servicios Externos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" >
                              <i class="ace-icon fas fa-user-secret bigger-130"></i>
                            </a>
                            <a title="Monetizar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" >
                              <i class="ace-icon fas fa-money-bill-alt bigger-130"></i>
                            </a>
                            <a *ngIf="orden_trabajo.activo && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="ordenes_trabajo.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #lista_productos_ot let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Productos Orden Trabajo</h4>
		</div>
		<div class="col-4">
			<button class="btn btn-primary" type="button" (click)="guardarProductosOt()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="row">
      <div class="col-7">
        <div class="row">
          <div class="col-12">
            <label>Producto</label>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <input autocomplete="off" 
            [disabled]="(producto_ot.producto && producto_ot.producto.id)" [(ngModel)]="producto_busqueda"
            [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
            [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
            type="text" class="form-control"/>
    
            <ng-template #rtp let-r="result" let-t="term">
                <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
            </ng-template>
          </div>
          <div class="col-2">
            <a href="javascript:void(0)" *ngIf="producto_ot.producto && producto_ot.producto.id" (click)="iniciarProductoOt()" class="card-toolbar-btn text-danger-m1">
                <i class="ace-icon fa fa-trash bigger-130"></i>
            </a>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="imagen-producto-vista clearfix" *ngIf="producto_ot.producto && producto_ot.producto.id">
                <a href="{{rest_server+producto_ot.producto.imagen}}" data-rel="colorbox" title="{{producto_ot.producto.nombre}}">
                    <img alt="sin imagen" src="{{rest_server+producto_ot.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-12">
            <label>P.U.</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input [(ngModel)]="producto_ot.precio_unitario" type="number" class="form-control" name="precio_unitario" placeholder="Precio Unitario">
          </div>
        </div>
			</div>
      <div class="col-2">
        <div class="row">
          <div class="col-12">
            <label>Cantidad</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input [(ngModel)]="producto_ot.cantidad" type="number" class="form-control" name="cantidad" placeholder="Cantidad">
          </div>
        </div>
			</div>
			<div class="col-1">
				<button class="btn btn-primary" type="button" (click)="agregarProductoOt()">
					<i class="fa fa-arrow-down align-top bigger-110"></i>
				</button>
			</div>
		</div>
		<hr>
		<div class="table-fixed-header">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>Nº</th>
						<th>Codigo</th>
						<th>Producto</th>
						<th>Unidad de Medida</th>
						<th>Precio Unitario</th>
            <th>Cantidad</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let producto_ot of orden_trabajo.productos | filterBy:{activo:true}; let i=index;">
						<th>{{i+1}}</th>
						<th>{{producto_ot.producto.codigo}}</th>
						<td>{{producto_ot.producto.nombre}}</td>
						<td>{{producto_ot.producto.unidad_medida}}</td>
						<td>{{producto_ot.precio_unitario}}</td>
            <td>{{producto_ot.cantidad}}</td>
						<td>
							<a (click)="abrirPopupConfirmacionEliminacion(producto_ot)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">          
                <i class="fa fa-trash-alt text-105"></i>        
              </a> 
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>