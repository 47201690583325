import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.css']
})
export class MenuHorizontalComponent implements OnInit {

  @Input() usuario:any;
  constructor() { }

  ngOnInit(): void {
  }

}
