import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { VecinosService } from '../../services/vecinos/vecinos.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GlobalVariable } from 'src/app/global';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { VecinoLecturacionComponent } from '../vecino-lecturacion/vecino-lecturacion.component';
import { ConfiguracionAplicacionVecinosComponent } from 'src/app/base/components/configuracion-aplicacion-vecinos/configuracion-aplicacion-vecinos.component';
import { Util } from 'src/app/utils/utils';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;
declare const aplicarScriptPaginaVecinos:any;

declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any

@Component({
  selector: 'app-vecinos',
  templateUrl: './vecinos.component.html',
  styleUrls: ['./vecinos.component.css']
})
export class VecinosComponent extends BaseComponent implements OnInit {

  configuracion_pagina:any;
  rest_server=GlobalVariable.SERVER_URL;
  global_variable=GlobalVariable;

  vecinos_importacion:any[];

  modalRegistroLecturacion:NgbModalRef;

  vecinosImportacion:NgbModalRef;
  @ViewChild('importacionvecinos')
  private importacionvecinos: TemplateRef<any>;

  edicion_vecino:NgbModalRef;
  @ViewChild('edicionvecino')
  private edicion_vecinoRef: TemplateRef<any>;

  modificacion_accion_agua:NgbModalRef;
  @ViewChild('modificacion_accion_agua')
  private modificacion_accion_agua_ref: TemplateRef<any>;

  vecinos:any[]=[];
  filtro_reporte_redudores:any={};
  tipo_categoria:any={clases:[]};
  tipo_accion_agua:any={clases:[]};
  accion_agua:any={};

  vecino:any={
    cliente:{
      persona:{}
    }
  }

  wizard_edicion_vecino:string="wizard_edicion_vecino";
  camara_capturada:boolean=false;

  @BlockUI() blockUI: NgBlockUI;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public excelService:ExcelService,
    public pdfService:PdfService,
    public modalService: NgbModal,
    private router: Router,
    private vecinosService:VecinosService,
    private toastr: ToastrService) { 
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa
    }
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_VECINOS);
    this.obtenerVecinos();
    this.obtenerPeriodos();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		//aplicarScriptPaginaVecinos();
  }
  
  obtenerPeriodos(){
    this.generalService.obtenerClases("GTN").subscribe((entidad:any) => {
		  this.filtro_reporte_redudores.gestiones=entidad.clases;
    });
  }
  
  obtenerVecinos(){
		this.column = "codigo";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.vecinosService.obtenerListaVecinos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.vecinos=dato.vecinos;
			this.blockUI.stop();
			//aplicarScriptPaginaVecinos();
		});
  }
  
  subirExcelVecinos(event){
		this.blockUI.start();
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=3,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
				let vecinos=[];
				do {
          let vecino:any={cliente:{persona:{imagen:'./img/icon-user-default.png'}},accion_agua:{}};
          vecino.cliente.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
          vecino.cliente.persona.apellido_paterno=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
          vecino.cliente.persona.apellido_materno=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
          vecino.cliente.persona.nombres=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
          vecino.cliente.persona.ci=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
          vecino.cliente.persona.telefono_fijo=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
          vecino.cliente.persona.telefono_movil=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString():null;
          vecino.cliente.persona.direccion_zona=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null;
          vecino.cliente.persona.direccion=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?worksheet['I'+row].v.toString():0;
          vecino.cliente.persona.direccion_numero=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?worksheet['J'+row].v.toString():null;
          vecino.cliente.nit=worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?worksheet['K'+row].v.toString():null;
          vecino.cliente.razon_social=worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?worksheet['L'+row].v.toString():null;
          vecino.accion_agua.direccion=worksheet['M'+row]!=undefined && worksheet['M'+row]!=""?worksheet['M'+row].v.toString():null;
          vecino.accion_agua.codigo_medidor=worksheet['N'+row]!=undefined && worksheet['N'+row]!=""?worksheet['N'+row].v.toString():null;
          vecino.accion_agua.categoria=worksheet['O'+row]!=undefined && worksheet['O'+row]!=""?worksheet['O'+row].v.toString():null;
          vecino.accion_agua.es_mantenimiento=worksheet['P'+row]!=undefined && worksheet['P'+row]!=""?worksheet['P'+row].v.toString():null;
          vecino.accion_agua.es_mantenimiento=vecino.accion_agua.es_mantenimiento=="SI"?true:false;
          vecino.accion_agua.observacion=worksheet['Q'+row]!=undefined && worksheet['Q'+row]!=""?worksheet['Q'+row].v.toString():null;
          vecino.accion_agua.latitud=worksheet['R'+row]!=undefined && worksheet['R'+row]!=""?parseFloat(worksheet['R'+row].v.toString()):null;
          vecino.accion_agua.longitud=worksheet['S'+row]!=undefined && worksheet['S'+row]!=""?parseFloat(worksheet['S'+row].v.toString()):null;
					vecinos.push(vecino);
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);
				//if(!me.usuario.empresa.usar_pedidos && productos.length>500){
				//	me.mensaje_importacion="Número de productos máximo permitido: 50";
				//}
				me.blockUI.stop();
				me.vecinos_importacion=vecinos;
				me.vecinosImportacion=me.modalService.open(me.importacionvecinos, {windowClass:"importacion-vecinos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
				me.vecinosImportacion.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
				//aplicarCongeladorCabecera();
				//this.abrirPopup(this.idModalVistaPreviaImportacion);
				//this.guardarProductos(productos);
				//blockUI.stop();*/
			};
			reader.readAsBinaryString(f);
	    }  
  }
  
  descargarExcelEjemploImportacion () {
		let data=[];
    let cabecera1=[];
    let cabecera2=[];
    cabecera1.push("DATOS VECINO");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("DATOS ACCIÓN");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera1.push("");
    cabecera2.push("Código");
    cabecera2.push("Apellido Paterno");
    cabecera2.push("Apellido Materno");
    cabecera2.push("Nombres");
    cabecera2.push("C.I.");
    cabecera2.push("Teléfono Fijo");
    cabecera2.push("Teléfono Móvil");
    cabecera2.push("Dirección Zona/Villa");
    cabecera2.push("Direccion Avenida/Calle");
    cabecera2.push("Dirección Número Vivienda");
    cabecera2.push("NIT/CI");
    cabecera2.push("Razón Social");
    cabecera2.push("Dirección");
    cabecera2.push("Código Medidor");
    cabecera2.push("Categoría");
    cabecera2.push("Mantenimiento (SI/NO)");
    cabecera2.push("Observación");
    cabecera2.push("Latitud");
    cabecera2.push("Longitud");


		let reportData = {
			title: "EJEMPLO-DATOS-VECINOS",
			data: data,
      headers1: cabecera1,
      headers2: cabecera2,
		}

		this.excelService.descargarFormatoVecinosExcel(reportData,"EJEMPLO-DATOS-VECINOS","VECINOS");
  }

  abrirRegistroVecino(){
		this.edicion_vecino=this.modalService.open(this.edicion_vecinoRef, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.edicion_vecino.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
		aplicarWizard(this.wizard_edicion_vecino,this,'guardar');
	}
  
	async crearNuevoVecino(){
		this.obtenerCategoriasVecino();
		this.camara_capturada=false;
		let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_VECINO);
		this.vecino={
			acciones_agua:[],
		cliente:{
			codigo:codigo_nuevo,
			id_empresa:this.usuario.id_empresa,
			persona:{}
		}
    }
    this.abrirRegistroVecino();
    aplicarPluginImagen();
  }

  cerrarRegistroVecino(){
    this.edicion_vecino.close();
  }

  guardarVecinos(){
    this.blockUI.start();
		this.vecinosService.guardarVecinos(this.vecinos_importacion,this.usuario.id_empresa).subscribe((res:any) => {
			this.vecinosImportacion.close();
			this.getItems();
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
  }

  modificarLecturacion(vecino){
    this.modalRegistroLecturacion = this.modalService.open(VecinoLecturacionComponent, {ariaLabelledBy: 'modal-basic-title',windowClass: 'registrolecturacion', backdrop: 'static'});
    this.modalRegistroLecturacion.componentInstance.vecino = vecino;
    this.modalRegistroLecturacion.componentInstance.usuario = this.usuario;
		
		this.modalRegistroLecturacion.componentInstance.alTerminar.subscribe((res) => {
			if(res.mensaje){
        this.toastr.success(res.mensaje);
        
      }
      this.modalRegistroLecturacion.close();
		});
  }

  async modificarVecino(vecino){
	this.obtenerCategoriasVecino();
	this.camara_capturada=false;
	let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_VECINO);
    this.vecinosService.obtenerVecino(vecino.id).subscribe((res) => {
      this.vecino=res;
      this.abrirRegistroVecino();
      aplicarPluginImagen();
		});
  }

  async guardar(){
		if(this.validarFormularioRegistroVecino()){
			this.blockUI.start();
			if(this.vecino.id){
				this.vecinosService.actualizarVecino(this.vecino).subscribe((res:any) => {
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
          }
					this.cerrarRegistroVecino();
					this.getItems();
				});
			}else{
        this.vecino.cliente.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_VECINO):this.vecino.cliente.codigo;
				this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_VECINO);
				this.vecinosService.guardarVecino(this.vecino).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.cerrarRegistroVecino();
					this.getItems();
				});
			}
		}	
  }
  
  validarFormularioRegistroVecino(){
		let res=true;
		if(!this.vecino.cliente.codigo){
			this.toastr.error("Debe ingresar un codigo de vecino!");
			res=false;
		}
		if(!this.vecino.cliente.persona.nombres){
			this.toastr.error("Debe ingresar el nombre del vecino!");
			res=false;
    }
    if(!this.vecino.cliente.nit){
			this.toastr.error("Debe ingresar el nit o ci del vecino!");
			res=false;
    }
    if(!this.vecino.cliente.razon_social){
			this.toastr.error("Debe ingresar la razon social del vecino!");
			res=false;
		}
		return res;
  }
  
  generarPdfDeudores(){
    if(this.filtro_reporte_redudores.gestion){
      this.blockUI.start('Recuperando...espere porfavor!');
      this.vecinosService.obtenerReporteDeudores(this.usuario.id_empresa,this.filtro_reporte_redudores.gestion.nombre_corto).subscribe((vecinos:any[])=>{
        this.pdfService.imprimirReporteVecinosDeudores(this.usuario, vecinos,this.filtro_reporte_redudores.gestion.nombre_corto);
        this.blockUI.stop();
      });
    }
  }

  subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.vecino.cliente.persona.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionVecinosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

	async generarTarjetaIdentificacion(vecinos_seleccionados){
    	var doc = new PDFDocument({margin:0,size:[612, 936]});
		var stream = doc.pipe(blobStream());
		let canvas:any=document.getElementById('qr-code');
		// draw some text
		let fondo_cara_principal=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/fondo-credencial.png");
		doc.fontSize(8);
		var yCard=60,items=0,page=1;
		let fecha_inicio=new Date();
		let fecha_fin=new Date();
		fecha_fin.setDate(fecha_inicio.getDate()+365-1);
		for(var i=0;i<vecinos_seleccionados.length;i++){
			doc.switchToPage(page-1);
			items++;
			//Carnet Cara Principal
			doc.image(fondo_cara_principal,50,yCard,{width: 241,height:156});
			doc.image(this.usuario.empresa.imagen,230,yCard,{width: 70});
			doc.fillColor('white');
			doc.font('Helvetica-Bold',11);
			doc.text(this.usuario.empresa.nombre,60,yCard+10,{width:200});
			doc.font('Helvetica-Bold',8);
			doc.text("REGISTRO Nº 03010102",60,yCard+20,{width:200, align:'center'});
			doc.text("DISTRITO Nº 04 COCHABAMBA - BOLIVIA",60,yCard+28,{width:200, align:'center'});
			//doc.text("COCHABAMBA - BOLIVIA",60,yCard+36,{width:200, align:'center'});
			doc.font('Helvetica-Bold',10);
			//doc.fillColor('#66C8D1');
			//doc.rect(75, yCard+46, 135,15).fillAndStroke("#50BDF2", "#900");
			doc.fillColor('#AFCD3A');
			doc.text("CREDENCIAL DE AFILIADO",80,yCard+42);
			doc.font('Helvetica-Bold',8);
			doc.text("("+vecinos_seleccionados[i].categoria.nombre+")",120,yCard+55);
			doc.fontSize(8);
			doc.fillColor('white');
			doc.text("NOMBRE:",140,yCard+75);
			doc.text("C.I.:",140,yCard+105);
			doc.text("VALIDEZ:",140,yCard+125);
			doc.fontSize(10);
			doc.fillColor('#FBD10C');
			let imagen_persona=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+vecinos_seleccionados[i].cliente.persona.imagen);
			doc.image(imagen_persona,59,(yCard+65),{width: 80,height:80});
			let apellido_paterno=vecinos_seleccionados[i].cliente.persona.apellido_paterno?vecinos_seleccionados[i].cliente.persona.apellido_paterno:"";
			let apellido_materno=vecinos_seleccionados[i].cliente.persona.apellido_materno?vecinos_seleccionados[i].cliente.persona.apellido_materno:"";
			doc.text(apellido_paterno.toUpperCase()+" "+apellido_materno.toUpperCase(),140,yCard+85,{width:180});
			doc.text(vecinos_seleccionados[i].cliente.persona.nombres.toUpperCase(),140,yCard+95,{width:180});
			doc.text(vecinos_seleccionados[i].cliente.persona.ci.toUpperCase(),140,yCard+115,{width:180});
			let texto_fecha_inicio=fecha_inicio.getDate()+"/"+(fecha_inicio.getMonth()+1)+"/"+fecha_inicio.getFullYear();
			let texto_fecha_fin=fecha_fin.getDate()+"/"+(fecha_fin.getMonth()+1)+"/"+fecha_fin.getFullYear();
			doc.text("Del "+texto_fecha_inicio+" al "+texto_fecha_fin,140,yCard+135,{width:180});
			doc.fillColor('white');
			doc.text(vecinos_seleccionados[i].cliente.codigo.toUpperCase(),80,yCard+137);
			
			
			//Carnet Cara trasera
			let imagen_credencial_2=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/fondo credencial 2.png");
			doc.image(imagen_credencial_2,292,yCard,{width: 241,height:156});
			let imagen_sello=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/sello-personal.png");
			doc.image(imagen_sello,350,yCard+100,{width: 120,align:"center"});
			qr.canvas({
				canvas: canvas,
				value: vecinos_seleccionados[i].cliente.codigo
			},function(){console.log("created");});
			var qrImage=canvas.toDataURL('image/png');
			doc.image(qrImage,460,(yCard+10),{width: 70});	
			//doc.rect(295, yCard+16, 155,85).fillAndStroke("yellow", "#900");
			doc.fillColor('black');
			doc.fontSize(9);
			doc.text("Al portador del presente credencial, otorgarle todos los beneficios acordados según convenio con el directorio de la Junta Vecinal de Pampa Grande.",310,yCard+10,{width:160});
			doc.fontSize(6);
			doc.text("-----------------------------------------------------------------------",340,yCard+110);

			yCard=yCard+157;
			
			if(items==5){
				items=0;
				yCard=60;
				doc.addPage();
				page++;
			}
		}
		
		doc.end();

		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.vecino.cliente.persona.imagen = webcamImage.imageAsDataUrl;
	this.camara_capturada=true;
  }

  public capturarImagen(): void {
    this.trigger.next();
  }

  	async obtenerCategoriasVecino(){
		let tipo_categoria:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_CATEGORIA_AFILIADO).toPromise();
		this.tipo_categoria=tipo_categoria?tipo_categoria:{clases:[]};
	}

	async obtenerCategoriasAccionAgua(){
		let tipo_accion_agua:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_PRODUCTO_SUBGRUPOS).toPromise();
		this.tipo_accion_agua=tipo_accion_agua?tipo_accion_agua:{clases:[]};
	}

	imprimirBoletaSemanal(vecinos_seleccionados){
		var doc = new PDFDocument({margin:0});
		var stream = doc.pipe(blobStream());
		for(var i=0;i<vecinos_seleccionados.length;i++)
		{
			var yPage=0;
			/*for(var neighborPaymentReport=0;neighborPaymentReport<2 && i<meterReaders.length;neighborPaymentReport++)
			{*/
				var xPage=0;
				for(var item=0;item<4 && i<vecinos_seleccionados.length;item++)
				{	
					doc.rect(xPage+5,yPage+5,295,385).stroke();
					doc.image(this.usuario.empresa.imagen,xPage+245,yPage+5, {width: 50, height:50});
					doc.fillColor('blue');
					doc.font('Helvetica-Bold',11);
					doc.text(this.usuario.empresa.nombre,xPage+10,yPage+10);
					doc.font('Helvetica-Bold',8);
					doc.text("CODIGO: "+vecinos_seleccionados[i].cliente.codigo.toUpperCase(),xPage+10,yPage+55);
					doc.font('Helvetica-Bold',8);
					doc.text("REGISTRO Nº 03010102",xPage+10,yPage+20);
					doc.text("DISTRITO Nº 04 COCHABAMBA - BOLIVIA",xPage+10,yPage+27);
					doc.fillColor('black');
					let apellido_paterno=vecinos_seleccionados[i].cliente.persona.apellido_paterno?vecinos_seleccionados[i].cliente.persona.apellido_paterno:"";
					let apellido_materno=vecinos_seleccionados[i].cliente.persona.apellido_materno?vecinos_seleccionados[i].cliente.persona.apellido_materno:"";
					doc.text("NOMBRE : "+apellido_paterno.toUpperCase()+" "+apellido_materno.toUpperCase()+" "+vecinos_seleccionados[i].cliente.persona.nombres.toUpperCase(),xPage+90,yPage+55);
					doc.fontSize(13);
					doc.font('Helvetica-Bold');
					doc.text("TARJETA ECONÓMICA",xPage+70,yPage+40);
					
					doc.font('Helvetica');
					doc.rect(xPage+5,yPage+65,295,0).stroke();
					doc.fontSize(9);

					let y_cuadro=yPage-40;
					let date_friday=this.getNextDayOfWeek(new Date(),5);
					for(var k=0;k<8;k++){
						doc.text('FECHA',xPage+15,y_cuadro+110);
						doc.text((date_friday.getDate()+"/"+(date_friday.getMonth()+1)+"/"+date_friday.getFullYear()),xPage+25,y_cuadro+130);
						doc.text('IMPORTE (Bs)',xPage+110,y_cuadro+110);
						doc.text('FIRMA CAJERO(A)',xPage+190,y_cuadro+110);
						doc.rect(xPage+13,y_cuadro+108,279,10).stroke();
						doc.rect(xPage+100,y_cuadro+108,0,40).stroke();
						doc.rect(xPage+180,y_cuadro+108,0,40).stroke();
						doc.rect(xPage+13,y_cuadro+118,279,30).stroke();
						y_cuadro=y_cuadro+40;
						date_friday.setDate(date_friday.getDate()+7);
					}
					
					xPage=xPage+306;
					if(item<=2){
						i++;
					}
					
					if(item==1){
						xPage=0;
						yPage=yPage+396;
					}
				}
				
				if((i+1)<vecinos_seleccionados.length){
					doc.addPage();
				}
				
				
			//}		
		}
		
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	getNextDayOfWeek(date, dayOfWeek) {
		// Code to check that date and dayOfWeek are valid left as an exercise ;)
	
		var resultDate = new Date(date.getTime());
	
		resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
	
		return resultDate;
	}

	async crearNuevaAccionAgua(){
		await this.obtenerCategoriasAccionAgua();
		this.accion_agua={};
		this.modificacion_accion_agua=this.modalService.open(this.modificacion_accion_agua_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modificacion_accion_agua.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	agregarAccionAgua(){
		if(this.validarAccionAgua()){
			this.vecino.acciones_agua.push(this.accion_agua);
			this.modificacion_accion_agua.close();
		}
	}

	validarAccionAgua(){
		let res=true;
		if(!this.accion_agua.codigo_medidor){
			this.toastr.error("Debe ingresar un codigo de accion de agua!");
			res=false;
		}
		if(!this.accion_agua.categoria){
			this.toastr.error("Debe ingresar la categoria!");
			res=false;
		}
		return res;
	}

	abrirPopupConfirmacionEliminacion(accion_agua){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular la accion de agua?";
		this.popupConfirmacion.componentInstance.data = accion_agua;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarAccionAgua($e.data);
			}
			this.popupConfirmacion.close();
    	});
	}

	eliminarAccionAgua(accion_agua){
		accion_agua.eliminado=true;
		accion_agua.activo=false;
	}

}
