import { UsuarioService } from '../base/services/usuario/usuario.service';
import { Util } from '../utils/utils';

export class PageConfiguration {
    constructor(
        public object:any,
        public application_id:number,
        public usuarioService:UsuarioService
    ){

    }
    
    getConfKeys(){
        return Object.keys(this.object.datos.visualizacion_columnas);
    }

    getOptionKeys(){
        return Object.keys(this.object.datos.opciones_permisos);
    }

    async updateObject(){
        let obj=this.object;
        let res:any=await this.usuarioService.actualizarConfiguracionPagina(this.object,this.application_id).toPromise();
        obj.datos=res.datos;
    }

    getConfiguration(){
        return this.object.datos.visualizacion_columnas;
    }

    getObject(){
        return this.object;
    }

    getOrden(){
        return this.object.datos.opciones_permisos;
    }

    async compareAndUpdateConfigurations(id_empresa,id_usuario:number=0){
        let oldConfiguration:any=await this.usuarioService.obtenerConfiguracionPagina(id_empresa,this.application_id,id_usuario).toPromise();
        //this.object.crear=await this.compareAndUpdateConfig(oldConfiguration) && await this.compareAndUpdateOptions(oldConfiguration);
        await this.compararYactualizarConfiguracionAplicacion(oldConfiguration?oldConfiguration.datos:{});
        await this.updateObject();
    }

    async compareAndUpdateConfig(oldConfiguration){
        let obj=this.object,res=true;
        if(oldConfiguration){
            if(oldConfiguration.configuracion){
                let respuesta=Util.completarAtributosJson(oldConfiguration.configuracion,obj.configuracion);
                obj.configuracion=respuesta[0];
                res=respuesta[1];
            }else{
                res=false;
            }
        }else{
            res=true;
        }
        return res;
    }

    async compareAndUpdateOptions(oldConfiguration){
        let obj=this.object,res=true;
        if(oldConfiguration){
            if(oldConfiguration.orden && oldConfiguration.orden!="null"){
                let orden_actual=JSON.parse(oldConfiguration.orden);
                let respuesta_impresion:any=[{},true];
                if(orden_actual.impresion){
                    respuesta_impresion=Util.completarAtributosJson(orden_actual.impresion?orden_actual.impresion:{},obj.orden.impresion);
                    obj.orden.impresion=respuesta_impresion[0];
                }
                if(obj.orden){
                    let respuesta=Util.completarAtributosJson(orden_actual,obj.orden);
                    obj.orden=respuesta[0];
                    res=respuesta[1] && respuesta_impresion[1];
                }
            }else{
                res=false;
            }
        }else{
            res=true;
        }
        return res;
    }

    async compararYactualizarConfiguracionAplicacion(oldConfiguration){
        let configuracion_nueva=this.object.datos;
        if(oldConfiguration){
            let configuracion_actual=oldConfiguration;
            let respuesta_col:any=[{},true];
            if(configuracion_actual.visualizacion_columnas && configuracion_nueva.visualizacion_columnas){
                respuesta_col=Util.completarAtributosJson(configuracion_actual.visualizacion_columnas,configuracion_nueva.visualizacion_columnas);
                configuracion_nueva.visualizacion_columnas=respuesta_col[0];
            }else if(!configuracion_actual.visualizacion_columnas && configuracion_nueva.visualizacion_columnas){
                configuracion_nueva.visualizacion_columnas=configuracion_nueva.visualizacion_columnas;
            }

            if(configuracion_actual.opciones_permisos && configuracion_nueva.opciones_permisos){
                if(configuracion_actual.opciones_permisos.impresion && configuracion_nueva.opciones_permisos.impresion){
                    respuesta_col=Util.completarAtributosJson(configuracion_actual.opciones_permisos.impresion,configuracion_nueva.opciones_permisos.impresion);
                    configuracion_nueva.opciones_permisos.impresion=respuesta_col[0];
                }else if(!configuracion_actual.opciones_permisos.impresion && configuracion_nueva.opciones_permisos.impresion){
                    configuracion_nueva.opciones_permisos.impresion=configuracion_nueva.opciones_permisos.impresion;
                }
                respuesta_col=Util.completarAtributosJson(configuracion_actual.opciones_permisos,configuracion_nueva.opciones_permisos);
                configuracion_nueva.opciones_permisos=respuesta_col[0];
            }else if(!configuracion_actual.opciones_permisos && configuracion_nueva.opciones_permisos){
                configuracion_nueva.opciones_permisos=configuracion_nueva.opciones_permisos;
            }
        }else{
        }
    }
}
