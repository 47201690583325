import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { ProductosComponent } from './components/productos/productos.component';
import { CombosComponent } from './components/combos/combos.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { CotizacionesComponent } from './components/cotizaciones/cotizaciones.component';
import { CajaComponent } from './components/caja/caja.component';
import { MovimientosCajaComponent } from './components/movimientos-caja/movimientos-caja.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { MovimientoInventarioComponent } from './components/movimiento-inventario/movimiento-inventario.component';
import { ComprasComponent } from './components/compras/compras.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { ReporteVentasProductosComponent } from './components/reporte-ventas-productos/reporte-ventas-productos.component';
import { ProveedoresComponent } from './components/proveedores/proveedores.component';
import { CuentasPorCobrarComponent } from './components/cuentas-por-cobrar/cuentas-por-cobrar.component';
import { CuentasPorPagarComponent } from './components/cuentas-por-pagar/cuentas-por-pagar.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { InventarioExternoComponent } from './components/inventario-externo/inventario-externo.component';
import { LibroComprasComponent } from './components/libro-compras/libro-compras.component';
import { LibroVentasComponent } from './components/libro-ventas/libro-ventas.component';
import { PantallaDespachoComponent } from './components/pantalla-despacho/pantalla-despacho.component';
import { PantallasComponent } from './components/pantallas/pantallas.component';
import { CierresCajaComponent } from './components/cierres-caja/cierres-caja.component';
import { ReporteVentasComponent } from './components/reporte-ventas/reporte-ventas.component';
import { CertificacionSinComponent } from './components/certificacion-sin/certificacion-sin.component';
import { CalendarioPedidosComponent } from './components/calendario-pedidos/calendario-pedidos.component';
import { ReporteComprasProductosComponent } from './components/reporte-compras-productos/reporte-compras-productos.component';

const routes: Routes = [
  { path: 'productos',component: ProductosComponent,canActivate:[HabilitadoGuard]},
  { path: 'snap-combo',component: CombosComponent,canActivate:[HabilitadoGuard]},  
  { path: 'ventas',component: VentasComponent,canActivate:[HabilitadoGuard]},
  { path: 'reportes',component: ReportesComponent,canActivate:[HabilitadoGuard]},
  { path: 'cotizacion',component: CotizacionesComponent,canActivate:[HabilitadoGuard]},
  { path: 'compras',component: ComprasComponent,canActivate:[HabilitadoGuard]},
  { path: 'clientes',component: ClientesComponent,canActivate:[HabilitadoGuard]},

  { path: 'inventario',component: InventarioComponent,canActivate:[HabilitadoGuard]},
  { path: 'inv-movimiento-inventario',component: MovimientoInventarioComponent,canActivate:[HabilitadoGuard]},
  { path: 'inv-inventario-externo',component: InventarioExternoComponent,canActivate:[HabilitadoGuard]},

  { path: 'reporte-ventas-productos',component: ReporteVentasProductosComponent,canActivate:[HabilitadoGuard]},
  { path: 'reporte-compras-productos',component: ReporteComprasProductosComponent,canActivate:[HabilitadoGuard]},
  { path: 'reporte-ventas',component: ReporteVentasComponent,canActivate:[HabilitadoGuard]},
  { path: 'pedidos',component: PedidosComponent,canActivate:[HabilitadoGuard]},
  { path: 'calendario-pedidos',component: CalendarioPedidosComponent,canActivate:[HabilitadoGuard]},
  { path: 'proveedores',component: ProveedoresComponent,canActivate:[HabilitadoGuard]},
  { path: 'estado-cuentas-cliente',component: CuentasPorCobrarComponent,canActivate:[HabilitadoGuard]},
  { path: 'estado-cuentas-proveedor',component: CuentasPorPagarComponent,canActivate:[HabilitadoGuard]},
  { path: 'pantallas',component:PantallasComponent,canActivate:[HabilitadoGuard]},
  { path: 'pantalla-despacho',component:PantallaDespachoComponent,canActivate:[HabilitadoGuard]},
  { path: 'libro-ventas',component:LibroVentasComponent,canActivate:[HabilitadoGuard]},
  { path: 'libro-compras',component:LibroComprasComponent,canActivate:[HabilitadoGuard]},

  { path: 'cierres-caja',component:CierresCajaComponent,canActivate:[HabilitadoGuard]},
  { path: 'codigo-control',component:CertificacionSinComponent,canActivate:[HabilitadoGuard]},
  { path: 'caja',component:CajaComponent,canActivate:[HabilitadoGuard]},
  { path: 'movimientos-caja',component:MovimientosCajaComponent,canActivate:[HabilitadoGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SnapquickRoutingModule { }
