import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroPacienteComponent } from '../registro-paciente/registro-paciente.component';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css']
})
export class PacientesComponent extends BaseComponent implements OnInit {

  pacientes:any[]=[];
  modal_registro_paciente:NgbModalRef;
  util:any=Util;
  pacientes_importacion:any[]=[];

  pacientes_importacion_modal:NgbModalRef;
  @ViewChild('pacientes_importacion_modal')
  private pacientes_importacion_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private pacientesService:PacientesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa,
      categoria: GlobalVariable.Dictionary.SELECCION_TODOS,
			categorias:[GlobalVariable.Dictionary.SELECCION_TODOS],
		}
    this.column = "updatedAt";
		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.pacientesService.obtenerListaPacientes(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.pacientes=dato.pacientes;
			this.blockUI.stop();
		});
	}

  crearNuevoPaciente(){
    this.modal_registro_paciente = this.modalService.open(RegistroPacienteComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'registro-paciente', backdrop: 'static',scrollable:true});
    this.modal_registro_paciente.componentInstance.cliente = JSON.parse(JSON.stringify({cliente:{persona:{}}}));
		this.modal_registro_paciente.componentInstance.usuario = this.usuario;
    this.modal_registro_paciente.componentInstance.alTerminar.subscribe((res) => {
      this.modal_registro_paciente.close();
      if(res){
        this.getItems();
      }
    });
  }

  modificarPaciente(paciente){
    paciente.cliente.persona.fecha_nacimiento=new Date(paciente.cliente.persona.fecha_nacimiento);
    paciente.cliente.persona.fecha_nacimiento_texto={day:paciente.cliente.persona.fecha_nacimiento.getDate(),month:paciente.cliente.persona.fecha_nacimiento.getMonth()+1,year:paciente.cliente.persona.fecha_nacimiento.getFullYear()},
    this.modal_registro_paciente = this.modalService.open(RegistroPacienteComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'registro-paciente', backdrop: 'static',scrollable:true});
    this.modal_registro_paciente.componentInstance.paciente = JSON.parse(JSON.stringify(paciente));
		this.modal_registro_paciente.componentInstance.usuario = this.usuario;
    this.modal_registro_paciente.componentInstance.alTerminar.subscribe((res) => {
      this.modal_registro_paciente.close();
      if(res){
        this.getItems();
      }
    });
  }

  descargarExcelEjemploImportacion(){
    let data=[];
    let cabecera=["Codigo","Apellido Paterno","Apellido Materno","Nombres", "Telefono Movil","Telefono","Fecha de Nacimiento"];
    data.push(cabecera);
    for (var i = 1; i <= 14; i++) {
      var columns = [];
      columns.push("COD"+i);
      columns.push("PACIENTE "+i);
      columns.push("PACIENTE "+i);
      columns.push("PACIENTE "+i);
      columns.push("70723756");
      columns.push("4438859");
      columns.push("17/03/1991");
      data.push(columns);
    }
  
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("PACIENTES");
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );
  
    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 20;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;
    const dobCol4 = worksheet.getColumn(4);
    dobCol4.width = 20;
  
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, "EJEMPLO-IMPORTACION-PACIENTES.xlsx");
    })
    this.blockUI.stop();
  }
  
  subirExcelPacientes(event){
    this.blockUI.start();
    let me=this;
    var files = event.target.files;
    var i, f;
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function (e) {
  
        var data = e.target.result;
  
        var workbook = XLSX.read(data, { type: 'binary' });
        var first_sheet_name = workbook.SheetNames[0];
        var row = 2, i = 0;
        var worksheet = workbook.Sheets[first_sheet_name];
        var pacientes = [];
        do {
          let paciente:any = {cliente:{persona:{}}};
          paciente.cliente.codigo = worksheet['A' + row] != undefined && worksheet['A' + row] != "" ? worksheet['A' + row].v.toString() : null;
          paciente.cliente.persona.apellido_paterno = worksheet['B' + row] != undefined && worksheet['B' + row] != "" ? worksheet['B' + row].v.toString() : null;
          paciente.cliente.persona.apellido_materno = worksheet['C' + row] != undefined && worksheet['C' + row] != "" ? worksheet['C' + row].v.toString() : null;
          paciente.cliente.persona.nombres = worksheet['D' + row] != undefined && worksheet['D' + row] != "" ? worksheet['D' + row].v.toString() : null;
          paciente.cliente.persona.telefono_movil = worksheet['E' + row] != undefined && worksheet['E' + row] != "" ? worksheet['E' + row].v.toString() : null;
          paciente.cliente.persona.telefono = worksheet['F' + row] != undefined && worksheet['F' + row] != "" ? worksheet['F' + row].v.toString() : null;
          let fecha_nacimiento=null;
          if (worksheet['G' + row] != undefined && worksheet['G' + row] != "") {
						if (typeof worksheet['G' + row].v === 'number') {
							if (worksheet['G' + row].v % 1 === 0) {
								fecha_nacimiento = new Date((worksheet['G' + row].v - (25567 + 1)) * 86400 * 1000);
							}
						} else {
							fecha_nacimiento = Util.convertirTextoAfecha(worksheet['G' + row].v);
						}
					}
          paciente.cliente.persona.fecha_nacimiento = fecha_nacimiento;
          pacientes.push(paciente);
          row++;
          i++;
        } while (worksheet['A' + row] != undefined);
          //$scope.guardarClientes(clientes);
          me.pacientes_importacion=pacientes;
          me.pacientes_importacion_modal=me.modalService.open(me.pacientes_importacion_modal_ref, {windowClass:"importacion-productos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
          me.pacientes_importacion_modal.result.then((result) => {
            me.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
          });
          me.blockUI.stop();
        };
        reader.readAsBinaryString(f);
      }
  }

  guardarPacientes() {
		this.blockUI.start();
		this.pacientesService.guardarPacientesImportacion({ pacientes: this.pacientes_importacion,
			id_empresa: this.usuario.id_empresa,
			id_usuario: this.usuario.id
		 }).subscribe((res:any)=>{
			 this.blockUI.stop();
			 this.pacientes_importacion_modal.close();
			 this.getItems();
			this.toastr.success(res.mensaje);
		 });
	}

}
