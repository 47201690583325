import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {

  constructor(private http: HttpClient) { }

  public obtenerListaProveedores(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"proveedores/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarProveedor(proveedor){
    return this.http.post(GlobalVariable.API_SERVER_URL+"proveedores",proveedor,GlobalVariable.httpOptions);
  }

  public actualizarProveedor(proveedor){
    return this.http.put(GlobalVariable.API_SERVER_URL+"proveedores/"+proveedor.id,proveedor,GlobalVariable.httpOptions);
  }

  public eliminarProveedor(id_proveedor){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"proveedores/"+id_proveedor,GlobalVariable.httpOptions);
  }

  public guardarProveedores(proveedores,id_empresa){
    return this.http.post(GlobalVariable.API_SERVER_URL+"proveedores/empresa",{proveedores:proveedores,id_empresa:id_empresa},GlobalVariable.httpOptions);
  }

  public buscarProveedoresNit(id_empresa,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"proveedores/empresa/"+id_empresa+"/texto/"+texto, GlobalVariable.httpOptions);
  }

  public obtenerCuentasPorPagar(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-proveedores/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/cuentas-liquidadas/"+(paginator.filter.cuentas_liquidadas?1:0)+"/identificacion/"+((paginator.filter.proveedor==null || paginator.filter.proveedor==undefined)?0:paginator.filter.proveedor)+"/columna/"+paginator.column+"/direccion/"+paginator.direction, GlobalVariable.httpOptions);
  }

  public obtenerCuentaProveedor(id_proveedor,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-proveedores/"+id_proveedor+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public obtenerHistoricoCompras(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/historico-compras-proveedor/"+paginator.filter.id_proveedor+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  
}
