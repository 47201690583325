import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PersistenciaService {

  usuario = new BehaviorSubject<any>(null);

  constructor() { }

  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  setUsuario(data: any): void {
    try {
      localStorage.setItem("usuario", JSON.stringify(data));
      this.usuario.next(data);
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  getUsuario() : Observable<any> {
    try {
      this.usuario.next(this.get('usuario'));
      return this.usuario.asObservable();
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  removerUsuario(): void {
    try {
      localStorage.removeItem("usuario");
      localStorage.removeItem("token");
      localStorage.removeItem("ngStorage-usuario");
      localStorage.removeItem("ngStorage-token");
      this.usuario=new BehaviorSubject<any>(null);
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  delete(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }
}
