import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../../models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-mensaje-vista',
  templateUrl: './mensaje-vista.component.html',
  styleUrls: ['./mensaje-vista.component.css']
})
export class MensajeVistaComponent extends BaseComponent implements OnInit {

  @Input() mensaje:any;
  @Input() movimiento_inventario:any;
  @Input() vista_bandeja:boolean;
  @Output() onVistaBandeja:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
  }

  volverBandeja(){
    this.onVistaBandeja.emit({vista_mensaje:false});
  }

}
