<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Asignacion de materias: {{estudiante.apellido_paterno}} {{estudiante.apellido_materno}} {{estudiante.nombres}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarAsignacionMaterias()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <div class="input-group">
                <label for="feini">Inscripción </label>&nbsp;
                <select (change)="buscarMaterias()" [compareWith]="compararObjectos" name="inscripcion" class="form-control" [(ngModel)]="filter.inscripcion_instituto">
                    <option [ngValue]="inscripcion" *ngFor="let inscripcion of filter.inscripciones_instituto">{{inscripcion.carrera.nombre}}-{{inscripcion.gestion?inscripcion.gestion.nombre:''}}-{{inscripcion.semestre?inscripcion.semestre.nombre:''}}</option>
                </select>
            </div>  
          </div>
        <div class="col-md-6 col-xs-3 col-sm-3">
            <button (click)="buscarMaterias()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <div class="row p-1">
        <div class="col-lg-6 p-1" [hidden]="materias.length==0">
            <div class="card bcard overflow-hidden shadow text-center">
                <div class="card-body p-3px bgc-green-d2">
                    <div class="radius-1 table-responsive">
                        <div class="col-12">
                            <span class="text-white text-125">Materias Carrera</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card bcard overflow-hidden shadow">
              <div class="card-body p-3px bgc-green-d2">
                <div class="radius-1 table-responsive">
                  <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                    <thead class="brc-transparent">
                      <tr class="bgc-green-d2 text-white">
                        <th>
                          Nº
                        </th>
                        <th>
                          Código
                        </th>
                        <th>
                          Materia
                        </th>
                        <th>
                            Periodo
                        </th>
                        <th>
                          Pre requisito
                        </th>
                        <th style="text-align: center; width: 140px; " data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                      </tr>
                    </thead>
        
                    <tbody>
                      <tr class="bgc-h-yellow-l3" *ngFor="let materia of materias;let i=index;">
                        <ng-container>
                          <td class="text-600 text-dark text-center">
                            {{i+1}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.codigo}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.nombre}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.periodo.nombre}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.pre_requisito?materia.pre_requisito.nombre:''}}
                          </td>
                          <td class="text-center">
                              <a title="Asignar Materia" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="asignarMateria(materia)">          
                                  <i class="fas fa-arrow-right text-105"></i>        
                              </a>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        
        </div>
        <div class="col-lg-6 p-1" [hidden]="materias_asignadas.length==0">
            <div class="card bcard overflow-hidden shadow text-center">
                <div class="card-body p-3px bgc-orange-d2">
                    <div class="radius-1 table-responsive">
                        <div class="col-12">
                            <span class="text-white text-125">Materias Asignadas</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card bcard overflow-hidden shadow">
              <div class="card-body p-3px bgc-orange-d2">
                <div class="radius-1 table-responsive">
                  <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                    <thead class="brc-transparent">
                      <tr class="bgc-orange-d2 text-white">
                        <th>
                          Nº
                        </th>
                        <th>
                          Código
                        </th>
                        <th>
                          Materia
                        </th>
                        <th>
                            Periodo
                        </th>
                        <th>
                          Pre requisito
                        </th>
                        <th style="text-align: center; width: 140px; " data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                      </tr>
                    </thead>
        
                    <tbody>
                      <tr class="bgc-h-yellow-l3" *ngFor="let materia of materias_asignadas;let i=index;">
                        <ng-container *ngIf="materia.activo">
                          <td class="text-600 text-dark text-center">
                            {{i+1}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.materia.codigo}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.materia.nombre}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.materia.periodo.nombre}}
                          </td>
                          <td class="text-600 text-dark text-center">
                            {{materia.materia.pre_requisito?materia.materia.pre_requisito.nombre:''}}
                          </td>
                          <td class="text-center">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="removerMateria(materia)">          
                                <i class="fa fa-trash-alt text-105"></i>        
                            </a>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        
        </div>
    </div>
</div>