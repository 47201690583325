import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ComprobantesService {

  constructor(private http: HttpClient) { }

  public obtenerComprobantes(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"comprobantes/empresa/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin+"/tipo-comprobante/"+paginator.filter.tipo.id+"/numero/"+(paginator.filter.numero?paginator.filter.numero:0), GlobalVariable.httpOptions);
  }

  public guardarComprobante(comprobante){
    return this.http.post(GlobalVariable.API_SERVER_URL+"comprobante-contabilidad",comprobante, GlobalVariable.httpOptions);    
  }

  public actualizarComprobante(comprobante){
    return this.http.put(GlobalVariable.API_SERVER_URL+"comprobante-contabilidad/"+comprobante.id,comprobante, GlobalVariable.httpOptions);    
  }

  public anularComprobante(comprobante){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"comprobante-contabilidad/"+comprobante.id, GlobalVariable.httpOptions);    
  }

  public generarBalanceComprobacionSumasSaldos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"balance-comprobacion-sumas-y-saldos/empresa/"+paginator.filter.id_empresa+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin, GlobalVariable.httpOptions);    
  }

  public obtenerComprobante(id_comprobante){
    return this.http.get(GlobalVariable.API_SERVER_URL+"comprobante-contabilidad/"+id_comprobante, GlobalVariable.httpOptions);    
  }
}
