<div class="modal-body card-body">
    <div id="dialog-panel-ventas" class="widget-body">
        <div class="row">
            <div class="col-12">
                <div class="widget-box">
                    <form id="formularioVentaPanel" name="formularioVentaPanel" novalidate>
                        <div class="widget-body">
                            <div class="widget-main {{color.style}}">
                                <div class="row">
                                    <div class="col-12">
                                            <button style="z-index:90000; position:absolute;" type="button" (click)="showHideFirstRow()" class="button-style btn {{color.stylebutton}} btn-xs pos-rel pull-left">
                                                <i class="fas fa-angle-double-down"></i>
                                            </button>
                                            <div class="row first-row">
                                                <div *ngIf="usuario.empresa.usar_mesas" class="col-md-1 col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-md-12 col-sm-12 col-12">
                                                            <label *ngIf="pedido.mesas" class="header smaller lighter blue">MESAS: <span  *ngFor="let mesa of pedido.mesas">{{mesa.numero+"-"}}</span> </label>
                                                            <label *ngIf="!pedido.mesas" class="header smaller lighter blue">MESAS: <span *ngFor="let mesaPedido of pedido.mesasPedidoRestaurante">{{mesaPedido.mesa.numero+"-"}}</span> </label>
                                                        </div>
                                                        <!--<div class="col-sm-4">
                                                            <label *ngIf="pedido.garzones"  class="header smaller lighter blue">MOZOS : <span *ngFor="let garzon of pedido.garzones"><img src="{{garzon.persona.imagen}}" width="80"></span> </label>
                                                            <label *ngIf="!pedido.garzones" class="header smaller lighter blue">MOZOS : <span *ngFor="let garzonPedido of pedido.garzonesPedidoRestaurante"><img src="{{garzonPedido.garzon.persona.imagen}}" width="80"></span></label>
                                                        </div>-->
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button (click)="guardarPedidoRestaurante()" type="button" [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" class="btn btn-primary btn-app btn-sm">
                                                        <i class="ace-icon fa fa-save align-top bigger-110"></i>
                                                        <br>GUARDAR
                                                    </button>
                                                </div>
                                                <div *ngIf="(usuario.empresa.usar_mesas || usuario.empresa.usar_pedidos_restaurante) && !usuario.empresa.usar_despachos" class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button type="button" class="btn btn-warning btn-app btn-sm" [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" (click)="generarCuenta()">
                                                        <i class="ace-icon far fa-newspaper align-top bigger-110"></i>
                                                        <br>CUENTA
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4" *ngIf="!usuario.empresa.usar_despachos">
                                                    <button type="button" class="btn btn-success btn-app btn-sm" [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" (click)="abrirPopupDatosFacturacion()">
                                                        <i class="ace-icon fas fa-dollar-sign align-top bigger-110"></i>
                                                        <br>COBRAR
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button type="button" class="btn btn-danger btn-app btn-sm" (click)="cerrarComponente()">
                                                        <i class="ace-icon fas fa-window-close red2 align-top bigger-110"></i>
                                                        <br> CERRAR
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4" *ngIf="usuario.empresa.usar_mesas">
                                                    <div class="row">
                                                        <div class="col-6 col-md-6">
                                                            <button [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupCambioMesa()">
                                                                <i class="ace-icon fa fa-exchange-alt align-top bigger-110"></i>
                                                            </button>
                                                        </div>											
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-sm-6">
                                                            <button [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupAsignacionGarzon()">
                                                                <i class="ace-icon fa fa-male align-top bigger-110"></i>
                                                            </button>
                                                        </div>										
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4" *ngIf="usuario.empresa.usar_mesas">
                                                    <div class="row">
                                                        <div class="col-md-6 col-6">
                                                            <button [disabled]="pedido.detallesPedido && pedido.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupUnionMesas()">
                                                                <i class="ace-icon fa fa-sync-alt align-top bigger-110"></i>
                                                            </button>
                                                        </div>											
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-4 col-md-4">
                                                    <div class="group-color-button">
                                                        <div class="row">
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-verde" (click)="cambiarColor('green-style','green-style-button')"></button>		
                                                            </div>
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-rojo" (click)="cambiarColor('red-style','red-style-button')"></button>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12">
                                                                <div class="punto"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-azul" (click)="cambiarColor('skyblue-style','skyblue-style-button')"></button>
                                                            </div>
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-cafe" (click)="cambiarColor('brown-style','brown-style-button')"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 col-4 col-sm-4">
                                                    <label>ORDENAR</label>
                                                    <button type="button" class="button-style btn red-style-button btn-app btn-xs" (click)="ordenarProductos(ordenProductos)">A<- ->Z</button>
                                                </div>
                                                <div class="col-md-1 col-4 col-sm-4 d-block d-sm-block d-md-block d-lg-none">
                                                    <div class="inline pos-rel pull-right">
                                                                    <a data-toggle="dropdown" href="javascript:void(0)" class="btn btn-danger btn-sm dropdown-toggle {{color.stylebutton}}" aria-expanded="false">
                                                                        <i class="fa fa-list-alt"></i>
                                                                        <span class="caret"></span>
                                                                    </a>
                                                                    <ul id="campos-detalle-venta" class="dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret button-check-group">
                                                                        
                                                                        <input type="text" ng-model="filterText" class="imput-search">
                                                                        <li *ngFor="let grupo of grupos_productos">
                                                                            <button type="button" (click)="clasificarGrupo(grupo.id)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">{{grupo.nombre}}
                                                                            </button>
                                                                        </li>
    
                                                                        
                                                                        <li class="divider"></li>
                                                                    </ul>
                                                                </div>
                                                </div>
                                                <div class="col-md-2 col-12 col-sm-4">
                                                    <label>BÚSQUEDA</label>
                                                    <input class="form-control" placeholder="Buscar" name="search" type="text" [(ngModel)]="search" (keyup)="filtrarProductos(search)">
                                                </div>
                                                <div class="col-md-2 col-12 col-sm-4" *ngIf="usuario.empresa.usar_combos">
                                                    <div class="sidebar-shortcuts-large" id="sidebar-shortcuts-large">
                                                        <div class="row" *ngIf="grupos_productos.length>0">
                                                            <div class="col-md-12">
                                                                <button type="button" (click)="mostrarProductos()" class="button-style btn-panel {{color.stylebutton}} btn-block text-cut">PRODUCTOS</button>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <button type="button" (click)="mostrarCombos()" class="button-style btn-panel {{color.stylebutton}} btn-block text-cut">COMBOS</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>	
                            
                            
                            
                                <div class="row">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-4">
                                        <!-- PAGE CONTENT BEGINS -->
                                        <div class="row">
                                            <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-6 col-6 col-sm-6  ">
                                                <label>Sucursal</label>
                                                <select [disabled]="pedido.id" [compareWith]="compararObjectos" class="form-control" name="sucursal" [(ngModel)]="pedido.sucursal" (change)="obtenerAlmacenesActividades(pedido.sucursal)">
                                                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="usuario.empresa.usar_almacenes" class="col-md-6 col-6 col-sm-6  ">
                                                <label>Almacen</label>
                                                <select [compareWith]="compararObjectos" id="almacen" name="almacen" class="form-control" (change)="cambiarAlmacen(almacen)"
                                                    [(ngModel)]="almacen" >
                                                        <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6  ">
                                                <label>Cliente</label>
                                                <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="pedido.cliente && pedido.cliente.id">
                                                    <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{pedido.cliente.identificacion}}</span><br>
                                                </div>
                                                <input *ngIf="!(pedido.cliente && pedido.cliente.id)" required id="venta_razon_social" name="venta_razon_social"
                                                    [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerClientePedido($event)"
                                                    type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                                                    [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off"
                                                />
                                                <ng-template #rt let-r="result" let-t="term">
                                                    <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                                </ng-template>

                                                <div class="col-12 col-sm-12 col-xs-12">
                                                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="pedido.cliente && pedido.cliente.id" (click)="reiniciarCliente()">
                                                        <i class="fa fa-trash bigger-130 red2"></i>
                                                    </a>
                                                    <a *ngIf="pedido.cliente && !pedido.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                                        <i class="fa fa-plus bigger-130"></i>
                                                    </a>
                                                    <a *ngIf="pedido.cliente && pedido.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                                        <i class="fa fa-edit bigger-130"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6  ">
                                                <span class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)"></span>&nbsp;
                                                <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}}</label>&nbsp;
                                                <span *ngIf="usuario.empresa.usar_pedidos_en_linea" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_DESPACHOS,'obtenerTiposDespachos')"></span>
                                                <select [compareWith]="compararObjectos" class="form-control" required name="tipo_despacho" [(ngModel)]="pedido.tipoDespacho">
                                                    <option [ngValue]="tipoDespacho" *ngFor="let tipoDespacho of tipos_despacho | filterBy:{habilitado:true}">{{tipoDespacho.nombre}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6  ">
                                                <label>Observación</label>&nbsp;
                                                <input class="form-control" autocomplete="off" id="pedido_observacion" name="observacion" type="text" [(ngModel)]="pedido.observacion" placeholder="Observacion">
                                            </div>
                                            <div *ngIf="usuario.empresa.usar_clientes_contactos" class="col-md-4 col-4 col-sm-4  ">
                                                <label>CONTACTO</label>
                                                <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="pedido.contacto_cliente">
                                                    <option [ngValue]="contacto_cliente" *ngFor="let contacto_cliente of pedido.cliente.contactos">{{contacto_cliente.persona.nombre_completo}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="usuario.empresa.usar_despachos" class="col-md-8 col-8 col-sm-8  ">
                                                <div class="input-group">
                                                    <label for="fechaDespachoTexto">Fecha de entrega </label>&nbsp;
                                                    <input id="fechaDespachoTexto" name="fechaDespachoTexto" class="form-control" placeholder="dd/mm/yyyy"
                                                            name="dp" [(ngModel)]="pedido.fechaDespachoTexto" ngbDatepicker #fi="ngbDatepicker">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="overflow-y: visible;width: 100%;height:auto;" *ngIf="pedido.detallesPedido && pedido.detallesPedido.length>0">
                                                <div class="col-12 table-responsive table-container">
                                                    <table class="table table-striped table-bordered table-hover" >
                                                            <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <label>Nº</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Cantidad</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Producto</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>P. U. (Bs)</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Imp. (Bs)</label>
                                                                        </th>
                                                                        <th>
                                                                            <div class='dropdown d-inline-block dd-backdrop dd-backdrop-none-md'>
                                                                                <a href='#' style="color:white !important;" class='btn {{color.stylebutton}} btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                                                                                <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                                                                                  <div class="dropdown-inner">
                                                                                    <a class="dropdown-item item-action" href="javascript:void(0)" (click)="imprimirPedido(pedido)">
                                                                                        <i class="fa fa-print {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                    <a *ngIf="usuario.empresa.usar_obsequios" class="dropdown-item item-action" href="javascript:void(0)" (click)="convertirTodoObsequio()">
                                                                                        <i class="fa fa-gift {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                            </thead>
                                                            <tbody data-drop="true" data-jqyoui-options ng-model="test" jqyoui-droppable="{onDrop:'agregarDetallePedidoCompuesto(detallePedido,test)',multiple:false}" *ngFor="let detallePedido of pedido.detallesPedido; let i=index;">
                                                                <ng-container *ngIf="!detallePedido.eliminado">
                                                                    <tr>
                                                                        <td><!--rowspan="{{1+detallePedido.detallesPedido.length}}"-->
                                                                            {{i+1}}
                                                                            <label>
                                                                                <input name="seleccionado{{i}}" [(ngModel)]="detallePedido.selected" (change)="addItemToSelected(detallePedido)" type="checkbox" class="align-middle" />
                                                                            </label>
                                                                        </td>
                                                                        <td *ngIf="!editar_cantidad">
                                                                            <div class="row">
                                                                                <div class="col-md-12 col-sm-12 col-12">
                                                                                    {{detallePedido.cantidad}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-md-12 col-sm-12 col-12">
                                                                                    <a class="btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" [disabled]="!aplicacion.puede_modificar" (click)="modificarCantidad()">
                                                                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td *ngIf="editar_cantidad">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <input name="cantidad{{i}}" step="0.001" min="0.1" required class="form-control" (change)="verificarInventario(detallePedido)" (keyup)="verificarInventario(detallePedido)" [(ngModel)]="detallePedido.cantidad" type="number" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" [disabled]="!detallePedido.cantidad" href="javascript:void(0)" (click)="establecerCantidad()">
                                                                                        <i class="ace-icon fa fa-check-square bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>		
                                                                        </td>
                                                                        <td *ngIf="detallePedido.producto">
                                                                            {{detallePedido.producto.nombre}}
                                                                            <span *ngIf="detallePedido.descripcion">({{detallePedido.descripcion}})</span>
                                                                        </td>
                                                                        <td *ngIf="detallePedido.combo">
                                                                            {{detallePedido.combo.nombre}}
                                                                            <span *ngIf="detallePedido.descripcion">({{detallePedido.descripcion}})</span>
                                                                        </td>
                                                                        <td *ngIf="!detallePedido.combo && !detallePedido.producto">
                                                                            {{detallePedido.descripcion}}
                                                                        </td>
                                                                        <td *ngIf="!usuario.empresa.usar_precios_productos && !editar_precio">
                                                                            <div clas="row">
                                                                                <div class="col-md-6 col-sm-6 col-6">
                                                                                    {{detallePedido.precio_unitario}}
                                                                                </div>
                                                                                <div class="col-md-6 col-sm-6 col-6" *ngIf="configuracion_pagina.getOrden().cambiar_precios">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarPrecio()">
                                                                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td *ngIf="!usuario.empresa.usar_precios_productos && editar_precio && configuracion_pagina.getOrden().cambiar_precios">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <input name="precio_unitario{{i}}" step="0.001" min="0.1" required class="form-control" (change)="calcularImporteDetallePedido(detallePedido)" (keyup)="calcularImporteDetallePedido(detallePedido)" [(ngModel)]="detallePedido.precio_unitario" type="number" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" [disabled]="!detallePedido.precio_unitario" href="javascript:void(0)" (click)="establecerPrecio()">
                                                                                        <i class="ace-icon fa fa-check-square bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>		
                                                                        </td>
                                                                        <td *ngIf="detallePedido.producto && usuario.empresa.usar_precios_productos">
                                                                            <select style="width:65px;" [compareWith]="compararObjectos" class="form-control" required name="prepro{{i}}" [(ngModel)]="detallePedido.producto_precio" (change)="establecerPrecioDetallePanel(detallePedido)">
                                                                                <option [ngValue]="precio" *ngFor="let precio of detallePedido.producto.precios">{{precio.precio_unitario+' - '+(precio.concepto?precio.concepto.nombre:'')}}</option>
                                                                            </select>
                                                                        </td>
                                                                        <td *ngIf="detallePedido.combo && usuario.empresa.usar_precios_productos">
                                                                            <select style="width:65px;" [compareWith]="compararObjectos" class="form-control" required name="precom{{i}}" [(ngModel)]="detallePedido.combo_precio" (change)="establecerPrecioDetallePanel(detallePedido)">
                                                                                <option [ngValue]="precio" *ngFor="let precio of detallePedido.combo.precios">{{precio.precio_unitario+' - '+(precio.concepto?precio.concepto.nombre:'')}}</option>
                                                                            </select>
                                                                            <!--<select style="width:65px;" ng-init="detallePedido.combo_precio=obtenerPrecioComboSeleccionado(detallePedido)"
                                                                                    ng-model="detallePedido.combo_precio" data-placeholder="Elija el precio" 
                                                                                    ng-change="establecerPrecioDetallePanel(detallePedido)"
                                                                                    ng-options="precio as precio.precio_unitario+' - '+precio.concepto.nombre for precio of  track by precio.id">
                                                                            </select>-->
                                                                        </td>
                                                                        <td>
                                                                            {{detallePedido.total}}
                                                                        </td>
                                                                        <td>
                                                                            <div class='dropdown d-inline-block dd-backdrop dd-backdrop-none-md'>
                                                                                <a href='#' style="color:white !important;" class='btn {{color.stylebutton}} btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                                                                                <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                                                                                  <div class="dropdown-inner">
                                                                                    <a class="dropdown-item item-action" href="javascript:void(0)" (click)="disminuirDetallePedidoRestaurante(detallePedido)">
                                                                                      <i class="fa fa-minus {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                    <a class="dropdown-item item-action" href="javascript:void(0)" (click)="agregarDescripcionDetallePedido(detallePedido)">
                                                                                        <i class="fa fa-comment {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                    <a *ngIf="usuario.empresa.usar_obsequios" class="dropdown-item item-action" href="javascript:void(0)" (click)="convertirObsequio(detallePedido)">
                                                                                        <i class="fa fa-gift {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                    <a class="dropdown-item item-action" href="javascript:void(0)" (click)="eliminarDetallePedidoRestaurante(detallePedido)">
                                                                                        <i class="fa fa-trash {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                        </td>
                                                                    </tr>
                                                                    <!--<tr *ngFor="let detallePedidoExtra of detallePedido.detallesPedido">
                                                                        <ng-container *ngIf="!detallePedidoExtra.eliminado">
                                                                            <td *ngIf="!editar_cantidad">
                                                                                <div clas="row">
                                                                                    <div class="col-md-6 col-sm-6 col-6">
                                                                                        {{detallePedidoExtra.cantidad}}
                                                                                    </div>
                                                                                    <div class="col-md-6 col-sm-6 col-6">
                                                                                        <a class="green" href="javascript:void(0)" e-disabled="!aplicacion.puede_modificar" ng-click="modificarCantidad()">
                                                                                            <i class="ace-icon fa fa-pencil-square-o bigger-130"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td *ngIf="editar_cantidad">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12">
                                                                                        <input name="cantidad" step="0.001" min="0.1" required class="col-12" ng-change="verificarInventario(detallePedidoExtra)" ng-model="detallePedidoExtra.cantidad" type="number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12">
                                                                                        <a class="blue" e-disabled="!detallePedidoExtra.cantidad" href="javascript:void(0)" ng-click="establecerCantidad()">
                                                                                            <i class="ace-icon fa fa-check-square-o bigger-130"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>		
                                                                            </td>
                                                                            <td>
                                                                                {{detallePedidoExtra.producto.nombre}}
                                                                                <span *ngIf="detallePedidoExtra.descripcion">({{detallePedidoExtra.descripcion}})</span>
                                                                            </td>
                                                                            <td *ngIf="!usuario.empresa.usar_precios_productos && !editar_precio">
                                                                                <div clas="row">
                                                                                    <div class="col-md-6 col-sm-6 col-6">
                                                                                        {{detallePedidoExtra.precio_unitario}}
                                                                                    </div>
                                                                                    <div class="col-md-6 col-sm-6 col-6">
                                                                                        <a class="green" href="javascript:void(0)" e-disabled="!aplicacion.puede_modificar" ng-click="modificarPrecio()">
                                                                                            <i class="ace-icon fa fa-pencil-square-o bigger-130"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td *ngIf="!usuario.empresa.usar_precios_productos && editar_precio">
                                                                                <div class="row">
                                                                                    <div class="col-sm-12">
                                                                                        <input name="precio_unitario" step="0.001" min="0.1" required class="col-12" ng-change="calcularImporteDetallePedido(detallePedidoExtra)" ng-model="detallePedidoExtra.precio_unitario" type="number" />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm-12">
                                                                                        <a class="blue" e-disabled="!detallePedidoExtra.precio_unitario" href="javascript:void(0)" ng-click="establecerPrecio()">
                                                                                            <i class="ace-icon fa fa-check-square-o bigger-130"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>		
                                                                            </td>
                                                                            <td *ngIf="usuario.empresa.usar_precios_productos">
                                                                                <select style="width:65px;"
                                                                                        ng-model="detallePedidoExtra.producto_precio" data-placeholder="Elija el precio" 
                                                                                        ng-change="establecerPrecioDetallePanel(detallePedidoExtra)"
                                                                                        ng-options="precio as precio.precio_unitario+' - '+precio.concepto.nombre for precio of detallePedido.producto.precios track by precio.id">
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                {{detallePedidoExtra.total}}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <div class="inline pos-rel {{color.stylebutton}}">
                                                                                        <button class="btn btn-minier dropdown-toggle" data-toggle="dropdown" data-position="auto">
                                                                                        <i class="ace-icon fa fa-caret-down icon-only bigger-120"></i>
                                                                                    </button>
                                                                
                                                                                        <ul class="dropdown-menu dropdown-only-icon dropdown-menu-right dropdown-caret dropdown-close">
                                                                                            <li>
                                                                                                <a class="{{color.stylebutton}}" href="javascript:void(0)" ng-click="disminuirDetallePedidoRestaurante(detallePedidoExtra)">
                                                                                                    <span class="white">
                                                                                                        <i class="ace-icon fa fa-minus bigger-110"></i>
                                                                                                    </span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a class="{{color.stylebutton}}" href="javascript:void(0)" ng-click="agregarDescripcionDetallePedido(detallePedidoExtra)">
                                                                                                    <span class="white">
                                                                                                        <i class="ace-icon fa fa-comment-o bigger-110"></i>
                                                                                                    </span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a class="{{color.stylebutton}}" href="javascript:void(0)" ng-click="eliminarDetallePedidoRestaurante(detallePedidoExtra)">
                                                                                                    <span class="white">
                                                                                                        <i class="ace-icon fa fa-trash-o bigger-110"></i>
                                                                                                    </span>
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </ng-container>
                                                                    </tr>-->
                                                                </ng-container>
                                                            </tbody>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                    <td>
                                                                            
                                                                    </td>
                                                                    <td>
                                                                        <b>TOTALES</b>
                                                                    </td>
                                                                    <td>
                                                                        {{pedido.total}}
                                                                    </td>
                                                                    <td>
                                                                            
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                                <div *ngIf="usuario.empresa.usar_reposiciones && pedido.detallesReposicion.length>0" class="col-12 table-responsive table-container">
                                                    <div class="col-md-6 col-6 col-sm-6  ">
                                                        <label>DETALLES REPOSICION</label>
                                                    </div>
                                                    <table class="table table-striped table-bordered table-hover" >
                                                            <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <label>#</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Cantidad</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Producto</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Acciones</label>
                                                                        </th>
                                                                    </tr>
                                                            </thead>
                                                            <tbody *ngFor="let detalleReposicion of pedido.detallesReposicion">
                                                                <tr>
                                                                    <td>
                                                                        {{$index+1}}
                                                                    </td>
                                                                    <td>
                                                                        {{detalleReposicion.cantidad}}
                                                                    </td>
                                                                    <td>
                                                                        {{detalleReposicion.producto.nombre}}
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- /.col -->
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-8">
                                        <!-- PAGE CONTENT BEGINS -->
                                        <div class="row">
                                            <div id="productos" class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-10 col-md-10 col-12 col-sm-12" *ngIf="mostrar_productos">
                                                        <ul class="ace-thumbnails clearfix">
                                                            <li class="swiper-slide" (click)="agregarDetallePedido(producto,pedido.detallesPedido)" *ngFor="let producto of productosProcesados">
                                                                <img src="{{global_variable.SERVER_URL+producto.imagen}}" width="100%" style="height: 98px;max-height:98px">
                                                                <label *ngIf="configuracion_pagina.getOrden().controlar_cantidades_panel && producto.activar_inventario && usuario.empresa.usar_inventario && producto.tipo_producto_nombre_corto==global_variable.Dictionary.TIPO_PRODUCTO_BASE" class="panel-cantidad">{{obtenerCantidadTotalInventarioProducto(producto)}}</label>
                                                                <label *ngIf="configuracion_pagina.getOrden().controlar_cantidades_panel && producto.activar_inventario && usuario.empresa.usar_inventario && usuario.empresa.usar_consumos && producto.tipo_producto_nombre_corto==global_variable.Dictionary.TIPO_PRODUCTO_FINAL" class="panel-produccion">{{obtenerCantidadTotalInventarioProducto(producto)}}</label>
                                                                <h4 style="white-space:initial;width:98px;height:10px;color:white;">{{producto.nombre}}</h4>
                                                            </li>
                                                        </ul>
                                                    </div><!-- /.col -->
                                                    <div class="col-lg-10 col-md-10 col-12 col-sm-12" *ngIf="!mostrar_productos">
                                                        <ul class="ace-thumbnails clearfix">
                                                            <li class="swiper-slide" (click)="agregarDetallePedidoPanelCombo(combo)" *ngFor="let combo of combos">
                                                                <img src="{{global_variable.SERVER_URL+combo.imagen}}" width="100%" style="height: 98px;max-height:98px">
                                                                <label class="panel-cantidad">{{obtenerCantidadTotalInventarioCombo(combo)}}</label>
                                                                <h4 ng-model="combon" style="white-space:initial;width:98px;height:10px;">{{combo.nombre}}</h4>
                                                            </li>
                                                        </ul>
                                                    </div><!-- /.col -->
                                                    <div class="col-lg-2 col-md-2 d-none d-lg-block d-xl-block">
                                                        <div class="row" *ngIf="grupos_productos.length>0">
                                                            <div class="col-12">
                                                                <button type="button" (click)="clasificarGrupo(0)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">TODOS
                                                                                    </button>
                                                            </div>
                                                            <div class="col-12" *ngFor="let grupo of grupos_productos">
                                                                <button type="button" (click)="clasificarGrupo(grupo.id)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">{{grupo.nombre}}
                                                                                    </button>
                                                            </div>
                                                        </div>
                                                    </div><!-- /.col -->
                                                </div><!-- /.row -->
                                            </div>
                                        </div>
                                    </div><!-- /.col -->
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #descripcionitem let-modal>
    <div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Agregar Descripción</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="!detallePedidoDescripcion.descripcion" (click)="agregarDetallePedidoDescripcion()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="row">
                        <div class="col-8">
                            <label>Descripcion/Observacion/Comentario</label>
                            <textarea class="form-control" [(ngModel)]="detallePedidoDescripcion.descripcion" placeholder="descripcion"></textarea>
                        </div>
                        <div class="col-4">
                            <label>Plantillas</label>&nbsp;
                            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_DESCRIPCIONES_DETALLE_PEDIDO,'obtenerTiposDescripcionesDetallePedido')"></span>
                            <!--<select [compareWith]="compararObjectos" class="form-control" required name="tipo_despacho" [(ngModel)]="pedido.tipoDespacho">
                                <option [ngValue]="tipoDespacho" *ngFor="let tipoDespacho of tipos_despacho | filterBy:{habilitado:true}">{{tipoDespacho.nombre}}</option>
                            </select>-->
                            <ng-container *ngFor="let clase of tipo_descripcion_ted_ped.clases">
                                <span (click)="establecerDetallePedidoDescripcion(clase.nombre)" class="badge radius-round bgc-purple-l1 text-dark-tp3 text-85 px-25 py-1 m-2px overflow-hidden">
                                    <span class="pos-rel">{{clase.nombre}}</span>
                                </span>
                            </ng-container>
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</ng-template>

<ng-template #obsequioitem let-modal>
    <div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">¿Convertir a obsequio?</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="row">
                        <div class="col-12">
                            <label>
                                <input (change)="calcularCambioObsequio()" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalle_pedido_obsequio.es_obsequio"/>
                            </label>
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</ng-template>

<ng-template #ventamodal let-modal>
    <div class="modal-header">
		<div class="col-4 col-md-4 col-sm-4" >
            <i class="fas fa-cash-register fa-4x"></i>
        </div>
        <div class="col-4 col-md-4 col-sm-4">
            <button type="button" (click)="facturarPedidoRestaurante()" class="btn btn-app btn-primary btn-xs text-size">
                <i class="ace-icon fa fa-save fa-3x"></i>
            </button>
        </div>
        <div class="col-4 col-md-4 col-sm-4">
            <button type="button" class="btn btn-app btn-danger btn-xs text-size" (click)="modal.dismiss('Cross click')">
                <i class="ace-icon fa fa-window-close fa-3x"></i>
            </button>
        </div>
	</div>
    <div id="dialog-panel-cobro" class="modal-body {{color.style}}">
        <div class="row button-style {{color.stylebutton}}">
            <div class="col-4">
                <label class="control-label" for="form-field-1"> Forma de Pago </label>
            </div>
            <div class="col-8">
                <select [compareWith]="compararObjectos" id="tipo_pago" name="tipo_pago" class="form-control" [(ngModel)]="venta.tipoPago">
                    <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                </select>	
            </div>
            <ng-container *ngIf="venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_TARJ">
                <div class="col-4">
                    <label>Número de Tarjeta:</label>
                </div>
                <div class="col-8">
                    <input [hiddenInput]="true" mask="0000-XXXX-XXXX-0000" class="form-control" placeholder="xxxx-xxxx-xxxx-xxxx" [(ngModel)]="venta.numero_tarjeta" />
                </div>
            </ng-container>
            <ng-container *ngIf="movimientosEgresoPanel.length>1">
                <div class="col-4">
                    <label class="control-label" for="form-field-1"> Movimiento </label>
                </div>
                <div class="col-8">
                    <select [compareWith]="compararObjectos" id="movimiento" name="movimiento" class="form-control" [(ngModel)]="venta.transaccion" (change)="establecerTransaccion()">
                        <option [ngValue]="transaccion" *ngFor="let transaccion of movimientosEgresoPanel">{{transaccion.nombre}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="(venta.transaccion.nombre_corto==global_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA) && actividades.length>1">
                <div class="col-5">
                    <label class="control-label" for="form-field-1"> Actividad </label>
                </div>
                <div class="col-7">
                    <select [compareWith]="compararObjectos" id="actividad" name="actividad" class="form-control" [(ngModel)]="venta.actividad" (change)="establecerActividad(venta.actividad)">
                        <option [ngValue]="actividad" *ngFor="let actividad of actividades">{{actividad.nombre}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="venta.transaccion.nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA && (documentos_sector && documentos_sector.length>1)">
                <div class="col-5">
                    <label class="control-label" for="form-field-1"> Documento Sector </label>
                </div>
                <div class="col-7">
                    <select style="width:300px;" [compareWith]="compararObjectos" name="sin_documento" class="form-control" [(ngModel)]="venta.sin_documento">
                        <option [ngValue]="sin_documento" *ngFor="let sin_documento of documentos_sector">{{sin_documento.nombre}}</option>
                    </select>
                </div>
            </ng-container>
        </div>
        <div class="row button-style {{color.stylebutton}}">
            <div class="col-10 p-1">
                <div class="row">
                    <div class="col-4">
                        <label class="control-label" for="form-field-1"> N.I.T./C.I. </label>
                    </div>
                    <div class="col-8">
                        <input (keyup)="buscarNit($event,venta.cliente.nit)" id="nitP1" name="nitP1" required type="text" [(ngModel)]="venta.cliente.nit" placeholder="Ingrese el NIT" class="form-control" >
                    </div>
                    <div class="col-4">
                        <label class="control-label" for="form-field-1-1"> Razón Social </label>
                    </div>
                    <div class="col-8">
                        <input id="razon_social" name="razon_social" required type="text" [(ngModel)]="venta.cliente.razon_social" (change)="actualizarIdentificacion(venta.cliente.razon_social)" placeholder="Ingrese la Razon Social" class="form-control">
                    </div>
                </div>
            </div>  
            <div class="col-2 p-1">
                <div class="row">
                    <div class="col-12">
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="pedido.cliente && pedido.cliente.id" (click)="reiniciarCliente()">
                            <i class="fa fa-trash bigger-130 red2"></i>
                        </a>
                        <a *ngIf="pedido.cliente && !pedido.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                            <i class="fa fa-plus bigger-130"></i>
                        </a>
                        <a *ngIf="pedido.cliente && pedido.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                            <i class="fa fa-edit bigger-130"></i>
                        </a>
                    </div>
                    <ng-container *ngIf="venta.transaccion.nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA && venta.cliente.tipo_documento && venta.cliente.tipo_documento.nombre_corto=='5'">
                        <div class="col-12">
                            <label class="control-label" for="excepcion"> ¿Exp.? </label>
                            <input name="excepcion" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="venta.cliente.excepcion"/>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row style-totales">
            <ng-container *ngIf="usuario.empresa.usar_descuento_global">
                <div class="col-7">
                    <label class="control-label no-padding-right text-160" for="form-field-1">Importe (Bs):</label>
                </div>
                <div class="col-5">
                    <div class="text-center m-1 bgc-primary-l1 border-1 brc-primary-m1 text-dark-tp3 text-160 px-25 py-3px radius-round">
                        {{venta.importe}}
                    </div>
                </div>
                <div class="col-7">
                    <label class="control-label no-padding-right text-160" for="form-field-1">Desc. (Bs):</label>
                </div>
                <div class="col-5">
                    <input name="descuento" required type="number" step="0.01" max="{{descuento_maximo}}" [(ngModel)]="venta.descuento" (keyup)="calcularTotalVenta()" class="form-control"/>
                </div>
            </ng-container>
            <div class="col-7">
                <label class="control-label text-160" for="form-field-1">Total (Bs):</label>
            </div>
            <div class="col-5">
                <div class="text-center m-1 bgc-warning-l1 border-1 brc-warning-m1 text-dark-tp3 text-160 px-25 py-3px radius-round">
                    {{venta.total}}
                </div>
            </div>
            <div class="col-7">
                <label class="control-label no-padding-right text-160" for="form-field-1-1">Pagado (Bs):</label>
            </div>
            <div class="col-5">
                <input id="pagadoP1" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" value="{{venta.total}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()" class="form-control"/>
            </div>
            <div class="col-7">
                <label class="control-label no-padding-right text-160" for="form-field-1-1">Cambio (Bs):</label>
            </div>
            <div class="col-5">
                <div class="text-center m-1 bgc-success-l1 border-1 brc-success-m1 text-dark-tp3 text-160 px-25 py-3px radius-round">
                    {{venta.cambio}}
                </div>
            </div>
            <ng-container *ngIf="usuario.empresa.usar_mesas && ((pedido.mesas && pedido.mesas.length>0) || (pedido.mesasPedidoRestaurante && pedido.mesasPedidoRestaurante.length>0))">
                <div class="col-7">
                    <label class="control-label text-120">¿Liberar Mesa?</label>
                </div>
                <div class="col-5">
                    <div>
                        <label>
                            <input (change)="cambiarLiberarMesa(venta.liberar_mesa)"  name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="venta.liberar_mesa" />
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>