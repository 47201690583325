import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class RecursoMultimediaService {

  constructor(private http: HttpClient) { }

  public subirRecursoMultimedia(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"subida-recurso",datos,GlobalVariable.httpOptions);
  }

  public eliminarRecursoMultimedia(id_recurso){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"eliminacion-recurso/"+id_recurso,GlobalVariable.httpOptions);
  }
}
