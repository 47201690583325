import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ParticipantesGruposWhatsappComponent } from 'src/app/base/components/participantes-grupos-whatsapp/participantes-grupos-whatsapp.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { NoticiasService } from '../../services/noticias/noticias.service';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent extends BaseComponent implements OnInit {

  @ViewChildren('noticias') noticias_lectura: QueryList<any>;

  noticias:any[]=[];

  participantes_grupo_whatsapp_modal:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public noticiasService:NoticiasService,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  
      async ngOnInit(): Promise<void> {
      this.init();
      this.buscarAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      this.filter={
        id_empresa:this.usuario.id_empresa,
        fuente:null,
        fuentes:[]
      }
      await this.obtenerFuentesPeriodisticas();
      this.getItems();
    }
  
    ngAfterViewInit() {
      resaltarPestañaMenu(this.router.url.substring(1)+"1");
      this.noticias_lectura.changes.subscribe(t => {
        aplicarVisorImagenes("imagen-noticia-vista");
      });
    }

    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.noticiasService.obtenerListaNoticias(this).subscribe((dato:any) => {
        if(dato.tiene_error){

        }else{
          this.setPages(1);
          for(let i=0;i<dato.noticias.length;i++){
            dato.noticias[i].fuente=this.filter.fuente;
          }
          this.noticias=dato.noticias;
        }
        this.blockUI.stop();
      });
    }

    async obtenerFuentesPeriodisticas(){
      let entidad:any=await this.generalService.obtenerClases("PERIOFUEMEDCOM").toPromise();
			this.filter.fuentes=entidad.clases;
      this.filter.fuente=this.filter.fuentes[0];
    }

    async registrarEnWordpress(noticia){
      this.blockUI.start();
      noticia.id_empresa=this.usuario.id_empresa;
      noticia.empresa=this.usuario.empresa;
      let res:any=await this.noticiasService.registrarEnWordpress(noticia).toPromise();
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.info(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
      }
      return res.noticia;
    }

    async enviarNoticiaWhatsapp(noticia){
      let noticia_recuperada=await this.registrarEnWordpress(noticia);
      this.blockUI.start();
      let res:any=await this.noticiasService.enviarNoticiaWhatsapp({empresa:this.usuario.empresa,noticia:noticia_recuperada}).toPromise();
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
      }
    }

    async enviarmeNoticiaWhatsapp(noticia){
      let noticia_recuperada=await this.registrarEnWordpress(noticia);
      let mensaje='https://wa.me/591'+this.usuario.empresa.telefono1+'?text='+noticia_recuperada.titulo+'%20%0A%20%0A'+noticia_recuperada.url;
      await this.enviarNavegadorWhatsapp(mensaje);
    }

    async enviarNavegadorWhatsapp(url){
      let a:any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.target = "_blank";
      a.click();
      document.body.removeChild(a);
    }

    abrirParticipantesGrupoWhatsapp(){
      this.participantes_grupo_whatsapp_modal = this.modalService.open(ParticipantesGruposWhatsappComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.participantes_grupo_whatsapp_modal.componentInstance.usuario = this.usuario;
      
      this.participantes_grupo_whatsapp_modal.componentInstance.alTerminar.subscribe((res) => {
        this.participantes_grupo_whatsapp_modal.close();
      });
    }

}
