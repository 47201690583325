<div class="modal-header card-header" *ngIf="!es_modal_interno">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Ficha Económica de Estudiante : {{estudiante.persona?estudiante.persona.nombre_completo:estudiante.nombre_completo}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button *ngIf="aplicacion_ventas.puede_crear && usuario.empresa.usar_cobros_caja_colegio" class="btn btn-success" type="button" (click)="abrirRegistroPago()">
          <i class="fas fa-money-bill-alt red2 text-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="generarPdfKardex()">
          <i class="fas fa-file-pdf red2 text-110"></i>
      </button>&nbsp;
        <button *ngIf="usuario.empresa.usar_estudiantes_instituto" class="btn btn-warning" type="button" (click)="abrirAsignacionMaterias(estudiante)">
          <i class="fas fa-id-card-alt red2 text-110"></i>
      </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
          <i class="fa fa-times red2 text-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row" *ngIf="es_visualizacion_por_gestion || !es_modal_interno">
        <div class="col-md-6 col-sm-12" *ngIf="usuario.empresa.usar_estudiantes_instituto">
            <div class="input-group">
                <label for="feini">Inscripción </label>&nbsp;
                <select [compareWith]="compararObjectos" name="inscripcion" class="form-control" [(ngModel)]="filter.inscripcion_instituto">
                    <option [ngValue]="inscripcion" *ngFor="let inscripcion of filter.inscripciones_instituto">{{inscripcion.carrera.nombre}}-{{inscripcion.gestion?inscripcion.gestion.nombre:''}}-{{inscripcion.semestre?inscripcion.semestre.nombre:''}}</option>
                </select>
            </div>  
          </div>
        <div class="col-md-6 col-sm-12" *ngIf="usuario.empresa.usar_estudiantes_colegio">
            <div class="input-group">
                <label for="feini">Inscripción </label>&nbsp;
                <select [compareWith]="compararObjectos" name="inscripcion" class="form-control" [(ngModel)]="filter.inscripcion_colegio">
                    <option [ngValue]="inscripcion" *ngFor="let inscripcion of filter.inscripciones_colegio">{{inscripcion.gestion.nombre}}-{{inscripcion.curso.nombre}} ({{inscripcion.activo?"Activo":"Anulada"}})</option>
                </select>
            </div>  
        </div>
        <div class="col-md-6 col-xs-3 col-sm-3">
            <button (click)="buscarConceptosPago()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search text-110"></i>
            </button>&nbsp;
            <button *ngIf="aplicacion_estudiantes.puede_modificar && usuario.empresa.usar_ingresos_economicos_colegio && filter.inscripcion_colegio" class="btn btn-primary" type="button" (click)="abrirAsignacionConceptoPagoEstudiante()">
              <i class="fas fa-hand-holding-usd red2 text-110"></i>
          </button>&nbsp;
          <button *ngIf="usuario.empresa.usar_mensajeria_redes_sociales && conceptos_pago.length>0" class="btn btn-success" type="button" (click)="enviarKardexEconomicoWhatsapp()">
            <i class="fab fa-whatsapp red2 text-110"></i>
        </button>
        </div>
    </div>
    <div class="row p-1">
        <div class="col-6 p-1" *ngFor="let concepto_pago of conceptos_pago">
            <div class="card bcard overflow-hidden shadow text-center">
                <div class="card-body p-3px bgc-green-d2">
                  <div class="row">
                    <div class="col-8">
                      <span class="text-white text-125">{{concepto_pago.concepto.nombre}}</span>
                    </div>
                    <div class="col-3">
                      <a *ngIf="aplicacion_estudiantes.puede_modificar && usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes" title="Fechas de Vencimiento" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalFechaVencimiento(concepto_pago)">          
                        <i class="fa fa-calendar-alt text-105"></i>        
                      </a> 
                      <a *ngIf="aplicacion_estudiantes.puede_eliminar" title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionAsignacionPago(concepto_pago)">          
                        <i class="fa fa-trash-alt text-105"></i>        
                      </a> 
                    </div>
                  </div>
                </div>
            </div>
            <div class="card bcard overflow-hidden shadow">
              <div class="card-body p-3px bgc-green-d2">
                <div class="radius-1 table-responsive">
                  <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                    <thead class="brc-transparent">
                      <tr class="bgc-green-d2 text-white">
                        <th>
                          Cuota
                        </th>
                        <th>
                          Importe
                        </th>
                        <th>
                          Desc.
                        </th>
                        <th style="text-align: center; width:250px; ">
                          Pagado
                        </th>
                        <th>
                          Obs.
                        </th>
                        <th *ngIf="usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes">
                          Fecha Venc.
                        </th>
                        <th data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                      </tr>
                    </thead>
        
                    <tbody>
                      <tr class="bgc-h-yellow-l3" *ngFor="let concepto_pago_estudiante of concepto_pago.conceptos_pagos_estudiante;let i=index;">
                        <ng-container *ngIf="concepto_pago_estudiante.activo">
                          <td class="text-600 text-dark text-center">
                            {{concepto_pago_estudiante.cuota}}
                          </td>
                          <td class="text-green-m1 text-180 text-600">
                              <span class="text-dark-tp4 text-md">Bs</span>{{concepto_pago_estudiante.importe}}
                          </td>
                          <td class="text-light-m1 text-180 text-600">
                            <span class="text-dark-tp4 text-md">Bs</span>{{concepto_pago_estudiante.descuento}}
                          </td>
                          <td [ngClass]="{'text-info-m1': concepto_pago_estudiante.saldo==0, 'text-danger-m1': concepto_pago_estudiante.saldo>0}" class="text-130 text-600">
                            <ng-container *ngIf="concepto_pago_estudiante.detalles_venta">
                              <ng-container *ngIf="concepto_pago_estudiante.detalles_venta.length>0">
                                <div class="row" *ngFor="let detalle_venta of concepto_pago_estudiante.detalles_venta">
                                  <div class="col-12">
                                    <span class="text-dark-tp4 text-md">Bs</span>{{detalle_venta.detalle_venta.total}} <span class="text-blue-m1 text-md">{{detalle_venta.detalle_venta.venta.fecha | date:"dd/MM/yyyy"}}</span> 
                                    <span class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirVenta(detalle_venta.detalle_venta.venta)">
                                      <i class="fa fa-print bigger-260 red2"></i>
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                              <span *ngIf="concepto_pago_estudiante.detalles_venta.length>0 && (concepto_pago_estudiante.importe-sumarPagos(concepto_pago_estudiante.detalles_venta)-sumarDescuentos(concepto_pago_estudiante.detalles_venta)-concepto_pago_estudiante.saldo)>0" ><span class="text-dark-tp4 text-md">Bs</span>{{(concepto_pago_estudiante.importe-sumarPagos(concepto_pago_estudiante.detalles_venta)-sumarDescuentos(concepto_pago_estudiante.detalles_venta)-concepto_pago_estudiante.saldo).toFixed(2)}}</span>
                              <span *ngIf="concepto_pago_estudiante.detalles_venta.length==0 && (concepto_pago_estudiante.importe==concepto_pago_estudiante.pagado || concepto_pago_estudiante.pagado>0)" ><span class="text-dark-tp4 text-md">Bs</span>{{concepto_pago_estudiante.pagado}}</span>
                              <span *ngIf="concepto_pago_estudiante.detalles_venta.length==0 && concepto_pago_estudiante.importe>concepto_pago_estudiante.pagado && concepto_pago_estudiante.pagado==0" ><span class="text-dark-tp4 text-md">Bs</span>0</span>
                            </ng-container>
                          </td>
                          <td>
                            {{concepto_pago_estudiante.observacion}}
                          </td>
                          <td class="text-danger-m1 text-130" *ngIf="usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes">
                            <div class="row" *ngIf="!concepto_pago_estudiante.fv_modificar">
                              <div class="col-md-12 col-sm-12 col-12">
                                {{concepto_pago_estudiante.fecha_vencimiento | date:"dd/MM/yyyy"}}
                              </div>
                              <div class="col-md-12 col-sm-12 col-12">
                                  <a *ngIf="aplicacion_estudiantes.puede_modificar" class="btn-sm btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarFechaVencimiento(concepto_pago_estudiante)">
                                      <i class="ace-icon fa fa-edit bigger-60"></i>
                                  </a>
                              </div>
                            </div>
                            <div class="row" *ngIf="concepto_pago_estudiante.fv_modificar">
                              <div class="col-md-12 col-sm-12 col-12">
                                <div class="input-group">
                                  <input name="{{concepto_pago_estudiante.id?concepto_pago_estudiante.id:i}}" class="form-control" placeholder="dd/mm/yyyy"
                                          [(ngModel)]="concepto_pago_estudiante.fecha_vencimiento_texto" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                                </div> 
                              </div>
                              <div class="col-md-12 col-sm-12 col-12">
                                  <a class="btn-sm btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="establecerFechaVencimiento(concepto_pago_estudiante)">
                                      <i class="ace-icon fa fa-check-square bigger-60"></i>
                                  </a>
                              </div>
                            </div>
                          </td>
                          <td class="text-center">
                              <a *ngIf="aplicacion_estudiantes.puede_modificar && concepto_pago_estudiante.pagado==0" title="Modificar Importe" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirCambioImporteModal(concepto_pago_estudiante)">          
                                  <i class="fa fa-pencil-alt text-105"></i>        
                              </a>
                              <!--<a *ngIf="concepto_pago_estudiante.saldo>0" title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCuotaPago(concepto_pago_estudiante)">          
                                  <i class="fas fa-money-bill-alt text-105"></i>        
                              </a>-->
                              <a *ngIf="aplicacion_estudiantes.puede_eliminar && concepto_pago_estudiante.pagado==0" title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(concepto_pago_estudiante)">          
                                  <i class="fa fa-trash-alt text-105"></i>        
                              </a> 
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div><!-- ./table-responsive -->
              </div><!-- /.card-body -->
            </div><!-- /.card -->
        
        </div><!-- /.col -->
    </div>
</div>

<ng-template #cambio_importe_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Cambio de Importede Pago</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="guardarConceptoPagoEstudiante()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <label>Importe</label>
            </div>
            <div class="col-sm-12 col-md-6">
                <input type="number" step="0.00" name="importe" [(ngModel)]="concepto_pago_estudiante.importe" placeholder="Importe" class="form-control"/>
            </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-12 col-md-6">
              <label>Descuento</label>
          </div>
          <div class="col-sm-12 col-md-6">
              <input type="number" step="0.00" name="descuento" [(ngModel)]="concepto_pago_estudiante.descuento" placeholder="Descuento" class="form-control"/>
          </div>
      </div>
      <hr>
        <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Observación</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <input type="text" name="observacion" [(ngModel)]="concepto_pago_estudiante.observacion" placeholder="Observación" class="form-control"/>
          </div>
      </div>
    </div>
</ng-template>

<ng-template #asignacion_fecha_vencimiento_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-7 col-xs-7 p-0">
        <span class="text-blue text-125">Inicio Fecha de Vencimiento</span>
    </div>
    <div class="col-md-5 col-xs-5 p-0">
        <button (click)="guardarInicioFechaVencimiento()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-12">
            <div class="input-group">
              <input name="fv_general" class="form-control" placeholder="dd/mm/yyyy"
                      [(ngModel)]="concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div> 
          </div>
      </div>
  </div>
</ng-template>


