import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { InventarioExternoService } from '../../services/inventario-externo/inventario-externo.service';
import { Util } from 'src/app/utils/utils';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientesService } from '../../services/clientes/clientes.service';
import { WebcamImage } from 'ngx-webcam';
import { ProductosService } from '../../services/productos/productos.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-inventario-externo',
  templateUrl: './inventario-externo.component.html',
  styleUrls: ['./inventario-externo.component.css']
})
export class InventarioExternoComponent extends BaseComponent implements OnInit {

  inventarios_externos:any[]=[];
  inventario_externo:any={};
  clientes_nit:any[]=[];
  sucursales_registro:any[]=[];
  inventario_externo_item:any={};
  producto_busqueda:any;
  producto_inventario_externo:any={};
  detalle_analisis:any={};
  razon_busqueda:any;
  searching = false;
  searchFailed = false;  
  markers:any[]=[];
  center= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  zoom = 17;
  display?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    //maxZoom: 15,
    //minZoom: 8,
  }
  bounds:google.maps.LatLngBounds;
  posicion_marcador:google.maps.LatLng;
  @ViewChild('mapa') map:google.maps.Map;

  @ViewChildren('inventario_externo_lectura') inventario_externo_lectura: QueryList<any>;

  vista_inventario_externo:NgbModalRef;
  @ViewChild('vista_inventario_externo')
  private vista_inventario_externo_ref: TemplateRef<any>;
  
  registro_inventario_externo:NgbModalRef;
  @ViewChild('registro_inventario_externo')
  private registro_inventario_externo_ref: TemplateRef<any>;

  registro_producto_inventario_externo:NgbModalRef;
  @ViewChild('registro_producto_inventario_externo')
  private registro_producto_inventario_externo_ref: TemplateRef<any>;

  registro_detalle_analisis:NgbModalRef;
  @ViewChild('registro_detalle_analisis')
  private registro_detalle_analisis_ref: TemplateRef<any>;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private clientesService:ClientesService,
    private inventarioExternoService:InventarioExternoService,
    private productosService:ProductosService) {
      super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));
    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:(es_usuario_administrador?[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)):Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: (es_usuario_administrador?{nombre:"TODOS",id:0}:Util.obtenerSucursalesUsuario(this.usuario)[0]),
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }

    
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }

    this.itemsPerPage=0;
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.inventario_externo_lectura.changes.subscribe(t => {
			aplicarVisorImagenes("imagen-inv-ext-vista");
		});
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.inventarioExternoService.obtenerListaInventariosExternos(this).subscribe((dato:any) => {
			this.setPages(1);
			this.inventarios_externos=dato;
			this.blockUI.stop();
		});
  }

  verInvExterno(inventario_externo){
    this.blockUI.start();
    this.bounds = new google.maps.LatLngBounds();
    this.posicion_marcador=new google.maps.LatLng(inventario_externo.latitud,inventario_externo.longitud);
		this.bounds.extend(this.posicion_marcador);
    this.markers.push({
			position: {
				lat: inventario_externo.latitud,
				lng: inventario_externo.longitud,
			},
			label: {
				color: 'red',
			},
			title:inventario_externo.cliente.identificacion,
			options: { 
				labelContent : inventario_externo.cliente.identificacion,
				labelAnchor: "5 55",
				labelClass: 'marker-label', 
			},
		});
    this.center={ lat: this.bounds.getCenter().lat(), lng: this.bounds.getCenter().lng()};
    this.inventarioExternoService.obtenerInventarioExterno(inventario_externo.id).subscribe((datos:any)=>{
      this.blockUI.stop();
      this.inventario_externo=datos;
      this.vista_inventario_externo=this.modalService.open(this.vista_inventario_externo_ref, {windowClass:'vista-inv-externo', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
      this.vista_inventario_externo.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
	}

  crearNuevoInventarioExterno(){
    this.sucursales_registro=Util.obtenerSucursalesUsuario(this.usuario);
    let fecha_actual=new Date();
    let me=this;
    this.inventario_externo={
      sucursal:this.sucursales_registro[0],
      id_usuario:this.usuario.id,
      cliente:{},
      detalles_inventario_externo:[],
      detalles_analisis:[],
      fecha_texto:{
        day:fecha_actual.getDate(),
        month:fecha_actual.getMonth()+1,
        year:fecha_actual.getFullYear()
      }
    }
    this.iniciarCliente();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position:any)=>{
        me.inventario_externo.latitud=position.coords.latitude;
        me.inventario_externo.longitud=position.coords.longitude;
      });
    } else {
      alert("Ubicacion GPS no habilitada!");
    }
    this.abrirRegistroModalInventarioExterno();
  }

  abrirRegistroModalInventarioExterno(){
    this.registro_inventario_externo=this.modalService.open(this.registro_inventario_externo_ref, {windowClass:'vista-inv-externo', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
      this.registro_inventario_externo.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClienteNit(this.usuario.id_empresa,term,this.usuario.empresa.usar_clientes_sucursal?this.inventario_externo.sucursal.id:0)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

  establecerCliente(event){
    let cliente=(event.id || event.nit)?event:event.item;
    this.razon_busqueda=cliente;
    this.inventario_externo.cliente=cliente;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.inventario_externo.fotografia = webcamImage.imageAsDataUrl;
  }

  public capturarImagen(): void {
    this.trigger.next();
  }

  crearProductoInventarioExterno(){
    this.iniciarProductoInventarioExterno();
    let fecha_actual=new Date();
    this.producto_inventario_externo={
      fecha_texto:{
        day:fecha_actual.getDate(),
        month:fecha_actual.getMonth()+1,
        year:fecha_actual.getFullYear()
      }
    }
    this.registro_producto_inventario_externo=this.modalService.open(this.registro_producto_inventario_externo_ref, {windowClass:'vista-inv-externo', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
      this.registro_producto_inventario_externo.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  iniciarProductoInventarioExterno(){
    this.producto_inventario_externo.producto={};
    this.producto_busqueda="";
  }

  iniciarCliente(){
    this.inventario_externo.cliente={};
    this.razon_busqueda="";
  }

  establecerProducto($event){
    this.producto_inventario_externo.producto=$event.item;
  }

  validarProductoInventarioExterno(){
    let res=true;
    if(!this.producto_inventario_externo.producto.id){
      res=res && false;
      this.toastr.error("Debe especificar el producto!");
    }
    if(!this.producto_inventario_externo.fecha_texto || !this.producto_inventario_externo.fecha_texto.year || !this.producto_inventario_externo.fecha_texto.month || !this.producto_inventario_externo.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa!");
    }
    if(!this.producto_inventario_externo.cantidad){
      res=res && false;
      this.toastr.error("Debe especificar la cantidad!");
    }
    return res;
  }

  agregarProductoInventarioExterno(){
    if(this.validarProductoInventarioExterno()){
      this.producto_inventario_externo.fecha_produccion=Util.convertirObjetoAfecha(this.producto_inventario_externo.fecha_texto);
      this.inventario_externo.detalles_inventario_externo.push(this.producto_inventario_externo);
      this.registro_producto_inventario_externo.close();
    }
  }

  crearDetalleAnalisis(){
    this.detalle_analisis={};
    this.registro_detalle_analisis=this.modalService.open(this.registro_detalle_analisis_ref, {windowClass:'vista-inv-externo', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
      this.registro_detalle_analisis.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  validarDetalleAnalisis(){
    let res=true;
    if(!this.detalle_analisis.nivel){
      res=res && false;
      this.toastr.error("Debe especificar el nivel!");
    }
    if(!this.detalle_analisis.marca){
      res=res && false;
      this.toastr.error("Debe especificar la marca!");
    }
    if(!this.detalle_analisis.peso){
      res=res && false;
      this.toastr.error("Debe especificar el peso!");
    }
    if(!this.detalle_analisis.precio_unitario){
      res=res && false;
      this.toastr.error("Debe especificar el precio unitario!");
    }
    return res;
  }

  agregarDetalleAnalisis(){
    if(this.validarDetalleAnalisis()){
      this.inventario_externo.detalles_analisis.push(this.detalle_analisis);
      this.registro_detalle_analisis.close();
    }
  }

  eliminarProductoInventarioExterno(producto_inventario_externo){
    producto_inventario_externo.eliminado=true;
  }

  eliminarDetalleAnalisis(detalle_analisis){
    detalle_analisis.eliminado=true;
  }

  validarInventarioExterno(){
    let res=true;
    if(!this.inventario_externo.cliente.id){
      res=res && false;
      this.toastr.error("Debe especificar el cliente!");
    }
    if(!this.inventario_externo.fecha_texto || !this.inventario_externo.fecha_texto.year || !this.inventario_externo.fecha_texto.month || !this.inventario_externo.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha!");
    }
    if(this.inventario_externo.detalles_inventario_externo.length==0){
      res=res && false;
      this.toastr.error("Debe especificar al menos un detalle de producto!");
    }
    if(this.inventario_externo.detalles_analisis.length==0){
      res=res && false;
      this.toastr.error("Debe especificar al menos un detalle de analisis!");
    }
    return res;
  }

  guardarInventarioExterno(){
    this.blockUI.start();
    if(this.validarInventarioExterno()){
      this.inventario_externo.fecha_inventario=Util.convertirObjetoAfecha(this.inventario_externo.fecha_texto);
      if(this.inventario_externo.id){
        this.inventarioExternoService.actualizarInventarioExterno(this.inventario_externo).subscribe((res:any)=>{
          this.toastr.success("Registro guardado exitosamente!");
          this.registro_inventario_externo.close();
          this.getItems();
          this.blockUI.stop();
        });
      }else{
        this.inventarioExternoService.guardarInventarioExterno(this.inventario_externo).subscribe((res:any)=>{
          this.toastr.success("Registro guardado exitosamente!");
          this.registro_inventario_externo.close();
          this.getItems();
          this.blockUI.stop();
        });
      }
    }else{
      this.blockUI.stop();
    }
  }

  modificarInventarioExterno(inventario_externo){
    this.blockUI.start();
    this.sucursales_registro=Util.obtenerSucursalesUsuario(this.usuario);
    this.inventarioExternoService.obtenerInventarioExterno(inventario_externo.id).subscribe((inventario_externo_datos:any)=>{
      this.blockUI.stop();
      let fecha_inventario=new Date(inventario_externo_datos.fecha_inventario);
      let me=this;
      this.inventario_externo=inventario_externo_datos;
      this.inventario_externo.sucursal=inventario_externo_datos.cliente.sucursal;
      this.razon_busqueda=inventario_externo_datos.cliente;
      this.inventario_externo.fecha_texto={
        day:fecha_inventario.getDate(),
        month:fecha_inventario.getMonth()+1,
        year:fecha_inventario.getFullYear()
      }
      //this.iniciarCliente();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position:any)=>{
          me.inventario_externo.latitud=position.coords.latitude;
          me.inventario_externo.longitud=position.coords.longitude;
        });
      } else {
        alert("Ubicacion GPS no habilitada!");
      }
      this.abrirRegistroModalInventarioExterno();
    });
  }

  abrirPopupConfirmacionEliminacion(inventario_externo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el inventario externo?";
		this.popupConfirmacion.componentInstance.data = inventario_externo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarInventarioExterno($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarInventarioExterno(inventario_externo){
		this.blockUI.start();
		this.inventarioExternoService.eliminarInventarioExterno(inventario_externo).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}



}
