import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class AsistenciasService {

  constructor(private http: HttpClient) { }

  public guardarAsistencia(asistencia){
    return this.http.post(GlobalVariable.API_SERVER_URL+"asistencia",asistencia, GlobalVariable.httpOptions);
  }

  public actualizarAsistencia(asistencia){
    return this.http.put(GlobalVariable.API_SERVER_URL+"asistencia/"+asistencia.id,asistencia, GlobalVariable.httpOptions);
  }

  public eliminarAsistencia(asistencia){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"asistencia/"+asistencia.id, GlobalVariable.httpOptions);
  }

  public obtenerAsistencias(id_sucursal,id_registro_profesor,id_curso,fecha_inicio,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"asistencias/sucursal/"+id_sucursal+"/registro-profesor/"+id_registro_profesor+"/curso/"+id_curso+"/fecha-inicio/"+fecha_inicio+"/fecha-fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public obtenerListaEstudiantes(id_sucursal,id_gestion,id_curso,id_registro_profesor,fecha_inicio,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lista-estudiantes-asistencia/sucursal/"+id_sucursal+"/gestion/"+id_gestion+"/registro-profesor/"+id_registro_profesor+"/curso/"+id_curso+"/fecha-inicio/"+fecha_inicio+"/fecha-fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionIntervaloEscolar(id_gestion,nombre_gestion,id_intervalo_escolar,nombre_intervalo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-intervalo-escolar/"+id_intervalo_escolar+"/nombre-intervalo-escolar/"+nombre_intervalo+"/gestion/"+id_gestion+"/nombre_gestion/"+nombre_gestion, GlobalVariable.httpOptions);
  }
}
