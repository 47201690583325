<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Llantas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Llantas
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
					  <div class="col-md-2 col-sm-12"><label>Marca </label></div>
					  <div class="col-md-4 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="filter.grupo">
							  <option [ngValue]="grupo" *ngFor="let grupo of filter.grupos_filtro">{{grupo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-md-2 col-sm-12"><label>Condicion </label></div>
					  <div class="col-md-4 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo" [(ngModel)]="filter.subgrupo">
							  <option [ngValue]="subgrupo" *ngFor="let subgrupo of filter.subgrupos_filtro">{{subgrupo.nombre}}</option>
						  </select>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
              <div class="export btn-group">
                <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				</button>
				<button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-repuestos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-repuestos" style="display:none" type="file" class="form-control" (change)="subirExcelImportacion($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
                <!--<div class="dropdown-menu dropdown-menu-right" style="">
                  <a class="dropdown-item " href="#" data-type="json">JSON</a>
                  <a class="dropdown-item " href="#" data-type="xml">XML</a>
                  <a class="dropdown-item " href="#" data-type="csv">CSV</a>
                  <a class="dropdown-item " href="#" data-type="txt">TXT</a>
                  <a class="dropdown-item " href="#" data-type="sql">SQL</a>
                  <a class="dropdown-item " href="#" data-type="excel">MS-Excel</a>
                </div>-->
			  </div>
			  <div class="keep-open btn-group show" title="Columns">
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="repuestos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
				<th *ngIf="usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_activado.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('activar_inventario')">¿Inventario Activado? 
						<span id="activar_inventario" class="sort fa fa-sort icon-only"></span>
					</a>
				</th>
                <th id="cabecera_codigo" class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().codigo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="th-inner ">
                        Imagen
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)"></span>&nbsp;
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Nombre</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().grupo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='grupo' && this.direction=='asc','desc':this.column=='grupo' && this.direction=='desc'}">
                        <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)"></span>&nbsp;
                        <a href="javascript:void(0)" (click)="this.sortColumn('grupo')">GRUPO</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().sub_grupo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='subgrupo' && this.direction=='asc','desc':this.column=='subgrupo' && this.direction=='desc'}">
                        <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)"></span>&nbsp;
                        <a href="javascript:void(0)" (click)="this.sortColumn('subgrupo')">SUBGRUPO</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                  <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='descripcion' && this.direction=='asc','desc':this.column=='descripcion' && this.direction=='desc'}">
                      <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)"></span>&nbsp;
                      <a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)}}</a>
                  </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().vida_util_dias.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='vida_util_dias' && this.direction=='asc','desc':this.column=='vida_util_dias' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('vida_util_dias')">Vida Util Días</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                  <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='cantidad' && this.direction=='asc','desc':this.column=='cantidad' && this.direction=='desc'}">
                      <a href="javascript:void(0)" (click)="this.sortColumn('banda')">CANTIDAD</a>
                  </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #repuestos *ngFor="let producto of repuestos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
				<td *ngIf="usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_activado.show"><label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'activar_inventario',producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_inventario"/>
					</label>
				</td>
                <td rel="cabecera_codigo" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().codigo.show">
                    {{producto.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="imagen-producto-vista clearfix">
                        <a href="{{rest_server+producto.imagen}}" data-rel="colorbox" title="{{producto.nombre}}">
                            <img alt="sin imagen" src="{{rest_server+producto.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                  {{producto.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().grupo.show">
                    {{producto.grupo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().sub_grupo.show">
                    {{producto.subgrupo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                  {{producto.descripcion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().vida_util_dias.show">
                    {{producto.vida_util_dias}}
                </td>
                <td>
                  {{producto.cantidad}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarProducto(producto)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					</a>       
					<a *ngIf="usuario.empresa.usar_kardex_inventario_productos && usuario.empresa.usar_inventario && producto.activar_inventario" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verKardexProducto(producto)">          
						<i class="fa fa-eye text-105"></i>        
					</a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(producto)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="repuestos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #importacionproductos let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Repuestos a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || productos_importacion.length==0" (click)="guardarRepuestos()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color sticky-nav">
								<tr>
									<th>Nº</th>
									<th>Código</th>
                  <th>Nombre</th>
                  <th>Grupo</th>
                  <th>Subgrupo</th>
                  <th>Descripción</th>
                  <th>Vida Útil Días</th>
                  <th *ngIf="usuario.empresa.usar_inventario">Costo Unitario</th>
                  <th *ngIf="usuario.empresa.usar_inventario">Cantidad</th>
								</tr>
							</thead>
		
							<tbody>
								<tr *ngFor="let repuesto of productos_importacion; let i=index;">
									<td>{{i+1}}</td>
									<td>{{repuesto.codigo}}</td>
									<td>{{repuesto.nombre}}</td>
									<td>{{repuesto.grupo}}</td>
                  <td>{{repuesto.subgrupo}}</td>
                  <td>{{repuesto.descripcion}}</td>
                  <td>{{repuesto.vida_util_dias}}</td>
									<td *ngIf="usuario.empresa.usar_inventario">{{repuesto.costo_unitario}}</td>
									<td *ngIf="usuario.empresa.usar_inventario">{{repuesto.cantidad}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>