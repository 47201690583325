import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-libro-ventas',
  templateUrl: './libro-ventas.component.html',
  styleUrls: ['./libro-ventas.component.css']
})
export class LibroVentasComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
	  public pdfService:PdfService,
    public modalService: NgbModal,
    public ventasService: VentasService,
	public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));

    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      gestiones:[],
      gestion: null,
      meses:[],
      mes: null
    }

    this.obtenerGestiones();
    this.obtenerMeses();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
  }
  
  obtenerGestiones(){
    this.blockUI.start();
    this.generalService.obtenerClases("GTN").subscribe((entidad:any)=>{
      this.filter.gestiones=entidad.clases;
      this.blockUI.stop();
    });
  }

  obtenerMeses(){
    this.blockUI.start();
    this.generalService.obtenerClases("MESES").subscribe((entidad:any)=>{
      this.filter.meses=entidad.clases;
      this.blockUI.stop();
    });
  }

  generarTXTLibroVentas(){
    this.blockUI.start();
    this.ventasService.obtenerDatosLibroVentas(this.filter).subscribe((datos:any)=>{
      let ventas=datos.ventas;
			let linea="";
			for(var i=0;i<ventas.length;i++){
				ventas[i].fecha=new Date(ventas[i].fecha);
				linea=linea+"3"+"|";
				linea=linea+(i+1)+"|";
				linea=linea+ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear()+"\|";
				linea=linea+ventas[i].factura+"|";
				linea=linea+ventas[i].autorizacion+"|";
				linea=linea+(ventas[i].activa?"V":"A")+"|";
				linea=linea+ventas[i].nit_ci+"|";
				linea=linea+ventas[i].razon_social+"|";
				linea=linea+ventas[i].importe+"|";
				linea=linea+0+"|";
				linea=linea+0+"|";
				linea=linea+0+"|";
				linea=linea+ventas[i].importe+"|";
				linea=linea+ventas[i].descuento+"|";
				linea=linea+ventas[i].total+"|";
				linea=linea+(Math.round((ventas[i].total*0.13)*100)/100)+"|";
				linea=linea+ventas[i].codigo_control;
				linea=linea+"\n"
			}
      var file = new Blob([linea.replace(/\n/g, "\r\n")], {type: 'text/plain'});
      let nombre_archivo="ventas_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit)+".txt"
		  fs.saveAs(file, nombre_archivo);
			this.blockUI.stop();
    });
  }
  
  generarExcelLibroVentas(){
		this.blockUI.start();
		this.ventasService.obtenerDatosLibroVentas(this.filter).subscribe((datos:any)=>{
			var ventas=datos.ventas;
			var data = [["N°","FECHA DE LA FACTURA","N° DE LA FACTURA","N° DE AUTORIZACION","ESTADO","NIT/CI CLIENTE","NOMBRE O RAZON SOCIAL","IMPORTE TOTAL DE LA VENTA","IMPORTE ICE/IEHD/TASAS","EXPORTACIONES Y OPERACIONES EXENTAS","VENTAS GRAVADAS A TASA CERO","SUBTOTAL","DESCUENTOS, BONIFICACIONES Y REBAJAS OBTENIDAS","IMPORTE BASE PARA DEBITO FISCAL","DEBITO FISCAL","CODIGO DE CONTROL"]]
			var sumaImporte=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			for(var i=0;i<ventas.length;i++){
				var columns=[];
				ventas[i].fecha=new Date(ventas[i].fecha);
				columns.push(i+1);
				columns.push(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear());
				columns.push(ventas[i].factura);
				columns.push(ventas[i].autorizacion?ventas[i].autorizacion:ventas[i].cuf);
				columns.push((ventas[i].activa?"V":"A"));
				columns.push(ventas[i].nit_ci);
				columns.push(ventas[i].razon_social);
				columns.push(ventas[i].importe);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(ventas[i].importe);
				columns.push(ventas[i].descuento);
				columns.push(ventas[i].total);
				columns.push(Math.round((ventas[i].total*0.13)*100)/100);
				columns.push(ventas[i].codigo_control);
				if(ventas[i].activa){
					sumaImporte=sumaImporte+ventas[i].importe;
					sumaImporteIce=0;
					sumaImporteExp=0;
					sumaImporteGrab=0;
					sumaTotal=sumaTotal+ventas[i].importe;
					sumaDescuentos=sumaDescuentos+ventas[i].descuento;
					sumaImporteBase=sumaImporteBase+ventas[i].total;
					sumaCredito=sumaCredito+(Math.round((ventas[i].total*0.13)*100)/100);
				}
				data.push(columns);
				if(i+1==ventas.length){
					columns=[];
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("TOTALES");
					columns.push(Math.round((sumaImporte)*100)/100);
					columns.push( Math.round((sumaImporteIce)*100)/100);
					columns.push( Math.round((sumaImporteExp)*100)/100);
					columns.push( Math.round((sumaImporteGrab)*100)/100);
					columns.push( Math.round((sumaTotal)*100)/100);
					columns.push( Math.round((sumaDescuentos)*100)/100);
					columns.push( Math.round((sumaImporteBase)*100)/100);
					columns.push( Math.round((sumaCredito)*100)/100);
					data.push(columns);
				}
      }
      let nombre_archivo="ventas_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
			
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet(nombre_archivo);
			data.forEach(d => {
				let row = worksheet.addRow(d);
			});

			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;

			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, nombre_archivo+".xlsx");
			})
			this.blockUI.stop();
    });
  }

  generarPdfLibroVentas(){
		this.blockUI.start();
		this.ventasService.obtenerDatosLibroVentas(this.filter).subscribe((datos:any)=>{
			var ventas=datos.ventas;
			var doc = new PDFDocument({margin:10,layout: 'landscape'});
			var stream = doc.pipe(blobStream());
			// draw some text
			this.dibujarCabeceraPDFLibroVentas(doc,datos,1);
			doc.font('Helvetica',8);
			var y=170,itemsPorPagina=12,items=0,pagina=1;
			var sumaImporte=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			var sumaSubImporte=0,sumaSubImporteIce=0,sumaSubImporteExp=0,sumaSubImporteGrab=0,sumaSubTotal=0,sumaSubDescuentos=0,sumaSubImporteBase=0,sumaSubCredito=0;
			for(var i=0;i<ventas.length && items<=itemsPorPagina;i++){
				doc.rect(40,y-10,720,30).stroke();
				ventas[i].fecha=new Date(ventas[i].fecha);				
				doc.text(i+1,45,y);
				doc.text(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear(),65,y);
				doc.text(ventas[i].factura,110,y);
				doc.text(ventas[i].autorizacion,145,y);
				doc.text((ventas[i].activa?"V":"A"),210,y);
				doc.text(ventas[i].nit_ci,235,y);
				doc.text(ventas[i].razon_social,283,y-6,{width:100});				
				doc.text(ventas[i].importe,385,y);
				doc.text(0,430,y);
				doc.text(0,465,y);
				doc.text(0,507,y);
				doc.text(ventas[i].importe,540,y);
				doc.text(ventas[i].descuento,580,y);
				doc.text(ventas[i].total,615,y);
				doc.text(Math.round((ventas[i].total*0.13)*100)/100,650,y);
				doc.text(ventas[i].codigo_control,685,y);
				y=y+30;
				sumaSubImporte=sumaSubImporte+ventas[i].importe;
				sumaSubImporteIce=0;
				sumaSubImporteExp=0;
				sumaSubImporteGrab=0;
				sumaSubTotal=sumaSubTotal+ventas[i].importe;
				sumaSubDescuentos=sumaSubDescuentos+ventas[i].descuento;
				sumaSubImporteBase=sumaSubImporteBase+ventas[i].total;
				sumaSubCredito=sumaSubCredito+(Math.round((ventas[i].total*0.13)*100)/100);
				sumaImporte=sumaImporte+ventas[i].importe;
				sumaImporteIce=0;
				sumaImporteExp=0;
				sumaImporteGrab=0;
				sumaTotal=sumaTotal+ventas[i].importe;
				sumaDescuentos=sumaDescuentos+ventas[i].descuento;
				sumaImporteBase=sumaImporteBase+ventas[i].total;
				sumaCredito=sumaCredito+(Math.round((ventas[i].total*0.13)*100)/100);
				items++;
				
				if(items==itemsPorPagina || i+1==ventas.length){
					doc.font('Helvetica-Bold',8);
					doc.text("SUBTOTALES",283,y);
					doc.text(Math.round((sumaSubImporte)*100)/100,385,y);
					doc.text(Math.round((sumaSubImporteIce)*100)/100,430,y);
					doc.text(Math.round((sumaSubImporteExp)*100)/100,465,y);
					doc.text(Math.round((sumaSubImporteGrab)*100)/100,507,y);
					doc.text(Math.round((sumaSubTotal)*100)/100,540,y);
					doc.text(Math.round((sumaSubDescuentos)*100)/100,580,y);
					doc.text(Math.round((sumaSubImporteBase)*100)/100,615,y);
					doc.text(Math.round((sumaSubCredito)*100)/100,650,y);
					doc.rect(40,y-10,720,30).stroke();
					doc.font('Helvetica',8);
					var sumaSubImporte=0,sumaSubImporteNo=0;sumaSubTotal=0;sumaSubDescuentos=0;sumaSubImporteBase=0;sumaSubCredito=0;
					
					if(i+1==ventas.length){
						doc.font('Helvetica-Bold',8);
						doc.text("TOTALES",283,y+30);
						doc.text(Math.round((sumaImporte)*100)/100,385,y+30);
						doc.text( Math.round((sumaImporteIce)*100)/100,430,y+30);
						doc.text( Math.round((sumaImporteExp)*100)/100,465,y+30);
						doc.text( Math.round((sumaImporteGrab)*100)/100,507,y+30);
						doc.text( Math.round((sumaTotal)*100)/100,540,y+30);
						doc.text( Math.round((sumaDescuentos)*100)/100,580,y+30);
						doc.text( Math.round((sumaImporteBase)*100)/100,615,y+30);
						doc.text( Math.round((sumaCredito)*100)/100,650,y+30);
						doc.rect(40,y-10+30,720,30).stroke();
						doc.font('Helvetica',8);
					}else{
						doc.addPage({margin:0,bufferPages: true,layout: 'landscape'});
						y=170;
						items=0;
						pagina=pagina+1;
						this.dibujarCabeceraPDFLibroVentas(doc,datos,pagina);
						doc.font('Helvetica',8);
					}
				}
			}
			doc.end();
			stream.on('finish', function() {
				var fileURL = stream.toBlobURL('application/pdf');
				window.open(fileURL,'_blank','location=no');
			});
			this.blockUI.stop();
		});
  }
  
  dibujarCabeceraPDFLibroVentas(doc,datos,pagina){
		doc.font('Helvetica-Bold',12);
		doc.text("LIBRO DE VENTAS IVA ESTÁNDAR",0,25,{align:"center"});
		doc.font('Helvetica-Bold',8);
		doc.text("FOLIO "+pagina,720,25);
		doc.rect(40,60,720,40).stroke();
		doc.text("PERIÓDO FISCAL : ",65,70);
		doc.text("NOMBRE O RAZÓN SOCIAL : ",65,85);
		doc.text("NIT : ",440,85);
		doc.font('Helvetica',8);
		doc.text("AÑO "+this.filter.gestion.nombre,140,70);
    doc.text("MES "+this.filter.mes.nombre,200,70);
    let razon_social=(this.filter.sucursal.id==0?datos.empresa.razon_social:this.filter.sucursal.razon_social),nit=(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
		doc.text(razon_social,195,85);
		doc.text(nit,460,85);
		
		doc.rect(40,100,720,60).stroke();
		doc.font('Helvetica-Bold',8);
		doc.text("Nº",45,110);
		doc.text("Fecha de la Factura",65,110,{width:40});
		doc.text("Nº de la Factura",110,110,{width:50});
		doc.text("Nº de Autorización",145,110,{width:50});
		doc.text("Estado",205,110,{width:35});
		doc.text("NIT / CI Cliente",235,110,{width:50});
		doc.text("Nombre o Razón Social",280,110);
		doc.text("Importe Total de la Venta",385,110,{width:35});
		doc.text("Importe ICE IE HD/T",425,110,{width:35});
		doc.text("Exp. y Op. exentas",460,110,{width:42});
		doc.text("Ventas grabadas a Tasa Cero",502,110,{width:42});
		doc.text("Subtotal",540,110,{width:40});
		doc.text("Desc., Bonif. y Rebajas",575,110,{width:42});
		doc.text("Importe Base para Débito Fiscal",615,110,{width:35});
		doc.text("Débito Fiscal I.V.A.",650,110,{width:35});
		doc.text("Código de Control",685,110);
	}

	generarExcelLibroVentas2(){
		this.blockUI.start();
		this.ventasService.obtenerDatosLibroVentas(this.filter).subscribe((datos:any)=>{
			var ventas=datos.ventas;
			var data = [];
			let cabecera=["N°","ESPECIFICACION","FECHA DE LA FACTURA","N° DE LA FACTURA","CODIGO DE AUTORIZACION",
			"NIT/CI CLIENTE","COMPLEMENTO","NOMBRE O RAZON SOCIAL","IMPORTE TOTAL DE LA VENTA","IMPORTE ICE",
			"IMPORTE IEHD","IMPORTE IPJ","TASAS","OTROS NO SUJETOS AL IVA","EXPORTACIONES Y OPERACIONES EXENTAS",
			"VENTAS GRABADAS A TASA CERO","SUBTOTAL","DESCUENTOS, BONIFICACIONES Y REBAJAS SUJETAS AL IVA",
			"IMPORTE GIFT CARD","IMPORTE BASE PARA DEBITO FISCAL","DEBITO FISCAL","ESTADO","CODIGO DE CONTROL","TIPO DE VENTA"];
			//data.push(cabecera);
			var sumaImporte=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			for(var i=0;i<ventas.length;i++){
				var columns=[];
				ventas[i].fecha=new Date(ventas[i].fecha);
				columns.push(i+1);
				columns.push("2");
				columns.push(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear());
				columns.push(ventas[i].factura);
				columns.push(ventas[i].autorizacion);
				columns.push(ventas[i].nit_ci);
				columns.push(ventas[i].cliente.complemento);
				columns.push(ventas[i].razon_social);
				columns.push(ventas[i].importe);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(0);
				columns.push(ventas[i].importe);
				columns.push(ventas[i].descuento);
				columns.push(0);
				columns.push(ventas[i].total);
				columns.push(Math.round((ventas[i].total*0.13)*100)/100);
				columns.push((ventas[i].activa?"V":"A"));
				columns.push(ventas[i].codigo_control);
				columns.push(0);
				if(ventas[i].activa){
					sumaImporte=sumaImporte+ventas[i].importe;
					sumaImporteIce=0;
					sumaImporteExp=0;
					sumaImporteGrab=0;
					sumaDescuentos=sumaDescuentos+ventas[i].descuento;
					sumaImporteBase=sumaImporteBase+ventas[i].total;
					sumaCredito=sumaCredito+(Math.round((ventas[i].total*0.13)*100)/100);
				}
				data.push(columns);
				if(i+1==ventas.length){
					columns=[];
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("TOTALES");
					columns.push(Math.round((sumaImporte)*100)/100);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push( Math.round((sumaImporte)*100)/100);
					columns.push( Math.round((sumaDescuentos)*100)/100);
					columns.push(0);
					columns.push( Math.round((sumaImporteBase)*100)/100);
					columns.push( Math.round((sumaCredito)*100)/100);
					data.push(columns);
				}
      }
      let nombre_archivo="ventas_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
			
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet(nombre_archivo);
			let headerRow = worksheet.addRow(cabecera);
			headerRow.eachCell((cell, number) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: '4167B8' },
					bgColor: { argb: '' }
				}
				cell.font = {
					bold: true,
					color: { argb: 'FFFFFF' },
					size: 12
				}
				cell.alignment ={ wrapText: true };
			})
			data.forEach(d => {
				let row = worksheet.addRow(d);
			});

			for(let i=1;i<=cabecera.length;i++){
				worksheet.getColumn(i).width = 15;
				worksheet.getColumn(i).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
			  }

			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, nombre_archivo+".xlsx");
			})
			this.blockUI.stop();
    });
  }

}
