import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class ProfesoresService {

  constructor(private http: HttpClient) { }

  public obtenerRegistrosProfesorGestion(id_persona,id_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"registro-profesor-gestion/"+id_gestion+"/persona/"+id_persona,GlobalVariable.httpOptions);
  }

  public obtenerGestionActual(id_empresa,nombre_corto_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"gestion-actual/"+nombre_corto_gestion+"/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerEscuela(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresa-escuela/"+id_empresa,GlobalVariable.httpOptions);
  }
}
