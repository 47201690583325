<div class="modal-header">
    
    <div class="col-3">
        <h4 class="widget-title">Registro de Notas: </h4>
    </div>
    <div class="col-5">
        <table class="table table-bordered table-hover table-striped">
            <tbody>
                <tr>
                <td><b>Gestión:</b> {{filter.gestion.nombre}}</td>
                <td><b>Colegio:</b> {{escuela.nombre}} </td>
                <td><b>Trimestre:</b> {{filter.intervalo_escolar.nombre}}</td>
                <td><b>Curso:</b> {{filter.registro_profesor.curso.nombre_corto}}</td>
                <td>
                    <b>Matéria:</b> {{filter.registro_profesor.materia.nombre}}
                </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-4">
        <button title="Guardar calificaciones" class="btn btn-primary" (click)="guardarCalificaciones()" type="button" >
            <i class="ace-icon fa fa-save red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button title="Reinicializar planilla" class="btn btn-danger" (click)="abrirPopupConfirmacionReinicializacion()" type="button">
            <i class="ace-icon fa fa-power-off red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button title="Descargar Pdf" class="btn btn-danger" (click)="descargarPdfCalificaciones()" type="button">
            <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button title="Cerrar sin guardar" class="btn btn-danger" type="button" (click)="cerrarRegistroCalificaciones()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>

</div>
<div class="modal-body">
<div class="card card-info card-outline">
    <div class="box box-primary">
        <div class="box-body">
            <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped">
                <thead class="bgc-white text-grey text-uppercase text-80">
                    <tr>
                        <th rowspan="2" class="bgc-primary-d1 text-white text-center">Nº</th>
                        <th rowspan="2" class="bgc-primary-d1 text-white text-center">ESTUDIANTE</th>
                        <th class="bgc-success-d1 text-white text-center" *ngFor="let dimension of tipo_calificacion_inicial.clases | orderBy:'nombre_corto';">
                            <!--{{dimension.nombre}}-->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let estudiante of estudiantes; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{estudiante.persona.nombre_completo}}</td>
                        <td class="bgc-primary-l2" *ngFor="let score of estudiante.inscripciones_colegio[0].calificaciones | orderBy:'orden';">
                            <textarea style="overflow:auto;resize:none" rows="5" cols="70" maxlength="400" [(ngModel)]="score.descripcion"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>