
    <div class="d-flex flex-wrap py-1 pt-lg-3 pb-lg-2 mt-lg-1" *ngIf="vista_bandeja">
      <a (click)="volverBandeja()" href="javascript:void(0)" class="btn btn-lighter-primary btn-tp border-0" id="message-list-back-btn">
        <i class="fa fa-arrow-left mr-1 text-90"></i>
        <span class="text-dark-m3">
        Volver
    </span>
      </a>

      <div class="mx-auto order-last order-sm-0 mt-2 mt-sm-0">
        <div class="dropdown d-inline-block">
          <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-blue btn-a-outline-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Action
            <i class="fa fa-caret-down ml-1"></i>
          </a>
          <div class="dropdown-menu shadow-sm dropdown-center brc-secondary-l1 radius-1 px-2px py-1">
            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <i class="fa fa-reply w-3 text-blue-d2"></i>
              Reply
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <span class="w-3 d-inline-block">
                    <i class="fa fa-reply fa-flip-horizontal text-green-d2"></i>
                </span>
              Forward
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <i class="fa fa-folder-open text-orange-d2 w-3"></i>
              Archive
            </a>

            <div class="dropdown-divider"></div>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <i class="fa fa-eye w-3 text-blue-d2"></i>
              Mark as read
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <i class="fa fa-eye-slash w-3 text-green-d2"></i>
              Mark as unread
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <i class="far fa-flag w-3 text-danger-d2"></i>
              Flag
            </a>
          </div>
        </div>


        <div class="dropdown d-inline-block">
          <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-blue btn-a-outline-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="far fa-folder mr-1"></i>
            Move to
            <i class="fa fa-caret-down ml-1"></i>
          </a>
          <div class="dropdown-menu shadow-sm dropdown-center brc-secondary-l1 radius-1 px-2px py-1">
            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <span class="d-inline-block w-2 h-2 border-3 brc-pink radius-round mr-1 mb-1 align-middle"></span>
              Family
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <span class="d-inline-block w-2 h-2 border-3 brc-blue radius-round mr-1 mb-1 align-middle"></span>
              Friends
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <span class="d-inline-block w-2 h-2 border-3 brc-green radius-round mr-1 mb-1 align-middle"></span>
              Work
            </a>

            <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
              <span class="d-inline-block w-2 h-2 border-3 brc-orange radius-round mr-1 mb-1 align-middle"></span>
              Other
            </a>
          </div>
        </div>

        <div class="dropdown d-inline-block">
          <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-danger btn-a-outline-danger">
            <i class="fa fa-trash-alt text-danger mr-sm-1"></i>
            <span class="d-none d-sm-inline">Delete</span>
          </a>
        </div>
      </div>

      <div class="d-inline-flex align-items-center">
        <span class="mr-2">
        4 of 151
    </span>

        <a href="#" class="btn btn-tp border-0 btn-lighter-blue">
          <i class="fa fa-chevron-left"></i>
        </a>

        <a href="#" class="btn btn-tp border-0 btn-lighter-blue">
          <i class="fa fa-chevron-right"></i>
        </a>
      </div>
    </div>


    <hr class="mt-2 mt-sm-1 brc-black-tp10" *ngIf="vista_bandeja" />


    <div class="d-flex flex-column flex-md-row">
      <div>
        <h3 class="mt-2 mb-3 text-130 d-inline-flex align-items-center">
          <i class="fa fa-star text-warning text-md align-middle"></i>
          <span class="mx-2">
            {{mensaje.titulo}}
        </span>
          <span class="align-middle ml-1 badge bgc-default-l3 border-1 brc-default-l1 text-default-d3 text-60 radius-1">Inbox</span>
        </h3>

        <div class="d-flex align-items-center">
          <img src="{{global_variable.SERVER_URL+mensaje.usuario_creador.persona.imagen}}" class="w-5 h-5 mx-1 radius-round p-2px bgc-white border-1 brc-grey-l1 shadow-sm" />

          <div class="ml-2">
            <a href="#" class="text-600">
              {{mensaje.usuario_creador.persona.nombre_completo}}
            </a>

            <div class="text-grey">
              to {{usuario.nombre_usuario}}
            </div>
          </div>
        </div>
      </div>


      <div class="ml-auto text-right mt-2 mt-md-0">
        <span class="text-sm text-grey mr-2">
            {{mensaje.createdAt | date:"dd/MM/yyyy - HH:mm"}}
    </span>

        <div class="d-inline-block text-nowrap">
          <a href="#" class="btn btn-lighter-blue btn-text-blue border-0 btn-bgc-tp px-2">
            <i class="fa fa-reply"></i>
          </a>

          <a href="#" class="btn btn-lighter-green btn-text-green border-0 btn-bgc-tp px-2">
            <i class="fa fa-reply fa-flip-horizontal"></i>
          </a>

          <a href="#" class="btn btn-lighter-pink btn-text-pink border-0 btn-bgc-tp px-2">
            <i class="fa fa-print"></i>
          </a>
        </div>
      </div>
    </div>


    <div class="mt-4">
      <p>
        {{mensaje.mensaje}}.
      </p>

        <app-vista-movimiento-inventario *ngIf="mensaje.clase.nombre_corto==global_variable.Dictionary.TIPO_MENSAJE_TRASPASO"
            [movimiento_inventario]="movimiento_inventario">
        </app-vista-movimiento-inventario>
    </div>


    <div class="my-5 d-none">
      <h5 class="text-dark-m3 text-600 text-105">
        Attachments
        <span class="text-grey text-400 text-md">(2 files, 30 MB)</span>
      </h5>

      <div class="row justify-content-between">
        <div class="col-12 col-md-6 col-lg-5 col-xl-4">
          <ul class="list-unstyled mt-3 p-3 radius-1 bgc-secondary-l4">
            <li class="mb-25 d-flex">
              <i class="far fa-file-pdf text-danger text-lg w-3 mr-1"></i>
              Document.pdf

              <div class="ml-auto action-buttons">
                <a href="#" class="text-blue mr-2">
                  <i class="fa fa-download"></i>
                </a>
                <a href="#" class="text-danger">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
            </li>


            <li class="d-flex">
              <i class="fa fa-film text-green text-lg w-3 mr-1"></i>
              Video.mp4

              <div class="ml-auto action-buttons">
                <a href="#" class="text-blue mr-2">
                  <i class="fa fa-download"></i>
                </a>
                <a href="#" class="text-danger">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>


        <div class="col-12 col-md-6 col-lg-5 col-xl-4">
          <div class="text-center text-md-right">
            <div role="button" class="overflow-hidden d-inline-block radius-1 pos-rel d-style">
              <img src="/assets/image/gallery/thumb9.jpg" class="d-zoom-2" style="width: 164px;" />
              <div class="position-tl w-100 h-100 bgc-black-tp5 v-hover">
                <div class="position-lc text-center w-100">
                  <a href="#" class="text-white no-underline mr-2 dh-zoom-3 d-inline-block">
                    <i class="fa fa-download"></i>
                  </a>

                  <a href="#" class="text-white no-underline dh-zoom-3 d-inline-block">
                    <i class="fa fa-star"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="text-center text-md-left d-none">
      <a href="#" class="btn btn-white px-35 btn-h-lighter-dark">
        <i class="fa fa-reply mr-2"></i>
        Reply
      </a>

      <a href="#" class="btn btn-white px-35 btn-h-lighter-dark">
        Forward
        <i class="fa fa-arrow-right ml-2"></i>
      </a>
    </div>

