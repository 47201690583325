<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Seguimiento</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarSeguimiento()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Estudiante</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <label>{{seguimiento.estudiante.persona.nombre_completo}}</label>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Materia</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <label>{{seguimiento.materia?seguimiento.materia.nombre:''}}</label>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Categoria</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" name="categoria" class="form-control" [(ngModel)]="seguimiento.categoria">
                <option [ngValue]="categoria" *ngFor="let categoria of tipo_categorias.clases">{{categoria.nombre}}</option>
            </select>
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CATEGORIA_SEGUIMIENTO_ESTUDIANTE,'obtenerCategoriasSeguimiento')"></span>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Fecha</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <div class="input-group">
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="seguimiento.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Observaciones</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <textarea style="overflow:auto;resize:none" rows="5" cols="40" maxlength="400" name="editordata" [(ngModel)]="seguimiento.descripcion" placeholder="Observación"></textarea>
        </div>
    </div>
</div>