import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from '../../../models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { RecursoMultimediaService } from '../../../base/services/recurso-multimedia/recurso-multimedia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const aplicarSubidaArchivo:any;
declare const aplicarEditor:any;

@Component({
  selector: 'app-registro-recurso-multimedia',
  templateUrl: './registro-recurso-multimedia.component.html',
  styleUrls: ['./registro-recurso-multimedia.component.css']
})
export class RegistroRecursoMultimediaComponent extends BaseComponent implements OnInit {

  recurso:any;
  pre_fijo_ruta:string='./files/';
  tipos_recursos:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr:ToastrService,
    public recursoMultimediaService:RecursoMultimediaService,
    public usuarioService:UsuarioService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.recurso={
      publico:false,
      id_empresa:this.usuario.id_empresa
    }
    this.obtenerTiposRecursos();
  }

  ngAfterViewInit(){
    aplicarEditor(this);
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerTiposRecursos(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_RECURSOS_MULTIMEDIA).subscribe((entidad:any) => {
      this.tipos_recursos=entidad.clases;
      this.blockUI.stop();
    });
  }

  validarRecurso(){
		let res=true;
    if(!this.recurso.tipo_recurso){
      this.toastr.error("Debe especificar el tipo de recurso que subirá!");
			res=false;
    }else{
      if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_ARCHIVO && !this.recurso.archivo){
        this.toastr.error("Debe subir un archivo!");
        res=false;
      }
    }
		if(!this.recurso.nombre){
			this.toastr.error("Debe ingresar el nombre del recurso!");
			res=false;
		}
		return res;
	}

  guardarRecurso(){
    if(this.validarRecurso()){
      this.blockUI.start();
      if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_ARCHIVO){
        this.recurso.extension=this.recurso.archivo.substring(this.recurso.archivo.indexOf('/') + 1, this.recurso.archivo.indexOf(';base64'));
        this.establecerExtensionIconoColor();
        this.recurso.ruta_url=this.pre_fijo_ruta;
      }else if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_CARPETA){
        this.recurso.icono="far fa-folder";
        this.recurso.color="text-orange-d1";
      }else if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_URL){
        this.recurso.icono="fas fa-link";
        this.recurso.color="text-purple-d1";
      }
      this.recursoMultimediaService.subirRecursoMultimedia(this.recurso).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.alTerminar.emit(res);
        this.blockUI.stop();
      });
    }
  }

  establecerExtensionIconoColor(){
    if(this.recurso.extension=="vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      this.recurso.icono="far fa-file-excel";
      this.recurso.extension="xlsx";
      this.recurso.color="text-success-m2";
    }else if(this.recurso.extension=="pdf"){
      this.recurso.icono="far fa-file-pdf";
      this.recurso.extension="pdf";
      this.recurso.color="text-danger-m2";
    }else if(this.recurso.extension=="msword" || this.recurso.extension=="vnd.openxmlformats-officedocument.wordprocessingml.document"){
      this.recurso.icono='far fa-file-word';
      this.recurso.extension="docx";
      this.recurso.color="text-primary-m2";
    }else if(this.recurso.extension=="png"){
      this.recurso.icono='far fa-file-image';
      this.recurso.extension="png";
      this.recurso.color="text-primary-m2";
    }else if(this.recurso.extension=="jpg" || this.recurso.extension=="jpeg"){
      this.recurso.icono="far fa-file-image";
      this.recurso.extension="jpeg";
      this.recurso.color="text-primary-m2";
    }else if(this.recurso.extension=="mp3" || this.recurso.extension=="mpeg"){
      this.recurso.icono="far fa-file-audio";
      this.recurso.extension="mp3";
      this.recurso.color="text-primary-m2";
    }else if(this.recurso.extension=="mp4"){
      this.recurso.icono='far fa-file-video';
      this.recurso.extension="mp4";
      this.recurso.color="text-primary-m2";
    }else if(this.recurso.extension=="x-zip-compressed"){
      this.recurso.icono='far fa-file-archive';
      this.recurso.extension="zip";
      this.recurso.color="text-orange-m2";
    }else if(this.recurso.extension=="octet-stream"){
      this.recurso.icono='far fa-file-archive';
      this.recurso.extension="rar";
      this.recurso.color="text-orange-m2";
    }
  }

  cambiarTipoRecurso(){
    if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_ARCHIVO){
      let me=this;
      setTimeout(function(){
        aplicarSubidaArchivo('dropzone','preview-template',me,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:50});
      },0)
    }else if(this.recurso.tipo_recurso.nombre_corto==GlobalVariable.Dictionary.TIPO_RECURSO_URL){
      this.recurso.publico=true;
    }
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.recurso.archivo=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.recurso.archivo=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  establecerDescripcion(dato){
    this.recurso.descripcion=dato;
  }

}
