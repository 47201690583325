import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/global';
import { GeneralService } from '../../services/general/general.service';

@Component({
  selector: 'app-popup-confirmacion',
  templateUrl: './popup-confirmacion.component.html',
  styleUrls: ['./popup-confirmacion.component.css']
})
export class PopupConfirmacionComponent implements OnInit {

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();
  message:string="¿Esta seguro de eliminar el elemento?";
  title:string="Confirmación de eliminación!";
  data:any;
  es_venta:boolean=false;
  global_variable:any=GlobalVariable;
  sin_motivos_anulacion:any[]=[];

  constructor(public activeModal: NgbActiveModal,public generalSerice:GeneralService) { }

  ngOnInit(): void {
    if(this.es_venta && (this.data.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || this.data.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL)){
      this.obtenerMotivosAnulacionSin();
    }
  }

  doYes(){
    this.onConfirm.emit({confirm:true,data:this.data});
  }

  doNo(){
    this.onConfirm.emit({confirm:false,data:this.data});
  }

  obtenerMotivosAnulacionSin(){
    this.generalSerice.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_MOTIVOS_ANULACION).subscribe((res:any)=>{
      this.sin_motivos_anulacion=res.clases;
      this.data.motivo_anulacion=this.sin_motivos_anulacion[0];
    });
  }

}
