
<div class="modal-header">
		<div class="col-3">
			<h5 class="widget-title">{{titulo_reporte}}:</h5>
            <h4 class="widget-title">{{cliente.identificacion}}</h4>
		</div>
        <div class="col-6">
            <div class="row" *ngIf="mostrar_filtro_fechas">
                <div class="col-6" *ngIf="mostrar_filtro_fechas">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>
                <div class="col-6" *ngIf="mostrar_filtro_fechas">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                        <legend >General</legend>
                        <div class="row">
                            <div class="col-12">
                                <button [disabled]="!cliente.ventas || cliente.ventas.length==0" class="btn btn-danger" type="button" (click)="generarPdf()">
                                    <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
                                </button>&nbsp;
                                <button [disabled]="!cliente.ventas || cliente.ventas.length==0" class="btn btn-success" type="button" (click)="generarExcel()">
                                    <i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
                                </button>&nbsp;
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-6">
                    <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                        <legend >Mayores</legend>
                        <div class="row">
                            <div class="col-12">
                                <!--<button [disabled]="!cliente.ventas || cliente.ventas.length==0" class="btn btn-danger" type="button" (click)="generarPdfMayores()">
                                    <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
                                </button>&nbsp;-->
                                <button [disabled]="!cliente.ventas || cliente.ventas.length==0" class="btn btn-success" type="button" (click)="generarExcelMayores()">
                                    <i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
                                </button>&nbsp;
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
		<div class="col-3">
            <button [disabled]="selectedItems.length==0" *ngIf="total_saldo>0" class="btn btn-success" type="button" (click)="abrirPopupPagoCreditosCliente()">
				<i class="ace-icon fa fa-money-bill-alt red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button *ngIf="mostrar_boton_busqueda" class="btn btn-primary" type="button" (click)="buscarHistorico()">
				<i class="ace-icon fa fa-search red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
            </button>
		</div>
</div>
<div class="modal-body">
    <div class="table-fixed-header">
        <table *ngIf="cliente.ventas_filtradas && cliente.ventas_filtradas.length>0" class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th class="text-center pr-0">
                        <label class="py-0">
                          <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(cliente.ventas_filtradas)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                        </label>
                    </th>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Transaccion</th>
                    <th>Nro. Doc.</th>
                    <th *ngIf="usuario.empresa.usar_clientes_contactos">Contacto
                        <input class="form-control" placeholder="Buscar" type="text" [(ngModel)]="busqueda_contacto.nombre" (keyup)="buscarContacto(busqueda_contacto.nombre)">
                    </th>
                    <th>Descripcion</th>
                    <th>Importe</th>
                    <th>A Cuenta</th>
                    <th>Saldo</th>
                    <th>Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let venta of cliente.ventas_filtradas; let i=index;">
                    <td class='text-center pr-0 pos-rel'>
                        <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                          <!-- border shown on hover -->
                        </div>
                        <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1">
                          <!-- border shown when row is selected -->
                        </div>
      
                        <label>
                          <input [(ngModel)]="venta.selected" (change)="addItemToSelected(venta)" type="checkbox" class="align-middle" />
                        </label>
                    </td>
                    <td>{{i+1}}</td>
                    <td>{{venta.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{venta.transaccion}} </td>
                    <td>{{venta.factura}} </td>
                    <td *ngIf="usuario.empresa.usar_clientes_contactos">{{venta.contacto_cliente.persona.nombre_completo}}</td>
                    <td ></td>

                    <td>{{venta.total}}</td>
                    <td>{{(venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE)?venta.a_cuenta:venta.total}}</td>
                    <td>{{(venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE)?venta.saldo:0}}</td>
                    <td>{{venta.usuario}}</td>
                    <td>
                        <div  class="hidden-sm hidden-xs action-buttons">
                            <a *ngIf="venta.saldo>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupPagoCreditosVenta(venta)">          
                                <i class="fa fa-money-bill-alt text-105"></i>        
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(venta)">          
                                <i class="fa fa-search text-105"></i>        
                            </a>
                            <a *ngIf="venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verPagosVenta(venta)">
                                <i class="fa fa-list-alt bigger-130"></i>
                            </a>
                        </div>							
                    </td>
                    
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th *ngIf="usuario.empresa.usar_clientes_contactos"></th>
                    <th>Total General</th>
                    <th>{{total_importe.toFixed(2)}}</th>
                    <th>{{total_a_cuenta.toFixed(2)}}</th>
                    <th>{{total_saldo.toFixed(2)}}</th>
                    <th></th>
                    <th></th>
                </tr>
            </tbody>					
        </table>
    </div>
</div>

<ng-template #pago_general_modal let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Registro de Pago General: {{cliente.identificacion}}</h4>
		</div>
		<div class="col-4">
            <button [disabled]="!pago_general.importe || pago_general.importe==0 || pago_general.importe>total_saldo" class="btn btn-primary" type="button" (click)="guardarPagoGeneral()">
				<i class="ace-icon fa fa-save red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
            </button>
		</div>
	</div>
	<div class="modal-body card-body">
        <div class="row p-1">
            <div class="col-2 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Total Importe : 
                </label>    
            </div>
            <div class="col-2">
                <label>
                    {{total_importe_seleccionado.toFixed(2)}}
                </label>    
            </div>
            <div class="col-2 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Total A Cuenta: 
                </label>    
            </div>
            <div class="col-2">
                <label>
                    {{total_a_cuenta_seleccionado.toFixed(2)}}
                </label>    
            </div>
            <div class="col-2 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Total Saldo: 
                </label>    
            </div>
            <div class="col-2">
                <label>
                    {{total_saldo_seleccionado.toFixed(2)}}
                </label>    
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Fecha</th>
                            <th>Transaccion</th>
                            <th>Nro. Doc.</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
        
                    <tbody>
                        <tr *ngFor="let venta of selectedItems; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{venta.fecha | date:"dd/MM/yyyy"}}</td>
                            <td>{{venta.transaccion}} </td>
                            <td>{{venta.factura}} </td>
                            <td>{{(venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE)?venta.saldo:0}}</td>
                        </tr>
                    </tbody>					
                </table>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-6 col-md-1">
                <label for="fefin">Fecha </label>&nbsp;
            </div>
            <div class="col-6 col-md-4">
                <div class="input-group">
                    <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="pago_general.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div> 
            </div>
            <div class="col-6 col-md-2">
                <label>¿Ingrese el monto a cobrar?</label>
            </div>
            <div class="col-6 col-md-3">
                <input id="importe" onfocus="this.select();" name="pago_venta" class="form-control" required (keyup)="calcularSaldoActualizado()" (change)="calcularSaldoActualizado()" [(ngModel)]="pago_general.importe" min="0.01" max="{{total_saldo}}" type="number" step="0.001">
            </div>
            <div class="col-6 col-md-2 p-0">
                <label>Saldo Actualizado Bs {{pago_general.saldo_actualizado.toFixed(2)}}</label>
            </div>
        </div>
        <ng-container *ngIf="usuario.empresa.usar_caja_general">
            <hr>
            <div class="row">
                <div class="col-2">
                    <label for="fefin">Transaccion: </label>&nbsp;
                </div>
                <div class="col-4">
                    <select [compareWith]="compararObjectos" id="transaccion" name="transaccion" class="form-control" [(ngModel)]="pago_general.transaccion">
                        <option [ngValue]="transaccion" *ngFor="let transaccion of transacciones">{{transaccion.nombre}}</option>
                    </select>
                </div>
                <div class="col-3">
                    <label>Caja:</label>
                </div>
                <div class="col-3">
                    <select [compareWith]="compararObjectos" name="caja" class="form-control" [(ngModel)]="pago_general.caja">
                        <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
	</div>
</ng-template>