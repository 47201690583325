<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Devoluciones de Ventas</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button *ngIf="!venta || (venta && venta.activa)" class="btn btn-primary" (click)="crearNuevaDevolucion()">
            <i class="ace-icon fa fa-plus align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row" *ngIf="!venta">
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Desde </label>&nbsp;
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="fefin">Hasta </label>&nbsp;
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>  
         </div>
         <div class="col-md-4 col-sm-12">
            <div class="input-group">
                <label for="fefin">Cliente </label>&nbsp;
                <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
            </div>  
         </div>
        <div class="col-md-2 col-xs-3 col-sm-3">
            <button (click)="getItems()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <div class="row p-1" *ngIf="venta">
        <div class="col-md-7">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Transacción : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.transaccion.nombre?venta.transaccion.nombre:venta.transaccion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.cliente.razon_social?venta.cliente.razon_social:venta.cliente}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Num. Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.factura}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row" *ngIf="venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Plazo (Días) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.total}} 
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.a_cuenta}} 
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="venta.tipo_pago_nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.saldo}} 
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th *ngIf="!venta">Transacción Venta</th>
                    <th *ngIf="!venta">Número Doc. Venta</th>
                    <th *ngIf="!venta">Cliente</th>
                    <th *ngIf="usuario.empresa.usar_clientes_contactos">Contacto Cliente</th>
                    <th>Número Doc. Devolución</th>
                    <th *ngIf="!venta">Total Venta</th>
                    <th *ngIf="!venta">Saldo Venta</th>
                    <th>Total Devolución</th>
                    <th>Obs.</th>							
                    <!--<th>Usuario</th>-->
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let devolucion_venta of lista_devoluciones_ventas; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{devolucion_venta.fecha | date:"dd/MM/yyyy"}}</td>
                    <td *ngIf="!venta">{{devolucion_venta.venta.transaccion.nombre}}</td>
                    <td *ngIf="!venta">{{devolucion_venta.venta.factura}}</td>
                    <td *ngIf="!venta">{{devolucion_venta.venta.cliente.identificacion}}</td>
                    <td *ngIf="usuario.empresa.usar_clientes_contactos">{{devolucion_venta.venta.contacto_cliente?devolucion_venta.venta.contacto_cliente.persona.nombre_completo:''}}</td>
                    <td>{{devolucion_venta.numero_documento}}</td>
                    <td *ngIf="!venta">{{devolucion_venta.venta.total}}</td>
                    <td *ngIf="!venta">{{devolucion_venta.venta.saldo}}</td>
                    <td><i class="eva eva-paper-plane-outline text-160 radius-round bgc-success-l4 text-success p-2 border-2 brc-success-m3">{{devolucion_venta.total}}</i></td>
                    <td>{{devolucion_venta.observacion}}</td>
                    <!--<td>{{devolucion_venta.usuario.nombre_usuario}}</td>-->
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirNotaDevolucion(devolucion_venta)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalDevolucionVentaVista(devolucion_venta)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>                            
                        </div>
                    </td>
                </tr>
                <tr *ngIf="lista_devoluciones_ventas.length>0">
                    <th></th>
                    <th></th>
                    <th *ngIf="!venta"></th>
                    <th *ngIf="!venta"></th>
                    <th *ngIf="!venta"></th>
                    <th *ngIf="usuario.empresa.usar_clientes_contactos"></th>
                    <th></th>
                    <th *ngIf="!venta"></th>
                    <th *ngIf="!venta"></th>
                    <th><b>TOTAL</b>: {{sumarImporte().toFixed(2) }}</th>
                    <th></th>										
                    <th></th>										
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #devolucion_venta_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Devolución Venta</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
        <div class="row p-1">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-6 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fas fa-money-bill-alt"></i> Numero Documento : 
                        </label>    
                    </div>
                    <div class="col-6">
                        <label>
                        {{devolucion_venta.numero_documento}}
                        </label>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fa fa-user red2 text-110 mr-1"></i> Fecha : 
                        </label>    
                    </div>
                    <div class="col-6">
                        <label>
                        {{devolucion_venta.fecha | date:"dd/MM/yyyy"}}
                        </label>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fas fa-money-bill-alt"></i> Observación : 
                        </label>    
                    </div>
                    <div class="col-6">
                        <label>
                        {{devolucion_venta.observacion}}
                        </label>    
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col-7 alert alert-info pr-0 mb-0">
                        <label class="text-primary p-0">
                            <i class="fas fa-money-bill-alt"></i> Usuario : 
                        </label>    
                    </div>
                    <div class="col-5">
                        <label>
                        {{devolucion_venta.usuario.nombre_usuario}}
                        </label>    
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th>Nº</th>
                <th>Codigo Item</th>
                <th>Item</th>
                <th>Cantidad</th>
                <th>Descripcion</th>
                <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let detalle_devolucion of devolucion_venta.detalles_devolucion; let i=index;">
                <td>{{i + 1}}</td>
                <td>{{detalle_devolucion.producto?detalle_devolucion.producto.codigo:detalle_devolucion.combo?detalle_devolucion.combo.codigo:""}}</td>
                <td>{{detalle_devolucion.producto?detalle_devolucion.producto.nombre:detalle_devolucion.combo?detalle_devolucion.combo.nombre:detalle_devolucion.descripcion}}</td>
                <td>{{detalle_devolucion.cantidad}}</td>
                <td>{{detalle_devolucion.descripcion}}</td>
                <td></td>
            </tr>
            </tbody>
        </table>
        </div>
	</div>
</ng-template>

<ng-template #registro_devolucion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Registro de devolución de Venta</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="row">
      <div class="col-6" *ngIf="venta">
        <h5 class="text-blue-d2 mb-3">Venta</h5>
        <div class="row p-1">
          <div class="col-md-12">
              <div class="row">
                  <div class="col-6 alert alert-info pr-0 mb-0">
                      <label class="text-primary p-0">
                          <i class="fas fa-money-bill-alt"></i> Venta Transacción : 
                      </label>    
                  </div>
                  <div class="col-6">
                      <label>
                        {{venta.transaccion.nombre}}
                      </label>    
                  </div>
              </div>
              <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Número de documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                      {{venta.factura}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Fecha : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                      {{venta.fecha | date:"dd/MM/yyyy"}}
                    </label>    
                </div>
            </div>
          </div>
        </div>
        <hr>
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Codigo Item</th>
              <th>Item</th>
              <th>Cantidad</th>
              <th>Devoluciones</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detalleVenta of venta.detallesVenta; let i=index;">
              <td>{{i + 1}}</td>
              <td>{{detalleVenta.producto?detalleVenta.producto.codigo:detalleVenta.combo?detalleVenta.combo.codigo:""}}</td>
              <td>{{detalleVenta.producto?detalleVenta.producto.nombre:detalleVenta.combo?detalleVenta.combo.nombre:detalleVenta.descripcion}}</td>
              <td>{{detalleVenta.cantidad}}</td>
              <td>{{detalleVenta.devoluciones}}</td>
              <td>
                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="agregarDetalleDevolucion(detalleVenta)">
                  <i class="ace-icon fa fa-arrow-right bigger-130"></i>
                </a>
                <a *ngIf="detalleVenta.combo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="agregarDetalleDevolucionProducto(detalleVenta)">
                  <i class="ace-icon fa fa-arrow-alt-circle-right bigger-130"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6" *ngIf="!venta">
        <h5 class="text-blue-d2 mb-3">Devolución Global</h5>
        <div class="row p-1">
          <div class="col-md-12">
              <div class="row" *ngIf="usuario.empresa.usar_sucursales">
                  <div class="col-6 alert alert-info pr-0 mb-0">
                      <label class="text-primary p-0">
                          <i class="fas fa-store"></i> Sucursal : 
                      </label>    
                  </div>
                  <div class="col-6">
                    <select [disabled]="devolucion_ventas_global.id" [compareWith]="compararObjectos" class="form-control" name="sucursal" [(ngModel)]="devolucion_ventas_global.sucursal" (change)="reiniciarCliente()">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                    </select> 
                  </div>
              </div>
              <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-store"></i> {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}} 
                    </label>    
                </div>
                <div class="col-6">
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_despacho" [(ngModel)]="devolucion_ventas_global.tipoDespacho">
                        <option [ngValue]="tipoDespacho" *ngFor="let tipoDespacho of tipos_despacho">{{tipoDespacho.nombre}}</option>
                    </select>
                </div>
            </div>
              <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Cliente 
                    </label>    
                </div>
                <div class="col-6">
                    <input name="venta_razon_social" [disabled]="devolucion_ventas_global.cliente && devolucion_ventas_global.cliente.id"
                        [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerClientePedido($event)"
                        type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                        [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off"
                    />
                    <ng-template #rt let-r="result" let-t="term">
                        <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                    </ng-template>

                    <div class="col-12 col-sm-12 col-xs-12">
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="devolucion_ventas_global.cliente && devolucion_ventas_global.cliente.id && !devolucion_ventas_global.id" (click)="reiniciarCliente()">
                            <i class="fa fa-trash bigger-130 red2"></i>
                        </a>
                    </div> 
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_clientes_contactos">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Contacto Cliente : 
                    </label>    
                </div>
                <div class="col-6">
                    <select *ngIf="devolucion_ventas_global.cliente" [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="devolucion_ventas_global.contacto_cliente" (change)="buscarDatosDevolucionGlobal()">
                        <option [ngValue]="contacto_cliente" *ngFor="let contacto_cliente of devolucion_ventas_global.cliente.contactos">{{contacto_cliente.persona.nombre_completo}}</option>
                    </select>  
                </div>
            </div>
          </div>
        </div>
        <hr>
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Codigo Item</th>
              <th>Item</th>
              <th>Cantidad</th>
              <th>Devoluciones</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detalleVenta of devolucion_ventas_global.detalles_venta; let i=index;">
              <td>{{i + 1}}</td>
              <td>{{detalleVenta.producto?detalleVenta.codigo_producto:detalleVenta.combo?detalleVenta.codigo_combo:""}}</td>
              <td>{{detalleVenta.producto?detalleVenta.producto:detalleVenta.combo?detalleVenta.combo:detalleVenta.descripcion}}</td>
              <td>{{detalleVenta.cantidad}}</td>
              <td>{{detalleVenta.devoluciones}}</td>
              <td>
                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="agregarDetalleDevolucion(detalleVenta)">
                  <i class="ace-icon fa fa-arrow-right bigger-130"></i>
                </a>
                <a *ngIf="detalleVenta.combo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="agregarDetalleDevolucionProducto(detalleVenta)">
                  <i class="ace-icon fa fa-arrow-alt-circle-right bigger-130"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-md-6">
            <h5 class="text-blue-d2 mb-3">Registro de devolución</h5>
          </div>
          <div class="col-md-6">
            <button class="btn btn-primary" (click)="guardarDevolucionVenta()">
              <i class="ace-icon fa fa-save align-top bigger-125"></i>
            </button>
          </div>
        </div>
        <div class="row p-1">
          <div class="col-md-12">
              <div class="row">
                  <div class="col-6 alert alert-info pr-0 mb-0">
                      <label class="text-primary p-0">
                          <i class="fas fa-money-bill-alt"></i> Fecha : 
                      </label>    
                  </div>
                  <div class="col-6">
                      <label>
                        {{devolucion_venta.fecha | date:"dd/MM/yyyy"}}
                      </label>    
                  </div>
              </div>
              <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Observación : 
                    </label>    
                </div>
                <div class="col-6">
                  <input class="form-control" type="text" [(ngModel)]="devolucion_venta.observacion" placeholder="Ingrese la observación" />
                </div>
            </div>
          </div>
        </div>
        <hr>
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Codigo Item</th>
              <th>Item</th>
              <th>Cantidad</th>
              <th>Descripcion</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let devolucion_venta_detalle of devolucion_venta.devolucion_venta_detalles; let i=index;">
              <td>{{i + 1}}</td>
              <td>{{devolucion_venta_detalle.producto?(devolucion_venta_detalle.producto.codigo?devolucion_venta_detalle.producto.codigo:devolucion_venta_detalle.codigo_producto):devolucion_venta_detalle.combo?(devolucion_venta_detalle.combo.codigo?devolucion_venta_detalle.combo.codigo:devolucion_venta_detalle.codigo_combo):""}}</td>
              <td>{{devolucion_venta_detalle.producto?(devolucion_venta_detalle.producto.nombre?devolucion_venta_detalle.producto.nombre:devolucion_venta_detalle.producto):devolucion_venta_detalle.combo?(devolucion_venta_detalle.combo.nombre?devolucion_venta_detalle.combo.nombre:devolucion_venta_detalle.combo):""}}</td>
              <td>
                <input class="form-control" type="number" [(ngModel)]="devolucion_venta_detalle.cantidad" step="0.001" />
              </td>
              <td>
                <input class="form-control" type="text" [(ngModel)]="devolucion_venta_detalle.descripcion" />
              </td>
              <td>
                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarDetalleDevolucion(devolucion_venta_detalle)">
                  <i class="fa fa-trash bigger-130"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>
</ng-template>

<ng-template #combo_producto_devolucion let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Combo: {{devolucion_combo_producto.nombre}}</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Nº</th>
            <th>Codigo</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detalle_combo of devolucion_combo_producto.detallesCombo; let i=index;">
            <td>{{i + 1}}</td>
            <td>{{detalle_combo.producto.codigo}}</td>
            <td>{{detalle_combo.producto.nombre}}</td>
            <td>{{detalle_combo.cantidad}}</td>
            <td>
              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="agregarDetalleDevolucionComboProducto(detalle_combo,detalle_combo.producto)">
                <i class="ace-icon fas fa-check bigger-130"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
</ng-template>