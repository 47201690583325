import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';

@Component({
  selector: 'app-vista-estudiante',
  templateUrl: './vista-estudiante.component.html',
  styleUrls: ['./vista-estudiante.component.css']
})
export class VistaEstudianteComponent extends BaseComponent implements OnInit {

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  estudiante:any={persona:{genero:{},usuario:{rol:{}},cliente:{}}};
  inscripcion:any=null;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    let gestion=new Date().getFullYear();
    let inscripcion=this.estudiante.inscripciones_colegio.filter(c => c.gestion.nombre==gestion.toString());
    if(inscripcion.length>0){
      this.inscripcion=inscripcion[0];
    }
  }

  cerrarVistaEstudiante(){
    this.onConfirm.emit({confirm:false});
  }

}
