import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { AppComponent } from './app.component';
import { HabilitadoGuard} from './habilitado.guard'
import { PaginaNoEncontradaComponent } from './components/pagina-no-encontrada/pagina-no-encontrada.component';

const routes: Routes = [
  { path: '',component: LoginComponent},
  { path: 'inicio',component: InicioComponent,canActivate:[HabilitadoGuard]},
  
  { path: 'base', loadChildren: () => import(`./base/base-routing.module`).then(m => m.BaseRoutingModule) },
  { path: 'snap', loadChildren: () => import(`./snapquick/snapquick-routing.module`).then(m => m.SnapquickRoutingModule) },
  { path: 'school', loadChildren: () => import(`./school/school-routing.module`).then(m => m.SchoolRoutingModule) },
  { path: 'rrhh', loadChildren: () => import(`./rrhh/rrhh-routing.module`).then(m => m.RrhhRoutingModule) },
  { path: 'nbh', loadChildren: () => import(`./neighborhood/neighborhood-routing.module`).then(m => m.NeighborhoodRoutingModule) },
  { path: 'trans', loadChildren: () => import(`./transporte/transporte-routing.module`).then(m => m.TransporteRoutingModule) },
  { path: 'mnt', loadChildren: () => import(`./mantenimiento/mantenimiento-routing.module`).then(m => m.MantenimientoRoutingModule) },
  { path: 'gece', loadChildren: () => import(`./gece/gece-routing.module`).then(m => m.GeceRoutingModule) },
  { path: 'inst', loadChildren: () => import(`./institute/institute-routing.module`).then(m => m.InstituteRoutingModule) },
  { path: 'hotel', loadChildren: () => import(`./hotel/hotel-routing.module`).then(m => m.HotelRoutingModule) },
  { path: 'perio', loadChildren: () => import(`./periodismo/periodismo-routing.module`).then(m => m.PeriodismoRoutingModule) },
  { path: 'cont', loadChildren: () => import(`./contabilidad/contabilidad-routing.module`).then(m => m.ContabilidadRoutingModule) },
  { path: 'tor', loadChildren: () => import(`./torneo/torneo-routing.module`).then(m => m.TorneoRoutingModule) },
  { path: 'med', loadChildren: () => import(`./medicina/medicina-routing.module`).then(m => m.MedicinaRoutingModule) },

  { path: '**', component: PaginaNoEncontradaComponent,canActivate:[HabilitadoGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
