<div class="modal-header card-header">
    <div class="col-4">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Datos Venta</span>
            </div>
            <div class="col-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardar()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-8">
        <div class="row">
            <div class="col-12">
                <h4 class="widget-title" *ngIf="venta.usuario.empresa.usar_servicios">
                    <label>
                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-servicio bgc-blue-d1" type="checkbox" [(ngModel)]="detalleVenta.es_servicio" (change)="cambiarServicio(detalleVenta.es_servicio)"/>
                    </label>
                </h4>
                <span class="text-blue text-125" *ngIf="!venta.usuario.empresa.usar_servicios">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-12">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-2 col-sm-2 col-xs-2 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="input-group">
                                            <input [disabled]="inhabilitar_fecha" required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="venta.fechaTexto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</label>&nbsp;
                                        <a *ngIf="venta.cliente.id" class="text-blue mx-2px" href="javascript:void(0)" (click)="modificarLecturacion(venta.vecino)">          
                                            <i class="fa fa-faucet text-105"></i>        
                                          </a> &nbsp;
                                        <a class="card-toolbar-btn text-danger-m1" [disabled]="inhabilitar_cliente" href="javascript:void(0)" *ngIf="venta.cliente.id" (click)="reiniciarCliente()">
                                            <i class="fa fa-trash bigger-130 red2"></i>
                                        </a>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input required id="venta_razon_social" name="venta_razon_social" [disabled]="inhabilitar_cliente || venta.cliente.id" 
                                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                            type="text" class="form-control" [(ngModel)]="venta.vecino" 
                                            [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" 
                                            (keypress)="enfocarElemento($event,'venta_id_producto')" autocomplete="off"
                                            />

                                        <ng-template #rt let-r="result" let-t="term">
                                            <img [src]="rest_server+r.cliente.persona.imagen" class="mr-1" style="width: 16px">
                                            <ngb-highlight [result]="r.cliente.persona.apellido_paterno+' '+r.cliente.persona.apellido_materno+' '+r.cliente.persona.nombres" [term]="t"></ngb-highlight>
                                          </ng-template>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Acción</label>&nbsp;
                                        <a class="text-blue mx-2px" *ngIf="venta.vecino && venta.vecino.id" href="javascript:void(0)" (click)="buscarServicios()">          
                                            <i class="fa fa-sync text-105"></i>        
                                          </a> 
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select *ngIf="venta.vecino && venta.vecino.id" [compareWith]="compararObjectos" id="venta_accion" name="accion_agua" class="form-control" [(ngModel)]="venta.accion_agua" (change)="buscarServicios()">
                                            <option [ngValue]="accion_agua" *ngFor="let accion_agua of venta.vecino.acciones_agua">{{accion_agua.codigo_medidor}}-{{accion_agua.categoria.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="venta.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label >Pagado (Bs):</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" id="venta_pagado" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()"/>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-xs-2 pl-0" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Cambio (Bs):</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" disabled type="number" step="0.01" [(ngModel)]="venta.cambio"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-5">
                <div class="widget-box">
                    <div class="card bgc-danger">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">Cobros Pendientes</h5>
                            <a *ngIf="venta.cliente.id" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-white btn-a-outline-white btn-text-white" href="javascript:void(0)" (click)="generarReporteCobrosPendientes()">
                                <i class="fa fa-file-pdf text-105"></i>        
                            </a>
                        </div>
                        <div tabindex="0"  class="focus-data card-body bg-white p-0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Concepto de Pago</label>
                                        </th>
                                        <th>
                                            <label>Consumo</label>
                                        </th>
                                        <th>
                                            <label>Precio (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>Importe (Bs)</label>
                                        </th>
                                        <th>
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let servicio_pendiente of servicios_pendientes; let i=index; ">
                                        <tr  *ngIf="!servicio_pendiente.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td >
                                                {{servicio_pendiente.producto.nombre}} {{servicio_pendiente.mes?servicio_pendiente.mes.nombre:''}} {{servicio_pendiente.gestion?servicio_pendiente.gestion.nombre:''}}
                                                <br>Categoria: {{servicio_pendiente.categoria.nombre}}
                                            </td>
                                            <td >
                                                <label *ngIf="servicio_pendiente.id_lectura_medidor">Lect. Ant. : </label><i *ngIf="servicio_pendiente.id_lectura_medidor" class="eva eva-paper-plane-outline text-90 radius-round bgc-primary-l4 text-primary p-2 border-2 brc-primary-m3">{{servicio_pendiente.lectura_anterior}}</i>
                                                <label *ngIf="servicio_pendiente.id_lectura_medidor">Lect. Act. : </label><i *ngIf="servicio_pendiente.id_lectura_medidor" class="eva eva-paper-plane-outline text-90 radius-round bgc-primary-l4 text-primary p-2 border-2 brc-primary-m3">{{servicio_pendiente.lectura_actual}}</i>
                                                <label *ngIf="servicio_pendiente.id_lectura_medidor">Consumo : </label><i *ngIf="servicio_pendiente.id_lectura_medidor" class="eva eva-paper-plane-outline text-90 radius-round bgc-purple-l4 text-purple p-2 border-2 brc-purple-m3">{{servicio_pendiente.consumo_cubos}}</i>
                                            </td>
                                            <td>
                                                {{servicio_pendiente.precio_unitario}}
                                            </td>
                                            <td>
                                                {{servicio_pendiente.total}}
                                            </td>
                                            <td>
                                                <button (click)="agregarServicioPendiente(servicio_pendiente)" class="btn btn-primary btn-sm"><i class="fa fa-plus bigger-130"></i></button>&nbsp;<br>
                                                <button *ngIf="servicio_pendiente.id_lectura_medidor && servicio_pendiente.lectura_actual==0" (click)="abrirPopupConfirmacionInterpolacion(servicio_pendiente)" class="btn btn-success btn-sm"><i class="fa fa-sliders-h bigger-130"></i></button>&nbsp;<br>
                                                <button (click)="abrirPopupConfirmacionEliminacion(servicio_pendiente)" class="btn btn-danger btn-sm"><i class="fa fa-trash bigger-130"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <b>TOTALES</b>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            {{sumarServiciosPendientes()}}
                                        </td>
                                        <td >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="widget-box">
                    <div class="card bgc-success" *ngIf="venta.detallesVenta.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">Detalle de Pagos</h5>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="venta.detallesVenta.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Concepto de Pago</label>
                                        </th>
                                        <th>
                                            <label>Consumo</label>
                                        </th>
                                        <th>
                                            <label>Precio (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>Importe (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Desc. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Total (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Pagado (Bs)</label>
                                        </th>
                                        <th>
                                            <label>Saldo (Bs)</label>
                                        </th>
                                        <th>
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detalleVenta of venta.detallesVenta; let i=index;">
                                        <tr  *ngIf="!detalleVenta.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td >
                                                {{detalleVenta.producto.nombre}} {{detalleVenta.mes?detalleVenta.mes.nombre:''}} {{detalleVenta.gestion?detalleVenta.gestion.nombre:''}}
                                                <br>{{detalleVenta.categoria?'Categoria : '+detalleVenta.categoria.nombre:''}}
                                            </td>
                                            <td >
                                                <label *ngIf="detalleVenta.lectura_actual!=null">Lect. Ant. : </label><i *ngIf="detalleVenta.lectura_actual!=null" class="eva eva-paper-plane-outline text-90 radius-round bgc-primary-l4 text-primary p-2 border-2 brc-primary-m3">{{detalleVenta.lectura_anterior}}</i>
                                                <label *ngIf="detalleVenta.lectura_actual!=null">Lect. Act. : </label><i *ngIf="detalleVenta.lectura_actual!=null" class="eva eva-paper-plane-outline text-90 radius-round bgc-primary-l4 text-primary p-2 border-2 brc-primary-m3">{{detalleVenta.lectura_actual}}</i>
                                                <label *ngIf="detalleVenta.lectura_actual!=null">Consumo : </label><i *ngIf="detalleVenta.lectura_actual!=null" class="eva eva-paper-plane-outline text-90 radius-round bgc-purple-l4 text-purple p-2 border-2 brc-purple-m3">{{detalleVenta.consumo_cubos}}</i>
                                            </td>
                                            <td>
                                                {{detalleVenta.precio_unitario}}
                                            </td>
                                            <td>
                                                {{detalleVenta.importe}}
                                            </td>
                                            <td class="input-cal">
                                                <input class="form-control" name="descuento_{{detalleVenta.producto.id}}" required type="number" step="0.01" min="0" [(ngModel)]="detalleVenta.descuento" (keyup)="calcularDescuentoDetalleVenta(detalleVenta)"/>
                                            </td>
                                            <td>
                                                {{detalleVenta.total}}
                                            </td>
                                            <td class="input-cal">
                                                <input [disabled]="detalleVenta.lectura_actual || detalleVenta.es_hijo" class="form-control" name="pagado_{{detalleVenta.producto.id}}" required type="number" step="0.01" min="0.10" [(ngModel)]="detalleVenta.pagado" (keyup)="establecerPagadoDetalleVenta(detalleVenta)"/>
                                            </td>
                                            <td>
                                                {{detalleVenta.saldo}}
                                            </td>
                                            <td>
                                                <button *ngIf="!detalleVenta.es_hijo"  (click)="eliminarDetalleVenta(detalleVenta)" class="btn btn-danger btn-sm"><i class="fa fa-trash bigger-130"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <b>TOTALES</b>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            {{venta.importe}}
                                        </td>
                                        <td>
                                            {{venta.descuento}}
                                        </td>
                                        <td>
                                            {{venta.total}}
                                        </td>
                                        <td>
                                            <i class="eva eva-paper-plane-outline text-160 radius-round bgc-success-l4 text-success p-2 border-2 brc-success-m3">{{calcularPagado()}}</i>
                                        </td>
                                        <td >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>