import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  constructor(private http: HttpClient) { }

  public obtenerListaNoticias(paginator){
    return this.http.post(GlobalVariable.API_SERVER_URL+"noticias/empresa",paginator.filter,GlobalVariable.httpOptions);
  }

  public registrarEnWordpress(noticia){
    return this.http.post(GlobalVariable.API_SERVER_URL+"noticias/wordpress",noticia,GlobalVariable.httpOptions);
  }

  public enviarNoticiaWhatsapp(noticia){
    return this.http.post(GlobalVariable.API_SERVER_URL+"noticias/envio-grupos-whatsapp",noticia,GlobalVariable.httpOptions);
  }
}
