import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { PlanDeCuentasService } from '../../services/plan-de-cuentas/plan-de-cuentas.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import { LibroMayorComponent } from '../libro-mayor/libro-mayor.component';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { ProveedoresService } from 'src/app/snapquick/services/proveedores/proveedores.service';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-plan-de-cuentas',
  templateUrl: './plan-de-cuentas.component.html',
  styleUrls: ['./plan-de-cuentas.component.css']
})
export class PlanDeCuentasComponent extends BaseComponent implements OnInit {

  cuentas:any[]=[];
  cuentas_importacion:any[]=[];
  clasificaciones:any[]=[];
  tipos_cuenta:any[]=[];
  cuentas_apropiacion:any[]=[];
  cliente_busqueda:any;
  proveedor_busqueda:any;
  searching = false;
  searchFailed = false;  

  cuentas_importacion_modal:NgbModalRef;
  @ViewChild('cuentas_importacion_modal')
  private cuentas_importacion_modal_ref: TemplateRef<any>;

  registro_cuenta_modal:NgbModalRef;
  @ViewChild('registro_cuenta_modal')
  private registro_cuenta_modal_ref: TemplateRef<any>;

  configuracion_cuentas_modal:NgbModalRef;
  @ViewChild('configuracion_cuentas_modal')
  private configuracion_cuentas_modal_ref: TemplateRef<any>;

  libroMayorModal:NgbModalRef;

  configuracion_cuentas:any;

  cuenta:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
	  private toastr: ToastrService,
    private planDeCuentasService:PlanDeCuentasService,
    private clientesService:ClientesService,
    private proveedoresService:ProveedoresService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));

    this.filter={
      id_empresa:this.usuario.id_empresa,
      tipo_cuenta:GlobalVariable.Dictionary.SELECCION_TODOS,
      tipos_cuentas:[GlobalVariable.Dictionary.SELECCION_TODOS],
      clasificacion:GlobalVariable.Dictionary.SELECCION_TODOS,
      clasificaciones:[GlobalVariable.Dictionary.SELECCION_TODOS]
    }
    this.obtenerTiposCuentas();
    this.obtenerClasificaciones();
    this.column = "codigo";
		this.itemsPerPage=10;
    this.getItems();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
	}

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.planDeCuentasService.obtenerPlanCuentas(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.cuentas=dato.cuentas;
			this.blockUI.stop();
		});
  }

  obtenerTiposCuentas(){
    this.generalService.obtenerClases("TCC").subscribe((res:any)=>{
      this.filter.tipos_cuentas=this.filter.tipos_cuentas.concat(res.clases);
      this.tipos_cuenta=res.clases;
    });
  }

  obtenerClasificaciones(){
    this.planDeCuentasService.obtenerClasificacionesCuentas().subscribe((res:any)=>{
      this.filter.clasificaciones=this.filter.clasificaciones.concat(res.clasificaciones);
      this.clasificaciones=res.clasificaciones;
    });
  }

  descargarExcelEjemploImportacion(){
    Util.descargarDocumento("EJEMPLO-DATOS-PLAN-CUENTAS",GlobalVariable.SERVER_URL+"recursos/EJEMPLO-DATOS-PLAN-CUENTAS.xlsx");
  }

  subirExcelPlanCuentas(event){
    this.blockUI.start();
    let me=this;
    var files = event.target.files;
    var i, f;
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function (e) {
        
        var data = e.target.result;
  
        var workbook = XLSX.read(data, { type: 'binary' });
        var first_sheet_name = workbook.SheetNames[0];
        var row = 2, i = 0;
        var worksheet = workbook.Sheets[first_sheet_name];
        let cuentas = [];
        do {
          let cuenta:any = { clasificacion: {}, tipoCuenta: {} };
          cuenta.codigo = worksheet['A' + row] != undefined && worksheet['A' + row] != "" ? worksheet['A' + row].v.toString() : null;
          cuenta.nombre = worksheet['B' + row] != undefined && worksheet['B' + row] != "" ? worksheet['B' + row].v.toString() : null;
          cuenta.descripcion = worksheet['C' + row] != undefined && worksheet['C' + row] != "" ? worksheet['C' + row].v.toString() : null;
          cuenta.clasificacion.nombre = worksheet['D' + row] != undefined && worksheet['D' + row] != "" ? worksheet['D' + row].v.toString() : null;
          cuenta.tipoCuenta.nombre = worksheet['E' + row] != undefined && worksheet['E' + row] != "" ? worksheet['E' + row].v.toString() : null;
          cuenta.debe = worksheet['F' + row] != undefined && worksheet['F' + row] != "" ? parseInt(worksheet['F' + row].v.toString()) : null;
          cuenta.haber = worksheet['G' + row] != undefined && worksheet['G' + row] != "" ? parseInt(worksheet['G' + row].v.toString()) : null;
          cuenta.saldo = worksheet['H' + row] != undefined && worksheet['H' + row] != "" ? parseInt(worksheet['H' + row].v.toString()) : null;
          cuenta.bimonetaria = worksheet['I' + row] != undefined && worksheet['I' + row] != "" ? worksheet['I' + row].v.toString() : null;
          if (cuenta.bimonetaria != "SI") {
            cuenta.bimonetaria = 0;
          } else {
            cuenta.bimonetaria = 1;
          }
          cuenta.codigo_cliente = worksheet['J' + row] != undefined && worksheet['J' + row] != "" ? worksheet['J' + row].v.toString() : null;
          cuentas.push(cuenta);
          row++;
          i++;
        } while (worksheet['A' + row] != undefined);
          //$scope.guardarClientes(clientes);
          me.cuentas_importacion=cuentas;
          me.cuentas_importacion_modal=me.modalService.open(me.cuentas_importacion_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
          me.cuentas_importacion_modal.result.then((result) => {
            me.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
          });
          me.blockUI.stop();
        };
        reader.readAsBinaryString(f);
      }
    }

    guardarCuentas(){
      this.blockUI.start();
      this.planDeCuentasService.guardarImportacionCuentas({cuentas:this.cuentas_importacion,id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.cuentas_importacion_modal.close();
          this.getItems();
        }
      });
    }

    abrirLibroMayorCuenta(cuenta){
      this.libroMayorModal = this.modalService.open(LibroMayorComponent, {windowClass:"libro-mayor", ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"lg"});
      this.libroMayorModal.componentInstance.cuenta = cuenta;
      this.libroMayorModal.componentInstance.usuario = this.usuario;
      
      this.libroMayorModal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            //this.establecerCliente(res.cliente);
            this.libroMayorModal.close();
          }
        }else{
          this.libroMayorModal.close();
        }
        
      });
    }

    abrirPopupConfirmacionEliminacion(cuenta){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar la cuenta?";
      this.popupConfirmacion.componentInstance.data = cuenta;
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.eliminarCuenta($e.data);
        }
        this.popupConfirmacion.close();
          });
    }
    
    eliminarCuenta(cuenta){
      this.blockUI.start();
      this.planDeCuentasService.eliminarCuenta(cuenta.id).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.getItems();
        this.blockUI.stop();
      });
    }

    async crearNuevaCuenta(cuenta) {
      let codigo="";
      if(cuenta){
        let res:any=await this.planDeCuentasService.obtenerCuentaPadreUltimoCodigo(cuenta.codigo).toPromise();
        codigo=cuenta.codigo+"."+(res.cuenta_codigo+1);
      }
			this.cuenta ={
				codigo:codigo,
				id_empresa: this.usuario.id_empresa, id_usuario: this.usuario.id, eliminado: false, bimonetaria: false,
				id_cuenta_padre:cuenta?cuenta.id:null,
        cliente:null,
        proveedor:null,
        debe:0,
        haber:0,
        saldo:0
			};
      this.cliente_busqueda=null;
      this.proveedor_busqueda=null;
			this.abrirModalRegistroCuenta();
		}

		modificarCuenta(cuenta) {
      this.blockUI.start();
      this.planDeCuentasService.obtenerCuenta(cuenta).subscribe((res:any)=>{
        this.cuenta=res;
        this.cliente_busqueda = this.cuenta.cliente;
        this.proveedor_busqueda = this.cuenta.proveedor;
        this.abrirModalRegistroCuenta();
        this.blockUI.stop();
      });
		}

    abrirModalRegistroCuenta(){
      this.registro_cuenta_modal=this.modalService.open(this.registro_cuenta_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
      this.registro_cuenta_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    async guardarCuenta(){
      this.blockUI.start();
      if(await this.validarRegistroCuenta()){
        if(this.cuenta.id){
          this.planDeCuentasService.actualizarCuenta(this.cuenta).subscribe((res:any)=>{
            this.registro_cuenta_modal.close();
            this.blockUI.stop();
            this.toastr.success(res.mensaje);
          });
        }else{
          this.planDeCuentasService.guardarCuenta(this.cuenta).subscribe((res:any)=>{
            this.registro_cuenta_modal.close();
            this.blockUI.stop();
            this.toastr.success(res.mensaje);
          });
        }
      }else{
        this.blockUI.stop();
      }
    }

    async validarRegistroCuenta(){
      let res=true;
      if(this.cuenta.codigo!="" || this.cuenta.codigo!=undefined || this.cuenta.codigo!=null){
        res=res && (await this.validarCodigoCuenta(this.cuenta));
      }
      if(!this.cuenta.codigo){
        res=res && false;
        this.toastr.error("Debe especificar el codigo de la cuenta!");
      }
      if(!this.cuenta.nombre){
        res=res && false;
        this.toastr.error("Debe especificar el nombre de la cuenta!");
      }
      if(!this.cuenta.clasificacion){
        res=res && false;
        this.toastr.error("Debe especificar la clasificacion de la cuenta!");
      }
      if(!this.cuenta.tipoCuenta){
        res=res && false;
        this.toastr.error("Debe especificar el tipo de cuenta!");
      }
      return res;
    }

    async validarCodigoCuenta(cuenta) {
      let validacion:any=await this.planDeCuentasService.validarCodigoCuenta(cuenta).toPromise();
      if(validacion.tiene_error){
        this.toastr.error(validacion.mensaje);
      }
      return !validacion.tiene_error;
		}

    reiniciarCliente(){
      this.cuenta.cliente=null;
      this.cliente_busqueda=null;
    }

    buscarCliente = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
      switchMap( (term) =>  this.clientesService.buscarClienteNit(this.usuario.id_empresa,term,0)),
      catchError(() => {
      this.searchFailed = true;
      return of([]);
      })  ,
      tap(() => this.searching = false)
	  )

    formatter = (x: any) => (x.id?(x.codigo)+" "+(x.identificacion):"");

    establecerCliente(event){
      let cliente=(event.id || event.nit)?event:event.item;
      this.cliente_busqueda=cliente.razon_social;
      this.cuenta.cliente=cliente;
    }

    reiniciarProveedor(){
      this.cuenta.proveedor=null;
      this.proveedor_busqueda=null;
    }

    buscarProveedor = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
      switchMap( (term) =>  this.proveedoresService.buscarProveedoresNit(this.usuario.id_empresa,term)),
      catchError(() => {
      this.searchFailed = true;
      return of([]);
      })  ,
      tap(() => this.searching = false)
	  )

    formatterProveedor = (x: any) => (x.id?(x.codigo)+" "+(x.identificacion):"");

    establecerProveedor(event){
      let proveedor=(event.id || event.nit)?event:event.item;
      this.proveedor_busqueda=proveedor.razon_social;
      this.cuenta.proveedor=proveedor;
    }

    abrirConfiguracionCuentasModal(){
      this.obtenerConfiguracionCuentas();
      this.obtenerCuentasGenericas();
      this.configuracion_cuentas_modal=this.modalService.open(this.configuracion_cuentas_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
      this.configuracion_cuentas_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    reiniciarCuenta(configuracion_cuenta){
      configuracion_cuenta.cuenta=null;
      configuracion_cuenta.cuenta_a_buscar=null;
    }
  
    buscarCuenta = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
      switchMap( (term) =>  this.planDeCuentasService.buscarCuenta(this.usuario.id_empresa,term,"TCAPR")),
      catchError(() => {
      this.searchFailed = true;
      return of([]);
      })  ,
      tap(() => this.searching = false)
    )
  
    formatterCuenta = (x: any) => (x.id?(x.codigo)+" "+(x.nombre):"");
  
    establecerCuenta(event,configuracion_cuenta,i){
      let cuenta=(event.id)?event:event.item;
      configuracion_cuenta.cuenta_a_buscar=cuenta;
      configuracion_cuenta.cuenta=cuenta;
    }
  
    cambiarAsientoCuentaBusqueda(configuracion_cuenta,cuenta_busqueda){
      configuracion_cuenta.cuenta_busqueda=!cuenta_busqueda;
    }    

    obtenerCuentasGenericas(){
      this.blockUI.start();
      this.planDeCuentasService.obtenerCuentasGenericas(this.usuario.id_empresa).subscribe((cuentas_apropiacion:any)=>{
        this.blockUI.stop();
        this.cuentas_apropiacion=[];
        let id_cuentas_padres = cuentas_apropiacion.filter((cuenta, i, arr) => arr.findIndex(t => t.id_cuenta_padre === cuenta.id_cuenta_padre) === i);
        for(let i=0;i<id_cuentas_padres.length;i++){
          let cuentas_apropiacion_filtradas=cuentas_apropiacion.filter(e => e.id_cuenta_padre == id_cuentas_padres[i].id_cuenta_padre);
          var cuenta_apropiacion={
            nombre:id_cuentas_padres[i].cuenta_padre?id_cuentas_padres[i].cuenta_padre.nombre:"",
            cuentas:cuentas_apropiacion_filtradas,
          }
          this.cuentas_apropiacion.push(cuenta_apropiacion);
        }
      });
    }

    obtenerConfiguracionCuentas(){
      this.planDeCuentasService.obtenerConfiguracionCuentas(this.usuario.id_empresa).subscribe((configuracion_cuentas:any)=>{
        this.configuracion_cuentas={
          cuenta_ventas:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS)[0],
          cuenta_cuentas_por_cobrar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR)[0],
          cuenta_caja_dinero:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS)[0],
          cuenta_iva_debito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF)[0],
          cuenta_it_por_pagar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR)[0],
          cuenta_impuesto_a_las_transacciones:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT)[0],
          cuenta_iva_credito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF)[0]
        }
      });
    }

    guardarConfiguracionCuentas(){
      this.blockUI.start();
      this.planDeCuentasService.guardarConfiguracionCuentas(this.usuario.id_empresa,this.configuracion_cuentas).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.configuracion_cuentas_modal.close();
        this.blockUI.stop();
      });
    }
}
