<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Usuarios
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Usuarios
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
					  <div class="col-md-2 col-sm-12" *ngIf="!usuario.id_empresa"><label>Empresa </label></div>
					  <div class="col-md-4 col-sm-12" *ngIf="!usuario.id_empresa">
						  <select [compareWith]="compararObjectos" class="form-control" required name="empresa" [(ngModel)]="filter.empresa">
							  <option [ngValue]="empresa" *ngFor="let empresa of filter.empresas_filtro">{{empresa.nombre}}</option>
						  </select>
					  </div>
            <div class="col-md-2 col-sm-12" *ngIf="usuario.id_empresa"><label>Rol </label></div>
					  <div class="col-md-4 col-sm-12" *ngIf="usuario.id_empresa">
						  <select [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="filter.rol">
							  <option [ngValue]="rol" *ngFor="let rol of filter.roles">{{rol.nombre}}</option>
						  </select>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="row">
                <div class="col-12">
                    <div class="columns columns-right btn-group float-right">
                      <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                        <i class="fa fa fa-search"></i>
                      </button>
                        <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoUsuario()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                          <i class="fa fa-plus text-purple-d1"></i>
                        </button>
                        <button *ngIf="usuario.id_empresa && usuario.empresa.usar_georeferenciacion_usuarios" title="Nuevo" (click)="abrirMapaSeguimiento()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-warning btn-a-outline-warning py-1 bs-print" type="button">
                            <i class="fa fa-eye text-warning-d1"></i>
                        </button>
                        <button *ngIf="!usuario.id_empresa || usuario.empresa.usar_usuarios" title="Refrescar Navegadores" (click)="actualizarNavegadoresUsuarios({})" class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1 bs-print" type="button">
                            <i class="fa fa-sync text-success-d1"></i>
                        </button>
                        <button *ngIf="!usuario.id_empresa || usuario.empresa.usar_usuarios" title="Cerrar Sesiones" (click)="cerrarSesionesUsuarios({},usuario.id_empresa?usuario.empresa:{})" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                            <i class="fa fa-sign-out-alt text-danger-d1"></i>
                        </button>
                        <div class="keep-open btn-group show" title="Columns">
                          <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                            <i class="fa fa-wrench text-blue-d1"></i>
                            <span class="caret"></span>
                          </button>
                  
                          <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                            <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                              <div class="card bgc-primary brc-primary radius-0">
                                <div class="card-header">
                                <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                                </div>
                      
                                <div class="card-body p-0 bg-white">
                                <table class="table table-striped table-hover mb-0">
                                  <tbody>
                                    <tr>
                                      <td class="text-dark-m2">
                                        Descarga reporte
                                      </td>
                                      <td class="text-dark-m2">
                                        <a (click)="descargarExcelUsuario()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                                      </td>
                                      <td>
                                        <a (click)="descargarPdfUsuarios()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </div>
                              </div>
                              </div>
                          </div>
                          <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                      </div>
                      </div>
                </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="usuarios.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().georeferenciacion_automatico.show && usuario.empresa.usar_georeferenciacion_usuarios" class="center">
                    <a href="javascript:void(0)" (click)="paginator.sortColumn('georeferenciacion_automatico')">¿Geo Automático?
                        <span id="georeferenciacion_automatico" class="sort fa fa-sort icon-only"></span>
                    </a>
                    <label>
                        <input (change)="actualizarAtributoUsuario(null,'georeferenciacion_automatico',tabla_usuario_general.georeferenciacion_automatico)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_usuario_general.georeferenciacion_automatico"/>
                    </label>
				</th>
				<th *ngIf="configuracion_pagina.getConfiguration().nombre.show" class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('nombres')">Nombre <span id="nombre_usuario" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().foto.show" class="hidden-480">Foto</th>
                <th *ngIf="!usuario.id_empresa" class="hidden-480">Proveedor</th>
                <th *ngIf="configuracion_pagina.getConfiguration().nombre_usuario.show" class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('nombre_usuario')">Nombre de Usuario <span id="nombre_usuario" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().rol.show" class="center">ROL </th>
                <th *ngIf="configuracion_pagina.getConfiguration().empresa.show" class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('empresa')">Empresa <span id="empresa" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show" class="center">Sucursal </th>
                <th *ngIf="configuracion_pagina.getConfiguration().activo.show">Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr #usuarios *ngFor="let usuario_item of usuarios; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().georeferenciacion_automatico.show && usuario.empresa.usar_georeferenciacion_usuarios">
                    <label>
                        <input (change)="actualizarAtributoUsuario(usuario_item,'georeferenciacion_automatico',usuario_itemoreferenciacion_automatico)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.georeferenciacion_automatico"/>
                    </label>
                </td>
				<td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                    {{usuario_item.nombre_completo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().foto.show"><img src="{{usuario_item.imagen?(rest_server+usuario_item.imagen):''}}" width="50"></td>
                <td *ngIf="!usuario.id_empresa">{{usuario_item.proveedor}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombre_usuario.show">{{usuario_item.nombre_usuario}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().rol.show">{{usuario_item.roles}}</td>

                <td *ngIf="configuracion_pagina.getConfiguration().empresa.show">
                    {{usuario_item.empresa?usuario_item.empresa:''}}
                </td>
                <td style="color:red;" *ngIf="!usuario_item.sucursales && configuracion_pagina.getConfiguration().sucursal.show">
                    No Asignado
                </td>
                <td *ngIf="usuario_item.sucursales && configuracion_pagina.getConfiguration().sucursal.show">
                    {{usuario_item.sucursales}}
                </td>
                <td *ngIf="usuario_item.activo==1 && configuracion_pagina.getConfiguration().activo.show">
                    <span class="badge badge-sm badge-success">Sí</span>
                </td>
                <td *ngIf="usuario_item.activo==0 && configuracion_pagina.getConfiguration().activo.show">
                    <span class="badge badge-sm badge-danger">No</span>
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a title="Actualizar navegador usuario" *ngIf="!usuario.id_empresa || usuario.empresa.usar_usuarios" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="actualizarNavegadoresUsuarios(usuario_item)">
                        <i class="ace-icon fa fa-sync bigger-130"></i>
                    </a>
                    <a title="Iniciar sesion usuario" *ngIf="!usuario.id_empresa && usuario_item.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="iniciarSesionUsuario(usuario_item)">
                      <i class="ace-icon fa fa-sign-in-alt bigger-130"></i>
                    </a>
                    <a title="Cerrar sesion usuario" *ngIf="!usuario.id_empresa || usuario.empresa.usar_usuarios" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="cerrarSesionesUsuarios(usuario_item,{})">
                        <i class="ace-icon fa fa-sign-out-alt bigger-130"></i>
                    </a>
                    <a title="Modificar" *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarUsuario(usuario_item)">          
                        <i class="fa fa-pencil-alt text-105"></i>        
                    </a>        
                    <a title="Precios de productos por usuario" *ngIf="usuario.id_empresa && usuario.empresa.usar_precios_productos_usuarios" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupPreciosProductos(usuario_item)">
                        <i class="ace-icon fa fa-credit-card bigger-130"></i>
                    </a>
                    <a title="Enviar información por correo" *ngIf="usuario_item.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvio(usuario_item)">
                        <i class="ace-icon fa fa-envelope bigger-130"></i>
                    </a>
                    <a title="Permisos avanzados" *ngIf="usuario.id_empresa && usuario.empresa.usar_permisos_avanzados_usuarios" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verListaConfiguracionAplicaciones(usuario_item)">
                      <i class="fas fa-user-cog"></i>
                    </a>
                    <a title="Restringir productos usuario" *ngIf="usuario.id_empresa && usuario.empresa.usar_restriccion_productos_venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="verListaRestriccionProductosVenta(usuario_item)">
                        <i class="fas fa-user-time"></i>
                    </a>

                    <a title="Eliminar usuario" *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(usuario_item)">
                        <i class="ace-icon fa fa-trash bigger-130"></i>
                    </a>
                    
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="usuarios.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #mapausuario let-modal>
    <div class="modal-header card-header">
        <div class="col-4">
            <span class="text-blue text-125">Seguimiento</span>
        </div>
        <div class="col-4 pr-0">
            <div class="row">
                <div class="col-4">
                    <label for="fefin">Fecha </label>&nbsp;
                </div>
                <div class="col-8">
                    <div class="input-group">
                        <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="fecha_seguimiento" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-4">
            <button  type="button" class="btn btn-primary" (click)="buscarPosiciones()">
                <i class="fa fa-search align-top bigger-125"></i>
            </button>&nbsp;
            <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarMapaSeguimiento()" >
                <i class="fa fa-times red2 align-top bigger-125"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="widget-body">
            <div class="row">
                <div class="col-10">
                    <google-map #mapa 
                        width="100%"
                        height="450px"
                        [center]="center"
                        [zoom]="zoom"
                        [options]="options">
                        <map-marker
                            #markerElem
                            *ngFor="let marker of markers"
                            [position]="marker.position"
                            [label]="marker.label"
                            [title]="marker.title"
                            [options]="marker.options"
                            (mapClick)="clickMarcador(marker)"
                            >
                        </map-marker>
                    </google-map>
                </div>
                <div class="col-2" id="gallery">
                    <div #imagenes_posicion class="row" *ngFor="let dato of imagenes_posiciones">
                        <div class="col-11">
                            <a href="#" class="show-lightbox">
                                <img alt="sin imagen" src="{{rest_server+dato.imagen}}" width="100" height="100" class="d-zoom-3" data-size="1000x700" />
                            </a>
                        </div>
                        <div class="col-1">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionImagen({dato:dato,imagenes:imagenes_posiciones})">
                                <i class="ace-icon fa fa-trash bigger-130"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #usuario_precios_productos let-modal>
  <div class="modal-header card-header">
    <div class="col-8">
        <span class="text-blue text-125">Precios Productos por Usuario : {{usuario_item.nombre_completo}}</span>
    </div>
    <div class="col-4">
        <button  type="button" class="btn btn-primary" (click)="guardarPreciosProductosUsuario()">
            <i class="fa fa-save align-top bigger-125"></i>
        </button>&nbsp;
        <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="modal.close('')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
  <div class="  form-row">
    <div class="col-12 col-form-label text-sm-center text-grey">
      <div class="card bgc-primary brc-primary radius-0">
        <div class="card-header">
          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Conceptos de precios</h5>
        </div>

        <div class="card-body p-0 bg-white">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">Sucursal</th>
                <th>Producto</th>
                <th>Concepto</th>
                <th>Precio Unitario</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                  <select [compareWith]="compararObjectos" class="form-control" name="id_sucursal" [(ngModel)]="usuario_item.producto_precio.sucursal">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales_precios">{{sucursal.nombre}}</option>
                  </select>
                </td>
                <td>
                  <input autocomplete="off" [disabled]="(usuario_item.producto_precio.producto && usuario_item.producto_precio.producto.id)"
                   [(ngModel)]="producto_busqueda" [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                  [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                  type="text" class="form-control"/>

                  <ng-template #rtp let-r="result" let-t="term">
                      <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                      <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                  </ng-template>

                  <a href="javascript:void(0)" *ngIf="usuario_item.producto_precio.producto && usuario_item.producto_precio.producto.id" (click)="iniciarProductoPrecio()" class="card-toolbar-btn text-danger-m1">
                      <i class="ace-icon fa fa-trash bigger-130"></i>
                  </a>
                </td>
                <td>
                  <select [compareWith]="compararObjectos" class="form-control" required name="id_concepto" [(ngModel)]="usuario_item.producto_precio.concepto">
                    <option [ngValue]="precios_producto" *ngFor="let precios_producto of tipoProductoPrecio.clases">{{precios_producto.nombre}}</option>
                  </select>
                  <span *ngIf="usuario.empresa.usar_precios_productos" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_PRECIOS,'obtenerConceptosPreciosProductos')"></span> &nbsp;
                </td>
                <td>
                  <input name="id_precio" id="id_precio" class="form-control text-center" type="number" [(ngModel)]="usuario_item.producto_precio.precio_unitario" step="0.001"
                  min="0">
                </td>
                <td>
                  <button *ngIf="usuario.empresa.usar_precios_productos" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(usuario_item.producto_precio)"
                      class="btn btn-success btn-sm">
                    <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                  </button>
                  <button *ngIf="usuario.empresa.usar_precios_productos_sucursal" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(usuario_item.producto_precio)"
                      class="btn btn-success btn-sm">
                    <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 col-form-label text-sm-center text-grey">
      <div class="card bgc-primary brc-primary radius-0">
        <div class="card-header">
          <h5 class="card-title text-white font-light">
            <i class="fa fa-table mr-2px"></i> Precios 
            <a title="Importar precios" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionsincronizacion()">
              <i class="ace-icon fa fa-sync bigger-130"></i>
            </a>
          </h5>
        </div>

        <div class="card-body p-0 bg-white">
          <table class="table table-striped table-hover mb-0">
            <thead>
              <tr>
                <th>
                  Nº
                </th>
                <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                  Sucursal
                </th>
                <th>
                  Producto
                </th>
                <th>
                  Concepto
                </th>
                <th>
                  Precio Unitario
                </th>
                <th>
                  Por Defecto
                </th>
                <th>
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let producto_precio of usuario_item.productos_precios; let i=index;">
                <tr *ngIf="!producto_precio.eliminado">
                  <td>
                    {{i+1}}
                  </td>
                  <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                    {{producto_precio.sucursal.nombre}}
                  </td>
                  <td>
                    {{producto_precio.producto.nombre}}
                  </td>
                  <td>
                    {{producto_precio.concepto?producto_precio.concepto.nombre:''}}
                  </td>
                  <td>
                    <input class="form-control text-center" type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001" min="0">
                  </td>
                  <td>
                    <label>
                      <input (change)="establecerPrecioPorDefecto(producto_precio)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_precio.por_defecto"/>
                    </label>
                  </td>
                  <td>
                    <a class="text-danger-m1 mx-2px" href="javascript:void(0)" (click)="eliminarDetalleProductosPrecio(producto_precio)">
                      <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #usuario_configuracion_aplicaciones let-modal>
  <div class="modal-header card-header">
    <div class="col-8">
        <span class="text-blue text-125">Configuracion Aplicaciones por Usuario : {{usuario_item.persona.nombre_completo}}</span>
    </div>
    <div class="col-4">
        <button type="button" class="btn btn-primary btn-danger" (click)="modal.close('')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
  <div class="row">
    <div class="col-12">
      <div class="card bcard bgc-transparent shadow-none">
        <div class="card-body tabs-left p-0">
          <ul class="nav nav-tabs align-self-start" role="tablist">
            <li class="nav-item brc-success shadow-sm" *ngFor="let aplicacion_usuario of usuario_item.aplicacionesUsuario; let i=index;">
              <a (click)="establecerAplicacion(aplicacion_usuario.aplicacion)" [ngClass]="{'active':i==0}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                <i class="fa {{aplicacion_usuario.aplicacion.atributo_clase}} red2 align-top bigger-125"></i> {{aplicacion_usuario.aplicacion.titulo}}
              </a>
            </li>
          </ul>

          <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
            <div [ngClass]="{'active':1==1}" class="tab-pane fade show text-95" role="tabpanel" aria-labelledby="home14-tab-btn">
              <div class="row">
                <div class="col-12">
                  <app-configuracion-aplicacion-ventas *ngIf="aplicacion_permisos.codigo==global_variable.Dictionary.CODIGO_APP_VENTAS"
                      [usuario]="usuario"
                      [configuracion_pagina]="aplicacion_permisos.configuracion_aplicacion">
                  </app-configuracion-aplicacion-ventas>
                  <app-configuracion-aplicacion-clientes *ngIf="aplicacion_permisos.codigo==global_variable.Dictionary.CODIGO_APP_CLIENTES"
                      [usuario]="usuario"
                      [configuracion_pagina]="aplicacion_permisos.configuracion_aplicacion">
                  </app-configuracion-aplicacion-clientes>                  
                  <app-configuracion-aplicacion-inventarios *ngIf="aplicacion_permisos.codigo==global_variable.Dictionary.CODIGO_APP_INVENTARIOS"
                      [usuario]="usuario"
                      [configuracion_pagina]="aplicacion_permisos.configuracion_aplicacion">
                  </app-configuracion-aplicacion-inventarios>
                  <app-configuracion-aplicacion-pedidos *ngIf="aplicacion_permisos.codigo==global_variable.Dictionary.CODIGO_APP_PEDIDOS"
                      [usuario]="usuario"
                      [configuracion_pagina]="aplicacion_permisos.configuracion_aplicacion">
                  </app-configuracion-aplicacion-pedidos>
                  <app-configuracion-aplicacion-movimiento-inventario *ngIf="aplicacion_permisos.codigo==global_variable.Dictionary.CODIGO_APP_MOVIMIENTOS_INVENTARIO"
                      [usuario]="usuario"
                      [configuracion_pagina]="aplicacion_permisos.configuracion_aplicacion">
                  </app-configuracion-aplicacion-movimiento-inventario>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.card -->
    </div>
  </div>
</div>
</ng-template>

<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>