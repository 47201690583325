import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';

import html2canvas from 'html2canvas';
import { VentasService } from '../../services/ventas/ventas.service';
import { ToastrService } from 'ngx-toastr';
import { ComprasService } from '../../../snapquick/services/compras/compras.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../general/general.service';
import { PersistenciaService } from '../persistencia/persistencia.service';
import { PedidosService } from 'src/app/snapquick/services/pedidos/pedidos.service';
import { HttpClient } from '@angular/common/http';
//import jsPDF from 'jspdf';
declare var require: any;
const { jsPDF } = require('jspdf');
require('jspdf-autotable');



declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any

@Injectable({
  providedIn: 'root'
})
export class PdfService extends BaseComponent {

	constructor(public ventasService:VentasService,
		public comprasService:ComprasService,
		public usuarioService:UsuarioService,
		public toastr: ToastrService,
		public generalService:GeneralService,
		public persistenciaService:PersistenciaService,
		public modalService: NgbModal,
		public pedidosService:PedidosService,
		private http: HttpClient) {
		super(persistenciaService,modalService,generalService,usuarioService);
		this.init();
		this.obtenerTextosEmpresa();
	}

  async imprimirReciboPagoVenta(pago_venta_referencia,usuario){
	let datos:any=await this.ventasService.obtenerPagoVenta(pago_venta_referencia.id).toPromise();
	var pago_venta=datos.pago_venta;
	pago_venta.importe_literal=datos.importe_literal;
	pago_venta.devoluciones=datos.total_devoluciones;
	let conf:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,usuario.empresa.usar_permisos_avanzados_usuarios?usuario.id:0).toPromise();
	let configuracion_aplicacion_ventas=conf;
	let configuracionImpresion=configuracion_aplicacion_ventas.datos.opciones_permisos.impresion;
	var papel;
	if (configuracionImpresion.papel_recibo_cobro == GlobalVariable.Dictionary.PAPEL_ROLLO) {
		return this.imprimirReciboRollo(pago_venta,usuario);
	}else{
		if (configuracionImpresion.papel_recibo_cobro == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			papel = [612, 936];
		} else if (configuracionImpresion.papel_recibo_cobro == GlobalVariable.Dictionary.PAPEL_CARTA) {
			papel = [612, 792];
		} else if (configuracionImpresion.papel_recibo_cobro == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			papel = [612, 792];
		} else if (configuracionImpresion.papel_recibo_cobro == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			papel = [612, 936];
		}
		return this.imprimirReciboCartaOficio(papel,pago_venta,usuario,configuracionImpresion);
	}
  }

  imprimirReciboPagoCompra(pago_compra_referencia,usuario){
	this.comprasService.obtenerPagoCompra(pago_compra_referencia.id).subscribe((datos:any) => {
		var pago_compra=datos.pago_compra;
		pago_compra.importe_literal=datos.importe_literal;
		this.usuarioService.obtenerConfiguracionPaginaPorCodigo(usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_COMPRAS,usuario.empresa.usar_permisos_avanzados_usuarios?usuario.id:0).subscribe((conf:any)=>{
			let configuracion_aplicacion_ventas=conf;
			let configuracionImpresion=configuracion_aplicacion_ventas.datos.opciones_permisos.impresion;
			var papel;
			if (configuracionImpresion.papel_recibo_pago == GlobalVariable.Dictionary.PAPEL_ROLLO) {
				this.imprimirReciboRollo(pago_compra,usuario);
			}else{
				var papel;
				if (configuracionImpresion.papel_recibo_pago == GlobalVariable.Dictionary.PAPEL_OFICIO) {
					papel = [612, 936];
				} else if (configuracionImpresion.papel_recibo_pago == GlobalVariable.Dictionary.PAPEL_CARTA) {
					papel = [612, 792];
				} else if (configuracionImpresion.papel_recibo_pago == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
					papel = [612, 792];
				} else if (configuracionImpresion.papel_recibo_pago == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
					papel = [612, 936];
				}
				this.imprimirReciboCartaOficio(papel,pago_compra,usuario,configuracionImpresion);
			}
		});
	});
  }

	async imprimirReciboCartaOficio(papel,pago,usuario,configuracionImpresion) {
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		let es_venta=pago.venta?true:false;
		var titulo="RECIBO DE "+(es_venta?"INGRESO":"EGRESO"),fecha_reporte=new Date();
		let sucursal=es_venta?pago.venta.sucursal:pago.compra.sucursal;
		let texto=es_venta?(pago.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION?"FACTURA":"NOTA DE VENTA"):"NOTA DE COMPRA";
		let entidad=es_venta?pago.venta:pago.compra;
		doc.font('Helvetica', 8);
		
		var y = 165;
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,1,1,usuario,
			usuario.empresa.imagen,usuario.empresa.nombre,sucursal.nombre,sucursal.direccion,
			(sucursal.telefono1!=null?sucursal.telefono1:"")+
		(sucursal.telefono2!=null?"-"+sucursal.telefono2:"")+
		(sucursal.telefono3!=null?"-"+sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_recibo_cobro?configuracionImpresion.papel_recibo_cobro:configuracionImpresion.papel_recibo_pago,
		fecha_reporte,configuracionImpresion);
		this.escribirCabeceraReciboCartaOficio(doc,pago,usuario);
		doc.text("CONCEPTO", 55, 145);
		doc.text(pago.observacion?"OBSERVACIÓN : "+pago.observacion:"", 200, 145);
		doc.font('Helvetica', 8);

		doc.text("PAGO A CUENTA DE "+texto+" Nro: "+entidad.factura, 100, y);
		doc.text("TOTAL "+texto+": Bs "+entidad.total, 100, y+15);
		if(usuario.empresa.usar_devoluciones_ventas){
			y=y+15;
			doc.text("DEVOLUCIONES: Bs "+pago.devoluciones, 100, y+15);
		}
		doc.text("A CUENTA ANTERIOR: Bs "+(pago.a_cuenta_anterior), 100, y+30);
		doc.text("PAGO A CUENTA: Bs "+(pago.monto_pagado), 100, y+45);
		doc.text("SALDO: Bs "+(pago.saldo_anterior-pago.monto_pagado), 100, y+60);

		doc.text("TOTAL: Bs "+pago.monto_pagado, 55, y+85);

		doc.text("SON: " + pago.importe_literal, 55, y+100);
		doc.x=0;
		
		doc.moveDown(0.9);
		doc.moveDown(0.9);
		doc.moveDown(0.9);
		doc.moveDown(0.9);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);

		doc.text("-------------------------                       -------------------------", { align: 'center' });
		doc.text("ENTREGUE CONFORME            RECIBI CONFORME", { align: 'center' });

		doc.end();
		return await new Promise(function (resolve, reject) {
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
				setTimeout(function () {
					w.print();
				}, 500);
				resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
	}

	async imprimirReciboRollo(pago,usuario){
		var doc = new PDFDocument({ size: [227, 353], margin: 10 });
		var stream = doc.pipe(blobStream());
		let es_venta=pago.venta?true:false;
		let titulo="RECIBO DE "+(es_venta?"INGRESO":"EGRESO");
		let sucursal=es_venta?pago.venta.sucursal:pago.compra.sucursal;
		let texto=es_venta?"VENTA":"COMPRA";
		let entidad=es_venta?pago.venta:pago.compra;
		let entidad_clipro=es_venta?pago.venta.cliente:pago.compra.proveedor;
		doc.moveDown(2);
		doc.font('Helvetica-Bold', 8);
		doc.text(usuario.empresa.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica', 7);
		doc.text(sucursal.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text(sucursal.direccion.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		var telefono = (sucursal.telefono1 != null ? sucursal.telefono1 : "") +
			(sucursal.telefono2 != null ? "-" + sucursal.telefono2 : "") +
			(sucursal.telefono3 != null ? "-" + sucursal.telefono3 : "");
		doc.text("TELF.: " + telefono, { align: 'center' });
		doc.moveDown(0.4);
		doc.text(sucursal.departamento.nombre.toUpperCase()+" - BOLIVIA", { align: 'center' });
		doc.moveDown(0.5);
		doc.font('Helvetica-Bold', 8);
		doc.text(titulo , { align: 'center' });
		doc.font('Helvetica', 7);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.text(pago.numero_documento, { align: 'center' });
		//doc.text("NIT: "+$scope.usuario.empresa.nit,{align:'center'});

		//doc.text("FACTURA No: "+venta.factura,{align:'center'});
		doc.moveDown(0.4);
		//doc.text("AUTORIZACIÓN No: "+venta.autorizacion,{align:'center'});
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		//doc.text(venta.actividad.nombre,{align:'center'});
		doc.moveDown(0.6);
		var date = new Date();
		doc.text("FECHA : " + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear(), { align: 'left' });
		doc.moveDown(0.4);
		doc.text("He recibido de : " + entidad_clipro.razon_social, { align: 'left' });
		doc.moveDown(0.4);
		doc.text("---------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.2);
		doc.text("       CONCEPTO                                   ", { align: 'left' });
		doc.moveDown(0.2);
		doc.text("---------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		entidad.fecha = new Date(entidad.fecha);
		doc.text("Fecha: " + entidad.fecha.getDate() + "/" + (entidad.fecha.getMonth() + 1) + "/" + entidad.fecha.getFullYear(), 15, 210);
		var textoFact = "Nro. Doc. " + (pago.venta?entidad.factura:pago.numero_documento);
		doc.text(textoFact, 105, 210, { width: 100 });
		doc.text("Saldo Bs " + (pago.saldo_anterior-pago.monto_pagado) + ".-", 105, 220, { width: 100 });
		doc.text("Bs " + pago.monto_pagado + ".-", 170, 210, { width: 100 });

		doc.text("--------------", 10, 230, { align: 'right' });
		//oc.text("--------------------",{align:'right'});
		doc.moveDown(0.3);
		doc.text("TOTAL Bs.              " + pago.monto_pagado.toFixed(2), { align: 'right' });
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.text("SON: " + pago.importe_literal, { align: 'left' });
		doc.moveDown(0.6);

		doc.moveDown(0.4);

		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);

		doc.text("-------------------------                       -------------------------", { align: 'center' });
		doc.text("ENTREGUE CONFORME            RECIBI CONFORME", { align: 'center' });
		doc.end();

		return await new Promise(function (resolve, reject) {
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
				setTimeout(function () {
					w.print();
				}, 500);
				resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
	}

	escribirCabeceraReciboCartaOficio(doc,pago,usuario){
		let texto_nombre=pago.venta?pago.venta.cliente.identificacion:pago.compra.proveedor.razon_social;
		let nit_ci=pago.venta?pago.venta.nit_ci:pago.compra.proveedor.nit;
		let razon_social=pago.venta?pago.venta.razon_social:pago.compra.proveedor.razon_social;
		pago.fecha=new Date(pago.fecha);
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,43,5).stroke();
		
		doc.text("Nº : ",355,55);
		doc.text("Fecha : ",410,55);
		doc.text("Nit/Ci : ",500,55);
		doc.text("Cliente : ",355,65);
		if(pago.venta && pago.venta.pedido){
			doc.text("Nº Pedido: ",355,85);
		}
		if(usuario.empresa.usar_clientes_contactos && pago.venta && pago.venta.contacto_cliente){
			doc.text("C. Cliente: ",420,85);
		}
		doc.font('Helvetica', 8);
		doc.text(pago.numero_documento,375,55);
		doc.text(pago.fecha.getDate()+"/"+(pago.fecha.getMonth()+1)+"/"+pago.fecha.getFullYear(),450,55);
		doc.text(nit_ci,530,55);
		doc.text(texto_nombre,387,65,{width:140});
		if(pago.venta && pago.venta.pedido){
			doc.text(pago.venta.pedido.numero_pedido,400,85);
		}
		if(pago.venta && usuario.empresa.usar_clientes_contactos && pago.venta.contacto_cliente){
			doc.text(pago.venta.contacto_cliente.persona.nombre_completo,460,85);
		}
	}

	dibujarDisenioLogoIzquierda(logo_empresa,doc,razon_social_empresa,nombre_sucursal,direccion_sucursal,telefonos_sucursal,ciudad_sucursal){
		if (logo_empresa.length > 100) { doc.image(logo_empresa,60,40, { width: 50, height: 50,align:'left' }); }
		doc.font('Helvetica-Bold', 8);
		doc.text(razon_social_empresa.toUpperCase(), 120, 50);
		doc.font('Helvetica', 7);
		doc.text(nombre_sucursal?nombre_sucursal.toUpperCase():"", 120, 60);
		doc.text(direccion_sucursal?direccion_sucursal.toUpperCase():"", 120, 70);
		doc.text("TELF.: " + (telefonos_sucursal?telefonos_sucursal:""), 120, 80);
		doc.text(ciudad_sucursal?ciudad_sucursal:"", 120, 90);			
	}



  dibujarCabeceraGeneralReporteCartaOficio(doc, titulo_reporte,pagina,totalPaginas,usuario,
		logo_empresa,razon_social_empresa,nombre_sucursal,direccion_sucursal,
	telefonos_sucursal,ciudad_sucursal,tamano_papel,fecha_reporte,configuracion_impresion){
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			doc.font('Helvetica-Bold',10);
			doc.text(titulo_reporte,20,70,{width:80,align:"center"});
			if (logo_empresa.length > 100) { doc.image(logo_empresa,20,10, { width: 50, height: 50,align:'center' }); }
			doc.font('Helvetica-Bold', 8);
			doc.text(razon_social_empresa.toUpperCase(), 80, 10);
			doc.font('Helvetica', 7);
			doc.text(nombre_sucursal?nombre_sucursal.toUpperCase():"", 80, 20);
			doc.text(direccion_sucursal?direccion_sucursal.toUpperCase():"", 80, 30);
			doc.text("TELF.: " + (telefonos_sucursal?telefonos_sucursal:""), 80, 40);
			doc.text(ciudad_sucursal?ciudad_sucursal:"", 80, 50);	
		}else{
			doc.font('Helvetica-Bold',16);
			doc.text(titulo_reporte,350,35,{width:250,align:"center"});
			if(configuracion_impresion.disenio_logo_impresion){
				if(configuracion_impresion.disenio_logo_impresion==GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_IZQUIERDA){
					this.dibujarDisenioLogoIzquierda(logo_empresa,doc,razon_social_empresa,nombre_sucursal,direccion_sucursal,telefonos_sucursal,ciudad_sucursal);
				}else if(configuracion_impresion.disenio_logo_impresion==GlobalVariable.Dictionary.DISENIO_LOGO_IMPRESION_ARRIBA){
					if (logo_empresa.length > 100) { doc.image(logo_empresa,110,35, { width: 150, height: 30,align:'center' }); }
					doc.font('Helvetica-Bold', 8);
					doc.text(razon_social_empresa.toUpperCase(), 60, 70, { width: 250, height: 30,align:'center' });
					doc.font('Helvetica', 7);
					doc.text((nombre_sucursal?nombre_sucursal.toUpperCase():"")+"  "+(direccion_sucursal?direccion_sucursal.toUpperCase():""), 60, 80, { width: 250, height: 30,align:'center' });
					doc.text(("TELF.: " + (telefonos_sucursal?telefonos_sucursal:"")+"  "+(ciudad_sucursal?ciudad_sucursal:"")), 60, 90, { width: 250, height: 30,align:'center' });
				}else{
		
				}
			}else{
				this.dibujarDisenioLogoIzquierda(logo_empresa,doc,razon_social_empresa,nombre_sucursal,direccion_sucursal,telefonos_sucursal,ciudad_sucursal);
			}
	
			doc.moveTo(0,100);
			doc.lineTo(610,100).stroke();
			doc.font('Helvetica-Bold', 8);
	
			doc.font('Helvetica', 7);
		}
		
		var min = fecha_reporte.getMinutes()<10?"0"+fecha_reporte.getMinutes():fecha_reporte.getMinutes();
		var y=0;
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			y=380;
		}
		doc.text("PÁGINA "+pagina+" DE "+totalPaginas, 0, y,{width:tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA?306:612,align:'center'});
		if(configuracion_impresion.usuario || configuracion_impresion.imprimir_usuario){
			doc.text("Usuario Impresor : " + usuario.persona.nombre_completo, 0,y-7,{width:tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA?295:590,align:'right'});
		}
		doc.text("Fecha : " + fecha_reporte.getDate() + "/" + (fecha_reporte.getMonth() + 1) + "/" + fecha_reporte.getFullYear() + " - Hora : " + fecha_reporte.getHours() + ":" + min + "  ", 0,y,{width:tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA?295:590,align:'right'});
	}

	dibujarCabeceraGeneralReporteCartaOficioV2(doc, titulo_reporte,pagina,totalPaginas,usuario,
		logo_empresa,razon_social_empresa,nombre_sucursal,direccion_sucursal,
	telefonos_sucursal,ciudad_sucursal,configuracion_papel,fecha_reporte,configuracion_impresion){
		let ancho=(configuracion_papel.layout && configuracion_papel.layout=="landscape")?configuracion_papel.size[1]:configuracion_papel.size[0];
		let alto=(configuracion_papel.layout && configuracion_papel.layout=="landscape")?configuracion_papel.size[0]:configuracion_papel.size[1];
		if (logo_empresa.length > 100) { doc.image(logo_empresa,60,40, { width: 50, height: 50,align:'left' }); }
		doc.font('Helvetica-Bold',16);
		doc.text(titulo_reporte,350,35,{width:250,align:"center"});
		doc.font('Helvetica-Bold', 8);
		doc.text(razon_social_empresa.toUpperCase(), 120, 50);
		doc.font('Helvetica', 7);
		doc.text(nombre_sucursal?nombre_sucursal.toUpperCase():"", 120, 60);
		doc.text(direccion_sucursal?direccion_sucursal.toUpperCase():"", 120, 70);
		doc.text("TELF.: " + (telefonos_sucursal?telefonos_sucursal:""), 120, 80);
		doc.text(ciudad_sucursal?ciudad_sucursal:"", 120, 90);			

		doc.moveTo(0,100);
		doc.lineTo(ancho,100).stroke();
		doc.font('Helvetica-Bold', 8);

		doc.font('Helvetica', 7);
		var min = fecha_reporte.getMinutes()<10?"0"+fecha_reporte.getMinutes():fecha_reporte.getMinutes();
		doc.text("PÁGINA "+pagina+" DE "+totalPaginas, 0, alto-30,{width:ancho,align:'center'});
		if(configuracion_impresion.usuario || configuracion_impresion.imprimir_usuario){
			doc.text("Usuario Impresor : " + usuario.persona.nombre_completo, 0,alto-37,{width:ancho-30,align:'right'});
		}
		doc.text("Fecha : " + fecha_reporte.getDate() + "/" + (fecha_reporte.getMonth() + 1) + "/" + fecha_reporte.getFullYear() + " - Hora : " + fecha_reporte.getHours() + ":" + min + "  ", 0,alto-30,{width:ancho-30,align:'right'});
	}

	imprimirReciboConsumoAgua(venta,configuracionImpresion, esAccionGuardar, usuario,tipo_textos){
		var papel, doc, stream;
		if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto;
			if (venta.detallesVenta.length <= 2) {
				alto = 400;
			} else {
				alto = 400 + (20 * (venta.detallesVenta.length - 2))
			}
			papel = [227, alto];

			if (esAccionGuardar && !configuracionImpresion.imprimir_al_guardar) {
				
			} else {
				doc = new PDFDocument({ size: papel, margins: { top: 0, bottom: 0, left: 20, right: 20 } });
				stream = doc.pipe(blobStream());
				//this.imprimirProformaRollo(venta, papel, doc, stream, usuario);
			}

			if (doc && stream) {
				doc.end();
				return new Promise(function (resolve, reject) {
					stream.on('finish', function () {
						var fileURL = stream.toBlobURL('application/pdf');
						var w = window.open(fileURL, '_blank', 'location=no');
						setTimeout(function () {
							w.print();
						}, 500);
						resolve(stream.toBlob());
					});
					stream.on('error', reject)
				});
			}
		}else{
			if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
				papel = [612, 936];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
				papel = [612, 936];
			}
			return this.imprimirReciboConsumoAguaCartaOficio(venta,configuracionImpresion, papel, true, false, false, usuario,tipo_textos);
		}
	}

	imprimirReciboConsumoAguaCartaOficio(venta,configuracionImpresion, papel, vacia, completa, semicompleta, usuario,tipo_textos){
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		//var canvas=document.getElementById('qr-code');
		// draw some text
		var existenDescuentos = Util.verificarDescuentos(venta.detallesVenta);
		var titulo="COMPROBANTE DE PAGO";
		var itemsPorPagina = 0;
		if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 19;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 29;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 7;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina =11;
		}else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			itemsPorPagina =8;
		}
		
		var distancia_items=20;

		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(venta.detallesVenta.length / itemsPorPagina);
		doc.font('Helvetica', 8);
		this.escribirCabeceraReciboConsumoAguaCartaOficio(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracionImpresion.papel_nota_venta,tipo_textos);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
			(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
		(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
		(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
		fecha_reporte,configuracionImpresion);

		if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			for (var i = 0; i < venta.detallesVenta.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 7);
				doc.text((i+1), 20, y-5);
				doc.text(venta.detallesVenta[i].producto.nombre +
				(venta.detallesVenta[i].lectura_medidor?" "+venta.detallesVenta[i].lectura_medidor.mes.nombre:"")+
				(venta.detallesVenta[i].lectura_medidor?" "+venta.detallesVenta[i].lectura_medidor.gestion.nombre:"")+
				(venta.detallesVenta[i].lectura_medidor?" Categoria: "+venta.detallesVenta[i].lectura_medidor.accion_agua.categoria.nombre:"")+
				(venta.detallesVenta[i].lectura_medidor?" Lect. Ant.:"+venta.detallesVenta[i].lectura_medidor.lectura_anterior:"")+
				(venta.detallesVenta[i].lectura_medidor?" Lect. Act.: "+venta.detallesVenta[i].lectura_medidor.lectura_actual:"")+
				(venta.detallesVenta[i].lectura_medidor?" Consumo:"+venta.detallesVenta[i].lectura_medidor.consumo_cubos:"")+
				(venta.detallesVenta[i].descripcion?" "+venta.detallesVenta[i].descripcion:""), 30, y-7,{width:180,align:'left'});
				doc.font('Helvetica', 8);
				doc.text(venta.detallesVenta[i].importe.toFixed(2), 210, y-7,{width:40,align:"right"});
				doc.text("(-) "+venta.detallesVenta[i].descuento.toFixed(2), 210, y,{width:40,align:"right"});
				doc.font('Helvetica-Bold', 8);
				doc.text(venta.detallesVenta[i].total.toFixed(2), 265, y-3,{width:30,align:"right"});

				doc.roundedRect(18, y - 10, 280, distancia_items,5).stroke();
	
				y = y+distancia_items;
				items++;
	
				if (items == itemsPorPagina && (i+1) < venta.detallesVenta.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.escribirCabeceraReciboConsumoAguaCartaOficio(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracionImpresion.papel_nota_venta,tipo_textos)
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
						(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
					(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
					(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
					doc.font('Helvetica', 7);
				}
			}

			doc.font('Helvetica-Bold', 8);
			doc.text("TOTAL (Bs)", 220, y - 5);
			doc.text(venta.total.toFixed(2), 265, y - 5,{width:30,align:"right"});

			doc.text("SON : " + (venta.total_literal?venta.total_literal:venta.numero_literal), 30, y - 7,{width:140});

			doc.font('Helvetica', 7);
			doc.roundedRect(18, y - 10, 280, distancia_items,5).stroke();
		}else{
			doc.font('Helvetica', 8);
			for (var i = 0; i < venta.detallesVenta.length && items <= itemsPorPagina; i++) {
				doc.text((i+1), 55, y);
				doc.text(venta.detallesVenta[i].producto.nombre + " " +
				(venta.detallesVenta[i].lectura_medidor?venta.detallesVenta[i].lectura_medidor.gestion.nombre:"")+" "+
				(venta.detallesVenta[i].lectura_medidor?venta.detallesVenta[i].lectura_medidor.mes.nombre:"")+" "+
				(venta.detallesVenta[i].lectura_medidor?"Categoria: "+venta.detallesVenta[i].lectura_medidor.accion_agua.categoria.nombre:""), 80, y-7,{width:150,align:'left'});
				doc.text((venta.detallesVenta[i].lectura_medidor?venta.detallesVenta[i].lectura_medidor.lectura_anterior:""), 230, y,{width:40,align:'center'});
				doc.text((venta.detallesVenta[i].lectura_medidor?venta.detallesVenta[i].lectura_medidor.lectura_actual:""), 280, y,{width:40,align:'center'});
				doc.text((venta.detallesVenta[i].lectura_medidor?venta.detallesVenta[i].lectura_medidor.consumo_cubos:""), 330, y,{width:45,align:'center'})
				doc.text(venta.detallesVenta[i].precio_unitario.toFixed(2), 400, y);
				doc.text(venta.detallesVenta[i].importe.toFixed(2), 440, y);
				doc.text(venta.detallesVenta[i].descuento.toFixed(2), 490, y);
				doc.text(venta.detallesVenta[i].total.toFixed(2), 530, y);
	
				if (completa || vacia) {
					doc.rect(50, y - 10, 540, distancia_items).stroke();
				}
				y = y+distancia_items;
				items++;
	
				if (items == itemsPorPagina && (i+1) < venta.detallesVenta.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.escribirCabeceraReciboConsumoAguaCartaOficio(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracionImpresion.papel_nota_venta,tipo_textos)
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
						(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
					(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
					(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
					doc.font('Helvetica', 7);
				}
			}
			doc.font('Helvetica-Bold', 7);
			doc.text("TOTAL (Bs)", 455, y - 5);
			doc.text(venta.total.toFixed(2), 530, y - 5);

			doc.text("SON : " + (venta.total_literal?venta.total_literal:venta.numero_literal), 55, y - 5);

			doc.font('Helvetica', 7);
			doc.rect(50, y - 10, 540, 15).stroke();
		}

		doc.end();
		return new Promise(function (resolve, reject) {
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
				setTimeout(function () {
					w.print();
				}, 500);
				resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
	}

	escribirCabeceraProformaCartaOficio (doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracion_impresion,tipo_textos) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,43,5).stroke();
		doc.text("Nº : ",355,55);
		doc.text("Fecha : ",410,55);
		doc.text("Nit/Ci : ",500,55);
		doc.text("Cliente : ",355,65);
		doc.text("Telf. : ",510,65);
		if(venta.pedido){
			doc.text("Nº Pedido: ",355,85);
		}
		if(usuario.empresa.usar_sucursales){
			doc.text("Sucursal: "+venta.sucursal.nombre,350,75);
		}
		if(usuario.empresa.usar_almacenes && venta.almacen){
			doc.text("Almacen: "+venta.almacen.nombre,470,75);
		}
		if(usuario.empresa.usar_clientes_contactos && venta.contacto_cliente){
			doc.text("C. Cliente: ",420,85);
		}
		doc.font('Helvetica', 8);
		doc.text(venta.factura,375,55);
		doc.text(venta.fechaTexto,450,55);
		doc.text(venta.nit_ci,530,55);
		doc.text((venta.cliente.identificacion?venta.cliente.identificacion:venta.cliente.razon_social),387,65,{width:140});
		doc.text((venta.cliente.telefono1?venta.cliente.telefono1+(venta.cliente.telefono2?"-"+venta.cliente.telefono2:""):""),540,65);
		if(venta.pedido){
			doc.text(venta.pedido.numero_pedido,400,85);
		}
		if(usuario.empresa.usar_clientes_contactos && venta.contacto_cliente){
			doc.text(venta.contacto_cliente.persona.nombre_completo,460,85);
		}
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		var existenDescuentos = Util.verificarDescuentos(venta.detallesVenta);
		if (existenDescuentos) {
			doc.text("CODIGO", 55, 115);
			doc.text("CANT.", 100, 115);
			doc.text("UNID.", 130, 115);
			doc.text("DETALLE", 160, 115);
			/*if (usuario.empresa.usar_vencimientos) {
				doc.text("VENC.", 245, 115)
				doc.text("LOTE.", 280, 115)
			}*/
			doc.text("P. UNIT.", 430, 115);
			doc.text("IMP.", 465, 115);
			doc.text("DESC.", 500, 115);
			/*doc.text("REC.", 430, 115);
			doc.text("ICE", 465, 115);
			doc.text("EXC.", 500, 115);*/
			doc.text("TOTAL", 530, 115);
		} else {
			doc.text("CODIGO", 55, 115);
			doc.text("CANT.", 120, 115);
			doc.text("UNID.", 160, 115);
			doc.text("DETALLE", 200, 115);
			/*if (usuario.empresa.usar_vencimientos) {
				doc.text("VENC.", 360, 115)
				doc.text("LOTE.", 415, 115)
			}*/
			doc.text("P.UNIT.", 460, 115);
			doc.text("TOTAL", 520, 115);
		}

		doc.font('Helvetica', 7);
		var y=0;
		if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			y=380;
		}
		let texto_credito=configuracion_impresion.datos_credito?(venta.tipoPago.nombre_corto=="CRE")?("A cuenta: Bs "+venta.a_cuenta+", Saldo: Bs "+venta.saldo):"":"";
		let observacion=(venta.observacion)?venta.observacion:"";
		observacion=(texto_credito.length>0 || observacion.length>0)?"OBS. : "+observacion+" "+texto_credito:"";
		doc.text(observacion, 50, y-14,{width:configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA?155:310});
		if(configuracion_impresion.usuario || configuracion_impresion.imprimir_usuario){
			doc.text("Usuario Vendedor: " + venta.usuario.persona.nombre_completo, 0,y-14,{width:configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA?295:590,align:'right'});
		}
	}

	escribirCabeceraReciboConsumoAguaCartaOficio (doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,tamano_papel,tipo_textos) {
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(107,60,191,43,5).stroke();
			doc.text("Nº : ",105,65,{width:40,align:"right"});
			doc.text("FECHA : ",190,65,{width:35,align:"right"});
			doc.text("CÓDIGO : ",105,75,{width:40,align:"right"});
			doc.text("SOCIO : ",105,85,{width:40,align:"right"});
			doc.font('Helvetica', 8);
			doc.text(venta.factura,145,65);
			doc.text(venta.fechaTexto,230,65);
			doc.text(venta.cliente.codigo,145,75);
			doc.text(venta.cliente.identificacion,145,85,{width:150});
			
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(18,105,280,25,5).fillOpacity(0.8).stroke();
	
			doc.text("Nº", 20, 115);
			doc.text("CONCEPTO DE PAGO", 30, 115,{width:100,align:'center'});
			doc.text("IMPORTE", 210, 110,{width:45,align:'center'});
			doc.text("(-) DESC.", 210, 119,{width:45,align:'center'});
			doc.text("TOTAL", 255, 110,{width:45,align:'center'});
		}else{
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(380,50,205,43,5).stroke();
			doc.text("Nº : ",385,55,{width:40,align:"right"});
			doc.text("FECHA : ",470,55,{width:35,align:"right"});
			doc.text("SOCIO : ",385,70,{width:40,align:"right"});
			doc.text("CÓDIGO : ",385,80,{width:40,align:"right"});
			doc.font('Helvetica', 8);
			doc.text(venta.factura,430,55);
			doc.text(venta.fechaTexto,510,55);
			doc.text(venta.cliente.identificacion,430,70);
			doc.text(venta.cliente.codigo,430,80);
			
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
	
			doc.text("Nº", 55, 115);
			doc.text("CONCEPTO DE PAGO", 95, 115,{width:100,align:'center'});
			doc.text("LECT. ANT.", 230, 110,{width:40,align:'center'});
			doc.text("LECT. ACT.", 280, 110,{width:40,align:'center'});
			doc.text("CONSUMO M3.", 330, 110,{width:45,align:'center'})
			doc.text("P.U.", 400, 115);
			doc.text("IMPORTE", 440, 115);
			doc.text("DESC.", 490, 115);
			doc.text("TOTAL", 530, 115);
		}
		

		doc.font('Helvetica', 7);
		var y=0;
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			y=380;
		}
		
		let es_papel_cuarto_carta=tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA;
		doc.font('Helvetica-Bold', 7);
		doc.text(venta.observacion?"OBS. : "+venta.observacion:"", 0, y-(es_papel_cuarto_carta?7:14),{width:es_papel_cuarto_carta?295:590,align:'right'});
		doc.text("-------------------------------", es_papel_cuarto_carta?20:50, y-(es_papel_cuarto_carta?7:14),{width:es_papel_cuarto_carta?295:590,align:'left'});
		doc.text("Entregue Conforme", es_papel_cuarto_carta?20:50, y-(es_papel_cuarto_carta?0:7),{width:es_papel_cuarto_carta?295:590,align:'left'});
		doc.text(venta.cliente.razon_social, es_papel_cuarto_carta?20:50, (es_papel_cuarto_carta?y+7:y),{width:es_papel_cuarto_carta?295:590,align:'left'});
	}

	imprimirNotaAlmacenCartaOficio(venta,configuracionImpresion,usuario) {
		var papel=[612, 792];
			var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date(),titulo="NOTA SALIDA DE ALMACEN";

		var canvas = document.getElementById('qr-code');
		// draw some text
		var existenDescuentos = Util.verificarDescuentos(venta.detallesVenta);

		var itemsPorPagina = 0;
		if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 27;
		} else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 24;
		} else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 5;
		}else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina = 7;
		}else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			itemsPorPagina = 7;
		}

		for (var j = 0; j < configuracionImpresion.numero_copias; j++) {
			if (j != 0) {
				doc.addPage({ size: papel, margin: 10 });
			}

			doc.font('Helvetica', 8);
			var y = 135, items = 0, pagina = 1, totalPaginas = Math.ceil(venta.detallesVenta.length / itemsPorPagina);
			this.dibujarCabeceraNotaAlmacen(doc, true, true, venta, papel, pagina, totalPaginas, usuario);
			this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
				usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
				(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
			(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
			(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
			fecha_reporte,configuracionImpresion);
			for (var i = 0; i < venta.detallesVenta.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 8);
				if (existenDescuentos) {
					doc.text(((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.codigo:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.codigo:"")), 55, y);
					doc.text(venta.detallesVenta[i].cantidad, 115, y);
					doc.text((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.unidad_medida:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.unidad_medida:""), 140, y);
					doc.text((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.nombre:venta.detallesVenta[i].combo.nombre, 180, y - 6, { width: 120 });
					doc.text(venta.detallesVenta[i].precio_unitario.toFixed(2), 300, y);
					doc.text(venta.detallesVenta[i].importe.toFixed(2), 335, y);
					doc.text(venta.detallesVenta[i].tipo_descuento ? "%" : "Bs", 385, y - 10);
					doc.text(venta.detallesVenta[i].descuento.toFixed(2), 385, y);
					doc.text(venta.detallesVenta[i].tipo_recargo ? "%" : "Bs", 420, y - 10);
					doc.text(venta.detallesVenta[i].recargo.toFixed(2), 420, y);
					doc.text(venta.detallesVenta[i].ice.toFixed(2), 455, y);
					doc.text(venta.detallesVenta[i].excento.toFixed(2), 490, y);
					doc.text(venta.detallesVenta[i].total.toFixed(2), 520, y);
				} else {
					doc.text(((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.codigo:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.codigo:"")), 55, y);
					doc.text(venta.detallesVenta[i].cantidad, 110, y);
					doc.text((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.unidad_medida:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.unidad_medida:""), 165, y);
					var longitudCaracteres = (venta.detallesVenta[i].descripcion?venta.detallesVenta[i].descripcion.length:0)+((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.nombre.length:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.nombre.length:0));
					var yDesc = (longitudCaracteres <= 45) ? y : ((longitudCaracteres > 45 && longitudCaracteres <= 90) ? y - 8 : y - 8);
					doc.text((venta.detallesVenta[i].producto)?venta.detallesVenta[i].producto.nombre:(venta.detallesVenta[i].combo?venta.detallesVenta[i].combo.nombre:"")+" "+venta.detallesVenta[i].descripcion, 220, yDesc, { width: 225 });
					doc.text(venta.detallesVenta[i].precio_unitario.toFixed(2), 450, y);
					doc.text(venta.detallesVenta[i].total.toFixed(2), 520, y);
				}
				//if (completa || vacia) {
					doc.roundedRect(50,y - 10,520,20,5).fillOpacity(0.8).stroke();
				//}
				y = y + 20;
				items++;

				if (items > itemsPorPagina) {
					doc.addPage({ size: papel, margin: 10 });
					y = 135;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraNotaAlmacen(doc, true, true, venta, papel, pagina, totalPaginas, usuario);
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
						(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
					(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
					(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
				}
			}
			//if (completa || vacia) {
				doc.font('Helvetica-Bold', 8);
				doc.text("TOTAL", 455, y);
			//}
			doc.font('Helvetica', 8);
			doc.text(venta.total.toFixed(2), 520, y);

			doc.text("SON : " + (venta.total_literal?venta.total_literal:venta.numero_literal), 55, y);

			doc.roundedRect(50,y - 10,520,20,5).fillOpacity(0.8).stroke();
			var fechaActual = new Date();
			let min:any = fechaActual.getMinutes();
			if (min < 10) {
				min = "0" + min;
			}
			doc.font('Helvetica-Bold', 8);
			doc.text((venta.transaccion?(venta.transaccion.nombre+" Nro. Doc.: "+ venta.factura):"") +(venta.observacion?" OBS. : "+venta.observacion:""), 270, y+15,{width:310,align:'right'});						
			doc.font('Helvetica', 8);
			doc.text("  Usuario : " + usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", 50,y+15);
			

			doc.text("---------------------------                                        -------------------------------------                                               ----------------------------------------------", 50, y+55);
			doc.text("Almacen                                                                  Entregue Conforme                                                                    Recibi Conforme (sello)", 50, y+65);
			doc.text("                                                                                                                                                              Nombre:________________", 50, y+75);
			doc.text("                                                                                                                                                              Firma:________________", 50, y+85);
			

		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	dibujarCabeceraNotaAlmacen(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario) {
		doc.roundedRect(350,50,235,43,5).stroke();
		doc.font('Helvetica-Bold', 8);
		doc.text("FECHA : "+venta.fechaTexto, 355, 55);
		//doc.text("FECHA : ", 60, 115);
		if(venta.cliente){
			doc.text("CLIENTE : "+venta.razon_social, 355, 65);
		}

		doc.text("CODIGO No. NOTA : "+venta.fechaTexto, 355, 75);
		doc.text("ALMACEN : "+venta.almacen.nombre, 355, 85);
		if(venta.tipoPago){
			doc.text("TIPO PAGO : "+venta.tipoPago.nombre, 470, 85);
		}
		
		//doc.rect(50,225,520,papel[1]-175-225).stroke();
		doc.roundedRect(50,105,520,20,5).fillOpacity(0.8).stroke();
		var existenDescuentos = Util.verificarDescuentos(venta.detallesVenta);
		if (existenDescuentos) {
			doc.text("CODIGO", 55, 115);
			doc.text("CANT.", 110, 115);
			doc.text("UNID.", 140, 115);
			doc.text("DETALLE", 180, 115);
			doc.text("P. UNIT.", 300, 115);
			doc.text("IMPORTE", 335, 115);
			doc.text("DESC.", 385, 115);
			doc.text("REC.", 420, 115);
			doc.text("ICE", 455, 115);
			doc.text("EXC.", 490, 115);
			doc.text("TOTAL", 520, 115);
		} else {
			doc.text("CODIGO", 55, 115);
			doc.text("CANTIDAD", 110, 115);
			doc.text("UNIDAD", 165, 115);
			doc.text("DETALLE", 220, 115);
			doc.text("P.UNIT.", 450, 115);
			doc.text("TOTAL", 520, 115);
		}
	}

	async imprimirEstadoNegocio(ventasEmpresa,gastosVariosEmpresa,usuario,fecha_inicial,fecha_final){
		var suma_ventas_total=ventasEmpresa.ventas_factura_contado[0].total+
							  ventasEmpresa.ventas_factura_credito[0].total+
							  ventasEmpresa.ventas_recibo_contado[0].total+
							  ventasEmpresa.ventas_recibo_credito[0].total;
		var suma_ventas_contado=ventasEmpresa.ventas_factura_contado[0].total+
							  ventasEmpresa.ventas_recibo_contado[0].total;
		var suma_ventas_credito=ventasEmpresa.ventas_factura_credito[0].total+
							  ventasEmpresa.ventas_recibo_credito[0].total;
		var suma_gastos=0;
		for(var i=0;i<gastosVariosEmpresa.gastos_factura.length;i++){
			suma_gastos=suma_gastos+gastosVariosEmpresa.gastos_factura[i].total;
		}
		for(var i=0;i<gastosVariosEmpresa.gastos_recibo.length;i++){
			suma_gastos=suma_gastos+gastosVariosEmpresa.gastos_recibo[i].total;
		}
		var doc = new PDFDocument({margin:10});
		var stream = doc.pipe(blobStream());
		// draw some text
		var fecha_reporte=new Date();
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,"ESTADO DEL NEGOCIO",1,1,usuario,
		usuario.empresa.imagen,usuario.empresa.nombre,usuario.empresa.nombre,usuario.empresa.direccion,
			(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
		(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")
		,"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});
		fecha_inicial=fecha_inicial.getDate()+"/"+(fecha_inicial.getMonth()+1)+"/"+fecha_inicial.getFullYear();
		fecha_final=fecha_final.getDate()+"/"+(fecha_final.getMonth()+1)+"/"+fecha_final.getFullYear();
		doc.font('Helvetica',8);
		doc.text("DEL "+fecha_inicial+" AL "+fecha_final,350,60,{width:250,align:"center"});
		var y=130;
		doc.font('Helvetica-Bold',8);
		doc.text("VENTAS",100,y);
		doc.text(suma_ventas_total,300,y);
		doc.text("COSTO DE VENTAS",100,y+100);
		doc.text(Math.round(ventasEmpresa.costo_ventas[0].total*100)/100,300,y+100);
		doc.font('Helvetica',8);
		doc.text("VENTAS CONTADO",150,y+30);
		doc.text(Math.round(suma_ventas_contado*100)/100,250,y+30);
		doc.text("VENTAS CREDITO",150,y+60);
		doc.text(Math.round(suma_ventas_credito*100)/100,250,y+60);
		doc.text("UTILIDAD BRUTA EN VENTAS",100,y+150);
		doc.text(Math.round((suma_ventas_total-ventasEmpresa.costo_ventas[0].total)*100)/100,300,y+150);
        doc.text("GASTOS",100,y+200);
		doc.text(suma_gastos,300,y+200);

		var z=360,totalGasto=0, sumaGastos=0;
		for(var i=0;i<gastosVariosEmpresa.gastos_factura.length;i++){
			doc.text(gastosVariosEmpresa.gastos_factura[i].nombre,130,z);
			doc.text(gastosVariosEmpresa.gastos_factura[i].total,280,z);
			z=z+25;
		}
		for(var i=0;i<gastosVariosEmpresa.gastos_recibo.length;i++){
			doc.text(gastosVariosEmpresa.gastos_recibo[i].nombre,130,z);
			doc.text(gastosVariosEmpresa.gastos_recibo[i].total,280,z);
			z=z+25;
		}

		var utilidadPeriodo=(Math.round((suma_ventas_total-ventasEmpresa.costo_ventas[0].total)*100)/100)-suma_gastos;
		doc.font('Helvetica-Bold',8);
		var textoUtilidad=((utilidadPeriodo>=0)?"UTILIDAD DEL PERIODO":"PÉRDIDA DEL PERIODO")
		doc.text(textoUtilidad,100,z+25);
		doc.text(utilidadPeriodo,300,z+25);
		
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}

	//-------------------------
	//----------------------
	imprimirReporteVentasCreditoPoCliente(clientes, resumen, usuario){
	
		const doc = new jsPDF('p', 'pt', 'letter');

		var datos = this.ObtenerDatosParaEncabezado(usuario);
		var img = new Image();	
		//img.src = datos[2]+"";
		img.src = GlobalVariable.SERVER_URL+usuario['empresa']['imagen'];
		//doc.addImage(img, 'png', 30, 5, 25, 25);

		//this.DibujarEncabezado(doc, usuario);


		//Punto de inicio de dibujo de tablas
		var incrementoEspacio = 40;
		for(var j=0; j<clientes.length; j++){

			var items = new Array();
			for(var i=0; i<clientes[j]['items'].length; i++){
				var nom_item;
				var tipo_item;
				var obsequios;
				var devoluciones;
				if(clientes[j]['items'][i]['combo']!=null){
					nom_item = clientes[j]['items'][i]['combo'];
					tipo_item = 'Combo';
					obsequios = clientes[j]['items'][i]['obsequios_combos'];
					devoluciones = clientes[j]['items'][i]['devoluciones_combos'];
				}else{
					nom_item = clientes[j]['items'][i]['producto'];
					tipo_item = 'Producto';
					obsequios = clientes[j]['items'][i]['obsequios_productos'];
					devoluciones = clientes[j]['items'][i]['devoluciones'];
				}

				items.push([
					nom_item,
					tipo_item,
					clientes[j]['items'][i]['vendidos'],
					obsequios,
					devoluciones
				]);
			}

			//console.log(j + ") item: ");
			//console.log(items);

			var headCliente = [[
				'Nombre de cliente',
				'Total',
				'Devoluciones',
				'A cuenta',
				'Saldo'
			]];
			
			var bodyCliente = [[
				clientes[j]['identificacion'],
				clientes[j]['total'],
				clientes[j]['devoluciones'],
				clientes[j]['a_cuenta'],
				clientes[j]['saldo']
			]];

			var headItems = [[
				'Item',
				'Tipo de item',
				'Vendidos',
				'Obsequios',
				'Devoluciones'
			]];

			var bodyItems = items;
		
			var total = this.calcularCantidadPaginas(4);
		
			//var startingPage = doc.internal.getCurrentPageInfo().pageNumber;
			

		//    doc.setPage(startingPage);

		var bodyDatosEncabezado = [
			["Dato1", '.-------'],
			["Dato1", '.-------'],
			["Dato1", '.-------']
		]
		bodyDatosEncabezado = [];

		
			

			doc.autoTable({ 
				//      theme : '',
				startY: incrementoEspacio,
				showHead: 'everyPage',
				//html: '#tabla1',
				head: headCliente,
				body: bodyCliente,
			
				 
			});

			

			doc.autoTable({ 
				startY: doc.lastAutoTable.finalY,
				showHead: 'firstPage',
				theme: 'grid',
				head: headItems,
				body: bodyItems,
				didDrawPage: function(data){	

					doc.autoTable({ 
						startY: 3,
						showHead: 'firstPage',
						theme: 'plain',
						//tableLineColor: [0, 0, 0],
						//tableLineWidth: 0.75,
						tableWidth: 235,
						body: datos[0],
						styles: {
							fontSize: 7,
							cellPadding: {
								left: 3,
								right: 0,
								top: 0,
								button: 0
							}
						},
						margin: {
							left: 160
						}
					});
					
					

					doc.autoTable({ 
						startY: 3,
						showHead: 'firstPage',
						theme: 'plain',
						
						body: datos[1],
						tableWidth: 90,
						styles: {
							fontSize: 7,
							cellPadding: {
								left: 3,
								right: 0,
								top: 0,
								button: 0
							}
						},
						
						margin: {
							left: 375
						},
						didDrawPage: function(data){
							// Header
							doc.setFontSize(7);
							doc.setTextColor(40);
							
							// Footer
							var str = "Página " + doc.internal.getNumberOfPages();

							var pageSize = doc.internal.pageSize;
							var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
							doc.text(str, data.settings.margin.left - 80, pageHeight - 10);
						}   
					});
				}
				
			});
			incrementoEspacio = doc.lastAutoTable.finalY + 50;
		}

		//console.log("objeto datos encabezado: ");
		//console.log( this.ObtenerDatosParaEncabezado(usuario));
    	doc.output('dataurlnewwindow');
	}

	calcularCantidadPaginas(numFilas){
		var resultado = numFilas / 27;
		resultado = Math.ceil(resultado);
		return resultado;
	}

	DibujarEncabezado(doc, usuario){
		
		//logo de empresa: usuario.empresa.imagen
		//nombres: usuario.nombre_usuario
		//Empresa: usuario.empresa.nombre
		//Direccion: usuario.empresa.nombre
		//Telefonos: usuario.empresa.telefono1
		//		usuario.empresa.telefono2
		//		usuario.empresa.telefono3
		//Titulo del reporte: Reporte de por usuario.nombre_usuario
		
		var img = new Image();
		img.src = GlobalVariable.SERVER_URL + usuario['empresa']['imagen'];

		doc.addImage(img, 'png', 30, 10, 100, 100);

		var bodyDatosEncabezado = [
			['Usuario: ', usuario['nombre_usuario'] ],
			['Empresa: ', usuario['empresa']['nombre'] ],
			['Dirección: ', usuario['empresa']['direccion'] ]
		];
		
		
		if(usuario['empresa']['telefono1'] != null){
			bodyDatosEncabezado.push(['Telefono 1: ', usuario['empresa']['telefono1']]);
		}
		if(usuario['empresa']['telefono2'] != null){
			bodyDatosEncabezado.push(['Telefono 2: ', usuario['empresa']['telefono2']]);
		}
		if(usuario['empresa']['telefono3'] != null){
			bodyDatosEncabezado.push(['Telefono 3: ', usuario['empresa']['telefono3']]);
		}

		

		doc.autoTable({ 
			startY: 3,
			showHead: 'firstPage',
			theme: 'plain',
			tableLineColor: [0, 0, 0],
			tableLineWidth: 0.75,
			body: bodyDatosEncabezado,
			margin: {
				left: 160
			},
			didDrawPage: function(data){
				// Header
				doc.setFontSize(20);
				doc.setTextColor(40);
				
				var str = 'adsfadfad';
				var pageSize = doc.internal.pageSize;
				var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
				doc.text(str, data.settings.margin.left, 20);
				body: bodyDatosEncabezado;
				
			}   
		});
		
		
	}

	ObtenerDatosParaEncabezado(usuario){
		
		var bodyDatosEncabezado = [
			['Usuario: ', usuario['nombre_usuario'] ],
			['Empresa: ', usuario['empresa']['nombre'] ],
			['Dirección: ', usuario['empresa']['direccion'] ]
		];
		var bodyTelefonos = [];
		var datos = [];

		if(usuario['empresa']['telefono1'] != null){
			bodyTelefonos.push(['Telefono 1: ', usuario['empresa']['telefono1']]);
		}
		if(usuario['empresa']['telefono2'] != null){
			bodyTelefonos.push(['Telefono 2: ', usuario['empresa']['telefono2']]);
		}
		if(usuario['empresa']['telefono3'] != null){
			bodyTelefonos.push(['Telefono 3: ', usuario['empresa']['telefono3']]);
		}
		datos.push(bodyDatosEncabezado);
		datos.push(bodyTelefonos);
		datos.push(GlobalVariable.SERVER_URL + usuario['empresa']['imagen']);
		return datos;

	}

	imprimirReporteVentasCreditoPoCliente2(clientes, resumen){

	/*
		var doc = new jsPDF();
		var elementHTML = document.body;
		var specialElementHandlers = {
			'#elementH': function (element, renderer) {
				return true;
			}
		};
		doc.addHTML(elementHTML, 15, 15, {
			'width': 170,
			'elementHandlers': specialElementHandlers
		});

		// Save the PDF
		doc.save('sample-document.pdf');
		*/
		/*
		//var data = document.getElementsByClassName('#main-content');
		var data = document.getElementsByClassName('row');

		

		html2canvas(data).then(canvas => {  
			// Few necessary setting options  
			var imgWidth = 208;   
			var pageHeight = 295;    
			var imgHeight = canvas.height * imgWidth / canvas.width;  
			var heightLeft = imgHeight;  
		
			const contentDataURL = canvas.toDataURL('image/png')  
			let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
			var position = 0;  
			pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
			//pdf.addImage(contentDataURL, 'PNG', 0, position, 100, 800)  

			pdf.save('dataurlnewwindow');
			
		});  
		

		////////////////////////*********** */
		/*
		html2canvas(document.body).then(function (canvas) {
			var img = canvas.toDataURL("image/png");
			console.log("img canvas: ");
			console.log(img);

			var doc = new jsPDF();
			doc.addImage(img, 'PNG', 200, 200);
			//doc.output('dataurlnewwindow');
			doc.save('test.pdf');        
		});
			
		
		
		var doc = new jsPDF();
		var elementHTML = ('class="page-content container'); //.html()
		var specialElementHandlers = {
			'd-flex flex-column align-items-center': function (element, renderer) {
				return true;
			}
		};
		doc.fromHTML(elementHTML, 15, 15, {
			'width': 170,
			'elementHandlers': specialElementHandlers
		});

		
		doc.output('dataurlnewwindow');
		//doc.save('sample-document.pdf');*/
		

		html2canvas(document.body).then(canvas => {
			let pdf = new jsPDF('p', 'mm', 'a4');
			pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 150, 700);
			pdf.save('pdf generado.pdf');
		});

	}

	descargarReporteComboPdf(combo, conceptos_precios, materiales, mano_obra){
		const doc = new jsPDF('p', 'pt', 'letter');
		//console.log("en pdf service, combo: ");
		//console.log(combo);

		var cuerpoEncabezado = [
			['Código: ', combo['codigo'], 'Nombre: ', combo['nombre'] ],
			['Unid. Med.: ', combo['unidad_medida'], 'Descripción: ', combo['descripcion'] ],
			['Grupo: ', combo['grupo']['nombre'] ]
		];

		doc.autoTable({ 
			showHead: 'everyPage',
			startY: 27,
			body: cuerpoEncabezado,
			
			styles: {
				fontSize: 5,
				cellPadding: 1
			}
			
		});

		var headTitleComposición = [['Composición']];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleComposición,
			styles: {
				fontSize: 7,
				cellPadding: 1,
				halign: 'center'
			}
		});


		var headComposicion = [
			['Producto', 'Unid. Med.', 'P.U.', 'Cantidad']
		];
		var headGenerico_mat_manObr = [
			['Producto', 'Unid. Med.', 'P.U.', 'Cantidad', 'Total']
		];
		var bodyComposicion = [];
		var bodyMateriales = [];
		var bodyManoObra = [];
		
		combo['detallesCombo'].forEach(element => {
			bodyComposicion.push([
				element['producto']['nombre'],
				element['producto']['unidad_medida'],
				element['producto']['precio_unitario'], 
				element['cantidad']
			]);
		});

		materiales.forEach(element => {
			bodyMateriales.push([
				element['producto']['nombre'],
				element['producto']['unidad_medida'],
				element['producto']['precio_unitario'], 
				element['cantidad'],
				(element['producto']['precio_unitario'] * element['cantidad'])
			]);
		});

		mano_obra.forEach(element => {
			bodyManoObra.push([
				element['producto']['nombre'],
				element['producto']['unidad_medida'],
				element['producto']['precio_unitario'], 
				element['cantidad'],
				(element['producto']['precio_unitario'] * element['cantidad'])
			]);
		});


		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			head: headComposicion,
			body: bodyComposicion,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});

		var headTitleMateriales = [['Materiales']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleMateriales,
			styles: {
				fontSize: 7,
				cellPadding: 1,
				halign: 'center'
			}
		});

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			head: headGenerico_mat_manObr,
			body: bodyMateriales,
			styles: {
				fontSize: 7,
				cellPadding: 1
			},
			columnStyles: {
				3: {
					cellWidth: 65
				},
				4: {
					cellWidth: 70
				}
			}
		});


		var bodyPostMaterialTotal = [
			['', 'Total Material', conceptos_precios['total_material']	]
		];
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyPostMaterialTotal,
			styles: {
				fontSize: 7,
				cellPadding: 1
			},
			columnStyles: {
				1: {
					cellWidth: 65
				},
				2: {
					cellWidth: 70
				}
			}
		});
		
		var headTitleManoObra = [['Mano de obra']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleManoObra,
			styles: {
				fontSize: 7,
				cellPadding: 1,
				halign: 'center'
			}
		});

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			head: headGenerico_mat_manObr,
			body: bodyManoObra,
			styles: {
				fontSize: 7,
				cellPadding: 1
			},
			columnStyles: {
				4: {
					cellWidth: 70,
				}
			}
		});

		var bodyPostManoObra = [
			['', 'Total Mano de Obra Inicial', conceptos_precios['total_mano_obra']],
			[conceptos_precios['beneficios']['nombre'], conceptos_precios['beneficios']['nombre_corto'], conceptos_precios['total_beneficios_sociales'] ],
			[conceptos_precios['iva']['nombre'], conceptos_precios['iva']['nombre_corto'], conceptos_precios['total_iva']],
			['', 'Total Mano de Obra', conceptos_precios['total_mano_obra_beneficios']]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyPostManoObra,
			styles: {
				fontSize: 7,
				cellPadding: 1
			},
			columnStyles: {
				2: {
					cellWidth: 70,
				}
			}
		});


		var headTitleEquipoMaquinaria = [['EQUIPO Y MAQUINARIA']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleEquipoMaquinaria,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});

		var bodyEquipoMaquinaria = [
			['HERRAMIENTAS - % DE LA MANO DE OBRA', conceptos_precios['equipo_maquinaria']['nombre_corto'], '0'	],
			['', 'Total Maquinaria y Equipo', conceptos_precios['total_maquinaria_equipo']	]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyEquipoMaquinaria,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});


		var headTitleGastosGenerales = [['GASTOS GENERALES Y ADMINISTR.']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleGastosGenerales,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});

		
	
	
		var bodyGastosGenerales = [
			['GASTOS GENERALES - % DE 1+2+3', conceptos_precios['gastos_generales']['nombre_corto'], '0'	],
			['', 'Total Material', conceptos_precios['total_gastos_generales']	]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyGastosGenerales,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});


		var headTitleUtilidad = [['UTILIDAD']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleUtilidad,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});
	
		var bodyUtilidad = [
			['UTILIDAD - % DE 1+2+3+4', conceptos_precios['utilidad']['nombre_corto'], '0' ],
			['', 'Total Utilidad', conceptos_precios['total_utilidad']	]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyUtilidad,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});


		var headTitleImpuestos = [['IMPUESTOS']];
		
		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			head: headTitleImpuestos,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});
	
		
    
		var bodyImpuestos = [
			[  'IMPUESTO A LAS TRANSACCIONES - % DE 1+2+3+4+5', conceptos_precios['impuestos']['nombre_corto'], '0'  ],
			[ '', 'Total Impuestos', conceptos_precios['total_impuestos']	]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyImpuestos,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});

		

		var bodyPrecioUnitario = [
			[ '', ''],
			[ 'Precio Unitario', combo['precio_unitario']	]
		];

		doc.autoTable({ 
			theme: 'grid',
			showHead: 'everyPage',
			startY: doc.lastAutoTable.finalY,
			body: bodyPrecioUnitario,
			styles: {
				fontSize: 7,
				cellPadding: 1
			}
		});

		

		
		doc.output('dataurlnewwindow');
	}

	generarPdfFiltroVentas(ventas,fechaInicio,fechaFin,con_detalle,usuario){
		var doc = new PDFDocument({size:[612,792],margin:0});
		var stream = doc.pipe(blobStream());
		var itemsPorPagina=20;
		if(con_detalle){
			var c=ventas.length*2;
			for(var i=0; i<ventas.length;i++){
				c=c+ventas[i].detallesVenta.length;						
			}
			var totalPaginas=Math.ceil(c/(itemsPorPagina));
		}else{
			var itemsPorPagina=20;
			var totalPaginas=Math.ceil(ventas.length/itemsPorPagina);	
		}

		var fecha_reporte=new Date();
		this.generarCabeceraPdfiltroVentas(doc,fechaInicio,fechaFin);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE LISTA DE VENTAS",1,1,usuario,
		usuario.empresa.imagen,usuario.empresa.nombre,usuario.empresa.nombre,usuario.empresa.direccion,
			(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
		(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")
		,"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});

		var y=121,items=0,pagina=1;

		doc.font('Helvetica',7);
		
		for(var i=0;i<ventas.length;i++){
					doc.font('Helvetica',7);
					doc.rect(50,y+9,540,30).stroke();
					
					doc.text(i+1,55,y+20);
					doc.font('Helvetica',6);
					doc.text(ventas[i].transaccion,70,y+20,{width:45});
					doc.text(ventas[i].sucursal,120,y+20,{width:50});
					if(ventas[i].cliente){
						doc.font('Helvetica',7);
						doc.text(ventas[i].cliente,170,y+15,{width:75});	
						doc.font('Helvetica',7);				
					doc.text(ventas[i].nit_ci,245,y+20);
					}else{
						doc.text("",205,y+16,{width:75});	
						doc.text("",265,y+20);
					}
					doc.text(ventas[i].factura,305,y+20);
					ventas[i].fecha=new Date(ventas[i].fecha);
					doc.text(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear(),345,y+20);
					doc.text(ventas[i].fecha.getHours()+":"+ventas[i].fecha.getMinutes()+" - ",385,y+13,{width:28});					
					doc.text(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear(),385,y+21,{width:32});					
					doc.text(ventas[i].total,425,y+20);
					if(ventas[i].tipo_pago){
						doc.text(ventas[i].tipo_pago,455,y+20);
						if(ventas[i].tipo_pago==GlobalVariable.Dictionary.TIPO_PAGO_CREDITO){
						doc.font('Helvetica',6);
						doc.text("Plazo: " +ventas[i].dias_credito+" A cuenta: Bs "+ventas[i].a_cuenta+" Saldo: Bs " +ventas[i].saldo,510,y+14,{width:50});
						}	
					}else{
						doc.text("",455,y+20);
						doc.text("",520,y+16,{width:65});
					}
					doc.font('Helvetica',7);				
					doc.text(ventas[i].activa?"V":"A",560,y+20);
					
					if(con_detalle){
						doc.rect(50,y+34,520,0).stroke();
						doc.font('Helvetica',7);
						y=y+50;
						
						doc.text("N°",105,y);
						doc.text("Nombre",115,y);
						doc.text("Codigo Item",270,y);
						doc.text("Unidad de Med",330,y);
						doc.text("Cantidad", 395,y);
						doc.text("Importe", 455,y);
						items++;
						for(var j=0; j<ventas[i].detallesVenta.length;j++){
							doc.font('Helvetica',7);
							
							doc.text(j+1,105,y+20);
							doc.text(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].producto:ventas[i].detallesVenta[j].descripcion?ventas[i].detallesVenta[j].descripcion:"",115,y+20,{width:140});
							doc.text(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].codigo_producto:"",270,y+20);
							doc.text(ventas[i].detallesVenta[j].producto?ventas[i].detallesVenta[j].unidad_medida:"",330,y+20);
							doc.text(ventas[i].detallesVenta[j].cantidad,395,y+20);
							doc.text(ventas[i].detallesVenta[j].total_detalle,455,y+20);
							
							y=y+24
							items++;
							if(items+1>itemsPorPagina-1){
								y=y+10;
								
								doc.addPage({size:[612,792],margin:10});
								y=121;
								items=0;
								pagina=pagina+1;
								this.generarCabeceraPdfiltroVentas(doc,fechaInicio,fechaFin);
								this.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE LISTA DE VENTAS",1,1,usuario,
								usuario.empresa.imagen,usuario.empresa.nombre,usuario.empresa.nombre,usuario.empresa.direccion,
									(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
								(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")
								,"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
								fecha_reporte,{imprimir_usuario:false});
							}
						}
						
						doc.font('Helvetica',7);
						y=y+4;
						items++;
					
						if(items>itemsPorPagina){
							y=y+10;
							
							doc.addPage({size:[612,792],margin:10});
							y=121;
							items=0;
							pagina=pagina+1;
							this.generarCabeceraPdfiltroVentas(doc,fechaInicio,fechaFin);
							this.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE LISTA DE VENTAS",1,1,usuario,
								usuario.empresa.imagen,usuario.empresa.nombre,usuario.empresa.nombre,usuario.empresa.direccion,
									(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
								(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")
								,"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
								fecha_reporte,{imprimir_usuario:false});
						}
					}else{
					
						doc.font('Helvetica',7);
						y=y+30;
						items++;
					
						if(items==itemsPorPagina){
							
							var currentDate=new Date();
							
							doc.addPage({size:[612,792],margin:10});
							y=121;
							items=0;
							pagina=pagina+1;
							this.generarCabeceraPdfiltroVentas(doc,fechaInicio,fechaFin);
							this.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE LISTA DE VENTAS",1,1,usuario,
								usuario.empresa.imagen,usuario.empresa.nombre,usuario.empresa.nombre,usuario.empresa.direccion,
									(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
								(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")
								,"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
								fecha_reporte,{imprimir_usuario:false});
						}

					}
		}
		doc.font('Helvetica',7);
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}
	generarCabeceraPdfiltroVentas(doc,fechaInicio,fechaFin){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,66,235,30,5).stroke();
		doc.text("Desde: "+fechaInicio+" Hasta: "+fechaFin,355,68);
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		doc.text("N°",55,110);
		doc.text("Transacción",65,110);
		doc.text("Sucursal",120,110,{width:43});
		doc.text("Razon Social",170,110);
		doc.text("Cliente", 245,110,{width:43});
		doc.text("Nº Doc..", 305,110);
		doc.text("Fecha Doc..", 345,110-2,{width:43});
		doc.text("Hora-Fecha", 385,110-2,{width:43});
		doc.text("Importe", 420,110);
		doc.text("Tipo de Pago",455,110);
		doc.text("Pago",520,110);
		doc.text("Estado",550,110);
	}

	imprimirMovimientoInventario(movimiento, usuario){
		this.usuarioService.obtenerConfiguracionPaginaPorCodigo(usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_INVENTARIO,usuario.empresa.usar_permisos_avanzados_usuarios?usuario.id:0).subscribe((conf:any)=>{
			let configuracion_aplicacion_ventas=conf;
			let configuracionImpresion=configuracion_aplicacion_ventas.datos.opciones_permisos.impresion;
			this.imprimirNotaMovimientoInventario(movimiento, usuario,configuracionImpresion);
		});
	}

	imprimirNotaMovimientoInventario(salida, usuario,configuracionImpresion){
		let itemsPorPagina,papel;
		if (configuracionImpresion.papel == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto, totalItems = salida.detallesMovimientoInventario.length;
			if (totalItems <= 2) {
				alto = 300;
			} else {
				alto = 300 + (20 * (totalItems - 2))
			}
			papel = [227, alto];
			this.imprimirNotaMovimientoInventarioRollo(salida, papel, usuario);
		}else{
			if (configuracionImpresion.papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
				papel = [612, 936];
				itemsPorPagina = 23;
			} else if (configuracionImpresion.papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
				papel = [612, 792];
				itemsPorPagina = 20;
			} else if (configuracionImpresion.papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
				papel = [612, 468];
				itemsPorPagina = 10;
			}
			this.imprimirNotaMovimientoInventarioCartaOficio(salida, papel, itemsPorPagina, usuario,configuracionImpresion);
		}
	}

	imprimirNotaMovimientoInventarioCartaOficio(movimiento_inventario, papel, itemsPorPagina, usuario,configuracionImpresion){
		let fecha_reporte=new Date();
		var doc = new PDFDocument({ size: papel, margin: 0 });
		var stream = doc.pipe(blobStream());
		doc.font('Helvetica', 8);
		var totalAray = 0;
		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(movimiento_inventario.detallesMovimientoInventario.length / itemsPorPagina);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,movimiento_inventario.clase.nombre,pagina,totalPaginas,usuario,
			usuario.empresa.imagen,usuario.empresa.nombre,movimiento_inventario.sucursal.nombre,movimiento_inventario.sucursal.direccion,
			(movimiento_inventario.sucursal.telefono1!=null?movimiento_inventario.sucursal.telefono1:"")+
		(movimiento_inventario.sucursal.telefono2!=null?"-"+movimiento_inventario.sucursal.telefono2:"")+
		(movimiento_inventario.sucursal.telefono3!=null?"-"+movimiento_inventario.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel,
		fecha_reporte,configuracionImpresion);
		this.dibujarCabeceraPdfMovimientoInventario(doc, movimiento_inventario, false, usuario,configuracionImpresion);
		for (var i = 0; i < movimiento_inventario.detallesMovimientoInventario.length && items <= itemsPorPagina; i++) {
			doc.font('Helvetica', 8);
				doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.codigo, 55, y, { width: 60 });
				doc.text(movimiento_inventario.detallesMovimientoInventario[i].cantidad, 115, y, { width: 70 });
				doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.unidad_medida, 153, y,{width:50});

				var longitudCaracteres = movimiento_inventario.detallesMovimientoInventario[i].producto.nombre.length;
				var yDesc = (longitudCaracteres <= 30) ? y : ((longitudCaracteres > 40 && longitudCaracteres <= 75) ? y : y - 7);
				if (usuario.empresa.usar_vencimientos && movimiento_inventario.detallesMovimientoInventario[i].producto.activar_vencimiento) {
					doc.font('Helvetica', 7);
					doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.nombre, 205, yDesc, { width: 160 });
					let fecha_vencimiento:any = movimiento_inventario.detallesMovimientoInventario[i].fecha_vencimiento?new Date(movimiento_inventario.detallesMovimientoInventario[i].fecha_vencimiento):null;
					fecha_vencimiento=fecha_vencimiento?(fecha_vencimiento.getDate() + "/" + (fecha_vencimiento.getMonth() + 1) + "/" + fecha_vencimiento.getFullYear()):"";
					doc.font('Helvetica', 8);
					doc.text(fecha_vencimiento, 375, y);
					doc.text(movimiento_inventario.detallesMovimientoInventario[i].lote, 435, y);
				} else {
					doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.nombre, 210, yDesc, { width: 250 });
				}
				doc.text(movimiento_inventario.detallesMovimientoInventario[i].precio_unitario?movimiento_inventario.detallesMovimientoInventario[i].precio_unitario.toFixed(2):0, 470, y);
				doc.text(movimiento_inventario.detallesMovimientoInventario[i].total.toFixed(2), 520, y);
			
			doc.rect(50, y - 10, 540, 30).stroke();
			y = y + 30;
			items++;

			if (items == itemsPorPagina) {
				totalAray = totalAray + items;
				if (totalAray != movimiento_inventario.detallesMovimientoInventario.length) {
					doc.addPage({ margin: 0, bufferPages: true });

					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,movimiento_inventario.clase.nombre,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,movimiento_inventario.sucursal.nombre,movimiento_inventario.sucursal.direccion,
						(movimiento_inventario.sucursal.telefono1!=null?movimiento_inventario.sucursal.telefono1:"")+
					(movimiento_inventario.sucursal.telefono2!=null?"-"+movimiento_inventario.sucursal.telefono2:"")+
					(movimiento_inventario.sucursal.telefono3!=null?"-"+movimiento_inventario.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraPdfMovimientoInventario(doc, movimiento_inventario, false, usuario,configuracionImpresion);
				}
			}
		}
		doc.font('Helvetica-Bold', 8);
		doc.text("TOTAL", 465, y - 5);
		doc.font('Helvetica', 8);
		doc.text(movimiento_inventario.total.toFixed(2), 520, y - 5);
		doc.rect(50, y - 10, 540, 20).stroke();
		if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO || movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO){
			doc.text("------------------------------------------", 180, y + 80);
			doc.text("------------------------------------------", 340, y + 80);
			doc.text("ENTREGA CONFORME", 190, y + 90);
			doc.text("RECIBO CONFORME", 350, y + 90);
		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
	}

	dibujarCabeceraPdfMovimientoInventario(doc, movimiento_inventario, existenDescuentos, usuario,configuracion_impresion) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,66,235,30,5).stroke();
		doc.text("Nº : ",355,68,{width:35,align:"right"});
		doc.text("FECHA : ",470,68,{width:35,align:"right"});
		doc.font('Helvetica', 8);
		doc.text(movimiento_inventario.numero_documento,400,68);
		doc.text(movimiento_inventario.fechaTexto,510,68);

		if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO ||
			movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO){
			if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO){
				doc.font('Helvetica-Bold',8);
				doc.text("DE SUCURSAL: ", 355, 78, { width:150 });
				doc.text("DE ALMACEN : ",355,88,{width:80});
				doc.font('Helvetica', 8);
				doc.text(movimiento_inventario.sucursal.nombre + " A " + movimiento_inventario.sucursalDestino.nombre,420,78);
				doc.text(movimiento_inventario.almacen.nombre+" A "+movimiento_inventario.almacenTraspaso.nombre,420,88);
			}
			if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO){
				doc.font('Helvetica-Bold',8);
				doc.text("DE SUCURSAL: ", 355, 78, { width:150 });
				doc.text("DE ALMACEN : ",355,88,{width:80});
				doc.font('Helvetica', 8);
				doc.text(movimiento_inventario.movimiento_inventario_origen.almacen.sucursal.nombre + " A " + movimiento_inventario.sucursal.nombre ,420,78);
				doc.text(movimiento_inventario.movimiento_inventario_origen.almacen.nombre+" A "+movimiento_inventario.almacen.nombre,420,88);
			}
		}else{
			if(usuario.empresa.usar_sucursales){
				doc.font('Helvetica-Bold',8);
				doc.text("SUCURSAL: ", 355, 78, { width:150 });
				doc.font('Helvetica', 8);
				doc.text(movimiento_inventario.sucursal.nombre,420,78);
			}
			if(usuario.empresa.usar_almacenes){
				doc.font('Helvetica-Bold',8);
				doc.text("ALMACEN : ",355,88,{width:80});
				doc.font('Helvetica', 8);
				doc.text(movimiento_inventario.almacen.nombre,420,88);
			}
		}
		
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		if (existenDescuentos) {
			doc.text("CODIGO", 55, 110);
			doc.text("CANT.", 105, 110);
			doc.text("UNID.", 135, 110);
			doc.text("DETALLE", 170, 110);
			doc.text("P. UNIT.", 300, 110);
			doc.text("TOTAL", 520, 110);
		} else {
			doc.text("CODIGO", 55, 110);
			doc.text("CANTIDAD", 115, 110);
			doc.text("UNIDAD", 160, 110);

			if (usuario.empresa.usar_vencimientos) {
				doc.text("DETALLE", 210, 110);
				doc.text("FECHA VENC.", 370, 110);
				doc.text("LOTE", 430, 110);
			} else {
				doc.text("DETALLE", 210, 110);
			}
			doc.text("P.UNIT.", 470, 110);
			doc.text("TOTAL", 520, 110);
		}

		doc.font('Helvetica', 7);
		var y=0;
		if (configuracion_impresion.papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (configuracion_impresion.papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (configuracion_impresion.papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (configuracion_impresion.papel == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}

		doc.text(movimiento_inventario.observacion?"OBS. : "+movimiento_inventario.observacion:"", 50, y-14,{width:310});

		if(configuracion_impresion.imprimir_usuario){
			doc.text("Usuario: " + movimiento_inventario.usuario.nombre_usuario, 0,y-14,{width:590,align:'right'});
		}
	}

	imprimirNotaMovimientoInventarioRollo(movimiento_inventario, papel, usuario) {
		var doc = new PDFDocument({ size: papel, margins: { top: 10, bottom: 10, left: 10, right: 20 } });
		var stream = doc.pipe(blobStream());
		doc.moveDown(2);
		doc.font('Helvetica-Bold', 8);
		doc.text(usuario.empresa.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica', 7);
		doc.text(movimiento_inventario.sucursal.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text(movimiento_inventario.sucursal.direccion.toUpperCase(), { align: 'center' });
		var telefono = (movimiento_inventario.sucursal.telefono1 != null ? movimiento_inventario.sucursal.telefono1 : "") +
			(movimiento_inventario.sucursal.telefono2 != null ? "-" + movimiento_inventario.sucursal.telefono2 : "") +
			(movimiento_inventario.sucursal.telefono3 != null ? "-" + movimiento_inventario.sucursal.telefono3 : "");
		doc.moveDown(0.4);
		doc.text("TELF.: " + telefono, { align: 'center' });
		doc.moveDown(0.4);
		doc.text(movimiento_inventario.almacen.sucursal.departamento.nombre.toUpperCase()+" - BOLIVIA", { align: 'center' });
		doc.moveDown(0.5);
		doc.font('Helvetica-Bold', 8);
		doc.text(movimiento_inventario.clase.nombre+" Nº "+movimiento_inventario.numero_documento, { align: 'center' });
		if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO ||
			movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO){
			if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO){
				doc.text("DE SUCURSAL: " + movimiento_inventario.sucursal.nombre + " A " + movimiento_inventario.sucursalDestino.nombre, { align: 'center' });
				doc.text("DE ALMACEN: " + movimiento_inventario.almacen.nombre+" A "+movimiento_inventario.almacenTraspaso.nombre, { align: 'center' });
			}
			if(movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO){
				doc.text("DE SUCURSAL: " + movimiento_inventario.movimiento_inventario_origen.almacen.sucursal.nombre + " A " + movimiento_inventario.sucursal.nombre, { align: 'center' });
				doc.text("DE ALMACEN: " + movimiento_inventario.movimiento_inventario_origen.almacen.nombre+" A "+movimiento_inventario.almacen.nombre, { align: 'center' });
			}
		}else{
			if(usuario.empresa.usar_sucursales){
				doc.text("SUCURSAL: "+movimiento_inventario.sucursal.nombre,{ align: 'center' });
			}
			if(usuario.empresa.usar_almacenes){
				doc.text("ALMACEN : "+movimiento_inventario.almacen.nombre, { align: 'center' });
			}
		}
		movimiento_inventario.fecha = new Date(movimiento_inventario.fecha);
		movimiento_inventario.fecha_texto = movimiento_inventario.fecha.getDate() + "/" + (movimiento_inventario.fecha.getMonth() + 1) + "/" + movimiento_inventario.fecha.getFullYear();
		doc.font('Helvetica', 7);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.text("FECHA : " + movimiento_inventario.fecha_texto, { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica-Bold', 7);
		var longitudCaracteres;
		var yDesc;
		doc.moveDown(0.4);
		doc.text("COD.   CANT.    DETALL                                                TOTAL", { align: 'left' });
		doc.moveDown(0.4);
		var y = doc.y, items = 0;
		for (var i = 0; i < movimiento_inventario.detallesMovimientoInventario.length; i++) {
			doc.font('Helvetica', 7);
			doc.moveDown(0.4);
			doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.codigo, 10, y, { width: 25 });
			doc.text(movimiento_inventario.detallesMovimientoInventario[i].cantidad, 38, y);
			doc.text(movimiento_inventario.detallesMovimientoInventario[i].producto.nombre, 57, y, { width: 130 });
			if (usuario.empresa.usar_vencimientos && movimiento_inventario.detallesMovimientoInventario[i].producto.activar_vencimiento) {
				//let fecha_vencimiento:any = salida.detallesMovimientoInventario[i].fecha_vencimiento?new Date(salida.detallesMovimientoInventario[i].fecha_vencimiento):null;
				//fecha_vencimiento=fecha_vencimiento?(fecha_vencimiento.getDate() + "/" + (fecha_vencimiento.getMonth() + 1) + "/" + fecha_vencimiento.getFullYear()):"";
				//doc.font('Helvetica', 8);
				//doc.text(fecha_vencimiento, 128, y);
				//doc.text(salida.detallesMovimientoInventario[i].lote, 164, y);
			}
			doc.text(movimiento_inventario.detallesMovimientoInventario[i].total.toFixed(2), 187, y, { width: 100 });
			y = y + 20;
			items++;
		}
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.x=10;
		doc.font('Helvetica', 7);
		var fechaActual = new Date();
		let min:any = fechaActual.getMinutes();
		if (min < 10) {
			min = "0" + min;
		}
		doc.text("  Usuario : " + usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", { align: 'center' });
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
	}

	async imprimirNotaCompra(compra,configuracionImpresion, usuario){
		var papel,itemsPorPagina;
		if (configuracionImpresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto;
			if (compra.detallesCompra.length <= 2) {
				alto = 400;
			} else {
				alto = 400 + (20 * (compra.detallesCompra.length - 2))
			}
			papel = [227, alto];

			var doc = new PDFDocument({ size: papel, margins: { top: 0, bottom: 0, left: 20, right: 20 } });
			var stream = doc.pipe(blobStream());
			await this.imprimirCompraRollo(compra,configuracionImpresion, doc, usuario);

			if (doc && stream) {
				doc.end();
				return await new Promise(function (resolve, reject) {
					stream.on('finish', function () {
						var fileURL = stream.toBlobURL('application/pdf');
						var w = window.open(fileURL, '_blank', 'location=no');
						setTimeout(function () {
							w.print();
						}, 500);
						resolve(stream.toBlob());
					});
					stream.on('error', reject)
				});
			}
		}else{
			if (configuracionImpresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_OFICIO) {
				papel = [612, 936];itemsPorPagina=19;
			} else if (configuracionImpresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_CARTA) {
				papel = [612, 792];itemsPorPagina=15;
			} else if (configuracionImpresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
				papel = [612, 792];itemsPorPagina=5;
			} else if (configuracionImpresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
				papel = [612, 936];itemsPorPagina=8;
			}
			this.imprimirCompraCartaOficio(compra, papel,itemsPorPagina, usuario,configuracionImpresion);
		}
	}

	async imprimirCompraRollo(compra,configuracionImpresion, doc, usuario) {
		doc.moveDown(2);
		doc.font('Helvetica-Bold', 8);
		doc.text(usuario.empresa.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica', 7);
		doc.text(compra.sucursal.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text(compra.sucursal.direccion.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		var telefono = (compra.sucursal.telefono1 != null ? compra.sucursal.telefono1 : "") +
			(compra.sucursal.telefono2 != null ? "-" + compra.sucursal.telefono2 : "") +
			(compra.sucursal.telefono3 != null ? "-" + compra.sucursal.telefono3 : "");
		doc.text("TELF.: " + telefono, { align: 'center' });
		doc.moveDown(0.4);
		doc.text("COCHABAMBA - BOLIVIA", { align: 'center' });
		doc.moveDown(0.5);
		doc.font('Helvetica-Bold', 8);
		doc.text((compra.detallesCompra[0].id_producto?"NOTA DE COMPRA":"NOTA DE EGRESO"), { align: 'center' });
		doc.font('Helvetica', 7);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.text("RECIBO "+compra.factura, { align: 'center' });
		doc.moveDown(0.4);
		doc.text("DOC. Nº (Sistema) : "+compra.numero_documento, { align: 'center' });
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.6);
		doc.text("FECHA : " + compra.fechaTexto+"                  "+(compra.proveedor.telefono1?" TELF. "+compra.proveedor.telefono1:""), { align: 'left' });
		doc.moveDown(0.4);
		doc.text("SEÑOR(ES) : " + compra.proveedor.razon_social, { align: 'left' });
		doc.moveDown(0.4);
		doc.text("NIT/CI : " + compra.proveedor.nit, { align: 'left' });
		doc.moveDown(0.4);
		doc.text("--------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.2);
		doc.text("DETALLE", { align: 'center' });
		doc.moveDown(0.2);
		doc.text("--------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		var y = doc.y, sumaDescuento = 0, sumaRecargo = 0, sumaIce = 0, sumaExcento = 0;
		for (var i = 0; i < compra.detallesCompra.length; i++) {
			let longitud_nombre_producto=(compra.detallesCompra[i].producto?compra.detallesCompra[i].producto.nombre.length:(compra.detallesCompra[i].combo?compra.detallesCompra[i].combo.nombre.length:(compra.detallesCompra[i].centroCosto?compra.detallesCompra[i].centroCosto.nombre.length+(compra.detallesCompra[i].descripcion?compra.detallesCompra[i].descripcion.length:0):0)));
			if (longitud_nombre_producto > 40) {
				doc.fontSize(6);
			}
			let nombre_producto=(compra.detallesCompra[i].producto)?(compra.detallesCompra[i].producto.nombre+" "+(compra.detallesCompra[i].descripcion?"("+compra.detallesCompra[i].descripcion+")":"")):(compra.detallesCompra[i].combo?compra.detallesCompra[i].combo.nombre+" "+(compra.detallesCompra[i].descripcion?"("+compra.detallesCompra[i].descripcion+")":""):(compra.detallesCompra[i].centroCosto?compra.detallesCompra[i].centroCosto.nombre+" "+(compra.detallesCompra[i].descripcion?"("+compra.detallesCompra[i].descripcion+")":""):""));
			doc.text(nombre_producto, 20, y, { width: 100 });
			doc.fontSize(7);
			doc.text("("+compra.detallesCompra[i].cantidad+" x ", 130, y);
			doc.text(compra.detallesCompra[i].costo_unitario.toFixed(2)+")", 150, y);
			doc.text("-"+compra.detallesCompra[i].descuento.toFixed(2), 130, y+7);
			doc.text(compra.detallesCompra[i].total.toFixed(2), 175, y,{align:'right'});
			sumaDescuento = sumaDescuento + (compra.detallesCompra[i].tipo_descuento ? (compra.detallesCompra[i].importe * (compra.detallesCompra[i].descuento / 100)) : compra.detallesCompra[i].descuento);
			y = y + 20;
		}
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.x = 20;
		doc.text("--------------", { align: 'right' });

		let sub_total=compra.importe-sumaDescuento;
		let descuento_global=(usuario.empresa.usar_descuento_global?compra.descuento:0);

		doc.moveDown(0.4);
		doc.text("SUBTOTAL Bs. " + sub_total.toFixed(2), 0, doc.y,{align:'right'});
		doc.text("DESCUENTO Bs. " + descuento_global.toFixed(2), 0, doc.y,{align:'right'});
		doc.text("TOTAL Bs. " + compra.total.toFixed(2), 0, doc.y,{align:'right'});
		doc.font('Helvetica-Bold');
		doc.x = 20;
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.font('Helvetica');
		doc.text("SON: " + (compra.total_literal?compra.total_literal:compra.numero_literal), { align: 'left' });
		doc.x=20;
		doc.moveDown(0.4);
		doc.moveDown(0.4);

		var fechaActual = new Date();
		let min:any = fechaActual.getMinutes();
		if (min < 10) {
			min = "0" + min;
		}
		doc.text("  Usuario : " + usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", { align: 'center' });
		
		if(!compra.activa){
			let imagen_nulado=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/anulado.png");
			doc.image(imagen_nulado, 15, 200, { width: 200, height: 200 });
		}
	}

	imprimirCompraCartaOficio(compra, papel,itemsPorPagina, usuario,configuracionImpresion){
		var doc = new PDFDocument({size:papel,margin:20});
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		// draw some text

		var existenDescuentos=Util.verificarDescuentos(compra.detallesCompra);
		var y=170,totalAray=0,items=0,pagina=1,totalPaginas=Math.ceil(compra.detallesCompra.length/itemsPorPagina);
		var titulo=compra.detallesCompra[0].id_producto?"NOTA DE COMPRA":"NOTA DE EGRESO";
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
				usuario.empresa.imagen,usuario.empresa.nombre,compra.sucursal.nombre,compra.sucursal.direccion,
				(compra.sucursal.telefono1!=null?compra.sucursal.telefono1:"")+
			(compra.sucursal.telefono2!=null?"-"+compra.sucursal.telefono2:"")+
			(compra.sucursal.telefono3!=null?"-"+compra.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_compra,
			fecha_reporte,configuracionImpresion);
		this.dibujarCabeceraImpresionCompra(doc,compra,existenDescuentos,usuario,configuracionImpresion);
		for(var i=0;i<compra.detallesCompra.length && items <= itemsPorPagina;i++){
			doc.font('Helvetica',8);				
			if(existenDescuentos){
				if(compra.detallesCompra[i].producto){
					doc.text(compra.detallesCompra[i].producto.codigo,55,y,{width:70});
					
					doc.text(compra.detallesCompra[i].producto.unidad_medida,135,y);
					doc.text(compra.detallesCompra[i].producto.nombre,170,y-6,{width:130});
					
				}else{
					doc.text(compra.detallesCompra[i].centroCosto.nombre+" - "+compra.detallesCompra[i].descripcion,170,y-6,{width:130});
				}
				doc.text(compra.detallesCompra[i].cantidad,110,y);
				if(usuario.empresa.usar_vencimientos && compra.detallesCompra[i].producto.activar_vencimiento){
					compra.detallesCompra[i].fecha_vencimiento=compra.detallesCompra[i].fecha_vencimiento?new Date(compra.detallesCompra[i].fecha_vencimiento):null;
					compra.detallesCompra[i].fechaVencimientoTexto=compra.detallesCompra[i].fecha_vencimiento?compra.detallesCompra[i].fecha_vencimiento.getDate()+"/"+(compra.detallesCompra[i].fecha_vencimiento.getMonth()+1)+"/"+compra.detallesCompra[i].fecha_vencimiento.getFullYear():"";
					doc.text(compra.detallesCompra[i].fechaVencimientoTexto,300,y);
					doc.text(compra.detallesCompra[i].lote,340,y);
				}
				doc.text(compra.detallesCompra[i].costo_unitario.toFixed(2),380,y);
				doc.text(compra.detallesCompra[i].importe.toFixed(2),415,y);
				doc.text(compra.detallesCompra[i].descuento.toFixed(2),455,y);
				doc.text(compra.detallesCompra[i].ice.toFixed(2),490,y);
				doc.text(compra.detallesCompra[i].total.toFixed(2),520,y);					
			}else{
				if(compra.detallesCompra[i].producto){
					doc.text(compra.detallesCompra[i].producto.codigo,55,y,{width:70});
					doc.text(compra.detallesCompra[i].producto.unidad_medida,160,y-5,{width:60});
					var longitudCaracteres=compra.detallesCompra[i].producto.nombre.length;
					var yDesc=(longitudCaracteres<=45)?y:((longitudCaracteres>45&&longitudCaracteres<=90)?y-4:y-11);
					if(usuario.empresa.usar_vencimientos && compra.detallesCompra[i].producto.activar_vencimiento){
						compra.detallesCompra[i].fecha_vencimiento=compra.detallesCompra[i].fecha_vencimiento?new Date(compra.detallesCompra[i].fecha_vencimiento):null;
						compra.detallesCompra[i].fechaVencimientoTexto=compra.detallesCompra[i].fecha_vencimiento?compra.detallesCompra[i].fecha_vencimiento.getDate()+"/"+(compra.detallesCompra[i].fecha_vencimiento.getMonth()+1)+"/"+compra.detallesCompra[i].fecha_vencimiento.getFullYear():"";
						doc.text(compra.detallesCompra[i].fechaVencimientoTexto,400,y);
						doc.text(compra.detallesCompra[i].lote,460,y);
						doc.text(compra.detallesCompra[i].producto.nombre,210,yDesc,{width:185});
					}else{
						doc.text(compra.detallesCompra[i].producto.nombre,210,yDesc,{width:225});
					}
				}else{
					doc.text(compra.detallesCompra[i].centroCosto.nombre+" - "+compra.detallesCompra[i].descripcion,210,y-6.5,{width:185});
				}
				doc.text(compra.detallesCompra[i].cantidad,140,y);
				doc.text(compra.detallesCompra[i].costo_unitario.toFixed(2),500,y);
				doc.text(compra.detallesCompra[i].total.toFixed(2),540,y);
			}
			
			doc.rect(50,y-15,540,30).stroke();
			
			y=y+30;
			
			items++;
			
			if(items==itemsPorPagina){
				totalAray=totalAray+items;
				if(totalAray!=compra.detallesCompra.length)
				{
				/* var currentDate=new Date();
				doc.text("Usuario: "+$scope.usuario.nombre_usuario,50,y);
				doc.text("Usuario: "+$scope.usuario.nombre_usuario+"   "+"Fecha:"+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear(),50,y); 
				*/

					doc.font('Helvetica', 8);
					var fechaActual=new Date();
					let min:any = fechaActual.getMinutes();
					if (min < 10) {
						min = "0" + min;
					}
					doc.text("  Usuario : " + usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", 70,y+25);

					//doc.text(pagina + " de " + totalPaginas, 540, y+25);

					doc.addPage({size:papel,margin:20});
					y=170;
					items=0;
					pagina=pagina+1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,compra.sucursal.nombre,compra.sucursal.direccion,
						(compra.sucursal.telefono1!=null?compra.sucursal.telefono1:"")+
					(compra.sucursal.telefono2!=null?"-"+compra.sucursal.telefono2:"")+
					(compra.sucursal.telefono3!=null?"-"+compra.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_compra,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraImpresionCompra(doc,compra,existenDescuentos,usuario,configuracionImpresion);
				}
			}
		}
		
			doc.font('Helvetica-Bold',8);
			doc.text("TOTAL : ",455,y+10);
		
		doc.font('Helvetica',8);
		doc.text(compra.total.toFixed(2),520,y+10);
		doc.text("SON : "+compra.numero_literal,55,y+10);
		//doc.text(pagina + " de " + totalPaginas, 540, y+25);
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}

	dibujarCabeceraImpresionCompra(doc,compra,existenDescuentos,usuario,configuracion_impresion){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(380,55,195,43,5).stroke();
		doc.text("NIT/CI : "+compra.proveedor.nit,390,60);
		doc.text(compra.tipo_documento.nombre+" : "+compra.factura,390,70);
		doc.text("DOC. No (Sistema) : "+compra.numero_documento,480,70);

		if(usuario.empresa.usar_sucursales){
			doc.text("Sucursal: "+compra.sucursal.nombre,380,80);
		}
		if(usuario.empresa.usar_almacenes && compra.almacen){
			doc.text("Almacen: "+compra.almacen.nombre,490,80);
		}

		if(usuario.empresa.usar_caja_general && compra.movimiento_caja){
			doc.text("Caja: "+compra.movimiento_caja.caja.nombre,390,90);
		}
		
		doc.text("FECHA : ",60,110);
		doc.text("SEÑOR(ES) : ",60,120);
		doc.text("NIT/CI : ",360,110);
	
		doc.text(compra.fechaTexto,120,110);
		doc.text(compra.proveedor.razon_social,120,120);
		doc.text(compra.proveedor.nit,400,110);
		
		doc.roundedRect(50,130,540,25,5).fillOpacity(0.8).stroke();
		//doc.roundedRect(50,225,540,792-118-225,5).stroke();
		
		if(existenDescuentos){
			doc.text("CODIGO",55,140);
			doc.text("CANT.",105,140);
			doc.text("UNID.",135,140);
			doc.text("DETALLE",170,140);
			if(usuario.empresa.usar_vencimientos){
				doc.text("FECHA VENC.",300,135,{width:50});
				doc.text("LOTE",340,140);
			}
			doc.text("P. UNIT.",380,140);
			doc.text("IMPORTE",415,140);
			doc.text("DESC.",455,140);
			doc.text("ICE",490,140);
			doc.text("TOTAL",520,140);
		}else{
			doc.text("CODIGO",55,140);
			doc.text("CANT.",135,140);
			doc.text("UNID.",165,140);
			if(usuario.empresa.usar_vencimientos){
				doc.text("DETALLE",210,140);
				doc.text("FECHA VENC.",400,135,{width:50});
				doc.text("LOTE",450,140);					
			}else{
				doc.text("DETALLE",210,140);
			}
			doc.text("P.UNIT.",495,140);
			doc.text("TOTAL",540,140);
		}
		
		doc.font('Helvetica', 7);
		var y=0;
		if (configuracion_impresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (configuracion_impresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (configuracion_impresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (configuracion_impresion.papel_nota_compra == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}
		let texto_credito=configuracion_impresion.datos_credito?(compra.tipoPago.nombre_corto=="CRE")?("A cuenta: Bs "+compra.a_cuenta+", Saldo: Bs "+compra.saldo):"":"";
		let observacion=(compra.observacion)?compra.observacion:"";
		observacion=(texto_credito.length>0 || observacion.length>0)?"OBS. : "+observacion+" "+texto_credito:"";
		doc.text(observacion, 50, y-14,{width:310});
		if(configuracion_impresion.usuario || configuracion_impresion.imprimir_usuario){
			doc.text("Usuario Comprador: " + compra.usuario.persona.nombre_completo, 0,y-14,{width:590,align:'right'});
		}
	}

	imprimirNotaObsequioCartaOficio (despacho,configuracionImpresion,usuario) {
		var titulo="NOTA DE ENTREGA (Obsequio)";
		var fecha_reporte=new Date();		
		var papel=[612, 792];
			var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		// draw some tex			

		var itemsPorPagina = 16;
		/*if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 19;
		} else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 16;
		} else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 3;
		}else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina = 5;
		}else if (configuracionImpresion.papel_factura == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			itemsPorPagina = 5;
		}*/

		for (var j = 0; j < 1; j++) {
			if (j != 0) {
				doc.addPage({ size: papel, margin: 10 });
			}

			doc.font('Helvetica', 8);
			var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(despacho.detallesDespacho.length / itemsPorPagina);
			this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
				usuario.empresa.imagen,usuario.empresa.nombre,despacho.almacen.sucursal.nombre,despacho.almacen.sucursal.direccion,
				(despacho.almacen.sucursal.telefono1!=null?despacho.almacen.sucursal.telefono1:"")+
			(despacho.almacen.sucursal.telefono2!=null?"-"+despacho.almacen.sucursal.telefono2:"")+
			(despacho.almacen.sucursal.telefono3!=null?"-"+despacho.almacen.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
			fecha_reporte,configuracionImpresion);
			this.dibujarCabeceraDespachoCartaOficio(doc, despacho,usuario,configuracionImpresion);
			for (var i = 0; i < despacho.detallesDespacho.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 8);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.codigo:despacho.detallesDespacho[i].combo.codigo, 55, y);
				doc.text(despacho.detallesDespacho[i].cantidad, 110, y);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.unidad_medida:despacho.detallesDespacho[i].combo.unidad_medida, 165, y);
				var longitudCaracteres = (despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.nombre.length:despacho.detallesDespacho[i].combo.nombre.length;
				var yDesc = (longitudCaracteres <= 45) ? y : ((longitudCaracteres > 45 && longitudCaracteres <= 90) ? y - 4 : y - 11);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.nombre:despacho.detallesDespacho[i].combo.nombre, 220, yDesc, { width: 225 });
				//doc.text(despacho.detallesDespacho[i].precio_unitario.toFixed(2), 450, y);
				//doc.text(despacho.detallesDespacho[i].total.toFixed(2), 520, y);
				//if (completa || vacia) {
					doc.rect(50, y - 10, 540, 20).stroke();
				//}
				y = y + 20;
				items++;

				if (items == itemsPorPagina && (i+1) < despacho.detallesDespacho.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,despacho.almacen.sucursal.nombre,despacho.almacen.sucursal.direccion,
						(despacho.almacen.sucursal.telefono1!=null?despacho.almacen.sucursal.telefono1:"")+
					(despacho.almacen.sucursal.telefono2!=null?"-"+despacho.almacen.sucursal.telefono2:"")+
					(despacho.almacen.sucursal.telefono3!=null?"-"+despacho.almacen.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraDespachoCartaOficio(doc, despacho, usuario,configuracionImpresion);
				}
			}
			
			doc.font('Helvetica-Bold', 8);
			//doc.text((venta.transaccion?(venta.transaccion.nombre+" Nro. Doc.: "+ venta.factura):"") +(venta.observacion?" OBS. : "+venta.observacion:""), 270, y+15,{width:310,align:'right'});						
			doc.font('Helvetica', 7);
			
			doc.text("-----------------------------------          -----------------------------------          -----------------------------------", 270, y+50,{width:300,align:'right'});
			doc.text("Responsable Almacen                Responsable                              Cliente", 290, y+60,{width:300,align:'left'});
			doc.text("Nombre                                       Nombre                                      Nombre", 290, y+70,{width:300,align:'left'});
			doc.text("C.I.                                               C.I.                                                C.I.", 290, y+80,{width:300,align:'left'});
		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	dibujarCabeceraDespachoCartaOficio(doc, despacho, usuario,configuracion_impresion) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,48,5).stroke();
		doc.text("Nº : ",355,55);
		doc.text("FECHA : ",395,55,{width:35});
		doc.text("ALMACEN : ",475,55);
		doc.text("CLIENTE : ",355,65,{width:40,align:"right"});
		doc.text("Nº P.: ",480,65,{width:80,align:"right"});
		doc.text("Nº V.: ",480,75,{width:80,align:"right"});
		if(usuario.empresa.usar_clientes_contactos){
			doc.text("RESP. : ",355,75,{width:40,align:"right"});
			doc.text("TELF. - CEL. : ",355,83);
			doc.text("DIRECCIÓN : ",355,91);
		}
		//doc.text("RAZÓN SOCIAL : ",355,85,{width:70,align:"right"});
		doc.font('Helvetica', 8);
		doc.text(despacho.numero_documento,375,55);
		despacho.fecha=new Date(despacho.fecha);
		doc.text(despacho.fecha.getDate()+"/"+(despacho.fecha.getMonth()+1)+"/"+despacho.fecha.getFullYear(),430,55);
		doc.text(despacho.almacen.nombre,520,55);
		doc.text(despacho.pedido.cliente.identificacion,400,65);
		doc.text(despacho.pedido.numero_pedido,560,65);
		doc.text(despacho.venta.factura,560,75);
		if(usuario.empresa.usar_clientes_contactos){
			doc.text(despacho.pedido.contacto_cliente.persona.nombre_completo,400,75);
			doc.text(despacho.pedido.contacto_cliente.persona.telefono+" - "+despacho.pedido.contacto_cliente.persona.telefono_movil,420,83);
			doc.text(despacho.pedido.contacto_cliente.persona.direccion,420,91);
		}
		//doc.text(adicion?adicion:"", 390, 50);
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		
		doc.text("CODIGO", 55, 115);
		doc.text("CANT.", 100, 115);
		doc.text("UNID.", 150, 115);
		doc.text("DETALLE", 200, 115);
		if(configuracion_impresion.datos_economicos_despacho){
			doc.text("P.UNIT.", 420, 115);
			doc.text("IMP.", 460, 115);
			doc.text("DESC.", 500, 115);
			doc.text("TOTAL", 540, 115);
		}
		
		//doc.text("P.UNIT.", 460, 115);
		//doc.text("TOTAL", 520, 115);

		doc.font('Helvetica', 7);
		var y=0;
		/*if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {*/
			y=760;
		/*} else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (configuracion_impresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}*/
		
		if(configuracion_impresion.usuario || configuracion_impresion.imprimir_usuario){
			doc.text("Usuario Vendedor: " + despacho.usuario.persona.nombre_completo, 0,y-14,{width:590,align:'right'});
		}
		
		//doc.text(venta.observacion?"OBS. : "+venta.observacion:"", 50, y-14,{width:310});
		//doc.text("Usuario Vendedor: " + despacho.usuario.nombre_usuario, 0,y-14,{width:590,align:'right'});
	}

	imprimirNotaDespachoCartaOficio(despacho,configuracionImpresion,usuario) {
		var titulo="NOTA DE ENTREGA";
		var fecha_reporte=new Date();				
		var papel=[612, 792];
			var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		// draw some tex			

		var itemsPorPagina = 0;
		/*if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 19;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {*/
			itemsPorPagina = 16;
		/*} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 3;
		}else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina = 7;
		}else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			itemsPorPagina = 5;
		}*/

		for (var j = 0; j < 1; j++) {
			if (j != 0) {
				doc.addPage({ size: papel, margin: 10 });
			}

			doc.font('Helvetica', 8);
			var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(despacho.detallesDespacho.length / itemsPorPagina);
			this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
				usuario.empresa.imagen,usuario.empresa.nombre,despacho.almacen.sucursal.nombre,despacho.almacen.sucursal.direccion,
				(despacho.almacen.sucursal.telefono1!=null?despacho.almacen.sucursal.telefono1:"")+
			(despacho.almacen.sucursal.telefono2!=null?"-"+despacho.almacen.sucursal.telefono2:"")+
			(despacho.almacen.sucursal.telefono3!=null?"-"+despacho.almacen.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
			fecha_reporte,configuracionImpresion);
			this.dibujarCabeceraDespachoCartaOficio(doc, despacho, usuario,configuracionImpresion);
			for (var i = 0; i < despacho.detallesDespacho.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 8);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.codigo:despacho.detallesDespacho[i].combo.codigo, 55, y);
				doc.text(despacho.detallesDespacho[i].cantidad, 110, y);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.unidad_medida:despacho.detallesDespacho[i].combo.unidad_medida, 135, y);
				var longitudCaracteres = (despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.nombre.length:despacho.detallesDespacho[i].combo.nombre.length;
				var yDesc = (longitudCaracteres <= 45) ? y : ((longitudCaracteres > 45 && longitudCaracteres <= 90) ? y - 8 : y - 11);
				doc.text((despacho.detallesDespacho[i].producto)?despacho.detallesDespacho[i].producto.nombre:despacho.detallesDespacho[i].combo.nombre, 200, yDesc, { width: 225 });

				if(configuracionImpresion.datos_economicos_despacho){
					doc.text(despacho.detallesDespacho[i].precio_unitario.toFixed(2), 420, y,{width:35});
					doc.text(despacho.detallesDespacho[i].importe.toFixed(2), 460, y,{width:35});
					doc.text(despacho.detallesDespacho[i].tipo_descuento ? "%" : "Bs", 500, y - 7);
					doc.text(despacho.detallesDespacho[i].descuento.toFixed(2), 500, y);
					doc.text(despacho.detallesDespacho[i].total.toFixed(2), 540, y,{width:35});
				}

				//doc.text(despacho.detallesDespacho[i].precio_unitario.toFixed(2), 450, y);
				//doc.text(despacho.detallesDespacho[i].total.toFixed(2), 520, y);
				//if (completa || vacia) {
					doc.rect(50, y - 10, 540, 20).stroke();
				//}
				y = y + 20;
				items++;

				if (items == itemsPorPagina && (i+1) < despacho.detallesDespacho.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,despacho.almacen.sucursal.nombre,despacho.almacen.sucursal.direccion,
						(despacho.almacen.sucursal.telefono1!=null?despacho.almacen.sucursal.telefono1:"")+
					(despacho.almacen.sucursal.telefono2!=null?"-"+despacho.almacen.sucursal.telefono2:"")+
					(despacho.almacen.sucursal.telefono3!=null?"-"+despacho.almacen.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraDespachoCartaOficio(doc, despacho, usuario,configuracionImpresion);
				}
			}

			doc.font('Helvetica-Bold', 8);
			doc.roundedRect(50,y-10,540,14,5).stroke();
			if(configuracionImpresion.datos_economicos_despacho){
				if(despacho.descuento>0){
					doc.text("DESC. "+(despacho.tipo_descuento?"%":"Bs"), 420, y-4);
					doc.text(despacho.descuento.toFixed(2), 450, y-4,{align:"right",width:35});
				}
				doc.text("TOTAL", 500, y-4);
				doc.text(despacho.total.toFixed(2), 530, y-4,{align:"right",width:35});
			}
			doc.font('Helvetica', 8);
			doc.text(configuracionImpresion.pie_texto_despacho, 50, y+90,{width:540,align:'left'});
			doc.font('Helvetica', 7);
			doc.text("-----------------------------------                                                                 -----------------------------------                                                                 -----------------------------------", 50, y+45,{width:540,align:'left'});
			doc.text("Responsable Almacen                                                                       Responsable                                                                                     Cliente", 50, y+50,{width:540,align:'left'});
			doc.text("Nombre                                                                                              Nombre                                                                                             Nombre", 50, y+65,{width:540,align:'left'});
			doc.text("C.I.                                                                                                      C.I.                                                                                                       C.I.", 50, y+80,{width:540,align:'left'});
			

		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	dibujarCabeceraDevolucionCartaOficio(doc, devolucion_venta, usuario,tamano_papel) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,48,5).stroke();
		doc.text("Nº : ",355,55);
		doc.text("FECHA : ",395,55,{width:35});
		doc.text("ALMACEN : ",475,55);
		doc.text("CLIENTE : ",355,65,{width:40,align:"right"});
		doc.text("Nº V.: ",480,75,{width:80,align:"right"});
		if(usuario.empresa.usar_clientes_contactos){
			doc.text("RESP. : ",355,75,{width:40,align:"right"});
			doc.text("TELF. - CEL. : ",355,83);
			doc.text("DIRECCIÓN : ",355,91);
		}
		//doc.text("RAZÓN SOCIAL : ",355,85,{width:70,align:"right"});
		doc.font('Helvetica', 8);
		doc.text(devolucion_venta.numero_documento,375,55);
		devolucion_venta.fecha=new Date(devolucion_venta.fecha);
		doc.text(devolucion_venta.fecha.getDate()+"/"+(devolucion_venta.fecha.getMonth()+1)+"/"+devolucion_venta.fecha.getFullYear(),430,55);
		doc.text(devolucion_venta.venta.almacen.nombre,520,55);
		doc.text(devolucion_venta.venta.cliente.identificacion,400,65);
		doc.text(devolucion_venta.venta.factura,560,75);
		if(usuario.empresa.usar_clientes_contactos && devolucion_venta.venta.contacto_cliente){
			doc.text(devolucion_venta.venta.contacto_cliente.persona.nombre_completo,400,75);
			doc.text(devolucion_venta.venta.contacto_cliente.persona.telefono+" - "+devolucion_venta.venta.contacto_cliente.persona.telefono_movil,420,83);
			doc.text(devolucion_venta.venta.contacto_cliente.persona.direccion,420,91);
		}
		//doc.text(adicion?adicion:"", 390, 50);
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		
		doc.text("CODIGO", 55, 115);
		doc.text("CANT.", 120, 115);
		doc.text("UNID.", 160, 115);
		doc.text("DETALLE", 200, 115);
		
		//doc.text("P.UNIT.", 460, 115);
		//doc.text("TOTAL", 520, 115);

		doc.font('Helvetica', 7);
		var y=0;
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}
		
		//doc.text(venta.observacion?"OBS. : "+venta.observacion:"", 50, y-14,{width:310});
		//doc.text("Usuario Vendedor: " + despacho.usuario.nombre_usuario, 0,y-14,{width:590,align:'right'});
	}

	imprimirNotaDevolucionCartaOficio(devolucion_venta,configuracionImpresion,usuario) {
		var titulo="NOTA DE DEVOLUCIÓN";
		var fecha_reporte=new Date();				
		var papel=[612, 792];
			var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		// draw some tex			

		var itemsPorPagina = 0;
		if (configuracionImpresion.tamanoPapelNotaVenta.nombre_corto == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 19;
		} else if (configuracionImpresion.tamanoPapelNotaVenta.nombre_corto == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 25;
		} else if (configuracionImpresion.tamanoPapelNotaVenta.nombre_corto == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 3;
		}else if (configuracionImpresion.tamanoPapelNotaVenta.nombre_corto == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina = 7;
		}else if (configuracionImpresion.tamanoPapelNotaVenta.nombre_corto == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			itemsPorPagina = 5;
		}

		for (var j = 0; j < 1; j++) {
			if (j != 0) {
				doc.addPage({ size: papel, margin: 10 });
			}

			doc.font('Helvetica', 8);
			var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(devolucion_venta.detalles_devolucion.length / itemsPorPagina);
			this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
				usuario.empresa.imagen,usuario.empresa.nombre,devolucion_venta.venta.sucursal.nombre,devolucion_venta.venta.sucursal.direccion,
				(devolucion_venta.venta.sucursal.telefono1!=null?devolucion_venta.venta.sucursal.telefono1:"")+
			(devolucion_venta.venta.sucursal.telefono2!=null?"-"+devolucion_venta.venta.sucursal.telefono2:"")+
			(devolucion_venta.venta.sucursal.telefono3!=null?"-"+devolucion_venta.venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.tamanoPapelNotaVenta.nombre_corto,
			fecha_reporte,configuracionImpresion);
			this.dibujarCabeceraDevolucionCartaOficio(doc, devolucion_venta, usuario,configuracionImpresion.tamanoPapelNotaVenta.nombre_corto);
			for (var i = 0; i < devolucion_venta.detalles_devolucion.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 8);
				doc.text((devolucion_venta.detalles_devolucion[i].producto)?devolucion_venta.detalles_devolucion[i].producto.codigo:devolucion_venta.detalles_devolucion[i].combo.codigo, 55, y);
				doc.text(devolucion_venta.detalles_devolucion[i].cantidad, 110, y);
				doc.text((devolucion_venta.detalles_devolucion[i].producto)?devolucion_venta.detalles_devolucion[i].producto.unidad_medida:devolucion_venta.detalles_devolucion[i].combo.unidad_medida, 165, y);
				var longitudCaracteres = (devolucion_venta.detalles_devolucion[i].producto)?devolucion_venta.detalles_devolucion[i].producto.nombre.length:devolucion_venta.detalles_devolucion[i].combo.nombre.length;
				var yDesc = (longitudCaracteres <= 45) ? y : ((longitudCaracteres > 45 && longitudCaracteres <= 90) ? y - 4 : y - 11);
				doc.text((devolucion_venta.detalles_devolucion[i].producto)?devolucion_venta.detalles_devolucion[i].producto.nombre:devolucion_venta.detalles_devolucion[i].combo.nombre, 220, yDesc, { width: 225 });
				//doc.text(despacho.detallesDespacho[i].precio_unitario.toFixed(2), 450, y);
				//doc.text(despacho.detallesDespacho[i].total.toFixed(2), 520, y);
				//if (completa || vacia) {
					doc.rect(50, y - 10, 540, 20).stroke();
				//}
				y = y + 20;
				items++;

				if (items == itemsPorPagina && (i+1) < devolucion_venta.detalles_devolucion.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,devolucion_venta.venta.sucursal.nombre,devolucion_venta.venta.sucursal.direccion,
						(devolucion_venta.venta.sucursal.telefono1!=null?devolucion_venta.venta.sucursal.telefono1:"")+
					(devolucion_venta.venta.sucursal.telefono2!=null?"-"+devolucion_venta.venta.sucursal.telefono2:"")+
					(devolucion_venta.venta.sucursal.telefono3!=null?"-"+devolucion_venta.venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.tamanoPapelNotaVenta.nombre_corto,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraDevolucionCartaOficio(doc, devolucion_venta, usuario,configuracionImpresion.tamanoPapelNotaVenta.nombre_corto);
				}
			}
		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	imprimirReporteLecturacionSocios(acciones_agua, usuario, filtro_lecturacion){
		let papel = [612, 792];
		const doc = new PDFDocument({ size: papel, margin: 10 });
		const stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		var x = 70;
		var y = 50;
		var itemsPorPagina = 0;
		var titulo="LECTURACION SERVICIO";
		//dibujar cabecera reporte lecturacion 
		doc.moveDown();
		doc.roundedRect(385,50,200,47,5)
		.strokeColor('#808080')
		.stroke();
		doc.font('Times-Roman', 11)
		doc.text('Periodo:', 390, 56)
		doc.text(filtro_lecturacion.gestion.nombre, 450, 56)
		doc.text('Mes:', 390, 71)
		doc.text(filtro_lecturacion.mes.nombre, 450, 71)
		doc.text('Categoria:', 390, 86)
		doc.text(filtro_lecturacion.categoria.nombre, 450, 86);

		
		doc.font('Helvetica', 8)
			.text('Nº', 59, 118)
			.text('Codigo Medidor', 77, 112, {width:30})
			.text('Codigo Vecino', 116, 112, {width:35})
			.text('Nombres Cliente', 180, 118)
			.text('Categoria', 277, 118)
			.text('Gestion', 327, 118)
			.text('Mes', 385, 118)
			.text('Lect. Anterior', 430, 112, {width:35} )
			.text('Lect. Actual', 469, 112, {width:35})
			.text('Consumo', 516, 118);
		/*doc.rect(60,125,105,25,)
		.strokeColor('#808080')
		.stroke();*/
		var distancia_items=29;

		itemsPorPagina = 20;

		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(acciones_agua.length / itemsPorPagina);
		doc.font('Helvetica', 8);

		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
			(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
		(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
		(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{});
		doc.font('Helvetica', 8);

		/*for(var i = 0; i <acciones_agua.length; i++){
			doc.text(acciones_agua[i].codigo_medidor, 300, 500)
			doc.text(acciones_agua[i].vecino.cliente.codigo, x, y+50);

			}*/
		
		for(var i = 0; i < acciones_agua.length && items <= itemsPorPagina; i++){
			doc.text((i+1), 55, y); //para contar nùmeros
			doc.text(acciones_agua[i].codigo_medidor, 80, y)
			doc.text(acciones_agua[i].vecino.cliente.codigo, 110, y)
			doc.text(acciones_agua[i].vecino.cliente.identificacion, 160, y, {width:100, align: 'left'})
			doc.text(acciones_agua[i].categoria.nombre, 280, y)
			doc.text(acciones_agua[i].lecturas_medidor[0].gestion.nombre, 330, y, {width:30, align:'left'});
			doc.text(acciones_agua[i].lecturas_medidor[0].mes.nombre, 370, y);
			doc.text(acciones_agua[i].lecturas_medidor[0].lectura_anterior, 435, y);
			doc.text(acciones_agua[i].lecturas_medidor[0].lectura_actual, 477, y);
			doc.text(acciones_agua[i].lecturas_medidor[0].consumo_cubos, 525, y);
			
			doc.rect(50, 108, 515, 22).strokeColor('#808080')//dibujar la cabecera del reporte, area rectangular
			.strokeColor('#808080')
			doc.rect(50, y - 10, 515, distancia_items) //lineas de los items
			doc.rect(75, y - 10, 30, distancia_items)
			doc.rect(150, y-10, 118, distancia_items)//identificacion clientes
			doc.rect(324, y-10, 35, distancia_items) //lineas gestion 
			doc.rect(423, y-10, 40, distancia_items) //lineas lectura anterior
			doc.rect(505, y-10, 60, distancia_items) //lineas consumo por cubos
			.stroke();
			y = y+distancia_items;
			items++;

			if (items == itemsPorPagina && (i+1) < acciones_agua.length) {
				doc.addPage({ size: papel, margin: 10 });
				y = 140;
				items = 0;
				pagina = pagina + 1;
				doc.font('Helvetica', 8)
				.text('Nº', 59, 118)
				.text('Codigo Medidor', 77, 112, {width:30})
				.text('Codigo Vecino', 116, 112, {width:35})
				.text('Nombres Cliente', 180, 118)
				.text('Categoria', 277, 118)
				.text('Gestion', 327, 118)
				.text('Mes', 385, 118)
				.text('Lect. Anterior', 430, 112, {width:35} )
				.text('Lect. Actual', 469, 112, {width:35})
				.text('Consumo', 516, 118);

				doc.moveDown();
				doc.roundedRect(385,50,200,47,5)
					.strokeColor('#808080')
					.stroke();
				doc.font('Times-Roman', 11)
				doc.text('Periodo:', 390, 56)
				doc.text(filtro_lecturacion.gestion.nombre, 450, 56)
				doc.text('Mes:', 390, 71)
				doc.text(filtro_lecturacion.mes.nombre, 450, 71)
				doc.text('Categoria:', 390, 86)
				doc.text(filtro_lecturacion.categoria.nombre, 450, 86);
				
				this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
					usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
					(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
				(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
				(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{});
				doc.font('Helvetica', 8);
			}

		}
		
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}
	
	imprimirReporteCatalogoLecturacion(usuario, lecturas_medidores, fecha_inicio, fecha_fin, categoria){
		let papel = [612, 792];
		const doc = new PDFDocument({ size: papel, margin: 10 });
		const stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		var x = 70;
		var y = 50;
		var itemsPorPagina = 0;
		var titulo="LECTURACION SERVICIO";
		//dibujar cabecera reporte lecturacion 
		doc.moveDown();
		doc.roundedRect(385,50,200,47,5)
		.strokeColor('#808080')
		.stroke();
		doc.font('Times-Roman', 11)
		doc.text('Fecha inicial:', 390, 56)
		doc.text(fecha_inicio.day+"/"+fecha_inicio.month+"/"+fecha_inicio.year, 460, 56)
		doc.text('Fecha fin:', 390, 71)
		doc.text(fecha_fin.day+"/"+fecha_fin.month+"/"+fecha_fin.year, 460, 71)
		doc.text('Categoria:', 390, 86)
		doc.text(categoria.nombre, 460, 86);

		
		doc.font('Helvetica', 8)
			.text('Nº', 59, 118)
			.text('Codigo Medidor', 77, 112, {width:30})
			.text('Codigo Vecino', 116, 112, {width:35})
			.text('Nombres Cliente', 180, 118)
			.text('Categoria', 277, 118)
			.text('Gestion', 327, 118)
			.text('Mes', 385, 118)
			.text('Lect. Anterior', 430, 112, {width:35} )
			.text('Lect. Actual', 469, 112, {width:35})
			.text('Consumo', 516, 118);
		/*doc.rect(60,125,105,25,)
		.strokeColor('#808080')
		.stroke();*/
		var distancia_items=29;

		itemsPorPagina = 20;

		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(lecturas_medidores.length / itemsPorPagina);
		doc.font('Helvetica', 8);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
			(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
		(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
		(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{});
		doc.font('Helvetica', 8);

		for(var i = 0; i <lecturas_medidores.length && items <= itemsPorPagina; i++){
			doc.text((i+1), 55, y); //para contar nùmeros
			doc.text(lecturas_medidores[i].accion_agua.codigo_medidor, 80, y)
			doc.text(lecturas_medidores[i].accion_agua.vecino.cliente.codigo, 110, y)
			doc.text(lecturas_medidores[i].accion_agua.vecino.cliente.identificacion, 160, y, {width:100, align: 'left'})
			doc.text(lecturas_medidores[i].accion_agua.categoria.nombre, 280, y)
			doc.text(lecturas_medidores[i].gestion.nombre, 330, y, {width:30, align:'left'});
			doc.text(lecturas_medidores[i].mes.nombre, 370, y);
			doc.text(lecturas_medidores[i].lectura_anterior, 435, y);
			doc.text(lecturas_medidores[i].lectura_actual, 477, y);
			doc.text(lecturas_medidores[i].consumo_cubos, 525, y);

			doc.rect(50, 108, 515, 22).strokeColor('#808080')//dibujar la cabecera del reporte, area rectangular
			.strokeColor('#808080')
			doc.rect(50, y - 10, 515, distancia_items) //lineas de los items
			doc.rect(75, y - 10, 30, distancia_items)
			doc.rect(150, y-10, 118, distancia_items)//identificacion clientes
			doc.rect(324, y-10, 35, distancia_items) //lineas gestion 
			doc.rect(423, y-10, 40, distancia_items) //lineas lectura anterior
			doc.rect(505, y-10, 60, distancia_items) //lineas consumo por cubos
			.stroke();
			y = y+distancia_items;
			items++;

			if (items == itemsPorPagina && (i+1) < lecturas_medidores.length) {
				doc.addPage({ size: papel, margin: 10 });
				y = 140;
				items = 0;
				pagina = pagina + 1;
				doc.font('Helvetica', 8)
				.text('Nº', 59, 118)
				.text('Codigo Medidor', 77, 112, {width:30})
				.text('Codigo Vecino', 116, 112, {width:35})
				.text('Nombres Cliente', 180, 118)
				.text('Categoria', 277, 118)
				.text('Gestion', 327, 118)
				.text('Mes', 385, 118)
				.text('Lect. Anterior', 430, 112, {width:35} )
				.text('Lect. Actual', 469, 112, {width:35})
				.text('Consumo', 516, 118);

				doc.moveDown();
				doc.roundedRect(385,50,200,47,5)
					.strokeColor('#808080')
					.stroke();
					doc.font('Times-Roman', 11)
					doc.text('Fecha inicial:', 390, 56)
					doc.text(fecha_inicio.day+"/"+fecha_inicio.month+"/"+fecha_inicio.year, 460, 56)
					doc.text('Fecha fin:', 390, 71)
					doc.text(fecha_fin.day+"/"+fecha_fin.month+"/"+fecha_fin.year, 460, 71)
					doc.text('Categoria:', 390, 86)
					doc.text(categoria.nombre, 460, 86);
				
				this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
					usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
					(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
				(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
				(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{});
				doc.font('Helvetica', 8);
			}
		}

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	imprimirReporteVecinosDeudores(usuario, vecinos,gestion){
		let papel = [612, 792];
		const doc = new PDFDocument({ size: papel, margin: 10 });
		const stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		var x = 70;
		var y = 50;
		var itemsPorPagina = 0;
		var titulo="DEUDAS SERVICIO";
		//dibujar cabecera reporte lecturacion 
		/*doc.moveDown();
		doc.roundedRect(385,50,200,47,5)
		.strokeColor('#808080')
		.stroke();
		doc.font('Times-Roman', 11)
		doc.text('Periodo:', 390, 56)
		//doc.text(filtro_lecturacion.gestion.nombre, 450, 56)
		doc.text('Mes:', 390, 71)
		//doc.text(filtro_lecturacion.mes.nombre, 450, 71)
		doc.text('Categoria:', 390, 86)
		//doc.text(filtro_lecturacion.categoria.nombre, 450, 86);*/

		doc.font('Helvetica', 8)
			.text('Nº', 59, 118)
			.text('Apellido Paterno', 93, 112, {width:40})
			.text('Apellido Materno', 157, 112, {width:40})
			.text('Nombres Cliente', 220, 118)
			.text('Telefono', 295, 118)
			.text('Direccion', 347, 118)
			.text('Categoria', 408, 112, {width:40})
			.text('Meses Deuda', 490, 112, {width:35})
			.text('Codigo Medidor', 454, 112, {width:35} )
			.text('TotalAgua', 520, 118);
		
		var distancia_items=29;

		itemsPorPagina = 20;

		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(vecinos.length / itemsPorPagina);
		doc.font('Helvetica', 8);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
			(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
		(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
		(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{});
		doc.text("GESTIÓN: "+gestion, 450, 60, {width:90, align:'left'})
		doc.font('Helvetica', 8);

		for(var i = 0; i <vecinos.length && items <= itemsPorPagina; i++){
			doc.text((i+1), 55, y); //para contar nùmeros
			doc.text(vecinos[i].apellido_paterno, 82, y)
			doc.text(vecinos[i].apellido_materno, 150, y)
			doc.text(vecinos[i].nombres, 216, y, {width:80, align: 'left'})
			doc.text(vecinos[i].telefono?vecinos[i].telefono:"", 296, y)
			if(vecinos[i].direccion == 0){
				doc.text('Sin direccion', 340, y, {width:90, align:'left'})
			}
			//doc.text(vecinos[i].direccion, 340, y, {width:30, align:'left'});
			doc.text(vecinos[i].categoria, 413, y);
			doc.text(vecinos[i].cantidad_meses, 497, y);
			doc.text(vecinos[i].codigo_medidor, 456, y);
			doc.text(vecinos[i].total_deuda, 529, y);

			doc.rect(50, 108, 515, 22).strokeColor('#808080')//dibujar la cabecera del reporte, area rectangular
			.strokeColor('#808080')
			doc.rect(50, y - 10, 515, distancia_items) //lineas de los items fila de los nùmeros
			doc.rect(75, y - 10, 70, distancia_items) //fila del apellido paterno
			doc.rect(210, y-10, 78, distancia_items)//identificacion clientes
			doc.rect(334, y-10, 70, distancia_items) //lineas codigo medidor
			doc.rect(447, y-10, 40, distancia_items) //lineas lectura anterior
			doc.rect(517, y-10, 48, distancia_items) //lineas consumo por cubos
			.stroke();
			y = y+distancia_items;
			items++;

			if (items == itemsPorPagina && (i+1) < vecinos.length) {
				doc.addPage({ size: papel, margin: 10 });
				y = 140;
				items = 0;
				pagina = pagina + 1;
				doc.font('Helvetica', 8)
				.text('Nº', 59, 118)
				.text('Apellido Paterno', 93, 112, {width:40})
				.text('Apellido Materno', 157, 112, {width:40})
				.text('Nombres Cliente', 220, 118)
				.text('Telefono', 295, 118)
				.text('Direccion', 347, 118)
				.text('Categoria', 408, 112, {width:40})
				.text('Meses Deuda', 490, 112, {width:35})
				.text('Codigo Medidor', 454, 112, {width:35} )
				.text('TotalAgua', 520, 118);

				/*doc.moveDown();
				doc.roundedRect(385,50,200,47,5)
					.strokeColor('#808080')
					.stroke();
					doc.font('Times-Roman', 11)
					doc.text('Fecha inicial:', 390, 56)
					//doc.text(fecha_inicio.day+"/"+fecha_inicio.month+"/"+fecha_inicio.year, 460, 56)
					doc.text('Fecha fin:', 390, 71)
					//doc.text(fecha_fin.day+"/"+fecha_fin.month+"/"+fecha_fin.year, 460, 71)
					doc.text('Categoria:', 390, 86)
					//doc.text(categoria.nombre, 460, 86);*/
				
				this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
					usuario.empresa.imagen, usuario.empresa.nombre, "CASA MATRIZ",usuario.empresa.direccion,
					(usuario.empresa.telefono1!=null?usuario.empresa.telefono1:"")+
				(usuario.empresa.telefono2!=null?"-"+usuario.empresa.telefono2:"")+
				(usuario.empresa.telefono3!=null?"-"+usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{});
				doc.font('Helvetica', 8);
			}
		}
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	async imprimirCotizacion(cotizacion,configuracionImpresion, usuario){
		let papel,itemsPorPagina;
		if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto;
			if (cotizacion.detallesCotizacion.length <= 2) {
				alto = 400;
			} else {
				alto = 400 + (20 * (cotizacion.detallesCotizacion.length - 2))
			}
			console.log('impresion papel rollo alto: ' + alto)
			papel = [227, alto];
			// itemsPorPagina = 10;
			this.imprimirCotizacionRollo(papel, cotizacion, configuracionImpresion,usuario);
		}else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			papel = [306, 396];
			itemsPorPagina = 8;
			this.imprimirCotizacionCuartoCarta(papel, cotizacion, itemsPorPagina,usuario);
		}else{
			this.imprimirCotizacionCartaOficio(cotizacion,configuracionImpresion,usuario);
		}
	}

	async imprimirCotizacionCartaOficio (cotizacion,configuracionImpresion,usuario) {
		let y_pie,itemsPorPagina,papel;
		if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			papel = [612, 936];
			itemsPorPagina = usuario.empresa.usar_imagenes_impresion_cotizacion?17:35;
			y_pie=820;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_CARTA) {
			papel = [612, 792];
			y_pie=750;
			itemsPorPagina = usuario.empresa.usar_imagenes_impresion_cotizacion?15:30;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			papel = [612, 936];
			y_pie=410;
			itemsPorPagina = usuario.empresa.usar_imagenes_impresion_cotizacion?6:12;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			papel = [612, 792];
			y_pie=375;
			itemsPorPagina = usuario.empresa.usar_imagenes_impresion_cotizacion?5:11;
		}
		var fecha_reporte=new Date();
		var titulo="COTIZACIÓN";
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		cotizacion.fecha = new Date(cotizacion.fecha);
		cotizacion.fechaTexto = cotizacion.fecha.getDate() + "/" + (cotizacion.fecha.getMonth() + 1) + "/" + cotizacion.fecha.getFullYear();
		var y = 140, totalAray = 0, items = 0, pagina = 1, totalPaginas = Math.ceil(cotizacion.detallesCotizacion.length / itemsPorPagina);
		var existenDescuentos = Util.verificarDescuentos(cotizacion.detallesCotizacion);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen,usuario.empresa.nombre,cotizacion.sucursal.nombre,cotizacion.sucursal.direccion,
			(cotizacion.sucursal.telefono1!=null?cotizacion.sucursal.telefono1:"")+
		(cotizacion.sucursal.telefono2!=null?"-"+cotizacion.sucursal.telefono2:"")+
		(cotizacion.sucursal.telefono3!=null?"-"+cotizacion.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_cotizacion,
		fecha_reporte,configuracionImpresion);
		this.dibujarCabeceraImpresionCotizacion(doc, cotizacion, pagina, totalPaginas, existenDescuentos, y - 20,configuracionImpresion);
		var salto=usuario.empresa.usar_imagenes_impresion_cotizacion?40:(existenDescuentos?30:20);
		for (var i = 0; i < cotizacion.detallesCotizacion.length; i++) {
			doc.font('Helvetica', 7);
			if (existenDescuentos) {
				doc.text(cotizacion.detallesCotizacion[i].producto.codigo, 55, y, { width: 70 });
				doc.text(cotizacion.detallesCotizacion[i].cantidad, 110, y);
				doc.text(cotizacion.detallesCotizacion[i].producto.unidad_medida, 135, y);
				var longitudCaracteres = cotizacion.detallesCotizacion[i].producto.nombre.length;
				var yDesc = y;//(longitudCaracteres <= 45) ? y : ((longitudCaracteres > 45 && longitudCaracteres <= 90) ? y - 7 : y - 14);
				doc.text(cotizacion.detallesCotizacion[i].producto.nombre, 170, yDesc - 5, { width: 270 });///////////
				doc.text((cotizacion.detallesCotizacion[i].precio_unitario===null)?'null':cotizacion.detallesCotizacion[i].precio_unitario.toFixed(2), 430, y);
				doc.text(cotizacion.detallesCotizacion[i].importe.toFixed(2), 465, y);
				doc.text(cotizacion.detallesCotizacion[i].descuento.toFixed(2), 500, y);
				doc.text(cotizacion.detallesCotizacion[i].tipo_descuento ? "%" : "Bs", 500, y - 7);
				/*doc.text(cotizacion.detallesCotizacion[i].recargo.toFixed(2), 420, y);
				doc.text(cotizacion.detallesCotizacion[i].ice.toFixed(2), 455, y);
				doc.text(cotizacion.detallesCotizacion[i].excento.toFixed(2), 490, y);*/
				doc.text(cotizacion.detallesCotizacion[i].total.toFixed(2), 530, y);
			} else {
				doc.text(cotizacion.detallesCotizacion[i].producto?cotizacion.detallesCotizacion[i].producto.codigo:"", 55, y);
				doc.text(cotizacion.detallesCotizacion[i].cantidad, 110, y,{align:"right",width:25});
				doc.text(cotizacion.detallesCotizacion[i].producto?cotizacion.detallesCotizacion[i].producto.unidad_medida:"", 160, y);
				if(cotizacion.detallesCotizacion[i].producto){
					doc.text(cotizacion.detallesCotizacion[i].producto.nombre, 200, y - 7, { width: 270 });
					if(usuario.empresa.usar_imagenes_impresion_cotizacion){
						let imagen_producto=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+cotizacion.detallesCotizacion[i].producto.imagen);
						doc.image(imagen_producto,400,y-10, { width: 40, height: 40,align:'left' });
					}
				}else{
					doc.text(cotizacion.detallesCotizacion[i].descripcion, 150, y - 7, { width: 300 });
				}
				doc.text(cotizacion.detallesCotizacion[i].precio_unitario.toFixed(2), 460, y,{align:"right",width:35});
				doc.text(cotizacion.detallesCotizacion[i].importe.toFixed(2), 520, y,{align:"right",width:35});
			}
			//doc.text(indx, 40, y)//, 555, 25)
			let ancho = longitudCaracteres <= 80 ? 20 : 30;
			doc.roundedRect(50,y - 10,540,salto,5).fillOpacity(0.8).stroke();
			y = y + salto;
			items = items + 1;

			if (items == itemsPorPagina) {
				totalAray = totalAray + items;
				if (totalAray != cotizacion.detallesCotizacion.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,cotizacion.sucursal.nombre,cotizacion.sucursal.direccion,
						(cotizacion.sucursal.telefono1!=null?cotizacion.sucursal.telefono1:"")+
					(cotizacion.sucursal.telefono2!=null?"-"+cotizacion.sucursal.telefono2:"")+
					(cotizacion.sucursal.telefono3!=null?"-"+cotizacion.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_cotizacion,
					fecha_reporte,configuracionImpresion);
					this.dibujarCabeceraImpresionCotizacion(doc, cotizacion, pagina, totalPaginas, existenDescuentos, y - 20,configuracionImpresion);
				}
			}
		}
		
		doc.font('Helvetica-Bold', 8);
		doc.text("SON : " + (cotizacion.total_literal?cotizacion.total_literal:""), 55, y_pie-18,{width:350});
		doc.font('Helvetica-Bold', 8);
		doc.roundedRect(50,y_pie-22,540,14,5).stroke();
		if(cotizacion.descuento>0){
			doc.text("DESC. "+(cotizacion.tipo_descuento?"%":"Bs"), 420, y_pie-18);
			doc.text(cotizacion.descuento.toFixed(2), 450, y_pie-18,{align:"right",width:35});
		}
		doc.text("TOTAL", 500, y_pie-18);
		doc.text(cotizacion.total.toFixed(2), 530, y_pie-18,{align:"right",width:35});
		
		doc.end();

		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
	}

	dibujarCabeceraImpresionCotizacion (doc, cotizacion, pagina, totalPaginas, existenDescuentos, yCuerpo,configuracionImpresion) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,43,5).stroke();
		doc.text("Nº : ",320,55,{width:65,align:"right"});
		doc.text("FECHA : ",420,55,{width:35,align:"right"});
		doc.text("VALIDEZ : ",510,55,{width:40,align:"right"});
		doc.text("NOMBRE : ",346,65,{width:65,align:"right"});
		doc.text("OBSERVACIÓN : ",350,75,{width:65,align:"right"});
		//doc.text("RAZÓN SOCIAL : ",355,85,{width:70,align:"right"});
		doc.font('Helvetica', 8);
		doc.text(cotizacion.numero_documento,390,55);
		doc.text(cotizacion.fechaTexto,460,55);
		doc.text((cotizacion.tiempo_validez?cotizacion.tiempo_validez+" Días":""),550,55);
		doc.text(cotizacion.cliente?cotizacion.cliente.identificacion:cotizacion.nombre,415,65);
		doc.text(cotizacion.descripcion?cotizacion.descripcion:"",415,75,{width:160});
		//doc.text(venta.razon_social,430,85);
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		var existenDescuentos:any = Util.verificarDescuentos(cotizacion.detallesCotizacion);
		if (existenDescuentos) {
			doc.text("CODIGO", 55, 115);
			doc.text("CANT.", 100, 115);
			doc.text("UNID.", 130, 115);
			doc.text("DETALLE", 160, 115);
			doc.text("P. UNIT.", 430, 115);
			doc.text("IMP.", 465, 115);
			doc.text("DESC.", 500, 115);
			doc.text("TOTAL", 530, 115);
		} else {
			doc.text("CODIGO", 55, 115);
			doc.text("CANT.", 120, 115);
			doc.text("UNID.", 160, 115);
			doc.text("DETALLE", 200, 115);
			doc.text("P.UNIT.", 460, 115);
			doc.text("TOTAL", 520, 115);
		}

		let y_pie;
		if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y_pie=820;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y_pie=750;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y_pie=410;
		} else if (configuracionImpresion.papel_nota_cotizacion == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y_pie=375;
		}
		doc.text("EL STOCK ESTA SUJETO A MODIFICACIÓN SIN PREVIO AVISO.", 50, y_pie-3);

	}

	imprimirCotizacionRollo (papel, cotizacion, configuracionImpresion,usuario) {
		///impresion rollo
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		cotizacion.fecha = new Date(cotizacion.fecha);
		cotizacion.fechaTexto = cotizacion.fecha.getDate() + "/" + (cotizacion.fecha.getMonth() + 1) + "/" + cotizacion.fecha.getFullYear();
		doc.moveDown(2);
		doc.font('Helvetica-Bold', 8);
		doc.text(usuario.empresa.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica', 7);
		doc.text(cotizacion.sucursal.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text(cotizacion.sucursal.direccion.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		var telefono = (cotizacion.sucursal.telefono1 != null ? cotizacion.sucursal.telefono1 : "") +
			(cotizacion.sucursal.telefono2 != null ? "-" + cotizacion.sucursal.telefono2 : "") +
			(cotizacion.sucursal.telefono3 != null ? "-" + cotizacion.sucursal.telefono3 : "");
		doc.text("TELF.: " + telefono, { align: 'center' });
		doc.moveDown(0.4);
		doc.text(cotizacion.sucursal.departamento.nombre.toUpperCase()+" - BOLIVIA", { align: 'center' });
		doc.moveDown(0.5);
		doc.font('Helvetica-Bold', 8);
		doc.text("COTIZACIÓN Nº " + cotizacion.numero_documento, { align: 'center' });
		doc.font('Helvetica', 7);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.6);
		doc.text("FECHA : " + cotizacion.fechaTexto+"                  ", { align: 'left' });
		doc.moveDown(0.4);
		doc.text("NOMBRE : " + cotizacion.nombre, { align: 'left' });
		doc.moveDown(0.4);
		doc.text("DESCRIPCIÓN : " + cotizacion.descripcion, { align: 'left' });
		doc.moveDown(0.4);
		doc.text("---------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.2);
		doc.text("CANT   CONCEPTO                          P. UNIT.    SUBTOTAL", { align: 'left' });
		doc.moveDown(0.2);
		doc.text("---------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		var y = doc.y, sumaDescuento = 0, sumaRecargo = 0, sumaIce = 0, sumaExcento = 0;
		for (var i = 0; i < cotizacion.detallesCotizacion.length; i++) {
			doc.text(cotizacion.detallesCotizacion[i].cantidad, 15, y);
			if (((cotizacion.detallesCotizacion[i].producto)?cotizacion.detallesCotizacion[i].producto.nombre.length:(cotizacion.detallesCotizacion[i].combo?cotizacion.detallesCotizacion[i].combo.nombre.length:cotizacion.detallesCotizacion[i].descripcion)) > 40) {
				doc.fontSize(6);
			}
			doc.text((cotizacion.detallesCotizacion[i].producto)?(cotizacion.detallesCotizacion[i].producto.nombre+" "+(cotizacion.detallesCotizacion[i].descripcion?"("+cotizacion.detallesCotizacion[i].descripcion+")":"")):(cotizacion.detallesCotizacion[i].combo?cotizacion.detallesCotizacion[i].combo.nombre:cotizacion.detallesCotizacion[i].descripcion), 35, y, { width: 100 });
			doc.fontSize(7);
			doc.text(cotizacion.detallesCotizacion[i].precio_unitario.toFixed(2), 140, y);
			doc.text(cotizacion.detallesCotizacion[i].importe.toFixed(2), 175, y);
			y = y + 20;
		}
		doc.text("--------------", 10, y, { align: 'right' });
		//oc.text("--------------------",{align:'right'});
		doc.moveDown(0.4);
		doc.text("Importe Total Bs.              " + cotizacion.importe.toFixed(2), { align: 'right' });
		doc.moveDown(0.3);
		sumaDescuento=cotizacion.descuento;
		if (sumaDescuento > 0) {
			doc.text("Descuento Bs.              " + sumaDescuento.toFixed(2), { align: 'right' });
		}
		doc.moveDown(0.3);
		doc.text("Total Bs.              " + cotizacion.total.toFixed(2), { align: 'right' });
		doc.moveDown(0.6);

		var fechaActual = new Date();
		let min:any = fechaActual.getMinutes();
		if (min < 10) {
			min = "0" + min;
		}
		doc.text("  Usuario : " + usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", { align: 'center' });
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
	}

	dibujarCabeceraImpresionCotizacionCuartoCarta (doc, cotizacion, existenDescuentos, yCuerpo,usuario) {
		var yCabecera = 80;
		var yEspacio = 10;
		if (usuario.empresa.imagen.length > 100) { doc.image(usuario.empresa.imagen, 60, 50, { width: 50, height: 50 }); }
		
		let sucurTel = {}
		for (var i = 0; i < usuario.sucursales.length; i++) {
			if (usuario.sucursales[i].id == cotizacion.id_sucursal) {
				sucurTel = usuario.sucursales[i].telefono1
			}
		}
		doc.font('Helvetica-Bold', 8);
		doc.text("COTIZACIÓN N°" + cotizacion.id, 120, yCuerpo - yCabecera);
		doc.font('Helvetica', 7);
		doc.text("Nombre: " + cotizacion.nombre + "    Descripción: " + cotizacion.descripcion, 20, yCuerpo - (yEspacio * 2));
		doc.text("Fecha: " + cotizacion.fechaTexto, 225, yCuerpo - (yEspacio * 2));
		doc.font('Helvetica-Bold', 7);
		doc.text(usuario.empresa.razon_social.toUpperCase(), 220, yCuerpo - yCabecera + (yEspacio * 0));
		doc.font('Helvetica', 6);
		doc.text('NIT ' + usuario.empresa.nit, 225, yCuerpo - yCabecera + (yEspacio * 1));
		doc.text("TELF.: " + sucurTel, 225, yCuerpo - yCabecera + (yEspacio * 2));
		doc.text("Fecha: " + cotizacion.fechaTexto, 225, yCuerpo - yCabecera + (yEspacio * 3));
		doc.font('Helvetica', 5);
		doc.text("COCHABAMBA - BOLIVIA", 220, yCuerpo - yCabecera + (yEspacio * 4));
		doc.rect(15, yCuerpo - yEspacio, 282, (yEspacio * 2)).stroke();
		doc.font('Helvetica', 6);
		doc.text("N°", 20, yCuerpo);
		if (existenDescuentos) {
			doc.text("Cod.", 30, yCuerpo);
			doc.text("Cant.", 60, yCuerpo);
			doc.text("Unid.", 80, yCuerpo);
			doc.text("Det.", 100, yCuerpo);
			doc.text("P.Unit.", 184, yCuerpo);
			doc.text("Imp.", 212, yCuerpo);
			doc.text("Desc.", 240, yCuerpo);

			doc.text("Totl.", 270, yCuerpo);
		} else {
			doc.text("Cod.", 30, yCuerpo);
			doc.text("Cant.", 60, yCuerpo);
			doc.text("Unid.", 80, yCuerpo);
			doc.text("Detalle.", 100, yCuerpo);
			doc.text("P.Unit.", 195, yCuerpo);
			doc.text("Total.", 270, yCuerpo);
		}
		doc.font('Helvetica', 8);
	}

	imprimirCotizacionCuartoCarta (papel, cotizacion, itemsPorPagina,usuario) {
		///impresion rollo, cuarto carta
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		cotizacion.fecha = new Date(cotizacion.fecha);
		cotizacion.fechaTexto = cotizacion.fecha.getDate() + "/" + (cotizacion.fecha.getMonth() + 1) + "/" + cotizacion.fecha.getFullYear();
		var yCuerpo = 140, totalAray = 0, items = 0, pagina = 1, totalPaginas = Math.ceil(cotizacion.detallesCotizacion.length / itemsPorPagina);
		var existenDescuentos = Util.verificarDescuentos(cotizacion.detallesCotizacion);
		this.dibujarCabeceraImpresionCotizacionCuartoCarta(doc, cotizacion, existenDescuentos, yCuerpo - 20,usuario);
		var totalImporte = 0;
		for (var i = 0; i < cotizacion.detallesCotizacion.length; i++) {
			doc.font('Helvetica', 7);
			totalImporte = totalImporte + cotizacion.detallesCotizacion[i].importe
			let indx = i + 1
			var longitudCaracteres = cotizacion.detallesCotizacion[i].producto.nombre.length;
			doc.text(indx, 20, yCuerpo)//, 555, 25)
			if (existenDescuentos) {
				doc.text(cotizacion.detallesCotizacion[i].producto.codigo, 30, yCuerpo - 7, { width: 32 });
				doc.text(cotizacion.detallesCotizacion[i].cantidad, 64, yCuerpo, { width: 30 });
				doc.text(cotizacion.detallesCotizacion[i].producto.unidad_medida, 79, yCuerpo, { width: 30 });
				var yDesc = longitudCaracteres <= 45 ? yCuerpo - 7 : yCuerpo;
				doc.text(cotizacion.detallesCotizacion[i].producto.nombre, 100, yDesc, { width: 80 });
				doc.text(cotizacion.detallesCotizacion[i].precio_unitario.toFixed(2), 184, yCuerpo, { width: 30 });
				doc.text(cotizacion.detallesCotizacion[i].importe.toFixed(2), 212, yCuerpo);
				doc.text(cotizacion.detallesCotizacion[i].tipo_descuento ? "%" : "Bs", 240, yCuerpo);
				doc.text(cotizacion.detallesCotizacion[i].descuento.toFixed(2), 250, yCuerpo);
				doc.text(cotizacion.detallesCotizacion[i].total.toFixed(2), 270, yCuerpo);
			} else {
				doc.text(cotizacion.detallesCotizacion[i].producto.codigo, 30, yCuerpo, { width: 32 });
				doc.text(cotizacion.detallesCotizacion[i].cantidad, 64, yCuerpo, { width: 30 });
				doc.text(cotizacion.detallesCotizacion[i].producto.unidad_medida, 79, yCuerpo, { width: 30 });
				var yDesc = longitudCaracteres <= 45 ? yCuerpo - 7 : yCuerpo;
				doc.text(cotizacion.detallesCotizacion[i].producto.nombre, 100, yDesc, { width: 80 });
				doc.text(cotizacion.detallesCotizacion[i].precio_unitario.toFixed(2), 184, yCuerpo, { width: 30 });
				doc.text(cotizacion.detallesCotizacion[i].total.toFixed(2), 270, yCuerpo);
			}
			let ancho = longitudCaracteres <= 80 ? 20 : 30
			doc.rect(15, yCuerpo - 10, 282, ancho).stroke(); /// fila de detalle
			yCuerpo = yCuerpo + 20;

			items++;

			if (items == itemsPorPagina) {
				totalAray = totalAray + items;
				if (totalAray != cotizacion.detallesCotizacion.length) {
					doc.text('Pag. ' + pagina + ' de ' + totalPaginas, 260, papel[1] - 40);
					doc.addPage({ size: papel, margin: 10 });
					yCuerpo = 140
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraImpresionCotizacionCuartoCarta(doc, cotizacion, existenDescuentos, yCuerpo - 20,usuario);
				}
			}
		}
		//TOTAL
		doc.font('Helvetica-Bold', 7);
		doc.text("TOTAL BS.", 225, yCuerpo);
		doc.text(totalImporte.toFixed(2), 270, yCuerpo);
		doc.rect(220, yCuerpo - 10, 77, 20).stroke();
		if (totalPaginas > 1) {
			doc.text('Pag. ' + pagina + ' de ' + totalPaginas, 255, papel[1] - 40);
		}
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
	}

	async imprimirHistoricoComprasProveedor (proveedor,usuario,titulo) {
		var titulo=titulo;
    	var fecha_reporte=new Date();
		var doc = new PDFDocument({size: 'letter',margin:10});
      var stream = doc.pipe(blobStream());
      var total_importe=0,total_a_cuenta=0,total_saldo=0;
    
      let y=150,itemsPorPagina=20,items=0,pagina=1,totalPaginas=Math.ceil(proveedor.compras.length/itemsPorPagina);
      this.dibujarCabeceraPDFHistoricoProveedor(doc,proveedor);
      this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
        usuario.empresa.imagen,usuario.empresa.nombre,proveedor.sucursal.nombre,proveedor.sucursal.direccion,
        (proveedor.sucursal.telefono1!=null?proveedor.sucursal.telefono1:"")+
      (proveedor.sucursal.telefono2!=null?"-"+proveedor.sucursal.telefono2:"")+
      (proveedor.sucursal.telefono3!=null?"-"+proveedor.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
      fecha_reporte,{imprimir_usuario:true});
      doc.font('Helvetica',8);
      for(var i=0;i<proveedor.compras.length && items<=itemsPorPagina;i++){
        items++;
        doc.rect(50,y-15,540,30).stroke();
        proveedor.compras[i].fecha=new Date(proveedor.compras[i].fecha);
        doc.text(proveedor.compras[i].fecha.getDate()+"/"+(proveedor.compras[i].fecha.getMonth()+1)+"/"+proveedor.compras[i].fecha.getFullYear(),55,y-7);
        doc.text(proveedor.compras[i].tipo_documento.nombre,95,y-7,{width:60,align:"center"});
        doc.text(proveedor.compras[i].factura,170,y-7,{width:45,align:"left"});
  
        //loop detalle de venta
        var descripcion="";
        for(var k=0;k<proveedor.compras[i].detallesCompra.length;k++){
          descripcion=descripcion+
            (proveedor.compras[i].detallesCompra[k].producto?proveedor.compras[i].detallesCompra[k].producto.nombre:(proveedor.compras[i].detallesCompra[k].combo?proveedor.compras[i].detallesCompra[k].combo.nombre:""))+" "+(proveedor.compras[i].detallesCompra[k].descripcion?proveedor.compras[i].detallesCompra[k].descripcion:"")+", ";
        }
        var alt=descripcion.length>100?y-12:descripcion.length>50?y-7:y;
        doc.text(descripcion.length>155?descripcion.substring(0,155)+"...":descripcion,220,alt,{width:210});
  
        doc.text(proveedor.compras[i].total,425,y,{width:50,align:"right"});
        doc.text(proveedor.compras[i].a_cuenta,475,y,{width:50,align:"right"});
        doc.text(proveedor.compras[i].saldo,530,y,{width:50,align:"right"});
        total_importe=total_importe+proveedor.compras[i].total;
        total_a_cuenta=total_a_cuenta+proveedor.compras[i].a_cuenta;
        total_saldo=total_saldo+proveedor.compras[i].saldo;
        y=y+30;
        if(proveedor.compras[i].pagosCompra.length>0){
          for (var j = 0; j < proveedor.compras[i].pagosCompra.length; j++) {
            if(proveedor.compras[i].pagosCompra[j].activo){
				if(items==itemsPorPagina){
					y=150;
					items=0;
					pagina=pagina+1;
					this.agregarPaginaPdfHistoricoProveedor(doc,proveedor,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte);
				}
				items++;
				doc.rect(50,y-15,0,30).stroke();
				doc.rect(590,y-15,0,30).stroke();
				let date=new Date(proveedor.compras[i].pagosCompra[j].createdAt);
				doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),100,y,{width:50,align:"right"});
				doc.text(proveedor.compras[i].pagosCompra[j].numero_documento,170,y,{width:50,align:"right"});
				doc.text("Pago a/c (Bs. "+proveedor.compras[i].pagosCompra[j].monto_pagado+")" + " "+(proveedor.compras[i].pagosCompra[j].observacion?proveedor.compras[i].pagosCompra[j].observacion:""),240,y);
				y=y+30;
            }
          };
        }
        
        if(items==itemsPorPagina){
			y=150;
			items=0;
			pagina=pagina+1;
			this.agregarPaginaPdfHistoricoProveedor(doc,proveedor,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte);
        }
      }
      doc.rect(50,y-15,540,20).stroke();
      doc.font('Helvetica-Bold',8);
      doc.text("Total General",380,y-7);
      doc.text(Math.round(total_importe*100)/100,425,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_a_cuenta*100)/100,475,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_saldo*100)/100,530,y-7,{width:50,align:"right"});
      doc.end();
      return await new Promise(function (resolve, reject) {
        stream.on('finish', function () {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
          resolve(stream.toBlob());
        });
        stream.on('error', reject)
      });
	}

	async imprimirHistoricoVentasCliente (cliente,usuario,titulo) {
    	var fecha_reporte=new Date();
		var doc = new PDFDocument({size: 'letter',margin:10});
      var stream = doc.pipe(blobStream());
      var total_importe=0,total_a_cuenta=0,total_saldo=0;
    
      let y=150,itemsPorPagina=20,items=0,pagina=1,totalPaginas=Math.ceil(cliente.ventas.length/itemsPorPagina);
      this.dibujarCabeceraPDFHistoricoCliente(doc,cliente);
      this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
        usuario.empresa.imagen,usuario.empresa.nombre,cliente.sucursal.nombre,cliente.sucursal.direccion,
        (cliente.sucursal.telefono1!=null?cliente.sucursal.telefono1:"")+
      (cliente.sucursal.telefono2!=null?"-"+cliente.sucursal.telefono2:"")+
      (cliente.sucursal.telefono3!=null?"-"+cliente.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
      fecha_reporte,{imprimir_usuario:true});
      doc.font('Helvetica',8);
      for(var i=0;i<cliente.ventas.length && items<=itemsPorPagina;i++){
        items++;
        doc.rect(50,y-15,540,30).stroke();
        cliente.ventas[i].fecha=new Date(cliente.ventas[i].fecha);
        doc.text(cliente.ventas[i].fecha.getDate()+"/"+(cliente.ventas[i].fecha.getMonth()+1)+"/"+cliente.ventas[i].fecha.getFullYear(),55,y-7);
        doc.text(cliente.ventas[i].transaccion,95,y-7,{width:60,align:"center"});
        doc.text(cliente.ventas[i].factura,170,y-7,{width:45,align:"left"});
  
        //loop detalle de venta
        var descripcion=cliente.ventas[i].productos;
        /*for(var k=0;k<cliente.ventas[i].detallesVenta.length;k++){
          descripcion=descripcion+
            (cliente.ventas[i].detallesVenta[k].producto?cliente.ventas[i].detallesVenta[k].producto.nombre:(cliente.ventas[i].detallesVenta[k].combo?cliente.ventas[i].detallesVenta[k].combo.nombre:""))+" "+(cliente.ventas[i].detallesVenta[k].descripcion?cliente.ventas[i].detallesVenta[k].descripcion:"")+", ";
        }*/
        var alt=descripcion.length>100?y-12:descripcion.length>50?y-7:y;
        doc.text(descripcion.length>155?descripcion.substring(0,155)+"...":descripcion,220,alt,{width:210});
  
        doc.text(cliente.ventas[i].total,425,y,{width:50,align:"right"});
        doc.text(cliente.ventas[i].a_cuenta,475,y,{width:50,align:"right"});
        doc.text(cliente.ventas[i].saldo,530,y,{width:50,align:"right"});
        total_importe=total_importe+cliente.ventas[i].total;
        total_a_cuenta=total_a_cuenta+cliente.ventas[i].a_cuenta;
        total_saldo=total_saldo+cliente.ventas[i].saldo;
        y=y+30;

		if(cliente.ventas[i].pagos_venta){
			let pagos_venta=cliente.ventas[i].pagos_venta.split(",");
			for(let j=0;j<pagos_venta.length;j++){
				if(items==itemsPorPagina){
					y=150;
					items=0;
					pagina=pagina+1;
					this.agregarPaginaPdfHistoricoCliente(doc,cliente,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte);
				}
				items++;
				doc.rect(50,y-15,0,30).stroke();
				doc.rect(590,y-15,0,30).stroke();
				let date=new Date(pagos_venta[j].split('|')[0]);
				doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),100,y,{width:50,align:"right"});
				doc.text(parseInt(pagos_venta[j].split('|')[1]),170,y,{width:50,align:"right"});
				doc.text("Pago a/c (Bs. "+parseFloat(pagos_venta[j].split('|')[2])+")",240,y);
				y=y+30;
			}
		}
        /*if(cliente.ventas[i].pagosVenta.length>0){
          for (var j = 0; j < cliente.ventas[i].pagosVenta.length; j++) {
            if(cliente.ventas[i].pagosVenta[j].activo){
				if(items==itemsPorPagina){
					y=150;
					items=0;
					pagina=pagina+1;
					this.agregarPaginaPdfHistoricoCliente(doc,cliente,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte);
				}
				items++;
				doc.rect(50,y-15,0,30).stroke();
				doc.rect(590,y-15,0,30).stroke();
				let date=new Date(cliente.ventas[i].pagosVenta[j].createdAt);
				doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),100,y,{width:50,align:"right"});
				doc.text(cliente.ventas[i].pagosVenta[j].numero_documento,170,y,{width:50,align:"right"});
				doc.text("Pago a/c (Bs. "+cliente.ventas[i].pagosVenta[j].monto_pagado+")" + " "+(cliente.ventas[i].pagosVenta[j].observacion?cliente.ventas[i].pagosVenta[j].observacion:""),240,y);
				y=y+30;
            }
          };
        }*/
        
        if(items==itemsPorPagina){
			y=150;
			items=0;
			pagina=pagina+1;
			this.agregarPaginaPdfHistoricoCliente(doc,cliente,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte);
        }
      }
      doc.rect(50,y-15,540,20).stroke();
      doc.font('Helvetica-Bold',8);
      doc.text("Total General",380,y-7);
      doc.text(Math.round(total_importe*100)/100,425,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_a_cuenta*100)/100,475,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_saldo*100)/100,530,y-7,{width:50,align:"right"});
      doc.end();
      return await new Promise(function (resolve, reject) {
        stream.on('finish', function () {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
          resolve(stream.toBlob());
        });
        stream.on('error', reject)
      });
	}

	async dibujarCabeceraPDFHistoricoCliente(doc,cliente){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,20,5).stroke();
		doc.text("CLIENTE : ",385,80);
		doc.text(cliente.identificacion,440,80);		
		
		doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
		doc.font('Helvetica-Bold',8);
		doc.text("Fecha",55,120);
		doc.text("Transaccion",95,120);
		doc.text("Nro. Doc.",170,120,{width:50});
		doc.text("Descripción",240,120,{width:60});
		doc.text("Importe",440,120,{width:50});
		doc.text("A Cuenta",500,120,{width:50});
		doc.text("Saldo",560,120,{width:50});	
	}

	async dibujarCabeceraPDFHistoricoProveedor(doc,proveedor){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,20,5).stroke();
		doc.text("PROVEEDOR : ",385,80);
		doc.text(proveedor.identificacion,440,80);		
		
		doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
		doc.font('Helvetica-Bold',8);
		doc.text("Fecha",55,120);
		doc.text("Transaccion",95,120);
		doc.text("Nro. Doc.",170,120,{width:50});
		doc.text("Descripción",240,120,{width:60});
		doc.text("Importe",440,120,{width:50});
		doc.text("A Cuenta",500,120,{width:50});
		doc.text("Saldo",560,120,{width:50});	
	}

	async agregarPaginaPdfHistoricoCliente(doc,cliente,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte){
		doc.addPage({margin:0,bufferPages: true});
		this.dibujarCabeceraPDFHistoricoCliente(doc,cliente);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
		  usuario.empresa.imagen,usuario.empresa.nombre,cliente.sucursal.nombre,cliente.sucursal.direccion,
		  (cliente.sucursal.telefono1!=null?cliente.sucursal.telefono1:"")+
		(cliente.sucursal.telefono2!=null?"-"+cliente.sucursal.telefono2:"")+
		(cliente.sucursal.telefono3!=null?"-"+cliente.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:true});
		doc.font('Helvetica',8);
	}

	async agregarPaginaPdfHistoricoProveedor(doc,proveedor,usuario,y,items,pagina,titulo,totalPaginas,fecha_reporte){
		doc.addPage({margin:0,bufferPages: true});
		this.dibujarCabeceraPDFHistoricoProveedor(doc,proveedor);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
		  usuario.empresa.imagen,usuario.empresa.nombre,proveedor.sucursal.nombre,proveedor.sucursal.direccion,
		  (proveedor.sucursal.telefono1!=null?proveedor.sucursal.telefono1:"")+
		(proveedor.sucursal.telefono2!=null?"-"+proveedor.sucursal.telefono2:"")+
		(proveedor.sucursal.telefono3!=null?"-"+proveedor.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:true});
		doc.font('Helvetica',8);
	}

	async imprimirRudes(rudes,datos){
		this.blockUI.start();
		let rude1=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude1.png");
		let rude2=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude2.png");
		let rude3=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude3.png");
		let rude4=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude4.png");
		let rude5=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude5.png");
		let rude6=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude6.png");
		let rude7=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude7.png");
		let rude8=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+"img/rude8.png");
		
		let estudiantes=rudes;
		/*Para imprimir hoja RUDE*/
		var documentName="RUDE";//'RUDE-'+management.name+'-'+school.name+'-'+grade.name+'.pdf';
		var doc = new PDFDocument({size:[612,936],margin:0});
		var stream = doc.pipe(blobStream());

		for(var ind=0;ind<estudiantes.length;ind++){
			var datos_estudiante=estudiantes[ind];
			//$scope.drawRudeSheet(doc,school.image);
			//doc.image(image,530, 10, {width: 50, height:50});
			doc.image(rude1,15, 10, {width: 590, height:290});
			doc.image(rude2,18, 300, {width: 590, height:270});
			doc.image(rude3,15, 570, {width: 590, height:200});
			doc.image(rude4,15, 770, {width: 590, height:145});

			doc.text(datos.codigo_sie,500,117);

			if(datos_estudiante.persona.apellido_paterno!=null){
				doc.text(datos_estudiante.persona.apellido_paterno,100,158);
			}
			if(datos_estudiante.persona.apellido_materno!=null){
				doc.text(datos_estudiante.persona.apellido_materno,100,172);
			}			
			
			doc.text(datos_estudiante.persona.nombres,100,186);
			
			if(datos_estudiante.persona.pais_nacimiento!=null){
				doc.text(datos_estudiante.persona.pais_nacimiento,60,212);
			}
			if(datos_estudiante.persona.ciudad_nacimiento!=null){
				doc.text(datos_estudiante.persona.ciudad_nacimiento,250,212);
			}
			if(datos_estudiante.persona.provincia_nacimiento!=null){
				doc.text(datos_estudiante.persona.provincia_nacimiento,60,225);
			}
			if(datos_estudiante.persona.localidad_nacimiento!=null){
				doc.text(datos_estudiante.persona.localidad_nacimiento,250,225);
			}
			
			if(datos_estudiante.codigo_rude!=null){
				doc.text(datos_estudiante.codigo_rude,380,157);
			}
			doc.text("x",582,185);
			if(datos_estudiante.persona.ci!=null){
				doc.text(datos_estudiante.persona.ci,120,287);
			}

			let fecha_nacimiento=datos_estudiante.persona.fecha_nacimiento?(new Date(datos_estudiante.persona.fecha_nacimiento).getFullYear()==1969)?"":new Date(datos_estudiante.persona.fecha_nacimiento):"";
			var fecha_nacimiento_date=typeof fecha_nacimiento=="string"?"":fecha_nacimiento.getDate();
			var fecha_nacimiento_month=typeof fecha_nacimiento=="string"?"":fecha_nacimiento.getMonth()+1;
			var fecha_nacimiento_year=typeof fecha_nacimiento=="string"?"":fecha_nacimiento.getFullYear();

			datos_estudiante.persona.fecha_nacimiento=new Date(datos_estudiante.persona.fecha_nacimiento);
			doc.text(fecha_nacimiento_date,238,253);
			doc.text(fecha_nacimiento_month,275,253);
			doc.text(fecha_nacimiento_year,300,253);
			if(datos_estudiante.persona.genero && datos_estudiante.persona.genero.nombre=="FEMENINO"){
				doc.text("x",425,185);
			}else{
				doc.text("x",425,171);
			}
			doc.fontSize(6);
			if(datos_estudiante.persona.certificado_nacimiento_oficialia!=null){
				doc.text(datos_estudiante.persona.certificado_nacimiento_oficialia,30,252);
			}
			if(datos_estudiante.persona.certificado_nacimiento_libro!=null){
				doc.text(datos_estudiante.persona.certificado_nacimiento_libro,77,252);
			}
			if(datos_estudiante.persona.certificado_nacimiento_partida!=null){
				doc.text(datos_estudiante.persona.certificado_nacimiento_partida,135,252);
			}
			if(datos_estudiante.persona.certificado_nacimiento_folio!=null){
				doc.text(datos_estudiante.persona.certificado_nacimiento_folio,180,252);
			}
			doc.fontSize(12);
			if(datos_estudiante.persona.direccion_ciudad!=null){
				doc.text(datos_estudiante.persona.direccion_ciudad,110,315);
			}
			if(datos_estudiante.persona.direccion_provincia!=null){
				doc.text(datos_estudiante.persona.direccion_provincia,110,329);
			}
			if(datos_estudiante.persona.direccion_municipio!=null){
				doc.text(datos_estudiante.persona.direccion_ciudad,110,343);
			}
			if(datos_estudiante.persona.direccion_localidad!=null){
				doc.text(datos_estudiante.persona.direccion_localidad,110,357);
			}
			if(datos_estudiante.persona.direccion_zona!=null){
				doc.text(datos_estudiante.persona.direccion_zona,110,371);
			}
			if(datos_estudiante.persona.direccion!=null){
				doc.text(datos_estudiante.persona.direccion,110,385);
			}
			if(datos_estudiante.persona.direccion_numero!=null){
				doc.text(datos_estudiante.persona.direccion_numero,110,399);
			}
			if(datos_estudiante.persona.telefono!=null){
				doc.text(datos_estudiante.persona.telefono,320,399);
			}
			if(datos_estudiante.persona.telefono_movil!=null){
				doc.text(datos_estudiante.persona.telefono_movil,520,399);
			}
			
			
			doc.fontSize(6).text(datos_estudiante.idioma_infancia,30,480);
			doc.fontSize(6).text(datos_estudiante.idiomas_frecuentes,30,536);
			
			if(!datos_estudiante.nacion_pertenencia || datos_estudiante.nacion_pertenencia.nombre=="NINGUNA"){
				doc.fontSize(12).text("x",140,453);
			}else{
				doc.fontSize(12).text("x",350,558);
			}
			
			if(datos_estudiante.existe_centro_salud_comunidad){
				doc.text("x",580,431);
			}else{
				doc.text("x",580,442);
			}

			doc.text("x",490,477);
			
			if(datos_estudiante.cantidad_asistencia_centro_salud && datos_estudiante.cantidad_asistencia_centro_salud.nombre=="1 A 2 VECES"){
				doc.text("x",420,545);
			}else if(datos_estudiante.cantidad_asistencia_centro_salud && datos_estudiante.cantidad_asistencia_centro_salud.nombre=="3 A 5 VECES"){
				doc.text("x",473,545);
			}else if(datos_estudiante.cantidad_asistencia_centro_salud && datos_estudiante.cantidad_asistencia_centro_salud.nombre=="6 O MÁS VECES"){
				doc.text("x",535,545);
			}else{
				doc.text("x",582,545);
			}
			

			if(datos_estudiante.numero_registro_discapacidad!=null){
				doc.text("x",510,556);
			}else{
				doc.text("x",565,556);
			}

			if(datos_estudiante.tiene_acceso_agua_red){
				doc.text("x",63,592);
			}else{
				doc.text("x",110,592);
			}
			
			doc.text("x",63,612);

			doc.text("x",63,633);
			
			if(datos_estudiante.tiene_energia_electrica){
				doc.text("x",270,592);
			}else{
				doc.text("x",315,592);
			}

			doc.text("x",270,622);

			doc.text("x",463,603);
			
			doc.text("x",90,675);

			doc.text("x",493,667);

			doc.text("x",40,710);

			if(!datos_estudiante.llegada_escuela || datos_estudiante.llegada_escuela.nombre=="A PIE"){
				doc.text("x",115,820);
			}else if(datos_estudiante.llegada_escuela.nombre=="EN VEHÍCULO DE TRANSPORTE TERRESTRE"){
				doc.text("x",115,830);
			}else{
				doc.text("x",115,852);
			}

			if(!datos_estudiante.tiempo_llegada_escuela || datos_estudiante.tiempo_llegada_escuela.nombre=="MENOS DE MEDIA HORA"){
				doc.text("x",245,842);
			}else if(datos_estudiante.tiempo_llegada_escuela.nombre=="ENTRE MEDIA HORA Y UNA HORA"){
				doc.text("x",245,852);
			}else if(datos_estudiante.tiempo_llegada_escuela.nombre=="ENTRE UNA HORA A DOS HORAS"){
				doc.text("x",245,863);
			}else{
				doc.text("x",245,874);
			}

			doc.text("x",530,795);

			doc.addPage({size:[612,936],margin:0});

			doc.image(rude5,13, 10, {width: 590, height:160});
			doc.image(rude6,14, 170, {width: 590, height:245});
			doc.image(rude7,15, 415, {width: 590, height:255});
			doc.image(rude8,15, 670, {width: 590, height:200});

			var otroTutor=false;
			for(var i=0;i<datos_estudiante.tutores.length;i++){
				if(datos_estudiante.tutores[i].parentesco && datos_estudiante.tutores[i].parentesco.nombre=="PADRE"){
					doc.text(datos_estudiante.tutores[i].persona.ci?datos_estudiante.tutores[i].persona.ci:"",135,53);
					doc.text(datos_estudiante.tutores[i].persona.apellido_paterno!=null?datos_estudiante.tutores[i].persona.apellido_paterno:"",135,65);
					doc.text(datos_estudiante.tutores[i].persona.apellido_materno!=null?datos_estudiante.tutores[i].persona.apellido_materno:"",135,78);
					doc.text(datos_estudiante.tutores[i].persona.nombres,135,93);
					doc.text(datos_estudiante.tutores[i].persona.lenguaje?datos_estudiante.tutores[i].persona.lenguaje.nombre:"",135,106);
					doc.text(datos_estudiante.tutores[i].lugar_trabajo?datos_estudiante.tutores[i].lugar_trabajo:"",135,119);
					doc.text(datos_estudiante.tutores[i].persona.grado?datos_estudiante.tutores[i].persona.grado.nombre:"",135,132);
					var fecha_nacimiento_text=datos_estudiante.tutores[i].persona.fecha_nacimiento?new Date(datos_estudiante.tutores[i].persona.fecha_nacimiento):"";
					var birthday_text_date=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getDate();
					var birthday_text_month=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getMonth()+1;
					var birthday_text_year=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getFullYear();
					doc.text(birthday_text_date,135,145);
					doc.text(birthday_text_month,160,145);
					doc.text(birthday_text_year,190,145);
				}
				else if( datos_estudiante.tutores[i].parentesco &&  datos_estudiante.tutores[i].parentesco.nombre=="MADRE"){
					doc.text( datos_estudiante.tutores[i].persona.ci? datos_estudiante.tutores[i].persona.ci:"",430,53);
					doc.text( datos_estudiante.tutores[i].persona.apellido_paterno!=null? datos_estudiante.tutores[i].persona.apellido_paterno:"",430,65);
					doc.text( datos_estudiante.tutores[i].persona.apellido_materno!=null? datos_estudiante.tutores[i].persona.apellido_materno:"",430,78);
					doc.text( datos_estudiante.tutores[i].persona.nombres,430,93);
					doc.text( datos_estudiante.tutores[i].persona.lenguaje? datos_estudiante.tutores[i].persona.lenguaje.nombre:"",430,106);
					doc.text( datos_estudiante.tutores[i].lugar_trabajo? datos_estudiante.tutores[i].lugar_trabajo:"",430,119);
					doc.text( datos_estudiante.tutores[i].persona.grado? datos_estudiante.tutores[i].persona.grado.nombre:"",430,132);
					var fecha_nacimiento_text= datos_estudiante.tutores[i].persona.fecha_nacimiento?new Date( datos_estudiante.tutores[i].persona.fecha_nacimiento):"";
					var birthday_text_date=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getDate();
					var birthday_text_month=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getMonth()+1;
					var birthday_text_year=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getFullYear();
					doc.text(birthday_text_date,430,145);
					doc.text(birthday_text_month,455,145);
					doc.text(birthday_text_year,485,145);
					//doc.text(student.tutors[i].dataValues.parentesco.name,455,840);
				}else if(!otroTutor){
					doc.text( datos_estudiante.tutores[i].persona.ci? datos_estudiante.tutores[i].persona.ci:"",135,186);
					doc.text( datos_estudiante.tutores[i].persona.apellido_paterno!=null? datos_estudiante.tutores[i].persona.apellido_paterno:"",135,201);
					doc.text( datos_estudiante.tutores[i].persona.apellido_materno!=null? datos_estudiante.tutores[i].persona.apellido_materno:"",135,216);
					doc.text( datos_estudiante.tutores[i].persona.nombres,135,231);
					doc.text( datos_estudiante.tutores[i].persona.lenguaje? datos_estudiante.tutores[i].persona.lenguaje.nombre:"",135,243);
					doc.text( datos_estudiante.tutores[i].lugar_trabajo? datos_estudiante.tutores[i].lugar_trabajo:"",135,257);
					doc.text( datos_estudiante.tutores[i].persona.grado? datos_estudiante.tutores[i].persona.grado.nombre:"",135,269);
					doc.text( datos_estudiante.tutores[i].parentesco? datos_estudiante.tutores[i].parentesco.nombre:"",135,285);
					var fecha_nacimiento_text= datos_estudiante.tutores[i].persona.fecha_nacimiento?new Date( datos_estudiante.tutores[i].persona.fecha_nacimiento):"";
					var birthday_text_date=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getDate();
					var birthday_text_month=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getMonth()+1;
					var birthday_text_year=typeof fecha_nacimiento_text=="string"?"":fecha_nacimiento_text.getFullYear();
					doc.text(birthday_text_date,135,310);
					doc.text(birthday_text_month,160,310);
					doc.text(birthday_text_year,190,310);
					otroTutor=true;
				}
			}

			var ciudad="";
			doc.text(ciudad,390,187);
			doc.text(new Date().getDate(),390,217);
			doc.text((new Date().getMonth()+1),435,217);
			doc.text(new Date().getFullYear(),490,217);


			if(ind+1<estudiantes.length){
				doc.addPage({size:[612,936],margin:0});
			}
		}

		doc.end();
		this.blockUI.stop();
		return await new Promise(function (resolve, reject) {
			stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
			resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
	}

	imprimirMovimientoCaja(movimiento_caja){
		var doc = new PDFDocument({ size: [612, 792], margin: 10 });
		var stream = doc.pipe(blobStream());
		var titulo="MOVIMIENTO DE CAJA",fecha_reporte=new Date();
		doc.font('Helvetica', 8);
		
		var y = 150;
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,1,1,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,"",this.usuario.empresa.direccion,
			(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
		(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
		(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:true});
		this.escribirCabeceraMovimientoCajaPdf(doc,movimiento_caja,this.usuario);
		doc.font('Helvetica-Bold', 8);
		doc.text("TIPO: ", 55, 130);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.tipo_movimiento.nombre, 85, 130);
		doc.font('Helvetica-Bold', 8);
		doc.text("SUCURSAL:", 100, y);
		doc.text("CAJA:", 350, y);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.sucursal.nombre, 150, y);
		doc.text(movimiento_caja.caja.nombre, 390, y);
		doc.font('Helvetica-Bold', 8);
		doc.text("GLOSA:  ", 100, y+15);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.glosa, 150, y+12);
		doc.font('Helvetica-Bold', 8);
		doc.text("MONEDA:  ", 100, y+30);
		doc.text("IMPORTE:  ", 350, y+30);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.caja.moneda.nombre, 150, y+30);
		doc.text(movimiento_caja.importe.toFixed(2), 390, y+30);
		doc.font('Helvetica-Bold', 8);
		doc.text("FORMA DE PAGO:  ", 100, y+45);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.transaccion.nombre, 200, y+45);
		if(movimiento_caja.caja.tipo_caja.nombre_corto==GlobalVariable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA){
			y=y+45;
			doc.font('Helvetica-Bold', 8);
			doc.text("BANCO:  ", 100, y+15);
			doc.text("CUENTA:  ", 300, y+15);
			doc.font('Helvetica', 8);
			doc.text(movimiento_caja.caja.banco.nombre, 150, y+15);
			doc.text(movimiento_caja.caja.numero_cuenta, 340, y+15);
			if(movimiento_caja.id_cheque){
				y=y+15;
				doc.font('Helvetica-Bold', 8);
				doc.text("CHEQUE Nro.:  ", 100, y+15);
				doc.font('Helvetica', 8);
				doc.text(movimiento_caja.numero_cheque, 170, y+15);
			}
		}
		
		doc.text("SON: " + movimiento_caja.total_literal, 55, y+80);
		doc.x=0;
		
		doc.moveDown(10);

		let texto_nombre=movimiento_caja.nombre?movimiento_caja.nombre:"";
		doc.text("-------------------------------------------                  ---------------------------------", { align: 'center' });
		doc.text(movimiento_caja.usuario.persona.nombre_completo+"            "+texto_nombre, { align: 'center' });

		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
	}

	escribirCabeceraMovimientoCajaPdf(doc,movimiento_caja,usuario){
		let texto_nombre=movimiento_caja.nombre?movimiento_caja.nombre:"";
		let nit_ci=movimiento_caja.ci?movimiento_caja.ci:"";
		movimiento_caja.fecha=new Date(movimiento_caja.fecha);
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,50,235,43,5).stroke();
		
		doc.text("Nº : ",355,55);
		doc.text("Fecha : ",410,55);
		doc.text("Nombre : ",355,65);
		doc.text("Nit/Ci : ",355,75);
		doc.font('Helvetica', 8);
		doc.text(movimiento_caja.numero_documento,375,55);
		doc.text(movimiento_caja.fecha.getDate()+"/"+(movimiento_caja.fecha.getMonth()+1)+"/"+movimiento_caja.fecha.getFullYear(),450,55);
		doc.text(texto_nombre,395,65,{width:170});
		doc.text(nit_ci,387,75);
	}

	async generarPdfDocumentoVenta(venta){
		this.blockUI.start();
		venta.usar_impresion_nativa=this.usuario.empresa.usar_impresion_nativa;
		venta.usar_clientes_contactos=this.usuario.empresa.usar_clientes_contactos;
		venta.usar_impresion_nativa=this.usuario.empresa.usar_impresion_nativa;
		venta.usar_permisos_avanzados_usuarios=this.usuario.empresa.usar_permisos_avanzados_usuarios;
		venta.usar_pedidos_restaurante=this.usuario.empresa.usar_pedidos_restaurante;
		venta.usar_descuento_global=this.usuario.empresa.usar_descuento_global;
		venta.usar_sucursales=this.usuario.empresa.usar_sucursales;
		venta.usar_almacenes=this.usuario.empresa.usar_almacenes;
		venta.usar_clientes_contactos=this.usuario.empresa.usar_clientes_contactos;
		venta.usar_facturacion_computarizada_en_linea_por_sucursal_empresa=this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa;
		venta.id_usuario_impresor=this.usuario.id;
		let pdf_respuesta=await this.ventasService.obtenerPdfVenta(venta).toPromise();
		this.blockUI.stop();
		return pdf_respuesta;
	}

	async generarPdfComandaPedido(pedido){
		this.blockUI.start();
		pedido.usar_impresion_nativa=this.usuario.empresa.usar_impresion_nativa;
		pedido.usar_clientes_contactos=this.usuario.empresa.usar_clientes_contactos;
		let pdf_respuesta=await this.pedidosService.obtenerComandaPedido(pedido).toPromise();
		this.blockUI.stop();
		return pdf_respuesta;
	}

	async imprimirVenta(venta){
		this.blockUI.start();
		let pdf=await this.generarPdfDocumentoVenta(venta);
		this.blockUI.stop();
		var w = window.open(window.URL.createObjectURL(pdf), '_blank', 'location=no');
		setTimeout(function () {w.print();}, 500);
		return pdf;
	}

	async imprimirPedido(pedido){
		this.blockUI.start();
		let pdf=await this.generarPdfComandaPedido(pedido);
		this.blockUI.stop();
		let configuracion_app_pedidos:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		if(configuracion_app_pedidos.datos.opciones_permisos.impresion.vista_previa){
			var w = window.open(window.URL.createObjectURL(pdf), '_blank', 'location=no');
			setTimeout(function () {w.print();}, 500);
		}
		return pdf;
	}

	imprimirReporteServiciosPendientes(servicios_pendientes,cliente,sucursal,configuracionImpresion, esAccionGuardar, usuario,tipo_textos){
		var papel, doc, stream;
		if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto;
			if (servicios_pendientes.length <= 2) {
				alto = 400;
			} else {
				alto = 400 + (20 * (servicios_pendientes.length - 2))
			}
			papel = [227, alto];

			if (esAccionGuardar && !configuracionImpresion.imprimir_al_guardar) {
				
			} else {
				doc = new PDFDocument({ size: papel, margins: { top: 0, bottom: 0, left: 20, right: 20 } });
				stream = doc.pipe(blobStream());
				//this.imprimirProformaRollo(venta, papel, doc, stream, usuario);
			}

			if (doc && stream) {
				doc.end();
				return new Promise(function (resolve, reject) {
					stream.on('finish', function () {
						var fileURL = stream.toBlobURL('application/pdf');
						var w = window.open(fileURL, '_blank', 'location=no');
						setTimeout(function () {
							w.print();
						}, 500);
						resolve(stream.toBlob());
					});
					stream.on('error', reject)
				});
			}
		}else{
			if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
				papel = [612, 936];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
				papel = [612, 792];
			} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
				papel = [612, 936];
			}
			return this.imprimirReporteServiciosPendientesCartaOficio(servicios_pendientes,cliente,sucursal,configuracionImpresion, papel, true, false, false, usuario,tipo_textos);
		}
	}

	imprimirReporteServiciosPendientesCartaOficio(servicios_pendientes,cliente,sucursal,configuracionImpresion, papel, vacia, completa, semicompleta, usuario,tipo_textos){
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		//var canvas=document.getElementById('qr-code');
		// draw some text
		var existenDescuentos = Util.verificarDescuentos(servicios_pendientes);
		var titulo="REPORTE PAGOS PENDIENTES";
		var itemsPorPagina = 0;
		if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 19;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 29;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 7;
		} else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina =11;
		}else if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			itemsPorPagina =8;
		}
		
		var distancia_items=20;

		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(servicios_pendientes.length / itemsPorPagina);
		doc.font('Helvetica', 8);
		this.escribirCabeceraReporteServiciosPendientesCartaOficio(doc, cliente,configuracionImpresion.papel_nota_venta);
		this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
			usuario.empresa.imagen,usuario.empresa.nombre,sucursal.nombre,sucursal.direccion,
			(sucursal.telefono1!=null?sucursal.telefono1:"")+
		(sucursal.telefono2!=null?"-"+sucursal.telefono2:"")+
		(sucursal.telefono3!=null?"-"+sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
		fecha_reporte,configuracionImpresion);

		/*if (configuracionImpresion.papel_nota_venta == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			for (var i = 0; i < servicios_pendientes.length && items <= itemsPorPagina; i++) {
				doc.font('Helvetica', 7);
				doc.text((i+1), 20, y-5);
				doc.text(venta.detallesVenta[i].producto.nombre +
				(servicios_pendientes[i].lectura_medidor?" "+venta.detallesVenta[i].lectura_medidor.mes.nombre:"")+
				(servicios_pendientes[i].lectura_medidor?" "+venta.detallesVenta[i].lectura_medidor.gestion.nombre:"")+
				(servicios_pendientes[i].lectura_medidor?" Categoria: "+venta.detallesVenta[i].lectura_medidor.accion_agua.categoria.nombre:"")+
				(servicios_pendientes[i].lectura_medidor?" Lect. Ant.:"+venta.detallesVenta[i].lectura_medidor.lectura_anterior:"")+
				(servicios_pendientes[i].lectura_medidor?" Lect. Act.: "+venta.detallesVenta[i].lectura_medidor.lectura_actual:"")+
				(servicios_pendientes[i].lectura_medidor?" Consumo:"+venta.detallesVenta[i].lectura_medidor.consumo_cubos:"")+
				(servicios_pendientes[i].descripcion?" "+venta.detallesVenta[i].descripcion:""), 30, y-7,{width:180,align:'left'});
				doc.font('Helvetica', 8);
				doc.text(venta.detallesVenta[i].importe.toFixed(2), 210, y-7,{width:40,align:"right"});
				doc.text("(-) "+venta.detallesVenta[i].descuento.toFixed(2), 210, y,{width:40,align:"right"});
				doc.font('Helvetica-Bold', 8);
				doc.text(venta.detallesVenta[i].total.toFixed(2), 265, y-3,{width:30,align:"right"});

				doc.roundedRect(18, y - 10, 280, distancia_items,5).stroke();
	
				y = y+distancia_items;
				items++;
	
				if (items == itemsPorPagina && (i+1) < venta.detallesVenta.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.escribirCabeceraReciboConsumoAguaCartaOficio(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracionImpresion.papel_nota_venta,tipo_textos)
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,venta.sucursal.nombre,venta.sucursal.direccion,
						(venta.sucursal.telefono1!=null?venta.sucursal.telefono1:"")+
					(venta.sucursal.telefono2!=null?"-"+venta.sucursal.telefono2:"")+
					(venta.sucursal.telefono3!=null?"-"+venta.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
					doc.font('Helvetica', 7);
				}
			}

			doc.font('Helvetica-Bold', 8);
			doc.text("TOTAL (Bs)", 220, y - 5);
			doc.text(venta.total.toFixed(2), 265, y - 5,{width:30,align:"right"});

			doc.text("SON : " + (venta.total_literal?venta.total_literal:venta.numero_literal), 30, y - 7,{width:140});

			doc.font('Helvetica', 7);
			doc.roundedRect(18, y - 10, 280, distancia_items,5).stroke();
		*///}else{
			doc.font('Helvetica', 8);
			let suma_servicios_pendientes=0;
			for (var i = 0; i < servicios_pendientes.length && items <= itemsPorPagina; i++) {
				doc.text((i+1), 55, y);
				doc.text(servicios_pendientes[i].producto.nombre + " " +
				(servicios_pendientes[i].gestion?servicios_pendientes[i].gestion.nombre:"")+" "+
				(servicios_pendientes[i].mes?servicios_pendientes[i].mes.nombre:"")+" "+
				(servicios_pendientes[i].categoria.nombre), 80, y-7,{width:150,align:'left'});
				doc.text((servicios_pendientes[i].id_lectura_medidor?servicios_pendientes[i].lectura_anterior:""), 230, y,{width:40,align:'center'});
				doc.text((servicios_pendientes[i].id_lectura_medidor?servicios_pendientes[i].lectura_actual:""), 280, y,{width:40,align:'center'});
				doc.text((servicios_pendientes[i].id_lectura_medidor?servicios_pendientes[i].consumo_cubos:""), 330, y,{width:45,align:'center'})
				doc.text(servicios_pendientes[i].precio_unitario.toFixed(2), 400, y);
				doc.text(servicios_pendientes[i].importe.toFixed(2), 440, y);
				//doc.text(servicios_pendientes[i].descuento.toFixed(2), 490, y);
				doc.text(servicios_pendientes[i].total.toFixed(2), 530, y);
	
				if (completa || vacia) {
					doc.rect(50, y - 10, 540, distancia_items).stroke();
				}
				y = y+distancia_items;
				items++;
				suma_servicios_pendientes=suma_servicios_pendientes+servicios_pendientes[i].total;
				if (items == itemsPorPagina && (i+1) < servicios_pendientes.length) {
					doc.addPage({ size: papel, margin: 10 });
					y = 140;
					items = 0;
					pagina = pagina + 1;
					this.escribirCabeceraReporteServiciosPendientesCartaOficio(doc, cliente,configuracionImpresion.papel_nota_venta);
					this.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,usuario,
						usuario.empresa.imagen,usuario.empresa.nombre,sucursal.nombre,sucursal.direccion,
						(sucursal.telefono1!=null?sucursal.telefono1:"")+
					(sucursal.telefono2!=null?"-"+sucursal.telefono2:"")+
					(sucursal.telefono3!=null?"-"+sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracionImpresion.papel_nota_venta,
					fecha_reporte,configuracionImpresion);
					doc.font('Helvetica', 7);
				}
			}
			doc.font('Helvetica-Bold', 7);
			doc.text("TOTAL (Bs)", 455, y - 5);
			doc.text(suma_servicios_pendientes.toFixed(2), 530, y - 5);

			//doc.text("SON : " + (venta.total_literal?venta.total_literal:venta.numero_literal), 55, y - 5);

			doc.font('Helvetica', 7);
			doc.rect(50, y - 10, 540, 15).stroke();
		//}

		doc.end();
		return new Promise(function (resolve, reject) {
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
				setTimeout(function () {
					w.print();
				}, 500);
				resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
	}

	escribirCabeceraReporteServiciosPendientesCartaOficio (doc, cliente,tamano_papel) {
		let fecha_actual=new Date();
		/*if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(107,60,191,43,5).stroke();
			doc.text("Nº : ",105,65,{width:40,align:"right"});
			doc.text("FECHA : ",190,65,{width:35,align:"right"});
			doc.text("CÓDIGO : ",105,75,{width:40,align:"right"});
			doc.text("SOCIO : ",105,85,{width:40,align:"right"});
			doc.font('Helvetica', 8);
			doc.text(venta.factura,145,65);
			doc.text(venta.fechaTexto,230,65);
			doc.text(venta.cliente.codigo,145,75);
			doc.text(venta.cliente.identificacion,145,85,{width:150});
			
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(18,105,280,25,5).fillOpacity(0.8).stroke();
	
			doc.text("Nº", 20, 115);
			doc.text("CONCEPTO DE PAGO", 30, 115,{width:100,align:'center'});
			doc.text("IMPORTE", 210, 110,{width:45,align:'center'});
			doc.text("(-) DESC.", 210, 119,{width:45,align:'center'});
			doc.text("TOTAL", 255, 110,{width:45,align:'center'});
		}else{*/
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(380,50,205,43,5).stroke();
			//doc.text("Nº : ",385,55,{width:40,align:"right"});
			doc.text("FECHA : ",470,55,{width:35,align:"right"});
			doc.text("SOCIO : ",385,70,{width:40,align:"right"});
			doc.text("CÓDIGO : ",385,80,{width:40,align:"right"});
			doc.font('Helvetica', 8);
			doc.text((fecha_actual.getDate()+"/"+(fecha_actual.getMonth()+1)+"/"+fecha_actual.getFullYear()),510,55);
			doc.text(cliente.identificacion,430,70);
			doc.text(cliente.codigo,430,80);
			
			doc.font('Helvetica-Bold',8);
			doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
	
			doc.text("Nº", 55, 115);
			doc.text("CONCEPTO DE PAGO", 95, 115,{width:100,align:'center'});
			doc.text("LECT. ANT.", 230, 110,{width:40,align:'center'});
			doc.text("LECT. ACT.", 280, 110,{width:40,align:'center'});
			doc.text("CONSUMO M3.", 330, 110,{width:45,align:'center'})
			doc.text("P.U.", 400, 115);
			doc.text("IMPORTE", 440, 115);
			//doc.text("DESC.", 490, 115);
			doc.text("TOTAL", 530, 115);
		//}
		

		doc.font('Helvetica', 7);
		var y=0;
		if (tamano_papel == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			y=820;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CARTA) {
			y=760;
		} else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			y=410;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			y=380;
		}else if (tamano_papel == GlobalVariable.Dictionary.PAPEL_CUARTOCARTA) {
			y=380;
		}		
	}

	imprimirComprobanteContabilidad(comprobante, bimonetario, usuario){
		var doc = new PDFDocument({ size: [612, 792], margin: 10 });
		var stream = doc.pipe(blobStream());
		doc.font('Helvetica', 8);
		var itemsPorPagina = 19;
		var y = 160, items = 0, pagina = 1, totalPaginas = Math.ceil(comprobante.asientos.length / itemsPorPagina);
		var sumaDebeBs = 0, sumaHaberBs = 0, sumaDebeSus = 0, sumaHaberSus = 0;
		var fecha = new Date()
		if (bimonetario) {
			this.dibujarCabeceraComprobante(doc, bimonetario, usuario, comprobante, pagina, totalPaginas);
			for (var i = 0; i < comprobante.asientos.length && items <= itemsPorPagina; i++) {
				var asiento = comprobante.asientos[i]
				doc.rect(390, y, 0, 30).stroke();
				doc.rect(440, y, 0, 30).stroke();
				doc.rect(490, y, 0, 30).stroke();
				doc.rect(540, y, 0, 30).stroke();
				doc.font('Helvetica', 7);
				doc.text(asiento.cuenta.codigo, 28, y + 5)
				doc.font('Helvetica-Bold', 7);
				if (asiento.cuenta.tipoAuxiliar) doc.text(asiento.cuenta.cuentaAux.nombre, 28, y + 13, { width: 80 })
				doc.font('Helvetica-Bold', 7);
				doc.text(asiento.cuenta.nombre, 120, y + 5, { width: 190, underline: true })
				doc.font('Helvetica', 7);
				doc.text(asiento.glosa, 125, y + 13, { width: 190 })
				if (asiento.centroCosto) doc.text(asiento.centroCosto.nombre, 310, y + 5)
				doc.text("", 350, y + 5)
				var debe_bs=Util.number_format(asiento.debe_bs,2)
				var haber_bs=Util.number_format(asiento.haber_bs,2)
				doc.text(debe_bs, 395, y + 5)
				doc.text(haber_bs, 445, y + 5)
				var debe_sus=Util.number_format(asiento.debe_sus,2)
				var haber_sus=Util.number_format(asiento.haber_sus,2)
				doc.text(debe_sus, 500, y + 5)
				doc.text(haber_sus, 545, y + 5)
				sumaDebeBs += 0 + asiento.debe_bs
				sumaHaberBs += 0 + asiento.haber_bs
				sumaDebeSus += 0 + asiento.debe_sus
				sumaHaberSus += 0 + asiento.haber_sus
				y = y + 30;
				items++;
				if (items > itemsPorPagina) {
					doc.addPage({ size: [612, 792], margin: 10 });
					y = 160;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraComprobante(doc, bimonetario, usuario, comprobante, pagina, totalPaginas);
				}
			}
			doc.font('Helvetica-Bold', 7);
			doc.text("SUMA TOTAL:", 330, y + 5)
			sumaDebeBs=Util.number_format(sumaDebeBs,2)
			sumaHaberBs =Util.number_format(sumaHaberBs,2)
			doc.text(sumaDebeBs, 395, y + 5)
			doc.text(sumaHaberBs, 445, y + 5)
			sumaHaberSus =Util.number_format(sumaHaberSus,2)
			sumaDebeSus =Util.number_format(sumaDebeSus,2)
			doc.text(sumaDebeSus, 500, y + 5)
			doc.text(sumaHaberSus, 545, y + 5)
			doc.rect(20, y, 571, 0).stroke();
			doc.rect(20, y + 20, 571, 0).stroke();
			doc.text("Son:", 38, y + 25)
			doc.text(comprobante.importe_literal, 60, y + 25)
			doc.rect(20, y + 40, 571, 0).stroke();
			doc.rect(390, y, 0, 20).stroke();
			doc.rect(440, y, 0, 20).stroke();
			doc.rect(490, y, 0, 20).stroke();
			doc.rect(540, y, 0, 20).stroke();
			doc.rect(20, 720, 571, 0).stroke();
			doc.font('Helvetica-Bold', 7);
			doc.text("Preparado por:", 38, 725)
			doc.font('Helvetica', 7);
			doc.text(usuario.persona.nombre_completo, 38, 735)
			doc.rect(200, 720, 0, 42).stroke();
			doc.text(fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear() + "         " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds(), 58, 752)
			doc.text("Revisado", 240, 752)
			doc.text("Autorizado", 350, 752)
			doc.text("Recibio conforme:", 425, 740)
			doc.text("CI:", 425, 752)
			doc.rect(310, 720, 0, 42).stroke();
			doc.rect(420, 720, 0, 42).stroke();
			doc.rect(20, 750, 571, 0).stroke();
			doc.end();
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
			});
		} else {
			this.dibujarCabeceraComprobante(doc, bimonetario, usuario, comprobante, pagina, totalPaginas);
			for (var i = 0; i < comprobante.asientos.length && items <= itemsPorPagina; i++) {
				var asiento = comprobante.asientos[i]
				doc.rect(450, y, 0, 30).stroke();
				doc.rect(520, y, 0, 30).stroke();
				doc.font('Helvetica', 7);
				doc.text(asiento.cuenta.codigo, 38, y + 5)
				doc.font('Helvetica-Bold', 7);
				if (asiento.cuenta.tipoAuxiliar) doc.text(asiento.cuenta.cuentaAux.nombre, 38, y + 13, { width: 80 })
				doc.font('Helvetica-Bold', 7);
				doc.text(asiento.cuenta.nombre, 130, y + 5, { width: 190, underline: true })
				doc.font('Helvetica', 7);
				doc.text(asiento.glosa, 135, y + 13, { width: 190 })
				if (asiento.centroCosto) doc.text(asiento.centroCosto.nombre, 340, y + 5)
				doc.text("", 380, y + 5)
				var debe_bs =Util.number_format(asiento.debe_bs,2)
				var haber_bs =Util.number_format(asiento.haber_bs,2)
				doc.text(debe_bs, 470, y + 5)
				doc.text(haber_bs, 540, y + 5)
				sumaDebeBs += 0 + asiento.debe_bs
				sumaHaberBs += 0 + asiento.haber_bs
				y = y + 30;
				items++;
				if (items > itemsPorPagina) {
					doc.rect(450, y, 0, 2).stroke();
					doc.rect(520, y, 0, 2).stroke();
					doc.addPage({ size: [612, 792], margin: 10 });
					y = 160;
					items = 0;
					pagina = pagina + 1;
					this.dibujarCabeceraComprobante(doc, bimonetario, usuario, comprobante, pagina, totalPaginas);
				}
			}
			doc.font('Helvetica-Bold', 7);
			doc.text("SUMA TOTAL:", 390, y + 5)
			doc.font('Helvetica', 7);
			sumaDebeBs =Util.number_format(sumaDebeBs,2)
			sumaHaberBs =Util.number_format(sumaHaberBs,2)
			doc.text(sumaDebeBs, 470, y + 5)
			doc.text(sumaHaberBs, 540, y + 5)
			doc.rect(20, y, 571, 0).stroke();
			doc.rect(20, y + 20, 571, 0).stroke();
			doc.font('Helvetica-Bold', 7);
			doc.text("Son:", 38, y + 25)
			doc.font('Helvetica', 7);
			doc.text(comprobante.importe_literal, 60, y + 25)
			doc.rect(20, y + 40, 571, 0).stroke();
			doc.rect(450, y, 0, 20).stroke();
			doc.rect(520, y, 0, 20).stroke();
			doc.rect(20, 720, 571, 0).stroke();
			doc.font('Helvetica-Bold', 7);
			doc.text("Preparado por:", 38, 725)
			doc.font('Helvetica', 7);
			doc.text(usuario.persona.nombre_completo, 38, 735)
			doc.rect(200, 720, 0, 42).stroke();
			doc.text(fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear() + "         " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds(), 58, 752)
			doc.text("Revisado", 240, 752)
			doc.text("Autorizado", 350, 752)
			doc.text("Recibio conforme:", 425, 740)
			doc.text("CI:", 425, 752)
			doc.rect(310, 720, 0, 42).stroke();
			doc.rect(420, 720, 0, 42).stroke();
			doc.rect(20, 750, 571, 0).stroke();
			doc.end();
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
			});
		}
	}

	dibujarCabeceraComprobante(doc, bimonetario, usuario, comprobante, pagina, totalPaginas){
		var fecha = new Date()
		if (bimonetario) {
			doc.rect(20, 30, 571, 732).stroke();
			doc.font('Helvetica-Bold', 8);
			doc.text(usuario.empresa.razon_social, 25, 35)
			doc.text("SISTEMA DE CONTABILIDAD.", 25, 50)
			doc.text("NIT: ", 25, 65)
			doc.font('Helvetica', 8);
			doc.text(usuario.empresa.nit + ".", 50, 65)
			doc.font('Helvetica-Bold', 14);
			doc.text("COMPROBANTE DE " + comprobante.tipoComprobante.nombre, 0, 75, { align: 'center' })
			doc.font('Helvetica', 8);
			doc.text(pagina + ' de: ' + totalPaginas, 545, 35)
			doc.font('Helvetica-Bold', 8);
			doc.text("Gestión: " + fecha.getFullYear(), 515, 50)
			doc.text("N°: " + comprobante.numero, 535, 65)
			doc.rect(20, 90, 571, 0).stroke();
			doc.font('Helvetica', 8);
			doc.text(comprobante.gloza, 68, 105)
			doc.font('Helvetica-Bold', 8);
			var mont = fecha.getMonth() + 1
			if (mont < 10) {
				doc.text("Fecha: " + fecha.getDate() + "/0" +mont+ "/" + fecha.getFullYear(), 500, 95)
			} else {
				doc.text("Fecha: " + fecha.getDate() + "/" +mont+ "/" + fecha.getFullYear(), 500, 95)
			}
			doc.text("T. Cambio: ", 500, 105)
			doc.font('Helvetica', 8);
			doc.text(comprobante.tipoCambio.dolar, 545, 105)
			doc.font('Helvetica-Bold', 8);
			doc.rect(20, 130, 571, 0).stroke();
			doc.font('Helvetica-Bold', 8);
			doc.text("Cuenta", 45, 145)
			doc.text("Descripcion/Glosa", 140, 145)
			doc.text("C.Costo", 310, 145)
			doc.text("ref", 350, 145)
			doc.rect(20, 160, 571, 0).stroke();
			doc.rect(390, 130, 0, 30).stroke();
			doc.rect(440, 145, 0, 15).stroke();
			doc.rect(390, 145, 200, 0).stroke();
			doc.text("BOLIVIANOS", 415, 137)
			doc.text("Debe", 405, 150)
			doc.text("Haber", 455, 150)
			doc.rect(490, 130, 0, 30).stroke();
			doc.rect(540, 145, 0, 15).stroke();
			doc.text("DOLARES", 520, 137)
			doc.text("Debe", 505, 150)
			doc.text("Haber", 550, 150)
			/* doc.rect(50, 700, 520, 0).stroke();
			doc.rect(200, 700, 0, 40).stroke();
			doc.rect(320, 700, 0, 40).stroke();
			doc.rect(420, 700, 0, 40).stroke();
			doc.rect(50, 730, 520, 0).stroke(); */
		} else {
			doc.rect(20, 30, 571, 732).stroke();
			doc.font('Helvetica-Bold', 8);
			doc.text(usuario.empresa.razon_social, 25, 35)
			doc.text("SISTEMA DE CONTABILIDAD.", 25, 50)
			doc.text("NIT: ", 25, 65)
			doc.font('Helvetica', 8);
			doc.text(usuario.empresa.nit + ".", 50, 65)
			doc.font('Helvetica-Bold', 14);
			doc.text("COMPROBANTE DE " + comprobante.tipo.nombre, 0, 75, { align: 'center' })
			doc.font('Helvetica', 8);
			doc.text(pagina + ' de: ' + totalPaginas, 545, 35)
			doc.font('Helvetica-Bold', 8);
			doc.text("Gestión: " + fecha.getFullYear(), 515, 50)
			doc.text("N°: " + comprobante.numero, 535, 65)
			doc.rect(20, 90, 571, 0).stroke();
			doc.font('Helvetica', 8);
			doc.text(comprobante.gloza, 38, 105)
			doc.font('Helvetica-Bold', 8);
			doc.text("Fecha: " + fecha.getDate() + "/" + (fecha.getMonth()) + "/" + fecha.getFullYear(), 500, 95)
			doc.text("T. Cambio: ", 500, 105)
			doc.font('Helvetica', 8);
			if(comprobante.tipo_cambio){
				doc.text(comprobante.tipo_cambio.dolar, 545, 105)
			}
			doc.font('Helvetica-Bold', 8);
			doc.rect(20, 130, 571, 0).stroke();
			doc.text("Cuenta", 50, 145)
			doc.text("Descripcion/Glosa", 150, 145)
			doc.text("C.Costo", 340, 145)
			doc.text("ref", 405, 145)
			doc.text("BOLIVIANOS", 500, 135)
			doc.rect(450, 145, 140, 0).stroke();
			doc.rect(20, 160, 571, 0).stroke();
			doc.rect(450, 130, 0, 30).stroke();
			doc.rect(520, 145, 0, 15).stroke();
			doc.text("Debe", 475, 150)
			doc.text("Haber", 545, 150)
			/* doc.rect(50, 700, 520, 0).stroke();
			doc.rect(200, 700, 0, 40).stroke();
			doc.rect(320, 700, 0, 40).stroke();
			doc.rect(420, 700, 0, 40).stroke();
			doc.rect(50, 730, 520, 0).stroke(); */
		}
	}
	

}

