<div class="modal-header">
    <div class="col-10 col-xs-2">
        <h4 class="widget-title">Kardex de Producto : {{producto.codigo}} - {{producto.nombre}} - {{producto.unidad_medida}}</h4>
    </div>
    <div class="col-10 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="box box-primary px-2">
        <div class="box-body">
            <div class="row">
                <div class="col-2 col-sm-2">
                    <label>Sucursal </label> 
                      <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal" (change)="obtenerAlmacenes()">
                          <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-2 col-sm-2">
                    <label>Almacen </label>&nbsp;
                      <select [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="filter.almacen">
                          <option [ngValue]="almacen" *ngFor="let almacen of filter.almacenes">{{almacen.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-2 col-sm-2">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                  </div>
                  <div class="col-2 col-sm-2">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>  
                <div *ngIf="usuario.empresa.usar_vencimientos" class="col-2 col-sm-1">
                    <label>Lote </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.lote">
                </div>
                <div class="col-1 col-sm-1">
                    <button [disabled]="!filter.almacen" (click)="buscarKardexProductoV2()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                        <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>
                </div>
                <div class="col-1 col-sm-1">
                    <button [disabled]="movimientos.length==0" (click)="generarPdfKardexProducto()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Pdf">
                        <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                    </button>
                </div>
                <div class="col-1 col-sm-1">
                    <button [disabled]="movimientos.length==0" (click)="generarExcelKardexProducto()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Excel">
                        <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                    </button>
                </div>
            </div>
            <hr>
            <h4 class="widget-title">Resumen</h4>
            <div class="row">
                <div class="col-6">
                    <div class="table-fixed-header">
                        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                            <thead fix-head class="table-head-color sticky-nav">
                                <tr>
                                    <th>Nº</th>
                                    <th>Ingresos</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Movimiento de Ingreso</td>
                                    <td>{{resumen.sumatoria_movimientos_ingresos}}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Compras</td>
                                    <td>{{resumen.sumatoria_compras}}</td>
                                </tr>
                                <ng-container *ngIf="usuario.empresa.usar_devoluciones_ventas">
                                    <tr>
                                        <td>3</td>
                                        <td>Devoluciones Ventas</td>
                                        <td>{{resumen.sumatoria_devoluciones_ventas}}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Devoluciones Ventas Combos</td>
                                        <td>{{resumen.sumatoria_devoluciones_ventas_combos}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-6">
                    <div class="table-fixed-header">
                        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                            <thead fix-head class="table-head-color sticky-nav">
                                <tr>
                                    <th>Nº</th>
                                    <th>Salidas</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Movimientos de Salida</td>
                                    <td>{{resumen.sumatoria_movimientos_salidas}}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Ventas</td>
                                    <td>{{resumen.sumatoria_ventas}}</td>
                                </tr>
                                <tr *ngIf="usuario.empresa.usar_combos">
                                    <td>3</td>
                                    <td>Ventas Combos</td>
                                    <td>{{resumen.sumatoria_ventas_combos}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="widget-title">Detalle</h4>
            <div class="table-fixed-header">
                <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                    <thead fix-head class="table-head-color sticky-nav">
                        <tr>
                            <th>Nº</th>
                            <th>Fecha</th>
                            <th>Detalle</th>
                            <th>C.U.</th>
                            <th>Fisico
                                <label> ¿Considerar saldo anterior?
                                    <input (change)="cambiarConsideracionSaldo()" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.considerar_saldo_anterior" />
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let movimiento of movimientos; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{movimiento.fecha | date:"dd/MM/yyyy "}}</td>
                            <td>
                                <p > {{movimiento.detalle}} {{movimiento.almacen_traspaso?movimiento.almacen_traspaso:''}}</p>
                            </td>
                            <td>{{movimiento.costo_unitario}}</td>
                            <td>
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>Ingreso</th>
                                            <th>Salida</th>
                                            <th>Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td>
                                            <p>{{movimiento.ingreso}}</p>
                                        </td>
                                        <td>
                                            <p>{{movimiento.salida}}</p>
                                        </td>
                                        <td>
                                            {{movimiento.saldo}}
                                            <a (click)="abrirPopupConfirmacionActualizacionInventario(movimiento)" href="javascript:void(0)" *ngIf="movimientos.length==i+1" class="card-toolbar-btn text-danger-m1">
                                                <i class="ace-icon fa fa-sync bigger-130"></i>
                                            </a>
                                        </td>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>