<div class="modal-body card-body">
    <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
            <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
                <li class="nav-item brc-success shadow-sm">
                <a class="link_tab elfec nav-link text-left py-3" (click)="verTab('elfec')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    <img src="https://ecommerce.elfec.com/assets/img/public/elfec_logo.png" width="80px">
                </a>
                </li>
                <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab comteco nav-link text-left py-3" (click)="verTab('comteco')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    <img src="https://www.comteco.com.bo/img/upload/configuraciones/TjoGS9.png" width="80px">
                </a>
                </li>
                <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab semapa nav-link text-left py-3" (click)="verTab('semapa')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    <img src="https://semapa.cuentas.bo/assets/admin/img/login_1.png" width="80px">
                </a>
                </li>
                <li class="nav-item brc-warning shadow-sm">
                <a class="link_tab tigo nav-link text-left py-3" (click)="verTab('tigo')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    <img src="https://liberarcelularmovil.com/wp-content/uploads/tigo.jpg" width="80px">
                </a>
                </li>
                <li class="nav-item brc-warning shadow-sm">
                    <a class="link_tab bcp nav-link text-left py-3" (click)="verTab('bcp')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                        <img src="https://www.entrechiquitines.com/wp-content/uploads/2020/06/tc5rwsdujmwgpn1imlh5.jpg" width="80px">
                    </a>
                </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
                <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                    <div class="contenido_tab elfec text-primary-d3 text-120 mb-2">
                        <iframe src="https://ecommerce.elfec.com/pagos/index/" width="100%" height="550px"></iframe>
                    </div>
                    <div class="contenido_tab comteco text-primary-d3 text-120 mb-2">
                        <iframe src="https://www.comteco.com.bo/pages/pagos" width="100%" height="550px"></iframe>
                    </div>
                    <div class="contenido_tab semapa text-primary-d3 text-120 mb-2">
                        <iframe src="https://semapa.cuentas.bo/" width="100%" height="550px"></iframe>
                    </div>
                    <div class="contenido_tab tigo text-primary-d3 text-120 mb-2">
                        <iframe src="https://micuenta.tigo.com.bo/pagos" width="100%" height="550px"></iframe>
                    </div>
                    <div class="contenido_tab bcp text-primary-d3 text-120 mb-2">
                        <iframe src="https://www.bcp.com.bo/ibk2/Account/Login" width="100%" height="550px"></iframe>
                    </div>
                </div>
            </div>
            </div>
        </div><!-- /.card -->
    </div><!-- /.page-content -->
</div><!-- /.page-content -->

