<div class="modal-body card-body">
    <div class="row pt-5 pl-5 pr-5 mr-5 ml-5 bgc-info-d3">
        <div class="col-12">
            <span class="text-white text-180">{{postulacion.nombre}}</span>
        </div>
    </div>
    <div class="row p-5 mr-5 ml-5 text-white bgc-info-d3">
        <div class="col-12">
            <div id="render-container"></div>
        </div>
    </div>
    <div class="row pb-5 pl-5 pr-5 mr-5 mr-5 ml-5 text-white bgc-info-d3">
        <div class="col-8">
            <div class="d-flex align-items-center input-floating-label text-success-m1 brc-success-m2">
                <re-captcha (resolved)="resolved($event)" siteKey="6LehsCATAAAAAHh09UCOL1Q0WQLqYfu0aN1AIzp3"></re-captcha>
            </div>
        </div>
        <div class="col-4">
            <button (click)="enviarFormulario()" class="btn btn-primary" >
                <i class="fas fa-paper-plane text-150 mr-1"></i> Enviar
            </button>&nbsp;      
        </div>
    </div>
</div>