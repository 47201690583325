import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class EmpleadosService {

  constructor(private http: HttpClient) { }

  public obtenerEmpleadosEmpresa(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empleado/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.configuracion_pagina.getOrden().columna+"/direccion/"+paginator.configuracion_pagina.getOrden().direccion,GlobalVariable.httpOptions);
  }

  public guardarEmpleado(empleado){
    return this.http.post(GlobalVariable.API_SERVER_URL+"empleado/",empleado,GlobalVariable.httpOptions);
  }

  public actualizarEmpleado(empleado){
    return this.http.put(GlobalVariable.API_SERVER_URL+"empleado/"+empleado.id,empleado,GlobalVariable.httpOptions);
  }

  public obtenerEmpleado(id_empleado){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empleado/"+id_empleado,GlobalVariable.httpOptions);
  }

  public eliminarEmpleado(empleado){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"empleado/"+empleado.id,GlobalVariable.httpOptions);
  }

  public obtenerListaTurnos(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"turnos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarTurno(turno){
    return this.http.post(GlobalVariable.API_SERVER_URL+"turno/",turno,GlobalVariable.httpOptions);
  }

  public actualizarTurno(turno){
    return this.http.put(GlobalVariable.API_SERVER_URL+"turno/"+turno.id,turno,GlobalVariable.httpOptions);
  }

  public obtenerTurno(turno){
    return this.http.get(GlobalVariable.API_SERVER_URL+"turno/"+turno.id,GlobalVariable.httpOptions);
  }

  public eliminarTurno(turno){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"turno/"+turno.id,GlobalVariable.httpOptions);
  }

  public obtenerTurnosEmpleado(id_empleado){
    return this.http.get(GlobalVariable.API_SERVER_URL+"turnos-empleado/"+id_empleado,GlobalVariable.httpOptions);
  }

  public obtenerTurnosEmpleadoPersona(id_persona){
    return this.http.get(GlobalVariable.API_SERVER_URL+"turnos-empleado-persona/"+id_persona,GlobalVariable.httpOptions);
  }

  public guardarTurnosEmpleado(empleado){
    return this.http.post(GlobalVariable.API_SERVER_URL+"turnos-empleado/",empleado,GlobalVariable.httpOptions);
  }

  public obtenerTodosEmpleadosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empleados/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerAsistencias(filtro){
    let fecha_inicial=new Date(filtro.fecha_inicio_texto.year,filtro.fecha_inicio_texto.month-1,filtro.fecha_inicio_texto.day,0,0);
    let fecha_fin=new Date(filtro.fecha_fin_texto.year,filtro.fecha_fin_texto.month-1,filtro.fecha_fin_texto.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"biometrico/empresa/"+filtro.id_empresa+"/asistencia/empleado/"+filtro.empleado.id+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin,GlobalVariable.httpOptions);
  }

  public marcarAsistencia(id_empresa,id_persona){
    return this.http.get(GlobalVariable.API_SERVER_URL+"biometrico/empresa/"+id_empresa+"/empleado/"+id_persona+"/biometrico/0",GlobalVariable.httpOptions);
  }

  public verAlmacenEmpleado(id_almacen_empleado){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empleado-almacen/almacen/"+id_almacen_empleado,GlobalVariable.httpOptions);
  }

  public obtenerProductosInventario(id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-inventario-empleado/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }

  public obtenerPedidoAsignadoEmpleado(id_empleado){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido-asignado-empleado/"+id_empleado,GlobalVariable.httpOptions);
  }

}
