<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Receta</span>
    </div>
    <div class="col-md-4 col-xs-4">
        <button (click)="guardarReceta()" class="btn btn-primary">
        <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-success" (click)="generarPdfReceta()">
            <i class="fas fa-file-pdf text-105"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
        <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-4">
            <label for="form-field-select-1">Diagnostico/Enfermedad</label>                
        </div>
        <div class="col-8">
          <input type="text" class="form-control" [(ngModel)]="historia_paciente.descripcion" placeholder="Descripcion"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label for="form-field-select-1">Medicamento</label>                
        </div>
        <div class="col-3">
            <label for="form-field-select-1">Presentacion</label>                
        </div>
        <div class="col-2">
            <label for="form-field-select-1">Cantidad</label>                
        </div>
        <div class="col-3">
            <label for="form-field-select-1">Indicacion</label>                
        </div>
        <div class="col-1">
            <button (click)="agregarReceta(receta)" class="btn btn-primary">
                <i class="fa fa-plus text-110"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <input type="text" class="form-control" [(ngModel)]="receta.medicamento" placeholder="Medicamento"/>
        </div>
        <div class="col-3">
            <input type="text" class="form-control" [(ngModel)]="receta.presentacion" placeholder="Presentacion"/>
        </div>
        <div class="col-2">
            <input type="number" class="form-control" [(ngModel)]="receta.cantidad" placeholder="Cantidad"/>
        </div>
        <div class="col-4">
            <input type="text" class="form-control" [(ngModel)]="receta.indicacion" placeholder="Indicacion"/>
        </div>
    </div>
    <hr>
    <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-container" style="padding-bottom: 0px;">
            <div class="fixed-table-header">
                <table></table>
            </div>
            <div class="fixed-table-body table-responsive">
                <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                <span class="loading-wrap">
                    <span class="animation-wrap">
                    <span class="animation-dot"></span>
                    </span>
                </span>
                </div>
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Medicamento</th>
                            <th>Presentacion</th>
                            <th>Cantidad</th>
                            <th>Indicacion</th>
                            <th data-field="tools">
                                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                <div class="fht-cell"></div>
                            </th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let receta of historia_paciente.recetas; let i=index;">
                            <ng-container *ngIf="!receta.eliminado">
                                <td>{{i+1}}</td>
                                <td>{{receta.medicamento}} </td>
                                <td>{{receta.presentacion}} </td>
                                <td>{{receta.cantidad}} </td>
                                <td>{{receta.indicacion}} </td>
                                <td>
                                    <div class="d-none d-lg-inline text-muted">
                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarReceta(receta)">
                                            <i class="fa fa-trash-alt"></i>
                                        </a>
                                    </div>
                                    <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                                    <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                                    <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                                        <div class="dropdown-inner">
                                            <a class="dropdown-item" href="javascript:void(0)" (click)="eliminarReceta(receta)">
                                                <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>					
                </table>
            </div>
        </div>
    </div>
</div>