import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SchoolRoutingModule } from './school-routing.module';
import { DesarrolloCurricularComponent } from './components/desarrollo-curricular/desarrollo-curricular.component';
import { RegistroCalificacionInicialComponent } from './components/registro-calificacion-inicial/registro-calificacion-inicial.component';
import { RuletaComponent } from './components/ruleta/ruleta.component';
import { RegistroCalificacionComponent } from './components/registro-calificacion/registro-calificacion.component';
import { HorarioProfesorComponent } from './components/horario-profesor/horario-profesor.component';
import { TareasProfesorComponent } from './components/tareas-profesor/tareas-profesor.component';
import { MultimediaComponent } from './components/multimedia/multimedia.component';
import { CalificacionesComponent } from './components/calificaciones/calificaciones.component';
import { RegistroEstudianteComponent } from './components/registro-estudiante/registro-estudiante.component';
import { AsistenciaComponent } from './components/asistencia/asistencia.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { ConfiguracionAplicacionEstudiantesComponent } from './components/configuracion-aplicacion-estudiantes/configuracion-aplicacion-estudiantes.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { InstituteModule } from '../institute/institute.module';
import { FichaEconomicaEstudianteComponent } from './components/ficha-economica-estudiante/ficha-economica-estudiante.component';
import { CursosComponent } from './components/cursos/cursos.component';
import { MateriasComponent } from './components/materias/materias.component';
import { TutoresComponent } from './components/tutores/tutores.component';
import { RegistroConceptoIngresoComponent } from './components/registro-concepto-ingreso/registro-concepto-ingreso.component';
import { ArchivosBancoComponent } from './components/archivos-banco/archivos-banco.component';
import { AsignacionPagoEstudianteComponent } from './components/asignacion-pago-estudiante/asignacion-pago-estudiante.component';
import { ReporteCursoComponent } from './components/reporte-curso/reporte-curso.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { DocumentosEstudianteComponent } from './components/documentos-estudiante/documentos-estudiante.component';
import { ConsultaKardexEconomicoEstudianteComponent } from './components/consulta-kardex-economico-estudiante/consulta-kardex-economico-estudiante.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { VencimientoPagosEstudiantesComponent } from './components/notificaciones/vencimiento-pagos-estudiantes/vencimiento-pagos-estudiantes.component';
import { AsuntosAdministrativosComponent } from './components/notificaciones/asuntos-administrativos/asuntos-administrativos.component';
import { ConceptoIngresoCursosComponent } from './components/concepto-ingreso-cursos/concepto-ingreso-cursos.component';
import { CentralizadorCalificacionesComponent } from './components/centralizador-calificaciones/centralizador-calificaciones.component';
import { SeguimientoEstudiantesComponent } from './components/seguimiento-estudiantes/seguimiento-estudiantes.component';
import { RegistroSeguimientoComponent } from './components/registro-seguimiento/registro-seguimiento.component';
import { MigracionInscripcionComponent } from './components/migracion-inscripcion/migracion-inscripcion.component';
import { VistaEstudianteComponent } from './components/vista-estudiante/vista-estudiante.component';


@NgModule({
  declarations: [
    DesarrolloCurricularComponent,
    RegistroCalificacionInicialComponent,
    RuletaComponent,
    RegistroCalificacionComponent,
    HorarioProfesorComponent,
    TareasProfesorComponent,
    MultimediaComponent,
    CalendarioComponent,
    RegistroEstudianteComponent,
    AsistenciaComponent,
    CalificacionesComponent,
    EstudiantesComponent,
    ConfiguracionAplicacionEstudiantesComponent,
    FichaEconomicaEstudianteComponent,
    CursosComponent,
    MateriasComponent,
    TutoresComponent,
    RegistroConceptoIngresoComponent,
    ArchivosBancoComponent,
    AsignacionPagoEstudianteComponent,
    ReporteCursoComponent,
    DocumentosEstudianteComponent,
    ConsultaKardexEconomicoEstudianteComponent,
    VencimientoPagosEstudiantesComponent,
    AsuntosAdministrativosComponent,
    ConceptoIngresoCursosComponent,
    CentralizadorCalificacionesComponent,
    SeguimientoEstudiantesComponent,
    RegistroSeguimientoComponent,
    MigracionInscripcionComponent,
    VistaEstudianteComponent
  ],
  imports: [
    CommonModule,
    SchoolRoutingModule,
    NgbModule,
    FormsModule,
    BaseModule,
    FilterPipeModule,
    NgxBootstrapMultiselectModule,
    OrderModule,
    InstituteModule,
    RecaptchaModule
  ]
})
export class SchoolModule { }
