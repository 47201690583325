<div class="modal-header">
    <div class="col-md-10 col-xs-10">
        <h4 class="widget-title">Documentos estudiante: {{estudiante.persona?estudiante.persona.nombre_completo:estudiante.nombre_completo}}</h4>
    </div>
    <div class="col-md-2 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
      <div class="col-12">
          <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
              <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm" *ngFor="let inscripcion of filter.inscripciones_colegio | orderBy:'gestion.nombre':true">
                  <a (click)="establecerGestionTab(inscripcion.gestion)" [ngClass]="{'active':gestion_tab.nombre==inscripcion.gestion.nombre}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                  {{inscripcion.gestion.nombre}}
                  </a>
              </li>
              </ul>

              <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div *ngFor="let inscripcion of filter.inscripciones_colegio" [ngClass]="{'active':gestion_tab.nombre==inscripcion.gestion.nombre}" class="tab-pane fade show text-95" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="row">
                  <div class="col-6">
                      <div class="card bcard h-auto border-0">
                          <div class="card-header bgc-green-d3">
                            <h3 class="card-title text-130 text-white">
                              Documentos
                            </h3>
                          </div>
        
                          <div class="card-body bgc-white border-1 border-t-0 brc-success-m3">
                            <div class="jqtree tree-dotted" id="id-jqtree-files">
                              <ul class="jqtree_common jqtree-tree jqtree-folder" role="group">
                                  <ng-container *ngIf="gestion_tab.documento">
                                    <ng-container *ngFor="let archivo of gestion_tab.documento.archivos">
                                      <li class="jqtree_common border-dotted" role="presentation" *ngIf="archivo.activo">
                                        <div class="jqtree-element jqtree_common bgc-h-warning-l3" role="presentation">
                                            <a class="jqtree-title jqtree_common" role="treeitem" aria-level="3" aria-selected="false" aria-expanded="false" target="_blank" href="{{rest_server+archivo.archivo}}">
                                              <i class="fas text-125 node-icon" [ngClass]="{'fa-file-archive text-purple-m1':(archivo.nombre.includes('.zip') || archivo.nombre.includes('.rar')),'fa-file-pdf text-danger-m1':archivo.nombre.includes('.pdf'),'fa-file-excel text-success-m1':(archivo.nombre.includes('.xls') || archivo.nombre.includes('.xlsx')),'fa-file-word text-primary-m1':(archivo.nombre.includes('.doc') || archivo.nombre.includes('.docx')),'fa-file-image text-primary-m1':(archivo.nombre.includes('.jpg') || archivo.nombre.includes('.png') || archivo.nombre.includes('.gif') || archivo.nombre.includes('.jpeg')),'fa-file-audio text-purple-m1':(archivo.nombre.includes('.mp3') || archivo.nombre.includes('.mpeg')),'fa-file-video text-purple-m1':(archivo.nombre.includes('.mp4'))}"></i> {{obtenerNombreArchivo(archivo.nombre)}}
                                            </a>
                                            <a title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(archivo)">          
                                              <i class="fa fa-trash-alt text-105"></i>        
                                            </a>  
                                        </div>
                                        <div class="d-inline-flex align-items-center col-12 col-sm-12">
                                          <label class="control-label">{{archivo.descripcion}}</label>
                                        </div>
                                        <div class="d-inline-flex align-items-center col-12 col-sm-12">
                                          <label class="control-label">Fecha registro: {{archivo.fecha_registro | date:"dd/MM/yyyy HH:mm"}}</label>
                                        </div>
                                      </li>
                                    </ng-container>
                                  </ng-container>
                              </ul>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-6" *ngIf="gestion_tab.documento && gestion_tab.documento.archivos_a_subir.length>0">
                    <div class="card bcard h-auto border-0">
                        <div class="card-header bgc-danger-d3">
                          <h3 class="card-title text-130 text-white">
                            Archivos
                            <span class="text-75">(a subir)</span>
                          </h3>
                          <button title="Guardar documentos" class="btn btn-primary" (click)="guardarDocumentos()" type="button" >
                            <i class="ace-icon fa fa-save red2 align-top bigger-110"></i>
                          </button>
                        </div>
      
                        <div class="card-body bgc-white border-1 border-t-0 brc-danger-m3">
                          <div class="jqtree tree-dotted" id="id-jqtree-files">
                            <ul class="jqtree_common jqtree-tree jqtree-folder" role="group">
                                <li class="jqtree_common" role="presentation" *ngFor="let archivo of gestion_tab.documento.archivos_a_subir">
                                    <div class="jqtree-element jqtree_common bgc-h-warning-l3" role="presentation">
                                      <a class="jqtree-title jqtree_common" role="treeitem" aria-level="3" aria-selected="false" aria-expanded="false" target="_blank" href="{{rest_server+archivo}}">
                                        <i class="fas text-125 node-icon" [ngClass]="{'fa-file-archive text-purple-m1':(archivo.nombre.includes('.zip') || archivo.nombre.includes('.rar')),'fa-file-pdf text-danger-m1':archivo.nombre.includes('.pdf'),'fa-file-excel text-success-m1':(archivo.nombre.includes('.xls') || archivo.nombre.includes('.xlsx')),'fa-file-word text-primary-m1':(archivo.nombre.includes('.doc') || archivo.nombre.includes('.docx')),'fa-file-image text-primary-m1':(archivo.nombre.includes('.jpg') || archivo.nombre.includes('.png') || archivo.nombre.includes('.gif') || archivo.nombre.includes('.jpeg')),'fa-file-audio text-purple-m1':(archivo.nombre.includes('.mp3') || archivo.nombre.includes('.mpeg')),'fa-file-video text-purple-m1':(archivo.nombre.includes('.mp4'))}"></i> {{archivo.nombre}}
                                      </a>
                                    </div>
                                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                      <input type="text" id="descripcion" name="descripcion" [(ngModel)]="archivo.descripcion" placeholder="Descripcion" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    </div>
                                </li>
                            </ul>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </div><!-- /.card -->
      </div>
      <div class="col-12 col-md-12">
        <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
          <div class="fallback d-none">
            <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
          </div>

          <div class="dz-default dz-message">
            <span class="text-150  text-grey-d2">
            <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
            para subir
            <span class="text-90 text-grey-m1">(o click)</span>
            <br />
            <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
            </span>
          </div>
        </form>

        <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
        <div id="preview-template" class="d-none">
          <div class="dz-preview dz-file-preview">
            <div class="dz-image">
              <img alt="Archivo" data-dz-thumbnail="" />
            </div>

            <div class="dz-details">
              <div class="dz-size">
                <span data-dz-size=""></span>
              </div>

              <div class="dz-filename">
                <span data-dz-name=""></span>
              </div>
            </div>

            <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
              <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
            </div>

            <div class="dz-error-message">
              <span data-dz-errormessage=""></span>
            </div>

            <div class="dz-success-mark">
              <span class="fa-stack fa-lg text-150">
                <i class="fa fa-circle fa-stack-2x text-white"></i>
                <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
            </span>
            </div>

            <div class="dz-error-mark">
              <span class="fa-stack fa-lg text-150">
                <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
