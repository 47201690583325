<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Vehiculos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Vehiculos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoVehiculo()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
			  <div class="keep-open btn-group show" title="Columns">
				
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="vehiculos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th id="placa" class="center" *ngIf="configuracion_pagina.getConfiguration().placa.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='placa' && this.direction=='asc','desc':this.column=='placa' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('placa')">Placa</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="imagen" class="center" *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    Foto
                </th>
                <th id="kilometraje_actual" class="center" *ngIf="configuracion_pagina.getConfiguration().kilometraje_actual.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='kilometraje_actual' && this.direction=='asc','desc':this.column=='kilometraje_actual' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('kilometraje_actual')">Kilometraje Actual</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="modelo" class="center" *ngIf="configuracion_pagina.getConfiguration().modelo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='modelo' && this.direction=='asc','desc':this.column=='modelo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('modelo')">Modelo</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="marca" class="center" *ngIf="configuracion_pagina.getConfiguration().marca.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='marca' && this.direction=='asc','desc':this.column=='marca' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('marca')">Marca</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="descripcion" class="center" *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='descripcion' && this.direction=='asc','desc':this.column=='descripcion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">Descripcion</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="numero_ejes" class="center" *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().numero_ejes.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='numero_ejes' && this.direction=='asc','desc':this.column=='numero_ejes' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('numero_ejes')">Num. Ejes</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="capacidad_asientos" class="center" *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().capacidad_asientos.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='capacidad_asientos' && this.direction=='asc','desc':this.column=='capacidad_asientos' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('capacidad_asientos')">Capacidad Asientos</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="numero_llantas" class="center" *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().numero_llantas.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='numero_llantas' && this.direction=='asc','desc':this.column=='numero_llantas' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('numero_llantas')">Num. Llantas</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #vehiculos *ngFor="let vehiculo of vehiculos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td rel="placa" *ngIf="configuracion_pagina.getConfiguration().placa.show">{{vehiculo.placa}}</td>                
                <td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="imagen-vehiculo-vista clearfix">
                        <a href="{{rest_server+vehiculo.imagen}}" data-rel="colorbox" title="{{vehiculo.nombre}}">
                            <img alt="sin imagen" src="{{rest_server+vehiculo.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td rel="kilometraje_actual" *ngIf="configuracion_pagina.getConfiguration().kilometraje_actual.show">{{vehiculo.kilometraje_actual}}</td>                
                <td rel="modelo" *ngIf="configuracion_pagina.getConfiguration().modelo.show">{{vehiculo.modelo}}</td>
                <td rel="marca" *ngIf="configuracion_pagina.getConfiguration().marca.show">{{vehiculo.marca}}</td>
                <td rel="descripcion" *ngIf="configuracion_pagina.getConfiguration().descripcion.show">{{vehiculo.descripcion}}</td>

                <td *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().numero_ejes.show">{{vehiculo.numero_ejes}}</td>
                <td *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().capacidad_asientos.show">{{vehiculo.capacidad_asientos}}</td>
                <td *ngIf="usuario.empresa.usar_buses && configuracion_pagina.getConfiguration().numero_llantas.show">{{vehiculo.numero_llantas}}</td>

                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirInventarioVehiculo(vehiculo)">          
                      <i class="fas fa-truck-loading text-105"></i>        
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirRegistroCambioAceite(vehiculo)">          
                      <i class="fas fa-oil-can text-105"></i>        
                    </a>       
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarVehiculo(vehiculo)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
                    </a>       
                    <a *ngIf="usuario.empresa.usar_kardex_inventario_productos && usuario.empresa.usar_inventario && vehiculo.activar_inventario" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verKardexProducto(vehiculo)">          
                      <i class="fa fa-eye text-105"></i>        
                    </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(vehiculo)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="vehiculos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #edicion_vehiculo let-modal>
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <span class="text-blue text-125">Registro Vehiculo</span>
    
              <div class="card-toolbar ml-auto no-border pr-2 d-none">
                <label>
                  <span class="align-middle d-block d-sm-inline">Validation:</span>
                  <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
                </label>
              </div>
              <div class="card-toolbar pl-3">
                <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
                  <i class="fa fa-chevron-left"></i>
                </button>
                <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
                  <i class="fa fa-chevron-right"></i>
                </button>
                <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
                  <i class="fa fa-arrow-right"></i>
                </button>
                <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                    <i class="fas fa-times-circle"></i>
                  </button>
              </div>
            </div>
    
            <div class="card-body px-2">
              <div id="{{wizard_edicion_vehiculo}}" class="d-none">
                <ul class="mx-auto">
                  <li class="wizard-progressbar"></li>
                  <li>
                    <a href="#step-1">
                      <span class="step-title">
                          1
                      </span>
    
                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos del Vehiculo
                    </span>
                  </li>
                  <li>
                    <a href="#step-2">
                      <span class="step-title">
                          2
                      </span>
    
                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos Adicionales
                    </span>
                  </li>
                </ul>
    
                <div class="px-2 py-2 mb-4">
                  <div id="step-1" class="">
    
                    <form novalidate>
                        <div class="  form-row">
                            <div [ngClass]="{'text-danger-m1':(!vehiculo.placa)}" class="col-2 col-form-label text-sm-right text-grey">
                                    Placa
                            </div>
                            <div class="col-10">
                                <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                    <input required type="text" id="placa" name="placa" [(ngModel)]="vehiculo.placa" placeholder="Placa" [ngClass]="{'brc-danger-m1':(!vehiculo.placa)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    <i *ngIf="!vehiculo.placa" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>
                                <div *ngIf="!vehiculo.placa" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>
    
                                <span *ngIf="!vehiculo.placa" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </div>
                        <div class="  form-row">
                            <div class="col-2 col-form-label text-sm-right">
                                Modelo
                            </div>
                            <div class="col-4">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input required type="text" name="modelo" id="modelo" [(ngModel)]="vehiculo.modelo" placeholder="Modelo" class="form-control form-control col-12 text-dark-m2 pr-45 pl-2"  />
                                </div>
                            </div>
                            <div class="col-2 col-form-label text-sm-right">
                                Marca
                            </div>
                            <div class="col-4">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input required type="text" name="marca" id="marca" [(ngModel)]="vehiculo.marca" placeholder="Marca" class="form-control form-control col-12 text-dark-m2 pr-45 pl-2"  />
                                </div>
                            </div>
                        </div>
                        <div class="  form-row">
                            <div class="col-6 col-form-label text-sm-right">
                                Kilometraje Actual
                            </div>
                            <div class="col-6">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input type="number" step="1" name="kilometraje_actual" id="kilometraje_actual" [(ngModel)]="vehiculo.kilometraje_actual" placeholder="Kilometraje Actual" class="form-control form-control col-12 text-dark-m2 pr-45 pl-2"  />
                                </div>
                            </div>
                        </div>
                        <div class="  form-row">
                            <div class="col-2 col-form-label text-sm-right">
                                Imágen
                            </div>
                            <div class="col-10" *ngIf="vehiculo.id">
                                <img  src="{{rest_server+vehiculo.imagen}}" width="100">
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                    <div>
                                        <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
    
                  </div>
    
                  <div id="step-2">
                    <div class="  form-row">
                        <div class="col-4 col-form-label text-sm-right">
                            Descripción
                        </div>
                        <div class="col-8">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input type="text" name="descripcion" id="descripcion" [(ngModel)]="vehiculo.descripcion" placeholder="Descripción" class="form-control form-control col-12 text-dark-m2 pr-45 pl-2"  />
                            </div>
                        </div>
                    </div>
                    <div class="  form-row" *ngIf="usuario.empresa.usar_buses">
                        <div class="col-2 col-form-label text-sm-right">
                            Num. Ejes
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input type="number" setp="1" name="numero_ejes" id="numero_ejes" [(ngModel)]="vehiculo.bus.numero_ejes" placeholder="Número ejes" class="form-control col-12 text-dark-m2 pl-2"  />
                            </div>
                        </div>
                        <div class="col-2 col-form-label text-sm-right">
                            Cap. Asientos
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input type="number" setp="1" name="capacidad_asientos" id="capacidad_asientos" [(ngModel)]="vehiculo.bus.capacidad_asientos" placeholder="Cap. Asientos" class="form-control text-dark-m2 pl-2"  />
                            </div>
                        </div>
                        <div class="col-2 col-form-label text-sm-right">
                            Num. Llantas
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input type="number" setp="1" name="numero_llantas" id="numero_llantas" [(ngModel)]="vehiculo.bus.numero_llantas" placeholder="Número llantas" class="form-control text-dark-m2 pl-2"  />
                            </div>
                        </div>
                    </div>
                    <div class="  form-row" *ngIf="usuario.empresa.usar_mantenimiento_vehiculos && !vehiculo.id">
                      <div class="col-4 col-form-label text-sm-right">¿Crear Almacen Vehiculo?</div>
                      <div class="col-8">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="vehiculo.crear_almacen"/>
                            </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
            </div>
          </div> <!-- .card -->
    
        </div>
    </div>
</ng-template>

<ng-template #inventario_vehiculo let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Inventario Vehículo: {{vehiculo.placa}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarUbicacionInventario()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
    <div class="table-fixed-header">
      <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
        <thead fix-head class="table-head-color sticky-nav">
          <tr>
            <th>Nº</th>
            <th>Código</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Ubicación
              <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('MNTUBIINV','obtenerUbicacionesInventarioVehiculo')"></span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let inventario of vehiculo.almacen.inventarios; let i=index;">
            <td>{{i+1}}</td>
            <td>{{inventario.producto.codigo}}</td>
            <td>{{inventario.producto.nombre}}</td>
            <td>{{inventario.cantidad}}</td>
            <td> <select [compareWith]="compararObjectos" class="form-control" name="ubicacion" [(ngModel)]="inventario.ubicacion">
                  <option [ngValue]="ubicacion" *ngFor="let ubicacion of tipo_inventario_ubicaciones.clases">{{ubicacion.nombre}}</option>
                </select> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #cambio_aceite_vehiculo let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Cambio Aceite Vehículo: {{vehiculo.placa}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarCambioAceite()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-sm-12 col-md-2">
          <label>Fecha</label>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="input-group">
          <input name="tiempo_salida" class="form-control" placeholder="dd/mm/yyyy"
                  name="dp" [(ngModel)]="cambio_aceite.fecha_texto" ngbDatepicker #fi="ngbDatepicker">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
          </div>
      </div>
      </div>
      <div class="col-sm-12 col-md-2">
        <label>Tipo de aceite</label>&nbsp;
        <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('MNTTIPOACEITE','obtenerTiposAceite')"></span>
      </div>
      <div class="col-sm-12 col-md-4">
          <select [compareWith]="compararObjectos" class="form-control" name="aceite" [(ngModel)]="cambio_aceite.tipo_aceite">
              <option [ngValue]="aceite" *ngFor="let aceite of tipo_aceite.clases">{{aceite.nombre}}</option>
          </select>
      </div>
    </div>
      <hr>
      <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Kilometraje Actual cambio (Km)</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="number" name="kilometraje_actual_cambio" [(ngModel)]="cambio_aceite.kilometraje_actual_cambio" placeholder="Kilometraje actual cambio" class="form-control" />
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Kilometraje proximo cambio (Km)</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="number" name="kilometraje_proximo_cambio" [(ngModel)]="cambio_aceite.kilometraje_proximo_cambio" placeholder="Kilometraje proximo cambio" class="form-control" />
        </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Cantidad litros</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input name="cantidad_litros" type="number" [(ngModel)]="cambio_aceite.cantidad_litros" placeholder="Cantidad Litros" class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Observación</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input type="text" name="observacion" [(ngModel)]="cambio_aceite.observacion" placeholder="Observación" class="form-control" />
          </div>
    </div>
  </div>
</ng-template>