import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VecinosService {

  constructor(private http: HttpClient) { }

  public obtenerListaVecinos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vecinos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarVecinos(vecinos,id_empresa){
    return this.http.post(GlobalVariable.API_SERVER_URL+"vecinos/empresa",{vecinos:vecinos,id_empresa:id_empresa},GlobalVariable.httpOptions);
  }

  public buscarVecino(id_empresa,id_categoria_accion,texto_busqueda){
    if (texto_busqueda === '') {
      return of([]);
    }
    return this.http.get(GlobalVariable.API_SERVER_URL+"vecinos/empresa/"+id_empresa+"/categoria-accion/"+id_categoria_accion+"/busqueda/"+texto_busqueda,GlobalVariable.httpOptions);
  }

  public obtenerVecino(id_vecino){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vecinos/"+id_vecino,GlobalVariable.httpOptions);
  }

  public actualizarVecino(vecino){
    return this.http.put(GlobalVariable.API_SERVER_URL+"vecinos/"+vecino.id,vecino,GlobalVariable.httpOptions);
  }

  public obtenerReporteDeudores(id_empresa,nombre_corto_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-deudores-consumo-agua/"+id_empresa+"/gestion/"+nombre_corto_gestion,GlobalVariable.httpOptions);
  }

  public guardarVecino(vecino){
    return this.http.post(GlobalVariable.API_SERVER_URL+"vecinos",vecino,GlobalVariable.httpOptions);
  }

  public eliminarConceptoPago(id_concepto_pago){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"concepto-pago-accion-agua/"+id_concepto_pago,GlobalVariable.httpOptions);
  }
}
