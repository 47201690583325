<div class="row">
    <div class="col-sm-12 col-md-5">
        <div class="dataTables_info" role="status" aria-live="polite">
            Mostrando página {{paginator.currentPage}} de {{paginator.pages.length}}
        </div>
    </div>
    <div class="col-sm-12 col-md-7">
        <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate">
            <ul class="pagination">
                <li class="paginate_button page-item">
                    <a class="page-link" href="javascript:void(0)" (click)="paginator.getFirstPage()">Primero</a>
                </li>
                <li class="paginate_button page-item" [ngClass]="{disabled:paginator.currentPage==1,active:paginator.currentPage==1}">
                    <a class="page-link" href="javascript:void(0)" (click)="paginator.getPreviousPage()"><<</a>
                </li>
                <li class="paginate_button page-item" [ngClass]="{active:paginator.currentPage==page}" *ngFor="let page of paginator.pages; let i=index;">
                    <ng-container *ngIf="paginator.abs(paginator.currentPage-page)<4">
                        <a class="page-link" href="javascript:void(0)" (click)="paginator.getPage(page)">{{page}}</a>
                    </ng-container>
                </li>
                <li class="paginate_button page-item" [ngClass]="{active:paginator.currentPage==paginator.pages.length,disabled:paginator.currentPage==paginator.pages.length}">
                    <a class="page-link" href="javascript:void(0)" (click)="paginator.getNextPage()">>></a>
                </li>
                <li class="paginate_button page-item">
                    <a class="page-link" href="javascript:void(0)" (click)="paginator.getLastPage()">Ultimo</a>
                </li>
            </ul>
        </div>
    </div>
</div>