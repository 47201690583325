<div class="page-content container container-plus">
  <div class="row bgc-white">
    <div class="col-12 col-sm-4 col-xl-3">
      <!-- .modal-off-sm will turn off modal features in sm+ view. So .modal is displayed without needing to toggle it -->
      <div id="aside-menu" class="modal fade modal-off-sm ace-aside aside-left">
        <div class="modal-dialog modal-dialog-scrollable" style="max-width: 280px;">
          <div class="modal-content brc-dark-l4 border-y-0 border-l-0 radius-l-0">

            <div class="modal-header d-sm-none position-tr mt-n25 mr-n2 border-0" style="z-index: 111;">
              <!-- .hide header in lg+ view -->
              <button type="button" class="btn btn-brc-tp btn-white btn-xs btn-h-red btn-a-red text-xl" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>


            <div class="modal-body pt-lg-1 px-2 px-sm-1 ace-scrollbar text-right">
              <div class="pr-2 px-lg-3 pt-lg-4">

                <div class="text-center mb-4">
                  <a href="#aside-compose" data-toggle="modal" class="btn btn-blue px-45 py-2 text-105 radius-2">
                    <i class="fa fa-pencil-alt mr-1"></i>
                    Componer
                  </a>
                </div>


                <form autocomplete="off" class="btn-group btn-group-toggle btn-group-vertical d-flex" data-toggle="buttons">
                  <a href="#" class="d-style mb-1 active btn py-25 btn-outline-dark btn-h-outline-blue btn-a-outline-blue btn-a-bold w-100 btn-brc-tp border-none border-l-4 radius-l-0 radius-r-round text-left">
                    <i class="fa fa-inbox w-3 f-n-hover"></i>
                    Bandeja de entrada
                    <span class="badge badge-pill px-3 badge-danger float-right">{{contador_mensajes_no_leidos}}</span>

                    <input type="radio" name="inbox" checked />
                  </a>

                  <a href="#" class="d-style mb-1 btn py-25 btn-outline-dark btn-h-outline-blue btn-a-outline-blue btn-a-bold w-100 btn-brc-tp border-none border-l-4 radius-0 text-left">
                    <i class="fa fa-star text-orange-d1 w-3 f-n-hover"></i>
                    Starred

                    <input type="radio" name="inbox" />
                  </a>

                  <a href="#" class="d-style mb-1 btn py-25 btn-outline-dark btn-h-outline-blue btn-a-outline-blue btn-a-bold w-100 btn-brc-tp border-none border-l-4 radius-0 text-left">
                    <i class="fa fa-paper-plane w-3 f-n-hover"></i>
                    Sent

                    <input type="radio" name="inbox" />
                  </a>

                  <a href="#" class="d-style mb-1 btn py-25 btn-outline-dark btn-h-outline-blue btn-a-outline-blue btn-a-bold w-100 btn-brc-tp border-none border-l-4 radius-0 text-left">
                    <i class="fa fa-edit w-3 f-n-hover"></i>
                    Draft
                    <span class="badge badge-pill px-3 bgc-primary-l2 text-primary-d1 float-right">2</span>

                    <input type="radio" name="inbox" />
                  </a>

                  <a href="#" class="d-style mb-1 btn py-25 btn-outline-dark btn-h-outline-blue btn-a-outline-blue btn-a-bold w-100 btn-brc-tp border-none border-l-4 radius-0 text-left">
                    <i class="fa fa-trash w-3 f-n-hover"></i>
                    Trash

                    <input type="radio" name="inbox" />
                  </a>
                </form>

                <hr class="brc-secondary-l3 mt-3 mb-4" />
                <div class="d-flex mb-2">
                  <h3 class="text-105 text-secondary-d3 pl-2 text-600">
                    Tags
                  </h3>

                  <a href="#" class="ml-auto btn btn-xs btn-info radius-round text-120 text-600 py-0">
                    +
                  </a>
                </div>

                <div class="p-2 d-flex align-items-center bgc-h-pink-l4 radius-1" role="button">
                  <span class="d-inline-block w-2 h-2 border-3 brc-pink radius-round mr-3"></span>
                  Family
                </div>

                <div class="p-2 d-flex align-items-center bgc-h-blue-l3 radius-1" role="button">
                  <span class="d-inline-block w-2 h-2 border-3 brc-blue radius-round mr-3"></span>
                  Friends
                </div>

                <div class="p-2 d-flex align-items-center bgc-h-green-l3 radius-1" role="button">
                  <span class="d-inline-block w-2 h-2 border-3 brc-green radius-round mr-3"></span>
                  Work
                </div>

                <div class="p-2 d-flex align-items-center bgc-h-orange-l3 radius-1" role="button">
                  <span class="d-inline-block w-2 h-2 border-3 brc-orange radius-round mr-3"></span>
                  Other
                </div>


                <hr class="brc-secondary-l3 mt-3 mb-4" />

                <div class="text-center mb-3">
                  <a href="#" class="py-15 px-4 btn btn-light-secondary btn-h-default btn-a-default btn-brc-tp" role="button">
                    <i class="fa fa-cog w-3"></i>
                    Settings
                  </a>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-xl-9 pt-3" id="message-list" *ngIf="!vista_mensaje">
      <div class="d-flex flex-column flex-md-row pb-35 pt-2">

        <div class="order-last order-md-first pl-1 pl-md-3 d-flex align-items-center">
          <label class="mb-0 ">
            <input id="select-all" type="checkbox" class="border-2 brc-secondary-m1 brc-h-orange-m1" />
          </label>

          <div class="dropdown ml-2 mr-3">
            <button type="button" class="btn btn-outline-lightgrey btn-h-light-lightgrey btn-a-light-lightgrey btn-brc-tp btn-xs px-1 py-2px dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-chevron-down text-85"></i>
            </button>

            <div class="dropdown-menu dropdown-center">
              <a class="dropdown-item m-1px px-4 py-1" href="#">
                All
              </a>

              <a class="dropdown-item m-1px px-4 py-1" href="#">
                Unread
              </a>

              <a class="dropdown-item m-1px px-4 py-1" href="#">
                Read
              </a>

              <a class="dropdown-item m-1px px-4 py-1" href="#">
                Starred
              </a>
            </div>
          </div>

          <div id="inbox-header" class="pb-1px">
            <h3 class="text-xl text-dark-m3 mb-0 d-inline-block">
              Bandeja de Entrada
            </h3>

            <span class="text-grey-m1 text-md ml-2">({{contador_mensajes_no_leidos}} mensajes no leídos)</span>
          </div>

          <div id="inbox-actions" class="d-none">
            <div class="dropdown d-inline-block">
              <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-blue btn-a-outline-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Action
                <i class="fa fa-caret-down ml-1"></i>
              </a>
              <div class="dropdown-menu shadow-sm dropdown-center brc-secondary-l1 radius-1 px-2px py-1">
                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <i class="fa fa-reply w-3 text-blue-d2"></i>
                  Reply
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <span class="w-3 d-inline-block">
                          <i class="fa fa-reply fa-flip-horizontal text-green-d2"></i>
                      </span>
                  Forward
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <i class="fa fa-folder-open text-orange-d2 w-3"></i>
                  Archive
                </a>

                <div class="dropdown-divider"></div>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <i class="fa fa-eye w-3 text-blue-d2"></i>
                  Mark as read
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <i class="fa fa-eye-slash w-3 text-green-d2"></i>
                  Mark as unread
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <i class="far fa-flag w-3 text-danger-d2"></i>
                  Flag
                </a>
              </div>
            </div>


            <div class="dropdown d-inline-block">
              <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-blue btn-a-outline-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="far fa-folder mr-1"></i>
                Move to
                <i class="fa fa-caret-down ml-1"></i>
              </a>
              <div class="dropdown-menu shadow-sm dropdown-center brc-secondary-l1 radius-1 px-2px py-1">
                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <span class="d-inline-block w-2 h-2 border-3 brc-pink radius-round mr-1 mb-1 align-middle"></span>
                  Family
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <span class="d-inline-block w-2 h-2 border-3 brc-blue radius-round mr-1 mb-1 align-middle"></span>
                  Friends
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <span class="d-inline-block w-2 h-2 border-3 brc-green radius-round mr-1 mb-1 align-middle"></span>
                  Work
                </a>

                <a href="#" class="dropdown-item m-1px px-3 py-1 text-dark-m3 btn btn-lighter-dark btn-h-lighter-secondary btn-a-lighter-secondary btn-bgc-tp radius-0 text-95">
                  <span class="d-inline-block w-2 h-2 border-3 brc-orange radius-round mr-1 mb-1 align-middle"></span>
                  Other
                </a>
              </div>
            </div>

            <div class="dropdown d-inline-block">
              <a href="#" class="btn btn-sm btn-lighter-default btn-text-dark btn-bgc-white btn-h-outline-danger btn-a-outline-danger">
                <i class="fa fa-trash-alt text-danger mr-sm-1"></i>
                <span class="d-none d-sm-inline">Delete</span>
              </a>
            </div>
          </div>
        </div>



        <div class="order-first order-md-last ml-md-auto mb-4 mb-md-0">
          <div class="input-group">
            <div class="input-group-prepend d-sm-none" style="z-index: 222;">
              <button type="button" class="mr-n5 btn btn-brc-tp btn-outline-dark btn-a-green btn-h-green radius-l-1 px-25 btn-sm static" data-toggle="modal" data-target="#aside-menu">
                <i class="fa fa-bars"></i>
              </button>
            </div>

            <input type="search" [(ngModel)]="text_search" class="form-control radius-1 px-5 pl-sm-3 brc-primary-tp2 brc-on-focus" placeholder="Buscar mensajes ...">
            <div class="input-group-append" style="z-index: 222;">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-blue radius-r-1 btn-tp px-25 btn-sm ml-n475 static" type="button">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ng-container *ngFor="let mensaje of mensajes;">
          <hr class="brc-black-tp10 my-0" />
          <div (click)="verMensaje(mensaje)" role="button" [ngClass]="{'bgc-default-l4':mensaje.receptores[0].leido}" class="message-item d-flex align-items-start bgc-h-primary-l4 px-2 px-md-3 py-25 radius-2px d-style pos-rel">
            <label class="mb-0 message-select-btn">
              <input type="checkbox" class="input-sm" />
            </label>
  
            <a href="#" class="d-none d-md-block ml-md-3 message-star-btn">
              <i class="fa fa-star text-warning-m1"></i>
            </a>

            <a href="#" class="d-md-none position-br mr-1 mb-2px message-star-btn">
              <i class="far fa-star text-grey-l1"></i>
            </a>

            <div class="col-auto px-0 ml-2 ml-md-3 w-5 h-5 text-white text-90 text-600 text-center pt-15">
              <img class="d-lg-inline-block radius-round border-2 brc-white-tp1 mr-2" width="36" height="36" src="{{rest_server+mensaje.usuario_creador.persona.imagen}}" alt="{{mensaje.usuario_creador.persona.nombre_completo}}">
            </div>

            <div class="ml-3 d-flex flex-column flex-lg-row align-items-lg-center">
              <div class="message-user mb-1 mb-lg-0 col-auto px-0 text-95 text-600 text-dark-m2">
                {{mensaje.usuario_creador.persona.nombre_completo}}
              </div>
              <div class="message-text ml-lg-3 ml-lg-5 pr-1 pr-lg-0 text-90 text-600 text-dark-m3 pos-rel">
                <span class="p-1 bgc-orange radius-round d-inline-block mr-1"></span>
                {{mensaje.mensaje}}
              </div>
            </div>

            <div class="message-time d-none d-lg-flex align-items-center ml-auto pl-2 col-auto text-nowrap pr-0 pl-1 text-90 text-600 text-dark-m1">
              <i class="w-3 fa fa-paperclip ml-n35 text-grey-l1"></i>
              {{mensaje.createdAt | date:'dd-MM-yyyy  HH:mm'}}
            </div>
            <div class="position-tr mt-15 w-auto message-time d-flex d-lg-none align-items-center text-nowrap  text-90 text-600 text-dark-m1">
              <i class="w-2 mr-2px fa fa-paperclip ml-n3 text-grey-l1"></i>
              {{mensaje.createdAt | date:'dd-MM-yyyy  HH:mm'}}
            </div>

            <div class="message-actions position-r mr-1 v-hover p-15 bgc-white-tp1 shadow-sm radius-2px">
              <a href="#" class="btn btn-tp border-0 btn-text-danger btn-light-danger mr-2px px-2">
                <i class="fa fa-trash-alt text-danger-m1 w-2"></i>
              </a>
              <a href="#" class="btn btn-tp border-0 btn-text-info btn-light-info px-2">
                <i class="fa fa-reply w-2"></i>
              </a>
            </div>
          </div>
        </ng-container>

        <hr class="brc-black-tp10 my-0" />
      </div>

      <div class="bgc-default-l4 p-3 align-items-center d-flex w-100">
        <div>
          Mostrando <span class="text-600">{{(1)+(this.itemsPerPage*(this.currentPage-1))}}</span> - <span class="text-600">{{(this.itemsPerPage*(this.currentPage))}}</span>
          de mas de
          <span class="text-600">{{(numberOfPages-1)*this.itemsPerPage}}</span> mensajes
        </div>

        <div class="ml-auto">
          <nav class="d-inline-block" aria-label="Inbox navigation">
            <ul class="pagination align-items-center d-inline-flex mb-0">
              <li class="page-item mr-1">
                <a *ngIf="currentPage>1" (click)="getPreviousPage()" class="page-link btn py-2 px-25 btn-sm border-2 brc-sec1ondary-l1 radius-r-0 text-600 btn-bgc-white btn-lighter-secondary btn-h-default btn-a-default" href="#">
                  <i class="fa fa-chevron-left"></i>
                </a>
              </li>

              <li class="page-item">
                <a *ngIf="currentPage<numberOfPages" (click)="getNextPage()" class="page-link btn py-2 px-25 btn-sm border-2 brc-sec1ondary-l1 radius-l-0 text-600 btn-bgc-white btn-lighter-secondary btn-h-default btn-a-default" href="#">
                  <i class="fa fa-chevron-right"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <app-mensaje-vista *ngIf="vista_mensaje" class="col-12 col-sm-8 col-xl-9 pt-3" id="message-view"
        [mensaje]="mensaje"
        [vista_bandeja]="true"
        [movimiento_inventario]="movimiento_inventario_mensaje"
        (onVistaBandeja)="onVistaBandeja($event)"
    ></app-mensaje-vista>
  </div>



  <!-- the "compose" trigger button to show the "Compose" aside/dialog  ... only shown in small devices -->
  <div class="position-br pos-fixed mb-5 pb-3 mr-2 d-sm-none">
    <button type="button" class="shadow ml-2 btn btn-info radius-round mw-auto px-3 py-2" data-toggle="modal" data-target="#aside-compose">
      <i class="fa fa-pen mr-1"></i>
      Compose
    </button>
  </div>


  <!-- the "message compose" aside (dialog) -->
  <div class="modal modal-nb ace-aside aside-bottom aside-r aside-fade aside-offset aside-shown-above-nav" id="aside-compose" tabindex="-1" role="dialog" aria-hidden="false">

    <div class="modal-dialog modal-dialog-scrollable mr-2 my-2" style="width: 640px;" role="document">
      <div class="modal-content border-0 mb-2 radius-1 shadow">
        <div class="modal-header bgc-dark-d3 border-0 text-white pt-25 pb-2">
          <h5 class="text-110 py-0 my-0">
            New Message
          </h5>

          <a href="#" class="action-btn text-white bgc-h-white-tp9 radius-1 px-2 py-1" data-dismiss="modal">
            <i class="fa fa-times"></i>
          </a>
        </div>
        <div class="modal-body">
          <form autocomplete="off" class="d-flex flex-column">
            <div class="  row">
              <div class="flex-grow-1 px-3">
                <input type="text" class="px-1 brc-grey-l2 form-control border-none border-b-1 shadow-none radius-0" id="id-form-field-1" placeholder="Recipients" />
              </div>
            </div>


            <div class="  row my-2">
              <div class="flex-grow-1 px-3">
                <input type="text" class="px-1 brc-grey-l2 form-control border-none border-b-1 shadow-none radius-0" i1d="id-form-field-1" placeholder="Subject">
              </div>
            </div>


            <div class="  row mt-3">
              <div class="px-3 w-100">
                <div id="message-editor" placeholder="Type your message" class="overflow-auto text-wrap w-100" style="height: 360px; max-height: 50vh;">

                </div>
              </div>
            </div>

          </form>

          <div id="message-editor-toolbar" class="collapse position-bl w-100">
            <!-- wysiwyg editor will be inserted here -->
            <!-- this is a .collapse element and will be toggeld using the below data-toggle="collapse" button -->
          </div>
        </div>

        <div class="modal-footer justify-content-start bgc-secondary-l4">
          <button type="button" class="btn btn-blue py-15 px-4 ml-2">
            Send
          </button>

          <div class="ml-3 pl-3 border-l-1 brc-secondary-l1">
            <a data-toggle="collapse" href="#message-editor-toolbar" class="btn btn-tp btn-white">
              <i class="fa fa-font"></i>
            </a>

            <a href="#" class="btn btn-tp btn-white">
              <i class="fa fa-paperclip"></i>
            </a>

            <div class="dropdown d-inline-block">
              <a href="#" class="btn btn-tp btn-white" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="far fa-smile text-125"></i>
              </a>

              <div class="dropdown-menu dropdown-menu-right p-1">
                <div class="d-flex ">
                  <a class="dropdown-item px-25" href="#">
                    <span class="fa-stack w-auto">
                      <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                      <i class="fas fa-smile text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                  </span>
                  </a>

                  <a class="dropdown-item px-25" href="#">
                    <span class="fa-stack w-auto">
                      <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                      <i class="fas fa-smile-beam text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                  </span>
                  </a>

                  <a class="dropdown-item px-25" href="#">
                    <span class="fa-stack w-auto">
                      <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                      <i class="fas fa-smile-wink text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                  </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="ml-auto">
            <span class="text-grey-d1 text-95 mr-2">Draft saved at 9:21 pm</span>
            <a href="#" class="btn btn-tp btn-outline-grey btn-h-lighter-danger btn-a-lighter-danger">
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- the button that toggle modal -->
      <!--
<div class="aside-header fade position-br mb-5 d-flex align-items-center">
<button type="button" class="shadow ml-2 btn btn-info radius-round mw-auto px-35 py-25"  data-toggle="modal" data-target="#aside-compose">
 <i class="fa fa-pen mr-1"></i>
 Compose
</button> 
</div>
-->
    </div>

  </div>    
</div>