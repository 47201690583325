<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Certificación
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Código de Control
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Certificación</span>
      Código de Control
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  
                </div>
              </div>
            </div>
		    </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="export btn-group">
                    <a href="{{formatoPruebaExcel}}" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                      <i class="fa fa-download text-green-d1"></i>
                      <span class="caret"></span>
                    </a>
                    <a href="{{ejemploPruebaExcel}}" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                        <i class="fa fa-download text-green-d1"></i>
                        <span class="caret"></span>
                      </a>
                    <button (click)="clickBotonSubir('subir-excel-pruebas')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                      <i class="fa fa-upload text-red fileUpload-button"></i>
                      <input (change)="subirExcelPruebasDosificaciones($event)" id="subir-excel-pruebas" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>

          <table id="tabla-pruebas-codigo-control" class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Autorización</th>
                    <th>Número Factura</th>
                    <th>NIT/CI</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Llave Digital</th>
                    <th>Código de Control Esperado</th>
                    <th>Código de Control Generado</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let pruebaCodigoControl of pruebasCodigoControl">
                    <td>
                        {{pruebaCodigoControl.autorizacion}}
                    </td>
                    <td>
                        {{pruebaCodigoControl.factura}}
                    </td>
                    <td>{{pruebaCodigoControl.nit}}</td>
                    <td>{{pruebaCodigoControl.fecha}}</td>

                    <td>
                        {{pruebaCodigoControl.monto}}
                    </td>
                    <td>
                        {{pruebaCodigoControl.llave_digital}}
                    </td>
                    <td>
                        {{pruebaCodigoControl.codigo_control_esperado}}
                    </td>
                    <td>
                        {{pruebaCodigoControl.codigo}}
                    </td>
                </tr>
            </tbody>
        </table>

        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>
