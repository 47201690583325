<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Registro de Cotización</legend>
        <div class="row">
            <div class="col-4">
                <label>¿Cliente registrado?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="cliente_registrado" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cliente_registrado" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
            <div class="col-4">
                <label>¿Imprimir usuario?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="impresion_usuario" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.usuario" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
            <div class="col-8">
                <label>¿Cantidad Decimal?</label>
            </div>
            <div class="col-4">
                <label>
                    <input name="cantidad_decimal" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cantidad_decimal" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Impresión</legend>
        <div class="row">
            <div class="col-3">
                <label>Tamaño papel cotización</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="papel_nota_cotizacion" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_nota_cotizacion">
                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                </select>
            </div>
        </div>
    </fieldset>
</div>