import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from 'src/app/global';
import { ReplaySubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

declare let gapi:any;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(private http: HttpClient,@Inject(DOCUMENT) private readonly document: any) { }

  public obtenerClases(nombre_corto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tipos/"+nombre_corto,GlobalVariable.httpOptions);
  }

  public obtenerClasesDatos(nombre_corto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clases/"+nombre_corto,GlobalVariable.httpOptions);
  }

  public obtenerClasesEmpresa(id_empresa,nombre_corto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tipos/empresa/"+id_empresa+"/"+nombre_corto,GlobalVariable.httpOptions);
  }

  public obtenerTipoClaseDato(id_empresa,tipo_nombre_corto,clase_nombre_corto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tipo/"+tipo_nombre_corto+"/clase/"+clase_nombre_corto+"/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public actualizarClase(clase){
    return this.http.put(GlobalVariable.API_SERVER_URL+"clase/"+clase.id,clase,GlobalVariable.httpOptions);
  }

  public actualizarTipo(tipo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"tipos/"+tipo.id,tipo,GlobalVariable.httpOptions);
  }

  public crearTipo(tipo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"tipos/empresa",tipo,GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientoProductos(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-vencimientos-productos/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientoCreditos(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-vencimientos-creditos/"+filtro.id_empresa+"/sucursal/"+filtro.id_sucursal+"/usuario/"+filtro.id_usuario,GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientoDeudas(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contador-vencimientos-deudas/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientoPagosEstudiantes(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-vencimientos-pagos-estudiantes/"+filtro.id_empresa+"/estado/1",GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientoPagosEstudiantesAnulados(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-vencimientos-pagos-estudiantes/"+filtro.id_empresa+"/estado/0",GlobalVariable.httpOptions);
  }

  public obtenerContadorAsuntosAdministrativos(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-asuntos-administrativos/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerContadorPedidosNotificacion(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-pedidos-notificacion/sucursal/"+filtro.id_sucursal+"/usuario/"+filtro.id_usuario,GlobalVariable.httpOptions);
  }

  public obtenerContadorInventarioMinimo(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-inventario-minimo/empresa/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerContadorResumenVentas(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-resumen-ventas/sucursales/"+filtro.id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerContadorMensajes(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-mensajes-usuario/"+filtro.id_usuario,GlobalVariable.httpOptions);
  }

  public obtenerTipos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tipos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerTiposEmpresa(id_empresa,nombre_corto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tipos/empresa/"+id_empresa+"/nombre-corto/"+nombre_corto,GlobalVariable.httpOptions);
  }

  public obtenerDatosApp(){
    return this.http.get(GlobalVariable.APP_DATA,GlobalVariable.httpOptions);
  }

  public obtenerDatosGece(){
    return this.http.get(GlobalVariable.SERVER_URL+"gece/datos.json",GlobalVariable.httpOptions);
  }

  public obtenerEscuela(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresa-escuela/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerFechaHoraServidor(){
    return this.http.get(GlobalVariable.API_SERVER_URL+"snapquick-fecha",GlobalVariable.httpOptions);
  }

  public descargarDatosSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-descargar-conceptos/"+id_empresa,GlobalVariable.httpOptions);
  }

  obtenerPosicionActual(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  public agregarParticipantesGrupoWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"participantes-grupo-whatsapp",datos,GlobalVariable.httpOptions);
  }

  public obtenerContadorVentasNoContabilizadas(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-ventas-no-contabilizadas/empresa/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerVentasNoContabilizadas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas-no-contabilizadas/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerContadorPagosVentaNoContabilizados(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-pagos-ventas-no-contabilizados/empresa/"+filtro.id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerPagosVentasNoContabilizadas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pagos-ventas-no-contabilizados/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }
}
