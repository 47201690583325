import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-desarrollo-curricular',
  templateUrl: './desarrollo-curricular.component.html',
  styleUrls: ['./desarrollo-curricular.component.css']
})
export class DesarrolloCurricularComponent extends BaseComponent implements OnInit {

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
	}

}
