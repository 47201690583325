import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ClientesService } from '../../services/clientes/clientes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { ListaPagosVentasComponent } from '../../components/lista-pagos-ventas/lista-pagos-ventas.component';
import { PagoVentaComponent } from '../pago-venta/pago-venta.component';
import { VistaVentaComponent } from '../vista-venta/vista-venta.component';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { ClientesHistoricoVentasComponent } from '../../components/clientes-historico-ventas/clientes-historico-ventas.component';
import { Socket } from 'ngx-socket-io';
import { MensajesEmpresaService } from 'src/app/marketing/services/mensajes-empresa/mensajes-empresa.service';
import { ConfiguracionAplicacionCuentasPorCobrarComponent } from 'src/app/base/components/configuracion-aplicacion-cuentas-por-cobrar/configuracion-aplicacion-cuentas-por-cobrar.component';

declare const PDFDocument:any;
declare const blobStream:any;
declare const $:any;

@Component({
  selector: 'app-cuentas-por-cobrar',
  templateUrl: './cuentas-por-cobrar.component.html',
  styleUrls: ['./cuentas-por-cobrar.component.css']
})
export class CuentasPorCobrarComponent extends BaseComponent implements OnInit {

  clientes:any[]=[];
  datosCliente:any;
  cliente:any;
  modalPagos:NgbModalRef;
  totalgeneral:number=0;
  total_importe:number=0;
  total_a_cuenta:number=0;
  total_devoluciones:number=0;
  total_saldo:number=0;
  clienteVentas:any;
  busqueda_contacto:any={};
  pago_general:any={};
  cliente_contacto:any={};

  
  modalHistoricoVentas:NgbModalRef;
  cuentacliente_modal:NgbModalRef;
  @ViewChild('cuentacliente')
  private cuentacliente_ref: TemplateRef<any>;

  pago_general_modal:NgbModalRef;
  @ViewChild('pago_general_modal')
  private pago_general_modal_ref: TemplateRef<any>;

  cuenta_contacto_cliente_modal:NgbModalRef;
  @ViewChild('cuenta_contacto_cliente_modal')
  private cuenta_contacto_cliente_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private clientesService:ClientesService,
    public usuarioService:UsuarioService,
    private pdfService:PdfService,
    private ventaService:VentasService,
    private socket: Socket,
    private mensajesEmpresaService:MensajesEmpresaService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  
      ngOnInit(): void {
      this.init();
      let fecha_actual=new Date();
      this.buscarSubAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      this.filter={
        id_empresa:this.usuario.id_empresa,
        sucursales:this.usuario.empresa.usar_clientes_sucursal?Util.obtenerSucursalesUsuario(this.usuario):[{nombre:"TODOS",id:0}],
        sucursal: this.usuario.empresa.usar_clientes_sucursal?Util.obtenerSucursalesUsuario(this.usuario)[0]:{nombre:"TODOS",id:0},
        usuarios:[{nombre_usuario:"TODOS",id:0}],
        usuario: {nombre_usuario:"TODOS",id:0},
        cliente:null,
        contacto_cliente:null,
        cuentas_liquidadas:false,
        fecha_fin:{
          year: fecha_actual.getFullYear(), 
          month: (fecha_actual.getMonth()+1),
          day:fecha_actual.getDate()
        }
      }
      this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_COBRAR);

      let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(es_usuario_administrador){	
          this.obtenerUsuarios();
        }else{
          this.filter.usuarios=[this.usuario]
          this.filter.usuario=this.usuario;
        }
      }else{
        this.obtenerUsuarios();
      }

      this.column = "codigo";
      this.getSearch(this.text_search,null);
    }

    obtenerUsuarios(){
      this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
        this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
      });
    }

    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.clientesService.obtenerCuentasPorCobrar(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.clientes=dato.clientes;
        this.blockUI.stop();
      });
    }

    generarExcelEstadoCuentasClientes(){
      this.blockUI.start();
      var data = [["","","ESTADO CUENTAS CLIENTE"],["Nº","Código","Cliente","Nit/Ci","Total","A Cuenta","Saldo"]]
      let total_importe:number=0,total_a_cuenta=0,total_saldo=0;
      if(this.usuario.empresa.usar_devoluciones_ventas && this.configuracion_pagina.getConfiguration().devoluciones.show){
				data[1].splice(5, 0, "Devoluciones");
			}
      for(var i=0;i<this.clientes.length;i++){
        var columns=[];
        total_importe= total_importe+this.clientes[i].total_cliente;
        total_a_cuenta= total_a_cuenta+this.clientes[i].a_cuenta_cliente;
        total_saldo= total_saldo+this.clientes[i].saldo_cliente;
        columns.push((i+1)+"");
        columns.push(this.clientes[i].codigo);
        columns.push(this.clientes[i].identificacion);
        columns.push(this.clientes[i].nit);
        columns.push(this.clientes[i].total_cliente);
        if(this.usuario.empresa.usar_devoluciones_ventas && this.configuracion_pagina.getConfiguration().devoluciones.show){
          columns.push(this.clientes[i].devoluciones);
        }
        columns.push(this.clientes[i].a_cuenta_cliente);
        columns.push(this.clientes[i].saldo_cliente);
        data.push(columns);
      }
      data.push(["","","","Total General",(Math.round(total_importe*100)/100)+"",(Math.round(total_a_cuenta*100)/100)+"",(Math.round(total_saldo*100)/100)+""]);

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte-cuentas-por-cobrar");
      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );

      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 40;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 20;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Reporte-cuentas-por-cobrar.xlsx");
      })
      this.blockUI.stop();
    }

    generarPdfEstadoCuentasClientes(){
      this.blockUI.start();
      if(this.configuracion_pagina.getOrden().impresion.papel_pdf== GlobalVariable.Dictionary.PAPEL_CARTA){
        this.generarPdfEstadoCuentasClientesCarta();
      }else if(this.configuracion_pagina.getOrden().impresion.papel_pdf== GlobalVariable.Dictionary.PAPEL_ROLLO){
        this.generarPdfEstadoCuentasClientesRollo();
      }
      this.blockUI.stop();
    }

    generarPdfEstadoCuentasClientesCarta(){
      var titulo="ESTADO DE CUENTA DE CLIENTES";
      var fecha_reporte=new Date();
        var doc = new PDFDocument({size: 'letter',margin:10});
        var stream = doc.pipe(blobStream());
        // draw some text
        var total_total=0,total_a_cuenta=0,total_saldo=0;
      
        var y=150,itemsPorPagina=30,items=0,pagina=1,totalPaginas=Math.ceil(this.clientes.length/itemsPorPagina);
        this.dibujarCabeceraPDFEstadoCuentasClientes(doc);
        this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
          this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
          (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
        (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
        (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
        fecha_reporte,{imprimir_usuario:true});
        doc.font('Helvetica',8);
        for(var i=0;i<this.clientes.length && items<=itemsPorPagina;i++){
          doc.rect(50,y-15,540,20).stroke();
          doc.text(i+1,55,y-7);
          doc.text(this.clientes[i].codigo?this.clientes[i].codigo:"",90,y-7,{width:45,align:"left"});
          doc.text(this.clientes[i].identificacion,170,y-7);
          doc.text(this.clientes[i].nit,350,y-7,{width:50,align:"left"});
          doc.text(this.clientes[i].total_cliente,425,y-7,{width:50,align:"right"});
          doc.text(this.clientes[i].a_cuenta_cliente,475,y-7,{width:50,align:"right"});
          doc.text(this.clientes[i].saldo_cliente,530,y-7,{width:50,align:"right"});
          total_total=total_total+this.clientes[i].total_cliente;
          total_a_cuenta=total_a_cuenta+this.clientes[i].a_cuenta_cliente;
          total_saldo=total_saldo+this.clientes[i].saldo_cliente;
          y=y+20;
          items++;
          
          if(items==itemsPorPagina){
            doc.addPage({margin:0,bufferPages: true});
            y=150;
            items=0;
            pagina=pagina+1;
            this.dibujarCabeceraPDFEstadoCuentasClientes(doc);
            this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
              this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
              (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
            (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
            (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
            fecha_reporte,{imprimir_usuario:true});
            doc.font('Helvetica',8);
          }
        }
        doc.rect(50,y-15,540,20).stroke();
        doc.font('Helvetica-Bold',8);
        doc.text("Total General",380,y-5);
        doc.text(Math.round(total_total*100)/100,425,y-5,{width:50,align:"right"});
        doc.text(Math.round(total_a_cuenta*100)/100,475,y-5,{width:50,align:"right"});
        doc.text(Math.round(total_saldo*100)/100,530,y-5,{width:50,align:"right"});
        doc.end();
        stream.on('finish', function() {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
        });
    }

    generarPdfEstadoCuentasClientesRollo(){
      var doc = new PDFDocument({ size: [227, 200 + (20 * (this.clientes.length - 2))], margin: 10 });
      var stream = doc.pipe(blobStream());
      let titulo="ESTADO DE CUENTA DE CLIENTES";
      doc.font('Helvetica-Bold', 8);
      doc.text(this.usuario.empresa.nombre.toUpperCase(), { align: 'center' });
      doc.moveDown(0.4);
      doc.text(this.usuario.empresa.direccion.toUpperCase(), { align: 'center' });
      doc.moveDown(0.4);
      var telefono = (this.usuario.empresa.telefono1 != null ? this.usuario.empresa.telefono1 : "") +
        (this.usuario.empresa.telefono2 != null ? "-" + this.usuario.empresa.telefono2 : "") +
        (this.usuario.empresa.telefono3 != null ? "-" + this.usuario.empresa.telefono3 : "");
      doc.text("TELF.: " + telefono, { align: 'center' });
      doc.moveDown(0.5);
      doc.font('Helvetica-Bold', 8);
      doc.text(titulo , { align: 'center' });
      doc.font('Helvetica', 7);
      doc.moveDown(0.4);
      doc.text("---------------------------------------------------------------------------------", { align: 'center' });
      doc.moveDown(0.2);
      doc.text("Nº       CLIENTE                               TOTAL   A CUENTA  SALDO", { align: 'left' });
      doc.moveDown(0.2);
      doc.text("---------------------------------------------------------------------------------", { align: 'center' });
      doc.moveDown(0.4);
      var y = doc.y, total_total = 0, total_a_cuenta = 0, total_saldo = 0;
      for(var i=0;i<this.clientes.length;i++){
        doc.text(i+1,15,y);
        doc.text(this.clientes[i].identificacion,30,y,{width:100});
        doc.text(this.clientes[i].total_cliente,140,y);
        doc.text(this.clientes[i].a_cuenta_cliente,170,y);
        doc.text(this.clientes[i].saldo_cliente,200,y);
        total_total=total_total+this.clientes[i].total_cliente;
        total_a_cuenta=total_a_cuenta+this.clientes[i].a_cuenta_cliente;
        total_saldo=total_saldo+this.clientes[i].saldo_cliente;
        y=y+20;
        doc.moveDown(0.4);
      }

      doc.text("--------------", 10, y, { align: 'right' });
      //oc.text("--------------------",{align:'right'});
      doc.moveDown(0.3);
      doc.text("TOTAL Bs.              " + total_total.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.text("A CUENTA Bs.              " + total_a_cuenta.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.text("SALDO Bs.              " + total_saldo.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.end();
      stream.on('finish', function () {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL, '_blank', 'location=no');
      });
    }
  
    dibujarCabeceraPDFEstadoCuentasClientes(doc){
        doc.font('Helvetica-Bold',8);
        doc.text("No",55,120);
        doc.text("Codigo",90,120);
        doc.text("Cliente",170,120,{width:50});
        doc.text("Nit/Ci",350,120,{width:60});
        doc.text("Total",440,120,{width:50});
        doc.text("A Cuenta",500,120,{width:50});
        doc.text("Saldo",560,120,{width:50});	
        doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();
    }

    async generarPdfResumidoEstadoCuentasCliente(cliente){
      if(this.configuracion_pagina.getOrden().impresion.papel_pdf== GlobalVariable.Dictionary.PAPEL_CARTA){
        return await this.generarPdfResumidoEstadoCuentasClienteCarta(cliente);
      }else if(this.configuracion_pagina.getOrden().impresion.papel_pdf== GlobalVariable.Dictionary.PAPEL_ROLLO){
        return await this.generarPdfResumidoEstadoCuentasClienteRollo(cliente);
      }
    }

    async generarPdfResumidoEstadoCuentasClienteCarta(cliente){
      this.datosCliente=await this.clientesService.obtenerCuentaCliente(cliente.id,this.filter.cuentas_liquidadas).toPromise();
      var titulo="KARDEX ESTADO DE CUENTA DE CLIENTE";
      var fecha_reporte=new Date();
      cliente.ventas=this.datosCliente.ventas;
      cliente.ventas_filtradas=this.datosCliente.ventas;
      var doc = new PDFDocument({size: 'letter',margin:10});
      var stream = doc.pipe(blobStream());
      var total_importe=0,total_a_cuenta=0,total_saldo=0;
    
      var y=150,itemsPorPagina=20,items=0,pagina=1,totalPaginas=Math.ceil(cliente.ventas_filtradas.length/itemsPorPagina);
      this.dibujarCabeceraPDFEstadoCuentasCliente(doc,cliente);
      this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
        this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
        (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
      (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
      (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
      fecha_reporte,{imprimir_usuario:false});
      doc.font('Helvetica',8);
      for(var i=0;i<cliente.ventas_filtradas.length && items<=itemsPorPagina;i++){
        items++;
        doc.rect(50,y-15,540,30).stroke();
        cliente.ventas_filtradas[i].fecha=new Date(cliente.ventas_filtradas[i].fecha);
        doc.text(cliente.ventas_filtradas[i].fecha.getDate()+"/"+(cliente.ventas_filtradas[i].fecha.getMonth()+1)+"/"+cliente.ventas_filtradas[i].fecha.getFullYear(),55,y-7);
        doc.text(cliente.ventas_filtradas[i].transaccion,93,y-11,{width:65,align:"center"});
        doc.text(cliente.ventas_filtradas[i].factura,170,y-7,{width:45,align:"left"});
  
        //loop detalle de venta
        var descripcion=cliente.ventas_filtradas[i].productos+(cliente.ventas_filtradas[i].descripcion?" "+cliente.ventas_filtradas[i].descripcion:"");
        /*for(var k=0;k<cliente.ventas_filtradas[i].detallesVenta.length;k++){
          descripcion=descripcion+
            (cliente.ventas_filtradas[i].detallesVenta[k].producto?cliente.ventas_filtradas[i].detallesVenta[k].producto.nombre:(cliente.ventas_filtradas[i].detallesVenta[k].combo?cliente.ventas_filtradas[i].detallesVenta[k].combo.nombre:""))+" "+(cliente.ventas_filtradas[i].detallesVenta[k].descripcion?cliente.ventas_filtradas[i].detallesVenta[k].descripcion:"")+", ";
        }*/
        var alt=descripcion.length>100?y-12:descripcion.length>40?y-7:y;
        doc.text(descripcion.length>155?descripcion.substring(0,155)+"...":descripcion,220,alt,{width:210});
  
        doc.text(cliente.ventas_filtradas[i].total,425,y,{width:50,align:"right"});
        doc.text(cliente.ventas_filtradas[i].a_cuenta,475,y,{width:50,align:"right"});
        doc.text(cliente.ventas_filtradas[i].saldo,530,y,{width:50,align:"right"});
        total_importe=total_importe+cliente.ventas_filtradas[i].total;
        total_a_cuenta=total_a_cuenta+cliente.ventas_filtradas[i].a_cuenta;
        total_saldo=total_saldo+cliente.ventas_filtradas[i].saldo;
        y=y+30;
        if(cliente.ventas_filtradas[i].pagos_venta){
          let pagos_venta=cliente.ventas_filtradas[i].pagos_venta.split(",");
          for(let j=0;j<pagos_venta.length;j++){

            items++;
            doc.rect(50,y-15,0,30).stroke();
            doc.rect(590,y-15,0,30).stroke();
            let date=new Date(pagos_venta[j].split('|')[0]);
            doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),100,y,{width:50,align:"right"});
            doc.text(parseInt(pagos_venta[j].split('|')[1]),170,y,{width:50,align:"right"});
            doc.text("Pago a/c (Bs. "+parseFloat(pagos_venta[j].split('|')[2])+")",240,y);
            y=y+30;
            if(items==itemsPorPagina){
              doc.addPage({margin:0,bufferPages: true});
              y=150;
              items=0;
              pagina=pagina+1;
              this.dibujarCabeceraPDFEstadoCuentasCliente(doc,cliente);
              this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
                this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
                (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
              (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
              (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
              fecha_reporte,{imprimir_usuario:true});
              doc.font('Helvetica',8);
            }
          }
        }
        /*if(cliente.ventas_filtradas[i].pagosVenta.length>0){
          for (var j = 0; j < cliente.ventas_filtradas[i].pagosVenta.length; j++) {
            if(cliente.ventas_filtradas[i].pagosVenta[j].activo){
              items++;
              doc.rect(50,y-15,0,30).stroke();
              doc.rect(590,y-15,0,30).stroke();
              let date=new Date(cliente.ventas_filtradas[i].pagosVenta[j].createdAt);
              doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),100,y,{width:50,align:"right"});
              doc.text(cliente.ventas_filtradas[i].pagosVenta[j].numero_documento,170,y,{width:50,align:"right"});
              doc.text("Pago a/c (Bs. "+cliente.ventas_filtradas[i].pagosVenta[j].monto_pagado+")" + " "+(cliente.ventas_filtradas[i].pagosVenta[j].observacion?cliente.ventas_filtradas[i].pagosVenta[j].observacion:""),240,y);
              y=y+30;
              if(items==itemsPorPagina){
                doc.addPage({margin:0,bufferPages: true});
                y=150;
                items=0;
                pagina=pagina+1;
                this.dibujarCabeceraPDFEstadoCuentasCliente(doc,cliente);
                this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
                  this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
                  (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
                (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
                (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
                fecha_reporte,{imprimir_usuario:true});
                doc.font('Helvetica',8);
              }
            }
          };
        }*/
        
        if(items==itemsPorPagina){
          doc.addPage({margin:0,bufferPages: true});
          y=150;
          items=0;
          pagina=pagina+1;
          this.dibujarCabeceraPDFEstadoCuentasCliente(doc,cliente);
          this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
            this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
            (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
          (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
          (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
          fecha_reporte,{imprimir_usuario:true});
          doc.font('Helvetica',8);
        }
      }
      doc.rect(50,y-15,540,20).stroke();
      doc.font('Helvetica-Bold',8);
      doc.text("Total General",380,y-7);
      doc.text(Math.round(total_importe*100)/100,425,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_a_cuenta*100)/100,475,y-7,{width:50,align:"right"});
      doc.text(Math.round(total_saldo*100)/100,530,y-7,{width:50,align:"right"});
      doc.end();
      return await new Promise(function (resolve, reject) {
        stream.on('finish', function () {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
          resolve(stream.toBlob());
        });
        stream.on('error', reject)
      });
    }

    async generarPdfResumidoEstadoCuentasClienteRollo(cliente){
      this.datosCliente=await this.clientesService.obtenerCuentaCliente(cliente.id,this.filter.cuentas_liquidadas).toPromise();
      var fecha_reporte=new Date();
      cliente.ventas=this.datosCliente.ventas;
      cliente.ventas_filtradas=this.datosCliente.ventas;
      let distancia=0;
      for(var i=0;i<cliente.ventas_filtradas.length;i++){
        distancia=distancia+30;
        if(cliente.ventas_filtradas[i].pagosVenta.length>0){
          for (var j = 0; j < cliente.ventas_filtradas[i].pagosVenta.length; j++) {
            if(cliente.ventas_filtradas[i].pagosVenta[j].activo){
              distancia=distancia+20;
            }
          };
        }
      }
      var doc = new PDFDocument({ size: [227, 200 + distancia], margin: 10 });
      var stream = doc.pipe(blobStream());
      var total_importe=0,total_a_cuenta=0,total_saldo=0;

      let titulo="KARDEX ESTADO DE CUENTA DE CLIENTE";
      doc.font('Helvetica-Bold', 8);
      doc.text(this.usuario.empresa.nombre.toUpperCase(), { align: 'center' });
      doc.moveDown(0.4);
      doc.text(this.usuario.empresa.direccion.toUpperCase(), { align: 'center' });
      doc.moveDown(0.4);
      var telefono = (this.usuario.empresa.telefono1 != null ? this.usuario.empresa.telefono1 : "") +
        (this.usuario.empresa.telefono2 != null ? "-" + this.usuario.empresa.telefono2 : "") +
        (this.usuario.empresa.telefono3 != null ? "-" + this.usuario.empresa.telefono3 : "");
      doc.text("TELF.: " + telefono, { align: 'center' });
      doc.moveDown(0.5);
      doc.font('Helvetica-Bold', 8);
      doc.text(titulo , { align: 'center' });
      doc.font('Helvetica', 7);
      doc.moveDown(0.4);
      doc.text("Cliente: "+cliente.identificacion.toUpperCase(), { align: 'center' });
      doc.moveDown(0.4);
      doc.text("---------------------------------------------------------------------------------", { align: 'center' });
      doc.moveDown(0.2);
      doc.text("Fecha    Nº. Doc.      Descripción       TOTAL   A CUENTA  SALDO", { align: 'left' });
      doc.moveDown(0.2);
      doc.text("---------------------------------------------------------------------------------", { align: 'center' });
      doc.moveDown(0.4);
      var y = doc.y, total_importe = 0, total_a_cuenta = 0, total_saldo = 0;

      for(var i=0;i<cliente.ventas_filtradas.length;i++){
        cliente.ventas_filtradas[i].fecha=new Date(cliente.ventas_filtradas[i].fecha);
        doc.text(cliente.ventas_filtradas[i].fecha.getDate()+"/"+(cliente.ventas_filtradas[i].fecha.getMonth()+1)+"/"+cliente.ventas_filtradas[i].fecha.getFullYear(),5,y,{width:35,align:"left"});
        doc.text(cliente.ventas_filtradas[i].factura,15,y+20,{width:45,align:"left"});
  
        //loop detalle de venta
        var descripcion="";
        for(var k=0;k<cliente.ventas_filtradas[i].detallesVenta.length;k++){
          descripcion=descripcion+
            (cliente.ventas_filtradas[i].detallesVenta[k].producto?cliente.ventas_filtradas[i].detallesVenta[k].producto.nombre:(cliente.ventas_filtradas[i].detallesVenta[k].combo?cliente.ventas_filtradas[i].detallesVenta[k].combo.nombre:""))+" "+(cliente.ventas_filtradas[i].detallesVenta[k].descripcion?cliente.ventas_filtradas[i].detallesVenta[k].descripcion:"")+", ";
        }
        var alt=descripcion.length>50?y-7:y;
        doc.text(descripcion.length>80?descripcion.substring(0,80)+"...":descripcion,40,alt,{width:100});
  
        doc.text(cliente.ventas_filtradas[i].total.toFixed(2),135,y);
        doc.text(cliente.ventas_filtradas[i].a_cuenta.toFixed(2),165,y);
        doc.text(cliente.ventas_filtradas[i].saldo.toFixed(2),195,y);
        total_importe=total_importe+cliente.ventas_filtradas[i].total;
        total_a_cuenta=total_a_cuenta+cliente.ventas_filtradas[i].a_cuenta;
        total_saldo=total_saldo+cliente.ventas_filtradas[i].saldo;
        y=y+30;
        doc.moveDown(0.4);
        if(cliente.ventas_filtradas[i].pagosVenta.length>0){
          for (var j = 0; j < cliente.ventas_filtradas[i].pagosVenta.length; j++) {
            if(cliente.ventas_filtradas[i].pagosVenta[j].activo){
              let date=new Date(cliente.ventas_filtradas[i].pagosVenta[j].createdAt);
              doc.text(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear(),25,y);
              doc.text(cliente.ventas_filtradas[i].pagosVenta[j].numero_documento,60,y);
              doc.text("Pago a/c (Bs. "+cliente.ventas_filtradas[i].pagosVenta[j].monto_pagado+")" + " "+(cliente.ventas_filtradas[i].pagosVenta[j].observacion?cliente.ventas_filtradas[i].pagosVenta[j].observacion:""),85,y);
              y=y+20;
              doc.moveDown(0.4);
            }
          };
        }
      }

      doc.text("--------------", 10, y, { align: 'right' });
      //oc.text("--------------------",{align:'right'});
      doc.moveDown(0.3);
      doc.text("TOTAL Bs.              " + total_importe.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.text("A CUENTA Bs.              " + total_a_cuenta.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.text("SALDO Bs.              " + total_saldo.toFixed(2), { align: 'right' });
      doc.moveDown(0.4);
      doc.end();
      stream.on('finish', function () {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL, '_blank', 'location=no');
      });
    }

    dibujarCabeceraPDFEstadoCuentasCliente(doc,cliente){
      doc.font('Helvetica-Bold',8);
      doc.roundedRect(360,70,230,20,5).stroke();
      doc.text("CLIENTE : ",385,80);
      doc.text(cliente.identificacion,440,80);		
      
      doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
      doc.font('Helvetica-Bold',8);
      doc.text("Fecha",55,120);
      doc.text("Transaccion",95,120);
      doc.text("Nro. Doc.",170,120,{width:50});
      doc.text("Descripción",240,120,{width:60});
      doc.text("Importe",440,120,{width:50});
      doc.text("A Cuenta",500,120,{width:50});
      doc.text("Saldo",560,120,{width:50});	
    }

    obtenerExcelEstadoCuentaCliente(cliente){
      this.cliente=cliente;
      this.blockUI.start();
      this.clientesService.obtenerCuentaCliente(cliente.id,this.filter.cuentas_liquidadas).subscribe((datosCliente:any)=>{
        this.cliente.ventas=datosCliente.ventas;
        this.generarExcelEstadoCuentasCliente(this.cliente,0);
      });
    }

    generarExcelEstadoCuentasCliente(cliente,tipoImprecion){		
      var data=this.generarExcelCliente(cliente,tipoImprecion);
      
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte-cuentas-por-cobrar-"+cliente.razon_social);
      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );

      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 40;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 20;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Reporte-cuentas-por-cobrar-"+cliente.razon_social+".xlsx");
      })
      this.blockUI.stop();
  }
  
  generarExcelCliente(cliente,tipoImprecion){
		var data = [["","","ESTADO CUENTAS CLIENTE"],["Deudor :"+cliente.razon_social],["FECHA","TRANSACCION","NRO. DOCUMENTO","DESCRIPCIÓN","IMPORTE","A CUENTA","SALDO"]]
		var total_importe=0,total_a_cuenta=0,total_saldo=0;

		for(var i=0;i<cliente.ventas.length;i++){
			var columns=[];
			
			total_importe= total_importe+cliente.ventas[i].total;
			total_a_cuenta= total_a_cuenta+cliente.ventas[i].a_cuenta;
			total_saldo= total_saldo+cliente.ventas[i].saldo;
			cliente.ventas[i].fecha=new Date(cliente.ventas[i].fecha);
			columns.push(cliente.ventas[i].fecha.getDate()+"/"+(cliente.ventas[i].fecha.getMonth()+1)+"/"+cliente.ventas[i].fecha.getFullYear());
			columns.push(cliente.ventas[i].transaccion);			
			columns.push(cliente.ventas[i].factura);
			var detalle="";
			/*for(var j=0;j<cliente.ventas[i].detallesVenta.length;j++){
				detalle=detalle+(cliente.ventas[i].detallesVenta[j].producto?cliente.ventas[i].detallesVenta[j].producto.nombre:cliente.ventas[i].detallesVenta[j].combo?cliente.ventas[i].detallesVenta[j].combo.nombre:cliente.ventas[i].detallesVenta[j].descripcion)+","
			}*/
			columns.push(detalle);
			columns.push(cliente.ventas[i].total);				
			columns.push(cliente.ventas[i].a_cuenta);				
			columns.push(cliente.ventas[i].saldo);				
			data.push(columns);
				/*if(tipoImprecion!=0 &&cliente.ventas[i].pagosVenta.length!=0)
				{
					for (var j = 0; j < cliente.ventas[i].pagosVenta.length; j++) {
						var columns2=[];
						let date=new Date(cliente.ventas[i].pagosVenta[j].createdAt);
						columns2.push("");
						columns2.push(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear());
						columns2.push(cliente.ventas[i].pagosVenta[j].numero_documento);
						columns2.push("Pago a/c Factura nro.  "+cliente.ventas[i].factura+"("+cliente.ventas[i].pagosVenta[j].total+" -)");
						columns2.push(cliente.ventas[i].pagosVenta[j].total);
						data.push(columns2);
					}
				}*/
			
		}

		data.push(['','','TOTAL GENERAL',null,total_importe+"",total_a_cuenta+"",total_saldo+""]);
		return data;
  }
  
  verPagos(){
		this.modalPagos = this.modalService.open(ListaPagosVentasComponent, {windowClass:'lista-pagos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalPagos.componentInstance.venta = null;
		this.modalPagos.componentInstance.usuario = this.usuario;
		this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modalPagos.close();
				}
			}else{
				this.modalPagos.close();
			}
			
		});
  }
  
  async abrirEstadoCuentaCliente(cliente){
    /*this.cliente=cliente?cliente:this.cliente;
    await this.obtenerCuentaCliente();
    this.cuentacliente_modal=this.modalService.open(this.cuentacliente_ref, {windowClass:'cuenta-por-cobrar',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.cuentacliente_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });*/

    this.modalHistoricoVentas = this.modalService.open(ClientesHistoricoVentasComponent, {windowClass:'historico-ventas', scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modalHistoricoVentas.componentInstance.cliente = cliente;
    this.modalHistoricoVentas.componentInstance.usuario = this.usuario;
    this.modalHistoricoVentas.componentInstance.mostrar_filtro_fechas = false;
    this.modalHistoricoVentas.componentInstance.mostrar_boton_busqueda = false;
    this.modalHistoricoVentas.componentInstance.cuentas_liquidadas = this.filter.cuentas_liquidadas;
    this.modalHistoricoVentas.componentInstance.titulo_reporte = "CUENTAS POR COBRAR";
    
    this.modalHistoricoVentas.componentInstance.alTerminar.subscribe((res) => {
      this.modalHistoricoVentas.close();
    });
  }
  
  calcularTotales(){
		this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0;
		for(var i=0;i<this.clienteVentas.ventas_filtradas.length;i++){
			this.total_importe=this.total_importe+this.cliente.ventas_filtradas[i].total;
			this.total_a_cuenta=this.total_a_cuenta+this.cliente.ventas_filtradas[i].a_cuenta;
			this.total_saldo=this.total_saldo+this.cliente.ventas_filtradas[i].saldo;
		}
  }

  async obtenerCuentaCliente(){
    this.blockUI.start();
    let datosCliente:any=await this.clientesService.obtenerCuentaCliente(this.cliente.id,this.filter.cuentas_liquidadas).toPromise();
    this.cliente.ventas=datosCliente?datosCliente.ventas:[];
    this.cliente.ventas_filtradas=datosCliente?datosCliente.ventas:[];

    for(var i=0;i<this.cliente.ventas.length;i++){
      var fecha = new Date(this.cliente.ventas[i].fecha);
      this.cliente.ventas[i].totalgeneral=0;
      this.cliente.ventas[i].totalPago=0;
      if(i==0){
        this.cliente.ventas[i].totalgeneral = this.cliente.ventas[i].totalgeneral+ this.cliente.ventas[i].saldo;
      }else{
        this.cliente.ventas[i].totalgeneral = this.cliente.ventas[i-1].totalgeneral+ this.cliente.ventas[i].saldo;	
      }
      for(var f=0;f<this.cliente.ventas[i].pagosVenta.length;f++){
        this.cliente.ventas[i].pagosVenta[f].total = this.cliente.ventas[i].pagosVenta[f].saldo_anterior-this.cliente.ventas[i].pagosVenta[f].monto_pagado;			
      
        this.cliente.ventas[i].totalPago=this.cliente.ventas[i].totalPago+this.cliente.ventas[i].pagosVenta[f].monto_pagado;				
      }
      this.cliente.ventas[i].fecha_vencimiento=Util.sumaFecha(this.cliente.ventas[i].dias_credito,fecha);
    }

    var i=this.cliente.ventas.length>0?this.cliente.ventas.length-1:0;		
    this.totalgeneral = this.cliente.ventas.length>0?this.cliente.ventas[i].totalgeneral:0;		
    this.clienteVentas = this.cliente;
    this.calcularTotales();
    this.blockUI.stop();
  }
  
  abrirPopupPagoCreditosVenta(venta){
		venta.cliente=this.cliente;
    this.modalPagos = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalPagos.componentInstance.venta = venta;
		this.modalPagos.componentInstance.usuario = this.usuario;
		this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
          this.obtenerCuentaCliente();
          this.getItems();
					this.modalPagos.close();
				}
			}else{
				this.modalPagos.close();
			}
			
		});
  }
  
  verVenta(venta){
		this.modalPagos = this.modalService.open(VistaVentaComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-venta', backdrop: 'static'});
		this.modalPagos.componentInstance.venta = venta;
		this.modalPagos.componentInstance.usuario = this.usuario;
		this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
			this.modalPagos.close();
		});
  }
  
  verDetallesPago() {
    setTimeout(function() {
        //$('#'+id_elemento).trigger('click');
        $(".verDetallePago").trigger('click');
    }, 0);
    
  }

  verDetallePagos(venta){
		var style=$("#"+venta.id).css("display");
		if(style=="none"){
			$("#"+venta.id).css("display","");
		}else{
			$("#"+venta.id).css("display","none");
		}
  }
  
  abrirPopupPagoCreditosCliente(cliente){
    this.cliente=cliente;
    this.pago_general.importe=1;
    this.pago_general_modal=this.modalService.open(this.pago_general_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.pago_general_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  calcularSaldoActualizado(){
		this.pago_general.saldo_actualizado=this.total_saldo-this.pago_general.importe;
  }
  
  async guardarPagoGeneral(){
    this.blockUI.start();
		var total_acumulado=this.pago_general.importe,ventas_a_pagar=[];
		for(var i=0;i<this.cliente.ventas.length;i++){
			if(total_acumulado>=this.cliente.ventas[i].saldo || total_acumulado>0){
				this.cliente.ventas[i].a_pagar=(total_acumulado>=this.cliente.ventas[i].saldo)?this.cliente.ventas[i].saldo:total_acumulado;
				ventas_a_pagar.push(this.cliente.ventas[i]);
				total_acumulado=total_acumulado-this.cliente.ventas[i].saldo;
			}
    }
    var promesas=[];
		for(var i=0;i<ventas_a_pagar.length;i++){
      promesas.push(this.ventaService.guardarPagoVenta(ventas_a_pagar[i].id,this.usuario.id_empresa,{ monto_pagado: ventas_a_pagar[i].a_pagar,observacion:"Pago en conjunto", id_usuario_cajero: this.usuario.id }).toPromise());
    }
    
    let me=this;
    Promise.all(promesas).then(function(datos){
			me.toastr.success("Pagos en conjunto registrados satisfactoriamente!");
      me.obtenerCuentaCliente();
      me.getItems();
			me.pago_general_modal.close();
			me.blockUI.stop();
			if(me.usuario.empresa.usar_contabilidad){
				datos.forEach(function(dato, index){
					//me.contabilizarPago(dato.pago.monto_pagado,this.cliente,"Por pago de facturas en conjunto "+dato.venta.factura,dato.pago.id);
				});
			}
		});
  }	
  
  abrirEstadoContactosCliente(cliente){
		this.cliente_contacto=null;
		this.cliente=cliente?cliente:this.cliente;
    this.blockUI.start();
    this.clientesService.obtenerCuentaContactosCliente(this.cliente.id,this.filter.cuentas_liquidadas).subscribe((datosContactos:any)=>{
      this.cliente.contactos=datosContactos;
      this.calcularTotalesContactosCliente();
      this.cuenta_contacto_cliente_modal=this.modalService.open(this.cuenta_contacto_cliente_modal_ref, {windowClass:'cuenta-por-cobrar', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.cuenta_contacto_cliente_modal.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
			this.blockUI.stop();
    });
  }
  
  calcularTotalesContactosCliente(){
		this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0;
		for(var i=0;i<this.cliente.contactos.length;i++){
			this.total_importe=this.total_importe+this.cliente.contactos[i].total;
			this.total_a_cuenta=this.total_a_cuenta+this.cliente.contactos[i].a_cuenta;
			this.total_saldo=this.total_saldo+this.cliente.contactos[i].saldo;
      this.total_devoluciones=this.total_devoluciones+this.cliente.contactos[i].devoluciones;
		}
  }
  
  imprimirDetalleContacto(contacto_cliente){
    this.blockUI.start();
    this.clientesService.obtenerCuentaPorCobrarContactoClienteDatosPdf(contacto_cliente.id_cliente_contacto,this.filter.cuentas_liquidadas).subscribe((res:any)=>{
      contacto_cliente=res;
      var doc = new PDFDocument({ size: 'letter', margin: 10 });
      var stream = doc.pipe(blobStream());
      var fecha_reporte=new Date();
      var y = 165, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(this.obtenerTotalItemsImpresionContactoliente(contacto_cliente) / itemsPorPagina);
      this.dibujarCabeceraPDFDetalleContacto(doc,contacto_cliente);
      this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"DETALLE DEUDA CONTACTO CLIENTE",pagina,totalPaginas,this.usuario,
      this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
      this.filter.sucursal.telefono1+" - "+this.filter.sucursal.telefono2,"",GlobalVariable.Dictionary.PAPEL_CARTA,
      fecha_reporte,{imprimir_usuario:true});
      var suma_total=0,suma_a_cuenta=0,suma_saldo=0;
      for (var i = 0; i < contacto_cliente.ventas.length && items <= itemsPorPagina; i++) {

        doc.rect(40, y - 10, 540, 20).stroke();
        doc.font('Helvetica', 8);
        doc.text((i+1)+"", 45, y, { width: 50 });
        contacto_cliente.ventas[i].fecha=new Date(contacto_cliente.ventas[i].fecha);
        doc.text(contacto_cliente.ventas[i].fecha.getDate() + "/" + (contacto_cliente.ventas[i].fecha.getMonth() + 1) + "/" + contacto_cliente.ventas[i].fecha.getFullYear(), 75, y, { width: 50 });
        doc.text(contacto_cliente.ventas[i].transaccion.nombre, 125, y);
        doc.text(contacto_cliente.ventas[i].factura, 225, y);
        doc.text(contacto_cliente.ventas[i].total, 275, y);
        doc.text(contacto_cliente.ventas[i].a_cuenta, 325, y);
        doc.text(contacto_cliente.ventas[i].saldo, 375, y);
        suma_total=suma_total+contacto_cliente.ventas[i].total;
        suma_a_cuenta=suma_a_cuenta+contacto_cliente.ventas[i].a_cuenta;
        suma_saldo=suma_saldo+contacto_cliente.ventas[i].saldo;
        y = y + 20;
        items++;

        if(contacto_cliente.ventas[i].detallesVenta.length>0){
          doc.font('Helvetica-Bold', 8);
          doc.text("Detalle",70,y);
          doc.text("Nº",125,y);
          doc.text("Producto",145,y);
          doc.text("Cantidad",315,y);
          doc.text("Precio Unit.",365,y);
          doc.text("Total",415,y);
          y = y + 20;
          items++;
          this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
          doc.font('Helvetica', 8);
          for(var j=0;j<contacto_cliente.ventas[i].detallesVenta.length;j++){
            doc.text(j+1,125,y);
            doc.text(contacto_cliente.ventas[i].detallesVenta[j].producto?
              contacto_cliente.ventas[i].detallesVenta[j].producto.nombre:
              contacto_cliente.ventas[i].detallesVenta[j].combo?
              contacto_cliente.ventas[i].detallesVenta[j].combo.nombre:
              contacto_cliente.ventas[i].detallesVenta[j].descripcion,145,y);
            doc.text(contacto_cliente.ventas[i].detallesVenta[j].cantidad,315,y);
            doc.text(contacto_cliente.ventas[i].detallesVenta[j].precio_unitario,365,y);
            doc.text(contacto_cliente.ventas[i].detallesVenta[j].total,415,y);
            y = y + 20;
            items++;
            this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
          }
        }

        if(contacto_cliente.ventas[i].devoluciones_venta.length>0){
          for(var j=0;j<contacto_cliente.ventas[i].devoluciones_venta.length;j++){
            doc.font('Helvetica-Bold', 8);
            doc.text("Devoluciones Nº: "+contacto_cliente.ventas[i].devoluciones_venta[j].numero_documento,60,y,{width:55,align:'center'});
            doc.text("Nº",125,y);
            doc.text("Producto",145,y);
            doc.text("Cantidad",315,y);
            doc.font('Helvetica', 8);
            doc.text(contacto_cliente.ventas[i].devoluciones_venta[j].observacion?
                  contacto_cliente.ventas[i].devoluciones_venta[j].observacion:'',365,y);
            y = y + 20;
            items++;
            this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
            for(var k=0;k<contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion.length;k++){
              doc.text(k+1,125,y);
              doc.text(contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].producto?
                contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].producto.nombre:
                contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].combo?
                contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].combo.nombre:
                contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].descripcion,145,y);
              doc.text(contacto_cliente.ventas[i].devoluciones_venta[j].detalles_devolucion[k].cantidad,315,y);
              y = y + 20;
              items++;
              this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
            }
          }
        }

        if(contacto_cliente.ventas[i].pagosVenta.length>0){
          doc.font('Helvetica-Bold', 8);
          doc.text("Pagos",70,y);
          doc.text("Nº",125,y);
          doc.text("Nro. Doc",145,y);
          doc.text("A Cuenta Anterior",200,y);
          doc.text("Saldo Anterior",300,y);
          doc.text("Importe Pagado",400,y);
          y = y + 20;
          items++;
          this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
          doc.font('Helvetica', 8);
          for(var j=0;j<contacto_cliente.ventas[i].pagosVenta.length;j++){
            doc.text(j+1,125,y);
            doc.text(contacto_cliente.ventas[i].pagosVenta[j].numero_documento,145,y);
            doc.text(contacto_cliente.ventas[i].pagosVenta[j].a_cuenta_anterior,200,y);
            doc.text(contacto_cliente.ventas[i].pagosVenta[j].saldo_anterior,300,y);
            doc.text(contacto_cliente.ventas[i].pagosVenta[j].monto_pagado,400,y);
            y = y + 20;
            items++;
            this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);
          }
        }

        this.verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte);

        if(i+1==contacto_cliente.ventas.length){
          this.dibujarPiePDFDetalleContacto(doc,y,{suma_total:suma_total,suma_a_cuenta:suma_a_cuenta,suma_saldo:suma_saldo});
        }
      }
      doc.end();
      stream.on('finish', function () {
        var fileURL = stream.toBlobURL('application/pdf');
        window.open(fileURL, '_blank', 'location=no');
      });
      this.blockUI.stop();
    });
  }

  obtenerTotalItemsImpresionContactoliente(contacto_cliente){
		var total_items=contacto_cliente.ventas.length;
		for(var i=0;i<contacto_cliente.ventas.length;i++){
			total_items=total_items+contacto_cliente.ventas[i].detallesVenta.length;
			total_items=total_items+contacto_cliente.ventas[i].devoluciones_venta.length;
			total_items=total_items+contacto_cliente.ventas[i].pagosVenta.length;
		}
		return total_items;
	}
  
  dibujarCabeceraPDFDetalleContacto (doc,contacto_cliente) {
		doc.font('Helvetica-Bold',8);
		doc.text(this.obtenerTextoEmpresa(GlobalVariable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)+" : "+contacto_cliente.cliente.identificacion,350,80);
		doc.text("Contacto Cliente : "+contacto_cliente.persona.nombre_completo,350,90);

		doc.text("Nº",45,140);
		doc.text("Fecha",75,140);
		doc.text("Transacción",125,140);
		doc.text("Num. Doc.",225,140);
		doc.text("Importe",275,140);
		doc.text("A Cuenta",325,140);
		doc.text("Saldo",375,140);
		doc.roundedRect(40,130,540,25,5).fillOpacity(0.8).stroke();
  }
  
  verificarAdicionPagina(items,itemsPorPagina,totalPaginas,doc,y,pagina,contacto_cliente,fecha_reporte){
		if (items == itemsPorPagina) {
			doc.addPage({ margin: 0, bufferPages: true });
			y = 165;
			items = 0;
			pagina = pagina + 1;

			this.dibujarCabeceraPDFDetalleContacto(doc,contacto_cliente);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"DETALLE DEUDA CONTACTO CLIENTE",pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
			this.filter.sucursal.telefono1+" - "+this.filter.sucursal.telefono2,"",GlobalVariable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});

			doc.font('Helvetica', 8);
		}
  }
  
  dibujarPiePDFDetalleContacto (doc,y,datos) {
		doc.font('Helvetica-Bold',8);
		doc.text("Totales", 125, y);
		doc.text(datos.suma_total, 275, y);
		doc.text(datos.suma_a_cuenta, 325, y);
		doc.text(datos.suma_saldo, 375, y);
		doc.roundedRect(40,y-10,540,25,5).fillOpacity(0.8).stroke();
  }
  
  imprimirPagoVenta(pago_venta){
    this.blockUI.start('Recuperando... espere por favor!');
    this.pdfService.imprimirReciboPagoVenta(pago_venta,this.usuario);
    this.blockUI.stop();
  }

  
  
  /*contabilizarPago=function(importe,cliente,glosa,fuente_comprobante){
    var promise=ClasesTipo("TIPFUENTECOMP");
    promise.then(function(tipoFuentesComprobante){
      this.fuentes_comprobantes=tipoFuentesComprobante.clases;
      var promesa=CuentaCodigo("1.1.2.1");//cuenta cuentas por cobrar
      promesa.then(function(cuenta_por_cobrar){
        promesa=CuentaCodigo("1.1.1.1");//cuenta caja
        promesa.then(function(cuenta_caja){
          promesa=CuentaAuxiliarCliente(cliente.id);
          promesa.then(function(cuenta_cliente){
            var fechaActual=new Date();
            var fechaTexto=fechaActual.getDate()+"/"+(fechaActual.getMonth()+1)+"/"+fechaActual.getFullYear();
            var comprobante=new EntidadComprobante(this.usuario,fechaActual,fechaTexto,this.sucursales);
            comprobante.iniciar(this.onComprobanteGuardadoEstaCliente);
            comprobante.datos_fuente=fuente_comprobante+"";
            comprobante.fuente=$.grep(this.fuentes_comprobantes, function (e) { return e.nombre_corto == Diccionario.TIPO_FUENTE_COMPROBANTE_AUTOMATICA_PAGO_VENTA; })[0];

            cuenta_por_cobrar.cuenta_auxiliar=cuenta_cliente;
            comprobante.glosa=glosa;
            comprobante.asientos=[];
            comprobante.asientos.push({ glosa: "", cuenta:cuenta_caja, debe_bs:importe, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false })
            comprobante.asientos.push({ glosa: "", cuenta:cuenta_por_cobrar, debe_bs:0, haber_bs:importe, debe_sus:0, haber_sus:0, eliminado:false })
            comprobante.calcularTotales();
  
            $timeout(function(){
              comprobante.guardarComprobanteSinValidar();
            },1000);
          });
        });
      });
    });
  }*/

  abrirPopupConfirmacionEnvioCorreo(cliente){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el reporte de estado de cuentas por correo electrónico?";
		this.popupConfirmacion.componentInstance.data = cliente;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarCorreoElectronico($e.data);
			}
			this.popupConfirmacion.close();
        });
    }
    
    async enviarCorreoElectronico(cliente){
      if(cliente.email){
        let me=this;
        me.blockUI.start();
        let pdf:any=await this.generarPdfResumidoEstadoCuentasCliente(cliente);
        var reader = new window.FileReader();
        reader.readAsDataURL(pdf);
        reader.onloadend = function () {
          let base64data = reader.result;
          
          me.clientesService.enviarCorreoCuentaCliente({
            documento:base64data,
            cliente:me.datosCliente,
            nombre_documento:"Estado de Cuenta de Cliente"
          }).subscribe((res:any)=>{
            me.toastr.success(res.mensaje);
            me.blockUI.stop();
          });
        }
      }else{
        this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente","error");
      }
    }

    verDetalleCuentaContactoCliente(cliente_contacto){
      this.cliente_contacto=cliente_contacto;
      this.blockUI.start();
      this.clientesService.obtenerCuentaPorCobrarContactoCliente(this.cliente_contacto.id_cliente_contacto,this.filter.cuentas_liquidadas).subscribe((res:any)=>{
        this.cliente_contacto.ventas=res.ventas;
        this.calcularTotalesContactoCliente();
        this.blockUI.stop();
      });
    }

    calcularTotalesContactoCliente(){
      this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0,this.total_devoluciones=0;
      for(var i=0;i<this.cliente_contacto.ventas.length;i++){
        this.total_importe=this.total_importe+this.cliente_contacto.ventas[i].total;
        this.total_a_cuenta=this.total_a_cuenta+this.cliente_contacto.ventas[i].a_cuenta;
        this.total_saldo=this.total_saldo+this.cliente_contacto.ventas[i].saldo;
        this.total_devoluciones=this.total_devoluciones+this.cliente_contacto.ventas[i].devoluciones;
      }
    }

    abrirPopupConfirmacionEnvioWhatsapp(cliente){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el reporte de estado de cuentas por Whatsapp?";
      this.popupConfirmacion.componentInstance.data = cliente;
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.enviarEstadoCuentaWhatsapp($e.data);
        }
			  this.popupConfirmacion.close();
      });
	  }

    async enviarEstadoCuentaWhatsapp(cliente){
      if(cliente.telefono1){
        this.blockUI.start();
        let me=this;
        let pdf:any=await this.generarPdfResumidoEstadoCuentasCliente(cliente);
        var reader = new window.FileReader();
        reader.readAsDataURL(pdf);
        reader.onloadend = function () {
          let base64data = reader.result;
          let datos={
            documentos:[
              {tipo:"application/pdf",es_base_64:true,dato_ruta:base64data,nombre:"Estado de Cuenta de Cliente"},
              {dato_ruta:'./files/empresa-'+me.usuario.id_empresa+'/qr.png'}
            ],
            cliente:cliente,
            nombre_corto_clase_mensaje:GlobalVariable.Dictionary.MKT.MEN_ENVIO_RECORDATORIO_PAGO,
            id_sucursal:Util.obtenerSucursalesUsuario(me.usuario)[0].id,
            id_empresa:me.usuario.id_empresa
          };
          me.clientesService.enviarMensajeRecordatorioPagoWhatsapp(datos).subscribe((res:any)=>{
            me.blockUI.stop();
            if(res.tiene_error){
              me.toastr.error(res.mensaje);
            }else{
              me.toastr.success(res.mensaje);
            }
          });
        }
			}else{
				this.toastr.error("Debe agregar un numero de whatsapp para el cliente");
			}
    }

    abrirConfiguracionAplicacion(){
      this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionCuentasPorCobrarComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
      this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
      this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
      this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
        this.configuracion_aplicacion_modal.close();
      });
    }

}
