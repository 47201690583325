<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Cheques: {{caja.nombre}} - {{caja.numero_cuenta}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-toolbar">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="row">
                <div class="col-12">
                  <div class="columns columns-right btn-group float-right">
                    <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="fa fa fa-search"></i>
                    </button>
                    <button title="Nuevo" (click)="crearNuevoCheque()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                      <i class="fa fa-plus text-purple-d1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="fixed-table-container" style="padding-bottom: 0px;">
          <div class="fixed-table-header" style="display: none;">
            <table></table>
          </div>
          <div id="gallery" class="fixed-table-body">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
              <span class="loading-wrap">
                <span class="animation-wrap">
                  <span class="animation-dot"></span>
                </span>
              </span>
            </div>
            <table *ngIf="cheques.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
              <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                  <th>N°</th>
                  <th><a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Codigo <span class="sort fa fa-sort icon-only"></span></a></th>
                  <th><a href="javascript:void(0)" (click)="this.sortColumn('inicio_correlativo')">Correlativo Inicial <span class="sort fa fa-sort icon-only"></span></a></th>
                  <th><a href="javascript:void(0)" (click)="this.sortColumn('fin_correlativo')">Correlativo Final <span class="sort fa fa-sort icon-only"></span></a></th>
                  <th><a href="javascript:void(0)" (click)="this.sortColumn('correlativo_actual')">Correlativo Actual <span class="sort fa fa-sort icon-only"></span></a></th>
                  <th><a href="javascript:void(0)" (click)="this.sortColumn('activo')">Estado <span id="activo" class="sort fa fa-sort icon-only"></span></a></th>
                  <th style="text-align: center; width: 140px; " data-field="tools">
                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                    <div class="fht-cell"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr #cierres_caja *ngFor="let cheque of cheques; let i=index;">
                  <td>
                    {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                  </td>
                  <td>
                      {{cheque.codigo}}
                  </td>
                  <td>
                      {{cheque.inicio_correlativo}}
                  </td>
                  <td>
                      {{cheque.fin_correlativo}}
                  </td>
                  <td>
                      {{cheque.correlativo_actual}}
                  </td>
                  <td *ngIf="cheque.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                  <td *ngIf="!cheque.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                  <td style="text-align: center; width: 140px; ">
                    <div class="action-buttons">  
                      <a *ngIf="cheque.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarCheque(cheque)">          
                          <i class="fa fa-edit text-105"></i>        
                      </a>
                      <a *ngIf="cheque.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cheque)">
                          <i class="fa fa-trash-alt"></i>
                        </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fixed-table-footer">
            <table><thead><tr></tr></thead></table>
          </div>
        </div>
        <div class="fixed-table-pagination">
          <app-paginator-footer *ngIf="cheques.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
        </div>
      </div>
</div>





<ng-template #modal_registro_cheque let-modal>
    <div class="modal-header card-header">
        <div class="col-6">
            <span class="text-blue text-125">Registro Cheque: Caja {{cheque.caja.nombre}}</span>
        </div>
        <div class="col-6">
            <button type="button" class="btn btn-primary btn-primary" (click)="guardarCheque()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-4">
                <label>Código</label>
            </div>
            <div class="col-8">
              <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="cheque.codigo" placeholder="Código" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-7">
            <label>Correlativo Inicial</label>
          </div>
          <div class="col-5">
              <input class="form-control" [(ngModel)]="cheque.inicio_correlativo" min="0" type="number" step="1">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-7">
            <label>Correlativo Final</label>
          </div>
          <div class="col-5">
              <input class="form-control" [(ngModel)]="cheque.fin_correlativo" min="0" type="number" step="1">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-7">
            <label>Correlativo Actual</label>
          </div>
          <div class="col-5">
              <input class="form-control" [(ngModel)]="cheque.correlativo_actual" min="0" type="number" step="1">
          </div>
        </div>
    </div>
  </ng-template>