import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-registro-paciente',
  templateUrl: './registro-paciente.component.html',
  styleUrls: ['./registro-paciente.component.css']
})
export class RegistroPacienteComponent extends BaseComponent implements OnInit {

  paciente:any={cliente:{persona:{}}}
  tipo_categoria:any={clases:[]};
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  
  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private pacientesService:PacientesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.init();
    this.paciente.cliente.id_empresa=this.usuario.id_empresa;
    if(this.paciente.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
      if(!this.paciente.cliente.codigo){
        let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
        this.paciente.cliente.codigo = codigo_nuevo;
      }
      this.paciente.cliente.persona.fecha_nacimiento_texto=this.paciente.cliente.persona.fecha_nacimiento?Util.convertirFechaAObjectoFecha(new Date(this.paciente.cliente.persona.fecha_nacimiento)):null;
    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
      this.paciente.cliente.codigo = codigo_nuevo;
      this.paciente.cliente.id_empresa=this.usuario.id_empresa;
      this.paciente.cliente.persona={};
    }
    this.obtenerCategoriasClientesEmpresa();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  validarPaciente(){
    let res=true;
    if(!this.paciente.cliente.persona.nombres){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del paciente!");
    }
    if(this.paciente.cliente.persona.fecha_nacimiento_texto && (!this.paciente.cliente.persona.fecha_nacimiento_texto.year || !this.paciente.cliente.persona.fecha_nacimiento_texto.month || !this.paciente.cliente.persona.fecha_nacimiento_texto.day)){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de nacimiento completa!");
    }
    return res;
  }

  async guardarRegistroPaciente(){
    if(this.validarPaciente()){
      this.blockUI.start();
      this.paciente.cliente.persona.fecha_nacimiento=this.paciente.cliente.persona.fecha_nacimiento_texto?Util.convertirObjetoAfecha(this.paciente.cliente.persona.fecha_nacimiento_texto):null;
      if (this.paciente.id) {
        this.pacientesService.actualizarPaciente(this.paciente).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      } else {
        this.paciente.cliente.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE):this.paciente.cliente.codigo;
        this.pacientesService.guardarPaciente(this.paciente).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

  obtenerCategoriasClientesEmpresa(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"CATEGORIAS CLIENTES").subscribe((tipoCategoriaClientes:any)=>{
      this.tipo_categoria=tipoCategoriaClientes;
      this.blockUI.stop();
    });
  }

}
