<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
        <legend >Tabla de Compras</legend>
        <div class="row">
            <div class="col-2">
                <label>Ordenar por</label>
            </div>
            <div class="col-4">
                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                </select>
            </div>
            <div class="col-2">
                <label>Dirección</label>
            </div>
            <div class="col-4">
                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
                    <option value="asc">Ascendente</option>
                    <option value="desc">Descendente</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Registro de Compra</legend>
        <div class="row">
            <div class="col-4">
                <label>¿Busqueda Codigo de Barra Activado?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="busqueda_codigo_barra" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.busqueda_codigo_barra" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
            <div class="col-4">
                <label>¿Cantidad Decimal?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="cantidad_decimal" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cantidad_decimal" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-3">
                <label>Tipo de pago por defecto</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="tipo_pago" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.tipo_pago_por_defecto">
                    <option [disabled]="tipo_pago.nombre=='TODOS'" [ngValue]="tipo_pago.nombre_corto" *ngFor="let tipo_pago of tipos_pago" >{{tipo_pago.nombre}}</option>
                </select>
            </div>
            <div class="col-3">
                <label>Tipo Documento por defecto</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="tipo_documento" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.tipo_documento_por_defecto">
                    <option [disabled]="tipo_documento.nombre=='TODOS'" [ngValue]="tipo_documento.nombre_corto" *ngFor="let tipo_documento of tipos_documentos" >{{tipo_documento.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-8">
                <label>¿Visualizar subida de comprobante?</label>
            </div>
            <div class="col-4">
                <label>
                    <input name="ver_subida_comprobante_adjunto" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_subida_comprobante_adjunto" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Impresión</legend>
        <div class="row">
            <div class="col-4">
                <label>¿Imprimir datos de crédito?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="datos_credito" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.datos_credito" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
            <div class="col-4">
                <label>¿Imprimir usuario?</label>
            </div>
            <div class="col-2">
                <label>
                    <input name="impresion_usuario" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.usuario" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-3">
                <label>Tamaño papel nota de compra</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="papel_nota_compra" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_nota_compra">
                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                </select>
            </div>
            <div class="col-3">
                <label>Tamaño papel recibo pago</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="papel_recibo_pago" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_recibo_pago">
                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <label>Diseño logo</label>
            </div>
            <div class="col-6">
                <select  class="form-control" name="disenio" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.disenio_logo_impresion">
                    <option [ngValue]="disenio.nombre_corto" *ngFor="let disenio of disenios_logo_impresion" >{{disenio.nombre}}</option>
                </select>
            </div>
        </div>
    </fieldset>
</div>