import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import * as XLSX from 'xlsx';
import { IncripcionMateriaComponent } from 'src/app/institute/components/incripcion-materia/incripcion-materia.component';
import { CarrerasService } from 'src/app/institute/services/carreras/carreras.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { ConfiguracionAplicacionEstudiantesComponent } from '../configuracion-aplicacion-estudiantes/configuracion-aplicacion-estudiantes.component';
import { FichaEconomicaEstudianteComponent } from '../ficha-economica-estudiante/ficha-economica-estudiante.component';
import { RegistroEstudianteComponent } from '../registro-estudiante/registro-estudiante.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { CursosService } from '../../services/cursos/cursos.service';
import { Util } from 'src/app/utils/utils';
import { ReporteCursoComponent } from '../reporte-curso/reporte-curso.component';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { DocumentosEstudianteComponent } from '../documentos-estudiante/documentos-estudiante.component';
import { RegistroAsuntoAdministrativoComponent } from 'src/app/base/components/registro-asunto-administrativo/registro-asunto-administrativo.component';
import { ParticipantesGruposWhatsappComponent } from 'src/app/base/components/participantes-grupos-whatsapp/participantes-grupos-whatsapp.component';
import { VistaEstudianteComponent } from '../vista-estudiante/vista-estudiante.component';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;
declare const $:any;

@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrls: ['./estudiantes.component.css']
})
export class EstudiantesComponent extends BaseComponent implements OnInit {

  estudiantes:any=[];
  conceptos_pagos_estudiantes_banco:any=[];
  estudiante:any;
  edicion_estudiante:NgbModalRef;
  ficha_economica_modal:NgbModalRef;
  ficha_asignacion_materia_modal:NgbModalRef;
  documentos_estudiante_materia_modal:NgbModalRef;

  importacion_estudiantes:NgbModalRef;
  @ViewChild('importacion_estudiantes')
  private importacion_estudiantes_ref: TemplateRef<any>;

  importacion_tutores:NgbModalRef;
  @ViewChild('importacion_tutores')
  private importacion_tutores_ref: TemplateRef<any>;

  archivo_banco_estudiantes:NgbModalRef;
  @ViewChild('archivo_banco_estudiantes')
  private archivo_banco_estudiantes_ref: TemplateRef<any>;

  participantes_grupo_whatsapp_modal:NgbModalRef;

  importacion:any={estudiantes:[],tutores:[]};
  generos:any[]=[];
  idiomas:any[]=[];
  grados_academicos:any[]=[];
  parentescos:any[]=[];
  lista_vive_con:any[]=[];

  searching = false;
  searchFailed = false;
  busqueda_estudiante:any=null;
  estudiantes_archivo_banco:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
	  private estudiantesService:EstudiantesService,
    private carrerasService:CarrerasService,
    private cursosService:CursosService,
    private pdfService:PdfService,
    private conceptosIngreso:ConceptosIngresosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_ESTUDIANTES);
    this.filter={
			escuela:null,
      carrera:GlobalVariable.Dictionary.SELECCION_TODOS,
      carreras:[GlobalVariable.Dictionary.SELECCION_TODOS],
      sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
		}
    await this.obtenerEscuela();  
    if(this.usuario.empresa.usar_estudiantes_instituto){
      await this.obtenerCarreras();
    }

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}

		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  async obtenerCarreras(){
    let carreras=await this.carrerasService.obtenerCarreras(this.filter.escuela.id).toPromise();
    this.filter.carreras=this.filter.carreras.concat(carreras);
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
    let promesa_paginador;
    if(this.usuario.empresa.usar_estudiantes_instituto){
      promesa_paginador=this.estudiantesService.obtenerListaEstudiantesInstitutoPaginador(this);
    }else{
      promesa_paginador=this.estudiantesService.obtenerListaEstudiantesColegioPaginador(this);
    }
		promesa_paginador.subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.estudiantes=dato.estudiantes;
			this.blockUI.stop();
		});
	}

  crearNuevoEstudiante(){
		this.estudiante={persona:{genero:{},usuario:{rol:{}},cliente:{}},inscripciones_instituto:[]};
		this.abrirRegistroEstudiante();
	}

  abrirRegistroEstudiante(){
		this.edicion_estudiante = this.modalService.open(RegistroEstudianteComponent, {scrollable:true,windowClass : "wizard_edicion_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
    //this.edicion_estudiante.componentInstance.gestion = this.gestion_tab;
    //this.edicion_estudiante.componentInstance.curso = {id:this.registro_profesor_actual.id_curso,nombre:this.registro_profesor_actual.curso};
		
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.getItems();
			}
			this.edicion_estudiante.close();
    });
  }

  modificarEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirRegistroEstudiante();
    });
  }

  verEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirVerEstudiante();
    });
  }

  abrirVerEstudiante(){
		this.edicion_estudiante = this.modalService.open(VistaEstudianteComponent, {scrollable:true,windowClass : "vista_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
		
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			this.edicion_estudiante.close();
    });
  }

  abrirPopupConfirmacionEliminacion(estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el estudiante?";
		this.popupConfirmacion.componentInstance.data = estudiante;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEstudiante($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.eliminarEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.getItems();
      this.toastr.success(res.mensaje);
    });
  }

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionEstudiantesComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  abrirReporteCursos(){
		this.configuracion_aplicacion_modal=this.modalService.open(ReporteCursoComponent, {windowClass:'reporte_curso_modal', size:'md', ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
    this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  abrirFichaEconomica(estudiante){
    this.ficha_economica_modal = this.modalService.open(FichaEconomicaEstudianteComponent, {scrollable:true, windowClass:'ficha-economica', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_economica_modal.componentInstance.estudiante = estudiante;
    this.ficha_economica_modal.componentInstance.usuario = this.usuario;
		
		this.ficha_economica_modal.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.getItems();
			}
			this.ficha_economica_modal.close();
    });
  }

  abrirAsignacionMaterias(estudiante){
    this.ficha_asignacion_materia_modal = this.modalService.open(IncripcionMateriaComponent, {windowClass:'inscripcion-materia', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_asignacion_materia_modal.componentInstance.estudiante = estudiante;
    this.ficha_asignacion_materia_modal.componentInstance.usuario = this.usuario;
		
		this.ficha_asignacion_materia_modal.componentInstance.onConfirm.subscribe(($e) => {
			this.ficha_asignacion_materia_modal.close();
    });
  }

  abrirModalImportacionEstudiantes(){
    this.importacion.estudiantes=[];
    this.filter.gestion=null;
    this.filter.nivel_escolar=null;
    this.filter.curso=null;
    this.obtenerGetionesEscolares();
    this.obtenerNivelesEscolares();
    this.obtenerGenerosPersona();
    this.obtenerTipoEstudianteViveCon();
    this.importacion_estudiantes=this.modalService.open(this.importacion_estudiantes_ref, {windowClass:"importacion-estudiantes",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.importacion_estudiantes.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async obtenerGetionesEscolares(){
    let gestiones_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    this.filter.gestiones_escolares=gestiones_escolares.clases;
  }

  async obtenerGenerosPersona(){
    let generos:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.GENEROS).toPromise();
    this.generos=generos.clases;
  }

  async obtenerTipoEstudianteViveCon(){
    let vive_con:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_ESTUDIANTE_VIVE_CON).toPromise();
    this.lista_vive_con=vive_con.clases;
  }

  async obtenerNivelesEscolares(){
    let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
    this.filter.niveles_escolares=niveles_escolares.clases;
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=cursos;
  }

  descargarExcelImportacion(){
    //var promise=GradeReportData($scope.filter.management.id,$scope.filter.school.id,$scope.filter.grade.id);
		//promise.then(function(data){
			let students=[];//data.students;
      let cabecera=["Nº","Código Estudiante","Código Rude","Apellido Paterno","Apellido Materno","Nombres",
      "Pais Nac.","Depto. Nac.","Prov. Nac","Loc. Nac.",
      "Cert. Nac. Oficialia","Cert. Nac. Libro","Cert. Nac. Partida","Cert. Nac. Folio",
      "Fecha de Nac. (mm/dd/aaaa)","Nº C.I.","C.I. Complemento","C.I. Expedido","Género (M/F)",
      "Presenta Discapacidad (SI/NO)","Nº Registro Discapacidad",
      "Direccion Departamento","Direccion Provincia","Direccion Municipio","Direccion Localidad",
      "Dirección (Zona/Villa)","Dirección (Avenida/Calle)","Dirección (Número Vivienda)","Telf. Fijo","Telf. Movil",
      "Idioma Infancia","Idiomas Frecuentes","Existencia Centro Salud (SI/NO)","Tiene Seguro de Salud (SI/NO)",
      "Tiene Agua por Red (SI/NO)","Tiene Baño (SI/NO)","Tiene Alcantarillado (SI/NO)","Tiene Energia Electrica (SI/NO)","Tiene Servicio Recojo Basura (SI/NO)",
      "Estudiante Trabajo (SI/NO)","Recibio Pago Trabajo (SI/NO)","Abandono Escuela (SI/NO)",
      "U. E. Anterior","Codigo S.I.E.","Vive Con","Correo Electronico","Usuario","Clave","Observación"];
			let data = [];
	
				for(var i=0;i<students.length;i++){
					var columns=[];
					
					columns.push(i+1);				
					columns.push(students[i].id);
					columns.push(students[i].rude_code);
					columns.push(students[i].persona.apellido_paterno);							
					columns.push(students[i].persona.apellido_materno);							
					columns.push(students[i].persona.nombres);							
					//columns.push(employees[i].ci);
					//columns.push(employees[i].mobile_phone);				
					data.push(columns);
				}
        

        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("DATOS ESTUDIANTES");
        let headerRow = worksheet.addRow(cabecera);

        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4167B8' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          }
          cell.alignment ={ wrapText: true };
        })

        data.forEach(d => {
        let row = worksheet.addRow(d);
        }
        );

        worksheet.getColumn(1).width = 5;
        worksheet.getColumn(1).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
        worksheet.getColumn(2).width = 13;
        worksheet.getColumn(2).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };

        for(let i=3;i<=cabecera.length;i++){
          worksheet.getColumn(i).width = 15;
          worksheet.getColumn(i).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
        }

        workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "PLANILLA-ACTUALIZACION-"+this.filter.gestion.nombre+"-"+this.filter.escuela.nombre+"-"+this.filter.nivel_escolar.nombre+"-"+this.filter.curso.nombre+".xlsx");
        })

		//});
  }

  subirArchivoExcelImportacionEstudiantes(event){
    this.blockUI.start();
    this.importacion.estudiantes=[];
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];

        do {
          let celda_genero=worksheet['S'+row]!=undefined && worksheet['S'+row]!=""?worksheet['S'+row].v.toString():null;
          let genero=celda_genero?me.generos.filter(g => g.nombre_corto[0]==celda_genero).length>0?me.generos.filter(g => g.nombre_corto[0]==celda_genero)[0]:null:null;

          let vive_con;
          if(worksheet['AS'+row]!=undefined && worksheet['AS'+row]!=""){
            var live_with_cell=Util.reemplazarCaracteresEspeciales(worksheet['AS'+row].v.toString().toUpperCase());
            if(live_with_cell=="PADRE Y MADRE" || live_with_cell=="AMBOS" || live_with_cell=="PADRE/MADRE"){
              vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_PADRE_Y_MADRE)[0];
            }else if(live_with_cell=="SOLO PADRE" || live_with_cell=="PAPA" || live_with_cell=="PADRE"){
              vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_SOLO_PADRE)[0];
            }else if(live_with_cell=="SOLO MADRE" || live_with_cell=="MAMA" || live_with_cell=="MADRE"){
              vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_SOLO_MADRE)[0];
            }else if(live_with_cell=="TUTOR"){
              vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_TUTOR)[0];
            }else{
              vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_TUTOR)[0];
            }
          }else{
            vive_con=me.lista_vive_con.filter(e => e.nombre_corto == GlobalVariable.Dictionary.SCH.NOMBRE_CORTO_VIVE_TUTOR)[0];
          }

          let fecha_nacimiento=null;
          if (worksheet['O' + row] != undefined && worksheet['O' + row] != "") {
						if (typeof worksheet['O' + row].v === 'number') {
							if (worksheet['O' + row].v % 1 === 0) {
								fecha_nacimiento = new Date((worksheet['O' + row].v - (25567 + 1)) * 86400 * 1000);
							}
						} else {
							fecha_nacimiento = Util.convertirTextoAfecha(worksheet['O' + row].v);
						}
					}

          let estudiante:any={
            codigo:worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null,
            codigo_rude:worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null,
            persona:{
              apellido_paterno:worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():"",
              apellido_materno:worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():"",
              nombres:worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null,
              pais_nacimiento:worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString():null,
              ciudad_nacimiento:worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null,
              provincia_nacimiento:worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?worksheet['I'+row].v.toString():null,
              localidad_nacimiento:worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?worksheet['J'+row].v.toString():null,
              certificado_nacimiento_oficialia:worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?worksheet['K'+row].v.toString():null,
              certificado_nacimiento_libro:worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?worksheet['L'+row].v.toString():null,
              certificado_nacimiento_partida:worksheet['M'+row]!=undefined && worksheet['M'+row]!=""?worksheet['M'+row].v.toString():null,
              certificado_nacimiento_folio:worksheet['N'+row]!=undefined && worksheet['N'+row]!=""?worksheet['N'+row].v.toString():null,
              fecha_nacimiento:fecha_nacimiento,
              ci:worksheet['P'+row]!=undefined && worksheet['P'+row]!=""?worksheet['P'+row].v.toString():null,
              ci_complemento:worksheet['Q'+row]!=undefined && worksheet['Q'+row]!=""?worksheet['Q'+row].v.toString():null,
              ci_expedido:worksheet['R'+row]!=undefined && worksheet['R'+row]!=""?worksheet['R'+row].v.toString():null,
              genero:genero,
              correo_electronico:worksheet['AT'+row]!=undefined && worksheet['AT'+row]!=""?worksheet['AT'+row].v.toString():null,
              direccion_ciudad:worksheet['V'+row]!=undefined && worksheet['V'+row]!=""?worksheet['V'+row].v.toString():null,
              direccion_municipio:worksheet['W'+row]!=undefined && worksheet['W'+row]!=""?worksheet['W'+row].v.toString():null,
              direccion_provincia:worksheet['X'+row]!=undefined && worksheet['X'+row]!=""?worksheet['X'+row].v.toString():null,
              direccion_localidad:worksheet['Y'+row]!=undefined && worksheet['Y'+row]!=""?worksheet['Y'+row].v.toString():null,
              direccion_zona:worksheet['Z'+row]!=undefined && worksheet['Z'+row]!=""?worksheet['Z'+row].v.toString():null,
              direccion:worksheet['AA'+row]!=undefined && worksheet['AA'+row]!=""?worksheet['AA'+row].v.toString():null,
              direccion_numero:worksheet['AB'+row]!=undefined && worksheet['AB'+row]!=""?worksheet['AB'+row].v.toString():null,
              telefono:worksheet['AC'+row]!=undefined && worksheet['AC'+row]!=""?worksheet['AC'+row].v.toString():null,
              telefono_movil:worksheet['AD'+row]!=undefined && worksheet['AD'+row]!=""?worksheet['AD'+row].v.toString():null,
              usuario:{
                nombre_usuario:worksheet['AU'+row]!=undefined && worksheet['AU'+row]!=""?worksheet['AU'+row].v.toString():null,
                clave:worksheet['AV'+row]!=undefined && worksheet['AV'+row]!=""?worksheet['AV'+row].v.toString():null
              },
              cliente:{
                razon_social:(worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():"")+" "+
                             (worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():"")+" "+
                             (worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():""),
                nit:worksheet['P'+row]!=undefined && worksheet['P'+row]!=""?worksheet['P'+row].v.toString():"0",
              }
            },
            presenta_discapacidad:worksheet['T'+row]!=undefined && worksheet['T'+row]!=""?(worksheet['T'+row].v.toString()=="SI"?true:false):null,
            numero_registro_discapacidad:worksheet['U'+row]!=undefined && worksheet['U'+row]!=""?worksheet['U'+row].v.toString():null,
            idioma_infancia:worksheet['AE'+row]!=undefined && worksheet['AE'+row]!=""?worksheet['AE'+row].v.toString():null,
            idiomas_frecuentes:worksheet['AF'+row]!=undefined && worksheet['AF'+row]!=""?worksheet['AF'+row].v.toString():null,
            existe_centro_salud_comunidad:worksheet['AG'+row]!=undefined && worksheet['AG'+row]!=""?(worksheet['AG'+row].v.toString()=="SI"?true:false):null,
            tiene_seguro_salud:worksheet['AH'+row]!=undefined && worksheet['AH'+row]!=""?(worksheet['AH'+row].v.toString()=="SI"?true:false):null,
            tiene_acceso_agua_red:worksheet['AI'+row]!=undefined && worksheet['AI'+row]!=""?(worksheet['AI'+row].v.toString()=="SI"?true:false):null,
            tiene_bano_vivienda:worksheet['AJ'+row]!=undefined && worksheet['AJ'+row]!=""?(worksheet['AJ'+row].v.toString()=="SI"?true:false):null,
            tiene_alcantarillado:worksheet['AK'+row]!=undefined && worksheet['AK'+row]!=""?(worksheet['AK'+row].v.toString()=="SI"?true:false):null,
            tiene_energia_electrica:worksheet['AL'+row]!=undefined && worksheet['AL'+row]!=""?(worksheet['AL'+row].v.toString()=="SI"?true:false):null,
            tiene_servicio_recojo_basura:worksheet['AM'+row]!=undefined && worksheet['AM'+row]!=""?(worksheet['AM'+row].v.toString()=="SI"?true:false):null,
            gestion_pasada_trabajo:worksheet['AN'+row]!=undefined && worksheet['AN'+row]!=""?(worksheet['AN'+row].v.toString()=="SI"?true:false):null,
            recibio_pago_trabajo:worksheet['AO'+row]!=undefined && worksheet['AO'+row]!=""?(worksheet['AO'+row].v.toString()=="SI"?true:false):null,
            abandono_escuela:worksheet['AP'+row]!=undefined && worksheet['AP'+row]!=""?(worksheet['AP'+row].v.toString()=="SI"?true:false):null,
            vive_con:vive_con,
            inscripcion:{
              nombre_ultima_escuela:worksheet['AQ'+row]!=undefined && worksheet['AQ'+row]!=""?worksheet['AQ'+row].v.toString():null,
              codigo_sie_ultima_escuela:worksheet['AR'+row]!=undefined && worksheet['AR'+row]!=""?worksheet['AR'+row].v.toString():null,
              observacion:worksheet['AW'+row]!=undefined && worksheet['AW'+row]!=""?worksheet['AW'+row].v.toString():null
            }
          }
          
          me.importacion.estudiantes.push(estudiante);
          row++;			   
        } while (worksheet['A'+row]!=undefined);

        me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
	  }
	}

  async guardarImportacionEstudiantes(){
		this.blockUI.start();
		this.estudiantesService.guardarImportacionEstudiantesColegio({
			estudiantes:this.importacion.estudiantes,
			id_gestion:this.filter.gestion.id,
			id_curso:this.filter.curso.id,
      id_escuela:this.filter.escuela.id,
      id_empresa:this.usuario.id_empresa
		}).subscribe((res:any) => {
      if(res.riene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.importacion_estudiantes.close();
        this.getItems();
        this.toastr.success(res.mensaje);
      }
      this.blockUI.stop();
		});
	}

  abrirModalImportacionTutores(){
    this.importacion.tutores=[];
    this.obtenerIdiomas();
    this.obtenerGradosAcademicos();
    this.obtenerParentescos();
    this.importacion_tutores=this.modalService.open(this.importacion_tutores_ref, {windowClass:"importacion-tutores",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.importacion_tutores.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  descargarExcelImportacionTutores(){
      let cabecera=["Nº","Códigos Estudiantes (Separado por ',')","Apellido Paterno","Apellido Materno","Nombres","Nº C.I.",
      "Parentesco","Profesion","Telf. Fijo","Telf. Movil","Correo Electronico","Direccion Domicilio","Direccion Trabajo"];
			let data = [];
	
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("DATOS TUTORES");
      let headerRow = worksheet.addRow(cabecera);

      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
        cell.alignment ={ wrapText: true };
      })

      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );

      worksheet.getColumn(1).width = 5;
      worksheet.getColumn(1).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
      worksheet.getColumn(2).width = 13;
      worksheet.getColumn(2).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };

      for(let i=3;i<=cabecera.length;i++){
        worksheet.getColumn(i).width = 15;
        worksheet.getColumn(i).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
      }

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "PLANILLA-TUTORES.xlsx");
      })
  }

  subirArchivoExcelImportacionTutores(event){
    this.blockUI.start();
    this.importacion.tutores=[];
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
        do {
          let celda_parentesco=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString().toUpperCase():null;
          let parentesco=celda_parentesco?me.parentescos.filter(g => g.nombre_corto==celda_parentesco).length>0?me.parentescos.filter(g => g.nombre_corto==celda_parentesco)[0]:null:null;

          let tutor:any={
            codigos_estudiantes:worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null,
            parentesco:parentesco,
            tutor_principal:false,
            direccion_trabajo:worksheet['M'+row]!=undefined && worksheet['M'+row]!=""?worksheet['M'+row].v.toString():null,
            persona:{
              apellido_paterno:worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():"",
              apellido_materno:worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():"",
              nombres:worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null,
              ci:worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null,
              profesion:worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null,
              telefono:worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?worksheet['I'+row].v.toString():null,
              telefono_movil:worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?worksheet['J'+row].v.toString():null,
              correo_electronico:worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?worksheet['K'+row].v.toString():null,
              direccion:worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?worksheet['L'+row].v.toString():null,
              lenguaje:me.idiomas[0],
              grado:me.grados_academicos[0],
            }
          }
          
          me.importacion.tutores.push(tutor);
          row++;			   
        } while (worksheet['A'+row]!=undefined);

        me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
	  }
	}

  async obtenerParentescos(){
    let parentescos:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_PARENTESCO).toPromise();
    this.parentescos=parentescos.clases;
  }

  async obtenerIdiomas(){
		let idiomas:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_IDIOMA).toPromise();
    this.idiomas=idiomas.clases;
	}

  async obtenerGradosAcademicos(){
		let grados:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SCH.TIPO_GRADO_INSTRUCCION).toPromise();
    this.grados_academicos=grados.clases;
	}

  async guardarImportacionTutores(){
		this.blockUI.start();
		this.estudiantesService.guardarImportacionTutores({
			tutores:this.importacion.tutores,
			id_empresa:this.usuario.id_empresa,
      id_escuela:this.filter.escuela.id
		}).subscribe((res:any) => {
			this.importacion_tutores.close();
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
	}

  async imprimirRude(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerRudesEstudiantes({id_curso:estudiante.id_curso,id_gestion:estudiante.id_gestion,id_estudiante:estudiante.id}).subscribe(async (rudes:any)=>{
      if(rudes.length>0){
        this.pdfService.imprimirRudes(rudes,{codigo_sie:this.filter.escuela.codigo_sie});
      }else{
        this.toastr.warning("No se encontro una inscripcion del estudiante para la gestion actual!");
      }
      this.blockUI.stop();
    });
  }

  abrirModalEstudiantesArchivoBanco(){
    this.importacion.estudiantes=[];
    this.filter.gestion=null;
    this.filter.ids_estudiantes=0;
    this.filter.concepto_pago={id:0,concepto:{nombre:"TODOS"}};
    this.filter.conceptos_pagos=[{id:0,concepto:{nombre:"TODOS"}}];
    this.filter.opciones_archivo=[
      {codigo:"1",version:"1",descripcion:"Crea al estudiante y la deuda en una sola cuota por estudiante a partir de pagos registrados (Usar cuando ya es gestion pasada)"},
      {codigo:"1",version:"2",descripcion:"Crea al estudiante y la deuda (Usar a principio de la gestion)"},
      {codigo:"2",descripcion:"Inhabilita la deuda"},
      {codigo:"3",descripcion:"Inhabilita al estudiante y todas sus deudas"},
      {codigo:"4",descripcion:"Permite modificar datos del estudiante y sus deudas"}
    ];
    this.obtenerGetionesEscolares();
    this.archivo_banco_estudiantes=this.modalService.open(this.archivo_banco_estudiantes_ref, {windowClass:"importacion-estudiantes",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.archivo_banco_estudiantes.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async obtenerConceptosPagoColegio(gestion){
    this.filter.conceptos_pagos=[{id:0,concepto:{nombre:"TODOS"}}];
		let idiomas:any=await this.conceptosIngreso.obtenerConceptosPagoColegio(gestion).toPromise();
    this.filter.conceptos_pagos=this.filter.conceptos_pagos.concat(idiomas);
	}

  buscarConceptosPagosEstudiantesBanco(){
    this.blockUI.start();
    if(this.estudiantes_archivo_banco.length>0){
      this.filter.ids_estudiantes="";
      for(let i=0;i<this.estudiantes_archivo_banco.length;i++){
        this.filter.ids_estudiantes=this.filter.ids_estudiantes+this.estudiantes_archivo_banco[i].id;
        if(i+1<this.estudiantes_archivo_banco.length){
          this.filter.ids_estudiantes=this.filter.ids_estudiantes+",";
        }
      }
    }else{
      this.filter.ids_estudiantes=0;
    }
    this.conceptosIngreso.buscarDatosEstudiantesConceptosPagoBanco(this.filter).subscribe((conceptos_pagos_estudiantes_banco:any[])=>{
      this.conceptos_pagos_estudiantes_banco=conceptos_pagos_estudiantes_banco;
      this.blockUI.stop();
    });
  }

  buscarEstudiante = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.estudiantesService.buscarEstudiante(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  async establecerEstudiante($event){
    this.estudiantes_archivo_banco.push($event.item);
    this.busqueda_estudiante=null;
    $("#nom_est").val("");
  }

  formatter = (x:any) => (x.codigo+" - "+x.apellido_paterno+" "+x.apellido_materno+" "+x.nombres);

  eliminarEstudianteBanco(estudiante){
    this.estudiantes_archivo_banco.splice(this.estudiantes_archivo_banco.indexOf(estudiante),1);
  }

  descargarEstudiantesBanco(){
    this.blockUI.start();
    let fecha_actual=new Date();
    let linea="";
    for(let i=0;i<this.conceptos_pagos_estudiantes_banco.length;i++){
      linea=linea+"3652"+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].codigo+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].nombres+",";
      linea=linea+",";
      linea=linea+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].apellido_paterno+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].apellido_materno+",";
      linea=linea+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].codigo_curso+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].codigo_concepto_pago+",";
      if(this.filter.opcion.codigo=="1" && this.filter.opcion.version=="1"){
        linea=linea+this.conceptos_pagos_estudiantes_banco[i].saldo+",";
        linea=linea+"1,";
      }else{
        linea=linea+this.conceptos_pagos_estudiantes_banco[i].importe+",";
        linea=linea+this.conceptos_pagos_estudiantes_banco[i].cuotas+",";
      }
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].razon_social+",";
      linea=linea+",";
      linea=linea+",";
      linea=linea+",";
      linea=linea+",";
      linea=linea+(this.conceptos_pagos_estudiantes_banco[i].tipo_documento?this.conceptos_pagos_estudiantes_banco[i].tipo_documento.split("-")[0].trim():"CI")+",";
      linea=linea+this.conceptos_pagos_estudiantes_banco[i].nit+",";
      linea=linea+(this.conceptos_pagos_estudiantes_banco[i].complemento?this.conceptos_pagos_estudiantes_banco[i].complemento:"")+",";
      linea=linea+"31/12/"+fecha_actual.getFullYear().toString()+",";
      linea=linea+(this.filter.opcion.codigo);
      linea=linea+"\n"
    }
    var file = new Blob([linea.replace(/\n/g, "\r\n")], {type: 'text/plain'});
    let nombre_archivo="estudiantes_deudas_"+this.filter.gestion.nombre+".txt"
    fs.saveAs(file, nombre_archivo);
    this.blockUI.stop();
  }

  abrirPopuDocumentosEstudiante(estudiante){
    this.documentos_estudiante_materia_modal = this.modalService.open(DocumentosEstudianteComponent, {windowClass:'documentos-estudiante',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
		this.documentos_estudiante_materia_modal.componentInstance.estudiante = estudiante;
    this.documentos_estudiante_materia_modal.componentInstance.usuario = this.usuario;
		
		this.documentos_estudiante_materia_modal.componentInstance.onConfirm.subscribe(($e) => {
			this.documentos_estudiante_materia_modal.close();
    });
  }

  abrirPopupAsuntoAdministrativo(estudiante){
    this.documentos_estudiante_materia_modal = this.modalService.open(RegistroAsuntoAdministrativoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
		this.documentos_estudiante_materia_modal.componentInstance.estudiante = estudiante;
    this.documentos_estudiante_materia_modal.componentInstance.usuario = this.usuario;
		
		this.documentos_estudiante_materia_modal.componentInstance.alTerminar.subscribe(($e) => {
			this.documentos_estudiante_materia_modal.close();
    });
  }

  abrirParticipantesGrupoWhatsapp(){
    this.participantes_grupo_whatsapp_modal = this.modalService.open(ParticipantesGruposWhatsappComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.participantes_grupo_whatsapp_modal.componentInstance.usuario = this.usuario;
    
    this.participantes_grupo_whatsapp_modal.componentInstance.alTerminar.subscribe((res) => {
      this.participantes_grupo_whatsapp_modal.close();
    });
  }

}
