import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VecinosComponent } from './components/vecinos/vecinos.component';
import { LecturacionComponent } from './components/lecturacion/lecturacion.component';
import { VecinoLecturacionComponent } from './components/vecino-lecturacion/vecino-lecturacion.component';
import { VentaServicioComponent } from './components/venta-servicio/venta-servicio.component';
import { NeighborhoodRoutingModule } from './neighborhood-routing.module';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { WebcamModule } from 'ngx-webcam';
import { OrderModule } from 'ngx-order-pipe';

@NgModule({
  declarations: [VecinosComponent, LecturacionComponent,VecinoLecturacionComponent,VentaServicioComponent],
  imports: [
    CommonModule,
    NeighborhoodRoutingModule,
    BaseModule,
    FormsModule,
    NgbModule,
    WebcamModule,
    OrderModule,
    BlockUIModule.forRoot({
      message: 'Procesando...'
    }),
  ]
})
export class NeighborhoodModule { }
