import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { VentaComponent } from '../../../base/components/venta/venta.component';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { ClientesService } from '../../../snapquick/services/clientes/clientes.service';
import { Observable, of } from 'rxjs';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { ProductosService } from '../../../snapquick/services/productos/productos.service';
import { CombosService } from '../../../snapquick/services/combos/combos.service';
import { InventariosService } from '../../../snapquick/services/inventarios/inventarios.service';
import { PedidosService } from '../../../snapquick/services/pedidos/pedidos.service';
import { ToastrService } from 'ngx-toastr';
import { CotizacionesService } from '../../../snapquick/services/cotizaciones/cotizaciones.service';
import { VecinosService } from '../../services/vecinos/vecinos.service';
import { LecturaMedidorService } from '../../services/lectura-medidor/lectura-medidor.service';
import { Util } from 'src/app/utils/utils';
import { OrderPipe } from 'ngx-order-pipe';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { VecinoLecturacionComponent } from '../vecino-lecturacion/vecino-lecturacion.component';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { CajasService } from 'src/app/snapquick/services/cajas/cajas.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';

declare const enfocarElemento:any;
declare const $:any;

@Component({
  selector: 'app-venta-servicio',
  templateUrl: './venta-servicio.component.html',
  styleUrls: ['./venta-servicio.component.css']
})
export class VentaServicioComponent extends VentaComponent implements OnInit {

  servicios_pendientes:any[]=[];
  servicios_pendientes_reporte:any[]=[];

  producto_consumo_agua:any;
  producto_formulario:any;
  popupConfirmacion:NgbModalRef;
  modalRegistroLecturacion:NgbModalRef;
  tarifas_agua:any[]=[];

  constructor(
    public generalService:GeneralService,
    public ventasService:VentasService,
    public productosService:ProductosService,
    public combosService:CombosService,
    public inventariosService:InventariosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public clientesService:ClientesService,
    public pedidosService:PedidosService,
    public toastr: ToastrService,
    public cotizacionesService:CotizacionesService,
    public vecinoService: VecinosService,
    public lecturasMedidorService:LecturaMedidorService,
    private orderPipe: OrderPipe,
    public empresaService:EmpresaService,
    public usuarioService:UsuarioService,
    public cajasService:CajasService,
    public pdfService:PdfService
      ) {
    super(generalService,ventasService,productosService,combosService,
      inventariosService,persistenciaService,modalService,clientesService,
      pedidosService,toastr,cotizacionesService,empresaService,usuarioService,cajasService);
   }

  async ngOnInit(): Promise<void> {
    this.venta.descontar_almacen=false;
    this.venta.vecino=null;
    this.venta.fecha=new Date();
    this.venta.fechaTexto={
			year: this.venta.fecha.getFullYear(), 
			month: (this.venta.fecha.getMonth()+1),
			day:this.venta.fecha.getDate()
		}
    //this.editar_precio=this.venta.usuario.empresa.usar_servicios;

    this.obtenerTiposPago(this.nombre_corto_tipo_pago);
    this.obtenerMovimientosEgreso(this.nombre_corto_transaccion);
    this.establecerSucursal(this.venta.sucursales.length>=1?this.venta.sucursales[0]:null);
    this.obtenerTarifasAgua();

    enfocarElemento('nit');
  }

  obtenerTarifasAgua(){
    this.blockUI.start();
    this.productosService.obtenerTarifasAgua(this.usuario.id_empresa).subscribe((tarifas:any[]) => {
      this.tarifas_agua=tarifas;
      this.blockUI.stop();
    });
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.vecinoService.buscarVecino(this.venta.usuario.id_empresa,0,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: {cliente: {persona:any}}) => (x.cliente.persona.apellido_paterno+" "+x.cliente.persona.apellido_materno+" "+x.cliente.persona.nombres);

  establecerCliente($event){
    this.venta.vecino=$event.item;
    this.venta.cliente=this.venta.vecino.cliente;
    this.venta.razon_social=this.venta.vecino.cliente.razon_social;
    this.venta.nit_ci=this.venta.vecino.cliente.nit;
    enfocarElemento('venta_accion');
  }

  reiniciarCliente(){
    this.venta.cliente={};
    this.venta.vecino=null;
    this.servicios_pendientes=[];
    this.servicios_pendientes_reporte=[];
    this.venta.detallesVenta=[];
    $("#venta_razon_social").val("");
    enfocarElemento('venta_razon_social');
  }

  async buscarServicios(){
    this.servicios_pendientes=[];
    this.servicios_pendientes_reporte=[];
    this.blockUI.start();
    let productos:any=await this.productosService.obtenerProductosSubGrupo(this.venta.accion_agua.id_categoria).toPromise();
    this.producto_consumo_agua=productos.filter(e => e.grupo.nombre=="MENSUAL")[0];
    this.producto_formulario=productos.filter(e => e.grupo.nombre==GlobalVariable.Dictionary.NBH.PRODUCTO_GRUPO_POR_VENTA);
    this.producto_formulario=this.producto_formulario.length>0?this.producto_formulario[0]:null;
    this.lecturasMedidorService.obtenerPagosPendientes(this.venta.accion_agua.id).subscribe((data:any) => {

      let consumos_agua=this.orderPipe.transform(data.consumos_agua,null,null,null,(a, b) => { 
        if(a.gestion && b.gestion){
          if(parseInt(a.gestion.nombre) < parseInt(b.gestion.nombre)){
            return -1;
          }else if(parseInt(a.gestion.nombre) > parseInt(b.gestion.nombre)){
            return 1;
          }else{
            if(parseInt(a.mes.nombre_corto) < parseInt(b.mes.nombre_corto)){
              return -1;
            }else if(parseInt(a.mes.nombre_corto) == parseInt(b.mes.nombre_corto)){
              if(a.id_lectura_medidor){
                return -1;
              }else{
                return 1;
              }
            }else{
              return 1;
            }
          }
        }else{
          if(!a.gestion){
            return -1;
          }else{
            return 1;
          }
        } 
      });

      /*let consumos_agua=this.orderPipe.transform(data.consumos_agua,null,null,null,(a, b) => { 
        if(parseInt(a.gestion.nombre) >= parseInt(b.gestion.nombre)){
          if(parseInt(a.mes.nombre_corto) > parseInt(b.mes.nombre_corto)){
            return 1;
          }else{
            return -1;
          }
        }else{
          return -1;
        } 
      });*/
      let servicios_varios=data.servicios_varios;
      let pago_pendiente;
      for(var i=0;i<consumos_agua.length;i++){
        consumos_agua[i].fecha_expiracion=new Date(consumos_agua[i].fecha_expiracion);
        let importe=this.calcularPrecioConsumoAgua(consumos_agua[i].consumo_cubos);
        pago_pendiente={
            id_lectura_medidor:consumos_agua[i].id,
            categoria:this.venta.accion_agua.categoria,
            producto:this.producto_consumo_agua,
            gestion:consumos_agua[i].gestion,
            mes:consumos_agua[i].mes,
            id_accion_agua:consumos_agua[i].id_accion_agua,
            lectura_anterior:consumos_agua[i].lectura_anterior,
            lectura_actual:consumos_agua[i].lectura_actual,
            consumo_cubos:consumos_agua[i].consumo_cubos,
            cantidad:consumos_agua[i].consumo_cubos,
            precio_unitario:importe,
            importe:importe,
            total:importe,
            pagado:importe,
            saldo:0
        };
        this.servicios_pendientes.push(pago_pendiente);
      }
      for(var i=0;i<servicios_varios.length;i++){
        pago_pendiente={
            id_concepto_pago:servicios_varios[i].id,
            producto:servicios_varios[i].producto,
            categoria:servicios_varios[i].accion_agua.categoria,
            cantidad:servicios_varios[i].cantidad,
            precio_unitario:servicios_varios[i].saldo,
            importe:servicios_varios[i].saldo,
            total:servicios_varios[i].saldo,
            pagado:servicios_varios[i].saldo,
            saldo:0
        }
        this.servicios_pendientes.push(pago_pendiente);
      }
      this.venta.detallesVenta=[];

      if(this.producto_formulario){
        this.venta.detallesVenta.push({
          producto:this.producto_formulario,
            cantidad:1,
            precio_unitario:this.producto_formulario.precio_unitario,
            importe:this.producto_formulario.precio_unitario,
            total:this.producto_formulario.precio_unitario,
            pagado:this.producto_formulario.precio_unitario,
            saldo:0,
            descuento:0
        });
      }
      this.servicios_pendientes_reporte=JSON.parse(JSON.stringify(this.servicios_pendientes));
      this.blockUI.stop();
      $(".focus-data").focus();
    });
  }

  calcularPrecioConsumoAgua(consumo_cubos){
    //recuperar el producto consumo de agua
    //verificar si tiene tarifa accionada == null
    //si no tiene, usar precio_unitario
    if(this.tarifas_agua.length>0){
      //usar consumo_cubos
      let precio=0,tarifa_encontrada=false;
      for(let i=0;i<this.tarifas_agua.length && !tarifa_encontrada;i++){
        if(consumo_cubos>=this.tarifas_agua[i].valor_inicial && (this.tarifas_agua[i].valor_final!=null && consumo_cubos<=this.tarifas_agua[i].valor_final)){
          tarifa_encontrada=true;
          if(this.tarifas_agua[i].orden==1){
            if(this.tarifas_agua[i].es_fija){
              precio=this.tarifas_agua[i].tarifa;
            }else{
              precio=this.tarifas_agua[i].tarifa*consumo_cubos;
            }
          }else{
            if(this.tarifas_agua[i].es_acumulativa){
              for(let j=i;j>=0;j--){
                if(this.tarifas_agua[j].es_fija){
                  precio=precio+this.tarifas_agua[j].tarifa;
                }else{
                  let limitValue=(this.tarifas_agua[j].valor_inicial==0)?this.tarifas_agua[j].valor_inicial:this.tarifas_agua[j].valor_inicial-1;
                  precio=precio+(this.tarifas_agua[j].tarifa*(consumo_cubos-limitValue));
                  consumo_cubos=limitValue;
                }
              }
            }else{
              if(this.tarifas_agua[i].es_fija){
                precio=this.tarifas_agua[i].tarifa;
              }else{
                precio=this.tarifas_agua[i].tarifa*consumo_cubos;
              }
            }
          }
        }else if(consumo_cubos>=this.tarifas_agua[i].valor_inicial && this.tarifas_agua[i].valor_final==null){
          tarifa_encontrada=true;
          if(this.tarifas_agua[i].es_acumulativa){
            for(let j=i;j>=0;j--){
              if(this.tarifas_agua[j].es_fija){
                precio=precio+this.tarifas_agua[j].tarifa;
              }else{
                let limitValue=(this.tarifas_agua[j].valor_inicial==0)?this.tarifas_agua[j].valor_inicial:this.tarifas_agua[j].valor_inicial-1;
                precio=precio+(this.tarifas_agua[j].tarifa*(consumo_cubos-limitValue));
                consumo_cubos=limitValue;
              }
            }
          }else{
            if(this.tarifas_agua[i].es_fija){
              precio=this.tarifas_agua[i].tarifa;
            }else{
              precio=this.tarifas_agua[i].tarifa*consumo_cubos;
            }
          }
        }
      }
      return precio;
    }else{
      if(consumo_cubos<=0){//cuando su medidor marca 0 en consumo
        return this.producto_consumo_agua.precio_unitario;
      }else{
        return this.producto_consumo_agua.precio_unitario*consumo_cubos;
      }
    }
    
    
		/*var totalComsuption=totalCom;
		*/
  }
  
  sumarServiciosPendientes(){
    var sum = 0;
    for(var i = 0; i < this.servicios_pendientes.length; i++){
      sum += (this.servicios_pendientes[i].total);
    }
    sum=Math.round((sum) * 100) / 100;
    return sum;
  }

  agregarServicioPendiente(servicio_pendiente){
    servicio_pendiente.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
    servicio_pendiente.descuento=0
    servicio_pendiente.recargo=0;
    servicio_pendiente.ice=0;
    servicio_pendiente.excento=0;
    servicio_pendiente.tipo_descuento=false;
    servicio_pendiente.tipo_recargo=false;
    var index=this.servicios_pendientes.indexOf(servicio_pendiente);
    if(servicio_pendiente.total>0){
      this.venta.detallesVenta.push(servicio_pendiente);
      this.servicios_pendientes.splice(index,1);

      for(let i=0;servicio_pendiente.producto.productos_hijos && i<servicio_pendiente.producto.productos_hijos.length;i++){
        let detalle_venta={
          es_hijo:true,
          id_padre:servicio_pendiente.id_lectura_medidor,
          producto:servicio_pendiente.producto.productos_hijos[i],
          categoria:servicio_pendiente.categoria,
          gestion:servicio_pendiente.gestion,
          mes:servicio_pendiente.mes,
          cantidad:1,
          precio_unitario:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          importe:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          descuento:0,
          total:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          pagado:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          saldo:0,
          descripcion:servicio_pendiente.mes.nombre+" "+servicio_pendiente.gestion.nombre+' '+'Categoria : '+servicio_pendiente.categoria.nombre
        }
        this.venta.detallesVenta.push(detalle_venta);
      }

    }else{
      this.toastr.error("¡No puede agregar un pago pendiente con monto igual a 0!");
    }
    
    this.venta.detallesVenta=this.orderPipe.transform(this.venta.detallesVenta,null,null,null,(a, b) => { 
      if(a.gestion && b.gestion){
        if(parseInt(a.gestion.nombre) < parseInt(b.gestion.nombre)){
          return -1;
        }else if(parseInt(a.gestion.nombre) > parseInt(b.gestion.nombre)){
          return 1;
        }else{
          if(parseInt(a.mes.nombre_corto) < parseInt(b.mes.nombre_corto)){
            return -1;
          }else if(parseInt(a.mes.nombre_corto) == parseInt(b.mes.nombre_corto)){
            if(a.id_lectura_medidor){
              return -1;
            }else{
              return 1;
            }
          }else{
            return 1;
          }
        }
      }else{
        if(!a.gestion){
          return -1;
        }else{
          return 1;
        }
      } 
    });
    //this.venta.descuento=this.venta.descuento+this.detalleVenta.descuento;
    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    this.venta.pagado=this.calcularPagado();
    this.calcularCambio();
  }

  calcularDescuentoDetalleVenta(detalleVenta){
    //detalleVenta.importe=Math.round((detalleVenta.cantidad*detalleVenta.precio_unitario)*1000)/1000;
    detalleVenta.total=Math.round((detalleVenta.importe-detalleVenta.descuento)*1000)/1000;
    detalleVenta.pagado=detalleVenta.total;
    this.sumarDescuentos();
    this.sumarTotalImporte();
    this.sumarTotal();
    this.calcularCambio();
  }

  calcularPagado(){
    let suma=0;
    for(let i=0;i<this.venta.detallesVenta.length;i++){
      suma=suma+this.venta.detallesVenta[i].pagado;
    }
    return suma;
  }

  sumarDescuentos(){
    this.venta.descuento=0;
    for(let i=0;i<this.venta.detallesVenta.length;i++){
      this.venta.descuento=this.venta.descuento+this.venta.detallesVenta[i].descuento;
    }
  }
  
  eliminarDetalleVenta(detalleVenta){
    let index=this.venta.detallesVenta.indexOf(detalleVenta);
    this.venta.detallesVenta.splice(index,1);
    this.venta.detallesVenta=this.venta.detallesVenta.filter(e => !(e.es_hijo && e.id_padre==detalleVenta.id_lectura_medidor));
    if(detalleVenta.producto.id!=this.producto_formulario.id){
      this.servicios_pendientes.push(detalleVenta);
    }

    this.servicios_pendientes=this.orderPipe.transform(this.servicios_pendientes,null,null,null,(a, b) => { 
      if(a.gestion && b.gestion){
        if(parseInt(a.gestion.nombre) < parseInt(b.gestion.nombre)){
          return -1;
        }else if(parseInt(a.gestion.nombre) > parseInt(b.gestion.nombre)){
          return 1;
        }else{
          if(parseInt(a.mes.nombre_corto) < parseInt(b.mes.nombre_corto)){
            return -1;
          }else if(parseInt(a.mes.nombre_corto) == parseInt(b.mes.nombre_corto)){
            if(a.id_lectura_medidor){
              return -1;
            }else{
              return 1;
            }
          }else{
            return 1;
          }
        }
      }else{
        if(!a.gestion){
          return -1;
        }else{
          return 1;
        }
      } 
    });

    this.sumarTotal();
    this.sumarTotalImporte();
    this.calcularSaldo();
    this.calcularCambio();
  }

  establecerPagadoDetalleVenta(detalleVenta){
    detalleVenta.saldo=detalleVenta.total-detalleVenta.pagado;
    this.venta.pagado=this.calcularPagado();
  }

  calcularCambio(){
    let total=this.calcularPagado();
    this.venta.cambio=Math.round((this.venta.pagado-total)*100)/100;
    this.venta.pagoMinimo=total;
  }

  validarVenta(){
    let res=true;
    if(!this.venta.cliente.id){
      res=res && false;
      this.toastr.error("Debe especificar al vecino!");
    }
    if(this.venta.detallesVenta.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item a la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.calcularPagado() || this.venta.cambio<0)){
      res=res && false;
      this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
    }
    return res;
  }

  guardar(){
    if(this.validarVenta()){
      this.blockUI.start();
      if(this.venta._id){
        let ventas_borrador=this.persistenciaService.get("ventas")?this.persistenciaService.get("ventas"):[];
        ventas_borrador=ventas_borrador.filter(e => e._id != this.venta._id);
        this.persistenciaService.set("ventas",ventas_borrador);
      }
      let tiempoActual=new Date();
      this.venta.fecha=Util.convertirObjetoAfecha(this.venta.fechaTexto);
      this.venta.fecha.setHours(tiempoActual.getHours());
      this.venta.fecha.setMinutes(tiempoActual.getMinutes());
      this.venta.fechaTexto=this.venta.fechaTexto.day+"/"+this.venta.fechaTexto.month+"/"+this.venta.fechaTexto.year;
      this.venta.razon_social=this.venta.cliente.razon_social;
      this.venta.nit_ci=this.venta.cliente.nit;
      this.venta.a_cuenta=this.venta.total;
      this.venta.saldo=0;
      //this.venta.total=0;
      for(let i=0;i<this.venta.detallesVenta.length;i++){
        if(this.venta.detallesVenta[i].pagado<this.venta.detallesVenta[i].total){
          this.venta.detallesVenta[i].total=this.venta.detallesVenta[i].pagado;
        }
      }
      this.sumarTotal();
      this.ventasService.guardarVenta(this.venta).subscribe((res:any) => {
        this.blockUI.stop();
        this.alTerminar.emit(res);
      });
    }
  }

  interpolateReader(servicio_pendiente){
    this.blockUI.start();
    this.lecturasMedidorService.inferirLecturaActual(servicio_pendiente).subscribe((res:any) => {
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
			this.buscarServicios();
    });
  }

  abrirPopupConfirmacionInterpolacion(servicio_pendiente){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.title="Confirmación de inferencia!";
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de inferenciar el dato de la lectura?";
		this.popupConfirmacion.componentInstance.data = servicio_pendiente;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.interpolateReader($e.data);
			}
			this.popupConfirmacion.close();
        });
  }
  
  abrirPopupConfirmacionEliminacion(servicio_pendiente){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el producto?";
		this.popupConfirmacion.componentInstance.data = servicio_pendiente;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarServicioPendiente($e.data);
			}
			this.popupConfirmacion.close();
        });
  }
  
  eliminarServicioPendiente(servicio_pendiente){console.log(servicio_pendiente);
		this.blockUI.start();
    if(servicio_pendiente.id_lectura_medidor){
      this.lecturasMedidorService.eliminarLecturaMedidor(servicio_pendiente.id_lectura_medidor).subscribe((dato:any) => {
        this.buscarServicios();
        this.toastr.info(dato.mensaje);
        this.blockUI.stop();
      });
    }else{
      this.vecinoService.eliminarConceptoPago(servicio_pendiente.id_concepto_pago).subscribe((dato:any) => {
        this.buscarServicios();
        this.toastr.info(dato.mensaje);
        this.blockUI.stop();
      });
    }
  }
  
  modificarLecturacion(vecino){
		this.modalRegistroLecturacion = this.modalService.open(VecinoLecturacionComponent, {ariaLabelledBy: 'modal-basic-title',windowClass: 'registrolecturacion', backdrop: 'static'});
      this.modalRegistroLecturacion.componentInstance.vecino = vecino;
      this.modalRegistroLecturacion.componentInstance.usuario = this.usuario;
			
			this.modalRegistroLecturacion.componentInstance.alTerminar.subscribe((res) => {
				if(res.mensaje){
          this.toastr.success(res.mensaje);
          if(this.venta.accion_agua){
            this.buscarServicios();
          }
			  }
			  this.modalRegistroLecturacion.close();
		});
	}

  generarReporteCobrosPendientes(){
    let servicios_pendientes_completos=[];
    for(let j=0;j<this.servicios_pendientes_reporte.length;j++){
      servicios_pendientes_completos.push(this.servicios_pendientes_reporte[j]);
      for(let i=0;this.servicios_pendientes_reporte[j].producto.productos_hijos && i<this.servicios_pendientes_reporte[j].producto.productos_hijos.length;i++){
        let servicio_pendiente={
          //id_concepto_pago:servicios_varios[i].id,
          producto:this.servicios_pendientes_reporte[j].producto.productos_hijos[i],
          categoria:this.servicios_pendientes_reporte[j].categoria,
          gestion:this.servicios_pendientes_reporte[j].gestion,
          mes:this.servicios_pendientes_reporte[j].mes,
          cantidad:this.servicios_pendientes_reporte[j].cantidad,
          precio_unitario:this.servicios_pendientes_reporte[j].producto.productos_hijos[i].precio_unitario,
          importe:this.servicios_pendientes_reporte[j].producto.productos_hijos[i].precio_unitario,
          descuento:0,
          total:this.servicios_pendientes_reporte[j].producto.productos_hijos[i].precio_unitario,
          descripcion:this.servicios_pendientes_reporte[j].mes.nombre+" "+this.servicios_pendientes_reporte[j].gestion.nombre+' '+'Categoria : '+this.servicios_pendientes_reporte[j].categoria.nombre,
          saldo:0
        }
        /*let detalle_venta={
          es_hijo:true,
          id_padre:servicio_pendiente.id_lectura_medidor,
          producto:servicio_pendiente.producto.productos_hijos[i],
          categoria:servicio_pendiente.categoria,
          gestion:servicio_pendiente.gestion,
          mes:servicio_pendiente.mes,
          cantidad:1,
          precio_unitario:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          importe:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          descuento:0,
          total:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          pagado:servicio_pendiente.producto.productos_hijos[i].precio_unitario,
          saldo:0,
          descripcion:servicio_pendiente.mes.nombre+" "+servicio_pendiente.gestion.nombre+' '+'Categoria : '+servicio_pendiente.categoria.nombre
        }*/
        servicios_pendientes_completos.push(servicio_pendiente);
      }
    }
    this.pdfService.imprimirReporteServiciosPendientes(servicios_pendientes_completos,this.venta.cliente,this.venta.sucursal,this.configuracion_pagina.getOrden().impresion,false,this.usuario,this.tipo_textos);
  }

}
