import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { Router } from '@angular/router';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { CotizacionesService } from '../../services/cotizaciones/cotizaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';
import { CotizacionComponent } from '../cotizacion/cotizacion.component';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { ConfiguracionAplicacionCotizacionesComponent } from 'src/app/base/components/configuracion-aplicacion-cotizaciones/configuracion-aplicacion-cotizaciones.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.css']
})
export class CotizacionesComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  cotizaciones:any[]=[];

  modalCotizacion:NgbModalRef;
  configuracion_aplicacion_modal:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public cotizacionesService:CotizacionesService,
    public modalService: NgbModal,
	private toastr: ToastrService,
	public pdfService:PdfService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));

    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_COTIZACIONES);

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }
    
    this.column = "fecha";
		this.direction = "desc";
    this.getItems();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }
  
  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.cotizacionesService.obtenerListaCotizaciones(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.cotizaciones=dato.cotizaciones;
			this.blockUI.stop();
		});
  }

  
  crearNuevaCotizacion(){
    this.modalCotizacion = this.modalService.open(CotizacionComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.modalCotizacion.componentInstance.cotizacion.usuario = this.usuario;
    this.modalCotizacion.componentInstance.usuario = this.usuario;
    this.modalCotizacion.componentInstance.configuracion_pagina = this.configuracion_pagina;
		this.modalCotizacion.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalCotizacion.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		
		this.modalCotizacion.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
          this.imprimirCotizacion(res.cotizacion);
          this.getItems();
					this.modalCotizacion.close();
				}
			}else{
				this.modalCotizacion.close();
			}
			
		});
  }

  imprimirCotizacion(cotizacion){
		this.cotizacionesService.obtenerCotizacion(cotizacion.id).subscribe((datos:any) => {
			let cotizacion_consultada=datos;
			var fecha=new Date(cotizacion_consultada.fecha);
			cotizacion_consultada.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
      this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_COTIZACIONES,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).subscribe((conf:any)=>{
        let configuracion_aplicacion_ventas=conf;
        let configuracionImpresion=configuracion_aplicacion_ventas.datos.opciones_permisos.impresion;
        this.pdfService.imprimirCotizacion(cotizacion_consultada,configuracionImpresion,this.usuario);
      });
		});
	}

  modificarCotizacion(cotizacion){
    this.cotizacionesService.obtenerCotizacion(cotizacion.id).subscribe((cotizacion:any) => {
      this.modalCotizacion = this.modalService.open(CotizacionComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.modalCotizacion.componentInstance.cotizacion = cotizacion;
      this.modalCotizacion.componentInstance.cotizacion.usuario = this.usuario;
      this.modalCotizacion.componentInstance.usuario = this.usuario;
      this.modalCotizacion.componentInstance.configuracion_pagina = this.configuracion_pagina;
      this.modalCotizacion.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
      this.modalCotizacion.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
      
      this.modalCotizacion.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.toastr.success(res.mensaje);
            this.getItems();
            this.modalCotizacion.close();
          }
        }else{
          this.modalCotizacion.close();
        }
        
      });
    });
  }

  abrirPopupConfirmacionEliminacion(cotizacion){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la cotización?";
		this.popupConfirmacion.componentInstance.data = cotizacion;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCotizacion($e.data);
			}
			this.popupConfirmacion.close();
        });
	}
	  
	eliminarCotizacion(cotizacion){
		this.blockUI.start();
		this.cotizacionesService.anularCotizacion(cotizacion).subscribe((res:any) => {
			this.toastr.info(res.mensaje);
			this.blockUI.stop();
			this.getItems();
		});
	}

  abrirConfiguracionAplicacion(){
    this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionCotizacionesComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
    this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
    this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
    this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
      this.configuracion_aplicacion_modal.close();
    });
  }

}

