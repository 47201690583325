<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Reportes

        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          reportes
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Reportes</span>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                  <label for="feini">Desde </label>&nbsp;
                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                  </div>
              </div>  
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                  <label for="fefin">Hasta </label>&nbsp;
                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
              </div>  
          </div>
					  <div class="col-md-3 col-sm-12">
                        <label>Sucursal </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                      </div>
					  <div class="col-md-3 col-sm-12">
						<label>Usuario </label>&nbsp;
						  <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
							  <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
						  </select>
                      </div>
                      <div class="col-md-3 col-sm-12">
						<label>Cliente </label>
                        <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
                      </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="generarReporteVentaCreditoProductosCliente()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
              </button>
              <button title="Pdf" (click)="generarPdf()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                      <i class="fa fa-file-pdf text-danger"></i>
              </button>
              <button title="Excel" (click)="generarExcel()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-file-excel text-success" aria-hidden="true"></i>
              </button>

              <script src="https://code.jquery.com/jquery-1.12.3.min.js"></script>
              <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>

              <div class="export btn-group">
                <button class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                          <i class="fa fa-download text-green-d1"></i>
                          <span class="caret"></span>
                </button>
                <button (click)="clickBotonSubir('subir-excel-productos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                  <i class="fa fa-upload text-red fileUpload-button"></i>
                  <input id="subir-excel-productos" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </button>
			        </div>
			  <div class="keep-open btn-group show" title="Columns">
				<button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
					<i class="fa fa-wrench text-blue-d1"></i>
					<span class="caret"></span>
				</button>

				<div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
					<div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
						<div class="card bgc-primary brc-primary radius-0">
						  <div class="card-header">
							<h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
						  </div>
	  
						  <div class="card-body p-0 bg-white">
							<table class="table table-striped table-hover mb-0">
							  <tbody>
								<tr>
								  <td class="text-dark-m2">
									Importación precios
								  </td>
								  <td class="text-dark-m2">
									<a href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
								  </td>
								  <td>
									<a href="javascript:void(0)" class="text-blue"><i class="fa fa-upload text-blue"></i></a>
								  </td>
								</tr>
								<tr>
									<td class="text-dark-m2">
										Descarga catálogo
									</td>
									<td class="text-dark-m2">
										<a href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
									</td>
									<td>
										<a href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
									</td>
								</tr>
							  </tbody>
							</table>
						  </div>
						</div>
					  </div>
				</div>
				<!--<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>-->
			</div>
            </div>
          </div>
		</div>
      </div>



      <ng-container *ngFor="let cliente of clientes">
        <div class="row" >
            <div class="col-4 col-sm-4 col-lg-4 px-2 mt-2 mt-sm-0">
                <div class="dropdown-menu d-block position-static radius-1 brc-success-tp2 border-t-3 p-1 m-2">
                    <a class="dropdown-item btn btn-outline-primary btn-h-light-primary btn-a-light-primary my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fas fa-home text-primary text-140 w-3 mr-2px"></i>
                      {{cliente.identificacion}}
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-success btn-h-light-success btn-a-light-success my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-check text-success w-3 mr-2px"></i>
                      Vendidos
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-primary btn-h-light-primary btn-a-light-primary my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-gift text-primary w-3 mr-2px"></i>
                      Obsequios
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-danger btn-h-light-danger btn-a-light-danger my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-times text-danger text-140 w-3 mr-2px"></i>
                      Devoluciones
                      
                    </a>
                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-purple btn-h-light-purple btn-a-light-purple my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="far fa-money-bill-alt text-purple text-140 w-3 mr-2px"></i>
                      Total Importe:  Bs {{cliente.total.toFixed(2)}}
                    </a><div class="dropdown-divider m-1"></div>
                    <a class="dropdown-item btn btn-outline-purple btn-h-light-purple btn-a-light-purple my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-times text-purple text-140 w-3 mr-2px"></i>
                      Devoluciones:  Bs {{cliente.devoluciones.toFixed(2)}}
                    </a><div class="dropdown-divider m-1"></div>
                    <a class="dropdown-item btn btn-outline-purple btn-h-light-purple btn-a-light-purple my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fas fa-dollar-sign text-purple text-140 w-3 mr-2px"></i>
                      A Cuenta:  Bs {{cliente.a_cuenta.toFixed(2)}}
                    </a>
                    <a class="dropdown-item btn btn-outline-purple btn-h-light-purple btn-a-light-purple my-1 text-90 font-bolder text-uppercase" href="#">
                        <i class="fas fa-hand-holding-usd text-purple text-140 w-3 mr-2px"></i>
                        Saldo:  Bs {{cliente.saldo.toFixed(2)}}
                      </a>
                  </div>
            </div>
            <div class="col-8 col-sm-8 col-lg-8 px-2 mt-2 mt-sm-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3 px-2 mt-2 mt-sm-0" *ngFor="let item of cliente.items | orderBy: 'codigo_producto'">
                    <div class="d-style active w-100 shadow-sm border-t-3 my-1 pb-3 btn btn-outline-light btn-h-outline-blue btn-h-bgc-white btn-a-outline-blue btn-a-bgc-white">
        
                      <div class="d-flex flex-column align-items-center">
                        <h5 [ngClass]="{'text-blue':item.producto,'text-success':item.combo,'text-warning':(!item.combo && !item.producto)}" class="w-90 pb-1 mt-2 mb-3 border-b-2 brc-grey-l2" >
                          <i>{{item.producto?item.producto:item.combo?item.combo:'SERVICIOS'}}</i>
                        </h5>                        
        
                        <div class="flex-grow-1 text-grey-m1 text-90 w-90">
                          <ul class="list-unstyled text-left mx-auto mb-1">
                            <li class="d-flex align-items-start mb-3">
                              <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110 text-1">
                                    {{item.vendidos}}
                                </span>
                              </div>
                            </li>
        
                            <li class="d-flex align-items-start">
                              <i class="fa fa-gift text-primary-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110">
                                   {{item.obsequios_productos?item.obsequios_productos:item.obsequios_combos?item.obsequios_combos:0}} 
                                </span>
                              </div>
                            </li>
        
                            <li class="d-flex align-items-start mt-25">
                              <i class="fa fa-times text-danger-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110">
                                    {{item.devoluciones?item.devoluciones:item.devoluciones_combos?item.devoluciones_combos:0}}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
        
                      </div>
        
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="space-24"></div>
        <hr class="w-90 my-4 brc-grey-l2" />
    </ng-container>

    <ng-container *ngIf="items_resumen.length>0">
        <div class="row" >
            <div class="col-4 col-sm-4 col-lg-4 px-2 mt-2 mt-sm-0">
                <div class="dropdown-menu d-block position-static radius-1 brc-success-tp2 border-t-3 p-1 m-2">
                    <a class="dropdown-item btn btn-outline-primary btn-h-light-primary btn-a-light-primary my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fas fa-home text-primary text-140 w-3 mr-2px"></i>
                      Resumen
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-success btn-h-light-success btn-a-light-success my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-check text-success w-3 mr-2px"></i>
                      Vendidos
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-primary btn-h-light-primary btn-a-light-primary my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-gift text-primary w-3 mr-2px"></i>
                      Obsequios
                    </a>

                    <div class="dropdown-divider m-1"></div>

                    <a class="dropdown-item btn btn-outline-danger btn-h-light-danger btn-a-light-danger my-1 text-90 font-bolder text-uppercase" href="#">
                      <i class="fa fa-times text-danger text-140 w-3 mr-2px"></i>
                      Devoluciones
                      
                    </a>
                    <div class="dropdown-divider m-1"></div>
                  </div>
            </div>
            <div class="col-8 col-sm-8 col-lg-8 px-2 mt-2 mt-sm-0">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3 px-2 mt-2 mt-sm-0" *ngFor="let item of items_resumen | orderBy: 'codigo_producto'">
                    <div class="d-style active w-100 shadow-sm border-t-3 my-1 pb-3 btn btn-outline-light btn-h-outline-blue btn-h-bgc-white btn-a-outline-blue btn-a-bgc-white">
        
                      <div class="d-flex flex-column align-items-center">
                        <h5 [ngClass]="{'text-blue':item.producto,'text-success':item.combo,'text-warning':(!item.combo && !item.producto)}" class="w-90 pb-1 mt-2 mb-3 border-b-2 brc-grey-l2" >
                          <i>{{item.producto?item.producto:item.combo?item.combo:'SERVICIOS'}}</i>
                        </h5>                        
        
                        <div class="flex-grow-1 text-grey-m1 text-90 w-90">
                          <ul class="list-unstyled text-left mx-auto mb-1">
                            <li class="d-flex align-items-start mb-3">
                              <i class="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110 text-1">
                                    {{item.vendidos}}
                                </span>
                              </div>
                            </li>
        
                            <li class="d-flex align-items-start">
                              <i class="fa fa-gift text-primary-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110">
                                   {{item.obsequios_productos?item.obsequios_productos:item.obsequios_combos?item.obsequios_combos:0}} 
                                </span>
                              </div>
                            </li>
        
                            <li class="d-flex align-items-start mt-25">
                              <i class="fa fa-times text-danger-m2 text-110 mr-2 mt-1"></i>
                              <div>
                                <span class="text-110">
                                    {{item.devoluciones?item.devoluciones:item.devoluciones_combos?item.devoluciones_combos:0}}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
        
                      </div>
        
                    </div>
                    </div>
                </div>





                
            </div>
        </div>
        <div class="space-24"></div>
    </ng-container>


    </div>
    <div class="clearfix"></div>
</div>


<!--<div class="row">
	<div class="col-md-4 col-sm-4 col-xs-12">
		<h3 class="header smaller lighter blue">REPORTES</h3>
	</div>
</div>
<div class="row">
	<div class="col-md-12 col-sm-12 col-xs-12">	
		
			<div class="row">
				<div class="col-xs-12 col-md-12">
					<div class="row">
						<div class="col-md-9 col-xs-9 col-sm-9">
							<div class="row">
								<div class="col-md-1 col-xs-4 col-sm-4">
									<label>Reporte</label>
								</div>
								<div class="col-md-2 col-xs-8 col-sm-8">
									
								</div>
								<div class="col-md-1 col-xs-4 col-sm-4">
									<label>Sucursal</label>
								</div>
								<div class="col-md-2 col-xs-8 col-sm-8">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                                    </select>
								</div>
								<div class="col-md-1 col-xs-4 col-sm-4">
									<label>Cliente</label>
								</div>
								<div class="col-md-2 col-xs-8 col-sm-8">
									<input class="form-control" type="text" [(ngModel)]="filter.cliente" class="form-control">
								</div>
								<div class="col-md-1 col-xs-4 col-sm-4" *ngIf="es_usuario_administrador">
									<label>Usuario</label>
								</div>
								<div class="col-md-2 col-xs-8 col-sm-8" *ngIf="es_usuario_administrador">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario" [(ngModel)]="filter.usuario">
                                        <option [ngValue]="usuario" *ngFor="let usuario of filter.usuarios">{{usuario.nombre_usuario}}</option>
                                    </select>
								</div>
							</div>
						</div>
						<div class="col-md-3 col-xs-3 col-sm-3">
							<button (click)="generarReporteVentaCreditoProductosCliente()" type="button" class="btn btn-app btn-primary btn-xs">
								<i class="ace-icon fa fa-search align-top bigger-125"></i>
								Buscar
							</button>
						</div>
					</div>
				</div>
			</div>
		
		<div class="clearfix">
			<div class="pull-right tableTools-container"></div>
		</div>
	</div>
</div>-->



