<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Búsqueda Tutor</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 col-12">
            <div class="bootstrap-table bootstrap4">
                <div class="fixed-table-toolbar">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="row">
                        <div class="col-12 col-md-12">
                            <app-paginator-header [paginator]="this"></app-paginator-header>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div class="fixed-table-container" style="padding-bottom: 0px;">
                  <div class="fixed-table-header" style="display: none;">
                    <table></table>
                  </div>
                  <div class="fixed-table-body">
                    <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                      <span class="loading-wrap">
                        <span class="animation-wrap">
                          <span class="animation-dot"></span>
                        </span>
                      </span>
                    </div>
                    <table *ngIf="personas_tutores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive">
                        <thead>
                            <tr>
                            <th>Nº</th>
                            <th>Nombre completo</th>
                            <th>Dependientes</th>
                            <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let persona of personas_tutores; let i=index;">
                            <td>{{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}</td>
                            <td>{{persona.nombre_completo}}</td>
                            <td><span style="display:block;" *ngFor="let tutor of persona.tutores">{{tutor.parentesco?tutor.parentesco.nombre:''}} <i class="fab fa-odnoklassniki"></i> {{tutor.estudiante.persona.nombre_completo}}</span></td>
                            <td>
                                <div class="row">
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <button class="btn btn-primary btn-sm" (click)="seleccionarTutor(persona)"><i class="fas fa-user-check"></i></button>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-6">
                                        <button class="btn btn-danger btn-sm" (click)="abrirPopupConfirmacionEliminacionTutor(persona)"><i class="fa fa-trash"></i></button>
                                    </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        
                    </table>
                  </div>
                  <div class="fixed-table-footer">
                    <table><thead><tr></tr></thead></table>
                  </div>
                </div>
                <div class="fixed-table-pagination">
                  <app-paginator-footer *ngIf="personas_tutores.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
                </div>
              </div>
        </div>
    </div>
</div>