import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-registro-dosificacion',
  templateUrl: './registro-dosificacion.component.html',
  styleUrls: ['./registro-dosificacion.component.css']
})
export class RegistroDosificacionComponent extends BaseComponent implements OnInit {

  dosificacion:any={};
  pies_factura:any[]=[];
  @Output() onTerminate:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    public toastr:ToastrService,
    private empresasSevice:EmpresaService,
    public usuarioService:UsuarioService) {
  super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    if(this.dosificacion && this.dosificacion.id){

    }else{
      this.dosificacion.id_empresa=this.usuario.id_empresa;
    }
    this.obtenerPiesFactura();
  }

  cerrarComponente(){
    this.onTerminate.emit();
  }

  obtenerPiesFactura(){
    this.blockUI.start();
    this.generalService.obtenerClases("PIÉFACT").subscribe((entidad:any)=>{
      this.pies_factura=entidad.clases;
			this.blockUI.stop();
    });
  }
  
  guardarRegistroDosificacion(){
    this.blockUI.start();
    if(this.validarRegistroDosificacion()){
      this.dosificacion.fecha_limite_emision=Util.convertirObjetoAfecha(this.dosificacion.fecha_limite_emision_texto);
      this.empresasSevice.guardarDosificacion(this.dosificacion).subscribe((res:any)=>{
        this.blockUI.stop();
        this.toastr.success("Dosificación registrada satisfactoriamente!");
        this.onTerminate.emit(res);
      });
    }else{
      this.blockUI.stop();
    }
  }

  validarRegistroDosificacion(){
    let res=true;
		if(!this.dosificacion.autorizacion){
      res=res && false;
      this.toastr.error("Debe especificar el número de autorización!");
		}
		if(!this.dosificacion.correlativo || this.dosificacion.correlativo<=0){
		  res=res && false;
		  this.toastr.error("Debe especificar el número de correlativo con el valor de al menos 1!");
    }
    if(!this.dosificacion.fecha_limite_emision_texto || !this.dosificacion.fecha_limite_emision_texto.year || !this.dosificacion.fecha_limite_emision_texto.month || !this.dosificacion.fecha_limite_emision_texto.day){
		  res=res && false;
		  this.toastr.error("Debe especificar la fecha límite de emision!");
		}
		if(!this.dosificacion.llave_digital){
      res=res && false;
      this.toastr.error("Debe especificar la llave digital!");
    }
    if(!this.dosificacion.pieFactura){
      res=res && false;
      this.toastr.error("Debe especificar la frase del pie de factura!");
		}
		return res;
  }

}
