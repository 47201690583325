import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ClientesService } from '../../services/clientes/clientes.service';
import { CombosService } from '../../services/combos/combos.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { Util } from 'src/app/utils/utils';
import { RegistroClienteComponent } from '../../../base/components/registro-cliente/registro-cliente.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';

declare const $:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-pedido-panel',
  templateUrl: './pedido-panel.component.html',
  styleUrls: ['./pedido-panel.component.css']
})
export class PedidoPanelComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  mostrar_productos:boolean=true;
  pedido:any={cliente:{contactos:[]},detallesPedido:[]};
  venta:any;
  sucursales:any[]=[];
  almacenes:any[];
  sucursal:any;
  mesa:any;
  combos:any[];
  nuevo_pedido:any;
  sin_leyendas:any[]=[];
  documentos_sector:any[];
  liberar_mesa:boolean=true;
	editar_precio:boolean=false;
  editar_cantidad:boolean=false;
  grupos_productos:any[]=[];
  listChecked:any[];
  allChecked:boolean;
  productos:any[]=[];
  productosProcesados:any[]=[];
  tipos_despacho:any[]=[];
  estados:any[]=[];
  almacen:any;
  ordenProductos:boolean;
  base_datos_inventario:any;
  search:any=null;
  searching:any;
  searchFailed:any;
  color:any={'style': 'red-style', 'stylebutton': 'red-style-button'};
  cantidadInventario:any;
  razon_busqueda:any;
  detallePedidoDescripcion:any;
  detalle_pedido_obsequio:any;
  descuento_maximo:any;
  movimientos_egreso:any[];
  movimientosEgresoPanel:any[];
  tiposPago:any[];
  pagoMinimo:any;
  entidad_tipo_despacho:any;
  canales:any[]=[];
  id_almacen_seleccionado:any=null;
  actividades:any[];
  tipo_descripcion_ted_ped:any={clases:[]}
  configuracion_aplicacion_ventas:any;

  modalCliente:NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;

  descripcionitem:NgbModalRef;
  @ViewChild('descripcionitem')
  private descripcionitemref: TemplateRef<any>;

  obsequioitem:NgbModalRef;
  @ViewChild('obsequioitem')
  private obsequioitemref: TemplateRef<any>;

  ventamodal:NgbModalRef;
  @ViewChild('ventamodal')
  private ventamodalref: TemplateRef<any>;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public pedidosService:PedidosService,
    private productosService:ProductosService,
	private orderPipe: OrderPipe,
	private clientesService:ClientesService,
  private combosService:CombosService,
  private toastr:ToastrService,
  private ventasService:VentasService,
  private pdfService:PdfService,
  public usuarioService:UsuarioService,
  public empresaService:EmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.blockUI.start();
    this.editar_precio=false;
    this.editar_cantidad=false;
    this.liberar_mesa=true;
    await this.obtenerEstadosPedido();
    await this.obtenerTiposDespachos();

    let estado_pendiente=this.estados.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_PEND)[0];
    let fecha_actual=new Date();
    this.nuevo_pedido={
      estado:estado_pendiente,detallesPedido:[],
      tipoDespacho:(this.tipos_despacho.length>0?this.tipos_despacho[0]:null),
      mesas:[],pedido_activo:true,tiempo_ingreso:new Date(),descuento:0,
      garzones:[],activo:true,id_usuario:this.usuario.id,
      cliente:{contactos:[]},
      fechaDespachoTexto:this.usuario.empresa.usar_clientes_contactos?{
        year:fecha_actual.getFullYear(),
        month:fecha_actual.getMonth()+1,
        day:fecha_actual.getDate()
      }:null,
    }

    if(this.mesa){
			let mesaPedido:any=await this.pedidosService.obtenerPedidoMesa(this.mesa.id).toPromise();
      if(mesaPedido && mesaPedido.pedidosRestaurante){
        this.pedido=mesaPedido.pedidosRestaurante[0].pedidoRestaurante;
        await this.obtenerAlmacenesActividades((this.pedido && this.pedido.id_sucursal)?this.sucursales.filter(e => e.id==this.pedido.id_sucursal)[0]:this.sucursales[0]);
        this.razon_busqueda=this.pedido.cliente;
        for(var i=0;i<this.pedido.detallesPedido.length;i++){						
          if(this.pedido.detallesPedido[i].producto.activar_inventario){
            var proinv=this.productos.filter(e => e.id == this.pedido.detallesPedido[i].producto.id)[0];
            if(proinv.inventarios){
              this.pedido.detallesPedido[i].costos=proinv.inventarios;
            }
          }
          for(var j=0;j<this.pedido.detallesPedido[i].detallesPedido.length;j++){						
            proinv=this.productos.filter(e => e.id == this.pedido.detallesPedido[i].detallesPedido[j].producto.id)[0];
            if(proinv.inventarios){
              this.pedido.detallesPedido[i].detallesPedido[j].costos=proinv.inventarios;
            }
          }
        }
      }else{
        this.pedido=this.nuevo_pedido;
        await this.obtenerAlmacenesActividades((this.pedido && this.pedido.id_sucursal)?this.sucursales.filter(e => e.id==this.pedido.id_sucursal)[0]:this.sucursales[0]);
        await this.establecerClienteInicio();
        this.pedido.mesas.push(this.mesa);
      }
      this.sumarTotal();
      this.sumarTotalImporte();
		}else{
			if(this.pedido && this.pedido.id){
				let pedidoRestaurante:any=await this.pedidosService.obtenerPedidoRestaurante(this.pedido.id).toPromise();
        if(this.usuario.empresa.usar_despachos){
          if(pedidoRestaurante.despachos.length>0){
            this.toastr.info("¡No se puede modificar, el pedido tiene despachos realizados!");
            this.alTerminar.emit({confirm:false});
          }
        }
        this.pedido=pedidoRestaurante;
        await this.obtenerAlmacenesActividades((this.pedido && this.pedido.id_sucursal)?this.sucursales.filter(e => e.id==this.pedido.id_sucursal)[0]:this.sucursales[0]);
        this.razon_busqueda=this.pedido.cliente;
        if(this.pedido.tiempo_despacho){
          this.pedido.tiempo_despacho=new Date(this.pedido.tiempo_despacho);
          this.pedido.fechaDespachoTexto=this.usuario.empresa.usar_clientes_contactos?{
            year:this.pedido.tiempo_despacho.getFullYear(),
            month:this.pedido.tiempo_despacho.getMonth()+1,
            day:this.pedido.tiempo_despacho.getDate()
          }:null;						
        }
        if(this.usuario.empresa.usar_inventario){
          for(var i=0;i<this.pedido.detallesPedido.length;i++){	
            if(this.pedido.detallesPedido[i].producto && this.pedido.detallesPedido[i].producto.activar_inventario)	{
              var proinv=this.productos.filter(e => e.id == this.pedido.detallesPedido[i].producto.id)[0];
              if(proinv.inventarios){
                this.pedido.detallesPedido[i].costos=proinv.inventarios;
              }
              
            }				
          }
        }
        this.sumarTotal();
        this.sumarTotalImporte();
			}else{
        this.pedido=this.nuevo_pedido;
        await this.obtenerAlmacenesActividades((this.pedido && this.pedido.id_sucursal)?this.sucursales.filter(e => e.id==this.pedido.id_sucursal)[0]:this.sucursales[0]);
				await this.establecerClienteInicio();
			}
    }
    this.blockUI.stop();
    
    this.color = this.persistenciaService.get("color")?this.persistenciaService.get("color"):{'style': 'red-style', 'stylebutton': 'red-style-button'};
    this.persistenciaService.set("color",this.color);
		this.obtenerGruposProductoEmpresa();
		this.mostrarProductos();

    this.configuracion_aplicacion_ventas=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
  }

  ngAfterViewInit(){
    setTimeout(function(){
      //Util.aplicarSwiper(4,3,true,2);
    },1000);
    this.obtenerTextosEmpresa();
  }

  async establecerClienteInicio(){
    let cliente_sin_nombre="SIN NOMBRE";
    let clientes:any=await this.clientesService.buscarClientePedido(this.usuario.id_empresa,(this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:0),0,cliente_sin_nombre).toPromise();
    if(clientes.length==0){
      //this.establecerClientePedido({identificacion:cliente_sin_nombre,razon_social:cliente_sin_nombre,nit:0});
    }else{
      this.establecerClientePedido(clientes[0]);
    }
  }

  async obtenerEstadosPedido(){
    let entidad:any=await this.generalService.obtenerClases("ESTPEDI").toPromise();
	  this.estados=entidad.clases;
  }	

  async obtenerTiposDespachos(){
    if(this.usuario.empresa.usar_pedidos_en_linea){
      let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"REST_DESP").toPromise();
      this.tipos_despacho=entidad.clases?entidad.clases:[];
      this.entidad_tipo_despacho=entidad;
    }else if(this.usuario.empresa.usar_clientes_contactos){
      let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
      this.tipos_despacho=this.orderPipe.transform(entidad.clases, 'createdAt',true);
      this.entidad_tipo_despacho=entidad;
    }else{
      let entidad:any=await this.generalService.obtenerClases("REST_DESP").toPromise();
      this.tipos_despacho=entidad.clases;
      this.entidad_tipo_despacho=entidad;
    }
  }

  async obtenerTiposDescripcionesDetallePedido(){
    let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_DESCRIPCIONES_DETALLE_PEDIDO).toPromise();
    this.tipo_descripcion_ted_ped=entidad.clases?entidad.clases:[];
    this.tipo_descripcion_ted_ped=entidad;
  }

  establecerDetallePedidoDescripcion(descripcion){
    if(this.detallePedidoDescripcion.descripcion){
      if(this.detallePedidoDescripcion.descripcion.includes(descripcion)){
        let textos=this.detallePedidoDescripcion.descripcion.split(" * ");console.log(textos);
        let texto_final="";
        for(let i=0;i<textos.length;i++){
          if(textos[i].includes(descripcion)){
            if(textos[i].length==descripcion.length){
              texto_final=texto_final+"2 "+descripcion;
            }else{console.log(textos[i]);
              let cantidad=parseInt(textos[i].split(" ")[0]);
              texto_final=texto_final+(cantidad+1)+" "+descripcion;
            }
          }else{
            texto_final=texto_final+textos[i];
          }

          if(i<textos.length-1){
            texto_final=texto_final+" * ";
          }
        }
        this.detallePedidoDescripcion.descripcion=texto_final;
      }else{
        this.detallePedidoDescripcion.descripcion=this.detallePedidoDescripcion.descripcion+" * "+descripcion;  
      }
    }else{
      this.detallePedidoDescripcion.descripcion=descripcion;
    }
  }

  async obtenerDatosCalculoInventario(){
    let datos=await this.pedidosService.obtenerInventarioPedidosMesas(this.sucursal.id).toPromise();
    this.base_datos_inventario=datos;
  }

  obtenerGruposProductoEmpresa(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,this.global_variable.Dictionary.TIPO_PRODUCTO_GRUPOS).subscribe((tipoGrupo:any)=>{
      let grupos=tipoGrupo.clases.filter(g => g.habilitado);
      this.grupos_productos=grupos;

      // == condicion save localstorage ====
      
			if ( this.persistenciaService.get("ngStorage-grupos_productos")) {
				this.grupos_productos=this.generarListaGruposSeleccionados(this.grupos_productos,this.persistenciaService.get("ngStorage-grupos_productos"));
			} else {
				for (var i = 0; i < grupos.length; i++) {
					this.grupos_productos[i].selected = true;
				}
			}

			
			//save checked list function
			this.listChecked = [];				
			
			this.saveCheckList();
			
			//checked or unchecked function	
			this.allChecked = false;							
			
    });
  }

  saveCheckList() {	
    //remove all list checked
    this.listChecked.splice(0, this.listChecked.length);
    for (var i = 0; i < this.grupos_productos.length; i++) {
      if (this.grupos_productos[i].selected == true) {
        this.listChecked.push(this.grupos_productos[i]);
      }
    }

    this.persistenciaService.set("ngStorage-grupos_productos",this.grupos_productos);
  }

  checkedUnchecked() {
    for (var i = 0; i < this.grupos_productos.length; i++) {
      this.grupos_productos[i].selected = this.allChecked;
    }
    this.saveCheckList();
  }
  
  generarListaGruposSeleccionados(gruposActualizado,gruposCache){
		var listaGruposSeleccionados=[];
		for(var i=0;i<gruposActualizado.length;i++){
			for(var j=0;j<gruposCache.length;j++){
				if(gruposActualizado[i].id==gruposCache[j].id){
					gruposActualizado[i].selected=gruposCache[j].selected;
				}
			}
		}
		return gruposActualizado;
  }
  
  async cargarProductosPanel(){
	  this.productos=[];
		this.productosProcesados=[];
    let id_sucursal=this.usuario.empresa.usar_productos_sucursal?this.pedido.sucursal.id:0;
		if(this.usuario.empresa.usar_inventario){
      let productos:any=await this.pedidosService.obtenerProductosPanelConInventarioActivado(this.usuario.id_empresa,id_sucursal,this.almacen.id).toPromise();
      for(var i=0;i<productos.length;i++){
        productos[i].activar_inventario=true;
      }
      this.productos=productos;
      this.productosProcesados=productos;
      let productosSinInventario:any=await this.pedidosService.obtenerProductosPanelSinInventarioActivado(this.usuario.id_empresa,id_sucursal).toPromise();
      for(var i=0;i<productosSinInventario.length;i++){
        productosSinInventario[i].activar_inventario=false;
      }
      this.productos=this.productos.concat(productosSinInventario);
      this.productosProcesados=this.productosProcesados.concat(productosSinInventario);
		}else{
			let productos:any=await this.productosService.obtenerProductosPanel(this.usuario.id_empresa).toPromise();
      this.productos=productos;
      this.productosProcesados=productos;
		}
    this.ordenarProductos(false);
  }
  
  establecerClientePedido(event){
    this.pedido.cliente=event.item?event.item:event;
    this.razon_busqueda=this.pedido.cliente;
  }
  
  async obtenerCombos(){
		let combos:any=await this.combosService.obtenerCombos(this.usuario.id_empresa).toPromise();
    this.combos=combos;
    this.combos=this.orderPipe.transform(this.combos, 'codigo',false);
    for(var i=0;i<this.combos.length;i++){
      var ids_productos="",combo=this.combos[i];
      for(var j=0;j<combo.detallesCombo.length;j++){
        if((j+1)==combo.detallesCombo.length){
          ids_productos=ids_productos+combo.detallesCombo[j].producto.id;
        }else{
          ids_productos=ids_productos+combo.detallesCombo[j].producto.id+",";
        }
      }
      await this.obtenerInventariosProductosCombo(ids_productos,combo);
    }
  }
  
  async obtenerInventariosProductosCombo(ids_productos,combo){
		let productos_inventario:any=await this.productosService.obtenerInventariosProductos(ids_productos,this.almacen.id).toPromise();
		combo.productos_inventario=productos_inventario;
  }
  
  mostrarProductos(){
		this.mostrar_productos=true;
  }
  
  async obtenerDatosProductoFinal(producto,id_almacen){
		let res:any=await this.pedidosService.obtenerProductoFinal(producto.id,id_almacen).toPromise();
		return res.productosBase;
  }
  
  ordenarProductos(orden){
		this.productosProcesados=this.orderPipe.transform(this.productos, this.configuracion_pagina.getOrden().orden_productos_panel,orden);
		this.ordenProductos=!orden;
		setTimeout(function(){
			//Util.aplicarSwiper(4,3,true,2);
		},5);
  }
  
  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClientePedido(this.usuario.id_empresa,(this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:0),0,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
    formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");
    
    sumarTotalImporte(){
      var sumaImporte=0;
      for(var i=0;i<this.pedido.detallesPedido.length;i++){
        if(!this.pedido.detallesPedido[i].eliminado){
          sumaImporte=sumaImporte+this.pedido.detallesPedido[i].importe;
        }
      }		
      this.pedido.importe=Math.round((sumaImporte)*1000)/1000;
    }
    
    sumarTotal(){
      var sumaTotal=0;
      for(var i=0;i<this.pedido.detallesPedido.length;i++){
        if(!this.pedido.detallesPedido[i].eliminado){
          sumaTotal=sumaTotal+this.pedido.detallesPedido[i].total;
        }
      }		
      this.pedido.total=Math.round((sumaTotal)*1000)/1000;
    }

    obtenerCantidadTotalInventarioProducto(producto){
      var cantidad_total=0;
      if(producto.activar_inventario && this.configuracion_pagina.getOrden().controlar_cantidades_panel){
        cantidad_total=producto.cantidad;
        //buscamos el producto y sus productos base en el pedido abierto
        if(this.pedido.detallesPedido){
          var detallesPedido=this.pedido.detallesPedido;
          cantidad_total=this.calcularCantidadPedidoActual(producto,cantidad_total,detallesPedido);
          //buscamos el producto en las otras mesas
          for(var i=0;this.base_datos_inventario && i<this.base_datos_inventario.mesas.length;i++){
            if(this.pedido.id!=this.base_datos_inventario.mesas[i].pedidosRestaurante[0].pedidoRestaurante.id){
              cantidad_total=this.calcularCantidadPedidoActual(producto,cantidad_total,this.base_datos_inventario.mesas[i].pedidosRestaurante[0].pedidoRestaurante.detallesPedido);
            }
          }
          //buscamos el producto en los pedidos sin mesa activos y no cobrados
          for(var i=0;this.base_datos_inventario && i<this.base_datos_inventario.pedidos.length;i++){
            if(this.pedido.id!=this.base_datos_inventario.pedidos[i].id){
              cantidad_total=this.calcularCantidadPedidoActual(producto,cantidad_total,this.base_datos_inventario.pedidos[i].detallesPedido);
            }
          }
        }
      }
      return (cantidad_total>=0?Math.round(cantidad_total*100)/100:0);
    }

    calcularCantidadPedidoActual(producto,cantidad_total,detallesPedido){
      let detalles_pedido_producto=detallesPedido.filter(d => d.producto && !d.eliminado && d.producto.id==producto.id);
      if(detalles_pedido_producto.length>0){
        cantidad_total=cantidad_total-detalles_pedido_producto[0].cantidad;
      }
      return cantidad_total;
    }

    cerrarComponente(){
      this.alTerminar.emit({confirm:false});
    }

    cambiarColor(color,buttonColor){
      // == save localstorage ====
      this.persistenciaService.set("color",{'style': color, 'stylebutton': buttonColor});
      this.color = {'style':color, 'stylebutton': buttonColor};
      // ==== fin ======
      
      $('#dialog-panel-ventas .widget-main').removeClass('red-style green-style skyblue-style brown-style');
      $('#dialog-panel-ventas .widget-main').addClass(color);
  
      $('#dialog-panel-ventas .widget-main .button-style').removeClass('red-style-button green-style-button skyblue-style-button brown-style-button');
      $('#dialog-panel-ventas .widget-main .button-style').addClass(buttonColor);
    }

    filtrarProductos(busqueda){
      this.productosProcesados=this.productos.filter(producto => (producto.nombre.toLowerCase().includes(busqueda.toLowerCase())) || (producto.descripcion && producto.descripcion.toLowerCase().includes(busqueda.toLowerCase())) || (producto.codigo && producto.codigo.toLowerCase().includes(busqueda.toLowerCase())));
      setTimeout(function(){
        //Util.aplicarSwiper(4,3,true,2);
      },5);
    }

    clasificarGrupo(grupo){
      if(grupo==0){
        this.productosProcesados=this.productos;
      }else{
        this.productosProcesados=this.productos.filter(producto => producto.id_grupo==grupo);
      }
      setTimeout(function(){
        //Util.aplicarSwiper(4,3,true,2);
      },5);
    }

    async agregarDetallePedido(producto,detallesPedido){
      this.blockUI.start();
      if(producto.tipo_producto_nombre_corto==GlobalVariable.Dictionary.TIPO_PRODUCTO_FINAL){
        let productosBase:any=await this.obtenerDatosProductoFinal(producto,this.almacen.id);
        producto.productosBase=productosBase;
      }
      let precios_datos:any=await this.productosService.obtenerPreciosProductos(producto.id).toPromise();
      let precios=[];
      if(this.usuario.empresa.usar_precios_productos_sucursal){
        precios=precios.concat(precios_datos.filter(p => p.id_sucursal==this.pedido.id_sucursal));
      }
      if(this.usuario.empresa.usar_precios_productos_clientes && this.pedido.cliente && this.pedido.cliente.id){
        precios=precios.concat(precios_datos.filter(p => p.id_cliente==this.pedido.cliente.id));
      }
      if(this.usuario.empresa.usar_precios_productos_usuarios){
        precios=precios.concat(precios_datos.filter(p => p.id_usuario==this.usuario.id));
      }
      if(precios.length==0){//Sin ninguno de los anteriores tiene precio
        precios=precios_datos.filter(p => (p.id_sucursal==null && p.id_cliente==null && p.id_usuario==null));
      }
      producto.precios=precios;

      var detallePedido;
      if(producto.activar_inventario && this.configuracion_pagina.getOrden().controlar_cantidades_panel){
        this.cantidadInventario=this.obtenerCantidadTotalInventarioProducto(producto);
        if(1<=this.cantidadInventario){
          detallePedido=this.buscarDetallePedido(producto,detallesPedido);
          if(detallePedido){//detallePedido Encontrado
            detallePedido.cantidad=detallePedido.cantidad+1;
          }else{//detallePedido no Encontrado
            detallePedido={producto:producto,precio_unitario:producto.precio_unitario,detallesPedido:[],
              inventario_disponible:this.cantidadInventario,costo_unitario:producto.costo_unitario,
              cantidad:1,descuento:0,tipo_descuento:false,activar_inventario:producto.activar_inventario};
            detallesPedido.push(detallePedido);
          }
        }else{
          this.toastr.error('¡Cantidad de inventario insuficiente, inventario disponible: '+this.cantidadInventario+'!');
          this.blockUI.stop();
          return 0;
        }
      }else{
        detallePedido=this.buscarDetallePedido(producto,detallesPedido);
        if(detallePedido){//detallePedido Encontrado
          detallePedido.cantidad=detallePedido.cantidad+1;
        }else{
          detallePedido={
            producto:producto,
            precio_unitario:producto.precio_unitario,detallesPedido:[],
            inventario_disponible:this.cantidadInventario,costo_unitario:producto.costo_unitario,
            cantidad:1,descuento:0,tipo_descuento:false,activar_inventario:producto.activar_inventario};
          detallesPedido.push(detallePedido);
        }
      }

      detallePedido.producto=producto;  
      detallePedido.activar_inventario=producto.activar_inventario;
      detallePedido.producto_precio=producto.precios.filter(e => e.por_defecto)[0];
      detallePedido.precio_unitario=detallePedido.producto_precio?detallePedido.producto_precio.precio_unitario:0;
      this.calcularImporteDetallePedido(detallePedido);
      this.sumarTotal();
      this.sumarTotalImporte();
      this.blockUI.stop();
    }

    buscarDetallePedido(producto,detallesPedido){
      var j=0,encontrado=false,detallePedido=null;
      while(j<detallesPedido.length && !encontrado){
        if(!detallesPedido[j].eliminado && detallesPedido[j].producto && (detallesPedido[j].producto.id==producto.id)){
          encontrado=true;
          detallePedido=detallesPedido[j];
        }
        j++;
      }
      return detallePedido;
    }

    calcularImporteDetallePedido(detallePedido){
      detallePedido.importe=Math.round((detallePedido.cantidad*detallePedido.precio_unitario)*1000)/1000;
      var descuento,recargo;
      if(detallePedido.tipo_descuento){
        descuento=detallePedido.importe*(detallePedido.descuento/100);
      }else{
        descuento=detallePedido.descuento;
      }
      detallePedido.total=detallePedido.importe-descuento;
      if(this.usuario.empresa.usar_obsequios && detallePedido.es_obsequio){
        detallePedido.importe=0;
        detallePedido.total=0;
      }
      this.sumarTotal();
      this.sumarTotalImporte();
    }

  showHideFirstRow(){

		if($(".first-row").css( "display")=="none"){
			$('.first-row').show( "slow" );
		}else{
			$( ".first-row" ).hide( 1000 );
		}
  }
  
  async reiniciarCliente(){
    this.pedido.cliente={};
    this.razon_busqueda=null;
    if(this.venta){
      this.venta.cliente={};
    }
  }
  
  abrirPopupRegistroRapidoCliente(){
    this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.pedido.cliente));
    this.modalCliente.componentInstance.sucursales = this.sucursales;
    this.modalCliente.componentInstance.sucursal = this.pedido.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerCliente(res.cliente);
					this.modalCliente.close();
				}
			}else{
				this.modalCliente.close();
			}
		});
  }

  modificarCantidad(){
		this.editar_cantidad=true;
  }
  
  establecerCantidad(){
		this.editar_cantidad=false;
  }
  
  verificarInventario(detallePedido){
		if(this.usuario.empresa.usar_inventario && detallePedido.producto && detallePedido.producto.activar_inventario){
			var producto=this.productos.filter(e => e.id == detallePedido.producto.id)[0];
			var cantidad_inventario=this.obtenerCantidadTotalInventarioProducto(producto);
			if(cantidad_inventario<0){
				detallePedido.cantidad=detallePedido.cantidad+cantidad_inventario;
				this.toastr.error('¡Cantidad de inventario insuficiente, inventario disponible: 0!');
			}else{
				this.calcularImporteDetallePedido(detallePedido);
			}
		}else{
			this.calcularImporteDetallePedido(detallePedido);
		}
  }
  
  disminuirDetallePedidoRestaurante(detallePedidoRestaurante){
		if(detallePedidoRestaurante.cantidad<=1){
			this.eliminarDetallePedidoRestaurante(detallePedidoRestaurante);
		}else{
			detallePedidoRestaurante.cantidad=detallePedidoRestaurante.cantidad-1;
			this.calcularImporteDetallePedido(detallePedidoRestaurante);
			this.sumarTotal();
			this.sumarTotalImporte();
		}
  }
  
  eliminarDetallePedidoRestaurante(detallePedidoRestaurante){
    if(detallePedidoRestaurante.id){
      detallePedidoRestaurante.eliminado=true;
    }else{
      this.pedido.detallesPedido.splice(this.pedido.detallesPedido.indexOf(detallePedidoRestaurante),1);
    }
		this.sumarTotal();
		this.sumarTotalImporte();
  }
  
  agregarDescripcionDetallePedido(detalleVenta){
    this.obtenerTiposDescripcionesDetallePedido();
    this.detallePedidoDescripcion=detalleVenta;
    this.descripcionitem=this.modalService.open(this.descripcionitemref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.descripcionitem.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  agregarDetallePedidoDescripcion(detalleVenta){
    //this.cerrarPopup(this.idModalDescripcionDetallePedido);
    this.descripcionitem.close();
  }

  mostrarCombos(){
		this.mostrar_productos=false;
  }
  
  modificarPrecio(){
		this.editar_precio=true;
  }
  
  establecerPrecio(){
		this.editar_precio=false;
  }
  
  establecerPrecioDetallePanel(detallePedido){
		if(detallePedido.producto){
			detallePedido.precio_unitario=detallePedido.producto_precio.precio_unitario;
		}else{
			detallePedido.precio_unitario=detallePedido.combo_precio.precio_unitario;
		}
		//detalleVenta.total=detalleVenta.precio_unitario*detalleVenta.cantidad;
		this.calcularImporteDetallePedido(detallePedido);
		this.sumarTotal();
		this.sumarTotalImporte();
  }
  
  obtenerCantidadTotalInventarioCombo(combo){
		if(combo.productos_inventario){
			var totalInventarioCombo=50000;
			for(var j=0;j<combo.detallesCombo.length;j++){
				var prodInv=$.grep(combo.productos_inventario, function(e){return e.id == combo.detallesCombo[j].producto.id;})[0];
				var totalInvProducto=this.obtenerInventarioTotal(prodInv);
				combo.detallesCombo[j].costos=prodInv.inventarios;
				var totalInventarioComboPorProducto=totalInvProducto/combo.detallesCombo[j].cantidad;
				totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
			}
			return totalInventarioCombo;
		}else{
			return 0;
		}
  }
  
  obtenerInventarioTotal(producto){
		var cantidadTotal = 0;
		if(this.pedido && this.pedido.detallesPedido){
			if(producto.activar_inventario){
				for(var i = 0; i < producto.inventarios.length; i++){
					cantidadTotal += (producto.inventarios[i].cantidad);
				}
				for(var j=0;j<this.pedido.detallesPedido.length;j++){
					if(this.pedido.detallesPedido[j].producto){//es producto
						if(this.pedido.detallesPedido[j].producto.id==producto.id){
							cantidadTotal=cantidadTotal-this.pedido.detallesPedido[j].cantidad;
						}
					}else{//es combo
						for(let k=0;k<this.pedido.detallesPedido[j].combo.detallesCombo.length;k++){
							if(this.pedido.detallesPedido[j].combo.detallesCombo[k].producto.id==producto.id){
								cantidadTotal=cantidadTotal-(this.pedido.detallesPedido[j].cantidad*this.pedido.detallesPedido[j].combo.detallesCombo[k].cantidad);
							}
						}
					}
				}
			}else{
				cantidadTotal=500000;
			}
		}
		return cantidadTotal;
  }
  
  agregarDetallePedidoPanelCombo(combo){
    var detallePedido;
    this.combosService.obtenerPreciosCombo(combo.id,this.pedido.id_sucursal).subscribe((precios:any)=>{
      combo.precios=precios;
			var totalInventarioCombo=50000;
			for(var j=0;j<combo.detallesCombo.length;j++){
				var prodInv=combo.productos_inventario.filter(e => e.id == combo.detallesCombo[j].producto.id)[0];
				var totalInvProducto=this.obtenerInventarioTotal(prodInv);

				combo.detallesCombo[j].costos=prodInv.inventarios;
				var totalInventarioComboPorProducto=(totalInvProducto/combo.detallesCombo[j].cantidad);
				totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
			}

			detallePedido=this.pedido.detallesPedido.filter(e => e.combo && e.combo.id==combo.id);
			if(detallePedido.length>0){
				detallePedido=detallePedido[0];
				if((detallePedido.cantidad+1)<=totalInventarioCombo){
					detallePedido.cantidad=detallePedido.cantidad+1;
				}else{
					this.toastr.error('¡Cantidad de inventario insuficiente, inventario disponible: '+totalInventarioCombo+'!');
				}
				this.calcularImporteDetallePedido(detallePedido);
			}else{
				detallePedido={tipo_detalle:false,combo:combo,precio_unitario:combo.precio_unitario,
					inventario_disponible:totalInventarioCombo,
					cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false};

				if(1<=totalInventarioCombo){
					if(this.usuario.empresa.usar_precios_productos_sucursal){
						var precio=combo.precios.filter(e => e.por_defecto && e.id_sucursal === this.pedido.id_sucursal);
						if(precio.length>0){
							detallePedido.combo_precio=precio[0];
							detallePedido.precio_unitario=precio[0].precio_unitario;
						}
					}else{
						detallePedido.combo_precio=combo.precios.filter(e => e.por_defecto)[0];
					}
					
					this.calcularImporteDetallePedido(detallePedido);
					this.pedido.detallesPedido.push(detallePedido);
				}else{
					this.toastr.error('¡Cantidad de inventario insuficiente, inventario disponible: '+totalInventarioCombo+'!');
				}
			}

			this.sumarTotal();
			this.sumarTotalImporte();
    });
  }
  
  convertirObsequio(detalle_pedido){
		this.detalle_pedido_obsequio=detalle_pedido;
		this.obsequioitem=this.modalService.open(this.obsequioitemref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.obsequioitem.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  convertirTodoObsequio(){
    for(let i=0;i<this.pedido.detallesPedido.length;i++){
      this.pedido.detallesPedido[i].es_obsequio=!this.pedido.detallesPedido[i].es_obsequio;
      this.calcularImporteDetallePedido(this.pedido.detallesPedido[i]);
    }
    this.sumarTotal();
    this.sumarTotalImporte();
  }
  
  calcularCambioObsequio(){
		this.calcularImporteDetallePedido(this.detalle_pedido_obsequio);
  }
  
  generarCuenta(){
    if(this.validarPedido() && this.validarDetallesPedido()){
      var alto,pedidoRestaurante=this.pedido;
      if (pedidoRestaurante.detallesPedido.length <= 2) {
        alto = 270;
      } else {
        alto = 270 + (20 * (pedidoRestaurante.detallesPedido.length - 2))
      }
      var doc=new PDFDocument({size:[227,alto],margins:{top:10,bottom:10,left:20,right:20}})
      var stream = doc.pipe(blobStream());
      doc.font('Helvetica-Bold', 14);
      doc.text("Nro. Pedido : " + (pedidoRestaurante.numero_pedido?pedidoRestaurante.numero_pedido:"") + (pedidoRestaurante.venta?" - V:"+pedidoRestaurante.venta.factura:""), { align: 'left' });
      doc.font('Helvetica', 8);
      doc.text("CLIENTE : "+ (pedidoRestaurante.cliente.identificacion?pedidoRestaurante.cliente.identificacion:pedidoRestaurante.cliente.razon_social), { align: 'left' });
      doc.moveDown(0.8);
      var mesas="";
      if(pedidoRestaurante.mesas){
        for(var i=0;i<pedidoRestaurante.mesas.length;i++){
          if(i+1==pedidoRestaurante.mesas.length){
            mesas=mesas+" "+pedidoRestaurante.mesas[i].numero;
          }else{
            mesas=mesas+" "+pedidoRestaurante.mesas[i].numero+",";
          }
        }
      }else{
        for(var i=0;i<pedidoRestaurante.mesasPedidoRestaurante.length;i++){
          if(i+1==pedidoRestaurante.mesasPedidoRestaurante.length){
            mesas=mesas+" "+pedidoRestaurante.mesasPedidoRestaurante[i].mesa.numero;
          }else{
            mesas=mesas+" "+pedidoRestaurante.mesasPedidoRestaurante[i].mesa.numero+",";
          }
        }
      }
      doc.text("MESAS: " +mesas,{align:'center'});
      doc.font('Helvetica',10);
      doc.moveDown(0.4);
      doc.text("Cant.  Consumo                P/U           total",{align:'left'});
      doc.moveDown(0.4);
      var y=doc.y,sumaDescuento=0,sumaRecargo=0,sumaIce=0,sumaExcento=0;
      for(var i=0;i<pedidoRestaurante.detallesPedido.length;i++){
        if(!pedidoRestaurante.detallesPedido[i].eliminado){
          doc.font('Helvetica',12);
          doc.text(pedidoRestaurante.detallesPedido[i].cantidad,20,y);
          doc.fontSize(8);
          /*if (pedidoRestaurante.detallesPedido[i].producto.nombre.length > 20) {
            doc.fontSize(6);
          }*/
          doc.text(pedidoRestaurante.detallesPedido[i].producto.nombre.toUpperCase(),30,y,{width:100});
          //doc.fontSize(7);
          doc.text(pedidoRestaurante.detallesPedido[i].precio_unitario.toFixed(2),140,y);
          doc.text(pedidoRestaurante.detallesPedido[i].precio_unitario*pedidoRestaurante.detallesPedido[i].cantidad,180,y);
          y=y+20;
        }
      }
      doc.x=20;
      doc.font('Helvetica',10);
      doc.moveDown(1);
      doc.text("Total Transacción:                         "+pedidoRestaurante.total.toFixed(2),{align:'left'});
      doc.moveDown(1);
      doc.text("Por favor llene sus datos para la emision de factura:",{align:'left'});
      doc.moveDown(0.4);
      doc.text("Razon social:.........................................",{align:'left'});
      doc.moveDown(0.4);
      doc.text("...............................................................",{align:'left'});
      doc.moveDown(0.4);
      doc.text("Nit:.........................................................",{align:'left'});
      
      doc.moveDown(2);
      var fechaActual = new Date();
      let min:any = fechaActual.getMinutes();

      if(min<10){
        min = "0"+min;
      }
      doc.text(" Fecha : "+ fechaActual.getDate()+"/"+(fechaActual.getMonth()+1)+"/"+fechaActual.getFullYear()+"  "+fechaActual.getHours()+":"+min+"  ",{align:'center'});
      doc.end();
      stream.on('finish', function() {
        var fileURL = stream.toBlobURL('application/pdf');
        var w=window.open(fileURL,'_blank','location=no');
        setTimeout(function(){
          w.print();
        },500);
      });
    }
  }
  
  validarPedido(){
    let res=true;
    if(!this.razon_busqueda || !this.pedido.cliente){
      res=res && false;
      this.toastr.error("Debe especificar el cliente!");
    }
    if(this.usuario.empresa.usar_clientes_contactos){
      if(!this.pedido.contacto_cliente){
        res=res && false;
        this.toastr.error("Debe especificar el contacto del cliente!");
      }
      if(!this.pedido.fechaDespachoTexto || !this.pedido.fechaDespachoTexto.year || !this.pedido.fechaDespachoTexto.month || !this.pedido.fechaDespachoTexto.day){
        res=res && false;
        this.toastr.error("Debe especificar la fecha de entrega del pedido!");
      }
    }
    if(this.pedido.detallesPedido.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item al pedido!");
    }
    return res;
  }

  guardarPedidoRestaurante() {
    this.blockUI.start();
		if(this.validarPedido() && this.validarDetallesPedido()){
      this.pedido.cliente.identificacion=(!this.pedido.cliente.id || !this.pedido.id_cliente)?(this.razon_busqueda.razon_social?this.razon_busqueda.identificacion:this.razon_busqueda):this.pedido.cliente.identificacion;
      this.pedido.cliente.id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:null;
			this.pedido.tiempo_salida=this.pedido.fechaDespachoTexto?Util.convertirObjetoAfecha(this.pedido.fechaDespachoTexto):null;
			this.pedido.tiempo_despacho=this.pedido.tiempo_salida;
      let liberar_mesa=false;
      this.pedido.usar_impresion_nativa=this.usuario.empresa.usar_impresion_nativa;
      this.pedido.usar_clientes_contactos=this.usuario.empresa.usar_clientes_contactos;
			if(this.pedido.id){
        this.pedidosService.actualizarPedidoRestaurante(this.pedido).subscribe((res:any)=>{
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.stack.substring(0,500));
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            if(res.venta){
              liberar_mesa=this.pedido.venta.liberar_mesa;
              //this.imprimirVenta(venta);
              if(this.usuario.empresa.punto_venta_offline){
                this.ventasService.sincronizarVenta({id_empresa:this.usuario.id_empresa,id_venta:res.venta.id}).subscribe((res:any)=>{

                });
              }
            }
            this.alTerminar.emit({confirm:true,res:res,liberar_mesa:liberar_mesa,pedido:this.pedido,venta:res.venta});
          }
        });
			}else{
        var mesas=this.pedido.mesas;
        this.pedidosService.guardarPedido(this.pedido).subscribe((resPc:any)=>{
          if(resPc.tiene_error){
            this.blockUI.stop();
            this.toastr.error(resPc.stack.substring(0,500));
            this.toastr.error(resPc.mensaje);
          }else{
            if(this.usuario.empresa.usar_pantalla_despacho){
              //socket.emit('pedidoGuardado',resPc.pedido_creado);
            }
            for(var i=0;i<mesas.length;i++){
              mesas[i].estado=resPc.estado;
            }
            this.blockUI.stop();
            this.alTerminar.emit({confirm:true,res:resPc,pedido:resPc.pedido_creado,venta:resPc.venta});
            this.toastr.success(resPc.mensaje);
            //this.obtenerPedidosSucursal(this.filtro.sucursal.id);
            if(resPc.venta){
              //this.imprimirVenta(venta);
              if(this.usuario.empresa.punto_venta_offline){
                this.ventasService.sincronizarVenta({id_empresa:this.usuario.id_empresa,id_venta:resPc.venta.id}).subscribe((res:any)=>{
  
                });
              }
            }else{
              this.imprimirPedido(resPc.pedido_creado);
            }
          }
        });
			}
		}else{
      this.blockUI.stop();
    }
  }
  
  async imprimirPedido(pedidoRestaurante){
    this.pdfService.imprimirPedido(pedidoRestaurante);
    /*this.blockUI.start();
    let pedido=pedidoRestaurante;
    if(pedido.id){
    }else{
      pedido.id=0;
      pedido.detallesPedidoSeleccionados=this.selectedItems.length>0?this.selectedItems:pedido.detallesPedido;
    }
    pedido.usar_impresion_nativa=this.usuario.empresa.usar_impresion_nativa;
    pedido.usar_clientes_contactos=this.usuario.empresa.usar_clientes_contactos;
    let pdf=await this.pedidosService.obtenerComandaPedido(pedido).toPromise();
    var w = window.open(window.URL.createObjectURL(pdf), '_blank', 'location=no');
    this.blockUI.stop();
    setTimeout(function () {
      w.print();
    }, 500);
*/
  /*
    let pedido:any;
    if(pedidoRestaurante.id){
      pedido=await this.pedidosService.obtenerPedidoRestaurante(pedidoRestaurante.id).toPromise();
    }else{
      pedido=pedidoRestaurante;
    }
    pedido.detallesPedidoSeleccionados=this.selectedItems.length>0?this.selectedItems:pedido.detallesPedido;
    var alto;
    if (pedido.detallesPedidoSeleccionados.length <= 2) {
      alto = GlobalVariable.Dictionary.LONGITUD_BASE_IMPRESION_PEDIDO;
    } else {
      alto = GlobalVariable.Dictionary.LONGITUD_BASE_IMPRESION_PEDIDO + (20 * (pedido.detallesPedidoSeleccionados.length - 2))
    }
    var papel = [227, alto];
    var doc = new PDFDocument({ size: papel, margins: { top: 0, bottom: 0, left: 20, right: 20 } });
    var stream = doc.pipe(blobStream());
    doc.y = 40;
    //doc.addPage({ size: [227, alto], margins: { top: 10, bottom: 10, left: 20, right: 20 } });
    this.pdfService.imprimirPedido(pedido, doc, stream, alto);

    doc.end();
    await new Promise(function (resolve, reject) {
      stream.on('finish', function () {
        var fileURL = stream.toBlobURL('application/pdf');
        var w = window.open(fileURL, '_blank', 'location=no');
        setTimeout(function () {
          w.print();
        }, 500);
        resolve(stream.toBlob());
      });
      stream.on('error', reject)
    });*/
  }
  
  async abrirPopupDatosFacturacion(){
    this.blockUI.start();
    await this.obtenerMovimientosEgreso();
    await this.obtenerTiposPago();
		if(!this.pedido.id){
			if(!this.pedido.cliente.id){
				this.pedido.cliente.razon_social=this.pedido.cliente.identificacion?this.pedido.cliente.identificacion:this.razon_busqueda;
				this.pedido.cliente.nit=0;
			}
		}
    await this.obtenerCanales();
		this.venta={
      canal:this.pedido.publico?this.canales.filter(e => e.nombre_corto == this.global_variable.Dictionary.CANAL_VENTA_ECOMERCE)[0]:this.canales[0],
      sin_documento:null,sin_en_linea:true,cliente:this.pedido.id?(JSON.parse(JSON.stringify(this.pedido.cliente))):this.pedido.cliente,pagado:0};
    this.venta.cliente.id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:null;
		this.venta.liberar_mesa=true;
    this.venta.usar_facturacion_computarizada_en_linea_por_sucursal_empresa=this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa;
		this.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
		this.venta.importe=this.pedido.importe;
		this.venta.descuento=0;
		this.descuento_maximo=this.venta.importe;
		this.pedido.total=this.pedido.importe;
    this.venta.total=this.pedido.importe;
		this.venta.pagado=this.pedido.importe;
		this.venta.cambio=0;
		this.movimientosEgresoPanel=this.movimientos_egreso;
		this.venta.transaccion=this.movimientosEgresoPanel.filter(t => t.nombre_corto==this.configuracion_aplicacion_ventas.datos.opciones_permisos.transaccion_por_defecto)[0];
		this.tiposPago=this.tiposPago.filter(e => e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_CONT || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_TARJ || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_TRANS || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_CODQR || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_ENLINEA);
		this.venta.tipoPago=this.tiposPago.filter(e => e.nombre == GlobalVariable.Dictionary.TIPO_PAGO_CONTADO)[0];
		this.venta.fecha=new Date();
    this.venta.id_tipo_despacho=this.pedido.tipoDespacho.id;
		this.establecerTransaccion();
    this.venta.fechaTexto=this.venta.fecha.getDate()+"/"+(this.venta.fecha.getMonth()+1)+"/"+this.venta.fecha.getFullYear();
		//this.buscarNit({which:13},"0");
		this.establecerCliente(this.venta.cliente);
    this.calcularCambio();
    this.blockUI.stop();
		this.ventamodal=this.modalService.open(this.ventamodalref, {windowClass:'datos-venta',size:"sm", ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.ventamodal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  async obtenerMovimientosEgreso(){
    let entidad:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
    let moingorg=entidad.clases;
    this.movimientos_egreso=moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
    if(this.usuario.empresa.usar_facturacion){
      this.movimientos_egreso=this.movimientos_egreso.concat(moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
    }
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      this.movimientos_egreso=this.movimientos_egreso.concat(moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA));
    }
  }	

  async establecerTransaccion(){
    await this.obtenerActividades();
    await this.obtenerDocumentosFacturacion();
    await this.obtenerSucursalFacturacion();
  }

  async obtenerDocumentosFacturacion(){
    this.documentos_sector=[];
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      let documentos:any;
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa){
        documentos=await this.empresaService.obtenerDocumentosSucursalSin(this.pedido.sucursal.id).toPromise();
      }else{
        documentos=await this.empresaService.obtenerDocumentosSin(this.usuario.id_empresa).toPromise();
      }
      this.documentos_sector=documentos;
      this.venta.sin_documento=this.documentos_sector.length>=1?this.documentos_sector[0]:null;
    }else{
      this.venta.sin_documento=null;
    }
  }

  async obtenerSucursalFacturacion(){
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_empresa){
        let sucursal_facturacion:any=await this.empresaService.obtenerSucrsalFacturacionSin(this.usuario.id_empresa).toPromise();
        this.venta.sucursal_facturacion=sucursal_facturacion;
      }
    }
  }

  async obtenerActividades(){
    this.actividades=[];
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && this.sucursal.actividadesDosificaciones.length>0){
      let actividadesDosificaciones=this.sucursal.actividadesDosificaciones?this.sucursal.actividadesDosificaciones:[];
      for(let i=0;i<actividadesDosificaciones.length;i++){
        this.actividades.push(actividadesDosificaciones[i].actividad);
      }
      this.venta.actividad=this.actividades.length==1?this.actividades[0]:null;
    }else if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      let actividades:any=await this.empresaService.obtenerActividadesSin(this.usuario.id_empresa).toPromise();
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa){
        actividades=actividades.filter(act => act.id_sucursal==this.pedido.sucursal.id);
      }
      for(let i=0;i<actividades.length;i++){
        this.actividades.push(actividades[i].actividad);
      }
      this.venta.actividad=this.actividades.length>=1?this.actividades[0]:null;
      if(this.venta.actividad){
        this.establecerActividad(this.venta.actividad);
      }
    }else{
      this.venta.actividad=null;
    }
  }

  establecerActividad(actividad){
    this.obtenerLeyendasFactura(actividad.nombre_corto.split("-")[0]);
  }

  obtenerLeyendasFactura(codigo_actividad){
    this.ventasService.obtenerLeyendas(codigo_actividad).subscribe((res:any)=>{
      this.sin_leyendas=res?res:[];
      this.establecerLeyendaFactura();
    });
  }

  establecerLeyendaFactura(){
    if(this.sin_leyendas.length>0){
      this.venta.leyenda=this.sin_leyendas[Math.floor(Math.random() * (this.sin_leyendas.length - 0)) + 0];
    }
  }

  async obtenerTiposPago(){
    let entidad:any=await this.generalService.obtenerClases("TIPA").toPromise();
	  this.tiposPago=entidad.clases;
  }	

  establecerCliente(cliente){
    this.pedido.cliente=cliente;
    this.razon_busqueda=cliente;
    if(this.venta){
      this.venta.cliente=cliente;
      this.venta.razon_social=cliente.razon_social;
      this.venta.nit_ci=cliente.nit;
    }
		Util.enfocarElemento('razon_social');
  }
  
  calcularCambio(){
		this.venta.cambio=Math.round((this.venta.pagado-this.pedido.total)*100)/100;
		this.pagoMinimo=this.pedido.total;
  }
  
  calcularTotalVenta(){
		this.pedido.descuento=this.venta.descuento;
		this.pedido.total=this.pedido.importe-this.pedido.descuento;
		this.venta.total=this.venta.importe-this.venta.descuento;
    this.venta.pagado=this.venta.total;
		this.calcularCambio();
    this.venta.usar_descuento_global=true;
  }
  
  buscarNit(evento,nit){
    if (evento.which === 13){
      let id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.venta.sucursal.id:0;

      this.clientesService.buscarClienteNit(this.usuario.id_empresa,nit,id_sucursal).subscribe((results:any[]) => {
        if(results.length==1  || results.length>1){
          this.establecerCliente(results[0]);
          Util.enfocarElemento("razon_social");
				}else{
          this.establecerCliente({nit:nit,razon_social:null});
					Util.enfocarElemento("razon_social");
				}
      });
    }
  }

  validarPagoPedido(){
    let res=true;
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea && this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      if(!this.venta.cliente.tipo_documento){
        res=res && false;
        this.toastr.error("Debe especificar el tipo de documento del cliente!");
      }
    }
    if((this.venta.cliente.nit==undefined || this.venta.cliente.nit==null) || !this.venta.cliente.razon_social){
      res=res && false;
      this.toastr.error("Debe especificar datos del cliente, nit y razon social!");
    }
    if(this.venta.descuento==null || this.venta.descuento==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el descuento!");
    }
    if(this.venta.pagado==null || this.venta.pagado==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el dato de pagado!");
    }
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && this.venta.sucursal.actividadesDosificaciones){
      let fecha_limite_emision=new Date(this.venta.sucursal.actividadesDosificaciones[0].dosificacion.fecha_limite_emision);
      fecha_limite_emision.setHours(23);
      fecha_limite_emision.setMinutes(59);
      fecha_limite_emision.setSeconds(59);
      let fecha_actual=new Date();
      if(fecha_limite_emision<fecha_actual){
        res=res && false;
        this.toastr.error("La fecha límite de emisión "+(fecha_limite_emision.getDate()+"/"+(fecha_limite_emision.getMonth()+1)+"/"+fecha_limite_emision.getFullYear())+" de su dosificación feneció, debe renovar!");
      }
    }
    if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.venta.total || this.venta.cambio<0)){
      res=res && false;
      this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_TARJ && this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA){
      if(!this.venta.numero_tarjeta){
        res=res && false;
        this.toastr.error("Debe especificar el numero de la tarjeta!");
      }
    }
    return res;
  }

  async facturarPedidoRestaurante() {
    this.blockUI.start();
    this.venta.sucursal={id:this.sucursal.id,actividadesDosificaciones:this.sucursal.actividadesDosificaciones,sin_clave_firma_digital:this.sucursal.sin_clave_firma_digital};
    if(this.validarPagoPedido() && this.validarDetallesPedido()){
      this.venta.total=this.pedido.total;
      this.venta.importe=this.pedido.importe;
      this.venta.usuario={id:this.usuario.id,id_empresa:this.usuario.id_empresa,nombre_usuario:this.usuario.nombre_usuario};
      this.venta.id_empresa=this.usuario.empresa.id;
      this.venta.detallesVentaNoConsolidadas=[];
      this.venta.almacen=this.almacen;
      this.venta.detallesVenta=[];
      this.venta.razon_social=this.venta.cliente.razon_social;
      this.venta.cliente.nit=this.venta.cliente.nit.toString().trim();
      this.venta.nit_ci=this.venta.cliente.nit;
      this.venta.observacion=this.pedido.nombre;
      this.venta.detallesVenta=this.pedido.detallesPedido;
      this.venta.a_cuenta=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.a_cuenta:this.venta.total;
      this.venta.saldo=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.saldo:0;
      this.venta.id_contacto_cliente=this.pedido.contacto_cliente?this.pedido.contacto_cliente.id:null;
      var liberar_mesa=this.venta.liberar_mesa;
      this.pedido.venta=this.venta;
      if(this.pedido.id){
        this.pedidosService.inactivarPedidoMesa(this.pedido).subscribe((resInact:any)=>{
          this.guardarPedidoRestaurante();
          this.blockUI.stop();
          this.ventamodal.close();
        });
      }else{
        this.pedido.pedido_activo=false;
        this.guardarPedidoRestaurante();
        this.blockUI.stop();
        this.ventamodal.close();
      }
      //ImprimirSalida(movimiento,res,true,this.usuario);
      //this.imprimirVenta(res.venta);
    }else{
      this.blockUI.stop();
    }
  }

  cambiarLiberarMesa(liberacion){
		this.venta.liberar_mesa=liberacion;
	}

  actualizarIdentificacion(razon_social){
    this.venta.cliente.identificacion=razon_social;
  }

  async establecerSucursal(sucursal){
    
  }

  async obtenerAlmacenesActividades(sucursal){
    this.sucursal=sucursal;
    this.pedido.sucursal={id:sucursal.id,nombre:sucursal.nombre};
    this.pedido.id_sucursal=sucursal.id;
    this.almacen=this.sucursal.almacenes.length>0?this.sucursal.almacenes[0]:null;
    await this.obtenerAlmacenes(sucursal.id);
    await this.cambiarAlmacen(this.almacen);
    if(this.usuario.empresa.usar_inventario && this.configuracion_pagina.getOrden().controlar_cantidades_pedidos_registrados){
			await this.obtenerDatosCalculoInventario();
		}
    //await this.reiniciarCliente();
  }

  async obtenerAlmacenes(idSucursal){
    let datos_sucursal:any=await this.empresaService.obtenerSucursal(idSucursal).toPromise();
    this.almacenes=datos_sucursal.almacenes;
    if(this.usuario.empresa.usar_almacenes_usuario){
      let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
      this.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes.filter(a => a.id_sucursal==idSucursal);
    }
    this.almacen=(this.id_almacen_seleccionado?this.almacenes.filter(a => a.id==this.id_almacen_seleccionado)[0]:(this.almacenes.length>0?this.almacenes[0]:null));	
  }

  async cambiarAlmacen(almacen){
    this.almacen=almacen;
    await this.cargarProductosPanel();
    if(this.usuario.empresa.usar_combos){
			await this.obtenerCombos();
		}
  }

  async obtenerCanales(){
    this.blockUI.start();
    let conf:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise(); 
    let configuracion_aplicacion_ventas=conf;
    let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
    this.canales=entidad.clases;
    if(this.usuario.empresa.usar_permisos_avanzados_usuarios && configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados && configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados.length>0){
      let canales=[];
      for(let i=0;i<configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados.length;i++){
        canales.push(entidad.clases.filter(cnf => cnf.id==configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados[i])[0]);
      }
      this.canales=canales;
    }
    this.blockUI.stop();
  }

  validarDetallesPedido(){
    let res=true;
    for(let i=0;i<this.pedido.detallesPedido.length;i++){
      if(!this.pedido.detallesPedido[i].eliminado){
        if(!this.pedido.detallesPedido[i].precio_unitario){
          res=res && false;
          this.toastr.error("Debe especificar el precio unitario para el producto: "+this.pedido.detallesPedido[i].producto.nombre);
        }
        if(!this.pedido.detallesPedido[i].cantidad){
          res=res && false;
          this.toastr.error("Debe especificar la cantidad para el producto: "+this.pedido.detallesPedido[i].producto.nombre);
        }
      }
    }
    return res;
  }

}
