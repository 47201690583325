import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  descargarFormatoProdcutosExcel(excelData,workbook_name,sheet_name){
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheet_name);
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    data.forEach(d => {
      let row = worksheet.addRow(d);

      /*let sales = row.getCell(6);
      let color = 'FF99FF99';
      /*if (+sales.value < 200000) {
        color = 'FF9999'
      }

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 30;
    const dobCol5 = worksheet.getColumn(5);
    dobCol5.width = 30;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, workbook_name + '.xlsx');
    })

  }

  descargarFormatoVecinosExcel(excelData,workbook_name,sheet_name){
    const title = excelData.title;
    const header1 = excelData.headers1;
    const header2 = excelData.headers2;
    const data = excelData.data;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheet_name);
    let header1Row = worksheet.addRow(header1);
    header1Row.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    })
    let header2Row = worksheet.addRow(header2);
    header2Row.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' },
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    worksheet.mergeCells('A1:L1');
    worksheet.mergeCells('M1:S1');

    data.forEach(d => {
      let row = worksheet.addRow(d);

      /*let sales = row.getCell(6);
      let color = 'FF99FF99';
      /*if (+sales.value < 200000) {
        color = 'FF9999'
      }

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 30;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 30;
    const dobCol4 = worksheet.getColumn(4);
    dobCol4.width = 30;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, workbook_name + '.xlsx');
    })

  }

  descargarFormatoLecturacionExcel(excelData, workbook_name, sheet_name){
    const title = excelData.title;
    const header1 = excelData.headers;
    const data = excelData.data;
    
    let workbook = new Workbook();
    let worksheet:any = workbook.addWorksheet(sheet_name);
    let header1Row = worksheet.addRow(header1);

    header1Row.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    data.forEach(d => {
      let row = worksheet.addRow(d);

      /*let sales = row.getCell(6);
      let color = 'FF99FF99';
      /*if (+sales.value < 200000) {
        color = 'FF9999'
      }

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
      }
    );

    const dobCol1 = worksheet.getColumn(1);
    dobCol1.width = 25;
    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 45;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;
    const dobCol4 = worksheet.getColumn(4);
    dobCol4.width = 15;
    const dobCol5 = worksheet.getColumn(5);
    dobCol5.width = 35;

    /*worksheet.getCell('A1').protection = {
      locked: true,
      hidden: true,
    };*/

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, workbook_name + '.xlsx');
    })

  }

  descargarReporteExcel(excelData, workbook_name, sheet_name, clientes){
    const title = excelData.title;
    const header1 = excelData.headers;
    const data = excelData.data;
    let workbook = new Workbook();
    let worksheet:any = workbook.addWorksheet(sheet_name);
    
    clientes.forEach(d => {
      let row = worksheet.addRow(['Nombre de cliente','Total','Devoluciones', 'A cuenta', 'Saldo']);
      row.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4c2882' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      row = worksheet.addRow([d['identificacion'], d['total'],d['devoluciones'],d['a_cuenta'], d['saldo']]);

      row = worksheet.addRow(['Item', 'Tipo de item', 'Vendidos', 'Obsequios', 'Devoluciones']);
      row.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      for(var i=0; i<d['items'].length; i++){
        var nom_item;
				var tipo_item;
				var obsequios;
				var devoluciones;
				if(d['items'][i]['combo']!=null){
					nom_item = d['items'][i]['combo'];
					tipo_item = 'Combo';
					obsequios = d['items'][i]['obsequios_combos'];
					devoluciones = d['items'][i]['devoluciones_combos'];
				}else{
					nom_item = d['items'][i]['producto'];
					tipo_item = 'Producto';
					obsequios = d['items'][i]['obsequios_productos'];
					devoluciones = d['items'][i]['devoluciones'];
				}
        row = worksheet.addRow([nom_item, tipo_item, d['items'][i]['vendidos'], obsequios, devoluciones]);  
      }
      row = worksheet.addRow(['']);
      

      /*let sales = row.getCell(6);
      let color = 'FF99FF99';
      /*if (+sales.value < 200000) {
        color = 'FF9999'
      }

      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
    }
    );

    const dobCol1 = worksheet.getColumn(1);
    dobCol1.width = 45;
    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 20;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 15;
    const dobCol4 = worksheet.getColumn(4);
    dobCol4.width = 15;
    const dobCol5 = worksheet.getColumn(5);
    dobCol5.width = 15;

    /*worksheet.getCell('A1').protection = {
      locked: true,
      hidden: true,
    };*/

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, workbook_name + '.xlsx');
    })

  }

  descargarReporteComboExcel(excelData, workbook_name, sheet_name, combo, conceptos_precios){
    const title = excelData.title;

    let workbook = new Workbook();
    let worksheet:any = workbook.addWorksheet(sheet_name);
    
    let row;
    var materiales = [];
    var mano_de_obra = [];

    row = worksheet.addRow([]);
    row = worksheet.addRow([ 'Código: ', combo['codigo'], '', 'Nombre: ', combo['nombre']]);
    row = worksheet.addRow([ 'Unid. Medid.: ', combo['unidad_medida'], '', 'Descripción: ', combo['descripcion']]);
    row = worksheet.addRow([ 'Grupo: ', combo['grupo']['nombre']]);
    row = worksheet.addRow(['']);
    row = worksheet.addRow(['']);
    
    row = worksheet.addRow(['', '', 'Composición', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow(['Producto',	'Und. Med.',	'P. U.',	'Cantidad']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    

    combo['detallesCombo'].forEach(d => {
      if(d['producto']['grupo']['nombre'] == 'MATERIALES'){
        materiales.push(d);
      }
      if(d['producto']['grupo']['nombre'] == 'MANO DE OBRA'){
        mano_de_obra.push(d);
      }
      row = worksheet.addRow([d['producto']['nombre'], d['producto']['unidad_medida'], d['producto']['precio_unitario'], d['cantidad'] ]);  
    });

    row = worksheet.addRow(['', '', 'Materiales', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow(['Producto',	'Und. Med.',	'P. U.',	'Cantidad', 'Total']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    materiales.forEach(d => {
      row = worksheet.addRow([d['producto']['nombre'], d['producto']['unidad_medida'], d['producto']['precio_unitario'], d['cantidad'], (d['producto']['precio_unitario'] * d['cantidad'])]);
    });

    row = worksheet.addRow(['', '', '', 'Total Material', conceptos_precios['total_material']]);


    row = worksheet.addRow(['' , '', 'Mano de obra', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow(['Producto',	'Und. Med.',	'P. U.',	'Cantidad', 'Total']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    mano_de_obra.forEach(d => {
      row = worksheet.addRow([d['producto']['nombre'], d['producto']['unidad_medida'], d['producto']['precio_unitario'], d['cantidad'], (d['producto']['precio_unitario'] * d['cantidad'])]);
    });
    row = worksheet.addRow(['', '', '', 'Total Mano de Obra Inicial', conceptos_precios['total_mano_obra']]);
    row = worksheet.addRow([conceptos_precios['beneficios']['nombre'], '', '', conceptos_precios['beneficios']['nombre_corto'], conceptos_precios['total_beneficios_sociales']]);
    row = worksheet.addRow([conceptos_precios['iva']['nombre'], '', '', conceptos_precios['iva']['nombre_corto'], conceptos_precios['total_iva']]);
    row = worksheet.addRow(['', '', '', 'Total Mano de Obra', conceptos_precios['total_mano_obra_beneficios']]);


    row = worksheet.addRow(['EQUIPO Y MAQUINARIA' , '', '', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow([  'HERRAMIENTAS - % DE LA MANO DE OBRA', '', '', conceptos_precios['equipo_maquinaria']['nombre_corto'], '0'  ]);
    row = worksheet.addRow(['', '', '', 'Total Maquinaria y Equipo', conceptos_precios['total_maquinaria_equipo']]);



    row = worksheet.addRow(['GASTOS GENERALES Y ADMINISTRATIVOS' , '', '', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow([  'GASTOS GENERALES - % DE 1+2+3', '', '', conceptos_precios['gastos_generales']['nombre_corto'], '0'  ]);
    row = worksheet.addRow(['', '', '', 'Total Material', conceptos_precios['total_gastos_generales']]);




    row = worksheet.addRow(['UTILIDAD' , '', '', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow([  'UTILIDAD - % DE 1+2+3+4', '', '', conceptos_precios['utilidad']['nombre_corto'], '0'  ]);
    row = worksheet.addRow(['', '', '', 'Total Utilidad', conceptos_precios['total_utilidad']]);


    row = worksheet.addRow(['IMPUESTOS' , '', '', '', '']);
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4c2882' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    row = worksheet.addRow([  '	IMPUESTO A LAS TRANSACCIONES - % DE 1+2+3+4+5', '', '', conceptos_precios['impuestos']['nombre_corto'], '0'  ]);
    row = worksheet.addRow(['', '', '', 'Total Impuestos', conceptos_precios['total_impuestos']]);

    
    row = worksheet.addRow([]);
    row = worksheet.addRow(['', '', '', 'Precio Unitario', combo['precio_unitario']]);
    



    const dobCol1 = worksheet.getColumn(1);
    dobCol1.width = 55;
    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 13;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 15;
    const dobCol4 = worksheet.getColumn(4);
    dobCol4.width = 30;
    const dobCol5 = worksheet.getColumn(5);
    dobCol5.width = 15;

    /*worksheet.getCell('A1').protection = {
      locked: true,
      hidden: true,
    };*/

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, workbook_name + '.xlsx');
    })

  }


  /*subirExcelProductos(){
    const workbook = new Workbook();
    await workbook.xlsx.read(stream);
  }*/
}
