import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProveedoresService } from '../../services/proveedores/proveedores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import * as XLSX from 'xlsx';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { RegistroProveedorComponent } from '../../components/registro-proveedor/registro-proveedor.component';
import { ProveedoresHistoricoComprasComponent } from '../proveedores-historico-compras/proveedores-historico-compras.component';
import { ConfiguracionAplicacionProveedoresComponent } from 'src/app/base/components/configuracion-aplicacion-proveedores/configuracion-aplicacion-proveedores.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css']
})
export class ProveedoresComponent extends BaseComponent implements OnInit {

  proveedores:any[]=[];
  modalProveedor:NgbModalRef;
  modalHistoricoCompras:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public proveedoresService:ProveedoresService,
    public modalService: NgbModal,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

    ngOnInit(): void {
      this.init();
      this.buscarAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      this.filter={
        id_empresa:this.usuario.id_empresa
      }
      this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PROVEEDORES);

      this.column = "codigo";
      this.getSearch(this.text_search,null);
    }
  
    ngAfterViewInit() {
      resaltarPestañaMenu(this.router.url.substring(1)+"1");
    }
    
    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.proveedoresService.obtenerListaProveedores(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.proveedores=dato.proveedores;
        this.blockUI.stop();
      });
    }

    subirExcelProveedores(event){
      var files = event.target.files;
      var i,f;
      let me=this;
      for (i = 0, f = files[i]; i != files.length; ++i) {
        var reader = new FileReader();
        var name = f.name;
        reader.onload = function(e) {
          me.blockUI.start();
          var data = e.target.result;
  
          var workbook = XLSX.read(data, {type: 'binary'});
          var first_sheet_name = workbook.SheetNames[0];
          var row=2,i=0;
          var worksheet = workbook.Sheets[first_sheet_name];
          var proveedores=[];
          do {
            let proveedor:any={};
            proveedor.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
            proveedor.identificacion=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
            proveedor.razon_social=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
            proveedor.nit=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
            proveedor.direccion=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
            proveedor.telefono1=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
            proveedores.push(proveedor);
            row++;	
            i++;				
          } while (worksheet['A'+row]!=undefined);
          me.guardarProveedores(proveedores);
          me.blockUI.stop();
        };
        reader.readAsBinaryString(f);
        }  
    }

    guardarProveedores(proveedores){
      this.blockUI.start();
      this.proveedoresService.guardarProveedores(proveedores,this.usuario.id_empresa).subscribe((res:any)=>{
        this.blockUI.stop();
        this.toastr.success("Proveedores registrados satisfactoriamente!");
        this.getItems();
      });
    }

    crearNuevoProveedor(){
      this.modalProveedor = this.modalService.open(RegistroProveedorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.modalProveedor.componentInstance.proveedor = {};
      this.modalProveedor.componentInstance.usuario = this.usuario;
      
      this.modalProveedor.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.getItems();
            this.modalProveedor.close();
          }
        }else{
          this.modalProveedor.close();
        }
        
      });
    }

    modificarProveedor(proveedor){
      this.modalProveedor = this.modalService.open(RegistroProveedorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.modalProveedor.componentInstance.proveedor =proveedor;
      this.modalProveedor.componentInstance.usuario = this.usuario;
      
      this.modalProveedor.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.getItems();
            this.modalProveedor.close();
          }
        }else{
          this.modalProveedor.close();
        }
        
      });
    }

    abrirPopupConfirmacionEliminacion(proveedor){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el proveedor?";
      this.popupConfirmacion.componentInstance.data = proveedor;
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.eliminarProveedor($e.data);
        }
        this.popupConfirmacion.close();
          });
    }
  
    eliminarProveedor(proveedor){
      this.blockUI.start();
      this.proveedoresService.eliminarProveedor(proveedor.id).subscribe((dato:any) => {
        this.getItems();
        this.toastr.info(dato.mensaje);
        this.blockUI.stop();
      });
    }

    abrirPopupHistoricoCompras(proveedor){
      this.modalHistoricoCompras = this.modalService.open(ProveedoresHistoricoComprasComponent, {windowClass:'historico-compras', scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modalHistoricoCompras.componentInstance.proveedor = proveedor;
      this.modalHistoricoCompras.componentInstance.usuario = this.usuario;
      
      this.modalHistoricoCompras.componentInstance.alTerminar.subscribe((res) => {
        this.modalHistoricoCompras.close();
      });
    }

    abrirConfiguracionAplicacion(){
      this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionProveedoresComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
      this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
      this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
      this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
        this.configuracion_aplicacion_modal.close();
      });
    }

}
