<div class="modal-header">
    <div class="col-6 col-md-6">
        <h4 class="widget-title">Pedidos Pendientes</h4>
    </div>
    <div class="col-6 col-md-6">
        <button class="btn btn-danger" type="button">
            <i class="ace-icon fa fa-file-pdf align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body p-0">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover ">
            <thead class="bgc-primary-d1 text-white sticky-nav">
                <tr class="sticky-nav">
                    <th>Nº</th>
                    <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
                    <th>Fecha - Hora Ingreso</th>
                    <th>
                        <div class="th-inner sortable both" [ngClass]="{'asc':filtro_pedido.columna=='tiempo_despacho' && filtro_pedido.direccion=='asc','desc':filtro_pedido.columna=='tiempo_despacho' && filtro_pedido.direccion=='desc'}">
                            <a class="text-white" href="javascript:void(0)" (click)="clasificarColumnaPedidos('tiempo_despacho')">Fecha de Entrega</a>
                        </div><div class="fht-cell"></div>
                    </th>
                    <th>Num. Pedido</th>
                    <th>Cliente</th>
                    <th>Telefono</th>
                    <th>Total</th>
                    <th>Observación</th>
                    <th>Usuario</th>
                    <th>Validez</th>
                    <th>Estado</th>
                    <th style="text-align: center;" data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pedido of pedidos_filtrados; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales">{{pedido.sucursal.nombre}}</td>
                    <td>{{pedido.tiempo_ingreso | date:"dd/MM/yyyy"}} {{pedido.tiempo_ingreso | date:"HH:mm"}}</td>
                    <td>{{pedido.tiempo_salida | date:"dd/MM/yyyy"}}</td>
                    <td>{{pedido.numero_pedido}}</td>
                    <td>{{pedido.cliente.identificacion}}</td>
                    <td>{{pedido.cliente.telefono1}}</td>
                    <td>{{pedido.total}}</td>
                    <td><div style="width: 100px">{{pedido.observacion}}</div></td>
                    <td>{{pedido.usuario?pedido.usuario.nombre_usuario:''}}</td>
                    <td *ngIf="pedido.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!pedido.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td><span class="badge badge-sm" [ngClass]="{'badge-danger':pedido.estado.nombre_corto=='PENDIENTE','badge-success':pedido.estado.nombre_corto=='ENTREGADO','badge-warning':pedido.estado.nombre_corto=='PORCOMPLETAR'}">{{pedido.estado.nombre}}</span></td>
                    <td>
                        <div class="row">
                            <div class="col-12">
                                <a *ngIf="usuario.empresa.usar_despachos && pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PCOMP" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_ENTGDINC,mensaje:'Esta acción cambiara el estado del pedido de POR COMPLETAR a ENTREGADO INCOMPLETO, ¿Esta seguro de realizarlo?'})">
                                    <i class="fa fa-exchange-alt"></i>
                                </a>
                                <a *ngIf="pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PEND" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_ENTGD,mensaje:'Esta acción cambiara el estado del pedido de PENDIENTE a ENTREGADO, ¿Esta seguro de realizarlo?'})">
                                  <i class="fa fa-exchange-alt"></i>
                                </a>
                                <a *ngIf="!pedido.id_venta && !usuario.empresa.usar_despachos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarVentaPedido(pedido)">
                                    <i class="ace-icon fas fa-money-bill-alt bigger-130"></i>
                                </a>
                                <a *ngIf="pedido.venta && pedido.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE && pedido.venta.saldo>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearPagoVenta(pedido)">
                                    <i class="fas fa-dollar-sign"></i>
                                </a>
                                <a *ngIf="usuario.empresa.usar_despachos && pedido.estado.nombre_corto!=global_variable.Dictionary.ESTPED_ENTGD" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="despacharPedidoNotificacion(pedido)">
                                    <i class="ace-icon fa fa-arrow-circle-right bigger-130"></i>
                                </a>
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verPedido(pedido)">
                                    <i class="ace-icon fa fa-search-plus bigger-130"></i>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="pedidos_filtrados.length>0">
                    <td></td>
                    <td *ngIf="usuario.empresa.usar_sucursales"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>TOTAL</b></td>
                    <td>{{sumarImporte()}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>