import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }
 
    req = this.addAuthenticationToken(req);
 
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        /*if (!window.localStorage.getItem('token')) {
            window.location.href="/";
        }*/
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && (error.status === 401 || error.status === 403)) {
         // 401 errors are most likely going to be because we have an expired token that we need to refresh.
         window.localStorage.removeItem("ngStorage-usuario");//clear();
         window.localStorage.removeItem("ngStorage-token");
         window.localStorage.removeItem("usuario");//clear();
         window.localStorage.removeItem("token");
         window.location.reload();
        }
        return throwError(error);
      })
    );
  }
 
  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    let usuario:any=localStorage.getItem('usuario')?JSON.parse(localStorage.getItem('usuario')):{id:0};
    let cadena_authorization=usuario.id+"&"+ (localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):undefined);
    if(usuario && usuario.empresa && usuario.empresa.usar_bitacora_usuario){
      cadena_authorization=cadena_authorization+'&1';
    }
    return request.clone({
      headers: request.headers.set('Authorization',cadena_authorization)
    });
  }
}