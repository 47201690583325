import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VehiculosService {

  constructor(private http: HttpClient) { }

  public obtenerListaVehiculos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vehiculos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarVehiculo(vehiculo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"vehiculos",vehiculo,GlobalVariable.httpOptions);
  }

  public obtenerVehiculo(id_vehiculo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vehiculos/"+id_vehiculo,GlobalVariable.httpOptions);
  }

  public actualizarVehiculo(vehiculo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"vehiculos/"+vehiculo.id,vehiculo,GlobalVariable.httpOptions);
  }

  public eliminarVehiculo(vehiculo){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"vehiculos/"+vehiculo.id,GlobalVariable.httpOptions);
  }

  public obtenerInventarioVehiculo(id_vehiculo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inventario/vehiculo/"+id_vehiculo,GlobalVariable.httpOptions);
  }

  public guardarInventarioVehiculo(vehiculo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inventario/vehiculo/"+vehiculo.id,vehiculo,GlobalVariable.httpOptions);
  }

  public obtenerContadorLlantas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-llantas-notificacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerLlantasNotificacion(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"llantas-notificacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarCambioAceite(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cambio-aceite",datos,GlobalVariable.httpOptions);
  }

  public obtenerContadorAceites(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contar-aceites-notificacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerAceitesNotificacion(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"aceites-notificacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public buscarVehiculo(id_empresa,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vehiculos/empresa/"+id_empresa+"/texto/"+texto, GlobalVariable.httpOptions);
  }
}
