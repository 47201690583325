import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  public obtenerListaEmpresas(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresas/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/rubro/"+paginator.filter.rubro.id+"/plan/"+paginator.filter.plan.id,GlobalVariable.httpOptions);
  }

  public obtenerEmpresas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresas/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public activarDesactivarEmpresa(empresa){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inactivacion-empresa/"+empresa.id,empresa,GlobalVariable.httpOptions);
  }

  public obtenerRoles(){
    return this.http.get(GlobalVariable.API_SERVER_URL+"roles",GlobalVariable.httpOptions);
  }

  public obtenerSucursalesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresa/"+id_empresa+"/sucursales",GlobalVariable.httpOptions);
  }

  public obtenerEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresas/"+id_empresa,GlobalVariable.httpOptions);
  }

  public modificarEmpresa(empresa){
    return this.http.put(GlobalVariable.API_SERVER_URL+"empresas/"+empresa.id,empresa,GlobalVariable.httpOptions);
  }

  public obtenerPlanesRubro(rubro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rubro-planes/"+rubro.id,GlobalVariable.httpOptions);
  }

  public obtenerModulos(){
    return this.http.get(GlobalVariable.API_SERVER_URL+"modulos",GlobalVariable.httpOptions);
  }

  public eliminarDatosEmpresa(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"eliminacion-datos-empresa",datos,GlobalVariable.httpOptions);
  }

  public obtenerTiposSincronizacionEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sync/tipos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public crearCuentaEmpresa(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"creacion-cuenta-empresa-snq",datos,GlobalVariable.httpOptions);
  }

  public guardarRol(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"roles",datos,GlobalVariable.httpOptions);
  }

  public obtenerRolesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"roles/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerRol(id_rol){
    return this.http.get(GlobalVariable.API_SERVER_URL+"roles/"+id_rol,GlobalVariable.httpOptions);
  }

  public actualizarRol(rol){
    return this.http.put(GlobalVariable.API_SERVER_URL+"roles/"+rol.id,rol,GlobalVariable.httpOptions);
  }

  public descargarDatosEmpresa(codigo_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sync/descargar-datos/empresa/"+codigo_empresa,GlobalVariable.httpOptions);
  }

  public reiniciarNumeroPedidoSucursal(sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"numero-pedido-actual/sucursal/"+sucursal.id,sucursal,GlobalVariable.httpOptions);
  }

  public reiniciarNumeroFichaSucursal(sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"numero-ficha-actual/sucursal/"+sucursal.id,sucursal,GlobalVariable.httpOptions);
  }

  public obtenerNumeroPedidoSucursal(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"numero-pedido-actual/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerNumeroFichaSucursal(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"numero-ficha-actual/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public sincronizacionSubirDatosServidor(){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/subir-datos",GlobalVariable.httpOptions);
  }

  public sincronizacionDescargarDatosServidor(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/descargar-datos",datos,GlobalVariable.httpOptions);
  }

  public obtenerSucursal(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sucursales/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerDosificacionesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"dosificaciones/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarDosificacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"dosificaciones",datos,GlobalVariable.httpOptions);
  }

  public guardarDatosFacturacion(sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"datos-facturacion-sucursal/"+sucursal.id,sucursal,GlobalVariable.httpOptions);
  }

  public guardarDatosSucursal(sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"datos-sucursal/"+sucursal.id,sucursal,GlobalVariable.httpOptions);
  }

  public guardarAlmacenesSucursal(sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"datos-almacenes-sucursal/"+sucursal.id,sucursal,GlobalVariable.httpOptions);
  }

  public obtenerAlmacenesUsuario(id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"almacenes-usuario/"+id_usuario,GlobalVariable.httpOptions);
  }

  public guardarDatosFacturacionEmpresa(empresa){
    return this.http.put(GlobalVariable.API_SERVER_URL+"datos-facturacion-empresa/"+empresa.id,empresa,GlobalVariable.httpOptions);
  }

  public autenticarEmpresaSin(empresa){
    return this.http.post(GlobalVariable.API_SERVER_URL+"autenticar-empresa-sin/",empresa,GlobalVariable.httpOptions);
  }

  public obtenerCuis(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sincronizar-sin-cuis/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerCufd(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sincronizar-sin-cufd/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerProductosSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-productos-servicios/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarProductoSin(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sin-producto-servicio",datos,GlobalVariable.httpOptions);
  }

  public sincronizarProductosSin(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sin-productos-servicios/empresa",datos,GlobalVariable.httpOptions);
  }

  public obtenerActividadesSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-actividades-empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerDocumentosSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-documentos-facturacion-empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerDocumentosSucursalSin(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-documentos-facturacion-sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public verificarComunicacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sin-verificar-comunicacion",datos,GlobalVariable.httpOptions);
  }

  public obtenerCufdUltimo(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"obtener-sin-cufd-ultimo/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerCufdUltimos(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"obtener-sin-cufd-ultimos/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerUltimosCafc(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"obtener-sin-cafc-ultimos/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public guardarEventoSignificativo(evento_significativo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"evento-significativo",evento_significativo,GlobalVariable.httpOptions);
  }

  public obtenerListaEventosSignificativos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"eventos-significativos/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public existeEventoSignificativoActivo(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"existe-evento-significativo-activo/sucursal/"+id_sucursal, GlobalVariable.httpOptions);
  }

  public validarRecepcionMasivaFacturas(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"validar-recepcion-masiva-facturas-offline-sin",datos, GlobalVariable.httpOptions);
  }

  public obtenerSucrsalFacturacionSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sucursal-facturacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionCalificaciones(id_empresa,id_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-calificaciones-gestion/"+id_gestion+"/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarConfiguracionCalificaciones(configuracion_calificaciones){
    return this.http.post(GlobalVariable.API_SERVER_URL+"configuracion-calificaciones-gestion/colegio",configuracion_calificaciones,GlobalVariable.httpOptions);
  }

  public certificarEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pruebas-certificacion-facturacion/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerCarpetasArchivosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"directorios/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }
  
}
