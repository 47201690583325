import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { RutasService } from '../../services/rutas/rutas.service';

import { GeneralService } from '../../../base/services/general/general.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { GlobalVariable } from 'src/app/global';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-rutas',
  templateUrl: './rutas.component.html',
  styleUrls: ['./rutas.component.css']
})
export class RutasComponent extends BaseComponent implements OnInit {

  rutas:any[]=[];
  ruta:any={};
  tipo_punto_ruta:any={clases:[]};

  edicion_ruta:NgbModalRef;
  @ViewChild('edicion_ruta')
  private edicion_ruta_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private rutasService:RutasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_RUTAS);
    this.obtenerRutas();
    this.obtenerPuntosRuta();
  }

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerPuntosRuta(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"TRANSPUNTORUTA").subscribe((tipo_punto_ruta:any) => {
			this.tipo_punto_ruta=tipo_punto_ruta;
		});
	}

  obtenerRutas(){
		this.column = "createdAt";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.rutasService.obtenerListaRutas(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.rutas=dato.rutas;
			this.blockUI.stop();
		});
  }

  crearNuevaRuta(){
		this.ruta={
			id_empresa:this.usuario.id_empresa
		}
		this.abrirRegistroRuta();
  }
  
  abrirRegistroRuta(){
    this.edicion_ruta=this.modalService.open(this.edicion_ruta_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.edicion_ruta.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  validarRegistroRuta(){
		let res=true;
		if(!this.ruta.origen){
			this.toastr.error("Debe ingresar el origen de la ruta!");
			res=false;
    }
    if(!this.ruta.destino){
			this.toastr.error("Debe ingresar el destino de la ruta!");
			res=false;
    }
    if(!this.ruta.distancia_kilometros){
			this.toastr.error("Debe ingresar la distancia de la ruta en Km!");
			res=false;
    }
		return res;
	}

  guardarRuta(){
		if(this.validarRegistroRuta()){
			this.blockUI.start();
			if(this.ruta.id){
				this.rutasService.actualizarRuta(this.ruta).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicion_ruta.close();
				});
			}else{
				this.rutasService.guardarRuta(this.ruta).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Ruta registrada satisfactoriamente!");
					this.getItems();
					this.edicion_ruta.close();
				});
			}
		}
		
  }
  
  modificarRuta(ruta){
		this.rutasService.obtenerRuta(ruta.id).subscribe((datosRuta:any) => {
			this.ruta=datosRuta;
		});
		this.abrirRegistroRuta();
	}

	abrirPopupConfirmacionEliminacion(ruta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar la ruta?";
		this.popupConfirmacion.componentInstance.data = ruta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarRuta($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarRuta(ruta){
		this.blockUI.start();
		this.rutasService.eliminarRuta(ruta).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

}
