<div class="modal-header card-header">
    <div class="col-12 col-md-4">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Datos Cotizacion</span>
            </div>
            <div class="col-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardar()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="abrirPopupConfirmacionCierreVentana()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8">
        <div class="row">
            <div class="col-12">
                <h4 class="widget-title" *ngIf="cotizacion.usuario.empresa.usar_servicios">
                    <label>
                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-servicio bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCotizacion.es_servicio" (change)="cambiarServicio(detalleCotizacion.es_servicio)"/>
                    </label>
                </h4>
                <span class="text-blue text-125" *ngIf="!cotizacion.usuario.empresa.usar_servicios">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Nombre</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input *ngIf="!configuracion_pagina.object.datos.opciones_permisos.cliente_registrado" class="form-control" id="cotizacion_nombre" name="cotizacion_nombre" required type="text" [(ngModel)]="cotizacion.nombre" placeholder="Ingrese el Nombre">

                                        <ng-container *ngIf="configuracion_pagina.object.datos.opciones_permisos.cliente_registrado">
                                            <input required name="razon_social" [disabled]="cotizacion.cliente && cotizacion.cliente.id"
                                                [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                                type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="razon_busqueda" 
                                                [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off"
                                            />
                                            <ng-template #rt let-r="result" let-t="term">
                                                <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                            </ng-template>
    
                                            <div class="col-12 col-sm-12 col-xs-12">
                                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="cotizacion.cliente && cotizacion.cliente.id" (click)="reiniciarCliente()">
                                                    <i class="fa fa-trash bigger-130 red2"></i>
                                                </a>
                                                <a *ngIf="cotizacion.cliente && !cotizacion.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                                    <i class="fa fa-plus bigger-130"></i>
                                                </a>
                                                <a *ngIf="cotizacion.cliente && cotizacion.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                                    <i class="fa fa-edit bigger-130"></i>
                                                </a>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="cotizacion_descripcion" name="cotizacion_descripcion" type="text" [(ngModel)]="cotizacion.descripcion" placeholder="Ingrese la descripción">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div *ngIf="usuario.empresa.usar_sucursales" class="col-5 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Sucursal</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="venta_sucursal" name="sucursal" class="form-control" [(ngModel)]="cotizacion.sucursal" (change)="establecerSucursal(cotizacion.sucursal)">
                                            <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                        <div class="input-group">
                                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="cotizacion.fechaTexto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-3 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Validez</label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                        <input name="tiempo_validez" class="form-control" placeholder="Días" [(ngModel)]="cotizacion.tiempo_validez" type="number" step="1" min="1">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <form id="formularioBusquedaProducto" name="formularioBusquedaProducto" novalidate>
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !cotizacion.usuario.empresa.usar_combos">PRODUCTO</label>
                                                <label *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_SERVICIO">SERVICIO</label>
                                                <label *ngIf="cotizacion.usuario.empresa.usar_combos && (detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO)">
                                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-item bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCotizacion.es_combo" (change)="cambiarCombo(detalleCotizacion.es_combo)" />
                                                </label>
                                                <button *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-warning btn-sm">
                                                    <i class="ace-icon fa fa-barcode bigger-110"></i>
                                                </button>
                                                <button *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-gift bigger-110"></i>
                                                </button>
                                            </th>
                                            <th *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>CÓD. ITEM</label>
                                            </th>
                                            <th *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>UNID. MED.</label>
                                            </th>
                                            <th *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && cotizacion.usuario.empresa.usar_vencimientos && detalleCotizacion.producto && detalleCotizacion.producto.id && detalleCotizacion.producto.activar_vencimiento">
                                                <label>VENCIMIENTO</label>
                                            </th>
                                            <th>
                                                <label>P.U. (Bs/u)</label>
                                            </th>
                                            <th>
                                                <label>CANT. (u)</label>
                                            </th>
                                            <th>
                                                <label>IMP. (Bs)</label>
                                            </th>
                                            <th>
                                                <label>ACC.</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td id="gallery" *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO">

                                                <input *ngIf="!busqueda_codigo_barra" autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                [disabled]="(detalleCotizacion.producto && detalleCotizacion.producto.id)" id="venta_id_producto" [(ngModel)]="producto_busqueda"
                                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtp let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+r.unidad_medida+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                </ng-template>

                                                <a href="javascript:void(0)" *ngIf="detalleCotizacion.producto && detalleCotizacion.producto.id" (click)="iniciarDetalleCotizacion()" class="card-toolbar-btn text-danger-m1">
                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                </a>
                                                <input name="busqueda_codigo_barra" class="form-control" *ngIf="busqueda_codigo_barra" autocomplete="off" (keypress)="buscarCodigoBarraProducto($event,codigo_barra)" id="venta_id_producto" required [disabled]="!cotizacion.almacen || (detalleCotizacion.producto && detalleCotizacion.producto.id)" type="text" [(ngModel)]="codigo_barra" placeholder="Esperando al lector..." >
                                                <div class="space-6"></div>
                                                <a *ngIf="detalleCotizacion.producto && detalleCotizacion.producto.id" href="#" class="show-lightbox" title="{{detalleCotizacion.producto.nombre}}">
                                                    <img width="30" height="30" alt="{{detalleCotizacion.producto.nombre}}" src="{{rest_server+detalleCotizacion.producto.imagen}}" class="d-zoom-3" data-size="1000x700" />
                                                </a>
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_SERVICIO">
                                                <input required autocomplete="off" (keypress)="enfocarElemento($event,'venta_precio_unitario')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detalleCotizacion.descripcion" type="text" />
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">

                                                <input autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                id="cotizacion_id_combo" [(ngModel)]="combo_busqueda" [disabled]="(detalleCotizacion.combo && detalleCotizacion.combo.id)"
                                                [ngbTypeahead]="buscarCombo" placeholder="Buscar combo" [resultTemplate]="rtc" name="combo_buqueda"
                                                [inputFormatter]="formatterCombo"  (selectItem)="establecerCombo($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtc let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre" [term]="t"></ngb-highlight>
                                                </ng-template>
                                                <a href="javascript:void(0)" *ngIf="detalleCotizacion.combo && detalleCotizacion.combo.id" (click)="iniciarDetalleCotizacion()" class="card-toolbar-btn text-danger-m1">
                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                </a>
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detalleCotizacion.producto?detalleCotizacion.producto.codigo:''}}</td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">{{detalleCotizacion.combo?detalleCotizacion.combo.codigo:''}}</td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detalleCotizacion.producto?detalleCotizacion.producto.unidad_medida:''}}</td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">{{detalleCotizacion.combo?detalleCotizacion.combo.unidad_medida:''}}</td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && cotizacion.usuario.empresa.usar_vencimientos && detalleCotizacion.producto && detalleCotizacion.producto.id && detalleCotizacion.producto.activar_vencimiento">
                                                <select *ngIf="detalleCotizacion.producto.activar_inventario" name="fechaVencimiento" style="width:95px;" id="venta_fechaVencimiento" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="detalleCotizacion.inventarioProducto" (change)="actualizarInventarioDisponibleFechaVencimiento()">
                                                    <option [ngValue]="inventario" *ngFor="let inventario of inventariosDisponibleProducto">{{('FV:'+inventario.fechaVencimientoTexto+'-'+'F:'+inventario.detallesMovimiento[0].movimiento.fechaTexto+'-'+inventario.detallesMovimiento[0].movimiento.clase.nombre)}}</option>
                                                </select>
                                                <label>Lote: {{detalleCotizacion.lote}}</label>
                                            </td>
                                            <td *ngIf="!cotizacion.usuario.empresa.usar_precios_productos_sucursal && !cotizacion.usuario.empresa.usar_precios_productos && !editar_precio">
                                                <div clas="row">
                                                    <div id="venta_Precio" class="col-12">
                                                        {{detalleCotizacion.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-sm-12">
                                                        <a class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_SERVICIO || (!cotizacion.usuario.empresa.usar_precios_productos_sucursal && !cotizacion.usuario.empresa.usar_precios_productos && editar_precio)">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <input id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required class="form-control" (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.precio_unitario" type="number" (keypress)="enfocarElemento($event,'venta_cantidad')"/>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <a class="card-toolbar-btn text-primary" href="javascript:void(0)" (click)="establecerPrecio()">
                                                            <i class="fa fa-check-square bigger-130"></i>
                                                        </a>
                                                    </div>
                                                </div>		
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && (cotizacion.usuario.empresa.usar_precios_productos || cotizacion.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-8">
                                                        {{detalleCotizacion.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-12 p-0">
                                                        <select *ngIf="detalleCotizacion.producto" class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleCotizacion.producto_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detalleCotizacion.producto.precios">{{precio.concepto.nombre}}</option>
                                                        </select>
                                                    </div>                                               
                                                </div>
                                                <div class="row" > <!--*ngIf="es_administrador"-->
                                                    <div class="col-12" *ngIf="editar_precio">
                                                        <input class="form-control" id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.precio_unitario" type="number" />
                                                        <a class="card-toolbar-btn text-primary" href="javascript:void(0)" (click)="establecerPrecio()">
                                                            <i class="fa fa-check-square bigger-130"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-12" *ngIf="!editar_precio">
                                                        <a class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div> 
                                                </div>
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO && (cotizacion.usuario.empresa.usar_precios_productos || cotizacion.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-md-12">
                                                        {{detalleCotizacion.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-md-12 no-padding">
                                                        <select class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleCotizacion.combo_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detalleCotizacion.combo.precios">{{precio.concepto.nombre}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input *ngIf="!configuracion_pagina.object.datos.opciones_permisos.cantidad_decimal" onfocus="this.select();" (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" name="cantidad" step="1" 
                                                min="1" max="{{detalleCotizacion.inventario_disponible}}" required class="form-control" 
                                                (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.cantidad" type="number" 
                                                onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"/>
                                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                                    <input class="form-control" *ngIf="configuracion_pagina.object.datos.opciones_permisos.cantidad_decimal" onfocus="this.select();" 
                                                    (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" 
                                                    name="cantidad" step="0.001" min="0.1" max="{{detalleCotizacion.inventario_disponible}}" 
                                                    required (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.cantidad" type="number" />
                                                </div>
                                            </td>
                                            <td>
                                                {{detalleCotizacion.importe}}
                                            </td>
                                            <td>
                                                <button id="agregar_detalle_venta" type="button" [disabled]="detalleCotizacion.cantidad==null || detalleCotizacion.precio_unitario==null || (detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO && detalleCotizacion.producto.id==null) || (detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO && detalleCotizacion.combo.id==null)" (click)="agregarDetalleCotizacion()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                                </button>
                                                <hr>
                                                <button id="mostrar_desc" type="button" (click)="mostrarDescuentos()" class="btn btn-info btn-sm">
                                                    <i class="fas fa-expand-alt bigger-110"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <th>
                                                <label>DESCUENTOS (-)</label>
                                                <label>
                                                    <input (change)="calcularImporte()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCotizacion.tipo_descuento"/>
                                                </label>
                                            </th>
                                            <!--<th>
                                                <label>RECARGOS (+)</label>
                                                <label>
                                                    <input class="ace ace-switch ace-switch-4" type="checkbox" [(ngModel)]="detalleCotizacion.tipo_recargo" (change)="calcularImporte()"/>
                                                    <span class="lbl check-compra"></span>
                                                </label>
                                            </th>
                                            <th>
                                                <label>ICE (-)</label>
                                            </th>
                                            <th>
                                                <label>EXCENTOS (-)</label>
                                            </th>-->
                                            <th>
                                                <label>Total</label>
                                            </th>
                                            <th *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>Observación</label>
                                            </th>
                                            <!--<th *ngIf="detalleCotizacion.es_servicio">
                                                <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)}} <span class="glyphicon glyphicon-pencil point-edit blue" (click)="abrirDialogTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1,obtenerTextosEmpresa)"></span></label>
                                            </th>-->
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <td >
                                                <input name="desc" required (keyup)="calcularImporte()" [(ngModel)]="detalleCotizacion.descuento" step="0.001" class="form-control" type="number" value="0"/>
                                            </td>
                                            <!--<td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.recargo" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.ice" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleCotizacion.excento" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>-->
                                            <td>
                                                {{detalleCotizacion.total}}
                                            </td>
                                            <td *ngIf="detalleCotizacion.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleCotizacion.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <input (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detalleCotizacion.descripcion" type="text" />
                                            </td>
                                            <!--<td *ngIf="detalleCotizacion.es_servicio">
                                                <input id="venta_id_campo1" name="campo1" class="form-control" [(ngModel)]="detalleCotizacion.campo_personalizado_1" type="text" />
                                            </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="cotizacion.detallesCotizacion.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE COTIZACIÓN</h5>
                            <div class="card-toolbar">
                                <label>
                                    <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleCotizacion.agrupar" (change)="agruparDetallesCotizacion(detalleCotizacion.agrupar)"/>
                                    <span>¿Agrupar?</span>
                                </label>
                            </div>
                        </div>
                        <div class="table-responsive card-body bg-white p-0" *ngIf="cotizacion.detallesCotizacion.length>0">
                            <table *ngIf="!detalleCotizacion.agrupar" class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>NOMBRE</label>
                                        </th>
                                        <th>
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th>
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th>
                                            <label>P.U. (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th>
                                            <label>IMP. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>DESC. (-)</label>
                                        </th>
                                        <th>
                                            <label>TOTAL. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>ACCIONES</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detalleCotizacion of cotizacion.detallesCotizacion; let i=index;">
                                        <tr  *ngIf="!detalleCotizacion.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.nombre}}</span>
                                                <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.nombre}} </span>
                                                <span *ngIf="!detalleCotizacion.combo && !detalleCotizacion.producto">{{detalleCotizacion.descripcion}} </span>
                                            </td>
                                            <td>
                                                <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.codigo}}</span>
                                                <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.codigo}}</span>
                                                <span *ngIf="!detalleCotizacion.combo && !detalleCotizacion.producto"> </span>
                                            </td>
                                            <td>
                                                <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.unidad_medida}}</span>
                                                <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.unidad_medida}}</span>
                                                <span *ngIf="!detalleCotizacion.combo && !detalleCotizacion.producto"> </span>
                                            </td>
                                            <td>
                                                {{detalleCotizacion.precio_unitario.toFixed(2)}}
                                            </td>
                                            <td class="cantidad-cotizacion">
                                                {{detalleCotizacion.cantidad}}
                                            </td>
                                            <td>
                                                {{detalleCotizacion.importe.toFixed(2)}}
                                            </td>
                                            <td>
                                                {{detalleCotizacion.descuento}} <i *ngIf="detalleCotizacion.tipo_descuento" class="fas fa-percent text-primary"></i> <i *ngIf="!detalleCotizacion.tipo_descuento" class="fas fa-dollar-sign text-primary"></i>
                                            </td>
                                            <td>
                                                {{detalleCotizacion.total.toFixed(2)}}
                                            </td>
                                            <td>
                                                <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" (click)="eliminarDetalleCotizacion(detalleCotizacion)">
                                                    <i class="fa fa-trash bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td >
                                            <b>TOTALES</b>
                                        </td>
                                        <td >
                                            
                                        </td>
                                        <td >
                                            
                                        </td>
                                        <td >
                                            
                                        </td>
                                        <td >
                                            
                                        </td>
                                        <td class="total-cotizacion">
                                            {{cotizacion.importe.toFixed(2)}}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="usuario.empresa.usar_descuento_global">
                                                <input  (change)="establecerDescuentoGlobal()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="cotizacion.tipo_descuento"/>
                                                <input class="form-control" type="number" step="0" min="0" [(ngModel)]="cotizacion.descuento" (change)="establecerDescuentoGlobal()" (keyup)="establecerDescuentoGlobal()">  
                                            </ng-container>
                                            <span *ngIf="!usuario.empresa.usar_descuento_global">{{cotizacion.descuento.toFixed(2)}}</span>
                                        </td>
                                        <td class="total-cotizacion">
                                            {{cotizacion.total.toFixed(2)}}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ng-container *ngIf="detalleCotizacion.agrupar">
                                <ng-container *ngFor="let grupo of grupos_combos">
                                <table *ngIf="grupo.items.length>0" class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th colspan="10">
                                                <label>{{grupo.nombre}}</label>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <label>Nº</label>
                                            </th>
                                            <th>
                                                <label>NOMBRE</label>
                                            </th>
                                            <th>
                                                <label>CÓD. ITEM</label>
                                            </th>
                                            <th>
                                                <label>UNID. MED.</label>
                                            </th>
                                            <th>
                                                <label>P.U. (Bs/u)</label>
                                            </th>
                                            <th>
                                                <label>CANT. (u)</label>
                                            </th>
                                            <th>
                                                <label>IMP. (Bs)</label>
                                            </th>
                                            <th>
                                                <label>DESC. (-)</label>
                                            </th>
                                            <th>
                                                <label>TOTAL. (Bs)</label>
                                            </th>
                                            <th>
                                                <label>ACCIONES</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let detalleCotizacion of grupo.items; let i=index;">
                                            <tr  *ngIf="!detalleCotizacion.eliminado">
                                                <td>
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.nombre}}</span>
                                                    <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.nombre}} </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.codigo}}</span>
                                                    <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.codigo}}</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="detalleCotizacion.producto">{{detalleCotizacion.producto.unidad_medida}}</span>
                                                    <span *ngIf="detalleCotizacion.combo">{{detalleCotizacion.combo.unidad_medida}}</span>
                                                </td>
                                                <td>
                                                    {{detalleCotizacion.precio_unitario}}
                                                </td>
                                                <td class="cantidad-cotizacion">
                                                    {{detalleCotizacion.cantidad}}
                                                </td>
                                                <td>
                                                    {{detalleCotizacion.importe}}
                                                </td>
                                                <td>
                                                    {{detalleCotizacion.descuento}}
                                                </td>
                                                <td>
                                                    {{detalleCotizacion.total}}
                                                </td>
                                                <td>
                                                    <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" (click)="eliminarDetalleCotizacion(detalleCotizacion)">
                                                        <i class="fa fa-trash bigger-130"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td>
                                                
                                            </td>
                                            <td >
                                                <b>TOTALES</b>
                                            </td>
                                            <td >
                                                
                                            </td>
                                            <td >
                                                
                                            </td>
                                            <td >
                                                
                                            </td>
                                            <td >
                                                
                                            </td>
                                            <td class="total-cotizacion">
                                                
                                            </td>
                                            <td>
                                                
                                            </td>
                                            <td class="total-cotizacion">
                                                {{sumarImporteGrupo(grupo)}}
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </ng-container>
                            </ng-container>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>