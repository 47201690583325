import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PacientesComponent } from './components/pacientes/pacientes.component';
import { CitasMedicasComponent } from './components/citas-medicas/citas-medicas.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { RegistroPacienteComponent } from './components/registro-paciente/registro-paciente.component';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TratamientosComponent } from './components/tratamientos/tratamientos.component';
import { KardexPacienteComponent } from './components/kardex-paciente/kardex-paciente.component';
import { RecetasComponent } from './components/recetas/recetas.component';



@NgModule({
  declarations: [
    PacientesComponent,
    CitasMedicasComponent,
    RegistroPacienteComponent,
    TratamientosComponent,
    KardexPacienteComponent,
    RecetasComponent
  ],
  imports: [
    CommonModule,
    BaseModule,
    FormsModule,
    NgbModule,
    OrderModule,
    FilterPipeModule,
    NgxBootstrapMultiselectModule,
  ]
})
export class MedicinaModule { }
