<div class="modal-header">
    <div class="col-8 col-xs-6">
        <h4 class="widget-title">Vencimiento Creditos</h4>
    </div>
    <div class="col-4 col-xs-6">
        <button class="btn btn-success btn-lg" type="button" (click)="generarExcelVencimientoCreditos()">
            <i class="ace-icon fa fa-file-excel align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger btn-lg" type="button" (click)="imprimirListaVencimientoCreditos()">
            <i class="ace-icon fa fa-file-pdf align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body p-0">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover ">
            <thead class="bgc-primary-d1 text-white sticky-nav">
                <tr class="sticky-nav">
                    <th>Nº</th>
                    <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
                    <th>Transacción</th>
                    <th>Fecha</th>
                    <th>Identificación
                        <input name="filtro-ident" class="form-control text-center" type="text" [(ngModel)]="filtro_venta.identificacion" (keyup)="filtrarVentas(filtro_venta.identificacion)">
                    </th>
                    <th>Razón Social</th>
                    <th>Nº Doc.</th>
                    <th>Fecha Venc.</th>
                    <th>Saldo</th>
                    <th>Dias Mora</th>
                    <th>Obs.</th>
                    <th>Estado
                        <select (change)="filtrarEstado()" [compareWith]="compararObjectos" class="form-control" name="estado" [(ngModel)]="filter.estado">
                            <option [ngValue]="estado" *ngFor="let estado of filter.estados">{{estado.nombre}}</option>
                        </select>
                    </th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let venta of ventas_filtradas; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales">{{venta.sucursal.nombre}}</td>
                    <td>{{venta.transaccion.nombre}}</td>
                    <td>{{venta.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{venta.cliente.identificacion}}</td>
                    <td>{{venta.cliente.razon_social}}</td>
                    <td>{{venta.factura}}</td>
                    <td>{{venta.fecha_vencimiento | date:"dd/MM/yyyy"}}
                        <span class="red" *ngIf="venta.fecha_anterior"> - ({{venta.fecha_anterior | date:"dd/MM/yyyy" }})</span>
                    </td>
                    <td>{{venta.saldo.toFixed(2)}}</td>
                    <td>{{calcularDiasMora(venta) }}</td>
                    <td>{{venta.observacion}}</td>
                    <td>
                        <span *ngIf="venta.id_estado && venta.estado.nombre_corto=='ESTVENTSAN'" class="badge badge-sm badge-success">{{venta.estado.nombre}}</span>
                        <span *ngIf="venta.id_estado && venta.estado.nombre_corto=='ESTVENTVIG'" class="badge badge-sm badge-warning">{{venta.estado.nombre}}</span>
                        <span *ngIf="venta.id_estado && venta.estado.nombre_corto=='ESTVENTMOR'" class="badge btn-pink">{{venta.estado.nombre}}</span>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-12">
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(venta)">
                                    <i class="ace-icon fa fa-search-plus bigger-130"></i>
                                </a>
                                <a title="Cobrar" class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearPagoVenta(venta)">
                                    <i class="fas fa-money-bill-alt"></i>
                                </a>
                                <!--<a class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirActualizarFechaCreditos(venta)">
                                    <i class="ace-icon fa fa-calendar bigger-130"></i>
                                </a>-->
                                <a class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="imprimirVenta(venta)">
                                    <i class="ace-icon fa fa-print bigger-130"></i>
                                </a>
                                <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioWhatsapp(venta)">
                                    <i class="ace-icon fab fa-whatsapp bigger-130"></i>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer p-0">
    <table class="table table-striped table-bordered table-hover ">
        <tfoot>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <h5 *ngIf="ventas_credito" class="widget-title">Total Saldo: {{sumarTotalSaldo(ventas_credito)}}</h5>
            </td>
            <td></td>
            <td></td>
        </tfoot>
    </table>
</div>

<canvas id="qr-code" style="display:none" width="100" height="100"></canvas>