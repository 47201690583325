import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class CarrerasService {

  constructor(private http: HttpClient) { }

  public obtenerListaCarreras(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"carreras/escuela/"+paginator.filter.escuela.id+"/periodicidad/"+paginator.filter.periodicidad.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarCarrera(carrera){
    return this.http.post(GlobalVariable.API_SERVER_URL+"carrera/",carrera,GlobalVariable.httpOptions);
  }

  public actualizarCarrera(carrera){
    return this.http.put(GlobalVariable.API_SERVER_URL+"carrera/"+carrera.id,carrera,GlobalVariable.httpOptions);
  }

  public eliminarCarrera(carrera){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"carrera/"+carrera.id,GlobalVariable.httpOptions);
  }

  public obtenerCarreras(id_escuela){
    return this.http.get(GlobalVariable.API_SERVER_URL+"carreras/escuela/"+id_escuela,GlobalVariable.httpOptions);
  }

  public guardarMateria(materia){
    return this.http.post(GlobalVariable.API_SERVER_URL+"materia/",materia,GlobalVariable.httpOptions);
  }

  public actualizarMateria(materia){
    return this.http.put(GlobalVariable.API_SERVER_URL+"materia/"+materia.id,materia,GlobalVariable.httpOptions);
  }

  public obtenerMateria(materia){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materia/"+materia.id,GlobalVariable.httpOptions);
  }

  public obtenerMaterias(id_carrera){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias/carrera/"+id_carrera,GlobalVariable.httpOptions);
  }

  public eliminarMateria(materia){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"materia/"+materia.id,GlobalVariable.httpOptions);
  }

  public obtenerListaPeriodosClase(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"periodos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public actualizarPeriodo(periodo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"periodo/"+periodo.id,periodo,GlobalVariable.httpOptions);
  }

  public guardarPeriodo(periodo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"periodo",periodo,GlobalVariable.httpOptions);
  }

  public obtenerPeriodo(periodo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"periodo/"+periodo.id,GlobalVariable.httpOptions);
  }

  public eliminarPeriodo(periodo){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"periodo/"+periodo.id,GlobalVariable.httpOptions);
  }

  public obtenerMateriasAsignadas(id_inscripcion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias-asignadas/inscripcion/"+id_inscripcion,GlobalVariable.httpOptions);
  }

  public guardarMateriasAsignadas(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"materias-asignadas",datos,GlobalVariable.httpOptions);
  }
}
