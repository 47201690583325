<div class="sidebar-inner">

  


  <div class="ace-scroll flex-grow-1" ace-scroll>

    <div class="sidebar-section my-2">
      <div class="sidebar-section-item fadeable-left">

        <div class="fadeinable sidebar-shortcuts-mini">
          <span class="btn btn-success p-0"></span>
          <span class="btn btn-info p-0"></span>
          <span class="btn btn-warning p-0"></span>
          <span class="btn btn-danger p-0"></span>
        </div>

        <div class="fadeable">
          <div class="sub-arrow"></div>
          <div>
            <button class="btn btn-smd btn-success">
              <i class="fa fa-signal"></i>
            </button>
            &nbsp;
            <button class="btn btn-smd btn-info">
              <i class="fa fa-edit"></i>
            </button>
            &nbsp;
            <button class="btn btn-smd btn-warning">
              <i class="fa fa-users"></i>
            </button>
            &nbsp;
            <button class="btn btn-smd btn-danger">
              <i class="fa fa-cogs"></i>
            </button>
          </div>
        </div>

      </div>


      <div class="sidebar-section-item">
        <i class="fadeinable fa fa-search text-info mr-n1"></i>

        <div class="fadeable d-inline-flex align-items-center ml-3 ml-lg-0">
          <i class="fa fa-search mr-n3 text-info"></i>
          <input type="text" class="sidebar-search-input pl-4 pr-3 mr-n2" maxlength="60" placeholder="Buscar ..." aria-label="Search" aria-describedby="basic-search" />
          <a href="#"><i class="fa fa-microphone text-muted ml-n1"></i></a>
        </div>
      </div>
    </div>

    <ul class="nav has-active-border" role="navigation" aria-label="Main">


      <li class="nav-item-caption">
        <span class="fadeable pl-3">Menú</span>
        <span class="fadeinable mt-n2 text-125">&hellip;</span>
        <!--
           OR something like the following (with .hideable text)
         -->
        <!--
           <div class="hideable">
             <span class="pl-3">MAIN</span>
           </div>
           <span class="fadeinable mt-n2 text-125">&hellip;</span>
         -->
      </li>
      <li id="{{aplicacion.aplicacion.url}}1" class="nav-item" *ngFor="let aplicacion of usuario.aplicaciones | orderBy:'aplicacion.titulo'; let i = index;"
        [ngClass]="{'d-none':(!aplicacion.puede_crear && !aplicacion.puede_ver && !aplicacion.puede_modificar && !aplicacion.puede_eliminar)}">

        <a href="javascript:void(0)" [state]="aplicacion" routerLink="/{{aplicacion.aplicacion.url}}" routerLinkActive="active" class="nav-link" ng-class="{'dropdown-toggle':aplicacion.aplicacion.subaplicaciones.length>0}">
          <i class="nav-icon {{aplicacion.aplicacion.atributo_clase}}"></i>
          <span class="nav-text fadeable">
            <span>{{aplicacion.aplicacion.titulos.length>0?aplicacion.aplicacion.titulos[0].titulo:aplicacion.aplicacion.titulo}}</span>
          </span>
          <b class="caret fa fa-angle-left rt-n90"></b>
        </a>

        <div class="hideable submenu collapse" *ngIf="aplicacion.aplicacion.subaplicaciones.length>0">
          <ul class="submenu-inner">
            <li id="{{subaplicacion.aplicacion.url}}1" class="nav-item" *ngFor="let subaplicacion of aplicacion.subaplicaciones; let j=index;" [ngClass]="{'d-none':(!subaplicacion.puede_crear && !subaplicacion.puede_ver && !subaplicacion.puede_modificar && !subaplicacion.puede_eliminar)}">
              <a routerLink="/{{subaplicacion.aplicacion.url}}" routerLinkActive="active" class="nav-link">
                <i class="nav-icon {{subaplicacion.aplicacion.atributo_clase}}"></i>
                <span class="nav-text">
                  <span>{{subaplicacion.aplicacion.titulo}}</span>
                </span>
							</a>
							<b class="arrow"></b>
						</li>
          </ul>
        </div>

        <b class="sub-arrow"></b>





      </li>


      

    </ul>

  </div><!-- /.sidebar scroll -->


  <div class="sidebar-section">
    <div class="sidebar-section-item fadeable-bottom">
      <div class="fadeinable">
        <div class="pos-rel">
          <img src="{{rest_server+usuario.persona.imagen}}" width="42" class="radius-round float-left mx-2 border-2 px-1px brc-secondary-m2" />
          <span class="bgc-success-tp2 radius-round border-2 brc-white p-1 position-tr mr-1"></span>
        </div>
      </div>

      <div class="fadeable hideable w-100 bg-transparent shadow-none border-0">
        <div id="sidebar-footer-bg" class="bgc-white d-flex align-items-center shadow-sm mx-2 mt-2px py-2 radius-t-1 border-1 border-t-2 border-b-0 brc-primary-m3">
          <div class="d-flex mr-auto py-1">
            <div class="pos-rel">
              <img src="{{rest_server+usuario.persona.imagen}}" width="42" class="radius-round float-left mx-2 border-2 px-1px brc-default-m2" />
              <span class="bgc-success-tp2 radius-round border-2 brc-white p-1 position-tr mr-1 mt-1"></span>
            </div>

            <div>
              <span class="text-blue font-bolder">{{usuario.persona.nombres}}</span>
              <div class="text-80 text-grey">
                
              </div>
            </div>
          </div>

          <a href="#" class="btn btn-outline-blue border-0 p-2 mr-2px ml-4" title="Configuración página" data-toggle="modal" data-target="#id-ace-settings-modal">
            <i class="fa fa-cog text-150"></i>
          </a>

          <a href="javascript:void(0)" (click)="cerrarSesion()" class="btn btn-outline-warning border-0 p-2 mr-1" title="Salir">
            <i class="fa fa-sign-out-alt text-150"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>