import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../../models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { MensajeriaService } from '../../services/mensajeria/mensajeria.service';
import { MovimientoInventarioService } from '../../../snapquick/services/movimiento-inventario/movimiento-inventario.service';
import { GlobalVariable } from 'src/app/global';
import { UsuarioService } from '../../services/usuario/usuario.service';

declare const aplicarScriptPaginaMensajeria:any;
declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css']
})
export class MensajeriaComponent extends BaseComponent implements OnInit {

  mensajes:any[]=[];
  vista_mensaje:boolean=false;
  mensaje:any={};
  movimiento_inventario_mensaje:any={};
  contador_mensajes_no_leidos:number=0;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public mensajeriaService:MensajeriaService,
    private movimientoInventarioService:MovimientoInventarioService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    this.filter={
			id_usuario:this.usuario.id
    }
    this.obtenerMensajesRecibidos();
    this.obtenerContadorMensajesNoLeidos();
  }

  ngAfterViewInit(){
    aplicarScriptPaginaMensajeria();
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerMensajesRecibidos(){
		this.column = "createdAt";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.mensajeriaService.obtenerMensajesRecibidos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.mensajes=dato.mensajes;
			this.blockUI.stop();
		});
  }
  
  verMensaje(mensaje){
    this.mensaje=mensaje;
    if(mensaje.clase.nombre_corto==GlobalVariable.Dictionary.TIPO_MENSAJE_TRASPASO){
      this.blockUI.start();
      this.movimientoInventarioService.obtenerMovimientoInventario(JSON.parse(mensaje.dato).id).subscribe((datos:any)=>{
        this.movimiento_inventario_mensaje=datos.movimiento_inventario;
        this.blockUI.stop();
        this.vista_mensaje=true;
      });
    }
    mensaje.receptores[0].leido=true;
    this.usuarioService.actualizarMensajeLeido(mensaje.receptores[0]).subscribe((res:any)=>{
      this.obtenerContadorMensajesNoLeidos();
      this.blockUI.stop();
    });
  }

  onVistaBandeja($event) {
    //this.pedido = $event
    this.vista_mensaje=false;
    this.getItems();
  }

  obtenerContadorMensajesNoLeidos(){
    this.generalService.obtenerContadorMensajes({id_usuario:this.usuario.id}).subscribe((dato:any)=>{
      this.contador_mensajes_no_leidos=dato.contador;
    });
  }

}
