<div class="sidebar-inner align-items-xl-end border-b-1 brc-grey-l1 border-r-0 shadow-none">

    <div class="sidebar-section d-none d-xl-block align-self-center">
      <button type="button" class="d-style btn align-self-center ml-2 btn-outline-grey" data-toggle="sidebar" data-toggle-class="collapsed-h" data-target="#sidebar2" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
        <i class="fa fa-minus d-n-collapsed"></i>
        <i class="fa fa-plus d-collapsed"></i>
      </button><!-- desktop sidebar toggler button -->
    </div>


    <div class="d-xl-none text-right position-tr" style="z-index: 5;">
      <button type="button" class="btn collapsed btn-light-red radius-0" data-toggle-mobile="sidebar" data-target="#sidebar2" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
        <i class="fa fa-times"></i>
      </button><!-- mobile sidebar toggler button -->
    </div>


    <div class="ml-xl-3 flex-grow-1 flex-xl-grow-0 d-xl-flex flex-xl-row ace-scroll" ace-scroll>
      <div class="nav w-auto has-active-border active-on-right active-on-top" role="navigation" aria-label="Main">


        <li class="nav-item-caption">
          <span class="fadeable pl-3">MAIN</span>
          <span class="fadeinable mt-n2 text-125">&hellip;</span>
          <!--
                      OR something like the following (with .hideable text)
                  -->
          <!--
                      <div class="hideable">
                          <span class="pl-3">MAIN</span>
                      </div>
                      <span class="fadeinable mt-n2 text-125">&hellip;</span>
                  -->
        </li>


        
        <li id="{{aplicacion.aplicacion.url}}12" class="nav-item" *ngFor="let aplicacion of usuario.aplicaciones | orderBy:'aplicacion.titulo' ; let i = index;"
              [ngClass]="{'d-none':(!aplicacion.puede_crear && !aplicacion.puede_ver && !aplicacion.puede_modificar && !aplicacion.puede_eliminar)}">

              <a href="javascript:void(0)" [state]="aplicacion" routerLink="/{{aplicacion.aplicacion.url}}" routerLinkActive="active" class="nav-link" [ngClass]="{'dropdown-toggle':aplicacion.aplicacion.subaplicaciones.length>0}">
                  <i class="nav-icon {{aplicacion.aplicacion.atributo_clase}}"></i>
                  <span class="nav-text fadeable">
                      <span>{{aplicacion.aplicacion.titulos.length>0?aplicacion.aplicacion.titulos[0].titulo:aplicacion.aplicacion.titulo}}</span>
                  </span>
              </a>

              <div class="hideable submenu collapse" *ngIf="aplicacion.aplicacion.subaplicaciones.length>0">
                  <ul class="submenu-inner">
                      <li id="{{subaplicacion.aplicacion.url}}1" class="nav-item" *ngFor="let subaplicacion of aplicacion.subaplicaciones; let j=index;" [ngClass]="{'d-none':(!subaplicacion.puede_crear && !subaplicacion.puede_ver && !subaplicacion.puede_modificar && !subaplicacion.puede_eliminar)}">
                          <a routerLink="/{{subaplicacion.aplicacion.url}}" routerLinkActive="active" class="nav-link">
                              <i class="nav-icon {{subaplicacion.aplicacion.atributo_clase}}"></i>
                              <span class="nav-text">
                                  <span>{{subaplicacion.aplicacion.titulo}}</span>
                              </span>
                          </a>
                      </li>
                  </ul>
              </div>
              <b class="sub-arrow"></b>
          </li>
      </div>
    </div>
  </div>