<div class="modal-header">
    <div class="col-3">
        <h5 class="widget-title">{{titulo_reporte}}:</h5>
        <h4 class="widget-title">{{proveedor.identificacion}}</h4>
    </div>
    <div class="col-6">
        <div class="row" *ngIf="mostrar_filtro_fechas">
            <div class="col-6" *ngIf="mostrar_filtro_fechas">
                <div class="input-group">
                    <label for="feini">Desde </label>&nbsp;
                    <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                    </div>
                </div>  
            </div>
            <div class="col-6" *ngIf="mostrar_filtro_fechas">
                <div class="input-group">
                    <label for="fefin">Hasta </label>&nbsp;
                    <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                    <legend >General</legend>
                    <div class="row">
                        <div class="col-12">
                            <button [disabled]="!proveedor.compras || proveedor.compras.length==0" class="btn btn-danger" type="button" (click)="generarPdf()">
                                <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
                            </button>&nbsp;
                            <button [disabled]="!proveedor.compras || proveedor.compras.length==0" class="btn btn-success" type="button" (click)="generarExcel()">
                                <i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
                            </button>&nbsp;
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-6">
                <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                    <legend >Mayores</legend>
                    <div class="row">
                        <div class="col-12">
                            <!--<button [disabled]="!cliente.ventas || cliente.ventas.length==0" class="btn btn-danger" type="button" (click)="generarPdfMayores()">
                                <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
                            </button>&nbsp;-->
                            <button [disabled]="!proveedor.compras || proveedor.compras.length==0" class="btn btn-success" type="button" (click)="generarExcelMayores()">
                                <i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
                            </button>&nbsp;
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <div class="col-3">
        <button *ngIf="total_saldo>0" class="btn btn-success" type="button" (click)="abrirPopupPagoCreditosProveedor()">
            <i class="ace-icon fa fa-money-bill-alt red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button *ngIf="mostrar_boton_busqueda" class="btn btn-primary" type="button" (click)="buscarHistorico()">
            <i class="ace-icon fa fa-search red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body  card-body">
    <div class="table-fixed-header">
        <table *ngIf="proveedor.compras_filtradas && proveedor.compras_filtradas.length>0" class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Transaccion</th>
                    <th>Nro. Doc.</th>
                    <th>Descripcion</th>
                    <th>Importe</th>
                    <th>A Cuenta</th>
                    <th>Saldo</th>
                    <th>Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let compra of proveedor.compras_filtradas">
                    <td>{{compra.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{compra.tipo_documento.nombre}} </td>
                    <td>{{compra.factura}} </td>
                    <td ></td>
                    <td>{{compra.total}}</td>
                    <td>{{compra.a_cuenta}}</td>
                    <td>{{compra.saldo}}</td>
                    <td>{{compra.usuario.nombre_usuario}}</td>
                    <td>
                        <div  class="hidden-sm hidden-xs action-buttons">
                            <a *ngIf="compra.saldo>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupPagoCreditosCompra(compra)">          
                                <i class="fa fa-money-bill-alt text-105"></i>        
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verCompra(compra)">          
                                <i class="fa fa-search text-105"></i>        
                            </a>
                            <a *ngIf="compra.pagosCompra.length!=0" class="verDetallePago btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verDetallePagos(compra)">          
                                <i class="fa fa-eye text-105"></i>        
                            </a>
                            
                            <table  id="{{compra.id}}" style="display:none;" class="table table-striped table-bordered table-hover">
                                <thead >
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Recibo</th>
                                        <th>Saldo Anterior</th>
                                        <th>Pago</th>
                                        <th>Saldo Actual</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let pagosCompra of compra.pagosCompra | orderBy: 'fecha'">
                                        <td>{{pagosCompra.fecha | date:"dd/MM/yyyy" }}</td>
                                        <td>{{pagosCompra.numero_documento}}</td>
                                        <td>{{pagosCompra.saldo_anterior}}</td>
                                        <td>{{pagosCompra.monto_pagado}}</td>
                                        <td>{{pagosCompra.total}}</td>
                                        <td *ngIf="pagosCompra.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                                        <td *ngIf="!pagosCompra.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                                        <td>
                                            <div class="hidden-sm hidden-xs action-buttons">
                                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirPagoCompra(pagosCompra)">          
                                                    <i class="fa fa-print text-105"></i>        
                                                </a>
                                                <a *ngIf="pagosCompra.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" [disabled]="!aplicacion_cuentas_por_pagar.puede_eliminar" (click)="abrirPopupConfirmacionAnulacionPago(pagosCompra)">
                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>							
                    </td>
                    
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th *ngIf="usuario.empresa.usar_clientes_contactos"></th>
                    <th>Total General</th>
                    <th>{{total_importe}}</th>
                    <th>{{total_a_cuenta}}</th>
                    <th>{{total_saldo}}</th>
                    <th></th>
                    <th></th>
                </tr>
            </tbody>					
        </table>
    </div>
</div>

<ng-template #pago_general_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Registro de Pago General: {{proveedor.identificacion}}</h4>
		</div>
		<div class="col-md-6 col-xs-6">
            <button [disabled]="!pago_general.importe || pago_general.importe==0 || pago_general.importe>total_saldo" class="btn btn-primary" type="button" (click)="guardarPagoGeneral()">
				<i class="ace-icon fa fa-save red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
            </button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
                    <div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Total importe</label>
						</div>
						<div class="col-sm-12 col-md-6">
							Bs {{total_importe}}
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Total A Cuenta</label>
						</div>
						<div class="col-sm-12 col-md-6">
							Bs {{total_a_cuenta}}
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Total Saldo</label>
						</div>
						<div class="col-sm-12 col-md-6">
							Bs {{total_saldo}}
						</div>
					</div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <label for="fefin">Fecha </label>&nbsp;
                        </div>
                        <div class="col-6">
                            <div class="input-group">
                                <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                                        name="dp" [(ngModel)]="pago_general.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div> 
                        </div>
                    </div>
					<hr>
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Importe a pagar</label>
						</div>
						<div class="col-sm-12 col-md-6">
							Bs <input required type="number" step="0.001" (keyup)="calcularSaldoActualizado()" (change)="calcularSaldoActualizado()" [(ngModel)]="pago_general.importe" placeholder="importe" class="form-control"/>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-sm-12 col-md-6">
							<label>Saldo Actualizado</label>
						</div>
						<div class="col-sm-12 col-md-6">
							Bs {{pago_general.saldo_actualizado}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>