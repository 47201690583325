import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { Router } from '@angular/router';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { VecinosService } from '../../services/vecinos/vecinos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { LecturaMedidorService } from '../../../neighborhood/services/lectura-medidor/lectura-medidor.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PageConfiguration } from 'src/app/models/page-configuration';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import * as XLSX from 'xlsx';
import { VecinoLecturacionComponent } from '../vecino-lecturacion/vecino-lecturacion.component';

declare const resaltarPestañaMenu:any;
declare const $:any;

@Component({
  selector: 'app-lecturacion',
  templateUrl: './lecturacion.component.html',
  styleUrls: ['./lecturacion.component.css']
})
export class LecturacionComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  configuracion_pagina:any={};
  lectura_medidor:any={};
  gestiones:any[]=[];
  meses:any[]=[];
  lecturas_medidores:any[]=[];
  lecturas_historicas:any[]=[];
  vecino_seleccionado:boolean=false;
  vecino:any={};
  filtro_lecturacion:any;
  acciones_agua:any=[];
  acciones_agua_filtro:any=[];


  rest_server=GlobalVariable.SERVER_URL;

  searching = false;
  searchFailed = false;

  modalRegistroLecturacion:NgbModalRef;

  edicionlectura:NgbModalRef;
  @ViewChild('edicionlectura')
  private edicionlecturaRef: TemplateRef<any>;

  importacionlecturacionhistoricaRef:NgbModalRef;
  @ViewChild('importacionlecturacionhistorica')
  private importacionlecturacionhistorica: TemplateRef<any>;

  registrolecturacion:NgbModalRef;
  @ViewChild('registrolecturacion')
  private registrolecturacionRef: TemplateRef<any>;
  

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
	public vecinoService:VecinosService,
    public usuarioService:UsuarioService,
	public excelService:ExcelService,
	public pdfService:PdfService,
	public modalService: NgbModal,
	public ventasService:VentasService,
	private toastr: ToastrService,
	private lecturaMedidorService:LecturaMedidorService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
	this.buscarAplicacion(this.router.url.substring(1));
	let fecha_actual=new Date();
	this.filter={
		id_empresa:this.usuario.id_empresa,
		periodo: {nombre:"TODOS",id:0},
		periodos:[{nombre:"TODOS",id:0}],
		mes: {nombre:"TODOS",id:0},
		meses:[{nombre:"TODOS",id:0}],
		categoria: {nombre:"TODOS",id:0},
		categorias:[{nombre:"TODOS",id:0}],
		fecha_inicio:{
			year: fecha_actual.getFullYear(), 
			month: (fecha_actual.getMonth()+1),
			day:fecha_actual.getDate()
		},
		fecha_fin:{
			year: fecha_actual.getFullYear(), 
			month: (fecha_actual.getMonth()+1),
			day:fecha_actual.getDate()
		}
    }
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_LECTURACION);
	this.obtenerPeriodos();
	this.obtenerMeses();
	this.obtenerCategorias();
	this.obtenerLecturasMedidor();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
	}

	obtenerLecturasMedidor(){
		this.column = "updatedAt";
		this.direction = "desc";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.lecturaMedidorService.obtenerListaLecturasMedidores(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.lecturas_medidores=dato.lecturas_medidores;
			this.blockUI.stop();
		});
	}

  obtenerPeriodos(){
    this.generalService.obtenerClases("GTN").subscribe((entidad:any) => {
		this.filter.periodos=this.filter.periodos.concat(entidad.clases);
		this.gestiones=entidad.clases;
    });
  }

  obtenerCategorias(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((entidad:any) => {
		this.filter.categorias=this.filter.categorias.concat(entidad.clases);
    });
  }

  obtenerMeses(){
    this.generalService.obtenerClases("MESES").subscribe((entidad:any) => {
		this.filter.meses=this.filter.meses.concat(entidad.clases);
		this.meses=entidad.clases;
    });
  }

  subirExcelLecturas($e){
  }

  descargarCatalogo(){
	this.blockUI.start('Recuperando... espere por favor!');
			console.log(this.lecturas_medidores.length)
			this.blockUI.stop();

			let data=[];
			let cabecera=[];
			cabecera.push("CÓDIGO");
			cabecera.push("NOMBRE");
			cabecera.push("CODIGO MEDIDOR");
			cabecera.push("GESTION");
			cabecera.push("MES");
			cabecera.push("CATEGORIA");
			cabecera.push("LECT. ANT");
			cabecera.push("LECT. ACT");
			cabecera.push("CONSUMO");
			cabecera.push("¿INFERIDO?");
			cabecera.push("OBSERVACION");
	
			for (let i = 0; i < this.lecturas_medidores.length; i++) {
				let columns = [];
				columns.push(this.lecturas_medidores[i].accion_agua.vecino.cliente.codigo);
				columns.push(this.lecturas_medidores[i].accion_agua.vecino.cliente.identificacion);
				columns.push(this.lecturas_medidores[i].accion_agua.codigo_medidor)
				columns.push(this.lecturas_medidores[i].gestion.nombre);
				columns.push(this.lecturas_medidores[i].mes.nombre);
				columns.push(this.lecturas_medidores[i].accion_agua.categoria.nombre);
				columns.push(this.lecturas_medidores[i].lectura_anterior);
				columns.push(this.lecturas_medidores[i].lectura_actual);
				columns.push(this.lecturas_medidores[i].consumo_cubos);
				data.push(columns);
			}
			let reportData = {
				title: "DATOS-CATALOGO",
				data: data,
				headers: cabecera
			}
			this.excelService.descargarFormatoLecturacionExcel(reportData,"DATOS-CATALOGO","LECTURACION-CATALOGO");

  }

  descargarExcelEjemploImportacion(){
	  this.blockUI.start();
	this.lecturaMedidorService.obtenerVecinosLecturacionExportacion(this.filter).subscribe((acciones_agua:any[]) => {
		this.blockUI.stop();
		let data=[];
		let cabecera=[];
		cabecera.push("CÓDIGO VECINO");
		cabecera.push("NOMBRE");
		cabecera.push("CODIGO MEDIDOR");
		cabecera.push("LECTURA ACTUAL");
		cabecera.push("OBSERVACIÓN");
		for (let i = 0; i < acciones_agua.length; i++) {
			let columns = [];
			columns.push(acciones_agua[i].vecino.cliente.codigo);
			columns.push(acciones_agua[i].vecino.cliente.persona.apellido_paterno);
			columns.push(acciones_agua[i].codigo_medidor);
			columns.push("");
			columns.push("");
			data.push(columns);
		}

		let reportData = {
			title: "EJEMPLO-DATOS-LECTURACION",
			data: data,
			headers: cabecera
		}

		this.excelService.descargarFormatoLecturacionExcel(reportData,"EJEMPLO-DATOS-LECTURACION","LECTURACION");
    });
  }

  descargarExcelImportacion(){
		this.blockUI.start();
		this.lecturaMedidorService.obtenerLecturasRegistro(this.filtro_lecturacion).subscribe((acciones_agua:any[]) => {
			this.blockUI.stop();
			let data=[];
			let cabecera=[];

			cabecera.push("Codigo medidor");
			cabecera.push("Codigo vecino");
			cabecera.push("Nombres");
			cabecera.push("Categoria");
			cabecera.push("Gestiòn");
			cabecera.push("Mes");
			cabecera.push("Lect. Anterior");
			cabecera.push("Lect. Actual");
			cabecera.push("Consumo");
			cabecera.push("Observaciòn");
			console.log(acciones_agua);
			for (let i = 0; i < acciones_agua.length; i++) {
				let columns = [];
				columns.push(acciones_agua[i].codigo_medidor);
				columns.push(acciones_agua[i].vecino.cliente.codigo);
				columns.push(acciones_agua[i].vecino.cliente.identificacion);
				columns.push(acciones_agua[i].categoria.nombre);
				columns.push(acciones_agua[i].lecturas_medidor[0].gestion.nombre);
				columns.push(acciones_agua[i].lecturas_medidor[0].mes.nombre);
				columns.push(acciones_agua[i].lecturas_medidor[0].lectura_anterior);
				columns.push(acciones_agua[i].lecturas_medidor[0].lectura_actual);
				columns.push(acciones_agua[i].lecturas_medidor[0].consumo_cubos);
				columns.push(acciones_agua[i].observacion);
				data.push(columns);
			}
			let reportData = {
				title: "DATOS-LECTURACION",
				data: data,
				headers: cabecera
			}
			this.excelService.descargarFormatoLecturacionExcel(reportData,"DATOS-LECTURACION","LECTURACION-IMPORTACION");
	  });
  }

  descargarExcelEjemploImportacionHistorial(){
	  this.blockUI.start();
	  let data=[];
		let cabecera=[];
		cabecera.push("CÓDIGO VECINO");
		cabecera.push("NOMBRE");
		cabecera.push("CODIGO MEDIDOR");
		cabecera.push("CATEGORIA");
		cabecera.push("GESTIÓN");
		cabecera.push("MES");
		cabecera.push("LECTURA ANTERIOR");
		cabecera.push("LECTURA ACTUAL");
		cabecera.push("CONSUMO");
		cabecera.push("OBSERVACIÓN");

		let reportData = {
			title: "EJEMPLO-DATOS-LECTURACION-HISTORICO",
			data: data,
			headers: cabecera
		}

		this.excelService.descargarFormatoLecturacionExcel(reportData,"EJEMPLO-DATOS-LECTURACION-HISTORICO","LECTURACION-HISTORICO");	
		this.blockUI.stop();
  }

	crearNuevaLectura(){
		this.vecino=null;
		this.vecino_seleccionado=false;
		let fecha_actual=new Date();
		let mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(fecha_actual.getMonth()+1).toString())[0];
		this.meses=this.meses.filter(e => parseInt(e.nombre_corto)>=(parseInt(mes_actual.nombre_corto)-1));
		let gestion_actual;
		if(mes_actual.nombre_corto=="1"){
			mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(12).toString())[0];
			gestion_actual=this.gestiones.filter(e => e.nombre_corto.toString()==(fecha_actual.getFullYear()-1).toString())[0];
			this.gestiones=this.gestiones.filter(e => parseInt(e.nombre_corto)>=(fecha_actual.getFullYear()-1));
		}else{
			mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(parseInt(mes_actual.nombre_corto)-1).toString())[0];
			gestion_actual=this.gestiones.filter(e => e.nombre_corto.toString()==fecha_actual.getFullYear().toString())[0];
			this.gestiones=this.gestiones.filter(e => e.nombre_corto.toString()==fecha_actual.getFullYear().toString());
		}
		this.lectura_medidor={
			gestion:gestion_actual,
			mes:mes_actual,
			accion_agua:null,
			inferido:false
		}
		this.abrirRegistroLecturaMedidor();
	}

	modificarLecturaMedidor(lectura_medidor){
		this.lectura_medidor=lectura_medidor;
		//this.lectura_medidor.vecino=lectura_medidor.accion_agua.vecino;
		//this.lectura_medidor.vecino.acciones_agua=[lectura_medidor.accion_agua];
		let accion_agua=JSON.parse(JSON.stringify(lectura_medidor.accion_agua));
		//accion_agua.vecino={};
		this.vecino.acciones_agua=[accion_agua];
		this.vecino_seleccionado=true;
		this.buscarLecturaAnterior();
		this.abrirRegistroLecturaMedidor();
	}

	abrirRegistroLecturaMedidor(){
		this.edicionlectura=this.modalService.open(this.edicionlecturaRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.edicionlectura.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	establecerVecino(){
		this.vecino_seleccionado=true;
		//this.lectura_medidor.accion_agua=this.lectura_medidor.vecino.acciones_agua[0];
	}

	limpiarVecino(){
		this.vecino_seleccionado=false;
	}

	buscarVecino = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.vecinoService.buscarVecino(this.usuario.id_empresa,0,term) ),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
	formatter = (x: {cliente: {persona:any}}) => (x.cliente.persona.apellido_paterno+" "+x.cliente.persona.apellido_materno+" "+x.cliente.persona.nombres);

	buscarLecturaAnterior(){
		if(this.lectura_medidor.accion_agua){
			this.blockUI.start();
			if(parseInt(this.lectura_medidor.mes.nombre_corto)==1){
				this.lecturaMedidorService.obtenerLecturaDiciembreGestionAnterior(this.lectura_medidor.accion_agua.id,(parseInt(this.lectura_medidor.gestion.nombre_corto)-1)).subscribe((lectura_medidor_diciembre:any)=>{
					this.lectura_medidor.lectura_anterior=lectura_medidor_diciembre.lectura_actual;
					this.blockUI.stop();
				});
			}else{
				this.lecturaMedidorService.obtenerLecturaAnterior(this.lectura_medidor).subscribe((res:any) => {
					this.lectura_medidor.lectura_anterior=res.lectura_anterior;
					this.lectura_medidor.lectura_actual=res.lectura_medidor.lectura_actual;
					this.lectura_medidor.consumo_cubos=res.lectura_medidor.consumo_cubos;
					this.lectura_medidor.observacion=res.lectura_medidor.observacion;
					this.calcularConsumo();
					this.blockUI.stop();
				});
			}
		}
	}

	calcularConsumo(){
		this.lectura_medidor.consumo_cubos=this.lectura_medidor.lectura_actual-this.lectura_medidor.lectura_anterior;
	}

	guardarLecturaMedidor(){
		this.blockUI.start();
		this.lecturaMedidorService.guardarLecturaMedidor(this.lectura_medidor).subscribe((res:any) => {
			this.getItems();
			this.edicionlectura.close();
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
	}

	subirExcelLecturacionHistorica(event){
		this.blockUI.start();
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
				let vecinos=[];
				do {
					let vecino:any={};
					vecino.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
					vecino.nombres=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
					vecino.codigo_medidor=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
					vecino.categoria=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
					vecino.gestion=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
					vecino.mes=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
					vecino.lectura_anterior=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?parseInt(worksheet['G'+row].v.toString()):0;
					vecino.lectura_actual=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?parseInt(worksheet['H'+row].v.toString()):0;
					vecino.consumo_cubos=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?parseInt(worksheet['I'+row].v.toString()):0;
					vecino.observacion=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?worksheet['J'+row].v.toString():null;				
					vecinos.push(vecino);
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);
				//if(!me.usuario.empresa.usar_pedidos && productos.length>500){
				//	me.mensaje_importacion="Número de productos máximo permitido: 50";
				//}
				me.blockUI.stop();
				me.lecturas_historicas=vecinos;
				me.importacionlecturacionhistoricaRef=me.modalService.open(me.importacionlecturacionhistorica, {windowClass:"importacion-vecinos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
				me.importacionlecturacionhistoricaRef.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
				//aplicarCongeladorCabecera();
				//this.abrirPopup(this.idModalVistaPreviaImportacion);
				//this.guardarProductos(productos);
				//blockUI.stop();*/
			};
			reader.readAsBinaryString(f);
	    }  
  }

  guardarLecturacionHistorica(){
	this.blockUI.start();
	this.lecturaMedidorService.guardarLecturacionHistorica(this.lecturas_historicas,this.usuario.id_empresa).subscribe((res:any) => {
		this.importacionlecturacionhistoricaRef.close();
		this.getItems();
		this.blockUI.stop();
		this.toastr.success(res.mensaje);
	});
  }

  	abrirListaLecturacion(){
		let fecha_actual=new Date();
		let mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(fecha_actual.getMonth()+1).toString())[0];
		//this.meses=this.meses.filter(e => parseInt(e.nombre_corto)>=(parseInt(mes_actual.nombre_corto)-6));
		let gestion_actual;
		if(mes_actual.nombre_corto=="1"){
			mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(12).toString())[0];
			gestion_actual=this.gestiones.filter(e => e.nombre_corto.toString()==(fecha_actual.getFullYear()-1).toString())[0];
			//this.gestiones=this.gestiones.filter(e => parseInt(e.nombre_corto)>=(fecha_actual.getFullYear()-1));
		}else{
			mes_actual=this.meses.filter(e => e.nombre_corto.toString()==(parseInt(mes_actual.nombre_corto)-1).toString())[0];
			gestion_actual=this.gestiones.filter(e => e.nombre_corto.toString()==fecha_actual.getFullYear().toString())[0];
			//this.gestiones=this.gestiones.filter(e => e.nombre_corto.toString()==fecha_actual.getFullYear().toString());
		}
		this.filtro_lecturacion={
			gestion:gestion_actual,
			mes:mes_actual,
			categoria:{id:0,nombre:'TODOS'}
		}
		this.registrolecturacion=this.modalService.open(this.registrolecturacionRef, {ariaLabelledBy: 'modal-basic-title',windowClass:'registrolecturacion', backdrop: 'static'});
		this.registrolecturacion.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	buscarLecturasMedidor(){
		this.blockUI.start();
		this.lecturaMedidorService.obtenerLecturasRegistro(this.filtro_lecturacion).subscribe((res:any[]) => {
			this.acciones_agua=res;
			this.acciones_agua_filtro=res;
			this.blockUI.stop();
		});
	}

	filtrarLecturacionRegistro(texto: string) {
		let texto_minus = texto.toLowerCase();
		if(texto === '' ) {
			this.acciones_agua_filtro=this.acciones_agua;
		} else {
		  this.acciones_agua_filtro = this.acciones_agua.filter((accion_agua) => ((accion_agua.vecino.cliente.persona.apellido_paterno+" "+accion_agua.vecino.cliente.persona.apellido_materno+" "+accion_agua.vecino.cliente.persona.nombres).toLowerCase().includes(texto_minus) || (accion_agua.vecino.cliente.codigo).toLowerCase().includes(texto_minus)));
		}
	 }

	 establecerlecturaMedidor(lectura_medidor){
		this.lectura_medidor=lectura_medidor;
		this.lectura_medidor.accion_agua={id:this.lectura_medidor.id_accion_agua};
	}

	 sincronizarLecturaAnterior(lectura_medidor){
		 this.blockUI.start();
		 this.establecerlecturaMedidor(lectura_medidor);
		this.lecturaMedidorService.obtenerLecturaAnterior(this.lectura_medidor).subscribe((res:any) => {
			this.lectura_medidor.lectura_anterior=res.lectura_anterior?res.lectura_anterior:0;
			this.calcularConsumo();
			this.blockUI.stop();
		});
	 }

	 enfocarLectura=function(keyEvent,fila){
		if (keyEvent.which == '38') {
			// up arrow
			keyEvent.preventDefault();
			setTimeout(function() {
				$("#sc"+(fila-1)).focus();
			},0);
		}
		else if (keyEvent.which == '40') {
			// down arrow
			keyEvent.preventDefault();
			setTimeout(function() {
				$("#sc"+(fila+1)).focus();
			},0);
		}
	}

	guardarLecturaMedidorRegistro(){
		this.blockUI.start();
		this.calcularConsumo();
		this.lecturaMedidorService.guardarLecturaMedidor(this.lectura_medidor).subscribe((res:any) => {
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
	}

	modificarLecturacion(vecino){
		this.modalRegistroLecturacion = this.modalService.open(VecinoLecturacionComponent, {ariaLabelledBy: 'modal-basic-title',windowClass: 'registrolecturacion', backdrop: 'static'});
			this.modalRegistroLecturacion.componentInstance.vecino = vecino;
			this.modalRegistroLecturacion.componentInstance.usuario = this.usuario;
			
			this.modalRegistroLecturacion.componentInstance.alTerminar.subscribe((res) => {
				if(res.mensaje){
			this.toastr.success(res.mensaje);
			
			}
			this.modalRegistroLecturacion.close();
		});
	}

	imprimirLecturacionSocios(){
		this.blockUI.start();
		this.lecturaMedidorService.obtenerLecturasRegistro(this.filtro_lecturacion).subscribe((acciones_agua:any[]) => {
			this.blockUI.stop();
			this.pdfService.imprimirReporteLecturacionSocios(this.acciones_agua, this.usuario, this.filtro_lecturacion);
		});
	}

	imprimirCatalogoLecturacion(){
		this.blockUI.start('Recuperando... espere por favor!');
			console.log(this.lecturas_medidores);
			this.blockUI.stop();
			console.log(this.filter.fecha_inicio);
			console.log(this.filter.fecha_fin);
			console.log(this.filter.categoria);
			this.pdfService.imprimirReporteCatalogoLecturacion(this.usuario, this.lecturas_medidores, this.filter.fecha_inicio, this.filter.fecha_fin, this.filter.categoria);
	}

}
