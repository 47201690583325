import { Component } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';
import { PersistenciaService } from './base/services/persistencia/persistencia.service';
import { GeneralService } from './base/services/general/general.service';
import { Socket } from 'ngx-socket-io';

declare const $:any;
declare const aplicarPluginsGeneral:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SnapquickClient';
  usuario:any={persona:{}};
  timeoutTimer:any;
  @BlockUI() blockUI: NgBlockUI;

  constructor(private persistenciaService:PersistenciaService,private router:Router,
    public generalService:GeneralService,
    private socket: Socket) { 
    
  }

  ngOnInit() {
    
    this.persistenciaService.getUsuario().subscribe(usuario => {
      this.usuario = usuario;
      if(usuario){
        let me=this;
        this.startTimers();
        this.configuracionSockets();
        $('body').mouseover(function() {
          me.resetTimers();
        });
      }
    });
  }

  ngAfterViewInit() {
    //aplicarPluginsGeneral();
  }

  resetTimers() {
    window.clearTimeout(this.timeoutTimer);
		this.startTimers();
  }
  
  startTimers() {
    if(this.usuario && this.usuario.cierre_sesion_automatico){
        let me=this;
        this.timeoutTimer = window.setTimeout(function(){
          me.logout();
        }, 900000);
		}
	}
  
  logout(){
    this.blockUI.start('Saliendo... espere por favor!');
    /*if((typeof gapi)!=undefined && this.user){
      if(gapi.auth2.getAuthInstance().isSignedIn.get()){
        gapi.auth2.getAuthInstance().signOut();
      }
    }*/
    this.usuario = null;
    this.persistenciaService.removerUsuario();
    window.localStorage.removeItem("ngStorage-usuario");//clear();
    window.localStorage.removeItem("ngStorage-token");
    window.localStorage.clear();
    this.router.navigateByUrl('/login', {skipLocationChange: false}).then(()=>{
      this.blockUI.stop();
      this.router.navigate(['/login']);
      window.location.reload()
    });
  }

  configuracionSockets(){
    let me=this;
    this.socket.on('actualizarNavegador', function (datos) {
      if (datos.usuario.id) {
        if(me.usuario.id==datos.usuario.id){
          window.location.reload();
        }
      }else{
        if(datos.empresa.id){
          if(me.usuario.id_empresa==datos.empresa.id){
            window.location.reload();
          }
        }else{
          window.location.reload();
        }
      }
    });

    this.socket.on('cerrarSesion', function (datos) {
      if (datos.usuario.id) {
        if(me.usuario.id==datos.usuario.id){
          me.logout();
        }
      }else{
        if(datos.empresa.id){
          if(me.usuario.id_empresa==datos.empresa.id){
            me.logout();
          }
        }else{
          me.logout();
        }
      }
    });
  }
}
