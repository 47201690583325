import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { CalendarioService } from '../../services/calendario/calendario.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const aplicarPluginCalendario:any;
declare const resaltarPestañaMenu:any;
declare const FullCalendar:any;
declare const $:any;

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent extends BaseComponent implements OnInit {

  registroevento:NgbModalRef;
  @ViewChild('registroevento')
  private registroeventoRef: TemplateRef<any>;
  evento:any={};
  eventos:any[]=[];
  calendar:any;
  lista_clases:any[]=['bgc-blue-d1 text-white text-95',
						'bgc-green-d2 text-white text-95',
					'bgc-red-d1 text-white text-95',
					'bgc-purple-d1 text-white text-95',
					'bgc-orange-d1 text-white text-95']

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
	public generalService:GeneralService,
	public profesoresService:ProfesoresService,
	public calendarioService:CalendarioService,
	private toastr: ToastrService,
	public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
	this.buscarAplicacion(this.router.url.substring(1));
	let fecha_actual=new Date();
	this.filter={
		id_empresa:this.usuario.id_empresa,
		gestion_actual:fecha_actual.getFullYear(),
		mes_actual:fecha_actual.getMonth(),
		tipos_eventos:[],
		registros_profesor:[{id:0,curso:{id:0,nombre:"TODOS"},materia:{id:0,nombre:'TODOS'}}],
		registro_profesor:{id:0,nombre:"TODOS"}
	}
	this.obtenerTiposEventos();
	if(this.usuario.empresa.usar_calendario_cursos){
		this.obtenerRegistrosProfesor();
	}
	this.obtenerEventos();
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    aplicarPluginCalendario();
	this.configurarCalendario();
	this.configurarBotonAnteriorSiguiente();
  }

  obtenerRegistrosProfesor(){
	  let gestion_actual_anio=new Date().getFullYear().toString();
	  this.profesoresService.obtenerGestionActual(this.usuario.id_empresa,gestion_actual_anio).subscribe((gestion_actual:any)=>{
		  if(gestion_actual){
			this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,gestion_actual.id).subscribe((registros_profesor:any[])=>{
				this.filter.registros_profesor=this.filter.registros_profesor.concat(registros_profesor);
			});
		  }
	  });
  }

  async obtenerTiposEventos(){
	this.blockUI.start();
    let tipo_eventos:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_EVENTOS).toPromise();
    this.filter.tipos_eventos=tipo_eventos?tipo_eventos.clases:[];
    this.blockUI.stop();
  }

  abrirRegistroEvento(){
		this.registroevento=this.modalService.open(this.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.registroevento.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
  }
  
  configurarCalendario(){
    let me=this;
    //for some random events to be added
	  var date = new Date();
	  var m = date.getMonth();
	  var y = date.getFullYear();
  
	  var day1 = Math.random() * 20 + 2;
	  var day2 = Math.random() * 25 + 1;
  
	// initialize the calendar
	this.calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
		themeSystem: 'bootstrap',
		locale: 'es',
		events: [],
		selectable: true,
		selectLongPressDelay: 200,
		select: function(date) {
			let hora_actual=new Date();
			me.evento={
				id_empresa:me.usuario.id_empresa,
				fecha:date.start,
				tipo:me.filter.tipos_eventos.filter(t => t.nombre_corto=="RECGRUP")[0],
				inicio_texto:{hour:hora_actual.getHours(),minute:hora_actual.getMinutes()},
				fin_texto:{hour:hora_actual.getHours()+1,minute:hora_actual.getMinutes()},
				id_usuario:me.usuario.id,
				publico:false
			}
			me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
			me.registroevento.result.then((result) => {
				me.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
			});
		},
		eventContent: function(arg) { 
			return {
			html: '<div class="row"><div class="col-12"><div class="fc-event-time">'+arg.event.start.getHours()+':'+arg.event.start.getMinutes()+' - '+arg.event.end.getHours()+':'+arg.event.end.getMinutes() +'</div></div></div><div class="row"><div class="col-12"><div class="fc-event-title">'+arg.event.title +'</div></div></div>'
			}
		},
		editable: true,
		droppable: true,
		drop: function(info) {
			me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
			me.registroevento.result.then((result) => {
				me.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
			});
		},
		eventClick: function(info) {
			me.calendarioService.obtenerEvento(info.event).subscribe((evento:any)=>{
				evento.inicio=new Date(evento.inicio);
				evento.fin=new Date(evento.fin);
				evento.fecha=new Date(evento.fecha);
				evento.inicio_texto={
					hour:evento.inicio.getHours(),
					minute:evento.inicio.getMinutes()
				}
				evento.fin_texto={
					hour:evento.fin.getHours(),
					minute:evento.fin.getMinutes()
				}
				me.evento=evento;
				me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
				me.registroevento.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
			});
		}
	
	});
	this.calendar.render();
  }

  configurarBotonAnteriorSiguiente(){
	  let me=this;
		$('.fc-prev-button').click(function(){
			if(me.filter.mes_actual==0){
				me.filter.mes_actual=11;
				me.filter.gestion_actual--;
			}
			else{
				me.filter.mes_actual--;
			}		   
		    me.obtenerEventos();
		});

		$('.fc-next-button').click(function(){			
			if(me.filter.mes_actual==11){
				me.filter.mes_actual=0;
				me.filter.gestion_actual++;
			}
			else{
				me.filter.mes_actual++;
			}		   
			me.obtenerEventos();
		});
	}

	async obtenerEventos(){
		this.blockUI.start();
		let eventos:any=await this.calendarioService.obtenerEventos(this.filter).toPromise();
		this.blockUI.stop();
		this.eventos=eventos;
		//$('#calendar').fullCalendar('removeEvents');
		let eventos_source:any[] = this.calendar.getEvents();
		eventos_source.forEach(event => {
			event.remove();
		});
		for(var i=0; i<eventos.length; i++) {
			eventos[i]={
				id:eventos[i].id,
				title:eventos[i].titulo+" "+(eventos[i].registro_profesor?(eventos[i].registro_profesor.curso.nombre_corto+" - "+eventos[i].registro_profesor.materia.nombre_corto):""),
				start: new Date(eventos[i].inicio),
				end: new Date(eventos[i].fin),
				className : this.lista_clases[Math.floor(Math.random() * 5)]
			}
			this.calendar.addEvent(eventos[i]);
			//$('#calendar').fullCalendar('renderEvent',eventos[i]);
		}
	}

	validarEvento(){
		let res=true;
		if(!this.evento.fecha){
		res=res && false;
		this.toastr.error("Debe especificar la fecha del evento!");
		}
		if(!this.evento.titulo){
		res=res && false;
		this.toastr.error("Debe especificar el título del evento!");
		}
		if(this.usuario.empresa.usar_calendario_cursos && !this.evento.registro_profesor){
		res=res && false;
		this.toastr.error("Debe especificar el curso y materia!");
		}
		if(!this.evento.inicio_texto){
		res=res && false;
		this.toastr.error("Debe especificar la hora de inicio!");
		}
		if(!this.evento.fin_texto){
			res=res && false;
			this.toastr.error("Debe especificar la hora de finalizacion!");
		}
		return res;
	}

	guardarEvento(){
		this.blockUI.start();
		if(this.validarEvento()){
			this.evento.inicio=new Date(this.evento.fecha.toUTCString());
			this.evento.inicio.setHours(this.evento.inicio_texto.hour);
			this.evento.inicio.setMinutes(this.evento.fin_texto.minute);
			this.evento.fin=new Date(this.evento.fecha.toUTCString());
			this.evento.fin.setHours(this.evento.fin_texto.hour);
			this.evento.fin.setMinutes(this.evento.fin_texto.minute);
			if(this.evento.id){
				this.calendarioService.actualizarEvento(this.evento).subscribe( (res:any)=>{
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.registroevento.close();
					this.obtenerEventos();
				});
			}else{
				this.calendarioService.guardarEvento(this.evento).subscribe( (evento_creado:any)=>{
					this.blockUI.stop();
					this.toastr.success("Evento registrado satisfactoriamente!");
					this.registroevento.close();
					this.obtenerEventos();
				});
			}
		}else{
			this.blockUI.stop();
		}
	}

	abrirPopupConfirmacionEliminacion(evento){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el evento?";
		this.popupConfirmacion.componentInstance.data = evento;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEvento($e.data);
			}
			this.popupConfirmacion.close();
        });
	}
	  
	eliminarEvento(evento){
		this.blockUI.start();
		this.calendarioService.eliminarEvento(evento).subscribe((res:any) => {
			this.toastr.info(res.mensaje);
			this.blockUI.stop();
			this.registroevento.close();
			this.obtenerEventos();
		});
  	}

}
