import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroDosificacionComponent } from 'src/app/snapquick/components/registro-dosificacion/registro-dosificacion.component';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';

declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-sucursal-facturacion',
  templateUrl: './sucursal-facturacion.component.html',
  styleUrls: ['./sucursal-facturacion.component.css']
})
export class SucursalFacturacionComponent extends BaseComponent implements OnInit {

  sucursal_modificacion:any;
  actividad_sucursal:any={};

  actividades_economicas:any[]=[];
  dosificaciones:any[]=[];

  tipoSinAmbientes:any[]=[];
  tipoSinModalidadesFacturacion:any[]=[];
  tipoSinDocumentosFacturacion:any[]=[];
  documentos_facturacion:IMultiSelectOption[]=[];

  registro_dosificacion:NgbModalRef;
  departamentos:any[]=[];
  municipios:any[]=[];
  aplicacion_empresa:any={};
  
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    public empresasService:EmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.obtenerDepartamentos();
    this.obtenerActividades();
    this.obtenerDosificaciones();
    this.blockUI.start();
    this.empresasService.obtenerSucursal(this.sucursal_modificacion.id).subscribe((sucursal:any)=>{
      this.blockUI.stop();
      this.sucursal_modificacion=sucursal;
      this.establecerDepartamento();
    });

    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa){
      this.obtenerAmbientesSin();
      this.obtenerModalidadeFacturacion();
      this.obtenerTiposDocumentosFacturacion();
    }

    let app_emp=this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_EMPRESAS);
    this.aplicacion_empresa= app_emp.length>0?app_emp[0]:{};
  }

  ngAfterViewInit(){
    let me=this;
    setTimeout(function(){
      aplicarSubidaArchivo('dropzone','preview-template',me,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:10,acceptedFiles:".p12"});
    },1000)
  }

  establecerDepartamento(){
    if(this.sucursal_modificacion.departamento_sin){
      this.generalService.obtenerClasesDatos(this.sucursal_modificacion.departamento_sin.nombre_corto+"M").subscribe((municipios:any)=>{
        this.municipios=municipios;
      });
    }
  }

  obtenerDepartamentos(){
		this.generalService.obtenerClases("DEP").subscribe((entidad:any)=>{
      this.departamentos=entidad?entidad.clases:[];
    });
  }

  cerrarComponente(){
		this.alTerminar.emit();
	}

	subirArchivo(file){
		let me=this;
		var fileToLoad = file;
		var fileReader = new FileReader();

		fileReader.onloadend = function() 
		{
			let base64EncodedImage = fileReader.result; // <--- data: base64
			me.sucursal_modificacion.sin_firma_digital=base64EncodedImage;
		}
		fileReader.readAsDataURL(fileToLoad);	
	}

	eliminarArchivo(file){
		let me=this;
		var fileToLoad = file;
		var fileReader = new FileReader();
	
		fileReader.onloadend = function() 
		{
			let base64EncodedImage = fileReader.result; // <--- data: base64
			me.sucursal_modificacion.sin_firma_digital=null;
		}
		fileReader.readAsDataURL(fileToLoad);	
	}

  obtenerAmbientesSin(){
		this.generalService.obtenerClases("SINAMB").subscribe((entidad:any)=>{
      this.tipoSinAmbientes=entidad?entidad.clases:[];
    });
  }

  obtenerModalidadeFacturacion(){
		this.generalService.obtenerClases("SINMODFACT").subscribe((entidad:any)=>{
      this.tipoSinModalidadesFacturacion=entidad?entidad.clases:[];
    });
  }

  	obtenerTiposDocumentosFacturacion(){
		this.generalService.obtenerClases("SINDOCSECTOR").subscribe((entidad:any)=>{
  			this.tipoSinDocumentosFacturacion=entidad?entidad.clases:[];
			  this.llenarDocumentosFacturacion(this.tipoSinDocumentosFacturacion);
		});
	}

  llenarDocumentosFacturacion(datos){
		this.documentos_facturacion=[];
		for(var i=0;i<datos.length;i++){
			var documento_facturacion={
				name:datos[i].nombre,
				maker: "",
				ticked:false,
				id:datos[i].id
			}
			this.documentos_facturacion.push(documento_facturacion);
		}
  }

  obtenerActividades(){
		this.generalService.obtenerClases("ACTCOM").subscribe((entidad:any)=>{
      this.actividades_economicas=entidad?entidad.clases:[];
    });
  }

  obtenerDosificaciones () {
    this.empresasService.obtenerDosificacionesEmpresa(this.usuario.id_empresa).subscribe((dosificaciones:any[])=>{
      this.dosificaciones=dosificaciones;
    });
  }

  crearNuevaDosificacion(){
    this.registro_dosificacion=this.modalService.open(RegistroDosificacionComponent, {ariaLabelledBy: 'modal-basic-title',size:'md', backdrop: 'static'});  
    this.registro_dosificacion.componentInstance.onTerminate.subscribe(($e) => {
        if($e){
            this.obtenerDosificaciones();
        }
        this.registro_dosificacion.close();
    });
  }

  agregarDosificacion(actividad_dosificacion){
    let res=true;
    if(!actividad_dosificacion.dosificacion){
      res=false;
      this.toastr.error("Debe especificar la dosificación!");
    }
    if(!actividad_dosificacion.actividad){
      res=false;
      this.toastr.error("Debe especificar la actividad económica!");
    }
    if(res){
      for(let i=0;i<this.sucursal_modificacion.actividadesDosificaciones.length;i++){
        if(actividad_dosificacion.dosificacion.id==this.sucursal_modificacion.actividadesDosificaciones[i].dosificacion.id){
          res=false;
        }
      }
      if(res){
        this.sucursal_modificacion.actividadesDosificaciones.push(actividad_dosificacion);
        this.actividad_sucursal={};
      }else{
        this.toastr.error("Ya existe una dosificacion con el numero de autorización especificado en la sucursal!");
      }
    } 
  }

  removerActividadDosificacion (actividad_dosificacion) {
    actividad_dosificacion.eliminado = true;
  }

  guardarDatosFacturacionSucursal(){
    this.blockUI.start();
    if(this.validarDatosFacturacion()){
      this.empresasService.guardarDatosFacturacion(this.sucursal_modificacion).subscribe((res:any)=>{
        this.blockUI.stop();
        this.cerrarComponente();
        this.toastr.success(res.mensaje);
        /*this.socket.emit('cerrarSesion',{empresa:this.usuario.empresa,usuario:{}});
        let me=this;
        setTimeout(function(){
          me.cerrarSesion();
        },2000);*/
      });
    }else{
      this.blockUI.stop();
    }
  }

  validarDatosFacturacion(){
    let res=true;
    if(!this.sucursal_modificacion.nombre_sin){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la sucursal!");
    }
    if(this.usuario.empresa.usar_facturacion && !this.sucursal_modificacion.razon_social){
      res=res && false;
      this.toastr.error("Debe especificar la razon social de la sucursal!");
    }
    if(this.usuario.empresa.usar_facturacion && !this.sucursal_modificacion.nit){
      res=res && false;
      this.toastr.error("Debe especificar el nit de la sucursal!");
    }
    if(!this.sucursal_modificacion.direccion_sin){
      res=res && false;
      this.toastr.error("Debe especificar la dirección de la sucursal!");
    }
    if(!this.sucursal_modificacion.departamento_sin){
      res=res && false;
      this.toastr.error("Debe especificar la ciudad de la sucursal!");
    }
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea && !this.sucursal_modificacion.municipio_sin){
      res=res && false;
      this.toastr.error("Debe especificar el municipio de la sucursal!");
    }
    if(this.usuario.empresa.usar_facturacion && this.sucursal_modificacion.actividadesDosificaciones.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos una dosificacion junto a su actividad!");
    }
    return res;
  }

  obtenerCuis(){
    this.blockUI.start();
		this.empresasService.obtenerCuis(this.sucursal_modificacion.id).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.sucursal_modificacion.sin_codigo_unico_inicio_sistema=res.res.codigo;
        this.toastr.success(res.mensaje);
      }
			this.blockUI.stop();
		});
	}

  obtenerCufd(){
    this.blockUI.start();
		this.empresasService.obtenerCufd(this.sucursal_modificacion.id).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.sucursal_modificacion.sin_cufd=res.sin_cufd;
        this.toastr.success(res.mensaje);
      }
			this.blockUI.stop();
		});
	}

}
