<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Lista de Materias</h4>
    </div>
    <div class="col-md-4 col-xs-4">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
    <div class="col-12">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-center pr-0">Nº</th>
                    <th class="text-center pr-0">Codigo</th>
                    <th class="text-center pr-0">Nombre</th>
                    <th class="text-center pr-0">Nombre Corto</th>
                    <th class="text-center pr-0">Orden</th>
                    <th class="text-center pr-0">Nivel</th>
                    <th class="text-center pr-0">Area</th>
                    <th class="text-center pr-0">
                        <div class="row">
                        <div class="col-6">
                            <a (click)="crearNuevaMateria()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" >
                                <i class="fa fa-plus text-blue text-180"></i>
                            </a>
                        </div>
                        <div class="col-6">
                            <a class="text-secondary-d1" href="javascript:void(0)">          
                            <i class="fa fa-cog text-105"></i>        
                            </a>      
                        </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let materia of materias; let i=index;">
                    <tr #cursos class="bgc-h-success-l3 jqgrow ui-row-ltr">
                        <td class="text-center pr-0">{{(i+1)}}</td>
                        <td class="text-center pr-0">{{materia.codigo}}</td>
                        <td class="text-center pr-0">{{materia.nombre}}</td>
                        <td class="text-center pr-0">{{materia.nombre_corto}}</td>
                        <td class="text-center pr-0">{{materia.orden}}</td>
                        <td class="text-center pr-0">{{materia.nivel.nombre}}</td>
                        <td class="text-center pr-0">{{materia.area.nombre}}</td>
                        <td class="text-center pr-0">
                            <a title="Modificar Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarMateria(materia)">          
                                <i class="fas fa-edit text-180"></i>        
                            </a>
                            <a title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(materia)">          
                                <i class="fas fa-trash-alt text-180"></i>        
                            </a>      
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    </div>
</div>

<ng-template #modal_modificacion_materia let-modal>
    <div class="modal-header card-header">
        <div class="col-md-6 col-xs-6">
            <span class="text-blue text-125">Registro Materia</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarMateria()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <label>Código</label>
            </div>
            <div class="col-sm-12 col-md-6">
                <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="materia.codigo" placeholder="Código" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Nombre</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input name="nombre" required type="text" [(ngModel)]="materia.nombre" placeholder="Nombre" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Nombre Corto</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input name="nombre_corto" required type="text" [(ngModel)]="materia.nombre_corto" placeholder="Nombre Corto" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Orden</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input name="orden" required type="number" [(ngModel)]="materia.orden" placeholder="Orden" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Nivel</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <select [compareWith]="compararObjectos" name="nivel" class="form-control" [(ngModel)]="materia.nivel" (change)="cambiarNivel(materia.nivel)">
                    <option [ngValue]="nivel" *ngFor="let nivel of niveles">{{nivel.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Area</label>
            </div>
            <div class="col-sm-12 col-md-2" *ngIf="areas.length==0">
                <a title="Reiniciar Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="sincronizarAreasMaterias()">
                    <i class="ace-icon fa fa-sync bigger-130"></i>
                </a>
            </div>
            <div class="col-sm-12 col-md-7">
                <select [compareWith]="compararObjectos" name="area" class="form-control" [(ngModel)]="materia.area">
                    <option [ngValue]="null" [selected]="true">Seleccionar area</option>
                    <option [ngValue]="area" *ngFor="let area of areas_nivel">{{area.nombre}}</option>
                </select>
            </div>
        </div>
    </div>
</ng-template>