import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(private http: HttpClient) { }

  public obtenerDatosReporteVentasCreditoProductosPorCliente(filter){
    let fecha_inicial=new Date(filter.fecha_inicio.year,filter.fecha_inicio.month-1,filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(filter.fecha_fin.year,filter.fecha_fin.month-1,filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/venta-credito-productos-cliente/empresa/"+filter.id_empresa+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin+"/cliente/"+((filter.cliente=="" || filter.cliente==undefined)?0:filter.cliente)+"/sucursal/"+filter.sucursal.id+"/usuario/"+filter.usuario.id,GlobalVariable.httpOptions);
  }

  public obtenerEstadoNegocioVentas(filtro,fecha_inicio,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estado-negocio-ventas/"+filtro.id_empresa+"/inicio/"+fecha_inicio+"/fin/"+fecha_fin+"/tipo-costo/"+filtro.tipo_costo.id+"/usuario/"+filtro.usuario.id,GlobalVariable.httpOptions);
  }

  public obtenerEstadoNegocioGastos(filtro,fecha_inicio,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estado-negocio-gastos/"+filtro.id_empresa+"/inicio/"+fecha_inicio+"/fin/"+fecha_fin+"/usuario/"+filtro.usuario.id,GlobalVariable.httpOptions);
  }

  public obtenerVentasProductos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/ventas-productos/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/almacen/"+paginator.filter.almacen.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto))+"/presentacion-cliente/"+paginator.filter.presentacion_cliente+"/solo-obsequios/"+(paginator.filter.solo_obsequios!=undefined && paginator.filter.solo_obsequios?1:0)+"/contacto-cliente/"+((paginator.filter.contacto_cliente=="" || paginator.filter.contacto_cliente==undefined)?0:paginator.filter.contacto_cliente)+"/producto/"+((paginator.filter.producto=="" || paginator.filter.producto==undefined)?0:paginator.filter.producto)+"/grupo/"+paginator.filter.grupo_producto.id+"/subgrupo/"+paginator.filter.subgrupo_producto.id+"/usuario/"+paginator.filter.usuario.id+"/tipo-pago/"+paginator.filter.tipo_pago.id+"/canal/"+paginator.filter.canal.id+"/tipo-item/"+paginator.filter.tipo_item.id, GlobalVariable.httpOptions);
  }

  public obtenerComprasProductos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/compras-productos/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/proveedor/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto))+"/presentacion-proveedor/"+paginator.filter.presentacion_cliente+"/producto/"+((paginator.filter.producto=="" || paginator.filter.producto==undefined)?0:paginator.filter.producto)+"/grupo/"+paginator.filter.grupo_producto.id+"/subgrupo/"+paginator.filter.subgrupo_producto.id+"/usuario/"+paginator.filter.usuario.id+"/tipo-pago/"+paginator.filter.tipo_pago.id, GlobalVariable.httpOptions);
  }

  public obtenerVentasProductosAgrupacionGrupos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/ventas-productos-agrupacion-grupos/"+paginator.filter.id_empresa+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/usuario/"+paginator.filter.usuario.id, GlobalVariable.httpOptions);
  }

  public obtenerVentasProductosAgrupacionProductos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/ventas-productos-agrupacion-productos/"+paginator.filter.id_empresa+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/usuario/"+paginator.filter.usuario.id, GlobalVariable.httpOptions);
  }

  public obtenerEstadoCuentaClientes(paginator){
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-clientes/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.usuario.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/cuentas-liquidadas/"+(paginator.filter.cuentas_liquidadas?1:0)+"/identificacion/"+((paginator.filter.cliente=="" || paginator.filter.cliente==undefined)?0:paginator.filter.cliente)+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/nombre-contacto-cliente/"+paginator.filter.nombre_contacto_cliente+"/hasta-fecha/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public obtenerPedidosProductosUsuarios(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/pedidos-productos-por-usuario/"+paginator.filter.ids_sucursales_usuario+"/empresa/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/usuario/"+paginator.filter.usuario.id, GlobalVariable.httpOptions);
  }

  public obtenerProductosMasVendidos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/ventas-productos-mas-vendidos/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/almacen/"+paginator.filter.almacen.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto))+"/solo-obsequios/"+(paginator.filter.solo_obsequios!=undefined && paginator.filter.solo_obsequios?1:0)+"/contacto-cliente/"+((paginator.filter.contacto_cliente=="" || paginator.filter.contacto_cliente==undefined)?0:paginator.filter.contacto_cliente)+"/producto/"+((paginator.filter.producto=="" || paginator.filter.producto==undefined)?0:paginator.filter.producto)+"/grupo/"+paginator.filter.grupo_producto.id+"/subgrupo/"+paginator.filter.subgrupo_producto.id+"/usuario/"+paginator.filter.usuario.id, GlobalVariable.httpOptions);
  }

  public obtenerCuentasPorCobrarEmpresa(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cuentas-por-cobrar/"+paginator.filter.id_empresa+"/cuentas-liquidadas/"+(paginator.filter.cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public obtenerProductosRotacionInventario(fi,ff,paginator){
    let fecha_inicial=fi;
    let fecha_fin=ff;
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/rotacion-inventarios/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto)), GlobalVariable.httpOptions);
  }

  public obtenerCaducidadProductos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/caducidad-productos/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto)), GlobalVariable.httpOptions);
  }

  public obtenerReporteVentas(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes-ventas/empresa/"+paginator.filter.id_empresa+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin+"/agrupador-1/"+paginator.filter.agrupador_1.valor.replace("?","1").replace("?","1")+"/agrupador-2/"+paginator.filter.agrupador_2.valor.replace("?","2").replace("?","2")+"/agrupador-3/"+paginator.filter.agrupador_3.valor.replace("?","3").replace("?","3")+"/cliente/"+(paginator.filter.presentacion_cliente=="lista"?paginator.filter.cliente.id:((paginator.filter.cliente_texto=="" || paginator.filter.cliente_texto==undefined)?0:paginator.filter.cliente_texto))+"/vendedor/"+paginator.filter.usuario.id+"/transaccion/"+paginator.filter.transaccion.id+"/tipo-pago/"+paginator.filter.tipo_pago.id+"/contacto-cliente/"+((paginator.filter.contacto_cliente=="" || paginator.filter.contacto_cliente==undefined)?0:paginator.filter.contacto_cliente)+"/sucursal/"+paginator.filter.sucursal.id, GlobalVariable.httpOptions);
  }

  public generarPruebasCodigoControl(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"pruebas-codigo-control",datos, GlobalVariable.httpOptions);
  }
 
}
