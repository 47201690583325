import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../../base/services/general/general.service';
import { PdfService } from '../../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../../base/services/persistencia/persistencia.service';
import { ComprasService } from '../../../services/compras/compras.service';
import { Util } from 'src/app/utils/utils';
import { PagoCompraComponent } from '../../pago-compra/pago-compra.component';
import { UsuarioService } from '../../../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-vencimiento-deuda',
  templateUrl: './vencimiento-deuda.component.html',
  styleUrls: ['./vencimiento-deuda.component.css']
})
export class VencimientoDeudaComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  usuario:any;
  compras_credito:any[]=[];
  compras_filtradas:any[]=[];
  filtro_compra: any ={};
  modal_pago_compra:NgbModalRef;

  constructor(
    public persistenciaService:PersistenciaService,
    public comprasService:ComprasService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  ngOnInit(): void {
    this.obtenerComprasCredito();
  }

  obtenerComprasCredito(){
    this.blockUI.start();
    let id_usuario=0;
    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    if(this.usuario.empresa.usar_control_por_usuarios){
      if(es_usuario_administrador){	
        id_usuario=0;
      }else{
        id_usuario=this.usuario.id;
      }
    }else{
      id_usuario=0;
    }

    this.comprasService.obtenerListaComprasCreditoNotificacion(this.usuario.id_empresa).subscribe((vencimientosDeudas:any[])=>{
      for (var i = 0; i < vencimientosDeudas.length; i++) {
        var fecha = new Date(vencimientosDeudas[i].fecha);
        vencimientosDeudas[i].fecha_vencimiento = Util.sumarDiasFecha(vencimientosDeudas[i].dias_credito, fecha);
        for (var j = 0; j < vencimientosDeudas[i].compraReprogramacionPagos.length; j++) {
          if (vencimientosDeudas[i].compraReprogramacionPagos[j].activo) {
            vencimientosDeudas[i].fecha_anterior = vencimientosDeudas[i].compraReprogramacionPagos[j].fecha_anterior
          }
        }

      }
      this.compras_credito = vencimientosDeudas;
      this.compras_filtradas = vencimientosDeudas;
      this.blockUI.stop();
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

   calcularDiasMora(compra){
    var fecha_compra=new Date(compra.fecha);
    var fecha_actual=new Date();
    var diferencia_tiempo = Math.abs(fecha_actual.getTime() - fecha_compra.getTime());
    var diferencia_dias = Math.ceil(diferencia_tiempo / (1000 * 3600 * 24)); 
    var diferencia=compra.dias_credito-diferencia_dias;
    if(diferencia>0){
      return 0;
    }else{
      return Math.abs(diferencia);
    }
  }

  filtrarCompras(texto: string) {
		let texto_minus = texto.toLowerCase();
		if(texto === '' ) {
			this.compras_filtradas=this.compras_credito;
		} else {
		  this.compras_filtradas = this.compras_credito.filter((compra) => ((compra.proveedor.razon_social).toLowerCase().includes(texto_minus)));
		}
   }

   sumarTotalSaldo(compras){
    var suma_saldo=0;
    for(var i=0;i<compras.length;i++){
      suma_saldo=suma_saldo+compras[i].saldo;
    }
    return suma_saldo;
  }

  crearPagoCompra(compra){
		this.modal_pago_compra = this.modalService.open(PagoCompraComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_pago_compra.componentInstance.compra = compra;
		this.modal_pago_compra.componentInstance.usuario = this.usuario;
		this.modal_pago_compra.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.obtenerComprasCredito();
					this.modal_pago_compra.close();
				}
			}else{
				this.modal_pago_compra.close();
			}
			
		});
  }

}
