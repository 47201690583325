<div class="modal-body card-body">
    <div id="dialog-panel-ventas" class="widget-body">
        <div class="row">
            <div class="col-12">
                <div class="widget-box">
                    <form id="formularioVentaPanel" name="formularioVentaPanel" novalidate>
                        <div class="widget-body">
                            <div class="widget-main {{color.style}}">
                                <div class="row">
                                    <div class="col-12">
                                            <button style="z-index:90000; position:absolute;" type="button" (click)="showHideFirstRow()" class="button-style btn {{color.stylebutton}} btn-xs pos-rel pull-left">
                                                <i class="fas fa-angle-double-down"></i>
                                            </button>
                                            <div class="row first-row">
                                                <div *ngIf="usuario.empresa.usar_mesas" class="col-md-1 col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-md-12 col-sm-12 col-12">
                                                            <label *ngIf="reserva.mesas" class="header smaller lighter blue">MESAS: <span  *ngFor="let mesa of reserva.mesas">{{mesa.numero+"-"}}</span> </label>
                                                            <label *ngIf="!reserva.mesas" class="header smaller lighter blue">MESAS: <span *ngFor="let mesaPedido of reserva.mesasPedidoRestaurante">{{mesaPedido.mesa.numero+"-"}}</span> </label>
                                                        </div>
                                                        <!--<div class="col-sm-4">
                                                            <label *ngIf="reserva.garzones"  class="header smaller lighter blue">MOZOS : <span *ngFor="let garzon of reserva.garzones"><img src="{{garzon.persona.imagen}}" width="80"></span> </label>
                                                            <label *ngIf="!reserva.garzones" class="header smaller lighter blue">MOZOS : <span *ngFor="let garzonPedido of reserva.garzonesPedidoRestaurante"><img src="{{garzonPedido.garzon.persona.imagen}}" width="80"></span></label>
                                                        </div>-->
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button (click)="guardarReserva(null)" type="button" [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" class="btn btn-primary btn-app btn-sm">
                                                        <i class="ace-icon fa fa-save align-top bigger-110"></i>
                                                        <br>GUARDAR
                                                    </button>
                                                </div>
                                                <div *ngIf="(usuario.empresa.usar_mesas || usuario.empresa.usar_pedidos_restaurante) && !usuario.empresa.usar_despachos" class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button type="button" class="btn btn-warning btn-app btn-sm" [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" (click)="generarCuenta(reserva)">
                                                        <i class="ace-icon far fa-newspaper align-top bigger-110"></i>
                                                        <br>CUENTA
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button type="button" class="btn btn-success btn-app btn-sm" [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" (click)="abrirPopupDatosFacturacion()">
                                                        <i class="ace-icon fas fa-dollar-sign align-top bigger-110"></i>
                                                        <br>COBRAR
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4">
                                                    <button type="button" class="btn btn-danger btn-app btn-sm" (click)="cerrarComponente()">
                                                        <i class="ace-icon fas fa-window-close red2 align-top bigger-110"></i>
                                                        <br> CERRAR
                                                    </button>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4" *ngIf="usuario.empresa.usar_mesas">
                                                    <div class="row">
                                                        <div class="col-6 col-md-6">
                                                            <button [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupCambioMesa()">
                                                                <i class="ace-icon fa fa-exchange-alt align-top bigger-110"></i>
                                                            </button>
                                                        </div>											
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-sm-6">
                                                            <button [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupAsignacionGarzon()">
                                                                <i class="ace-icon fa fa-male align-top bigger-110"></i>
                                                            </button>
                                                        </div>										
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-sm-4 col-md-2 col-4" *ngIf="usuario.empresa.usar_mesas">
                                                    <div class="row">
                                                        <div class="col-md-6 col-6">
                                                            <button [disabled]="reserva.detallesPedido && reserva.detallesPedido.length==0" type="button" class="btn btn-xs btn-light btn-app" ng-click="abrirPopupUnionMesas()">
                                                                <i class="ace-icon fa fa-sync-alt align-top bigger-110"></i>
                                                            </button>
                                                        </div>											
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-4 col-md-4">
                                                    <div class="group-color-button">
                                                        <div class="row">
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-verde" (click)="cambiarColor('green-style','green-style-button')"></button>		
                                                            </div>
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-rojo" (click)="cambiarColor('red-style','red-style-button')"></button>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12">
                                                                <div class="punto"></div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-azul" (click)="cambiarColor('skyblue-style','skyblue-style-button')"></button>
                                                            </div>
                                                            <div class="col-6 col-md-6">
                                                                <button type="button" class="btn boton-cafe" (click)="cambiarColor('brown-style','brown-style-button')"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 col-4 col-sm-4">
                                                    <label>ORDENAR</label>
                                                    <button type="button" class="button-style btn red-style-button btn-app btn-xs" (click)="ordenarProductos(ordenProductos)">A<- ->Z</button>
                                                </div>
                                                <div class="col-md-1 col-4 col-sm-4 d-block d-sm-block d-md-block d-lg-none">
                                                    <div class="inline pos-rel pull-right">
                                                                    <a data-toggle="dropdown" href="javascript:void(0)" class="btn btn-danger btn-sm dropdown-toggle {{color.stylebutton}}" aria-expanded="false">
                                                                        <i class="fa fa-list-alt"></i>
                                                                        <span class="caret"></span>
                                                                    </a>
                                                                    <ul id="campos-detalle-venta" class="dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret button-check-group">
                                                                        
                                                                        <input type="text" ng-model="filterText" class="imput-search">
                                                                        <li *ngFor="let grupo of grupos_productos">
                                                                            <button type="button" (click)="clasificarGrupo(grupo.id)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">{{grupo.nombre}}
                                                                            </button>
                                                                        </li>
    
                                                                        
                                                                        <li class="divider"></li>
                                                                    </ul>
                                                                </div>
                                                </div>
                                                <div class="col-md-2 col-12 col-sm-4">
                                                    <label>BÚSQUEDA</label>
                                                    <input class="form-control" placeholder="Buscar" name="search" type="text" [(ngModel)]="search" (keyup)="filtrarProductos(search)">
                                                </div>
                                                <div class="col-md-2 col-12 col-sm-4" *ngIf="usuario.empresa.usar_combos">
                                                    <div class="sidebar-shortcuts-large" id="sidebar-shortcuts-large">
                                                        <div class="row" *ngIf="grupos_productos.length>0">
                                                            <div class="col-md-12">
                                                                <button type="button" (click)="mostrarProductos()" class="button-style btn-panel {{color.stylebutton}} btn-block text-cut">PRODUCTOS</button>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <button type="button" (click)="mostrarCombos()" class="button-style btn-panel {{color.stylebutton}} btn-block text-cut">COMBOS</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>	
                            
                            
                            
                                <div class="row">
                                    <div class="col-12 col-sm-5 col-md-5 col-lg-4">
                                        <!-- PAGE CONTENT BEGINS -->
                                        <div class="row">
                                            <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-6 col-6 col-sm-6  ">
                                                <label>Sucursal</label>
                                                <select [disabled]="reserva.id" [compareWith]="compararObjectos" class="form-control" name="sucursal" [(ngModel)]="reserva.sucursal" (change)="cambiarSucursal(reserva.sucursal)">
                                                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-8 col-8 col-sm-8  ">
                                                <label>Cliente</label>
                                                <input required id="venta_razon_social" name="venta_razon_social" [disabled]="reserva.cliente && reserva.cliente.id"
                                                    [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerClientePedido($event)"
                                                    type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="razon_busqueda" 
                                                    [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off"
                                                />
                                                <ng-template #rt let-r="result" let-t="term">
                                                    <ngb-highlight [result]="(r.identificacion==r.razon_social)?r.identificacion+' '+r.nit:r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                                </ng-template>

                                                <div class="col-12 col-sm-12 col-xs-12">
                                                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="reserva.cliente && reserva.cliente.id && !reserva.id" (click)="reiniciarCliente()">
                                                        <i class="fa fa-trash bigger-130 red2"></i>
                                                    </a>
                                                    <a *ngIf="reserva.cliente && !reserva.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                                        <i class="fa fa-plus bigger-130"></i>
                                                    </a>
                                                    <a *ngIf="reserva.cliente && reserva.cliente.id" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                                        <i class="fa fa-edit bigger-130"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-4 col-sm-4  ">
                                                <label>Estado</label>&nbsp;
                                                <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="reserva.estado">
                                                    <option [ngValue]="estado" *ngFor="let estado of estados">{{estado.nombre}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6 align-items-center">
                                                <div class="input-group">
                                                    <label for="fecha_ingreso_texto">Fecha-Hora Ingreso </label>
                                                </div>
                                                <div class="input-group">
                                                    <input id="fecha_ingreso_texto" name="fecha_ingreso_texto" class="form-control" placeholder="dd/mm/yyyy" (dateSelect)="establecerFechaIngreso($event)" (blur)="establecerFechaIngreso(reserva.fecha_ingreso_texto)"
                                                            [(ngModel)]="reserva.fecha_ingreso_texto" ngbDatepicker #fi="ngbDatepicker" [minDate]="{year: fecha_minima_ingreso.year, month: fecha_minima_ingreso.month, day: fecha_minima_ingreso.day}">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6 text-center">
                                                <div class="input-group">
                                                    <ngb-timepicker (ngModelChange)="establecerFechaIngreso(reserva.fecha_ingreso_texto)" name="tiempo_ingreso_texto" [(ngModel)]="reserva.tiempo_ingreso_texto" ></ngb-timepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6 align-items-center">
                                                <div class="input-group">
                                                    <label for="fecha_ingreso_texto">Fecha-Hora Salida </label>
                                                </div>
                                                <div class="input-group">
                                                    <input id="fecha_salida_texto" name="fecha_salida_texto" class="form-control" placeholder="dd/mm/yyyy" (dateSelect)="establecerFechaSalida($event)" (blur)="establecerFechaSalida(reserva.fecha_salida_texto)"
                                                            [(ngModel)]="reserva.fecha_salida_texto" ngbDatepicker #ff="ngbDatepicker" [minDate]="{year: fecha_minima_ingreso.year, month: fecha_minima_ingreso.month, day: fecha_minima_ingreso.day}">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary calendar" (click)="ff.toggle()" type="button"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-6 col-sm-6 text-center">
                                                <div class="input-group">
                                                    <ngb-timepicker (ngModelChange)="establecerFechaSalida(reserva.fecha_salida_texto)" name="tiempo_salida_texto" [(ngModel)]="reserva.tiempo_salida_texto"></ngb-timepicker>
                                                </div>
                                            </div>
                                            <div style="overflow-y: visible;width: 100%;height:auto;" *ngIf="reserva.ambientes && reserva.ambientes.length>0">
                                                <div class="col-12 table-responsive table-container">
                                                    <table class="table table-striped table-bordered table-hover" >
                                                            <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <label>Nº</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Días</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Ambiente</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>P. Día. (Bs)</label>
                                                                        </th>
                                                                        <th>
                                                                            <label>Imp. (Bs)</label>
                                                                        </th>
                                                                        <th>
                                                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                                                            <a *ngIf="usuario.empresa.usar_obsequios" class="dropdown-item item-action" href="javascript:void(0)" (click)="convertirTodoObsequio()">
                                                                                <i class="fa fa-gift {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                            </a>
                                                                            
                                                                        </th>
                                                                    </tr>
                                                            </thead>
                                                            <tbody *ngFor="let detalle_ambiente of reserva.ambientes; let i=index;">
                                                                <ng-container *ngIf="!detalle_ambiente.eliminado">
                                                                    <tr>
                                                                        <td><!--rowspan="{{1+detallePedido.detallesPedido.length}}"-->
                                                                            {{i+1}}
                                                                        </td>
                                                                        <td *ngIf="!editar_cantidad">
                                                                            <div class="row">
                                                                                <div class="col-md-12 col-sm-12 col-12">
                                                                                    {{detalle_ambiente.cantidad}}
                                                                                </div>
                                                                            </div>
                                                                            <!--<div class="row">
                                                                                <div class="col-md-12 col-sm-12 col-12">
                                                                                    <a class="btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCantidad()">
                                                                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>-->
                                                                        </td>
                                                                        <td *ngIf="editar_cantidad">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <input name="cantidad{{i}}" step="0.001" min="0.1" required class="form-control" [(ngModel)]="detalle_ambiente.cantidad" type="number" (keyup)="calcularImporteDetallePedido(detalle_ambiente)"/>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" [disabled]="!detalle_ambiente.cantidad" href="javascript:void(0)" (click)="establecerCantidad()">
                                                                                        <i class="ace-icon fa fa-check-square bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>		
                                                                        </td>
                                                                        <td>
                                                                            {{detalle_ambiente.producto.nombre}}
                                                                        </td>
                                                                        <td *ngIf="!editar_precio">
                                                                            <div clas="row">
                                                                                <div class="col-md-6 col-sm-6 col-6">
                                                                                    {{detalle_ambiente.precio_unitario}}
                                                                                </div>
                                                                                <div class="col-md-6 col-sm-6 col-6" *ngIf="aplicacion.puede_modificar">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarPrecio()">
                                                                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td *ngIf="editar_precio">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <input name="precio_unitario{{i}}" step="0.001" min="0.1" required class="form-control" (change)="calcularImporteDetallePedido(detalle_ambiente)" (keyup)="calcularImporteDetallePedido(detalle_ambiente)" [(ngModel)]="detalle_ambiente.precio_unitario" type="number" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <a class="btn-h-outline-primary btn-a-outline-primary btn-text-primary" [disabled]="!detalle_ambiente.precio_unitario" href="javascript:void(0)" (click)="establecerPrecio()">
                                                                                        <i class="ace-icon fa fa-check-square bigger-130"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>		
                                                                        </td>
                                                                        <td>
                                                                            {{detalle_ambiente.total}}
                                                                        </td>
                                                                        <td>
                                                                            <div class='dropdown d-inline-block dd-backdrop dd-backdrop-none-md'>
                                                                                <a href='#' style="color:white !important;" class='btn {{color.stylebutton}} btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                                                                                <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                                                                                  <div class="dropdown-inner">
                                                                                    <a class="dropdown-item item-action" href="javascript:void(0)" (click)="eliminarDetalleReserva(detalle_ambiente)">
                                                                                        <i class="fa fa-trash {{color.stylebutton}} mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>
                                                                                    </a>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                            <tbody>
                                                                <tr>
                                                                    <td >
                                                                        
                                                                    </td>
                                                                    <td >
                                                                        
                                                                    </td>
                                                                    <td>
                                                                            
                                                                    </td>
                                                                    <td>
                                                                        <b>TOTALES</b>
                                                                    </td>
                                                                    <td>
                                                                        {{reserva.total}}
                                                                    </td>
                                                                    <td>
                                                                            
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- /.col -->
                                    <div class="col-12 col-sm-7 col-md-7 col-lg-8">
                                        <div class="row">
                                            <div id="productos" class="col-12">
                                                <div class="row">
                                                    <div class="col-lg-10 col-md-10 col-12 col-sm-12">
                                                        <ul class="ace-thumbnails clearfix">
                                                            <li class="swiper-slide" (click)="agregarAmbiente(producto)" *ngFor="let producto of productosProcesados">
                                                                <img src="{{global_variable.SERVER_URL+producto.imagen}}" width="100%" style="height: 98px;max-height:98px">
                                                                <h4 data-drag="true" data-jqyoui-options="{revert:true}" ng-model="producton" jqyoui-draggable="{animate:true,onStart:enviarProductoCompuesto({{producto}})}" style="white-space:initial;width:98px;height:10px;color:white;">{{producto.nombre}}</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 d-none d-lg-block d-xl-block">
                                                        <div class="row" *ngIf="grupos_productos.length>0">
                                                            <div class="col-12">
                                                                <button type="button" (click)="clasificarGrupo(0)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">TODOS
                                                                                    </button>
                                                            </div>
                                                            <div class="col-12" *ngFor="let grupo of grupos_productos">
                                                                <button type="button" (click)="clasificarGrupo(grupo.id)" class="button-style btn-panel {{color.stylebutton}} btn-block btn-minier text-cut">{{grupo.nombre}}
                                                                                    </button>
                                                            </div>
                                                        </div>
                                                    </div><!-- /.col -->
                                                </div><!-- /.row -->
                                            </div>
                                        </div>
                                    </div><!-- /.col -->
                                </div>
                                
                                
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #ventamodal let-modal>
    <div class="modal-body">
        <div class="widget-box">
            <div class="widget-body">
                <div class="widget-main">
            
                    <form name="formularioCobro" class="form-horizontal" novalidate>
                        <div class="row">
                            <div class="col-sm-3 col-md-3 col-xs-2 style-button-align" >
                                <img src="{{global_variable.SERVER_URL+'img/CAJERA.png'}}" width="30%">
                            </div>
                            <div class="col-sm-3 col-md-3 col-xs-4 style-modal-dates">
                                <i class="ace-icon fa fa-calendar  bigger-110 fa-3"></i>
                                {{venta.fechaTexto}}
                            </div>
                            <div class="col-sm-3 col-md-3 col-xs-3 style-button-align" align="center">
                                <button type="button" (click)="generarVentaReserva()" class="btn btn-app btn-primary btn-xs text-size">
                                    <i class="ace-icon fa fa-save bigger-110"></i>
                                    <br>GUARDAR
                                </button>
                            </div>
                            <div class="col-sm-3 col-md-3 col-xs-3 style-button-align" align="center">
                                <button type="button" class="btn btn-app btn-danger btn-xs text-size" (click)="modal.dismiss('Cross click')">
                                    <i class="ace-icon fa fa-window-close bigger-110"></i>
                                    <br>CANCELAR
                                </button>
                            </div>
                        </div>
                        <div class="row button-style {{color.stylebutton}}">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                <label class="control-label" for="form-field-1"> TIPO DE PAGO </label>
                                <select [compareWith]="compararObjectos" id="tipo_pago" name="tipo_pago" class="form-control" [(ngModel)]="venta.tipoPago">
                                    <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                                </select>	
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                <label class="control-label" for="form-field-1"> MOVIMIENTO </label>
                                <select [compareWith]="compararObjectos" id="movimiento" name="movimiento" class="form-control" [(ngModel)]="venta.transaccion">
                                    <option [ngValue]="transaccion" *ngFor="let transaccion of movimientosEgresoPanel">{{transaccion.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 col-12" *ngIf="venta.transaccion.nombre_corto==global_variable.Dictionary.EGRE_FACTURACION">
                                <label class="control-label" for="form-field-1"> ACTIVIDAD </label>
                                <select [compareWith]="compararObjectos" id="actividad" name="actividad" class="form-control" [(ngModel)]="venta.actividad">
                                    <option [ngValue]="actividad" *ngFor="let actividad of venta.actividades">{{actividad.nombre}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row style-totales">
                            <!--<div class="col-md-2 col-sm-2 col-xs-6">
                                <label class="control-label no-padding-right" for="form-field-1"> IMPORTE  (Bs):</label>

                                <div class="center">
                                    <label >{{venta.importe}}</label>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-6">
                                <label class="control-label no-padding-right" for="form-field-1"> DESCUENTO  (Bs):</label>
                                <input name="descuento" required type="number" step="0.01" max="{{descuento_maximo}}" [(ngModel)]="venta.descuento" (change)="calcularTotalVenta()" class="form-control"/>
                            </div>-->
                            <div class="col-md-4 col-sm-4 col-xs-6">
                                <label class="control-label no-padding-right" for="form-field-1"> TOTAL  (Bs):</label>

                                <div class="center">
                                    <h1 >{{venta.total}}</h1>
                                </div>
                            </div>
                            <ng-container *ngIf="venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                                <div class="col-md-4 col-sm-4 col-xs-6">
                                    <label class="control-label no-padding-right" for="form-field-1"> A CUENTA  (Bs):</label>
                                    <input name="a_cuenta" required type="number" step="0.01" [(ngModel)]="venta.a_cuenta" (keyup)="calcularSaldo()" class="form-control"/>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-6">
                                    <label class="control-label no-padding-right" for="form-field-1"> SALDO (Bs):</label>

                                    <div class="center">
                                        <h1 >{{venta.saldo}}</h1>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CONT">
                                <div class="col-md-4 col-sm-4 col-xs-6">
                                    <label class="control-label no-padding-right" for="form-field-1-1"> PAGADO (Bs):</label>

                                    <input id="pagadoP1" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" value="{{venta.total}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()" class="form-control"/>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-6">
                                    <label class="control-label no-padding-right" for="form-field-1-1"> CAMBIO (Bs):</label>

                                    <div align="center">
                                        <label >{{venta.cambio}}</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>