<div class="page-content container container-plus">
    <div class="page-header border-0">
      <h1 class="page-title text-primary-d2">
        Multimedia
        <small class="page-info text-secondary-d3">
          <i class="fa fa-angle-double-right text-80"></i>
          recursos multimedia para el maestro
        </small>
      </h1>
    </div>


    <div class="row">
      <div class="col-12 col-md-12 mt-3 mt-md-0">
        
      </div>

    </div>
</div>