<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Proveedor</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarRegistroProveedor()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input [disabled]="codigo_automatico" type="text" id="codigo" name="codigo" [(ngModel)]="proveedor.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input (blur)="establecerRazonSocial()" name="nombre" required type="text" id="reg_nit" [(ngModel)]="proveedor.identificacion" placeholder="identificacion" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nit</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input required type="text" id="reg_nit" name="nit" [(ngModel)]="proveedor.nit" placeholder="nit" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Razon Social</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input required type="text" id="reg_razon_social" name="razon_social" [(ngModel)]="proveedor.razon_social" placeholder="Razón Social" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Telefono</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="reg_telefono" name="telefono1" [(ngModel)]="proveedor.telefono1" placeholder="Telefono" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Direccion</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="reg_direccion" name="direccion" [(ngModel)]="proveedor.direccion" placeholder="Direccion" class="form-control"/>
        </div>
    </div>
</div>