import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { IncripcionMateriaComponent } from 'src/app/institute/components/incripcion-materia/incripcion-materia.component';
import { VentaEstudianteComponent } from 'src/app/institute/components/venta-estudiante/venta-estudiante.component';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { Util } from 'src/app/utils/utils';
import { AsignacionPagoEstudianteComponent } from '../asignacion-pago-estudiante/asignacion-pago-estudiante.component';
import { Socket } from 'ngx-socket-io';
import { OrderPipe } from 'ngx-order-pipe';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-ficha-economica-estudiante',
  templateUrl: './ficha-economica-estudiante.component.html',
  styleUrls: ['./ficha-economica-estudiante.component.css']
})
export class FichaEconomicaEstudianteComponent extends BaseComponent implements OnInit {

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  @Input() estudiante:any;
  @Input() usuario:any;
  @Input() inscripcion_instituto:any;
  @Input() inscripcion_colegio:any;
  conceptos_pago:any[]=[];
  concepto_pago_estudiante:any;
  @Input() es_modal_interno:boolean=false;
  @Input() es_visualizacion_por_gestion:boolean=false;
  asignacion_pago_estudiante_modal:NgbModalRef;
  concepto_pago:any;

  modalVenta:NgbModalRef;

  cambio_importe_modal:NgbModalRef;
  @ViewChild('cambio_importe_modal')
  private cambio_importe_modal_ref: TemplateRef<any>;

  asignacion_fecha_vencimiento_modal:NgbModalRef;
  @ViewChild('asignacion_fecha_vencimiento_modal')
  private asignacion_fecha_vencimiento_modal_ref: TemplateRef<any>;

  ficha_asignacion_materia_modal:NgbModalRef;
  concepto_pago_estudiante_fecha_vencimiento:any={};

  aplicacion_estudiantes:any={puede_modificar:false,puede_eliminar:false};
  aplicacion_ventas:any={puede_crear:false};

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    public ventasService:VentasService,
    public pdfService:PdfService,
    private socket: Socket,
    private orderPipe: OrderPipe,
    private conceptosIngresosService:ConceptosIngresosService,
    private estudiantesService:EstudiantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.filter={
      escuela:null,
      sucursal:(this.usuario?Util.obtenerSucursalesUsuario(this.usuario)[0]:null),
			inscripciones_instituto:[],
      inscripciones_colegio:[],
      inscripcion_instituto:this.inscripcion_instituto?this.inscripcion_instituto:null,
      inscripcion_colegio:this.inscripcion_colegio?this.inscripcion_colegio:null
    }
    await this.obtenerEscuela();
    if(this.es_modal_interno || this.es_visualizacion_por_gestion){
      if(this.usuario.empresa.usar_ingresos_economicos_instituto){
        this.filter.inscripciones_instituto.push(this.inscripcion_instituto);
        this.obtenerConceptosPago();
      }else if(this.usuario.empresa.usar_ingresos_economicos_colegio && this.inscripcion_colegio){
        this.filter.inscripciones_colegio.push(this.inscripcion_colegio);
        this.buscarConceptosPago();
      }
    }else{
      if(this.usuario.empresa.usar_ingresos_economicos_instituto){
        this.obtenerInscripcionesInstitutoEstudiante();
      }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
        this.obtenerInscripcionesColegioEstudiante();
      }
    }
    this.aplicacion_estudiantes= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_ESTUDIANTES)[0];
    this.aplicacion_ventas= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_VENTAS)[0];
  }

  obtenerInscripcionesInstitutoEstudiante(){
    this.estudiantesService.obtenerInscripcionesInstitutoEstudiante(this.estudiante.id).subscribe((inscripciones:any)=>{
      this.filter.inscripciones_instituto=inscripciones;
    });
  }

  obtenerInscripcionesColegioEstudiante(){
    this.estudiantesService.obtenerInscripcionesColegioEstudiante(this.estudiante.id).subscribe((inscripciones:any)=>{
      if(this.usuario.empresa.usar_acceso_gestion_escolar){
        if(this.usuario.datos && this.usuario.datos.gestiones){
          this.filter.inscripciones_colegio=inscripciones.filter(ins => this.usuario.datos.gestiones.includes(ins.gestion.id))
        }
      }else{
        this.filter.inscripciones_colegio=inscripciones;
      }
    });
  }

  cerrarComponente(){
    this.onConfirm.emit({confirm:false});
  }

  buscarConceptosPago(){
    let promesa;
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      promesa=this.conceptosIngresosService.buscarConceptosPagoEstudiante(this.filter.inscripcion_instituto);
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      if(!this.filter.inscripcion_colegio){
        this.toastr.error("Debe seleccionar una inscripcion!");
        return;
      }
      this.blockUI.start();
      promesa=this.conceptosIngresosService.buscarConceptosPagoEstudianteColegio({id_gestion:this.filter.inscripcion_colegio.gestion.id,id_estudiante:this.estudiante.id,id_escuela:this.filter.escuela.id});
    }
    promesa.subscribe((conceptos_pagos_estudiante:any[])=>{
      this.conceptos_pago=conceptos_pagos_estudiante;
      for(let i=0;i<this.conceptos_pago.length;i++){
        this.conceptos_pago[i].conceptos_pagos_estudiante=this.orderPipe.transform(this.conceptos_pago[i].conceptos_pagos_estudiante, ['cuota'],false);
      }
      this.blockUI.stop();
    });
  }

  obtenerConceptosPago(){
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      this.blockUI.start();
      this.conceptosIngresosService.obtenerConceptosPago(this.filter.inscripcion_instituto).subscribe((conceptos_pagos:any[])=>{
        this.conceptos_pago=conceptos_pagos;
        for(let i=0;i<this.conceptos_pago.length;i++){
          let conceptos_pagos_estudiante=[];
          let criterio_vencimiento_fecha,criterio_vencimiento_dias,criterio_vencimiento_dias_fecha_inicial;
          if(this.conceptos_pago[i].tipo_fecha_vencimiento && this.conceptos_pago[i].tipo_fecha_vencimiento.nombre_corto=='CCF'){
            criterio_vencimiento_fecha=new Date(this.conceptos_pago[i].fecha_inicial_pago);
            criterio_vencimiento_fecha.setMonth(criterio_vencimiento_fecha.getMonth()-1)
          }console.log(criterio_vencimiento_fecha);
          if(this.conceptos_pago[i].tipo_fecha_vencimiento && this.conceptos_pago[i].tipo_fecha_vencimiento.nombre_corto=='NDC'){
            criterio_vencimiento_dias=this.conceptos_pago[i].pago_clases;
            criterio_vencimiento_dias_fecha_inicial=new Date();
            criterio_vencimiento_dias_fecha_inicial.setDate(criterio_vencimiento_dias_fecha_inicial.getDate()-criterio_vencimiento_dias)
          }
          var numero_cuotas=Array.apply( null, { length: this.conceptos_pago[i].cuotas } );
          for(let j=0;j<numero_cuotas.length;j++){
            let concepto_pago_estudiante={
              id_concepto_pago:this.conceptos_pago[i].id,
              id_estudiante:null,
              cuota:j+1,
              importe:this.conceptos_pago[i].importe,
              pagado:0,
              saldo:this.conceptos_pago[i].importe,
              activo:true,
              fecha_vencimiento:criterio_vencimiento_fecha?new Date(criterio_vencimiento_fecha.setMonth(criterio_vencimiento_fecha.getMonth()+1)):(criterio_vencimiento_dias?new Date(criterio_vencimiento_dias_fecha_inicial.setDate(criterio_vencimiento_dias_fecha_inicial.getDate()+criterio_vencimiento_dias)):null)
            }
            conceptos_pagos_estudiante.push(concepto_pago_estudiante);
          }
          this.conceptos_pago[i].conceptos_pagos_estudiante=conceptos_pagos_estudiante;
        }
        this.inscripcion_instituto.conceptos_pago=this.conceptos_pago;
        this.blockUI.stop();
      }); 
    }
  }

  abrirCambioImporteModal(concepto_pago_estudiante){
    this.concepto_pago_estudiante=JSON.parse(JSON.stringify(concepto_pago_estudiante));
    this.cambio_importe_modal=this.modalService.open(this.cambio_importe_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
    this.cambio_importe_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarConceptoPagoEstudiante(){
    if(!this.es_modal_interno){
      if(this.validarFormularioCambioImporte()){
        this.blockUI.start();
        let promesa;
        if(this.usuario.empresa.usar_ingresos_economicos_instituto){
          promesa=this.conceptosIngresosService.actualizarConceptoPagoEstudiante(this.concepto_pago_estudiante);
        }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
          promesa=this.conceptosIngresosService.actualizarConceptoPagoEstudianteColegio(this.concepto_pago_estudiante);
        }
        promesa.subscribe((res:any)=>{
          this.blockUI.stop();
          this.cambio_importe_modal.close();
          this.toastr.success(res.mensaje);
          this.buscarConceptosPago();
          this.cambio_importe_modal.close();
        });
      }
    }else{
      this.cambio_importe_modal.close();
    }
  }

  abrirPopupConfirmacionEliminacion(concepto_pago_estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el pago?";
		this.popupConfirmacion.componentInstance.data = concepto_pago_estudiante;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEstudianteConceptoPago($e.data);
			}
			this.popupConfirmacion.close();
        });
  }
  
  eliminarEstudianteConceptoPago(concepto_pago_estudiante){
    if(this.es_modal_interno){
      concepto_pago_estudiante.activo=false;
    }else{
      this.blockUI.start();
      let promesa;
      if(this.usuario.empresa.usar_ingresos_economicos_instituto){
        promesa=this.estudiantesService.eliminarConceptoPagoEstudiante(concepto_pago_estudiante.id);
      }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
        promesa=this.estudiantesService.eliminarConceptoPagoEstudianteColegio({id:concepto_pago_estudiante.id,id_empresa:this.usuario.id_empresa});
      }
      promesa.subscribe((dato:any) => {
        let concepto_pago=this.conceptos_pago.filter(cp => cp.id==concepto_pago_estudiante.id_concepto_pago)[0]
        if(concepto_pago.conceptos_pagos_estudiante.length==1){
          this.eliminarAsignacionConceptoPagoEstudiante(concepto_pago);
        }else{
          this.buscarConceptosPago();
        }
        this.toastr.success(dato.mensaje);
        this.blockUI.stop();
      });
    }
  }

  abrirPopupConfirmacionEliminacionAsignacionPago(concepto_pago){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar la asignacion de concepto de pago?";
		this.popupConfirmacion.componentInstance.data = concepto_pago;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarAsignacionConceptoPagoEstudiante($e.data);
			}
			this.popupConfirmacion.close();
        });
  }
  
  eliminarAsignacionConceptoPagoEstudiante(concepto_pago){
    this.blockUI.start();
    let i=0,pago_encontrado=false;
		while(i<concepto_pago.conceptos_pagos_estudiante.length && !pago_encontrado){
			if(concepto_pago.conceptos_pagos_estudiante[i].detalles_venta.length>0){
				pago_encontrado=true;
			}
			i++;
		}
    if(pago_encontrado){
      this.toastr.warning("No se puede eliminar el concepto de pago puesto que existe al menos una cuota pagada!");
      this.blockUI.stop();
    }else{
      let promesa;
      if(this.usuario.empresa.usar_ingresos_economicos_instituto){
        
      }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
        promesa=this.estudiantesService.eliminarAsignacionConceptoPagoEstudianteColegio({id_concepto_pago:concepto_pago.id,id_estudiante:this.estudiante.id});
      }
      promesa.subscribe((dato:any) => {
        this.buscarConceptosPago();
        this.toastr.success(dato.mensaje);
        this.blockUI.stop();
      });
    }
  }

  abrirRegistroPago(){
    this.modalVenta = this.modalService.open(VentaEstudianteComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.estudiante.persona={cliente:{}};
		this.modalVenta.componentInstance.venta.usuario = this.usuario;
		this.modalVenta.componentInstance.usuario = this.usuario;
    this.modalVenta.componentInstance.estudiante = this.estudiante;
    this.modalVenta.componentInstance.curso = this.filter.inscripcion_colegio.curso;
		this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
		//this.modalVenta.componentInstance.inhabilitar_fecha = true;
    this.modalVenta.componentInstance.inhabilitar_cliente = true;
		
		this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
          this.buscarConceptosPago();
					this.imprimirVenta(res.venta);
					this.modalVenta.close();
				}
			}else{
				this.modalVenta.close();
			}
			
		});
  }

  imprimirVenta(venta){
		this.pdfService.imprimirVenta(venta);
	}

  modificarFechaVencimiento(concepto_pago_estudiante){
    concepto_pago_estudiante.fv_modificar=true;
    concepto_pago_estudiante.fecha_vencimiento=concepto_pago_estudiante.fecha_vencimiento?new Date(concepto_pago_estudiante.fecha_vencimiento):new Date();
    concepto_pago_estudiante.fecha_vencimiento_texto={
      year:concepto_pago_estudiante.fecha_vencimiento.getFullYear(),
      month:concepto_pago_estudiante.fecha_vencimiento.getMonth()+1,
      day:concepto_pago_estudiante.fecha_vencimiento.getDate()
    }
  }

  establecerFechaVencimiento(concepto_pago_estudiante){
    if(concepto_pago_estudiante.fecha_vencimiento_texto && concepto_pago_estudiante.fecha_vencimiento_texto.year && concepto_pago_estudiante.fecha_vencimiento_texto.month && concepto_pago_estudiante.fecha_vencimiento_texto.day){
      concepto_pago_estudiante.fecha_vencimiento=Util.convertirObjetoAfecha(concepto_pago_estudiante.fecha_vencimiento_texto);
      //this.toastr.error("Debe especificar la fecha de vencimiento!");
    }else{
      concepto_pago_estudiante.fecha_vencimiento=null;
    }
    if(concepto_pago_estudiante.id){
      this.blockUI.start();
      let promesa;
      if(this.usuario.empresa.usar_estudiantes_instituto){
        promesa=this.conceptosIngresosService.actualizarConceptoPagoEstudiante(concepto_pago_estudiante);
      }
      if(this.usuario.empresa.usar_estudiantes_colegio){
        promesa=this.conceptosIngresosService.actualizarConceptoPagoEstudianteColegio(concepto_pago_estudiante);
      }
      promesa.subscribe((res:any)=>{
        this.blockUI.stop();
        this.toastr.success(res.mensaje);
      });
    }
    concepto_pago_estudiante.fv_modificar=false;
  }

  abrirAsignacionMaterias(estudiante){
    this.ficha_asignacion_materia_modal = this.modalService.open(IncripcionMateriaComponent, {windowClass:'inscripcion-materia', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_asignacion_materia_modal.componentInstance.estudiante = estudiante;
    this.ficha_asignacion_materia_modal.componentInstance.usuario = this.usuario;
		
		this.ficha_asignacion_materia_modal.componentInstance.onConfirm.subscribe(($e) => {
			this.ficha_asignacion_materia_modal.close();
    });
  }

  generarPdfKardex(){
    var doc = new PDFDocument({size:[612, 792],margin:20});
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		var items=0,pagina=1,totalPaginas=2;//Math.ceil(compra.detallesCompra.length/itemsPorPagina);
		var titulo="KARDEX ECONÓMICO";
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
      this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion,
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{});
		this.dibujarCabeceraImpresionKardexEstudiante(doc,this.estudiante);
    let x=50,y_max=0,y_ini=160,y=0;
		for(let i=0;i<this.conceptos_pago.length;i++){
      y=(items%2==0)?y_ini+y_max:y-20;
      doc.font('Helvetica-Bold',8);	
      doc.roundedRect(x,y-50,260,20,5).fillOpacity(0.8).stroke();
      doc.roundedRect(x,y-30,260,20,5).fillOpacity(0.8).stroke();
      doc.text(this.conceptos_pago[i].concepto.nombre,x+5,y-40);	
      doc.text("Cuota",x+5,y-20);
      doc.text("Importe",x+50,y-20);
      doc.text("Pagado",x+100,y-20);
      doc.text("Fecha de Vencimiento",x+180,y-27,{width:50,align:"center"});
      doc.font('Helvetica',8);
      for(let j=0;j<this.conceptos_pago[i].conceptos_pagos_estudiante.length;j++){
        doc.roundedRect(x,y-10,260,20,5).fillOpacity(0.8).stroke();
        doc.text(this.conceptos_pago[i].conceptos_pagos_estudiante[j].cuota,x+15,y,{width:70});
        doc.text(this.conceptos_pago[i].conceptos_pagos_estudiante[j].importe,x+60,y);
        let texto_pagado="";
        if(this.conceptos_pago[i].conceptos_pagos_estudiante[j].detalles_venta && this.conceptos_pago[i].conceptos_pagos_estudiante[j].detalles_venta.length>0){
          for(let k=0;k<this.conceptos_pago[i].conceptos_pagos_estudiante[j].detalles_venta.length;k++){
            let fecha_pago:any=new Date(this.conceptos_pago[i].conceptos_pagos_estudiante[j].detalles_venta[k].detalle_venta.venta.fecha);
            fecha_pago=fecha_pago.getDate()+"/"+(fecha_pago.getMonth()+1)+"/"+fecha_pago.getFullYear();
            texto_pagado=texto_pagado+("Bs. "+this.conceptos_pago[i].conceptos_pagos_estudiante[j].detalles_venta[k].detalle_venta.total)+" ("+fecha_pago+") "+"\n ";
          }
        }else{
          texto_pagado="0";
        }
        doc.text(texto_pagado,x+90,(texto_pagado.length>8 && texto_pagado.length<16)?y-6:(texto_pagado.length>=16 && texto_pagado.length<24)?y-8:y);
        if(this.conceptos_pago[i].conceptos_pagos_estudiante[j].fecha_vencimiento){
          let fecha_vencimiento=new Date(this.conceptos_pago[i].conceptos_pagos_estudiante[j].fecha_vencimiento);
          let fecha_vencimiento_texto=fecha_vencimiento.getDate()+"/"+(fecha_vencimiento.getMonth()+1)+"/"+fecha_vencimiento.getFullYear();
          doc.text(fecha_vencimiento_texto,x+170,y);
        }
        y=y+20;
      }
			
			items++;
      x=(items%2==0)?50:320;
      y_max=y_max<y?y+60:y_max;
      y_ini=0;
		}
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
  }

  dibujarCabeceraImpresionKardexEstudiante(doc,estudiante){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(380,55,195,43,5).stroke();
    doc.text("ESTUDIANTE : ",390,60);
    doc.font('Helvetica', 7);
		doc.text(estudiante.persona?(estudiante.persona.apellido_paterno+" "+estudiante.persona.apellido_materno+" "+estudiante.persona.nombres):(estudiante.apellido_paterno+" "+estudiante.apellido_materno+" "+estudiante.nombres),400,70);
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      doc.font('Helvetica-Bold',8);
      doc.text("CARRERA : ",390,80);
      doc.font('Helvetica', 7);
      doc.text(this.filter.inscripcion_instituto.carrera.nombre,400,90);
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      doc.font('Helvetica-Bold',8);
      doc.text("CURSO : ",390,80);doc.text("GESTIÓN : ",500,80);
      doc.font('Helvetica', 7);
      doc.text(this.filter.inscripcion_colegio.curso.nombre,400,90);
      doc.text(this.filter.inscripcion_colegio.gestion.nombre,510,90);
    }
	}

  abrirAsignacionConceptoPagoEstudiante(){
    this.asignacion_pago_estudiante_modal = this.modalService.open(AsignacionPagoEstudianteComponent, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
		this.asignacion_pago_estudiante_modal.componentInstance.gestion = this.filter.inscripcion_colegio.gestion;
    this.asignacion_pago_estudiante_modal.componentInstance.id_estudiante = this.estudiante.id;
    this.asignacion_pago_estudiante_modal.componentInstance.usuario = this.usuario;
    this.asignacion_pago_estudiante_modal.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				this.buscarConceptosPago();
			}
			this.asignacion_pago_estudiante_modal.close();
    });
  }

  validarFormularioCambioImporte(){
    let res=true;
		if(!this.concepto_pago_estudiante.importe){
      res=res && false;
      this.toastr.error("Debe especificar el importe!");
    }
    if(this.concepto_pago_estudiante.descuento==null || this.concepto_pago_estudiante.descuento==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el descuento!");
    }else{
      if(this.concepto_pago_estudiante.descuento>=this.concepto_pago_estudiante.importe || this.concepto_pago_estudiante.descuento<0){
        res=res && false;
        this.toastr.error("El descuento debe ser mayor o igual a 0 y menor al importe!");
      } 
    }
		return res;
  }
  
  abrirModalFechaVencimiento(concepto_pago){
    this.concepto_pago=concepto_pago;
    this.asignacion_fecha_vencimiento_modal=this.modalService.open(this.asignacion_fecha_vencimiento_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
    this.asignacion_fecha_vencimiento_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  validarFechaVencimientoInicio(){
    let res=true;
    if(!this.concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto || !this.concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto.year || !this.concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto.month || !this.concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de vencimiento completa!");
    }
    return res;
  }

  guardarInicioFechaVencimiento(){
    if(this.validarFechaVencimientoInicio()){
      let fecha_inicio=Util.convertirObjetoAfecha(this.concepto_pago_estudiante_fecha_vencimiento.fecha_vencimiento_texto);
      for(let i=0;i<this.concepto_pago.conceptos_pagos_estudiante.length;i++){
        this.concepto_pago.conceptos_pagos_estudiante[i].fecha_vencimiento_texto=Util.convertirTextoAObjectoFecha(fecha_inicio.getDate()+"/"+(fecha_inicio.getMonth()+1)+"/"+fecha_inicio.getFullYear());
        this.establecerFechaVencimiento(this.concepto_pago.conceptos_pagos_estudiante[i]);
        fecha_inicio.setMonth(fecha_inicio.getMonth()+1);
      }
      this.asignacion_fecha_vencimiento_modal.close();
    }
  }

  async enviarKardexEconomicoWhatsapp(){
    let mensaje="🙋🏻‍♂️ Apreciado Padre y/o Madre de Familia de *"+(this.estudiante.nombre_completo?this.estudiante.nombre_completo:(this.estudiante.persona?this.estudiante.persona.nombre_completo:this.estudiante.nombre_completo))+"*! 😔\n\n💴 Le informamos sobre el estado de pagos pendientes a la escuela deportiva:\n\n";
    let fecha_actual=new Date(),total=0;
    for(let i=0;i<this.conceptos_pago.length;i++){
      for(let j=0;j<this.conceptos_pago[i].conceptos_pagos_estudiante.length;j++){
        this.conceptos_pago[i].conceptos_pagos_estudiante[j].fecha_vencimiento=new Date(this.conceptos_pago[i].conceptos_pagos_estudiante[j].fecha_vencimiento);
        if(this.conceptos_pago[i].conceptos_pagos_estudiante[j].saldo>0 && fecha_actual.getTime()>=this.conceptos_pago[i].conceptos_pagos_estudiante[j].fecha_vencimiento.getTime()){
          let mes=this.conceptos_pago[i].descripcion?this.conceptos_pago[i].descripcion.clases.filter(d => d.nombre_corto==this.conceptos_pago[i].conceptos_pagos_estudiante[j].cuota.toString()):[];
          mes=mes.length>0?mes[0]:null;
          let descripcion="❓ "+(this.conceptos_pago[i].concepto.nombre)+" "+this.conceptos_pago[i].conceptos_pagos_estudiante[j].cuota+(mes?" - "+mes.nombre:"")+" - "+((this.filter.inscripcion_colegio.gestion.nombre))+(" (Bs "+this.conceptos_pago[i].conceptos_pagos_estudiante[j].saldo.toFixed(2)+")");
          mensaje=mensaje+descripcion+"\n";
          total=total+this.conceptos_pago[i].conceptos_pagos_estudiante[j].saldo;
        }
      }      
    }

    mensaje=mensaje+"\n 💵 Total Bs "+total.toFixed(2);
    if(!this.estudiante.inscripcion_activo){
      mensaje=mensaje+"\n\n🙏🦅⚽ Entendemos que pueden existir diversas razones por las que se tomo la decisión de no asistir, sin embargo, como toda institución educativa, esperemos su pronto retorno, muchas gracias por haber formado parte de nuestra escuela deportiva. 🙋🏻‍♂️";
    }
    console.log(this.estudiante);
    console.log(mensaje);
    let envio:any={
      telefono_whatsapp:this.estudiante.telefono_movil?this.estudiante.telefono_movil:this.estudiante.persona.telefono_movil,
      mensaje:mensaje,
      sucursal:{id:this.usuario.sucursalesUsuario[0].sucursal.id}
    };
    this.socket.emit('enviarKardexEconomicoEstudianteWhatsapp',envio);
    this.toastr.success("Se envio el mensaje por whatsapp satisfactoriamente!");
	}

  sumarPagos(detalles_venta){
    let suma=0;
    for(let i=0;i<detalles_venta.length;i++){
      suma=suma+detalles_venta[i].detalle_venta.total;
    }
    return Math.round(suma*100)/100;
  }

  sumarDescuentos(detalles_venta){
    let suma=0;
    for(let i=0;i<detalles_venta.length;i++){
      suma=suma+detalles_venta[i].detalle_venta.descuento;
    }
    return Math.round(suma*100)/100;
  }

}
