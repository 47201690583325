import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class TareasService {

  constructor(private http: HttpClient) { }

  public obtenerListaTareas(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tarea/persona/"+paginator.filter.id_persona+"/registro-profesor/"+paginator.filter.registro_profesor.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarTarea(tarea){
    return this.http.post(GlobalVariable.API_SERVER_URL+"tarea/",tarea,GlobalVariable.httpOptions);
  }

  public actualizarTarea(tarea){
    return this.http.put(GlobalVariable.API_SERVER_URL+"tarea/"+tarea.id,tarea,GlobalVariable.httpOptions);
  }

  public obtenerTarea(id_tarea){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tarea/"+id_tarea,GlobalVariable.httpOptions);
  }

  public eliminarTarea(tarea){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"tarea/"+tarea.id,GlobalVariable.httpOptions);
  }

  public enviarCorreoTarea(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"envio-correo-tarea/",datos,GlobalVariable.httpOptions);
  }
}
