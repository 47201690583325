import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CajasService } from '../../services/cajas/cajas.service';
import { ListaChequesComponent } from '../lista-cheques/lista-cheques.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-caja',
  templateUrl: './caja.component.html',
  styleUrls: ['./caja.component.css']
})
export class CajaComponent extends BaseComponent implements OnInit {

  cajas:any[]=[];
  caja:any;
  monedas:any[]=[];
  bancos:any[]=[];
  tipos_caja:any[]=[];

  modal_registro_caja:NgbModalRef;
  @ViewChild('modal_registro_caja')
  private modal_registro_caja_ref: TemplateRef<any>;
  
  modal_lista_cheques:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private cajasService:CajasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

    async ngOnInit(): Promise<void> {
      this.init();
        this.buscarAplicacion(this.router.url.substring(1));
        this.filter={
          id_empresa:this.usuario.id_empresa
        }
    
        this.column = "nombre";
        this.direction="asc";
        this.getSearch(this.text_search,null);
        this.obtenerMonedas();
        this.obtenerBancos();
        this.obtenerTiposCaja();
    }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
    this.cajasService.obtenerListaCajas(this).subscribe((dato:any) => {
      this.setPages(dato.paginas);
      this.cajas=dato.cajas;
      this.blockUI.stop();
    });
  }

  crearNuevaCaja(){
    this.caja={
      id_empresa:this.usuario.id_empresa
    };
    this.modal_registro_caja=this.modalService.open(this.modal_registro_caja_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_registro_caja.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  obtenerMonedas(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.MONEDAS_CAJA).subscribe((tipo_monedas_caja:any)=>{
      this.monedas=tipo_monedas_caja.clases;
      this.blockUI.stop();
    });
  }

  obtenerTiposCaja(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_CAJA).subscribe((tipos_caja:any)=>{
      this.tipos_caja=tipos_caja.clases;
      this.blockUI.stop();
    });
  }

  obtenerBancos(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.BANCOS_CAJA).subscribe((bancos:any)=>{
      this.bancos=bancos.clases;
      this.blockUI.stop();
    });
  }

  validarRegistroCaja(){
    let res=true;
    if(!this.caja.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la caja!");
    }
    if(this.caja.saldo==null){
      res=res && false;
      this.toastr.error("Debe especificar el saldo de la caja!");
    }
    if(!this.caja.moneda){
      res=res && false;
      this.toastr.error("Debe especificar la moneda de la caja!");
    }
    if(!this.caja.tipo_caja){
      res=res && false;
      this.toastr.error("Debe especificar el tipo de caja!");
    }
    if(this.caja.tipo_caja.nombre_corto==GlobalVariable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA){
      if(!this.caja.banco){
        res=res && false;
        this.toastr.error("Debe especificar el banco de la caja!");
      }
      if(!this.caja.numero_cuenta){
        res=res && false;
        this.toastr.error("Debe especificar el numero de cuenta del banco de la caja!");
      }
    }
    return res;
  }

  guardarCaja(){
    if(this.validarRegistroCaja()){
      this.blockUI.start();
      if(this.caja.id){
        this.cajasService.actualizarCaja(this.caja).subscribe((res:any)=>{
          this.modal_registro_caja.close();
          this.toastr.success(res.mensaje);
          this.getItems();
          this.blockUI.stop();
        });
      }else{
        this.cajasService.guardarCaja(this.caja).subscribe((res:any)=>{
          this.modal_registro_caja.close();
          this.toastr.success(res.mensaje);
          this.getItems();
          this.blockUI.stop();
        }); 
      }
    }
  }

  modificarCaja(caja){
    this.caja=caja;
    this.modal_registro_caja=this.modalService.open(this.modal_registro_caja_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_registro_caja.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  abrirPopupConfirmacionEliminacion(caja){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la caja?";
		this.popupConfirmacion.componentInstance.data = caja;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCaja($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarCaja(caja){
		this.blockUI.start();
		this.cajasService.eliminarCaja(caja).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
			}
      this.blockUI.stop();
		});
	}

  verCheques(caja){
		this.modal_lista_cheques = this.modalService.open(ListaChequesComponent, {size:'md',ariaLabelledBy: 'modal-basic-title', backdrop: 'static',windowClass:'lista-cheques',scrollable:true});
		this.modal_lista_cheques.componentInstance.caja = caja;
		
		this.modal_lista_cheques.componentInstance.alTerminar.subscribe(($e) => {
			this.modal_lista_cheques.close();
    });
	}

}
