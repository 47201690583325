import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ProductosService } from '../../services/productos/productos.service';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-impresion-codigos-barras',
  templateUrl: './impresion-codigos-barras.component.html',
  styleUrls: ['./impresion-codigos-barras.component.css']
})
export class ImpresionCodigosBarrasComponent extends BaseComponent implements OnInit {

  productos:any[]=[];
  detalle_producto:any;
  searching = false;
  searchFailed = false;  
  producto_busqueda:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  configuracion_aplicacion_productos:any;
  ancho_imagen_codigo:number=1;
  alto_imagen_codigo:number=30;

  constructor(public generalService:GeneralService,
    public productosService:ProductosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
    this.init();
    this.inicializarDetalleProducto();
    this.configuracion_aplicacion_productos=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
    this.ancho_imagen_codigo=this.configuracion_aplicacion_productos.datos.opciones_permisos.impresion.papel_codigo_barras == GlobalVariable.Dictionary.PAPEL_ROLLO?2:1;
    this.alto_imagen_codigo=this.configuracion_aplicacion_productos.datos.opciones_permisos.impresion.papel_codigo_barras == GlobalVariable.Dictionary.PAPEL_ROLLO?60:30;
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  async imprimirCodigosBarras(){
    let bar_code_elements:any=document.getElementsByTagName('ngx-barcode');
    let codigos=[];
    for(let i=0;i<bar_code_elements.length;i++){
      let img=bar_code_elements[i].getElementsByTagName('img')[0];
      for(let j=0;j<parseInt(bar_code_elements[i].attributes['data-cantidad'].value);j++){
        codigos.push({
          codigo_fabrica:img.src,
          precio_unitario:parseFloat(bar_code_elements[i].attributes['data-precio-unitario'].value),
          codigo:bar_code_elements[i].attributes['data-codigo'].value
        });
      }
    }

    let doc,stream;
    if (this.configuracion_aplicacion_productos.datos.opciones_permisos.impresion.papel_codigo_barras == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			doc = new PDFDocument({ size: [142, 71], margins: { top: 0, bottom: 0, left: 20, right: 20 } });
      stream = doc.pipe(blobStream());
      for(let i=0;i<codigos.length;i++){
        doc.font('Helvetica-Bold', 8);
        doc.text(this.usuario.empresa.razon_social,20,8,{align:"center"});
        doc.font('Helvetica', 6);
        doc.text(codigos[i].precio_unitario+" Bs",20,15,{align:"center"});
        doc.text("CODE",20,21,{align:"center"});
        doc.image(codigos[i].codigo_fabrica,20,30, { width: 100, height: 40,align:'left' });
        doc.text(codigos[i].codigo,20,27,{align:"center"});
        if(i+1<codigos.length){
          doc.addPage({ size: [142, 71], margins: { top: 0, bottom: 0, left: 20, right: 20 } });
        }
      }			
		}else{
			doc = new PDFDocument({ size: [612, 792], margin: 10 });
      stream = doc.pipe(blobStream());
      doc.font('Helvetica', 8);
      let x=40,y=20,items=1,items_por_pagina=15*9;
      for(let i=0;i<codigos.length;i++){
        x=x<541?x:40;
        doc.image(codigos[i].codigo,x,y, { width: 50, height: 50,align:'left' });
        x=x+60;
        y=items%9==0?y+50:y;
        items++;
        if(items>items_por_pagina){
          items=1,x=40,y=20;
          doc.addPage({ size: [612, 792], margin: 10 });
        }
      }
		}
    
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			var w = window.open(fileURL, '_blank', 'location=no');
			setTimeout(function () {
				w.print();
			}, 500);
		});
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProducto($event){
    this.detalle_producto.producto=$event.item;
    this.detalle_producto.cantidad=1;
  }

  agregarDetalleProducto(){
    if(this.validarDetalleProducto()){    
      this.productos.push(this.detalle_producto);
      this.inicializarDetalleProducto();
    }
  }

  validarDetalleProducto(){
    let res=true;
    if(!this.detalle_producto.producto || !this.detalle_producto.producto.id){
      res=res && false;
      this.toastr.error("Debe especificar el producto!");
    }
    if(!this.detalle_producto.cantidad){
      res=res && false;
      this.toastr.error("Debe especificar la cantidad!");
    }
    if(this.configuracion_aplicacion_productos.datos.opciones_permisos.impresion.usar_codigos_sistema && !this.detalle_producto.producto.codigo){
      res=res && false;
      this.toastr.error("El producto no tiene código de Sistema!");
    }
    if(!this.configuracion_aplicacion_productos.datos.opciones_permisos.impresion.usar_codigos_sistema && !this.detalle_producto.producto.codigo_fabrica){
      res=res && false;
      this.toastr.error("El producto no tiene código de fábrica!");
    }
    return res;
  }

  inicializarDetalleProducto(){
    this.detalle_producto={producto:{}};
    this.producto_busqueda=null;
  }

  eliminarProducto(detalle_producto){
    this.productos.splice(this.productos.indexOf(detalle_producto),1);
  }

}
