<div class="modal-header card-header bg-dark">
    <div class="col-6 col-md-8">
        <span class="text-blue text-125">Configuración Whatsapp</span>
    </div>
    <div class="col-6 col-md-4">
        <button class="btn btn-primary" type="button">
            <i class="fas fa-save red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
          <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body bg-dark">
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="text-white font-bolder">1. Abra WhatsApp en su teléfono</div>
            <div class="text-white font-bolder">2. Abra Menú o Configuración y elija Dispositivos vinculados</div>
            <div class="text-white font-bolder">3. Apunte su teléfono para capturar el código</div>
            <div class="text-white font-bolder">4. Es así de fácil</div>
        </div><!-- /.col -->
        <div class="col-12 col-md-12">
            <div class="row">
                <div class="col-5 col-md-5">
                    <div class="text-white font-bolder">Sucursal: {{sucursal.nombre}}</div>
                </div>
                <div class="col-7 col-md-7" *ngIf="sucursal.codigo_qr">
                    <qrcode [qrdata]="sucursal.codigo_qr" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                </div>
            </div>
        </div><!-- /.col -->
    </div>
</div>
