import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-reporte-compras-productos',
  templateUrl: './reporte-compras-productos.component.html',
  styleUrls: ['./reporte-compras-productos.component.css']
})
export class ReporteComprasProductosComponent extends BaseComponent implements OnInit {

  grafico:any={};
  public configuracion_pagina_ventas:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public reportesService:ReportesService,
    public pdfService:PdfService,
	public clientesService:ClientesService,
	private toastr: ToastrService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

	async ngOnInit(): Promise<void> {
		this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));

		let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: {nombre:"TODOS",id:0},
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			clientes:[{identificacion:"TODOS",id:0}],
			cliente:{identificacion:"TODOS",id:0},
			tipos_pago:[{nombre:"TODOS",id:0}],
			tipo_pago: {nombre:"TODOS",id:0},
			grupos_producto:[{nombre:"TODOS",id:0}],
			grupo_producto: {nombre:"TODOS",id:0},
			subgrupos_producto:[{nombre:"TODOS",id:0}],
			subgrupo_producto: {nombre:"TODOS",id:0},
			tipo_item: {nombre:"PRODUCTO",id:1},
			tipos_items: [{nombre:"PRODUCTOS",id:1},{nombre:"COMBOS",id:2}],
			canales:[{nombre:"TODOS",id:0}],
			canal: {nombre:"TODOS",id:0},
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			solo_obsequios:false,
			meses:[]
		}

		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_REPORTE_COMPRAS_PRODUCTOS);
		this.filter.presentacion_cliente=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente;
		this.filter.presentacion_periodos=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_periodos;

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
    
		this.obtenerTiposDePago();
		this.obtenerGruposProductos();
		this.obtenerSubGruposProductos();
  	}

    ngAfterViewInit() {
      resaltarPestañaMenu(this.router.url.substring(1)+"1");
    }

    obtenerUsuarios(){
      this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
        this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
      });
    }

    obtenerTiposDePago(){
      this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
        this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
      });
    }	
  
    obtenerGruposProductos(){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((entidad:any) => {
        this.filter.grupos_producto=this.filter.grupos_producto.concat(entidad.clases);
      });
    }
  
    obtenerSubGruposProductos(){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((entidad:any) => {
        this.filter.subgrupos_producto=this.filter.subgrupos_producto.concat(entidad.clases);
      });
    }

    generarExcelHistorico(){
      this.blockUI.start();
      this.reportesService.obtenerComprasProductos(this).subscribe((datos:any)=>{
        let detallesCompra=datos.detallesCompra;			
        var data = [["FECHA","REGISTRO","HORA","N° DE DOC-SISTEMA","TRANSACCION","CODIGO","DETALLE","COSTO UNIT.","CANTIDAD","IMPORTE","DESCUENTOS",
        "TOTAL COSTO","OBSERVACION","TIPO DE PAGO","USUARIO","SUCURSAL","NOMBRE PROVEEDOR","NIT/CI PROVEEDOR","NOMBRE O RAZON SOCIAL"/*,"UBICACION CLIENTE"*/,
              "UNIDAD","GRUPO"]]
              
        if(this.usuario.empresa.usar_vencimientos){
          data[0].push('FECHA VENCIMIENTO');
          data[0].push('LOTE');
        }

        if(this.usuario.empresa.usar_caja_general){
          data[0].push('CAJA');
        }

        var sumaImporte=0,sumaTotal=0,suma_descuento=0;
        for(var i=0;i<detallesCompra.length;i++){
          var columns=[];
          detallesCompra[i].compra.fecha=new Date(detallesCompra[i].compra.fecha);
          detallesCompra[i].compra.fecha.setHours(detallesCompra[i].compra.fecha.getHours()-4);
          detallesCompra[i].compra.createdAt=new Date(detallesCompra[i].compra.createdAt);
          columns.push(detallesCompra[i].compra.fecha);
          var fecha_hora=detallesCompra[i].compra.createdAt.getDate()+"/"+(detallesCompra[i].compra.createdAt.getMonth()+1)+"/"+detallesCompra[i].compra.createdAt.getFullYear()
          var hora=detallesCompra[i].compra.createdAt.getHours()+":"+detallesCompra[i].compra.createdAt.getMinutes();
          columns.push(fecha_hora);
          columns.push(hora);
          columns.push(detallesCompra[i].compra.factura+"-"+detallesCompra[i].compra.numero_documento);
          columns.push(detallesCompra[i].compra.tipo_documento.nombre);
          columns.push(detallesCompra[i].producto?detallesCompra[i].producto.codigo:detallesCompra[i].combo?detallesCompra[i].combo.codigo:"");
          columns.push(detallesCompra[i].producto?detallesCompra[i].producto.nombre:detallesCompra[i].combo?detallesCompra[i].combo.nombre:detallesCompra[i].descripcion);
          columns.push(detallesCompra[i].costo_unitario);
          columns.push(detallesCompra[i].cantidad);
          columns.push(detallesCompra[i].importe);
          columns.push(detallesCompra[i].descuento);
          columns.push(detallesCompra[i].total);
          columns.push(detallesCompra[i].compra.observacion);
          columns.push(detallesCompra[i].compra.tipoPago.nombre);
          columns.push(detallesCompra[i].compra.usuario.nombre_usuario);
          columns.push(detallesCompra[i].compra.sucursal.nombre);
          columns.push(detallesCompra[i].compra.proveedor.identificacion);
          columns.push(detallesCompra[i].compra.proveedor.nit);
          columns.push(detallesCompra[i].compra.proveedor.razon_social);
          //columns.push(detallesVenta[i].venta.cliente.ubicacion_geografica);
          columns.push(detallesCompra[i].producto?detallesCompra[i].producto.unidad_medida:detallesCompra[i].combo?detallesCompra[i].combo.unidad_medida:"");
          columns.push(detallesCompra[i].producto?detallesCompra[i].producto.grupo?detallesCompra[i].producto.grupo.nombre:"":"");
          
          if(this.usuario.empresa.usar_vencimientos){
            if(detallesCompra[i].inventario){
              detallesCompra[i].fecha_vencimiento=new Date(detallesCompra[i].fecha_vencimiento);
              columns.push(detallesCompra[i].fecha_vencimiento.getDate()+"/"+(detallesCompra[i].fecha_vencimiento.getMonth()+1)+"/"+detallesCompra[i].fecha_vencimiento.getFullYear());
              columns.push(detallesCompra[i].lote);
            }else if(detallesCompra[i].lote!=null){
              detallesCompra[i].fecha_vencimiento=new Date(detallesCompra[i].fecha_vencimiento);
              columns.push(detallesCompra[i].fecha_vencimiento.getDate()+"/"+(detallesCompra[i].fecha_vencimiento.getMonth()+1)+"/"+detallesCompra[i].fecha_vencimiento.getFullYear());
              columns.push(detallesCompra[i].lote);
            }else{
              columns.push("");
              columns.push("");
            }
          }

          if(this.usuario.empresa.usar_caja_general){
            columns.push(detallesCompra[i].compra.movimiento_caja.caja.nombre);
          }
          
          data.push(columns);
          sumaImporte=sumaImporte+detallesCompra[i].importe;
          suma_descuento=suma_descuento+detallesCompra[i].descuento;
          sumaTotal=sumaTotal+detallesCompra[i].total;
        }
        data.push(["","","","","","TOTALES","","","",sumaImporte.toString(),
        suma_descuento.toString(),sumaTotal.toString(),"",
        "","","","","","","",""]);
        
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("REPORTE COMPRAS PRODUCTOS");
        data.forEach(d => {
        let row = worksheet.addRow(d);
        }
        );
        const dobCol1 = worksheet.getColumn(1);
        dobCol1.width = 20;
      const dobCol2 = worksheet.getColumn(2);
        dobCol2.width = 20;
        const dobCol3 = worksheet.getColumn(3);
        dobCol3.width = 20;
  
        workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "REPORTE COMPRAS PRODUCTOS.xlsx");
        })
        this.blockUI.stop();
      });
    }

    generarPdfHistorico(){
      this.blockUI.start();
      this.reportesService.obtenerComprasProductos(this).subscribe((datos:any)=>{
        var titulo="REPORTE DE COMPRAS PRODUCTOS";
        let fecha_reporte=new Date();
        let detallesCompra=datos.detallesCompra;
        console.log(detallesCompra)
        var doc = new PDFDocument({margin:10});
        var stream = doc.pipe(blobStream());
        // draw some text
        
        doc.font('Helvetica',8);
        var y=150,itemsPorPagina=15,items=0,pagina=1,totalPaginas = Math.ceil(detallesCompra.length / itemsPorPagina);;
        this.generarCabeceraPdfHistorico(doc);
        this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
          this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
          (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
        (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
        (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
        fecha_reporte,{imprimir_usuario:true});
        var suma_total=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
        var sumaSubImporte=0,sumaSubImporteIce=0,sumaSubImporteExp=0,sumaSubImporteGrab=0,sumaSubTotal=0,sumaSubDescuentos=0,sumaSubImporteBase=0,sumaSubCredito=0;
        for(var i=0;i<detallesCompra.length && items<=itemsPorPagina;i++){
          doc.rect(50,y-15,540,40).stroke();
          doc.font('Helvetica',8);
          detallesCompra[i].compra.fecha=new Date(detallesCompra[i].compra.fecha);
          doc.text(detallesCompra[i].compra.fecha.getDate()+"/"+(detallesCompra[i].compra.fecha.getMonth()+1)+"/"+detallesCompra[i].compra.fecha.getFullYear().toString().substr(-2),55,y-7);
          doc.text((detallesCompra[i].compra.factura?detallesCompra[i].compra.factura:""),85,y-7);
          doc.font('Helvetica',7);
          doc.text(detallesCompra[i].compra.tipo_documento.nombre,110,y-2,{width:55});				
          doc.text((detallesCompra[i].compra.proveedor.identificacion?detallesCompra[i].compra.proveedor.identificacion:detallesCompra[i].compra.proveedor.razon_social),165,y-2,{width:80});				
          doc.text(detallesCompra[i].producto?detallesCompra[i].producto.nombre:detallesCompra[i].combo?detallesCompra[i].combo.nombre:detallesCompra[i].descripcion,255,y-2,{width:80});	
          doc.font('Helvetica',8);			
          doc.text(detallesCompra[i].producto?detallesCompra[i].producto.unidad_medida:detallesCompra[i].combo?detallesCompra[i].combo.unidad_medida:"",350,y,{width:50});
          doc.text(detallesCompra[i].cantidad,380,y,{width:50});
          doc.text(detallesCompra[i].costo_unitario,410,y,{width:50});
          if(this.usuario.empresa.usar_vencimientos){
            detallesCompra[i].fecha_vencimiento=new Date(detallesCompra[i].fecha_vencimiento);
            doc.text(detallesCompra[i].fecha_vencimiento.getDate()+"/"+(detallesCompra[i].fecha_vencimiento.getMonth()+1)+"/"+detallesCompra[i].fecha_vencimiento.getFullYear().toString().substr(-2),475,y);				
            //var descuento=detallesVenta[i].importe-detallesVenta[i].ice-detallesVenta[i].excento+detallesVenta[i].recargo;
            doc.text(detallesCompra[i].lote,510,y);
          }				
          //doc.text(detallesVenta[i].descuento,475,y);				
          //doc.text(detallesVenta[i].recargo,505,y);
            doc.text(detallesCompra[i].total,545,y);
            suma_total=suma_total+detallesCompra[i].total;
          //doc.text(detallesVenta[i].total,705,y);
          y=y+40;
          items++;
          
          if(items==itemsPorPagina || i+1==detallesCompra.length){
            if(i+1==detallesCompra.length){
              
            }else{
              doc.addPage({margin:0,bufferPages: true});
              y=150;
              items=0;
              pagina=pagina+1;
              this.generarCabeceraPdfHistorico(doc);
              this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
                this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
                (this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
              (this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
              (this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
              fecha_reporte,{imprimir_usuario:true});
              doc.font('Helvetica',8);
            }
          }
        }
        doc.rect(50,y-15,540,20).stroke();
        doc.text("TOTAL : "+(Math.round(suma_total*100)/100),480,y-7,{width:80,align:'right'});
        doc.end();
        stream.on('finish', function() {
          var fileURL = stream.toBlobURL('application/pdf');
          window.open(fileURL,'_blank','location=no');
        });
        this.blockUI.stop();
      });
    }

    generarCabeceraPdfHistorico(doc){
      doc.font('Helvetica-Bold',8);
      doc.roundedRect(360,70,230,25,5).stroke();
      doc.text("DEL : ",385,75);
      doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,440,75);		
      doc.text("AL : ",385,85);
      doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,440,85);		
      
      doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
      doc.font('Helvetica-Bold',8);
      doc.text("Fecha",55,120);
      doc.text("Nro. Doc",88,115,{width:30});
      doc.text("Tipo Doc.",110,120);
      doc.text("Proveedor",170,120);
      doc.text("Producto",260,120,{width:50});
      doc.text("Unidad",340,120,{width:60});
      doc.text("Cant.",380,120,{width:50});
      doc.text("C.U.",415,120,{width:50});
      //doc.text("Saldo",560,120,{width:50});
      if(this.usuario.empresa.usar_vencimientos){
        doc.text("Fecha Venc.",475,115,{width:30});
        doc.text("Lote",510,120,{width:35});
      }
      doc.text("Total",545,120);	
      
    }

    establecerSucursal(){
      
    }

}
