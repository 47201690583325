import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from '../../../global';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  constructor(private http: HttpClient) { }

  public obtenerListaVentas(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+((paginator.filter.cliente=="" || paginator.filter.cliente==undefined)?0:paginator.filter.cliente)+"/tipo-venta/"+paginator.filter.tipo_pago.id+"/sucursal/"+paginator.filter.sucursal.id+"/transaccion/"+paginator.filter.transaccion.id+"/usuario/"+paginator.filter.usuario.id+"/observacion/"+(paginator.filter.observacion==null?0:paginator.filter.observacion)+"/producto/"+(paginator.filter.producto==null?0:paginator.filter.producto)+"/columna/"+paginator.configuracion_pagina.getOrden().columna+"/direccion/"+paginator.configuracion_pagina.getOrden().direccion+"/canal/"+paginator.filter.canal.id+"/despacho/"+paginator.filter.despacho.id+"/activa/"+paginator.filter.activa.id+"/con-detalle/"+paginator.filter.con_detalle+"/solo-obsequios/"+(paginator.filter.solo_obsequios!=undefined && paginator.filter.solo_obsequios?1:0), GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionVista(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresas/"+id_empresa+"/configuracion-venta-vista", GlobalVariable.httpOptions);
  }

  public actualizarConfiguracionVista(id_empresa,dato){
    return this.http.put(GlobalVariable.API_SERVER_URL+"empresas/"+id_empresa+"/configuracion-venta-vista",dato, GlobalVariable.httpOptions);
  }

  public guardarVenta(venta){
    return this.http.post(GlobalVariable.API_SERVER_URL+"ventas",venta, GlobalVariable.httpOptions);
  }
  
  public obtenerVenta(id_venta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas/"+id_venta, GlobalVariable.httpOptions);
  }

  public obtenerDatosVentaCredito(id_venta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"datos-venta-credito/"+id_venta, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionImpresion(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-impresion/"+id_sucursal, GlobalVariable.httpOptions);
  }
  
  public anularVenta(venta){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"ventas/"+venta.id,venta, GlobalVariable.httpOptions);
  }

  public anularServicio(id_venta){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"venta-servicio-consumo/"+id_venta, GlobalVariable.httpOptions);
  }

  public anularVentaEstudiante(id_venta){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"venta-estudiante/"+id_venta, GlobalVariable.httpOptions);
  }

  public guardarPagoVenta(id_venta,id_empresa,pago_venta){
    return this.http.put(GlobalVariable.API_SERVER_URL+"ventas/"+id_venta+"/empresa/"+id_empresa,pago_venta, GlobalVariable.httpOptions);
  }

  public obtenerPagoVenta(id_pago_venta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pago-venta/"+id_pago_venta, GlobalVariable.httpOptions);
  }

  public obtenerListaPagosVenta(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"pagos-ventas/empresa/"+paginator.filter.id_empresa+"/venta/"+paginator.filter.id_venta+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+((paginator.filter.cliente==null || paginator.filter.cliente==undefined || paginator.filter.cliente=="")?0:paginator.filter.cliente), GlobalVariable.httpOptions);
  }

  public anularPagoVenta(id_pago_venta){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"pago-venta/"+id_pago_venta, GlobalVariable.httpOptions);
  }

  public guardarDevolucionVenta(devolucion_venta){
    return this.http.post(GlobalVariable.API_SERVER_URL+"devolucion-venta",devolucion_venta, GlobalVariable.httpOptions);
  }
  
  public descontarAlmacen(venta){
    return this.http.put(GlobalVariable.API_SERVER_URL+"venta-descuento-almacen/"+venta.id,venta, GlobalVariable.httpOptions);
  }

  public enviarDocumentoVentaCorreo(documento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-documento-venta-correo",documento, GlobalVariable.httpOptions);
  }

  public enviarAnulacionVentaCorreo(documento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-anulacion-venta-correo",documento, GlobalVariable.httpOptions);
  }

  public sincronizarAnulacionVenta(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/subir-anulacion-venta",datos, GlobalVariable.httpOptions);
  }

  public obtenerListaVentasCreditoNotificacion(id_empresa,id_sucursal,id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vencimientos-creditos/"+id_empresa+"/sucursal/"+id_sucursal+"/usuario/"+id_usuario, GlobalVariable.httpOptions);
  }

  public actualizarDescuentoAlmacen(id_venta,venta){
    return this.http.put(GlobalVariable.API_SERVER_URL+"venta/"+id_venta+"/descuento-almacen",venta, GlobalVariable.httpOptions);
  }

  public sincronizarVenta(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/subir-venta",datos, GlobalVariable.httpOptions);
  }

  public importarVentas(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"ventas-importacion",datos, GlobalVariable.httpOptions);
  }

  public obtenerDatosLibroVentas(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/libro-ventas/"+filtro.id_empresa+"/sucursal/"+filtro.sucursal.id+"/gestion/"+filtro.gestion.nombre_corto+"/mes/"+filtro.mes.nombre_corto, GlobalVariable.httpOptions);
  }

  public enviarAgradecimientoPagoCliente(documento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-agradecimiento-pago-cliente-correo",documento, GlobalVariable.httpOptions);
  }

  public obtenerListaDevolucionesVenta(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"devoluciones-ventas/empresa/"+paginator.filter.id_empresa+"/venta/"+paginator.filter.id_venta+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/cliente/"+((paginator.filter.cliente==null || paginator.filter.cliente==undefined || paginator.filter.cliente=="")?0:paginator.filter.cliente), GlobalVariable.httpOptions);
  }

  public obtenerDevolucionVenta(id_devolucion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"devolucion-venta/"+id_devolucion, GlobalVariable.httpOptions);
  }

  public obtenerDatosDevolucionGlobal(id_cliente,id_contacto_cliente,id_despacho){
    return this.http.get(GlobalVariable.API_SERVER_URL+"devolucion-ventas-global/despacho/"+id_despacho+"/cliente/"+id_cliente+"/contacto_cliente/"+id_contacto_cliente, GlobalVariable.httpOptions);
  }

  public actualizarVenta(venta){
    return this.http.put(GlobalVariable.API_SERVER_URL+"ventas/"+venta.id,venta, GlobalVariable.httpOptions);
  }

  public obtenerLeyendas(codigo_actividad){
    return this.http.get(GlobalVariable.API_SERVER_URL+"leyendas-factura/"+codigo_actividad, GlobalVariable.httpOptions);
  }
    
  public verificarEstadoFacturaSin(id_venta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-verificar-estado-factura/"+id_venta, GlobalVariable.httpOptions);
  }

  public obtenerVentasEventoSignificativo(id_evento_significativo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas/sin-evento-significativo/"+id_evento_significativo, GlobalVariable.httpOptions);
  }

  public anularEventoSignificativo(evento_significativo){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"evento-significativo/"+evento_significativo.id, GlobalVariable.httpOptions);
  }

  public sincronizarElimincionDatos(fecha){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/eliminar-ventas",{fecha:fecha}, GlobalVariable.httpOptions);
  }

  public subirDocumentosVenta(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"ventas-documentos",datos, GlobalVariable.httpOptions);
  }

  public obtenerDatosPagosEstudiantes(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-pagos-estudiantes/sucursales/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public convertirVentaCredito(venta){
    return this.http.post(GlobalVariable.API_SERVER_URL+"conversion-venta-credito",venta, GlobalVariable.httpOptions);
  }

  public activarEventoSignificativo(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"api/sfe/activar-evento-significativo",datos, GlobalVariable.httpOptions);
  }

  public desactivarEventoSignificativo(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"api/sfe/desactivar-evento-significativo",datos, GlobalVariable.httpOptions);
  }

  public obtenerPdfVenta(venta){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(GlobalVariable.API_SERVER_URL+"impresion-venta/"+venta.id,venta,{ headers: headers, responseType: 'blob' });
  }

  public anularFacturaCuf(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"anulacion-factura-cuf",datos, GlobalVariable.httpOptions);
  }

  public enviarVentaWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-venta-whatsapp",datos, GlobalVariable.httpOptions);
  }
}
