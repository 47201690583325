<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Reporte de Pagos</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="generarPdfReportePagos()">
          <i class="fas fa-file-pdf red2 text-110"></i>
      </button>&nbsp;
      <button class="btn btn-success" type="button" (click)="generarExcelReportePagos()">
        <i class="fas fa-file-excel red2 text-110"></i>
    </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
          <i class="fa fa-times red2 text-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row" >
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Carrera </label>&nbsp;
                <select [compareWith]="compararObjectos" name="carrera" class="form-control" [(ngModel)]="filter.carrera">
                    <option [ngValue]="carrera" *ngFor="let carrera of filter.carreras">{{carrera.nombre}}</option>
                </select>
            </div>  
          </div>
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Gestión </label>&nbsp;
                <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="filter.gestion">
                    <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                </select>
            </div>  
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Semestre </label>&nbsp;
                <select [compareWith]="compararObjectos" name="semestre" class="form-control" [(ngModel)]="filter.semestre">
                    <option [ngValue]="semestre" *ngFor="let semestre of filter.semestres">{{semestre.nombre}}</option>
                </select>
            </div>  
        </div>
        <div class="col-md-3 col-xs-3 col-sm-3">
            <button (click)="obtenerReportePagosInstituto()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <div class="row p-1">
        <div class="col-lg-12 p-1">
            <div class="card bcard overflow-hidden shadow text-center">
                <div class="card-body p-3px bgc-green-d2">
                    <div class="radius-1 table-responsive">
                        <div class="col-12">
                            <span class="text-white text-125">Reporte de Pagos</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card bcard overflow-hidden shadow">
              <div class="card-body p-3px bgc-green-d2">
                <div class="radius-1 table-responsive">
                  <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                    <thead class="brc-transparent">
                      <tr class="bgc-green-d2 text-white">
                        <th>
                          Nº
                        </th>
                        <th>
                          Código
                        </th>
                        <th>
                          Estudiante
                        </th>
                        <th>
                          Pagos
                        </th>
                        <th style="text-align: center; width: 140px; " data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                      </tr>
                    </thead>
        
                    <tbody>
                      <tr class="bgc-h-yellow-l3" *ngFor="let estudiante of estudiantes;let i=index;">
                        <ng-container >
                          <td class="text-600 text-dark text-center">
                            {{i+1}}
                          </td>
                          <td class="text-green-m1 text-180 text-600">
                              {{estudiante.codigo}}
                          </td>
                          <td class="text-info-m1 text-130 text-600">
                            {{estudiante.persona.nombres}}
                          </td>
                          <td class="text-danger-m1 text-130">
                            {{estudiante.persona.nombres}}
                          </td>
                          <td class="text-center">
                              
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div><!-- ./table-responsive -->
              </div><!-- /.card-body -->
            </div><!-- /.card -->
        
        </div><!-- /.col -->
    </div>
</div>