<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Asunto Administrativo</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarAsuntoAdministrativo()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row" *ngIf="usuario.empresa.usar_estudiantes_colegio">
        <div class="col-sm-12 col-md-3">
            <label>Estudiante</label>
        </div>
        <div class="col-sm-12 col-md-9">
            {{estudiante.nombre_completo}}
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Asunto</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="asunto" type="text" id="asunto" [(ngModel)]="asunto_administrativo.asunto" placeholder="Asunto" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <textarea style="overflow:auto;resize:none" rows="5" cols="30" [(ngModel)]="asunto_administrativo.descripcion"></textarea>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Estado</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="asunto_administrativo.estado">
                <option [ngValue]="estado" *ngFor="let estado of estados">{{estado.nombre}}</option>
            </select>
        </div>
    </div>
</div>