import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { VecinosComponent } from './components/vecinos/vecinos.component';
import { LecturacionComponent } from './components/lecturacion/lecturacion.component';

const routes: Routes = [
  { path: 'vecinos',component: VecinosComponent,canActivate:[HabilitadoGuard]},
  { path: 'lecturacion',component: LecturacionComponent,canActivate:[HabilitadoGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NeighborhoodRoutingModule { }
