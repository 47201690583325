import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { Socket } from 'ngx-socket-io';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-lista-pagos-ventas',
  templateUrl: './lista-pagos-ventas.component.html',
  styleUrls: ['./lista-pagos-ventas.component.css']
})
export class ListaPagosVentasComponent extends BaseComponent implements OnInit {

  lista_pagos_ventas:any[]=[];
  venta:any;
  @BlockUI() blockUI: NgBlockUI;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  popupConfirmacion:NgbModalRef;
  global_variable=GlobalVariable;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public ventasService:VentasService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    private socket: Socket) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    let fecha_actual=new Date();
    let fecha_inicio=this.venta?new Date(this.venta.fecha):fecha_actual;
    this.filter={
      id_empresa:this.usuario.id_empresa,
      fecha_inicio:{
        year: fecha_inicio.getFullYear(), 
        month: (fecha_inicio.getMonth()+1),
        day:fecha_inicio.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      id_venta:this.venta?this.venta.id:0
    }
    this.getItems();
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.ventasService.obtenerListaPagosVenta(this).subscribe((lista_pagos_ventas:any) => {
			this.setPages(1);
			this.lista_pagos_ventas=lista_pagos_ventas;
			this.blockUI.stop();
		});
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  imprimirPagoVenta(pago_venta){
    this.blockUI.start('Recuperando... espere por favor!');
    this.pdfService.imprimirReciboPagoVenta(pago_venta,this.usuario);
    this.blockUI.stop();
  }

  abrirPopupConfirmacionEliminacion(pago_venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el pago de venta?";
		this.popupConfirmacion.componentInstance.data = pago_venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarPagoVenta($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarPagoVenta(pago_venta){
		this.blockUI.start();
		this.ventasService.anularPagoVenta(pago_venta.id).subscribe((res:any) => {
      this.toastr.info(res.mensaje);
      if(this.usuario.empresa.usar_contabilidad){
        /*var promesa=ClasesTipo("TIPFUENTECOMP");
        promesa.then(function(tipoFuentesComprobante){
          var fuentes_comprobantes=tipoFuentesComprobante.clases;
          var datos_fuente=new AnulacionComprobanteAutomatizado({
            datos_fuente:pago_venta.id,
            fuente:$.grep(fuentes_comprobantes, function (e) { return e.nombre_corto == Diccionario.TIPO_FUENTE_COMPROBANTE_AUTOMATICA_PAGO_VENTA; })[0]
          });
          datos_fuente.$save(function(resAnu){
            MostrarMensaje(resAnu.mensaje);
          })
        });*/
      }else{
				this.blockUI.stop();
			}
			this.getItems();
		});
  }
  
  sumarImporte(){
		var suma=0;
		for(var i=0;i<this.lista_pagos_ventas.length;i++){
			if(this.lista_pagos_ventas[i].activo){
				suma=suma+this.lista_pagos_ventas[i].monto_pagado;
			}
		}
		return Math.round(suma*100)/100;
	}

  abrirPopupConfirmacionEnvioCorreo(pago_venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el agradecimiento del pago de la venta?";
		this.popupConfirmacion.componentInstance.data = pago_venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarAgradecimientoPago($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	  async enviarAgradecimientoPago(pago_venta){
      if(pago_venta.venta.cliente.email){
        let me=this;
        let fecha=new Date(pago_venta.fecha);
        let pdf=await this.generarPdfDocumentoPago(pago_venta);
        let reader = new window.FileReader();
        reader.readAsDataURL(pdf);
        reader.onloadend = function () {
          let base64data = reader.result;
          let en={
            documento:base64data,
            cliente:pago_venta.venta.cliente,
            nombre_documento:(pago_venta.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION?"FACTURA":"NOTA DE VENTA")+" No "+pago_venta.venta.factura,
            numero_documento:pago_venta.numero_documento,
            fecha:fecha.getDate()+"-"+(fecha.getMonth()+1)+"-"+fecha.getFullYear()
          };
          me.blockUI.start();
          me.ventasService.enviarAgradecimientoPagoCliente(en).subscribe((res:any) => {
            if(res.tiene_error){
              me.toastr.error(res.mensaje);
            }else{
              me.toastr.success(res.mensaje);
            }
            me.blockUI.stop();
          });
        }
      }else{
        this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente");
      }
	  }

  async generarPdfDocumentoPago(pago_venta){
    let pdf_respuesta=null;
    pdf_respuesta=await this.pdfService.imprimirReciboPagoVenta(pago_venta,this.usuario);
    return pdf_respuesta;
  }

  abrirPopupConfirmacionHabilitacionWhatsapp(cliente){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el mensaje de habilitacion por Whatsapp?";
    this.popupConfirmacion.componentInstance.data = cliente;
    
    this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
      if($e.confirm){
        this.enviarMensajeHabilitacionWhatsapp($e.data);
      }
      this.popupConfirmacion.close();
    });
  }

  enviarMensajeHabilitacionWhatsapp(cliente){
    if(cliente.telefono1){
      let me=this;
      let mensaje='🙋🏻‍♂️ *Hola '+cliente.identificacion+'!* 😊 \n🤖 *Soy el Bot Goku!* 🤖\n\n🔓Se acaba de habilitar su acceso al sistema Snapquick. \n\n😊Bienvenido nuevamente!';
      let en={
        cliente:cliente,
        mensaje:mensaje,
        sucursal:Util.obtenerSucursalesUsuario(me.usuario)[0]
      };
      me.blockUI.start();
      me.socket.emit('enviarMensajeHabilitacionWhatsapp',en);
      me.blockUI.stop();
    }else{
      this.toastr.error("Debe agregar una dirección de correo electrónico para el cliente");
    }
  }

}
