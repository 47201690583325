import { Component, OnInit, Output,EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { VehiculosService } from 'src/app/transporte/services/vehiculos/vehiculos.service';
import { Util } from 'src/app/utils/utils';
import { OrdenesTrabajosService } from '../../services/ordenes-trabajos/ordenes-trabajos.service';

declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;
declare const enfocarElemento:any;
declare const $:any;

@Component({
  selector: 'app-registro-orden-trabajo',
  templateUrl: './registro-orden-trabajo.component.html',
  styleUrls: ['./registro-orden-trabajo.component.css']
})
export class RegistroOrdenTrabajoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  wizard_edicion_orden_trabajo:string="wizard_edicion_orden_trabajo";

  orden_trabajo:any={ vehiculo:{propietario:{}},inventario_ingreso:{}}
  vehiculo_busqueda:any;
  searching = false;
  searchFailed = false;
  codigo_automatico_propietario:boolean=false;

  registro_servicio_solicitado_modal:NgbModalRef;
  @ViewChild('registro_servicio_solicitado_modal')
  private registro_servicio_solicitado_modal_ref: TemplateRef<any>;

  servicio_solicitado:any={};

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    public empresasService:EmpresaService,
    public vehiculosService:VehiculosService,
    public ordenTrabajoService:OrdenesTrabajosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.init();
    let fecha_actual=new Date();
    let codigo_nuevo_propietario=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
    this.codigo_automatico_propietario=this.codigo_automatico;
    this.orden_trabajo={
      id_empresa: this.usuario.id_empresa, 
      id_usuario:this.usuario.id,
      vehiculo:{
        propietario:{
          codigo:codigo_nuevo_propietario
        }
      },
      fecha_ingreso_texto:{
        year:fecha_actual.getFullYear(),
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      inventario_ingreso:{},
      servicios_solicitados:[],
      sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0]
    }
  }

  ngAfterViewInit() {
    aplicarWizard(this.wizard_edicion_orden_trabajo,this,'guardar');
	  aplicarPluginImagen();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  buscarVehiculo = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.vehiculosService.buscarVehiculo(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatter = (x: any) => (x.id?(x.placa)+" "+(x.marca)+" "+(x.modelo):"");

  async establecerVehiculo(event){
    let vehiculo=(event.id || event.placa)?event:event.item;
    this.vehiculo_busqueda=vehiculo.placa;
    this.orden_trabajo.vehiculo=vehiculo;
    if(!this.orden_trabajo.vehiculo.propietario){
      let codigo_nuevo_propietario=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
      this.codigo_automatico_propietario=this.codigo_automatico;
      this.orden_trabajo.vehiculo.propietario={codigo:codigo_nuevo_propietario};
    }
    //this.orden_trabajo.razon_social=vehiculo.placa;
  }

  reiniciarVehiculo(){
    this.orden_trabajo.vehiculo={};
    this.vehiculo_busqueda=null;
    $("#ot_vehiculo").val("");
    enfocarElemento('ot_vehiculo');
  }

  subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.orden_trabajo.vehiculo.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  async guardar(){
		this.blockUI.start();
    if(this.validarOrdenTrabajo()){
      this.orden_trabajo.fecha_ingreso=Util.convertirObjetoAfecha(this.orden_trabajo.fecha_ingreso_texto);
      this.orden_trabajo.fecha_salida=Util.convertirObjetoAfecha(this.orden_trabajo.fecha_salida_texto);
      this.orden_trabajo.estado={};//this.venta.tipoPago.nombre_corto=="CRE" && this.venta.saldo>0?this.estados.filter(e => e.nombre_corto == "ESTVENTVIG")[0]:this.estados.filter(e => e.nombre_corto == "ESTVENTSAN")[0];
      this.orden_trabajo.vehiculo.propietario.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE):this.orden_trabajo.vehiculo.propietario.codigo;
      this.ordenTrabajoService.guardarOrdenTrabajo(this.orden_trabajo).subscribe((res:any) => {
        if(!res.tiene_error){
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
        }
        this.blockUI.stop();
        this.alTerminar.emit(res);
      });
    }else{
      this.blockUI.stop();
    }
	}

  validarOrdenTrabajo(){
    let res=true;
    if(!this.orden_trabajo.fecha_ingreso_texto || !this.orden_trabajo.fecha_ingreso_texto.year || !this.orden_trabajo.fecha_ingreso_texto.month || !this.orden_trabajo.fecha_ingreso_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de ingreso de la orden de trabajo!");
    }
    if(!this.orden_trabajo.fecha_salida_texto || !this.orden_trabajo.fecha_salida_texto.year || !this.orden_trabajo.fecha_salida_texto.month || !this.orden_trabajo.fecha_salida_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de salida de la orden de trabajo!");
    }
    if(!this.orden_trabajo.vehiculo.placa){
      res=res && false;
      this.toastr.error("Debe especificar la placa del vehiculo!");
    }
    if(!this.orden_trabajo.vehiculo.marca){
      res=res && false;
      this.toastr.error("Debe especificar la marca del vehiculo!");
    }
    if(!this.orden_trabajo.vehiculo.kilometraje_actual){
      res=res && false;
      this.toastr.error("Debe especificar el kilometraje actual del vehiculo!");
    }
    if(!this.orden_trabajo.vehiculo.propietario.identificacion){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del propietario del vehiculo!");
    }
    if(!this.orden_trabajo.vehiculo.propietario.telefono2){
      res=res && false;
      this.toastr.error("Debe especificar el telefono movil del propietario del vehiculo!");
    }
    /*if(this.orden_trabajo.servicios_solicitados.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un servicio a la orden de trabajo!");
    }*/
    return res;
  }

  crearServicioSolicitado(){
    this.servicio_solicitado={
      activo:true
    }
    this.abrirRegistroServicioSolicitado();
  }

  abrirRegistroServicioSolicitado(){
    this.registro_servicio_solicitado_modal=this.modalService.open(this.registro_servicio_solicitado_modal_ref, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_servicio_solicitado_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  agregarServicioSolicitado(){
    if(this.validarServicioSolicitado()){
      this.orden_trabajo.servicios_solicitados.push(this.servicio_solicitado);
      this.registro_servicio_solicitado_modal.close();
    }
  }

  validarServicioSolicitado(){
    let res=true;
    if(!this.servicio_solicitado.descripcion){
      res=res && false;
      this.toastr.error("Debe especificar la descripcion del servicio!");
    }
		return res;
  }

  abrirPopupConfirmacionEliminacion(servicio_solicitado){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el servicio solicitado?";
		this.popupConfirmacion.componentInstance.data = servicio_solicitado;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarServicioSolicitado($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarServicioSolicitado(servicio_solicitado){
    servicio_solicitado.eliminado=true;
    servicio_solicitado.activo=false;
    if(!servicio_solicitado.id){
      this.orden_trabajo.servicios_solicitados.splice(this.orden_trabajo.servicios_solicitados.indexOf(servicio_solicitado),1);
    }
  }

}
