import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { BaseComponent } from 'src/app/models/base-component';

@Component({
  selector: 'app-registro-concepto-ingreso',
  templateUrl: './registro-concepto-ingreso.component.html',
  styleUrls: ['./registro-concepto-ingreso.component.css']
})
export class RegistroConceptoIngresoComponent extends BaseComponent implements OnInit {

  concepto_ingreso:any;
  tipo_concepto_pago:any;
  tipo_descripcion_pago:any[]=[];
  gestiones:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private conceptosIngresosService:ConceptosIngresosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.obtenerConceptosDescripcionPagoEscuela();
    if(this.concepto_ingreso.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING);
    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING);
      this.concepto_ingreso.codigo=codigo_nuevo;
    }
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  validarConceptoIngreso(){
    let res=true;
    if(!this.concepto_ingreso.concepto){
      res=res && false;
      this.toastr.error("Debe especificar el concepto de ingreso!");
    }
    if(!this.concepto_ingreso.importe){
      res=res && false;
      this.toastr.error("Debe especificar el importe!");
    }
    if(!this.concepto_ingreso.cuotas){
      res=res && false;
      this.toastr.error("Debe especificar el número de cuotas!");
    }
    return res;
  }

  async guardarConceptoIngreso(){
    if(this.validarConceptoIngreso()){
      this.blockUI.start();
			if(this.concepto_ingreso.id){
				this.conceptosIngresosService.actualizarConceptoPagoColegio(this.concepto_ingreso).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.alTerminar.emit(res);
				});
			}else{
        this.concepto_ingreso.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING):this.concepto_ingreso.codigo;
				this.conceptosIngresosService.guardarConceptoPagoColegio(this.concepto_ingreso).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING);
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.alTerminar.emit(res);
				});
			}
		}
  }

  async obtenerConceptosPagoEscuela(){
		this.tipo_concepto_pago=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CONCEPTO_PAGOS).toPromise();
	}

  async obtenerConceptosDescripcionPagoEscuela(){
    let tipo_descripcion_pago:any=await this.generalService.obtenerTiposEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_DESCRIPCION_PAGO).toPromise();
		this.tipo_descripcion_pago=tipo_descripcion_pago;
	}

}
