<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Movimientos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Inventario
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Lista de</span>
          Movimientos de Inventario
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button *ngIf="aplicacion.puede_crear" (click)="crearNuevoMovimiento()" title="Nuevo"  class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-purple-d1"></i>
            </button>
            <button *ngIf="aplicacion.puede_crear" (click)="crearNuevaConversionMovimiento()" title="Nuevo"  class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fas fa-cart-plus text-success-d1"></i>
            </button>
            <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
              <i class="fa fa-wrench text-blue-d1"></i>
              <span class="caret"></span>
            </button>
            <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                  <div class="card bgc-primary brc-primary radius-0">
                    <div class="card-header">
                    <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                    </div>
          
                    <div class="card-body p-0 bg-white">
                    <table class="table table-striped table-hover mb-0">
                      <tbody>
                        <tr>
                          <td class="text-dark-m2">
                          Configuración Vista
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-dark-m2">
                            Descarga Movimientos Inventario
                          </td>
                          <td>
                            ¿Con Detalle?
                            <label>
                              <input name="datos_con_detalle" [(ngModel)]="con_detalle" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                            </label>
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="imprimirFiltroExcelMovimientos()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                          </td>
                          <td>
                            <a (click)="imprimirFiltroMovimientos()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                    </div>
                  </div>
                  </div>
            </div>
            <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                            <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                  <div class="col-md-3 col-sm-12">
                              <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                  <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                              </select>
                            </div>
                  <div class="col-md-3 col-sm-12">
                  <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Transacción </label>&nbsp;
                                    <select [compareWith]="compararObjectos" class="form-control" required name="transaccion" [(ngModel)]="filter.transaccion">
                                    <option [ngValue]="transaccion" *ngFor="let transaccion of filter.transacciones">{{transaccion.nombre}}</option>
                                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Producto </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.producto">
                            </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="movimientos_inventario.length>0" id="tabla-ventas" class="table table-striped table-bordered table-hover table-responsive">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo.show">Movimiento</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().clase.show">Transaccion</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">Num. Doc.</th>
                    <th *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal.show">Sucursal</th>
                    <th *ngIf="usuario.empresa.usar_almacenes && configuracion_pagina.getConfiguration().almacen.show">Almacen</th>
                    <th *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal_destino.show">Sucursal Traspaso</th>
                    <th *ngIf="usuario.empresa.usar_almacenes && configuracion_pagina.getConfiguration().almacen_traspaso.show">Almacen Traspaso</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show">Fecha Doc.</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().createdAt.show">Hora - Fecha</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show">Importe</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show">Observacion</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activa.show">Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let movimiento_inventario of movimientos_inventario; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="movimiento_inventario.tipo.nombre_corto=='MOVING' && configuracion_pagina.getConfiguration().tipo.show"><span class="badge badge-sm bgc-purple brc-purple text-white arrowed-right">{{movimiento_inventario.tipo.nombre}}</span></td>
                    <td *ngIf="movimiento_inventario.tipo.nombre_corto=='MOVEGR' && configuracion_pagina.getConfiguration().tipo.show"><span class="badge btn-pink badge-sm arrowed arrowed-in-right">{{movimiento_inventario.tipo.nombre}}</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().clase.show">{{movimiento_inventario.clase.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">{{movimiento_inventario.numero_documento}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal.show">{{movimiento_inventario.almacen.sucursal.nombre}}</td>
                    <td *ngIf="usuario.empresa.usar_almacenes && configuracion_pagina.getConfiguration().almacen.show">{{movimiento_inventario.almacen.nombre}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal_destino.show">{{movimiento_inventario.almacenTraspaso?movimiento_inventario.almacenTraspaso.sucursal.nombre:(movimiento_inventario.movimiento_inventario_origen?movimiento_inventario.movimiento_inventario_origen.almacen.sucursal.nombre:"")}}</td>
                    <td *ngIf="usuario.empresa.usar_almacenes && configuracion_pagina.getConfiguration().almacen_traspaso.show">{{movimiento_inventario.almacenTraspaso?movimiento_inventario.almacenTraspaso.nombre:(movimiento_inventario.movimiento_inventario_origen?movimiento_inventario.movimiento_inventario_origen.almacen.nombre:"")}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha.show">{{movimiento_inventario.fecha | date:"dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().createdAt.show">{{movimiento_inventario.createdAt | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{(movimiento_inventario.total?movimiento_inventario.total.toFixed(2):'')}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{movimiento_inventario.usuario?movimiento_inventario.usuario.nombre_usuario:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().observacion.show"><div style="width: 150px" >{{movimiento_inventario.observacion}}</div></td>
                    <td *ngIf="movimiento_inventario.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!movimiento_inventario.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td>
                        <div class="hidden-sm hidden-xs action-buttons">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirMovimientoInventario(movimiento_inventario)">
                                <i class="ace-icon fa fa-print bigger-130"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verMovimientoInventario(movimiento_inventario)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="movimiento_inventario.activa && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(movimiento_inventario)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="movimientos_inventario.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #configuracion_aplicacion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Configuración Aplicación</h4>
		</div>
		<div class="col-md-6 col-xs-6">
      <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
        <div class="col-3">
            <label>Ordenar por</label>
        </div>
        <div class="col-9">
          <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
            <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
          </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>Dirección</label>
        </div>
        <div class="col-9">
          <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </select>
        </div>
    </div>
	</div>
</ng-template>

<ng-template #vista_detalle_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Datos del Movimiento</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <app-vista-movimiento-inventario
     [movimiento_inventario]="movimiento_inventario"
    >
    </app-vista-movimiento-inventario>
</ng-template>