import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-configuracion-aplicacion-cotizaciones',
  templateUrl: './configuracion-aplicacion-cotizaciones.component.html',
  styleUrls: ['./configuracion-aplicacion-cotizaciones.component.css']
})
export class ConfiguracionAplicacionCotizacionesComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  tipos_documentos:any[]=[];
  tipos_pago:any[]=[];
  tamanos_papel:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTamanosPapel();
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
