<div class="page-content container container-plus">
    <div class="row justify-content-center pos-rel">

      <div class="pos-rel col-12 col-sm-7 mt-1 mt-sm-3">
        <div class="py-3 px-1 py-lg-4 px-lg-5">

          <div class="text-center fa-4x">
            <span class="text-100 text-dark-m3 d-sm-none"><!-- smaller text to fit in small devices -->
        ¯\_(ツ)_/¯
    </span>
            <span class="text-110 text-dark-m3 d-none d-sm-inline">
        ¯\_(ツ)_/¯
    </span>
          </div>


          <div class="text-center fa-4x text-orange-d2 letter-spacing-4">
            404
          </div>


          <div class="text-center">
            <span class="text-150 text-primary-d2">
        Pagina no encontrada
    </span>
          </div>


          <div class="text-dark-m2 text-110 text-center text-md-left mt-45">
            Hemos buscado la pagina que solicitaste, pero no hemos logrado localizarla!
          </div>


          <div class="text-center mt-4">
            <button type="button" class="btn btn-bgc-white btn-outline-default px-35 btn-text-slide-x">
              <i class="btn-text-2 fa fa-arrow-left text-110 align-text-bottom mr-2"></i>Ir Atras
            </button>

            <button type="button" class="btn btn-bgc-white btn-outline-primary px-35">
              <i class="fa fa-home"></i>
              Inicio
            </button>
          </div>


        </div>
      </div>
    </div>
  </div>