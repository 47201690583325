<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Plan de
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Cuentas
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Plan de</span>
          Cuentas
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button title="Nuevo" (click)="crearNuevaCuenta(null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
            <button *ngIf="usuario.empresa.usar_contabilidad_semi_automatica" title="Configuracion Cuentas" (click)="abrirConfiguracionCuentasModal()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-secondary btn-a-outline-secondary py-1 bs-print" type="button">
              <i class="fas fa-list-ul text-warning"></i>
            </button>
            <div class="export btn-group">
                <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                          <i class="fa fa-download text-green-d1"></i>
                          <span class="caret"></span>
                </button>
                <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-plan-cuentas')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                  <i class="fa fa-upload text-red fileUpload-button"></i>
                  <input id="subir-excel-plan-cuentas" style="display:none" type="file" class="form-control" (change)="subirExcelPlanCuentas($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-md-2 col-sm-12"><label>Clasificación </label></div>
                  <div class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="clasificacion" [(ngModel)]="filter.clasificacion">
                        <option [ngValue]="clasificacion" *ngFor="let clasificacion of filter.clasificaciones">{{clasificacion.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-2 col-sm-12"><label>Tipo de Cuenta </label></div>
                  <div class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_cuenta" [(ngModel)]="filter.tipo_cuenta">
                        <option [ngValue]="tipo_cuenta" *ngFor="let tipo_cuenta of filter.tipos_cuentas">{{tipo_cuenta.nombre}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="cuentas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th>Código</th>
                    <th>Nombre</th>
                    <th>Debe</th>
                    <th>Haber</th>
                    <th>Saldo</th>
                    <th>Clasificación</th>
                    <th>Tipo Cuenta</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let cuenta of cuentas; let i=index;">
                    <td>{{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}</td>
                    <td>{{cuenta.codigo}}</td>
                    <td>{{cuenta.nombre}}</td>
                    <td>{{cuenta.debe}}</td>
                    <td>{{cuenta.haber}}</td>
                    <td>{{cuenta.saldo}}</td>
                    <td>{{cuenta.clasificacion.nombre}}</td>
                    <td>{{cuenta.tipoCuenta.nombre}}</td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="d-lg-inline text-muted">
                            <a title="Libro Mayor" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirLibroMayorCuenta(cuenta)" >
                                <i class="fas fa-atlas"></i>
                            </a>
                            <a title="Libro Mayor" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCuenta(cuenta)">
                                <i class="fas fa-pencil-alt"></i>
                            </a>
                            <a title="Cuenta Auxiliar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="crearNuevaCuenta(cuenta)" >
                                <i class="far fa-plus-square"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cuenta)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="cuentas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #cuentas_importacion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Plan de Cuentas a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || cuentas_importacion.length==0" (click)="guardarCuentas()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color sticky-nav">
								<tr>
									<th class="detail" rowspan="1">
                                        <div class="th-inner ">Nº</div>
                                    </th>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Debe</th>
                                    <th>Haber</th>
                                    <th>Saldo</th>
                                    <th>Clasificación</th>
                                    <th>Tipo Cuenta</th>
                                    <th>Bimonetaria</th>
								</tr>
							</thead>
		
							<tbody>
								<tr *ngFor="let cuenta of cuentas_importacion; let i=index;">
									<td>{{i+1}}</td>
									<td>{{cuenta.codigo}}</td>
									<td>{{cuenta.nombre}}</td>
                                    <td>{{cuenta.descripcion}}</td>
                                    <td>{{cuenta.clasificacion.nombre}}</td>
                                    <td>{{cuenta.tipoCuenta.nombre}}</td>
                                    <td>{{cuenta.debe}}</td>
                                    <td>{{cuenta.haber}}</td>
                                    <td>{{cuenta.saldo}}</td>
                                    <td>{{cuenta.bimonetaria==0?"NO":"SI"}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #registro_cuenta_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Cuenta</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarCuenta()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-4">
            <label>Código</label>
        </div>
        <div class="col-8">
            <input [disabled]="cuenta.id_cuenta_padre" required type="text" id="codigo" name="codigo" [(ngModel)]="cuenta.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="nombre" required type="text" [(ngModel)]="cuenta.nombre" placeholder="Nombre" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="descripcion" required type="text" [(ngModel)]="cuenta.descripcion" placeholder="Descripción" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>Clasificación</label>
      </div>
      <div class="col-sm-12 col-md-9">
          <select [compareWith]="compararObjectos" name="clasificacion" class="form-control" [(ngModel)]="cuenta.clasificacion">
              <option [ngValue]="clasificacion" *ngFor="let clasificacion of clasificaciones">{{clasificacion.nombre}}</option>
          </select>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>Tipo Cuenta</label>
      </div>
      <div class="col-sm-12 col-md-9">
          <select [compareWith]="compararObjectos" name="tipo_cuenta" class="form-control" [(ngModel)]="cuenta.tipoCuenta">
              <option [ngValue]="tipo_cuenta" *ngFor="let tipo_cuenta of tipos_cuenta">{{tipo_cuenta.nombre}}</option>
          </select>
      </div>
    </div>
    <ng-container *ngIf="usuario.empresa.usar_contabilidad_semi_automatica && (!cuenta.proveedor || !cuenta.proveedor.id)">
      <hr>
      <div class="row">
        <div class="col-3">
            <label>Cliente</label>
        </div>
        <div class="col-7">
          <input name="cliente" [disabled]="cuenta.cliente" [inputFormatter]="formatter" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="cliente_busqueda" 
          [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off" (selectItem)="establecerCliente($event)"/>
          <ng-template #rt let-r="result" let-t="term">
            <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
          </ng-template>
        </div>
        <div class="col-2">
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="cuenta.cliente && cuenta.cliente.id" (click)="reiniciarCliente()">
            <i class="fa fa-trash bigger-130 red2"></i>
        </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!cuenta.cliente || !cuenta.cliente.id">
      <hr>
      <div class="row">
        <div class="col-3">
            <label>Proveedor</label>
        </div>
        <div class="col-7">
          <input name="proveedor" [disabled]="cuenta.proveedor" [inputFormatter]="formatterProveedor" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="proveedor_busqueda" 
          [ngbTypeahead]="buscarProveedor" placeholder="Buscar proveedor" autocomplete="off" (selectItem)="establecerProveedor($event)"/>
          <ng-template #rt let-r="result" let-t="term">
            <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
          </ng-template>
        </div>
        <div class="col-2">
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="cuenta.proveedor && cuenta.proveedor.id" (click)="reiniciarProveedor()">
            <i class="fa fa-trash bigger-130 red2"></i>
        </a>
        </div>
      </div>
    </ng-container>
</div>
</ng-template>

<ng-template #configuracion_cuentas_modal let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Registro Cuenta</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="guardarConfiguracionCuentas()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-3">
              <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS}}</label>
          </div>
          <div class="col-6">
            <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS}}" [disabled]="configuracion_cuentas.cuenta_ventas.cuenta && configuracion_cuentas.cuenta_ventas.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_ventas.cuenta_busqueda" 
            [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_ventas.cuenta_a_buscar" 
            [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_ventas)"/>
            <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
            </ng-template>

            <select *ngIf="!configuracion_cuentas.cuenta_ventas.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_ventas.cuenta,configuracion_cuentas.cuenta_ventas)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS}}" [(ngModel)]="configuracion_cuentas.cuenta_ventas.cuenta">
              <option *ngIf="!configuracion_cuentas.cuenta_ventas.cuenta" [ngValue]="null" selected>Ninguno</option>
                <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                    <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
                </optgroup>
            </select>
          </div>
          <div class="col-3">
            <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_ventas.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_ventas,configuracion_cuentas.cuenta_ventas.cuenta_busqueda)" >
              <i class="fas fa-search"></i>
            </a>
            <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_ventas.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_ventas,configuracion_cuentas.cuenta_ventas.cuenta_busqueda)" >
                <i class="fas fa-list-alt"></i>
            </a>
            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_ventas)">
              <i class="fa fa-trash-alt"></i>
            </a>
          </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR}}" [disabled]="configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta && configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_cuentas_por_cobrar)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta,configuracion_cuentas.cuenta_cuentas_por_cobrar)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR}}" [(ngModel)]="configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_cuentas_por_cobrar,configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_cuentas_por_cobrar,configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_cuentas_por_cobrar)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS}}" [disabled]="configuracion_cuentas.cuenta_caja_dinero.cuenta && configuracion_cuentas.cuenta_caja_dinero.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_caja_dinero.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_caja_dinero)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_caja_dinero.cuenta,configuracion_cuentas.cuenta_caja_dinero)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS}}" [(ngModel)]="configuracion_cuentas.cuenta_caja_dinero.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_caja_dinero.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_caja_dinero,configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_caja_dinero,configuracion_cuentas.cuenta_caja_dinero.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_caja_dinero)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF}}" [disabled]="configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta && configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_iva_credito_fiscal)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta,configuracion_cuentas.cuenta_iva_credito_fiscal)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF}}" [(ngModel)]="configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_iva_credito_fiscal,configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_iva_credito_fiscal,configuracion_cuentas.cuenta_iva_credito_fiscal.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_iva_credito_fiscal)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF}}" [disabled]="configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta && configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_iva_debito_fiscal)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta,configuracion_cuentas.cuenta_iva_debito_fiscal)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF}}" [(ngModel)]="configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_iva_debito_fiscal,configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_iva_debito_fiscal,configuracion_cuentas.cuenta_iva_debito_fiscal.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_iva_debito_fiscal)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT}}" [disabled]="configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta && configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_impuesto_a_las_transacciones)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta,configuracion_cuentas.cuenta_impuesto_a_las_transacciones)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT}}" [(ngModel)]="configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_impuesto_a_las_transacciones,configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_impuesto_a_las_transacciones,configuracion_cuentas.cuenta_impuesto_a_las_transacciones.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_impuesto_a_las_transacciones)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-3">
            <label>{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR}}</label>
        </div>
        <div class="col-6">
          <input name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR}}" [disabled]="configuracion_cuentas.cuenta_it_por_pagar.cuenta && configuracion_cuentas.cuenta_it_por_pagar.cuenta.id" [inputFormatter]="formatterCuenta" *ngIf="configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda" 
          [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="configuracion_cuentas.cuenta_it_por_pagar.cuenta_a_buscar" 
          [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuenta($event,configuracion_cuentas.cuenta_it_por_pagar)"/>
          <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
          </ng-template>

          <select *ngIf="!configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda" (change)="establecerCuenta(configuracion_cuentas.cuenta_it_por_pagar.cuenta,configuracion_cuentas.cuenta_it_por_pagar)" [compareWith]="compararObjectos" class="form-control" required name="{{global_variable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR}}" [(ngModel)]="configuracion_cuentas.cuenta_it_por_pagar.cuenta">
            <option *ngIf="!configuracion_cuentas.cuenta_it_por_pagar.cuenta" [ngValue]="null" selected>Ninguno</option>
              <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                  <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
              </optgroup>
          </select>
        </div>
        <div class="col-3">
          <a title="Buscar Cuenta" *ngIf="!configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_it_por_pagar,configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda)" >
            <i class="fas fa-search"></i>
          </a>
          <a title="Seleccionar Cuenta" *ngIf="configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(configuracion_cuentas.cuenta_it_por_pagar,configuracion_cuentas.cuenta_it_por_pagar.cuenta_busqueda)" >
              <i class="fas fa-list-alt"></i>
          </a>
          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarCuenta(configuracion_cuentas.cuenta_it_por_pagar)">
            <i class="fa fa-trash-alt"></i>
          </a>
        </div>
    </div>
  </div>
</ng-template>