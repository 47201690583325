<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Datos Compra</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-4">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.tipo_documento.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Num. Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.factura}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Num. Documento (Sistema)  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{compra.numero_documento}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="compra.tipo_documento.nombre_corto==global_variable.Dictionary.TIPO_DOCUMENTO_FACTURA">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Autorización  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{compra.autorizacion}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="compra.tipo_documento.nombre_corto==global_variable.Dictionary.TIPO_DOCUMENTO_FACTURA">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Código de Control : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{compra.codigo_control}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Fecha : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.fecha | date:"dd/MM/yyyy"}}
                    </label>    
                </div>
            </div>

            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> NIT : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.proveedor.nit}}
                    </label>    
                </div>
            </div>

            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> RAZÓN SOCIAL : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.proveedor.razon_social}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Sucursal : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.sucursal.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_inventario">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Almacen : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.almacen.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Documentos : 
                    </label>    
                </div>
                <div class="col-5">
                    <div class="row">
                    <ng-container *ngFor="let archivo of compra.documentos">
                        <div class="col">
                            <a title="{{archivo}}" class="btn btn-md" [ngClass]="{'btn-purple':(archivo.includes('.zip') || archivo.includes('.rar')),'btn-danger':archivo.includes('.pdf'),'btn-success':(archivo.includes('.xls') || archivo.includes('.xlsx')),'btn-primary':(archivo.includes('.doc') || archivo.includes('.docx') || archivo.includes('.jpg') || archivo.includes('.png') || archivo.includes('.gif') || archivo.includes('.jpeg') || archivo.includes('.mp3') || archivo.includes('.mpeg') || archivo.includes('.mp4'))}" target="_blank" href="{{rest_server+archivo}}">
                                <i class="fas" [ngClass]="{'fa-file-archive':(archivo.includes('.zip') || archivo.includes('.rar')),'fa-file-pdf':archivo.includes('.pdf'),'fa-file-excel':(archivo.includes('.xls') || archivo.includes('.xlsx')),'fa-file-word':(archivo.includes('.doc') || archivo.includes('.docx')),'fa-file-image':(archivo.includes('.jpg') || archivo.includes('.png') || archivo.includes('.gif') || archivo.includes('.jpeg')),'fa-file-audio':(archivo.includes('.mp3') || archivo.includes('.mpeg')),'fa-file-video':(archivo.includes('.mp4'))}"></i>
                                </a>&nbsp;
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(compra)">
                                    <i class="fa fa-trash-alt"></i>
                                </a>
                        </div>
                    </ng-container> 
                        <div class="col p-0">
                            <form id="form-documentos-compra" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                <div class="fallback d-none">
                                    <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                </div>
                
                                <div class="dz-default dz-message" style="margin: 0px !important;">
                                    <span class="text-150  text-grey-d2">
                                    <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                    </span>
                                </div>
                            </form>
                
                                <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                            <div id="template-documentos-compra" class="d-none">
                                <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                    <div class="dz-image">
                                    <img alt="Archivo" data-dz-thumbnail="" />
                                    </div>
                
                                    <div class="dz-details">
                                    <div class="dz-size">
                                        <span data-dz-size=""></span>
                                    </div>
                
                                    <div class="dz-filename">
                                        <span data-dz-name=""></span>
                                    </div>
                                    </div>
                
                                    <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                    <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                    </div>
                
                                    <div class="dz-error-message">
                                    <span data-dz-errormessage=""></span>
                                    </div>
                
                                    <div class="dz-success-mark">
                                    <span class="fa-stack fa-lg text-150">
                                        <i class="fa fa-circle fa-stack-2x text-white"></i>
                                        <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                    </span>
                                    </div>
                
                                    <div class="dz-error-mark">
                                    <span class="fa-stack fa-lg text-150">
                                        <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                        <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <a *ngIf="compra.subidas.length>0" class="btn radius-1 btn-md btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="guardarDocumentos()">
                                <i class="fa fa-save text-110"></i>
                            </a> 
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo de pago : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.tipoPago.nombre}}
                    </label>    
                </div>
            </div>

            <div class="row" *ngIf="compra.saldo">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.total}}
                    </label>    
                </div>
            </div>

            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Sujeto a Iva : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.sujeto_a_iva}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="compra.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Días : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="compra.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A Cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.a_cuenta}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="compra.tipoPago.nombre=='CREDITO'">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.saldo}}
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header" *ngIf="compra.detallesCompra.length>0">
        <h5 class="text-blue-d2 mb-3">Detalle de compra</h5>
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>
                        <label>NOMBRE</label>
                    </th>
                    <th>
                        <label>CÓDIGO ÍTEM</label>
                    </th>
                    <th>
                        <label>UNIDAD DE MEDIDA</label>
                    </th>
                    <th>
                        <label>COSTO UNITARIO (Bs/u)</label>
                    </th>
                    <th>
                        <label>CANTIDAD (u)</label>
                    </th>
                    <th *ngIf="usuario.empresa.usar_vencimientos">
                        <label>VENC</label>
                    </th>
                    <th *ngIf="usuario.empresa.usar_vencimientos">
                        <label>LOTE</label>
                    </th>
                    <th>
                        <label>IMPORTE (Bs)</label>
                    </th>
                    <th *ngIf="!compra.descuento_general">
                        <label>DESCUENTOS (-)</label>
                    </th>
                    <th>
                        <label>TOTAL (Bs)</label>
                    </th>
                    <th *ngIf="!compra.descuento_general">
                        <label>ICE (-)</label>
                    </th>
                    <!--<th *ngIf="!compra.descuento_general">
                        <label>EXENTOS (-)</label>
                    </th>-->
                    <th>
                        <label>SUJETO A IVA</label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalleCompra of compra.detallesCompra">
                    <td *ngIf="detalleCompra.producto">
                        {{detalleCompra.producto.nombre}}
                    </td>
                    <td *ngIf="!detalleCompra.producto">
                        {{detalleCompra.centroCosto.nombre}} - {{detalleCompra.descripcion}}
                    </td>
                    <td>
                        {{detalleCompra.producto?detalleCompra.producto.codigo:''}}
                    </td>
                    <td>
                        {{detalleCompra.producto?detalleCompra.producto.unidad_medida:''}}
                    </td>
                    <td>
                        {{detalleCompra.costo_unitario}}
                    </td>
                    <td>
                        {{detalleCompra.cantidad}}
                    </td>
                    <td *ngIf="usuario.empresa.usar_vencimientos">
                        {{detalleCompra.fecha_vencimiento | date:"dd/MM/yyyy"}}
                    </td>
                    <td *ngIf="usuario.empresa.usar_vencimientos">
                        {{detalleCompra.lote}}
                    </td>
                    <td>
                        {{detalleCompra.importe}}
                    </td>
                    <td >
                        {{detalleCompra.descuento}}
                    </td>
                    <td>
                        {{detalleCompra.total}}
                    </td>
                    <td>
                        {{detalleCompra.ice}}
                    </td>
                    <td>
                        {{detalleCompra.sujeto_a_iva}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>TOTALES</b>
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td *ngIf="usuario.empresa.usar_vencimientos">
                        
                    </td>
                    <td *ngIf="usuario.empresa.usar_vencimientos">
                        
                    </td>
                    <td>
                        {{compra.importe}}
                    </td>
                    <td >
                        
                    </td>
                    <td >
                        
                    </td>
                    <td >
                        
                    </td>
                    <td>
                        {{compra.total}}
                    </td>
                    <td>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>