import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RrhhRoutingModule } from './rrhh-routing.module';
import { EmpleadosComponent } from './components/empleados/empleados.component';
import { PostulacionesComponent } from './components/postulaciones/postulaciones.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { FormularioPostulacionComponent } from './components/formulario-postulacion/formulario-postulacion.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { OrderModule } from 'ngx-order-pipe';
import { ConfiguracionAplicacionEmpleadosComponent } from './components/configuracion-aplicacion-empleados/configuracion-aplicacion-empleados.component';
import { RegistroAsistenciaComponent } from './components/registro-asistencia/registro-asistencia.component';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [EmpleadosComponent, PostulacionesComponent, FormularioPostulacionComponent, ConfiguracionAplicacionEmpleadosComponent, RegistroAsistenciaComponent],
  imports: [
    CommonModule,
    RrhhRoutingModule,
    BaseModule,
    FormsModule,
    RecaptchaModule,
    NgbModule,
    NgxBootstrapMultiselectModule,
    OrderModule,
    FilterPipeModule
  ]
})
export class RrhhModule { }
