import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { Util } from 'src/app/utils/utils';
import { VecinosService } from 'src/app/neighborhood/services/vecinos/vecinos.service';

declare const aplicarWizard:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-registro-producto',
  templateUrl: './registro-producto.component.html',
  styleUrls: ['./registro-producto.component.css']
})
export class RegistroProductoComponent extends BaseComponent implements OnInit {

  producto:any={tipoProducto:{}};
  productoBaseSeleccion:any={};
  usuario:any;
  vecino:any={};
  tamano_imagen_maximo:number=1;
  tipo_grupo:any={clases:[]};
  tipo_subgrupo:any={clases:[]};
  producto_precio:any={};
  tipo_producto_precio:any={clases:[]};
  tipos_productos:any[];
  producto_base_seleccion:any;
  fuentes_despacho:any[];
  searchFailed:any;
  searching:any;
  producto_busqueda:any;
  productoBase:any={};
  sucursales:any[];
  sin_unidades_medidas:any[]=[];
  sin_productos_servicios:any[]=[];
  vecino_busqueda:any;

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  wizard_edicion_producto:string="wizard_edicion_producto";
  @BlockUI() blockUI: NgBlockUI;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private productosService:ProductosService,
    private toastr: ToastrService,
	public usuarioService:UsuarioService,
	public empresaService:EmpresaService,
	public vecinoService:VecinosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
	await this.obtenerGruposProductosEmpresa();
	await this.obtenerSubGruposProductosEmpresa();
	await this.obtenerConceptosPreciosProductos();
	await this.obtenerTipoProducto();
	await this.obtenerFuentesDespacho();
	await this.obtenerSinUnidadesMedida();
	await this.obtenerListaProductosSin();
	if(this.producto.id){
		await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PRODUCTO);
	}else{
		let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PRODUCTO);
		this.producto={
			unidad_medida:"UNIDAD",
			unidad_medida_receta:"UNIDAD",
			equivalencia:1,
			codigo:codigo_nuevo,
			productosBase:[],id_empresa:this.usuario.id_empresa,id_usuario:this.usuario.id,
			imagen:'./img/icon-producto-default.png',
			inventario_minimo:0,comision:0,alerta:0,descuento:0,
			precios:[],
			publicar_panel:false,
			activar_inventario:this.usuario.empresa.usar_inventario,
			activar_vencimiento:false,
			comunitario:false,
			conceptos_pago:[],
			tipoProducto:{},
			publicar_tienda:false,
			activar_precio:true,
			producible:false,
			costo_unitario:1
		}

		let precio:any={por_defecto:true,precio_unitario:1 }
		let precio_general=this.tipo_producto_precio.clases.filter(e => e.nombre_corto==GlobalVariable.Dictionary.PRECIO_GENERAL_PRODUCTO);
		if(precio_general.length>0){
			precio.concepto=precio_general[0];
			this.producto.precios.push(precio);
		}else{
			if(this.tipo_producto_precio.clases.length>0){
				precio.concepto=this.tipo_producto_precio.clases[0];
				this.producto.precios.push(precio);
			}
		}

		this.cambiarPrecio(this.producto.precios[0]);
		//if(!this.usuario.empresa.usar_consumos){
			this.producto.tipoProducto=this.tipos_productos.filter(e => e.nombre_corto == GlobalVariable.Dictionary.TIPO_PRODUCTO_BASE)[0];
		//}
		this.producto.grupo=this.tipo_grupo.clases[0];
		this.producto.subgrupo=this.tipo_subgrupo.clases[0];
		this.producto_base_seleccion={};

		if(this.usuario.empresa.usar_precios_productos_grupos){
			this.producto.precio_unitario=parseFloat(this.tipo_grupo.clases[0].nombre_corto);
		}
	}
  }

  ngAfterViewInit() {
    aplicarWizard(this.wizard_edicion_producto,this,'guardar');
	aplicarSubidaArchivo('dropzone','preview-template',this,'subirImagen','eliminarImagen',{maximo_tamano_archivo:this.tamano_imagen_maximo,acceptedFiles:"image/*",maxFiles:1});
	this.obtenerTextosEmpresa();
  }

  async obtenerSinUnidadesMedida(){
	if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
		let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_UNIDADES_MEDIDA).toPromise();
		this.sin_unidades_medidas=entidad.clases;
	}
  }

  	async obtenerListaProductosSin(){
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			let productos_servicios_sin:any=await this.productosService.obtenerProductosSin(this.usuario.id_empresa).toPromise();
			this.sin_productos_servicios=productos_servicios_sin;
		}
	}

  	async obtenerFuentesDespacho(){
		let entidad:any=await this.generalService.obtenerClases("RESTTIDES").toPromise();
		this.fuentes_despacho=entidad.clases;
	}

  cambiarPrecio(producto_precio){
	if(producto_precio.por_defecto){
		this.producto.precio_unitario=producto_precio.precio_unitario;
	}
   }

   establecerUnidadMedidaSin(){
		this.producto.unidad_medida=this.producto.sin_unidad_medida.nombre;//this.producto.unidad_medida?this.producto.unidad_medida:this.producto.sin_unidad_medida.nombre;
		this.producto.unidad_medida_receta=this.producto.sin_unidad_medida.nombre;
   }

	subirImagen(file){
		let me=this;
		var fileToLoad = file;
		var fileReader = new FileReader();

		fileReader.onloadend = function() 
		{
			let base64EncodedImage = fileReader.result; // <--- data: base64
			if((file.size/1024/1024)<=me.tamano_imagen_maximo){
				me.producto.imagen=base64EncodedImage;
			}
		}
		fileReader.readAsDataURL(fileToLoad);

	}

  eliminarImagen(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.producto.imagen=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }
  
  establecerPrecioGeneral(precio_unitario){
		this.producto.precios[0].precio_unitario=precio_unitario;
  }
  
  cerrarRegistroProducto(){
    this.onConfirm.emit({confirm:false});
  }

  validarFormularioRegistroProducto(){
		let res=true;
		if(!this.producto.codigo){
			this.toastr.error("Debe ingresar un codigo de producto!");
			res=false;
		}
		if(!this.producto.nombre){
			this.toastr.error("Debe ingresar el nombre del producto!");
			res=false;
		}
		if(!this.producto.precio_unitario || this.producto.precio_unitario<=0){
			this.toastr.error("El precio unitario debe ser superior a 0!");
			res=false;
		}
		if(this.usuario.empresa.usar_ventas && !this.producto.unidad_medida){
			this.toastr.error("Debe ingresar la unidad de medida!");
			res=false;
		}
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			if(!this.producto.sin_unidad_medida){
				this.toastr.error("Debe ingresar la unidad de medida de impuestos!");
				res=false;
			}
			if(this.usuario.empresa.usar_produccion){
				if(this.producto.tipoProducto.nombre_corto==GlobalVariable.Dictionary.TIPO_PRODUCTO_FINAL){
					if(!this.producto.producto_sin){
						this.toastr.error("Debe ingresar el producto o servicio asociado al S.I.N.!");
						res=false;
					}
				}
			}else{
				if(!this.producto.producto_sin){
					this.toastr.error("Debe ingresar el producto o servicio asociado al S.I.N.!");
					res=false;
				}
			}
			
		}
		if(this.producto.precios.length<=0){
			this.toastr.error("Debe agregar al menos un concepto de precio!");
			res=false;
		}
		if(this.usuario.empresa.usar_consumos){
			if(!this.producto.unidad_medida_receta){
				this.toastr.error("Debe especificar la unidad de medida de la receta!");
				res=false;
			}
			if(!this.producto.equivalencia){
				this.toastr.error("Debe especificar la equivalencia del producto!");
				res=false;
			}
		}
		return res;
	}

	async guardar(){
		if(this.validarFormularioRegistroProducto()){
			this.blockUI.start();
			if(this.producto.id){
				this.productosService.actualizarProducto(this.producto).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Producto registrado satisfactoriamente");
					this.onConfirm.emit({confirm:true});
				});
			}else{
				if(this.usuario.empresa.usar_inventario && this.producto.cantidad){
					let sucursal:any=await this.empresaService.obtenerSucursal(Util.obtenerSucursalesUsuario(this.usuario)[0].id).toPromise();
					this.producto.id_almacen=sucursal.almacenes[0].id;
					this.producto.id_sucursal=sucursal.id;
				}
				this.producto.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PRODUCTO):this.producto.codigo;
				this.productosService.guardarProducto(this.producto).subscribe((res:any) => {
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
					}else{
						this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PRODUCTO);
						this.toastr.success(res.mensaje);
						this.onConfirm.emit({confirm:true,producto:res});
					}
					this.blockUI.stop();
				});
			}
		}
		
	}

	agregarProductoPrecio(precio_producto){
		if(this.usuario.empresa.usar_precios_productos_sucursal){
			if(!this.producto_precio.concepto || !this.producto_precio.sucursal || !this.producto_precio.precio_unitario){
				this.toastr.error("Debe seleccionar un concepto de precio, la sucursal y especificar el precio unitario");
				return;
			}
		}

		if(this.usuario.empresa.usar_precios_productos){
			if(!this.producto_precio.concepto || !this.producto_precio.precio_unitario){
				this.toastr.error("Debe seleccionar un concepto de precio y especificar el precio unitario");
				return;
			}
		}

		if(!this.usuario.empresa.usar_precios_productos){
			precio_producto.por_defecto=true;
		}
		this.producto.precios.push(precio_producto);
		this.producto_precio={};
	}

	async obtenerConceptosPreciosProductos(){
		let entidad = await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").toPromise();
		this.tipo_producto_precio=entidad;
	}

	async obtenerTipoProducto(){
		this.blockUI.start();
		let entidad:any=await this.generalService.obtenerClases("TPS").toPromise();
		this.tipos_productos=entidad.clases;
		this.blockUI.stop();
	}

	establecerPrecioPorDefecto(producto_precio){
		for(let i=0;i<this.producto.precios.length;i++){
			if(this.usuario.empresa.usar_precios_productos_sucursal){
				if(!this.producto.precios[i].eliminado && producto_precio.sucursal && producto_precio.sucursal.id==this.producto.precios[i].sucursal.id){
					this.producto.precios[i].por_defecto=false;
				}
			}else{
				this.producto.precios[i].por_defecto=false;
			}
		}
		producto_precio.por_defecto=true;
		this.producto.precio_unitario=producto_precio.precio_unitario;
	}

	eliminarDetalleProductosPrecio(producto_precio){
		producto_precio.eliminado=true;		
	}

	async obtenerGruposProductosEmpresa(){
		this.tipo_grupo=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").toPromise();
	}

	async alActualizarGrupos(){
		await this.obtenerGruposProductosEmpresa();
		if(this.usuario.empresa.usar_precios_productos_grupos){
			for(let i=0;i<this.tipo_grupo.clases.length;i++){
				await this.productosService.actualizarPreciosProductosGrupos({
					precio_unitario:parseFloat(this.tipo_grupo.clases[i].nombre_corto),
					id_grupo:this.tipo_grupo.clases[i].id,
					id_empresa:this.usuario.id_empresa
				}).toPromise();
			}
		}
	}

	async obtenerSubGruposProductosEmpresa(){
		this.tipo_subgrupo=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").toPromise();
	}

	establecerPrecioUnitario(){
		this.establecerPrecioGeneral(this.producto.precio_unitario);
		this.producto.porcentaje_utilidad=Math.round((((this.producto.precio_unitario*100)/this.producto.costo_unitario)-100)*100)/100;
	}

	calcularPrecioUnitario(){
		this.producto.precio_unitario=this.producto.costo_unitario+(Math.round(((this.producto.costo_unitario*this.producto.porcentaje_utilidad)/100)*100)/100);
		this.establecerPrecioGeneral(this.producto.precio_unitario);
	}

	buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProductoBase($event){
	let producto=$event.item;
	this.productoBaseSeleccion.producto=producto;
	this.productoBase ={
		id:producto.id,
		nombre:producto.nombre,
		formulacion:1,
		unidad_medida:producto.unidad_medida,
		unidad_medida_receta:producto.unidad_medida_receta
	};
  }

  	agregarDetalleProductosBase(producto,datos){
		  this.producto_busqueda=null;
		datos.formulacion=producto.formulacion;
		this.producto.productosBase.push({productoBase:datos,formulacion:producto.formulacion});			
		this.productoBase={};
		this.productoBaseSeleccion={};
		this.producto.totalBase=0;
		for (var i = 0; i < this.producto.productosBase.length; i++) {
			this.producto.totalBase =this.producto.totalBase+(this.producto.productosBase[i].productoBase.precio_unitario*this.producto.productosBase[i].formulacion);
		}
	}

	eliminarDetalleProductosBase(producto){
		producto.eliminado=true;		
		this.producto.totalBase=this.producto.totalBase-producto.productoBase.precio_unitario*producto.formulacion;
	}

	establecerGrupo(){
		if(this.usuario.empresa.usar_precios_productos_grupos){
			this.producto.precio_unitario=parseFloat(this.producto.grupo.nombre_corto);
			this.establecerPrecioUnitario();
		}
	}

	generarCodigoFabrica(){
		this.producto.codigo_fabrica=(Date.now()).toString();
	}

	buscarVecino = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.vecinoService.buscarVecino(this.usuario.id_empresa,this.producto.subgrupo.id,term) ),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

	establecerVecino(event){
		this.vecino=event.item;
	}

	agregarVecino(vecino){
		let accion_agua=vecino.acciones_agua[0];
		vecino.acciones_agua=null;
		accion_agua.vecino=vecino;
		this.producto.conceptos_pago.push({accion_agua:accion_agua,activo:true});
		this.reiniciarVecino();
	}

	reiniciarVecino(){
		this.vecino_busqueda=null;
		this.vecino={acciones_agua:[{categoria:{}}]}
	}

	formatter = (x: {cliente: {persona:any}}) => (x.cliente.persona.apellido_paterno+" "+x.cliente.persona.apellido_materno+" "+x.cliente.persona.nombres);

	eliminarVecino(concepto_pago){
		concepto_pago.activo=false;
	}


}
