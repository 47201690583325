import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';

@Component({
  selector: 'app-consulta-kardex-economico-estudiante',
  templateUrl: './consulta-kardex-economico-estudiante.component.html',
  styleUrls: ['./consulta-kardex-economico-estudiante.component.css']
})
export class ConsultaKardexEconomicoEstudianteComponent extends BaseComponent implements OnInit {

  codigo_estudiante:any;
  estudiante:any;
  inscripcion_tab:any={};
  conceptos_pago:any[]=[];
  codigo_empresa:any;

  constructor(private _Activatedroute:ActivatedRoute,
    private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private conceptosIngresosService:ConceptosIngresosService,
    private estudiantesService:EstudiantesService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.codigo_estudiante=this._Activatedroute.snapshot.paramMap.get("codigo_estudiante");
    this.codigo_empresa=this._Activatedroute.snapshot.paramMap.get("codigo_empresa");
    this.obtenerEstudiante();
  }

  obtenerEstudiante(){
    this.estudiantesService.consultarFichaEconomicaEstudiante(this.codigo_empresa,this.codigo_estudiante).subscribe((res:any)=>{
      this.estudiante=res;
      if(this.estudiante.inscripciones_colegio.length>0){
        this.establecerInscripcionTab(this.estudiante.inscripciones_colegio[this.estudiante.inscripciones_colegio.length-1]);
      }
    });
  }

  establecerInscripcionTab(inscripcion){
    this.inscripcion_tab=inscripcion;
    this.buscarConceptosPago();
  }

  buscarConceptosPago(){
    this.blockUI.start();
    let promesa;
    promesa=this.conceptosIngresosService.buscarConceptosPagoEstudianteColegio({id_gestion:this.inscripcion_tab.gestion.id,id_estudiante:this.estudiante.id,id_escuela:this.estudiante.id_escuela});
    promesa.subscribe((conceptos_pagos_estudiante:any[])=>{
      this.conceptos_pago=conceptos_pagos_estudiante;
      for(let i=0;i<this.conceptos_pago.length;i++){
          let descripcion:any=this.conceptos_pago[i].descripcion;
          if(descripcion){
            for(let j=0;j<this.conceptos_pago[i].conceptos_pagos_estudiante.length;j++){
              this.conceptos_pago[i].conceptos_pagos_estudiante[j].descripcion=descripcion.clases.filter(desc => desc.nombre_corto==this.conceptos_pago[i].conceptos_pagos_estudiante[j].cuota.toString())[0].nombre;
            }
          }
      }
      this.blockUI.stop();
    });
  }

}
