import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { InventariosService } from '../../services/inventarios/inventarios.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { ProveedoresService } from '../../services/proveedores/proveedores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import {Md5} from 'ts-md5/dist/md5';
import { RegistroProductoComponent } from '../registro-producto/registro-producto.component';
import { RegistroProveedorComponent } from '../registro-proveedor/registro-proveedor.component';
import { CajasService } from '../../services/cajas/cajas.service';

declare const aplicarVisorImagenes:any;
declare const $:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css']
})
export class CompraComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  rest_server:any=GlobalVariable.SERVER_URL;
  modalProveedor:NgbModalRef;

  compra:any={
    id:null,
			usuario:null,
			proveedor:{},
			detallesCompra:[],
      fecha_texto:null,
			tipo_descuento:false,
			codigo_control:0,
			autorizacion:0,
			tipo_recargo:false,
			descuento:0,
			recargo:0,
			ice:0,
			excento:0,
			factura:0,
			sucursal:null,
			a_cuenta:0,
			saldo:0,
			total:0,
			almacen:null,
			importe:0,
      tipoPago:{},
      tipo_documento:{},
      documentos:[]
  }

  es_almacen:boolean=false;
  tiposPago:any[]=[];
  conceptos_egreso_filtro:any[]=[];
  tipoFiltro:any={clases:[]};
  configuracionCompraVista:any={};
  sucursales:any[]=[];
  almacenes:any[]=[];
  detalleCompra:any={centroCosto:{}};
  tiposDocumentos:any[]=[];
  esContado:boolean=true;
  productos:any[];
  descuento_general:boolean=false;
  busqueda_codigo_barra:boolean=false;
  producto_busqueda:any;
  razon_busqueda:any;
  tipoEgreso:any;
  proveedores_nit:any[]=[];
  aplicacion_producto:any;

  nombre_corto_tipo_pago:string=GlobalVariable.Dictionary.TIPO_PAGO_CONT;
  nombre_corto_tipo_documento:string=GlobalVariable.Dictionary.TIPO_DOCUMENTO_RECIBO;
  estados:any[]=[];
  cajas:any[]=[];
  cheques:any[]=[];

  inhabilitar_proveedor:boolean=false;
  inhabilitar_sucursal:boolean=false;
  inhabilitar_busqueda_item:boolean=false;
  inhabilitar_almacen:boolean=false;

  searching = false;
  searchFailed = false;  

  edicionproducto:NgbModalRef;
  aplicacion_movimientos_caja:any;

  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,
    public comprasService:ComprasService,
    public productosService:ProductosService,
    public inventariosService:InventariosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public socket : Socket,
    public proveedoresService:ProveedoresService,
    public usuarioService:UsuarioService,
    public cajasService:CajasService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.aplicacion_movimientos_caja= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_CAJA);
    this.aplicacion_movimientos_caja=this.aplicacion_movimientos_caja.length>0?this.aplicacion_movimientos_caja[0]:{puede_crear:false};
    this.aplicacion_producto= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS);
    this.aplicacion_producto=this.aplicacion_producto.length>0?this.aplicacion_producto[0]:{puede_crear:false,puede_modificar:false};
    this.compra.fecha=this.compra.fecha?new Date(this.compra.fecha):new Date();
    this.compra.fecha_texto={
			year: this.compra.fecha.getFullYear(), 
			month: (this.compra.fecha.getMonth()+1),
			day:this.compra.fecha.getDate()
		}

    this.obtenerConceptosEgreso();
    this.obtenerTiposPago(this.nombre_corto_tipo_pago);
    this.obtenerTiposDocumentos(this.nombre_corto_tipo_documento);
    this.obtenerConfiguracionCompraVista();
    this.establecerSucursal(this.sucursales.length>0?this.sucursales[0]:null);
    this.iniciarDetalleCompra();
    if(this.compra.proveedor && this.compra.proveedor.id){
      this.establecerProveedor(this.compra.proveedor);
    }else{
      this.buscarNit({which:13},"0");
    }

    if(this.usuario.empresa.usar_caja_general){
      this.obtenerCajasEmpresa();
    }

    let compra_class:any=this;
    this.socket.on('establecerPorveedor', function (codigo) {
      compra_class.establecerTipoDocumento(GlobalVariable.Dictionary.TIPO_DOCUMENTO_FACTURA);
      let datos=codigo.split("|");
      compra_class.compra.factura=parseInt(datos[1]);
      compra_class.compra.autorizacion=parseInt(datos[2]);
      compra_class.compra.fecha_texto=Util.convertirTextoAObjectoFecha(datos[3]);
      compra_class.compra.codigo_control=datos[6];
      compra_class.buscarNit({which:13},datos[0])
    });
  }

  ngAfterViewInit(){
    aplicarSubidaArchivo('form-documentos-compra','template-documentos-compra',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    aplicarSubidaArchivo('form-pago-comprobante','template-pago-comprobante',this,'subirArchivoComprobantePago','eliminarArchivoComprobantePago',{maximo_tamano_archivo:2});
  }

  obtenerCajasEmpresa(){
    this.blockUI.start();
    this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).subscribe((cajas:any)=>{
      this.cajas=cajas;
      this.blockUI.stop();
    });
  }

  iniciarDetalleCompra(){
    let fechaActual:any=new Date();
    this.producto_busqueda="";
    this.detalleCompra={
      centroCosto:(this.es_almacen?this.conceptos_egreso_filtro[0]:{}),
      producto:{},
      cantidad:1,
      descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
      fechaVencimientoTexto:{
        year: fechaActual.getFullYear(), 
        month: (fechaActual.getMonth()+1),
        day:fechaActual.getDate()
      }
    }
    if(this.es_almacen){
      Util.enfocarElemento('compra_id_producto');
    }else{
      Util.enfocarElemento('compra_concepto_egreso');
    }
  }

  obtenerConceptosEgreso(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CONCEPTO_EGRESOS).subscribe((entidad:any)=>{
      this.tipoEgreso=entidad;
      this.tipoFiltro=JSON.parse(JSON.stringify(entidad));
      if(this.es_almacen){
        this.conceptos_egreso_filtro=this.tipoEgreso.clases.filter(e => e.nombre_corto == "CEALM");
      }else{
        this.conceptos_egreso_filtro=this.tipoEgreso.clases.filter(e => e.nombre_corto != "CEALM");
      }
      this.tipoFiltro.clases=this.conceptos_egreso_filtro;
      this.blockUI.stop();
    });
  }

  obtenerTiposPago(tipo_pago){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any)=>{
      this.tiposPago=entidad.clases;
      this.establecerTipoPago(tipo_pago);
      this.blockUI.stop();
    });
  }

  establecerTipoPago(nombre_corto_tipo_pago){
    let tiposP=this.tiposPago;
    this.compra.tipoPago=tiposP.filter(e => e.nombre_corto == nombre_corto_tipo_pago)[0];
  }

  obtenerTiposDocumentos(nombre_corto_tipo_documento){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPDOC").subscribe((entidad:any)=>{
      this.tiposDocumentos=entidad.clases;
      this.establecerTipoDocumento(nombre_corto_tipo_documento);
      this.blockUI.stop();
    });
  }

  establecerTipoDocumento(nombre_corto_tipo_documento){
    let tiposDoc=this.tiposDocumentos;
    this.compra.tipo_documento=tiposDoc.filter(e => e.nombre_corto == nombre_corto_tipo_documento)[0];
  }

  obtenerConfiguracionCompraVista(){
    this.blockUI.start();
    this.comprasService.obtenerConfiguracionVista(this.usuario.id_empresa).subscribe((configuracion:any) => {
      this.configuracionCompraVista=configuracion;
      this.blockUI.stop();
    });
  }

  establecerSucursal(sucursal){
    this.compra.sucursal=sucursal;
    if(this.compra.sucursal){
      this.obtenerAlmacenes();
    }
  }

  obtenerAlmacenes(){
    this.almacenes=this.compra.sucursal.almacenes;
    this.compra.almacen=this.almacenes.length>0?this.almacenes[0]:null;
    this.iniciarDetalleCompra();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  verificarDuplicidadDocumento(evento,codigo_control){
    if (evento.which === 13){
      this.comprasService.verificarDuplicidadCompra(codigo_control).subscribe((resultado:any)=>{
        if(resultado.existe){
          this.toastr.error(resultado.mensaje);
        }
      });
    }
  }

  formatearCodigoControl(codigo){
    codigo=codigo?codigo.split("-").join(""):"";console.log(codigo);
    var codigoFormateado="";
    var i;
    for(i=2;i<codigo.length;i=i+2){
      codigoFormateado=codigoFormateado+codigo.substring(i-2,i)+"-"
    }
    if(codigo.length<=2){
      this.compra.codigo_control=codigo;
    }else{
      codigoFormateado=codigoFormateado+codigo.substring(i-2,codigo.length);
      this.compra.codigo_control=codigoFormateado;
    }
  }

  buscarNit(evento,nit){
    this.proveedores_nit=[];
    if (evento.which === 13){
      this.proveedoresService.buscarProveedoresNit(this.usuario.id_empresa,nit).subscribe((results:any[])=>{
        if(results.length==1){
          this.establecerProveedor(results[0]);
        }else if(results.length>1){
          this.establecerProveedor(results[0]);
          this.proveedores_nit=results;
        }else{
          this.establecerProveedor({nit:nit});
        }
      });
    }
  }

  buscarProveedor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.proveedoresService.buscarProveedoresNit(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  establecerProveedor(event){
    let proveedor=(event.id || event.nit)?event:event.item;
    this.razon_busqueda=proveedor;
    this.compra.proveedor=proveedor;
    this.compra.razon_social=proveedor.razon_social;
    this.compra.nit_ci=proveedor.nit;
    Util.enfocarElemento('compra_razon_social');
  }

  reiniciarProveedor(){
    this.compra.proveedor={};
    this.razon_busqueda=null;
    this.proveedores_nit=[];
    //$("#venta_razon_social").val("");
    Util.enfocarElemento('compra_razon_social');
  }

  enfocarElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      Util.enfocarElemento(id_elemento);
    }
  }

  formatter = (x: any) => (x.id?(x.razon_social?x.razon_social:""):"");

  cambiarTipoPago(){
    this.esContado=this.compra.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT?true:false;
    if(!this.esContado){
      this.compra.dias_credito=1;
      this.calcularSaldo();
    }
  }

  calcularSaldo(){
    this.compra.saldo=this.compra.total-this.compra.a_cuenta;
  }

  activarDesactivarBusquedaCodigoBarra(){
    this.busqueda_codigo_barra=!this.busqueda_codigo_barra;
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  async establecerProducto($event){
    let producto=$event.item
    if(this.usuario.empresa.usar_productos_sucursal){
      let producto_sucursal:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,producto.id,this.compra.sucursal.id).toPromise();
      producto.activar_inventario=producto_sucursal.productos_sucursal[0].activar_inventario;
      producto.activar_vencimiento=producto_sucursal.productos_sucursal[0].activar_vencimiento;
      producto.producto_sucursal=producto_sucursal;
    }
    if(!producto.activar_inventario){
      this.toastr.warning("El producto tiene el inventario desactivado!");
    }
    producto.tipoProducto=producto['tipoProducto']==null?{id:producto['tipoProducto.id'],nombre:producto['tipoProducto.nombre'],nombre_corto:producto['tipoProducto.nombre_corto']}:producto.tipoProducto;
    this.detalleCompra.producto=producto;
    this.detalleCompra.precio_unitario=producto.precio_unitario;
    this.detalleCompra.activar_inventario=producto.activar_inventario;
    this.detalleCompra.cantidad=1;
    this.detalleCompra.descuento=producto.descuento;
    this.detalleCompra.costo_unitario=(producto.costo_unitario==null || producto.costo_unitario==undefined)?1:producto.costo_unitario,
    this.detalleCompra.recargo=0;
    this.detalleCompra.ice=0;
    this.detalleCompra.excento=0;
    this.detalleCompra.tipo_descuento=(producto.descuento>0?true:false);
    this.detalleCompra.tipo_recargo=false;
    this.detalleCompra.centroCosto=this.es_almacen?this.conceptos_egreso_filtro[0]:this.detalleCompra.centroCosto;							
    if(!this.usuario.empresa.usar_vencimientos || !producto.activar_vencimiento){
      this.detalleCompra.fechaVencimientoTexto=null;
    }
    this.calcularImporte();
    Util.enfocarElemento('compra_costo_unitario');
    setTimeout(()=>{
      aplicarVisorImagenes("imagen-producto-vista");
    }, 200);
  }

  actualizarAtributoProducto(producto,atributo,valor){
		this.blockUI.start();
    this.detalleCompra.activar_inventario=valor;
		if(this.usuario.empresa.usar_productos_sucursal){
			let producto_sucursal:any=producto.producto_sucursal;
			producto_sucursal.productos_sucursal[0][atributo]=valor;
			this.productosService.actualizarProductoSucursal(producto_sucursal).subscribe((res:any)=>{
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}else{
			let datos:any={
				atributo:atributo,
				valor:valor
			};
			if(producto){
				datos.producto=producto;
			}else{
				datos.id_empresa=this.usuario.id_empresa;
			}
			this.productosService.actualizarAtributoProducto(datos).subscribe((res:any) => {
				this.getItems();
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}
	}

  calcularImporte(){
    this.detalleCompra.importe=Math.round((this.detalleCompra.cantidad*this.detalleCompra.costo_unitario)*1000)/1000;
    var descuento,recargo;
    if(this.detalleCompra.tipo_descuento){
      descuento=this.detalleCompra.importe*(this.detalleCompra.descuento/100);
    }else{
      descuento=this.detalleCompra.descuento;
    }
    if(this.detalleCompra.tipo_recargo){
      recargo=this.detalleCompra.importe*(this.detalleCompra.recargo/100);
    }else{
      recargo=this.detalleCompra.recargo;
    }
    this.detalleCompra.total=this.detalleCompra.importe-descuento+recargo;
    this.detalleCompra.sujeto_a_iva=this.detalleCompra.total-this.detalleCompra.ice;
  }

  buscarCodigoBarraProducto(evento:any,codigo_barra:any){
    let compra=this;
    if (evento.which === 13){
      this.productosService.buscarProducto(this.usuario.id_empresa,codigo_barra).subscribe((results:any[]) => {
        if(results.length>=1){
          compra.establecerProducto({item:results[0]});
          Util.enfocarElemento('compra_costo_unitario');
        }
      });
    }
  }

  guardarCompraBorrador(){
    const md5 = new Md5();
    this.compra.fecha=Util.convertirObjetoAfecha(this.compra.fecha_texto);
    let compras_borrador=this.persistenciaService.get("compras")?this.persistenciaService.get("compras"):[];
    if(this.compra._id){
      compras_borrador=compras_borrador.filter(e => e._id != this.compra._id);
      compras_borrador.push(this.compra);
    }else{
      this.compra._id=md5.appendStr(new Date().toUTCString()).end();
      compras_borrador.push(this.compra);
    }
    this.persistenciaService.set("compras",compras_borrador);
    this.toastr.info("Compra guardada como borrador!");
    this.alTerminar.emit();
  }

  guardarCompra(){
    this.blockUI.start();
    this.compra.proveedor.razon_social=(!this.compra.proveedor.id)?this.razon_busqueda:this.compra.proveedor.razon_social;
    if(this.validarCompra()){
      if(this.compra._id){
        let compras_borrador=this.persistenciaService.get("compras")?this.persistenciaService.get("compras"):[];
        compras_borrador=compras_borrador.filter(e => e._id != this.compra._id);
        this.persistenciaService.set("compras",compras_borrador);
      }
      let tiempoActual=new Date();
      this.compra.fecha=Util.convertirObjetoAfecha(this.compra.fecha_texto);
      this.compra.fecha.setHours(tiempoActual.getHours());
      this.compra.fecha.setMinutes(tiempoActual.getMinutes());
      this.comprasService.guardarCompra(this.compra).subscribe((res:any) => {
        this.blockUI.stop();
        this.alTerminar.emit(res);
      });
    }else{
      this.blockUI.stop();
    }
  }

  agregarDetalleCompra(){
    if(this.detalleCompra.fechaVencimientoTexto){
      this.detalleCompra.fecha_vencimiento=Util.convertirObjetoAfecha(this.detalleCompra.fechaVencimientoTexto);
    }
    if(this.es_almacen && this.detalleCompra.producto.id){
      //this.detalleCompra.producto=this.detalleCompra.producto.nombre;

      var detalleCompraAAgregar=this.detalleCompra;

      var detalleCompraProductoExistente=this.compra.detallesCompra.filter(e => e.producto && (e.producto.id == detalleCompraAAgregar.producto.id));
      if(detalleCompraProductoExistente.length>0){
        detalleCompraProductoExistente=detalleCompraProductoExistente[0];
        detalleCompraProductoExistente.cantidad=detalleCompraProductoExistente.cantidad+detalleCompraAAgregar.cantidad;
        this.calcularImporteDetalleEdicion(detalleCompraProductoExistente);
        this.compra.detallesCompra[this.compra.detallesCompra.indexOf(detalleCompraProductoExistente)]=detalleCompraProductoExistente;
      }else{
        this.compra.detallesCompra.push(this.detalleCompra);
      }
    }else{
      this.compra.detallesCompra.push(this.detalleCompra);
    }
    this.sumarTotal();
    this.sumarTotalImporte();
    this.sumarIce();
    this.sumarSujetoAiva();
    if(this.descuento_general){
      this.calcularImporteGeneral();
    }else{
      this.compra.descuento=this.compra.descuento+this.detalleCompra.descuento;
    }
    if(this.compra.tipoPago.nombre==GlobalVariable.Dictionary.TIPO_PAGO_CREDITO){
      this.calcularSaldo();
    }
    this.iniciarDetalleCompra();
  }

  calcularImporteDetalleEdicion(detalleCompra){
    detalleCompra.importe=Math.round((detalleCompra.cantidad*detalleCompra.costo_unitario)*100)/100;
    var descuento,recargo;
    if(detalleCompra.tipo_descuento){
      descuento=detalleCompra.importe*(detalleCompra.descuento/100);
    }else{
      descuento=detalleCompra.descuento;
    }
    if(detalleCompra.tipo_recargo){
      recargo=detalleCompra.importe*(detalleCompra.recargo/100);
    }else{
      recargo=detalleCompra.recargo;
    }
    detalleCompra.total=detalleCompra.importe-descuento+recargo-detalleCompra.ice-detalleCompra.excento;
    this.sumarTotal();
    this.sumarTotalImporte();
    this.sumarIce();
    this.sumarSujetoAiva();
    if(this.descuento_general){
      this.calcularImporteGeneral();
    }
    if(this.compra.tipoPago.nombre==GlobalVariable.Dictionary.TIPO_PAGO_CREDITO){
      this.calcularSaldo();
    }
  }

  sumarTotalImporte(){
    var sumaImporte=0;
    for(var i=0;i<this.compra.detallesCompra.length;i++){
      if(!this.compra.detallesCompra[i].eliminado){
        sumaImporte=sumaImporte+this.compra.detallesCompra[i].importe;
      }
    }		
    this.compra.importe=Math.round((sumaImporte)*100)/100;
  }

  sumarTotal(){
    var sumaTotal=0;
    for(var i=0;i<this.compra.detallesCompra.length;i++){
      if(!this.compra.detallesCompra[i].eliminado){
        sumaTotal=sumaTotal+this.compra.detallesCompra[i].total;
      }
    }		
    this.compra.total=Math.round((sumaTotal)*100)/100;
  }

  sumarIce(){
    var sumaIce=0;
    for(var i=0;i<this.compra.detallesCompra.length;i++){
      if(!this.compra.detallesCompra[i].eliminado){
        sumaIce=sumaIce+this.compra.detallesCompra[i].ice;
      }
    }		
    this.compra.ice=Math.round((sumaIce)*100)/100;
  }

  sumarSujetoAiva(){
    var sumaSujetoAIva=0;
    for(var i=0;i<this.compra.detallesCompra.length;i++){
      if(!this.compra.detallesCompra[i].eliminado){
        sumaSujetoAIva=sumaSujetoAIva+this.compra.detallesCompra[i].sujeto_a_iva;
      }
    }		
    this.compra.sujeto_a_iva=Math.round((sumaSujetoAIva)*100)/100;
  } 

  calcularImporteGeneral(){
    var descuento,recargo;
    if(this.compra.tipo_descuento){
      descuento=this.compra.importe*(this.compra.descuento/100);
    }else{
      descuento=this.compra.descuento;
    }
    if(this.compra.tipo_recargo){
      recargo=this.compra.importe*(this.compra.recargo/100);
    }else{
      recargo=this.compra.recargo;
    }		
    this.compra.total=Math.round((this.compra.importe-descuento+recargo)*100)/100;
    this.compra.sujeto_a_iva=Math.round((this.compra.total-this.compra.ice)*100)/100;
  }

  mostrarDescuentos(){
    var style=$(".des-datos").css("display");
    if(style=="none"){
      $(".des-datos").css("display","");
    }else{
      $(".des-datos").css("display","none");
    }
  }

  guardarConfiguracionCompraVista(){
    this.comprasService.actualizarConfiguracionVista(this.usuario.id_empresa,this.configuracionCompraVista).subscribe((res:any[]) => {
      this.toastr.info("Actualizado satisfactoriamente!");
    });
  }

  eliminarDetalleCompra(detalleCompra){
    if(this.compra.id){
      detalleCompra.eliminado=true;
    }else{
      this.compra.detallesCompra.splice(this.compra.detallesCompra.indexOf(detalleCompra),1);
    }
    this.sumarTotal();
    this.sumarTotalImporte();
    this.sumarIce();
    this.sumarSujetoAiva();
    if(this.descuento_general){
      this.calcularImporteGeneral();
    }
    if(this.compra.tipoPago.nombre==GlobalVariable.Dictionary.TIPO_PAGO_CREDITO){
      this.calcularSaldo();
    }
  }

  validarCompra(){
    let res=true;
    if(!this.compra.proveedor.razon_social || !this.compra.proveedor.nit){
      res=res && false;
      this.toastr.error("Debe especificar el nit/ci y razon social del proveedor!");
    }
    if(!this.compra.fecha_texto || !this.compra.fecha_texto.year || !this.compra.fecha_texto.month || !this.compra.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la compra!");
    }
    if(this.compra.detallesCompra.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item a la compra!");
    }
    if(this.compra.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE && (!this.compra.dias_credito)){
      res=res && false;
      this.toastr.error("Debe especificar los dias de credito de la compra!");
    }
    if(this.compra.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE && (this.compra.a_cuenta==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar el importe a cuenta de la compra!");
    }
    return res;
  }

  abrirPopupRegistroRapidoProveedor(){
    this.modalProveedor = this.modalService.open(RegistroProveedorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalProveedor.componentInstance.proveedor = JSON.parse(JSON.stringify(this.compra.proveedor));
		this.modalProveedor.componentInstance.usuario = this.usuario;
		
		this.modalProveedor.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerProveedor(res.proveedor);
					this.modalProveedor.close();
				}
			}else{
				this.modalProveedor.close();
			}
			
		});
  }

  agregarNuevoProducto(){
    this.edicionproducto = this.modalService.open(RegistroProductoComponent, {windowClass : "wizard-edicion-producto",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicionproducto.componentInstance.producto = {};
		this.edicionproducto.componentInstance.usuario = this.usuario;
		this.edicionproducto.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		
		this.edicionproducto.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.establecerProducto({item:$e.producto});
			}
			this.edicionproducto.close();
    });
  }

  mostrarOcultarDatosAdicionales(){
    if($(".datos-adicionales").css( "display")=="none"){
      $('.datos-adicionales').show( "slow" );
    }else{
      $( ".datos-adicionales" ).hide( 1000 );
    }
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.documentos.push(base64EncodedImage);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.documentos.splice(me.compra.documentos.indexOf(base64EncodedImage),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoComprobantePago(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.comprobante_pago=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoComprobantePago(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.comprobante_pago=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  establecerCaja(){
    if(this.compra.caja.tipo_caja.nombre_corto==GlobalVariable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA && this.compra.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE){
      this.obtenerCheques(this.compra.caja.id);
    }
  }
  
  obtenerCheques(id_caja){
    this.blockUI.start();
    this.cajasService.obtenerListaChequesCaja(id_caja).subscribe((cheques:any)=>{
    this.cheques=cheques;
    this.compra.cheque=null;
    this.blockUI.stop();
    });
  }

  establecerChequeBanco(){
    this.compra.numero_cheque=this.compra.cheque.correlativo_actual;
  }

}
