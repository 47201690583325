import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';

declare const $:any;

@Component({
  selector: 'app-pagos-servicios-basicos',
  templateUrl: './pagos-servicios-basicos.component.html',
  styleUrls: ['./pagos-servicios-basicos.component.css']
})
export class PagosServiciosBasicosComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.verTab("elfec");
  }

  /*verTab(tab_seleccionado){
    $(".contenido_tab").css('display','none');
    $(".link_tab").removeClass('active');
    $("."+tab_seleccionado).addClass('active');
    $("."+tab_seleccionado).css('display','block');
    window.open("https://semapa.cuentas.bo/", 'Nombre de la ventana', 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=300,height=200,left = 390,top = 50');
  }*/

}
