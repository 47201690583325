import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ReservasService } from '../../../hotel/services/reservas/reservas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { RegistroReservaComponent } from '../registro-reserva/registro-reserva.component';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { PagoVentaComponent } from 'src/app/snapquick/components/pago-venta/pago-venta.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.css']
})
export class ReservasComponent extends BaseComponent implements OnInit {

	ambientes:any=[];
	modal_reserva:NgbModalRef;
	reserva:any;
	estados_cambio:any[]=[];

	modal_pago_reserva:NgbModalRef;

	modal_cambio_estado:NgbModalRef;
	@ViewChild('modal_cambio_estado')
  	private modal_cambio_estado_ref: TemplateRef<any>;

	  modal_vista_reserva:NgbModalRef;
	@ViewChild('modal_vista_reserva')
  	private modal_vista_reserva_ref: TemplateRef<any>;

	ambiente:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
	public reservasService:ReservasService,
	public ventasService:VentasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));

    let fecha_actual=new Date();
	let fecha_mes_siguiente=new Date();
	fecha_mes_siguiente.setMonth(fecha_mes_siguiente.getMonth()+1);
		this.filter={
		id_empresa:this.usuario.id_empresa,
		ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
		sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
		sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
		usuarios:[{nombre_usuario:"TODOS",id:0}],
		usuario: {nombre_usuario:"TODOS",id:0},
		estados:[GlobalVariable.Dictionary.SELECCION_TODOS],
		estado: GlobalVariable.Dictionary.SELECCION_TODOS,
		clases:[GlobalVariable.Dictionary.SELECCION_TODOS],
		clase: GlobalVariable.Dictionary.SELECCION_TODOS,
		plantas:[GlobalVariable.Dictionary.SELECCION_TODOS],
		planta: GlobalVariable.Dictionary.SELECCION_TODOS,
		fecha_inicio:{
			year: fecha_actual.getFullYear(), 
			month: (fecha_actual.getMonth()+1),
			day:fecha_actual.getDate()
		},
		fecha_fin:{
			year: fecha_mes_siguiente.getFullYear(), 
			month: (fecha_mes_siguiente.getMonth()+1),
			day:fecha_mes_siguiente.getDate()
		},
		fechas_visualizacion:[]
		}
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_RESERVAS);

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}

		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}

		this.obtenerClases();
		this.obtenerPlantas();
		await this.obtenerEstadosReserva();
		this.buscarReservas();
  }

  ngAfterViewInit() {
    //aplicarScriptPaginaProductos();	
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

	async obtenerClases(){
		let tipo_clase:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").toPromise();
		this.filter.clases=this.filter.clases.concat(tipo_clase.clases);
	}

	async obtenerPlantas(){
		let tipo_planta:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").toPromise();
		this.filter.plantas=this.filter.plantas.concat(tipo_planta.clases);
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  buscarReservas(){
    this.blockUI.start();
    this.filter.fechas_visualizacion=[];
    let fecha_inicio=Util.convertirObjetoAfecha(this.filter.fecha_inicio);
    let fecha_fin=Util.convertirObjetoAfecha(this.filter.fecha_fin);
    this.reservasService.obtenerAmbientesReservas(this).subscribe((ambientes:any)=>{
      this.ambientes=ambientes;
      for(var dt=fecha_inicio; dt<=fecha_fin; dt.setDate(dt.getDate()+1)){
		  let fecha=new Date(dt);
		  fecha.setHours(1);
        this.filter.fechas_visualizacion.push(fecha);
      }
	  this.buscarReservasFechas();
      this.blockUI.stop();
      //document.querySelector(".focus-data").focus();
    });
  }

  buscarReservasFechas(){
	  for(let i=0;i<this.ambientes.length;i++){
		this.ambientes[i].reservas_encontradas=[];
		for(let j=0;j<this.filter.fechas_visualizacion.length;j++){
		  let res=this.buscarReservaFecha(this.ambientes[i].reservas,this.filter.fechas_visualizacion[j]);
		  this.ambientes[i].reservas_encontradas.push(res);
		}
	  }
  }

  buscarReservaFecha(reservas_ambiente,fecha){//aqui identificar la reserva que esta saliendo y la que esta entrando para dibujar en la pantalla
	  let encontrado=false,res:any={color:"success",icono:"fas fa-home",fecha_visualizacion:fecha};
	  let reservas_encontradas=[];
	for(let i=0;i<reservas_ambiente.length;i++){
		if(reservas_ambiente[i].reserva){
			let fecha_ingreso=new Date(reservas_ambiente[i].reserva.fecha_ingreso);
			let fecha_ingreso_inicio=new Date(fecha_ingreso);fecha_ingreso_inicio.setHours(0);fecha_ingreso_inicio.setMinutes(0);
			let fecha_ingreso_fin=new Date(fecha_ingreso);fecha_ingreso_fin.setHours(23);fecha_ingreso_fin.setMinutes(59);
			let fecha_salida=new Date(reservas_ambiente[i].reserva.fecha_salida);
			let fecha_salida_inicio=new Date(fecha_salida);fecha_salida_inicio.setHours(0);fecha_salida_inicio.setMinutes(0);
			let fecha_salida_fin=new Date(fecha_salida);fecha_salida_fin.setHours(23);fecha_salida_fin.setMinutes(59);
			if(fecha>=fecha_ingreso_inicio && fecha<=fecha_ingreso_fin){
				res=this.establecerEstadoReserva(reservas_ambiente[i]);
				res.fecha_ingreso=fecha_ingreso;
				encontrado=true;
				res.reserva=reservas_ambiente[i].reserva;
				reservas_encontradas.push(res);
			}else if(fecha>=fecha_salida_inicio && fecha<=fecha_salida_fin){
				res=this.establecerEstadoReserva(reservas_ambiente[i]);
				res.fecha_salida=fecha_salida;
				encontrado=true;
				res.reserva=reservas_ambiente[i].reserva;
				reservas_encontradas.push(res);
			}else if(fecha>=fecha_ingreso_inicio && fecha<=fecha_salida_fin){
				res=this.establecerEstadoReserva(reservas_ambiente[i]);
				encontrado=true;
				res.reserva=reservas_ambiente[i].reserva;
				reservas_encontradas.push(res);
			}
		}
	}
	if(!encontrado){
		reservas_encontradas.push(res);
	}
	return reservas_encontradas;
  }

  establecerEstadoReserva(reserva_ambiente){
	  let res:any;
	  if(reserva_ambiente.reserva.venta){
		if(reserva_ambiente.reserva.venta.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE){
			if(reserva_ambiente.reserva.venta.saldo==0){
				if(reserva_ambiente.estado.nombre=="OCUPADO"){
					res={color:"danger",icono:"fas fa-bed"};
				}else if(reserva_ambiente.estado.nombre=="RESERVADO"){
					res={color:"danger",icono:"fas fa-dolly-flatbed"};
				}else if(reserva_ambiente.estado.nombre=="DISPONIBLE"){
					res={color:"success",icono:"fas fa-home"}
				}
			}else if(reserva_ambiente.reserva.venta.a_cuenta==0){
				if(reserva_ambiente.estado.nombre=="OCUPADO"){
					res={color:"warning",icono:"fas fa-bed"};
				}else if(reserva_ambiente.estado.nombre=="RESERVADO"){
					res={color:"warning",icono:"fas fa-dolly-flatbed"};
				}else if(reserva_ambiente.estado.nombre=="DISPONIBLE"){
					res={color:"success",icono:"fas fa-home"}
				}
			}else if(reserva_ambiente.reserva.venta.saldo>0){
				if(reserva_ambiente.estado.nombre=="OCUPADO"){
					res={color:"warning",icono:"fas fa-bed"};
				}else if(reserva_ambiente.estado.nombre=="RESERVADO"){
					res={color:"warning",icono:"fas fa-dolly-flatbed"};
				}else if(reserva_ambiente.estado.nombre=="DISPONIBLE"){
					res={color:"success",icono:"fas fa-home"}
				}
			}
		}else{
			if(reserva_ambiente.estado.nombre=="OCUPADO"){
				res={color:"danger",icono:"fas fa-bed"};
			}else if(reserva_ambiente.estado.nombre=="RESERVADO"){
				res={color:"danger",icono:"fas fa-dolly-flatbed"};
			}else if(reserva_ambiente.estado.nombre=="DISPONIBLE"){
				res={color:"success",icono:"fas fa-home"}
			}
		}
	  }else{
		if(reserva_ambiente.estado.nombre=="OCUPADO"){
			res={color:"warning",icono:"fas fa-bed"};
		}else if(reserva_ambiente.estado.nombre=="RESERVADO"){
			res={color:"warning",icono:"fas fa-dolly-flatbed"};
		}else if(reserva_ambiente.estado.nombre=="DISPONIBLE"){
			res={color:"success",icono:"fas fa-home"}
		}
	  }
	  return res;
  }

  async abrirModalRegistroReserva(reserva,fecha_visualizacion,ambiente){
	  let reserva_consultada=null;
	  if(reserva && reserva.id){
		reserva_consultada=await this.reservasService.obtenerReserva(reserva.id).toPromise();
	  }
	this.modal_reserva = this.modalService.open(RegistroReservaComponent, {windowClass:'edicion-pedido',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
	this.modal_reserva.componentInstance.fecha_reserva = fecha_visualizacion;
	this.modal_reserva.componentInstance.reserva = reserva_consultada?reserva_consultada:{};
	this.modal_reserva.componentInstance.ambiente_seleccionado = ambiente;
	this.modal_reserva.componentInstance.usuario = this.usuario;
	this.modal_reserva.componentInstance.sucursales = this.filter.sucursales;
	this.modal_reserva.componentInstance.aplicacion = this.aplicacion;
	this.modal_reserva.componentInstance.alTerminar.subscribe((res) => {
		this.buscarReservas();
		this.modal_reserva.close();
	});
  }

  crearNuevaReserva(){
	this.modal_reserva = this.modalService.open(RegistroReservaComponent, {windowClass:'edicion-pedido',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
	this.modal_reserva.componentInstance.fecha_reserva = new Date();
	this.modal_reserva.componentInstance.reserva = {};
	//this.modal_reserva.componentInstance.ambiente_seleccionado = null;
	this.modal_reserva.componentInstance.usuario = this.usuario;
	this.modal_reserva.componentInstance.sucursales = this.filter.sucursales;
	this.modal_reserva.componentInstance.aplicacion = this.aplicacion;
	this.modal_reserva.componentInstance.alTerminar.subscribe((res) => {
		this.buscarReservas();
		this.modal_reserva.close();
	});
  }

  abrirPopupConfirmacionEliminacion(reserva){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la reserva?";
		this.popupConfirmacion.componentInstance.data = reserva;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarReserva($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	async eliminarReserva(reserva){
		this.blockUI.start();
		if(reserva.id_venta){
			let venta={id:reserva.id_venta};
			await this.ventasService.anularVenta(venta).toPromise();
		}
		let res_reserva:any=await this.reservasService.anularReserva(reserva.id).toPromise();
		this.blockUI.stop();
		this.toastr.success(res_reserva.mensaje);
		this.buscarReservas();
	}

	abrirPopupConfirmacionCambioEstadoReserva(reserva,fecha_visualizacion){
		this.reserva=reserva;
		this.modal_cambio_estado=this.modalService.open(this.modal_cambio_estado_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"sm"});
		this.modal_cambio_estado.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
  
	cambiarEstadoReserva(){
		this.blockUI.start();
		this.reservasService.actualizarEstadoReserva(this.reserva).subscribe((resP:any)=>{
			this.blockUI.stop();
			this.modal_cambio_estado.close();
			this.toastr.success(resP.mensaje);
			this.buscarReservas();
		});
	}

	async obtenerEstadosReserva(){
		let estados:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_ESTADOS_RESERVAS).toPromise();
		this.estados_cambio=estados.clases;
		this.filter.estados=this.filter.estados.concat(estados.clases);
	}

	crearPagoVenta(reserva){
		this.modal_pago_reserva = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_pago_reserva.componentInstance.venta = {id:reserva.id_venta};
		this.modal_pago_reserva.componentInstance.usuario = this.usuario;
		this.modal_pago_reserva.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.buscarReservas();
					this.modal_pago_reserva.close();
				}
			}else{
				this.modal_pago_reserva.close();
			}
			
		});
  	}

	verReserva(reserva,ambiente){console.log(reserva);
		this.reserva=reserva;
		this.ambiente=ambiente;
		this.modal_vista_reserva=this.modalService.open(this.modal_vista_reserva_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"md"});
		this.modal_vista_reserva.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

}
