<div class="keep-open btn-group show" title="Columns">
    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Visualización columnas" aria-expanded="true">
        <i class="fa fa-th-list text-orange-d1"></i>
        <span class="caret"></span>
    </button>

    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
        <label class="dropdown-item dropdown-item-marker" *ngFor="let fieldKey of page_configuration.getConfKeys()">
            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1 " type="checkbox" [(ngModel)]="page_configuration.object.datos.visualizacion_columnas[fieldKey].show" (change)="page_configuration.updateObject()"/>
            <span> {{page_configuration.object.datos.visualizacion_columnas[fieldKey].value}}</span>
        </label>
    </div>
  </div>

  