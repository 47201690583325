import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class PlanDeCuentasService {

  constructor(private http: HttpClient) { }

  public obtenerPlanCuentas(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contabilidad-cuentas/empresa/"+paginator.filter.id_empresa+"/clasificacion/"+paginator.filter.clasificacion.id+"/tipo/"+paginator.filter.tipo_cuenta.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+(paginator.search)+"/columna/"+paginator.column+"/direccion/"+paginator.direction, GlobalVariable.httpOptions);
  }

  public obtenerClasificacionesCuentas(){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contabilidad-cuentas/clasificaciones", GlobalVariable.httpOptions);
  }

  public guardarImportacionCuentas(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cuentas/empresa",datos, GlobalVariable.httpOptions);
  }

  public obtenerLibroMayorCuenta(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"comprobante-cuenta/"+paginator.cuenta.id+"/periodo/"+fecha_inicial+"/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public eliminarCuenta(id_cuenta){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"contabilidad-cuenta/"+id_cuenta, GlobalVariable.httpOptions);
  }

  public validarCodigoCuenta(cuenta){
    return this.http.post(GlobalVariable.API_SERVER_URL+"validar-codigo",cuenta, GlobalVariable.httpOptions);
  }

  public guardarCuenta(cuenta){
    return this.http.post(GlobalVariable.API_SERVER_URL+"contabilidad-cuenta",cuenta,GlobalVariable.httpOptions);
  }

  public actualizarCuenta(cuenta){
    return this.http.put(GlobalVariable.API_SERVER_URL+"contabilidad-cuenta/"+cuenta.id,cuenta,GlobalVariable.httpOptions);
  }

  public obtenerCuenta(cuenta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"contabilidad-cuenta/"+cuenta.id,GlobalVariable.httpOptions);
  }

  public buscarCuenta(id_empresa,texto,tipo_cuenta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"comprobante-cuenta/empresa/"+id_empresa+"/busqueda/"+texto+"/tipo-cuenta/"+tipo_cuenta, GlobalVariable.httpOptions);
  }

  public obtenerCuentasGenericas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cuentas-genericas-empresa/"+id_empresa, GlobalVariable.httpOptions);
  }

  public obtenerCuentasAuxiliares(id_cuenta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cuenta/"+id_cuenta+"/auxiliares", GlobalVariable.httpOptions);
  }

  public obtenerCuentaPadreUltimoCodigo(codigo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cuenta-padre/ultimo-codigo/"+codigo, GlobalVariable.httpOptions);
  }

  public asignarCuentaCliente(asignacion_cuenta_cliente){
    return this.http.post(GlobalVariable.API_SERVER_URL+"contabilidad-cuenta/asignacion-cuenta-cliente",asignacion_cuenta_cliente,GlobalVariable.httpOptions);
  }

  public obtenerCuentaAuxiliarCliente(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cuenta-auxiliar-cliente/"+id_cliente, GlobalVariable.httpOptions);
  }

  public contabilizarVenta(datos){
    return this.http.put(GlobalVariable.API_SERVER_URL+"venta-contabilizada/"+datos.id_venta,datos, GlobalVariable.httpOptions);
  }

  public contabilizarPagoVenta(datos){
    return this.http.put(GlobalVariable.API_SERVER_URL+"pago-venta-contabilizado/"+datos.id_pago_venta,datos, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionCuentas(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-cuentas/empresa/"+id_empresa, GlobalVariable.httpOptions);
  }

  public guardarConfiguracionCuentas(id_empresa,datos){
    return this.http.put(GlobalVariable.API_SERVER_URL+"configuracion-cuentas/empresa/"+id_empresa,datos, GlobalVariable.httpOptions);
  }
}