import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { GeneralService } from '../../services/general/general.service';
import { ToastrService } from 'ngx-toastr';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { CategoriaTiendaComponent } from 'src/app/snapquick/components/categoria-tienda/categoria-tienda.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-concepto-empresa',
  templateUrl: './concepto-empresa.component.html',
  styleUrls: ['./concepto-empresa.component.css']
})
export class ConceptoEmpresaComponent implements OnInit {

  tipo_edicion:any={clases:[]};
  clase_nombre_corto:any;
  id_empresa:any;
  clase:any={};
  usuario:any;
  @Output() onTerminate:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;
  edicion_categoria_tienda:NgbModalRef;

  constructor(public generalService:GeneralService,
    private toastr: ToastrService,
    public modalService: NgbModal,
    private persistenciaService:PersistenciaService) { }

  ngOnInit(): void {
    this.usuario=this.persistenciaService.get("usuario");
    if(this.clase_nombre_corto){
      this.generalService.obtenerClasesEmpresa(this.id_empresa,this.clase_nombre_corto).subscribe((entidad:any) => {
        this.tipo_edicion=entidad;
      });
    }else{
      this.tipo_edicion={
        id_empresa:this.id_empresa
      }
    }
  }

  guardarConceptoEdicion(){
    this.blockUI.start();
    if(this.tipo_edicion.id){
      this.generalService.actualizarTipo(this.tipo_edicion).subscribe((res:any) => {
        this.toastr.info(res.message);
        this.cerrarVentana(true);     
        this.blockUI.stop();
      });
    }else{
      this.generalService.crearTipo(this.tipo_edicion).subscribe((res:any) => {
        this.toastr.info(res.message);
        this.cerrarVentana(true);     
        this.blockUI.stop();
      });
    }
  }

  cerrarVentana(save){
    this.onTerminate.emit({save:save});
  }

  agregarConceptoEdicion (clase) {
    if (clase.nombre/* && clase.nombre_corto*/) {
      this.tipo_edicion.clases=!this.tipo_edicion.clases?[]:this.tipo_edicion.clases;
      if (this.tipo_edicion.clases.indexOf(clase) == -1) {
        clase.habilitado=true;
        this.tipo_edicion.clases.push(clase);
      }
      this.clase = {}
    }
  }

  modificarConceptoEdicion (clase) {
    this.clase = clase;
  }

  removerConceptoEdicion(clase){
    clase.eliminado = true;
  }

  abrirRegistroCategoriaTienda(clase){
		this.edicion_categoria_tienda = this.modalService.open(CategoriaTiendaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.edicion_categoria_tienda.componentInstance.usuario = this.usuario;
    this.edicion_categoria_tienda.componentInstance.clase = clase;
    
		this.edicion_categoria_tienda.componentInstance.alTerminar.subscribe(($e) => {
			
			this.edicion_categoria_tienda.close();
    });
	}

}
