import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CursosService } from '../../services/cursos/cursos.service';
import * as fs from 'file-saver';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.css']
})
export class CursosComponent extends BaseComponent implements OnInit {

  cursos:any[]=[];
  niveles:any[]=[];
  curso:any={};
  escuela:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  modal_modificacion_curso:NgbModalRef;
  @ViewChild('modal_modificacion_curso')
  private modal_modificacion_curso_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    private cursosService:CursosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.obtenerCursos();
    this.obtenerNiveles();
    this.escuela=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerCursos(){
    this.blockUI.start();
    this.cursosService.obtenerCursosColegio(this.usuario.id_empresa).subscribe((cursos:any)=>{
      this.blockUI.stop();
      this.cursos=cursos;
    });
  }

  obtenerNiveles(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).subscribe((entidad:any)=>{
      this.blockUI.stop();
      this.niveles=entidad.clases;
    });
  }

  async crearNuevoCurso(){
    this.curso={};
    this.curso.id_escuela = this.escuela.id;
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CURSO);
    this.curso.codigo = codigo_nuevo;
    this.modal_modificacion_curso=this.modalService.open(this.modal_modificacion_curso_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_modificacion_curso.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  async validarDatosCurso(){
    let res=true;
		if(!this.curso.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del curso!");
    }
    if(!this.curso.nombre_corto){
      res=res && false;
      this.toastr.error("Debe especificar el nombre corto del curso!");
    }
    if(this.curso.orden==null || this.curso.orden==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el orden del curso!");
    }
    if(!this.curso.nivel){
      res=res && false;
      this.toastr.error("Debe especificar el nivel escolar del curso!");
    }
		return res;
  }

  async guardarCurso(){
    if(await this.validarDatosCurso()){
      this.blockUI.start();
      if (this.curso.id) {
        this.cursosService.actualizarCurso(this.curso).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_modificacion_curso.close();
          this.obtenerCursos();
        });
      } else {
        this.curso.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CURSO):this.curso.codigo;
        this.cursosService.guardarCurso(this.curso).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CURSO);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_modificacion_curso.close();
          this.obtenerCursos();
        });
      }
    }
  }

  async modificarCurso(curso){
    this.curso=curso;
    this.modal_modificacion_curso=this.modalService.open(this.modal_modificacion_curso_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_modificacion_curso.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  abrirPopupConfirmacionEliminacion(curso){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el curso?";
		this.popupConfirmacion.componentInstance.data = curso;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCurso($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarCurso(curso){
    this.blockUI.start();
    this.cursosService.eliminarCurso(curso).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
		  this.obtenerCursos();
    });
  }

  descargarCursosBanco(){
    this.blockUI.start();
    let linea="";
    for(let i=0;i<this.cursos.length;i++){
      linea=linea+"3652"+",";
      linea=linea+this.cursos[i].nombre+",";
      linea=linea+(this.cursos[i].nombre.split(' ')[this.cursos[i].nombre.split(' ').length-1])+",";
      linea=linea+this.cursos[i].nivel.nombre_corto+",";
      linea=linea+"MAÑANA"+",";
      linea=linea+this.cursos[i].codigo+",";
      linea=linea+(new Date().getFullYear());
      linea=linea+"\n"
    }
    var file = new Blob([linea.replace(/\n/g, "\r\n")], {type: 'text/plain'});
    let nombre_archivo="cursos_banco.txt"
    fs.saveAs(file, nombre_archivo);
    this.blockUI.stop();
  }

}
