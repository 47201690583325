import { Component, OnInit, Input } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PersistenciaService } from '../../base/services/persistencia/persistencia.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input() usuario:any;
  rest_server=GlobalVariable.SERVER_URL;
  aplicaciones:any[];
  @BlockUI() blockUI: NgBlockUI;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router) {
  }

  ngOnInit(): void {    
  }

  cerrarSesion(){
    this.blockUI.start('Saliendo... espere por favor!');
    this.usuario = null;
    this.persistenciaService.removerUsuario();
    window.localStorage.removeItem("ngStorage-usuario");//clear();
    window.localStorage.removeItem("ngStorage-token");
    window.localStorage.clear();
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>{
      this.blockUI.stop();
      this.router.navigate(['/']);
      window.location.reload()
    });
  }
}
