<div class="modal-header">
    <div class="col-10">
        <span class="widget-title text-blue text-125">Pagos de ventas No Contabilizadas</span>
    </div>
    <div class="col-md-2 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
      <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-container" style="padding-bottom: 0px;">
          <div class="fixed-table-header" style="display: none;">
            <table></table>
          </div>
          <div class="table-responsive table-fixed-header" style="height: 450px;">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
              <span class="loading-wrap">
                <span class="animation-wrap">
                  <span class="animation-dot"></span>
                </span>
              </span>
            </div>
  
            <table class="table table-striped table-bordered table-hover ">
                <thead fix-head class="table-head-color">
                    <tr>
                        <th>Nº</th>
                        <th>Sucursal</th>
                        <th>Num. Doc.</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>A Cuenta anterior</th>
                        <th>Saldo anterior</th>
                        <th style="text-align: center; width: 140px; " data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pago_venta_no_contabilizado of pagos_ventas_no_contabilizados; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{pago_venta_no_contabilizado.sucursal}}</td>
                        <td>{{pago_venta_no_contabilizado.numero_documento}}</td>
                        <td>{{pago_venta_no_contabilizado.fecha | date:"dd/MM/yyyy"}}</td>
                        <td>{{pago_venta_no_contabilizado.cliente}}</td>
                        <td>{{pago_venta_no_contabilizado.monto_pagado.toFixed(2)}}</td>
                        <td>{{pago_venta_no_contabilizado.a_cuenta_anterior.toFixed(2)}}</td>
                        <td>{{pago_venta_no_contabilizado.saldo_anterior.toFixed(2)}}</td>
                        <td style="text-align: center; width: 140px; ">
                            <div class="d-lg-inline text-muted">
                                <a title="Contabilizar" *ngIf="pago_venta_no_contabilizado.id_cliente_cuenta_contabilidad" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="generarNuevoComprobantePagoVenta(pago_venta_no_contabilizado)">
                                    <i class="fas fa-sign-in-alt"></i>
                                </a>
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pago_venta_no_contabilizado)">
                                  <i class="fa fa-trash-alt"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
              
          </div>
          <div class="fixed-table-footer">
            <table><thead><tr></tr></thead></table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
</div>