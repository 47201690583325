import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GeneralService } from '../../../base/services/general/general.service';
import { CotizacionesService } from '../../services/cotizaciones/cotizaciones.service';
import { ProductosService } from '../../services/productos/productos.service';
import { CombosService } from '../../services/combos/combos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Util } from 'src/app/utils/utils';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ClientesService } from '../../services/clientes/clientes.service';
import { RegistroClienteComponent } from 'src/app/base/components/registro-cliente/registro-cliente.component';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

declare const enfocarElemento:any;
declare const $:any;
declare const clickearElemento:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  rest_server:any=GlobalVariable.SERVER_URL;

  cotizacion:any={
    detallesCotizacion:[],
    usuario:null,
    fecha:null,
    id:null,
    fechaTexto:null,
    sucursal:null,
    importe:0,
    descuento:0,
    total:0,
    cliente:{},
    tipo_descuento:false,
    tiempo_validez:10
  }

  grupos_combos:any;
  razon_busqueda:any;

  combo:any;
  producto:any;
  detalleCotizacion:any;
  es_administrador:boolean=false;
  producto_busqueda:any;
  combo_busqueda:any;
  editar_precio:boolean=false;
  filtro:any;
  sucursales:any[];
  productos:any[];

  gloval_variable:any=GlobalVariable;

  searching = false;
  searchFailed = false;  

  busqueda_codigo_barra:boolean=false;
  modalCliente:NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,
    public productosService:ProductosService,
    public combosService:CombosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public cotizacionesService:CotizacionesService,
    public usuarioService:UsuarioService,
    public clientesService:ClientesService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.cotizacion.fecha=this.cotizacion.fecha?new Date(this.cotizacion.fecha):new Date();
    this.cotizacion.fechaTexto={
			year: this.cotizacion.fecha.getFullYear(), 
			month: (this.cotizacion.fecha.getMonth()+1),
			day:this.cotizacion.fecha.getDate()
		}
    this.iniciarDetalleCotizacion();
    this.editar_precio=this.cotizacion.usuario.empresa.usar_servicios;
    this.establecerSucursal(this.sucursales.length>=1?this.sucursales[0]:null);
    
    enfocarElemento('nit');
    this.obtenerGruposCombosEmpresa();
    this.razon_busqueda=this.cotizacion.cliente;
  }

  iniciarDetalleCotizacion(){
    this.producto_busqueda="";
    this.combo_busqueda="";
    this.detalleCotizacion={
      es_servicio:false,
      es_combo:false,
      item:GlobalVariable.Dictionary.ITEM_PRODUCTO,
      cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false
    }
    if(this.detalleCotizacion.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      $("#venta_id_producto").val("");
      enfocarElemento('venta_id_producto');
    }else if(this.detalleCotizacion.item==GlobalVariable.Dictionary.ITEM_COMBO){
      $("#venta_id_combo").val("");
      enfocarElemento('venta_id_combo');
    }else{
      $("#venta_id_descripcion").val("");
      enfocarElemento('venta_id_descripcion');
    }
  }

  establecerSucursal(sucursal){
    this.cotizacion.sucursal=sucursal;
  }

  cambiarServicio(es_servicio){
    if(es_servicio){
      this.detalleCotizacion.item=GlobalVariable.Dictionary.ITEM_SERVICIO;
    }else{
      if(this.cotizacion.usuario.empresa.usar_combos){
        this.detalleCotizacion.item=GlobalVariable.Dictionary.ITEM_COMBO;
        this.detalleCotizacion.combo={};
      }else{
        this.detalleCotizacion.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
        this.detalleCotizacion.producto={};
      }
    }
    this.editar_precio=es_servicio;
  }

  cambiarCombo(es_combo){
    if(es_combo){
      this.detalleCotizacion.item=GlobalVariable.Dictionary.ITEM_COMBO;
      this.detalleCotizacion.combo={};
    }else{
      this.detalleCotizacion.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
      this.detalleCotizacion.producto={};
    }
  }

  buscarCombo = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.combosService.buscarCombo(this.cotizacion.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterCombo = (x: any) => (x.nombre);

  establecerCombo($event){
    this.combo=$event.item;
    this.detalleCotizacion.combo=this.combo;
    this.detalleCotizacion.precio_unitario=this.combo.precio_unitario;
    this.editar_precio=false;
    this.calcularImporte();
    enfocarElemento('venta_cantidad');
    /*this.combosService.obtenerPreciosCombo(this.combo.id,this.cotizacion.sucursal.id).subscribe((precios:any[]) => {
      this.combo.precios=precios;
      this.editar_precio=false;
      
      var ids_productos="";
      for(let j=0;j<this.combo.detallesCombo.length;j++){
        if((j+1)==this.combo.detallesCombo.length){
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id;
        }else{
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id+",";
        }
      }

      this.productosService.obtenerInventariosProductos(ids_productos,this.venta.almacen.id).subscribe((productos_inventario:any[]) => {
        let totalInventarioCombo=50000;
        for(let j=0;j<this.combo.detallesCombo.length;j++){
          let prodInv=productos_inventario.filter(e => e.id == this.combo.detallesCombo[j].producto.id)[0];
          let totalInvProducto=this.obtenerInventarioTotal(prodInv);

          this.combo.detallesCombo[j].costos=prodInv.inventarios;
          let totalInventarioComboPorProducto=parseInt((totalInvProducto/this.combo.detallesCombo[j].cantidad).toString());
          totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
        }

        this.detalleVenta.combo=this.combo;
        this.detalleVenta.precio_unitario=this.combo.precio_unitario;
        this.detalleVenta.inventario_disponible=totalInventarioCombo;

        if(this.venta.usuario.empresa.usar_precios_productos_sucursal){
          var precio=this.combo.precios.filter(e => e.por_defecto && e.id_sucursal === this.venta.sucursal.id);
          if(precio.length>0){
            this.detalleVenta.combo_precio=precio[0];
            this.detalleVenta.precio_unitario=precio[0].precio_unitario;
          }
        }else{
          this.detalleVenta.combo_precio=this.combo.precios.filter(e => e.por_defecto)[0];
        }

        this.combo.inventario_minimo=1;
        var arreglo=Util.colorearInventario(totalInventarioCombo,this.combo);
        this.inventario_porcentaje=arreglo[0];
        this.inventario_color=arreglo[1];
        this.calcularImporte();
        enfocarElemento('venta_cantidad');
      });
    });*/
  }

  calcularImporte(){
    this.detalleCotizacion.importe=Math.round((this.detalleCotizacion.cantidad*this.detalleCotizacion.precio_unitario)*1000)/1000;
    let descuento;
    if(this.detalleCotizacion.tipo_descuento){
      descuento=this.detalleCotizacion.importe*(this.detalleCotizacion.descuento/100);
    }else{
      descuento=this.detalleCotizacion.descuento;
    }
    this.detalleCotizacion.total=Math.round((this.detalleCotizacion.importe-descuento)*1000)/1000;
  }

  abrirPopupConfirmacionCierreVentana(){
    if(this.cotizacion.detallesCotizacion.length>2){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de cerrar la cotizacion? Todo lo ingresado se perdera.";
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.cerrarComponente();
        }
        this.popupConfirmacion.close();
      });
    }else{
      this.cerrarComponente();
    }
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  enfocarElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      enfocarElemento(id_elemento);
    }
  }

  clickearElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      clickearElemento(id_elemento);
    }
  }

  agregarDetalleCotizacion(){
    if(this.detalleCotizacion.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      this.cotizacion.descuento=(this.usuario.empresa.usar_descuento_global?this.cotizacion.descuento:this.cotizacion.descuento+(this.detalleCotizacion.tipo_descuento?this.detalleCotizacion.importe*(this.detalleCotizacion.descuento/100):this.detalleCotizacion.descuento));
      var detalleVentaAAgregar=this.detalleCotizacion;
      var detalleVentaProductoExistente=this.cotizacion.detallesCotizacion.filter(e => !e.eliminado && e.producto && (e.producto.id == detalleVentaAAgregar.producto.id));
      if(detalleVentaProductoExistente.length>0){
        detalleVentaProductoExistente=detalleVentaProductoExistente[0];
        detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
        this.calcularImporteDetalle(detalleVentaProductoExistente);
        this.cotizacion.detallesCotizacion[this.cotizacion.detallesCotizacion.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
      }else{
        this.cotizacion.detallesCotizacion.push(this.detalleCotizacion);
      }
      this.iniciarDetalleCotizacion();
    }else if(this.detalleCotizacion.item==GlobalVariable.Dictionary.ITEM_COMBO){
      this.cotizacion.detallesCotizacion.push(this.detalleCotizacion);
      this.iniciarDetalleCotizacion();
    }else{
      this.cotizacion.detallesCotizacion.push(this.detalleCotizacion);
      this.iniciarDetalleCotizacion();
    }
    this.sumarTotal();
    this.sumarTotalImporte();
  }

  calcularImporteDetalle(detalleCotizacion){
    detalleCotizacion.importe=Math.round((detalleCotizacion.cantidad*detalleCotizacion.precio_unitario)*1000)/1000;
    var descuento,recargo;
    if(detalleCotizacion.tipo_descuento){
      descuento=detalleCotizacion.importe*(detalleCotizacion.descuento/100);
    }else{
      descuento=detalleCotizacion.descuento;
    }
    if(detalleCotizacion.tipo_recargo){
      recargo=detalleCotizacion.importe*(detalleCotizacion.recargo/100);
    }else{
      recargo=detalleCotizacion.recargo;
    }
    detalleCotizacion.total=Math.round((detalleCotizacion.importe-descuento+(recargo?recargo:0)-(detalleCotizacion.ice?detalleCotizacion.ice:0)-(detalleCotizacion.excento?detalleCotizacion.excento:0))*1000)/1000;
    if(this.cotizacion.usuario.empresa.usar_obsequios && detalleCotizacion.es_obsequio){
      detalleCotizacion.importe=0;
      detalleCotizacion.total=0;
    }
  }

  sumarTotalImporte(){
    var sumaImporte=0;
    for(var i=0;i<this.cotizacion.detallesCotizacion.length;i++){
      if(!this.cotizacion.detallesCotizacion[i].eliminado){
        sumaImporte=sumaImporte+this.cotizacion.detallesCotizacion[i].importe;
      }
    }		
    this.cotizacion.importe=Math.round((sumaImporte)*1000)/1000;
  }

  sumarTotal(){
    var sumaTotal=0;
    for(var i=0;i<this.cotizacion.detallesCotizacion.length;i++){
      if(!this.cotizacion.detallesCotizacion[i].eliminado){
        sumaTotal=sumaTotal+this.cotizacion.detallesCotizacion[i].total;
      }
    }		
    this.cotizacion.total=Math.round((sumaTotal)*1000)/1000;
  }

  eliminarDetalleCotizacion(detalleCotizacion){
    if(detalleCotizacion.id){
      detalleCotizacion.eliminado=true;
    }else{
      this.cotizacion.detallesCotizacion.splice(this.cotizacion.detallesCotizacion.indexOf(detalleCotizacion),1);
    }
    this.cotizacion.descuento=(this.usuario.empresa.usar_descuento_global?this.cotizacion.descuento:this.cotizacion.descuento-(detalleCotizacion.tipo_descuento?detalleCotizacion.importe*(detalleCotizacion.descuento/100):detalleCotizacion.descuento));
    this.sumarTotal();
    this.sumarTotalImporte();
  }

  activarDesactivarBusquedaCodigoBarra(){
    this.busqueda_codigo_barra=!this.busqueda_codigo_barra;
  }

  validarCotizacion(){
    let res=true;
    if((!this.configuracion_pagina.object.datos.opciones_permisos.cliente_registrado && !this.cotizacion.nombre) || 
    (this.configuracion_pagina.object.datos.opciones_permisos.cliente_registrado && !this.cotizacion.cliente.id)){
      res=res && false;
      this.toastr.error("Debe especificar el nombre del cliente!");
    }
    if(!this.cotizacion.fechaTexto || !this.cotizacion.fechaTexto.year || !this.cotizacion.fechaTexto.month || !this.cotizacion.fechaTexto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la cotización!");
    }
    if(this.cotizacion.detallesCotizacion.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item a la cotización!");
    }
    return res;
  }

  guardar(){
    if(this.validarCotizacion()){
      this.blockUI.start();
      let tiempoActual=new Date();
      this.cotizacion.fecha=Util.convertirObjetoAfecha(this.cotizacion.fechaTexto);
      this.cotizacion.fecha.setHours(tiempoActual.getHours());
      this.cotizacion.fecha.setMinutes(tiempoActual.getMinutes());
      this.cotizacion.fechaTexto=this.cotizacion.fechaTexto.day+"/"+this.cotizacion.fechaTexto.month+"/"+this.cotizacion.fechaTexto.year;
      if(this.cotizacion.id){
        this.cotizacionesService.actualizarCotizacion(this.cotizacion).subscribe((res:any) => {
          this.blockUI.stop();
          this.alTerminar.emit(res);
        });
      }else{
        this.cotizacionesService.guardarCotizacion(this.cotizacion).subscribe((res:any) => {
          this.blockUI.stop();
          this.alTerminar.emit(res);
        });
      }

      /*if(venta_actual.usuario.empresa.usar_pantalla_cliente){
        socket.emit('terminarVenta',venta_actual.sucursal);
      }*/
    }
  }

  obtenerGruposCombosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.cotizacion.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_COMBO_GRUPOS).subscribe((tipoGrupoEmpresa:any) => {
			this.grupos_combos=tipoGrupoEmpresa.clases;
			//this.filter.grupos_filtro=this.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
		});
	}

  agruparDetallesCotizacion(agrupar){
    if(agrupar){
      for(let i=0;i<this.grupos_combos.length;i++){
        this.grupos_combos[i].items=this.cotizacion.detallesCotizacion.filter(e => e.combo.id_grupo==this.grupos_combos[i].id);
      }
    }
  }

  sumarImporteGrupo(grupo){
    var suma=0;
    for(let j=0;j<grupo.items.length;j++){
      suma=suma+grupo.items[j].total;
    }
    return suma;
  }

  buscarCodigoBarraProducto(a,r){

  }

  buscarProducto = (text$: Observable<string>) => 
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError((e) => {console.log(e);
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProducto($event){
    this.producto=$event.item
    this.producto.tipoProducto=this.producto['tipoProducto']==null?{id:this.producto['tipoProducto.id'],nombre:this.producto['tipoProducto.nombre'],nombre_corto:this.producto['tipoProducto.nombre_corto']}:this.producto.tipoProducto;
    this.productosService.obtenerPreciosProductos(this.producto.id).subscribe(async (precios_datos:any[]) => {
      let precios=[];
      if(this.usuario.empresa.usar_precios_productos_sucursal){
        precios=precios.concat(precios_datos.filter(p => p.id_sucursal==this.cotizacion.sucursal.id));
      }
      if(this.usuario.empresa.usar_precios_productos_usuarios){
        precios=precios.concat(precios_datos.filter(p => p.id_usuario==this.usuario.id));
      }
      if(precios.length==0){//Sin ninguno de los anteriores tiene precio
        precios=precios_datos.filter(p => (p.id_sucursal==null && p.id_cliente==null && p.id_usuario==null));
      }
      this.producto.precios=precios;
      this.editar_precio=false;

      this.detalleCotizacion.producto=this.producto;
      this.detalleCotizacion.precio_unitario=this.producto.precio_unitario;
      this.detalleCotizacion.activar_inventario=this.producto.activar_inventario;
      this.detalleCotizacion.costos=this.producto.activar_inventario?this.producto.inventarios:[];
      this.detalleCotizacion.producto_precio=this.producto.precios.filter(e => e.por_defecto)[0];
      this.detalleCotizacion.precio_unitario=this.detalleCotizacion.producto_precio?this.detalleCotizacion.producto_precio.precio_unitario:0;
      this.calcularImporte();
      //CerrarPopup(idModalInventario);
      enfocarElemento('venta_cantidad');
      setTimeout(()=>{
        aplicarVisorImagenes("imagen-producto-vista");
      }, 200);
    });
  }

  modificarPrecio(){
    this.editar_precio=true;
  }

  establecerPrecio(){
    this.editar_precio=false;
  }

  mostrarDescuentos(){
    var style=$(".des-datos").css("display");
    if(style=="none"){
      $(".des-datos").css("display","");
    }else{
      $(".des-datos").css("display","none");
    }
  }

  establecerCliente(event){
    this.cotizacion.cliente=event.item?event.item:event;
    this.razon_busqueda=this.cotizacion.cliente;
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClientePedido(this.usuario.id_empresa,0,0,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
    formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

    reiniciarCliente(){
      this.cotizacion.cliente={};
      this.razon_busqueda=null;
    }

    abrirPopupRegistroRapidoCliente(){
      this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.cotizacion.cliente));
      this.modalCliente.componentInstance.sucursales = this.sucursales;
      this.modalCliente.componentInstance.sucursal = this.cotizacion.sucursal;
      this.modalCliente.componentInstance.usuario = this.usuario;
      
      this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.establecerCliente(res.cliente);
            this.modalCliente.close();
          }
        }else{
          this.modalCliente.close();
        }
      });
    }

    establecerDescuentoGlobal(){
      let descuento_detalles=0,descuento=0;
      for(let i=0;i<this.cotizacion.detallesCotizacion.length;i++){
        descuento_detalles=descuento_detalles+(this.cotizacion.detallesCotizacion[i].tipo_descuento?(this.cotizacion.detallesCotizacion[i].importe*(this.cotizacion.detallesCotizacion[i].descuento/100)):this.cotizacion.detallesCotizacion[i].descuento);
      }
      if(this.cotizacion.tipo_descuento){
        descuento=(this.cotizacion.importe-descuento_detalles)*(this.cotizacion.descuento/100);
      }else{
        descuento=this.cotizacion.descuento;
      }
      this.cotizacion.total=this.cotizacion.importe-descuento_detalles-descuento;
      this.cotizacion.usar_descuento_global=true;
    }

}
