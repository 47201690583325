<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Búsqueda de Producto</h4>
    </div>
    <div class="col-md-4 col-xs-4">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
<div class="row">
  <div class="col-12">
    <div class="radius-">
        <ul class="nav nav-tabs nav-tabs-faded bgc-info-m3 pt-2 pl-2 radius-t-1" role="tablist">
          <li class="nav-item mr-3px">
            <a class="active btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0" data-toggle="tab" href="#catalogo" role="tab" aria-controls="catalogo" aria-selected="true">
              Catálogo
            </a>
          </li>
          <li class="nav-item mr-3px">
            <a class="btn btn-brc-tp btn-info btn-h-red btn-a-white btn-a-text-info text-95 px-3 px-lg-4 py-2 border-0 radius-b-0" data-toggle="tab" href="#inventario" role="tab" aria-controls="inventario" aria-selected="false">
              Inventario
            </a>
          </li>
        </ul>

        <div class="tab-content bgc-white p-35 border-1 border-t-0 brc-default-l2 radius-b-1">
          <div class="tab-pane fade show active text-95" id="catalogo" role="tabpanel">
            <div class="bootstrap-table bootstrap4">
                <div class="fixed-table-toolbar">
                  <div class="row">
                    <div class="col-12 col-md-9">
                        <div class="row">
                          <div class="col-12 col-md-12">
                              <app-paginator-header [paginator]="catalogo"></app-paginator-header>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-12">
                              <div class="row">
                                <div class="col-md-2 col-sm-12"><label>Grupo </label></div>
                                <div class="col-md-4 col-sm-12">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="catalogo.filter.grupo">
                                        <option [ngValue]="grupo" *ngFor="let grupo of catalogo.filter.grupos_filtro">{{grupo.nombre}}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 col-sm-12"><label>Sub Grupo </label></div>
                                <div class="col-md-4 col-sm-12">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo" [(ngModel)]="catalogo.filter.subgrupo">
                                        <option [ngValue]="subgrupo" *ngFor="let subgrupo of catalogo.filter.subgrupos_filtro">{{subgrupo.nombre}}</option>
                                    </select>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="columns columns-right btn-group float-right">
                        <button (click)="catalogo.getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                          <i class="fa fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div class="fixed-table-container" style="padding-bottom: 0px;">
                  <div class="fixed-table-header" style="display: none;">
                    <table></table>
                  </div>
                  <div id="gallery" class="fixed-table-body">
                    <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                      <span class="loading-wrap">
                        <span class="animation-wrap">
                          <span class="animation-dot"></span>
                        </span>
                      </span>
                    </div>
                    <table *ngIf="catalogo.productos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
                      <thead class="bgc-white text-grey text-uppercase text-80">
                        <tr>
                          <th class="detail" rowspan="1">
                            <div class="th-inner ">Nº</div>
                          </th>
                            <th id="cabecera_codigo" class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='codigo' && catalogo.direction=='asc','desc':catalogo.column=='codigo' && catalogo.direction=='desc'}">
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('codigo')">Código</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th id="cabecera_nombre" class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='nombre' && catalogo.direction=='asc','desc':catalogo.column=='nombre' && catalogo.direction=='desc'}">
                                    <span class="fa fa-edit point-edit blue" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_NOMBRE)"></span> &nbsp;
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('nombre')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_NOMBRE)}}</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th>
                                <div class="th-inner ">
                                    Imagen
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='unidad_medida' && catalogo.direction=='asc','desc':catalogo.column=='unidad_medida' && catalogo.direction=='desc'}">
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('unidad_medida')">Unid. de Med.</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='precio_unitario' && catalogo.direction=='asc','desc':catalogo.column=='precio_unitario' && catalogo.direction=='desc'}">
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('precio_unitario')">P. Unit.</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='inventario_minimo' && catalogo.direction=='asc','desc':catalogo.column=='inventario_minimo' && catalogo.direction=='desc'}">
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('inventario_minimo')">Inventario Mínimo</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='descripcion' && catalogo.direction=='asc','desc':catalogo.column=='descripcion' && catalogo.direction=='desc'}">
                                    <span class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)"></span>&nbsp;
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('descripcion')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)}}</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center" *ngIf="usuario.empresa.usar_consumos"><a href="javascript:void(0)" (click)="paginator.sortColumn('tipo_producto')">Tipo <span id="tipo_producto" class="sort fa fa-sort icon-only"></span></a></th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='grupo' && catalogo.direction=='asc','desc':catalogo.column=='grupo' && catalogo.direction=='desc'}">
                                    <span class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)"></span>&nbsp;
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('grupo')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}}</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='subgrupo' && catalogo.direction=='asc','desc':catalogo.column=='subgrupo' && catalogo.direction=='desc'}">
                                    <span class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)"></span>&nbsp;
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('subgrupo')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)}}</a>
                                </div><div class="fht-cell"></div>
                            </th>
                            <th class="center">
                                <div class="th-inner sortable both " [ngClass]="{'asc':catalogo.column=='codigo_fabrica' && catalogo.direction=='asc','desc':catalogo.column=='codigo_fabrica' && catalogo.direction=='desc'}">
                                    <a href="javascript:void(0)" (click)="catalogo.sortColumn('codigo_fabrica')">Código Fábrica</a>
                                </div><div class="fht-cell"></div>
                            </th>
                          <th style="text-align: center;" data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr #productos *ngFor="let producto of catalogo.productos; let i=index;">
                          <td>
                            {{(i+1)+(catalogo.itemsPerPage*(catalogo.currentPage-1))}}
                          </td>
                          
                            <td rel="cabecera_codigo">
                                {{producto.codigo}}
                            </td>
                            <td rel="cabecera_nombre">
                                {{producto.nombre}}
                            </td>
                            <td>
                                <div class="imagen-producto-vista clearfix">
                                    <a href="{{rest_server+producto.imagen}}" data-rel="colorbox" title="{{producto.nombre}}">
                                        <img alt="sin imagen" src="{{rest_server+producto.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                                    </a>
                                </div>
                            </td>
                            <td>{{producto.unidad_medida}}</td>
                            <td >{{producto.precio_unitario}}</td>

                            <td>
                                {{producto.inventario_minimo}}
                            </td>
                            <td>
                                {{producto.descripcion}}
                            </td>
                            <td *ngIf="usuario.empresa.usar_consumos">
                                {{producto.tipo_producto}}
                            </td>
                            <td>
                                {{producto.grupo}}
                            </td>
                            <td >
                                {{producto.subgrupo}}
                            </td>
                            <td>
                                {{producto.codigo_fabrica}}
                            </td>
                          <td style="text-align: center;">
                            <div class="action-buttons">        
                              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="seleccionarProducto(producto)">          
                                <i class="fa fa-check text-105"></i>        
                              </a>    
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="fixed-table-footer">
                    <table><thead><tr></tr></thead></table>
                  </div>
                </div>
                <div class="fixed-table-pagination">
                  <app-paginator-footer *ngIf="catalogo.productos.length>0" class="pagination-detail" [paginator]="catalogo"></app-paginator-footer>
                </div>
              </div>
          </div>

          <div class="tab-pane fade text-95" id="inventario" role="tabpanel">
            <div class="bootstrap-table bootstrap4">
                <div class="fixed-table-toolbar">
                  <div class="row">
                    <div class="col-12 col-md-9">
                        <div class="row">
                          <div class="col-12 col-md-12">
                              <app-paginator-header [paginator]="inventario"></app-paginator-header>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-12">
                              <div class="row">
                                  <div class="col-1"><label>Sucursal </label></div>
                                  <div class="col-2">
                                      <select [compareWith]="compararObjectos" (change)="obtenerAlmacenesSucursal(inventario.filter.sucursal)" class="form-control" required name="sucursal" [(ngModel)]="inventario.filter.sucursal">
                                          <option [ngValue]="sucursal" *ngFor="let sucursal of inventario.filter.sucursales">{{sucursal.nombre}}</option>
                                      </select>
                                  </div>
                                  <div class="col-1"><label>Almacen </label></div>
                                  <div class="col-2">
                                      <select [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="inventario.filter.almacen">
                                          <option [ngValue]="almacen" *ngFor="let almacen of inventario.filter.almacenes">{{almacen.nombre}}</option>
                                      </select>
                                  </div>
                                <div class="col-1"><label>Grupo </label></div>
                                <div class="col-2">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="inventario.filter.grupo">
                                        <option [ngValue]="grupo" *ngFor="let grupo of inventario.filter.grupos_filtro">{{grupo.nombre}}</option>
                                    </select>
                                </div>
                                <div class="col-1"><label>Sub Grupo </label></div>
                                <div class="col-2">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo" [(ngModel)]="inventario.filter.subgrupo">
                                        <option [ngValue]="subgrupo" *ngFor="let subgrupo of inventario.filter.subgrupos_filtro">{{subgrupo.nombre}}</option>
                                    </select>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="columns columns-right btn-group float-right">
                        <button (click)="inventario.getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                          <i class="fa fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
          
                <div class="fixed-table-container" style="padding-bottom: 0px;">
                  <div class="fixed-table-header" style="display: none;">
                    <table></table>
                  </div>
                  <div id="gallery" class="fixed-table-body">
                    <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
                      <span class="loading-wrap">
                        <span class="animation-wrap">
                          <span class="animation-dot"></span>
                        </span>
                      </span>
                    </div>
          
                    <table *ngIf="inventario.productos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="tabla-inventarios">
                      <thead class="bgc-white text-grey text-uppercase text-80">
                          <tr>
                              <th>
                                  Nº
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='codigo' && this.direction=='asc','desc':inventario.column=='codigo' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="inventario.sortColumn('codigo')">Código </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='nombre' && this.direction=='asc','desc':inventario.column=='nombre' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="inventario.sortColumn('nombre')">Producto</a>
                                </div><div class="fht-cell"></div>
                              <th >
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='unidad_medida' && this.direction=='asc','desc':inventario.column=='unidad_medida' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('unidad_medida')">Unidad de Medida</a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='descripcion' && this.direction=='asc','desc':inventario.column=='descripcion' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">Descripción</a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='cantidad' && this.direction=='asc','desc':inventario.column=='cantidad' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('cantidad')">Cantidad Total</a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='precio_unitario' && this.direction=='asc','desc':inventario.column=='precio_unitario' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('precio_unitario')">Precio Unitario </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='grupo' && this.direction=='asc','desc':inventario.column=='grupo' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('grupo')">Grupo </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='subgrupo' && this.direction=='asc','desc':inventario.column=='subgrupo' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('subgrupo')">Sub Grupo  </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='observacion' && this.direction=='asc','desc':inventario.column=='observacion' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('observacion')">Observacion  </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th *ngIf="usuario.empresa.usar_vencimientos">
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='fecha_vencimiento' && this.direction=='asc','desc':inventario.column=='fecha_vencimiento' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('fecha_vencimiento')">Fecha  Vencimiento  </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th *ngIf="usuario.empresa.usar_vencimientos">
                                <div class="th-inner sortable both " [ngClass]="{'asc':inventario.column=='lote' && this.direction=='asc','desc':inventario.column=='lote' && this.direction=='desc'}">
                                  <a href="javascript:void(0)" (click)="this.sortColumn('lote')">Lote  </a>
                                </div><div class="fht-cell"></div>
                              </th>
                              <th>Acciones</th>
                          </tr>
                      </thead>
                  
                      <tbody>
                          <tr *ngFor="let producto of inventario.productos;let i=index;">
                              <td class="center">
                                  {{i+1}}
                              </td>
                              <td >{{producto.codigo}}</td>
                              <td >{{producto.nombre}}</td>
                              <td >{{producto.unidad_medida}}</td>
                              <td >{{producto.descripcion}}</td>
                              <td>
                                  <div class="c100 p{{producto.porcentaje}} small {{producto.color}}">
                                      <span style="color:black !important;">{{producto.cantidad}}</span>
                                      <div class="slice">
                                          <div class="bar"></div>
                                          <div class="fill"></div>
                                      </div>
                                  </div>
                              </td>
                              <td >{{producto.precio_unitario?producto.precio_unitario.toFixed(2):''}}</td>
                              <td >{{producto.grupo}}</td>
                              <td >{{producto.subgrupo}}</td>
                              <td >{{producto.observacion}}</td>
                              <td *ngIf="usuario.empresa.usar_vencimientos">{{producto.fecha_vencimiento | date:"dd/MM/yyyy"}}</td>
                              <td *ngIf="usuario.empresa.usar_vencimientos">{{producto.lote}}</td>
                              <td>
                                  <div class="d-none d-lg-inline text-muted">
                                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="seleccionarProducto(producto)">          
                                        <i class="fa fa-check text-105"></i>        
                                      </a>    
                                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verDetalleInventario(producto)">
                                          <i class="ace-icon fa fa-search-plus bigger-130"></i>
                                      </a>
                                  </div>
                                  <table id="{{producto.id}}" style="display:none;" class="table table-striped table-bordered table-hover">
                                      <thead>
                                          <tr>
                                              <th>#</th>
                                              <th>Cantidad</th>
                                              <th *ngIf="producto.activar_vencimiento">Fecha Venc.</th>
                                              <th *ngIf="producto.activar_vencimiento">Lote</th>
                                              <th>Costo Unitario</th>
                                              <th>Costo Total</th>
                                              
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let inventario of producto.inventarios;let j=index;">
                                              <td>{{j+1}}</td>
                                              <td>{{inventario.cantidad}}</td>
                                              <td *ngIf="producto.activar_vencimiento">{{inventario.fecha_vencimiento | date:"dd/MM/yyyy	"}}</td>
                                              <td *ngIf="producto.activar_vencimiento">{{inventario.lote}}</td>
                                              <td>{{inventario.costo_unitario}}</td>
                                              <td>{{inventario.costo_total}}</td>
                                          </tr>
                                          <tr>
                                              <td><b>Total Almacen</b></td>
                                              <td>{{sumarCantidadAlmacen(producto.inventarios,sucursal,almacen)}}</td>
                                              <td *ngIf="producto.activar_vencimiento"></td>
                                              <td *ngIf="producto.activar_vencimiento"></td>
                                              <td></td>
                                              <td>{{sumarCostoTotalAlmacen(producto.inventarios,sucursal,almacen)}}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  </div>
                  <div class="fixed-table-footer">
                    <table><thead><tr></tr></thead></table>
                  </div>
                </div>
                <div class="fixed-table-pagination">
                  <app-paginator-footer *ngIf="inventario.productos.length>0" class="pagination-detail" [paginator]="inventario"></app-paginator-footer>
                </div>
              </div>
          </div>
        </div>
      </div><!-- /.bcard -->
  </div>
</div>
</div>