import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { VentasService } from '../../../../base/services/ventas/ventas.service';
import { Util } from 'src/app/utils/utils';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PagoVentaComponent } from '../../pago-venta/pago-venta.component';
import { ToastrService } from 'ngx-toastr';
import { PdfService } from '../../../../base/services/pdf/pdf.service';
import { VistaVentaComponent } from '../../vista-venta/vista-venta.component';
import { BaseComponent } from 'src/app/models/base-component';
import { PersistenciaService } from '../../../../base/services/persistencia/persistencia.service';
import { GeneralService } from '../../../../base/services/general/general.service';
import { GlobalVariable } from 'src/app/global';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { UsuarioService } from '../../../../base/services/usuario/usuario.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-vencimiento-credito',
  templateUrl: './vencimiento-credito.component.html',
  styleUrls: ['./vencimiento-credito.component.css']
})
export class VencimientoCreditoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  usuario:any;
  ventas_credito:any[]=[];
  ventas_filtradas:any[]=[];
  filtro_venta: any ={};
  modal_pago_venta:NgbModalRef;

  constructor(
    public persistenciaService:PersistenciaService,
    public ventasService:VentasService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    public clientesService:ClientesService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  async ngOnInit(): Promise<void> {
    this.filter={
      estados:[{id:0,nombre:"TODOS"}]
    }
    await this.obtenerVentasCredito();
    await this.obtenerEstados();
  }

  async obtenerVentasCredito(){
    this.blockUI.start();
    let id_usuario=0;
    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				id_usuario=0;
			}else{
				id_usuario=this.usuario.id;
			}
		}else{
			id_usuario=0;
    }
    let vencimientosCreditos:any=await this.ventasService.obtenerListaVentasCreditoNotificacion(this.usuario.id_empresa,Util.obtenerIdsSucursales(this.usuario),id_usuario).toPromise();
    for (var i = 0; i < vencimientosCreditos.length; i++) {
      var fecha = new Date(vencimientosCreditos[i].fecha);
      vencimientosCreditos[i].fecha_vencimiento = Util.sumarDiasFecha(vencimientosCreditos[i].dias_credito, fecha);
      for (var j = 0; j < vencimientosCreditos[i].ventaReprogramacionPagos.length; j++) {
        if (vencimientosCreditos[i].ventaReprogramacionPagos[j].activo) {
          vencimientosCreditos[i].fecha_anterior = vencimientosCreditos[i].ventaReprogramacionPagos[j].fecha_anterior
        }
      }

    }
    this.ventas_credito = vencimientosCreditos;
    this.ventas_filtradas = vencimientosCreditos;
    this.blockUI.stop();
  }

  async obtenerEstados(){
    this.blockUI.start();
    let entidad:any=await this.generalService.obtenerClases("ESTVENT").toPromise();
    this.filter.estados=this.filter.estados.concat(entidad.clases);
    this.filter.estado=this.filter.estados.filter(est=>est.nombre_corto=="ESTVENTMOR")[0];
    this.filtrarEstado();
    this.blockUI.stop();
  }

  filtrarEstado(){
    if(this.filter.estado.id==0){
      this.ventas_filtradas = this.ventas_credito;
    }else{
      this.ventas_filtradas = this.ventas_credito.filter(venta => (venta.estado && venta.estado.id==this.filter.estado.id));
    }
  }

  calcularDiasMora(venta){
    var fecha_venta=new Date(venta.fecha);
    var fecha_actual=new Date();
    var diferencia_tiempo = Math.abs(fecha_actual.getTime() - fecha_venta.getTime());
    var diferencia_dias = Math.ceil(diferencia_tiempo / (1000 * 3600 * 24)); 
    var diferencia=venta.dias_credito-diferencia_dias;
    if(diferencia>0){
      return 0;
    }else{
      return Math.abs(diferencia);
    }
  }

  sumarTotalSaldo(ventas){
    var suma_saldo=0;
    for(var i=0;i<ventas.length;i++){
      suma_saldo=suma_saldo+ventas[i].saldo;
    }
    return suma_saldo;
  }

  filtrarVentas(texto: string) {
		let texto_minus = texto.toLowerCase();
		if(texto === '' ) {
			this.ventas_filtradas=this.ventas_credito;
		} else {
		  this.ventas_filtradas = this.ventas_credito.filter((venta) => ((venta.cliente.identificacion).toLowerCase().includes(texto_minus)));
		}
   }
   
   cerrarComponente(){
    this.alTerminar.emit();
   }

   generarExcelVencimientoCreditos(){
     let ventas=this.ventas_filtradas;
    this.blockUI.start();
    var suma_importe=0;
    var data = [];
    var header=["N°"];
    header.push("Sucursal");
    header.push("Tipo de Transaccion");
    header.push("Fecha Doc.");
    header.push("Cliente");
    header.push("Razón social");
    header.push("Nit");
    header.push("Número Doc.");
    header.push("Fecha Venc.");
    header.push("Saldo");
    header.push("Dias Mora");
    header.push("Observación");
    header.push("Estado");
    
    for(var i=0;i<ventas.length;i++){
      var columns=[];
      columns.push(i+1);
      columns.push(ventas[i].sucursal.nombre);
      columns.push(ventas[i].transaccion.nombre);
      ventas[i].fecha=new Date(ventas[i].fecha);
      columns.push(ventas[i].fecha.getDate()+"/"+(ventas[i].fecha.getMonth()+1)+"/"+ventas[i].fecha.getFullYear());
      columns.push(ventas[i].cliente.identificacion);
      columns.push(ventas[i].razon_social);
      columns.push(ventas[i].nit_ci);
      columns.push(ventas[i].factura);
      columns.push(ventas[i].fecha_vencimiento.getDate()+"/"+(ventas[i].fecha_vencimiento.getMonth()+1)+"/"+ventas[i].fecha_vencimiento.getFullYear());
      columns.push(ventas[i].saldo);
      columns.push(this.calcularDiasMora(ventas[i]));
      columns.push(ventas[i].observacion);
      columns.push(ventas[i].estado?ventas[i].estado.nombre:"");

      if(ventas[i].activa){
        suma_importe=suma_importe+ventas[i].saldo;
      }

      data.push(columns);
      if(i+1==ventas.length){
        columns=[];
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("TOTALES");
        columns.push(suma_importe);
        columns.push("");
      
        data.push(columns);
      }
      
    }

    let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte Vencimiento Creditos");

      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })

      data.forEach(d => {
        let row = worksheet.addRow(d);
      });

      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 40;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "Reporte Vencimiento Creditos.xlsx");
			  this.blockUI.stop();
		  });
  }

  imprimirListaVencimientoCreditos () {
    this.blockUI.start();
    let vencimientosCreditos=this.ventas_filtradas;

    var doc = new PDFDocument({ size: 'letter', margin: 10 });
    var stream = doc.pipe(blobStream());
    // draw some text
    var saldoFisico = 0;
    var saldoValuado = 0;

    var y = 140, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(vencimientosCreditos.length / itemsPorPagina);
    this.dibujarCabeceraPDFVencimientoCreditos(doc, 1, totalPaginas, vencimientosCreditos);
    for (var i = 0; i < vencimientosCreditos.length && items <= itemsPorPagina; i++) {

      doc.rect(30, y - 10, 555, 20).stroke();
      doc.font('Helvetica', 8);
      if (vencimientosCreditos[i].cliente.codigo == null) {
        doc.text("", 45, y, { width: 50 });
      } else {
        doc.text(vencimientosCreditos[i].cliente.codigo, 45, y, { width: 50 });
      }
      doc.text(vencimientosCreditos[i].cliente.razon_social, 100, y);
      if (vencimientosCreditos[i].factura == null) {
        doc.text("Proforma", 280, y);
      } else {
        doc.text("Factura Nro. " + vencimientosCreditos[i].factura, 280, y);
      }
      vencimientosCreditos[i].fecha = new Date(vencimientosCreditos[i].fecha);
      doc.text(vencimientosCreditos[i].fecha.getDate() + "/" + (vencimientosCreditos[i].fecha.getMonth() + 1) + "/" + vencimientosCreditos[i].fecha.getFullYear(), 400, y, { width: 50 });
      doc.text(vencimientosCreditos[i].saldo, 500, y, { width: 50, align: "right" });
      y = y + 20;
      items++;

      if (items == itemsPorPagina) {
        doc.addPage({ margin: 0, bufferPages: true });
        y = 140;
        items = 0;
        pagina = pagina + 1;

        this.dibujarCabeceraPDFVencimientoCreditos(doc, pagina, totalPaginas, vencimientosCreditos);

        doc.font('Helvetica', 8);
      }
    }
    doc.end();
    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL, '_blank', 'location=no');
    });
    this.blockUI.stop();

  }

  dibujarCabeceraPDFVencimientoCreditos (doc, pagina, totalPaginas, vencimientosCreditos) {
    var fechaActual = new Date();
    let min:any = fechaActual.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    doc.font('Helvetica-Bold', 10);
    doc.text(this.usuario.empresa.nombre, 0, 35, { align: "center" });
    doc.font('Helvetica', 8);
    doc.text("COCHABAMBA - BOLIVIA", 0, 50, { align: "center" });
    doc.font('Helvetica-Bold', 10);
    doc.text("LISTA DE VENCIMIENTOS CLIENTE", 0, 65, { align: "center" });
    doc.rect(210, 75, 180, 0);
    doc.font('Helvetica-Bold', 8);
    doc.text("PÁGINA " + pagina + " DE " + totalPaginas, 0, 740, { align: "center" });
    doc.rect(30, 100, 555, 30).stroke();
    doc.font('Helvetica-Bold', 8);
    doc.text("Codigo", 45, 110);
    doc.text("Cliente", 100, 110, { width: 50 });
    doc.text("Detalle", 280, 110, { width: 60 });
    doc.text("Vencimiento", 400, 110, { width: 50 });
    doc.text("monto", 525, 110, { width: 50 });
    doc.font('Helvetica', 7);
    doc.text("usuario : " + this.usuario.nombre_usuario, 475, 740);
    doc.text("fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min, 475, 750);
  }

  crearPagoVenta(venta){
		this.modal_pago_venta = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_pago_venta.componentInstance.venta = venta;
		this.modal_pago_venta.componentInstance.usuario = this.usuario;
		this.modal_pago_venta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.obtenerVentasCredito();
					this.modal_pago_venta.close();
				}
			}else{
				this.modal_pago_venta.close();
			}
			
		});
  }
  
  abrirActualizarFechaCreditos(venta) {
    /*this.venta = venta;
    $scope.abrirPopup($scope.idmodalActualizarCreditoCliente);*/
  }

  imprimirVenta(venta){
    this.pdfService.imprimirVenta(venta);
  }
  
  verVenta(venta){
		this.modal_pago_venta = this.modalService.open(VistaVentaComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-venta', backdrop: 'static'});
		this.modal_pago_venta.componentInstance.venta = venta;
		this.modal_pago_venta.componentInstance.usuario = this.usuario;
		this.modal_pago_venta.componentInstance.alTerminar.subscribe((res) => {
			this.modal_pago_venta.close();
		});
	}

  abrirPopupConfirmacionEnvioWhatsapp(venta){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar el mensaje de recordatorio por Whatsapp?";
    this.popupConfirmacion.componentInstance.data = venta;
    
    this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
      if($e.confirm){
        this.enviarMensajeRecordatorioWhatsapp($e.data);
      }
      this.popupConfirmacion.close();
    });
  }

  async enviarMensajeRecordatorioWhatsapp(venta){
    if(venta.cliente.telefono1){
      this.blockUI.start();
      let datos={
        documentos:[],
        cliente:venta.cliente,
        nombre_corto_clase_mensaje:GlobalVariable.Dictionary.MKT.MEN_ENVIO_RECORDATORIO_PAGO,
        id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id,
        id_empresa:this.usuario.id_empresa
      };
      this.clientesService.enviarMensajeRecordatorioPagoWhatsapp(datos).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
        }
      });
    }else{
      this.toastr.error("Debe agregar un numero de whatsapp para el cliente");
    }
  }

}
