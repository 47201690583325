import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private http: HttpClient) { }

  public guardarEvento(evento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"eventos",evento,GlobalVariable.httpOptions);
  }

  public obtenerEventos(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"eventos/empresa/"+filtro.id_empresa+"/registro-profesor/"+filtro.registro_profesor.id+"/gestion/"+filtro.gestion_actual+"/mes/"+filtro.mes_actual,GlobalVariable.httpOptions);
  }

  public obtenerEvento(evento){
    return this.http.get(GlobalVariable.API_SERVER_URL+"eventos/"+evento.id,GlobalVariable.httpOptions);
  }

  public actualizarEvento(evento){
    return this.http.put(GlobalVariable.API_SERVER_URL+"eventos/"+evento.id,evento,GlobalVariable.httpOptions);
  }

  public eliminarEvento(evento){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"eventos/"+evento.id,GlobalVariable.httpOptions);
  }

}
