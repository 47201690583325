import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ComprasService {

  constructor(private http: HttpClient) { }

  public obtenerListaCompras(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"compras/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/razon-social/"+((paginator.filter.proveedor.razon_social=="" || paginator.filter.proveedor.razon_social==undefined)?0:paginator.filter.proveedor.razon_social)+"/nit/"+(paginator.filter.proveedor.nit==null?0:paginator.filter.proveedor.nit)+"/monto/0/tipo-compra/"+paginator.filter.tipo_pago.id+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.usuario.id+"/tipo-documento/"+paginator.filter.tipo_documento.id+"/con-detalle/"+paginator.filter.con_detalle, GlobalVariable.httpOptions);
  }

  public obtenerListaComprasCreditoNotificacion(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vencimientos-deudas/"+id_empresa, GlobalVariable.httpOptions);
  }

  public obtenerCompra(id_compra){
    return this.http.get(GlobalVariable.API_SERVER_URL+"compras/"+id_compra, GlobalVariable.httpOptions);
  }

  public guardarPagoCompra(id_compra,pago_compra){
    return this.http.put(GlobalVariable.API_SERVER_URL+"compras/"+id_compra,pago_compra, GlobalVariable.httpOptions);
  }  

  public anularCompra(compra){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"compras/"+compra.id,compra, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionImpresion(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-impresion/"+id_sucursal, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionVista(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"empresas/"+id_empresa+"/configuracion-compra-vista", GlobalVariable.httpOptions);
  }

  public verificarDuplicidadCompra(codigo_control){
    return this.http.get(GlobalVariable.API_SERVER_URL+"verificacion-duplicidad-compra/"+codigo_control, GlobalVariable.httpOptions);
  }

  public actualizarConfiguracionVista(id_empresa,dato){
    return this.http.put(GlobalVariable.API_SERVER_URL+"empresas/"+id_empresa+"/configuracion-compra-vista",dato, GlobalVariable.httpOptions);
  }

  public guardarCompra(compra){
    return this.http.post(GlobalVariable.API_SERVER_URL+"compras",compra, GlobalVariable.httpOptions);
  }

  public obtenerListaPagosCompra(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"pagos-compras/empresa/"+paginator.filter.id_empresa+"/compra/"+paginator.filter.id_compra+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public anularPagoCompra(id_pago_compra){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"pago-compra/"+id_pago_compra, GlobalVariable.httpOptions);
  }

  public obtenerPagoCompra(id_pago_compra){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pago-compra/"+id_pago_compra, GlobalVariable.httpOptions);
  }

  public obtenerDatosLibroCompras(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/libro-compras/"+filtro.id_empresa+"/sucursal/"+filtro.sucursal.id+"/gestion/"+filtro.gestion.nombre_corto+"/mes/"+filtro.mes.nombre_corto, GlobalVariable.httpOptions);
  }

  public subirDocumentosCompra(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"compras-documentos",datos, GlobalVariable.httpOptions);
  }

}
