<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Migracion Inscripción</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="migrarInscripcion()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-6">
            <h5>Inscripción Anterior</h5>
            <label>{{migracion_inscripcion.inscripcion_anterior.gestion.nombre}} - {{migracion_inscripcion.inscripcion_anterior.curso.nombre}}</label>
        </div>
        <div class="col-6">
            <h5>Inscripción Nueva</h5>
            <select (change)="obtenerMigracionesInscripcion()" [compareWith]="compararObjectos" name="inscripcion" class="form-control" [(ngModel)]="migracion_inscripcion.inscripcion_nueva">
                <option [ngValue]="inscripcion" *ngFor="let inscripcion of inscripciones_destino">{{inscripcion.gestion.nombre}} - {{inscripcion.curso.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row" *ngFor="let migracion_inscripcion of migraciones_inscripcion">
        <div class="col-3">
            <label>{{migracion_inscripcion.inscripcion_anterior.gestion.nombre}} - {{migracion_inscripcion.inscripcion_anterior.curso.nombre_corto}}</label>
        </div>
        <div class="col-1">
            <i class="fas fa-arrow-circle-right text-125"></i>
        </div>
        <div class="col-3">
            <label>{{migracion_inscripcion.inscripcion_nueva.gestion.nombre}} - {{migracion_inscripcion.inscripcion_nueva.curso.nombre_corto}}</label>
        </div>
        <div class="col-3">
            <label>{{migracion_inscripcion.datos}}</label>
        </div>
        <div class="col-2">
            <input type="checkbox" class="form-control" [(ngModel)]="migracion_inscripcion.migrado" [disabled]="migracion_inscripcion.consolidado">
        </div>
    </div>
    <!--<hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input (blur)="establecerRazonSocial()" name="nombre" required type="text" id="reg_nit" [(ngModel)]="cliente.identificacion" placeholder="identificacion" class="form-control" />
        </div>
    </div>-->
</div>