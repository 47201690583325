<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Empresas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Empresas
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
					  <div class="col-md-2 col-sm-12"><label>Rubro </label></div>
					  <div class="col-md-4 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="rubro" [(ngModel)]="filter.rubro">
							  <option [ngValue]="rubro" *ngFor="let rubro of filter.rubros">{{rubro.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-md-2 col-sm-12"><label>Plan </label></div>
					  <div class="col-md-4 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="plan_filtro" [(ngModel)]="filter.plan">
							  <option [ngValue]="plan" *ngFor="let plan of filter.planes">{{plan.nombre}}</option>
						  </select>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="!usuario.id_empresa" title="Nuevo" (click)="crearNuevaEmpresa()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
        <button *ngIf="!usuario.id_empresa" title="Descarga Empresa" (click)="abrirDescargaEmpresa()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1 bs-print" type="button">
          <i class="fa fa-download text-success-d1"></i>
        </button>
			  <div class="keep-open btn-group show" title="Columns" *ngIf="usuario.id_empresa">
          <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
            <i class="fa fa-wrench text-blue-d1"></i>
            <span class="caret"></span>
          </button>

          <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
            <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                <table class="table table-striped table-hover mb-0">
                  <tbody>
                    <tr *ngIf="usuario.empresa.usar_ventas_app_offline">
                      <td class="text-dark-m2">
                      Descarga datos App
                      </td>
                      <td class="text-dark-m2">
                        <a (click)="descargarDatosEmpresaApp()" href="javascript:void(0)" class="text-blue"><i class="fa fa-file text-primary"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
              </div>
          </div>
          <!--<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>-->
        </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="empresas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="center">
                    Nº
                </th>
                <th class="hidden-480">Imagen</th>
                <th>Código</th>
                <th>Nombre</th>
                <th>Rubro</th>
                <th>Plan</th>
                <th>
                    Dirección
                </th>
                <th>Telefono 1</th>
                <th>Departamento</th>
                <th>Activo</th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let empresa of empresas; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
				<td><img src="{{global_variable.SERVER_URL+empresa.imagen}}" width="50"></td>
                <td>{{empresa.codigo}}</td>
                <td>{{empresa.nombre}}</td>
                <td>{{empresa.rubro}}</td>
                <td>{{empresa.plan}}</td>
                <td>{{empresa.direccion}}</td>
                <td>{{empresa.telefono1}}</td>
                <td>{{empresa.departamento}}</td>
                <td *ngIf="empresa.activo"><span class="badge badge-sm badge-success">SI</span></td>
                <td *ngIf="!empresa.activo"><span class="badge badge-sm badge-danger">NO</span></td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a title="Modificar" [disabled]="!aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEmpresa(empresa)">
                      <i class="ace-icon fa fa-edit bigger-130"></i>
                    </a>
                    <a *ngIf="usuario.empresa && usuario.empresa.usar_estudiantes_colegio" title="Datos Gestión" [disabled]="!aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirConfiguracionGestiones(empresa)">
                      <i class="ace-icon fas fa-tools bigger-130"></i>
                    </a>
                    <a *ngIf="usuario.empresa && usuario.empresa.usar_facturacion_computarizada_en_linea" title="Datos Facturación" [disabled]="!aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirDatosFacturacion(empresa)">
                      <i class="ace-icon fas fa-file-invoice-dollar bigger-130"></i>
                    </a>
                    <a title="Inactivar" *ngIf="usuario.id_empresa==null && empresa.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionInactivacion(empresa)">
                        <i class="ace-icon fa fa-lock bigger-130"></i>
                    </a>
                    <a title="Activar" *ngIf="usuario.id_empresa==null && !empresa.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionActivacion(empresa)">
                        <i class="ace-icon fa fa-unlock bigger-130"></i>
                    </a>
                    <a title="Reiniciar Datos" *ngIf="usuario.id_empresa==null" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirModalEliminacionDatos(empresa)">
                      <i class="ace-icon fa fa-undo bigger-130"></i>
                    </a>
                    <a title="Agregar Profesor" *ngIf="usuario.id_empresa==null && empresa.plan && empresa.plan.includes('PLAN COLEGIO PROFESOR')" (click)="crearNuevoUsuario(empresa)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                      <i class="ace-icon fa fa-user-plus bigger-130"></i>
                    </a>
                    <a title="Gestionar Roles" *ngIf="empresa.plan && (empresa.plan.includes('PLAN COLEGIO') || empresa.plan.includes('PLAN INSTITUTO'))" (click)="verRoles(empresa)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                      <i class="ace-icon fa fa-user-tag bigger-130"></i>
                    </a>

                    <a title="Certificar Empresa" *ngIf="usuario.id_empresa==null" (click)="certificarEmpresa(empresa)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                      <i class="ace-icon fas fa-stamp bigger-130"></i>
                    </a>
                    
                    <!--
                        
									<a class="blue" href="javascript:void(0)" e-disabled="!aplicacion.puede_ver" ng-click="verEmpresa(empresa)">
										<i class="ace-icon fa fa-search-plus bigger-130"></i>
                  </a>
									<a ng-if="usuario.id_empresa==null" class="red" href="javascript:void(0)" e-disabled="!aplicacion.puede_eliminar" ng-click="abrirModalEliminacionDatos(empresa)">
										<i class="ace-icon fa fa-undo bigger-130"></i>
									</a>
	
									<a class="red" href="javascript:void(0)" e-disabled="!aplicacion.puede_eliminar" ng-click="mostrarConfirmacionEliminacion(empresa)">
										<i class="ace-icon fa fa-trash-o bigger-130"></i>
									</a>
                    -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="empresas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modalEdicionEmpresa let-modal>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <span class="text-blue text-125">Modificación Datos Empresa</span>

          <div class="card-toolbar ml-auto no-border pr-2 d-none">
            <label>
              <span class="align-middle d-block d-sm-inline">Validation:</span>
              <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
            </label>
          </div>
          <div class="card-toolbar pl-3">
            <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
              <i class="fa fa-chevron-right"></i>
            </button>
            <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
              <i class="fa fa-arrow-right"></i>
            </button>
            <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                <i class="fas fa-times-circle"></i>
              </button>
          </div>
        </div>

        <div class="card-body px-2">
          <div id="{{wizard_edicion_empresa}}" class="d-none">
            <ul class="mx-auto">
              <li class="wizard-progressbar"></li>
              <li>
                <a href="#step-1">
                  <span class="step-title">
                      1
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos de la Empresa
                </span>
              </li>
              <li>
                <a href="#step-2">
                  <span class="step-title">
                      2
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos Adicionales
                </span>
              </li>
            </ul>

            <div class="px-2 py-2 mb-4">
              <div id="step-1" class="">

                <form novalidate>
                    <div class="  form-row">
                        <div *ngIf="!usuario.id_empresa" class="col-sm-2 col-form-label text-sm-right text-grey">
                                Código 
                        </div>
                        <div *ngIf="!usuario.id_empresa" class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" name="codigo" id="codigo" [(ngModel)]="empresa.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                              <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                            </div>
  
                            <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                        </div>
                        <div [ngClass]="{'text-danger-m1':(!empresa.nombre)}" class="col-sm-2 col-form-label text-sm-right">
                            Nombre
                        </div>

                        <div class="col-sm-6">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <input required type="text" id="nombre" name="nombre" [(ngModel)]="empresa.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!empresa.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!empresa.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!empresa.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!empresa.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                      </div>
                      <div class="  form-row">
                      <div [ngClass]="{'text-danger-m1':(!empresa.razon_social)}" class="col-sm-2 col-form-label text-sm-right">
                          Razón Social
                      </div>

                      <div class="col-sm-4">
                          <div class="d-inline-flex align-items-center col-12 col-sm-8 px-0">
                              <input required type="text" id="razon_social" name="razon_social" [(ngModel)]="empresa.razon_social" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!empresa.razon_social)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              <i *ngIf="!empresa.razon_social" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!empresa.razon_social" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>

                          <span *ngIf="!empresa.razon_social" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>
                      <div [ngClass]="{'text-danger-m1':(!empresa.razon_social)}" class="col-sm-2 col-form-label text-sm-right">
                        Nit
                    </div>

                    <div class="col-sm-4">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <input required type="text" id="nit" name="nit" [(ngModel)]="empresa.nit" placeholder="Ni" [ngClass]="{'brc-danger-m1':(!empresa.nit)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            <i *ngIf="!empresa.nit" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                        </div>
                        <div *ngIf="!empresa.nit" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                            <div class="arrow brc-danger-m1 mt-0"></div>
                            <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                        </div>

                        <span *ngIf="!empresa.nit" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                    </div>
                    </div>
                    <div class="  form-row">
                        <div class="col-sm-3 col-form-label text-sm-right">
                            Dirección
                        </div>
                        <div class="col-sm-9">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input required type="text" id="direccion" name="direccion" [(ngModel)]="empresa.direccion" placeholder="Dirección" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <div class="col-sm-3 col-form-label text-sm-right">
                          Teléfono 1
                      </div>
                      <div class="col-sm-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" id="telefono1" name="telefono1" [(ngModel)]="empresa.telefono1" placeholder="Teléfono" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>
                      
                      <div class="col-sm-3 col-form-label text-sm-right">
                          Teléfono 2
                      </div>
                      <div class="col-sm-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" id="telefono2" name="telefono2" [(ngModel)]="empresa.telefono2" placeholder="Teléfono" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>

                      <div class="col-sm-3 col-form-label text-sm-right">
                        Teléfono 3
                      </div>
                      <div class="col-sm-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" id="telefono3" name="telefono3" [(ngModel)]="empresa.telefono3" placeholder="Teléfono" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>

                      <div *ngIf="!usuario.id_empresa" class="col-sm-3 col-form-label text-sm-right">
                        Url Acceso
                      </div>
                      <div *ngIf="!usuario.id_empresa" class="col-sm-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" id="url_acceso" name="url_acceso" [(ngModel)]="empresa.url_acceso" placeholder="Url Acceso" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>
                      <div class="col-sm-3 col-form-label text-sm-right">
                        Información
                      </div>
                      <div class="col-sm-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" id="informacion" name="informacion" [(ngModel)]="empresa.informacion" placeholder="Información" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>

                      <div *ngIf="!usuario.id_empresa" class="col-sm-1 col-form-label text-sm-right">¿Sugerido?</div>
                      <div *ngIf="!usuario.id_empresa" class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empresa.sugerido"/>
                              </label>
                          </div>
                      </div>
                      <div *ngIf="!usuario.id_empresa" class="col-sm-1 col-form-label text-sm-right">¿En Línea?</div>
                      <div *ngIf="!usuario.id_empresa" class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empresa.en_linea"/>
                              </label>
                          </div>
                      </div>
                    </div>
                    <div class="  form-row">
                      <div class="col-1 col-form-label text-sm-right">
                          Imágen/Logo
                      </div>
                      <div class="col-2" *ngIf="empresa.id">
                          <img  src="{{rest_server+empresa.imagen}}" width="100">
                      </div>
                      <div class="col-3">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <div>
                                  <input type="file" class="ace-file-input" id="id_logo" multiple autocomplete="off" (change)="subirImagen($event)" />
                              </div>
                          </div>
                      </div>
                      <div class="col-1 col-form-label text-sm-right">
                        Imágen Portada
                      </div>
                      <div class="col-2" *ngIf="empresa.id">
                        <img  src="{{rest_server+empresa.imagen_portada}}" width="100">
                      </div>
                      <div class="col-3">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <div>
                                <input type="file" class="ace-file-input" id="id_portada" multiple autocomplete="off" (change)="subirImagenPortada($event)" />
                            </div>
                        </div>
                      </div>
                   </div>
                </form>

              </div>

              <div id="step-2">
                <div class="  form-row">
                    <div class="col-sm-4 col-form-label text-sm-right">
                        Departamento
                    </div>
                    <div class="col-sm-6">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select [compareWith]="compararObjectos" class="form-control" required name="departamento" [(ngModel)]="empresa.departamento">
                                <option [ngValue]="departamento" *ngFor="let departamento of tipoDepartamentos.clases">{{departamento.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!usuario.id_empresa" class="col-sm-4 col-form-label text-sm-right">
                        Rubro
                    </div>
                    <div *ngIf="!usuario.id_empresa" class="col-sm-6">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select [compareWith]="compararObjectos" class="form-control" required name="rubro" [(ngModel)]="empresa.rubro" (change)="buscarPlanes(empresa.rubro)">
                                <option [ngValue]="rubro" *ngFor="let rubro of rubros">{{rubro.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!usuario.id_empresa" class="col-sm-4 col-form-label text-sm-right">
                        Paquete
                    </div>
                    <div *ngIf="!usuario.id_empresa" class="col-sm-6">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select [compareWith]="compararObjectos" class="form-control" required name="plan" [(ngModel)]="empresa.plan_snapquick" (change)="buscarModulos(empresa.plan_snapquick)">
                                <option [ngValue]="plan" *ngFor="let plan of planes">{{plan.titulo}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="!usuario.id_empresa" class="  form-row">
                  <table id="tabla-modulos" class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Nº</th>
                        <th>Modulo</th>
                        <th>¿Habilitado?</th>
                        <th>Sub Modulos</th>
                      </tr>
                    </thead>
    
                    <tbody>
                      <tr *ngFor="let modulo_empresa of empresa.modulos; let i=index;">
                        <td class="center">
                          {{i+1}}
                        </td>
    
                        <td>
                          {{modulo_empresa.modulo.titulo}}
                        </td>
                        <td>
                          <label>
                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="modulo_empresa.habilitado"/>
                          </label>
                        </td>
                        <td>
                          <table id="tabla-submodulos" *ngIf="modulo_empresa.hijos && modulo_empresa.hijos.length>0" class="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>SubModulo</th>
                                <th>Descripción</th>
                                <th>¿Habilitado?</th>
                              </tr>
                            </thead>
            
                            <tbody>
                              <tr *ngFor="let modulo_hijo of modulo_empresa.hijos; let j=index;">
                                <td class="center">
                                  {{j+1}}
                                </td>
            
                                <td>
                                  {{modulo_hijo.modulo.titulo}}
                                </td>
                                <td>
                                  {{modulo_hijo.modulo.descripcion}}
                                </td>
                                <td>
                                  <label>
                                    <input [disabled]="!modulo_empresa.habilitado" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="modulo_hijo.habilitado"/>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> <!-- .card -->

    </div>
  </div>
</ng-template>

<ng-template #modalEliminacionDatos let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Selección eliminación datos</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-primary" type="button" (click)="abrirPopupConfirmacionEliminacion()">
				<i class="fa fa-trash align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-3">
          <label>¿Ventas?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="ventas" [(ngModel)]="datos_eliminacion.ventas" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Compras?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="compras" [(ngModel)]="datos_eliminacion.compras" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Pedidos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="pedidos" [(ngModel)]="datos_eliminacion.pedidos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Inventario?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="inventario" [(ngModel)]="datos_eliminacion.inventario" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Productos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="productos" [(ngModel)]="datos_eliminacion.productos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Clientes?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="clientes" [(ngModel)]="datos_eliminacion.clientes" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Proveeedores?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="proveedores" [(ngModel)]="datos_eliminacion.proveedores" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Reiniciar correlativos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="reiniciar_correlativos" [(ngModel)]="datos_eliminacion.reiniciar_correlativos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
	</div>
</ng-template>

<ng-template #descargaDatos let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Selección descarga datos app</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-primary" type="button" (click)="descargaArchivoApp()">
				<i class="fa fa-download align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-3">
          <label>¿Conceptos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="conceptos" [(ngModel)]="descarga_app.tipos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Productos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="productos" [(ngModel)]="descarga_app.productos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Clientes?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="clientes" [(ngModel)]="descarga_app.clientes" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Cuentas Clientes?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="cuentas_clientes" [(ngModel)]="descarga_app.cuentas_clientes" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Usuarios y precios?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="usuarios_precios" [(ngModel)]="descarga_app.usuarios_precios" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <ng-container *ngIf="usuario.empresa.usar_cobros_caja_colegio">
      <hr>
      <div class="row">
        <div class="col-3">
            <label>¿Estudiantes?</label>
        </div>
        <div class="col-3">
          <label>
            <input name="estudiantes" [(ngModel)]="descarga_app.estudiantes" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
          </label>
        </div>
        <div class="col-3">
          <label>¿Fichas Economicas?</label>
        </div>
        <div class="col-3">
          <label>
            <input name="estudiantes" [(ngModel)]="descarga_app.fichas_economicas" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
          </label>
        </div>
      </div>
    </ng-container>
    <hr>
	</div>
</ng-template>

<ng-template #modalCreacionEmpresa let-modal>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <span class="text-blue text-125">Registro Empresa</span>

          <div class="card-toolbar ml-auto no-border pr-2 d-none">
            <label>
              <span class="align-middle d-block d-sm-inline">Validation:</span>
              <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
            </label>
          </div>
          <div class="card-toolbar pl-3">
            <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
              <i class="fa fa-chevron-right"></i>
            </button>
            <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
              <i class="fa fa-arrow-right"></i>
            </button>
            <button (click)="cerrarRegistroEmprea()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                <i class="fas fa-times-circle"></i>
              </button>
          </div>
        </div>

        <div class="card-body px-2">
          <div id="{{wizard_creacion_empresa}}" class="d-none">
            <ul class="mx-auto">
              <li class="wizard-progressbar"></li>
              <li>
                <a href="#step-1">
                  <span class="step-title">
                      1
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos de la Empresa
                </span>
              </li>
              <li>
                <a href="#step-2">
                  <span class="step-title">
                      2
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos Adicionales
                </span>
              </li>
              <li>
                <a href="#step-3">
                  <span class="step-title">
                      3
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-title">
                        Datos de Ubicación
                </span>
              </li>
              <li>
                <a href="#step-4">
                  <span class="step-title">
                      4
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-title">
                        Datos de Usuario
                </span>
              </li>
            </ul>

            <div class="px-2 py-2 mb-4">
              <div id="step-1" class="">

                <form novalidate>
                    <div class="  form-row">
                        <div class="col-sm-2 col-form-label text-sm-right text-grey">
                                Código
                        </div>
                        <div class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input required type="text" name="codigo" id="codigo" [(ngModel)]="empresa.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                              <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                            </div>
  
                            <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                        </div>
                        <div [ngClass]="{'text-danger-m1':(!empresa.nombre)}" class="col-sm-2 col-form-label text-sm-right">
                            Nombre
                        </div>

                        <div class="col-sm-6">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <input required type="text" id="nombre" name="nombre" [(ngModel)]="empresa.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!empresa.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!empresa.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!empresa.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!empresa.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                    <div class="  form-row">
                      <div class="col-sm-2 col-form-label text-sm-right" [ngClass]="{'text-danger-m1':(!empresa.nit)}">
                              Nit
                      </div>
                      <div class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input required type="text" name="nit" id="nit" [(ngModel)]="empresa.nit" placeholder="Nit" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                          </div>
                          <div *ngIf="!empresa.nit" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>

                          <span *ngIf="!empresa.nit" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>
                      <div [ngClass]="{'text-danger-m1':(!empresa.razon_social)}" class="col-sm-2 col-form-label text-sm-right">
                          Razón Social
                      </div>

                      <div class="col-sm-6">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input required type="text" id="razon_social" name="razon_social" [(ngModel)]="empresa.razon_social" placeholder="Razón Social" [ngClass]="{'brc-danger-m1':(!empresa.razon_social)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              <i *ngIf="!empresa.razon_social" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!empresa.razon_social" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>

                          <span *ngIf="!empresa.razon_social" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>
                    </div>
                    <div class="  form-row">
                      <div class="col-sm-2 col-form-label text-sm-right text-grey">
                              Dirección
                      </div>
                      <div class="col-sm-4">
                          <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" name="direccion" id="direccion" [(ngModel)]="empresa.direccion" placeholder="Dirección" class="form-control form-control col-12 text-dark-m2 pr-45 pl-2"  />
                          </div>
                      </div>
                      <div class="col-sm-2 col-form-label text-sm-right">
                          Teléfono Whatsapp
                      </div>

                      <div class="col-sm-4">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input type="text" id="telefono1" name="telefono1" [(ngModel)]="empresa.telefono1" placeholder="Teléfono Whatsapp" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>
                    </div>
                    <div class="  form-row">
                        <div class="col-2 col-form-label text-sm-right">
                            Imágen/Logo
                        </div>
                        <div class="col-4" *ngIf="empresa.id">
                            <img  src="{{rest_server+empresa.imagen}}" width="100">
                        </div>
                        <div class="col-4">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <div>
                                    <input type="file" class="ace-file-input" id="id_logo" multiple autocomplete="off" (change)="subirImagen($event)" />
                                </div>
                            </div>
                        </div>
                        <div class="col-2 col-form-label text-sm-right">
                          Imágen Portada
                      </div>
                      <div class="col-4" *ngIf="empresa.id">
                          <img  src="{{rest_server+empresa.imagen_portada}}" width="100">
                      </div>
                      <div class="col-4">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <div>
                                  <input type="file" class="ace-file-input" id="id_portada" multiple autocomplete="off" (change)="subirImagenPortada($event)" />
                              </div>
                          </div>
                      </div>
                    </div>
                </form>

              </div>

              <div id="step-2">
                <div class="  form-row">
                  <ng-container *ngIf="!usuario.empresa">
                    <div class="col-sm-1 col-form-label text-sm-right">Url Servidor</div>
                    <div class="col-sm-3">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" id="url_servidor" name="url_servidor" [(ngModel)]="empresa.url_servidor" placeholder="Url Servidor" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        </div>
                    </div>
                  </ng-container>
                    <div class="col-sm-1 col-form-label text-sm-right">Url Acceso</div>
                    <div class="col-sm-3">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <input type="text" id="url_acceso" name="url_acceso" [(ngModel)]="empresa.url_acceso" placeholder="Url Acceso" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                        </div>
                    </div>
                    <div class="col-sm-1 col-form-label text-sm-right">
                        Rubro
                    </div>
                    <div class="col-sm-3">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select (change)="buscarPlanes(empresa.rubro)" [compareWith]="compararObjectos" class="form-control" required name="rubro" [(ngModel)]="empresa.rubro">
                                <option [ngValue]="rubro" *ngFor="let rubro of rubros">{{rubro.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-1 col-form-label text-sm-right">
                      Paquete
                  </div>
                  <div class="col-sm-3">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <select (change)="buscarModulos(empresa.plan_snapquick)" [compareWith]="compararObjectos" class="form-control" required name="plan_snapquick_c" [(ngModel)]="empresa.plan_snapquick">
                              <option [ngValue]="plan" *ngFor="let plan of planes">{{plan.titulo}}</option>
                          </select>
                      </div>
                  </div>
                </div>

                <div class="  form-row" *ngIf="empresa.rubro && (empresa.rubro.nombre.includes('COLEGIO') || empresa.rubro.nombre.includes('INSTITUTO'))">
                  <div class="col-sm-1 col-form-label text-sm-right">Nombre U.E.</div>
                  <div class="col-sm-3">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <input required type="text" id="nombre_escuela" name="nombre_escuela" [(ngModel)]="empresa.sucursal.escuela.nombre" placeholder="Nombre" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                      </div>
                  </div>
                  <div class="col-sm-1 col-form-label text-sm-right">Código S.I.E.</div>
                  <div class="col-sm-3">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <input type="text" id="codigo_sie" name="codigo_sie" [(ngModel)]="empresa.sucursal.escuela.codigo_sie" placeholder="Codigo S.I.E." class="form-control form-control-lg col-12 pr-45 pl-25"  />
                      </div>
                  </div>
                  <div class="col-sm-1 col-form-label text-sm-right">Número de distrito</div>
                  <div class="col-sm-3">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <input type="text" id="numero_distrito" name="numero_distrito" [(ngModel)]="empresa.sucursal.escuela.numero_distrito_educacional" placeholder="Número de Distrito" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                      </div>
                  </div>
                </div>
                <div class="  form-row" *ngIf="empresa.rubro && (empresa.rubro.nombre.includes('COLEGIO') || empresa.rubro.nombre.includes('INSTITUTO'))">
                  <div class="col-sm-2 col-form-label text-sm-right">Literal distrito</div>
                  <div class="col-sm-4">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <input type="text" id="literal_distrito" name="literal_distrito" [(ngModel)]="empresa.sucursal.escuela.literal_distrito_educacional" placeholder="Literal de Distrito" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                      </div>
                  </div>
                  <div class="col-sm-2 col-form-label text-sm-right">
                      Dependencia
                  </div>
                  <div class="col-sm-4">
                      <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <select [compareWith]="compararObjectos" class="form-control" name="dependencia" [(ngModel)]="empresa.sucursal.escuela.dependencia">
                              <option [ngValue]="dependencia" *ngFor="let dependencia of tipo_dependencias.clases">{{dependencia.nombre}}</option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="  form-row" *ngIf="empresa.rubro && (empresa.rubro.nombre.includes('COLEGIO') || empresa.rubro.nombre.includes('INSTITUTO'))">
                <div class="col-sm-2 col-form-label text-sm-right">
                  Turno
                </div>
                <div class="col-sm-4">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" name="turno" [(ngModel)]="empresa.sucursal.escuela.turno">
                            <option [ngValue]="turno" *ngFor="let turno of tipo_turnos.clases">{{turno.nombre}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-2 col-form-label text-sm-right">
                  Tipo de Cálculo de Calificación
                </div>
                <div class="col-sm-4">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <select [compareWith]="compararObjectos" class="form-control" name="tipo_calculo_calificacion" [(ngModel)]="empresa.sucursal.escuela.tipo_calculo_calificacion">
                            <option [ngValue]="tipo_calculo_calificacion" *ngFor="let tipo_calculo_calificacion of tipo_calificacion_notas.clases">{{tipo_calculo_calificacion.nombre}}</option>
                        </select>
                    </div>
                </div>
              </div>

                <div class="  form-row">
                  <table id="tabla-modulos" class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Nº</th>
                        <th>Modulo</th>
                        <th>¿Habilitado?</th>
                        <th>Sub Modulos</th>
                      </tr>
                    </thead>
    
                    <tbody>
                      <tr *ngFor="let modulo_empresa of empresa.modulos; let i=index;">
                        <td class="center">
                          {{i+1}}
                        </td>
                        <td>
                          {{modulo_empresa.modulo.titulo}}
                        </td>
                        <td>
                          <label>
                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="modulo_empresa.habilitado"/>
                          </label>
                        </td>
                        <td>
                          <table id="tabla-submodulos" *ngIf="modulo_empresa.hijos && modulo_empresa.hijos.length>0" class="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Nº</th>
                                <th>Sub Modulo</th>
                                <th>Descripción</th>
                                <th>¿Habilitado?</th>
                              </tr>
                            </thead>
            
                            <tbody>
                              <tr *ngFor="let modulo_hijo of modulo_empresa.hijos; let j=index;">
                                <td class="center">
                                  {{j+1}}
                                </td>
            
                                <td>
                                  {{modulo_hijo.modulo.titulo}}
                                </td>
                                <td>
                                  {{modulo_hijo.modulo.descripcion}}
                                </td>
                                <td>
                                  <label>
                                    <input [disabled]="!modulo_empresa.habilitado" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="modulo_hijo.habilitado"/>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div id="step-3" >
                <div class="  form-row">
                    <div class="col-sm-1 col-form-label text-sm-right">¿Delivery Propio Activado?</div>
                    <div class="col-sm-1">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                          <label>
                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empresa.sucursal.delivery_propio_activado"/>
                          </label>
                        </div>
                    </div>
                    <div class="col-sm-1 col-form-label text-sm-right">
                        Departamento
                    </div>
                    <div class="col-sm-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                            <select [compareWith]="compararObjectos" class="form-control" required name="rubro" [(ngModel)]="empresa.departamento">
                                <option [ngValue]="departamento" *ngFor="let departamento of tipoDepartamentos.clases">{{departamento.nombre}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-1 col-form-label text-sm-right">
                      Ubicación
                    </div>
                    <div class="col-sm-6">
                      <google-map *ngIf="conexion_google_map" #mapa 
                          width="100%"
                          height="300px"
                          [center]="center"
                          [zoom]="zoom"
                          [options]="options">
                          <map-marker
                              #markerElem
                              *ngFor="let marker of markers"
                              [position]="marker.position"
                              [label]="marker.label"
                              [title]="marker.title"
                              [options]="marker.options"
                              (mapDragend)="arrastarPinMapa($event)"
                              >
                          </map-marker>
                      </google-map>
                    </div>
                </div>
              </div>
              <div id="step-4" >
                <div class="  form-row">
                  <label class="col-sm-2 control-label no-padding-right" for="nombre_completo">Nombre Completo</label>
    
                  <div class="col-sm-4">
                    <input type="text" id="nombre_completo" [(ngModel)]="empresa.persona.nombre_completo" placeholder="Nombre Completo" class="form-control" />
                  </div>
                  <label class="col-sm-2 control-label no-padding-right" for="correo_electronico">Correo Electrónico</label>
    
                  <div class="col-sm-4">
                    <input type="email" id="correo_electronico" [(ngModel)]="empresa.persona.correo_electronico" placeholder="Correo Electrónico" class="form-control" />
                  </div>
                </div>
                <div class="  form-row">
                  <label class="col-sm-2 control-label no-padding-right" for="nombre_usuario">Usuario</label>
    
                  <div class="col-sm-4">
                    <input disabled type="email" id="nombre_usuario" [(ngModel)]="empresa.persona.usuario.nombre_usuario" placeholder="Usuario" class="form-control" [value]="empresa.persona.correo_electronico" />
                  </div>
                  <label class="col-sm-2 control-label no-padding-right" for="clave">Clave</label>
    
                  <div class="col-sm-4">
                    <input type="password" id="clave" [(ngModel)]="empresa.persona.usuario.clave" placeholder="Clave" class="form-control" />
                  </div>
                </div>
                <div class="  form-row">
                  <label class="col-sm-3 control-label no-padding-right" for="clave">¿Enviar correo?</label>
    
                  <div class="col-sm-9">
                    <label>
                      <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empresa.persona.usuario.enviar_correo"/>
                    </label>
                  </div>
                </div>	
              </div>
            </div>
          </div>

        </div>
      </div> <!-- .card -->

    </div>
  </div>
</ng-template>

<ng-template #vistaRoles let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">{{empresa.nombre}} : Gestión de Roles</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-primary" type="button" (click)="crearNuevoRol()">
				<i class="fa fa-plus align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Rol</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rol of roles; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{rol.nombre}}</td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarRol(rol)">          
                            <i class="fa fa-pencil-alt text-105"></i>        
                          </a>                                  
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
	</div>
</ng-template>

<ng-template #registroRol let-modal>
	<div class="modal-header">
		<div class="col-md-7 col-xs-7">
			<h4 class="widget-title">Registro Rol</h4>
		</div>
		<div class="col-md-5 col-xs-5">
      <button *ngIf="rol.id" class="btn btn-primary" type="button" (click)="actualizarAplicacionesRol()">
				<i class="fa fa-sync align-top bigger-110"></i>
			</button>&nbsp;
      <button class="btn btn-primary" type="button" (click)="guardarRol()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
			<div class="col-12">
				<label for="vecino">Nombre</label>
				<input type="text" class="form-control" [(ngModel)]="rol.nombre" placeholder="Nombre"/>
			</div>
    </div>
    <div class="row">
			<div class="col-12">
				<table id="tabla-aplicaciones" class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Aplicación</th>
              <th>Descripción</th>
              <th>¿Habilitado?</th>
              <th>
                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                <div class="fht-cell"></div>
              </th>
            </tr>
          </thead>
    
          <tbody>
            <ng-container *ngFor="let rol_aplicacion of rol.aplicacionesRol; let i=index;">
              <tr *ngIf="!rol_aplicacion.eliminado">
                <td class="center">
                  {{i+1}}
                </td>
      
                <td>
                  {{rol_aplicacion.aplicacion.titulo}}
                </td>
                <td>
                  {{rol_aplicacion.aplicacion.descripcion}}
                </td>
                <td>
                  <label>
                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="rol_aplicacion.habilitado"/>
                  </label>
                </td>
                <td>
                  <a (click)="eliminarAplicacion(rol_aplicacion)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                    <i class="fa fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
			</div>
    </div>
	</div>
</ng-template>

<ng-template #descargaempresa let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Descarga de Empresa</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-success" type="button" (click)="descargarEmpresa()">
				<i class="fa fa-download align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
			<div class="col-12">
				<label for="codigo">Código de Empresa</label>
				<input name="codigo" type="text" class="form-control" [(ngModel)]="empresa.codigo" placeholder="Codigo de empresa"/>
			</div>
    </div>
	</div>
</ng-template>

<ng-template #configuracionGestiones let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Configuraciones</h4>
		</div>
		<div class="col-md-3 col-xs-3">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <table class="table table-bordered table-striped text-center">
      <thead>
        <tr>
        <th>Nº</th>
        <th>Gestión</th>
        <th>
          <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
          <div class="fht-cell"></div>
        </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let gestion of tipo_gestiones.clases; let i=index;">
        <td>{{i+1}}</td>
        <td>{{gestion.nombre}}</td>
        <td>
          <a (click)="modificarRegistroConfiguracionNotas(gestion)" title="Configuración Notas" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
            <i class="ace-icon fas fa-star text-180"></i>
          </a>
          <a (click)="modificarRegistroConfiguracionPeriodosEscolares(gestion)" title="Configuración Periodos Escolares" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
            <i class="ace-icon far fa-clock text-180"></i>
          </a>
          <a (click)="modificarRegistroConfiguracionCalificacionesRangos(gestion)" title="Configuración Rangos literal calificaciones" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
            <i class="ace-icon fas fa-transgender text-180"></i>
          </a>
        </td>
        </tr>
      </tbody>
    </table>
	</div>
</ng-template>

<ng-template #configuracionCalificaciones let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Configuración de calificaciones Gestión : {{gestion.nombre}}</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-primary" type="button" (click)="guardarConfiguracionCalificaciones()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-3">
        <label >{{global_variable.Dictionary.SCH.TEXTO_PERIODOS_ANUAL}}</label>
      </div>
      <div class="col-3">
        <label >Dimensión</label>
      </div>
      <div class="col-2">
        <label >Número de casillas</label>
      </div>
      <div class="col-2">
        <label >Orden</label>
      </div>
      <div class="col-2">
        <label >¿Habilitar modificación?</label>
      </div>
    </div>
    <div class="row" *ngFor="let configuracion_calificacion of configuraciones_calificaciones;">
      <div class="col-3">
        <label for="ser">{{configuracion_calificacion.intervalo_escolar.nombre}}</label>
      </div>
      <div class="col-3">
        <label for="ser">{{configuracion_calificacion.dimension.nombre}}</label>
      </div>
      <div class="col-2">
        <label>
          <select id="{{configuracion_calificacion.dimension.id}}" name="{{configuracion_calificacion.dimension.nombre}}" class="form-control" [(ngModel)]="configuracion_calificacion.configuracion.numero_casillas">
            <option [ngValue]="data" *ngFor="let data of [1,2,3,4,5,6,7,8,9,10]">{{data}}</option>
          </select>
        </label>
      </div>
      <div class="col-2">
        <input disabled required min="1" ng-max="configuraciones_calificaciones.length" type="number" class="form-control" [(ngModel)]="configuracion_calificacion.configuracion.orden">
      </div>
      <div class="col-2">
        <label>
          <input name="{{configuraciones_calificaciones.id}}-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracion_calificacion.configuracion.casilla_habilitada"/>
        </label>
      </div>
    </div>
	</div>
</ng-template>