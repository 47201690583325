import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { OrderPipe } from 'ngx-order-pipe';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { InventariosService } from '../../services/inventarios/inventarios.service';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-movimiento-producto',
  templateUrl: './movimiento-producto.component.html',
  styleUrls: ['./movimiento-producto.component.css']
})
export class MovimientoProductoComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  producto:any;
  movimientos:any[]=[];
  Math:any=Math;
  resumen:any={
	sumatoria_movimientos_ingresos:0,
	sumatoria_movimientos_salidas:0,
	sumatoria_compras:0,
	sumatoria_ventas:0,
	sumatoria_ventas_combos:0,
	sumatoria_devoluciones_ventas:0,
	sumatoria_devoluciones_ventas_combos:0
  }

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public productosService: ProductosService,
    public pdfService:PdfService,
    private toastr: ToastrService,
	public usuarioService:UsuarioService,
	public inventariosService:InventariosService,
	private orderPipe: OrderPipe) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:Util.obtenerSucursalesUsuario(this.usuario),
      sucursal: Util.obtenerSucursalesUsuario(this.usuario)[0],
      almacenes:[],
      almacen:null,
	  considerar_saldo_anterior:true,
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      lote:null
    }

	this.obtenerAlmacenes();
  }

  obtenerAlmacenes(){
    this.filter.almacenes=this.filter.sucursal.almacenes;	
	this.filter.almacen=this.filter.almacenes.length>0?this.filter.almacenes[0]:null;
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

	buscarKardexProductoV2(){
		this.blockUI.start();
		let fecha_inicial:any=(this.filter.fecha_inicio=="" || this.filter.fecha_inicio==undefined)?0:new Date(this.filter.fecha_inicio.year,this.filter.fecha_inicio.month-1,this.filter.fecha_inicio.day,0,0);
		let fecha_fin=(this.filter.fecha_fin=="" || this.filter.fecha_fin==undefined)?0:new Date(this.filter.fecha_fin.year,this.filter.fecha_fin.month-1,this.filter.fecha_fin.day,0,0);

		if(fecha_inicial!=0){
			let fecha_anterior=new Date(fecha_inicial);fecha_anterior.setDate(fecha_inicial.getDate()-1);
			this.productosService.obtenerKardexMovimientosProducto(this.filter,this.producto.id,0,fecha_anterior).subscribe( (movimientos_saldo:any) =>{
				movimientos_saldo=this.orderPipe.transform(movimientos_saldo, 'fecha',false);
				this.filter.movimiento_saldo=this.obtenerSaldoV2(movimientos_saldo);
				this.productosService.obtenerKardexMovimientosProducto(this.filter,this.producto.id,fecha_inicial,fecha_fin).subscribe( (movimientos:any) =>{
					movimientos=this.orderPipe.transform(movimientos, 'fecha',false);
					movimientos.unshift(this.filter.movimiento_saldo);
					movimientos=this.generarSaldos(movimientos);
					this.movimientos=movimientos;
					this.blockUI.stop()
				});
			});
		}else{
			this.productosService.obtenerKardexMovimientosProducto(this.filter,this.producto.id,fecha_inicial,fecha_fin).subscribe( (movimientos:any) =>{
				movimientos=this.orderPipe.transform(movimientos, 'fecha',false);
				if(movimientos.length>0){
					movimientos[0].saldo=movimientos[0].ingreso-movimientos[0].salida;
				}
				movimientos=this.generarSaldos(movimientos);
				this.movimientos=movimientos;
				this.blockUI.stop();
			});
		}
	}
  
  buscarKardexProducto(){
    this.blockUI.start();
    let fecha_inicial:any=(this.filter.fecha_inicio=="" || this.filter.fecha_inicio==undefined)?0:new Date(this.filter.fecha_inicio.year,this.filter.fecha_inicio.month-1,this.filter.fecha_inicio.day,0,0);
    let fecha_fin=(this.filter.fecha_fin=="" || this.filter.fecha_fin==undefined)?0:new Date(this.filter.fecha_fin.year,this.filter.fecha_fin.month-1,this.filter.fecha_fin.day,0,0);

		if(fecha_inicial!=0){
      let fecha_anterior=new Date(fecha_inicial);fecha_anterior.setDate(fecha_inicial.getDate()-1);
      this.productosService.obtenerMovimientosProducto(this.filter,this.producto.id,0,fecha_anterior).subscribe( (detMovsSaldo:any) =>{
        let detalleMovimientoSaldoAnterior=this.obtenerSaldo(detMovsSaldo);
        this.productosService.obtenerMovimientosProducto(this.filter,this.producto.id,fecha_inicial,fecha_fin).subscribe( (detMovs:any) =>{
          if(detalleMovimientoSaldoAnterior!=0){
						detMovs.unshift(detalleMovimientoSaldoAnterior);
					}
					this.generarKardexProducto(detMovs);
          this.blockUI.stop()
        });
      });
		}else{
			this.productosService.obtenerMovimientosProducto(this.filter,this.producto.id,fecha_inicial,fecha_fin).subscribe( (detMovs:any) =>{
        this.generarKardexProducto(detMovs);
				this.blockUI.stop();
      });
		}
	}

	generarKardexProducto(detMovs){
		var dato=this.producto;
		dato.detallesMovimiento=detMovs;			
		for (var i = 0; i < dato.detallesMovimiento.length; i++) {
			//dato.detallesMovimiento[i].costo_unitario=Math.round((dato.detallesMovimiento[i].costo_unitario*0.87)*100)/100;
			if (i == 0 && dato.detallesMovimiento[i].tipo=="SALDO ANTERIOR") {
				dato.detallesMovimiento[i].saldoFisico=dato.detallesMovimiento[i].saldoFisico;
				dato.detallesMovimiento[i].saldoValuado=dato.detallesMovimiento[i].saldoValuado;
				//dato.detallesMovimiento[i].costo_unitario=Math.round((dato.detallesMovimiento[i].costo_unitario*100/87)*100)/100;
			}else if (i == 0 && dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING && dato.detallesMovimiento[i].movimiento.clase.nombre_corto==GlobalVariable.Dictionary.ING_INV_INICIAL) {
				dato.detallesMovimiento[i].saldoFisico=dato.detallesMovimiento[i].cantidad;
				dato.detallesMovimiento[i].saldoValuado=Math.round((dato.detallesMovimiento[i].saldoFisico*dato.detallesMovimiento[i].costo_unitario)*100)/100;
				dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
			}else if (i == 0 && dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING) {
				dato.detallesMovimiento[i].saldoFisico=dato.detallesMovimiento[i].cantidad;
				dato.detallesMovimiento[i].saldoValuado=Math.round((dato.detallesMovimiento[i].saldoFisico*dato.detallesMovimiento[i].costo_unitario)*100)/100;
				dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
			}else{
				if(dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING){
					dato.detallesMovimiento[i].saldoFisico = dato.detallesMovimiento[i-1].saldoFisico + dato.detallesMovimiento[i].cantidad;
					dato.detallesMovimiento[i].saldoValuado = Math.round((dato.detallesMovimiento[i-1].saldoValuado +(dato.detallesMovimiento[i].cantidad*dato.detallesMovimiento[i].costo_unitario))*100)/100;
					if(dato.detallesMovimiento[i].movimiento.compra){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.compra.numero_documento+" Proveedor: "+dato.detallesMovimiento[i].movimiento.compra.proveedor.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.movimiento_inventario && dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.numero_documento+" Almacen Origen: "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso.nombre;
					}else if(dato.detallesMovimiento[i].movimiento.venta_anulada){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Anulación "+dato.detallesMovimiento[i].movimiento.venta_anulada.transaccion.nombre+" Nro: "+dato.detallesMovimiento[i].movimiento.venta_anulada.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.venta_anulada.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.devolucion_venta){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Devolución Nro: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.numero_documento+" "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.transaccion.nombre+" Nro: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.razon_social;
					}else{
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
					}
				}else{
					dato.detallesMovimiento[i].saldoFisico = dato.detallesMovimiento[i-1].saldoFisico - dato.detallesMovimiento[i].cantidad;
					dato.detallesMovimiento[i].saldoValuado = Math.round((dato.detallesMovimiento[i-1].saldoValuado - (dato.detallesMovimiento[i].cantidad*dato.detallesMovimiento[i].costo_unitario))*100)/100;

					if(dato.detallesMovimiento[i].movimiento.venta){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.venta.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.venta.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.despacho){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. Pedido "+dato.detallesMovimiento[i].movimiento.despacho.pedido.numero_pedido+" "+" Cliente: "+dato.detallesMovimiento[i].movimiento.despacho.pedido.cliente.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.movimiento_inventario && dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.numero_documento+" Almacen Destino: "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso.nombre;
					}else{
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
					}
				} 
				
			}
			dato.detallesMovimiento[i].saldoV=dato.detallesMovimiento[i].saldoValuado.toFixed(2);	
		}			
		this.movimientos=dato;
  }

  obtenerSaldo(detMovs){
		let dato={ detallesMovimiento:detMovs };
		for (var i = 0; i < dato.detallesMovimiento.length; i++) {
			//dato.detallesMovimiento[i].costo_unitario=Math.round((dato.detallesMovimiento[i].costo_unitario*0.87)*100)/100;
			if (i == 0 && dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING && dato.detallesMovimiento[i].movimiento.clase.nombre_corto==GlobalVariable.Dictionary.ING_INV_INICIAL) {
				dato.detallesMovimiento[i].saldoFisico=dato.detallesMovimiento[i].cantidad;
				dato.detallesMovimiento[i].saldoValuado=Math.round((dato.detallesMovimiento[i].saldoFisico*dato.detallesMovimiento[i].costo_unitario)*100)/100;
				dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
			}else if (i == 0 && dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING) {
				dato.detallesMovimiento[i].saldoFisico=dato.detallesMovimiento[i].cantidad;
				dato.detallesMovimiento[i].saldoValuado=Math.round((dato.detallesMovimiento[i].saldoFisico*dato.detallesMovimiento[i].costo_unitario)*100)/100;
				dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
			}
			else{
				if(dato.detallesMovimiento[i].movimiento.tipo.nombre_corto==GlobalVariable.Dictionary.MOV_ING){
					dato.detallesMovimiento[i].saldoFisico = dato.detallesMovimiento[i-1].saldoFisico + dato.detallesMovimiento[i].cantidad;
					dato.detallesMovimiento[i].saldoValuado = Math.round((dato.detallesMovimiento[i-1].saldoValuado +(dato.detallesMovimiento[i].cantidad*dato.detallesMovimiento[i].costo_unitario))*100)/100;
					if(dato.detallesMovimiento[i].movimiento.compra){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.compra.numero_documento+" Proveedor: "+dato.detallesMovimiento[i].movimiento.compra.proveedor.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.movimiento_inventario && dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.numero_documento+" Almacen Origen: "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso.nombre;
					}else if(dato.detallesMovimiento[i].movimiento.venta_anulada){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Anulación "+dato.detallesMovimiento[i].movimiento.venta_anulada.transaccion.nombre+" Nro: "+dato.detallesMovimiento[i].movimiento.venta_anulada.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.venta_anulada.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.devolucion_venta){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Devolución Nro: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.numero_documento+" "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.transaccion.nombre+" Nro: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.devolucion_venta.venta.razon_social;
					}else{
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
					}
				}else{
					dato.detallesMovimiento[i].saldoFisico = dato.detallesMovimiento[i-1].saldoFisico - dato.detallesMovimiento[i].cantidad;
					dato.detallesMovimiento[i].saldoValuado = Math.round((dato.detallesMovimiento[i-1].saldoValuado - (dato.detallesMovimiento[i].cantidad*dato.detallesMovimiento[i].costo_unitario))*100)/100;
					if(dato.detallesMovimiento[i].movimiento.venta){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.venta.factura+" Cliente: "+dato.detallesMovimiento[i].movimiento.venta.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.despacho){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. Pedido "+dato.detallesMovimiento[i].movimiento.despacho.pedido.numero_pedido+" "+" Cliente: "+dato.detallesMovimiento[i].movimiento.despacho.pedido.cliente.razon_social;
					}else if(dato.detallesMovimiento[i].movimiento.movimiento_inventario && dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso){
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre+" Nro. "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.numero_documento+" Almacen Destino: "+dato.detallesMovimiento[i].movimiento.movimiento_inventario.almacenTraspaso.nombre;
					}else{
						dato.detallesMovimiento[i].tipo=dato.detallesMovimiento[i].movimiento.clase.nombre;
					}
				} 
				
			}
			dato.detallesMovimiento[i].saldoV=dato.detallesMovimiento[i].saldoValuado.toFixed(2);	
		}

		if( dato.detallesMovimiento.length>0){
			dato.detallesMovimiento[dato.detallesMovimiento.length-1].tipo="SALDO ANTERIOR";
			dato.detallesMovimiento[dato.detallesMovimiento.length-1].movimiento.compra=null;
			dato.detallesMovimiento[dato.detallesMovimiento.length-1].movimiento.venta=null;
			return dato.detallesMovimiento[dato.detallesMovimiento.length-1];
		}else{
			return 0;
		}
  }

  	obtenerSaldoV2(movimientos){
		let sumatoria_ingresos=0,sumatoria_salidas=0;
		for (let i = 0; i < movimientos.length; i++) {
			sumatoria_ingresos=sumatoria_ingresos+movimientos[i].ingreso;
			sumatoria_salidas=sumatoria_salidas+movimientos[i].salida;
		}
		let movimiento_saldo={costo_unitario:0,detalle:"SALDO ANTERIOR",ingreso:sumatoria_ingresos,salida:sumatoria_salidas,saldo:sumatoria_ingresos-sumatoria_salidas}

		return movimiento_saldo
	}

	cambiarConsideracionSaldo(){
		this.movimientos=this.generarSaldos(this.movimientos);
	}

	generarSaldos(movimientos){
		this.resumen={
			sumatoria_movimientos_ingresos:0,
			sumatoria_movimientos_salidas:0,
			sumatoria_compras:0,
			sumatoria_ventas:0,
			sumatoria_ventas_combos:0,
			sumatoria_devoluciones_ventas:0,
			sumatoria_devoluciones_ventas_combos:0
		}
		let saldo_general=this.filter.considerar_saldo_anterior?movimientos.length>0?movimientos[0].saldo:0:0;
		for (let i = 1; i < movimientos.length; i++) {
			saldo_general=(movimientos[i].ingreso>movimientos[i].salida)?saldo_general+movimientos[i].ingreso:saldo_general-movimientos[i].salida;
			movimientos[i].saldo=Math.round(saldo_general*100)/100;
			if(movimientos[i].tipo=="MOVIMIENTO INGRESO"){
				this.resumen.sumatoria_movimientos_ingresos=this.resumen.sumatoria_movimientos_ingresos+movimientos[i].ingreso;
			}else if(movimientos[i].tipo=="MOVIMIENTO SALIDA"){
				this.resumen.sumatoria_movimientos_salidas=this.resumen.sumatoria_movimientos_salidas+movimientos[i].salida;
			}else if(movimientos[i].tipo=="COMPRA"){
				this.resumen.sumatoria_compras=this.resumen.sumatoria_compras+movimientos[i].ingreso;
			}else if(movimientos[i].tipo=="VENTA"){
				this.resumen.sumatoria_ventas=this.resumen.sumatoria_ventas+movimientos[i].salida;
			}else if(movimientos[i].tipo=="VENTA COMBO"){
				this.resumen.sumatoria_ventas_combos=this.resumen.sumatoria_ventas_combos+movimientos[i].salida;
			}else if(movimientos[i].tipo=="DEVOLUCION"){
				this.resumen.sumatoria_devoluciones_ventas=this.resumen.sumatoria_devoluciones_ventas+movimientos[i].ingreso;
			}else if(movimientos[i].tipo=="DEVOLUCION COMBO"){
				this.resumen.sumatoria_devoluciones_ventas_combos=this.resumen.sumatoria_devoluciones_ventas_combos+movimientos[i].ingreso;
			}
		}
		return movimientos;
	}
  
  generarPdfKardexProducto(){
		this.blockUI.start();
			var titulo="KARDEX DE PRODUCTO";
			var fecha_reporte=new Date();
			var doc = new PDFDocument({size: 'letter',margin:10});
			var stream = doc.pipe(blobStream());
			// draw some text
			var saldoFisico=0;
			var saldoValuado=0;
			
			var y=160,itemsPorPagina=20,items=0,pagina=1,totalPaginas=Math.ceil(this.movimientos.length/itemsPorPagina);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
				this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});
			this.dibujarCabeceraPDFKardexProducto(doc);
			for(var i=0;i<this.movimientos.length && items<=itemsPorPagina;i++){
				doc.font('Helvetica',8);
				doc.rect(50,y-15,540,30).stroke();
				this.movimientos[i].fecha=this.movimientos[i].fecha?new Date(this.movimientos[i].fecha):null;
				let fecha_texto=this.movimientos[i].fecha?this.movimientos[i].fecha.getDate()+"/"+(this.movimientos[i].fecha.getMonth()+1)+"/"+this.movimientos[i].fecha.getFullYear():"";
				doc.text(fecha_texto,60,y-2);
				doc.text(this.movimientos[i].detalle,110,y-12,{width:150});
				doc.text(this.movimientos[i].costo_unitario,230,y-2,{width:50,align:"right"});
				doc.text(this.movimientos[i].ingreso,285,y-2,{width:50,align:"right"});
				doc.text(this.movimientos[i].salida,330,y-2,{width:50,align:"right"});
				doc.text(this.movimientos[i].saldo,380,y-2,{width:50,align:"right"});
				y=y+30;
				items++;
				
				if(items==itemsPorPagina){
				doc.addPage({margin:0,bufferPages: true});
				y=160;
				items=0;
				pagina=pagina+1;

				this.dibujarCabeceraPDFKardexProducto(doc);
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
					(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
				(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
				(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:true});
				 	doc.font('Helvetica',8);
				}
			}
			doc.end();
			stream.on('finish', function() {
				var fileURL = stream.toBlobURL('application/pdf');
				window.open(fileURL,'_blank','location=no');
			});
			this.blockUI.stop();
	
  }
  
  dibujarCabeceraPDFKardexProducto(doc){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,50,230,45,5).stroke();
		doc.text("SUCURSAL : ",385,55);
		doc.text(this.filter.sucursal.nombre,440,55);		
		doc.text("ALMACEN : ",385,65);
		doc.text(this.filter.almacen.nombre,440,65);	
		doc.text("PRODUCTO : ",385,75);
		doc.text(this.producto.nombre,440,75);	
		doc.text("UNID. MED. : ",385,85);
		doc.text(this.producto.unidad_medida,440,85);	
			
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,115,540,30,5).stroke();			
		doc.text("Fecha",65,125);
		doc.text("Detalle",120,125,{width:50});
		doc.text("c/u",270,125,{width:60});
		doc.roundedRect(295,115,150,30,5).stroke();	
		doc.text("Fisico",360,120,{width:50});
		doc.roundedRect(295,115,150,15,5).stroke();	
		doc.text("Ingreso",310,135,{width:50});
		doc.text("Salida",360,135,{width:50});
		doc.text("Saldo",410,135,{width:50});
		doc.roundedRect(445,115,145,15,5).stroke();
  }
  
  generarExcelKardexProducto(){
		this.blockUI.start();
		var data = [["KARDEX DE PRODUCTO",null,null],
					["Sucursal:",this.filter.sucursal.nombre,null,"Almacen:",this.filter.almacen.nombre],
					["Producto:",this.producto.nombre,null,"Unid. Med.:",this.producto.unidad_medida],
					[null,null,null,null,null],
					["Nº","Fecha","Detalle","C.U.","Físico",null,null],
					[null,null,null,null,"Ingreso","Salida","Saldo"]];
		var saldoFisico=0;
		var saldoValuado=0;
		for(var i=0;i<this.movimientos.length;i++){
			var columns=[];

			this.movimientos[i].fecha=new Date(this.movimientos[i].fecha);
			columns.push(i+1);
			columns.push(this.movimientos[i].fecha.getDate()+"/"+(this.movimientos[i].fecha.getMonth()+1)+"/"+this.movimientos[i].fecha.getFullYear());
			columns.push(this.movimientos[i].detalle);
			columns.push(this.movimientos[i].costo_unitario);
			columns.push(this.movimientos[i].ingreso);
			columns.push(this.movimientos[i].salida);
			columns.push(this.movimientos[i].saldo);
			data.push(columns);
    }
    
    let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Kardex Producto");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 80;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Kardex-Producto.xlsx");
		})
		this.blockUI.stop();
	}

	abrirPopupConfirmacionActualizacionInventario(movimiento){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de actualizar el inventario?";
		this.popupConfirmacion.componentInstance.data = movimiento;
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.actualizarInventario($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	actualizarInventario(movimiento){
		this.blockUI.start();
		let productos=[{id:this.producto.id,costo_unitario:movimiento.costo_unitario,cantidad:movimiento.saldo}];
		this.inventariosService.actualizarCantidadInventarioProducto({id_almacen:this.filter.almacen.id,productos:productos}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}

}
