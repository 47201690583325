import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../../models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { Socket } from 'ngx-socket-io';
import { PopupConfirmacionComponent } from '../../components/popup-confirmacion/popup-confirmacion.component';
import { Util } from 'src/app/utils/utils';
import { GoogleMap } from '@angular/google-maps';
import { RegistroUsuarioComponent } from '../registro-usuario/registro-usuario.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ProductosService } from '../../../snapquick/services/productos/productos.service';
import { GlobalVariable } from 'src/app/global';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { PdfService } from '../../services/pdf/pdf.service';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;
declare const aplicarVisorImagenes:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  usuarios:any[]=[];
  usuario_item:any;
  tipoProductoPrecio:any;
  empresas:any[]=[];
  imagenes_posiciones:any[];
  tabla_usuario_general:any={georeferenciacion_automatico:false};

  edicionusuario:NgbModalRef;

  center= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  zoom = 17;
  display?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    //maxZoom: 15,
    //minZoom: 8,
  }
  bounds:google.maps.LatLngBounds;
  posicion_marcador:google.maps.LatLng;
  @ViewChild('mapa') map:google.maps.Map;
  markers:any[];

  mapausuario:NgbModalRef;
  @ViewChild('mapausuario')
  private mapausuarioRef: TemplateRef<any>;

  usuario_precios_productos:NgbModalRef;
  @ViewChild('usuario_precios_productos')
  private usuario_precios_productos_ref: TemplateRef<any>;

  usuario_configuracion_aplicaciones:NgbModalRef;
  @ViewChild('usuario_configuracion_aplicaciones')
  private usuario_configuracion_aplicaciones_ref: TemplateRef<any>;

  fecha_seguimiento:any;

  @ViewChildren('imagenes_posicion') imagenes_posicion: QueryList<any>;

  sucursales_precios:any[]=[];
  producto_busqueda:any;

  searching = false;
  searchFailed = false;

  aplicacion_permisos:any={};

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
	private empresasService:EmpresaService,
	private socket: Socket,
	private productosService:ProductosService,
	private pdfService:PdfService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.obtenerEmpresas();
		this.filter={
      empresa:this.usuario.id_empresa?this.usuario.empresa:{id:0},
      empresas_filtro:[{nombre:"TODOS",id:0}],
	  rol:{nombre:"TODOS",id:0},
	  roles:[{nombre:"TODOS",id:0}]
    }
	this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_USUARIOS);
    this.obtenerRoles();
    this.obtenerUsuarios();

    if(this.usuario.empresa){
      this.empresasService.obtenerSucursalesEmpresa(this.usuario.empresa.id).subscribe(sucursalesEmpresa =>{
        this.usuario.empresa.sucursales=sucursalesEmpresa;
		//this.llenarSucursales(this.usuario.empresa.sucursales);
      });
			if(this.usuario.empresa.usar_precios_productos_usuarios){
				this.obtenerConceptosPreciosProductos();
			}
		}else{
			//this.llenarSucursales([]);
		}
  }

  ngAfterViewInit() {
	resaltarPestañaMenu(this.router.url.substring(1)+"1");
	this.imagenes_posicion.changes.subscribe(t => {
		aplicarVisorImagenes();
	});
  }

  obtenerRoles(){
	this.blockUI.start();
	if(this.usuario.empresa && this.usuario.empresa.usar_aplicaciones_por_rol){
		this.empresasService.obtenerRolesEmpresa(this.usuario.empresa.id).subscribe((roles:any[])=>{
			this.filter.roles=this.filter.roles.concat(roles);
			this.blockUI.stop();
		});
	}else{
		this.empresasService.obtenerRoles().subscribe((roles:any[])=>{
			this.filter.roles=this.filter.roles.concat(roles);
			this.blockUI.stop();
		  });
	}
  }
  
  obtenerEmpresas(){
    this.blockUI.start();
    this.empresasService.obtenerEmpresas(0).subscribe((empresas:any[])=>{
		this.empresas=empresas;
      	this.filter.empresas_filtro=this.filter.empresas_filtro.concat(empresas);
	  	this.blockUI.stop();
    });
  }
  
  obtenerUsuarios(){
    this.column = "id";
    this.direction="asc";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.usuarioService.obtenerListaUsuarios(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.usuarios=dato.usuarios;
			this.blockUI.stop();
		});
  }
  
  obtenerConceptosPreciosProductos(){
		this.blockUI.start();
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").subscribe(entidad => {
      this.tipoProductoPrecio=entidad;
			this.blockUI.stop();
    });
  }
  
  crearNuevoUsuario(){
	this.edicionusuario=this.modalService.open(RegistroUsuarioComponent, {windowClass : "wizard-edicion-usuario",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
	this.edicionusuario.componentInstance.usuario = this.usuario;
	this.edicionusuario.componentInstance.empresas = this.empresas;
	this.edicionusuario.componentInstance.alTerminar.subscribe((res) => {
		if(res){
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
				this.toastr.error(res.stack.substring(0,500));
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
				this.edicionusuario.close();
			}
		}else{
			this.edicionusuario.close();
		}
		
	});
  }

	modificarUsuario(usuario){
		usuario.persona={};
		this.edicionusuario=this.modalService.open(RegistroUsuarioComponent, {windowClass : "wizard-edicion-usuario",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicionusuario.componentInstance.usuario = this.usuario;
		this.edicionusuario.componentInstance.usuario_item = usuario;
		this.edicionusuario.componentInstance.empresas = this.empresas;
		this.edicionusuario.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicionusuario.close();
				}
			}else{
				this.edicionusuario.close();
			}
			
		});
	}

	

	

	actualizarNavegadoresUsuarios(usuario){
		this.socket.emit('actualizarNavegador',{usuario:usuario,empresa:{}});
		this.toastr.info("Solicitud de actualización de navegador enviado satisfactoriamente!");
	}

	cerrarSesionesUsuarios(usuario,empresa){
		this.socket.emit('cerrarSesion',{usuario:usuario,empresa:empresa});
		this.toastr.info("Solicitud de cierre de sesión enviado satisfactoriamente!");
	}

	abrirPopupConfirmacionEliminacion(usuario){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el usuario?";
		this.popupConfirmacion.componentInstance.data = usuario;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarUsuario($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	eliminarUsuario(usuario){
		this.blockUI.start();
		this.usuarioService.eliminarUsuario(usuario).subscribe(res => {
			this.toastr.success("Usuario eliminado satisfactoriamentes!");
			this.getItems();
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionEnvio(usuario){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar informació al usuario por correo electrónico?";
		this.popupConfirmacion.componentInstance.data = usuario;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarInformacionCuentaUsuario($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	enviarInformacionCuentaUsuario(usuario){
		usuario.clave=null;
		this.enviarCorreoElectronico(usuario);
	}

	enviarCorreoElectronico(usuario){
		this.blockUI.start();
		this.usuarioService.enviarCuentaUsuario(usuario).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
			}
			this.blockUI.stop();
		});
	}

	abrirMapaSeguimiento(){
		let fecha_actual=new Date();
		this.fecha_seguimiento={
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		this.mapausuario=this.modalService.open(this.mapausuarioRef, {windowClass : "wizard-edicion-usuario",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.mapausuario.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	buscarPosiciones(){
		this.bounds = new google.maps.LatLngBounds();
		let fecha_posicion=Util.convertirObjetoAfecha(this.fecha_seguimiento);
		this.usuarioService.buscarPosicionesUsuarios(this.usuario.id_empresa,fecha_posicion).subscribe( (usuarios:any[]) =>{
			let markers = [];
			for (var i = 0; i < usuarios.length; i++) {
				for (var j = 0; j < usuarios[i].posiciones.length; j++) {
					this.posicion_marcador=new google.maps.LatLng(usuarios[i].posiciones[j].latitud,usuarios[i].posiciones[j].longitud);
					this.bounds.extend(this.posicion_marcador);
					var date=new Date(usuarios[i].posiciones[j].createdAt);
					markers.push(this.crearMarcador(i+"-"+j+1,
										null,
										usuarios[i].posiciones[j].latitud,
										usuarios[i].posiciones[j].longitud,
										(usuarios[i].persona.nombre_completo+" - "+usuarios[i].posiciones[j].descripcion+" - "+date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+"-"+date.getHours()+":"+date.getMinutes()),
										usuarios[i].posiciones[j].imagenes));
					
				}
			}
			this.markers = markers;
			this.center={ lat: this.bounds.getCenter().lat(), lng: this.bounds.getCenter().lng()};
		});
	}

	crearMarcador(i, idKey,lat,lng,nombre,imagenes) {
		if (idKey == null) {
			idKey = "id";
		}

		var latitude = lat;
		var longitude = lng;
		var ret = {
			position: {
				lat: latitude,
				lng: longitude,
			},
			imagenes:imagenes,
			label: {
				color: 'red',
				text: nombre,
			},
			title:nombre,
			options: { 
				icon:'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
				labelContent : nombre,
				labelAnchor: "5 55",
				labelClass: 'marker-label', 
			},
		};
		ret[idKey] = i;
		return ret;
	}

	clickMarcador(marcador){
		this.imagenes_posiciones=marcador.imagenes;
		/*$timeout(function(){
			aplicarVisorImagenes("imagen-posicion-usuario");
		},1000);*/
	}

	abrirPopupConfirmacionEliminacionImagen(dato){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.data = dato;
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarImagen($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	eliminarImagen(dato){
		this.usuarioService.eliminarPosicionImagen(dato.dato.id).subscribe( (res:any) =>{
			this.toastr.success(res.mensaje);
			dato.imagenes.splice(dato.imagenes.indexOf(dato.dato),1);
		});
	}

	cerrarMapaSeguimiento(){
		this.mapausuario.close();
	}

	actualizarAtributoUsuario(usuario,atributo,valor){
		this.blockUI.start();
		let datos:any={
			atributo:atributo,
			valor:valor
		};
		if(usuario){
			datos.usuario=usuario;
		}else{
			datos.id_empresa=this.usuario.id_empresa;
		}
		this.usuarioService.actualizarAtributoUsuario(datos).subscribe((res:any) => {
			this.getItems();
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}

	abrirPopupPreciosProductos(usuario){
		this.blockUI.start();
		this.sucursales_precios=Util.obtenerSucursalesUsuario(this.usuario);
		this.usuario_item=usuario;
		this.usuario_item.producto_precio={};
		this.usuarioService.obtenerPreciosProductosUsuario(usuario.id).subscribe((productos_precios:any[])=>{
			this.blockUI.stop();
			this.usuario_item.productos_precios=productos_precios;
			this.usuario_precios_productos=this.modalService.open(this.usuario_precios_productos_ref, {scrollable:true, windowClass:'precios_usuario', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.usuario_precios_productos.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	buscarProducto = (text$: Observable<string>) =>
		text$.pipe(
		debounceTime(300),
		distinctUntilChanged(),
		tap(() => this.searching = true),
		switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
		catchError(() => {
			this.searchFailed = true;
			return of([]);
		})  ,
		tap(() => this.searching = false)
	)
	
  	formatterProducto = (x: any) => (x.nombre);

	establecerProducto($event){
		this.usuario_item.producto_precio.producto=$event.item
	}

	iniciarProductoPrecio(){
		this.producto_busqueda="";
		this.usuario_item.producto_precio.producto=null;
	}

	agregarProductoPrecio(precio_producto){
		precio_producto.id_usuario=this.usuario_item.id;
		this.usuario_item.productos_precios.push(precio_producto);
		this.usuario_item.producto_precio={};
		this.iniciarProductoPrecio();
	}

	establecerPrecioPorDefecto(producto_precio){
		for(var i=0;i<this.usuario_item.productos_precios.length;i++){
			if(this.usuario.empresa.usar_precios_productos_sucursal){
				/*if(producto_precio.sucursal && producto_precio.sucursal.id==$scope.producto.precios[i].sucursal.id){
					$scope.producto.precios[i].por_defecto=false;
				}*/
			}else{
				if(producto_precio.producto.id==this.usuario_item.productos_precios[i].producto.id && !this.usuario_item.productos_precios[i].eliminado){
					this.usuario_item.productos_precios[i].por_defecto=false;
				}
			}
		}
		producto_precio.por_defecto=true;
	}

	eliminarDetalleProductosPrecio(producto_precio){
		producto_precio.eliminado=true;		
	}

	abrirPopupConfirmacionsincronizacion(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de sincronizar los precios? Todos los anteriores asignados al usuario seran sobreescritos!";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.obtenerPreciosProductos();
			}
			this.popupConfirmacion.close();
        });
	  }

	obtenerPreciosProductos(){
		this.blockUI.start();
		this.productosService.obtenerPreciosProductosEmpresa(this.usuario.id_empresa).subscribe((precios:any[])=>{
			this.usuario_item.productos_precios=precios;
			for(let i=0;i<this.usuario_item.productos_precios.length;i++){
				this.usuario_item.productos_precios[i].id=null;
				this.usuario_item.productos_precios[i].id_usuario=this.usuario_item.id;
			}
			this.blockUI.stop();
		});
	}

	guardarPreciosProductosUsuario(){
		this.blockUI.start();
		this.usuarioService.guardarPreciosProductosUsuario({productos_precios:this.usuario_item.productos_precios}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.usuario_precios_productos.close();
			this.blockUI.stop();
		});
	}

	verListaConfiguracionAplicaciones(usuario){
		this.aplicacion_permisos={};
		this.blockUI.start();
		this.usuarioService.obtenerUsuario(usuario.id).subscribe((usuario_item:any)=>{
			this.blockUI.stop();
			this.usuario_item=usuario_item;
			this.usuario_configuracion_aplicaciones=this.modalService.open(this.usuario_configuracion_aplicaciones_ref, {scrollable:true, windowClass:'precios_usuario', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.usuario_configuracion_aplicaciones.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	establecerAplicacion(aplicacion){
		this.aplicacion_permisos=aplicacion;
		this.aplicacion_permisos.configuracion_aplicacion=Util.obtenerConfiguracionAplicacion(this.aplicacion_permisos.codigo,this);
		this.aplicacion_permisos.configuracion_aplicacion=new PageConfiguration({
			crear: true,
			id_empresa: this.usuario.id_empresa,
			id_usuario: this.usuario_item.id,
			datos: this.aplicacion_permisos.configuracion_aplicacion
		},this.aplicacion_permisos.id,
		this.usuarioService);
		this.aplicacion_permisos.configuracion_aplicacion.compareAndUpdateConfigurations(this.usuario.id_empresa,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario_item.id:0);
	}

	async iniciarSesionUsuario(usuario_item){
		this.blockUI.start('Iniciando sesión... espere por favor!');
		usuario_item.token=this.usuario.token;
		let usuario_respuesta=await this.usuarioService.iniciarSesionUsuario(usuario_item,this.blockUI);
		if(usuario_respuesta){
			this.usuario=usuario_respuesta;
			this.verificarSesion();
		}
	}

	verificarSesion(){
		this.persistenciaService.getUsuario().subscribe(usuario => {
		  if(usuario && usuario.persona){
			if(usuario.aplicacion_partida){
			  this.router.navigateByUrl('/'+usuario.aplicacion_partida.url, {skipLocationChange: false}).then(()=>{
				this.router.navigate(['/'+usuario.aplicacion_partida.url]);
				window.location.reload()
			  });
			}else{
			  this.router.navigateByUrl('/inicio', {skipLocationChange: false}).then(()=>{
				this.router.navigate(['/inicio']);
				window.location.reload()
			  });
			}
		  }
		});
	}

	descargarExcelUsuario(){
		this.blockUI.start();
		let data=[];
		let cabecera=["Nº", "Nombre","Usuarip", "Roles", "Activo"];
		data.push(cabecera);
		for (var i = 0; i < this.usuarios.length; i++) {
			var columns = [];
			columns.push(i+1);
			columns.push(this.usuarios[i].nombre_completo);
			columns.push(this.usuarios[i].nombre_usuario);
			columns.push(this.usuarios[i].roles);
			columns.push(this.usuarios[i].activo?"SI":"NO");
			data.push(columns);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("USUARIOS");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 30;
		const dobCol4 = worksheet.getColumn(4);
		dobCol4.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "ReporteUsuarios.xlsx");
		})
		this.blockUI.stop();
	}

	async descargarPdfUsuarios(){
		this.blockUI.start();
		let doc = new PDFDocument({size:[612, 792],margin:10});
		let stream = doc.pipe(blobStream());
		var y = 140, items = 0,itemsPorPagina=14, pagina = 1, totalPaginas = Math.ceil(this.usuarios.length / itemsPorPagina),fecha_reporte=new Date();
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE DE USUARIOS",pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
			(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
		(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
		(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});
		await this.dibujarCabeceraPdfReporteUsuarios(doc);
		for (let i = 0; i < this.usuarios.length; i++) {
			let imagen = await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+this.usuarios[i].imagen);
			doc.rect(50,y-10,540,40).stroke();
			doc.font('Helvetica',8);
			doc.text((i+1)+"",55,y);
			doc.image(imagen,70,y-8,{width:35,height:35});
			doc.text(this.usuarios[i].nombre_completo,110,y,{width:150});
			doc.text(this.usuarios[i].nombre_usuario,280,y,{width:150});
			doc.text(this.usuarios[i].roles,420,y);
			doc.text(this.usuarios[i].activo?"SÍ":"NO",520,y,{width:70});
			y=y+40;
			items++;

			if (items == itemsPorPagina && (i+1) < this.usuarios.length) {
				doc.addPage({ size: [612, 792], margin: 10 });
				y = 140;
				items = 0;
				pagina = pagina + 1;
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE DE USUARIOS",pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
					(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
				(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
				(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:false});
				await this.dibujarCabeceraPdfReporteUsuarios(doc);
			}
			
		}
		doc.end();
		this.blockUI.stop();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}

	dibujarCabeceraPdfReporteUsuarios(doc){
		let y=140;
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		
		doc.text("Nº", 55, y-30);
		doc.text("IMAGEN", 70, y-30);
		doc.text("NOMBRE", 110, y-30);
		doc.text("USUARIO", 280, y-30);
		doc.text("ROLES", 420, y-30);
		doc.text("¿ACTIVO?", 520, y-30);
	}

}
