import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { BaseComponent } from 'src/app/models/base-component';

@Component({
  selector: 'app-asignacion-pago-estudiante',
  templateUrl: './asignacion-pago-estudiante.component.html',
  styleUrls: ['./asignacion-pago-estudiante.component.css']
})
export class AsignacionPagoEstudianteComponent extends BaseComponent implements OnInit {

  gestion:any;
  id_estudiante:any;
  conceptos_ingreso:any[]=[];
  asignacion_concepto_pago_estudiante:any={};
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private conceptosIngresoService:ConceptosIngresosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.blockUI.start();
    this.conceptosIngresoService.obtenerConceptosPagoColegio(this.gestion).subscribe((conceptos_ingreso:any)=>{
      this.conceptos_ingreso=conceptos_ingreso;
      this.blockUI.stop();
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  validarAsignacion(){
    var res=true;
    if(!this.asignacion_concepto_pago_estudiante.concepto_pago){
      res=false;
      this.toastr.error("Debe asignar el concepto de pago al estudiante!");
    }
    return res;
  }

  asignarPago(){
    if(this.validarAsignacion()){
      this.asignacion_concepto_pago_estudiante.id_estudiante=this.id_estudiante;
      this.blockUI.start();
      this.conceptosIngresoService.asignarConceptoPagoEstudiante(this.asignacion_concepto_pago_estudiante).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
      });
    }
  }

}
