import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanDeCuentasComponent } from './components/plan-de-cuentas/plan-de-cuentas.component';
import { ComprobantesComponent } from './components/comprobantes/comprobantes.component';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { LibroMayorComponent } from './components/libro-mayor/libro-mayor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistroComprobanteComponent } from './components/registro-comprobante/registro-comprobante.component';
import { BalanceSumasSaldosComponent } from './components/balance-sumas-saldos/balance-sumas-saldos.component';
import { VentasNoContabilizadasComponent } from './components/notificaciones/ventas-no-contabilizadas/ventas-no-contabilizadas.component';
import { AsignacionCuentaClienteComponent } from './components/asignacion-cuenta-cliente/asignacion-cuenta-cliente.component';
import { PagosVentasNoContabilizadosComponent } from './components/notificaciones/pagos-ventas-no-contabilizados/pagos-ventas-no-contabilizados.component';



@NgModule({
  declarations: [
    PlanDeCuentasComponent,
    ComprobantesComponent,
    LibroMayorComponent,
    RegistroComprobanteComponent,
    BalanceSumasSaldosComponent,
    VentasNoContabilizadasComponent,
    AsignacionCuentaClienteComponent,
    PagosVentasNoContabilizadosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BaseModule,
    NgbModule
  ]
})
export class ContabilidadModule { }
