<div class="modal-header">
    <div class="col-3">
        <span class="widget-title text-blue text-125">Asignación Cuenta</span>
    </div>
    <div class="col-6">
        <h4 class="widget-title">Cliente: {{cliente.razon_social}}</h4>
    </div>
    <div class="col-3">
        <button (click)="guardarAsignacionCuentaCliente()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-6 col-form-label text-sm-right">¿Crear cuenta contabilidad?</div>
        <div class="col-6">
            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <label>
                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="asignacion_cuenta.crear_cuenta_contabilidad"/>
                </label>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!asignacion_cuenta.crear_cuenta_contabilidad">
        <hr>
        <div class="row">
            <div class="col-3">
                <label>Cuenta</label>
            </div>
            <div class="col-7">
                <input name="cuenta"[disabled]="asignacion_cuenta.cuenta && asignacion_cuenta.cuenta.id"
                [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCuentaActual($event)"
                type="text" class="form-control" [(ngModel)]="cuenta_busqueda" 
                [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off"
                />
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
                </ng-template>
            </div>
            <div class="col-2">
                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="asignacion_cuenta.cuenta && asignacion_cuenta.cuenta.id" (click)="reiniciarCuenta()">
                    <i class="fa fa-trash bigger-130 red2"></i>
                </a>
            </div>
        </div>
    </ng-container>
</div>