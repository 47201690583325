<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-6">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Movimiento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{movimiento_inventario.tipo.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Transacción : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{movimiento_inventario.clase.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_sucursales">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Sucursal  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        <span>{{movimiento_inventario.almacen.sucursal.nombre}}</span>
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="usuario.empresa.usar_sucursales || usuario.empresa.usar_almacenes">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Almacen  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        <span>{{movimiento_inventario.almacen.nombre}}</span>
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row" *ngIf="usuario.empresa.usar_sucursales && (movimiento_inventario.almacenTraspaso || movimiento_inventario.movimiento_inventario_origen)">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Sucursal Traspaso : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        <span>{{movimiento_inventario.almacenTraspaso?movimiento_inventario.almacenTraspaso.sucursal.nombre:(movimiento_inventario.movimiento_inventario_origen?movimiento_inventario.movimiento_inventario_origen.almacen.sucursal.nombre:"")}}</span>
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="(usuario.empresa.usar_sucursales || usuario.empresa.usar_almacenes) && (movimiento_inventario.almacenTraspaso || movimiento_inventario.movimiento_inventario_origen)">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Almacen Traspaso : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        <span>{{movimiento_inventario.almacenTraspaso?movimiento_inventario.almacenTraspaso.nombre:(movimiento_inventario.movimiento_inventario_origen?movimiento_inventario.movimiento_inventario_origen.almacen.nombre:"")}}</span>
                    </label>    
                </div>
            </div>

            <div class="row" *ngIf="usuario.empresa.usar_facturacion">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Observación : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        <span >{{movimiento_inventario.observacion}}</span>
                    </label>    
                </div>
            </div>

            
        </div>
    </div>
    <hr>
    <div class="table-fixed-header" *ngIf="movimiento_inventario.detallesMovimientoInventario.length>0">
        <h5 class="text-blue-d2 mb-3">Detalle del movimiento</h5>
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>
                        <label>Nº</label>
                    </th>
                    <th>
                        <label>NOMBRE</label>
                    </th>
                    <th>
                        <label>CÓDIGO ITEM</label>
                    </th>
                    <th>
                        <label>UNIDAD DE MEDIDA</label>
                    </th>
                    <th>
                        <label>COSTO UNITARIO (Bs/u)</label>
                    </th>
                    <th>
                        <label>CANTIDAD (u)</label>
                    </th>
                    <th>
                        <label>IMPORTE (Bs)</label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalleMovimiento of movimiento_inventario.detallesMovimientoInventario;let i=index;">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{detalleMovimiento.producto.nombre}}
                    </td>
                    <td>
                        {{detalleMovimiento.producto.codigo}}
                    </td>
                    <td>
                        {{detalleMovimiento.producto.unidad_medida}}
                    </td>
                    <td>
                        {{detalleMovimiento.precio_unitario}}
                    </td>
                    <td>
                        {{detalleMovimiento.cantidad}}
                    </td>
                    <td>
                        {{detalleMovimiento.total}}
                    </td>
                </tr>
                <tr>
                    <td>
                        
                    </td>
                    <td>
                        <b>TOTALES</b>
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        
                    </td>
                    <td>
                        {{movimiento_inventario.total}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>