import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelRoutingModule } from './hotel-routing.module';
import { ReservasComponent } from './components/reservas/reservas.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { RegistroReservaComponent } from './components/registro-reserva/registro-reserva.component';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs,'es');

@NgModule({
  declarations: [ReservasComponent, RegistroReservaComponent],
  imports: [
    CommonModule,
    HotelRoutingModule,
    NgbModule,
    FormsModule,
    BaseModule,
    FilterPipeModule,
    OrderModule
  ],
  providers:[{ provide:LOCALE_ID, useValue:'es'}]
})
export class HotelModule { }
