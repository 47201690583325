<div class="modal-header card-header">
    <div class="col-10">
        
    </div>
    <div class="col-2">
        <button class="btn btn-danger" type="button" (click)="cerrarVistaEstudiante()">
          <i class="fa fa-times red2 text-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row mt-2 mt-md-4">

        <!-- the left side profile picture and other info -->
        <div class="col-12 col-md-4">
          <div class="card acard">
            <div class="card-body">
              <span class="d-none position-tl mt-2 pt-3px">
              <span class="text-white bgc-blue-d1 ml-2 radius-b-1 py-2 px-2">
                  <i class="fa fa-star"></i>
              </span>
              </span>
    
    
              <div class="d-flex flex-column py-3 px-lg-3 justify-content-center align-items-center">
    
                <div class="pos-rel">
                  <img alt="Profile image" src="{{rest_server+estudiante.persona.imagen}}" class="radius-round bord1er-2 brc-warning-m1" width="100" />
                  <span class=" position-tr bgc-success p-1 radius-round border-2 brc-white mt-2px mr-2px"></span>
                </div>
    
                <div class="text-center mt-2">
                  <h5 class="text-130 text-dark-m3">
                    {{estudiante.persona.nombre_completo}}
                  </h5>
    
                  <span class="text-80 text-primary text-600">
                      {{inscripcion?inscripcion.curso.nombre:''}}
                  </span>
    
                  <span class="d-none badge bgc-orange-l3 text-orange-d3 pt-2px pb-1 text-85 radius-round px-25 border-1 brc-orange-m3">
                      pro
                  </span>
                </div>
    
              </div><!-- /.d-flex -->
            </div><!-- /.card-body -->
          </div><!-- /.card -->
        </div><!-- .col -->
    
    
        <!-- the right side profile tabs -->
        <div class="col-12 col-md-8 mt-3 mt-md-0">
          <div class="card dcard h-100">
            <div class="card-body p-0">
              <div class="sticky-nav">
                <div class="position-tr w-100 border-t-4 brc-blue-m2 radius-2 d-md-none"></div>
    
                <ul id="profile-tabs" class="nav nav-tabs-scroll is-scrollable nav-tabs nav-tabs-simple p-1px pl-25 bgc-secondary-l4 border-b-1 brc-dark-l3 radius-t-1" role="tablist">
                  <li class="nav-item mr-2 mr-lg-3">
                    <a class="d-style nav-link active px-2 py-35 brc-primary-tp1" data-toggle="tab" href="#profile-tab-overview" role="tab" aria-controls="profile-tab-overview" aria-selected="true">
                      <span class="d-n-active text-dark-l1">1. Datos</span>
                      <span class="d-active text-dark-m3">1. Datos</span>
                    </a>
                  </li>
  
    
                  <li class="nav-item mr-2 mr-lg-3">
                    <a class="d-style nav-link px-2 py-35 brc-primary-tp1" data-toggle="tab" href="#profile-tab-timeline" role="tab" aria-controls="profile-tab-timeline" aria-selected="false">
                      <span class="d-n-active text-dark-l1">2. Inscripción</span>
                      <span class="d-active text-dark-m3">2. Inscripción</span>
                    </a>
                  </li>
    
                  <li class="nav-item mr-2 mr-lg-3">
                    <a class="d-style nav-link px-2 py-35 brc-primary-tp1" data-toggle="tab" href="#profile-tab-edit" role="tab" aria-controls="profile-tab-edit" aria-selected="false">
                      <span class="d-n-active text-dark-l1">3. Tutores</span>
                      <span class="d-active text-dark-m3">3. Tutores</span>
                    </a>
                  </li>
                </ul>
              </div><!-- /.sticky-nav-md -->
    
    
              <div class="tab-content px-0 tab-sliding flex-grow-1 border-0">
    
                <!-- overview tab -->
                <div class="tab-pane active show px-1 px-md-2 px-lg-4" id="profile-tab-overview">    
                  <div class="row mt-5">
                    <div class="col-12 px-4 mb-3">
    
                      <h4 class="text-dark-m3 text-140">
                        <i class="fa fa-info text-blue mr-1 w-2"></i>
                        Personales
                      </h4>
    
                      <hr class="w-100 mx-auto mb-0 brc-default-l2" />
    
                      <div class="bgc-white radius-1">
                        <table class="table table table-striped-default table-borderless">
                          <tr>
                            <td>
                              <i class="fas fa-code text-warning"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Código
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.codigo}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <i class="far fa-user text-success"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Nombre Completo
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.nombre_completo}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <i class="far fa-id-card text-primary"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              C.I.
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.ci}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <i class="fas fa-venus-double"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Género
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.genero.nombre}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <i class="fas fa-birthday-cake"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Fecha de Nacimiento
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.fecha_nacimiento | date:'dd/MM/yyyy'}}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <i class="far fa-envelope text-blue"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Correo
                            </td>
    
                            <td class="text-blue-d1 text-wrap">
                              {{estudiante.persona.correo_electronico}}
                            </td>
                          </tr>
    
                          <tr>
                            <td>
                              <i class="fa fa-phone text-purple"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Telefono
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.telefono_fijo}}-
                              {{estudiante.persona.telefono_movil}}
                            </td>
                          </tr>
    
                          <tr>
                            <td>
                              <i class="fa fa-map-marker text-orange-d1"></i>
                            </td>
    
                            <td class="text-95 text-600 text-secondary-d2">
                              Dirección
                            </td>
    
                            <td class="text-dark-m3">
                              {{estudiante.persona.direccion}}
                            </td>
                          </tr>
                        </table>
                      </div>
    
                    </div>
    
                  </div><!-- /.row -->
                </div><!-- /.tab-pane -->
  
    
    
    
                <!-- timeline tab -->
                <div class="tab-pane px-1 px-md-2 px-lg-3" id="profile-tab-timeline">
                  <div class="px-3 text-grey-m1 text-95">

                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                          <tr>
                              <th><label>Nº</label></th>
                              <th><label>Gestión</label></th>
                              <th><label>Curso</label></th>
                              <th><label>¿Consolidado?</label></th>
                              <th><label>Observación</label></th>
                              <th><label>Estado</label></th>
                          </tr>
                      </thead>
                      <tbody>
                          <ng-container *ngFor="let inscripcion of estudiante.inscripciones_colegio;let i=index;">
                              <tr>
                                  <td>
                                      {{(i+1)}}
                                  </td>
                                  <td>
                                      {{inscripcion.gestion.nombre}}
                                  </td>
                                  <td>
                                      {{inscripcion.curso.nombre}}
                                  </td>
                                  <td *ngIf="inscripcion.consolidado"><span class="badge badge-md badge-success">SÍ</span></td>
                                  <td *ngIf="!inscripcion.consolidado"><span class="badge badge-md badge-warning">NO</span></td>
                                  <td>
                                      {{inscripcion.observacion}}
                                  </td>
                                  <td *ngIf="inscripcion.activo"><span class="badge badge-md badge-success">ACTIVO</span></td>
                                  <td *ngIf="!inscripcion.activo"><span class="badge badge-md badge-danger">ANULADO</span></td>
                              </tr>
                          </ng-container>
                      </tbody>
                    </table>

                  </div>
                </div><!-- /.tab-pane -->
    
    
    
                <!-- profile edit tab -->
                <div class="tab-pane px-1 px-md-2 px-lg-4" id="profile-tab-edit">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Nombre Tutor</th>
                            <th>C.I.</th>
                            <th>Teléfonos</th>
                            <th>Parentesco</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let tutor of estudiante.tutores; let i=index;" >
                            <tr *ngIf="tutor.activo && !tutor.eliminado">
                                <td>{{i+1}}</td>
                                <td>{{tutor.persona.apellido_paterno}} {{tutor.persona.apellido_materno}} {{tutor.persona.nombres}}</td>
                                <td><i class="far fa-id-card text-primary"></i> {{tutor.persona.ci}}</td>
                                <td>{{tutor.persona.telefono_fijo}} - {{tutor.persona.telefono_movil}}</td>
                                <td>{{tutor.parentesco?tutor.parentesco.nombre:''}}</td>
                            </tr>
                         </ng-container>
                    </tbody>
                  </table>
                </div><!-- /.tab-pane -->
    
              </div><!-- /.tab-content -->
    
            </div>
          </div><!-- /.card -->
    
        </div>
      </div>
</div>


