<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-4 col-xs-4">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab impresion nav-link text-left py-3" (click)="verTab('impresion')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Impresión
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="contenido_tab impresion text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Impresión</legend>
                        <div class="row">
                            <div class="col-6">
                                <label>Tamaño papel</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="papel_nota_venta" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_pdf">
                                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
</div>