<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Pcientes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Pacientes
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                <div class="col-md-2 col-sm-12"><label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}} </label></div>
                <div class="col-md-4 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.categoria">
                        <option [ngValue]="categoria" *ngFor="let categoria of filter.categorias">{{categoria.nombre}}</option>
                    </select>
                </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoPaciente()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <div class="export btn-group">
                    <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                              <i class="fa fa-download text-green-d1"></i>
                              <span class="caret"></span>
                    </button>
                    <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-pacientes')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                      <i class="fa fa-upload text-red fileUpload-button"></i>
                      <input id="subir-excel-pacientes" style="display:none" type="file" class="form-control" (change)="subirExcelPacientes($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="pacientes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.apellido_paterno')">Apellido Paterno <span id="apellido_paterno" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.apellido_paterno')">Apellido Paterno <span id="apellido_paterno" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.nombres')">Nombres <span id="nombres" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.telefono')">Teléfono <span id="telefono" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.telefono_movil')">Teléfono Móvil <span id="telefono_movil" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.persona.fecha_nacimiento')">Fecha Nacimiento <span id="fecha_nacimiento" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Edad <span id="edad" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)" (click)="paginator.sortColumn('cliente.categoria')">Categoría <span id="categoria" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let paciente of pacientes; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td>{{paciente.cliente.codigo}}</td>
                <td>{{paciente.cliente.persona.apellido_paterno}}</td>
                <td>{{paciente.cliente.persona.apellido_materno}}</td>
                <td>{{paciente.cliente.persona.nombres}}</td>
                <td>{{paciente.cliente.persona.telefono}}</td>
                <td>{{paciente.cliente.persona.telefono_movil}}</td>
                <td>{{paciente.cliente.persona.fecha_nacimiento | date:'dd/MM/yyyy'}}</td>
                <td>{{paciente.cliente.persona.fecha_nacimiento?util.calcularEdad(paciente.cliente.persona.fecha_nacimiento):''}}</td>
                <td>{{paciente.cliente.categoria?paciente.cliente.categoria.nombre:''}}</td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarPaciente(paciente)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cliente)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="pacientes.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #pacientes_importacion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Pacientes a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || pacientes_importacion.length==0" (click)="guardarPacientes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color sticky-nav">
								<tr>
									<th>Nº</th>
									<th>Código</th>
									<th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
									<th>Nombres</th>
									<th>Telefono Movil</th>
                  <th>Telefono Fijo</th>
                  <th>Fecha de Nacimiento</th>
								</tr>
							</thead>

							<tbody>
								<tr *ngFor="let paciente of pacientes_importacion; let i=index;">
									<td>{{i+1}}</td>
									<td>{{paciente.cliente.codigo}}</td>
									<td>{{paciente.cliente.persona.apellido_paterno}}</td>
                  <td>{{paciente.cliente.persona.apellido_materno}}</td>
                  <td>{{paciente.cliente.persona.nombres}}</td>
                  <td>{{paciente.cliente.persona.telefono_movil}}</td>
                  <td>{{paciente.cliente.persona.telefono}}</td>
                  <td>{{paciente.cliente.persona.fecha_nacimiento | date:'dd/MM/yyyy'}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>