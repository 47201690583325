import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehiculosComponent } from './components/vehiculos/vehiculos.component';
import { ViajesComponent } from './components/viajes/viajes.component';
import { RutasComponent } from './components/rutas/rutas.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorTokenService } from '../base/services/interceptor-token/interceptor-token.service';
import { NgbDateCustomParserFormatter } from '../utils/dateformat';


@NgModule({
  declarations: [
    VehiculosComponent,
    ViajesComponent,
    RutasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BaseModule,
    NgbModule
  ],
  providers: [{provide:NgbDateParserFormatter,useClass:NgbDateCustomParserFormatter},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true}],
  exports:[
    VehiculosComponent,
    ViajesComponent,
    RutasComponent
  ]
})
export class TransporteModule { }
