import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { RegistroClienteComponent } from 'src/app/base/components/registro-cliente/registro-cliente.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { Util } from 'src/app/utils/utils';
import { ReservasService } from '../../services/reservas/reservas.service';

declare const $:any;

@Component({
  selector: 'app-registro-reserva',
  templateUrl: './registro-reserva.component.html',
  styleUrls: ['./registro-reserva.component.css']
})
export class RegistroReservaComponent extends BaseComponent implements OnInit {

  reserva:any={};
  grupos_productos:any[]=[];
  color:any={'style': 'red-style', 'stylebutton': 'red-style-button'};
  productos:any[]=[];
  productosProcesados:any[]=[];
  sucursales:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  razon_busqueda:any;
  searchFailed:any;
  searching:any;
  modalCliente:NgbModalRef;
  estados:any[]=[];
  fecha_reserva:any;
  ordenProductos:any;
  ambiente_seleccionado:any;
  editar_cantidad:boolean=false;
  fecha_minima_ingreso:any={year:2021,month:12,day:1};
  movimientos_egreso:any[];
  movimientosEgresoPanel:any[];
  tiposPago:any[];
  venta:any;
  descuento_maximo:any;
  pagoMinimo:any;
  canales:any[]=[];
  editar_precio:boolean=false;

  ventamodal:NgbModalRef;
  @ViewChild('ventamodal')
  private ventamodalref: TemplateRef<any>;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    private productosService:ProductosService,
	private orderPipe: OrderPipe,
	private clientesService:ClientesService,
  private toastr:ToastrService,
  private ventasService:VentasService,
  private pdfService:PdfService,
  public usuarioService:UsuarioService,
  public reservasService:ReservasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.blockUI.start();
    await this.obtenerEstadosReserva();
    if(this.reserva && this.reserva.id){
      let fecha_ingreso=new Date(this.reserva.fecha_ingreso);
      this.reserva.fecha_ingreso_texto={
        year:fecha_ingreso.getFullYear(),
        month:fecha_ingreso.getMonth()+1,
        day:fecha_ingreso.getDate()
      };
      this.reserva.tiempo_ingreso_texto={
        hour:fecha_ingreso.getHours(),
        minute:fecha_ingreso.getMinutes()
      };
      let fecha_salida=new Date(this.reserva.fecha_salida);
      this.reserva.fecha_salida_texto={
        year:fecha_salida.getFullYear(),
        month:fecha_salida.getMonth()+1,
        day:fecha_salida.getDate()
      };
      this.reserva.tiempo_salida_texto={
        hour:fecha_salida.getHours(),
        minute:fecha_salida.getMinutes()
      };
      this.fecha_minima_ingreso={
        year:fecha_ingreso.getFullYear(),
        month:fecha_ingreso.getMonth()+1,
        day:fecha_ingreso.getDate()-1
      }
    }else{
      let estado_reservado=this.estados.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTADO_MESA_RESERVADO)[0];
      let fecha_actual=this.fecha_reserva?new Date(this.fecha_reserva):new Date();
      let fecha_dia_siguiente=this.fecha_reserva?new Date(this.fecha_reserva):new Date();
      fecha_dia_siguiente.setDate(fecha_dia_siguiente.getDate()+1);
      this.reserva={
        ambientes:[],
        id_usuario:this.usuario.id,
        activo:true,
        fecha_ingreso_texto:{
          year:fecha_actual.getFullYear(),
          month:fecha_actual.getMonth()+1,
          day:fecha_actual.getDate()
        },
        tiempo_ingreso_texto:{
          hour:12,
          minute:0
        },
        fecha_salida_texto:{
          year:fecha_dia_siguiente.getFullYear(),
          month:fecha_dia_siguiente.getMonth()+1,
          day:fecha_dia_siguiente.getDate()
        },
        tiempo_salida_texto:{
          hour:11,
          minute:59
        },
        estado:estado_reservado
      }
      this.fecha_minima_ingreso={
        year:fecha_actual.getFullYear(),
        month:fecha_actual.getMonth()+1,
        day:fecha_actual.getDate()-1
      }
      if(this.ambiente_seleccionado){
        this.agregarAmbiente(this.ambiente_seleccionado);
      }
    }
    this.establecerSucursal((this.reserva && this.reserva.id_sucursal)?this.sucursales.filter(e => e.id==this.reserva.id_sucursal)[0]:this.sucursales[1]);
    this.blockUI.stop();
    
    this.color = this.persistenciaService.get("color")?this.persistenciaService.get("color"):{'style': 'red-style', 'stylebutton': 'red-style-button'};
    this.persistenciaService.set("color",this.color);
		this.obtenerGruposProductoEmpresa();
    this.establecerClienteInicio();
  }

  obtenerGruposProductoEmpresa(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,this.global_variable.Dictionary.TIPO_PRODUCTO_GRUPOS).subscribe((tipoGrupo:any)=>{
      let grupos=tipoGrupo.clases;
      this.grupos_productos=grupos;
    });
  }

  async obtenerEstadosReserva(){
    let grupos:any=await this.generalService.obtenerClases(this.global_variable.Dictionary.TIPO_ESTADOS_RESERVAS).toPromise();
    this.estados=grupos.clases;
  }

  async establecerSucursal(sucursal){
    this.reserva.sucursal={id:sucursal.id,nombre:sucursal.nombre};
    this.reserva.id_sucursal=sucursal.id;
    await this.cargarProductosPanel();
  }

  async cargarProductosPanel(){
	  this.productos=[];
		this.productosProcesados=[];
		let productos:any=await this.productosService.obtenerProductosPanel(this.usuario.id_empresa).toPromise();
      this.productos=productos;
      this.productosProcesados=productos;
      //this.ordenarProductos(false);
  }

  cerrarComponente(){
    this.alTerminar.emit({confirm:false});
  }

  async establecerClienteInicio(){
    let cliente_sin_nombre="SIN NOMBRE";
    let clientes:any=await this.clientesService.buscarClientePedido(this.usuario.id_empresa,(this.usuario.empresa.usar_clientes_sucursal?this.reserva.sucursal.id:0),0,cliente_sin_nombre).toPromise();
    if(clientes.length==0){
      this.establecerClientePedido({identificacion:cliente_sin_nombre,razon_social:cliente_sin_nombre,nit:0});
    }else{
      this.establecerClientePedido(clientes[0]);
    }
  }

  establecerClientePedido(event){
    this.reserva.cliente=event.item?event.item:event;
    this.razon_busqueda=this.reserva.cliente;
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClientePedido(this.usuario.id_empresa,(this.usuario.empresa.usar_clientes_sucursal?this.reserva.sucursal.id:0),0,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatter = (x: any) => (x.id?(x.identificacion==x.razon_social?x.identificacion:x.identificacion+" "+(x.razon_social?x.razon_social:"")):"");

  cambiarColor(color,buttonColor){
    // == save localstorage ====
    this.persistenciaService.set("color",{'style': color, 'stylebutton': buttonColor});
    this.color = {'style':color, 'stylebutton': buttonColor};
    // ==== fin ======
    
    $('#dialog-panel-ventas .widget-main').removeClass('red-style green-style skyblue-style brown-style');
    $('#dialog-panel-ventas .widget-main').addClass(color);

    $('#dialog-panel-ventas .widget-main .button-style').removeClass('red-style-button green-style-button skyblue-style-button brown-style-button');
    $('#dialog-panel-ventas .widget-main .button-style').addClass(buttonColor);
  }

  filtrarProductos(busqueda){
    this.productosProcesados=this.productos.filter(producto => (producto.nombre.toLowerCase().includes(busqueda.toLowerCase())) || (producto.descripcion && producto.descripcion.toLowerCase().includes(busqueda.toLowerCase())) || (producto.codigo && producto.codigo.toLowerCase().includes(busqueda.toLowerCase())));
    setTimeout(function(){
      //Util.aplicarSwiper(4,3,true,2);
    },5);
  }

  clasificarGrupo(grupo){
    if(grupo==0){
      this.productosProcesados=this.productos;
    }else{
      this.productosProcesados=this.productos.filter(producto => producto.id_grupo==grupo);
    }
    setTimeout(function(){
      //Util.aplicarSwiper(4,3,true,2);
    },5);
  }

  abrirPopupRegistroRapidoCliente(){
    this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.reserva.cliente));
    this.modalCliente.componentInstance.sucursales = this.sucursales;
    this.modalCliente.componentInstance.sucursal = this.reserva.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerClientePedido(res.cliente);
					this.modalCliente.close();
				}
			}else{
				this.modalCliente.close();
			}
		});
  }

  reiniciarCliente(){
    this.reserva.cliente={};
    this.razon_busqueda=null;
  }

  showHideFirstRow(){

		if($(".first-row").css( "display")=="none"){
			$('.first-row').show( "slow" );
		}else{
			$( ".first-row" ).hide( 1000 );
		}
  }

  async validarReserva(){
    let res=true;
    if(!this.razon_busqueda || !this.reserva.cliente || !this.reserva.cliente.razon_social || !this.reserva.cliente.nit){
      res=res && false;
      this.toastr.error("Debe especificar el cliente!");
    }
    if(this.reserva.ambientes.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un ambiente a la reserva!");
    }else{
      let fecha_inicio=Util.convertirObjetoAfecha(this.reserva.fecha_ingreso_texto);
      let fecha_fin=Util.convertirObjetoAfecha(this.reserva.fecha_salida_texto);
      fecha_inicio.setHours(this.reserva.tiempo_ingreso_texto.hour);
      fecha_inicio.setMinutes(this.reserva.tiempo_ingreso_texto.minute);
      fecha_fin.setHours(this.reserva.tiempo_salida_texto.hour);
      fecha_fin.setMinutes(this.reserva.tiempo_salida_texto.minute);
      for(let i=0;i<this.reserva.ambientes.length;i++){
        if(!this.reserva.ambientes[i].eliminado){
          let res_ambiente:any=await this.reservasService.verificarDisponibilidadAmbiente(this.reserva.ambientes[i].producto.id,fecha_inicio,fecha_fin).toPromise();
          if(res_ambiente.length>0){
            for(let j=0;j<res_ambiente.length;j++){
              if(this.reserva.id!=res_ambiente[j].reserva.id){
                res=res && false;
                let fi=new Date(res_ambiente[j].reserva.fecha_ingreso);
                let ff=new Date(res_ambiente[j].reserva.fecha_salida);
                this.toastr.error("Existe una reserva desde "+(fi.getDate()+"/"+(fi.getMonth()+1)+"/"+fi.getFullYear()+" "+fi.getHours()+":"+fi.getMinutes())+" hasta "+(ff.getDate()+"/"+(ff.getMonth()+1)+"/"+ff.getFullYear()+" "+ff.getHours()+":"+ff.getMinutes()));
              }
            }
          }
        }
      }
    }
    if(!this.reserva.fecha_ingreso_texto || !this.reserva.fecha_ingreso_texto.year || !this.reserva.fecha_ingreso_texto.month || !this.reserva.fecha_ingreso_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de ingreso para la reserva!");
    }
    if(!this.reserva.fecha_salida_texto || !this.reserva.fecha_salida_texto.year || !this.reserva.fecha_salida_texto.month || !this.reserva.fecha_salida_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de salida para la reserva!");
    }
    if(!this.reserva.tiempo_ingreso_texto || !this.reserva.tiempo_ingreso_texto.hour || this.reserva.tiempo_ingreso_texto.minute==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el tiempo de ingreso para la reserva!");
    }
    if(!this.reserva.tiempo_salida_texto || !this.reserva.tiempo_salida_texto.hour || this.reserva.tiempo_salida_texto.minute==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el tiempo de salida para la reserva!");
    }
    return res;
  }

  async guardarReserva(venta){
    this.blockUI.start();
		if(await this.validarReserva()){
      this.reserva.cliente.identificacion=(!this.reserva.cliente.id || !this.reserva.id_cliente)?(this.razon_busqueda.id?this.razon_busqueda.identificacion:this.razon_busqueda):this.reserva.cliente.identificacion;
      this.reserva.cliente.id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.reserva.sucursal.id:null;
			this.reserva.fecha_ingreso=this.reserva.fecha_ingreso_texto?Util.convertirObjetoAfecha(this.reserva.fecha_ingreso_texto):null;
      this.reserva.fecha_salida=this.reserva.fecha_salida_texto?Util.convertirObjetoAfecha(this.reserva.fecha_salida_texto):null;
      this.reserva.fecha_ingreso.setHours(this.reserva.tiempo_ingreso_texto.hour);
      this.reserva.fecha_ingreso.setMinutes(this.reserva.tiempo_ingreso_texto.minute);
      this.reserva.fecha_salida.setHours(this.reserva.tiempo_salida_texto.hour);
      this.reserva.fecha_salida.setMinutes(this.reserva.tiempo_salida_texto.minute);
			if(this.reserva.id){
        this.reservasService.actualizarReserva(this.reserva).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
					if(venta){
						//this.imprimirVenta(venta);
          }
          this.alTerminar.emit({confirm:true,res:res});
        });
			}else{
        this.reservasService.guardarReserva(this.reserva).subscribe((res:any)=>{
          this.blockUI.stop();
          this.alTerminar.emit({confirm:true,res:res});
					this.toastr.success('Reserva registrada exitosamente!');
        });
			}
		}else{
      this.blockUI.stop();
    }
  }

  ordenarProductos(orden){
  }

  agregarAmbiente(ambiente){
    let detalle_ambiente=this.buscarDetalleAmbiente(ambiente);
    if(!detalle_ambiente){//detallePedido no Encontrado
      detalle_ambiente={producto:ambiente,precio_unitario:ambiente.precio_unitario,cantidad:1,descuento:0};
      this.reserva.ambientes.push(detalle_ambiente);
      this.calcularImporteDetallePedido(detalle_ambiente);
      this.establecerCantidadDiasDetallesReserva();
    }
  }

  buscarDetalleAmbiente(ambiente){
    var j=0,encontrado=false,detalle_ambiente=null;
    while(j<this.reserva.ambientes.length && !encontrado){
      if(!this.reserva.ambientes[j].eliminado && this.reserva.ambientes[j].producto && (this.reserva.ambientes[j].producto.id==ambiente.id)){
        encontrado=true;
        detalle_ambiente=this.reserva.ambientes[j];
      }
      j++;
    }
    return detalle_ambiente;
  }

  calcularImporteDetallePedido(detalle_ambiente){
    detalle_ambiente.importe=Math.round((detalle_ambiente.cantidad*detalle_ambiente.precio_unitario)*1000)/1000;
    detalle_ambiente.total=detalle_ambiente.importe-detalle_ambiente.descuento;
    this.sumarTotales();
  }

  sumarTotales(){
    var sumaImporte=0,sumaTotal=0;
    for(var i=0;i<this.reserva.ambientes.length;i++){
      if(!this.reserva.ambientes[i].eliminado){
        sumaImporte=sumaImporte+this.reserva.ambientes[i].importe;
        sumaTotal=sumaTotal+this.reserva.ambientes[i].total;
      }
    }		
    this.reserva.importe=Math.round((sumaImporte)*1000)/1000;
    this.reserva.total=Math.round((sumaTotal)*1000)/1000;
  }

  modificarCantidad(){
		this.editar_cantidad=true;
  }

  establecerCantidad(){
		this.editar_cantidad=false;
  }

  establecerFechaIngreso(nueva_fecha_ingreso){
    let fecha_ingreso=Util.convertirObjetoAfecha(nueva_fecha_ingreso);
    fecha_ingreso.setDate(fecha_ingreso.getDate()+1);
    this.reserva.fecha_salida_texto={
      year:fecha_ingreso.getFullYear(),
      month:fecha_ingreso.getMonth()+1,
      day:fecha_ingreso.getDate()
    }
    this.establecerCantidadDiasDetallesReserva();
  }

  establecerFechaSalida(nueva_fecha_salida){
    let fecha_ingreso=Util.convertirObjetoAfecha(this.reserva.fecha_ingreso_texto);fecha_ingreso.setHours(this.reserva.tiempo_ingreso_texto.hour);fecha_ingreso.setMinutes(this.reserva.tiempo_ingreso_texto.minute);
    let fecha_salida=Util.convertirObjetoAfecha(nueva_fecha_salida);fecha_salida.setHours(this.reserva.tiempo_salida_texto.hour);fecha_salida.setMinutes(this.reserva.tiempo_salida_texto.minute);
    if(fecha_ingreso>fecha_salida){
      fecha_salida.setDate(fecha_salida.getDate()-1);
      this.reserva.fecha_ingreso_texto={
        year:fecha_salida.getFullYear(),
        month:fecha_salida.getMonth()+1,
        day:fecha_salida.getDate()
      }
    }
    this.establecerCantidadDiasDetallesReserva();
  }

  establecerCantidadDiasDetallesReserva(){
    let fecha_ingreso=Util.convertirObjetoAfecha(this.reserva.fecha_ingreso_texto);fecha_ingreso.setHours(this.reserva.tiempo_ingreso_texto.hour);fecha_ingreso.setMinutes(this.reserva.tiempo_ingreso_texto.minute);
    let fecha_salida=Util.convertirObjetoAfecha(this.reserva.fecha_salida_texto);fecha_salida.setHours(this.reserva.tiempo_salida_texto.hour);fecha_salida.setMinutes(this.reserva.tiempo_salida_texto.minute);
    let diferencia_dias=this.calcularDiferenciaFechaIngresoSalida(fecha_ingreso,fecha_salida);
    diferencia_dias=diferencia_dias<1?1:diferencia_dias;
    for(let i=0;i<this.reserva.ambientes.length;i++){
      if(!this.reserva.ambientes[i].eliminado){
        this.reserva.ambientes[i].cantidad=diferencia_dias;
        this.calcularImporteDetallePedido(this.reserva.ambientes[i]);
      }
    }
  }

  calcularDiferenciaFechaIngresoSalida(fecha_ingreso,fecha_salida){
    return Math.ceil(Util.obtenerDiferenciaDiasFechas(fecha_salida,fecha_ingreso));
  }

  async abrirPopupDatosFacturacion(){
    this.blockUI.start();
    await this.obtenerMovimientosEgreso();
    await this.obtenerTiposPago();
		if(!this.reserva.id){
			if(!this.reserva.cliente.id){
				this.reserva.cliente.razon_social=this.reserva.cliente.identificacion?this.reserva.cliente.identificacion:this.razon_busqueda;
				this.reserva.cliente.nit=0;
			}
		}
		this.venta={cliente:this.reserva.id?(JSON.parse(JSON.stringify(this.reserva.cliente))):this.reserva.cliente,pagado:0};
    this.venta.cliente.id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.reserva.sucursal.id:null;
		this.venta.dias_credito=1;
		this.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
		this.venta.importe=this.reserva.importe;
		this.venta.descuento=0;
		this.descuento_maximo=this.venta.importe;
		this.venta.total=this.reserva.total;
		this.venta.pagado=this.reserva.total;
		this.venta.cambio=0;
    this.venta.a_cuenta=0;
    this.venta.saldo=this.reserva.total;
		this.movimientosEgresoPanel=this.movimientos_egreso;
		this.venta.transaccion=this.movimientosEgresoPanel[0];
		//this.tiposPago=this.tiposPago.filter(e => e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_CONT || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_TARJ || e.nombre_corto == GlobalVariable.Dictionary.TIPO_PAGO_TRANS);
		this.venta.tipoPago=this.tiposPago.filter(e => e.nombre == GlobalVariable.Dictionary.TIPO_PAGO_CREDITO)[0];
		this.venta.fecha=new Date();
		if(this.usuario.empresa.usar_facturacion && this.reserva.sucursal.actividadesDosificaciones.length>0){
			this.venta.actividades=[];
			for(var u=0;u<this.reserva.sucursal.actividadesDosificaciones.length;u++){
				this.venta.actividades.push(this.reserva.sucursal.actividadesDosificaciones[u].actividad);
			}
			this.venta.actividad=this.venta.actividades[0];
		}
		this.venta.fechaTexto=this.venta.fecha.getDate()+"/"+(this.venta.fecha.getMonth()+1)+"/"+this.venta.fecha.getFullYear();
		//this.buscarNit({which:13},"0");
		this.establecerCliente(this.venta.cliente);
    this.calcularCambio();
    this.blockUI.stop();
		this.ventamodal=this.modalService.open(this.ventamodalref, {windowClass:'datos-venta', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.ventamodal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async obtenerMovimientosEgreso(){
    let entidad:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
    let moingorg=entidad.clases;
    this.movimientos_egreso=moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
    if(this.usuario.empresa.usar_facturacion){
    this.movimientos_egreso=this.movimientos_egreso.concat(moingorg.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
    }
  }

  async obtenerTiposPago(){
    let entidad:any=await this.generalService.obtenerClases("TIPA").toPromise();
	  this.tiposPago=entidad.clases;
  }

  establecerCliente(cliente){
    this.reserva.cliente=cliente;
    this.razon_busqueda=cliente;
		this.venta.cliente=cliente;
		this.venta.razon_social=cliente.razon_social;
		this.venta.nit_ci=cliente.nit;
		Util.enfocarElemento('razon_social');
  }

  calcularCambio(){
		this.venta.cambio=Math.round((this.venta.pagado-this.reserva.total)*100)/100;
		this.pagoMinimo=this.reserva.total;
  }

  buscarNit(evento,nit){
    if (evento.which === 13){
      let id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.venta.sucursal.id:0;

      this.clientesService.buscarClienteNit(this.usuario.id_empresa,nit,id_sucursal).subscribe((results:any[]) => {
        if(results.length==1  || results.length>1){
          this.establecerCliente(results[0]);
          Util.enfocarElemento("razon_social");
				}else{
          this.establecerCliente({nit:nit,razon_social:null});
					Util.enfocarElemento("razon_social");
				}
      });
    }
  }

  async generarVentaReserva() {
    this.blockUI.start();
    this.venta.sucursal=this.reserva.sucursal;
    if(await this.validarPagoReserva() && await this.validarReserva()){
      await this.obtenerCanales();
      this.venta.canal=this.canales[0];
      this.venta.total=this.reserva.total;
      this.venta.importe=this.reserva.importe;
      this.venta.usuario={id:this.usuario.id,id_empresa:this.usuario.id_empresa};
      this.venta.id_empresa=this.usuario.empresa.id;
      this.venta.detallesVenta=[];
      this.venta.razon_social=this.venta.cliente.razon_social;
      this.venta.cliente.nit=this.venta.cliente.nit.toString().trim();
      this.venta.nit_ci=this.venta.cliente.nit;
      this.venta.detallesVenta=this.reserva.ambientes;
      this.venta.a_cuenta=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.a_cuenta:this.venta.total;
      this.venta.saldo=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.saldo:0;
      this.ventasService.guardarVenta(this.venta).subscribe((res:any)=>{
        if(res.tiene_error){
          this.blockUI.stop();
          this.toastr.error(res.mensaje);
        }else{
          this.reserva.id_venta=res.venta.id;
          this.reserva.id_cliente=res.venta.cliente.id;
          this.reserva.cliente=res.venta.cliente;
          this.razon_busqueda=this.reserva.cliente;
          this.guardarReserva(res.venta);
          this.blockUI.stop();
          this.alTerminar.emit({confirm:true,reserva:this.reserva});
          this.ventamodal.close();
          this.toastr.success(res.mensaje);
        }
      });
    }else{
      this.blockUI.stop();
    }
  }

  async validarPagoReserva(){
    let res=true;
    if((!this.venta.cliente || this.venta.cliente.nit==undefined || this.venta.cliente.nit==null) || !this.venta.cliente.razon_social){
      res=res && false;
      this.toastr.error("Debe especificar datos del cliente, nit y razon social!");
    }
    if(this.venta.descuento==null || this.venta.descuento==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el descuento!");
    }
    if(this.venta.pagado==null || this.venta.pagado==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el dato de pagado!");
    }
    if(this.venta.tipoPago.nombre_corto=="CRE" && (this.venta.a_cuenta==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar el importe a cuenta de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.venta.total || this.venta.cambio<0)){
      res=res && false;
      this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
    }
    if(this.venta.tipoPago.nombre_corto=="CRE" && (this.venta.a_cuenta>=this.venta.total)){
      res=res && false;
      this.toastr.error("El importe a cuenta de la venta no debe exceder al importe total de la venta!");
    }
    if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && this.venta.sucursal.actividadesDosificaciones){
      let fecha_limite_emision=new Date(this.venta.sucursal.actividadesDosificaciones[0].dosificacion.fecha_limite_emision);
      fecha_limite_emision.setHours(23);
      fecha_limite_emision.setMinutes(59);
      fecha_limite_emision.setSeconds(59);
      let fecha_actual=new Date();
      if(fecha_limite_emision<fecha_actual){
        res=res && false;
        this.toastr.error("La fecha límite de emisión "+(fecha_limite_emision.getDate()+"/"+(fecha_limite_emision.getMonth()+1)+"/"+fecha_limite_emision.getFullYear())+" de su dosificación feneció, debe renovar!");
      }
    }
    return res;
  }

  async obtenerCanales(){
    this.blockUI.start();
    let conf:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise(); 
    let configuracion_aplicacion_ventas=conf;
    let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
    this.canales=entidad.clases;
    if(this.usuario.empresa.usar_permisos_avanzados_usuarios && configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados.length>0){
      let canales=[];
      for(let i=0;i<configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados.length;i++){
        canales.push(entidad.clases.filter(cnf => cnf.id==configuracion_aplicacion_ventas.datos.opciones_permisos.canales_asignados[i])[0]);
      }
      this.canales=canales;
    }
    this.blockUI.stop();
  }

  calcularTotalVenta(){
		this.reserva.descuento=this.venta.descuento;
		this.reserva.total=this.reserva.importe-this.reserva.descuento;
		this.venta.total=this.venta.importe-this.venta.descuento;
		this.calcularCambio();
  }

  calcularSaldo(){
    this.venta.saldo=this.venta.total-this.venta.a_cuenta;
  }

  eliminarDetalleReserva(detalle_reserva){
		detalle_reserva.eliminado=true;
		this.sumarTotales();
  }

  modificarPrecio(){
		this.editar_precio=true;
  }
  
  establecerPrecio(){
		this.editar_precio=false;
  }

}
