import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-configuracion-aplicacion-cuentas-por-cobrar',
  templateUrl: './configuracion-aplicacion-cuentas-por-cobrar.component.html',
  styleUrls: ['./configuracion-aplicacion-cuentas-por-cobrar.component.css']
})
export class ConfiguracionAplicacionCuentasPorCobrarComponent extends BaseComponent implements OnInit {

  tamanos_papel:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.obtenerTamanosPapel();
    this.verTab("impresion");
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
