<div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <span class="text-blue text-125">Registro Usuario</span>

          <div class="card-toolbar ml-auto no-border pr-2 d-none">
            <label>
              <span class="align-middle d-block d-sm-inline">Validation:</span>
              <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
            </label>
          </div>
          <div class="card-toolbar pl-3">
            <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
              <i class="fa fa-chevron-left"></i>
            </button>
            <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
              <i class="fa fa-chevron-right"></i>
            </button>
            <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
              <i class="fa fa-arrow-right"></i>
            </button>
            <button (click)="cerrarRegistroUsuario()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                <i class="fas fa-times-circle"></i>
              </button>
          </div>
        </div>

        <div class="card-body px-2">
          <div id="{{wizard_edicion_usuario}}" class="d-none">
            <ul class="mx-auto">
              <li class="wizard-progressbar"></li>
              <li>
                <a href="#step-1">
                  <span class="step-title">
                      1
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos Personales
                </span>
              </li>
              <li>
                <a href="#step-2">
                  <span class="step-title">
                      2
                  </span>

                  <span class="step-title-done">
                      <i class="fa fa-check text-success-m1"></i>
                  </span>
                </a>
                <span class="step-description">
                        Datos de Usuario
                </span>
              </li>
              <li>
                  <a href="#step-3">
                    <span class="step-title">
                        3
                    </span>

                    <span class="step-title-done">
                        <i class="fa fa-check text-success-m1"></i>
                    </span>
                  </a>
                  <span class="step-description">
                          Datos de la Empresa
                  </span>
                </li>
            </ul>

            <div class="px-2 py-2 mb-4">
              <div id="step-1" class="">

                <form novalidate>
                    <div class="  form-row" *ngIf="!usuario.id_empresa">
                        <div class="col-4 col-form-label text-sm-right">
                            Empresa
                        </div>
                        <div class="col-8">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <select (change)="buscarEmpresa(usuario_item.empresa)" [compareWith]="compararObjectos" class="form-control" required name="empresa" [(ngModel)]="usuario_item.empresa">
                                    <option [ngValue]="empresa" *ngFor="let empresa of empresas">{{empresa.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="  form-row">
                      <div [ngClass]="{'text-danger-m1':(!usuario_item.persona.nombres)}" class="col-3 col-form-label text-sm-right">
                          Nombre
                      </div>
                      <div class="col-9">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input required type="text" id="nombre" name="nombre" [(ngModel)]="usuario_item.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!usuario_item.persona.nombres)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              <i *ngIf="!usuario_item.persona.nombres" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!usuario_item.persona.nombres" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>
                          <span *ngIf="!usuario_item.persona.nombres" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>

                      <div class="col-2 col-form-label text-sm-right">
                          Apellido Paterno
                      </div>
                      <div class="col-4">
                          <div class="d-inline-flex align-items-center col-12 px-0">
                              <input required type="text" id="apellido_paterno" name="apellido_paterno" [(ngModel)]="usuario_item.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>

                      <div class="col-2 col-form-label text-sm-right">
                          Apellido Materno
                      </div>
                      <div class="col-4">
                          <div class="d-inline-flex align-items-center col-12 px-0">
                              <input required type="text" id="apellido_materno" name="apellido_materno" [(ngModel)]="usuario_item.persona.apellido_materno" placeholder="Apellido Materno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                          </div>
                      </div>
                    </div>
                    <div class="  form-row" *ngIf="usuario_item.empresa.usar_gece">
                        <div class="col-6 col-form-label text-sm-right">
                            Códigos de libros (separado por coma)
                        </div>
                        <div class="col-6">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                                <input type="text" name="datos" [(ngModel)]="usuario_item.codigos_libros" (change)="construirDatos()" placeholder="Códigos de libros" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                    <div class="  form-row" *ngIf="usuario_item.empresa.usar_empleados">
                        <div class="col-1 col-form-label text-sm-right">
                            Género
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="genero" [(ngModel)]="usuario_item.persona.genero">
                                    <option *ngIf="!usuario_item.id" [ngValue]="null" selected>Ninguno</option>
                                    <option [ngValue]="genero" *ngFor="let genero of generos">{{genero.nombre}}</option>
                                </select>
                            </div>
                        </div>
  
                        <div class="col-1 col-form-label text-sm-right">
                            C.I.
                        </div>
                        <div class="col-3">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="usuario_item.persona.ci" placeholder="C.I." class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <ng-container *ngIf="usuario_item.persona.empleado">
                            <div class="col-1 col-form-label text-sm-right">
                                Cargo
                            </div>
                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 px-0">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="cargo" [(ngModel)]="usuario_item.persona.empleado.cargo">
                                        <option *ngIf="!usuario_item.id" [ngValue]="null" selected>Ninguno</option>
                                        <option [ngValue]="cargo" *ngFor="let cargo of cargos">{{cargo.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="usuario.empresa && usuario.empresa.usar_profesores_instituto">
                            <div  class="col-1 col-form-label text-sm-right">
                                ¿Es Profesor?
                            </div>
                            <div class="col-1">
                                <div class="d-inline-flex align-items-center col-12 px-0">
                                    <label>
                                        <input name="switch-field-22" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.persona.empleado.es_profesor"/>
                                    </label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="  form-row">
                        <div class="col-4 col-form-label text-sm-right">
                          Foto
                        </div>

                        <div class="col-2" *ngIf="usuario_item.id">
                          <img  src="{{rest_server+usuario_item.persona.imagen}}" width="100">
                        </div>
                        <div class="col-6">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <div>
                                  <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                              </div>
                          </div>
                        </div>
                    </div>
                </form>

              </div>

              <div id="step-2">
                  <div class="  form-row">
                      <div [ngClass]="{'text-danger-m1':(!usuario_item.nombre_usuario)}" class="col-4 col-form-label text-sm-right">
                          Usuario
                      </div>
                      <div class="col-8">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input (blur)="validarUsuario(usuario_item)" [disabled]="usuario.id_empresa && usuario_item.id" required type="email" id="nombre_usuario" name="nombre_usuario" [(ngModel)]="usuario_item.nombre_usuario" placeholder="Nombre de usuario" [ngClass]="{'brc-danger-m1':(!usuario_item.nombre_usuario)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              <i *ngIf="!usuario_item.nombre_usuario" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!usuario_item.nombre_usuario" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>
                          <span *ngIf="!usuario_item.nombre_usuario" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>

                      <div [ngClass]="{'text-danger-m1':(!usuario_item.clave)}" class="col-4 col-form-label text-sm-right">
                          Clave
                      </div>
                      <div class="col-8">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input required type="password" id="clave" name="clave" [(ngModel)]="usuario_item.clave" placeholder="Clave" [ngClass]="{'brc-danger-m1':(!usuario_item.clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                              <i *ngIf="!usuario_item.clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!usuario_item.clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>
                          <span *ngIf="!usuario_item.clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>

                      <div [ngClass]="{'text-danger-m1':(!usuario_item.clave)}" class="col-4 col-form-label text-sm-right">
                          Repeticion Clave
                      </div>
                      <div class="col-8">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <input value="{{usuario_item.clave}}" (blur)="verificarClaveRepeticion(usuario_item)" required type="password" id="repeticion_clave" name="repeticion_clave" [(ngModel)]="usuario_item.repeticion_clave" placeholder="Repeticion Clave" [ngClass]="{'brc-danger-m1':(!usuario_item.repeticion_clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                              <i *ngIf="!usuario_item.repeticion_clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                          </div>
                          <div *ngIf="!usuario_item.repeticion_clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                              <div class="arrow brc-danger-m1 mt-0"></div>
                              <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                          </div>
                          <span *ngIf="!usuario_item.repeticion_clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                      </div>

                      <div class="col-4 col-form-label text-sm-right">
                          Rol
                      </div>
                      <div class="col-8">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <select (change)="buscarAplicacionesRol(usuario_item.rol)" [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="usuario_item.rol">
                                  <option [ngValue]="rol" *ngFor="let rol of roles_registro">{{rol.nombre}}</option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="  form-row">
                      <div class="col-sm-2 col-form-label text-sm-right">¿Activo?</div>
                      <div class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.activo"/>
                              </label>
                          </div>
                      </div>
                      <div class="col-sm-2 col-form-label text-sm-right">¿Cierre de Sesión Automático?</div>
                      <div class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <label>
                                  <input name="switch-field-2" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.cierre_sesion_automatico"/>
                              </label>
                          </div>
                      </div>
                      <div class="col-sm-2 col-form-label text-sm-right">¿Multidispositivo?</div>
                      <div class="col-sm-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <label>
                                  <input name="switch-field-3" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.multidispositivo"/>
                              </label>
                          </div>
                      </div>
                      <ng-container *ngIf="(usuario.empresa?usuario.empresa.usar_georeferenciacion_usuarios:false)">
                          <div class="col-sm-2 col-form-label text-sm-right">¿Georeferenciacion Automatico?</div>
                          <div class="col-sm-2">
                              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                  <label>
                                      <input name="switch-field-4" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.georeferenciacion_automatico"/>
                                  </label>
                              </div>
                          </div>
                      </ng-container>
                      <ng-container *ngIf="!usuario_item.id">
                          <div class="col-sm-2 col-form-label text-sm-right">¿Enviar Correo?</div>
                          <div class="col-sm-2">
                              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                  <label>
                                      <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="usuario_item.enviar_correo"/>
                                  </label>
                              </div>
                          </div>
                      </ng-container>
                  </div>
              </div>
              <div id="step-3">
                  <div class="form-row">
                      <div class="col-4 col-md-2 col-form-label text-sm-right">
                          Sucursales
                      </div>
                      <div class="col-8 col-md-2">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <ngx-bootstrap-multiselect *ngIf="usuario_item.sucursales" [options]="sucursales" [(ngModel)]="usuario_item.sucursales" (ngModelChange)="seleccionarSucursal($event)"></ngx-bootstrap-multiselect>
                          </div>
                      </div>
                    <ng-container *ngIf="(usuario.empresa?usuario.empresa.usar_almacenes_usuario:false)">
                        <div class="col-4 col-md-2 col-form-label text-sm-right">
                            Almacenes
                        </div>
                        <div class="col-8 col-md-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle> Seleccionar Almacen</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <label *ngFor="let almacen of almacenes; let i=index;"><input type="checkbox" name="sdfsd" class="input-lg bgc-blue" (change)="agregarRemoverAlmacen(almacen)" [value]="almacenes[i]" (ngModel)="almacen" checked="{{almacen.ticked}}">{{almacen.nombre}}</label>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(usuario_item.empresa.usar_acceso_gestion_escolar)">
                        <div class="col-4 col-md-2 col-form-label text-sm-right">
                            Gestiones
                        </div>
                        <div class="col-8 col-md-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <div ngbDropdown class="d-inline-block">
                                    <button class="btn btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle> Seleccionar Gestiones</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                                        <label *ngFor="let gestion of gestiones; let i=index;"><input type="checkbox" name="sdfsd" class="input-lg bgc-blue" (change)="agregarRemoverGestion(gestion)" [value]="gestiones[i]" (ngModel)="gestion" checked="{{gestion.ticked}}">{{gestion.nombre}}</label>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <hr>
                  <div class="  form-row">
                       <ng-container *ngIf="usuario.id_empresa">
                        <div class="col-4 col-md-3 col-form-label text-sm-right">
                            Actualizar aplicaciones
                        </div>
                        <div class="col-8 col-md-3">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <a class="card-toolbar-btn text-primary-m1" href="javascript:void(0)" (click)="buscarModulosUsuario(usuario.empresa)">
                                    <i class="fa fa-sync bigger-130 red2"></i>
                                </a>
                            </div>
                        </div>
                      </ng-container>
                      <div class="col-4 col-md-2 col-form-label text-sm-right">
                          Aplicación de partida
                      </div>
                      <div class="col-8 col-md-4">
                          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                              <select [compareWith]="compararObjectos" class="form-control" name="aplicacion_partida" [(ngModel)]="usuario_item.aplicacion_partida">
                                  <option [ngValue]="aplicacionUsuario.aplicacion" *ngFor="let aplicacionUsuario of usuario_item.aplicacionesUsuario | filterBy:{puede_ver:true}">{{aplicacionUsuario.aplicacion.titulo}}</option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <hr>
                  <div class="form-row">
                    <div class="col-12">
                        <table id="tabla-permisos" class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Nombre Aplicación</th>
                                    <th>¿Puede Crear?
                                        <label class="pos-rel">
                                            <input (change)="seleccionarCapacidad(1)" type="checkbox" class="ace" [(ngModel)]="todos_app_usuario.puede_crear"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </th>
                                    <th>¿Puede Ver?
                                        <label class="pos-rel">
                                            <input (change)="seleccionarCapacidad(2)" type="checkbox" class="ace" [(ngModel)]="todos_app_usuario.puede_ver"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </th>
                                    <th>¿Puede Modificar?
                                        <label class="pos-rel">
                                            <input (change)="seleccionarCapacidad(3)" type="checkbox" class="ace" [(ngModel)]="todos_app_usuario.puede_modificar"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </th>
                                    <th>¿Puede Eliminar?
                                        <label class="pos-rel">
                                            <input (change)="seleccionarCapacidad(4)" type="checkbox" class="ace" [(ngModel)]="todos_app_usuario.puede_eliminar"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </th>
                                    <th>
                                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                      <div class="fht-cell"></div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let aplicacionUsuario of usuario_item.aplicacionesUsuario; let i=index;">
                                <tr *ngIf="!aplicacionUsuario.eliminado">
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        {{aplicacionUsuario.aplicacion.titulo}}
                                    </td>
                                    <td class="center">
                                        <label class="pos-rel">
                                            <input type="checkbox" class="ace" [(ngModel)]="aplicacionUsuario.puede_crear"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <label class="pos-rel">
                                            <input type="checkbox" class="ace" [(ngModel)]="aplicacionUsuario.puede_ver"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <label class="pos-rel">
                                            <input type="checkbox" class="ace" [(ngModel)]="aplicacionUsuario.puede_modificar"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <label class="pos-rel">
                                            <input type="checkbox" class="ace" [(ngModel)]="aplicacionUsuario.puede_eliminar"/>
                                            <span class="lbl"></span>
                                        </label>
                                    </td>
                                    <td>
                                      <a (click)="eliminarAplicacion(aplicacionUsuario)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                                        <i class="fa fa-trash-alt"></i>
                                      </a>
                                    </td>
                                </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                  </div>
              </div>
            </div>
          </div>

        </div>
      </div> <!-- .card -->

    </div>
  </div>