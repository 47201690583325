<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Productos Impresión Código de Barras</h4>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="imprimirCodigosBarras()">
            <i class="fa fa-print align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="card card-info card-outline">
        <div class="box box-primary">
            <div class="box-body">
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>
                                <label>PRODUCTO</label>
                            </th>
                            <th>
                                <label>CÓD. ITEM</label>
                            </th>
                            <th>
                                <label>UNID. MED.</label>
                            </th>
                            <th>
                                <label>CÓD. FÁBRICA</label>
                            </th>
                            <th>
                                <label>CANT. (u)</label>
                            </th>
                            <th>
                                <label>ACC.</label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-0" id="gallery">
                                <input autocomplete="off"
                                [disabled]="detalle_producto.producto && detalle_producto.producto.id" [(ngModel)]="producto_busqueda"
                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                type="text" class="form-control"/>

                                <ng-template #rtp let-r="result" let-t="term">
                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                </ng-template>

                                <a href="javascript:void(0)" *ngIf="detalle_producto.producto && detalle_producto.producto.id" (click)="inicializarDetalleProducto()" class="card-toolbar-btn text-danger-m1">
                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                </a>
                                <hr>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="imagen-producto-vista clearfix" *ngIf="detalle_producto.producto && detalle_producto.producto.id">
                                            <a href="{{rest_server+detalle_producto.producto.imagen}}" data-rel="colorbox" title="{{detalle_producto.producto.nombre}}">
                                                <img alt="sin imagen" src="{{rest_server+detalle_producto.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{detalle_producto.producto?detalle_producto.producto.codigo:''}}</td>
                            <td>{{detalle_producto.producto?detalle_producto.producto.unidad_medida:''}}</td>
                            <td>{{detalle_producto.producto?detalle_producto.producto.codigo_fabrica:''}}</td>
                            <td>
                                <input onfocus="this.select();" name="cantidad" step="1" 
                                min="1"  required class="form-control" 
                                [(ngModel)]="detalle_producto.cantidad" type="number" 
                                onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"/>
                            </td>
                            <td>
                                <button id="agregar_detalle_venta" type="button" (click)="agregarDetalleProducto()" class="btn btn-success btn-sm">
                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="table-fixed-header">
                    <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                        <thead fix-head class="table-head-color sticky-nav">
                            <tr>
                                <th>Nº</th>
                                <th>Código</th>
                                <th>Producto</th>
                                <th>Unid. de Med.</th>
                                <th>Código generado</th>
                                <th>Cant.</th>
                                <th style="text-align: center; width: 140px; " data-field="tools">
                                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                    <div class="fht-cell"></div>
                                </th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr *ngFor="let detalle_producto of productos; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{detalle_producto.producto.codigo}}</td>
                                <td>{{detalle_producto.producto.nombre}}</td>
                                <td >{{detalle_producto.producto.unidad_medida}}</td>
                                <td *ngIf="configuracion_aplicacion_productos.datos.opciones_permisos.impresion.usar_codigos_sistema"><ngx-barcode [attr.data-codigo]="detalle_producto.producto.codigo" [attr.data-cantidad]="detalle_producto.cantidad" [attr.data-precio-unitario]="detalle_producto.producto.precio_unitario" id="{{detalle_producto.producto.id}}" [bc-element-type]="'img'" [bc-line-color]="'#0F5D97'" [bc-width]="ancho_imagen_codigo" [bc-height]="alto_imagen_codigo" [bc-value]="detalle_producto.producto.codigo" [bc-display-value]="true"></ngx-barcode></td>
                                <td *ngIf="!configuracion_aplicacion_productos.datos.opciones_permisos.impresion.usar_codigos_sistema"><ngx-barcode [attr.data-codigo]="detalle_producto.producto.codigo" [attr.data-cantidad]="detalle_producto.cantidad" [attr.data-precio-unitario]="detalle_producto.producto.precio_unitario" id="{{detalle_producto.producto.id}}" [bc-element-type]="'img'" [bc-line-color]="'#0F5D97'" [bc-width]="ancho_imagen_codigo" [bc-height]="alto_imagen_codigo" [bc-value]="detalle_producto.producto.codigo_fabrica" [bc-display-value]="true"></ngx-barcode></td>
                                <td>{{detalle_producto.cantidad}}</td>
                                <td>
                                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarProducto(detalle_producto)">          
                                        <i class="fa fa-trash-alt text-105"></i>        
                                      </a> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
