<div class="modal-header card-header">
    <div class="col-3 col-md-3">
        <h5 class="widget-title">Kardex de Paciente</h5>
    </div>
    <div class="col-7 col-md-6">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="input-group">
                    <label for="feini">Desde </label>&nbsp;
                    <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                    </div>
                </div>  
            </div>
            <div class="col-12 col-md-6">
                <div class="input-group">
                    <label for="fefin">Hasta </label>&nbsp;
                    <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div class="col-2 col-md-3">
        <button class="btn btn-primary" type="button" (click)="buscarHistorialPaciente()">
            <i class="ace-icon fa fa-search red2 align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
  <div class="row p-1">
    <div class="col-md-7">
        <div class="row">
            <div class="col-5 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-user-injured"></i> Paciente : 
                </label>    
            </div>
            <div class="col-7">
                <label>
                    {{paciente.cliente.persona.nombres}} {{paciente.cliente.persona.apellido_paterno}} {{paciente.cliente.persona.apellido_materno}}
                </label>    
            </div>
        </div>
        <div class="row">
            <div class="col-5 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha de Nacimiento : 
                </label>    
            </div>
            <div class="col-7">
                <label>
                    {{paciente.cliente.persona.fecha_nacimiento | date:'dd/MM/yyyy'}}
                </label>    
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="row">
        <div class="col-7 alert alert-info pr-0 mb-0">
            <label class="text-primary p-0">
                <i class="fas fa-sort-numeric-up-alt"></i> Edad : 
            </label>    
        </div>
        <div class="col-5">
            <label>
                {{paciente.cliente.persona.fecha_nacimiento?util.calcularEdad(paciente.cliente.persona.fecha_nacimiento):''}}
            </label>    
        </div>
        </div>
        <div class="row">
            <div class="col-7 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-phone"></i> Telefono : 
                </label>    
            </div>
            <div class="col-5">
                <label>
                    {{paciente.cliente.persona.telefono_movil}} - {{paciente.cliente.persona.telefono}}
                </label>    
            </div>
        </div>
    </div>
  </div>
  <hr>
  <div class="bootstrap-table bootstrap4">
    <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
            <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
                <span class="animation-wrap">
                <span class="animation-dot"></span>
                </span>
            </span>
            </div>
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Nº</th>
                        <th>Fecha - Hora</th>
                        <th>Tipo Cita</th>
                        <th>Servicio</th>
                        <th>Obs. Cita/Trat.</th>
                        <th>Nº Doc. Pago</th>
                        <th>Importe</th>
                        <th>Obs. Cobro</th>
                        <th>Usuario</th>
                        <th data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let historia_paciente of historias_paciente; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{historia_paciente.fecha_hora | date:"dd/MM/yyyy - HH:mm"}}</td>
                        <td>{{historia_paciente.tipo_cita}} </td>
                        <td>{{historia_paciente.nombre}} </td>
                        <td>{{historia_paciente.observacion}} </td>
                        <td>{{historia_paciente.numero_documento_pago}} </td>
                        <td>{{historia_paciente.importe}} </td>
                        <td>{{historia_paciente.observacion_cobro}} </td>
                        <td>{{historia_paciente.nombre_usuario}} </td>
                        <td>
                            <div class="d-none d-lg-inline text-muted">
                                <a (click)="abrirModalRegistroReceta(historia_paciente)" title="Receta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)">
                                    <i class="fas fa-file-alt"></i>
                                </a>
                                <a title="Laboratorio" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                                    <i class="fas fa-vials"></i>
                                </a>
                            </div>
                            <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                              <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                              <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                                <div class="dropdown-inner">
                                  <a title="Ver" class="dropdown-item" href="javascript:void(0)">
                                    <i class="fas fa-file-alt text-purple mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Receta
                                  </a>
                                  <a title="Laboratorio" class="dropdown-item" href="javascript:void(0)">
                                    <i class="fas fa-vials text-warning mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Laboratorio
                                  </a>
                                </div>
                              </div>
                            </div>
                        </td>
                    </tr>
                </tbody>					
            </table>
        </div>
    </div>
    </div>
</div>