<div class="modal-header">
    <div class="col-10">
        <span class="widget-title text-blue text-125">Balance de Comprobación de Sumas y Saldos</span>
    </div>
    <div class="col-md-2 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-toolbar">
            <div class="row">
                <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="feini">Desde </label>&nbsp;
                    <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                    </div>
                </div>  
                </div>
                <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="fefin">Hasta </label>&nbsp;
                    <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
                </div>
                <div class="col-4">
                    <button (click)="generarBalanceComprobacionSumasSaldos()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>&nbsp;
                </div>
            </div>
        </div>
        <div class="fixed-table-container" style="padding-bottom: 0px;">
          <div class="fixed-table-header" style="display: none;">
            <table></table>
          </div>
          <div class="table-responsive table-fixed-header" style="height: 450px;">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
              <span class="loading-wrap">
                <span class="animation-wrap">
                  <span class="animation-dot"></span>
                </span>
              </span>
            </div>

            <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                <thead fix-head class="table-head-color sticky-nav">
                    <tr class="text-white text-center">
                        <th rowspan="2" class="bgc-grey-d1">Nº</th>
                        <th rowspan="2" class="bgc-grey-d1">Cuenta</th>
                        <th colspan="2" class="bgc-primary-d1">Sumas</th>
                        <th colspan="2" class="bgc-warning-d1">Saldos</th>
                    </tr>
                    <tr class="text-white text-center">
                        <th class="bgc-primary-d1">Debe</th>
                        <th class="bgc-primary-d1">Haber</th>
                        <th class="bgc-warning-d1">Deudor</th>
                        <th class="bgc-warning-d1">Acreedor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cuenta of cuentas;let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{cuenta.nombre}} <span class="float-right"><a title="Libro Mayor" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirLibroMayorCuenta(cuenta)" >
                            <i class="fas fa-atlas"></i>
                        </a></span></td>
                        <td><span class="float-right">{{cuenta.debe.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{cuenta.haber.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{cuenta.deudor.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{cuenta.acreedor.toFixed(2)}}</span></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2"><b class="float-right">TOTALES</b></td>
                        <td><span class="float-right">{{suma_debe.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{suma_haber.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{suma_deudor.toFixed(2)}}</span></td>
                        <td><span class="float-right">{{suma_acreedor.toFixed(2)}}</span></td>
                    </tr>
                </tfoot>
            </table>
              
          </div>
          <div class="fixed-table-footer">
            <table><thead><tr></tr></thead></table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
</div>