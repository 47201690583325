import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from '../../../models/base-component';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;

@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.css']
})
export class RegistroUsuarioComponent extends BaseComponent implements OnInit {

  wizard_edicion_usuario:string="wizard_edicion_usuario";
  usuario_item:any={persona:{empleado:{}},empresa:{},rolesUsuario:[]};
  sucursales:IMultiSelectOption[]=[];
  almacenes:any[];
  todos_app_usuario:any={
    puede_crear:true,
    puede_ver:true,
    puede_modificar:true,
    puede_eliminar:true,
  };
  roles:any[]=[];
  roles_registro:any[]=[];
  empresas:any[]=[];
  generos:any[]=[];
  cargos:any[]=[];
  gestiones:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private empresasService:EmpresaService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.blockUI.start();
	this.llenarSucursales([]);
    if(this.usuario_item && this.usuario_item.id){
      let usuario=this.usuario_item;
      let datosUsuario:any=await this.usuarioService.obtenerUsuario(usuario.id).toPromise();
	  this.usuario_item=datosUsuario;
	  this.usuario_item.empresa=await this.empresasService.obtenerEmpresa(this.usuario_item.id_empresa).toPromise();
	  this.usuario_item=Util.establecerPermisos(this.usuario_item);
      this.usuario_item.repeticion_clave=this.usuario_item.clave;
      //this.rol=usuario.rolesUsuario[0].rol;
      this.usuario_item.rol=this.usuario_item.rolesUsuario[0].rol;
      this.usuario_item.id_rol=this.usuario_item.rolesUsuario[0].rol?this.usuario_item.rolesUsuario[0].rol.id:null;
      var sucursales;
      if(this.usuario.empresa){
		let datos:any=await this.empresasService.obtenerSucursalesEmpresa(this.usuario.empresa.id).toPromise();
        sucursales=datos;
        this.llenarSucursales(sucursales);
		this.usuario_item.sucursales=this.seleccionarSucursales(this.usuario_item.sucursalesUsuario);
		this.usuario_item.almacenes=[];
		await this.llenarAlmacenes();
        this.seleccionarAlmacenes(this.usuario_item.almacenesUsuario);
      }else{
        if(this.usuario_item.id_empresa){
          let datos:any=await this.empresasService.obtenerSucursalesEmpresa(this.usuario_item.id_empresa).toPromise();
          sucursales=datos;
          this.llenarSucursales(sucursales);
		  this.usuario_item.sucursales=this.seleccionarSucursales(this.usuario_item.sucursalesUsuario);
		  this.usuario_item.almacenes=[];
          await this.llenarAlmacenes();
          this.seleccionarAlmacenes(this.usuario_item.almacenesUsuario);
        }
      }
	  if(this.usuario_item.empresa.usar_gece && this.usuario_item.datos){
		this.usuario_item.codigos_libros=this.usuario_item.datos.codigos_libros.join();
	  }
	  if(this.usuario_item.empresa.usar_acceso_gestion_escolar){
		if(!this.usuario_item.datos){
			this.usuario_item.datos={};
		}
		if(!this.usuario_item.datos.gestiones){
			try{
				this.usuario_item.datos.gestiones=[];
			}catch(e){
				this.usuario_item.datos={gestiones:[]};
				console.log(e);
			}
		}
	  }
      this.blockUI.stop();
    }else{
      if(this.usuario.id_empresa){
		  let empresa_a_buscar=await this.empresasService.obtenerEmpresa(this.usuario.id_empresa).toPromise();
		this.usuario_item={
            persona:{imagen:"img/icon-user-default.png",empleado:{}},
            sucursales:[],
            id_empresa:this.usuario.id_empresa,
            empresa:this.usuario.empresa?this.usuario.empresa:empresa_a_buscar,
			activo:true,
			almacenes:[],
			datos:{gestiones:[]}
		  }
		  this.usuario_item=Util.establecerPermisos(this.usuario_item);
          this.seleccionarSucursales([]);
		  this.buscarSucursales(empresa_a_buscar);
		  if(this.usuario_item.empresa.usar_aplicaciones_por_rol){
			this.buscarAplicacionesRol(this.usuario_item.rol);
		  }else{
			this.buscarModulos(empresa_a_buscar);
		  }
          this.blockUI.stop();
      }else{
        this.usuario_item={
          persona:{imagen:"img/icon-user-default.png",empleado:{}},
          sucursales:[],
		  activo:true,
		  datos:{gestiones:[]},
		  empresa:await this.empresasService.obtenerEmpresa(this.empresas[0].id).toPromise()
		}
		this.usuario_item=Util.establecerPermisos(this.usuario_item);
		this.buscarEmpresa(this.usuario_item.empresa);
        this.sucursales=[];
        this.blockUI.stop();console.log(this.usuario_item);
      }
	}
	await this.obtenerRoles();
	if(this.usuario_item.empresa.usar_empleados){
		this.obtenerGeneros();
		this.obtenerCargos();
	}
	if(this.usuario_item.empresa.usar_acceso_gestion_escolar){
		await this.obtenerGestionesEmpresa();
		this.seleccionarGestiones(this.usuario_item.datos.gestiones);
	}
  }

  ngAfterViewInit(){
    aplicarWizard(this.wizard_edicion_usuario,this,'guardar');
    aplicarPluginImagen();
  }

  obtenerGeneros(){  
	this.generalService.obtenerClases(GlobalVariable.Dictionary.GENEROS).subscribe((entidad:any)=>{
		this.generos=entidad.clases;
	});
  }

  obtenerCargos(){  
	this.generalService.obtenerClases(GlobalVariable.Dictionary.RRHH_CARGOS).subscribe((entidad:any)=>{
		this.cargos=entidad.clases;
	});
  }

  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }

  seleccionarSucursales(sucursalesUsuario){
		var sucursales_usuario=[];
		for(var j=0;j<sucursalesUsuario.length;j++){
			sucursales_usuario.push(sucursalesUsuario[j].sucursal.id);
		}
		return sucursales_usuario;
  }
  
  buscarSucursales(empresa_a_buscar){
		this.sucursales=empresa_a_buscar.sucursales;
		this.empresasService.obtenerSucursalesEmpresa(empresa_a_buscar.id).subscribe((sucursales:any)=>{
			this.llenarSucursales(sucursales);
			this.usuario_item.sucursales=this.usuario_item.id?this.seleccionarSucursales(this.usuario_item.sucursalesUsuario):[];
		});
  }
  
  llenarSucursales(datosSucursales){
		this.sucursales=[];
		for(var i=0;i<datosSucursales.length;i++){
			var sucursal={
				name:datosSucursales[i].nombre,
				maker: "",
				ticked:false,
				id:datosSucursales[i].id,
				almacenes:datosSucursales[i].almacenes
			}
			this.sucursales.push(sucursal);
		}
  }
  
  buscarModulos(empresa_a_buscar){
		var aplicaciones_usuario=this.usuario_item.aplicacionesUsuario?JSON.parse(JSON.stringify(this.usuario_item.aplicacionesUsuario)):[];
		this.todos_app_usuario={
			puede_crear:true,
			puede_ver:true,
			puede_modificar:true,
			puede_eliminar:true,
		}
		var empresa=empresa_a_buscar;//($.grep(this.empresas, function(e){return (e.id == empresa_a_buscar.id);})[0]);
		//var aplicaciones_usuario=[];
		this.usuario_item.aplicacionesUsuario=/*this.usuario_item.id?this.usuario_item.aplicacionesUsuario:*/[];
		for(var i=0;i<empresa.modulos.length;i++){
			if(empresa.modulos[i].habilitado){
				for(var j=0;j<empresa.modulos[i].modulo.aplicaciones.length;j++){
					var encontrados=aplicaciones_usuario.filter(e => e.id_aplicacion == empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id);
					if(encontrados.length==0){
						var duplicado=this.usuario_item.aplicacionesUsuario.filter(e => e.id_aplicacion == empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id);
						if(duplicado.length==0){
							this.usuario_item.aplicacionesUsuario.push({id_aplicacion:empresa.modulos[i].modulo.aplicaciones[j].aplicacion.id,
								aplicacion:empresa.modulos[i].modulo.aplicaciones[j].aplicacion,
								puede_crear:true,
								puede_ver:true,
								puede_modificar:true,
								puede_eliminar:true});
						}		
					}else{
						var duplicado=this.usuario_item.aplicacionesUsuario.filter(e => e.id_aplicacion == encontrados[0].id_aplicacion);
						if(duplicado.length==0){
							this.usuario_item.aplicacionesUsuario.push(encontrados[0]);
						}
					}
				}	
			}
		}
  }

  buscarAplicacionesRol(rol_a_buscar){
	this.usuario_item.id_rol=rol_a_buscar?rol_a_buscar.id:null;
	if(this.usuario_item.empresa.usar_aplicaciones_por_rol && rol_a_buscar){
		this.usuario_item.aplicacionesUsuario=[];
		this.empresasService.obtenerRol(rol_a_buscar.id).subscribe((rol:any)=>{
			for(var j=0;j<rol.aplicacionesRol.length;j++){
				if(rol.aplicacionesRol[j].habilitado){
					this.usuario_item.aplicacionesUsuario.push({id_aplicacion:rol.aplicacionesRol[j].aplicacion.id,
						aplicacion:rol.aplicacionesRol[j].aplicacion,
						puede_crear:true,
						puede_ver:true,
						puede_modificar:true,
						puede_eliminar:true});
				}
			}
		});			
	}
  }

  async llenarAlmacenes(){
	this.almacenes=[];
	for(var i=0;i<this.usuario_item.sucursales.length;i++){
		let sucursal_seleccionada:any=this.sucursales.filter(e => e.id==this.usuario_item.sucursales[i])[0];
		if(sucursal_seleccionada){
			sucursal_seleccionada.almacenes=await this.obtenerAlmacenesSucursal(sucursal_seleccionada);
			for(var j=0;j<sucursal_seleccionada.almacenes.length;j++){
				let almacen:any={
					nombre:sucursal_seleccionada.name +" - "+sucursal_seleccionada.almacenes[j].nombre,
					maker: "",
					ticked:false,
					id:sucursal_seleccionada.almacenes[j].id
				}
				this.almacenes.push(almacen);
				console.log(this.almacenes);
			}
			this.cdr.detectChanges();
		}
	}
  }

  async obtenerGestionesEmpresa(){
	let tipo_gestion:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa?this.usuario.id_empresa:this.usuario_item.empresa.id,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
	for(var j=0;j<tipo_gestion.clases.length;j++){
		let gestion:any={
			nombre:tipo_gestion.clases[j].nombre,
			maker: "",
			ticked:false,
			id:tipo_gestion.clases[j].id
		}
		this.gestiones.push(gestion);
	}
	this.cdr.detectChanges();
  }

  agregarRemoverAlmacen(almacen){
	  if(this.usuario_item.almacenes.indexOf(almacen.id)==-1){
		this.usuario_item.almacenes.push(almacen.id);
	  }else{
		this.usuario_item.almacenes.splice(this.usuario_item.almacenes.indexOf(almacen.id),1);
	  }
  }

  	agregarRemoverGestion(gestion){
		if(this.usuario_item.datos.gestiones.indexOf(gestion.id)==-1){
			this.usuario_item.datos.gestiones.push(gestion.id);
		}else{
			this.usuario_item.datos.gestiones.splice(this.usuario_item.datos.gestiones.indexOf(gestion.id),1);
		}
	}

  	async obtenerAlmacenesSucursal(sucursal){
		let datos_sucursal:any=await this.empresasService.obtenerSucursal(sucursal.id).toPromise();
		return datos_sucursal.almacenes;
	}
  
  seleccionarAlmacenes(almacenes){
		for(var j=0;j<almacenes.length;j++){
			let alm:any=this.almacenes.filter(alm=> alm.id==almacenes[j].almacen.id);
			if(alm.length>0){console.log(alm);
				alm[0].ticked=true;
				this.usuario_item.almacenes.push(alm[0].id);
			}
		}
  }

  	seleccionarGestiones(gestiones){
		for(var j=0;j<gestiones.length;j++){
			let alm:any=this.gestiones.filter(gt=> gt.id==gestiones[j]);
			if(alm.length>0){
				alm[0].ticked=true;
			}
		}
	}
  
  seleccionarCapacidad(valor){
		for(var i=0;i<this.usuario_item.aplicacionesUsuario.length;i++){
			if(valor==1){
				this.usuario_item.aplicacionesUsuario[i].puede_crear=this.todos_app_usuario.puede_crear;
			}else if(valor ==2){
				this.usuario_item.aplicacionesUsuario[i].puede_ver=this.todos_app_usuario.puede_ver;
			}else if(valor ==3){
				this.usuario_item.aplicacionesUsuario[i].puede_modificar=this.todos_app_usuario.puede_modificar;
			}else if(valor ==4){
				this.usuario_item.aplicacionesUsuario[i].puede_eliminar=this.todos_app_usuario.puede_eliminar;
			}
		}
  }
  
  async guardar(){
		this.blockUI.start();
		if(await this.validarFormularioRegistroUsuario()){
			if(this.usuario_item.id){
				this.usuarioService.actualizarUsuario(this.usuario_item).subscribe((res:any)=>{
					this.blockUI.stop();
					this.alTerminar.emit(res);
				});
			}else{
				this.usuarioService.guardarUsuario(this.usuario_item).subscribe(res=>{
					this.blockUI.stop();
					this.alTerminar.emit({mensaje:'Usuario registrado exitosamente!'});
				});
			}
		}else{
			this.blockUI.stop();
		}
	}

	async validarFormularioRegistroUsuario(){
		let res=true;
		
		if(!this.usuario_item.persona.nombres){
			this.toastr.error("Debe ingresar un nombre para la persona!");
			res=false;
		}
		if(!this.usuario_item.nombre_usuario){
			this.toastr.error("Debe ingresar un nombre de usuario!");
			res=false;
		}
		if(!this.usuario_item.clave){
			this.toastr.error("Debe ingresar una clave!");
			res=false;
		}
		if(!this.usuario_item.repeticion_clave){
			this.toastr.error("Debe ingresar la repetición de la clave!");
			res=false;
		}
		if(!this.usuario_item.rol){
			this.toastr.error("Debe ingresar el rol del usuario!");
			res=false;
		}
		if(!this.usuario_item.empresa){
			this.toastr.error("Debe ingresar la empresa del usuario!");
			res=false;
		}
		if(this.usuario_item.sucursales.length==0){
			this.toastr.error("Debe asignar al menos una sucursal al usuario!");
			res=false;
		}
		if(this.usuario_item.nombre_usuario && this.usuario_item.nombre_usuario.toUpperCase()==this.usuario_item.clave.toUpperCase()){
			this.toastr.error("La clave debe ser diferente del nombre de usuario!");
			res=false;
		}
    	if(this.usuario_item.clave && this.usuario_item.clave.length<6){
			this.toastr.error("La clave debe tener al menos una longitud de 6 caracteres alfanumericos!");
			res=false;
		}
		res = res && (await this.validarUsuario(this.usuario_item));
		res = res && this.verificarClaveRepeticion(this.usuario_item);
		return res;
  }
  
  cerrarRegistroUsuario(){
		this.alTerminar.emit();
	}

	async validarUsuario(usuario) {
		if(usuario.nombre_usuario){
			var res=true;
			let existencia_usuario:any=await this.usuarioService.verificarUsuario(usuario.id?usuario.id:0,usuario.nombre_usuario).toPromise();
			if(existencia_usuario.usuario){
				res=false;
				this.toastr.error("Ya existe una cuenta con el correo electronico ingresado, intente nuevamente!");
			}	
			return res;
		}
	}

	verificarClaveRepeticion(usuario){
		var res=true;
		if(usuario.clave!=usuario.repeticion_clave){
			res=false;
			this.toastr.error("Las claves ingresadas no coinciden! Vuelva a intentarlo.");
		}
		return res;
  }
  
  buscarEmpresa(empresa){
		this.empresasService.obtenerEmpresa(empresa.id).subscribe(async (empresa_a_buscar) =>{
			this.usuario_item.sucursales=[];
			this.buscarSucursales(empresa_a_buscar);
			this.buscarModulos(empresa_a_buscar);
			this.usuario_item.empresa=empresa_a_buscar;
			this.usuario_item=Util.establecerPermisos(this.usuario_item);
			this.obtenerRoles();
			this.obtenerGeneros();
			this.obtenerCargos();
			if(this.usuario_item.empresa.usar_acceso_gestion_escolar){
				await this.obtenerGestionesEmpresa();
				this.seleccionarGestiones(this.usuario_item.datos.gestiones);
			}
		});
  }
  
  seleccionarSucursal(e){
	this.cdr.detectChanges();
		this.llenarAlmacenes();
  }

  seleccionarAlmacen(e){
	  this.cdr.detectChanges();
	}
  
  async obtenerRoles(){
	this.blockUI.start();
	let roles:any;
	if(this.usuario_item.empresa && this.usuario_item.empresa.usar_aplicaciones_por_rol){
		roles=await this.empresasService.obtenerRolesEmpresa(this.usuario_item.empresa.id).toPromise();
	}else{
		roles=await this.empresasService.obtenerRoles().toPromise();
	}
	this.roles=roles;
	this.roles_registro=this.roles;

	if(this.usuario.empresa){
		this.roles_registro=this.roles.filter(e => e.nombre!='SUPER-ADMINISTRADOR');
	}
	
	this.blockUI.stop();
  }

  subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.usuario_item.persona.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }
  
  buscarModulosUsuario(empresa){
	  if(empresa){
		this.empresasService.obtenerEmpresa(empresa.id).subscribe(empresa_a_buscar =>{
			this.buscarModulos(empresa_a_buscar);
		});  
	  }else{
		this.empresasService.obtenerEmpresa(this.usuario_item.empresa.id).subscribe(empresa_a_buscar =>{
			this.buscarModulos(empresa_a_buscar);
		});
	  }
  }

  construirDatos(){
	this.usuario_item.datos=this.usuario_item.datos?this.usuario_item.datos:{};
	  this.usuario_item.datos.codigos_libros=this.usuario_item.codigos_libros.replace(' ','').split(",");
  }

	eliminarAplicacion(aplicacion){
		aplicacion.eliminado=true;
		this.usuario_item.aplicacionesUsuario.splice(this.usuario_item.aplicacionesUsuario.indexOf(aplicacion),1);
	}

}
