<div class="modal-header card-header bg-dark">
    <div class="col-6 col-md-8">
        <span class="text-blue text-125">Registro de Asistencia</span>
    </div>
    <div class="col-6 col-md-4">
        <button class="btn btn-warning" type="button" (click)="obtenerPosicionActual()">
            <i class="fas fa-map-marker-alt red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
          <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body bg-dark">
  <div class="row" *ngIf="!ubicacion_permitida">
    <div class="col-12">
      <div class="alert d-flex bgc-red-l3 brc-success-m4 border-0 p-0" role="alert">
        <div class="bgc-red px-3 py-1 text-center radius-l-1">
          <span class="fa-2x text-white">⚠ <!-- &#9888; --></span>
        </div>
        <span class="ml-3 align-self-center text-dark-tp3 text-110">Debe habilitar su GPS!</span>
      </div>
    </div>
  </div>
    <div class="row">
        <div class="text-200 text-white text-center align-self-center col-8 col-sm-6 col-md-6">
          <h4>{{saludo}}</h4>
          <h5 class="text-warning">{{usuario.persona.nombre_completo}}</h5>
          <h1>{{time | date:'mediumTime'}}</h1>
          <h3>{{time | date:'dd/MM/yyyy'}}</h3>
        </div>
        <div class="text-200 col-4 col-md-6 col-sm-6">
            <div class="py-3 mt-3">
                <div class="row">
                  <div class="col-12 col-md-10 offset-md-1">
                    <div class="row mx-0 justify-content-center">
                      <div class="col-12 text-center px-2">
                        <div style="cursor: pointer;" class="pos-rel d-style my-3 radius-1 shadow-sm overflow-hidden bgc-brown-m3">
                          <a href="javascript:void(0)" >
                            <img  alt="Asistencia" src="{{global_variable.SERVER_URL+'img/huella-digital.jpg'}}" class="w-100 d-zoom-2 " data-size="1200x800" />
                          </a>
                          <div (click)="marcarAsistencia()" class="v-hover position-center h-100 w-100 bgc-dark-grad"></div>
  
                          <a href="#" class="btn btn-white btn-h-yellow btn-a-yellow v-hover position-tr mt-1 mr-1 py-0 radius-1 border-0">
                            <span class="text-140 text-600"></span>
                          </a>
                        </div>

                      </div>
                    </div><!-- /.row -->
                  </div><!-- /.col -->
                </div><!-- /.row -->
              </div><!-- /.bcard -->
        </div>
    </div>
    <div class="row" *ngIf="turnos.length>0">
      <div class="col-12">
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
        <table class="table table-striped table-bordered table-hover">
          <thead class="text-white">
              <tr>
                  <th class="center">N°</th>
                  <th class="center">Turno</th>
                  <th class="center">Periodo</th>
                  <th class="center">Tolerancia</th>
                  <th class="center">Habilitado</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let empleado_turno of turnos | orderBy : 'turno.numero_orden'; let i=index;">
                  <td class="center">
                      {{i+1}}
                  </td>
                  <td class="center">
                      {{empleado_turno.turno.nombre}}
                  </td>
                  <td class="center">
                      {{empleado_turno.turno.hora_inicio}}:{{empleado_turno.turno.minuto_inicio}} - {{empleado_turno.turno.hora_fin}}:{{empleado_turno.turno.minuto_fin}}
                  </td>
                  <td class="center">
                    <p><span class="text-success"><i class="fas fa-sign-in-alt red2 text-110 mr-1"></i></span> {{empleado_turno.turno.minutos_tolerancia_entrada}} min.</p>
                    <p><span class="text-danger"><i class="fas fa-sign-out-alt red2 text-110 mr-1"></i></span> {{empleado_turno.turno.minutos_tolerancia_salida}} min.</p>
                  </td>
                  <td class="center">
                    <p><span class="text-success"><i class="fas fa-sign-in-alt red2 text-110 mr-1"></i></span> {{empleado_turno.turno.tickeo_inicio_hora_inicio}}:{{empleado_turno.turno.tickeo_inicio_minuto_inicio}} a {{empleado_turno.turno.tickeo_inicio_hora_fin}}:{{empleado_turno.turno.tickeo_inicio_minuto_fin}}</p>
                    <p><span class="text-danger"><i class="fas fa-sign-out-alt red2 text-110 mr-1"></i></span> {{empleado_turno.turno.tickeo_fin_hora_inicio}}:{{empleado_turno.turno.tickeo_fin_minuto_inicio}} a {{empleado_turno.turno.tickeo_fin_hora_fin}}:{{empleado_turno.turno.tickeo_fin_minuto_fin}}</p>
                  </td>
              </tr>
          </tbody>
        </table>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="turnos.length==0">
      <div class="col-12">
        <div class="alert d-flex bgc-red-l3 brc-success-m4 border-0 p-0" role="alert">
          <div class="bgc-red px-3 py-1 text-center radius-l-1">
            <span class="fa-2x text-white">⚠ <!-- &#9888; --></span>
          </div>
          <span class="ml-3 align-self-center text-dark-tp3 text-110">Usted no tiene turnos de asistencia asignados!</span>
        </div>
      </div>
    </div>
</div>