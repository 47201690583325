<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Reporte de Compras
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Productos
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Reporte de Compras</span>
      Productos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <ng-container *ngIf="configuracion_pagina && filter.presentacion_periodos=='fechas'">
                          <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                          </div>
                        </ng-container>
                        <select (change)="establecerFechaInicioMes(filter.mes_inicial)" *ngIf="configuracion_pagina && filter.presentacion_periodos=='meses'" [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes_inicial">
                          <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
                        </select>
                    </div>  
                  </div>
                  <div class="col-md-4 col-sm-12">
                      <div class="input-group">
                          <label for="fefin">Hasta </label>&nbsp;
                          <ng-container *ngIf="configuracion_pagina && filter.presentacion_periodos=='fechas'">
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                          </ng-container>
                          <select (change)="establecerFechaFinMes(filter.mes_final)" *ngIf="configuracion_pagina && filter.presentacion_periodos=='meses'" [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes_final">
                            <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
                          </select>
                      </div>  
                  </div>
                  <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                  <div class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal" (change)="establecerSucursal()">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Grupo </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="grupo_producto" [(ngModel)]="filter.grupo_producto">
                      <option [ngValue]="grupo_producto" *ngFor="let grupo_producto of filter.grupos_producto">{{grupo_producto.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Subgrupo </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo_producto" [(ngModel)]="filter.subgrupo_producto">
                      <option [ngValue]="subgrupo_producto" *ngFor="let subgrupo_producto of filter.subgrupos_producto">{{subgrupo_producto.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                  </div>
                  
                <div class="col-md-3 col-sm-12">
                  <label>Proveedor </label>
                  <input *ngIf="configuracion_pagina && filter.presentacion_cliente=='campo_texto'" class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente_texto">
                  <select *ngIf="configuracion_pagina && filter.presentacion_cliente=='lista'" [compareWith]="compararObjectos" class="form-control" required name="canal" [(ngModel)]="filter.cliente">
                    <option [ngValue]="cliente" *ngFor="let cliente of filter.clientes">{{cliente.identificacion}}</option>
                  </select>
                </div>
                <div class="col-md-3 col-sm-12">
                  <label>Producto </label>
                  <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.producto">
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr *ngIf="aplicacion.puede_modificar">
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-2">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Histórico
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-6 text-center">
                <button (click)="generarPdfHistorico()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                </button>
              </div>
              <div class="col-6 text-center">
                <button (click)="generarExcelHistorico()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <canvas id="myChart" width="400" height="400">
            </canvas>
          </div>
        </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>