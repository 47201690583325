import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ProductosService } from '../../services/productos/productos.service';

declare const aplicarPluginImagen:any;

@Component({
  selector: 'app-categoria-tienda',
  templateUrl: './categoria-tienda.component.html',
  styleUrls: ['./categoria-tienda.component.css']
})
export class CategoriaTiendaComponent implements OnInit {

  usuario:any;
  clase:any;
  rest_server:any=GlobalVariable.SERVER_URL;
  categoria_tienda:any={
    clase:null,
    tiene_imagen:false,
    cantidad_disposicion_visualizacion:4,
    cantidad_maxima_visualizacion:null
  };
  tipos_catalogo:any[]=[];
  @BlockUI() blockUI: NgBlockUI;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public productosService:ProductosService) { 
      
    }

  async ngOnInit(): Promise<void> {
    this.usuario=this.persistenciaService.get("usuario");
    let categoria_tienda:any=await this.obtenerCategoriaTienda();
    if(categoria_tienda){
      this.categoria_tienda=categoria_tienda;
    }
    this.categoria_tienda.clase=this.clase;
    this.categoria_tienda.id_empresa=this.usuario.id_empresa;
    this.obtenerTiposCatalogos();
  }

  ngAfterViewInit() {
	  this.aplicarPluginImagen();
  }

  aplicarPluginImagen(){
    setTimeout(function(){
      aplicarPluginImagen();
    },1000);
    
  }

  async obtenerCategoriaTienda(){
    this.blockUI.start();
    let res:any=await this.productosService.obtenerCategoriaTienda(this.clase.id).toPromise();
    this.blockUI.stop();
    return res;
  }

  subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.categoria_tienda.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  async obtenerTiposCatalogos(){
    let tipo_catalogo:any=await this.generalService.obtenerClases("TVCATTIE").toPromise();
    this.tipos_catalogo=tipo_catalogo?tipo_catalogo.clases:[];
  }

  compararObjectos(c1, c2): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  guardarCategoriaTienda(){
    if(this.validarRegistroCategoriaTienda()){
      this.blockUI.start();
      if(this.categoria_tienda.id){
        this.productosService.actualizarCategoriaTienda(this.categoria_tienda).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.cerrarVentana(res);
        });
      }else{
        this.productosService.guardarCategoriaTienda(this.categoria_tienda).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.cerrarVentana(res);
        });
      }
    }
  }

  validarRegistroCategoriaTienda(){
    let res=true;
    if(!this.categoria_tienda.tipo_catalogo){
      this.toastr.error("Debe ingresar un catalogo!");
      res=false;
    }
    if(!this.categoria_tienda.cantidad_disposicion_visualizacion){
      this.toastr.error("Debe ingresar la cantidad de disposicion de visualizacion!");
      res=false;
    }
    return res;
  }

  cerrarVentana(res){
    this.alTerminar.emit({res:res});
  }

}
