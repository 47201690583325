<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Datos Pedido</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button *ngIf="usuario.empresa.usar_pedidos_produccion && pedido.venta && pedido.estado.nombre_corto!=global_variable.Dictionary.ESTPED_ENTGD && aplicacion_ventas.puede_modificar" class="btn btn-primary" type="button" (click)="modificarVenta(pedido)">
            <i class="fas fa-pen-square red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button *ngIf="pedido.venta && pedido.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE && pedido.venta.saldo>0" class="btn btn-success" type="button" (click)="crearPagoVenta(pedido)">
            <i class="fas fa-dollar-sign red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button *ngIf="usuario.empresa.usar_pedidos_produccion && ( pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PCOMP || pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PEND)" class="btn btn-primary" type="button" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_PROD,mensaje:'Esta acción cambiara el estado del pedido de '+pedido.estado.nombre+' a PRODUCIDO, ¿Esta seguro de realizarlo?'})">
            <i class="fas fa-flag-checkered red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button *ngIf="pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PCOMP || pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PROD || pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PEND" class="btn btn-success" type="button" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_ENTGD,mensaje:'Esta acción cambiara el estado del pedido de '+pedido.estado.nombre+' a ENTREGADO, ¿Esta seguro de realizarlo?'})">
            <i class="fas fa-calendar-check red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-4">
            <div class="row" *ngIf="usuario.empresa.usar_sucursales">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-store"></i> Sucursal : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.sucursal.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="far fa-clock"></i> Fecha Ingreso : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.tiempo_ingreso | date:"dd/MM/yyyy HH:mm"}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-business-time"></i> Fecha Entrega : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.tiempo_despacho | date:"dd/MM/yyyy HH:mm"}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-list-ol"></i> Num. Pedido : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.numero_pedido}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-toggle-on"></i> Estado : 
                    </label>    
                </div>
                <div class="col-6">
                    <span [ngClass]="{'badge-danger':pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PEND,'badge-success':pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_ENTGD,'badge-warning':pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PCOMP,'btn-pink':pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_ENTGDINC,'btn-primary':pedido.estado.nombre_corto==global_variable.Dictionary.ESTPED_PROD}" class="badge badge-sm">{{pedido.estado.nombre}}</span>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-user"></i> Usuario  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.usuario?pedido.usuario.nombre_usuario:''}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-user-tag"></i> Cliente : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.cliente.identificacion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-phone"></i> Telefono / Celular  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.cliente.telefono1}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-map-marker-alt"></i> Dirección  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.cliente.direccion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-images"></i> Imagen Pedido : 
                    </label>    
                </div>
                <div class="col-6">
                    <div class="imagen-pedido-vista clearfix">
                        <a href="{{rest_server+pedido.imagen}}" data-rel="colorbox" title="Imagen pedido">
                            <img alt="sin imagen" src="{{rest_server+pedido.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>   
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="pedido.venta">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-list-ol"></i> Num. Doc. Venta  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.venta.factura}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo de Pago  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.venta.tipoPago.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total (Bs)  : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.venta.total}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="pedido.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A Cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.venta.a_cuenta}}
                    </label>    
                </div>
            </div>
            <div class="row" *ngIf="pedido.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{pedido.venta.saldo}}
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row p-1">
        <div class="col-md-6">
            <div class="table-fixed-header" *ngIf="pedido.detallesPedido.length>0">
                <h5 class="text-blue-d2 mb-3">Detalle del pedido</h5>
                <table class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Item</th>
                            <th *ngIf="pedido.venta">Und. Med.</th>
                            <th>Cantidad</th>
                            <th *ngIf="usuario.empresa.usar_despachos">Cantidad Despachado</th>
                            <th *ngIf="usuario.empresa.usar_despachos">Saldo</th>
                            <th *ngIf="pedido.venta">P. U.</th>
                            <th *ngIf="pedido.venta">Imp.</th>
                            <th *ngIf="pedido.venta">Desc.</th>
                            <th *ngIf="pedido.venta">Total</th>
                            <th *ngIf="usuario.empresa.usar_almacenes_empleado">Asignado</th>
                            <th style="text-align: center; width: 140px; " data-field="tools">
                                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                <div class="fht-cell"></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detallePedido of pedido.detallesPedido; let i=index;">
                            <td>{{i+1}}</td>
                            <td *ngIf="detallePedido.producto">{{detallePedido.producto.nombre +(detallePedido.descripcion?"("+detallePedido.descripcion+")":"")}}</td>
                            <td *ngIf="detallePedido.combo">{{detallePedido.combo.nombre}}</td>
                            <td *ngIf="!detallePedido.combo && !detallePedido.producto">{{detallePedido.descripcion}} {{detallePedido.campo_personalizado_1?(obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)+':'+detallePedido.campo_personalizado_1):''}}</td>
                            <td *ngIf="pedido.venta">{{detallePedido.producto?detallePedido.producto.unidad_medida:detallePedido.combo?detallePedido.combo.unidad_medida:''}}</td>
                            <td>{{detallePedido.cantidad}}</td>
                            <td *ngIf="usuario.empresa.usar_despachos">{{detallePedido.cantidad_despachado}}</td>
                            <td *ngIf="usuario.empresa.usar_despachos">{{detallePedido.saldo}}</td>
                            <td *ngIf="pedido.venta">{{detallePedido.precio_unitario}}</td>
                            <td *ngIf="pedido.venta">{{detallePedido.importe}}</td>
                            <td *ngIf="pedido.venta">{{detallePedido.descuento}}</td>
                            <td *ngIf="pedido.venta">{{detallePedido.total}}</td>
                            <td *ngIf="usuario.empresa.usar_almacenes_empleado">{{detallePedido.empleado_asignado?detallePedido.empleado_asignado.persona.nombre_completo:''}}</td>
                            <td>
                                <a *ngIf="usuario.empresa.usar_almacenes_empleado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verEmpleados(detallePedido)">          
                                    <i class="fa fa-users text-105"></i>        
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        <div class="col-md-6" *ngIf="usuario.empresa.usar_despachos && pedido.despachos.length>0">
            <div class="table-fixed-header" *ngFor="let despacho of pedido.despachos;">
                <h5 class="text-blue-d2 mb-3">
                    Despacho Nro: {{despacho.numero_documento}} - Fecha: {{despacho.fecha | date:"dd/MM/yyyy HH:mm"}}
                    <a class="green" href="javascript:void(0)" (click)="imprimirDespacho(despacho)">
                        <i class="ace-icon fa fa-print bigger-130"></i>
                    </a>
                </h5>
                <table  class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Item</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detalleDespacho of despacho.detallesDespacho; let i=index;">
                            <td>{{i+1}}</td>
                            <td *ngIf="detalleDespacho.producto">{{detalleDespacho.producto.nombre}}</td>
                            <td *ngIf="detalleDespacho.combo">{{detalleDespacho.combo.nombre}}</td>
                            <td>{{detalleDespacho.cantidad}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    </div>
</div>

<ng-template #empleado_asignacion_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Empleados</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        
        <table id="tabla-empleados" class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th class="center">
                        Nº
                    </th>
                    <th><a href="javascript:void(0)">Nombre Completo <span id="nombre_completo" class="sort fa fa-sort icon-only"></span></a></th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let empleado of empleados;let i=index;">
                    <td class="center">
                        {{i+1}}
                    </td>
                    <td>{{empleado.persona.nombre_completo}}</td>
                    <td>
                        <a  class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="asignarEmpleado(empleado)">          
                            <i class="fas fa-user-check text-105"></i>        
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        

    </div>
</ng-template>
   