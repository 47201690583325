import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ToastrService } from 'ngx-toastr';

declare const aplicarSubidaArchivo:any;
declare const $:any;

@Component({
  selector: 'app-vista-compra',
  templateUrl: './vista-compra.component.html',
  styleUrls: ['./vista-compra.component.css']
})
export class VistaCompraComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  compra:any={
    almacen:{}
  };
  usuario:any;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public comprasService:ComprasService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.obtenerCompra();
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
    aplicarSubidaArchivo('form-documentos-compra','template-documentos-compra',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
  }

  obtenerCompra(){
    this.blockUI.start();
    this.comprasService.obtenerCompra(this.compra.id).subscribe((datos:any) => {
      this.compra=datos;
      this.compra.subidas=[];
      setTimeout(function() {
        $(".dz-remove").trigger('click');
      }, 0);
      this.blockUI.stop();
		});
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.subidas.push(base64EncodedImage);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.compra.subidas.splice(me.compra.documentos.indexOf(base64EncodedImage),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  guardarDocumentos(){
    this.blockUI.start();
    this.comprasService.subirDocumentosCompra({documentos:this.compra.documentos,subidas:this.compra.subidas,id_compra:this.compra.id,id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.blockUI.stop();
      this.obtenerCompra();
    });
  }

}
