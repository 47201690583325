import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ConceptosIngresosService {

  constructor(private http: HttpClient) { }

  public obtenerListaConceptosPagos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"conceptos-pagos-instituto/escuela/"+paginator.filter.escuela.id+"/carrera/"+paginator.filter.carrera.id+"/gestion/"+paginator.filter.gestion.id+"/semestre/"+paginator.filter.semestre.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerListaConceptosPagosColegio(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"conceptos-pagos-colegio/escuela/"+paginator.filter.escuela.id+"/gestion/"+paginator.filter.gestion.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarConceptoPago(concepto_pago){
    return this.http.post(GlobalVariable.API_SERVER_URL+"concepto-pago-instituto/",concepto_pago,GlobalVariable.httpOptions);
  }

  public guardarConceptoPagoColegio(concepto_pago){
    return this.http.post(GlobalVariable.API_SERVER_URL+"concepto-pago-colegio/",concepto_pago,GlobalVariable.httpOptions);
  }

  public actualizarConceptoPago(concepto_pago){
    return this.http.put(GlobalVariable.API_SERVER_URL+"concepto-pago-instituto/"+concepto_pago.id,concepto_pago,GlobalVariable.httpOptions);
  }

  public actualizarConceptoPagoColegio(concepto_pago){
    return this.http.put(GlobalVariable.API_SERVER_URL+"concepto-pago-colegio/"+concepto_pago.id,concepto_pago,GlobalVariable.httpOptions);
  }

  public obtenerConceptoPago(concepto_pago){
    return this.http.get(GlobalVariable.API_SERVER_URL+"concepto-pago-instituto/"+concepto_pago.id,GlobalVariable.httpOptions);
  }

  public obtenerConceptoPagoColegio(concepto_pago){
    return this.http.get(GlobalVariable.API_SERVER_URL+"concepto-pago-colegio/"+concepto_pago.id,GlobalVariable.httpOptions);
  }

  public eliminarConceptoPago(concepto_pago){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"concepto-pago-instituto/"+concepto_pago.id,GlobalVariable.httpOptions);
  }

  public eliminarConceptoPagoColegio(concepto_pago){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"concepto-pago-colegio/"+concepto_pago.id,GlobalVariable.httpOptions);
  }

  public buscarConceptosPagoEstudiante(inscripcion_instituto){
    return this.http.post(GlobalVariable.API_SERVER_URL+"conceptos-pago-inscripcion-instituto",inscripcion_instituto,GlobalVariable.httpOptions);
  }

  public buscarConceptosPagoEstudianteColegio(inscripcion_colegio){
    return this.http.post(GlobalVariable.API_SERVER_URL+"conceptos-pago-inscripcion-colegio",inscripcion_colegio,GlobalVariable.httpOptions);
  }

  public actualizarConceptoPagoEstudiante(concepto_pago_estudiante){
    return this.http.put(GlobalVariable.API_SERVER_URL+"instituto/concepto-pago-estudiante/"+concepto_pago_estudiante.id,concepto_pago_estudiante,GlobalVariable.httpOptions);
  }

  public actualizarConceptoPagoEstudianteColegio(concepto_pago_estudiante){
    return this.http.put(GlobalVariable.API_SERVER_URL+"colegio/concepto-pago-estudiante/"+concepto_pago_estudiante.id,concepto_pago_estudiante,GlobalVariable.httpOptions);
  }

  public obtenerConceptosPago(inscripcion_instituto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"conceptos-pago-inscripcion-instituto/carrera/"+(inscripcion_instituto.carrera?inscripcion_instituto.carrera.id:0)+"/gestion/"+(inscripcion_instituto.gestion?inscripcion_instituto.gestion.id:0)+"/semestre/"+(inscripcion_instituto.semestre?inscripcion_instituto.semestre.id:0),GlobalVariable.httpOptions);
  }

  public obtenerConceptosPagoColegio(gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"conceptos-pago-colegio/gestion/"+gestion.id,GlobalVariable.httpOptions);
  }

  public obtenerReportePagosInstituto(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-pagos-instituto/carrera/"+(datos.carrera?datos.carrera.id:0)+"/gestion/"+(datos.gestion?datos.gestion.id:0)+"/semestre/"+(datos.semestre?datos.semestre.id:0),GlobalVariable.httpOptions);
  }

  public asignarConceptoPagoEstudiante(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"asignacion-concepto-pago-estudiante",datos,GlobalVariable.httpOptions);
  }

  public buscarDatosEstudiantesConceptosPagoBanco(filter){
    return this.http.get(GlobalVariable.API_SERVER_URL+"descarga-datos-estudiantes-banco/gestion/"+filter.gestion.id+"/concepto-pago/"+filter.concepto_pago.id+"/estudiantes/"+filter.ids_estudiantes+"/opcion/"+filter.opcion.codigo+"/version/"+(filter.opcion.version?filter.opcion.version:"0"),GlobalVariable.httpOptions);
  }

  public sincronizarCursoConceptoPago(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sincronizar-curso-concepto-pago/curso/"+datos.id_curso+"/concepto-pago/"+datos.id_concepto_pago+"/gestion/"+datos.id_gestion,GlobalVariable.httpOptions);
  }

  public actualizarConceptoPagoFechaInicioVencimiento(concepto_pago){
    return this.http.put(GlobalVariable.API_SERVER_URL+"actualizacion-fecha-vencimiento-concepto-pago-colegio/"+concepto_pago.id,concepto_pago,GlobalVariable.httpOptions);
  }
}
