<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm">
                <a class="link_tab tabla_pedidos nav-link text-left py-3" (click)="verTab('tabla_pedidos')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Tabla de Pedidos
                </a>
              </li>
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab registro_pedido nav-link text-left py-3" (click)="verTab('registro_pedido')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Registro de Pedido
                </a>
              </li>
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab impresion nav-link text-left py-3" (click)="verTab('impresion')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Impresión
                </a>
              </li>
              <li class="nav-item brc-warning shadow-sm">
                <a class="link_tab permisos nav-link text-left py-3" (click)="verTab('permisos')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Permisos
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="contenido_tab tabla_pedidos text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
                        <legend >Tabla de Pedidos</legend>
                        <div class="row">
                            <div class="col-6">
                                <label>Ordenar por</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.orden_por_columna">
                                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label>Dirección</label>
                            </div>
                            <div class="col-6">
                                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.orden_direccion">
                                    <option value="asc">Ascendente</option>
                                    <option value="desc">Descendente</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab registro_pedido text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Registro de Pedido</legend>
                        <div class="row">
                            <div class="col-6">
                                <label>Despacho por defecto</label>
                            </div>
                            <div class="col-6">
                              <select  class="form-control" name="despacho" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.registro_despacho_por_defecto">
                                <option [disabled]="despacho.nombre=='TODOS'" [ngValue]="despacho.nombre_corto" *ngFor="let despacho of tipos_despacho" >{{despacho.nombre}}</option>
                              </select>
                            </div>
                            <div class="col-6">
                                <label>Ordenar Panel por</label>
                            </div>
                            <div class="col-6">
                              <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.orden_productos_panel">
                                <option value="nombre">Nombre Producto</option>
                                <option value="codigo">Codigo Producto</option>
                              </select>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Controlar cantidades Panel?</label>
                            </div>
                            <div class="col-4">
                              <label>
                                <input name="controlar_cantidades" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.controlar_cantidades_panel" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                              </label>
                            </div>
                            <div class="col-8">
                                <label>¿Controlar cantidades Pedidos registrados?</label>
                            </div>
                            <div class="col-4">
                              <label>
                                <input name="controlar_cantidades_pedidos_registrados" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.controlar_cantidades_pedidos_registrados" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                              </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Cobrar Pedido?</label>
                            </div>
                            <div class="col-4">
                              <label>
                                <input name="cobrar_pedido" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cobrar_pedido" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                              </label>
                            </div>
                            <div class="col-8">
                                <label>¿Descontar Almacen?</label>
                            </div>
                            <div class="col-4">
                              <label>
                                <input name="descontar_almacen" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.descontar_almacen" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                              </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab impresion text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Impresión</legend>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Imprimir comanda en Venta?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="comanda_en_venta" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.comanda_en_venta" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Imprimir solo comanda?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="solo_comanda" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.solo_comanda" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Mostrar vista previa?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="vista_previa" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.vista_previa" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <ng-container *ngIf="usuario.empresa.usar_despachos">
                                <div class="col-8">
                                    <label>¿Datos económicos en despacho?</label>
                                </div>
                                <div class="col-4">
                                    <label>
                                        <input name="datos_economicos_despacho" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.datos_economicos_despacho" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                    </label>
                                </div>
                                <div class="col-4">
                                    <label>Pie de Texto Nota de Entrega</label>
                                </div>
                                <div class="col-8">
                                    <label>
                                        <textarea class="form-control" name="pie_texto_despacho" style="overflow:auto;resize:none" rows="5" cols="70" maxlength="800" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.pie_texto_despacho"></textarea>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab permisos text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                        <legend >Permisos</legend>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Cambiar Precios?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="excel" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cambiar_precios" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Ver Excel?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="excel" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_excel" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Ver Pdf?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="pdf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_pdf" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
</div>