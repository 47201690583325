import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class RedesSocialesService {

  constructor(private http: HttpClient) { }

  public solicitarConexionClienteWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"conexion-cliente-whatsapp",datos,GlobalVariable.httpOptions);
  }

  public solicitarReconexionClienteWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"reconexion-cliente-whatsapp",datos,GlobalVariable.httpOptions);
  }

  public obtenerListaContactosWhatsapp(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lista-contactos-whatsapp/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerListaMensajesContactoWhatsapp(id_sucursal,chat){
    return this.http.post(GlobalVariable.API_SERVER_URL+"lista-mensajes-contacto-whatsapp/sucursal/"+id_sucursal,chat,GlobalVariable.httpOptions);
  }

  public desconectarClienteWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"desconectar-cliente-whatsapp",datos,GlobalVariable.httpOptions);
  }
}
