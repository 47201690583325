<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Reservas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Reservas
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-6 col-md-6">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="feini">Desde </label>&nbsp;
                            <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="fefin">Hasta </label>&nbsp;
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Usuario </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Clase </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="clase" [(ngModel)]="filter.clase">
                        <option [ngValue]="clase" *ngFor="let clase of filter.clases">{{clase.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Planta </label>
                        <select [compareWith]="compararObjectos" class="form-control" required name="planta" [(ngModel)]="filter.planta">
                        <option [ngValue]="planta" *ngFor="let planta of filter.plantas">{{planta.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>Cliente </label>
                      <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
                    </div>
                  <!--<div class="col-md-1 col-sm-12">
                  <label>¿Inferido? </label>
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.inferido"/>
                              </label>
                  </div>-->
                </div>
              </div>
            </div>
		  </div>
          <div class="col-6 col-md-6">
            <div class="row">
              <div class="col-3">
                <button (click)="buscarReservas()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
                <div class="columns columns-right btn-group float-right">
                  <button title="Nuevo" (click)="crearNuevaReserva()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-primary"></i>
                  </button>
                </div>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col-4">
                    <span class="badge badge-success arrowed-in radius-0 text-white mr-1">
                      Disponible
                    </span>
                    <span class="d-inline-block radius-round bgc-success py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-home fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="badge bgc-warning brc-warning arrowed-in radius-0 text-white mr-1">
                      Reservado
                    </span>
                    <span class="d-inline-block radius-round bgc-warning py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-dolly-flatbed fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="badge badge-danger arrowed-in radius-0 text-white mr-1">
                      Ocupado
                    </span>
                    <span class="d-inline-block radius-round bgc-danger py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-bed fas fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    
                  </div>
                  <div class="col-4">
                    <span class="badge bgc-warning brc-warning arrowed-in radius-0 text-white mr-1">
                      Reservado<br>con pago<br>parcial
                    </span>
                    <span class="d-inline-block radius-round bgc-warning py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-dolly-flatbed fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="badge badge-warning brc-warning arrowed-in radius-0 text-white mr-1">
                      Ocupado<br>con pago<br>parcial
                    </span>
                    <span class="d-inline-block radius-round bgc-warning py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-bed fas fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    
                  </div>
                  <div class="col-4">
                    <span class="badge badge-danger arrowed-in radius-0 text-white mr-1">
                      Reservado<br>pagado
                    </span>
                    <span class="d-inline-block radius-round bgc-danger py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-dolly-flatbed fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                  <div class="col-4">
                    <span class="badge badge-danger arrowed-in radius-0 text-white mr-1">
                      Ocupado<br>pagado
                    </span>
                    <span class="d-inline-block radius-round bgc-danger py-2 px-1 text-center border-2 brc-dark-tp3">
                      <i class="fa-bed fas fas w-4 text-105 text-dark-tp3"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
	    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table id="tabla-reservas" *ngIf="filter.fechas_visualizacion.length>0" class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th style="z-index:2 !important;" class="sticky-col first-col bgc-primary-d1 text-white text-center align-middle">Nº</th>
                    <th style="z-index:2 !important;" class="sticky-col second-col bgc-primary-d1 text-white text-center">Clase</th>
                    <th style="z-index:2 !important;" class="sticky-col third-col bgc-primary-d1 text-white text-center">Ambiente</th>
                    <th class="fechas" *ngFor="let fecha_visualizacion of filter.fechas_visualizacion">
                      <span class="badge bgc-white border-2 brc-blue-m1 text-dark-tp3 badge-pill badge-lg pb-4 px-3 bgc-h-blue-l4">
                        {{fecha_visualizacion | date:'EEE':'es'}}
                      </span>
                      <br>
                      <span class="badge bgc-white border-2 brc-blue-m1 text-dark-tp3 badge-pill badge-lg pb-4 px-3 bgc-h-blue-l4">
                        {{fecha_visualizacion | date:'dd'}}
                      </span>
                      <br>
                      <span class="badge bgc-white border-2 brc-purple-m1 text-dark-tp3 badge-pill badge-lg pb-4 px-3 bgc-h-purple-l3">
                        {{fecha_visualizacion | date:'MMM'}}
                      </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ambiente of ambientes; let i=index;">
                    <td class="sticky-col first-col text-grey bgc-h-success-l3 bgc-white">{{i+1}}</td>
                    <td class="sticky-col second-col text-grey bgc-h-success-l3 bgc-white">
                      <span *ngIf="ambiente.grupo.nombre=='SIMPLE'" class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-90 px-25 py-3px radius-round">
                        {{ambiente.grupo.nombre}}
                      </span>
                      <span *ngIf="ambiente.grupo.nombre=='DOBLE'" class="m-1 bgc-green-l3 border-1 brc-green-m3 text-dark-tp3 text-90 px-25 py-3px radius-round">
                        {{ambiente.grupo.nombre}}
                      </span>
                      <span *ngIf="ambiente.grupo.nombre=='TRIPLE'" class="m-1 bgc-orange-l3 border-1 brc-orange-m3 text-dark-tp3 text-90 px-25 py-3px radius-round">
                        {{ambiente.grupo.nombre}}
                      </span>
                      <span *ngIf="ambiente.grupo.nombre!='SIMPLE' && ambiente.grupo.nombre!='DOBLE' && ambiente.grupo.nombre!='TRIPLE'" class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-90 px-25 py-3px radius-round">
                        {{ambiente.grupo.nombre}}
                      </span>
                    </td>
                    <td class="sticky-col third-col text-grey bgc-h-success-l3 bgc-white">{{ambiente.nombre}}</td>
                    <td class="fechas" *ngFor="let reservas_encontradas of ambiente.reservas_encontradas;">
                      <ng-container *ngFor="let dato of reservas_encontradas">
                        <ng-container *ngIf="(!dato.reserva || !dato.reserva.id || dato.reserva.estado.nombre_corto=='DIS') && !filter.cliente">
                          <a (click)="abrirModalRegistroReserva(null,dato.fecha_visualizacion,ambiente)" href="javascript:void(0)">
                            <span class="d-inline-block radius-round bgc-{{dato.color}} py-2 px-1 text-center border-2 brc-dark-tp3">
                              <i class="{{dato.icono}} w-4 text-105 text-dark-tp3"></i>
                            </span>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="dato.reserva && dato.reserva.id && dato.reserva.estado.nombre_corto!='DIS'">
                          <span *ngIf="dato.fecha_ingreso && dato.reserva.estado.nombre_corto!='DIS'" class="badge badge-primary arrowed-in radius-0 text-white mr-1">
                            {{dato.fecha_ingreso | date:'HH:mm'}}
                          </span>
                          <div class="dropdown d-inline-block dd-backdrop dd-backdrop-none-md">
                            <!--<a class="d-style btn btn-white btn-h-lighter-yellow btn-a-yellow shadow-sm radius-1 text-600 letter-spacing px-4 dropdown-toggle" href="#" role="button" id="dropdownMenuLink7" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa fa-share mr-2 f-n-hover"></i>
                              Share!
                            </a>-->
                            <a class="dropdown-toggle " role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="javascript:void(0)">
                              <span class="d-inline-block radius-round bgc-{{dato.color}} py-2 px-1 text-center border-2 brc-dark-tp3">
                                <i class="{{dato.icono}} w-4 text-105 text-dark-tp3"></i>
                              </span>
                            </a>
      
                            <div class="dropdown-menu dropdown-menu-right radius-1 shadow py-1" style="width: 150px;">
                              <div class="row justify-content-center mx-1">
                                <div class="col-3 col-sm-3 px-0">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verReserva(dato.reserva,ambiente)">
                                    <i class="ace-icon fa fa-search-plus bigger-130 text-170 mt-2 w-3 f-hover"></i>
                                  </a>
                                </div>
                                <div class="col-3 col-sm-3 px-0" *ngIf="!dato.reserva.id_venta">
                                  <a title="Generar Venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirModalRegistroReserva(dato.reserva,fecha_visualizacion,ambiente)">
                                      <i class="fas fa-money-bill-alt text-170 mt-2 w-3 f-hover"></i>
                                  </a>
                                </div>
      
                                <div class="col-3 col-sm-3 px-0" *ngIf="dato.reserva.id_venta && dato.reserva.venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE && dato.reserva.venta.saldo>0">
                                  <a title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearPagoVenta(dato.reserva)">
                                    <i class="fas fa-dollar-sign text-170 mt-2 w-3"></i>
                                  </a>
                                </div>
      
                                <div class="col-3 col-sm-3 px-0">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoReserva(dato.reserva,fecha_visualizacion)">
                                    <i class="fa fa-exchange-alt text-170 mt-2 w-3 f-hover"></i>
                                  </a>
                                </div>
      
                                <div class="col-3 col-sm-3 px-0" *ngIf="aplicacion.puede_eliminar">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(dato.reserva)">
                                    <i class="fa fa-trash-alt text-170 mt-2 w-3 f-hover"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div><!-- /.dropdown -->
                          <span *ngIf="dato.fecha_salida && dato.reserva.estado.nombre_corto!='DIS'" class="badge badge-warning arrowed-in-right radius-0 text-white mr-1">
                            {{dato.fecha_salida | date:'HH:mm'}}
                          </span>
                        </ng-container>
                      </ng-container>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modal_cambio_estado let-modal>
  <div class="modal-header">
    <div class="col-md-6 col-xs-6">
      <h4 class="widget-title">Cambio de estado</h4>
    </div>
    <div class="col-md-6 col-xs-6">
      <button class="btn btn-primary" type="button" (click)="cambiarEstadoReserva()">
        <i class="fa fa-save align-top bigger-110"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
        <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="card card-info card-outline">
      <div class="box box-primary">
        <div class="box-body">
          <div class="row">
              <div class="col-12">
                <label>Estado </label>
                <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="reserva.estado">
                  <option [ngValue]="estado" *ngFor="let estado of estados_cambio">{{estado.nombre}}</option>
                </select>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal_vista_reserva let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Datos Reserva</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-6">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user"></i> Cliente : 
                    </label>    
                </div>
                <div class="col-6 align-middle">
                    <label>
                        {{reserva.cliente.identificacion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha-Hora Ingreso : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{reserva.fecha_ingreso | date:'dd/MM/yyyy - HH:mm'}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha-Hora Salida : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                        {{reserva.fecha_salida | date:'dd/MM/yyyy - HH:mm'}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-6">
          <div class="row">
              <div class="col-6 alert alert-info pr-0 mb-0">
                  <label class="text-primary p-0">
                      <i class="fa fa-user"></i> Ambiente : 
                  </label>    
              </div>
              <div class="col-6 align-middle">
                  <label>
                      {{ambiente.nombre}} - {{ambiente.grupo.nombre}} - {{ambiente.subgrupo.nombre}}
                  </label>    
              </div>
          </div>
          <div class="row">
              <div class="col-6 alert alert-info pr-0 mb-0">
                  <label class="text-primary p-0">
                      <i class="fas fa-unlock red2 text-110 mr-1"></i> Estado : 
                  </label>    
              </div>
              <div class="col-6">
                  <label>
                      {{reserva.estado.nombre}}
                  </label>    
              </div>
          </div>
      </div>
    </div>
    <div class="row p-1" *ngIf="reserva.venta">
      <div class="col-md-6">
          <div class="row">
            <div class="col-6 alert alert-info pr-0 mb-0">  
                <label class="text-primary p-0">
                    <i class="fa fa-user red2 text-110 mr-1"></i> Num. Documento : 
                </label>  
            </div>
            <div class="col-6">
                <label>
                    {{reserva.venta.factura}}
                </label>    
            </div>
          </div>
          <div class="row">
            <div class="col-7 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Tipo de pago : 
                </label>    
            </div>
            <div class="col-5">
                <label>
                    {{reserva.venta.tipoPago.nombre}}
                </label>    
            </div>
          </div>
          <div class="row" *ngIf="reserva.venta.saldo">
            <div class="col-7 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Total : 
                </label>    
            </div>
            <div class="col-5">
                <label>
                    {{reserva.venta.total}}
                </label>    
            </div>
          </div>
      </div>
      <div class="col-md-6">
        <div class="row" *ngIf="reserva.venta.tipoPago.nombre=='CREDITO'">
          <div class="col-7 alert alert-info pr-0 mb-0">
              <label class="text-primary p-0">
                  <i class="fas fa-money-bill-alt"></i> A Cuenta (Bs) : 
              </label>    
          </div>
          <div class="col-5">
              <label>
                  {{reserva.venta.a_cuenta}}
              </label>    
          </div>
        </div>
        <div class="row" *ngIf="reserva.venta.tipoPago.nombre=='CREDITO'">
            <div class="col-7 alert alert-info pr-0 mb-0">
                <label class="text-primary p-0">
                    <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                </label>    
            </div>
            <div class="col-5">
                <label>
                    {{reserva.venta.saldo}}
                </label>    
            </div>
        </div>  
      </div>
  </div>
</div>
</ng-template>