import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-empresa-facturacion',
  templateUrl: './empresa-facturacion.component.html',
  styleUrls: ['./empresa-facturacion.component.css']
})
export class EmpresaFacturacionComponent extends BaseComponent implements OnInit {

  empresa:any={};
  tipoSinAmbientes:any[]=[];
  tipoSinModalidadesFacturacion:any[]=[];
  tipoSinDocumentosFacturacion:any[]=[];
  documentos_facturacion:IMultiSelectOption[]=[];

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
	public usuariosService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private empresasService:EmpresaService) { 
      super(persistenciaService,modalService,generalService,usuariosService);
    }

  ngOnInit(): void {
    this.obtenerAmbientesSin();
    this.obtenerModalidadeFacturacion();
	this.obtenerTiposDocumentosFacturacion();
  }

  ngAfterViewInit(){
    aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:10,acceptedFiles:".p12"});
  }

  validarDatosFacturacion(){
		let res=true;
		if(!this.empresa.razon_social){
			this.toastr.error("Debe ingresar la razon social de la empresa!");
			res=false;
		}
		if(!this.empresa.nit){
			this.toastr.error("Debe ingresar el nit de la empresa!");
			res=false;
		}
		if(!this.empresa.sin_codigo_sistema){
			this.toastr.error("Debe ingresar el codigo de sistema!");
			res=false;
		}
		if(!this.empresa.sin_modalidad_facturacion){
			this.toastr.error("Debe ingresar la modalidad de facturación!");
			res=false;
		}
		if(!this.empresa.sin_ambiente){
			this.toastr.error("Debe ingresar el ambiente de facturación!");
			res=false;
		}
			return res;
	  }

	guardarDatosFacturacion(){
		if(this.validarDatosFacturacion()){
			this.blockUI.start();
			this.empresasService.guardarDatosFacturacionEmpresa(this.empresa).subscribe((res:any)=>{
        //this.toastr.success(res.mensaje);
				this.blockUI.stop();
				this.alTerminar.emit({mensaje:res.mensaje});
			});
		}
	}

  obtenerCufd(){
    this.blockUI.start();
		this.empresasService.obtenerCufd(this.empresa.id).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.empresa.sin_cufd=res.sin_cufd;
        this.toastr.success(res.mensaje);
      }
			this.blockUI.stop();
		});
	}

  obtenerAmbientesSin(){
		this.generalService.obtenerClases("SINAMB").subscribe((entidad:any)=>{
      this.tipoSinAmbientes=entidad?entidad.clases:[];
    });
  }

  obtenerModalidadeFacturacion(){
		this.generalService.obtenerClases("SINMODFACT").subscribe((entidad:any)=>{
      this.tipoSinModalidadesFacturacion=entidad?entidad.clases:[];
    });
  }

  	obtenerTiposDocumentosFacturacion(){
		this.generalService.obtenerClases("SINDOCSECTOR").subscribe((entidad:any)=>{
  			this.tipoSinDocumentosFacturacion=entidad?entidad.clases:[];
			  this.llenarDocumentosFacturacion(this.tipoSinDocumentosFacturacion);
		});
	}

	llenarDocumentosFacturacion(datos){
		this.documentos_facturacion=[];
		for(var i=0;i<datos.length;i++){
			var documento_facturacion={
				name:datos[i].nombre,
				maker: "",
				ticked:false,
				id:datos[i].id
			}
			this.documentos_facturacion.push(documento_facturacion);
		}
  	}

  cerrarComponente(){
		this.alTerminar.emit();
	}

	subirArchivo(file){
		let me=this;
		var fileToLoad = file;
		var fileReader = new FileReader();

		fileReader.onloadend = function() 
		{
			let base64EncodedImage = fileReader.result; // <--- data: base64
			me.empresa.sin_firma_digital=base64EncodedImage;
		}
		fileReader.readAsDataURL(fileToLoad);	
	}

	eliminarArchivo(file){
		let me=this;
		var fileToLoad = file;
		var fileReader = new FileReader();
	
		fileReader.onloadend = function() 
		{
			let base64EncodedImage = fileReader.result; // <--- data: base64
			me.empresa.sin_firma_digital=null;
		}
		fileReader.readAsDataURL(fileToLoad);	
	}

}
