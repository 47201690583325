<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Combos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Combos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
              </button>
              <button title="Nuevo" (click)="crearNuevoCombo()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
              </div>
              <div class="keep-open btn-group show" title="Columns">
                <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="combos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().publicado_panel.show" class="center">
                    <!--<a href="javascript:void(0)" (click)="paginator.sortColumn('publicar_panel')">¿Publicado Panel? 
                        <span id="publicar_panel" class="sort fa fa-sort icon-only"></span>
                    </a>
                    <label>
                        <input (change)="actualizarAtributoProducto(null,'publicar_panel',tabla_producto_general.publicar_panel)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.publicar_panel"/>
                    </label>-->
                    <div class="th-inner ">
                      ¿Publicar Panel?
                    </div><div class="fht-cell"></div>
				        </th>		
                <th id="cabecera_codigo" class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().codigo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="cabecera_nombre" class="center" *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Nombre</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="th-inner ">
                        Imagen
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().unidad_medida.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='unidad_medida' && this.direction=='asc','desc':this.column=='unidad_medida' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('unidad_medida')">Unid. de Med.</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='precio_unitario' && this.direction=='asc','desc':this.column=='precio_unitario' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('precio_unitario')">P. Unit.</a>
                    </div><div class="fht-cell"></div>
                </th>
								
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='descripcion' && this.direction=='asc','desc':this.column=='descripcion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">Descripción</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #combos *ngFor="let combo of combos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().publicado_panel.show">
                    <label>
                        <input (change)="actualizarAtributoProducto(combo,'publicar_panel',producto.publicar_panel)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="combo.publicar_panel"/>
                    </label>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{combo.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                    {{combo.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                  <a href="#" class="show-lightbox">
                    <img alt="{{combo.nombre}}" src="{{rest_server+combo.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                  </a>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().unidad_medida.show">{{combo.unidad_medida}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">{{combo.precio_unitario}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                    {{combo.descripcion}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a title="Reporte en pdf" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="#" (click)="imprimirPdf(combo)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>     
                    <a title="Reporte en excel" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="#" (click)="imprimirExcel(combo)">          
                      <i class="fa fa-file-excel text-105"></i>        
                    </a>          
                    <a title="Editar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCombo(combo)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
                    </a>        
                    <a title="Eliminar" class="text-danger-m1 mx-2px" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(combo)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="combos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #edicioncombo let-modal>
  <div class="row">
  <div class="col-12">
    <div class="card">
    <div class="card-header">
      <span class="text-blue text-125">Registro Combo</span>

      <div class="card-toolbar ml-auto no-border pr-2 d-none">
      <label>
        <span class="align-middle d-block d-sm-inline">Validation:</span>
        <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
      </label>
      </div>
      <div class="card-toolbar pl-3">
      <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
        <i class="fa fa-chevron-left"></i>
      </button>
      <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
        <i class="fa fa-chevron-right"></i>
      </button>
      <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
        <i class="fa fa-arrow-right"></i>
      </button>
      <button (click)="cerrarRegistroCombo()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
        <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>

    <div class="card-body px-2">
      <div id="{{wizard_edicion_combo}}" class="d-none">
      <ul class="mx-auto">
        <li class="wizard-progressbar"></li>
        <li>
        <a href="#step-1">
          <span class="step-title">
            1
          </span>

          <span class="step-title-done">
            <i class="fa fa-check text-success-m1"></i>
          </span>
        </a>
        <span class="step-description">
            Datos del Combo
        </span>
        </li>
        <li>
        <a href="#step-2">
          <span class="step-title">
            2
          </span>

          <span class="step-title-done">
            <i class="fa fa-check text-success-m1"></i>
          </span>
        </a>
        <span class="step-description">
            Datos Adicionales
        </span>
        </li>
      </ul>

      <div class="px-2 py-2 mb-4">
        <div id="step-1" class="">

        <form novalidate>
          <div class="  form-row">
            <div class="col-sm-2 col-form-label text-sm-right text-grey">
                Código 
            </div>
            <div *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)" class="col-sm-2">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input required type="text" name="codigo" id="codigo" [(ngModel)]="combo.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
              </div>
            </div>
            <div [ngClass]="{'text-danger-m1':(!combo.nombre)}" class="col-sm-2 col-form-label text-sm-right">
              Nombre
            </div>

            <div class="col-sm-6">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <input required type="text" id="nombre" name="nombre" [(ngModel)]="combo.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!combo.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                <i *ngIf="!combo.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
              </div>


              <div *ngIf="!combo.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                <div class="arrow brc-danger-m1 mt-0"></div>
                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
              </div>

              <span *ngIf="!combo.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
            </div>
          </div>
          <div class="  form-row">
            <div [ngClass]="{'text-danger-m1':(!combo.unidad_medida)}" class="col-sm-1 col-form-label text-sm-right">
              Unid. Med.
            </div>
            <div class="col-sm-3">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input required type="text" id="und" name="und" [(ngModel)]="combo.unidad_medida" placeholder="unidad de medida" [ngClass]="{'brc-danger-m1':(!combo.unidad_medida)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                
                <i *ngIf="!combo.unidad_medida" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
              </div>


              <div *ngIf="!combo.unidad_medida" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                <div class="arrow brc-danger-m1 mt-0"></div>
                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
              </div>

              <span *ngIf="!combo.unidad_medida" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
            </div>

            <div class="col-sm-3 col-form-label text-sm-right">
              Descripción
            </div>

            <div class="col-sm-5">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input required type="text" id="descripcion" name="descripcion" [(ngModel)]="combo.descripcion" placeholder="Descripción" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-1 col-form-label text-sm-right" *ngIf="usuario.empresa.usar_combos_conceptos">
							Grupo
							<span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_COMBO_GRUPOS,'obtenerGruposCombosEmpresa')"></span>
						</div>
						<div class="col-sm-3" *ngIf="usuario.empresa.usar_combos_conceptos">
							<div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
								<select [compareWith]="compararObjectos" class="form-control" required name="grupo_producto" [(ngModel)]="combo.grupo">
									<option [ngValue]="grupoCombo" *ngFor="let grupoCombo of tipo_grupo.clases | filterBy:{habilitado:true}">{{grupoCombo.nombre}}</option>
								</select>
							</div>
						</div>
          </div>
          <div class="  form-row">
            <div class="col-sm-3 col-form-label text-sm-right">
              Imágen
            </div>

            <div class="col-sm-9">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <div>
                  <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                </div>
              </div>
            </div>
          </div>
        </form>

        </div>

        <div id="step-2">
          <div class="  form-row">
            <div class="col-sm-6 col-form-label text-sm-center text-grey" *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Conceptos de precios</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-bordered table-hover" *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
                    <thead>
                      <tr>
                        <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">Sucursal</th>
                        <th>Concepto</th>
                        <th>Precio Unitario</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                          <select [compareWith]="compararObjectos" class="form-control" required name="id_sucursal" [(ngModel)]="producto_precio.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                          </select>
                        </td>
                        <td>
                          <select [compareWith]="compararObjectos" class="form-control" required name="id_concepto" [(ngModel)]="producto_precio.concepto">
                            <option [ngValue]="precios_producto" *ngFor="let precios_producto of tipo_producto_precio.clases">{{precios_producto.nombre}}</option>
                          </select>
                          <span *ngIf="usuario.empresa.usar_precios_productos" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_PRECIOS,'obtenerConceptosPreciosProductos')"></span> &nbsp;
                        </td>
                        <td>
                          <input name="id_precio" id="id_precio" class="form-control text-center" type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001"
                          min="0">
                        </td>
                        <td>
                          <button *ngIf="usuario.empresa.usar_precios_productos" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(producto_precio)"
                              class="btn btn-success btn-sm">
                            <i class="ace-icon fa fa-arrow-right bigger-110"></i>
                          </button>
                          <button *ngIf="usuario.empresa.usar_precios_productos_sucursal" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(producto_precio)"
                              class="btn btn-success btn-sm">
                            <i class="ace-icon fa fa-arrow-right bigger-110"></i>
                          </button>
                        </td>
    
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-form-label text-sm-center text-grey" *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Precios</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                          Sucursal
                        </th>
                        <th>
                          Concepto
                        </th>
                        <th>
                          Precio Unitario
                        </th>
                        <th>
                          Por Defecto
                        </th>
                        <th>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let producto_precio of combo.precios">
                        <tr *ngIf="!producto_precio.eliminado">
                          <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                            {{producto_precio.sucursal.nombre}}
                          </td>
                          <td>
                            {{producto_precio.concepto.nombre}}
                          </td>
                          <td>
                            <input class="form-control text-center" (change)="cambiarPrecio(producto_precio)" required type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001" min="0">
                          </td>
                          <td>
                            <label>
                              <input (change)="establecerPrecioPorDefecto(producto_precio)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_precio.por_defecto"/>
                            </label>
                          </td>
                          <td>
                            <a class="text-danger-m1 mx-2px" e-disabled="!aplicacion.puede_eliminar || (!usuario.empresa.usar_precios_productos && !usuario.empresa.usar_precios_productos_sucursal)" href="javascript:void(0)" (click)="eliminarDetalleProductosPrecio(producto_precio)">
                              <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                            </a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                </div>
            </div>
          </div>
          <div class="  form-row">
            <div class="col-sm-12 col-form-label text-sm-center text-grey">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Producto</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Unidad de Medida</th>
                        <th>Cantidad</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                          <input  [inputFormatter]="formatter" (selectItem)="establecerProducto()" [resultTemplate]="rt" id="typeahead-http" type="text" class="form-control mx-sm-3" [class.is-invalid]="searchFailed" [(ngModel)]="producto" [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" />
                          <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                          <div class="invalid-feedback" *ngIf="searchFailed">Lo sentimos, los productos no pueden ser cargados.</div>
                        </td>
                        <td>
                          {{producto.unidad_medida}}
                        </td>
                        <td>
                          <input name="cantidad" class="form-control text-center" type="number" [(ngModel)]="producto.cantidad" step="0.001" min="0">
                        </td>
                        <td>
                          <button type="button" [disabled]="producto==null || producto.cantidad==null" (click)="agregarDetalleCombo(producto)"
                              class="btn btn-success btn-sm">
                            <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                          </button>
                        </td>
    
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-form-label text-sm-center text-grey">
              <div class="card bgc-success brc-success radius-0">
                <div class="card-header" data-toggle="collapse" href="#composicionoculta">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px "></i> Composición <i class="fas fa-arrow-circle-up"></i></h5>
                </div>
      
                <div class="card-body p-0 bg-white" id="composicionoculta">
                  <table class="table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>
                          Producto
                        </th>
                        <th>
                          Und. Med.
                        </th>
                        <th>
                          P. U.
                        </th>
                        <th>
                          Cantidad
                        </th>
                        <th>
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let detalleCombo of combo.detallesCombo">
                        <tr *ngIf="!detalleCombo.eliminado">
                          <td>
                            {{detalleCombo.producto.nombre}}
                          </td>
                          <td>
                            {{detalleCombo.producto.unidad_medida}}
                          </td>
                          <td>
                            {{detalleCombo.producto.precio_unitario}}
                          </td>
                          <td>
                            {{detalleCombo.cantidad}}
                          </td>
                          <td>
                            <button type="button" (click)="eliminarDetalleCombo(detalleCombo)"
                                class="btn btn-danger btn-sm">
                              <i class="fa fa-trash bigger-110"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-sm-center text-grey">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Materiales</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>
                          Producto
                        </th>
                        <th>
                          Und. Med.
                        </th>
                        <th>
                          P. U.
                        </th>
                        <th>
                          Cantidad
                        </th>
                        <th>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let detalleCombo of materiales">
                        <tr *ngIf="!detalleCombo.eliminado">
                          <td>
                            {{detalleCombo.producto.nombre}}
                          </td>
                          <td>
                            {{detalleCombo.producto.unidad_medida}}
                          </td>
                          <td>
                            {{detalleCombo.producto.precio_unitario}}
                          </td>
                          <td>
                            {{detalleCombo.cantidad}}
                          </td>
                          <td>
                            {{detalleCombo.cantidad * detalleCombo.producto.precio_unitario}}
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Material</td>
                        <td>{{sumarMaterial()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-sm-center text-grey">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Mano de Obra</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>
                          Producto
                        </th>
                        <th>
                          Und. Med.
                        </th>
                        <th>
                          P. U.
                        </th>
                        <th>
                          Cantidad
                        </th>
                        <th>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let detalleCombo of manos_de_obra">
                        <tr *ngIf="!detalleCombo.eliminado">
                          <td>
                            {{detalleCombo.producto.nombre}}
                          </td>
                          <td>
                            {{detalleCombo.producto.unidad_medida}}
                          </td>
                          <td>
                            {{detalleCombo.producto.precio_unitario}}
                          </td>
                          <td>
                            {{detalleCombo.cantidad}}
                          </td>
                          <td>
                            {{detalleCombo.cantidad * detalleCombo.producto.precio_unitario}}
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Mano de Obra inicial</td>
                        <td>{{sumarManoDeObra()}}</td>
                      </tr>
                      <tr>
                        <td>{{beneficios_sociales.nombre}}</td>
                        <td></td>
                        <td></td>
                        <td>{{beneficios_sociales.nombre_corto}}</td>
                        <td>{{calcularBeneficiosSociales()}}</td>
                      </tr>
                      <tr>
                        <td>{{iva.nombre}}</td>
                        <td></td>
                        <td></td>
                        <td>{{iva.nombre_corto}}</td>
                        <td>{{calcularIva()}}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Mano de Obra</td>
                        <td>{{sumarManoDeObraBeneficios()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-grey">
              <div class="card bgc-purple brc-purple radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i>{{concepto_equipomaquinaria.nombre}}</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          HERRAMIENTAS - % DE LA MANO DE OBRA
                        </td>
                        <td>
                          {{concepto_equipomaquinaria.nombre_corto}}
                        </td>
                        <td>
                          {{0}}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Maquinaria y Equipo</td>
                        <td>{{calcularMaquinariaEquipo()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-grey">
              <div class="card bgc-purple brc-purple radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i>{{gastos_generales.nombre}}</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          GASTOS GENERALES - % DE 1+2+3
                        </td>
                        <td>
                          {{gastos_generales.nombre_corto}}
                        </td>
                        <td>
                          {{0}}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Material</td>
                        <td>{{calcularGastosGenerales()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-grey">
              <div class="card bgc-purple brc-purple radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i>{{utilidad.nombre}}</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          UTILIDAD - % DE 1+2+3+4
                        </td>
                        <td>
                          {{utilidad.nombre_corto}}
                        </td>
                        <td>
                          {{0}}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Utilidad</td>
                        <td>{{calcularUtilidad()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_combos_conceptos" class="col-sm-12 col-form-label text-grey">
              <div class="card bgc-purple brc-purple radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i>{{impuestos.nombre}}</h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          IMPUESTO A LAS TRANSACCIONES - % DE 1+2+3+4+5
                        </td>
                        <td>
                          {{impuestos.nombre_corto}}
                        </td>
                        <td>
                          {{0}}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Impuestos</td>
                        <td>{{calcularImpuestos()}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="  form-row">
            <div class="col-sm-2 col-form-label text-sm-right">¿Publicar Panel?</div>
            <div class="col-sm-1">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <label>
                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="combo.publicar_panel"/>
                </label>
              </div>
            </div>
            <div class="col-sm-2 col-form-label text-sm-right">¿Precio Calculado?</div>
            <div class="col-sm-2">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <label>
                  <input (change)="calcularPrecioUnitario()" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="combo.precio_calculado"/>
                </label>
              </div>
            </div>
            <div [ngClass]="{'text-danger-m1':(!combo.precio_unitario)}" class="col-sm-2 col-form-label text-sm-right">
              Precio Unitario
            </div>

            <div class="col-sm-3">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input required type="number" [disabled]="combo.precio_calculado" name="precio_unitario" [(ngModel)]="combo.precio_unitario" placeholder="Precio Unitario" [ngClass]="{'brc-danger-m1':(!combo.precio_unitario)}" step="0.001" min="0" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                <i *ngIf="!combo.precio_unitario" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
              </div>


              <div *ngIf="!combo.precio_unitario" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                <div class="arrow brc-danger-m1 mt-0"></div>
                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
              </div>

              <span *ngIf="!combo.precio_unitario" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
    </div> <!-- .card -->

  </div>
  </div>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
  <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
  <ngb-highlight [result]="r.codigo+'-'+r.nombre" [term]="t"></ngb-highlight>
</ng-template>

<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>