import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-sin-lista-eventos-significativos',
  templateUrl: './sin-lista-eventos-significativos.component.html',
  styleUrls: ['./sin-lista-eventos-significativos.component.css']
})
export class SinListaEventosSignificativosComponent extends BaseComponent implements OnInit {

  lista_eventos_significativos:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  evento_significativo:any={};
  eventos_significativos:any[]=[];
  sucursales:any[]=[];
  codigos_cafc:any[]=[];
  bandera_cafc:boolean=true;

  registro_evento_significativo:NgbModalRef;
  @ViewChild('registro_evento_significativo')
  private registro_evento_significativo_ref: TemplateRef<any>;

  ventas_evento_significativo:NgbModalRef;
  @ViewChild('ventas_evento_significativo')
  private ventas_evento_significativo_ref: TemplateRef<any>;

  cufds:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public ventasService:VentasService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public usuarioService:UsuarioService,
    public empresaService:EmpresaService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    let fecha_actual=new Date();
    let fecha_inicio=fecha_actual;
    this.filter={
      id_empresa:this.usuario.id_empresa,
      fecha_inicio:{
        year: fecha_inicio.getFullYear(), 
        month: (fecha_inicio.getMonth()+1),
        day:fecha_inicio.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      sucursales:this.sucursales,
		  sucursal: this.sucursales[0],
    }
    this.obtenerEventosSignificativos();
    this.getItems();
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  crearEventoSignificativo(){
    let fecha_actual=new Date();
    this.evento_significativo={
      sucursal:this.sucursales[0],
      evento:this.eventos_significativos[0],
      fecha_inicio_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      hora_inicio:{
        hour:fecha_actual.getHours(),
        minute:fecha_actual.getMinutes()
      },
      sin_sucursal_cufd:{},
      activo:true
    }
    this.establecerFechaFin();
    this.obtenerUltimosCodigosSucursal();
    this.registro_evento_significativo=this.modalService.open(this.registro_evento_significativo_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_evento_significativo.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  obtenerUltimosCodigosSucursal(){
    this.evento_significativo.codigo_cafc=null;
    this.obtenerCufdUltimos();
    this.obtenerUltimosCafc();
  }

  establecerFechaFin(){
    if(this.evento_significativo.evento.nombre_corto=="5" || this.evento_significativo.evento.nombre_corto=="6" || this.evento_significativo.evento.nombre_corto=="7"){
      let fecha_actual=new Date();
      this.evento_significativo.fecha_fin_texto={
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      };
      this.evento_significativo.hora_fin={
        hour:fecha_actual.getHours(),
        minute:fecha_actual.getMinutes()
      };
    }else{
      this.evento_significativo.fecha_fin_texto=null;
      this.evento_significativo.fecha_fin=null;
      this.evento_significativo.hora_fin=null;
    }
    this.evento_significativo.codigo_cafc=null;
  }

  async obtenerEventosSignificativos(){
    let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_EVENTOS_SIGNIFICATIVOS).toPromise();
    this.eventos_significativos=entidad.clases;
  }

  obtenerCufdUltimos(){
    this.blockUI.start();
    this.empresaService.obtenerCufdUltimos(this.evento_significativo.sucursal.id).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
        this.cufds=res.sucursal_cufds;
        this.evento_significativo.sin_sucursal_cufd=res.sucursal_cufds[0];
      }
      this.blockUI.stop();
    });
  }

  obtenerUltimosCafc(){
    this.blockUI.start();
    this.empresaService.obtenerUltimosCafc(this.evento_significativo.sucursal.id).subscribe((res:any)=>{
      if(res.tiene_error){
        this.bandera_cafc=false;
        this.toastr.error(res.mensaje);
        this.codigos_cafc=[];
      }else{
        this.bandera_cafc=true;
        this.toastr.success(res.mensaje);
        this.codigos_cafc=res.sucursal_cafcs;
        //this.evento_significativo.sin_sucursal_cufd=res.sucursal_cafcs[0];
      }
      this.blockUI.stop();
    });
  }

  cambiarBanderaCafc(){
    this.bandera_cafc=!this.bandera_cafc;
  }

  async validarRegistroEventoSignificativo(){
    let res=true;
    if(!this.evento_significativo.evento){
      res=res && false;
      this.toastr.error("Debe establecer el evento!");
    }
    if(!this.evento_significativo.sin_sucursal_cufd.id){
      res=res && false;
      this.toastr.error("Debe existir al menos el ultimo codigo CUFD!");
    }
    if(!this.evento_significativo.fecha_inicio_texto || !this.evento_significativo.fecha_inicio_texto.year || !this.evento_significativo.fecha_inicio_texto.month || !this.evento_significativo.fecha_inicio_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de inicio completa para el evento!");
    }
    if(!this.evento_significativo.hora_inicio || !this.evento_significativo.hora_inicio.hour || (this.evento_significativo.hora_inicio.minute==null || this.evento_significativo.hora_inicio.minute==undefined)){
      res=res && false;
      this.toastr.error("Debe especificar la hora de inicio completa para el evento!");
    }
    if(res){
      this.evento_significativo.fecha_inicio=Util.convertirObjetoAfecha(this.evento_significativo.fecha_inicio_texto);
      this.evento_significativo.fecha_inicio.setHours(this.evento_significativo.hora_inicio.hour);
      this.evento_significativo.fecha_inicio.setMinutes(this.evento_significativo.hora_inicio.minute);
    }
    if(this.evento_significativo.evento.nombre_corto=="5" || this.evento_significativo.evento.nombre_corto=="6" || this.evento_significativo.evento.nombre_corto=="7"){
      if(!this.evento_significativo.fecha_fin_texto || !this.evento_significativo.fecha_fin_texto.year || !this.evento_significativo.fecha_fin_texto.month || !this.evento_significativo.fecha_fin_texto.day){
        res=res && false;
        this.toastr.error("Debe especificar la fecha de finalizacion completa para el evento!");
      }
      if(!this.evento_significativo.hora_fin || !this.evento_significativo.hora_fin.hour || (this.evento_significativo.hora_fin.minute==null || this.evento_significativo.hora_fin.minute==undefined)){
        res=res && false;
        this.toastr.error("Debe especificar la hora de finalizacion completa para el evento!");
      }
      if(res){
        this.evento_significativo.fecha_fin=Util.convertirObjetoAfecha(this.evento_significativo.fecha_fin_texto);
        this.evento_significativo.fecha_fin.setHours(this.evento_significativo.hora_fin.hour);
        this.evento_significativo.fecha_fin.setMinutes(this.evento_significativo.hora_fin.minute);
        if(this.evento_significativo.fecha_fin.getTime()<=this.evento_significativo.fecha_inicio.getTime()){
          res=res && false;
          this.toastr.error("La fecha de finalizacion no debe ser menor o igual a la fecha de inicio!");
        }
      }
      if(!this.evento_significativo.codigo_cafc){
        res=res && false;
        this.toastr.error("Debe especificar el codigo CAFC!");
      }
    }
    return res;
  }

  async guardarEventoSignificativo(){
    if(await this.validarRegistroEventoSignificativo()){
      this.blockUI.start();
      this.ventasService.activarEventoSignificativo(this.evento_significativo).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          if(res.stack){
            this.toastr.error(res.stack);
          }
        }else{
          this.toastr.success(res.mensaje);
          this.getItems();
          this.registro_evento_significativo.close();
        }
      });
    }
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.empresaService.obtenerListaEventosSignificativos(this).subscribe((eventos:any) => {
			this.setPages(1);
			this.lista_eventos_significativos=eventos;
			this.blockUI.stop();
		});
  }

  verVentas(evento_significativo){
    this.evento_significativo=evento_significativo;
    //recuperar y mostrar todas las ventas que se generaron dentro del evento significativo
    this.blockUI.start();
    this.ventasService.obtenerVentasEventoSignificativo(evento_significativo.id).subscribe((ventas:any[])=>{
      this.evento_significativo.ventas=ventas;
      this.ventas_evento_significativo=this.modalService.open(this.ventas_evento_significativo_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.ventas_evento_significativo.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      this.blockUI.stop();
    });
  }

  sincronizarEventoSignificativo(evento_significativo){
    this.blockUI.start();
    this.ventasService.desactivarEventoSignificativo(evento_significativo).subscribe((res:any)=>{
      this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
        this.getItems();
				this.toastr.success(res.mensaje);
			}
    });
  }

  validarRecepcionMasivaFacturas(evento_significativo){
    this.evento_significativo=evento_significativo;
    //recuperar y mostrar todas las ventas que se generaron dentro del evento significativo
    this.blockUI.start();
    this.empresaService.validarRecepcionMasivaFacturas(evento_significativo).subscribe((res:any)=>{
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.getItems();
        this.toastr.success(res.mensaje);
      }
    });
  }

  abrirPopupConfirmacionEliminacion(evento_significativo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el evento significativo?";
		this.popupConfirmacion.componentInstance.data = evento_significativo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarEventoSignificativo($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarEventoSignificativo(evento_significativo){
		this.blockUI.start();
		this.ventasService.anularEventoSignificativo(evento_significativo).subscribe((res:any) => {
      this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
			}
		});
	}

}
