<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Calendario
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80 opacity-1"></i>
          Pedidos
        </small>
      </h1>
    </div>


    <!-- message to be displayed on touch devices -->
    <div id="alert-1" class="d-none alert bgc-white border-l-4 brc-purple-m1 shadow-sm" role="alert">
      Touch a date slot and hold down to add a new event
    </div>

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row" *ngIf="usuario.empresa.usar_calendario_cursos">
        <div class="col-2"><label>Curso - Materia </label></div>
        <div class="col-6">
            <select [compareWith]="compararObjectos" class="form-control" required name="registro_profesor" [(ngModel)]="filter.registro_profesor">
                <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
            </select>
        </div>
        <div class="col-2">
            <button (click)="obtenerEventos()" type="button" class="btn btn-sm btn-lighter-info btn-a-outline-info dropdown-toggle border-b-2">
                <i class="fa fa-search text-180"></i>
            </button>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12 col-md-12" id='calendar-container'>
        <div class="card acard">
          <div class="card-body p-lg-4">
            <div id='calendar' class="text-blue-d1"></div>
          </div>
        </div>
      </div>
    </div>
</div>