import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EmpleadosService } from '../../services/empleados/empleados.service';

@Component({
  selector: 'app-registro-asistencia',
  templateUrl: './registro-asistencia.component.html',
  styleUrls: ['./registro-asistencia.component.css']
})
export class RegistroAsistenciaComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  time:any;
  temporizador:any;
  saludo:string;
  ubicacion_permitida:boolean=false;
  usuario_posicion:any;
  turnos:any=[];

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public usuarioService:UsuarioService,
    public empleadosService:EmpleadosService,
    public toastr:ToastrService,
    private deviceService: DeviceDetectorService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.usuario_posicion={
      id_usuario:this.usuario.id,
      descripcion:this.deviceService.getDeviceInfo()+";"+(this.deviceService.isMobile()?"Telefono":(this.deviceService.isTablet()?"Tablet":(this.deviceService.isDesktop()?"Escritorio":"")))
    }
    this.consultarEstado();
    this.obtenerPosicionActual();
    this.obtenerTurnosEmpleadoPersona();
  }

  obtenerTurnosEmpleadoPersona(){
    this.empleadosService.obtenerTurnosEmpleadoPersona(this.usuario.id_persona).subscribe((empleado:any)=>{
      this.turnos=empleado.turnos;
    });
  }
  
  async obtenerPosicionActual(){
    let res=false;
    try{
      let ubicacion_actual:any=await this.generalService.obtenerPosicionActual(); 
      console.log(ubicacion_actual);
      res=true;
      this.ubicacion_permitida=true;
      this.usuario_posicion.latitude=ubicacion_actual.lat;
      this.usuario_posicion.longitude=ubicacion_actual.lng;
    }catch(err){
      console.log(err);
      res=false;
      this.ubicacion_permitida=false;
    }

    return res;
    /*this.generalService.obtenerPosicionActual().then(pos=>
      {
         console.log(`Positon: ${pos.lng} ${pos.lat}`);
      }).catch(err=>{
        console.log(err);
      });*/
  }

  cerrarComponente(){
    clearInterval(this.temporizador);
    this.alTerminar.emit();
  }

  consultarEstado() {
    let me=this;
    this.temporizador =setInterval(async () => {
      let tiempo:any=await me.generalService.obtenerFechaHoraServidor().toPromise();
      me.time = new Date(tiempo.fecha_hora); //set time variable with current date 
      me.generarSaludo(me);
      me.verificarPermisoUbicacion(me);
    }, 1000); // set it every one seconds
  }

  generarSaludo(me) {                                                                 
    let horas = me.time.getHours();                       
    if (horas < 12) {                                                         
      me.saludo = "Buenos dias!";
    } else if (horas < 19) {                                                  
      me.saludo = "Buenas tardes!";
    } else if (horas < 24) {                                                   
      me.saludo = "Buenas noches!";                                                                
    }                                                                                   
  }

  async verificarPermisoUbicacion(me){
    let permiso:any=await navigator.permissions.query({name:'geolocation'});
    if (permiso.state === 'granted') {
      me.ubicacion_permitida=true;
    } else if(permiso.state === 'denied'){
      me.ubicacion_permitida=false;
    }
  }

  async validarRegistroAsistencia(){
    let res=true;
		if(!await this.obtenerPosicionActual()){
      res=res && false;
      this.toastr.error("Debe habilitar su GPS!");
    }
		return res;
  }

  async marcarAsistencia(){
    if(await this.validarRegistroAsistencia()){
      this.blockUI.start();
      this.empleadosService.marcarAsistencia(this.usuario.id_empresa,this.usuario.id_persona).subscribe((res:any)=>{
        if(res.error){
          this.toastr.error(res.mensaje);
          this.blockUI.stop();
        }else{
          this.toastr.success(res.mensaje);
          this.usuario_posicion.time=this.time;
          this.usuario_posicion.id_empleado_asistencia=res.empleado_asistencia.id;
          this.usuarioService.guardarPosicionUsuario(this.usuario_posicion).subscribe((res_pos:any)=>{
            this.blockUI.stop();
          });
        }
      });
    }
  }
}
