import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from '../../../global';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(private http: HttpClient) { }

  public obtenerListaClientes(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cliente/empresa/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.id_usuario+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/categoria/"+paginator.filter.categoria.id,GlobalVariable.httpOptions);
  }

  public obtenerCliente(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clientes/"+id_cliente,GlobalVariable.httpOptions);
  }

  public eliminarContactoCliente(id_contacto_cliente){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"cliente-contacto/"+id_contacto_cliente,GlobalVariable.httpOptions);
  }

  public buscarClienteNit(id_empresa,texto,id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clientes/empresa/"+id_empresa+"/texto/"+texto+"/sucursal/"+id_sucursal, GlobalVariable.httpOptions);
  }

  public obtenerPreciosProductosCliente(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cliente/"+id_cliente+"/productos-precios", GlobalVariable.httpOptions);
  }

  public guardarPreciosProductosCliente(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cliente-productos-precios",datos, GlobalVariable.httpOptions);
  }

  public buscarClienteContacto(id_empresa,texto_busqueda){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cliente-contacto/empresa/"+id_empresa+"/busqueda/"+texto_busqueda, GlobalVariable.httpOptions);
  }

  public verSiguienteCodigo(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cliente/empresa/"+id_empresa+"/siguiente-codigo", GlobalVariable.httpOptions);
  }

  public guardarCliente(cliente){
    return this.http.post(GlobalVariable.API_SERVER_URL+"clientes",cliente, GlobalVariable.httpOptions);
  }

  public actualizarCliente(cliente){
    return this.http.put(GlobalVariable.API_SERVER_URL+"clientes/"+cliente.id,cliente, GlobalVariable.httpOptions);
  }

  public actualizarAtributoCliente(cliente){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"actualizar-atributo-cliente/"+cliente.id, cliente, GlobalVariable.httpOptions);
  }

  public buscarClientePedido(id_empresa,id_sucursal,id_usuario,texto_busqueda){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clientes/empresa/"+id_empresa+"/sucursal/"+id_sucursal+"/usuario/"+id_usuario+"/texto_busqueda/"+texto_busqueda, GlobalVariable.httpOptions);
  }

  public obtenerValorLimiteDeudaCliente(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-total-deuda/cliente/"+id_cliente, GlobalVariable.httpOptions);
  }

  public obtenerClientesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clientes/empresa/"+id_empresa+"/sucursal/0", GlobalVariable.httpOptions);
  }

  public obtenerClientesSucursal(id_empresa,id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"clientes/empresa/"+id_empresa+"/sucursal/"+id_sucursal, GlobalVariable.httpOptions);
  }

  public obtenerCuentasPorCobrar(paginator){
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-clientes/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.usuario.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/cuentas-liquidadas/"+(paginator.filter.cuentas_liquidadas?1:0)+"/identificacion/"+((paginator.filter.cliente==null || paginator.filter.cliente==undefined)?0:paginator.filter.cliente)+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/nombre-contacto-cliente/"+((paginator.filter.contacto_cliente==null || paginator.filter.contacto_cliente==undefined)?0:paginator.filter.contacto_cliente)+"/hasta-fecha/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public obtenerCuentaCliente(id_cliente,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-clientes/"+id_cliente+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public obtenerCuentaContactosCliente(id_cliente,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-contactos-clientes/"+id_cliente+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public obtenerCuentaContactoCliente(id_contacto_cliente,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-contacto-cliente/"+id_contacto_cliente+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public enviarCorreoCuentaCliente(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-estado-cuenta-cliente-correo",datos, GlobalVariable.httpOptions);
  }

  public eliminarCliente(id_cliente){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"clientes/"+id_cliente, GlobalVariable.httpOptions);
  }

  public guardarContactoCliente(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cliente-contacto",datos, GlobalVariable.httpOptions);
  }

  public obtenerHistoricoVentas(paginator){
    let fecha_inicial=paginator.filter.fecha_inicio==undefined?0:new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/historico-ventas-cliente/"+paginator.filter.id_cliente+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin, GlobalVariable.httpOptions);
  }

  public guardarClientesImportacion(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"clientes/empresa",datos, GlobalVariable.httpOptions);
  }

  public obtenerCuentaPorCobrarContactoCliente(id_contacto_cliente,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-contacto-cliente/"+id_contacto_cliente+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public obtenerCuentaPorCobrarContactoClienteDatosPdf(id_contacto_cliente,cuentas_liquidadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reportes/estado-cuentas-contacto-cliente-pdf/"+id_contacto_cliente+"/cuentas-liquidadas/"+(cuentas_liquidadas?1:0), GlobalVariable.httpOptions);
  }

  public enviarMensajeRecordatorioPagoWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-recordatorio-pago-cliente",datos, GlobalVariable.httpOptions);
  }

  public construirEnviarMensajeRecordatorioPagoWhatsapp(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"construir-enviar-recordatorio-pago-cliente",datos, GlobalVariable.httpOptions);
  }


}
