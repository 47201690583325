<div class="row">
    <div class="col-6 col-md-6">
        <div class="dataTables_length row py-1">
          <div class="col-md-2 col-sm-12"><label>Mostrar </label></div>
          <div class="col-md-4 col-sm-12">
              <select [(ngModel)]="paginator.itemsPerPage" (change)="paginator.getFirstPage()" name="tabla-proveedores_length" aria-controls="tabla-proveedores" class="custom-select custom-select-sm form-control form-control-sm">
                  <option value="0">Todos</option>
                  <option selected value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
          </div>
          <div class="col-md-6 col-sm-12">
            <label>
              Elementos
            </label>
          </div>
        </div>
    </div>
    <div class="col-6 col-md-6">
        <div id="example1_filter" class="dataTables_filter row py-1">
          <div class="col-md-2 col-sm-12"><label>Buscar:</label></div>
          <div class="col-md-10 col-sm-12">
              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="paginator.text_search" (keydown)="paginator.getSearch(paginator.text_search,$event)">
          </div>
        </div>
    </div>
</div>