<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Conceptos de Pago
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Conceptos de Pago
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                <ng-container *ngIf="usuario.empresa.usar_estudiantes_instituto">
                  <div class="col-md-1 col-sm-12"><label>Carreras </label></div>
                  <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.carrera">
                            <option [ngValue]="carrera" *ngFor="let carrera of filter.carreras">{{carrera.nombre}}</option>
                        </select>
                  </div>
                </ng-container>
                  <div class="col-md-1 col-sm-12"><label>Gestiones </label></div>
                  <div class="col-md-3 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.gestion">
                          <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                  </div>
                  <ng-container *ngIf="usuario.empresa.usar_estudiantes_instituto">
                    <div class="col-md-1 col-sm-12"><label>Semestres </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.semestre">
                            <option [ngValue]="semestre" *ngFor="let semestre of filter.semestres">{{semestre.nombre}}</option>
                        </select>
                    </div>
                  </ng-container>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoConceptoIngreso()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                      <i class="fa fa-wrench text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                      <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                        <div class="card bgc-primary brc-primary radius-0">
                          <div class="card-header">
                          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                          </div>
                
                          <div class="card-body p-0 bg-white">
                          <table class="table table-striped table-hover mb-0">
                            <tbody>
                              <tr *ngIf="aplicacion.puede_modificar">
                                <td class="text-dark-m2">
                                Configuración Aplicación
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                </td>
                              </tr>
                              <tr *ngIf="usuario.empresa.usar_cobros_banco_colegio">
                                <td class="text-dark-m2" >
                                  Descargar Conceptos Banco
                                </td>
                                <td class="text-dark-m2">
                                    <a (click)="descargarConceptosBanco()" href="javascript:void(0)" class="text-success"><i class="fa fa-download text-success"></i></a>
                                </td>
                              </tr>
                              <tr *ngIf="usuario.empresa.usar_ingresos_economicos_colegio">
                                <td class="text-dark-m2" >
                                  Migración Cuotas
                                </td>
                                <td class="text-dark-m2">
                                    <a title="Migración Cuotas" (click)="abrirMigracionConceptoPago()" href="javascript:void(0)" class="text-warning"><i class="fab fa-monero text-warning"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        </div>
                    </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="keep-open btn-group show" title="Columns">
                    <button *ngIf="aplicacion.puede_ver && usuario.empresa.usar_georeferenciacion_clientes" title="Mapa Clientes" (click)="abrirMapaClientes()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                      <i class="fas fa-map text-danger-d1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="conceptos_ingresos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().carrera.show && usuario.empresa.usar_ingresos_economicos_instituto" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('codigo')">Carrera <span id="carrera" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().gestion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('identificacion')">Gestión <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().semestre.show && usuario.empresa.usar_ingresos_economicos_instituto" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('descripcion')">Semestre <span id="descripcion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().concepto.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('carga_horaria')">Concepto <span id="carga_horaria" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().importe.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('contacto')">Importe <span id="periodicidad" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().cuotas.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('duracion')">Cuotas <span id="duracion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().obligado.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('activo')">Obligado <span id="activo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().calculable.show && usuario.empresa.usar_ingresos_economicos_instituto" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('activo')">Calculable <span id="activo" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #conceptos_ingresos *ngFor="let concepto_ingreso of conceptos_ingresos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{concepto_ingreso.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().carrera.show && usuario.empresa.usar_ingresos_economicos_instituto">
                  {{concepto_ingreso.carrera.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().gestion.show">
                    {{concepto_ingreso.gestion?concepto_ingreso.gestion.nombre:''}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().semestre.show && usuario.empresa.usar_ingresos_economicos_instituto">
                    {{concepto_ingreso.semestre?concepto_ingreso.semestre.nombre:''}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().concepto.show">
                  {{concepto_ingreso.concepto.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().importe.show">
                    {{concepto_ingreso.importe}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().cuotas.show">
                  {{concepto_ingreso.cuotas}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().obligado.show">
                  <span *ngIf="concepto_ingreso.obligado" class="badge badge-sm badge-success">SÍ</span>
                  <span *ngIf="!concepto_ingreso.obligado" class="badge badge-sm badge-danger">NO</span>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().calculable.show && usuario.empresa.usar_ingresos_economicos_instituto">
                    <span *ngIf="concepto_ingreso.calculable" class="badge badge-sm badge-success">SÍ</span>
                    <span *ngIf="!concepto_ingreso.calculable" class="badge badge-sm badge-danger">NO</span>
                  </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarConceptoIngreso(concepto_ingreso)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_modificar && usuario.empresa.usar_estudiantes_colegio" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarCursosConceptoIngreso(concepto_ingreso)">          
                      <i class="fas fa-list text-105"></i>        
					          </a>
                    <a *ngIf="usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes" title="Fechas de Vencimiento" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalFechaVencimiento(concepto_ingreso)">          
                      <i class="fa fa-calendar-alt text-105"></i>        
                    </a> 
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(concepto_ingreso)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="conceptos_ingresos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #registro_concepto_ingreso_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Concepto Pago</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarConceptoIngreso()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
      <div class="col-1">
        <label>Código</label>
      </div>
      <div class="col-2">
          <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="concepto_ingreso.codigo" placeholder="Código" class="form-control" />
      </div>
        <div class="col-1">
            <label>Carrera</label>
        </div>
        <div class="col-5">
            <select [compareWith]="compararObjectos" name="carrera" class="form-control" [(ngModel)]="concepto_ingreso.carrera">
                <option [ngValue]="carrera" *ngFor="let carrera of carreras">{{carrera.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
          <label>Gestión</label>
      </div>
      <div class="col-2">
          <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="concepto_ingreso.gestion">
              <option [ngValue]="gestion" *ngFor="let gestion of gestiones">{{gestion.nombre}}</option>
          </select>
      </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-1">
          <label>Semestre</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="semestre" class="form-control" [(ngModel)]="concepto_ingreso.semestre">
                <option [ngValue]="semestre" *ngFor="let semestre of semestres">{{semestre.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Concepto <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CONCEPTO_PAGOS,'obtenerConceptosPagoEscuela')"></span></label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" class="form-control" required name="concepto" [(ngModel)]="concepto_ingreso.concepto">
                <option [ngValue]="concepto" *ngFor="let concepto of tipo_concepto_pago.clases | filterBy:{habilitado:true}">{{concepto.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
          <label>Tipo Cálculo Fecha Vencimiento</label>
        </div>
        <div class="col-3">
            <select [compareWith]="compararObjectos" name="tipo_fecha_vencimiento" class="form-control" [(ngModel)]="concepto_ingreso.tipo_fecha_vencimiento">
              <option [ngValue]="null" [selected]="true">Seleccionar Cálculo</option>
                <option [ngValue]="tipo_fecha_vencimiento" *ngFor="let tipo_fecha_vencimiento of calculos_fecha_vencimiento">{{tipo_fecha_vencimiento.nombre}}</option>
            </select>
        </div>
        <ng-container *ngIf="concepto_ingreso.tipo_fecha_vencimiento && concepto_ingreso.tipo_fecha_vencimiento.nombre_corto=='NDC'">
          <div class="col-2">
            <label>Número de clases</label>
          </div>
          <div class="col-2">
              <input type="number" step="0" name="pago_clases" [(ngModel)]="concepto_ingreso.pago_clases" placeholder="Número de clases" class="form-control"/>
          </div>
        </ng-container>
        <ng-container *ngIf="concepto_ingreso.tipo_fecha_vencimiento && concepto_ingreso.tipo_fecha_vencimiento.nombre_corto=='CCF'">
          <div class="col-1">
            <label>Fecha Inicial Pago</label>
          </div>
          <div class="col-3">
            <div class="input-group">
              <input name="fecha_inicial_pago" class="form-control" placeholder="dd/mm/yyyy"
                      name="dp" [(ngModel)]="concepto_ingreso.fecha_inicial_pago_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
          </div> 
          </div>
        </ng-container>
    </div>
    <hr>
    <div class="row">
        <div class="col-1">
            <label>Importe</label>
        </div>
        <div class="col-2">
            <input type="number" step="0.00" name="importe" [(ngModel)]="concepto_ingreso.importe" placeholder="Importe" class="form-control"/>
        </div>
        <div class="col-1">
          <label>Cuotas</label>
        </div>
        <div class="col-2">
            <input type="number" step="0" name="cuotas" [(ngModel)]="concepto_ingreso.cuotas" placeholder="Cuotas" class="form-control"/>
        </div>
        <div class="col-1">
          <label>¿Obligado?</label>
        </div>
        <div class="col-2">
            <label>
                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="concepto_ingreso.obligado"/>
            </label>
        </div>
        <div class="col-1">
          <label>Calculable?</label>
        </div>
        <div class="col-2">
            <label>
                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="concepto_ingreso.calculable"/>
            </label>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #asignacion_fecha_vencimiento_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-7 col-xs-7 p-0">
        <span class="text-blue text-125">Inicio Fecha de Vencimiento</span>
    </div>
    <div class="col-md-5 col-xs-5 p-0">
        <button (click)="guardarInicioFechaVencimiento()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-12">
            <div class="input-group">
              <input name="fv_general" class="form-control" placeholder="dd/mm/yyyy"
                      [(ngModel)]="concepto_pago.fecha_vencimiento_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div> 
          </div>
      </div>
  </div>
</ng-template>