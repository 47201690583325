<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Dosificación</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarRegistroDosificacion()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Numero de Autorización</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="number" step="0" id="autorizacion" name="autorizacion" [(ngModel)]="dosificacion.autorizacion" placeholder="Autorización" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Número Correlativo</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input name="correlativo" min="1" type="number" id="reg_nit" [(ngModel)]="dosificacion.correlativo" placeholder="Correlativo" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Fecha Límite de Emisión</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="input-group">
                <input id="feliem" name="fecha_limite_emision_texto" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="dosificacion.fecha_limite_emision_texto" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div> 
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Llave Digital</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="llave_digital" name="llave_digital" [(ngModel)]="dosificacion.llave_digital" placeholder="Lllave Digital" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Frase Pié Factura</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" class="form-control" name="pie_factura" [(ngModel)]="dosificacion.pieFactura">
                <option [ngValue]="pie_factura" *ngFor="let pie_factura of pies_factura">{{pie_factura.nombre}}</option>
            </select>
        </div>
    </div>
</div>