import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeceComponent } from './components/gece/gece.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    GeceComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    PdfViewerModule
  ],
  exports:[
    GeceComponent
  ]
})
export class GeceModule { }
