import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ConfiguracionAplicacionReportevpComponent } from '../../../base/components/configuracion-aplicacion-reportevp/configuracion-aplicacion-reportevp.component';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';

declare const PDFDocument:any;
declare const blobStream:any;
declare const Chart:any;

@Component({
  selector: 'app-reporte-ventas-productos',
  templateUrl: './reporte-ventas-productos.component.html',
  styleUrls: ['./reporte-ventas-productos.component.css']
})
export class ReporteVentasProductosComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  grafico:any={};
  public configuracion_pagina_ventas:any;

  reporte_rotacion_inventarios:NgbModalRef;
  @ViewChild('reporte_rotacion_inventarios')
  private reporte_rotacion_inventarios_ref: TemplateRef<any>;

  reporte_caducidad_productos:NgbModalRef;
  @ViewChild('reporte_caducidad_productos')
  private reporte_caducidad_productos_ref: TemplateRef<any>;

  datos_rotacion_inventario:any={};
  datos_caducidad_productos:any={};

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public reportesService:ReportesService,
    public pdfService:PdfService,
	public clientesService:ClientesService,
	private toastr: ToastrService,
	public empresaService:EmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

	async ngOnInit(): Promise<void> {
		this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));

		let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
			almacenes:[GlobalVariable.Dictionary.SELECCION_TODOS],
			almacen: GlobalVariable.Dictionary.SELECCION_TODOS,
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			clientes:[{identificacion:"TODOS",id:0}],
			cliente:{identificacion:"TODOS",id:0},
			tipos_pago:[{nombre:"TODOS",id:0}],
			tipo_pago: {nombre:"TODOS",id:0},
			grupos_producto:[{nombre:"TODOS",id:0}],
			grupo_producto: {nombre:"TODOS",id:0},
			subgrupos_producto:[{nombre:"TODOS",id:0}],
			subgrupo_producto: {nombre:"TODOS",id:0},
			tipo_item: {nombre:"PRODUCTO",id:1},
			tipos_items: [{nombre:"PRODUCTOS",id:1},{nombre:"COMBOS",id:2}],
			canales:[{nombre:"TODOS",id:0}],
			canal: {nombre:"TODOS",id:0},
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			solo_obsequios:false,
			meses:[]
		}

		await this.obtenerConfiguracionPaginaVentas();
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_REPORTE_VENTAS_PRODUCTOS);
		this.filter.presentacion_cliente=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente;
		this.filter.presentacion_periodos=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_periodos;

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
    
		this.obtenerTiposDePago();
		this.obtenerGruposProductos();
		this.obtenerSubGruposProductos();
		await this.obtenerCanales();
		await this.obtenerClientes();
		await this.obtenerMeses();
  	}

	async obtenerClientes(){
		if(this.filter.presentacion_cliente=="lista" || this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente=="lista"){
			let clientes:any;
			this.filter.clientes=[{identificacion:"TODOS",id:0}];
			if(this.usuario.empresa.usar_clientes_sucursal){
				clientes=await this.clientesService.obtenerClientesSucursal(this.usuario.id_empresa,this.filter.sucursal.id).toPromise();
			}else{
				clientes=await this.clientesService.obtenerClientesEmpresa(this.usuario.id_empresa).toPromise();
			}
			this.filter.clientes=this.filter.clientes.concat(clientes);
		}
	}

	async obtenerMeses(){
		let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_MESES).toPromise();
		this.filter.meses=entidad.clases;
		this.establecerMesActual();
	}

	establecerMesActual(){
		let fecha_actual=new Date();
		this.filter.mes_inicial=this.filter.meses.filter(m => m.nombre_corto==(fecha_actual.getMonth()+1))[0];
		this.filter.mes_final=this.filter.meses.filter(m => m.nombre_corto==(fecha_actual.getMonth()+1))[0];
		this.establecerFechaInicioMes(this.filter.mes_inicial);
		this.establecerFechaInicioMes(this.filter.mes_final);
	}

	establecerFechaInicioMes(mes){
		let fecha_actual=new Date();
		this.filter.fecha_inicio={
			year: fecha_actual.getFullYear(), 
			month: parseInt(mes.nombre_corto),
			day:1
		}
	}

	establecerFechaFinMes(mes){
		let fecha_actual=new Date();
		this.filter.fecha_fin={
			year: fecha_actual.getFullYear(), 
			month: parseInt(mes.nombre_corto),
			day:new Date(fecha_actual.getFullYear(),parseInt(mes.nombre_corto),0).getDate()
		}
	}

	async obtenerConfiguracionPaginaVentas () {
		let configuracion_aplicacion_ventas:any=Util.obtenerConfiguracionAplicacion(GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this);
		this.configuracion_pagina_ventas=new PageConfiguration({
			crear: true,
			id_empresa: this.usuario.id_empresa,
			id_usuario:this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:null,
			datos: configuracion_aplicacion_ventas
		},this.usuario.aplicacionesUsuario.filter(app => app.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_VENTAS)[0].aplicacion.id,
		this.usuarioService);
		await this.configuracion_pagina_ventas.compareAndUpdateConfigurations(this.usuario.id_empresa,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0);
  	}

	  async obtenerCanales(){
		let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
		let canales=entidad.clases;
		if(this.usuario.empresa.usar_permisos_avanzados_usuarios){
			canales=this.configuracion_pagina_ventas.object.datos.opciones_permisos.canales_asignados.length==0?canales:[];
			for(let i=0;i<this.configuracion_pagina_ventas.object.datos.opciones_permisos.canales_asignados.length;i++){
				canales.push(entidad.clases.filter(cnf => cnf.id==this.configuracion_pagina_ventas.object.datos.opciones_permisos.canales_asignados[i])[0]);
			}
			let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.filter.canales=es_usuario_administrador?this.filter.canales.concat(canales):canales;
			this.filter.canal=this.filter.canales[0];
		}else{
			this.filter.canales=this.filter.canales.concat(canales);
		}
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerTiposDePago(){
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
    });
  }	

  obtenerGruposProductos(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((entidad:any) => {
      this.filter.grupos_producto=this.filter.grupos_producto.concat(entidad.clases);
    });
  }

  obtenerSubGruposProductos(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((entidad:any) => {
      this.filter.subgrupos_producto=this.filter.subgrupos_producto.concat(entidad.clases);
    });
  }

  generarPdfVentasProductos(){
    this.blockUI.start();
    this.reportesService.obtenerVentasProductos(this).subscribe((datos:any)=>{
      var titulo="REPORTE DE VENTAS PRODUCTOS";
			var fecha_reporte=new Date();
			var detallesVenta=datos.detallesVenta;
			console.log(detallesVenta)
			var doc = new PDFDocument({margin:10});
			var stream = doc.pipe(blobStream());
			// draw some text
			
			doc.font('Helvetica',8);
			var y=150,itemsPorPagina=15,items=0,pagina=1,totalPaginas = Math.ceil(detallesVenta.length / itemsPorPagina);;
			this.generarCabeceraPdfVentasProductos(doc);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
				this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});
			var suma_total=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			var sumaSubImporte=0,sumaSubImporteIce=0,sumaSubImporteExp=0,sumaSubImporteGrab=0,sumaSubTotal=0,sumaSubDescuentos=0,sumaSubImporteBase=0,sumaSubCredito=0;
			for(var i=0;i<detallesVenta.length && items<=itemsPorPagina;i++){
				doc.rect(50,y-15,540,40).stroke();
				doc.font('Helvetica',8);
				detallesVenta[i].venta.fecha=new Date(detallesVenta[i].venta.fecha);
				doc.text(detallesVenta[i].venta.fecha.getDate()+"/"+(detallesVenta[i].venta.fecha.getMonth()+1)+"/"+detallesVenta[i].venta.fecha.getFullYear().toString().substr(-2),55,y-7);
				doc.text((detallesVenta[i].venta.factura?detallesVenta[i].venta.factura:""),85,y-7);
				doc.font('Helvetica',7);
				doc.text(detallesVenta[i].venta.transaccion.nombre,110,y-2,{width:55});				
				doc.text(detallesVenta[i].venta.cliente.identificacion,165,y-2,{width:80});				
				doc.text(detallesVenta[i].producto?detallesVenta[i].producto.nombre:detallesVenta[i].combo?detallesVenta[i].combo.nombre:detallesVenta[i].descripcion,255,y-8,{width:80});	
				doc.font('Helvetica',8);			
				doc.text(detallesVenta[i].producto?detallesVenta[i].producto.unidad_medida:detallesVenta[i].combo?detallesVenta[i].combo.unidad_medida:"",350,y,{width:50});
				doc.text(detallesVenta[i].cantidad,380,y,{width:50});
				doc.text(detallesVenta[i].precio_unitario,410,y,{width:50});
				if(this.usuario.empresa.usar_devoluciones_ventas){
					doc.text(this.calcularDevoluciones(detallesVenta,i),440,y,{width:50});
				}
				if(this.usuario.empresa.usar_vencimientos){
					if(detallesVenta[i].inventario){
						detallesVenta[i].inventario.fecha_vencimiento=new Date(detallesVenta[i].inventario.fecha_vencimiento);
						doc.text(detallesVenta[i].inventario.fecha_vencimiento.getDate()+"/"+(detallesVenta[i].inventario.fecha_vencimiento.getMonth()+1)+"/"+detallesVenta[i].inventario.fecha_vencimiento.getFullYear().toString().substr(-2),475,y);				
						//var descuento=detallesVenta[i].importe-detallesVenta[i].ice-detallesVenta[i].excento+detallesVenta[i].recargo;
						doc.text(detallesVenta[i].inventario.lote,510,y);
					}
				}				
				//doc.text(detallesVenta[i].descuento,475,y);				
				//doc.text(detallesVenta[i].recargo,505,y);
				if(this.usuario.empresa.usar_devoluciones_ventas){
					doc.text(detallesVenta[i].total-this.calcularDevolucionesBs(detallesVenta,i),545,y);
					suma_total=suma_total+detallesVenta[i].total-this.calcularDevolucionesBs(detallesVenta,i);
				}else{
					doc.text(detallesVenta[i].total,545,y);
					suma_total=suma_total+detallesVenta[i].total;
				}
				//doc.text(detallesVenta[i].total,705,y);
				y=y+40;
				items++;
				
				if(items==itemsPorPagina || i+1==detallesVenta.length){
					if(i+1==detallesVenta.length){
						
					}else{
						doc.addPage({margin:0,bufferPages: true});
						y=150;
						items=0;
						pagina=pagina+1;
						this.generarCabeceraPdfVentasProductos(doc);
						this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
							this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
							(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
						(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
						(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
						fecha_reporte,{imprimir_usuario:true});
						doc.font('Helvetica',8);
					}
				}
			}
			doc.rect(50,y-15,540,20).stroke();
			doc.text("TOTAL : "+suma_total,480,y-7,{width:80,align:'right'});
			doc.end();
			stream.on('finish', function() {
				var fileURL = stream.toBlobURL('application/pdf');
				window.open(fileURL,'_blank','location=no');
			});
			this.blockUI.stop();
    });
  }
  
  generarCabeceraPdfVentasProductos(doc){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,25,5).stroke();
		doc.text("SUCURSAL : "+this.filter.sucursal.nombre,370,75);
		if(this.usuario.empresa.usar_inventario){
			doc.text("ALMACEN : "+this.filter.almacen.nombre,370,85);
		}
		doc.text("DEL : ",500,75);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,530,75);		
		doc.text("AL : ",500,85);
		doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,530,85);		
		
		doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
		doc.font('Helvetica-Bold',8);
		doc.text("Fecha",55,120);
		doc.text("Nro. Doc",88,115,{width:30});
		doc.text("Transacción",110,120);
		doc.text("Cliente",170,120);
		doc.text("Producto",260,120,{width:50});
		doc.text("Unidad",340,120,{width:60});
		doc.text("Cant.",380,120,{width:50});
		doc.text("P.U.",415,120,{width:50});
		//doc.text("Saldo",560,120,{width:50});

		//if(this.usuario.empresa.usar_devoluciones_ventas){
			doc.text("Devs.",440,120);
		//}
		//if(this.usuario.empresa.usar_vencimientos){
			doc.text("Fecha Venc.",475,115,{width:30});
			doc.text("Lote",510,120,{width:35});
		//}
		doc.text("Total",545,120);	
		
  }
  
  calcularDevoluciones(detallesVenta,i){
		var devoluciones=0,es_producto=detallesVenta[i].producto?true:false;
		for(var j=0;(es_producto && j<detallesVenta[i].producto.devoluciones.length) || (!es_producto && j<detallesVenta[i].combo.devoluciones.length);j++){
			if(es_producto){
				if(detallesVenta[i].producto.devoluciones[j].devolucion_venta.id_venta==detallesVenta[i].id_venta){
					devoluciones=devoluciones+detallesVenta[i].producto.devoluciones[j].cantidad;
				}
			}else{
				if(detallesVenta[i].combo.devoluciones[j].devolucion_venta.id_venta==detallesVenta[i].id_venta){
					devoluciones=devoluciones+detallesVenta[i].combo.devoluciones[j].cantidad;
				}
			}
		}
		return devoluciones;
  }
  
  calcularDevolucionesBs(detallesVenta,i){
		var devoluciones=0,es_producto=detallesVenta[i].producto?true:false;
		for(var j=0;(es_producto && j<detallesVenta[i].producto.devoluciones.length) || (!es_producto && j<detallesVenta[i].combo.devoluciones.length);j++){
			if(es_producto){
				if(detallesVenta[i].producto.devoluciones[j].devolucion_venta.id_venta==detallesVenta[i].id_venta){
					devoluciones=devoluciones+(detallesVenta[i].producto.devoluciones[j].cantidad*detallesVenta[i].precio_unitario);
				}
			}else{
				if(detallesVenta[i].combo.devoluciones[j].devolucion_venta.id_venta==detallesVenta[i].id_venta){
					devoluciones=devoluciones+(detallesVenta[i].combo.devoluciones[j].cantidad*detallesVenta[i].precio_unitario);
				}
			}
		}
		return devoluciones;
  }
  
  generarExcelVentasProductos(){
    this.blockUI.start();
    this.reportesService.obtenerVentasProductos(this).subscribe((datos:any)=>{
      var detallesVenta=datos.detallesVenta;			
			var data = [["FECHA","REGISTRO","HORA","N° DE DOC","TRANSACCION","CODIGO","DETALLE","COSTO UNIT.","PRECIO UNIT.","CANTIDAD","IMPORTE","DESCUENTOS",
			"TOTAL VENTA","TOTAL COSTO","UTILIDAD","OBSERVACION","TIPO DE PAGO","USUARIO","NOMBRE CLIENTE","NIT/CI CLIENTE","NOMBRE O RAZON SOCIAL"/*,"UBICACION CLIENTE"*/,
						"UNIDAD","GRUPO","CANAL"]]

			data[0].splice(18, 0, "SUCURSAL");
			data[0].splice(19, 0, "ALMACENES");

			if(this.usuario.empresa.usar_devoluciones_ventas){
				data[0].splice(9, 0, "DEVOLUCIONES");
			}
						
			if(this.usuario.empresa.usar_vencimientos){
				data[0].push('FECHA VENCIMIENTO');
				data[0].push('LOTE');
			}
			if(this.usuario.empresa.usar_precios_productos){
				data[0].push('CONCEPTO PRECIO');
			}
			if(this.usuario.empresa.usar_obsequios){
				data[0].push('¿ES OBSEQUIO?');
			}
			if(this.usuario.empresa.usar_clientes_contactos){
				data[0].push('CLIENTE CONTACTO');
			}
			if(this.usuario.empresa.usar_pedidos_restaurante){
				data[0].push('TIPO DESPACHO');
			}
			var sumaImporte=0,sumaTotal=0,suma_costo=0,suma_utilidad=0;
			for(var i=0;i<detallesVenta.length;i++){
				var columns=[];
				detallesVenta[i].venta.fecha=new Date(detallesVenta[i].venta.fecha);
				detallesVenta[i].venta.fecha.setHours(detallesVenta[i].venta.fecha.getHours()-4);
				detallesVenta[i].venta.createdAt=new Date(detallesVenta[i].venta.createdAt);
				columns.push(detallesVenta[i].venta.fecha);
				var fecha_hora=detallesVenta[i].venta.createdAt.getDate()+"/"+(detallesVenta[i].venta.createdAt.getMonth()+1)+"/"+detallesVenta[i].venta.createdAt.getFullYear()
				var hora=detallesVenta[i].venta.createdAt.getHours()+":"+detallesVenta[i].venta.createdAt.getMinutes();
				columns.push(fecha_hora);
				columns.push(hora);
				columns.push(detallesVenta[i].venta.factura);
				columns.push(detallesVenta[i].venta.transaccion.nombre);
				columns.push(detallesVenta[i].producto?detallesVenta[i].producto.codigo:detallesVenta[i].combo?detallesVenta[i].combo.codigo:"");
				columns.push(detallesVenta[i].producto?detallesVenta[i].producto.nombre:detallesVenta[i].combo?detallesVenta[i].combo.nombre:detallesVenta[i].descripcion);
				columns.push(detallesVenta[i].costo_unitario);
				columns.push(detallesVenta[i].precio_unitario);
				columns.push(detallesVenta[i].cantidad);
				if(this.usuario.empresa.usar_devoluciones_ventas){
					columns.push(this.calcularDevoluciones(detallesVenta,i));
				}
				columns.push(detallesVenta[i].importe);
				columns.push(detallesVenta[i].descuento);
				columns.push(detallesVenta[i].total);
				let costo_total=detallesVenta[i].costo_unitario*detallesVenta[i].cantidad;
				columns.push(costo_total);
				let utilidad=detallesVenta[i].total-costo_total;
				columns.push(utilidad);
				columns.push(detallesVenta[i].venta.observacion);
				columns.push(detallesVenta[i].venta.tipoPago.nombre);
				columns.push(detallesVenta[i].venta.usuario.nombre_usuario);
				columns.push(detallesVenta[i].venta.sucursal.nombre);
				columns.push((detallesVenta[i].venta.almacen)?detallesVenta[i].venta.almacen.nombre:"");
				columns.push(detallesVenta[i].venta.cliente?detallesVenta[i].venta.cliente.identificacion:null);
				columns.push(detallesVenta[i].venta.nit_ci);
				columns.push(detallesVenta[i].venta.razon_social);
				//columns.push(detallesVenta[i].venta.cliente.ubicacion_geografica);
				columns.push(detallesVenta[i].producto?detallesVenta[i].producto.unidad_medida:detallesVenta[i].combo?detallesVenta[i].combo.unidad_medida:"");
				columns.push(detallesVenta[i].producto?detallesVenta[i].producto.grupo?detallesVenta[i].producto.grupo.nombre:"":"");
				columns.push(detallesVenta[i].venta.canal?detallesVenta[i].venta.canal.nombre:"");
				
				if(this.usuario.empresa.usar_vencimientos){
					if(detallesVenta[i].inventario){
						detallesVenta[i].inventario.fecha_vencimiento=new Date(detallesVenta[i].inventario.fecha_vencimiento);
						columns.push(detallesVenta[i].inventario.fecha_vencimiento.getDate()+"/"+(detallesVenta[i].inventario.fecha_vencimiento.getMonth()+1)+"/"+detallesVenta[i].inventario.fecha_vencimiento.getFullYear());
						columns.push(detallesVenta[i].inventario.lote);
					}else if(detallesVenta[i].lote!=null){
						detallesVenta[i].fecha_vencimiento=new Date(detallesVenta[i].fecha_vencimiento);
						columns.push(detallesVenta[i].fecha_vencimiento.getDate()+"/"+(detallesVenta[i].fecha_vencimiento.getMonth()+1)+"/"+detallesVenta[i].fecha_vencimiento.getFullYear());
						columns.push(detallesVenta[i].lote);
					}else{
						columns.push("");
						columns.push("");
					}
				}

				if(this.usuario.empresa.usar_precios_productos){
					columns.push((detallesVenta[i].producto_precio)?detallesVenta[i].producto_precio.concepto?detallesVenta[i].producto_precio.concepto.nombre:"":"");
				}

				if(this.usuario.empresa.usar_obsequios){
					columns.push((detallesVenta[i].es_obsequio)?"SI":"NO");
				}

				if(this.usuario.empresa.usar_clientes_contactos){
					columns.push(detallesVenta[i].venta.contacto_cliente?detallesVenta[i].venta.contacto_cliente.persona.nombre_completo:"");
				}

				if(this.usuario.empresa.usar_pedidos_restaurante){
					columns.push(detallesVenta[i].venta.pedido?detallesVenta[i].venta.pedido.tipoDespacho?detallesVenta[i].venta.pedido.tipoDespacho.nombre:"":"");
				}
				
				data.push(columns);
				sumaImporte=sumaImporte+detallesVenta[i].importe;
				sumaTotal=sumaTotal+detallesVenta[i].total;
				suma_costo=suma_costo+costo_total;
				suma_utilidad=suma_utilidad+utilidad;
			}
			data.push(["","","","","","","TOTALES","","","",sumaImporte.toString(),
			"",sumaTotal.toString(),suma_costo.toString(),
			suma_utilidad.toString(),"","","","","","",""]);
			if(this.usuario.empresa.usar_devoluciones_ventas){
				data[detallesVenta.length+1].splice(6, 0, "");
      }
      
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("REPORTE VENTAS PRODUCTOS");
      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );
      const dobCol1 = worksheet.getColumn(1);
      dobCol1.width = 15;
	  const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 15;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 10;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "REPORTE VENTAS PRODUCTOS.xlsx");
      })
      this.blockUI.stop();
    });
  }
  
  generarExcelVentasProductosAgrupacionGrupos(){
    this.blockUI.start();
    this.reportesService.obtenerVentasProductosAgrupacionGrupos(this).subscribe((datos:any)=>{
      let grupos=this.filter.grupos_producto;
			var data = [];
			let fecha_inicial=this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year;
			let fecha_final=this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year;
			let cabecera=["N°"];
			cabecera.push("Venta de productos por grupos");
			cabecera.push("Del "+fecha_inicial+" Al "+fecha_final);
			
			data.push(cabecera);
			for(let k=0;k<grupos.length;k++){
				grupos[k].productos=[];
				grupos[k].productos=grupos[k].productos.concat(datos.ventas_grupos_productos.filter(e => e.id_grupo==grupos[k].id));
			}
			data.push(["","GRUPO","PRODUCTO","PRECIO UNITARIO","CANTIDAD","TOTAL"]);
			for(let i=0;i<grupos.length;i++){
        if(grupos[i].id!=0 && grupos[i].productos.length>0){
          data.push(["",grupos[i].nombre]);
          for(let j=0;j<grupos[i].productos.length;j++){
            data.push(["","",grupos[i].productos[j].producto,grupos[i].productos[j].precio_unitario,grupos[i].productos[j].cantidad,grupos[i].productos[j].total]);
          }
        }
			}
			

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("Reporte-ventas-grupos");
			data.forEach(d => {
			let row = worksheet.addRow(d);
			}
			);

			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;

			workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "Reporte-ventas-grupos.xlsx");
			})
			this.blockUI.stop();

    });
  }

  generarExcelVentasProductosAgrupacionProductos(){
    this.blockUI.start();
    this.reportesService.obtenerVentasProductosAgrupacionProductos(this).subscribe((datos:any)=>{
		let producto=datos
		var data = [];
		let total=0;
		let fecha_inicial=this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year;
		let fecha_final=this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year;
		let cabecera=[""];
		cabecera.push("Venta por productos");
		cabecera.push("Del "+fecha_inicial+" Al "+fecha_final);
		
		data.push(cabecera);
		data.push(["N°","PRODUCTO","PRECIO UNITARIO","CANTIDAD","TOTAL"]);
		for(let j=0;j<producto.length;j++){
			total=total+producto[j].total;
			data.push([j+1,producto[j].nombre,producto[j].precio_unitario,producto[j].cantidad,producto[j].total]);
		}
		data.push([null,null,null,"TOTALES",total]);
			
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("Reporte-ventas-productos");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Reporte-ventas-productos.xlsx");
		})
		this.blockUI.stop();

    });
  }

	generarPdfMasVendidos(){
		this.blockUI.start();
    	this.reportesService.obtenerProductosMasVendidos(this).subscribe((productos:any[])=>{
			var doc = new PDFDocument({ size: 'letter', margin: 10 });
			var stream = doc.pipe(blobStream());
			var fecha_reporte=new Date();
			var y = 165, itemsPorPagina = 29, items = 0, pagina = 1, totalPaginas = Math.ceil(productos.length / itemsPorPagina);
			this.dibujarCabeceraPDFMasVendido(doc);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE PRODUCTOS MAS VENDIDOS",pagina,totalPaginas,this.usuario,
				this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});
			for (var i = 0; i < productos.length && items <= itemsPorPagina; i++) {

				doc.rect(40, y - 10, 540, 20).stroke();
				doc.font('Helvetica', 8);
				doc.text((i+1)+"", 45, y, { width: 50 });
				doc.text(productos[i].nombre, 125, y);
				doc.text(productos[i].cantidad, 500, y);
				y = y + 20;
				items++;

				if (items == itemsPorPagina) {
					doc.addPage({ margin: 0, bufferPages: true });
					y = 165;
					items = 0;
					pagina = pagina + 1;

					this.dibujarCabeceraPDFMasVendido(doc);
					this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"REPORTE PRODUCTOS MAS VENDIDOS",pagina,totalPaginas,this.usuario,
						this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
						(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
					(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
					(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
					fecha_reporte,{imprimir_usuario:true});

					doc.font('Helvetica', 8);
				}
			}
			doc.end();
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				window.open(fileURL, '_blank', 'location=no');
			});
			this.blockUI.stop();
		});
	}

	dibujarCabeceraPDFMasVendido(doc) {
		doc.font('Helvetica-Bold',8);
		doc.text("Nº",45,140);
		doc.text("Producto",125,140);
		doc.text("Cantidad",500,140);
		doc.roundedRect(40,130,540,25,5).fillOpacity(0.8).stroke();
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,25,5).stroke();
		doc.text("SUCURSAL : "+this.filter.sucursal.nombre,370,75);
		if(this.usuario.empresa.usar_inventario){
			doc.text("ALMACEN : "+this.filter.almacen.nombre,370,85);
		}
		doc.text("DEL : ",500,75);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,530,75);		
		doc.text("AL : ",500,85);
		doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,530,85);		
	}

	generarExcelMasVendidos(){
		this.blockUI.start();
		this.reportesService.obtenerProductosMasVendidos(this).subscribe((productos:any[])=>{
			var data = [["Nº","PRODUCTO","CANTIDAD","PRECIO UNITARIO","TOTAL"]];
			var suma_total=0;
			for(var i=0;i<productos.length;i++){
				var columns=[];
				columns.push(i+1);
				columns.push(productos[i].nombre);
				columns.push(productos[i].cantidad);
				columns.push(productos[i].precio_unitario);
				columns.push((productos[i].cantidad*productos[i].precio_unitario));
				suma_total=suma_total+(productos[i].cantidad*productos[i].precio_unitario);
				data.push(columns);
			}
			data.push([null,null,null,null,suma_total+""]);
			/*data.push(["","","","","","TOTALES",sumaImporte,/*"",
			"","","","",sumaTotal,"","","","",
			"","","",
			"",""]);*/

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("PRODUCTOS-MAS-VENDIDOS");
			data.forEach(d => {
			let row = worksheet.addRow(d);
			}
			);

			const dobCol1 = worksheet.getColumn(1);
			dobCol1.width = 3;
			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol2.width = 10;

			workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "PRODUCTOS-MAS-VENDIDOS.xlsx");
			})
			this.blockUI.stop();
		});
	}

	generarGraficoMasVendidos(){
		this.blockUI.start();
		this.reportesService.obtenerProductosMasVendidos(this).subscribe((productos:any[])=>{
			this.grafico.labels =[],this.grafico.data = [],this.grafico.series = [];
			for(var i=0;i<productos.length && i<30;i++){
				this.grafico.labels.push(productos[i].nombre);
				this.grafico.data.push(productos[i].cantidad);
				this.grafico.series.push(productos[i].nombre);
			}
			this.blockUI.stop();
			var ctx = document.getElementById('myChart');
			var myChart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels:this.grafico.labels,
					datasets: [{
						label: 'Productos más vendidos',
						data: this.grafico.data,
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 159, 64, 0.2)'
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)'
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true
							}
						}]
					}
				}
			});
		});
	}

	generarExcelVentasProductosConsumo(){
		this.blockUI.start();
		this.reportesService.obtenerVentasProductos(this).subscribe((datos:any)=>{
		  var detallesVenta=datos.detallesVenta;			
				var data = [["FECHA","REGISTRO","HORA","N° DE DOC","TRANSACCION",
				"CODIGO","DETALLE","PU","CANTIDAD","IMPORTE","TOTAL","COD. PRODUCTO","PRODUCTO","CANTIDAD","OBSERVACION","TIPO DE PAGO",
				"USUARIO","SUCURSAL","NOMBRE CLIENTE","NIT/CI CLIENTE","NOMBRE O RAZON SOCIAL",
				"UNIDAD","GRUPO","CANAL"]]
				
				if(this.usuario.empresa.usar_obsequios){
					data[0].push('¿ES OBSEQUIO?');
				}
				if(this.usuario.empresa.usar_pedidos_restaurante){
					data[0].push('TIPO DESPACHO');
				}
				var sumaImporte=0,sumaTotal=0;
				for(var i=0;i<detallesVenta.length;i++){
					for(let j=0;j<detallesVenta[i].producto.productosBase.length;j++){
						var columns=[];
						detallesVenta[i].venta.fecha=new Date(detallesVenta[i].venta.fecha);
						detallesVenta[i].venta.createdAt=new Date(detallesVenta[i].venta.createdAt);
						columns.push(detallesVenta[i].venta.fecha.getDate()+"/"+(detallesVenta[i].venta.fecha.getMonth()+1)+"/"+detallesVenta[i].venta.fecha.getFullYear());
						var fecha_hora=detallesVenta[i].venta.createdAt.getDate()+"/"+(detallesVenta[i].venta.createdAt.getMonth()+1)+"/"+detallesVenta[i].venta.createdAt.getFullYear()
						var hora=detallesVenta[i].venta.createdAt.getHours()+":"+detallesVenta[i].venta.createdAt.getMinutes();
						columns.push(fecha_hora);
						columns.push(hora);
						columns.push(detallesVenta[i].venta.factura);
						columns.push(detallesVenta[i].venta.transaccion.nombre);
						columns.push(detallesVenta[i].producto?detallesVenta[i].producto.codigo:detallesVenta[i].combo?detallesVenta[i].combo.codigo:"");
						columns.push(detallesVenta[i].producto?detallesVenta[i].producto.nombre:detallesVenta[i].combo?detallesVenta[i].combo.nombre:detallesVenta[i].descripcion);
						columns.push(detallesVenta[i].precio_unitario);
						columns.push(detallesVenta[i].cantidad);
						columns.push(detallesVenta[i].importe);
						columns.push(detallesVenta[i].total);
						columns.push(detallesVenta[i].producto.productosBase[j].productoBase.codigo);
						columns.push(detallesVenta[i].producto.productosBase[j].productoBase.nombre);
						columns.push(detallesVenta[i].producto.productosBase[j].formulacion*detallesVenta[i].cantidad);
						columns.push(detallesVenta[i].venta.observacion);
						columns.push(detallesVenta[i].venta.tipoPago.nombre);
						columns.push(detallesVenta[i].venta.usuario.nombre_usuario);
						columns.push(detallesVenta[i].venta.sucursal.nombre);
						columns.push(detallesVenta[i].venta.cliente.identificacion);
						columns.push(detallesVenta[i].venta.nit_ci);
						columns.push(detallesVenta[i].venta.razon_social);
						//columns.push(detallesVenta[i].venta.cliente.ubicacion_geografica);
						columns.push(detallesVenta[i].producto?detallesVenta[i].producto.unidad_medida:detallesVenta[i].combo?detallesVenta[i].combo.unidad_medida:"");
						columns.push(detallesVenta[i].producto?detallesVenta[i].producto.grupo?detallesVenta[i].producto.grupo.nombre:"":"");
						columns.push(detallesVenta[i].venta.canal?detallesVenta[i].venta.canal.nombre:"");
		
						if(this.usuario.empresa.usar_obsequios){
							columns.push((detallesVenta[i].es_obsequio)?"SI":"NO");
						}
		
						if(this.usuario.empresa.usar_pedidos_restaurante){
							columns.push(detallesVenta[i].venta.pedido?detallesVenta[i].venta.pedido.tipoDespacho?detallesVenta[i].venta.pedido.tipoDespacho.nombre:"":"");
						}
						
						data.push(columns);
						sumaImporte=sumaImporte+detallesVenta[i].importe;
						sumaTotal=sumaTotal+detallesVenta[i].total;
					}
				}
				data.push(["","","","","","","TOTALES","","",sumaImporte.toString()/*"",
				"","","",*/,sumaTotal.toString(),"","","","",
				"","","",
				"",""]);
		  
		  let workbook = new Workbook();
		  let worksheet = workbook.addWorksheet("REPORTE VENTAS PRODUCTOS CONSUMO");
		  data.forEach(d => {
		  let row = worksheet.addRow(d);
		  }
		  );
	
		  const dobCol7 = worksheet.getColumn(7);
		  dobCol7.width = 25;
	
		  workbook.xlsx.writeBuffer().then((data) => {
		  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, "REPORTE VENTAS PRODUCTOS CONSUMO.xlsx");
		  })
		  this.blockUI.stop();
		});
	  }

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionReportevpComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
			this.filter.presentacion_cliente=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente;
			this.filter.presentacion_periodos=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_periodos;
			this.obtenerClientes();
			this.obtenerMeses();
		});
	}

	establecerSucursal(){
		this.obtenerClientes();
		this.obtenerAlmacenes();
	}

	async obtenerAlmacenes(){
		this.filter.almacenes=[GlobalVariable.Dictionary.SELECCION_TODOS];
		if(this.filter.sucursal.id==0){
			this.filter.almacen=GlobalVariable.Dictionary.SELECCION_TODOS;
		}else{
			let datos_sucursal:any=await this.empresaService.obtenerSucursal(this.filter.sucursal.id).toPromise();
			this.filter.almacenes=this.filter.almacenes.concat(datos_sucursal.almacenes);
			if(this.usuario.empresa.usar_almacenes_usuario){
				this.filter.almacenes=[GlobalVariable.Dictionary.SELECCION_TODOS];
				let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
				this.filter.almacenes=this.filter.almacenes.concat((almacenes.length==0?datos_sucursal.almacenes:almacenes));
			}
		}
	  }

	validarFiltroReporteRotacionInventarios(){
		let res=true;
		if(!this.filter.fecha_inicio || !this.filter.fecha_inicio.year || !this.filter.fecha_inicio.month || !this.filter.fecha_inicio.day){
			res=res && false;
			this.toastr.error("Debe especificar la fecha inicial!");
		}
		if(!this.filter.fecha_fin || !this.filter.fecha_fin.year || !this.filter.fecha_fin.month || !this.filter.fecha_fin.day){
			res=res && false;
			this.toastr.error("Debe especificar la fecha final!");
		}
		if(this.filter.sucursal.id==0){
			res=res && false;
			this.toastr.error("Debe especificar la sucursal!");
		}
		if(this.filter.cliente.id==0){
			res=res && false;
			this.toastr.error("Debe especificar el cliente!");
		}
		if(!(this.filter.presentacion_cliente=="lista")){
			res=res && false;
			this.toastr.error("Debe cambiar el filtro de cliente a lista!");
		}
		return res;
	}

	async generarReporteRotacionInventarios(){
		if(this.validarFiltroReporteRotacionInventarios()){
			this.blockUI.start();
			let anio_inicio,anio_fin;
			if(this.filter.presentacion_periodos=="meses"){
				let tiempo_actual=new Date();
				anio_inicio=tiempo_actual.getFullYear();
				anio_fin=tiempo_actual.getFullYear();
				this.datos_rotacion_inventario.meses=this.filter.meses.filter(m => parseInt(m.nombre_corto)>=parseInt(this.filter.mes_inicial.nombre_corto) && parseInt(m.nombre_corto)<=parseInt(this.filter.mes_final.nombre_corto))	
			}else{
				this.datos_rotacion_inventario.meses=this.filter.meses.filter(m => parseInt(m.nombre_corto)>=parseInt(this.filter.fecha_inicio.month) || parseInt(m.nombre_corto)<=parseInt(this.filter.fecha_fin.month));
			}
			this.datos_rotacion_inventario.productos=[];
			let indice_venta=0;let indice_inventario=0;
			for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
				let fecha_inicio;
				let fecha_fin;
				if(this.filter.presentacion_periodos=="fechas"){
					if(this.filter.fecha_inicio.year==this.filter.fecha_fin.year){
						anio_inicio=parseInt(this.filter.fecha_inicio.year);
						anio_fin=parseInt(this.filter.fecha_fin.year);
					}else{
						if(this.datos_rotacion_inventario.meses[i].nombre_corto>=this.filter.fecha_inicio.month){
							anio_inicio=parseInt(this.filter.fecha_inicio.year);
							anio_fin=parseInt(this.filter.fecha_inicio.year);
						}else if(this.datos_rotacion_inventario.meses[i].nombre_corto<=this.filter.fecha_fin.month){
							anio_inicio=parseInt(this.filter.fecha_fin.year);
							anio_fin=parseInt(this.filter.fecha_fin.year);
						}
					}
				}
				fecha_inicio=new Date(anio_inicio,parseInt(this.datos_rotacion_inventario.meses[i].nombre_corto)-1,1);
				fecha_fin=new Date(anio_fin,parseInt(this.datos_rotacion_inventario.meses[i].nombre_corto),0);
				let datos:any=await this.reportesService.obtenerProductosRotacionInventario(fecha_inicio,fecha_fin,this).toPromise();
				for(let j=0;j<datos.ventas_productos.length;j++){
					let producto=this.datos_rotacion_inventario.productos.filter(p => p.codigo==datos.ventas_productos[j].codigo);
					if(producto.length==0){
						this.datos_rotacion_inventario.productos.push(datos.ventas_productos[j]);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].ventas=new Array(this.datos_rotacion_inventario.meses.length);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].inventarios=new Array(this.datos_rotacion_inventario.meses.length);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].ventas[indice_venta]=datos.ventas_productos[j].cantidad;
					}else{
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.indexOf(producto[0])].ventas[indice_venta]=datos.ventas_productos[j].cantidad;
					}
				}
				for(let j=0;j<datos.inventarios_productos.length;j++){
					let producto=this.datos_rotacion_inventario.productos.filter(p => p.codigo==datos.inventarios_productos[j].codigo);
					if(producto.length==0){
						this.datos_rotacion_inventario.productos.push(datos.inventarios_productos[j]);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].ventas=new Array(this.datos_rotacion_inventario.meses.length);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].inventarios=new Array(this.datos_rotacion_inventario.meses.length);
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.length-1].inventarios[indice_inventario]=datos.inventarios_productos[j].cantidad;
					}else{
						this.datos_rotacion_inventario.productos[this.datos_rotacion_inventario.productos.indexOf(producto[0])].inventarios[indice_inventario]=datos.inventarios_productos[j].cantidad;
					}
				}
				this.datos_rotacion_inventario.sumatoria_ir=0;
				for(let i=0;i<this.datos_rotacion_inventario.productos.length;i++){
					let ventas:number=0;
					let inventarios:number=0;
					for(let j=0;j<this.datos_rotacion_inventario.productos[i].ventas.length;j++){
						ventas=ventas+(this.datos_rotacion_inventario.productos[i].ventas[j]?this.datos_rotacion_inventario.productos[i].ventas[j]:0);
						inventarios=inventarios+(this.datos_rotacion_inventario.productos[i].inventarios[j]?this.datos_rotacion_inventario.productos[i].inventarios[j]:0);
					}
					this.datos_rotacion_inventario.productos[i].total_ventas=ventas;
					this.datos_rotacion_inventario.productos[i].total_inventarios=inventarios;
					this.datos_rotacion_inventario.productos[i].ir=Math.round(parseFloat((ventas-inventarios).toString())/parseFloat(this.datos_rotacion_inventario.meses.length.toString())*100)/100;
					this.datos_rotacion_inventario.sumatoria_ir=this.datos_rotacion_inventario.sumatoria_ir+this.datos_rotacion_inventario.productos[i].ir;
				}
				indice_venta++;
				indice_inventario++;
			}
			this.blockUI.stop();console.log(this.datos_rotacion_inventario);
			this.reporte_rotacion_inventarios=this.modalService.open(this.reporte_rotacion_inventarios_ref, {scrollable:true,ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static'});
			this.reporte_rotacion_inventarios.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		}
	}

	generarPdfRotacionInventarios(){
		let configuracion_papel={ size: [612, 792], margin: 10,layout : 'landscape' }
		var doc = new PDFDocument(configuracion_papel);
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		let titulo_reporte="REPORTE ROTACION DE INVENTARIOS";
		var y = 145, itemsPorPagina = 20, items = 0, pagina = 1, totalPaginas = Math.ceil(this.datos_rotacion_inventario.productos.length / itemsPorPagina);
		this.dibujarCabeceraPdfRotacionInventario(doc,this.filter.sucursal.nombre,this.filter.cliente.identificacion);
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficioV2(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
			(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
		(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
		(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracion_papel,
		fecha_reporte,{imprimir_usuario:true});
		let ancho_circulo=this.datos_rotacion_inventario.meses.length*2*20+60+180;let x;
		for (let i = 0; i < this.datos_rotacion_inventario.productos.length && items <= itemsPorPagina; i++) {

			doc.rect(40, y - 10, ancho_circulo, 20).stroke();
			doc.font('Helvetica', 8);
			doc.text((i+1)+"", 45, y, { width: 50 });
			doc.text(this.datos_rotacion_inventario.productos[i].codigo, 60, y);
			doc.text(this.datos_rotacion_inventario.productos[i].nombre, 90, y-8,{width:100});
			x=200;
			for(let j=0;j<this.datos_rotacion_inventario.productos[i].ventas.length;j++){
				let dato=this.datos_rotacion_inventario.productos[i].ventas[j]?this.datos_rotacion_inventario.productos[i].ventas[j]:"";
				doc.text(dato, x, y,{width:100});
				x=x+20;
			}
			doc.text(this.datos_rotacion_inventario.productos[i].total_ventas, x, y,{width:100});
			x=x+30;
			for(let j=0;j<this.datos_rotacion_inventario.productos[i].inventarios.length;j++){
				let dato=this.datos_rotacion_inventario.productos[i].inventarios[j]?this.datos_rotacion_inventario.productos[i].inventarios[j]:"";
				doc.text(dato, x, y,{width:100});
				x=x+20;
			}
			doc.text(this.datos_rotacion_inventario.productos[i].total_inventarios, x, y,{width:100});
			x=x+30;
			doc.text(this.datos_rotacion_inventario.productos[i].ir, x-5, y,{width:100});

			y = y + 20;
			items++;

			if (items == itemsPorPagina) {
				doc.addPage({ margin: 0, bufferPages: true });
				y = 165;
				items = 0;
				pagina = pagina + 1;

				this.dibujarCabeceraPdfRotacionInventario(doc,this.filter.sucursal.nombre,this.filter.cliente.identificacion);
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficioV2(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
					(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
				(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
				(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:true});

				doc.font('Helvetica', 8);
			}
		}
		doc.rect(40, y - 10, ancho_circulo, 20).stroke();
		doc.font('Helvetica', 8);
		doc.text("TOTALES", 45, y, { width: 50 });
		doc.text(this.datos_rotacion_inventario.sumatoria_ir, x-5, y, { width: 50 });
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
		this.blockUI.stop();
	}

	generarExcelRotacionInventarios(){
		this.blockUI.start();
		//var detallesVenta=datos.detallesVenta;			
		var data = [["","REPORTE DE ROTACION DE INVENTARIOS"],
					["","Sucursal: ",this.filter.sucursal.nombre,"Cliente: ",this.filter.cliente.identificacion,"","","Periodos de "+this.filter.mes_inicial.nombre+" a "+this.filter.mes_final.nombre]]
		
		let cabecera=["","","","Ventas en unidades"],pie=["","","","TOTALES"];
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			cabecera.push("");
			pie.push("");
		}
		cabecera.push("Inventario Externo");
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			cabecera.push("");
			pie.push("");
		}
		data.push(cabecera);
		let cabecera_meses=["Nº","Codigo","Producto"];
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			cabecera_meses.push(this.datos_rotacion_inventario.meses[i].nombre);
		}
		cabecera_meses.push("Total");
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			cabecera_meses.push(this.datos_rotacion_inventario.meses[i].nombre);
		}
		cabecera_meses.push("Total");
		cabecera_meses.push("IR");
		data.push(cabecera_meses);

		var sumaImporte=0,sumaTotal=0;
		for (let i = 0; i < this.datos_rotacion_inventario.productos.length; i++) {
			var columns=[];
			columns.push((i+1));
			columns.push(this.datos_rotacion_inventario.productos[i].codigo);
			columns.push(this.datos_rotacion_inventario.productos[i].nombre);
			for(let j=0;j<this.datos_rotacion_inventario.productos[i].ventas.length;j++){
				let dato=this.datos_rotacion_inventario.productos[i].ventas[j]?this.datos_rotacion_inventario.productos[i].ventas[j]:"";
				columns.push(dato);
			}
			columns.push(this.datos_rotacion_inventario.productos[i].total_ventas);
			for(let j=0;j<this.datos_rotacion_inventario.productos[i].inventarios.length;j++){
				let dato=this.datos_rotacion_inventario.productos[i].inventarios[j]?this.datos_rotacion_inventario.productos[i].inventarios[j]:"";
				columns.push(dato);
			}
			columns.push(this.datos_rotacion_inventario.productos[i].total_inventarios);
			columns.push(this.datos_rotacion_inventario.productos[i].ir);
			data.push(columns);
		}
		pie.push("");
		pie.push(this.datos_rotacion_inventario.sumatoria_ir);
		data.push(pie);
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("ROTACION INVENTARIOS");
		data.forEach(d => {
			let row = worksheet.addRow(d);
		});
		
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 40;

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "ROTACION INVENTARIOS.xlsx");
			this.blockUI.stop();
		});
	}

	dibujarCabeceraPdfRotacionInventario(doc,sucursal,cliente) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,25,5).stroke();
		doc.text("Sucursal : ",360,75);
		doc.text(sucursal,400,75);
		doc.text("Periodo:",465,75);
		doc.text(this.filter.mes_inicial.nombre+" a "+this.filter.mes_final.nombre,500,75);
		doc.text("Cliente : ",385,85);
		doc.text(cliente,440,85);		

		doc.font('Helvetica-Bold',8);
		let x=200,y=110,ancho_circulo=this.datos_rotacion_inventario.meses.length*2*20+60+180;
		doc.text("Nº",45,y+10);
		doc.text("Codigo",60,y+10);
		doc.text("Producto",100,y+10);
		doc.text("Ventas en Unidades",x,y+5);
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			doc.text(this.datos_rotacion_inventario.meses[i].nombre.substring(0,3),x,y+14);
			x=x+20;
		}
		doc.text("Total",x,y+10);
		x=x+30;
		doc.text("Inventario Externo",x,y+5);
		for(let i=0;i<this.datos_rotacion_inventario.meses.length;i++){
			doc.text(this.datos_rotacion_inventario.meses[i].nombre.substring(0,3),x,y+14);
			x=x+20;
		}
		doc.text("Total",x,y+10);
		doc.text("IR",x+30,y+10);
		
		doc.roundedRect(40,y,ancho_circulo,25,5).fillOpacity(0.8).stroke();
	}

	validarFiltroReporteCaducidadProductos(){
		let res=true;
		if(!this.filter.fecha_inicio || !this.filter.fecha_inicio.year || !this.filter.fecha_inicio.month || !this.filter.fecha_inicio.day){
			res=res && false;
			this.toastr.error("Debe especificar la fecha inicial!");
		}
		if(!this.filter.fecha_fin || !this.filter.fecha_fin.year || !this.filter.fecha_fin.month || !this.filter.fecha_fin.day){
			res=res && false;
			this.toastr.error("Debe especificar la fecha final!");
		}
		if((this.filter.fecha_inicio && this.filter.fecha_fin) && (this.filter.fecha_fin.year!=this.filter.fecha_inicio.year || this.filter.fecha_fin.month!=this.filter.fecha_inicio.month || this.filter.fecha_fin.day!=this.filter.fecha_inicio.day)){
			res=res && false;
			this.toastr.error("La fecha inicial debe ser igual a la fecha final!");
		}
		if(this.filter.sucursal.id==0){
			res=res && false;
			this.toastr.error("Debe especificar la sucursal!");
		}
		if(this.filter.cliente.id==0){
			res=res && false;
			this.toastr.error("Debe especificar el cliente!");
		}
		if(!(this.filter.presentacion_cliente=="lista")){
			res=res && false;
			this.toastr.error("Debe cambiar el filtro de cliente a lista!");
		}
		return res;
	}

	async generarReporteCaducidadProductos(){
		if(this.validarFiltroReporteCaducidadProductos()){
			this.blockUI.start();
			let datos:any=await this.reportesService.obtenerCaducidadProductos(this).toPromise();
			this.datos_caducidad_productos.productos=[];this.datos_caducidad_productos.mas_fechas=1;this.datos_caducidad_productos.alertas=[];
			let fecha_reporte=new Date(this.filter.fecha_inicio.year,this.filter.fecha_inicio.month-1,this.filter.fecha_inicio.day,0,0);
			for(let i=0;i<datos.inventarios_productos.length;i++){
				let producto=this.datos_caducidad_productos.productos.filter(p => p.codigo==datos.inventarios_productos[i].codigo);
				let producto_encontrado;
				if(producto.length==0){
					producto_encontrado=datos.inventarios_productos[i];
					this.datos_caducidad_productos.productos.push(datos.inventarios_productos[i]);
					producto_encontrado.fechas=[];
				}else{
					producto_encontrado=this.datos_caducidad_productos.productos[this.datos_caducidad_productos.productos.indexOf(producto[0])];
				}
				let obsolescencia=Util.obtenerDiferenciaDiasFechas(fecha_reporte,new Date(datos.inventarios_productos[i].fecha_produccion));
				let color=obsolescencia<=180?"success":((obsolescencia>=181 && obsolescencia<=270)?"warning":"danger");
				producto_encontrado.fechas.push({fecha:datos.inventarios_productos[i].fecha_produccion,cantidad:datos.inventarios_productos[i].cantidad,obsolescencia_color:color});
				this.datos_caducidad_productos.mas_fechas=producto_encontrado.fechas.length>this.datos_caducidad_productos.mas_fechas?producto_encontrado.fechas.length:this.datos_caducidad_productos.mas_fechas;
			}
			this.datos_caducidad_productos.sumatoria_satisfactorio=0;
			this.datos_caducidad_productos.sumatoria_alerta=0;
			this.datos_caducidad_productos.sumatoria_riesgo=0;
			for(let i=0;i<this.datos_caducidad_productos.productos.length;i++){
				let satisfactorio=0,alerta=0,riesgo=0;
				for(let j=0;j<this.datos_caducidad_productos.productos[i].fechas.length;j++){
					if(this.datos_caducidad_productos.productos[i].fechas[j].obsolescencia_color=="success"){
						satisfactorio=satisfactorio+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
						this.datos_caducidad_productos.sumatoria_satisfactorio=this.datos_caducidad_productos.sumatoria_satisfactorio+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
					}else if(this.datos_caducidad_productos.productos[i].fechas[j].obsolescencia_color=="warning"){
						alerta=alerta+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
						this.datos_caducidad_productos.sumatoria_alerta=this.datos_caducidad_productos.sumatoria_alerta+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
					}else{
						riesgo=riesgo+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
						this.datos_caducidad_productos.sumatoria_riesgo=this.datos_caducidad_productos.sumatoria_riesgo+this.datos_caducidad_productos.productos[i].fechas[j].cantidad;
					}
				}
				this.datos_caducidad_productos.alertas.push({satisfactorio:satisfactorio,alerta:alerta,riesgo:riesgo});
			}
			this.blockUI.stop();
			this.reporte_caducidad_productos=this.modalService.open(this.reporte_caducidad_productos_ref, {scrollable:true,ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static'});
			this.reporte_caducidad_productos.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		}
	}

	async cambiarPresentacionPeriodo(){
		if(this.filter.presentacion_periodos=="meses"){
			this.filter.presentacion_periodos="fechas";
		}else{
			this.filter.presentacion_periodos="meses";
			if(this.filter.meses.length==0){
				await this.obtenerMeses();
			}
		}
	}

	async cambiarPresentacionCliente(){
		if(this.filter.presentacion_cliente=="lista"){
			this.filter.presentacion_cliente="campo_texto";
		}else{
			this.filter.presentacion_cliente="lista";
			if(this.filter.clientes.length==1){
				await this.obtenerClientes();
			}
		}
	}

	generarPdfCaducidadProductos(){
		let configuracion_papel={ size: [612, 792], margin: 10,layout : 'landscape' }
		var doc = new PDFDocument(configuracion_papel);
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		let titulo_reporte="REPORTE CADUCIDAD DE PRODUCTOS";
		var y = 145, itemsPorPagina = 8, items = 0, pagina = 1, totalPaginas = Math.ceil(this.datos_caducidad_productos.productos.length / itemsPorPagina);
		this.dibujarCabeceraPdfCaducidadProductos(doc,this.filter.sucursal.nombre,this.filter.cliente.identificacion);
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficioV2(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
			(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
		(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
		(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",configuracion_papel,
		fecha_reporte,{imprimir_usuario:true});
		let ancho_circulo=this.datos_caducidad_productos.mas_fechas*61+235;let x;
		for (let i = 0; i < this.datos_caducidad_productos.productos.length && items <= itemsPorPagina; i++) {

			doc.rect(40, y - 10, ancho_circulo, 20).stroke();
			doc.font('Helvetica', 8);
			doc.text((i+1)+"", 45, y, { width: 50 });
			doc.text(this.datos_caducidad_productos.productos[i].codigo, 60, y);
			doc.text(this.datos_caducidad_productos.productos[i].nombre, 90, y-3,{width:250});
			x=280;
			for(let j=0;j<this.datos_caducidad_productos.productos[i].fechas.length;j++){
				let fecha=new Date(this.datos_caducidad_productos.productos[i].fechas[j].fecha);
				doc.text((fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear()), x, y,{width:100});
				doc.text(this.datos_caducidad_productos.productos[i].fechas[j].cantidad, x+45, y,{width:100});
				x=x+60;
				//docScoreCard.fillColor('red');
				//docScoreCard.fillColor('black');
			}
			doc.text(this.datos_caducidad_productos.alertas[i].satisfactorio, 645, y,{width:100});
			doc.text(this.datos_caducidad_productos.alertas[i].alerta, 675, y,{width:100});
			doc.text(this.datos_caducidad_productos.alertas[i].riesgo, 705, y,{width:100});
			doc.rect(635, y - 10, 100, 20).stroke();
			y = y + 20;
			items++;

			if (items == itemsPorPagina) {
				doc.addPage({ margin: 0, bufferPages: true });
				y = 165;
				items = 0;
				pagina = pagina + 1;

				this.dibujarCabeceraPdfCaducidadProductos(doc,this.filter.sucursal.nombre,this.filter.cliente.identificacion);
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficioV2(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
					(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
				(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
				(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:true});

				doc.font('Helvetica', 8);
			}
		}
		doc.rect(635, y - 10, 100, 20).stroke();
		doc.font('Helvetica', 8);
		doc.text("TOTALES", 50, y, { width: 50 });
		doc.text(this.datos_caducidad_productos.sumatoria_satisfactorio, 645, y, { width: 50 });
		doc.text(this.datos_caducidad_productos.sumatoria_alerta, 675, y, { width: 50 });
		doc.text(this.datos_caducidad_productos.sumatoria_riesgo, 705, y, { width: 50 });
		doc.end();
		stream.on('finish', function () {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL, '_blank', 'location=no');
		});
		this.blockUI.stop();
	}

	generarExcelCaducidadProductos(){
		this.blockUI.start();
		//var detallesVenta=datos.detallesVenta;			
		var data = [["","REPORTE DE CADUCIDAD DE PRODUCTOS"],
					["","Sucursal: ",this.filter.sucursal.nombre,"Cliente: ",this.filter.cliente.identificacion,"","","Fecha Hoy:"+this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year]]
		
		let cabecera=["Nº","Codigo","Producto"];
		for(let i=0;i<this.datos_caducidad_productos.mas_fechas;i++){
			cabecera.push("Fecha Prod.");
			cabecera.push("Cantidad");
		}
		cabecera.push("");
		cabecera.push("Satisfactorio");
		cabecera.push("Alerta");
		cabecera.push("Riesgo");
		data.push(cabecera);

		for (let i = 0; i < this.datos_caducidad_productos.productos.length; i++) {
			var columns=[];
			columns.push((i+1));
			columns.push(this.datos_caducidad_productos.productos[i].codigo);
			columns.push(this.datos_caducidad_productos.productos[i].nombre);
			for(let j=0;j<this.datos_caducidad_productos.productos[i].fechas.length;j++){
				let fecha:any=new Date(this.datos_caducidad_productos.productos[i].fechas[j].fecha);
				fecha=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
				columns.push(fecha);
				columns.push(this.datos_caducidad_productos.productos[i].fechas[j].cantidad);
			}
			for(let j=0;j<this.datos_caducidad_productos.mas_fechas-this.datos_caducidad_productos.productos[i].fechas.length;j++){
				columns.push("");
				columns.push("");
			}
			columns.push("");
			columns.push(this.datos_caducidad_productos.alertas[i].satisfactorio);
			columns.push(this.datos_caducidad_productos.alertas[i].alerta);
			columns.push(this.datos_caducidad_productos.alertas[i].riesgo);
			data.push(columns);
		}

		let pie=["","",""];
		for(let i=0;i<this.datos_caducidad_productos.mas_fechas;i++){
			pie.push("");
			pie.push("");
		}
		pie.push("TOTALES");
		pie.push(this.datos_caducidad_productos.sumatoria_satisfactorio);
		pie.push(this.datos_caducidad_productos.sumatoria_alerta);
		pie.push(this.datos_caducidad_productos.sumatoria_riesgo);
		data.push(pie);
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("CADUCIDAD PRODUCTOS");
		data.forEach(d => {
			let row = worksheet.addRow(d);
		});

		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 40;

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "CADUCIDAD PRODUCTOS.xlsx");
			this.blockUI.stop();
		});
	}

	dibujarCabeceraPdfCaducidadProductos(doc,sucursal,cliente) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,25,5).stroke();
		doc.text("Sucursal : ",360,75);
		doc.text(sucursal,400,75);
		doc.text("Fecha Hoy:",465,75);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,515,75);
		doc.text("Cliente : ",385,85);
		doc.text(cliente,440,85);		

		doc.font('Helvetica-Bold',8);
		let x=280,y=110,ancho_circulo=this.datos_caducidad_productos.mas_fechas*61+235;
		doc.text("Nº",45,y+10);
		doc.text("Codigo",60,y+10);
		doc.text("Producto",100,y+10);
		for(let i=0;i<this.datos_caducidad_productos.mas_fechas;i++){
			doc.text("F. Prod.",x+5,y+10);
			doc.text("Cant.",x+40,y+10);
			x=x+60;
		}
		doc.roundedRect(40,y,ancho_circulo,25,5).fillOpacity(0.8).stroke();

		doc.text("Satisf.",640,y+10);
		doc.text("Alerta",670,y+10);
		doc.text("Riesgo",700,y+10);
		doc.roundedRect(635,y,100,25,5).fillOpacity(0.8).stroke();
	}

}
