<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Ventas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Lista de</span>
          Ventas
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button *ngIf="usuario.empresa.usar_consumo_de_agua" title="Nuevo" (click)="crearNuevoConsumo()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
            <button *ngIf="usuario.empresa.usar_ingresos_economicos_instituto" title="Nuevo" (click)="crearNuevoVentaInstituto()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
            <button *ngIf="usuario.empresa.usar_ventas && aplicacion.puede_crear" title="Nuevo" (click)="crearNuevaVenta()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-purple-d1"></i>
            </button>
            <div class="export btn-group">
              <button class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                <i class="fa fa-download text-green-d1"></i>
                <span class="caret"></span>
              </button>
              <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-productos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                <i class="fa fa-upload text-red fileUpload-button"></i>
                <input id="subir-excel-productos" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              </button>
              <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                <i class="fa fa-wrench text-blue-d1"></i>
                <span class="caret"></span>
              </button>
              <button (click)="verPagosVenta(null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista Pagos Ventas" aria-expanded="true">
                <i class="fa fa-list-alt text-blue-d1"></i>
                <span class="caret"></span>
              </button>
              <button *ngIf="usuario.empresa.usar_cotizaciones" (click)="verCotizaciones()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista de cotizaciones" aria-expanded="true">
                <i class="fas fa-clipboard-list text-warning-d1"></i>
                <span class="caret"></span>
              </button>
              <button *ngIf="usuario.empresa.usar_devoluciones_ventas" (click)="verDevolucionesVentas(null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1" type="button" title="Lista Devoluciones Ventas" aria-expanded="true">
                <i class="fa fa-list text-danger-d1"></i>
                <span class="caret"></span>
              </button>
              <button (click)="verVentasBorrador()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" type="button" title="Lista de ventas borrador" aria-expanded="true">
                <i class="fas fa-eraser text-success"></i>
                <span class="caret"></span>
              </button>
              <button *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea" (click)="verEventosSignificativos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-purple btn-a-outline-purple py-1" type="button" title="Lista Eventos Significativos" aria-expanded="true">
                <i class="fas fa-cloud-meatball text-purple-d1"></i>
                <span class="caret"></span>
              </button>
    
              <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                  <div class="card bgc-primary brc-primary radius-0">
                    <div class="card-header">
                    <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                    </div>
          
                    <div class="card-body p-0 bg-white">
                    <table class="table table-striped table-hover mb-0">
                      <tbody>
                        <tr>
                          <td class="text-dark-m2">
                          Configuración Aplicación
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                          </td>
                          <td class="text-dark-m2">
                            Anulación Factura Via C.U.F.
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="anularFacturaCuf()" href="javascript:void(0)" class="text-danger"><i class="fa fa-trash text-danger"></i></a>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-dark-m2">
                            Descarga Ventas
                          </td>
                          <td>
                            ¿Con Detalle?
                            <label>
                              <input name="datos_con_detalle" [(ngModel)]="datos_con_detalle" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                            </label>
                          </td>
                          <td class="text-dark-m2">
                            <a [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_excel" (click)="generarExcelFiltroVentas()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                          </td>
                          <td>
                            <a [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_pdf" (click)="generarPdfFiltroVentas()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                          </td>
                        </tr>
                        <tr *ngIf="usuario.empresa.usar_ingresos_economicos_instituto">
                          <td class="text-dark-m2">
                            Reporte de Pagos
                          </td>
                          <td class="text-dark-m2">
                            <button (click)="verReportePagosEstudiantesInstituto()" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                              <i class="fas fa-chart-bar text-primary text-red fileUpload-button"></i>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="usuario.empresa.usar_ingresos_economicos_colegio">
                          <td class="text-dark-m2">
                            Reporte de Pagos
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="generarExcelReportePagosCuotasColegio()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                          </td>
                        </tr>
                        <ng-container *ngIf="usuario.empresa.usar_ventas_app_offline">
                          <tr *ngIf="aplicacion.puede_crear">
                            <td class="text-dark-m2">
                              Subida ventas App
                            </td>
                            <td class="text-dark-m2">
                              <button (click)="clickBotonSubir('subir-datos-app')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                                <i class="fa fa-upload text-red fileUpload-button"></i>
                                <input id="subir-datos-app" (change)="subirVentasApp($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".snapquick">
                              </button>
                            </td>
                          </tr>
                          <tr *ngIf="aplicacion.puede_crear">
                            <td class="text-dark-m2">
                              Subida pagos ventas App
                            </td>
                            <td class="text-dark-m2">
                              <button (click)="clickBotonSubir('subir-datos-pagos-app')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                                <i class="fa fa-upload text-red fileUpload-button"></i>
                                <input id="subir-datos-pagos-app" (change)="subirPagosVentasApp($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".snapquick">
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="!usuario.empresa.usar_sucursales">
                          <tr *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
                            <td class="text-dark-m2">
                              Activar Facturación Offline
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="activarFacturacionOffline()" href="javascript:void(0)" class="text-green"><i class="fas fa-ethernet text-danger"></i></a>
                            </td>
                            <td class="text-dark-m2">
                              Activar Facturación Online
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="activarFacturacionOnline()" href="javascript:void(0)" class="text-green"><i class="fas fa-ethernet text-success"></i></a>
                            </td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="usuario.empresa.usar_mensajeria_redes_sociales">
                          <td class="text-dark-m2">
                          Grupos Whatsapp
                          </td>
                          <td class="text-dark-m2">
                            <a (click)="abrirParticipantesGrupoWhatsapp()" href="javascript:void(0)" class="text-success"><i class="fas fa-user-friends text-purple-d1"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
    
            <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                  <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
                  <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                  <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                  <label>Transacción </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="transaccion" [(ngModel)]="filter.transaccion">
                      <option [ngValue]="transaccion" *ngFor="let transaccion of filter.transacciones">{{transaccion.nombre}}</option>
                    </select>
                            </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Canal </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="canal" [(ngModel)]="filter.canal">
                      <option [ngValue]="canal" *ngFor="let canal of filter.canales">{{canal.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea">
                    <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}}</label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="despacho" [(ngModel)]="filter.despacho">
                      <option [ngValue]="despacho" *ngFor="let despacho of filter.despachos">{{despacho.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Validez </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="activa" [(ngModel)]="filter.activa">
                      <option [ngValue]="activa" *ngFor="let activa of filter.activas">{{activa.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Cliente </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Producto </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.producto">
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Observación </label>
                    <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.observacion">
                  </div>
                  <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_obsequios">
                    <label>¿Solo Obsequios? </label>
                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1 form-control form-control-sm" type="checkbox" [(ngModel)]="filter.solo_obsequios"/>
                  </div>
                  <!--<div class="col-md-1 col-sm-12">
                  <label>¿Inferido? </label>
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.inferido"/>
                              </label>
                  </div>-->
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="ventas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().transaccion.show">
                        
                        <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='transaccion' && this.direction=='asc','desc':this.column=='transaccion' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('transaccion')">Transacción</a>
                        </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().factura.show">Num. Doc.</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">Sucursal</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes">Almacen</th>
                    <th *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cliente.show">Cliente</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().nit.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cliente_categoria.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show">Contacto Cliente</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show">Fecha Doc..</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().createdAt.show">Hora - Fecha</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha_ingreso_hotel.show && usuario.empresa.usar_hoteles">Fecha Ingreso Hotel</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha_salida_hotel.show && usuario.empresa.usar_hoteles">Fecha Salida Hotel</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descuento.show">Descuento</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show">Total</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cobrado.show">Cobrado</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().saldo.show">Saldo</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().dias_credito.show">Dias Credito</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().dias_mora.show">Dias Mora</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">Tipo de Pago</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().canal.show">Canal</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show">Estado</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activa.show">Validez</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show">Observacion</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let venta of ventas; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().transaccion.show">
                        <span *ngIf="venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION || venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_MANUAL" class="badge" [ngClass]="{'badge-primary':venta.sin_en_linea,'badge-warning':!venta.sin_en_linea}">FACTURACIÓN</span>
                        <span *ngIf="venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_PROFORMA" class="badge badge-secondary">{{venta.transaccion}}</span>
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().factura.show">{{venta.factura}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">{{venta.sucursal}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes">{{venta.almacen?venta.almacen:''}}</td>
                    <td *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show">{{venta.tipo_despacho?venta.tipo_despacho:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().cliente.show">{{venta.cliente}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{venta.razon_social}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{venta.nit_ci}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().cliente_categoria.show">{{venta.categoria_cliente?venta.categoria_cliente:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show">{{venta.contacto_cliente?venta.contacto_cliente:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha.show">{{venta.fecha | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().createdAt.show">{{venta.createdAt | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha_ingreso_hotel.show && usuario.empresa.usar_hoteles">{{venta.fecha_ingreso_hotel | date:"dd/MM/yyyy - HH:mm"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha_salida_hotel.show && usuario.empresa.usar_hoteles">{{venta.fecha_salida_hotel | date:"dd/MM/yyyy - HH:mm"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().importe.show">{{venta.importe.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().descuento.show">{{venta.descuento.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{venta.total.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().cobrado.show">{{venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO?venta.a_cuenta:venta.total.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().saldo.show">{{(venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO?venta.saldo:0).toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().dias_credito.show">{{venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO?venta.dias_credito:0}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().dias_mora.show">{{(venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO && venta.saldo>0)?calcularDiasMora(venta):0}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">
                        <span *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="badge btn-purple">
                          {{venta.tipo_pago}} 
                        </span>
                        <span *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CONTADO" class="badge btn-pink">{{venta.tipo_pago}}</span>                        
                        <span *ngIf="venta.tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CONTADO && venta.tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CREDITO" class="badge btn-info">{{venta.tipo_pago}}</span>
                        <a (click)="convertirVentaCredito(venta)" *ngIf="venta.activa==1 && usuario.empresa.usar_vencimiento_economico_ventas && venta.tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CREDITO" class="card-toolbar-btn text-danger-m1" href="javascript:void(0)">
                          <i class="fas fa-exchange-alt bigger-130 red2"></i>
                        </a>
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{venta.usuario}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().canal.show">{{venta.canal?venta.canal:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().estado.show && venta.activa">
                        <span *ngIf="venta.id_estado && venta.estado_nombre_corto=='ESTVENTSAN'" class="badge badge-sm badge-success">{{venta.estado}}</span>
                        <span *ngIf="venta.id_estado && venta.estado_nombre_corto=='ESTVENTVIG'" class="badge badge-sm badge-warning">{{venta.estado}}</span>
                        <span *ngIf="venta.id_estado && venta.estado_nombre_corto=='ESTVENTMOR'" class="badge btn-pink">{{venta.estado}}</span>
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().estado.show && !venta.activa">
                        <span class="badge badge-sm badge-danger">ANULADO</span>
                    </td>
                    <td *ngIf="venta.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!venta.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().observacion.show"><div style="width: 150px">{{venta.observacion}}</div></td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="d-none d-lg-inline text-muted">
                            <a title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO && venta.saldo>0 && venta.activa" (click)="crearPagoVenta(venta)">
                                <i class="fas fa-money-bill-alt"></i>
                            </a>
                            <a *ngIf="usuario.empresa.usar_consumo_de_agua && venta.activa" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(venta)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirVenta(venta,false)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(venta)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verPagosVenta(venta)">
                                <i class="fa fa-list-alt bigger-130"></i>
                            </a>
                            <a *ngIf="usuario.empresa.usar_devoluciones_ventas" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="verDevolucionesVentas(venta)">
                                <i class="fa fa-list bigger-130"></i>
                            </a>
                            <a *ngIf="venta.pedido==null && usuario.empresa.usar_inventario && venta.activa && !venta.descontar_almacen" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" e-disabled="!aplicacion.puede_modificar" (click)="abrirPopupConfirmacionExtraccion(venta)">
                                <i class="ace-icon fa fa-gift bigger-130"></i>
                            </a>
                            <a *ngIf="!venta.activa" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="copiarVenta(venta)">
                                <i class="ace-icon fa fa-copy bigger-130"></i>
                            </a>
                            <a *ngIf="venta.activa && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                            <a  class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioCorreo(venta)">
                                <i class="ace-icon fa fa-envelope bigger-130"></i>
                            </a>
                            <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioWhatsapp(venta)">
                              <i class="ace-icon fab fa-whatsapp bigger-130"></i>
                            </a>
                            <a *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && (venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_MANUAL) && venta.sin_en_linea" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="verificarEstadoFacturaSin(venta)">
                              <i class="ace-icon fas fa-clipboard-check bigger-130"></i>
                            </a>
                        </div>
                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a title="Cobrar" class="dropdown-item" href="javascript:void(0)" *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO && venta.saldo>0 && venta.activa" (click)="crearPagoVenta(venta)">
                                <i class="fas fa-money-bill-alt text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Cobrar
                              </a>
                              <a *ngIf="usuario.empresa.usar_consumo_de_agua && venta.activa" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(venta)">
                                <i class="fa fa-print text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVenta(venta,false)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>

                              <a class="dropdown-item" href="javascript:void(0)" (click)="verVenta(venta)">
                                  <i class="fa fa-search-plus text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a *ngIf="venta.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="dropdown-item" href="javascript:void(0)" (click)="verPagosVenta(venta)">
                                  <i class="fa fa-list-alt text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver Pagos
                              </a>
                              <a *ngIf="usuario.empresa.usar_devoluciones_ventas" class="dropdown-item" href="javascript:void(0)" (click)="verDevolucionesVentas(venta)">
                                  <i class="fa fa-list text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver Devoluciones
                              </a>
                              <a *ngIf="venta.pedido==null && usuario.empresa.usar_inventario && venta.activa && !venta.descontar_almacen" class="dropdown-item" href="javascript:void(0)" e-disabled="!aplicacion.puede_modificar" (click)="abrirPopupConfirmacionExtraccion(venta)">
                                  <i class="fa fa-gift text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Descontar Almacen
                              </a>
                              <a *ngIf="!venta.activa" class="dropdown-item" href="javascript:void(0)" (click)="copiarVenta(venta)">
                                  <i class="fa fa-copy text-purple mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Copiar
                              </a>
                              <a *ngIf="venta.activa && aplicacion.puede_eliminar" class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                                <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                              </a>
                              <a  class="dropdown-item" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioCorreo(venta)">
                                  <i class="fa fa-envelope text-purple mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Enviar Correo
                              </a>
                              <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="dropdown-item" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioWhatsapp(venta)">
                                <i class="fab fa-whatsapp text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Enviar Whatsapp
                              </a>
                              <a *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && (venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_MANUAL) && venta.sin_en_linea" class="dropdown-item" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="verificarEstadoFacturaSin(venta)">
                                <i class="fas fa-clipboard-check text-warning mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Verificar S.I.N.
                              </a>
                            </div>
                          </div>
                        </div>

                        <!--<div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a *ngIf="usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(venta)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVenta(venta)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="verVenta(venta)">
                                  <i class="fa fa-search-plus text-blue-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a *ngIf="venta.activa" class="dropdown-item" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                                <i class="fa fa-trash text-danger-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Anular
                              </a>
                            </div>
                          </div>
                        </div>-->
                    </td>
                </tr>
                <tr *ngIf="ventas.length>0">
                    <th></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().transaccion.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().factura.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes"></th>
                    <th *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cliente.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().nit.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cliente_categoria.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().createdAt.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show"><b>TOTAL</b>: {{sumarImporte().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descuento.show"><b>TOTAL</b>: {{sumarDescuento().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show"><b>TOTAL</b>: {{sumarTotal().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cobrado.show"><b>TOTAL</b>: {{sumarAcuenta().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().saldo.show"><b>TOTAL</b>: {{sumarSaldo().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show"> </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activa.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show"></th>
                    <th></th>											
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="ventas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<canvas id="qr-code" style="display:none" width="100" height="100"></canvas>

<ng-template #ventas_borrador_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Lista de ventas en borrador</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="table-fixed-header">
						<table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().transaccion.show">Transaccion</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show">Sucursal</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().nit.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().fecha.show">Fecha Doc..</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">Tipo de Pago</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().observacion.show">Observacion</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let venta_borrador of ventas_borrador; let i=index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().transaccion.show">{{venta_borrador.transaccion.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().sucursal.show">{{venta_borrador.sucursal.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{venta_borrador.cliente.razon_social}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{venta_borrador.cliente.nit}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().fecha.show">{{venta_borrador.fecha | date:"dd/MM/yyyy"}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().importe.show">{{venta_borrador.total.toFixed(2)}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">{{venta_borrador.tipoPago.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{venta_borrador.usuario.nombre_usuario}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().observacion.show"><div style="width: 150px">{{venta_borrador.observacion}}</div></td>
                  <td>
                    <div class="d-none d-lg-inline text-muted">
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarVentaBorrador(venta_borrador)">
                        <i class="fa fa-edit bigger-130"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionBorrador(venta_borrador)">
                        <i class="fa fa-trash-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #importacion_ventas_modal let-modal>
	<div class="modal-header">
		<div class="col-6">
			<h4 class="widget-title">Importación de ventas</h4>
    </div>
		<div class="col-6">
      <button class="btn btn-primary" type="button" [disabled]="ventas_importacion.length==0" (click)="guardarImportacionVentas()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
  </div>
  <div class="modal-body" *ngIf="!mensaje_importacion">
    <div class="alert alert-danger" *ngIf="mensaje_importacion">
      <strong>
        <i class="ace-icon fa fa-times"></i>
        Oh no!
      </strong>
      {{mensaje_importacion}}.
      <br>
    </div>
    <div *ngFor="let venta of ventas_importacion;let i=index;">
      <div class="table-header">
        <div class="row">
          <div class="col-md-6">
            <h4 class="widget-title">Venta {{venta.numero?venta.numero:venta.factura}}</h4>
          </div>
          <div class="col-md-6">
            <button class="btn btn-danger" type="button" (click)="abrirPopupConfirmacionEliminacionVentaImportacion(venta)">
              <i class="ace-icon fa fa-trash red2 align-top bigger-125"></i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
              <th *ngIf="usuario.empresa.usar_almacenes || usuario.empresa.usar_almacenes_usuario">Almacen</th>
              <th>Transacción</th>
              <th>Fecha</th>
              <th>Tipo de Pago</th>
              <th>Nº de Doc.</th>
              <th>Total</th>
              <th *ngIf="venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">Datos Crédito</th>
              <th>Observacion</th>
              <th>Cliente</th>
              <th>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</th>
              <th>Usuario</th>
            </tr>
          </thead>
    
          <tbody>
            <tr >
              <td>{{i+1}}</td>
              <td *ngIf="usuario.empresa.usar_sucursales">{{venta.sucursal.nombre}}</td>
              <td *ngIf="usuario.empresa.usar_almacenes || usuario.empresa.usar_almacenes_usuario">
                <select [compareWith]="compararObjectos" name="almacen{{i+1}}" 
                  class="form-control" [(ngModel)]="venta.almacen">
                    <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                </select>
              </td>
              <td>{{venta.transaccion.nombre}}</td>
              <td>{{venta.fecha | date:"dd/MM/yyyy" }}</td>
              <td>{{venta.tipoPago.nombre}}</td>
              <td>{{venta.factura}}</td>
              <td>{{venta.total.toFixed(2)}}</td>
              <td *ngIf="venta.tipoPago.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CRE">
                Días Crédito: {{venta.dias_credito}}<br>
                A Cuenta: {{venta.a_cuenta}}<br>
                Saldo: {{venta.saldo}}
              </td>
              <td>{{venta.observacion}}</td>
              <td>{{venta.cliente.razon_social}}</td>
              <td>{{venta.cliente.nit}}</td>
              <td>
                <select [compareWith]="compararObjectos" name="usuario_empresa{{i+1}}" 
                class="form-control" [(ngModel)]="venta.usuario">
                  <option [ngValue]="usuario" *ngFor="let usuario of filter.usuarios | filterBy:{activo:true}">{{usuario.nombre_usuario}}</option>
                </select>
              </td>
            </tr>
            <tr>
              <td colspan="20">
                <table>
                  <thead *ngIf="venta.detallesVenta.length>0">
                    <tr>                      
                      <th>DETALLE VENTA</th>
                      <th>Tipo Item</th>
                      <th>Nombre</th>
                      <th>Código</th>
                      <th>Cantidad</th>
                      <th>P. U.</th>
                      <th>Importe</th>
                      <th>Desc.</th>
                      <th>Total</th>
                      <th>Descripción</th>
                      <th>¿Inventario activado?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let detalleVenta of venta.detallesVenta;let j=index;">
                      <tr *ngIf="!detalleVenta.eliminado">
                        <td></td>
                        <td>{{detalleVenta.producto.id?'PRODUCTO':'COMBO'}}</td>
                        <td>{{detalleVenta.producto.nombre}}</td>
                        <td>{{detalleVenta.producto.codigo}}</td>
                        <td>{{detalleVenta.cantidad}}</td>
                        <td>{{detalleVenta.precio_unitario}}</td>
                        <td>{{detalleVenta.importe}}</td>
                        <td>{{detalleVenta.descuento}}</td>
                        <td>{{detalleVenta.total}}</td>
                        <td>{{detalleVenta.descripcion}}</td>
                        <td>
                          <input name="switch-field-1{{ij}}" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleVenta.activar_inventario"/>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <table>
            <thead *ngIf="venta.pagos && venta.pagos.length>0">
              <tr>						
                <th></th>
                <th>PAGOS</th>
                <th>Numero Pago</th>
                <th>Numero Documento</th>
                <th>A Cuenta Anterior</th>
                <th>Saldo Anterior</th>
                <th>Importe de Pago</th>
              </tr>
            </thead>
            </table>
            <tr *ngFor="let pago of venta.pagos">						
                <td></td>
                <td></td>
                <td>{{pago.numero}}</td>
                <td>{{pago.numero_documento}}</td>
                <td>{{pago.a_cuenta_anterior}}</td>
                <td>{{pago.saldo_anterior}}</td>
                <td>{{pago.monto_pagado}}</td>
            </tr>
            <table>
            <thead *ngIf="venta.devoluciones && venta.devoluciones.length>0">
              <tr>						
                <th></th>
                <th>DEVOLUCIONES</th>
                <th>Numero Devolucion</th>
                <th>Fecha Devolucion</th>
                <th>Numero Documento</th>
                <th>Observacion</th>
              </tr>
            </thead>
            <tbody *ngFor="let devolucion of venta.devoluciones">
              <tr>						
                <td></td>
                <td></td>
                <td>{{devolucion.numero}}</td>
                <td>{{devolucion.fecha | date:"dd/MM/yyyy" }}</td>
                <td>{{devolucion.numero_documento}}</td>
                <td>{{devolucion.observacion}}</td>
              </tr>
              <tr *ngIf="devolucion.detalles_devolucion.length>0">						
                <th></th>
                <th></th>
                <th>DETALLE DEVOLUCION</th>
                <th>Tipo Item Devolucion</th>
                <th>Codigo Item Devolucion</th>
                <th>Cantidad Item Devolucion</th>
                <th>Descripcion Item Devolucion</th>
              </tr>
              <tr *ngFor="detalle_devolucion in devolucion.detalles_devolucion">						
                <td></td>
                <td></td>
                <td></td>
                <td>{{detalle_devolucion.item_tipo}}</td>
                <td>{{detalle_devolucion.item_codigo}}</td>
                <td>{{detalle_devolucion.item_cantidad}}</td>
                <td>{{detalle_devolucion.item_descripcion}}</td>
              </tr>
            </tbody>
            </table>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #importacion_pagos_ventas_modal let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Importación de pagos de ventas</h4>
    </div>
		<div class="col-4">
      <button class="btn btn-primary" type="button" [disabled]="pagos_ventas_importacion.length==0" (click)="guardarImportacionPagosVentas()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
  </div>
  <div class="modal-body" *ngIf="!mensaje_importacion">
    <div class="alert alert-danger" *ngIf="mensaje_importacion">
      <strong>
        <i class="ace-icon fa fa-times"></i>
        Oh no!
      </strong>
      {{mensaje_importacion}}.
      <br>
    </div>
    <div>
      <div class="table-header">
        <div class="row">
          <div class="col-md-12">
            <h4 class="widget-title">Pago Ventas</h4>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Nº</th>
              <th>Fecha Venta</th>
              <th>Nro. Doc Venta</th>
              <th>Transacción Venta</th>
              <th>Cliente</th>
              <th>Total Venta</th>
              <th>Pago A Cuenta</th>
              <th>Acciones</th>
            </tr>
          </thead>
    
          <tbody>
            <tr *ngFor="let pago_venta of pagos_ventas_importacion; let i=index;">
              <td>{{i+1}}</td>
              <td>{{pago_venta.venta.fecha | date:"dd/MM/yyyy" }}</td>
              <td>{{pago_venta.venta.factura}}</td>
              <td>{{pago_venta.venta.transaccion.nombre}}</td>
              <td>{{pago_venta.cliente.identificacion}}</td>
              <td>{{pago_venta.venta.total}}</td>
              <td>{{pago_venta.monto_pagado}}</td>
              <td>
                <button class="btn btn-danger" type="button" (click)="abrirPopupConfirmacionEliminacionPagoVentaImportacion(pago_venta)">
                  <i class="ace-icon fa fa-trash red2 align-top bigger-125"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lista_cotizaciones_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Lista de Cotizaciones</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Nº</th>
            <th>Fecha</th>
            <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
            <th>Nombre</th>
            <th>Descripcion</th>
            <th>Num. Doc.</th>
            <th>Importe</th>							
            <th>Usuario</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cotizacion of cotizaciones | orderBy:fecha:true;let i=index;">
            <td>{{i+1}}</td>
            <td>{{cotizacion.fecha | date:"dd/MM/yyyy"}}</td>
            <td *ngIf="usuario.empresa.usar_sucursales">{{cotizacion.sucursal.nombre}}</td>
            <td>{{cotizacion.cliente?cotizacion.cliente.identificacion:cotizacion.nombre}}</td>
            <td>{{cotizacion.descripcion}}</td>
            <td>{{cotizacion.numero_documento}}</td>
            <td>{{cotizacion.total}}</td>
            <td>{{cotizacion.usuario.nombre_usuario}}</td>
            <td>
              <div class="d-none d-lg-inline text-muted">
                <a title="Usar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="usarCotizacion(cotizacion)">
                    <i class="fas fa-check"></i>
                </a>
                <a title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionCotizacion(cotizacion)">
                  <i class="fas fa-trash-alt"></i>
                </a>
            </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
</ng-template>

<ng-template #conversion_venta_credito_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Conversion Venta Credito</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarVentaCredito()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Días Crédito</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="dias_credito" required type="number" step="1" id="dias_credito" [(ngModel)]="venta.dias_credito" placeholder="Díás crédito" class="form-control" />
        </div>
    </div>
</div>
</ng-template>

<ng-template #anulacion_factura_cuf_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Anulación de Factura via C.U.F.</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarAnulacionCuf()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Sucursal</label>
        </div>
        <div class="col-sm-12 col-md-9">
          <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="anulacion_cuf.sucursal">
            <option [ngValue]="sucursal" *ngFor="let sucursal of anulacion_cuf.sucursales">{{sucursal.nombre}}</option>
          </select>
        </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>Motivo anulación: </label>
      </div>
      <div class="col-sm-12 col-md-9">
        <select class="form-control" name="motivo_anulacion" [(ngModel)]="anulacion_cuf.motivo_anulacion">
          <option [ngValue]="motivo_anulacion" *ngFor="let motivo_anulacion of sin_motivos_anulacion">{{motivo_anulacion.nombre}}</option>
        </select>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>C.U.F.</label>
      </div>
      <div class="col-sm-12 col-md-9">
          <input name="cuf" class="form-control" type="text" [(ngModel)]="anulacion_cuf.cuf" placeholder="C.U.F." class="form-control" />
      </div>
    </div>
</div>
</ng-template>