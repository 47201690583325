import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';

@Component({
  selector: 'app-registro-despacho',
  templateUrl: './registro-despacho.component.html',
  styleUrls: ['./registro-despacho.component.css']
})
export class RegistroDespachoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  pedido:any;
  existe_saldo_pedido:boolean=true;
  ids_productos:string="";
  despacho:any={};
  productos_inventario:any[];
  otros_almacenes:any[];
  sucursal:any;
  sucursales:any[];

  constructor(
    public persistenciaService:PersistenciaService,
    public pedidosService:PedidosService,
    public productosService:ProductosService,
    public ventasService:VentasService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  ngOnInit(): void {
    this.sucursal=this.sucursales.filter( e => e.id==this.pedido.sucursal.id)[0];
    for(var i=0;i<this.pedido.detallesPedido.length;i++){
      this.existe_saldo_pedido=this.existe_saldo_pedido && this.pedido.detallesPedido[i].saldo==0;
      if(this.pedido.detallesPedido[i].producto){
        if((i+1)==this.pedido.detallesPedido.length){
          this.ids_productos=this.ids_productos+this.pedido.detallesPedido[i].producto.id;
        }else{
          this.ids_productos=this.ids_productos+this.pedido.detallesPedido[i].producto.id+",";
        }
      }else{
        for(var j=0;j<this.pedido.detallesPedido[i].combo.detallesCombo.length;j++){
          if((i+1)==this.pedido.detallesPedido.length && (j+1)==this.pedido.detallesPedido[i].combo.detallesCombo.length){
            this.ids_productos=this.ids_productos+this.pedido.detallesPedido[i].combo.detallesCombo[j].producto.id;
          }else{
            this.ids_productos=this.ids_productos+this.pedido.detallesPedido[i].combo.detallesCombo[j].producto.id+",";
          }
        }
      }
      
    }

    this.despacho={id_sucursal:this.pedido.id_sucursal,id_pedido:this.pedido.id,id_usuario:this.pedido.id_usuario,
    detallesDespacho:JSON.parse(JSON.stringify(this.pedido.detallesPedido)),total:this.pedido.total,importe:this.pedido.importe,descuento:this.pedido.descuento,tipo_descuento:this.pedido.tipo_descuento};
    for(var i=0;i<this.despacho.detallesDespacho.length;i++){
      this.despacho.detallesDespacho[i].id_detalle_pedido=this.despacho.detallesDespacho[i].id;
    }

    this.despacho.almacen_origen=this.sucursal.almacenes[0];
    this.obtenerAlmacenesDiferente(this.despacho.almacen_origen)
  }

  obtenerAlmacenesDiferente(almacen_aexcluir){
    this.productosService.obtenerInventariosProductos(this.ids_productos,almacen_aexcluir.id).subscribe((productos_inventario:any)=>{
      this.productos_inventario=productos_inventario;
			//if(this.usuario.empresa.usar_combos){
				this.calcularCantidades(-1);
			//}
    });
		this.otros_almacenes= this.sucursal.almacenes.filter(e => e.id != almacen_aexcluir.id);
  }
  
  calcularCantidades(indice){
		for(var i=indice+1;i<this.despacho.detallesDespacho.length;i++){
			if(this.despacho.detallesDespacho[i].producto){
				if(this.despacho.detallesDespacho[i].producto.activar_inventario){
					var prodInv=this.productos_inventario.filter(e => e.id == this.despacho.detallesDespacho[i].producto.id)[0];
					this.despacho.detallesDespacho[i].cantidad_disponible=this.obtenerInventarioTotal(prodInv,i);
					this.despacho.detallesDespacho[i].cantidad_requerida=this.despacho.detallesDespacho[i].cantidad-this.despacho.detallesDespacho[i].cantidad_despachado;
					this.despacho.detallesDespacho[i].cantidad_despacho=(this.despacho.detallesDespacho[i].cantidad_disponible>this.despacho.detallesDespacho[i].cantidad_requerida?this.despacho.detallesDespacho[i].cantidad_requerida:this.despacho.detallesDespacho[i].cantidad_disponible);
					this.despacho.detallesDespacho[i].cantidad_maximo=(this.despacho.detallesDespacho[i].cantidad_requerida<this.despacho.detallesDespacho[i].cantidad_disponible)?this.despacho.detallesDespacho[i].cantidad_requerida:this.despacho.detallesDespacho[i].cantidad_disponible;
				}else{
					this.despacho.detallesDespacho[i].cantidad_requerida=this.despacho.detallesDespacho[i].cantidad-this.despacho.detallesDespacho[i].cantidad_despachado;
					this.despacho.detallesDespacho[i].cantidad_disponible=this.despacho.detallesDespacho[i].cantidad_requerida;
					this.despacho.detallesDespacho[i].cantidad_despacho=this.despacho.detallesDespacho[i].cantidad-this.despacho.detallesDespacho[i].cantidad_despachado;
					this.despacho.detallesDespacho[i].cantidad_maximo=this.despacho.detallesDespacho[i].cantidad-this.despacho.detallesDespacho[i].cantidad_despachado;
				}
			}else{
				var totalInventarioCombo=50000;
				for(var j=0;j<this.despacho.detallesDespacho[i].combo.detallesCombo.length;j++){
					var prodInv=this.productos_inventario.filter(e => e.id == this.despacho.detallesDespacho[i].combo.detallesCombo[j].producto.id)[0];
					var totalInvProducto=this.obtenerInventarioTotal(prodInv,i);
					this.despacho.detallesDespacho[i].combo.detallesCombo[j].costos=prodInv.inventarios;
					var totalInventarioComboPorProducto=totalInvProducto/this.despacho.detallesDespacho[i].combo.detallesCombo[j].cantidad;
					totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
				}
				this.despacho.detallesDespacho[i].cantidad_disponible=totalInventarioCombo;

				this.despacho.detallesDespacho[i].cantidad_requerida=this.despacho.detallesDespacho[i].cantidad-this.despacho.detallesDespacho[i].cantidad_despachado;
				this.despacho.detallesDespacho[i].cantidad_despacho=(this.despacho.detallesDespacho[i].cantidad_disponible>this.despacho.detallesDespacho[i].cantidad_requerida?this.despacho.detallesDespacho[i].cantidad_requerida:this.despacho.detallesDespacho[i].cantidad_disponible);
				this.despacho.detallesDespacho[i].cantidad_maximo=(this.despacho.detallesDespacho[i].cantidad_requerida<this.despacho.detallesDespacho[i].cantidad_disponible)?this.despacho.detallesDespacho[i].cantidad_requerida:this.despacho.detallesDespacho[i].cantidad_disponible;
			}
		}
  }
  
  obtenerInventarioTotal(producto,indice){
		var cantidadTotal = 0;
		for(var i = 0; i < producto.inventarios.length; i++){
			cantidadTotal += (producto.inventarios[i].cantidad);
		}

		for(var i=0;i<indice;i++){
			if(this.despacho.detallesDespacho[i].producto){
				if(this.despacho.detallesDespacho[i].producto.id==producto.id){
					cantidadTotal=this.despacho.detallesDespacho[i].cantidad>cantidadTotal?0:cantidadTotal-this.despacho.detallesDespacho[i].cantidad;
				}
			}else{
				for(var j=0;j<this.despacho.detallesDespacho[i].combo.detallesCombo.length;j++){
					if(this.despacho.detallesDespacho[i].combo.detallesCombo[j].producto.id==producto.id){
						var cantidadDespachoCombo=this.despacho.detallesDespacho[i].cantidad_despacho*this.despacho.detallesDespacho[i].combo.detallesCombo[j].cantidad;
						cantidadTotal=(cantidadDespachoCombo>cantidadTotal)?0:cantidadTotal-cantidadDespachoCombo;
					}
				}
			}
			
		}
		return cantidadTotal;
  }
  
  cerrarComponente(){
    this.alTerminar.emit();
  }

  async guardarDespacho(){
    this.blockUI.start();
    var contador_cantidad_despacho=0,noexiste_saldo=true;
    let datos_despacho=JSON.parse(JSON.stringify(this.despacho));
		for(var i=0;i<datos_despacho.detallesDespacho.length;i++){
			datos_despacho.detallesDespacho[i].cantidad_original=datos_despacho.detallesDespacho[i].cantidad;
      datos_despacho.detallesDespacho[i].cantidad=datos_despacho.detallesDespacho[i].cantidad_despacho;
			contador_cantidad_despacho=contador_cantidad_despacho+datos_despacho.detallesDespacho[i].cantidad_despacho;
			noexiste_saldo=noexiste_saldo && datos_despacho.detallesDespacho[i].saldo==0;
		}
		if(contador_cantidad_despacho>0){
      var despacho_copia=JSON.parse(JSON.stringify(this.despacho));
      let venta:any=await this.generarVentaDespacho(datos_despacho);
      let res_venta:any=await this.ventasService.guardarVenta(venta).toPromise();
      datos_despacho.id_venta=res_venta.venta.id;
      let res:any=await this.pedidosService.registrarDespacho(datos_despacho).toPromise();
      if(res.tiene_error){
        this.blockUI.stop();
        this.toastr.error(res.mensaje);
      }else{
        //Actualizar pedido si las cantidades son igual a cero
        this.generalService.obtenerClases("ESTPEDI").subscribe(async (entidad:any)=>{
          let estados_pedido=entidad.clases;
          if(noexiste_saldo){
            var estado_entregado=estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_ENTGD)[0];
            this.pedido.estado=estado_entregado;
            
            if(this.pedido.id_venta){
              let res_v:any=await this.ventasService.actualizarDescuentoAlmacen(this.pedido.id_venta,this.pedido.venta).toPromise();
              this.toastr.success(res_v.mensaje);
            }
          }else{
            var estado_por_completar=estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_PCOMP)[0];
            this.pedido.estado=estado_por_completar;
          }
          this.pedidosService.actualizarEstadoPedido(this.pedido).subscribe((resP:any)=>{
            this.pedidosService.obtenerDespacho(res.despacho.id).subscribe((despachoConsultado:any)=>{
              this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).subscribe(async (conf:any)=>{
                let configuracion_aplicacion_pedidos=conf;
                despachoConsultado.detallesVenta=despachoConsultado.detallesDespacho;
                if(this.esNotaObsequio()){
                  this.pdfService.imprimirNotaObsequioCartaOficio(despachoConsultado,configuracion_aplicacion_pedidos.datos.opciones_permisos.impresion,this.usuario);
                }else{
                  this.pdfService.imprimirNotaDespachoCartaOficio(despachoConsultado,configuracion_aplicacion_pedidos.datos.opciones_permisos.impresion,this.usuario);
                }
                this.blockUI.stop();
                this.toastr.success(res.mensaje);
                this.toastr.success(resP.mensaje);
                this.alTerminar.emit({confirm:true});
              });
            });
          });
        });
      }
		}else{
      this.blockUI.stop();
      this.toastr.error("Cantidades disponibles insuficientes!");
    }
  }

  async generarVentaDespacho(despacho){
    let suma_total=0,suma_importe=0,suma_descuento=0;
    for(let i=0;i<despacho.detallesDespacho.length;i++){
      despacho.detallesDespacho[i].activar_inventario=despacho.detallesDespacho[i].producto?despacho.detallesDespacho[i].producto.activar_inventario:true;
      let descuento=0;
      if(despacho.detallesDespacho[i].es_obsequio){
        despacho.detallesDespacho[i].importe=0;
        despacho.detallesDespacho[i].total=0;
      }else{
        despacho.detallesDespacho[i].importe=(despacho.detallesDespacho[i].cantidad*despacho.detallesDespacho[i].precio_unitario);
        despacho.detallesDespacho[i].descuento=(despacho.detallesDespacho[i].tipo_descuento?despacho.detallesDespacho[i].descuento:((despacho.detallesDespacho[i].descuento/despacho.detallesDespacho[i].cantidad_original)*(despacho.detallesDespacho[i].cantidad)));
        descuento=(despacho.detallesDespacho[i].tipo_descuento?(despacho.detallesDespacho[i].importe*(despacho.detallesDespacho[i].descuento/100)):despacho.detallesDespacho[i].descuento);
        despacho.detallesDespacho[i].total=despacho.detallesDespacho[i].importe-descuento;
      }
      suma_total=suma_total+despacho.detallesDespacho[i].total;
      suma_importe=suma_importe+despacho.detallesDespacho[i].importe;
      suma_descuento=suma_descuento+descuento;
    }

    let pedido=this.pedido;
    let venta:any={cliente:pedido.cliente,pagado:0};

    if(this.usuario.empresa.usar_descuento_global){
      let descuento=0;
      if(this.pedido.tipo_descuento){
        descuento=(suma_importe-suma_descuento)*(this.pedido.descuento/100);
        despacho.descuento=this.pedido.descuento;
      }else{
        let sumatoria_subtotal=0;
        for(let i=0;i<this.pedido.detallesPedido.length;i++){
          sumatoria_subtotal=sumatoria_subtotal+this.pedido.detallesPedido[i].total;
        }
        descuento=((this.pedido.descuento/sumatoria_subtotal)*(suma_importe-suma_descuento));
        despacho.descuento=descuento;
      }
      venta.total=suma_importe-suma_descuento-descuento;
    }else{
      despacho.descuento=suma_descuento;
      venta.total=suma_total;
    }
    
    venta.cliente.id_sucursal=this.usuario.empresa.usar_clientes_sucursal?pedido.sucursal.id:null;
		venta.descontar_almacen=true;
		venta.importe=suma_importe;
		venta.descuento=despacho.descuento;
		venta.tipo_descuento=this.pedido.tipo_descuento;
		venta.pagado=0;
    venta.cambio=0;
    venta.dias_credito=1;
    venta.a_cuenta=0;
    venta.saldo=venta.total;
		venta.cambio=0;
		let entidad_transaccion:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
		venta.transaccion=entidad_transaccion.clases.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA)[0];
    let entidad_pago:any=await this.generalService.obtenerClases("TIPA").toPromise();
		venta.tipoPago=entidad_pago.clases.filter(e => e.nombre == GlobalVariable.Dictionary.TIPO_PAGO_CREDITO)[0];
		venta.fecha=new Date();
		venta.fechaTexto=venta.fecha.getDate()+"/"+(venta.fecha.getMonth()+1)+"/"+venta.fecha.getFullYear();
    venta.sucursal={id:despacho.id_sucursal};
    let entidad_canales:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
    venta.canal=entidad_canales.clases[0];
    venta.usuario={id:despacho.id_usuario,id_empresa:this.usuario.id_empresa};
    venta.id_empresa=this.usuario.empresa.id;
    venta.almacen=despacho.almacen_origen;
    venta.detallesVenta=[];
    venta.razon_social=pedido.cliente.razon_social;
    venta.nit_ci=pedido.cliente.nit;
    venta.observacion=pedido.nombre;
    venta.detallesVenta=despacho.detallesDespacho;
    venta.a_cuenta=venta.tipoPago.nombre_corto=="CRE"?venta.a_cuenta:venta.total;
    venta.saldo=venta.tipoPago.nombre_corto=="CRE"?venta.saldo:0;
    venta.id_contacto_cliente=pedido.id_contacto_cliente;
    venta.id_tipo_despacho=pedido.id_tipo_despacho;
    return venta;
  }
  
  esNotaObsequio(){
		var respuesta=true;
		for(var i=0;i<this.pedido.detallesPedido.length;i++){
			respuesta=respuesta && this.pedido.detallesPedido[i].es_obsequio;
		}
		return respuesta;
  }
  
  calcularSaldo(detalleDespacho){
		detalleDespacho.saldo=detalleDespacho.cantidad-detalleDespacho.cantidad_despachado-detalleDespacho.cantidad_despacho;
		return Math.round(detalleDespacho.saldo*100)/100;
	}

}
