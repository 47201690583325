<div class="modal-header">
    <div class="col-8 col-xs-6">
        <h4 class="widget-title">Vencimiento Deudas</h4>
    </div>
    <div class="col-4 col-xs-6">
        <button class="btn btn-success btn-lg" type="button">
            <i class="ace-icon fa fa-file-excel align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger btn-lg" type="button" >
            <i class="ace-icon fa fa-file-pdf align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body p-0">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover ">
            <thead class="bgc-primary-d1 text-white sticky-nav">
                <tr class="sticky-nav">
                    <th>Nº</th>
                    <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
                    <th>Transacción</th>
                    <th>Fecha</th>
                    <th>Proveedor
                        <input name="filtro-ident" class="form-control text-center" type="text" [(ngModel)]="filtro_compra.razon_social" (keyup)="filtrarCompras(filtro_compra.razon_social)">
                    </th>
                    <th>Nº Doc.</th>
                    <th>Fecha Venc.</th>
                    <th>Saldo</th>
                    <th>Dias Mora</th>
                    <th>Obs.</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let compra of compras_filtradas; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales">{{compra.sucursal.nombre}}</td>
                    <td>{{compra.tipo_documento.nombre}}</td>
                    <td>{{compra.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{compra.proveedor.razon_social}}</td>
                    <td>Prov: {{compra.factura}}  Int: {{compra.numero_documento}}</td>
                    <td>{{compra.fecha_vencimiento | date:"dd/MM/yyyy"}}
                        <span class="red" *ngIf="compra.fecha_anterior"> - ({{compra.fecha_anterior | date:"dd/MM/yyyy" }})</span>
                    </td>
                    <td>{{compra.saldo.toFixed(2)}}</td>
                    <td>{{calcularDiasMora(compra) }}</td>
                    <td>{{compra.observacion}}</td>
                    <td>
                        <div class="row">
                            <div class="col-12">
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(compra)">
                                    <i class="ace-icon fa fa-search-plus bigger-130"></i>
                                </a>
                                <a title="Cobrar" class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearPagoCompra(compra)">
                                    <i class="fas fa-money-bill-alt"></i>
                                </a>
                                <!--<a class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirActualizarFechaCreditos(compra)">
                                    <i class="ace-icon fa fa-calendar bigger-130"></i>
                                </a>-->
                                <a class="btn radius-1 btn-xs btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="imprimirVenta(compra,false)">
                                    <i class="ace-icon fa fa-print bigger-130"></i>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer p-0">
    <table class="table table-striped table-bordered table-hover ">
        <tfoot>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <h5 *ngIf="compras_credito" class="widget-title">Total Saldo: {{sumarTotalSaldo(compras_credito)}}</h5>
            </td>
            <td></td>
            <td></td>
        </tfoot>
    </table>
</div>