<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-4 col-xs-4">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-danger shadow-sm">
                <a class="link_tab codificacion_estudiantes nav-link text-left py-3" (click)="verTab('codificacion_estudiantes')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Codificación
                </a>
              </li>
              <li class="nav-item brc-success shadow-sm">
                <a class="link_tab tabla_estudiantes nav-link text-left py-3" (click)="verTab('tabla_estudiantes')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Tabla de Estudiantes
                </a>
              </li>
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab opciones_reportes nav-link text-left py-3" (click)="verTab('opciones_reportes')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Opciones de Reportes
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="contenido_tab codificacion_estudiantes text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-danger-l3 text-danger-d4 px-3 py-15 radius-1">
                        <legend >Codificación Estudiantes</legend>
                        <div class="row">
                            <div class="col-6">
                                <label>Acrónimo</label>
                            </div>
                            <div class="col-6">
                                <input type="text" id="acronimo" name="acronimo" [(ngModel)]="tipo_codigo.clases[0].nombre" placeholder="Acrónimo" class="form-control form-control-lg col-12"  />
                            </div>
                            <div class="col-6">
                                <label>Correlativo</label>
                            </div>
                            <div class="col-6">
                                <input type="number" step="0" min="1" id="correlativo" name="correlativo" [(ngModel)]="tipo_codigo.clases[0].nombre_corto" placeholder="Correlativo" class="form-control form-control-lg col-12"  />
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <label>¿Automático?</label>
                            </div>
                            <div class="col-6">
                                <label>
                                    <input name="automatico" [(ngModel)]="tipo_codigo.clases[0].habilitado" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab tabla_estudiantes text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
                        <legend >Tabla de Estudiantes</legend>
                        <div class="row">
                            <div class="col-5">
                                <label>Ordenar por</label>
                            </div>
                            <div class="col-7">
                                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
                                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                                </select>
                            </div>
                            <div class="col-5">
                                <label>Dirección</label>
                            </div>
                            <div class="col-7">
                                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
                                    <option value="asc">Ascendente</option>
                                    <option value="desc">Descendente</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab opciones_reportes text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Opciones Reportes</legend>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Reporte de pagos con fechas?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="reporte_pagos_con_fechas" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.reporte_pagos_con_fechas" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
</div>