import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { CarrerasService } from '../../services/carreras/carreras.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { Util } from 'src/app/utils/utils';
import { RegistroConceptoIngresoComponent } from 'src/app/school/components/registro-concepto-ingreso/registro-concepto-ingreso.component';
import { ConfiguracionAplicacionConceptosIngresosComponent } from '../configuracion-aplicacion-conceptos-ingresos/configuracion-aplicacion-conceptos-ingresos.component';
import * as fs from 'file-saver';
import { ConceptoIngresoCursosComponent } from 'src/app/school/components/concepto-ingreso-cursos/concepto-ingreso-cursos.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-conceptos-ingresos',
  templateUrl: './conceptos-ingresos.component.html',
  styleUrls: ['./conceptos-ingresos.component.css']
})
export class ConceptosIngresosComponent extends BaseComponent implements OnInit {

  conceptos_ingresos:any[]=[];
  concepto_ingreso:any;
  gestiones:any[]=[];
  semestres:any[]=[];
  carreras:any[]=[];
  calculos_fecha_vencimiento:any[]=[];

  tipo_concepto_pago:any={clases:[]};

  registro_concepto_ingreso_modal:NgbModalRef;
  @ViewChild('registro_concepto_ingreso_modal')
  private registro_concepto_ingreso_modal_ref: TemplateRef<any>;

  modificacion_cursos:NgbModalRef;

  concepto_pago:any;

  asignacion_fecha_vencimiento_modal:NgbModalRef;
  @ViewChild('asignacion_fecha_vencimiento_modal')
  private asignacion_fecha_vencimiento_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private conceptosIngresosService:ConceptosIngresosService,
    private carrerasService:CarrerasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CONCEPTOS_INGRESOS_INSTITUTO);
		this.filter={
      escuela:null,
			gestion:GlobalVariable.Dictionary.SELECCION_TODOS,
      gestiones:[GlobalVariable.Dictionary.SELECCION_TODOS],
      semestre:GlobalVariable.Dictionary.SELECCION_TODOS,
      semestres:[GlobalVariable.Dictionary.SELECCION_TODOS],
      carrera:GlobalVariable.Dictionary.SELECCION_TODOS,
      carreras:[GlobalVariable.Dictionary.SELECCION_TODOS]
		}
    await this.obtenerEscuela();
    await this.obtenerGestiones();
    if(this.usuario.empresa.usar_estudiantes_instituto){
      this.obtenerSemestresGestion();
      this.obtenerCarreras();
      this.obtenerCalculosFechaVencimiento();
    }
    this.obtenerConceptosPagoEscuela();
    this.column = "createdAt";
		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");console.log(this.router.url.substring(1));
	}

  async obtenerGestiones(){
    if(this.usuario.empresa.usar_estudiantes_instituto){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
        this.filter.gestiones=this.filter.gestiones.concat(entidad.clases);
        this.gestiones=entidad.clases
      });
    }else{
      let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
      if(this.usuario.empresa.usar_acceso_gestion_escolar){
        if(this.usuario.datos && this.usuario.datos.gestiones){
          this.filter.gestiones=entidad.clases.filter(gt => this.usuario.datos.gestiones.includes(gt.id));
          this.filter.gestion=this.filter.gestiones[0];
          this.gestiones=entidad.clases.filter(gt => this.usuario.datos.gestiones.includes(gt.id));
        }
      }else{
        this.filter.gestiones=this.filter.gestiones.concat(entidad.clases);
        this.gestiones=entidad.clases;
      }
    }
  }

  obtenerSemestresGestion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_SEMESTRES_GESTION).subscribe((entidad:any)=>{
      this.filter.semestres=this.filter.semestres.concat(entidad.clases);
      this.semestres=entidad.clases
    });
  }

  obtenerCarreras(){
    this.carrerasService.obtenerCarreras(this.filter.escuela.id).subscribe((carreras:any)=>{
      this.filter.carreras=this.filter.carreras.concat(carreras);
      this.carreras=carreras;
    });
  }

  obtenerCalculosFechaVencimiento(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_CALCULO_FECHA_VENCIMIENTO_PAGO_INSTITUTO).subscribe((entidad:any)=>{
      this.calculos_fecha_vencimiento=entidad.clases
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
    let promesaLista;
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      promesaLista=this.conceptosIngresosService.obtenerListaConceptosPagos(this);
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      promesaLista=this.conceptosIngresosService.obtenerListaConceptosPagosColegio(this);
    }
		promesaLista.subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.conceptos_ingresos=dato.conceptos_pagos;
			this.blockUI.stop();
		});
	}

  async crearNuevoConceptoIngreso(){
    this.concepto_ingreso={
      cuotas:1,
      obligado:true,
      calculable:false,
      id_escuela:this.filter.escuela.id
    }
    this.abriRegistroConceptoIngreso();
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING);
    this.concepto_ingreso.codigo=codigo_nuevo;
  }

  abriRegistroConceptoIngreso(){
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      this.registro_concepto_ingreso_modal=this.modalService.open(this.registro_concepto_ingreso_modal_ref, {windowClass:'concepto_ingreso', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.registro_concepto_ingreso_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      this.registro_concepto_ingreso_modal = this.modalService.open(RegistroConceptoIngresoComponent, {windowClass:'concepto_ingreso', ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
      this.registro_concepto_ingreso_modal.componentInstance.usuario = this.usuario;
      this.registro_concepto_ingreso_modal.componentInstance.concepto_ingreso = this.concepto_ingreso;
      this.registro_concepto_ingreso_modal.componentInstance.tipo_concepto_pago = this.tipo_concepto_pago;
      this.registro_concepto_ingreso_modal.componentInstance.gestiones = this.gestiones;
      
      this.registro_concepto_ingreso_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          this.getItems();
        }
        this.registro_concepto_ingreso_modal.close();
      });
    }
  }

  async obtenerConceptosPagoEscuela(){
		this.tipo_concepto_pago=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CONCEPTO_PAGOS).toPromise();
	}

  validarConceptoIngreso(){
    let res=true;
    if(!this.concepto_ingreso.carrera){
      res=res && false;
      this.toastr.error("Debe especificar la carrera!");
    }
    if(!this.concepto_ingreso.concepto){
      res=res && false;
      this.toastr.error("Debe especificar el concepto de ingreso!");
    }
    if(!this.concepto_ingreso.importe){
      res=res && false;
      this.toastr.error("Debe especificar el importe!");
    }
    if(!this.concepto_ingreso.cuotas){
      res=res && false;
      this.toastr.error("Debe especificar el número de cuotas!");
    }
    if(this.concepto_ingreso.tipo_fecha_vencimiento && this.concepto_ingreso.tipo_fecha_vencimiento.nombre_corto=='NDC' && !this.concepto_ingreso.pago_clases){
      res=res && false;
      this.toastr.error("Debe especificar el número de clases con la que se calculara la fecha de vencimiento de las cuotas!");
    }
    if(this.concepto_ingreso.tipo_fecha_vencimiento && this.concepto_ingreso.tipo_fecha_vencimiento.nombre_corto=='CCF' && (!this.concepto_ingreso.fecha_inicial_pago_texto || !this.concepto_ingreso.fecha_inicial_pago_texto.year || !this.concepto_ingreso.fecha_inicial_pago_texto.month || !this.concepto_ingreso.fecha_inicial_pago_texto.day)){
      res=res && false;
      this.toastr.error("Debe especificar la fecha inicial de pago con la que se calculara la fecha de vencimiento de las cuotas!");
    }
    return res;
  }

  async guardarConceptoIngreso(){
    this.blockUI.start();
    if(this.validarConceptoIngreso()){
      this.concepto_ingreso.fecha_inicial_pago=this.concepto_ingreso.fecha_inicial_pago_texto?Util.convertirObjetoAfecha(this.concepto_ingreso.fecha_inicial_pago_texto):null;
			if(this.concepto_ingreso.id){
				this.conceptosIngresosService.actualizarConceptoPago(this.concepto_ingreso).subscribe((res:any) => {
					this.blockUI.stop();
          this.getItems();
					this.toastr.success(res.mensaje);
					this.registro_concepto_ingreso_modal.close();
				});
			}else{
        this.concepto_ingreso.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING):this.concepto_ingreso.codigo;
				this.conceptosIngresosService.guardarConceptoPago(this.concepto_ingreso).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING);
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.getItems();
					this.registro_concepto_ingreso_modal.close();
				});
			}
		}else{
			this.blockUI.stop();
		}
  }

  modificarConceptoIngreso(concepto_ingreso){
    this.blockUI.start();
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      this.conceptosIngresosService.obtenerConceptoPago(concepto_ingreso).subscribe((concepto_ingreso_con:any)=>{
        this.concepto_ingreso=concepto_ingreso_con;
        if(this.concepto_ingreso.fecha_inicial_pago){
          this.concepto_ingreso.fecha_inicial_pago=new Date(this.concepto_ingreso.fecha_inicial_pago);
          this.concepto_ingreso.fecha_inicial_pago_texto={
            year:this.concepto_ingreso.fecha_inicial_pago.getFullYear(),
            month:this.concepto_ingreso.fecha_inicial_pago.getMonth()+1,
            day:this.concepto_ingreso.fecha_inicial_pago.getDate()
          }
        }
        this.blockUI.stop();
        this.abriRegistroConceptoIngreso();
      });
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      this.conceptosIngresosService.obtenerConceptoPagoColegio(concepto_ingreso).subscribe((concepto_ingreso_con:any)=>{
        this.concepto_ingreso=concepto_ingreso_con;
        this.blockUI.stop();
        this.abriRegistroConceptoIngreso();
      });
    }
  }

  abrirPopupConfirmacionEliminacion(concepto_ingreso){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el concepto de pago?";
		this.popupConfirmacion.componentInstance.data = concepto_ingreso;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarConceptoPago($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarConceptoPago(concepto_ingreso){
    this.blockUI.start();
    let promesa;
    if(this.usuario.empresa.usar_ingresos_economicos_instituto){
      promesa=this.conceptosIngresosService.eliminarConceptoPago(concepto_ingreso);
    }else if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      promesa=this.conceptosIngresosService.eliminarConceptoPagoColegio(concepto_ingreso);
    }
    promesa.subscribe((res:any)=>{
      this.blockUI.stop();
      this.getItems();
      this.toastr.success(res.mensaje);
    });
  }

  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionConceptosIngresosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

  descargarConceptosBanco(){
    this.blockUI.start();
    let fecha_actual=new Date();
    let linea="";
    for(let i=0;i<this.conceptos_ingresos.length;i++){
      linea=linea+"3652"+",";
      linea=linea+this.conceptos_ingresos[i].codigo+",";
      linea=linea+this.conceptos_ingresos[i].concepto.nombre+",";
      linea=linea+this.conceptos_ingresos[i].importe+",";
      linea=linea+"1"+",";
      linea=linea+",";
      linea=linea+this.conceptos_ingresos[i].cuotas+",";
      linea=linea+"31/12/"+fecha_actual.getFullYear().toString()+",";
      linea=linea+(this.conceptos_ingresos[i].obligado?"SI":"NO")+",";
      linea=linea+"SI"+",";
      linea=linea+"NO"+",";
      linea=linea+"NO"+",";
      linea=linea+"NO"+",";
      linea=linea+"NO"+",";
      linea=linea+(this.conceptos_ingresos[i].cuotas>1?"1":"2")+",";
      linea=linea+"4010716821";
      linea=linea+"\n"
    }
    var file = new Blob([linea.replace(/\n/g, "\r\n")], {type: 'text/plain'});
    let nombre_archivo="conceptos_pagos_banco_"+this.filter.gestion.nombre+".txt"
    fs.saveAs(file, nombre_archivo);
    this.blockUI.stop();
  }

  modificarCursosConceptoIngreso(concepto_ingreso){
    this.blockUI.start();
    this.conceptosIngresosService.obtenerConceptoPagoColegio(concepto_ingreso).subscribe((concepto_ingreso_con:any)=>{
      this.concepto_ingreso=concepto_ingreso_con;
      this.blockUI.stop();
      this.modificacion_cursos = this.modalService.open(ConceptoIngresoCursosComponent, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modificacion_cursos.componentInstance.concepto_pago = concepto_ingreso_con;
      this.modificacion_cursos.componentInstance.usuario = this.usuario;
      this.modificacion_cursos.componentInstance.onConfirm.subscribe(($e) => {
        this.modificacion_cursos.close();
      });
    });
  }

  abrirModalFechaVencimiento(concepto_pago){
    this.concepto_pago=concepto_pago;
    this.asignacion_fecha_vencimiento_modal=this.modalService.open(this.asignacion_fecha_vencimiento_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
    this.asignacion_fecha_vencimiento_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarInicioFechaVencimiento(){
    if(this.validarFechaVencimientoInicio()){
      this.concepto_pago.fecha_inicio_vencimiento=Util.convertirObjetoAfecha(this.concepto_pago.fecha_vencimiento_texto);
      this.blockUI.start();
      this.conceptosIngresosService.actualizarConceptoPagoFechaInicioVencimiento(this.concepto_pago).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.blockUI.stop();
        this.asignacion_fecha_vencimiento_modal.close();
      });
    }
  }

  validarFechaVencimientoInicio(){
    let res=true;
    if(!this.concepto_pago.fecha_vencimiento_texto || !this.concepto_pago.fecha_vencimiento_texto.year || !this.concepto_pago.fecha_vencimiento_texto.month || !this.concepto_pago.fecha_vencimiento_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de vencimiento completa!");
    }
    return res;
  }

}
