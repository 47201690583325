<div class="  row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <span class="text-blue text-125">Texto:</span>
        </div>

        <div class="card-body">
          <div>
            <label for="ciudad">Nombre</label>
            <input [(ngModel)]="clase_texto.nombre" type="text" class="form-control" name="ciudad" placeholder="Cochabamba">
          </div>
        </div>
        <div class="card-footer">
            <div class="col-md-12">
                <button class="btn btn-primary" type="button" (click)="guardarTextoEdicion()">
                    <i class="fa fa-save mr-1"></i>Guardar
                </button>&nbsp;
                <button class="btn btn-danger" type="button" (click)="cerrarVentana(false)">
                    <i class="fa fa-times mr-1"></i>Cancelar
                </button>
            </div>  
        </div>
      </div>
    </div>
</div>