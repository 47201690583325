<div class="modal-header">
    <div class="col-6 col-xs-6">
        <h4 class="widget-title">Registro Recurso</h4>
    </div>
    <div class="col-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="guardarRecurso()">
            <i class="ace-icon fa fa-save red2 align-top text-180"></i>
  </button>&nbsp;
  <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top text-180"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="box box-primary px-2" >
  <div class="box-body">
    <div class="row">
      <div class="col-3">
        <label>Tipo de Recurso</label>
      </div>
      <div class="col-5">
        <label>
            <select [compareWith]="compararObjectos" name="movimiento" class="form-control" [(ngModel)]="recurso.tipo_recurso" (change)="cambiarTipoRecurso()">
                <option [ngValue]="tipo_recurso" *ngFor="let tipo_recurso of tipos_recursos">{{tipo_recurso.nombre}}</option>
            </select>
        </label>
      </div>
      <div class="col-2">
        <label>¿Público?</label>
      </div>
      <div class="col-2">
        <input [disabled]="recurso.tipo_recurso && recurso.tipo_recurso.nombre_corto==global_variable.Dictionary.TIPO_RECURSO_URL" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="recurso.publico"/>
      </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-4">
            <label>Nombre</label>
        </div>
         <div class="col-8">
            <input [(ngModel)]="recurso.nombre" placeholder="Nombre" type="text" class="form-control border-l-2" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Descripción</label>
        </div>
         <div class="col-12">
            <div class="card bcard border-1 brc-dark-l1">
                <div class="card-body p-0">
                  <form method="post">
                    <textarea id="summernote" name="editordata" [(ngModel)]="recurso.descripcion" placeholder="Descripción"></textarea>
                  </form>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="recurso.tipo_recurso && recurso.tipo_recurso.nombre_corto==global_variable.Dictionary.TIPO_RECURSO_ARCHIVO">
      <div class="col-12 col-md-12">
        <label>Adjuntar archivo</label>
      </div>
      <div class="col-12 col-md-12">
        <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
          <div class="fallback d-none">
            <input name="file" type="file" (change)="subirArchivo($event)"/>
          </div>
  
          <div class="dz-default dz-message">
            <span class="text-150  text-grey-d2">
            <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
            para subir
            <span class="text-90 text-grey-m1">(o click)</span>
            <br />
            <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
            </span>
          </div>
        </form>
  
        <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
        <div id="preview-template" class="d-none">
          <div class="dz-preview dz-file-preview">
            <div class="dz-image">
              <img alt="Archivo" data-dz-thumbnail="" />
            </div>
  
            <div class="dz-details">
              <div class="dz-size">
                <span data-dz-size=""></span>
              </div>
  
              <div class="dz-filename">
                <span data-dz-name=""></span>
              </div>
            </div>
  
            <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
              <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
            </div>
  
            <div class="dz-error-message">
              <span data-dz-errormessage=""></span>
            </div>
  
            <div class="dz-success-mark">
              <span class="fa-stack fa-lg text-150">
                <i class="fa fa-circle fa-stack-2x text-white"></i>
                <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
            </span>
            </div>
  
            <div class="dz-error-mark">
              <span class="fa-stack fa-lg text-150">
                <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <hr>
    <div class="row" *ngIf="recurso.tipo_recurso && recurso.tipo_recurso.nombre_corto==global_variable.Dictionary.TIPO_RECURSO_URL">
        <div class="col-4">
            <label>URL</label>
        </div>
         <div class="col-8">
            <input [(ngModel)]="recurso.ruta_url" placeholder="URL" type="text" class="form-control border-l-2" />
        </div>
    </div>
    <hr>
  </div>
</div>
</div>