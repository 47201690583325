import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-configuration',
  templateUrl: './page-configuration.component.html',
  styleUrls: ['./page-configuration.component.css']
})
export class PageConfigurationComponent implements OnInit {
  @Input() page_configuration:any;
  constructor() { }

  ngOnInit(): void {
  }

}
