import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class CierresCajaService {

  constructor(private http: HttpClient) { }

  public obtenerListaCierresCaja(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cierres-caja/empresa/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/usuario/"+paginator.filter.usuario.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerCierreCaja(cierre_caja){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cierres-caja/"+cierre_caja.id,GlobalVariable.httpOptions);
  }

  public obtenerMovimientosProductosCierreCaja(cierre_caja){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cierre-caja/movimiento-productos/"+cierre_caja.id,GlobalVariable.httpOptions);
  }

  public aperturarCierreCaja(cierre_caja){
    return this.http.post(GlobalVariable.API_SERVER_URL+"aperturar-cierre-caja",cierre_caja,GlobalVariable.httpOptions);
  }

  public obtenerVentasContado(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas-contado/"+datos.ids_sucursales+"/inicio/"+datos.inicio+"/fin/"+datos.fin+"/usuario/"+datos.id_usuario+"/cierre-caja/"+datos.cierre_caja.id,GlobalVariable.httpOptions);
  }

  public obtenerPagosVenta(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pagos-venta/"+datos.ids_sucursales+"/inicio/"+datos.inicio+"/fin/"+datos.fin+"/usuario/"+datos.id_usuario+"/cierre-caja/"+datos.cierre_caja.id,GlobalVariable.httpOptions);
  }

  public obtenerPagosCompra(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pagos-compra/"+datos.ids_sucursales+"/inicio/"+datos.inicio+"/fin/"+datos.fin+"/usuario/"+datos.id_usuario+"/cierre-caja/"+datos.cierre_caja.id,GlobalVariable.httpOptions);
  }

  public obtenerComprasContado(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"compras-contado/"+datos.ids_sucursales+"/inicio/"+datos.inicio+"/fin/"+datos.fin+"/usuario/"+datos.id_usuario+"/cierre-caja/"+datos.cierre_caja.id,GlobalVariable.httpOptions);
  }

  public obtenerCierresCajaPendientes(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cierre-caja-pendiente/"+datos.ids_sucursales,GlobalVariable.httpOptions);
  }

  public obtenerVentasNoContado(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"ventas-no-contado/"+datos.ids_sucursales+"/inicio/"+datos.inicio+"/fin/"+datos.fin+"/usuario/"+datos.id_usuario+"/cierre-caja/"+datos.cierre_caja.id,GlobalVariable.httpOptions);
  }

  public cerrarCierreCaja(cierre_caja){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cierres-caja/empresa",cierre_caja,GlobalVariable.httpOptions);
  }

  public guardarArqueoCaja(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"conteo-caja",datos,GlobalVariable.httpOptions);
  }

  public guardarTransaccionCaja(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"registrar-transaccion-caja",datos,GlobalVariable.httpOptions);
  }

  public obtenerTransaccionesCaja(caja){
    return this.http.get(GlobalVariable.API_SERVER_URL+"transacciones-caja/"+caja.id,GlobalVariable.httpOptions);
  }

  public actualizarDestinoCaja(cierre_caja){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cierre-caja-destino/"+cierre_caja.id,cierre_caja,GlobalVariable.httpOptions);
  }
  
}
