<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Cajas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Cajas
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevaCaja()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="cajas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Nombre <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('moneda')">Moneda <span id="moneda" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('saldo')">Saldo <span id="saldo" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('tipo_caja')">Tipo Caja <span id="tipo_caja" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('banco')">Banco <span id="banco" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('numero_cuenta')">Numero Cuenta <span id="numero_cuenta" class="sort fa fa-sort icon-only"></span></a></th>
                <th><a href="javascript:void(0)" (click)="this.sortColumn('activo')">Estado <span id="activo" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #cierres_caja *ngFor="let caja of cajas; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td>
                    {{caja.nombre}}
                </td>
                <td>
                    {{caja.moneda.nombre}}
                </td>
                <td>
                  <span class="m-1 bgc-success-l3 border-1 brc-success-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                      {{caja.saldo.toFixed(2)}}
                  </span>
                </td>
                <td>
                    {{caja.tipo_caja.nombre}}
                </td>
                <td>
                    {{caja.banco?caja.banco.nombre:''}}
                </td>
                <td>
                    {{caja.numero_cuenta}}
                </td>
                <td *ngIf="caja.activo"><span class="badge badge-sm badge-success">ACTIVA</span></td>
                <td *ngIf="!caja.activo"><span class="badge badge-sm badge-danger">ANULADA</span></td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a *ngIf="caja.activo && aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarCaja(caja)">          
                        <i class="fa fa-edit text-105"></i>        
                    </a>
                    <a *ngIf="caja.activo && caja.tipo_caja.nombre_corto=='CJCB' && aplicacion.puede_crear" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="verCheques(caja)">          
                      <i class="fas fa-money-check-alt text-105"></i>        
                    </a>
                    <a *ngIf="caja.activo && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(caja)">
                        <i class="fa fa-trash-alt"></i>
                      </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="cajas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modal_registro_caja let-modal>
    <div class="modal-header card-header">
        <div class="col-8">
            <span class="text-blue text-125">Registro Caja</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="guardarCaja()" >
                <i class="fa fa-save red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-2">
                <label>Nombre</label>
            </div>
            <div class="col-4">
                <input name="nombre" class="form-control" [(ngModel)]="caja.nombre" type="text">
            </div>
            <div class="col-2">
                <label>Saldo</label>
            </div>
            <div class="col-4">
                <input [disabled]="caja.id" class="form-control" [(ngModel)]="caja.saldo" min="0" type="number" step="0.001">
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-2">
                <label>Moneda</label>
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" class="form-control" name="moneda" [(ngModel)]="caja.moneda">
                    <option [ngValue]="moneda" *ngFor="let moneda of monedas">{{moneda.nombre}}</option>
                </select>
            </div>
            <div class="col-2">
                <label>Tipo Caja</label>
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" class="form-control" name="tipo_caja" [(ngModel)]="caja.tipo_caja">
                    <option [ngValue]="tipo_caja" *ngFor="let tipo_caja of tipos_caja">{{tipo_caja.nombre}}</option>
                </select>
            </div>
        </div>
        <ng-container *ngIf="caja.tipo_caja && caja.tipo_caja.nombre_corto==global_variable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA">
            <hr>
            <div class="row">
                <div class="col-2">
                    <label>Banco</label>
                </div>
                <div class="col-4">
                    <select [compareWith]="compararObjectos" class="form-control" name="banco" [(ngModel)]="caja.banco">
                        <option [ngValue]="banco" *ngFor="let banco of bancos">{{banco.nombre}}</option>
                    </select>
                </div>
                <div class="col-2">
                    <label>Número Cuenta</label>
                </div>
                <div class="col-4">
                    <input name="nombre" class="form-control" [(ngModel)]="caja.numero_cuenta" type="text">
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>