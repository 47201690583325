import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { PedidosService } from 'src/app/snapquick/services/pedidos/pedidos.service';
import { BaseComponent } from 'src/app/models/base-component';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';
import { VistaPedidoComponent } from '../vista-pedido/vista-pedido.component';

declare const aplicarPluginCalendario:any;
declare const resaltarPestañaMenu:any;
declare const FullCalendar:any;
declare const $:any;

@Component({
  selector: 'app-calendario-pedidos',
  templateUrl: './calendario-pedidos.component.html',
  styleUrls: ['./calendario-pedidos.component.css']
})
export class CalendarioPedidosComponent extends BaseComponent implements OnInit {

  pedido:any={};
  pedidos_calendario:any[]=[];
  estados:any[]=[];
  calendar:any;
  lista_clases:any[]=['bgc-blue-d1 text-white text-95',
						'bgc-green-d2 text-white text-95',
					'bgc-red-d1 text-white text-95',
					'bgc-purple-d1 text-white text-95',
					'bgc-orange-d1 text-white text-95']
  modalPedido:NgbModalRef;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public pedidosService:PedidosService,
    private toastr: ToastrService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));
    this.configurarCalendario();

    let fecha_actual=new Date();
    
    this.filter={
      gestion_actual:fecha_actual.getFullYear(),
		  mes_actual:fecha_actual.getMonth(),
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      estados_validez:[{nombre:"TODOS",id:-1},{nombre:"ACTIVOS",id:1},{nombre:"ANULADOS",id:0}],
      validez: {nombre:"TODOS",id:-1},
      estados:[{nombre:"TODOS",id:0}],
      estado: {nombre:"TODOS",id:0},
      estados_mesa:[{nombre:"TODOS",id:0}],
      estado_mesa: {nombre:"TODOS",id:0},
      tipos_pago:[GlobalVariable.Dictionary.SELECCION_TODOS],
      tipo_pago: GlobalVariable.Dictionary.SELECCION_TODOS,
      tipos_despacho:[{nombre:"TODOS",id:0}],
      tipo_despacho: {nombre:"TODOS",id:0}
    }
    this.establecerFechas(this.filter.gestion_actual,this.filter.mes_actual);
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS);
    this.obtenerEstadosPedido();
    this.obtenerPedidos();
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    aplicarPluginCalendario();
    this.configurarBotonAnteriorSiguiente();
  }

  obtenerEstadosPedido(){
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
	  this.filter.estados=this.filter.estados.concat(entidad.clases);
	  this.estados=entidad.clases;
    });
  }

  configurarCalendario(){
    let me=this;
    //for some random events to be added
	  var date = new Date();
	  var m = date.getMonth();
	  var y = date.getFullYear();
  
	  var day1 = Math.random() * 20 + 2;
	  var day2 = Math.random() * 25 + 1;
  
	// initialize the calendar
	this.calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
		themeSystem: 'bootstrap',
		locale: 'es',
		events: [],
		selectable: true,
		selectLongPressDelay: 200,
		select: function(date) {
			let hora_actual=new Date();
			me.pedido={
				id_empresa:me.usuario.id_empresa,
				fecha:date.start,
				tipo:me.filter.tipos_eventos.filter(t => t.nombre_corto=="RECGRUP")[0],
				inicio_texto:{hour:hora_actual.getHours(),minute:hora_actual.getMinutes()},
				fin_texto:{hour:hora_actual.getHours()+1,minute:hora_actual.getMinutes()},
				id_usuario:me.usuario.id,
				publico:false
			}
			/*me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
			me.registroevento.result.then((result) => {
				me.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
			});*/
		},
		eventContent: function(arg) { 
			return {
			html: '<div class="row"><div class="col-12"><div class="fc-event-time">'+arg.event.start.getHours()+':'+arg.event.start.getMinutes() +'</div></div></div><div class="row"><div class="col-12"><div class="fc-event-title">'+arg.event.title +'</div></div></div>'
			}
		},
		editable: true,
		droppable: true,
		drop: function(info) {
			/*me.registroevento=me.modalService.open(me.registroeventoRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
			me.registroevento.result.then((result) => {
				me.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
			});*/
		},
		eventClick: function(info) {
      me.modalPedido = me.modalService.open(VistaPedidoComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-pedido', backdrop: 'static'});
      me.modalPedido.componentInstance.pedido = info.event;
      me.modalPedido.componentInstance.usuario = me.usuario;
      me.modalPedido.componentInstance.alTerminar.subscribe((res) => {
        if(res.recargar_calendario){
          me.obtenerPedidos();
        }
        me.modalPedido.close();
      });
		}
	
	});
	this.calendar.render();
  }

  establecerFechas(gestion_actual,mes_actual){
    let fecha_inicio_mes=new Date(gestion_actual,mes_actual,1);
    let fecha_fin_mes=new Date(gestion_actual,mes_actual+1,0);
    this.filter.fecha_inicio={
      year: fecha_inicio_mes.getFullYear(), 
      month: (fecha_inicio_mes.getMonth()+1),
      day:fecha_inicio_mes.getDate()
    };
    this.filter.fecha_fin={
      year: fecha_fin_mes.getFullYear(), 
      month: (fecha_fin_mes.getMonth()+1),
      day:fecha_fin_mes.getDate()
    }
  }

  configurarBotonAnteriorSiguiente(){
	  let me=this;
		$('.fc-prev-button').click(function(){
			if(me.filter.mes_actual==0){
				me.filter.mes_actual=11;
				me.filter.gestion_actual--;
			}
			else{
				me.filter.mes_actual--;
			}		   
      me.establecerFechas(me.filter.gestion_actual,me.filter.mes_actual);
		  me.obtenerPedidos();
		});

		$('.fc-next-button').click(function(){			
			if(me.filter.mes_actual==11){
				me.filter.mes_actual=0;
				me.filter.gestion_actual++;
			}
			else{
				me.filter.mes_actual++;
			}		   
			me.establecerFechas(me.filter.gestion_actual,me.filter.mes_actual);
		  me.obtenerPedidos();
		});
	}

  async obtenerPedidos(){
    this.blockUI.start();
    let eventos_source:any[] = this.calendar.getEvents();
		eventos_source.forEach(event => {
			event.remove();
		});
    this.pedidosService.obtenerListaPedidos(this).subscribe((pedidos:any)=>{
      this.blockUI.stop();
      this.pedidos_calendario=[];
      for(var i=0;i<pedidos.length;i++){
          if(pedidos[i].activo==1){
              let fecha_pedido=new Date(pedidos[i].tiempo_ingreso);
              let pedido_calendario:any={
                  id:pedidos[i].id,
                  title: "No. "+pedidos[i].numero_pedido+ " - "+(pedidos[i].cliente)
              }
              if(pedidos[i].tiempo_despacho){
                  let fecha_despacho=pedidos[i].tiempo_despacho;
                  pedido_calendario.start=fecha_pedido;
                  pedido_calendario.end=fecha_despacho;
              }else{
                  pedido_calendario.start=new Date(fecha_pedido.getFullYear(),fecha_pedido.getMonth(),fecha_pedido.getDate());   
              }
              if(pedidos[i].estado_nombre_corto==GlobalVariable.Dictionary.ESTPED_PEND){
                  pedido_calendario.className='bgc-red-d1 text-white text-95';
              }else if(pedidos[i].estado_nombre_corto==GlobalVariable.Dictionary.ESTPED_ENTGD){
                  pedido_calendario.className='bgc-green-d2 text-white text-95';
              }else if(pedidos[i].estado_nombre_corto==GlobalVariable.Dictionary.ESTPED_PROD){
                  pedido_calendario.className='bgc-blue-d1 text-white text-95';
              }else if(pedidos[i].estado_nombre_corto==GlobalVariable.Dictionary.ESTPED_PCOMP){
                  pedido_calendario.className='bgc-orange-d1 text-white text-95';
              }
              //this.pedidos_calendario.push(pedido_calendario);
              this.calendar.addEvent(pedido_calendario);
          }
      }
      //$('#'+$scope.idCalendario).fullCalendar('addEventSource',$scope.pedidos_calendario);
    });
	}

}
