import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ComprobantesService } from 'src/app/contabilidad/services/comprobantes/comprobantes.service';
import { PlanDeCuentasService } from 'src/app/contabilidad/services/plan-de-cuentas/plan-de-cuentas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { AsignacionCuentaClienteComponent } from '../../asignacion-cuenta-cliente/asignacion-cuenta-cliente.component';
import { RegistroComprobanteComponent } from '../../registro-comprobante/registro-comprobante.component';

@Component({
  selector: 'app-ventas-no-contabilizadas',
  templateUrl: './ventas-no-contabilizadas.component.html',
  styleUrls: ['./ventas-no-contabilizadas.component.css']
})
export class VentasNoContabilizadasComponent extends BaseComponent implements OnInit {

  ventas_no_contabilizadas:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  asignacion_cuenta_cliente_modal:NgbModalRef;
  configuracion_cuentas:any;
  registro_comprobante_modal:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private planDeCuentasService:PlanDeCuentasService,
    private pdfService:PdfService,
    private comprobantesService:ComprobantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
    this.obtenerVentasNoContabilizadas();
    this.obtenerConfiguracionCuentas();
  }

  obtenerVentasNoContabilizadas(){
    this.blockUI.start();
    this.generalService.obtenerVentasNoContabilizadas(this.usuario.id_empresa).subscribe((ventas_no_contabilizadas:any)=>{
      this.blockUI.stop();
      this.ventas_no_contabilizadas=ventas_no_contabilizadas;
    });
  }

  obtenerConfiguracionCuentas(){
    this.planDeCuentasService.obtenerConfiguracionCuentas(this.usuario.id_empresa).subscribe((configuracion_cuentas:any)=>{
      this.configuracion_cuentas={
        cuenta_ventas:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS)[0].cuenta,
        cuenta_cuentas_por_cobrar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR)[0].cuenta,
        cuenta_caja_dinero:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS)[0].cuenta,
        cuenta_iva_debito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF)[0].cuenta,
        cuenta_it_por_pagar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR)[0].cuenta,
        cuenta_impuesto_a_las_transacciones:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT)[0].cuenta,
        cuenta_iva_credito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF)[0].cuenta
      }
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  abrirAsignacionCuentaCliente(cliente){
    this.asignacion_cuenta_cliente_modal = this.modalService.open(AsignacionCuentaClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"md"});
    this.asignacion_cuenta_cliente_modal.componentInstance.cliente = cliente;
    this.asignacion_cuenta_cliente_modal.componentInstance.usuario = this.usuario;
    this.asignacion_cuenta_cliente_modal.componentInstance.cuenta_por_cobrar = this.configuracion_cuentas.cuenta_cuentas_por_cobrar;
    
    this.asignacion_cuenta_cliente_modal.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          //this.establecerCliente(res.cliente);
          this.asignacion_cuenta_cliente_modal.close();
          this.obtenerVentasNoContabilizadas();
        }
      }else{
        this.asignacion_cuenta_cliente_modal.close();
      }
      
    });
  }

  async generarNuevoComprobanteVenta(venta_no_contabilizada){
    let res=true;
    if(this.configuracion_cuentas.cuenta_caja_dinero==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Caja/Bancos!");
    }
    if(this.configuracion_cuentas.cuenta_cuentas_por_cobrar==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Cuentas Por Cobrar!");
    }
    if(this.configuracion_cuentas.cuenta_impuesto_a_las_transacciones==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Impuesto a las transacciones!");
    }
    if(this.configuracion_cuentas.cuenta_ventas==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Ventas!");
    }
    if(this.configuracion_cuentas.cuenta_iva_debito_fiscal==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Iva Debito Fiscal!");
    }
    if(this.configuracion_cuentas.cuenta_it_por_pagar==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de I.T. por pagar!");
    }
    if(res){
      this.blockUI.start();
      let fuente_comprobante:any=await this.generalService.obtenerClases("TIPFUENTECOMP").toPromise();
      let texto_documento="Nota de Venta";
      let comprobante:any={
        sucursal:venta_no_contabilizada.sucursal,
        usuario:this.usuario,
        importe:venta_no_contabilizada.total,
        fecha:venta_no_contabilizada.fecha,
        asientos:[],
        fuente:fuente_comprobante.clases.filter(e => e.nombre_corto == GlobalVariable.Dictionary.TIPO_FUENTE_COMPROBANTE_SEMIAUTOMATICA_VENTA)[0],
        datos_fuente:venta_no_contabilizada.id+"",
        abierto:false,
        eliminado:false,
        opcion_bimonetario:false,
        venta:venta_no_contabilizada
      }
      this.blockUI.stop();
      if(venta_no_contabilizada.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION ||
        venta_no_contabilizada.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA ||
        venta_no_contabilizada.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION_MANUAL){
        texto_documento="Factura"
        if(venta_no_contabilizada.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE){
          let importe=0;
          if(venta_no_contabilizada.a_cuenta>0){
            importe=venta_no_contabilizada.a_cuenta;
            comprobante.asientos.push({ tipo:{},glosa: "",cuenta:this.configuracion_cuentas.cuenta_caja_dinero, debe_bs:importe, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
          }
          if(venta_no_contabilizada.saldo>0){
            let cuenta_cliente:any=await this.planDeCuentasService.obtenerCuentaAuxiliarCliente(venta_no_contabilizada.cliente.id).toPromise();
            this.configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_auxiliar=cuenta_cliente;
            comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_cuentas_por_cobrar, debe_bs:venta_no_contabilizada.saldo, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
          }
        }else if(venta_no_contabilizada.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT){
          comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_caja_dinero, debe_bs:venta_no_contabilizada.total, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
        }
        comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_impuesto_a_las_transacciones, debe_bs:(venta_no_contabilizada.total*0.03), haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
        comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_ventas, debe_bs:0, haber_bs:venta_no_contabilizada.total*0.87, debe_sus:0, haber_sus:0, eliminado:false });
        comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_iva_debito_fiscal, debe_bs:0, haber_bs:(venta_no_contabilizada.total*0.13), debe_sus:0, haber_sus:0, eliminado:false });
        comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_it_por_pagar, debe_bs:0, haber_bs:(venta_no_contabilizada.total*0.03), debe_sus:0, haber_sus:0, eliminado:false });
        comprobante.importe=venta_no_contabilizada.total+(venta_no_contabilizada.total*0.03);
      }else{
        if(venta_no_contabilizada.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE){
          let importe=0;
          if(venta_no_contabilizada.a_cuenta>0){
            importe=venta_no_contabilizada.a_cuenta;
            comprobante.asientos.push({ tipo:{},glosa: "",cuenta:this.configuracion_cuentas.cuenta_caja_dinero, debe_bs:importe, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
          }
          if(venta_no_contabilizada.saldo>0){
            let cuenta_cliente:any=await this.planDeCuentasService.obtenerCuentaAuxiliarCliente(venta_no_contabilizada.cliente.id).toPromise();
            this.configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_auxiliar=cuenta_cliente;
            comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_cuentas_por_cobrar, debe_bs:venta_no_contabilizada.saldo, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
          }
        }else if(venta_no_contabilizada.tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT){
          comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_caja_dinero, debe_bs:venta_no_contabilizada.total, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
        }
        comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_ventas, debe_bs:0, haber_bs:venta_no_contabilizada.total, debe_sus:0, haber_sus:0, eliminado:false });
      }
      comprobante.glosa="Por venta con "+texto_documento+" Nº "+venta_no_contabilizada.factura+", cliente "+venta_no_contabilizada.cliente.razon_social;

      this.registro_comprobante_modal = this.modalService.open(RegistroComprobanteComponent, {windowClass:"comprobante", ariaLabelledBy: 'modal-basic-title',scrollable:true, backdrop: 'static',size:"lg"});
      this.registro_comprobante_modal.componentInstance.usuario = this.usuario;
      this.registro_comprobante_modal.componentInstance.comprobante = comprobante;
      
      this.registro_comprobante_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            //this.establecerCliente(res.cliente);
            this.registro_comprobante_modal.close();
            this.comprobantesService.obtenerComprobante(res.comprobante.id).subscribe((datos_comprobante:any)=>{
              this.pdfService.imprimirComprobanteContabilidad(datos_comprobante.comprobante,false,this.usuario);
              this.obtenerVentasNoContabilizadas();
            });  
          }
        }else{
          this.registro_comprobante_modal.close();
        }
        
      });
    }
  }

  abrirPopupConfirmacionEliminacion(venta_no_contabilizada){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.message = "¿Esta seguro de dejar de contabilizar la venta?";
    this.popupConfirmacion.componentInstance.data = venta_no_contabilizada;
    
    this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
      if($e.confirm){
        this.dejarContabilizarVenta($e.data);
      }
      this.popupConfirmacion.close();
        });
  }
  
  dejarContabilizarVenta(venta_no_contabilizada){
    this.blockUI.start();
    this.planDeCuentasService.contabilizarVenta({id_venta:venta_no_contabilizada.id,id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.obtenerVentasNoContabilizadas();
      this.blockUI.stop();
    });
  }

}
