<div class="page-content container">
    <div class="page-header">
      <div class="col-8">
        <h1 class="page-title text-primary-d2">
          Afiliados
          <small class="page-info text-secondary-d2">
            <i class="fa fa-angle-double-right text-80"></i>
            Lista
          </small>
        </h1>
      </div>
      <div class="col-4" *ngIf="usuario.empresa.usar_consumo_de_agua">
        <div class="row">
          <div class="col-3">Reporte Deudores:</div>
          <div class="col-8">
            <label>Gestión</label>
            <select class="form-control" name="periodo" [(ngModel)]="filtro_reporte_redudores.gestion">
              <option [ngValue]="periodo" *ngFor="let periodo of filtro_reporte_redudores.gestiones">{{periodo.nombre}}</option>
            </select>
          </div> 
          <div class="col-1"><a (click)="generarPdfDeudores()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a></div>
        </div>
      </div>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Vecinos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button title="Nuevo" (click)="crearNuevoVecino()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
                <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				</button>
				<button (click)="clickBotonSubir('subir-excel-vecinos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-vecinos" style="display:none" type="file" class="form-control" (change)="subirExcelVecinos($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
        <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
          <i class="fa fa-wrench text-blue-d1"></i>
          <span class="caret"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
          <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
            <div class="card bgc-primary brc-primary radius-0">
              <div class="card-header">
              <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
              </div>
    
              <div class="card-body p-0 bg-white">
              <table class="table table-striped table-hover mb-0">
                <tbody>
                  <tr *ngIf="aplicacion.puede_modificar">
                    <td class="text-dark-m2">
                    Configuración Aplicación
                    </td>
                    <td class="text-dark-m2">
                      <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="aplicacion.puede_modificar">
                    <td class="text-dark-m2">
                    Impresión credenciales
                    </td>
                    <td class="text-dark-m2">
                      <a (click)="generarTarjetaIdentificacion(selectedItems)" href="javascript:void(0)" class="text-warning"><i class="fas fa-id-card text-warning"></i></a>
                    </td>
                  </tr>
                  <tr *ngIf="aplicacion.puede_modificar">
                    <td class="text-dark-m2">
                    Impresión cuotas semanales
                    </td>
                    <td class="text-dark-m2">
                      <a (click)="imprimirBoletaSemanal(selectedItems)" href="javascript:void(0)" class="text-danger"><i class="fas fa-file-pdf text-danger"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            </div>
        </div>
                <!--<div class="dropdown-menu dropdown-menu-right" style="">
                  <a class="dropdown-item " href="#" data-type="json">JSON</a>
                  <a class="dropdown-item " href="#" data-type="xml">XML</a>
                  <a class="dropdown-item " href="#" data-type="csv">CSV</a>
                  <a class="dropdown-item " href="#" data-type="txt">TXT</a>
                  <a class="dropdown-item " href="#" data-type="sql">SQL</a>
                  <a class="dropdown-item " href="#" data-type="excel">MS-Excel</a>
                </div>-->
        </div>
			  <div class="keep-open btn-group show" title="Columns">
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="vecinos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="text-center pr-0">
                  <label class="py-0">
                    <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(vecinos)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                  </label>
                </th>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th id="cabecera_codigo" class="center" *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="apellido_paterno" class="center" *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_paterno' && this.direction=='asc','desc':this.column=='apellido_paterno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('apellido_paterno')">Apellido Paterno</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="apellido_materno" class="center" *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_materno' && this.direction=='asc','desc':this.column=='apellido_materno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('apellido_materno')">Apellido Materno</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().nombres.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombres' && this.direction=='asc','desc':this.column=='nombres' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Nombres</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="th-inner ">
                        Imagen
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().ci.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='ci' && this.direction=='asc','desc':this.column=='ci' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('ci')">C.I.</a>
									</div><div class="fht-cell"></div>
								</th>
                <th *ngIf="configuracion_pagina.getConfiguration().telefono_movil.show">
                    <div class="th-inner ">
                        Teléfono Móvil
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().categoria.show">
                  <div class="th-inner ">
                      Categoría
                  </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #productos *ngFor="let vecino of vecinos; let i=index;">
                <td class='text-center pr-0 pos-rel'>
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                    <!-- border shown on hover -->
                  </div>
                  <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1">
                    <!-- border shown when row is selected -->
                  </div>

                  <label>
                    <input [(ngModel)]="vecino.selected" (change)="addItemToSelected(vecino)" type="checkbox" class="align-middle" />
                  </label>
                </td>
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{vecino.cliente.codigo}}
                </td>
								<td *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show">
									{{vecino.cliente.persona.apellido_paterno}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show">
									{{vecino.cliente.persona.apellido_materno}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombres.show">
									{{vecino.cliente.persona.nombres}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
									<a href="#" class="show-lightbox">
										<img alt="{{vecino.cliente.persona.nombres}}" src="{{rest_server+vecino.cliente.persona.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
									</a>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().ci.show">
									{{vecino.cliente.persona.ci}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().telefono_movil.show">
									{{vecino.cliente.persona.telefono_movil}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().categoria.show">
									{{vecino.categoria?vecino.categoria.nombre:''}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">                      
                    <a *ngIf="usuario.empresa.usar_consumo_de_agua" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarLecturacion(vecino)">          
                      <i class="fa fa-faucet text-105"></i>        
                    </a>        
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="generarTarjetaIdentificacion([vecino])">          
                      <i class="fas fa-id-card text-105"></i>        
                    </a>    
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarVecino(vecino)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
                    </a>        
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(producto)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="vecinos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>
<canvas id="qr-code" style="display:none" width="100" height="100"></canvas>
<ng-template #importacionvecinos let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Vecinos a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || vecinos_importacion.length==0" (click)="guardarVecinos()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary">
            <div class="box-body">
                <div class="alert alert-danger" *ngIf="mensaje_importacion">
                    <strong>
                        <i class="ace-icon fa fa-times"></i>
                        Oh no!
                    </strong>
                    {{mensaje_importacion}}.
                    <br>
                </div>
                <div class="table-fixed-header">
                    <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                        <thead fix-head class="table-head-color sticky-nav">
                            <tr>
                                <th>Nº</th>
                                <th>Código</th>
                                <th>Apellido Paterno</th>
                                <th>Apellido Materno</th>
                                <th>Nombres</th>
                                <th>C.I.</th>
                                <th>Teléfono Fijo</th>
                                <th>Teléfono Móvil</th>
                                <th>Zona</th>
                                <th>Avenida</th>
                                <th>Número</th>
                                <th>Nit/C.I.</th>
                                <th>Razón Social</th>
                                <th>Dir. Acción</th>
                                <th>Código Medidor</th>
                                <th>Categoria</th>
                                <th>¿Es mantenimiento?</th>
                                <th>Observación</th>
                                <th>Latitud</th>
                                <th>Longitud</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr *ngFor="let vecino of vecinos_importacion; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{vecino.cliente.codigo}}</td>
                                <td>{{vecino.cliente.persona.apellido_paterno}}</td>
                                <td>{{vecino.cliente.persona.apellido_materno}}</td>
                                <td>{{vecino.cliente.persona.nombres}}</td>
                                <td>{{vecino.cliente.persona.ci}}</td>
                                <td>{{vecino.cliente.persona.telefono_fijo}}</td>
                                <td>{{vecino.cliente.persona.telefono_movil}}</td>
                                <td>{{vecino.cliente.persona.direccion_zona}}</td>
                                <td>{{vecino.cliente.persona.direccion}}</td>
                                <td>{{vecino.cliente.persona.direccion_numero}}</td>
                                <td>{{vecino.cliente.nit_ci}}</td>
                                <td>{{vecino.cliente.razon_social}}</td>
                                <td>{{vecino.accion_agua.direccion}}</td>
                                <td>{{vecino.accion_agua.codigo_medidor}}</td>
                                <td>{{vecino.accion_agua.categoria}}</td>
                                <td>{{vecino.accion_agua.es_mantenimiento?"SI":"NO"}}</td>
                                <td>{{vecino.accion_agua.observacion}}</td>
                                <td>{{vecino.accion_agua.latitud}}</td>
                                <td>{{vecino.accion_agua.longitud}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
	</div>
</ng-template>

<ng-template #edicionvecino let-modal>
  <div class="row">
  <div class="col-12">
    <div class="card">
    <div class="card-header">
      <span class="text-blue text-125">Registro Afiliado</span>

      <div class="card-toolbar ml-auto no-border pr-2 d-none">
      <label>
        <span class="align-middle d-block d-sm-inline">Validation:</span>
        <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
      </label>
      </div>
      <div class="card-toolbar pl-3">
      <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
        <i class="fa fa-chevron-left"></i>
      </button>
      <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
        <i class="fa fa-chevron-right"></i>
      </button>
      <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
        <i class="fa fa-arrow-right"></i>
      </button>
      <button (click)="cerrarRegistroVecino()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
        <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>

    <div class="card-body px-2">
      <div id="{{wizard_edicion_vecino}}" class="d-none">
      <ul class="mx-auto">
        <li class="wizard-progressbar"></li>
        <li>
        <a href="#step-1">
          <span class="step-title">
            1
          </span>

          <span class="step-title-done">
            <i class="fa fa-check text-success-m1"></i>
          </span>
        </a>
        <span class="step-description">
            Datos del Vecino
        </span>
        </li>
        <li>
        <a href="#step-2">
          <span class="step-title">
            2
          </span>

          <span class="step-title-done">
            <i class="fa fa-check text-success-m1"></i>
          </span>
        </a>
        <span class="step-description">
            Datos Adicionales
        </span>
        </li>
      </ul>

      <div class="px-2 py-2 mb-4">
        <div id="step-1" class="">

        <form novalidate>
          <div class="  form-row">
            <div class="col-sm-4 col-form-label text-sm-right text-grey">
                Código 
            </div>
            <div class="col-sm-8">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input [disabled]="codigo_automatico" required type="text" name="codigo" id="codigo" [(ngModel)]="vecino.cliente.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
              </div>
            </div>
            <div class="col-sm-2 col-form-label text-sm-right">
              Apellido Paterno
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 px-0">
                <input type="text" id="apellido_paterno" name="apellido_paterno" [(ngModel)]="vecino.cliente.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-2 col-form-label text-sm-right">
              Apellido Materno
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 px-0">
                <input type="text" id="apellido_materno" name="apellido_materno" [(ngModel)]="vecino.cliente.persona.apellido_materno" placeholder="Apellido Materno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div [ngClass]="{'text-danger-m1':(!vecino.cliente.persona.nombres)}" class="col-sm-2 col-form-label text-sm-right">
              Nombres
            </div>
            <div class="col-sm-8">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <input required type="text" id="nombre" name="nombre" [(ngModel)]="vecino.cliente.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!vecino.cliente.persona.nombres)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                <i *ngIf="!vecino.cliente.persona.nombres" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
              </div>

              <div *ngIf="!vecino.cliente.persona.nombres" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                <div class="arrow brc-danger-m1 mt-0"></div>
                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
              </div>
              <span *ngIf="!vecino.cliente.persona.nombres" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
            </div>
          </div>

          <div class="  form-row">
            <div class="col-5 col-form-label text-sm-left">
              Foto
              <div class="row">
                <div class="col-12" *ngIf="vecino.id && !camara_capturada">
                  <img src="{{rest_server+vecino.cliente.persona.imagen}}" width="100">
                </div>
                <div class="col-12" *ngIf="!vecino.id || camara_capturada">
                  <img src="{{vecino.cliente.persona.imagen}}" width="100">
                </div>
                <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                  <div>
                      <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7">
              <webcam width="200" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
              <a (click)="capturarImagen()" class="btn radius-1 btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                  <i class="fa fa-camera bigger-110 red2"></i>
              </a>
            </div>
          </div>

          <div class="  form-row">
            <div class="col-sm-2 col-form-label text-sm-right">
              C.I.
            </div>
            <div class="col-sm-10">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="ci" name="ci" [(ngModel)]="vecino.cliente.persona.ci" placeholder="C.I." class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-2 col-form-label text-sm-right">
              Teléfono Fijo
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="telefono_fijo" name="telefono_fijo" [(ngModel)]="vecino.cliente.persona.telefono_fijo" placeholder="Teléfono Fijo" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-2 col-form-label text-sm-right">
              Teléfono Móvil
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="telefono_movil" name="telefono_movil" [(ngModel)]="vecino.cliente.persona.telefono_movil" placeholder="Teléfono Móvil" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>
          </div>
        </form>

        </div>

        <div id="step-2">
          <div class="form-row">
            <div class="col-3 col-form-label text-sm-right">
              Categoria
            </div>
            <div class="col-9">
                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                    <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="vecino.categoria">
                        <option [ngValue]="grupoProducto" *ngFor="let grupoProducto of tipo_categoria.clases">{{grupoProducto.nombre}}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="  form-row">
            <div class="col-sm-3 col-form-label text-sm-right">
              Dirección Avenida/Calle
            </div>
            <div class="col-sm-9">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="direccion" name="direccion" [(ngModel)]="vecino.cliente.persona.direccion" placeholder="Avenida/Calle" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-2 col-form-label text-sm-right">
              Dirección Zona
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="zona" name="zona" [(ngModel)]="vecino.cliente.persona.direccion_zona" placeholder="Zona" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-2 col-form-label text-sm-right">
              Dirección Número
            </div>
            <div class="col-sm-4">
              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                <input type="text" id="direccion_numero" name="direccion_numero" [(ngModel)]="vecino.cliente.persona.direccion_numero" placeholder="Número" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>
            <div class="col-sm-3 col-form-label text-sm-right">
              N.I.T/C.I. para el recibo/factura
            </div>
            <div class="col-sm-9">
              <div class="d-inline-flex align-items-center col-12 px-0">
                <input type="text" id="nit" name="nit" [(ngModel)]="vecino.cliente.nit" placeholder="Nit/C.i." class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>

            <div class="col-sm-3 col-form-label text-sm-right">
              Razón Social para el recibo/factura
            </div>
            <div class="col-sm-9">
              <div class="d-inline-flex align-items-center col-12 px-0">
                <input type="text" id="razon_social" name="razon_social" [(ngModel)]="vecino.cliente.razon_social" placeholder="Razón Social" class="form-control form-control-lg col-12 pr-45 pl-25"  />
              </div>
            </div>
          </div>
          <div class="  form-row">
            <div class="col-12 col-form-label text-sm-center text-grey" *ngIf="usuario.empresa.usar_consumo_de_agua">
              <div class="card bgc-primary brc-primary radius-0">
                <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> 
                    Acciones de Agua  
                    <a (click)="crearNuevaAccionAgua()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary text-white" href="javascript:void(0)" >
                      <i class="ace-icon fa fa-plus bigger-130"></i>
                    </a>
                  </h5>
                </div>
      
                <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>Nº</th>
                        <th>Codigo Medidor</th>
                        <th>Categoria</th>
                        <th>Estado</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let accion_agua of vecino.acciones_agua; let i=index;">
                        <tr>
                          <td>
                            {{i+1}}
                          </td>
                          <td>
                            {{accion_agua.codigo_medidor}}
                          </td>
                          <td>
                            {{accion_agua.categoria.nombre}}
                          </td>
                          <td *ngIf="accion_agua.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                          <td *ngIf="!accion_agua.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                          <td>
                            <a *ngIf="accion_agua.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(accion_agua)">
                              <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                            </a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>

    </div>
    </div> <!-- .card -->

  </div>
  </div>
</ng-template>

<ng-template #modificacion_accion_agua let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Acción Agua</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="agregarAccionAgua()" class="btn btn-primary"  >
            <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código Medidor</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input type="text" id="codigo" name="codigo" [(ngModel)]="accion_agua.codigo_medidor" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Categoria</label>
        </div>
        <div class="col-sm-12 col-md-9">
          <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="accion_agua.categoria">
            <option [ngValue]="categoria" *ngFor="let categoria of tipo_accion_agua.clases">{{categoria.nombre}}</option>
          </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Dirección</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="direccion" name="direccion" [(ngModel)]="accion_agua.direccion" placeholder="direccion" class="form-control"/>
        </div>
    </div>
</div>
</ng-template>