<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Inventarios
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Inventarios
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
                        <div class="col-1"><label>Sucursal </label></div>
                        <div class="col-2">
                            <select [compareWith]="compararObjectos" (change)="obtenerAlmacenesSucursal(filter.sucursal)" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-1"><label>Almacen </label></div>
                        <div class="col-2">
                            <select [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="filter.almacen">
                                <option [ngValue]="almacen" *ngFor="let almacen of filter.almacenes">{{almacen.nombre}}</option>
                            </select>
                        </div>
					  <div class="col-1"><label>Grupo </label></div>
					  <div class="col-2">
						  <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="filter.grupo">
							  <option [ngValue]="grupo" *ngFor="let grupo of filter.grupos_filtro">{{grupo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-1"><label>Sub Grupo </label></div>
					  <div class="col-2">
						  <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo" [(ngModel)]="filter.subgrupo">
							  <option [ngValue]="subgrupo" *ngFor="let subgrupo of filter.subgrupos_filtro">{{subgrupo.nombre}}</option>
						  </select>
					  </div>
					</div>
          <div class="row">
            <div class="col-1"><label>Código </label></div>
            <div class="col-2">
              <input [(ngModel)]="filter.codigo" type="text" class="form-control" />
            </div>
            


      </div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			        </button>
              <div class="export btn-group">
                <button (click)="bajarExcelInventarios()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
			      	  </button>
                <button (click)="clickBotonSubir('subir-excel-inventarios')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                  <i class="fa fa-upload text-red fileUpload-button"></i>
                  <input id="subir-excel-inventarios" style="display:none" type="file" class="form-control" (change)="subirExcelInventarios($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </button>
                <button *ngIf="usuario.empresa.usar_inventario_flexible" (click)="abrirPopupConfirmacionReinicializacion()" class="btn btn-outline-purple btn-smd bgc-white btn-h-light-purple btn-a-outline-purple py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Reiniciar">
                  <i class="fa fa fa-sync"></i>
                </button>
              </div>
              <div class="columns columns-right btn-group float-right">
                <div class="keep-open btn-group show" title="Columns">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr>
                              <td class="text-dark-m2">
                              Configuración Vista
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-dark-m2">
                                Descarga Inventario
                              </td>
                              <td class="text-dark-m2">
                                <a [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_excel" (click)="generarExcelFiltroInventarios()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                              </td>
                              <td>
                                <a [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_pdf" (click)="generarPdfFiltroInventarios()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-dark-m2">
                                Kardex Inventario
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirModalKardexInventario()" href="javascript:void(0)" class="text-primary"><i class="fas fa-clipboard-list text-primary"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="keep-open btn-group show" title="Columns">
                <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>

          <table *ngIf="productos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="tabla-inventarios">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th>
                        Nº
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().producto.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Producto</a>
                      </div><div class="fht-cell"></div>
                    <th *ngIf="configuracion_pagina.getConfiguration().unidad_medida.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='unidad_medida' && this.direction=='asc','desc':this.column=='unidad_medida' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('unidad_medida')">Unidad de Medida</a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='descripcion' && this.direction=='asc','desc':this.column=='descripcion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">Descripción</a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().cantidad.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='cantidad' && this.direction=='asc','desc':this.column=='cantidad' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('cantidad')">Cantidad Total</a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='precio_unitario' && this.direction=='asc','desc':this.column=='precio_unitario' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('precio_unitario')">Precio Unitario </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().grupo.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='grupo' && this.direction=='asc','desc':this.column=='grupo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('grupo')">Grupo </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().sub_grupo.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='subgrupo' && this.direction=='asc','desc':this.column=='subgrupo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('subgrupo')">Sub Grupo  </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().codigo_fabrica.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo_fabrica' && this.direction=='asc','desc':this.column=='codigo_fabrica' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo_fabrica')">Código Fabrica  </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='observacion' && this.direction=='asc','desc':this.column=='observacion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('observacion')">Observacion  </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().fecha_vencimiento.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='fecha_vencimiento' && this.direction=='asc','desc':this.column=='fecha_vencimiento' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('fecha_vencimiento')">Fecha  Vencimiento  </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th *ngIf="usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().lote.show">
                      <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='lote' && this.direction=='asc','desc':this.column=='lote' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('lote')">Lote  </a>
                      </div><div class="fht-cell"></div>
                    </th>
                    <th>Acciones</th>
                </tr>
            </thead>
        
            <tbody>
                <tr *ngFor="let producto of productos;let i=index;">
                    <td class="center">
                        {{i+1}}
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">{{producto.codigo}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().producto.show">{{producto.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().unidad_medida.show">{{producto.unidad_medida}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().descripcion.show">{{producto.descripcion}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().cantidad.show">
                        <div class="c100 p{{producto.porcentaje}} small {{producto.color}}">
                            <span style="color:black !important;">{{producto.cantidad}}</span>
                            <div class="slice">
                                <div class="bar"></div>
                                <div class="fill"></div>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">{{producto.precio_unitario?producto.precio_unitario.toFixed(2):''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().grupo.show">{{producto.grupo}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().sub_grupo.show">{{producto.subgrupo}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().codigo_fabrica.show">{{producto.codigo_fabrica}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().observacion.show">{{producto.observacion}}</td>
                    <td *ngIf="usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().fecha_vencimiento.show">{{producto.fecha_vencimiento | date:"dd/MM/yyyy"}}</td>
                    <td *ngIf="usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().lote.show">{{producto.lote}}</td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a title="Transferir cantidades" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="transferirCantidadesProducto(producto)">
                                <i class="fa fa-exchange-alt"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verDetalleInventario(producto)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupActualizacionObservacion(producto)">
                                <i class="ace-icon fa fa-eye bigger-130"></i>
                            </a>
                        </div>
                        <table id="{{producto.id}}" style="display:none;" class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Cantidad</th>
                                    <th *ngIf="producto.activar_vencimiento">Fecha Venc.</th>
                                    <th *ngIf="producto.activar_vencimiento">Lote</th>
                                    <th>Costo Unitario</th>
                                    <th>Costo Total</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let inventario of producto.inventarios;let j=index;">
                                    <td>{{j+1}}</td>
                                    <td>{{inventario.cantidad}}</td>
                                    <td *ngIf="producto.activar_vencimiento">{{inventario.fecha_vencimiento | date:"dd/MM/yyyy	"}}</td>
                                    <td *ngIf="producto.activar_vencimiento">{{inventario.lote}}</td>
                                    <td>{{inventario.costo_unitario}}</td>
                                    <td>{{inventario.costo_total}}</td>
                                    <td>
                                      <a *ngIf="usuario.empresa.usar_inventario_flexible && aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupActualizacionInventario(producto,inventario)">
                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                      </a>
                                      <a *ngIf="producto.activar_vencimiento && aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirPopupActualizacionFechaLote(producto,inventario)">
                                        <i class="ace-icon fa fa-edit bigger-130"></i>
                                      </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Total Almacen</b></td>
                                    <td>{{sumarCantidadAlmacen(producto.inventarios,sucursal,almacen)}}</td>
                                    <td *ngIf="producto.activar_vencimiento"></td>
                                    <td *ngIf="producto.activar_vencimiento"></td>
                                    <td></td>
                                    <td>{{sumarCostoTotalAlmacen(producto.inventarios,sucursal,almacen)}}</td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="productos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #actualizacionobservacion let-modal>
    <div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Actualizacion observacion</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="!producto.observacion" (click)="guardarInventarioObservacion()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="row">
            <div class="col-6">
                <label for="form-field-select-1">Producto: {{producto.nombre}}</label>
            </div>
            <div class="col-6">
                <label for="form-field-select-1">Observacion</label>
                <input [(ngModel)]="producto.observacion" type="text" class="form-control" />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #importacioninventarios let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Lista de productos con inventario a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || detalles_vista_previa.length==0" (click)="guardarInventario()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
          </div>
          <div class="widget-body">
            <label>SUCURSAL : {{filter.sucursal.nombre}}</label>&nbsp;
            <label>ALMACEN : {{filter.almacen.nombre}}</label>
          </div>
					<div class="table-fixed-header">
						<table id="tabla-compras" class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Código</th>
                  <th>Producto</th>
                  <th>Unidad de Medida</th>
                  <th>Costo Unitario</th>
                  <th>Cantidad</th>
                  <th *ngIf="usuario.empresa.usar_vencimientos">Fecha Vencimiento</th>
                  <th *ngIf="usuario.empresa.usar_vencimientos">Lote</th>
                </tr>
              </thead>
    
              <tbody>
                <tr *ngFor="let inventario of detalles_vista_previa; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{inventario.producto.codigo}}</td>
                  <td>{{inventario.producto.nombre}}</td>
                  <td>{{inventario.producto.unidad_medida}}</td>
                  <td>{{inventario.costo_unitario}}</td>
                  <td>{{inventario.cantidad}}</td>
                  <td *ngIf="usuario.empresa.usar_vencimientos">{{inventario.fecha_vencimiento_texto}}</td>
                  <td *ngIf="usuario.empresa.usar_vencimientos">{{inventario.lote}}</td>
                </tr>
              </tbody>
            </table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #modificacion_cantidad let-modal>
  <div class="modal-header">
  <div class="col-md-6 col-xs-6">
    <h4 class="widget-title">Ingrese la nueva cantidad</h4>
  </div>
  <div class="col-md-6 col-xs-6">
    <button class="btn btn-primary" type="button" [disabled]="!inventario.cantidad" (click)="modificarCantidadInventario()">
      <i class="fa fa-save align-top bigger-110"></i>
    </button>&nbsp;
    <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
      <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="row">
          <div class="col-6">
              <label for="form-field-select-1">Producto: {{producto.nombre}}</label>
          </div>
          <div class="col-6">
              <label for="form-field-select-1">Cantidad</label>
              <input [(ngModel)]="inventario.cantidad" min="0" type="number" step="0.001" class="form-control" />
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modificacion_fecha_lote let-modal>
  <div class="modal-header">
    <div class="col-8">
      <h4 class="widget-title">Fecha de vencimiento y Lote</h4>
    </div>
    <div class="col-4">
      <button class="btn btn-primary" type="button" [disabled]="!inventario.fecha_vencimiento_texto" (click)="modificarFechaLoteInventario()">
        <i class="fa fa-save align-top bigger-110"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
        <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-5">
            <label for="form-field-select-1">{{producto.nombre}}</label>
        </div>
        <div class="col-4">
            <label for="form-field-select-1">Fecha de Vencimiento</label>

            <div class="input-group">
              <input name="fecha_ven" class="form-control" placeholder="dd/mm/yyyy"
                      name="dp" [(ngModel)]="inventario.fecha_vencimiento_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div> 
        </div>
        <div class="col-3">
          <label for="form-field-select-1">Lote</label>
          <input [(ngModel)]="inventario.lote" type="text" class="form-control" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #kardex_inventario let-modal>
  <div class="modal-header">
    <div class="col-10 col-xs-2">
        <h4 class="widget-title">Kardex de Inventario</h4>
    </div>
    <div class="col-10 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="box box-primary px-2">
        <div class="box-body">
            <div class="row">
                <div class="col-2 col-sm-2">
                    <label>Sucursal </label> 
                      <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal" (change)="obtenerAlmacenes()">
                          <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-2 col-sm-2">
                    <label>Almacen </label>&nbsp;
                      <select (change)="cambiarAlmacen()" [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="filter.almacen">
                          <option [ngValue]="almacen" *ngFor="let almacen of filter.almacenes">{{almacen.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-3 col-sm-3">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>  
                <div class="col-1 col-sm-1">
                    <button [disabled]="!filter.almacen" (click)="obtenerKardexInventario()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                        <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>
                </div>
                <div class="col-1 col-sm-1">
                    <button (click)="abrirPopupConfirmacionActualizacionInventarios()" [disabled]="kardex_productos.length==0" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Sync">
                        <i class="d-block h-4 fa fa fa-sync text-150"></i>
                    </button>
                </div>
                <!--<div class="col-1 col-sm-1">
                    <button [disabled]="kardex_productos.length==0" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Excel">
                        <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                    </button>
                </div>-->
            </div>
            <hr>
            <h4 class="widget-title">Productos</h4>
            <div class="table-fixed-header">
                <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                    <thead fix-head class="table-head-color sticky-nav">
                        <tr>
                            <th>Nº</th>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Unid. Med.</th>
                            <th>Tipo Producto</th>
                            <th>Grupo</th>
                            <th>Subgrupo</th>
                            <th>Ingresos</th>
                            <th>Salidas</th>
                            <th>Saldo</th>
                            <th data-field="tools">
                              <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                              <div class="fht-cell"></div>
                            </th>                          
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let producto of kardex_productos; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{producto.codigo}}</td>
                            <td>{{producto.nombre}}</td>
                            <td>{{producto.unidad_medida}}</td>
                            <td>{{producto.tipo_producto}}</td>
                            <td>{{producto.grupo}}</td>
                            <td>{{producto.subgrupo}}</td>
                            <td>{{producto.suma_ingreso}}</td>
                            <td>{{producto.suma_salida}}</td>
                            <td>
                              <div class="c100 p{{producto.porcentaje}} small {{producto.color}}">
                                <span style="color:black !important;">{{(producto.suma_ingreso-producto.suma_salida).toFixed(2)}}</span>
                                <div class="slice">
                                    <div class="bar"></div>
                                    <div class="fill"></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a (click)="abrirPopupConfirmacionActualizacionInventario(producto)" href="javascript:void(0)" class="card-toolbar-btn text-danger-m1">
                                <i class="ace-icon fa fa-sync bigger-130"></i>
                              </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</ng-template>

