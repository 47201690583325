import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ArchivosBancoService {

  constructor(private http: HttpClient) { }

  public obtenerListaArchivos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"archivo-banco/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+(paginator.text_search==null?0:paginator.text_search)+"/columna/"+paginator.column+"/direccion/"+paginator.direction, GlobalVariable.httpOptions);
  }

  public guardarArchivoBanco(archivo_banco){
    return this.http.post(GlobalVariable.API_SERVER_URL+"archivo-banco/",archivo_banco,GlobalVariable.httpOptions);
  }

  public eliminarArchivoBanco(archivo_banco){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"archivo-banco/"+archivo_banco.id,GlobalVariable.httpOptions);
  }

  public obtenerArchivoBancoRelacion(archivo_banco){
    return this.http.get(GlobalVariable.API_SERVER_URL+"archivo-banco/"+archivo_banco.id,GlobalVariable.httpOptions);
  }

  public obtenerConceptosPagoEstudiante(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"estudiantes-conceptos-pago/"+datos.codigos_estudiante,GlobalVariable.httpOptions);
  }

  public establecerPagosConceptosEstudiante(detalle_archivo_banco){
    return this.http.put(GlobalVariable.API_SERVER_URL+"detalle-archivo-banco/"+detalle_archivo_banco.id,detalle_archivo_banco,GlobalVariable.httpOptions);
  }

  public obtenerCuotasEstudiante(id_estudiante,id_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"colegio/estudiante-concepto-pago/"+id_estudiante+"/gestion/"+id_gestion,GlobalVariable.httpOptions);
  }

  public consolidarArchivoBanco(archivo_banco){
    return this.http.put(GlobalVariable.API_SERVER_URL+"consolidar-archivo-banco/"+archivo_banco.id,archivo_banco,GlobalVariable.httpOptions);
  }

}
