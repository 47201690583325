import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ViajesService } from '../../services/viajes/viajes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-viajes',
  templateUrl: './viajes.component.html',
  styleUrls: ['./viajes.component.css']
})
export class ViajesComponent extends BaseComponent implements OnInit {

  viajes:any[]=[];
  rutas:any[]=[];
  vehiculos:any[]=[];
  viaje:any={};

  edicion_viaje:NgbModalRef;
  @ViewChild('edicion_viaje')
  private edicion_viaje_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private viajesService:ViajesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_VIAJES);
		this.obtenerViajes();
		this.obtenerRutasEmpresa();
		this.obtenerVehiculosEmpresa();
  }

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerRutasEmpresa(){
    this.viajesService.obtenerRutasEmpresa(this.usuario.id_empresa).subscribe((rutas:any[])=>{
      this.rutas=rutas;
    });
  }

  obtenerVehiculosEmpresa(){
    this.viajesService.obtenerVehiculosEmpresa(this.usuario.id_empresa).subscribe((vehiculos:any[])=>{
      this.vehiculos=vehiculos;
    });
  }

  obtenerViajes(){
		this.column = "tiempo_salida";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.viajesService.obtenerListaViajes(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.viajes=dato.viajes;
			this.blockUI.stop();
		});
  }

  crearNuevoViaje(){
	  let fecha_actual=new Date();
		this.viaje={
			id_empresa:this.usuario.id_empresa,
			tiempo_salida_texto:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		}
		this.abrirRegistroViaje();
  }

  abrirRegistroViaje(){
    this.edicion_viaje=this.modalService.open(this.edicion_viaje_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.edicion_viaje.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  calcularKilometraje(){
	  if(this.viaje.vehiculo && this.viaje.ruta){
		this.viaje.kilometraje_inicial=this.viaje.vehiculo.kilometraje_actual;
	  	this.viaje.kilometraje_final=this.viaje.kilometraje_inicial+this.viaje.ruta.distancia_kilometros;
	  }
  }

	validarRegistroViaje(){
		let res=true;
		if(!this.viaje.vehiculo){
			this.toastr.error("Debe especificar el vehículo!");
			res=false;
		}
		if(!this.viaje.ruta){
			this.toastr.error("Debe especificar la ruta!");
			res=false;
		}
		if(!this.viaje.tiempo_salida_texto){
			this.toastr.error("Debe especificar la fecha!");
			res=false;
		}
		return res;
	}

  guardarViaje(){
		if(this.validarRegistroViaje()){
			this.blockUI.start();
			this.viaje.tiempo_salida=Util.convertirObjetoAfecha(this.viaje.tiempo_salida_texto);
			if(this.viaje.id){
				this.viajesService.actualizarViaje(this.viaje).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicion_viaje.close();
				});
			}else{
				this.viajesService.guardarViaje(this.viaje).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Ruta registrada satisfactoriamente!");
					this.getItems();
					this.edicion_viaje.close();
				});
			}
		}
		
  }
  
  abrirPopupConfirmacionEliminacion(viaje){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el viaje?";
		this.popupConfirmacion.componentInstance.data = viaje;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarViaje($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarViaje(viaje){
		this.blockUI.start();
		this.viajesService.eliminarViaje(viaje).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

}
