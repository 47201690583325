<div class="modal-header card-header">
    <div class="col-10 col-md-10">
        <span class="text-blue text-125">Ruleta de Participación</span>
    </div>
    <div class="col-2 col-md-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-12 col-md-4">
            <div class="row">
                <div class="col-12 col-md-12">
                    <label>Curso: </label>&nbsp;
                    <select class="form-control" name="registro_profesor" [(ngModel)]="filter.registro_profesor" (change)="obtenerListaEstudiantes(filter.registro_profesor)">
                        <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}}-{{registro_profesor.materia.nombre}}</option>
                    </select>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-12">
                    <label>Nivel vuelta: </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" name="departamento" [(ngModel)]="filter.nivel" (change)="powerSelected(filter.nivel.valor)">
                        <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles">{{nivel.nombre}}</option>
                    </select>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-12">
                    <button (click)="startSpin()" [disabled]="!filter.registro_profesor" class="btn btn-primary"  >
                        <i class="fas fa-dharmachakra text-110 mr-1"></i>
                    </button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12 col-md-12">
                    <button (click)="resetWheel()" class="btn btn-secondary"  >
                        <i class="fas fa-power-off text-110 mr-1"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8">
            <table cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td width="438" height="582" class="the_wheel" align="center" valign="center">
                        <canvas id="canvas" width="434" height="434">
                            <p style="{color: white}" align="center">Sorry, your browser doesn't support canvas. Please try another.</p>
                        </canvas>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>