import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { VehiculosService } from '../../services/vehiculos/vehiculos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;

@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.css']
})
export class VehiculosComponent extends BaseComponent implements OnInit {

  vehiculos:any[]=[];
  vehiculo:any={bus:{}};
  tipo_inventario_ubicaciones:any={clases:[]};
  tipo_aceite:any={clases:[]};
  cambio_aceite:any={};

  wizard_edicion_vehiculo:string="wizard_edicion_vehiculo";

  edicion_vehiculo:NgbModalRef;
  @ViewChild('edicion_vehiculo')
  private edicion_vehiculo_ref: TemplateRef<any>;

  inventario_vehiculo:NgbModalRef;
  @ViewChild('inventario_vehiculo')
  private inventario_vehiculo_ref: TemplateRef<any>;

  cambio_aceite_vehiculo:NgbModalRef;
  @ViewChild('cambio_aceite_vehiculo')
  private cambio_aceite_vehiculo_ref: TemplateRef<any>;
  
  @ViewChildren('vehiculos') vehiculos_lectura: QueryList<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private vehiculosService:VehiculosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_VEHICULOS);
		this.obtenerVehiculos();
		this.obtenerUbicacionesInventarioVehiculo();
		this.obtenerTiposAceite();
  }

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.vehiculos_lectura.changes.subscribe(t => {
			aplicarVisorImagenes("imagen-vehiculo-vista");
		});
  }

  	obtenerUbicacionesInventarioVehiculo(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"MNTUBIINV").subscribe((tipo_inventario_ubicaciones:any) => {
			this.tipo_inventario_ubicaciones=tipo_inventario_ubicaciones;
		});
	}

	obtenerTiposAceite(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"MNTTIPOACEITE").subscribe((tipo_aceite:any) => {
			this.tipo_aceite=tipo_aceite;
		});
	}
  
  obtenerVehiculos(){
		this.column = "placa";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.vehiculosService.obtenerListaVehiculos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.vehiculos=dato.vehiculos;
			this.blockUI.stop();
		});
  }
  
  crearNuevoVehiculo(){
		this.vehiculo={
			bus:{
				crear_bus:this.usuario.empresa.usar_buses
			},
			id_empresa:this.usuario.id_empresa,
			id_sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0].id,
			crear_almacen:this.usuario.empresa.usar_mantenimiento_vehiculos
		};
		this.abrirRegistroVehiculo();
  }
  
  abrirRegistroVehiculo(){
    this.edicion_vehiculo=this.modalService.open(this.edicion_vehiculo_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.edicion_vehiculo.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    aplicarWizard(this.wizard_edicion_vehiculo,this,'guardar');
    aplicarPluginImagen();
  }

  validarRegistroVehiculo(){
		let res=true;
		if(!this.vehiculo.placa){
			this.toastr.error("Debe ingresar la placa del vehiculo!");
			res=false;
		}
		return res;
	}
  
	guardar(){
		if(this.validarRegistroVehiculo()){
			this.blockUI.start();
			if(this.vehiculo.id){
				this.vehiculosService.actualizarVehiculo(this.vehiculo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicion_vehiculo.close();
				});
			}else{
				this.vehiculosService.guardarVehiculo(this.vehiculo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Vehiculo registrado satisfactoriamente");
					this.getItems();
					this.edicion_vehiculo.close();
				});
			}
		}
		
	}

	subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.vehiculo.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  	modificarVehiculo(vehiculo){
		this.vehiculosService.obtenerVehiculo(vehiculo.id).subscribe((datosVehiculo:any) => {
			this.vehiculo=datosVehiculo;
		});
		this.abrirRegistroVehiculo();
	}

	abrirPopupConfirmacionEliminacion(vehiculo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el vehiculo?";
		this.popupConfirmacion.componentInstance.data = vehiculo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarVehiculo($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarVehiculo(vehiculo){
		this.blockUI.start();
		this.vehiculosService.eliminarVehiculo(vehiculo).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

	abrirInventarioVehiculo(vehiculo){
		this.blockUI.start();
		this.vehiculosService.obtenerInventarioVehiculo(vehiculo.id).subscribe((datos_vehiculo:any)=>{
			this.blockUI.stop();
			this.vehiculo=datos_vehiculo;
			this.inventario_vehiculo=this.modalService.open(this.inventario_vehiculo_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.inventario_vehiculo.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	  }

	  guardarUbicacionInventario(){
		this.blockUI.start();
		this.vehiculosService.guardarInventarioVehiculo(this.vehiculo).subscribe((res:any) => {
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
			this.inventario_vehiculo.close();
		});
	}

	abrirRegistroCambioAceite(vehiculo){
		this.vehiculo=vehiculo;
		let fecha_actual=new Date();
		this.cambio_aceite={
			id_vehiculo:vehiculo.id,
			kilometraje_actual_cambio:vehiculo.kilometraje_actual,
			fecha_texto:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		};
		this.cambio_aceite_vehiculo=this.modalService.open(this.cambio_aceite_vehiculo_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
			this.cambio_aceite_vehiculo.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	validarRegistroCambioAceite(){
		let res=true;
		if(!this.cambio_aceite.fecha_texto){
			this.toastr.error("Debe ingresar la fecha del cambio!");
			res=false;
		}
		if(!this.cambio_aceite.tipo_aceite){
			this.toastr.error("Debe ingresar el tipo de aceite!");
			res=false;
		}
		if(!this.cambio_aceite.kilometraje_actual_cambio){
			this.toastr.error("Debe ingresar el kilometraje actual del cambio!");
			res=false;
		}
		if(!this.cambio_aceite.kilometraje_proximo_cambio){
			this.toastr.error("Debe ingresar el kilometraje de proximo cambio!");
			res=false;
		}
		return res;
	}
  
	guardarCambioAceite(){
		if(this.validarRegistroCambioAceite()){
			this.blockUI.start();
			this.cambio_aceite.fecha=Util.convertirObjetoAfecha(this.cambio_aceite.fecha_texto);
			if(this.cambio_aceite.id){
				/*this.vehiculosService.actualizarVehiculo(this.vehiculo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
					this.getItems();
					this.edicion_vehiculo.close();
				});*/
			}else{
				this.vehiculosService.guardarCambioAceite(this.cambio_aceite).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Cambio de aceite registrado satisfactoriamente");
					this.cambio_aceite_vehiculo.close();
				});
			}
		}
		
	}

}
