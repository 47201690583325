import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class MovimientoInventarioService {

  constructor(private http: HttpClient) { }

  public obtenerListaMovimientosInventario(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"movimiento-inventario/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/sucursal/"+paginator.filter.sucursal.id+"/transaccion/"+paginator.filter.transaccion.id+"/usuario/"+paginator.filter.usuario.id+"/columna/"+paginator.configuracion_pagina.getOrden().columna+"/direccion/"+paginator.configuracion_pagina.getOrden().direccion+"/producto/"+(paginator.filter.producto==null?0:paginator.filter.producto), GlobalVariable.httpOptions);
  }

  public obtenerMovimientoInventario(id_movimiento){
    return this.http.get(GlobalVariable.API_SERVER_URL+"movimiento-inventario/"+id_movimiento, GlobalVariable.httpOptions);
  }

  public guardarMovimientoInventario(movimiento_inventario){
    return this.http.post(GlobalVariable.API_SERVER_URL+"movimiento-inventario",movimiento_inventario, GlobalVariable.httpOptions);
  }

  public anularMovimiento(movimiento_inventario){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"movimiento-inventario/"+movimiento_inventario.id,GlobalVariable.httpOptions);
  }
  
}
