import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { RedesSocialesService } from '../../services/redes-sociales/redes-sociales.service';

declare const qr:any

@Component({
  selector: 'app-whatsapp-configuracion',
  templateUrl: './whatsapp-configuracion.component.html',
  styleUrls: ['./whatsapp-configuracion.component.css']
})
export class WhatsappConfiguracionComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  codigo_qr:string;
  sucursales_usuario:any[]=[];
  sucursal:any;

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    private socket: Socket,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    public redesSocialesService:RedesSocialesService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.sucursal.empresa=this.usuario.empresa;
    this.sucursal.codigo_qr=null;
    let me=this;
    this.socket.on('enviarCodigoQrSucursal', (dato) => { console.log(dato);
      //me.blockUI.start();
      if(me.sucursal.id==dato.id_sucursal){
        this.sucursal.codigo_qr=dato.qr;
      }
      //me.blockUI.stop();
    });

    this.socket.on('clienteIniciado', (dato) => { console.log(dato);
      if(dato.id_sucursal==this.sucursal.id){
        this.sucursal.conexion_redes_sociales=dato.uid;
        this.persistenciaService.set("uid_sucursal_whatsapp_"+dato.id_sucursal,dato.uid);
        me.toastr.success(dato.mensaje);
        this.alTerminar.emit(dato);
      }
    });

    this.socket.on('enviarChatWhatsapp', (dato) => { console.log(dato);
      this.alTerminar.emit(dato);
    });

    this.conectarClienteWhtasapp();
  }

  conectarClienteWhtasapp(){
    this.blockUI.start();
    this.redesSocialesService.solicitarConexionClienteWhatsapp({id_empresa:this.usuario.id_empresa,id_sucursal:this.sucursal.id}).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
