<footer class="footer d-none d-sm-block">
    <div class="footer-inner bgc-white-tp1">
      <div class="pt-3 border-none border-t-3 brc-grey-l1 border-double">
        <span class="text-primary-m2 font-bolder text-120">{{datos_app.nombre}} </span>
        <span class="text-muted">Application &copy; 2013 - 2023</span>

        <span class="mx-3 action-buttons">
          <a href="{{datos_app.canal_whatsapp}}" target="_blank" type="button" class="btn-text-green text-140"><i class="fab fa-whatsapp text-110"></i></a>
          <a target="_blank" type="button" href="{{datos_app.canal_web}}" class="text-orange text-140"><i class="fa fa-globe"></i></a>
          <a target="_blank" type="button" href="{{datos_app.canal_facebook}}" class="text-blue-d1 text-140"><i class="fab fa-facebook"></i></a>
          <a target="_blank" type="button" href="{{datos_app.canal_youtube}}" class="btn-text-red text-140"><i class="fab fa-youtube"></i></a>
              
           </span>
      </div>
    </div><!-- .footer-inner -->

    <div class="footer-tools">
      <a id="btn-scroll-up" href="#" class="btn-scroll-up btn btn-dark btn-smd mb-2 mr-2">
        <i class="fa fa-angle-double-up mx-1"></i>
      </a>
    </div>
  </footer>