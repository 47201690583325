import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class CitasMedicasService {

  constructor(private http: HttpClient) { }

  public guardarCitaMedica(cita_medica){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cita-medica",cita_medica,GlobalVariable.httpOptions);
  }

  public actualizarCitaMedica(cita_medica){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cita-medica/"+cita_medica.id,cita_medica,GlobalVariable.httpOptions);
  }

  public eliminarCitaMedica(cita_medica){
    return this.http.patch(GlobalVariable.API_SERVER_URL+"cita-medica/"+cita_medica.id,cita_medica,GlobalVariable.httpOptions);
  }

  public obtenerListaCitasMedicas(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"citas-medicas/empresa/"+paginator.filter.id_empresa+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarHistoriaPaciente(historia_paciente){
    return this.http.post(GlobalVariable.API_SERVER_URL+"historia-paciente",historia_paciente,GlobalVariable.httpOptions);
  }

  public actualizarHistoriaPaciente(historia_paciente){
    return this.http.put(GlobalVariable.API_SERVER_URL+"historia-paciente/"+historia_paciente.id,historia_paciente,GlobalVariable.httpOptions);
  }

  public obtenerFichasHistoriasPacientes(paginator){
    let fecha=new Date(paginator.filter.fecha_historias.year,paginator.filter.fecha_historias.month-1,paginator.filter.fecha_historias.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"historias-pacientes-citas-medicas/empresa/"+paginator.filter.id_empresa+"/fecha/"+fecha,GlobalVariable.httpOptions);
  }
}
