import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';

@Component({
  selector: 'app-lista-pagos-compras',
  templateUrl: './lista-pagos-compras.component.html',
  styleUrls: ['./lista-pagos-compras.component.css']
})
export class ListaPagosComprasComponent extends BaseComponent implements OnInit {

  lista_pagos_compras:any[]=[];
  compra:any;
  @BlockUI() blockUI: NgBlockUI;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  popupConfirmacion:NgbModalRef;
  global_variable=GlobalVariable;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public comprasService:ComprasService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    let fecha_actual=new Date();
    let fecha_inicio=this.compra?new Date(this.compra.fecha):fecha_actual;
    this.filter={
      id_empresa:this.usuario.id_empresa,
      fecha_inicio:{
        year: fecha_inicio.getFullYear(), 
        month: (fecha_inicio.getMonth()+1),
        day:fecha_inicio.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      id_compra:this.compra?this.compra.id:0
    }
    this.getItems();
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.comprasService.obtenerListaPagosCompra(this).subscribe((lista_pagos_compras:any[]) => {
			this.setPages(1);
			this.lista_pagos_compras=lista_pagos_compras;
			this.blockUI.stop();
		});
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  imprimirPagoCompra(pago_compra){
    this.blockUI.start('Recuperando... espere por favor!');
    this.pdfService.imprimirReciboPagoCompra(pago_compra,this.usuario);
    this.blockUI.stop();
  }

  abrirPopupConfirmacionEliminacion(pago_compra){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el pago de compra?";
		this.popupConfirmacion.componentInstance.data = pago_compra;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarPagoCompra($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarPagoCompra(pago_compra){
		this.blockUI.start();
		this.comprasService.anularPagoCompra(pago_compra.id).subscribe((res:any) => {
      this.toastr.info(res.mensaje);
      if(this.usuario.empresa.usar_contabilidad){
        /*var promesa=ClasesTipo("TIPFUENTECOMP");
        promesa.then(function(tipoFuentesComprobante){
          var fuentes_comprobantes=tipoFuentesComprobante.clases;
          var datos_fuente=new AnulacionComprobanteAutomatizado({
            datos_fuente:pago_venta.id,
            fuente:$.grep(fuentes_comprobantes, function (e) { return e.nombre_corto == Diccionario.TIPO_FUENTE_COMPROBANTE_AUTOMATICA_PAGO_VENTA; })[0]
          });
          datos_fuente.$save(function(resAnu){
            MostrarMensaje(resAnu.mensaje);
          })
        });*/
      }else{
				this.blockUI.stop();
			}
			this.getItems();
		});
  }
  
  sumarImporte(){
		var suma=0;
		for(var i=0;i<this.lista_pagos_compras.length;i++){
			if(this.lista_pagos_compras[i].activo){
				suma=suma+this.lista_pagos_compras[i].monto_pagado;
			}
		}
		return Math.round(suma*100)/100;
	}

}
