<div class="page-content container">
  <div class="page-header">
    <h1 class="page-title text-primary-d2">
      Tareas
      <small class="page-info text-secondary-d2">
        <i class="fa fa-angle-double-right text-80"></i>
        Lista
      </small>
    </h1>
  </div>

  

  <div class="bgc-primary-d1 text-white px-3 py-25">
    <span class="text-90">Lista de</span>
    Tareas
  </div>

  <div class="bootstrap-table bootstrap4">
    <div class="fixed-table-toolbar">
      <div class="row">
        <div class="col-12 col-md-9">
      <div class="row">
      <div class="col-12 col-md-12">
        <app-paginator-header [paginator]="this"></app-paginator-header>
      </div>
      </div>
      <div class="row">
      <div class="col-12 col-md-12">
        <div class="row">
          <div class="col-3"><label>Curso - Materia </label></div>
          <div class="col-6">
              <select [compareWith]="compararObjectos" class="form-control" required name="registro_profesor" [(ngModel)]="filter.registro_profesor">
                  <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
              </select>
          </div>
        </div>
      </div>
    </div>
    </div>
        <div class="col-12 col-md-3">
          <div class="columns columns-right btn-group float-right">
            <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
              <i class="fa fa fa-search"></i>
      </button>
      <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevaTarea()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-purple-d1"></i>
      </button>
      <div class="keep-open btn-group show" title="Columns">
      <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
    </div>
          </div>
        </div>
  </div>
    </div>

    <div class="fixed-table-container" style="padding-bottom: 0px;">
      <div class="fixed-table-header" style="display: none;">
        <table></table>
      </div>
      <div id="gallery" class="fixed-table-body">
        <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
          <span class="loading-wrap">
            <span class="animation-wrap">
              <span class="animation-dot"></span>
            </span>
          </span>
        </div>
        <table *ngIf="tareas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
          <thead class="bgc-white text-grey text-uppercase text-80">
            <tr>
              <th class="detail" rowspan="1">
                <div class="th-inner ">Nº</div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Nombre</a>
                          </div><div class="fht-cell"></div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().curso.show">
                <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='curso' && this.direction=='asc','desc':this.column=='curso' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('curso')">Curso</a>
                </div><div class="fht-cell"></div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().materia.show">
                <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='materia' && this.direction=='asc','desc':this.column=='materia' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('materia')">Materia</a>
                </div><div class="fht-cell"></div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().fecha_creacion.show">
                <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='createdAt' && this.direction=='asc','desc':this.column=='createdAt' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('createdAt')">Fecha Creación</a>
                </div><div class="fht-cell"></div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().fecha_entrega.show">
                <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='fecha_entrega' && this.direction=='asc','desc':this.column=='fecha_entrega' && this.direction=='desc'}">
                            <a href="javascript:void(0)" (click)="this.sortColumn('fecha_entrega')">Fecha Entrega</a>
                </div><div class="fht-cell"></div>
              </th>
              <th *ngIf="configuracion_pagina.getConfiguration().archivos.show">
                <div class="th-inner sortable both " >
                            Archivos
                </div><div class="fht-cell"></div>
              </th>
              <th style="text-align: center; width: 140px; " data-field="tools">
                <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                <div class="fht-cell"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr #tareas *ngFor="let tarea of tareas; let i=index;">
              <td>
                {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                {{tarea.nombre}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().curso.show">
                {{tarea.registro_profesor.curso.nombre}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().materia.show">
                {{tarea.registro_profesor.materia.nombre}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().fecha_creacion.show">
                {{tarea.createdAt | date:"dd/MM/yyyy"}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().fecha_entrega.show">
                {{tarea.fecha_entrega | date:"dd/MM/yyyy"}}
              </td>
              <td *ngIf="configuracion_pagina.getConfiguration().archivos.show">
                <ng-container *ngIf="tarea.archivos">
                  <ng-container *ngFor="let archivo of tarea.archivos">
                    <a title="{{archivo}}" class="btn btn-md" [ngClass]="{'btn-purple':(archivo.includes('.zip') || archivo.includes('.rar')),'btn-danger':archivo.includes('pdf'),'btn-success':(archivo.includes('xls') || archivo.includes('xlsx')),'btn-primary':(archivo.includes('doc') || archivo.includes('docx') || archivo.includes('jpg') || archivo.includes('png') || archivo.includes('gif') || archivo.includes('jpeg') || archivo.includes('mp3') || archivo.includes('mpeg') || archivo.includes('mp4'))}" target="_blank" href="{{rest_server+archivo}}">
                      <i class="fas" [ngClass]="{'fa-file-archive':(archivo.includes('.zip') || archivo.includes('.rar')),'fa-file-pdf':archivo.includes('pdf'),'fa-file-excel':(archivo.includes('xls') || archivo.includes('xlsx')),'fa-file-word':(archivo.includes('doc') || archivo.includes('docx')),'fa-file-image':(archivo.includes('jpg') || archivo.includes('png') || archivo.includes('gif') || archivo.includes('jpeg')),'fa-file-audio':(archivo.includes('mp3') || archivo.includes('mpeg')),'fa-file-video':(archivo.includes('mp4'))}"></i>
                    </a>&nbsp;
                  </ng-container>
                </ng-container>
              </td>
              <td style="text-align: center; width: 140px; ">
                <div class="action-buttons">             
                  <a (click)="verTarea(tarea)" title="Ver Tarea" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">          
                    <i class="fa fa-eye text-105"></i>        
                  </a>
                  <a title="Enviar tarea por correo" *ngIf="tarea.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioCorreo(tarea)">
                    <i class="ace-icon fa fa-envelope bigger-130"></i>
                  </a>
                  <a title="Eliminar" *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(tarea)">          
                    <i class="fa fa-trash-alt text-105"></i>        
                  </a>      
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="fixed-table-footer">
        <table><thead><tr></tr></thead></table>
      </div>
    </div>
    <div class="fixed-table-pagination">
      <app-paginator-footer *ngIf="tareas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
    </div>
  </div>
  <div class="clearfix"></div>
</div>

<ng-template #registro_tarea_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Tarea</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarTarea()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-12 col-md-4">
              <label>Curso y Materia</label>
          </div>
          <div class="col-12 col-md-8">
            <select [compareWith]="compararObjectos" class="form-control" required name="registro_profesor" [(ngModel)]="tarea.registro_profesor">
              <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-4">
                <label>Nombre</label>
              </div>
              <div class="col-8">
                  <input name="nombre" required type="text" [(ngModel)]="tarea.nombre" placeholder="Nombre" class="form-control" />
              </div>
            </div>
            <hr>
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-6 ">
                <label>Fecha de Entrega</label>
              </div>
              <div class="col-6">
                <div class="input-group">
                  <input name="fecha_entrega" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="tarea.fecha_entrega_texto" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col-12 col-md-12">
              <label>Descripción</label>
            </div>
            <div class="col-12 col-md-12">
                <div class="card bcard border-1 brc-dark-l1">
                  <div class="card-body p-0">
                    <form method="post">
                      <textarea id="summernote" name="editordata" [(ngModel)]="tarea.descripcion" placeholder="Descripción"></textarea>
                    </form>
                  </div>
                </div>
            </div>
          </div>
          <hr>
        </div>
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12 col-md-12">
              <label>Adjuntar archivos</label>
            </div>
            <div class="col-12 col-md-12">
              <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                <div class="fallback d-none">
                  <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                </div>

                <div class="dz-default dz-message">
                  <span class="text-150  text-grey-d2">
                  <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                  para subir
                  <span class="text-90 text-grey-m1">(o click)</span>
                  <br />
                  <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                  </span>
                </div>
              </form>

              <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
              <div id="preview-template" class="d-none">
                <div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                    <img alt="Archivo" data-dz-thumbnail="" />
                  </div>

                  <div class="dz-details">
                    <div class="dz-size">
                      <span data-dz-size=""></span>
                    </div>

                    <div class="dz-filename">
                      <span data-dz-name=""></span>
                    </div>
                  </div>

                  <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                    <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                  </div>

                  <div class="dz-error-message">
                    <span data-dz-errormessage=""></span>
                  </div>

                  <div class="dz-success-mark">
                    <span class="fa-stack fa-lg text-150">
                      <i class="fa fa-circle fa-stack-2x text-white"></i>
                      <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                  </span>
                  </div>

                  <div class="dz-error-mark">
                    <span class="fa-stack fa-lg text-150">
                      <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                      <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #vista_tarea_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Datos de la Tarea</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-6">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-graduation-cap"></i> Curso : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{tarea.registro_profesor.curso.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-chalkboard-teacher red2 text-110 mr-1"></i> Materia : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                      {{tarea.registro_profesor.materia.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
              <div class="col-6 alert alert-info pr-0 mb-0">  
                  <label class="text-primary p-0">
                      <i class="fas fa-tasks red2 text-110 mr-1"></i> Tarea  : 
                  </label>  
              </div>
              <div class="col-6">
                  <label>
                    {{tarea.nombre}}
                  </label>    
              </div>
            </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6 alert alert-info pr-0 mb-0">  
                <label class="text-primary p-0">
                    <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha de creación  : 
                </label>  
            </div>
            <div class="col-6">
                <label>
                  {{tarea.createdAt | date:"dd/MM/yyyy"}}
                </label>    
            </div>
          </div>
              <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">  
                    <label class="text-primary p-0">
                        <i class="fa fa-calendar red2 text-110 mr-1"></i> Fecha de entrega  : 
                    </label>  
                </div>
                <div class="col-6">
                    <label>
                      {{tarea.fecha_entrega | date:"dd/MM/yyyy"}}
                    </label>    
                </div>
            </div>

            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-clone"></i> Archivos : 
                    </label>    
                </div>
                <div class="col-5">
                  <ng-container *ngIf="tarea.archivos">
                    <ng-container *ngFor="let archivo of tarea.archivos">
                      <a title="{{archivo}}" class="btn btn-md" [ngClass]="{'btn-purple':(archivo.includes('.zip') || archivo.includes('.rar')),'btn-danger':archivo.includes('pdf'),'btn-success':(archivo.includes('xls') || archivo.includes('xlsx')),'btn-primary':(archivo.includes('doc') || archivo.includes('docx') || archivo.includes('jpg') || archivo.includes('png') || archivo.includes('gif') || archivo.includes('jpeg') || archivo.includes('mp3') || archivo.includes('mpeg') || archivo.includes('mp4'))}" target="_blank" href="{{rest_server+archivo}}">
                        <i class="fas" [ngClass]="{'fa-file-archive':(archivo.includes('.zip') || archivo.includes('.rar')),'fa-file-pdf':archivo.includes('pdf'),'fa-file-excel':(archivo.includes('xls') || archivo.includes('xlsx')),'fa-file-word':(archivo.includes('doc') || archivo.includes('docx')),'fa-file-image':(archivo.includes('jpg') || archivo.includes('png') || archivo.includes('gif') || archivo.includes('jpeg')),'fa-file-audio':(archivo.includes('mp3') || archivo.includes('mpeg')),'fa-file-video':(archivo.includes('mp4'))}"></i>
                      </a>&nbsp;
                    </ng-container>
                  </ng-container>  
                </div>
            </div>
            
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <h5 class="text-blue-d2 mb-3">Descripción</h5>
        <div [innerHTML]="tarea.descripcion"></div>
    </div>
</div>
</ng-template>