<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Comprobantes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Comprobantes</span>
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button title="Nuevo" (click)="crearNuevoComprobante()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
            <button title="Nuevo" (click)="abrirBalanceComprobacionSumasSaldos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1 bs-print" type="button">
              <i class="fas fa-calculator btn-text-success"></i>
            </button>
            <!--<div class="export btn-group">
                <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                          <i class="fa fa-download text-green-d1"></i>
                          <span class="caret"></span>
                </button>
                <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-comprobantes')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                  <i class="fa fa-upload text-red fileUpload-button"></i>
                  <input id="subir-excel-comprobantes" style="display:none" type="file" class="form-control" (change)="subirExcelComprobantes($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </button>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="input-group">
                          <label for="feini">Desde </label>&nbsp;
                          <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                          </div>
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="input-group">
                          <label for="fefin">Hasta </label>&nbsp;
                          <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                          </div>
                      </div>  
                  </div>
                    <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Usuario </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Tipo </label>
                        <select [compareWith]="compararObjectos" class="form-control" required name="tipo" [(ngModel)]="filter.tipo">
                        <option [ngValue]="tipo" *ngFor="let tipo of filter.tipos">{{tipo.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Número </label>
                        <input class="form-control form-control search-input" type="search" [(ngModel)]="filter.numero">
                    </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="comprobantes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
                    <th>Tipo</th>
                    <th>Número</th>
                    <th>Fecha</th>
                    <th>Glosa General</th>
                    <th>Hora - Fecha Registro</th>
                    <th>Importe</th>
                    <th>Usuario</th>
                    <th>Activo</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let comprobante of comprobantes; let i=index;">
                    <td>{{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales">{{comprobante.sucursal.nombre}}</td>
                    <td>{{comprobante.tipo.nombre}}</td>
                    <td>{{comprobante.numero}}</td>
                    <td>{{comprobante.fecha | date:'dd/MM/yyyy'}}</td>
                    <td>{{comprobante.glosa}}</td>
                    <td>{{comprobante.createdAt | date:'HH:mm dd/MM/yyyy'}}</td>
                    <td>{{comprobante.importe.toFixed(2)}}</td>
                    <td>{{comprobante.usuario.nombre_usuario}}</td>
                    <td *ngIf="!comprobante.eliminado"><span class="badge badge-sm badge-success">SÍ</span></td>
                    <td *ngIf="comprobante.eliminado"><span class="badge badge-sm badge-danger">NO</span></td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="d-lg-inline text-muted">
                            <a title="Ver" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verComprobante(comprobante)" >
                                <i class="fas fa-search-plus"></i>
                            </a>
                            <a *ngIf="!comprobante.eliminado" title="Modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarComprobante(comprobante)">
                                <i class="fas fa-pencil-alt"></i>
                            </a>
                            <a *ngIf="!comprobante.eliminado" title="Imprimir" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="imprimirComprobante(comprobante)" >
                                <i class="fas fa-print"></i>
                            </a>
                            <a *ngIf="!comprobante.eliminado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(comprobante)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #vista_comprobante_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Vista Comprobante</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-4">
            <div class="col-12">
                <label>Sucursal</label>
            </div>
            <div class="col-12">
              <label>{{comprobante.sucursal.nombre}}</label>
            </div>
        </div>
        <div class="col-4">
            <div class="col-12">
                <label>Tipo Comprobante</label>
            </div>
            <div class="col-12">
              <label>{{comprobante.tipo.nombre}}</label>
            </div>
        </div>
        <div class="col-4">
            <div class="col-12">
                <label>Fecha</label>
            </div>
            <div class="col-12">
              <label>{{comprobante.fecha | date:'dd/MM/yyyy'}}</label>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Glosa General</label>
        </div>
        <div class="col-12">
          <label>{{comprobante.glosa}}</label>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th rowspan="2" style="text-align: center">Nº</th>
                        <th rowspan="2" style="text-align: center">
                            <div class="col-12">Cuenta
                            </div>
                        </th>
                        <th colspan="2" style="text-align: center">Bolivianos</th>
                        <th class="red" style="text-align: center" *ngIf="comprobante.opcion_bimonetario" colspan="2">Dólares</th>
                    </tr>
                    <tr>

                        <th style="text-align: center">Debe</th>
                        <th style="text-align: center">Haber</th>
                        <th style="text-align: center" class="red" *ngIf="comprobante.opcion_bimonetario">Debe</th>
                        <th style="text-align: center" class="red" *ngIf="comprobante.opcion_bimonetario">Haber</th>
                    </tr>
                </thead>

                <tbody *ngFor="let asiento of comprobante.asientos; let i=index;">
                    <ng-container *ngIf="!asiento.eliminado" >
                        <tr>
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                      <label>{{asiento.cuenta.codigo}} - {{asiento.cuenta.nombre}}</label>
                                    </div>
                                
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input disabled type="text" class="form-control" [(ngModel)]="asiento.glosa">
                                    </div>
                                </div>
                                <span *ngIf="asiento.cuenta && asiento.cuenta.cuenta_auxiliar" class="text-danger" (click)="abrirLibroMayorCuenta(asiento.cuenta.cuenta_auxiliar)">{{asiento.cuenta.cuenta_auxiliar.codigo}} - {{asiento.cuenta.cuenta_auxiliar.nombre}}</span>
                            </td>
                            <td class="input-number">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="establecerCuentaVista(asiento.cuenta)" id="{{i}}-Bs" type="number" [(ngModel)]="asiento.debe_bs"
                                    (change)="convertirMonedaAdolares(asiento)" class="form-control" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                            </td>
                            <td class="input-number">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="establecerCuentaVista(asiento.cuenta)" id="{{i}}-Cs" type="number" [(ngModel)]="asiento.haber_bs"
                                (change)="convertirMonedaAdolares(asiento)" class="form-control" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                            </td>
                            <td class="input-number" *ngIf="comprobante.opcion_bimonetario">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="comprobante.establecerCuentaVista(asiento.cuenta)" type="number" [(ngModel)]="asiento.debe_sus"
                                (change)="convertirDolaresAmoneda(asiento)" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                            </td>
                            <td class="input-number" *ngIf="comprobante.opcion_bimonetario">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="comprobante.establecerCuentaVista(asiento.cuenta)" type="number" [(ngModel)]="asiento.haber_sus"
                                (change)="convertirDolaresAmoneda(asiento)" oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr *ngIf="comprobante.asientos.length>0">
                        <td colspan="2" style="text-align: right">Totales</td>
                        <td style="text-align: center">{{comprobante.importe}}</td>
                        <td style="text-align: center">{{comprobante.importe}}</td>
                        <td *ngIf="comprobante.opcion_bimonetario" style="text-align: center">{{total_debe_sus}}</td>
                        <td *ngIf="comprobante.opcion_bimonetario" style="text-align: center">{{total_haber_sus}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
</ng-template>