import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';

@Component({
  selector: 'app-configuracion-aplicacion-conceptos-ingresos',
  templateUrl: './configuracion-aplicacion-conceptos-ingresos.component.html',
  styleUrls: ['./configuracion-aplicacion-conceptos-ingresos.component.css']
})
export class ConfiguracionAplicacionConceptosIngresosComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  tipo_codigo:any={clases:[{}]};
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTiposCodigo();
  }

  async obtenerTiposCodigo(){
    this.tipo_codigo=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING).toPromise();
    if(!this.tipo_codigo.clases || this.tipo_codigo.clases.length==0){
      this.tipo_codigo.clases=[{nombre:"COD",nombre_corto:"1",habilitado:true}];
      await this.generalService.actualizarTipo(this.tipo_codigo).toPromise();
      this.tipo_codigo=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CONCEPTO_ING).toPromise();
    }
  }

  guardarConfiguracionVista(){
    this.generalService.actualizarTipo(this.tipo_codigo).toPromise();
		//this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
