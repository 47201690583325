import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { MateriasService } from '../../services/materias/materias.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { AsistenciasService } from '../../services/asistencias/asistencias.service';
import {Md5} from 'ts-md5/dist/md5';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { Util } from 'src/app/utils/utils';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { CursosService } from '../../services/cursos/cursos.service';
import { OrderPipe } from 'ngx-order-pipe';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.css']
})
export class AsistenciaComponent extends BaseComponent implements OnInit {

  gestion_actual:any;
  estudiantes:any[]=[];
  estados_asistencia:any=[];
  fechas_asistencia:any[]=[];
  estudiante_actual:any={};
  es_rol_profesor:boolean=true;

  @ViewChildren('estudiantes_lectura') estudiantes_lectura: QueryList<any>;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public materiasService:MateriasService,
    public estudiantesService:EstudiantesService,
    public asistenciasService:AsistenciasService,
    public cursosService:CursosService,
    public toastr: ToastrService,
    private orderPipe: OrderPipe,
    public usuarioService:UsuarioService,
    public profesoresService:ProfesoresService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    this.es_rol_profesor=Util.esRolUsuario("PROFESOR",this.usuario);
    let opcion_seleccion={id:0,curso:{id:0,nombre:"Seleccione el"},materia:{id:0,nombre:'curso y materia'}};
    this.filter={
      registros_profesor:[opcion_seleccion],
		  registro_profesor:opcion_seleccion,
      intervalos_escolares:[],
      sucursales:Util.obtenerSucursalesUsuario(this.usuario),
			sucursal: Util.obtenerSucursalesUsuario(this.usuario)[0],
    }
    await this.obtenerGestionActual();
    if(this.es_rol_profesor){
      this.obtenerRegistrosProfesor();
    }else{
      this.obtenerCursosColegio();
    }
    this.obtenerIntervalosColegio();
  }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    this.estudiantes_lectura.changes.subscribe(t => {
			//document.getElementById('table_asistencia').scrollLeft += 1000000;
		});
  }

  obtenerCursosColegio(){
    this.cursosService.obtenerCursosColegio(this.usuario.id_empresa).subscribe((cursos:any)=>{
      this.blockUI.stop();
      cursos=this.orderPipe.transform(cursos, 'orden',true);
      for(let i=0;i<cursos.length;i++){
        this.filter.registros_profesor.push({id:0,curso:cursos[i],materia:{id:0}});
      }
    });
  }

  obtenerRegistrosProfesor(){
	  this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,this.gestion_actual.id).subscribe((registros_profesor:any[])=>{
      this.filter.registros_profesor=this.filter.registros_profesor.concat(registros_profesor);
    });
  }

  async obtenerGestionActual(){
    this.blockUI.start();
    let gestion_actual=(new Date().getFullYear()).toString();
    let tipo_gestion:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    this.gestion_actual=(tipo_gestion && tipo_gestion.clases)?tipo_gestion.clases.filter(g => g.nombre==gestion_actual).length>0?tipo_gestion.clases.filter(g => g.nombre==gestion_actual)[0]:null:null;
    this.blockUI.stop();
  }

  obtenerDatosCurso(id_curso){
    this.obtenerMateriasCurso(id_curso);
    //this.obtenerEstudiantesCurso(id_curso);
  }

  obtenerMateriasCurso(id_curso){
    this.materiasService.obtenerMateriasCurso(this.usuario.id_persona,this.gestion_actual.id,id_curso).subscribe((registros_profesor:any[])=>{
      this.filter.registros_profesor=registros_profesor;
    }); 
  }

  async obtenerEstudiantesCurso(id_sucursal,id_curso,configuracion_intervalo_escolar){
    this.blockUI.start();
    let res:any=await this.asistenciasService.obtenerListaEstudiantes(id_sucursal,this.gestion_actual.id,id_curso,this.filter.registro_profesor.id,configuracion_intervalo_escolar.fecha_inicio,configuracion_intervalo_escolar.fecha_fin).toPromise();
    this.estudiantes=res;
    this.blockUI.stop();
  }

  establecerMateria(registro_profesor){
    this.filter.registro_profesor=registro_profesor;
    //this.buscarAsistencias();
  }

  async buscarAsistencias(){
    if(this.es_rol_profesor && this.filter.registro_profesor.id==0){
      this.toastr.info("Debe seleccionar el curso y materia!");
    }else{
      let configuracion_intervalo_escolar:any=await this.asistenciasService.obtenerConfiguracionIntervaloEscolar(this.gestion_actual.id,this.gestion_actual.nombre,this.filter.intervalo_escolar.id,this.filter.intervalo_escolar.nombre_corto).toPromise();
      await this.obtenerEstudiantesCurso(this.filter.sucursal.id,this.filter.registro_profesor.curso.id,configuracion_intervalo_escolar);
      await this.obtenerAsistenciasRegistroProfesor(this.filter.sucursal,this.filter.registro_profesor,configuracion_intervalo_escolar);
      await this.verificarFechasAsistenciasEstudiantes();
      let md5=new Md5();
      let asistencia={_id:md5.appendStr(new Date().toUTCString()).end(),fecha:new Date(),estudiantes:[],id_registro_profesor:this.filter.registro_profesor.id,id_sucursal:this.filter.sucursal.id,id_curso:this.filter.registro_profesor.curso.id};
      this.fechas_asistencia.push(asistencia);
    }
  }

  async obtenerAsistenciasRegistroProfesor(sucursal,registro_profesor,configuracion_intervalo_escolar){
    this.blockUI.start();
    let asistencias:any=await this.asistenciasService.obtenerAsistencias(sucursal.id,registro_profesor.id,registro_profesor.curso.id,configuracion_intervalo_escolar.fecha_inicio,configuracion_intervalo_escolar.fecha_fin).toPromise();
    this.fechas_asistencia=asistencias;
    this.blockUI.stop();
  }

  async verificarFechasAsistenciasEstudiantes(){
    for(let i=0;i<this.estudiantes.length;i++){
      for(let j=0;j<this.fechas_asistencia.length;j++){
        this.fechas_asistencia[j].fecha=new Date(this.fechas_asistencia[j].fecha);
        this.fechas_asistencia[j].fecha_texto=this.fechas_asistencia[j].fecha.getDate()+"/"+this.fechas_asistencia[j].fecha.getMonth()+"/"+this.fechas_asistencia[j].fecha.getFullYear();
        let encontrado=this.estudiantes[i].asistencias.filter(a => {
          a.asistencia.fecha=new Date(a.asistencia.fecha);
          a.asistencia.fecha_texto=a.asistencia.fecha.getDate()+"/"+a.asistencia.fecha.getMonth()+"/"+a.asistencia.fecha.getFullYear();
          return a.asistencia.fecha_texto==this.fechas_asistencia[j].fecha_texto;
        });
        if(encontrado.length==0){
          this.estudiantes[i].asistencias.splice(j, 0, {asistencia:{}});
        }
      }
    }
  }

  async obtenerEstadoAsistencia(){
    this.blockUI.start();
    let tipo_asistencia:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_ESTADOS_ASISTENCIA).toPromise();
    this.estados_asistencia=tipo_asistencia.clases;
    this.blockUI.stop();
  }

  async obtenerIntervalosColegio(){
    this.blockUI.start();
    let tipo_intervalo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_INTERVALOS_ESCOLAR).toPromise();
    this.filter.intervalos_escolares=tipo_intervalo?tipo_intervalo.clases:[];
    this.blockUI.stop();
  }

  async generarNuevoRegistroAsistencia(asistencia){
    asistencia.modificado=true;
    asistencia.fecha_texto={
			year: asistencia.fecha.getFullYear(), 
			month: (asistencia.fecha.getMonth()+1),
			day:asistencia.fecha.getDate()
		}
    await this.obtenerEstadoAsistencia();
    let estado_asistencia_presente=this.estados_asistencia.filter(estado => estado.nombre=="PRESENTE")[0];
    for(let i=0;i<this.estudiantes.length;i++){
      this.estudiantes[i].asistencias=this.estudiantes[i].asistencias?this.estudiantes[i].asistencias:[];
      this.estudiantes[i].asistencias.push({_id_asistencia:asistencia._id,id_estudiante:this.estudiantes[i].id,estado:estado_asistencia_presente,modificado:true});
    }
  }

  guardarAsistencia(asistencia){
    asistencia.modificado=false;
    asistencia.fecha=Util.convertirObjetoAfecha(asistencia.fecha_texto);
    this.blockUI.start();
    if(asistencia.id){
      asistencia.estudiantes=[];
      for(let i=0;i<this.estudiantes.length;i++){
        asistencia.estudiantes.push(this.estudiantes[i].asistencias.filter(asistencia_c => asistencia_c.id_asistencia==asistencia.id)[0]);
      }
      this.contarAsistencias(asistencia);
      this.asistenciasService.actualizarAsistencia(asistencia).subscribe((res:any)=>{
        this.blockUI.stop();
        this.toastr.success(res.mensaje);
        this.buscarAsistencias();
      });
    }else{
      asistencia.estudiantes=[];
      for(let i=0;i<this.estudiantes.length;i++){
        asistencia.estudiantes.push(this.estudiantes[i].asistencias.filter(asistencia_c => asistencia_c._id_asistencia==asistencia._id)[0]);
      }
      this.contarAsistencias(asistencia);
      this.asistenciasService.guardarAsistencia(asistencia).subscribe((res:any)=>{
        this.blockUI.stop();
        asistencia.id=res.id;
        this.toastr.success("Registro de asistencia guardado satisfactoriamente!");
        this.buscarAsistencias();
      });
    }
  }

  contarAsistencias(asistencia){
    asistencia.contador_asistentes=0;
    asistencia.contador_atrasados=0;
    asistencia.contador_ausentes=0;
    asistencia.contador_licencias=0;
    for(let i=0;i<asistencia.estudiantes.length;i++){
      if(asistencia.estudiantes[i].estado.nombre_corto=="P"){
        asistencia.contador_asistentes++;
      }
      if(asistencia.estudiantes[i].estado.nombre_corto=="AT"){
        asistencia.contador_atrasados++;
      }
      if(asistencia.estudiantes[i].estado.nombre_corto=="AU"){
        asistencia.contador_ausentes++;
      }
      if(asistencia.estudiantes[i].estado.nombre_corto=="L"){
        asistencia.contador_licencias++;
      }
    }
  }

  async modificarAsistencia(asistencia){
    asistencia.fecha=new Date(asistencia.fecha);
    asistencia.fecha_texto={
			year: asistencia.fecha.getFullYear(), 
			month: (asistencia.fecha.getMonth()+1),
			day:asistencia.fecha.getDate()
		}
    asistencia.modificado=true;
    await this.obtenerEstadoAsistencia();
    for(let i=0;i<this.estudiantes.length;i++){
      let asistencia_estudiante=this.estudiantes[i].asistencias.filter( ae => ae.id_asistencia==asistencia.id)[0];
      if(asistencia_estudiante){
        asistencia_estudiante.modificado=true;
      }
    }
  }

  abrirPopupConfirmacionEliminacion(asistencia){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el registro de asistencia?";
		this.popupConfirmacion.componentInstance.data = asistencia;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarAsistencia($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarAsistencia(asistencia){
		this.blockUI.start();
		this.asistenciasService.eliminarAsistencia(asistencia).subscribe((res:any) => {
      this.toastr.info(res.mensaje);
      this.blockUI.stop();
			this.buscarAsistencias();
		});
  }
  
  verEstudiante(estudiante){
    this.estudiante_actual=estudiante;
  }

  abrirCalendario(d,asistencia){
    d.toggle();
    //$("#table_asistencia").scrollLeft( 300 );
    if(!asistencia.id){
      document.getElementById('table_asistencia').scrollLeft += 1000000;
    }
  }

  crearNuevaAsistencia(){
    this.generarNuevoRegistroAsistencia(this.fechas_asistencia[this.fechas_asistencia.length-1]);
    document.getElementById('table_asistencia').scrollLeft += 1000000;
  }

}
