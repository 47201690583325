<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Lista de mensajes</h4>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="guardarMensajesEmpresa()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="table-fixed-header">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Clase</th>
                    <th>Plantilla</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mensaje of lista_mensajes; let i=index;">
                    <th>{{i+1}}</th>
                    <th>{{mensaje.clase.nombre}}</th>
                    <td>
                        <select [attr.name]="mensaje.id" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="mensaje.plantilla_mensaje">
                            <option [value]="plantilla_mensaje" [attr.selected]="(mensaje.plantilla_mensaje && mensaje.plantilla_mensaje.id==plantilla_mensaje.id )? true : null" [ngValue]="plantilla_mensaje" *ngFor="let plantilla_mensaje of lista_plantillas_mensajes | filterBy:{id_clase:mensaje.clase.id}">{{(plantilla_mensaje.mensaje)}}</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>