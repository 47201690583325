import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ReservasService {

  constructor(private http: HttpClient) { }

  public obtenerAmbientesReservas(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"reservas/empresa/"+paginator.filter.id_empresa+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/sucursal/"+paginator.filter.sucursal.id+"/clase/"+paginator.filter.clase.id+"/planta/"+paginator.filter.planta.id+"/cliente/"+((paginator.filter.cliente=="" || paginator.filter.cliente==undefined)?0:paginator.filter.cliente), GlobalVariable.httpOptions);
  }

  public guardarReserva(reserva){
    return this.http.post(GlobalVariable.API_SERVER_URL+"reservas",reserva,GlobalVariable.httpOptions);
  }

  public obtenerReserva(id_reserva){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reservas/"+id_reserva,GlobalVariable.httpOptions);
  }

  public verificarDisponibilidadAmbiente(id_ambiente,inicio,fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"disponibilidad/ambiente/"+id_ambiente+"/inicio/"+inicio+"/fin/"+fin,GlobalVariable.httpOptions);
  }

  public anularReserva(id_reserva){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"reservas/"+id_reserva,GlobalVariable.httpOptions);
  }

  public actualizarEstadoReserva(reserva){
    return this.http.put(GlobalVariable.API_SERVER_URL+"reserva-actualizacion-estado/"+reserva.id,reserva,GlobalVariable.httpOptions);
  }

  public actualizarReserva(reserva){
    return this.http.put(GlobalVariable.API_SERVER_URL+"reservas/"+reserva.id,reserva,GlobalVariable.httpOptions);
  }
}
