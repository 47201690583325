<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Eventos Significativos</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" (click)="crearEventoSignificativo()">
            <i class="ace-icon fa fa-plus align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-1 col-sm-12" *ngIf="usuario.empresa.usar_sucursales"><label>Sucursal </label></div>
        <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_sucursales">
        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
        </select>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Desde </label>&nbsp;
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="fefin">Hasta </label>&nbsp;
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>  
         </div>
        <div class="col-md-2 col-xs-3 col-sm-3">
            <button (click)="getItems()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th *ngIf="usuario.empresa.usar_sucursales">Sucursal</th>
                    <th>Evento-C.U.F.D.</th>
                    <th>Fechas</th>
                    <th>Estado</th>
                    <th>Activo</th>
                    <th>
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let evento_significativo of lista_eventos_significativos; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales">{{evento_significativo.sucursal.nombre}}</td>
                    <td>
                        {{evento_significativo.evento.nombre}}<br>
                        <b>Fecha-Hora C.U.F.D.:</b> {{evento_significativo.sin_sucursal_cufd.createdAt | date:"HH:mm - dd/MM/yyyy"}}<br>
                        <b>C.U.F.D.:</b> {{evento_significativo.sin_sucursal_cufd.codigo_cufd}}<br>
                        <b>CAFC:</b> {{evento_significativo.codigo_cafc}}
                    </td>
                    <td>
                        <b>Creación:</b> {{evento_significativo.createdAt | date:"HH:mm - dd/MM/yyyy"}}<br>
                        <b>Inicio:</b> {{evento_significativo.fecha_inicio | date:"HH:mm - dd/MM/yyyy"}}<br>
                        <b>Fin:</b> {{evento_significativo.fecha_fin | date:"HH:mm - dd/MM/yyyy"}}
                    </td>
                    <td>{{evento_significativo.estado}}</td>
                    <td *ngIf="evento_significativo.activo"><span class="badge badge-sm badge-success">SI</span></td>
                    <td *ngIf="!evento_significativo.activo"><span class="badge badge-sm badge-danger">NO</span></td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a title="Ver Ventas" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVentas(evento_significativo)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="evento_significativo.activo" title="Subir" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="sincronizarEventoSignificativo(evento_significativo)">
                                <i class="ace-icon fa fa-upload bigger-130"></i>
                            </a>
                            <a *ngIf="!evento_significativo.activo" title="Sincronizar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="validarRecepcionMasivaFacturas(evento_significativo)">
                                <i class="ace-icon fas fa-sync bigger-130"></i>
                            </a>   
                            <a *ngIf="evento_significativo.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(evento_significativo)">
                                <i class="fa fa-trash-alt"></i>
                            </a>                         
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #registro_evento_significativo let-modal>
	<div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Evento Significativo</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarEventoSignificativo()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <ng-container *ngIf="usuario.empresa.usar_sucursales">
            <div class="row" >
                <div class="col-sm-12 col-md-3">
                    <label>Sucursal</label>
                </div>
                <div class="col-sm-12 col-md-9">
                    <select (change)="obtenerUltimosCodigosSucursal()" [compareWith]="compararObjectos" id="sucursal" name="sucursal" class="form-control" [(ngModel)]="evento_significativo.sucursal">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                    </select>
                </div>
            </div>
            <hr>
        </ng-container>
        <div class="row" >
            <div class="col-sm-12 col-md-3">
                <label>Evento</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <select [compareWith]="compararObjectos" id="evento" name="evento" class="form-control" [(ngModel)]="evento_significativo.evento" (change)="establecerFechaFin()">
                    <option [ngValue]="evento" *ngFor="let evento of eventos_significativos">{{evento.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>C.U.F.D.</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <select [compareWith]="compararObjectos" id="sucursal_cufd" name="sucursal_cufd" class="form-control" [(ngModel)]="evento_significativo.sin_sucursal_cufd">
                    <option [ngValue]="sucursal_cufd" *ngFor="let sucursal_cufd of cufds">{{sucursal_cufd.createdAt | date:'dd/MM/yyyy - HH:mm'}} {{sucursal_cufd.codigo_cufd}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-2 col-md-2">
                <label>Fecha Inicio</label>
            </div>
            <div class="col-sm-4 col-md-4">
                <div class="input-group">
                    <input required name="fecha_inicio" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="evento_significativo.fecha_inicio_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
            </div>
            <div class="col-sm-2 col-md-2">
                <label>Hora</label>
            </div>
            <div class="col-sm-4 col-md-4">
                <div class="input-group">
                    <ngb-timepicker [(ngModel)]="evento_significativo.hora_inicio"></ngb-timepicker>
                </div>  
            </div>
        </div>
        <ng-container *ngIf="evento_significativo.evento.nombre_corto=='5' || evento_significativo.evento.nombre_corto=='6' || evento_significativo.evento.nombre_corto=='7'">
            <hr>
            <div class="row">
                <div class="col-sm-2 col-md-2">
                    <label>Fecha Fin</label>
                </div>
                <div class="col-sm-4 col-md-4">
                    <div class="input-group">
                        <input required name="fecha_fin" class="form-control" placeholder="dd/mm/yyyy"
                                name="df" [(ngModel)]="evento_significativo.fecha_fin_texto" ngbDatepicker #df="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="df.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>
                <div class="col-sm-2 col-md-2">
                    <label>Hora</label>
                </div>
                <div class="col-sm-4 col-md-4">
                    <div class="input-group">
                        <ngb-timepicker [(ngModel)]="evento_significativo.hora_fin"></ngb-timepicker>
                    </div>  
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <label>Código Cafc</label>
                </div>
                <div class="col-sm-12 col-md-7">
                    <select *ngIf="bandera_cafc" id="codigo_cafc" name="codigo_cafc" class="form-control" [(ngModel)]="evento_significativo.codigo_cafc">
                        <option [ngValue]="null" [selected]="true">Seleccionar</option>
                        <option value="{{codigo_cafc.codigo_cafc}}" *ngFor="let codigo_cafc of codigos_cafc">{{codigo_cafc.codigo_cafc}}</option>
                    </select>
                    <input *ngIf="!bandera_cafc" name="cafc" type="text" [(ngModel)]="evento_significativo.codigo_cafc" placeholder="cafc" class="form-control" />
                </div>
                <div class="col-sm-12 col-md-2">
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarBanderaCafc()">
                        <i class="ace-icon fa fa-exchange-alt bigger-130"></i>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #ventas_evento_significativo let-modal>
	<div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Lista de ventas del Evento Significativo</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <table class="table table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th>Nº</th>
                <th>Num. Doc.</th>
                <th>Cliente</th>
                <th>Fecha</th>
                <th>Total</th>
                <th>Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let venta of evento_significativo.ventas; let i=index;">
                <td>{{i + 1}}</td>
                <td>{{venta.factura}}</td>
                <td>{{venta.cliente.identificacion}}</td>
                <td>{{venta.fecha | date:"dd/MM/yyyy"}}</td>
                <td>{{venta.total}}</td>
                <td></td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>