import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { ComprobantesService } from '../../services/comprobantes/comprobantes.service';
import { PlanDeCuentasService } from '../../services/plan-de-cuentas/plan-de-cuentas.service';
import { LibroMayorComponent } from '../libro-mayor/libro-mayor.component';

declare const $:any;

@Component({
  selector: 'app-registro-comprobante',
  templateUrl: './registro-comprobante.component.html',
  styleUrls: ['./registro-comprobante.component.css']
})
export class RegistroComprobanteComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  comprobante:any;
  sucursales:any[]=[];
  tipo_cambio:any=null;
  cuenta_vista:any;
  cuentas_auxiliares:any[]=[];
  asiento:any={};
  cuentas_apropiacion:any[]=[];
  searching = false;
  searchFailed = false;
  tipos_comprobantes:any[]=[];

  total_debe:number=0;
  total_haber:number=0;
  total_debe_sus:number=0;
  total_haber_sus:number=0;

  libroMayorModal:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
	  private toastr: ToastrService,
    private comprobanteService:ComprobantesService,
    private planDeCuentasService:PlanDeCuentasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    if(!this.comprobante){
      let fecha_actual=new Date();
      this.comprobante={
        id:null,
        tipo:null,
        tipos_comprobante:[],
        tipo_cambio:null,
        usuario:this.usuario,
        fecha_texto:{
          year: fecha_actual.getFullYear(), 
          month: (fecha_actual.getMonth()+1),
          day:fecha_actual.getDate()
        },
        abierto:false,
        eliminado:false,
        glosa:null,
        importe:0,
        asientos:[],
        opcion_bimonetario:false,
        id_fuente:null,
        fuente:null,
        datos_fuente:null,
        sucursal:this.sucursales[0]
      }
      this.agregarAsiento();
    }else{
      this.total_debe=this.comprobante.importe;
      this.total_haber=this.comprobante.importe;
      let fecha_comprobante=new Date(this.comprobante.fecha);
      this.comprobante.fecha_texto={
        year: fecha_comprobante.getFullYear(), 
        month: (fecha_comprobante.getMonth()+1),
        day:fecha_comprobante.getDate()
      }
      this.comprobante.asientos=Util.construirAsientos(this.comprobante.asientos); 
    }

    this.obtenerCuentasGenericas();
    this.obtenerTiposComprobantes();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerTiposComprobantes(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_COMPROBANTES).subscribe((res:any)=>{
      this.tipos_comprobantes=res.clases;
    });
  }

  agregarAsiento() {
    this.comprobante.asientos.push({ glosa: "", cuenta:null, debe_bs:0, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false,cuenta_busqueda:null });
  }

  establecerCuentaVista(cuenta){
    this.cuenta_vista = cuenta;
  }

  reiniciarCuenta(asiento){
    asiento.cuenta=null;
    asiento.cuenta_a_buscar=null;
  }

  buscarCuenta = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
    switchMap( (term) =>  this.planDeCuentasService.buscarCuenta(this.usuario.id_empresa,term,"TCAPR")),
    catchError(() => {
    this.searchFailed = true;
    return of([]);
    })  ,
    tap(() => this.searching = false)
  )

  formatter = (x: any) => (x.id?(x.codigo)+" "+(x.nombre):"");

  establecerCuentaActual(event,asiento,i){
    let cuenta=(event.id)?event:event.item;
    asiento.cuenta_a_buscar=cuenta;
    asiento.cuenta=cuenta;
    this.abrirCuentasAuxiliares(asiento,i);
  }

  cambiarAsientoCuentaBusqueda(asiento,cuenta_busqueda){
    asiento.cuenta_busqueda=!cuenta_busqueda;
    asiento.cuenta=asiento.cuenta;
  }

  abrirLibroMayorCuenta(cuenta){
    this.libroMayorModal = this.modalService.open(LibroMayorComponent, {windowClass:"libro-mayor", ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"lg"});
    this.libroMayorModal.componentInstance.cuenta = cuenta;
    this.libroMayorModal.componentInstance.usuario = this.usuario;
    
    this.libroMayorModal.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          //this.establecerCliente(res.cliente);
          this.libroMayorModal.close();
        }
      }else{
        this.libroMayorModal.close();
      }
      
    });
  }

  eliminarAsiento(asiento) {
    if(asiento.id){
      asiento.eliminado = true;
      if(asiento.cuenta.cuenta_auxiliar && asiento.cuenta.cuenta_auxiliar.asiento){
        asiento.cuenta.cuenta_auxiliar.asiento.eliminado=true;
      }
    }else{
      this.comprobante.asientos.splice(this.comprobante.asientos.indexOf(asiento),1);
    }
    this.calcularTotales();
  }

  calcularTotales() {
    this.total_debe=0;
    this.total_haber=0;
    this.total_debe_sus=0;
    this.total_haber_sus=0;
    for(var i=0;i<this.comprobante.asientos.length;i++){
      if(!this.comprobante.asientos[i].eliminado){
        this.comprobante.asientos[i].debe_bs=this.comprobante.asientos[i].debe_bs==undefined?0:this.comprobante.asientos[i].debe_bs;
        this.comprobante.asientos[i].haber_bs=this.comprobante.asientos[i].haber_bs==undefined?0:this.comprobante.asientos[i].haber_bs;
        this.comprobante.asientos[i].debe_sus=this.comprobante.asientos[i].debe_sus==undefined?0:this.comprobante.asientos[i].debe_sus;
        this.comprobante.asientos[i].haber_sus=this.comprobante.asientos[i].haber_sus==undefined?0:this.comprobante.asientos[i].haber_sus;
        
        this.total_debe=this.total_debe+this.comprobante.asientos[i].debe_bs;
        this.total_haber=this.total_haber+this.comprobante.asientos[i].haber_bs;
        this.total_debe_sus=this.total_debe_sus+this.comprobante.asientos[i].debe_sus;
        this.total_haber_sus=this.total_debe_sus+this.comprobante.asientos[i].haber_sus;
      }
    }

    this.total_debe=Math.round(this.total_debe * 10000) / 10000;
    this.total_haber=Math.round(this.total_haber * 10000) / 10000;
    this.total_debe_sus=Math.round(this.total_debe_sus * 10000) / 10000;
    this.total_haber_sus=Math.round(this.total_debe_sus * 10000) / 10000;
    this.comprobante.importe=this.total_debe;
  }

  obtenerCuentasGenericas(){
    this.blockUI.start();
    this.planDeCuentasService.obtenerCuentasGenericas(this.usuario.id_empresa).subscribe((cuentas_apropiacion:any)=>{
      this.blockUI.stop();
      this.cuentas_apropiacion=[];
      let id_cuentas_padres = cuentas_apropiacion.filter((cuenta, i, arr) => arr.findIndex(t => t.id_cuenta_padre === cuenta.id_cuenta_padre) === i);
      for(let i=0;i<id_cuentas_padres.length;i++){
        let cuentas_apropiacion_filtradas=cuentas_apropiacion.filter(e => e.id_cuenta_padre == id_cuentas_padres[i].id_cuenta_padre);
        var cuenta_apropiacion={
          nombre:id_cuentas_padres[i].cuenta_padre?id_cuentas_padres[i].cuenta_padre.nombre:"",
          cuentas:cuentas_apropiacion_filtradas,
        }
        this.cuentas_apropiacion.push(cuenta_apropiacion);
      }
    });
  }

  abrirCuentasAuxiliares(asiento,i){
    this.blockUI.start();
    this.planDeCuentasService.obtenerCuentasAuxiliares(asiento.cuenta.id).subscribe((cuentas_auxiliares:any)=>{
      asiento.cuenta.cuentas_auxiliares=cuentas_auxiliares;
      let j=i;
      setTimeout(function(){
        $('#'+(j).toString()+'-ca')
        .popover({
            container: 'body',
            trigger: 'focus',
            template: '<div class="popover brc-success-m3" role="tooltip"><div class="arrow brc-success"></div><h3 class="popover-header border-0 bgc-success-m2 text-dark-tp4 text-600"></h3><div class="popover-body text-grey-d2 text-110"></div></div>'
        });
      },1000);
      this.blockUI.stop();
    });
  }

  guardarRegistroComprobante(){
    if(this.validarRegistroComprobante()){
      this.blockUI.start();
      this.comprobante.fecha=Util.convertirObjetoAfecha(this.comprobante.fecha_texto);
      if(this.comprobante.id){
        this.comprobanteService.actualizarComprobante(this.comprobante).subscribe((res:any)=>{
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            this.alTerminar.emit(res);
          }
        });
      }else{
        this.comprobanteService.guardarComprobante(this.comprobante).subscribe((res:any)=>{
          this.blockUI.stop();
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
          }else{
            this.toastr.success(res.mensaje);
            this.alTerminar.emit(res);
          }
        });
      }
    }
  }

  convertirMonedaAdolares(asiento){
    this.calcularTotales();
    if(this.tipo_cambio.id && typeof(this.tipo_cambio) !=='string'){
      asiento.debe_sus=Math.round((asiento.debe_bs/this.tipo_cambio.dolar)*10000)/10000;
      asiento.haber_sus=Math.round((asiento.haber_bs/this.tipo_cambio.dolar)*10000)/10000;
    }else{
      this.toastr.error("Tipo de cambio de moneda no establecido.");
    }
  }

  convertirDolaresAmoneda(asiento){
    if(this.tipo_cambio.id && typeof(this.tipo_cambio) !=='string'){
      asiento.debe_bs=Math.round((this.tipo_cambio.dolar*asiento.debe_sus)*10000)/10000;
      asiento.haber_bs=Math.round((this.tipo_cambio.dolar*asiento.haber_sus)*10000)/10000;
      this.calcularTotales();
    }else{
      this.toastr.error("Tipo de cambio de moneda no establecido.");
    }
  }

  validarRegistroComprobante(){
    let res=true;
    if (!this.comprobante.tipo) {
      res=res && false;
      this.toastr.error("Debe especificar el tipo de comprobante!");
    }
    if (this.total_debe<=0 || this.total_debe != this.total_haber) {
      res=res && false;
      this.toastr.error("La suma total del DEBE y HABER deben ser iguales y mayores a 0!");
    }
    if(this.comprobante.opcion_bimonetario && this.total_debe_sus!=this.total_haber_sus){
      res=res && false;
      this.toastr.error("La suma total del DEBE y HABER dolares deben ser iguales y mayores a 0!");
    }
    if(!this.comprobante.fecha_texto || !this.comprobante.fecha_texto.year || !this.comprobante.fecha_texto.month || !this.comprobante.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el comprobante!");
    }
    if(!this.comprobante.glosa){
      res=res && false;
      this.toastr.error("Debe especificar la glosa general para el comprobante!");
    }
    if(this.comprobante.asientos.length<=0){
      res=res && false;
      this.toastr.error("Debe existir asientos en el comprobante!");
    }else{
      for(let i=0;i<this.comprobante.asientos.length;i++){
        res=res && (this.comprobante.asientos[i].cuenta.id && (this.comprobante.asientos[i].debe_bs>0 || this.comprobante.asientos[i].haber_bs>0));
      }
      if(!res){
        this.toastr.error("El registro del DEBE y HABER deben tener valores válidos!");
      }
    }
    return res;
  }

}
