import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { NoticiasComponent } from './components/noticias/noticias.component';

const routes: Routes = [
  { path: 'noticias',component: NoticiasComponent,canActivate:[HabilitadoGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeriodismoRoutingModule { }
