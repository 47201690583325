import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { MensajesEmpresaService } from '../../services/mensajes-empresa/mensajes-empresa.service';

@Component({
  selector: 'app-mensajes-empresa',
  templateUrl: './mensajes-empresa.component.html',
  styleUrls: ['./mensajes-empresa.component.css']
})
export class MensajesEmpresaComponent extends BaseComponent implements OnInit {

  empresa:any;
  lista_mensajes:any[]=[];
  lista_plantillas_mensajes:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private mensajesEmpresaService:MensajesEmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.mensajesEmpresaService.obtenerMensajesEmpresa(this.empresa.id).subscribe((res:any)=>{
        this.lista_mensajes=res;
    });
    this.obtenerPlantillasMensajes();
  }

  obtenerPlantillasMensajes(){
    this.mensajesEmpresaService.obtenerPlantillasMensajes().subscribe((res:any)=>{
      this.lista_plantillas_mensajes=res;
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  guardarMensajesEmpresa(){
    this.blockUI.start();
    this.mensajesEmpresaService.guardarMensajesEmpresa(this.lista_mensajes).subscribe((res:any)=>{
      this.blockUI.stop();
        this.toastr.success(res.mensaje);
        this.alTerminar.emit();
    });
  }

}
