import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { Paginator } from 'src/app/models/paginator';
import { InventariosService } from 'src/app/snapquick/services/inventarios/inventarios.service';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { Util } from 'src/app/utils/utils';
import { EmpresaService } from '../../services/empresas/empresa.service';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

class BusquedaCatalogo extends BaseComponent{

  productos:any[]=[];

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private productosService:ProductosService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.productosService.obtenerListaProductos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.productos=dato.productos;
			this.blockUI.stop();
		});
  }
}

class BusquedaInventario extends BaseComponent{

  productos:any[]=[];

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private inventariosService:InventariosService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.inventariosService.obtenerListaInventarios(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.productos=dato.productos;
			this.blockUI.stop();
		});
	}
}

declare const aplicarVisorImagenes:any;
declare const $:any;

@Component({
  selector: 'app-busqueda-producto',
  templateUrl: './busqueda-producto.component.html',
  styleUrls: ['./busqueda-producto.component.css']
})
export class BusquedaProductoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  usuario:any;
  catalogo:BusquedaCatalogo;
  inventario:BusquedaInventario;

  @ViewChildren('productos') productos_lectura: QueryList<any>;
  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private productosService:ProductosService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private empresasService:EmpresaService,
    private inventariosService:InventariosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.catalogo=new BusquedaCatalogo(this.generalService,this.persistenciaService,this.productosService,this.usuarioService,this.modalService);
    this.inventario=new BusquedaInventario(this.generalService,this.persistenciaService,this.inventariosService,this.usuarioService,this.modalService);
    this.catalogo.filter={
			id_empresa:this.usuario.id_empresa,
			grupo: {nombre:"TODOS",id:0},//this.grupos_filtro[0],
			subgrupo:{nombre:"TODOS",id:0},//this.subgrupos_filtro[0],
			grupos_filtro:[{nombre:"TODOS",id:0}],
			subgrupos_filtro:[{nombre:"TODOS",id:0}]
		}
    this.inventario.filter={
			id_empresa:this.usuario.id_empresa,
			grupo: {nombre:"TODOS",id:0},//this.grupos_filtro[0],
			subgrupo:{nombre:"TODOS",id:0},//this.subgrupos_filtro[0],
			grupos_filtro:[{nombre:"TODOS",id:0}],
			subgrupos_filtro:[{nombre:"TODOS",id:0}],
      sucursales:Util.obtenerSucursalesUsuario(this.usuario),
      sucursal: Util.obtenerSucursalesUsuario(this.usuario)[0],
      almacenes:[],
      almacen: {},
		}
    this.inventario.column = "codigo";
    await this.obtenerSucursales();
    await this.obtenerAlmacenesSucursal(this.inventario.filter.sucursal);
		this.obtenerGruposProductosEmpresa();
		this.obtenerSubGruposProductosEmpresa();
    this.catalogo.column = "codigo";
		this.catalogo.getSearch(this.catalogo.text_search,null);

		//this.inventario.getSearch(this.catalogo.text_search,null);
  }

  obtenerGruposProductosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((tipoGrupoEmpresa:any) => {
			this.catalogo.filter.grupos_filtro=this.catalogo.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
      this.inventario.filter.grupos_filtro=this.inventario.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
		});
	}

	obtenerSubGruposProductosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((tipoSubgrupoEmpresa:any) => {
			this.catalogo.filter.subgrupos_filtro=this.catalogo.filter.subgrupos_filtro.concat(tipoSubgrupoEmpresa.clases);
      this.inventario.filter.subgrupos_filtro=this.inventario.filter.subgrupos_filtro.concat(tipoSubgrupoEmpresa.clases);
		});
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  ngAfterViewInit() {
		this.productos_lectura.changes.subscribe(t => {
			aplicarVisorImagenes("imagen-producto-vista");
		});
		this.obtenerTextosEmpresa();
	}

  seleccionarProducto(producto){
    this.alTerminar.emit(producto);
  }

  async obtenerAlmacenesSucursal(sucursal){
		let datos_sucursal:any=await this.empresasService.obtenerSucursal(sucursal.id).toPromise();
		this.inventario.filter.almacenes=datos_sucursal.almacenes;
		if(this.usuario.empresa.usar_almacenes_usuario){
			let almacenes:any=await this.empresasService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
			this.inventario.filter.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
		}
		this.inventario.filter.almacen=this.inventario.filter.almacenes.length>0?this.inventario.filter.almacenes[0]:{};
    this.inventario.getItems();
	}

  sumarCantidadAlmacen(inventarios, sucursal, almacen) {
		var suma = 0;
    if(inventarios){
      for (var i = 0; i < inventarios.length; i++) {
        suma = suma + inventarios[i].cantidad;
      }
    }
		return suma;
	}

  sumarCostoTotalAlmacen (inventarios, sucursal, almacen) {
		var suma = 0;
    if(inventarios){
      for (var i = 0; i < inventarios.length; i++) {
        suma = suma + inventarios[i].costo_total;
      }
    }
		return suma;
	}

  async obtenerSucursales(){
    let sucursales=await this.empresasService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
    this.inventario.filter.sucursales=sucursales;
    this.inventario.filter.sucursal=sucursales[0];
  }

  verDetalleInventario(producto) {
		this.inventariosService.obtenerInventariosProducto(producto.id,this.inventario.filter.almacen.id).subscribe((inventarios:any[]) => {
			producto.inventarios = inventarios
		});
		var style = $("#" + producto.id).css("display");
		if (style == "none") {
			$("#" + producto.id).css("display", "");
		} else {
			$("#" + producto.id).css("display", "none");
		}

	}

}
