import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProveedoresService } from '../../services/proveedores/proveedores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { ListaPagosComprasComponent } from '../lista-pagos-compras/lista-pagos-compras.component';
import { PagoCompraComponent } from '../pago-compra/pago-compra.component';
import { VistaCompraComponent } from '../vista-compra/vista-compra.component';
import { ProveedoresHistoricoComprasComponent } from '../proveedores-historico-compras/proveedores-historico-compras.component';

declare const $:any;

@Component({
  selector: 'app-cuentas-por-pagar',
  templateUrl: './cuentas-por-pagar.component.html',
  styleUrls: ['./cuentas-por-pagar.component.css']
})
export class CuentasPorPagarComponent extends BaseComponent implements OnInit {

  proveedores:any[]=[];
  datosProveedor:any;
  proveedor:any;
  modalPagos:NgbModalRef;
  totalgeneral:number=0;
  total_importe:number=0;
  total_a_cuenta:number=0;
  total_saldo:number=0;
  proveedorCompras:any;
  pago_general:any={};

  modalHistoricoCompras:NgbModalRef;
  
  cuentaproveedor_modal:NgbModalRef;
  @ViewChild('cuentaproveedor')
  private cuentaproveedor_ref: TemplateRef<any>;

  pago_general_modal:NgbModalRef;
  @ViewChild('pago_general_modal')
  private pago_general_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private proveedoresService:ProveedoresService,
    public usuarioService:UsuarioService,
    private pdfService:PdfService,
    private compraService:ComprasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  
    ngOnInit(): void {
      this.init();
      this.buscarSubAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      this.filter={
        id_empresa:this.usuario.id_empresa,
        sucursales:this.usuario.empresa.usar_clientes_sucursal?Util.obtenerSucursalesUsuario(this.usuario):[{nombre:"TODOS",id:0}],
        sucursal: this.usuario.empresa.usar_clientes_sucursal?Util.obtenerSucursalesUsuario(this.usuario)[0]:{nombre:"TODOS",id:0},
        usuarios:[{nombre_usuario:"TODOS",id:0}],
        usuario: {nombre_usuario:"TODOS",id:0},
        proveedor:null,
        cuentas_liquidadas:false
      }
      this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_PAGAR);

      let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(es_usuario_administrador){	
          this.obtenerUsuarios();
        }else{
          this.filter.usuarios=[this.usuario]
          this.filter.usuario=this.usuario;
        }
      }else{
        this.obtenerUsuarios();
      }

      this.column = "codigo";
      this.getSearch(this.text_search,null);
    }

    obtenerUsuarios(){
      this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
        this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
      });
    }

    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.proveedoresService.obtenerCuentasPorPagar(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.proveedores=dato.proveedores;
        this.blockUI.stop();
      });
    }

    async abrirEstadoCuentaProveedor(proveedor){
      /*this.proveedor=proveedor?proveedor:this.proveedor;
      await this.obtenerCuentaProveedor();
      this.cuentaproveedor_modal=this.modalService.open(this.cuentaproveedor_ref, {windowClass:'cuenta-por-cobrar',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
        this.cuentaproveedor_modal.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });*/

      this.modalHistoricoCompras = this.modalService.open(ProveedoresHistoricoComprasComponent, {windowClass:'historico-compras', scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modalHistoricoCompras.componentInstance.proveedor = proveedor;
      this.modalHistoricoCompras.componentInstance.usuario = this.usuario;
      this.modalHistoricoCompras.componentInstance.mostrar_filtro_fechas = false;
      this.modalHistoricoCompras.componentInstance.mostrar_boton_busqueda = false;
      this.modalHistoricoCompras.componentInstance.cuentas_liquidadas = this.filter.cuentas_liquidadas;
      this.modalHistoricoCompras.componentInstance.titulo_reporte = "CUENTAS POR PAGAR";
      
      this.modalHistoricoCompras.componentInstance.alTerminar.subscribe((res) => {
        this.modalHistoricoCompras.close();
      });
    }

    verPagos(){
      this.modalPagos = this.modalService.open(ListaPagosComprasComponent, {windowClass:'lista-pagos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.modalPagos.componentInstance.compra = null;
      this.modalPagos.componentInstance.usuario = this.usuario;
      this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.toastr.success(res.mensaje);
            this.modalPagos.close();
          }
        }else{
          this.modalPagos.close();
        }
        
      });
    }
}
