import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { GlobalVariable } from 'src/app/global';
import { CursosService } from '../../services/cursos/cursos.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-concepto-ingreso-cursos',
  templateUrl: './concepto-ingreso-cursos.component.html',
  styleUrls: ['./concepto-ingreso-cursos.component.css']
})
export class ConceptoIngresoCursosComponent extends BaseComponent implements OnInit {

  concepto_pago:any;
  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public conceptoIngresoService:ConceptosIngresosService,
    public cursosService:CursosService,
    private toastr: ToastrService,
	  public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.filter={
      cursos:[],
      nivel:null,
      curso:null
    };
    this.obtenerNivelesEscolares();
  }

  async obtenerNivelesEscolares(){
    this.filter.niveles=[];
    let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
    this.filter.niveles=this.filter.niveles.concat(niveles_escolares.clases);
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    this.filter.cursos=[];
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=this.filter.cursos.concat(cursos);
    this.filter.curso=null;
  }

  agregarCurso(){
    let rest=this.concepto_pago.cursos.filter(e => (e.curso.id == this.filter.curso.id && e.curso.nivel.id==this.filter.nivel.id));
		if(rest.length>0){
			this.toastr.info("Ya existen un nivel y curso con el mismo valor en el concepto de pago!");
		}else{
			var curso_concepto_pago={
				curso:this.filter.curso
			}
			curso_concepto_pago.curso.nivel=this.filter.nivel;
			this.concepto_pago.cursos.push(curso_concepto_pago);
		}
  }

  cerrarModal(){
    this.onConfirm.emit({confirm:false});
  }

  eliminarCursoConceptoPago(curso_concepto_pago){
    curso_concepto_pago.eliminado=true;
  }

  guardarCursosConceptoPago(){
    this.blockUI.start();
    this.conceptoIngresoService.actualizarConceptoPagoColegio(this.concepto_pago).subscribe((res:any) => {
      this.toastr.success(res.mensaje);
      this.conceptoIngresoService.obtenerConceptoPagoColegio(this.concepto_pago).subscribe((concepto_ingreso_con:any)=>{
        this.concepto_pago=concepto_ingreso_con;
        this.blockUI.stop();
      });
    });
  }

  sincronizarCursoConceptoPago(curso_concepto_pago){
    this.blockUI.start();
    this.conceptoIngresoService.sincronizarCursoConceptoPago({id_curso:curso_concepto_pago.curso.id,id_concepto_pago:this.concepto_pago.id,id_gestion:this.concepto_pago.gestion.id}).subscribe((res:any) => {
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

}
