import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'app-configuracion-aplicacion-compras',
  templateUrl: './configuracion-aplicacion-compras.component.html',
  styleUrls: ['./configuracion-aplicacion-compras.component.css']
})
export class ConfiguracionAplicacionComprasComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  tipos_documentos:any[]=[];
  tipos_pago:any[]=[];
  tamanos_papel:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  disenios_logo_impresion:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTiposPago();
    this.obtenerTiposDocumentos();
    this.obtenerTamanosPapel();
    this.obtenerDiseniosLogoImpresion();
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
  }

  obtenerTiposPago(){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tipos_pago=entidad.clases;
      this.blockUI.stop();
    });
  }

  obtenerDiseniosLogoImpresion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_DISENIOS_LOGO_IMPRESION).subscribe((entidad:any) => {
      this.disenios_logo_impresion=entidad.clases;
    });
  }

  obtenerTiposDocumentos(){
    this.generalService.obtenerClases("TIPDOC").subscribe((entidad:any) => {
      this.tipos_documentos=entidad.clases;
    });
  }

  obtenerTamanosPapel(){
    this.generalService.obtenerClases("TAMPAPFACT").subscribe((entidad:any) => {
      this.tamanos_papel=entidad.clases;
    });
  }

  seleccionarCanal(e){
    this.cdr.detectChanges();
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
