<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Lecturación
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Lecturas de medidores de agua
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
                      <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="feini">Fecha Inicial </label>&nbsp;
                            <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="fefin">Fecha Fin </label>&nbsp;
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>  
                     </div>
                      <div class="col-md-1 col-sm-12"><label>Vecino </label></div>
					  <div class="col-md-3 col-sm-12">
                        <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.vecino">
                      </div>
					  <div class="col-md-2 col-sm-12">
						<label>Periodo </label> 
						  <select [compareWith]="compararObjectos" class="form-control" required name="periodo" [(ngModel)]="filter.periodo">
							  <option [ngValue]="periodo" *ngFor="let periodo of filter.periodos">{{periodo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-md-3 col-sm-12">
						<label>Mes </label>&nbsp;
						  <select [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes">
							  <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
						  </select>
                      </div>
					  <div class="col-md-3 col-sm-12">
						<label>Cod. Medidor </label>
                        <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.codigo_medidor">
					  </div>
					  <div class="col-md-3 col-sm-12">
						<label>Categoria </label>
						  <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="filter.categoria">
							  <option [ngValue]="categoria" *ngFor="let categoria of filter.categorias">{{categoria.nombre}}</option>
						  </select>
                      </div>
					  <div class="col-md-1 col-sm-12">
						<label>¿Inferido? </label>
                        <label>
                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.inferido"/>
                        </label>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
				<i class="fa fa fa-search"></i>
			  </button>
			  <button title="Registrar Lectura" (click)="crearNuevaLectura()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
			  </button>
			  <button title="Lista-Lecturacion" (click)="abrirListaLecturacion()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
				<i class="fa fa-plus text-primary"></i>
			  </button>
              <div class="export btn-group">
				<!--<button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
					<i class="fa fa-download text-green-d1"></i>
					<span class="caret"></span>
				</button>-->
				<button (click)="descargarExcelEjemploImportacionHistorial()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación histórico" aria-expanded="false">
					<i class="fa fa-download text-green-d1"></i>
					<span class="caret"></span>
				  </button>
				<button (click)="clickBotonSubir('subir-excel-lecturacion-historico')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-lecturacion-historico" style="display:none" type="file" class="form-control" (change)="subirExcelLecturacionHistorica($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
			  </div>
			  <div class="keep-open btn-group show" title="Columns">
				<button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
					<i class="fa fa-wrench text-blue-d1"></i>
					<span class="caret"></span>
				</button>

				<div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
					<div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
						<div class="card bgc-primary brc-primary radius-0">
						  <div class="card-header">
							<h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
						  </div>
	  
						  <div class="card-body p-0 bg-white">
							<table class="table table-striped table-hover mb-0">
							  <tbody>
								<tr>
								  <td class="text-dark-m2">
									Importación precios
								  </td>
								  <td class="text-dark-m2">
									<a href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
								  </td>
								  <td>
									<a href="javascript:void(0)" class="text-blue"><i class="fa fa-upload text-blue"></i></a>
								  </td>
								</tr>
								<tr>
									<td class="text-dark-m2">
										Descarga catálogo
									</td>
									<td class="text-dark-m2">
										<a href="javascript:void(0)" (click)="descargarCatalogo()" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
									</td>
									<td>
										<a href="javascript:void(0)" (click)="imprimirCatalogoLecturacion()" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
									</td>
								</tr>
							  </tbody>
							</table>
						  </div>
						</div>
					  </div>
				</div>
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="lecturas_medidores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().codigo_vecino.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().nombre.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Nombre</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().codigo_medidor.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo_medidor' && this.direction=='asc','desc':this.column=='codigo_medidor' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('codigo_medidor')">Código Medidor</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().gestion.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='gestion' && this.direction=='asc','desc':this.column=='gestion' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('gestion')">Gestión</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().mes.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='mes' && this.direction=='asc','desc':this.column=='mes' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('mes')">Mes</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().categoria.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='categoria' && this.direction=='asc','desc':this.column=='mes' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('categoria')">Categoria</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().lectura_anterior.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='lectura_anterior' && this.direction=='asc','desc':this.column=='lectura_anterior' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('lectura_anterior')">Lect. Ant.</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().lectura_actual.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='lectura_actual' && this.direction=='asc','desc':this.column=='lectura_actual' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('lectura_actual')">Lect. Act.</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().consumo.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='consumo' && this.direction=='asc','desc':this.column=='consumo' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('consumo')">Consumo</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().inferido.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='consumo' && this.direction=='asc','desc':this.column=='consumo' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('consumo')">¿Inferido?</a>
					</div><div class="fht-cell"></div>
				</th>
				<th class="center" *ngIf="configuracion_pagina.getConfiguration().observacion.show">
					<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='consumo' && this.direction=='asc','desc':this.column=='consumo' && this.direction=='desc'}">
						<a href="javascript:void(0)" (click)="this.sortColumn('consumo')">Observacion</a>
					</div><div class="fht-cell"></div>
				</th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lectura_medidor of lecturas_medidores; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
				<td *ngIf="configuracion_pagina.getConfiguration().codigo_vecino.show">
					{{lectura_medidor.accion_agua.vecino.cliente.codigo}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
					{{lectura_medidor.accion_agua.vecino.cliente.persona.apellido_paterno+' '+lectura_medidor.accion_agua.vecino.cliente.persona.apellido_materno+' '+lectura_medidor.accion_agua.vecino.cliente.persona.nombres}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().codigo_medidor.show">
					{{lectura_medidor.accion_agua.codigo_medidor}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().gestion.show">
					{{lectura_medidor.gestion.nombre}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().mes.show">
					{{lectura_medidor.mes.nombre}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().categoria.show">
					{{lectura_medidor.accion_agua.categoria.nombre}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().lectura_anterior.show">
					{{lectura_medidor.lectura_anterior}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().lectura_actual.show">
					{{lectura_medidor.lectura_actual}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().consumo.show">
					{{lectura_medidor.consumo_cubos}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().inferido.show">
					{{lectura_medidor.inferido?'SI':'NO'}}
				</td>
				<td *ngIf="configuracion_pagina.getConfiguration().observacion.show">
					{{lectura_medidor.observacion}}
				</td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">              
                    <a class="text-success mx-2px" href="javascript:void(0)" (click)="modificarLecturaMedidor(lectura_medidor)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					</a>
					<a class="text-blue mx-2px" href="javascript:void(0)" (click)="modificarLecturacion(lectura_medidor.accion_agua.vecino)">          
						<i class="fa fa-faucet text-105"></i>        
					  </a> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="lecturas_medidores.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #edicionlectura let-modal>
	<div class="modal-header card-header">
		<div class="col-md-8 col-xs-8">
			<span class="text-blue text-125">Registro Lectura</span>
		</div>
		<div class="col-md-6 col-xs-6">
			<button (click)="guardarLecturaMedidor()" class="btn btn-primary"  [disabled]="!lectura_medidor.lectura_actual">
				<i class="fa fa-save text-110 mr-1"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="fa fa-times red2 text-110 mr-1"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="row">
			<div class="col-6">
				<label for="form-field-select-1">Periodo</label>
				<select [disabled]="lectura_medidor.id" [compareWith]="compararObjectos" class="form-control" required name="periodo_registro" [(ngModel)]="lectura_medidor.gestion">
					<option [ngValue]="periodo" *ngFor="let periodo of gestiones">{{periodo.nombre}}</option>
				</select>
			</div>
			<div class="col-6">
				<label for="form-field-select-11">Mes</label>
				<select [disabled]="lectura_medidor.id" [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="lectura_medidor.mes" (change)="buscarLecturaAnterior()">
					<option [ngValue]="mes" *ngFor="let mes of meses">{{mes.nombre}}</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="col-10">
				<label for="vecino">Vecino</label>
				<input [disabled]="vecino_seleccionado" [inputFormatter]="formatter" (selectItem)="establecerVecino()" [resultTemplate]="rt" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="vecino" [ngbTypeahead]="buscarVecino" placeholder="Buscar vecino" />
				<small *ngIf="searching" class="form-text text-muted">Buscando...</small>
				<div class="invalid-feedback" *ngIf="searchFailed">Lo sentimos, los productos no pueden ser cargados.</div>
			</div>
			<div class="col-2">
				<button [disabled]="lectura_medidor.id" *ngIf="vecino_seleccionado" class="btn btn-secondary" type="button" (click)="limpiarVecino()">
					<i class="fa fa-broom red2 text-110 mr-1"></i>
				</button>
			</div>
		</div>
		<div class="row">
			<div class="col-4">
				<label for="form-field-select-1">Acción</label>
				<select [disabled]="lectura_medidor.id" *ngIf="vecino" [compareWith]="compararObjectos" class="form-control" required name="accion_agua" [(ngModel)]="lectura_medidor.accion_agua" (change)="buscarLecturaAnterior()">
					<option [ngValue]="accion_agua" *ngFor="let accion_agua of vecino.acciones_agua">{{accion_agua.codigo_medidor+' - '+accion_agua.categoria.nombre}}</option>
				</select>
			</div>
			<div class="col-4">
				<label for="form-field-select-11">Lectura Anterior</label>
				<input [disabled]="true" type="number" class="form-control" [(ngModel)]="lectura_medidor.lectura_anterior" placeholder="Lect. Anterior" />
			</div>
			<div class="col-4">
				<label for="form-field-select-11">Lectura Actual</label>
				<input type="number" step="0" class="form-control" [(ngModel)]="lectura_medidor.lectura_actual" placeholder="Lect. Actual" (keyup)="calcularConsumo()" />
			</div>
		</div>

		<div class="row">
			<div class="col-8">
				<label for="form-field-select-11">Observación</label>
				<textarea class="form-control" maxlength="50" [(ngModel)]="lectura_medidor.observacion" placeholder="Observación"></textarea>
			</div>
			<div class="col-4">
				<label for="form-field-select-1">Consumo</label>
				<input [disabled]="true" type="number" class="form-control" [(ngModel)]="lectura_medidor.consumo_cubos"/>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
	<img [src]="rest_server+r.cliente.persona.imagen" class="mr-1" style="width: 16px">
	<ngb-highlight [result]="r.cliente.persona.apellido_paterno+' '+r.cliente.persona.apellido_materno+' '+r.cliente.persona.nombres" [term]="t"></ngb-highlight>
  </ng-template>

<ng-template #importacionlecturacionhistorica let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Lecturacion a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || lecturas_historicas.length==0" (click)="guardarLecturacionHistorica()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary">
            <div class="box-body">
                <div class="alert alert-danger" *ngIf="mensaje_importacion">
                    <strong>
                        <i class="ace-icon fa fa-times"></i>
                        Oh no!
                    </strong>
                    {{mensaje_importacion}}.
                    <br>
                </div>
                <div class="table-fixed-header">
                    <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                        <thead fix-head class="table-head-color sticky-nav">
                            <tr>
                                <th>Nº</th>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Código Medidor</th>
                                <th>Categoria</th>
                                <th>Gestión</th>
                                <th>Mes</th>
                                <th>Lect. Ant.</th>
								<th>Lect. Act.</th>
								<th>Consumo</th>
								<th>Observación</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr *ngFor="let vecino of lecturas_historicas; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{vecino.codigo}}</td>
                                <td>{{vecino.nombres}}</td>
                                <td>{{vecino.codigo_medidor}}</td>
                                <td>{{vecino.categoria}}</td>
                                <td>{{vecino.gestion}}</td>
                                <td>{{vecino.mes}}</td>
								<td>{{vecino.lectura_anterior}}</td>
								<td>{{vecino.lectura_actual}}</td>
								<td>{{vecino.consumo_cubos}}</td>
                                <td>{{vecino.observacion}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
	</div>
</ng-template>

<ng-template #registrolecturacion let-modal>
	<div class="modal-header">
		<div class="col-6 col-xs-6">
			<h4 class="widget-title">Lecturación</h4>
		</div>
		<div class="col-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary px-2">
            <div class="box-body">
                <div class="row">
					<div class="col-3 col-sm-3">
						<label>Periodo </label> 
						  <select [compareWith]="compararObjectos" class="form-control" required name="periodo" [(ngModel)]="filtro_lecturacion.gestion">
							  <option [ngValue]="periodo" *ngFor="let periodo of gestiones">{{periodo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-3 col-sm-3">
						<label>Mes </label>&nbsp;
						  <select [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filtro_lecturacion.mes">
							  <option [ngValue]="mes" *ngFor="let mes of meses">{{mes.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-3 col-sm-3">
						<label>Categoria </label>
						  <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="filtro_lecturacion.categoria">
							  <option [ngValue]="categoria" *ngFor="let categoria of filter.categorias">{{categoria.nombre}}</option>
						  </select>
					  </div>
					<div class="col-3 col-xs-3">
						<div class="columns columns-right btn-group float-top">
						<button class="btn btn-primary" type="button" (click)="buscarLecturasMedidor()">
							<i class="ace-icon fa fa-search red2 align-top bigger-110"></i>
						</button>
						<button [disabled]="acciones_agua_filtro.length==0" (click)="descargarExcelImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
							<i class="fa fa-file-excel text-green-d1 bigger-110"></i>
						</button>
						<button (click)="imprimirLecturacionSocios()" class="btn btn-outline-danger btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1" aria-label="Export"  type="button" title="Generar reporte PDF" aria-expanded="false">
							<i class="fa fa-file-pdf text-danger-d1 bigger-110"></i> 
						</button>
						</div>
					</div>
				</div>
				<hr>
                <div class="table-fixed-header">
                    <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                        <thead fix-head class="table-head-color sticky-nav bgc-primary text-white">
                            <tr>
								<th>Nº</th>
								<th>Código Medidor
								</th>
                                <th>Código Vecino
									<input name="filtro-nom" class="form-control text-center" type="text" [(ngModel)]="filtro_lecturacion.nombre" (keyup)="filtrarLecturacionRegistro(filtro_lecturacion.nombre)">
								</th>
                                <th>
									Nombres
									<input name="filtro-nom" class="form-control text-center" type="text" [(ngModel)]="filtro_lecturacion.nombre" (keyup)="filtrarLecturacionRegistro(filtro_lecturacion.nombre)">
								</th>
                                <th>Categoria</th>
                                <th>Gestión</th>
                                <th>Mes</th>
                                <th>Lect. Ant.</th>
								<th>Lect. Act.</th>
								<th>Consumo</th>
								<th>Observación</th>
                            </tr>
                        </thead>
    
                        <tbody>
                            <tr *ngFor="let accion_agua of acciones_agua_filtro; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{accion_agua.codigo_medidor}}</td>
                                <td>{{accion_agua.vecino.cliente.codigo}}</td>
                                <td>{{accion_agua.vecino.cliente.persona.apellido_paterno}} {{accion_agua.vecino.cliente.persona.apellido_materno}} {{accion_agua.vecino.cliente.persona.nombres}}</td>
                                <td>{{accion_agua.categoria.nombre}}</td>
                                <td>{{accion_agua.lecturas_medidor[0].gestion.nombre}}</td>
                                <td>{{accion_agua.lecturas_medidor[0].mes.nombre}}</td>
								<td>{{accion_agua.lecturas_medidor[0].lectura_anterior}}</td>
								<td class="input-cal">
									<input id="sc{{i}}" (keyup)="calcularConsumo()" (change)="guardarLecturaMedidorRegistro()" (keydown)="enfocarLectura($event,i)" name="lecact" class="form-control text-center" type="number" [(ngModel)]="accion_agua.lecturas_medidor[0].lectura_actual" step="0" min="0" (focus)="sincronizarLecturaAnterior(accion_agua.lecturas_medidor[0])">
								</td>
								<td>{{accion_agua.lecturas_medidor[0].consumo_cubos}}</td>
                                <td>
									<input (focus)="establecerlecturaMedidor(accion_agua.lecturas_medidor[0])" (change)="guardarLecturaMedidorRegistro()" name="lecact" class="form-control text-center" type="text" [(ngModel)]="accion_agua.lecturas_medidor[0].observacion">
								</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
	</div>
</ng-template>
