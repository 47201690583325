import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ReporteCursoService {

  constructor(private http: HttpClient) { }

  public obtenerListaEstudiantes(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"reporte-curso/sucursal/"+paginator.filter.sucursal.id+"/gestion/"+paginator.filter.gestion.id+"/nivel/"+paginator.filter.nivel.id+"/curso/"+paginator.filter.curso.id+"/estado/"+paginator.filter.estado.id,GlobalVariable.httpOptions);
  }

  public obtenerReportePagosEstudiantes(paginator){
    return this.http.post(GlobalVariable.API_SERVER_URL+"pagos-estudiantes/gestion/"+paginator.filter.gestion.id+"/nivel/"+paginator.filter.nivel.id+"/curso/"+paginator.filter.curso.id,{conceptos_pagos:paginator.filter.conceptos},GlobalVariable.httpOptions);
  }

  public obtenerMateriasReporteCalificaciones(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias-reporte-calificaciones/gestion/"+paginator.filter.gestion.id+"/curso/"+paginator.filter.curso.id,GlobalVariable.httpOptions);
  }

  public obtenerBoletinCalificaciones(id_empresa,id_gestion,id_curso,id_intervalo_escolar,materias_seleccionadas,ids_estudiantes){
    return this.http.get(GlobalVariable.API_SERVER_URL+"boletin-calificaciones-colegio/empresa/"+id_empresa+"/gestion/"+id_gestion+"/curso/"+id_curso+"/intervalo-escolar/"+id_intervalo_escolar+"/materias/"+materias_seleccionadas+"/estudiantes/"+ids_estudiantes,GlobalVariable.httpOptions);
  }

  public obtenerReportePromediosCalificaciones(id_gestion,id_curso,id_intervalo_escolar,materias_seleccionadas){
    return this.http.get(GlobalVariable.API_SERVER_URL+"promedios-estudiantes/gestion/"+id_gestion+"/curso/"+id_curso+"/intervalo-escolar/"+id_intervalo_escolar+"/materias/"+materias_seleccionadas,GlobalVariable.httpOptions);
  }

  public guardarImportacionPagosEstudiantes(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"importacion-pagos-estudiantes",datos,GlobalVariable.httpOptions);
  }

  public guardarRegistroInscripcionMasiva(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"inscripcion-masiva-estudiantes",datos,GlobalVariable.httpOptions);
  }
}
