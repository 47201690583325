<div class="modal-header">
    <span class="text-blue text-125">Registro Orden de Trabajo</span>

    <div class="card-toolbar ml-auto no-border pr-2 d-none">
    <label>
        <span class="align-middle d-block d-sm-inline">Validation:</span>
        <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
    </label>
    </div>
    <div class="card-toolbar pl-3">
    <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
        <i class="fa fa-chevron-left"></i>
    </button>
    <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
        <i class="fa fa-chevron-right"></i>
    </button>
    <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
        <i class="fa fa-arrow-right"></i>
    </button>
    <button (click)="cerrarComponente()" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
        <i class="fas fa-times-circle"></i>
        </button>
    </div>
</div>
<div class="modal-body px-2 py-2 mb-4">
    <div id="{{wizard_edicion_orden_trabajo}}" class="d-none">
        <ul class="mx-auto">
          <li class="wizard-progressbar"></li>
          <li>
            <a href="#step-1">
              <span class="step-title">
                  1
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Datos de Vehiculo
            </span>
          </li>
          <li>
            <a href="#step-2">
              <span class="step-title">
                  2
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Datos de Propietario
            </span>
          </li>
          <li>
            <a href="#step-3">
              <span class="step-title">
                  3
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Datos de Ingreso
            </span>
          </li>
          <li>
            <a href="#step-4">
              <span class="step-title">
                  4
              </span>

              <span class="step-title-done">
                  <i class="fa fa-check text-success-m1"></i>
              </span>
            </a>
            <span class="step-description">
                    Servicios Solicitados
            </span>
          </li>
        </ul>

        <div class="px-2 py-2 mb-4">
          <div id="step-1" class="">
            <div class="  form-row">
                <div class="col-md-2 col-sm-2 col-xs-2" >
                    
                </div>
                <div class="col-md-3 col-sm-2 col-xs-2" >
                    <label>Búsqueda de Vehiculo</label>
                </div>
                <div class="col-md-4 col-sm-2 col-xs-2">
                    <ng-container >
                        <ng-container *ngIf="orden_trabajo.vehiculo.id">
                            <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{orden_trabajo.vehiculo.placa}} - {{orden_trabajo.vehiculo.marca}} - {{orden_trabajo.vehiculo.modelo}}</span><br>
                        </ng-container>
                        <input id="ot_vehiculo" *ngIf="!orden_trabajo.vehiculo.id" name="vehiculo" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerVehiculo($event)"
                        type="text" class="form-control" [(ngModel)]="vehiculo_busqueda" 
                        [ngbTypeahead]="buscarVehiculo" placeholder="Ingrese placa, modelo o marca" autocomplete="off"
                        />
                    </ng-container>

                    <ng-template #rt let-r="result" let-t="term">
                        <ngb-highlight [result]="r.placa+' '+r.marca+' '+r.modelo" [term]="t"></ngb-highlight>
                    </ng-template>
                </div>
                <div class="col-2 col-sm-2 col-xs-2">
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="orden_trabajo.vehiculo.id" (click)="reiniciarVehiculo()">
                        <i class="fa fa-trash bigger-130 red2"></i>
                    </a>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Placa
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="orden_trabajo.vehiculo.placa" placeholder="Placa" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Modelo
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="orden_trabajo.vehiculo.modelo" placeholder="Modelo" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Marca
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="orden_trabajo.vehiculo.marca" placeholder="Marca" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Descripción
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="ci" name="ci" [(ngModel)]="orden_trabajo.vehiculo.descripcion" placeholder="Descripción" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Kilometraje Actual
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                              <input type="number" step="1" name="kilometraje_actual" id="kilometraje_actual" [(ngModel)]="orden_trabajo.vehiculo.kilometraje_actual" placeholder="Kilometraje Actual" class="form-control form-control-lg col-12 text-dark-m2 pr-45 pl-2"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Combustible
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-form-label">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Observacion
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" name="observaciones" [(ngModel)]="orden_trabajo.observacion" placeholder="Observacion" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Fecha de Ingreso
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                        name="dp" [(ngModel)]="orden_trabajo.fecha_ingreso_texto" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Fecha de Salida
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                        name="dp" [(ngModel)]="orden_trabajo.fecha_salida_texto" ngbDatepicker #g="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="g.toggle()" type="button"></button>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-1 col-form-label text-sm-right">
                    Imagen
                </div>
                <div class="col-3" *ngIf="orden_trabajo.vehiculo.id">
                    <img  src="{{rest_server+orden_trabajo.vehiculo.imagen}}" width="100">
                </div>
                <div class="col-4">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <div>
                            <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-2" class="">
            <div class="  form-row">
                <div class="col-2 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Código Propietario
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input [disabled]="codigo_automatico_propietario" type="text" id="codigo" name="codigo" [(ngModel)]="orden_trabajo.vehiculo.propietario.codigo" placeholder="Código Cliente" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-form-label text-center">
                    <div class="row">
                        <div [ngClass]="{'text-danger-m1':(!orden_trabajo.vehiculo.propietario.identificacion)}" class="col-12 col-form-label">
                            Nombre Propietario
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                                <input required type="text" id="nombre" name="nombre" [(ngModel)]="orden_trabajo.vehiculo.propietario.identificacion" placeholder="Identificación" [ngClass]="{'brc-danger-m1':(!orden_trabajo.vehiculo.propietario.identificacion)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!orden_trabajo.vehiculo.propietario.identificacion" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>
    
    
                            <div *ngIf="!orden_trabajo.vehiculo.propietario.identificacion" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>
    
                            <span *ngIf="!orden_trabajo.vehiculo.propietario.identificacion" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  form-row">
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Teléfono Fijo
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono" name="telefono" [(ngModel)]="orden_trabajo.vehiculo.propietario.telefono1" placeholder="Teléfono Fijo" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Teléfono Móvil
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono_movil" name="telefono_movil" [(ngModel)]="orden_trabajo.vehiculo.propietario.telefono2" placeholder="Teléfono Móvil" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-form-label text-center">
                    <div class="row">
                        <div class="col-12 col-form-label">
                            Dirección Domicilio
                        </div>
                        <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" name="direccion_localidad" [(ngModel)]="orden_trabajo.vehiculo.propietario.direccion" placeholder="Dirección" class="form-control form-control-lg col-12"  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-3">
            <div class="form-row">
                <div class="col-1">
                    <label>Tablero</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="tablero" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.tablero"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Luz Testigo</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="luz_testigo" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.luz_testigo"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Cenicero</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="cenicero" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.cenicero"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Radio</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="radio" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.radio"/>
                    </div>
                </div>
            </div>
            <hr>
            <div class="form-row">
                <div class="col-1">
                    <label>Cd's</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="cds" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.cds"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Objetos de Valor</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="objetos_valor" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.objetos_valor"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Llavero</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="llavero" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.llavero"/>
                    </div>
                </div>
                <div class="col-1">
                    <label>Retrovisores</label>
                </div>
                <div class="col-1">
                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                        <input name="retrovisores" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="orden_trabajo.inventario_ingreso.retrovisores"/>
                    </div>
                </div>
            </div>
          </div>
          <div id="step-4">
            <div class="form-row">
                <div class="col-12 col-form-label text-sm-center text-grey">
                    <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light">
                            <i class="fa fa-table mr-2px"></i> Servicios Solicitados 
                            <a (click)="crearServicioSolicitado()" title="Registro Servicio Solicitado" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" >
                                <i class="ace-icon fa fa-plus bigger-130"></i>
                            </a>
                        </h5>
                        </div>
                
                        <div class="card-body p-0 bg-white">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>Descripcion</label>
                                        </th>
                                        <th>
                                            <label>Estado</label>
                                        </th>
                                        <th>
                                            <label>Acciones</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let servicio_solicitado of orden_trabajo.servicios_solicitados;let i=index;">
                                        <tr>
                                            <td>
                                                {{(i+1)}}
                                            </td>
                                            <td>
                                                {{servicio_solicitado.descripcion}}
                                            </td>
                                            <td *ngIf="servicio_solicitado.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                                            <td *ngIf="!servicio_solicitado.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                                            <td>
                                                <a (click)="abrirPopupConfirmacionEliminacion(servicio_solicitado)" *ngIf="servicio_solicitado.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" >
                                                    <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>

<ng-template #registro_servicio_solicitado_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Servicio Solicitado</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="agregarServicioSolicitado()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
        <div class="col-4">
            <label>Descripción</label>
        </div>
        <div class="col-8">
            <textarea name="descripcion" [(ngModel)]="servicio_solicitado.descripcion" placeholder="Descripción" class="form-control"></textarea>
        </div>
      </div>
    </div>
</ng-template>