import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-registro-asunto-administrativo',
  templateUrl: './registro-asunto-administrativo.component.html',
  styleUrls: ['./registro-asunto-administrativo.component.css']
})
export class RegistroAsuntoAdministrativoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  asunto_administrativo:any={};
  estudiante:any;
  estados:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public estudiantesService:EstudiantesService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
    this.estudiante.nombre_completo=this.estudiante.persona?this.estudiante.persona.nombre_completo:this.estudiante.nombre_completo;
    this.asunto_administrativo={
      id_usuario:this.usuario.id,
      id_empresa:this.usuario.id_empresa,
      id_estudiante:this.estudiante.id
    }
    await this.obtenerEstadosAsuntosAdministrativos();
  }

  async obtenerEstadosAsuntosAdministrativos(){
    let tipo_estado:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_ESTADO_ASUNTO_ADMINISTRATIVO).toPromise();
    this.estados=tipo_estado.clases.filter(e => e.nombre_corto!="ATENDIDO");
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  validarAsuntoAdministrativo(){
    let res=true;
    if(!this.asunto_administrativo.asunto){
      res=res && false;
      this.toastr.error("Debe especificar el asunto administrativo!");
    }
    if(!this.asunto_administrativo.descripcion){
      res=res && false;
      this.toastr.error("Debe especificar la descripcion administrativa!");
    }
    if(!this.asunto_administrativo.estado){
      res=res && false;
      this.toastr.error("Debe especificar el estado!");
    }
    return res;
  }

  async guardarAsuntoAdministrativo(){
    if(this.validarAsuntoAdministrativo()){
      this.blockUI.start();
      this.estudiantesService.guardarAsuntoAdministrativo(this.asunto_administrativo).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
      });
    }
  }

}
