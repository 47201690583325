import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class MateriasService {

  constructor(private http: HttpClient) { }

  public obtenerMateriasNivel(id_empresa,id_nivel_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias/empresa/"+id_empresa+"/nivel/"+id_nivel_escolar,GlobalVariable.httpOptions);
  }

  public guardarRegistrosProfesor(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"registro-profesor-gestion",datos,GlobalVariable.httpOptions);
  }

  public obtenerCursosProfesorGestion(id_persona,id_gestion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cursos-profesor-gestion/"+id_gestion+"/persona/"+id_persona,GlobalVariable.httpOptions);
  }

  public obtenerMateriasCurso(id_persona,id_gestion,id_curso){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias-curso/persona/"+id_persona+"/gestion/"+id_gestion+"/curso/"+id_curso,GlobalVariable.httpOptions);
  }

  public obtenerMateriasColegio(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"materias/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarMateria(materia){
    return this.http.post(GlobalVariable.API_SERVER_URL+"materias",materia,GlobalVariable.httpOptions);
  }

  public actualizarMateria(materia){
    return this.http.put(GlobalVariable.API_SERVER_URL+"materias/"+materia.id,materia,GlobalVariable.httpOptions);
  }

  public eliminarMateria(materia){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"materias/"+materia.id,GlobalVariable.httpOptions);
  }

  public sincronizarAreasMaterias(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sincronizar-areas-materias",datos,GlobalVariable.httpOptions);
  }

  public obtenerAreasMaterias(id_escuela){
    return this.http.get(GlobalVariable.API_SERVER_URL+"areas-materias/"+id_escuela,GlobalVariable.httpOptions);
  }
}
