import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ReportesService } from '../../services/reportes/reportes.service';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-reporte-ventas',
  templateUrl: './reporte-ventas.component.html',
  styleUrls: ['./reporte-ventas.component.css']
})
export class ReporteVentasComponent extends BaseComponent implements OnInit {

  datos_reporte:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public reportesService:ReportesService,
    public pdfService:PdfService,
	private toastr: ToastrService,
  private clientesService:ClientesService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));

    let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: {nombre:"TODOS",id:0},
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			clientes:[{identificacion:"TODOS",id:0}],
			cliente:{identificacion:"TODOS",id:0},
			tipos_pago:[{nombre:"TODOS",id:0}],
			tipo_pago: {nombre:"TODOS",id:0},
			transacciones:[{nombre:"TODOS",id:0}],
			transaccion: {nombre:"TODOS",id:0},
			subgrupos_producto:[{nombre:"TODOS",id:0}],
			subgrupo_producto: {nombre:"TODOS",id:0},
			canales:[{nombre:"TODOS",id:0}],
			canal: {nombre:"TODOS",id:0},
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			solo_obsequios:false,
			meses:[]
		}

		//await this.obtenerConfiguracionPaginaVentas();
		await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_REPORTE_VENTAS);
		this.filter.presentacion_cliente=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente;
		this.filter.presentacion_periodos=this.configuracion_pagina.object.datos.opciones_permisos.presentacion_periodos;

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
    
		this.obtenerTranacciones();
		this.obtenerTiposDePago();

    this.filter.agrupadores_1=[
			{
				id:1,
				nombre:"Sucursal",
				valor:"sucursal.id as id_agrupador_?$sucursal.nombre as nombre_agrupador_?$venta.sucursal"
			},
			{
				id:2,
				nombre:"Cliente",
				valor:"cliente.id as id_agrupador_?$cliente.razon_social as nombre_agrupador_?$venta.cliente"
			},
			{
				id:3,
				nombre:"Usuario",
				valor:"usuario.id as id_agrupador_?$persona_usuario.nombre_completo as nombre_agrupador_?$venta.usuario"
			},
			{
				id:4,
				nombre:"Transaccion",
				valor:"transaccion.id as id_agrupador_?$transaccion.nombre as nombre_agrupador_?$venta.transaccion"
			},
			{
				id:5,
				nombre:"Tipo de pago",
				valor:"tipo_pago.id as id_agrupador_?$tipo_pago.nombre as nombre_agrupador_?$venta.tipo_pago"
			}
		];

		if(this.usuario.empresa.usar_clientes_contactos){
			this.filter.agrupadores_1.push({
				id:6,
				nombre:"Contacto cliente",
				valor:"cliente_contacto.id as id_agrupador_?$persona.nombre_completo as nombre_agrupador_?$venta.contacto_cliente"
			});
		}

		this.filter.agrupadores_2=JSON.parse(JSON.stringify(this.filter.agrupadores_1));
		this.filter.agrupadores_2.unshift({id:0,valor:"0",nombre:"NINGUNO"});
		this.filter.agrupadores_3=JSON.parse(JSON.stringify(this.filter.agrupadores_1));
		this.filter.agrupadores_3.unshift({id:0,valor:"0",nombre:"NINGUNO"});

		this.filter.agrupador_1=this.filter.agrupadores_1[0];
		this.filter.agrupador_2=this.filter.agrupadores_2[0];
		this.filter.agrupador_3=this.filter.agrupadores_3[0];
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerTranacciones(){
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any) => {
      let movimientos_egreso_original=entidad.clases;
			let movimientos_egreso=movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
			if(this.usuario.empresa.usar_facturacion){
				movimientos_egreso=movimientos_egreso.concat(movimientos_egreso_original.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
			}
      this.filter.transacciones=this.filter.transacciones.concat(movimientos_egreso);
    });
	}

  obtenerTiposDePago(){
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
    });
  }	

  cambiarAgrupador1(){
		this.filter.agrupador_1.valor.replace("?","1");
	}

	cambiarAgrupador2(){
		this.filter.agrupador_2.valor.replace("?","2");
	}

	cambiarAgrupador3(){
		this.filter.agrupador_3.valor.replace("?","3");
	}

	generarReporte(){
    this.blockUI.start();
    this.reportesService.obtenerReporteVentas(this).subscribe((ventas:any)=>{
      let array_agrupadores_1=this.agrupar(JSON.parse(JSON.stringify(ventas)),"id_agrupador_1");
			for(var i=0;i<array_agrupadores_1.length;i++){
				if(array_agrupadores_1[i].id_agrupador_2){
					array_agrupadores_1[i].array_agrupadores_2=this.agrupar2(JSON.parse(JSON.stringify(ventas)),array_agrupadores_1[i].id_agrupador_1,"id_agrupador_1","id_agrupador_2");
				}
			}
			for(var i=0;i<array_agrupadores_1.length;i++){
				if(array_agrupadores_1[i].id_agrupador_2){
					for(var j=0;j<array_agrupadores_1[i].array_agrupadores_2.length;j++){
						if(array_agrupadores_1[i].array_agrupadores_2[j].id_agrupador_3){
							array_agrupadores_1[i].array_agrupadores_2[j].array_agrupadores_3=this.agrupar3(JSON.parse(JSON.stringify(ventas)),array_agrupadores_1[i].id_agrupador_1,array_agrupadores_1[i].array_agrupadores_2[j].id_agrupador_2,"id_agrupador_1","id_agrupador_2","id_agrupador_3");
						}
					}
				}
			}
			this.datos_reporte=array_agrupadores_1;
      this.sumarTotales();
      this.blockUI.stop();
    });
	}

  agrupar(array,value){
		var result = [];
		var map = new Map();
		for (var item of array) {
			if(!map.has(item[value])){
				map.set(item[value], true);    // set any value to Map
				result.push(item);
			}
		}
		return result;
	}

  agrupar2(array,const_value,value1,value2){
		var result = [];
		var map = new Map();
		for (var item of array) {
			if(!map.has(item[value1]+"-"+item[value2]) && item[value1]==const_value){
				map.set(item[value1]+"-"+item[value2], true);    // set any value to Map
				result.push(item);
			}
		}
		return result;
	}

  agrupar3(array,const_value1,const_value2,value1,value2,value3){
		var result = [];
		var map = new Map();
		for (var item of array) {
			if(!map.has(item[value1]+"-"+item[value2]+"-"+item[value3]) && item[value1]==const_value1 && item[value2]==const_value2){
				map.set(item[value1]+"-"+item[value2]+"-"+item[value3], true);    // set any value to Map
				result.push(item);
			}
		}
		return result;
	}

  establecerSucursal(){
		this.obtenerClientes();
	}

  async cambiarPresentacionCliente(){
		if(this.filter.presentacion_cliente=="lista"){
			this.filter.presentacion_cliente="campo_texto";
		}else{
			this.filter.presentacion_cliente="lista";
			if(this.filter.clientes.length==1){
				await this.obtenerClientes();
			}
		}
	}

  async obtenerClientes(){
		if(this.filter.presentacion_cliente=="lista" || this.configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente=="lista"){
			let clientes:any;
			this.filter.clientes=[{identificacion:"TODOS",id:0}];
			if(this.usuario.empresa.usar_clientes_sucursal){
				clientes=await this.clientesService.obtenerClientesSucursal(this.usuario.id_empresa,this.filter.sucursal.id).toPromise();
			}else{
				clientes=await this.clientesService.obtenerClientesEmpresa(this.usuario.id_empresa).toPromise();
			}
			this.filter.clientes=this.filter.clientes.concat(clientes);
		}
	}

  generarPdfReporteVentas(){
	let cantidad_items=this.datos_reporte.length,arreglo_items=[];
	for(let i=0;i<this.datos_reporte.length;i++){
		arreglo_items.push({
			nombre_agrupador:this.datos_reporte[i].nombre_agrupador_1,
			x:65,
			suma_importe:this.datos_reporte[i].suma_importe,
			suma_descuento:this.datos_reporte[i].suma_descuento,
			suma_total:this.datos_reporte[i].suma_total,
			suma_a_cuenta:this.datos_reporte[i].suma_a_cuenta,
			suma_saldo:this.datos_reporte[i].suma_saldo,
			suma_devoluciones:this.datos_reporte[i].suma_devoluciones
		});
		if(this.datos_reporte[i].array_agrupadores_2){
			cantidad_items=cantidad_items+this.datos_reporte[i].array_agrupadores_2.length;
			for(let j=0;j<this.datos_reporte[i].array_agrupadores_2.length;j++){
				arreglo_items.push({
					nombre_agrupador:this.datos_reporte[i].array_agrupadores_2[j].nombre_agrupador_2,
					x:120,
					suma_importe:this.datos_reporte[i].array_agrupadores_2[j].suma_importe,
					suma_descuento:this.datos_reporte[i].array_agrupadores_2[j].suma_descuento,
					suma_total:this.datos_reporte[i].array_agrupadores_2[j].suma_total,
					suma_a_cuenta:this.datos_reporte[i].array_agrupadores_2[j].suma_a_cuenta,
					suma_saldo:this.datos_reporte[i].array_agrupadores_2[j].suma_saldo,
					suma_devoluciones:this.datos_reporte[i].array_agrupadores_2[j].suma_devoluciones
				});
				if(this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3){
					cantidad_items=cantidad_items+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3.length;
					for(let k=0;k<this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3.length;k++){
						arreglo_items.push({
							nombre_agrupador:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].nombre_agrupador_3,
							x:180,
							suma_importe:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].importe,
							suma_descuento:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].descuento,
							suma_total:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].total,
							suma_a_cuenta:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].a_cuenta,
							suma_saldo:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].saldo,
							suma_devoluciones:this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].devoluciones
						});
					}
				}
			}
		}
	}
    
	var doc = new PDFDocument({ size: 'letter', margin: 10 });
	var stream = doc.pipe(blobStream());
	var fecha_reporte=new Date(),titulo_reporte="REPORTE DE VENTAS";
	var y = 145, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(cantidad_items / itemsPorPagina);
	this.dibujarCabeceraReportePdfVentas(doc);
	this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
		this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre==GlobalVariable.Dictionary.SELECCION_TODOS.nombre?"":this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
		(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:this.usuario.empresa.telefono1)+
	(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
	(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
	fecha_reporte,{imprimir_usuario:true});
	for (var i = 0; i < arreglo_items.length && items <= itemsPorPagina; i++) {
		doc.rect(40, y - 10, 540, 20).stroke();
		doc.font('Helvetica', 8);
		doc.text((i+1)+"", 45, y, { width: 50 });
		doc.text(arreglo_items[i].nombre_agrupador, arreglo_items[i].x, y);
		doc.text(arreglo_items[i].suma_importe, 340, y,{align:"right",width:38});
		doc.text(arreglo_items[i].suma_descuento, 380, y,{align:"right",width:38});
		doc.text(arreglo_items[i].suma_total, 420, y,{align:"right",width:38});
		doc.text(arreglo_items[i].suma_a_cuenta, 460, y,{align:"right",width:38});
		doc.text(arreglo_items[i].suma_saldo, 500, y,{align:"right",width:38});
		doc.text(arreglo_items[i].suma_devoluciones, 540, y,{align:"right",width:38});
		//doc.text(productos[i].cantidad, 375, y);
		y = y + 20;
		items++;

		if (items == itemsPorPagina) {
			doc.addPage({ margin: 0, bufferPages: true });
			y = 145;
			items = 0;
			pagina = pagina + 1;
			this.dibujarCabeceraReportePdfVentas(doc);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo_reporte,pagina,totalPaginas,this.usuario,
				this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});

			doc.font('Helvetica', 8);
		}
	}
	doc.end();
	stream.on('finish', function () {
		var fileURL = stream.toBlobURL('application/pdf');
		window.open(fileURL, '_blank', 'location=no');
	});
	this.blockUI.stop();
  }

  	dibujarCabeceraReportePdfVentas(doc) {
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,70,230,25,5).stroke();
		doc.text("DEL : ",385,75);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,420,75);		
		doc.text("AL : ",385,85);
		doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,420,85);		
		if(this.filter.usuario.id!=0){
			doc.text("Usuario : ",480,75);
			doc.text(this.filter.usuario.nombre_usuario,480,85);		
		}

		doc.font('Helvetica-Bold',8);
		doc.text("Nº",45,120);
		doc.text(this.filter.agrupador_1.nombre,65,120);
		doc.text(this.filter.agrupador_2.nombre,120,120);
		doc.text(this.filter.agrupador_3.nombre,180,120);
		doc.text("Importe",340,120);
		doc.text("Desc.",380,120);
		doc.text("Total",420,120);
		doc.text("A Cuenta",460,120);
		doc.text("Saldo",500,120);
		doc.text("Devs.",540,120);
		doc.roundedRect(40,110,540,25,5).fillOpacity(0.8).stroke();
	}

	sumarTotales(){
		for(let i=0;i<this.datos_reporte.length;i++){
			this.datos_reporte[i].suma_importe=0;
			this.datos_reporte[i].suma_descuento=0;
			this.datos_reporte[i].suma_total=0;
			this.datos_reporte[i].suma_a_cuenta=0;
			this.datos_reporte[i].suma_saldo=0;
			this.datos_reporte[i].suma_devoluciones=0;
			if(this.datos_reporte[i].id_agrupador_2){
				for(var j=0;j<this.datos_reporte[i].array_agrupadores_2.length;j++){
					this.datos_reporte[i].array_agrupadores_2[j].suma_importe=0;
					this.datos_reporte[i].array_agrupadores_2[j].suma_descuento=0;
					this.datos_reporte[i].array_agrupadores_2[j].suma_total=0;
					this.datos_reporte[i].array_agrupadores_2[j].suma_a_cuenta=0;
					this.datos_reporte[i].array_agrupadores_2[j].suma_saldo=0;
					this.datos_reporte[i].array_agrupadores_2[j].suma_devoluciones=0;
					if(this.datos_reporte[i].array_agrupadores_2[j].id_agrupador_3){
						for(var k=0;k<this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3.length;k++){
							this.datos_reporte[i].suma_importe=this.datos_reporte[i].suma_importe+this.datos_reporte[i].array_agrupadores_2[j].importe;
							this.datos_reporte[i].suma_descuento=this.datos_reporte[i].suma_descuento+this.datos_reporte[i].array_agrupadores_2[j].descuento;
							this.datos_reporte[i].suma_total=this.datos_reporte[i].suma_total+this.datos_reporte[i].array_agrupadores_2[j].total;
							this.datos_reporte[i].suma_a_cuenta=this.datos_reporte[i].suma_a_cuenta+this.datos_reporte[i].array_agrupadores_2[j].a_cuenta;
							this.datos_reporte[i].suma_saldo=this.datos_reporte[i].suma_saldo+this.datos_reporte[i].array_agrupadores_2[j].saldo;
							this.datos_reporte[i].suma_devoluciones=this.datos_reporte[i].suma_devoluciones+this.datos_reporte[i].array_agrupadores_2[j].devoluciones;
							this.datos_reporte[i].array_agrupadores_2[j].suma_importe=this.datos_reporte[i].array_agrupadores_2[j].suma_importe+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].importe;
							this.datos_reporte[i].array_agrupadores_2[j].suma_descuento=this.datos_reporte[i].array_agrupadores_2[j].suma_descuento+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].descuento;
							this.datos_reporte[i].array_agrupadores_2[j].suma_total=this.datos_reporte[i].array_agrupadores_2[j].suma_total+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].total;
							this.datos_reporte[i].array_agrupadores_2[j].suma_a_cuenta=this.datos_reporte[i].array_agrupadores_2[j].suma_a_cuenta+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].a_cuenta;
							this.datos_reporte[i].array_agrupadores_2[j].suma_saldo=this.datos_reporte[i].array_agrupadores_2[j].suma_saldo+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].saldo;
							this.datos_reporte[i].array_agrupadores_2[j].suma_devoluciones=this.datos_reporte[i].array_agrupadores_2[j].suma_devoluciones+this.datos_reporte[i].array_agrupadores_2[j].array_agrupadores_3[k].devoluciones;
              			}
					}else{
						this.datos_reporte[i].suma_importe=this.datos_reporte[i].suma_importe+this.datos_reporte[i].array_agrupadores_2[j].importe;
						this.datos_reporte[i].suma_descuento=this.datos_reporte[i].suma_descuento+this.datos_reporte[i].array_agrupadores_2[j].descuento;
						this.datos_reporte[i].suma_total=this.datos_reporte[i].suma_total+this.datos_reporte[i].array_agrupadores_2[j].total;
						this.datos_reporte[i].suma_a_cuenta=this.datos_reporte[i].suma_a_cuenta+this.datos_reporte[i].array_agrupadores_2[j].a_cuenta;
						this.datos_reporte[i].suma_saldo=this.datos_reporte[i].suma_saldo+this.datos_reporte[i].array_agrupadores_2[j].saldo;
						this.datos_reporte[i].suma_devoluciones=this.datos_reporte[i].suma_devoluciones+this.datos_reporte[i].array_agrupadores_2[j].devoluciones;
						this.datos_reporte[i].array_agrupadores_2[j].suma_importe=this.datos_reporte[i].array_agrupadores_2[j].suma_importe+this.datos_reporte[i].array_agrupadores_2[j].importe;
            		}
				}
			}else{
          		this.datos_reporte[i].suma_importe=this.datos_reporte[i].importe;
        	}
		}
	}

}
