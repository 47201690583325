import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MensajeriaService {

  constructor(private http: HttpClient) { }

  public obtenerMensajesRecibidos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"mensajes-recibidos/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/receptor/"+paginator.filter.id_usuario,GlobalVariable.httpOptions);
  }
}
