import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';
import { CarrerasService } from '../../services/carreras/carreras.service';

@Component({
  selector: 'app-incripcion-materia',
  templateUrl: './incripcion-materia.component.html',
  styleUrls: ['./incripcion-materia.component.css']
})
export class IncripcionMateriaComponent extends BaseComponent implements OnInit {

  @Input() estudiante:any;
  @Input() inscripcion_instituto:any;
  materias:any[]=[];
  materias_asignadas:any[]=[];

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private estudiantesService:EstudiantesService,
    private carrerasService: CarrerasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.filter={
      escuela:null,
			inscripciones_instituto:[],
      inscripcion_instituto:this.inscripcion_instituto?this.inscripcion_instituto:null
    }
    this.obtenerInscripcionesInstitutoEstudiante();
  }

  obtenerInscripcionesInstitutoEstudiante(){
    this.estudiantesService.obtenerInscripcionesInstitutoEstudiante(this.estudiante.id).subscribe((inscripciones:any)=>{
      this.filter.inscripciones_instituto=inscripciones;
    });
  }

  buscarMaterias(){
    this.blockUI.start();
    this.carrerasService.obtenerMaterias(this.filter.inscripcion_instituto.id_carrera).subscribe((materias:any[])=>{
      this.materias=materias;
      this.carrerasService.obtenerMateriasAsignadas(this.filter.inscripcion_instituto.id).subscribe((materias_asignadas:any[])=>{
        this.materias_asignadas=materias_asignadas;
        this.materias=this.materias.filter((m) =>{
          let encontrado=this.materias_asignadas.filter(ma => ma.materia.id==m.id);
          if(encontrado.length>0){
            return false;
          }else{
            return true;
          }
        })
        this.blockUI.stop();
      });
    });
  }

  cerrarComponente(){
    this.onConfirm.emit({confirm:false});
  }

  asignarMateria(materia){
    let index=this.materias.indexOf(materia);
    this.materias.splice(index,1);  
    this.materias_asignadas.push({activo:true,id_inscripcion:this.filter.inscripcion_instituto.id,materia:materia});
  }

  removerMateria(materia){
    materia.activo=false;
    this.materias.push(materia.materia);
  }

  guardarAsignacionMaterias(){
    this.blockUI.start();
    this.carrerasService.guardarMateriasAsignadas({materias_asignadas:this.materias_asignadas}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.cerrarComponente();
      this.blockUI.stop();
    });
    
  }

}
