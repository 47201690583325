<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Cotizaciones
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Cotizaciones
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
                      <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="feini">Desde </label>&nbsp;
                            <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="fefin">Hasta </label>&nbsp;
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>  
                     </div>
                      <!--<div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
					  <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                      </div>
					  <div class="col-md-3 col-sm-12">
						<label>Usuario </label>&nbsp;
						  <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
							  <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
						  </select>
                      </div>-->
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
              </button>
			    <button title="Nuevo" (click)="crearNuevaCotizacion()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                </button>
              <div class="export btn-group">
                <button class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				</button>
				<button (click)="clickBotonSubir('subir-excel-productos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-productos" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
              </div>
                <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                        <i class="fa fa-wrench text-blue-d1"></i>
                        <span class="caret"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                      <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                        <div class="card bgc-primary brc-primary radius-0">
                          <div class="card-header">
                          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                          </div>
                
                          <div class="card-body p-0 bg-white">
                          <table class="table table-striped table-hover mb-0">
                            <tbody>
                              <tr>
                                <td class="text-dark-m2">
                                Configuración Vista
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        </div>
                    </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			    </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="cotizaciones.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th *ngIf="configuracion_pagina.getConfiguration().nombre.show">Nombre</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descripcion.show">Descripción</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show">Fecha</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">Número de documento</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tiempo_validez.show">Tiempo de validez</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show">Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let cotizacion of cotizaciones; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().nombre.show">{{cotizacion.cliente?cotizacion.cliente.identificacion:cotizacion.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().descripcion.show">{{cotizacion.descripcion}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha.show">{{cotizacion.fecha | date:"dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">{{cotizacion.numero_documento}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tiempo_validez.show">{{cotizacion.tiempo_validez}} Días</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().importe.show">{{cotizacion.total.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{cotizacion.usuario.nombre_usuario}}</td>
                    <td *ngIf="cotizacion.activo && configuracion_pagina.getConfiguration().estado.show"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="!cotizacion.activo && configuracion_pagina.getConfiguration().estado.show"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td>
                        <div class="d-none d-lg-flex text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirCotizacion(cotizacion)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="cotizacion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarCotizacion(cotizacion)">
                                <i class="ace-icon fa fa-edit bigger-130"></i>
                            </a>
                            <a *ngIf="cotizacion.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cotizacion)">
                                <i class="fa fa-trash-alt"></i>
                              </a>
                        </div>

                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <!--<a *ngIf="usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(venta)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVenta(venta)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" ng-click="verVenta(venta)">
                                  <i class="fa fa-search-plus text-blue-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a *ngIf="venta.activo" class="dropdown-item" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(venta)">
                                <i class="fa fa-trash text-danger-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Anular
                              </a>-->
                            </div>
                          </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="cotizaciones.length>0">
                    <th></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().nombre.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descripcion.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show"></th>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="cotizaciones.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>