import { Component, OnInit, TemplateRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { CombosService } from '../../services/combos/combos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { GlobalVariable } from 'src/app/global';
import { ProductosService } from '../../services/productos/productos.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { Util } from 'src/app/utils/utils';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;
declare const aplicarPluginImagen:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-combos',
  templateUrl: './combos.component.html',
  styleUrls: ['./combos.component.css']
})
export class CombosComponent extends BaseComponent implements OnInit {

  rest_server:string=GlobalVariable.SERVER_URL;
  configuracion_pagina:any;
  combos:any[]=[];
  combo:any;
  producto:any={};
  producto_precio:any={};
  producto_busqueda:any;

  materiales:any[]=[];
  total_material:number=0;
  total_mano_obra:number=0;
  total_mano_obra_beneficios:number=0;
  total_beneficiossociales:number=0;
  total_iva:number=0;
  total_maquinariaequipo:number=0;
  total_gastosgenerales:number=0;
  total_utilidad:number=0;
  total_impuestos:number=0;
  manos_de_obra:any[]=[];
  concepto_precios_unitarios:any[]=[];
  concepto_equipomaquinaria:any;
  gastos_generales:any;
  utilidad:any;
  impuestos:any;
  beneficios_sociales:any;
  iva:any;
  tipo_grupo:any;
  tipo_producto_precio:any;


  @ViewChildren('combos') combos_lectura: QueryList<any>;

  searching = false;
  searchFailed = false;

  global_variable=GlobalVariable;

  wizard_edicion_combo:string="wizard_edicion_combo";
  popupConfirmacion:NgbModalRef;
  sucursales:any[]=[];

  edicioncombo:NgbModalRef;
  @ViewChild('edicioncombo')
  private edicioncomboRef: TemplateRef<any>;

  @BlockUI() blockUI: NgBlockUI;

  constructor(private router: Router,
    public usuarioService:UsuarioService,
    public combosService: CombosService,
    public generalService:GeneralService,
	public persistenciaService:PersistenciaService,
	public modalService: NgbModal,
	public productosService:ProductosService,
	public excelService:ExcelService,
	public pdfService:PdfService,
	private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

	ngOnInit(): void {
		this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_COMBOS);
		this.obtenerCombos();

		if(this.usuario.empresa.usar_combos_conceptos){
			this.obtenerConceptosPreciosUnitarios();
		}

		this.obtenerGruposCombosEmpresa();
		this.obtenerConceptosPreciosProductos();
	}

	obtenerConceptosPreciosProductos(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").subscribe((tipoPrecioProducto:any) => {
			this.tipo_producto_precio=tipoPrecioProducto;
			//this.filter.grupos_filtro=this.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
		});
	}

	obtenerGruposCombosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_COMBO_GRUPOS).subscribe((tipoGrupoEmpresa:any) => {
			this.tipo_grupo=tipoGrupoEmpresa;
			//this.filter.grupos_filtro=this.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
		});
	}

	obtenerConceptosPreciosUnitarios(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"CONCPREUNIT").subscribe((entidad:any) => {
			this.concepto_precios_unitarios=entidad.clases;
			this.concepto_equipomaquinaria=this.concepto_precios_unitarios.filter(e => e.nombre=="EQUIPO Y MAQUINARIA")[0];
			this.gastos_generales=this.concepto_precios_unitarios.filter(e => e.nombre=="GASTOS GENERALES Y ADMINISTR.")[0];
			this.utilidad=this.concepto_precios_unitarios.filter(e => e.nombre=="UTILIDAD")[0];
			this.impuestos=this.concepto_precios_unitarios.filter(e => e.nombre=="IMPUESTOS")[0];
			this.beneficios_sociales=this.concepto_precios_unitarios.filter(e => e.nombre=="BENEFICIOS SOCIALES")[0];
			this.iva=this.concepto_precios_unitarios.filter(e => e.nombre=="IMPUESTO AL VALOR AGREGADO")[0];
		});
	}

	ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.combos_lectura.changes.subscribe(t => {
			aplicarVisorImagenes();
		});
	}
  
  	obtenerCombos(){
		this.column = "codigo";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.combosService.obtenerListaCombos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.combos=dato.combos;
			this.blockUI.stop();
		});
	}

	crearNuevoCombo(){
		this.producto_precio={};
		this.combo={
			detallesCombo:[],
			precios:[],
			precio_unitario:1,
			imagen:'./img/icon-producto-default.png',
			id_empresa:this.usuario.id_empresa,
			precio_calculado:false
		}
		this.abrirRegistroCombo();
	}

	abrirRegistroCombo(){
		this.edicioncombo = this.modalService.open(this.edicioncomboRef, {windowClass : "wizard-edicion-combo",size:'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		
		this.edicioncombo.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
		aplicarWizard(this.wizard_edicion_combo,this,'guardarCombo');
		aplicarPluginImagen();
	}

	cerrarRegistroCombo(){
		this.edicioncombo.close();
	}

	subirImagen(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.combo.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
	}

	buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.productosService.buscarProducto(this.usuario.id_empresa,term) ),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
	formatter = (x: {nombre: string}) => x.nombre;

	establecerProducto(){
		//this.producto=this.producto_busqueda;
	}

	agregarDetalleCombo(producto){
		this.combo.detallesCombo.push({producto:producto,cantidad:producto.cantidad});			
		this.producto={};
		this.calcularPrecioUnitario();
		if(this.usuario.empresa.usar_combos_conceptos){
			this.materiales=this.combo.detallesCombo.filter(e => e.producto.grupo.nombre=="MATERIALES");
			this.manos_de_obra=this.combo.detallesCombo.filter(e => e.producto.grupo.nombre=="MANO DE OBRA");
		}
	}

	eliminarDetalleCombo(detalleCombo){
		detalleCombo.eliminado=true;
		this.calcularPrecioUnitario();
		if(this.usuario.empresa.usar_combos_conceptos){console.log(this.combo);
			this.materiales=this.materiales.filter(e => e.producto.id!=detalleCombo.producto.id);
			this.manos_de_obra=this.manos_de_obra.filter(e => e.producto.id!=detalleCombo.producto.id);
		}		
	}

	calcularPrecioUnitario(){
		if(this.combo.precio_calculado){
			let suma=0;
			if(this.usuario.empresa.usar_combos_conceptos){
				suma=this.total_material+this.total_mano_obra_beneficios+this.total_maquinariaequipo+this.total_gastosgenerales+this.total_utilidad+this.total_impuestos;
			}else{
				for(let i=0;i<this.combo.detallesCombo.length;i++){
					if(!this.combo.detallesCombo[i].eliminado){
						suma=suma+(this.combo.detallesCombo[i].producto.precio_unitario*this.combo.detallesCombo[i].cantidad);
					}
				}
			}
			this.combo.precio_unitario=suma;
		}else{
			this.combo.precio_unitario=1;
		}
	}

	validarFormularioRegistroCombo(){
		let res=true;
		if(!this.combo.codigo){
			this.toastr.error("Debe ingresar un codigo de producto!");
			res=false;
		}
		if(!this.combo.nombre){
			this.toastr.error("Debe ingresar el nombre del producto!");
			res=false;
		}
		if(!this.combo.unidad_medida){
			this.toastr.error("Debe ingresar la unidad de medida!");
			res=false;
		}
		if(this.combo.detallesCombo.length<=0){
			this.toastr.error("Debe agregar al menos un detalle en el combo!");
			res=false;
		}
		return res;
	}

	guardarCombo(){
		if(this.validarFormularioRegistroCombo()){
			this.blockUI.start();
			if(this.combo.id){
				this.combosService.actualizarCombo(this.combo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Combo registrado satisfactoriamente");
					this.edicioncombo.close();
					this.getItems();
				});
			}else{
				this.combosService.guardarCombo(this.combo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Combo registrado satisfactoriamente");
					this.edicioncombo.close();
					this.getItems();
				});
			}
		}
	}

	modificarCombo(combo){
		this.blockUI.start();
		this.combosService.obtenerCombo(combo).subscribe((dato:any) => {
			this.combo=dato;
			if(this.usuario.empresa.usar_combos_conceptos){console.log(this.combo);
				this.materiales = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre =="MATERIALES");
				this.manos_de_obra = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre=="MANO DE OBRA");
			}
			this.abrirRegistroCombo();
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionEliminacion(combo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el combo?";
		this.popupConfirmacion.componentInstance.data = combo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCombo($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarCombo(combo){
		this.blockUI.start();
		this.combosService.eliminarCombo(combo).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

	sumarMaterial(){
		this.total_material=0;

		for(let i=0;i<this.materiales.length;i++){
			this.total_material=this.total_material+(this.materiales[i].producto.precio_unitario*this.materiales[i].cantidad);
		}
		this.total_material=Math.round(this.total_material*100)/100;
		return this.total_material;
	}

	sumarManoDeObra(){
		this.total_mano_obra=0;
		for(let i=0;i<this.manos_de_obra.length;i++){
			this.total_mano_obra=this.total_mano_obra+(this.manos_de_obra[i].producto.precio_unitario*this.manos_de_obra[i].cantidad);
		}
		this.total_mano_obra=Math.round(this.total_mano_obra*100)/100;
		return this.total_mano_obra;
	}

	sumarManoDeObraBeneficios(){
		this.total_mano_obra_beneficios=this.total_mano_obra+this.total_beneficiossociales+this.total_iva;
		return this.total_mano_obra_beneficios;
	}

	calcularBeneficiosSociales(){
		this.total_beneficiossociales=Math.round((parseFloat(this.beneficios_sociales.nombre_corto)*this.total_mano_obra)*100)/100;
		return this.total_beneficiossociales;
	}

	calcularIva(){
		this.total_iva=Math.round((parseFloat(this.iva.nombre_corto)*(this.total_mano_obra+this.total_beneficiossociales))*100)/100;
		return this.total_iva;
	}

	calcularMaquinariaEquipo(){
		this.total_maquinariaequipo=Math.round((parseFloat(this.concepto_equipomaquinaria.nombre_corto)*this.total_mano_obra_beneficios)*100)/100;
		return this.total_maquinariaequipo;
	}

	calcularGastosGenerales(){
		this.total_gastosgenerales=Math.round((parseFloat(this.gastos_generales.nombre_corto)*(this.total_material+this.total_maquinariaequipo +this.total_mano_obra_beneficios))*100)/100;
		return this.total_gastosgenerales;
	}

	calcularUtilidad(){
		this.total_utilidad=Math.round((parseFloat(this.utilidad.nombre_corto)*(this.total_gastosgenerales+this.total_material+this.total_maquinariaequipo +this.total_mano_obra_beneficios))*100)/100;
		return this.total_utilidad;
	}

	calcularImpuestos(){
		this.total_impuestos=Math.round((parseFloat(this.impuestos.nombre_corto)*(this.total_utilidad+this.total_gastosgenerales+this.total_material+this.total_maquinariaequipo +this.total_mano_obra_beneficios))*100)/100;
		return this.total_impuestos;
	}

	imprimirPdf(combo){
		this.blockUI.start();
		this.combosService.obtenerCombo(combo).subscribe((dato:any) => {
			this.combo=dato;

			if(this.usuario.empresa.usar_combos_conceptos){
				
				this.materiales = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre =="MATERIALES");
				this.manos_de_obra = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre=="MANO DE OBRA");

				

			}

			let _total_material = this.sumarMaterial();
			let _total_mano_obra = this.sumarManoDeObra();
			
			let _total_beneficios_sociales = this.calcularBeneficiosSociales();
			let _total_iva = this.calcularIva();
			let _total_mano_obra_beneficios = this.sumarManoDeObraBeneficios();

			let _total_maquinaria_equipo = this.calcularMaquinariaEquipo();
			let _total_gastos_generales = this.calcularGastosGenerales();
			let _total_utilidad = this.calcularUtilidad();

			let _total_impuestos = this.calcularImpuestos();
			
			//this.calcularPrecioUnitario();
			let _total_precio_unitario = combo.precio_unitario;

			let ConceptosPreciosUnitarios = {
				concepto_precio_unitarios: this.concepto_precios_unitarios,
				equipo_maquinaria: this.concepto_equipomaquinaria,
				gastos_generales: this.gastos_generales,
				utilidad: this.utilidad,
				impuestos: this.impuestos,
				beneficios: this.beneficios_sociales,
				iva: this.iva,
				total_material: _total_material,
				total_mano_obra: _total_mano_obra,
				total_mano_obra_beneficios: _total_mano_obra_beneficios,
				total_beneficios_sociales: _total_beneficios_sociales,
				total_gastos_generales: _total_gastos_generales,
				total_iva: _total_iva,
				total_impuestos: _total_impuestos,
				total_maquinaria_equipo: _total_maquinaria_equipo,
				total_precio_unitario: _total_precio_unitario,
				total_utilidad: _total_utilidad
				
			}
			

			
			this.pdfService.descargarReporteComboPdf(this.combo, ConceptosPreciosUnitarios, this.materiales, this.manos_de_obra);

			this.blockUI.stop();
		});
	}

	imprimirExcel(combo){
		this.blockUI.start();
		
		this.combosService.obtenerCombo(combo).subscribe((dato:any) => {
			this.combo=dato;
			

			if(this.usuario.empresa.usar_combos_conceptos){
				
				this.materiales = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre =="MATERIALES");
				this.manos_de_obra = this.combo.detallesCombo.filter(e => e.producto.grupo.nombre=="MANO DE OBRA");
			}

			let reportData = {
				title: "Reporte Combo",
			}

			let _total_material = this.sumarMaterial();
			let _total_mano_obra = this.sumarManoDeObra();
			
			let _total_beneficios_sociales = this.calcularBeneficiosSociales();
			let _total_iva = this.calcularIva();
			let _total_mano_obra_beneficios = this.sumarManoDeObraBeneficios();

			let _total_maquinaria_equipo = this.calcularMaquinariaEquipo();
			let _total_gastos_generales = this.calcularGastosGenerales();
			let _total_utilidad = this.calcularUtilidad();

			let _total_impuestos = this.calcularImpuestos();
			
			//this.calcularPrecioUnitario();
			let _total_precio_unitario = combo.precio_unitario;

			let ConceptosPreciosUnitarios = {
				concepto_precio_unitarios: this.concepto_precios_unitarios,
				equipo_maquinaria: this.concepto_equipomaquinaria,
				gastos_generales: this.gastos_generales,
				utilidad: this.utilidad,
				impuestos: this.impuestos,
				beneficios: this.beneficios_sociales,
				iva: this.iva,
				total_material: _total_material,
				total_mano_obra: _total_mano_obra,
				total_mano_obra_beneficios: _total_mano_obra_beneficios,
				total_beneficios_sociales: _total_beneficios_sociales,
				total_gastos_generales: _total_gastos_generales,
				total_iva: _total_iva,
				total_impuestos: _total_impuestos,
				total_maquinaria_equipo: _total_maquinaria_equipo,
				total_precio_unitario: _total_precio_unitario,
				total_utilidad: _total_utilidad
				
			}
			

			this.excelService.descargarReporteComboExcel(reportData, "Reporte Combo", "Hoja 1", dato, ConceptosPreciosUnitarios);
			
			this.blockUI.stop();
		});
	}

	establecerPrecioPorDefecto(producto_precio){
		for(var i=0;i<this.combo.precios.length;i++){
			if(this.usuario.empresa.usar_precios_productos_sucursal){
				if(producto_precio.sucursal && producto_precio.sucursal.id==this.combo.precios[i].sucursal.id){
					this.combo.precios[i].por_defecto=false;
				}
			}else{
				this.combo.precios[i].por_defecto=false;
			}
		}
		producto_precio.por_defecto=true;
		this.combo.precio_unitario=producto_precio.precio_unitario;
	}

	agregarProductoPrecio(precio_producto){
		if(!this.usuario.empresa.usar_precios_productos){
			precio_producto.por_defecto=true;
		}
		this.combo.precios.push(precio_producto);
		this.producto_precio={};
	}

	eliminarDetalleProductosPrecio(producto_precio){
		producto_precio.eliminado=true;		
	}

}
