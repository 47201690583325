<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Categoria Tienda</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" (click)="guardarCategoriaTienda()" >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarVentana(null)">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Categoria </label>&nbsp;
        </div>
        <div class="col-sm-12 col-md-9">
            <label>{{categoria_tienda.clase.nombre}} </label>&nbsp;
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Tipo Catálogo</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" name="tipo_catalogo" class="form-control" [(ngModel)]="categoria_tienda.tipo_catalogo">
                <option [ngValue]="tipo_catalogo" *ngFor="let tipo_catalogo of tipos_catalogo">{{tipo_catalogo.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
            <label>¿Tiene imagen?</label>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12">
            <label>
                <input (change)="aplicarPluginImagen()" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="categoria_tienda.tiene_imagen"/>
            </label>
        </div>
    </div>
    <ng-container *ngIf="categoria_tienda.tiene_imagen">
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Imagen</label>
            </div>
            <div class="col-3" *ngIf="categoria_tienda.id">
                <img  src="{{rest_server+categoria_tienda.imagen}}" width="100">
            </div>
            <div class="col-6">
                <div class="d-inline-flex align-items-center col-12 col-12 px-0">
                    <div>
                        <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Cantidad Disposicion Visualización</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <select name="cantidad_disposicion_visualizacion" class="form-control" [(ngModel)]="categoria_tienda.cantidad_disposicion_visualizacion">
                <option [ngValue]="cantidad" *ngFor="let cantidad of [1,2,3,4,6,12]">{{cantidad}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Cantidad Máxima Visualización</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input type="number" name="cantidad_maxima_visualizacion" [(ngModel)]="categoria_tienda.cantidad_maxima_visualizacion" placeholder="Cantidad Máxima Visualizacion" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="descripcion" [(ngModel)]="categoria_tienda.descripcion" placeholder="Descripción" class="form-control"/>
        </div>
    </div>
    
</div>