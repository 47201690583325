import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GeneralService } from '../../../base/services/general/general.service';
import { GlobalVariable } from 'src/app/global';
import { ClientesService } from '../../../snapquick/services/clientes/clientes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProductosService } from '../../../snapquick/services/productos/productos.service';
import { CombosService } from '../../../snapquick/services/combos/combos.service';
import { InventariosService } from '../../../snapquick/services/inventarios/inventarios.service';
import { PedidosService } from '../../../snapquick/services/pedidos/pedidos.service';
import { CotizacionesService } from '../../../snapquick/services/cotizaciones/cotizaciones.service';
import { ToastrService } from 'ngx-toastr';
import {Md5} from 'ts-md5/dist/md5';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { BusquedaProductoComponent } from 'src/app/base/components/busqueda-producto/busqueda-producto.component';
import { RegistroClienteComponent } from 'src/app/base/components/registro-cliente/registro-cliente.component';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';

declare const enfocarElemento:any;
declare const clickearElemento:any;
declare const aplicarVisorImagenes:any;
declare const $:any;

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})

export class PedidoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  rest_server:any=GlobalVariable.SERVER_URL;
  modalCliente:NgbModalRef;

  pedido:any={
    detallesPedido:[],
    cliente:{},
    usuario:null,
    fecha:null,
    _id:null,
    id:null,
    pagado:0,
    cambio:0,
    fechaTexto:null,
    sucursal:null,
    a_cuenta:0,
    saldo:0,
    total:0,
    almacen:null,
    actividad:null,
    pagoMinimo:0,
    importe:0,
    filtro_consulta:null,
    pedido:{},
    es_pedido:false,
    transaccion:{},
    descuento:0,
    descontar_almacen:true,
    canal:null,
    estado:null,
    cantidad_decimal:true,
    tipoPago:{},
    dias_credito:1,
    observacion:null,
    razon_social:"",
    nit_ci:"",
    tipo_descuento:false
  }

  venta:any={
    detallesVenta:[],
    cliente:{},
    usuario:null,
    fecha:null,
    _id:null,
    id:null,
    pagado:0,
    cambio:0,
    fechaTexto:null,
    sucursal:null,
    a_cuenta:0,
    saldo:0,
    total:0,
    almacen:null,
    actividad:null,
    pagoMinimo:0,
    importe:0,
    cotizacion:null,
    filtro_consulta:null,
    pedido:{},
    es_pedido:false,
    transaccion:{},
    descuento:0,
    descontar_almacen:true,
    canal:null,
    estado:null,
    cantidad_decimal:true,
    tipoPago:{},
    dias_credito:1,
    observacion:null,
    razon_social:"",
    nit_ci:""
  }

  razon_busqueda:any;
  combo:any;
  almacenBusqueda:any;
  inventario_color:any;
  inventario_porcentaje:any;
  producto:any;
  configuracionVentaVista:any={};
  detallePedido:any;
  registro_cliente:any;
  es_administrador:boolean=false;
  busqueda_codigo_barra:boolean=false;
  producto_busqueda:any;
  combo_busqueda:any;
  editar_precio:boolean=false;
  paginator:any;
  filtro:any;
  opcion_vista_busqueda:string="consulta_inventario_primer";
  almacenes:any[];
  actividades:any[];
  productos_restriccion:any[];
  canales:any[];
  clientes_nit:any[]=[];
  estados_pedido:any[];
  almacenes_consulta:any[];
  inventariosDisponibleProducto:any[]=[];
  movimientosEgreso:any[]=[];
  sucursales:any[];
  tiposPago:any[]=[];
  sucursales_consulta:any[];
  productos:any[];

  nombre_corto_tipo_pago:string=GlobalVariable.Dictionary.TIPO_PAGO_CONT;
  nombre_corto_transaccion:string=GlobalVariable.Dictionary.EGRE_PROFORMA;
  nombre_corto_estado_saneado:string=GlobalVariable.Dictionary.ESTADO_VENTA_SANEADA;
  nombre_corto_canal_directo:string=GlobalVariable.Dictionary.CANAL_VENTA_DIRECTA;
  estados:any[]=[];

  inhabilitar_cliente:boolean=false;
  inhabilitar_sucursal:boolean=false;
  inhabilitar_busqueda_item:boolean=false;
  inhabilitar_almacen:boolean=false;
  inhabilitar_movimiento:boolean=false;
  inhabilitar_tipo_pago:boolean=false;
  inhabilitar_generar_pedido:boolean=false;
  inhabilitar_fecha:boolean=false;
  inhabilitar_a_cuenta:boolean=false;
  inhabilitar_descontar_almacen:boolean=false;

  gloval_variable:any=GlobalVariable;

  searching = false;
  searchFailed = false;  

  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,
    public ventasService:VentasService,
    public productosService:ProductosService,
    public combosService:CombosService,
    public inventariosService:InventariosService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public clientesService:ClientesService,
    public pedidosService:PedidosService,
    public toastr: ToastrService,
    public cotizacionesService:CotizacionesService,
    public empresaService:EmpresaService,
    public usuarioService:UsuarioService,
    private pdfService:PdfService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.pedido.cobrar_pedido=this.configuracion_pagina.getOrden().cobrar_pedido;
    this.pedido.descontar_almacen=this.configuracion_pagina.getOrden().descontar_almacen;
    this.pedido.fecha=this.pedido.fecha?new Date(this.pedido.fecha):new Date();
    this.pedido.fecha_texto={
			year: this.pedido.fecha.getFullYear(), 
			month: (this.pedido.fecha.getMonth()+1),
			day:this.pedido.fecha.getDate()
		}
    this.iniciarDetalleVenta();
    this.editar_precio=this.usuario.empresa.usar_servicios;
    this.obtenerConfiguracionVentaVista();

    if(this.pedido.sucursal && this.pedido.sucursal.id){
      this.pedido.sucursal=this.pedido.sucursales.filter(e => e.id==this.pedido.sucursal.id)[0];
      this.obtenerAlmacenesActividades();
    }else{
      this.establecerSucursal(this.pedido.sucursales.length>=1?this.pedido.sucursales[0]:null);
    }
    if(this.pedido.cliente && this.pedido.cliente.id){
      this.establecerCliente(this.pedido.cliente);
    }else{
      this.buscarNit({which:13},"0");
    }

    this.obtenerEstadosPedido();
    if(this.usuario.empresa.usar_restriccion_productos_venta){
      this.obtenerRestriccionProductos();
    }

    if(this.pedido.cobrar_pedido){
      this.establecerVenta();
    }

    enfocarElemento('nit');
  }
  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerTiposPago(tipo_pago){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tiposPago=entidad.clases;
      this.establecerTipoPago(tipo_pago);
      this.blockUI.stop();
    });
  }

  obtenerRestriccionProductos(){
    this.blockUI.start();
    this.productosService.obtenerProductosRestringidosUsuario(this.usuario.id).subscribe((datos:any) => {
      this.productos_restriccion=datos;
      this.blockUI.stop();
    });
  }

  async obtenerCanales(canal){
    this.blockUI.start();
    let entidad:any=await this.generalService.obtenerClases("SNAPCANVEN").toPromise();
    this.canales=entidad.clases;
    if(this.usuario.empresa.usar_permisos_avanzados_usuarios && this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados.length>0){
      let canales=[];
      for(let i=0;i<this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados.length;i++){
        canales.push(entidad.clases.filter(cnf => cnf.id==this.configuracion_pagina.object.datos.opciones_permisos.canales_asignados[i])[0]);
      }
      this.canales=canales;
      canal=this.canales[0].nombre_corto;
    }
    this.establecerCanal(canal);
    this.blockUI.stop();
  }

  obtenerEstadosPedido(){
    this.blockUI.start();
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
      this.estados_pedido=entidad.clases;
      var estado_pendiente=this.estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_PEND)[0];
      this.pedido.estado=estado_pendiente;
      this.blockUI.stop();
    });
  }

  obtenerEstados(estado){
    this.blockUI.start();
    this.generalService.obtenerClases("ESTVENT").subscribe((entidad:any) => {
      this.estados=entidad.clases;
      this.establecerEstado(estado);
      this.blockUI.stop();
    });
  }

  establecerEstado(nombre_corto_estado){
    let tiposP=this.estados;
    this.venta.estado=tiposP.filter(e => e.nombre_corto == nombre_corto_estado)[0];
  }

  establecerCanal(nombre_corto_canal){
    let tiposP=this.canales;
    this.venta.canal=tiposP.filter(e => e.nombre_corto == nombre_corto_canal)[0];
  }

  establecerTipoPago(nombre_corto_tipo_pago){
    let tiposP=this.tiposPago;
    this.venta.tipoPago=tiposP.filter(e => e.nombre_corto == nombre_corto_tipo_pago)[0];
  }

  obtenerMovimientosEgreso(nombre_corto_transaccion){
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any) => {
      let movimientosEgresoOriginal=entidad.clases;
      this.movimientosEgreso=movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
      if(this.usuario.empresa.usar_facturacion){
        this.movimientosEgreso=this.movimientosEgreso.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
      }
      this.establecerMovimientoEgreso(nombre_corto_transaccion);
    });
  }

  establecerMovimientoEgreso(nombre_corto_movimiento){
    let movEgres=this.movimientosEgreso;
    this.venta.transaccion=movEgres.filter(e => e.nombre_corto == nombre_corto_movimiento)[0];
  }

  obtenerConfiguracionVentaVista(){
    this.ventasService.obtenerConfiguracionVista(this.usuario.id_empresa).subscribe((configuracion:any) => {
      this.configuracionVentaVista=configuracion;
    });
  }

  establecerSucursal(sucursal){
    this.pedido.sucursal=sucursal;
    if(this.pedido.sucursal){
      this.obtenerAlmacenesActividades();
      if(this.usuario.empresa.usar_pedidos_produccion){
        this.pedido.pedido.id_sucursal=this.pedido.sucursal.id;
      }
    }
    if(this.usuario.empresa.usar_clientes_sucursal){
      this.reiniciarCliente();
    }
  }

  reiniciarCliente(){
    this.pedido.cliente={};
    this.razon_busqueda=null;
    this.clientes_nit=[];
    $("#venta_razon_social").val("");
    enfocarElemento('venta_razon_social');
  }

  obtenerAlmacenesActividades(){
    this.obtenerAlmacenes();
    this.obtenerActividades();
  }

  async obtenerAlmacenes(){
    let datos_sucursal:any=await this.empresaService.obtenerSucursal(this.pedido.sucursal.id).toPromise();
    this.almacenes=datos_sucursal.almacenes;
    if(this.usuario.empresa.usar_almacenes_usuario){
      let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
      this.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
    }
    this.pedido.almacen=this.almacenes.length>0?this.almacenes[0]:null;
  }

  obtenerActividades(){
    this.actividades=[];
    let actividadesDosificaciones=this.pedido.sucursal.actividadesDosificaciones?this.pedido.sucursal.actividadesDosificaciones:[];
    let actividades=[];
    for(let i=0;i<actividadesDosificaciones.length;i++){
      this.actividades.push(actividadesDosificaciones[i].actividad);
    }
    this.pedido.actividad=this.actividades.length==1?this.actividades[0]:null;
  }

  buscarNit(evento,nit){
    this.clientes_nit=[];
    if (evento.which === 13){
      let id_sucursal=this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:0;

      this.clientesService.buscarClienteNit(this.usuario.id_empresa,nit,id_sucursal).subscribe((results:any[]) => {
        if(results.length==1){
          this.establecerCliente(results[0]);
        }else if(results.length>1){
          this.establecerCliente(results[0]);
          this.clientes_nit=results;
        }else{
          this.establecerCliente({nit:nit});
        }
        //this.capturarInteraccion();
      });
    }
  }

  establecerCliente(event){
    let cliente=(event.id || event.nit)?event:event.item;
    this.razon_busqueda=cliente;
    this.pedido.cliente=cliente;
    this.pedido.razon_social=cliente.razon_social;
    this.pedido.nit_ci=cliente.nit;
    enfocarElemento('venta_razon_social');
  }

  cambiarServicio(es_servicio){
    if(es_servicio){
      this.detallePedido.item=GlobalVariable.Dictionary.ITEM_SERVICIO;
    }else{
      if(this.usuario.empresa.usar_combos){
        this.detallePedido.item=GlobalVariable.Dictionary.ITEM_COMBO;
        this.detallePedido.combo={};
      }else{
        this.detallePedido.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
        this.detallePedido.producto={};
      }
    }
    this.editar_precio=es_servicio;
  }

  cambiarCombo(es_combo){
    if(es_combo){
      this.detallePedido.item=GlobalVariable.Dictionary.ITEM_COMBO;
      this.detallePedido.combo={};
    }else{
      this.detallePedido.item=GlobalVariable.Dictionary.ITEM_PRODUCTO;
      this.detallePedido.producto={};
    }
  }

  calcularCambio(){
    if(this.venta.tipoPago.nombre_corto=="CONT"){
      this.venta.cambio=Math.round((this.venta.pagado-this.pedido.total)*100)/100;
      this.venta.pagoMinimo=this.pedido.total;
    }else{
      this.venta.cambio=0;
      this.venta.pagoMinimo=0;
    }
  }

  enfocarElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      enfocarElemento(id_elemento);
    }
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClienteNit(this.usuario.id_empresa,term,this.usuario.empresa.usar_clientes_sucursal?this.pedido.sucursal.id:0)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

  calcularSaldo(){
    this.venta.saldo=this.pedido.total-this.venta.a_cuenta;
  }

  mostrarOcultarDatosAdicionales(){
    this.pedido.mostrar_datos_adicionales=!this.pedido.mostrar_datos_adicionales;
    if($(".datos-adicionales").css( "display")=="none"){
      $('.datos-adicionales').show( "slow" );
    }else{
      $( ".datos-adicionales" ).hide( 1000 );
    }
  }

  activarDesactivarBusquedaCodigoBarra(){
    this.busqueda_codigo_barra=!this.busqueda_codigo_barra;
  }

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProducto($event){
    this.producto=$event.item
    let producto_habilitado=true;
    if(this.usuario.empresa.usar_restriccion_productos_venta){
      let productoRestringido=this.productos_restriccion.filter(e => e.producto.id==this.producto.id)[0];
      producto_habilitado=productoRestringido?false:true;
    }

    if(producto_habilitado){
      this.producto.tipoProducto=this.producto['tipoProducto']==null?{id:this.producto['tipoProducto.id'],nombre:this.producto['tipoProducto.nombre'],nombre_corto:this.producto['tipoProducto.nombre_corto']}:this.producto.tipoProducto;

      this.productosService.obtenerPreciosProductos(this.producto.id).subscribe(async (precios_datos:any[]) => {
        let precios=[];
        if(this.usuario.empresa.usar_precios_productos_sucursal){
          precios=precios.concat(precios_datos.filter(p => p.id_sucursal==this.pedido.sucursal.id));
        }
        if(this.usuario.empresa.usar_precios_productos_clientes && this.pedido.cliente && this.pedido.cliente.id){
          precios=precios.concat(precios_datos.filter(p => p.id_cliente==this.pedido.cliente.id));
        }
        if(this.usuario.empresa.usar_precios_productos_usuarios){
          precios=precios.concat(precios_datos.filter(p => p.id_usuario==this.usuario.id));
        }
        if(precios.length==0){//Sin ninguno de los anteriores tiene precio
          precios=precios_datos.filter(p => (p.id_sucursal==null && p.id_cliente==null && p.id_usuario==null));
        }
        this.producto.precios=precios;
        this.editar_precio=false;

        let inventarios:any=[];
        if(this.usuario.empresa.usar_inventario && this.producto.activar_inventario){
          inventarios=await this.inventariosService.obtenerInventariosProducto(this.producto.id,this.pedido.almacen.id).toPromise();
        }else{
          if(this.usuario.empresa.usar_inventario){
            this.toastr.error("El producto tiene el control de inventario desactivado!");
          }
        }
        this.producto.inventarios=inventarios;
        if(this.usuario.empresa.usar_vencimientos && this.producto.activar_vencimiento){
          for(let i=0;i<this.producto.inventarios.length;i++){
            this.producto.inventarios[i].fecha_vencimiento=(this.producto.inventarios[i].fecha_vencimiento?new Date(this.producto.inventarios[i].fecha_vencimiento):null);
            this.producto.inventarios[i].fechaVencimientoTexto=(this.producto.inventarios[i].fecha_vencimiento?this.producto.inventarios[i].fecha_vencimiento.getDate()+"/"+(this.producto.inventarios[i].fecha_vencimiento.getMonth()+1)+"/"+this.producto.inventarios[i].fecha_vencimiento.getFullYear():"");
            this.producto.inventarios[i].detallesMovimiento=this.producto.inventarios[i].detallesMovimiento.filter( e => e.movimiento.tipo.nombre_corto=='MOVING');
            this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha=new Date(this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha);
            this.producto.inventarios[i].detallesMovimiento[0].movimiento.fechaTexto=this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getDate()+"/"+(this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getMonth()+1)+"/"+this.producto.inventarios[i].detallesMovimiento[0].movimiento.fecha.getFullYear();
          }
        }
        this.inventariosDisponibleProducto=[];
        this.inventariosDisponibleProducto.push({id:0,fecha_vencimiento:"TODOS",fechaVencimientoTexto:"TODOS",detallesMovimiento:[{movimiento:{fechaTexto:"TODOS",clase:{nombre:'TODOS'}}}]});
        this.inventariosDisponibleProducto=this.inventariosDisponibleProducto.concat(this.producto.inventarios);
        let inventarioDisponible=this.obtenerInventarioTotal(this.producto);

        this.detallePedido.producto=this.producto;
        this.detallePedido.precio_unitario=this.producto.precio_unitario;
        this.detallePedido.activar_inventario=this.producto.activar_inventario;
        this.detallePedido.inventarioProducto=this.inventariosDisponibleProducto[0];
        this.detallePedido.inventario_disponible=inventarioDisponible;
        this.detallePedido.costos=this.producto.activar_inventario?this.producto.inventarios:[];
        this.detallePedido.producto_precio=this.producto.precios.filter(e => e.por_defecto)[0];
        this.detallePedido.precio_unitario=this.detallePedido.producto_precio?this.detallePedido.producto_precio.precio_unitario:0;
        
        let arreglo=Util.colorearInventario(inventarioDisponible,this.producto);
        this.inventario_porcentaje=arreglo[0];
        this.inventario_color=arreglo[1];
        this.calcularImporte();
        //CerrarPopup(idModalInventario);
        enfocarElemento('venta_cantidad');
        setTimeout(()=>{
          aplicarVisorImagenes("imagen-producto-vista");
        }, 200);
      });
    }else{
      this.toastr.error("El producto no esta habilitado para la venta!");
      this.iniciarDetalleVenta();
    }
  }

  obtenerInventarioTotal(producto:any){
    let cantidadTotal = 0;
    if(producto.activar_inventario){
      for(let i = 0; i < producto.inventarios.length; i++){
        cantidadTotal += (producto.inventarios[i].cantidad);
      }
      for(let j=0;j<this.pedido.detallesPedido.length;j++){
        if(this.pedido.detallesPedido[j].producto && this.pedido.detallesPedido[j].producto.id){//es producto
          if(this.pedido.detallesPedido[j].producto.id==producto.id){
            cantidadTotal=cantidadTotal-this.pedido.detallesPedido[j].cantidad;
          }
        }else if(this.pedido.detallesPedido[j].combo && this.pedido.detallesPedido[j].combo.id){//es combo
          for(let k=0;k<this.pedido.detallesPedido[j].combo.detallesCombo.length;k++){
            if(this.pedido.detallesPedido[j].combo.detallesCombo[k].producto.id==producto.id){
              cantidadTotal=cantidadTotal-(this.pedido.detallesPedido[j].cantidad*this.pedido.detallesPedido[j].combo.detallesCombo[k].cantidad);
            }
          }
        }
      }
    }else{
      cantidadTotal=500000;
    }
    return (cantidadTotal>0?cantidadTotal:0);
  }

  calcularImporte(){
    this.detallePedido.importe=Math.round((this.detallePedido.cantidad*this.detallePedido.precio_unitario)*1000)/1000;
    let descuento,recargo;
    if(this.detallePedido.tipo_descuento){
      descuento=this.detallePedido.importe*(this.detallePedido.descuento/100);
    }else{
      descuento=this.detallePedido.descuento;
    }
    if(this.detallePedido.tipo_recargo){
      recargo=this.detallePedido.importe*(this.detallePedido.recargo/100);
    }else{
      recargo=this.detallePedido.recargo;
    }
    this.detallePedido.total=Math.round((this.detallePedido.importe-descuento+recargo-this.detallePedido.ice-this.detallePedido.excento)*1000)/1000;
  }

  iniciarDetalleVenta(){
    this.producto_busqueda="";
    this.combo_busqueda="";
    this.detallePedido={
      es_servicio:this.usuario.empresa.usar_servicios,
      es_combo:this.usuario.empresa.usar_combos,
      item:this.usuario.empresa.usar_servicios?GlobalVariable.Dictionary.ITEM_SERVICIO:this.usuario.empresa.usar_combos?GlobalVariable.Dictionary.ITEM_COMBO:GlobalVariable.Dictionary.ITEM_PRODUCTO,
      cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
    }
    if(this.detallePedido.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      this.detallePedido.producto={};
      $("#venta_id_producto").val("");
      enfocarElemento('venta_id_producto');
    }else if(this.detallePedido.item==GlobalVariable.Dictionary.ITEM_COMBO){
      this.detallePedido.combo={};
      $("#venta_id_combo").val("");
      enfocarElemento('venta_id_combo');
    }else{
      $("#venta_id_descripcion").val("");
      enfocarElemento('venta_id_descripcion');
    }
  }

  buscarCodigoBarraProducto(evento:any,codigo_barra:any){
    var venta=this;
    if (evento.which === 13){
      this.productosService.buscarProducto(this.usuario.id_empresa,codigo_barra).subscribe((results:any[]) => {
        if(results.length>=1){
          venta.establecerProducto({item:results[0]});
          enfocarElemento('venta_cantidad');
        }
      });
    }
  }

  clickearElemento(keyEvent,id_elemento){
    if (keyEvent.which === 13){
      clickearElemento(id_elemento);
    }
  }

  buscarCombo = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.combosService.buscarCombo(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterCombo = (x: any) => (x.nombre);

  establecerCombo($event){
    this.combo=$event.item;
    this.combosService.obtenerPreciosCombo(this.combo.id,this.pedido.sucursal.id).subscribe((precios:any[]) => {
      this.combo.precios=precios;
      this.editar_precio=false;
      
      var ids_productos="";
      for(let j=0;j<this.combo.detallesCombo.length;j++){
        if((j+1)==this.combo.detallesCombo.length){
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id;
        }else{
          ids_productos=ids_productos+this.combo.detallesCombo[j].producto.id+",";
        }
      }

      this.productosService.obtenerInventariosProductos(ids_productos,this.pedido.almacen.id).subscribe((productos_inventario:any[]) => {
        let totalInventarioCombo=50000;
        for(let j=0;j<this.combo.detallesCombo.length;j++){
          let prodInv=productos_inventario.filter(e => e.id == this.combo.detallesCombo[j].producto.id)[0];
          let totalInvProducto=this.obtenerInventarioTotal(prodInv);

          this.combo.detallesCombo[j].costos=prodInv.inventarios;
          let totalInventarioComboPorProducto=parseInt((totalInvProducto/this.combo.detallesCombo[j].cantidad).toString());
          totalInventarioCombo=(totalInventarioComboPorProducto<totalInventarioCombo)?totalInventarioComboPorProducto:totalInventarioCombo;
        }

        this.detallePedido.combo=this.combo;
        this.detallePedido.precio_unitario=this.combo.precio_unitario;
        this.detallePedido.inventario_disponible=totalInventarioCombo;

        if(this.usuario.empresa.usar_precios_productos_sucursal){
          var precio=this.combo.precios.filter(e => e.por_defecto && e.id_sucursal === this.pedido.sucursal.id);
          if(precio.length>0){
            this.detallePedido.combo_precio=precio[0];
            this.detallePedido.precio_unitario=precio[0].precio_unitario;
          }
        }else{
          this.detallePedido.combo_precio=this.combo.precios.filter(e => e.por_defecto)[0];
        }

        this.combo.inventario_minimo=1;
        var arreglo=Util.colorearInventario(totalInventarioCombo,this.combo);
        this.inventario_porcentaje=arreglo[0];
        this.inventario_color=arreglo[1];
        this.calcularImporte();
        enfocarElemento('venta_cantidad');
      });
    });
  }

  actualizarInventarioDisponibleFechaVencimiento(){
    if(this.detallePedido.inventarioProducto.id!=0){
      this.detallePedido.costos=[];
      this.detallePedido.costos.push(this.detallePedido.inventarioProducto);
      this.detallePedido.inventario_disponible=this.obtenerInventarioTotalPorFechaVencimiento();
      this.detallePedido.lote=this.detallePedido.inventarioProducto.lote;
    }else{
      this.detallePedido.inventario_disponible=this.obtenerInventarioTotal(this.detallePedido.producto);
      this.detallePedido.costos=this.detallePedido.producto.inventarios;
      this.detallePedido.lote="";
    }
    var arreglo=Util.colorearInventario(this.detallePedido.inventario_disponible,this.detallePedido.producto);
    this.inventario_porcentaje=arreglo[0];
    this.inventario_color=arreglo[1];
    this.calcularImporte();
  }

  obtenerInventarioTotalPorFechaVencimiento(){
    var cantidadTotal = this.detallePedido.inventarioProducto.cantidad;
    for(var j=0;j<this.pedido.detallesPedido.length;j++){
      if(this.pedido.detallesPedido[j].producto){
        if(this.pedido.detallesPedido[j].producto.id==this.detallePedido.producto.id && this.pedido.detallesPedido[j].costos[0].id==this.detallePedido.inventarioProducto.id){
          cantidadTotal=cantidadTotal-this.pedido.detallesPedido[j].cantidad;
        }
      }else{//es combo
        for(let k=0;k<this.pedido.detallesPedido[j].combo.detallesCombo.length;k++){
          if(this.pedido.detallesPedido[j].combo.detallesCombo[k].producto.id==this.detallePedido.producto.id && this.pedido.detallesPedido[j].combo.detallesCombo[k].costos[0].id==this.detallePedido.inventarioProducto.id){
            cantidadTotal=cantidadTotal-(this.pedido.detallesPedido[j].cantidad*this.pedido.detallesPedido[j].combo.detallesCombo[k].cantidad);
          }
        }
      }
    }
    return cantidadTotal;
  }

  modificarPrecio(){
    this.editar_precio=true;
  }

  establecerPrecio(){
    this.editar_precio=false;
  }

  establecerPrecioDetalle(){
    if(this.detallePedido.producto){
      this.detallePedido.precio_unitario=this.detallePedido.producto_precio.precio_unitario;
    }else{
      this.detallePedido.precio_unitario=this.detallePedido.combo_precio.precio_unitario;
    }
    this.calcularImporte();
  }

  validarDetallePedido(detallePedido){
    let res=true;
    if(!detallePedido.cantidad){
      res=res && false;
      this.toastr.error("Debe especificar la cantidad del detalle!");
    }
    if(!detallePedido.precio_unitario){
      res=res && false;
      this.toastr.error("Debe especificar el precio unitario del detalle!");
    }

    if(detallePedido.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
      if(this.configuracion_pagina.getOrden().controlar_cantidades_panel && detallePedido.producto.activar_inventario && detallePedido.cantidad>detallePedido.inventario_disponible){
        this.toastr.error("Cantidad insuficiente en inventario!");
        res=res && false;
      }
      if(!detallePedido.producto.id){
        res=res && false;
        this.toastr.error("Debe especificar el producto!");
      }
      if(!detallePedido.producto_precio){
        res=res && false;
        this.toastr.error("Debe especificar el concepto del precio!");
      }
    }else if(detallePedido.item==GlobalVariable.Dictionary.ITEM_COMBO){
      if(detallePedido.cantidad>detallePedido.inventario_disponible){
        this.toastr.error("Cantidad insuficiente en inventario!");
        res=res && false;
      }
      if(!detallePedido.combo.id){
        res=res && false;
        this.toastr.error("Debe especificar el combo!");
      }
    }
    return res;
  }

  agregarDetallePedido(){
    if(this.validarDetallePedido(this.detallePedido)){
      if(this.detallePedido.item==GlobalVariable.Dictionary.ITEM_PRODUCTO){
        this.pedido.descuento=(this.usuario.empresa.usar_descuento_global?this.pedido.descuento:this.pedido.descuento+(this.detallePedido.tipo_descuento?this.detallePedido.importe*(this.detallePedido.descuento/100):this.detallePedido.descuento));
        var detalleVentaAAgregar=this.detallePedido;
        var detalleVentaProductoExistente=this.pedido.detallesPedido.filter(e => e.producto && (e.producto.id == detalleVentaAAgregar.producto.id));
        if(detalleVentaProductoExistente.length>0){
          detalleVentaProductoExistente=detalleVentaProductoExistente[0];
          detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
          this.calcularImporteDetalle(detalleVentaProductoExistente);
          this.pedido.detallesPedido[this.pedido.detallesPedido.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
        }else{
          this.pedido.detallesPedido.push(this.detallePedido);
        }
        this.inventariosDisponibleProducto=[];
        this.iniciarDetalleVenta();
      }else if(this.detallePedido.item==GlobalVariable.Dictionary.ITEM_COMBO){
        this.pedido.detallesPedido.push(this.detallePedido);
        this.iniciarDetalleVenta();
      }else{
        this.pedido.detallesPedido.push(this.detallePedido);
        this.iniciarDetalleVenta();
      }
      this.sumarTotal();
      this.sumarTotalImporte();
      if(this.pedido.cobrar_pedido){
        this.calcularSaldo();
        this.venta.pagado=this.pedido.total;
        this.calcularCambio();
      }
      //this.capturarInteraccion();
    }
  }

  actualizarCantidadDetallePedido(detalle_pedido){
    this.calcularImporteDetalle(detalle_pedido);
    this.sumarTotal();
    this.sumarTotalImporte();
    if(this.pedido.cobrar_pedido){
      this.calcularSaldo();
      this.venta.pagado=this.pedido.total;
      this.calcularCambio();
    }
  }

  calcularImporteDetalle(detallePedido){
    detallePedido.importe=Math.round((detallePedido.cantidad*detallePedido.precio_unitario)*1000)/1000;
    var descuento,recargo;
    if(detallePedido.tipo_descuento){
      descuento=detallePedido.importe*(detallePedido.descuento/100);
    }else{
      descuento=detallePedido.descuento;
    }
    if(detallePedido.tipo_recargo){
      recargo=detallePedido.importe*(detallePedido.recargo/100);
    }else{
      recargo=detallePedido.recargo;
    }
    detallePedido.total=Math.round((detallePedido.importe-descuento+(recargo?recargo:0)-(detallePedido.ice?detallePedido.ice:0)-(detallePedido.excento?detallePedido.excento:0))*1000)/1000;
    if(this.usuario.empresa.usar_obsequios && detallePedido.es_obsequio){
      detallePedido.importe=0;
      detallePedido.total=0;
    }
  }

  sumarTotalImporte(){
    var sumaImporte=0;
    for(var i=0;i<this.pedido.detallesPedido.length;i++){
      if(!this.pedido.detallesPedido[i].eliminado){
        sumaImporte=sumaImporte+this.pedido.detallesPedido[i].importe;
      }
    }		
    this.pedido.importe=Math.round((sumaImporte)*1000)/1000;
  }

  sumarTotal(){
    var sumaTotal=0;
    for(var i=0;i<this.pedido.detallesPedido.length;i++){
      if(!this.pedido.detallesPedido[i].eliminado){
        sumaTotal=sumaTotal+this.pedido.detallesPedido[i].total;
      }
    }		
    this.pedido.total=Math.round((sumaTotal)*1000)/1000;
  }

  mostrarDescuentos(){
    var style=$(".des-datos").css("display");
    if(style=="none"){
      $(".des-datos").css("display","");
    }else{
      $(".des-datos").css("display","none");
    }
  }

  guardarConfiguracionVentaVista(){
    this.ventasService.actualizarConfiguracionVista(this.usuario.id_empresa,this.configuracionVentaVista).subscribe((res:any) => {
      this.toastr.info("Actualizado satisfactoriamente!");
    });
  }

  eliminarDetallePedido(detallePedido){
    if(detallePedido.id){
      detallePedido.eliminado=true;
    }else{
      this.pedido.detallesPedido.splice(this.pedido.detallesPedido.indexOf(detallePedido),1);
    }
    this.pedido.descuento=(this.usuario.empresa.usar_descuento_global?this.pedido.descuento:this.pedido.descuento-(detallePedido.tipo_descuento?detallePedido.importe*(detallePedido.descuento/100):detallePedido.descuento));
    this.sumarTotal();
    this.sumarTotalImporte();
    if(this.pedido.cobrar_pedido){
      this.calcularSaldo();
      this.calcularCambio();
    }
  }

  guardarVentaBorrador(){
    const md5 = new Md5();
    this.pedido.fecha=Util.convertirObjetoAfecha(this.pedido.fecha_texto);
    let ventas_borrador=this.persistenciaService.get("ventas")?this.persistenciaService.get("ventas"):[];
    if(this.pedido._id){
      ventas_borrador=ventas_borrador.filter(e => e._id != this.pedido._id);
      ventas_borrador.push(this.pedido);
    }else{
      this.pedido._id=md5.appendStr(new Date().toUTCString()).end();
      ventas_borrador.push(this.pedido);
    }
    this.persistenciaService.set("ventas",ventas_borrador);
    this.toastr.info("Venta guardada como borrador!");
    this.alTerminar.emit();
  }

  validarPedido(){
    let res=true;
    if(!this.pedido.cliente.razon_social || !this.pedido.cliente.nit){
      res=res && false;
      this.toastr.error("Debe especificar el nit/ci y razon social del cliente!");
    }
    if(!this.pedido.fecha_texto || !this.pedido.fecha_texto.year || !this.pedido.fecha_texto.month || !this.pedido.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la venta!");
    }
    if(this.pedido.detallesPedido.length==0){
      res=res && false;
      this.toastr.error("Debe agregar al menos un item al pedido!");
    }
    if(this.pedido.cobrar_pedido){
      if(this.venta.tipoPago.nombre_corto=="CRE" && (!this.venta.dias_credito)){
        res=res && false;
        this.toastr.error("Debe especificar los dias de credito de la venta!");
      }
      if(this.venta.tipoPago.nombre_corto=="CRE" && (this.venta.a_cuenta==undefined)){
        res=res && false;
        this.toastr.error("Debe especificar el importe a cuenta de la venta!");
      }
      if(this.venta.tipoPago.nombre_corto=="CONT" && (this.venta.pagado<this.venta.total || this.venta.cambio<0)){
        res=res && false;
        this.toastr.error("El importe a pagar debe ser igual o superior al importe de la venta!");
      }
      if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && !this.venta.actividad){
        res=res && false;
        this.toastr.error("Debe especificar la actividad!");
      }
      if(this.venta.transaccion.nombre_corto==GlobalVariable.Dictionary.EGRE_FACTURACION && this.venta.sucursal.actividadesDosificaciones[0]){
        let fecha_limite_emision=new Date(this.venta.sucursal.actividadesDosificaciones[0].dosificacion.fecha_limite_emision);
        fecha_limite_emision.setHours(23);
        fecha_limite_emision.setMinutes(59);
        fecha_limite_emision.setSeconds(59);
        let fecha_actual=new Date();
        if(fecha_limite_emision<fecha_actual){
          res=res && false;
          this.toastr.error("La fecha límite de emisión "+(fecha_limite_emision.getDate()+"/"+(fecha_limite_emision.getMonth()+1)+"/"+fecha_limite_emision.getFullYear())+" de su dosificación feneció, debe renovar!");
        }
      }
    }
    for(let i=0;i<this.pedido.detallesPedido.length;i++){
      res=res && this.validarDetallePedido(this.pedido.detallesPedido[i]);
    }
    return res;
  }
  
  async guardar(){
    this.blockUI.start();
    if(this.validarPedido()){
      let tiempoActual=new Date();
      this.pedido.tiempo_ingreso=Util.convertirObjetoAfecha(this.pedido.fecha_texto);
      this.pedido.tiempo_ingreso.setHours(tiempoActual.getHours());
      this.pedido.tiempo_ingreso.setMinutes(tiempoActual.getMinutes());
      this.pedido.id_sucursal=this.pedido.sucursal.id;

      let venta_res:any=null;
      if(this.pedido.cobrar_pedido){
        this.venta.descontar_almacen=this.pedido.descontar_almacen;
        this.venta.sucursal=this.pedido.sucursal;
        this.venta.observacion=this.pedido.observacion;
        this.venta.fecha=this.pedido.tiempo_ingreso;
        this.venta.canal=this.pedido.publico?this.canales.filter(e => e.nombre_corto == this.global_variable.Dictionary.CANAL_VENTA_ECOMERCE)[0]:this.canales[0];
        this.venta.total=this.pedido.total;
        this.venta.importe=this.pedido.importe;
        this.venta.usuario={id:this.usuario.id,id_empresa:this.usuario.id_empresa};
        this.venta.id_empresa=this.usuario.empresa.id;
        this.venta.detallesVentaNoConsolidadas=[];
        this.venta.almacen=this.pedido.almacen;
        this.venta.detallesVenta=[];
        this.venta.cliente=this.pedido.cliente;
        this.venta.razon_social=this.venta.cliente.razon_social;
        this.venta.nit_ci=this.venta.cliente.nit;
        this.venta.detallesVenta=this.pedido.detallesPedido;
        this.venta.a_cuenta=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.a_cuenta:this.venta.total;
        this.venta.saldo=this.venta.tipoPago.nombre_corto=="CRE"?this.venta.saldo:0;
        this.venta.id_contacto_cliente=this.pedido.contacto_cliente?this.pedido.contacto_cliente.id:null;
        this.venta.estado=this.pedido.tipoPago.nombre_corto=="CRE" && this.pedido.saldo>0?this.estados.filter(e => e.nombre_corto == "ESTVENTVIG")[0]:this.estados.filter(e => e.nombre_corto == "ESTVENTSAN")[0];

        venta_res=await this.ventasService.guardarVenta(this.venta).toPromise();
        if(venta_res.tiene_error){
          this.toastr.error(venta_res.mensaje);
        }else{
          this.pedido.id_venta=venta_res.venta.id;
          this.pedido.id_cliente=venta_res.venta.cliente.id;
          this.pedido.cliente=venta_res.venta.cliente;
          this.toastr.success(venta_res.mensaje);
          this.imprimirVenta(venta_res.venta);
        }
      }

      let resPc:any;
      if(this.pedido.cobrar_pedido){
        if(venta_res.tiene_error){
          resPc={tiene_error:venta_res.tiene_error,mensaje:'No se pudo guardar el pedido!'};
        }else{
          resPc=await this.pedidosService.guardarPedido(this.pedido).toPromise();
          if(this.pedido.descontar_almacen){
            this.pedido.id_movimiento=venta_res?venta_res.venta.id_movimiento:null;
          }
        }
      }else{
        resPc=await this.pedidosService.guardarPedido(this.pedido).toPromise();
      }

      if(resPc.tiene_error){
        this.toastr.error(resPc.mensaje);
      }else{
        this.alTerminar.emit({confirm:true});
        this.toastr.success('Pedido guardado Exitosamente!');
      }
      this.blockUI.stop();
    }else{
      this.blockUI.stop();
    }
  }

  async imprimirVenta(venta){
    this.pdfService.imprimirVenta(venta);
		/*this.ventasService.obtenerVenta(venta.id).subscribe(async (ventaConsultada:any) => {
			var fecha=new Date(ventaConsultada.fecha);
			ventaConsultada.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
			this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).subscribe((conf:any)=>{
        let configuracion_aplicacion_ventas=conf;
        this.pdfService.imprimirVenta(ventaConsultada,configuracion_aplicacion_ventas.datos.opciones_permisos.impresion,false,this.usuario,this.tipo_textos);
      });
		});*/
  }

  

  abrirPopupRegistroRapidoCliente(){
    this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.pedido.cliente));
    this.modalCliente.componentInstance.sucursales = this.pedido.sucursales;
    this.modalCliente.componentInstance.sucursal = this.pedido.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerCliente(res.cliente);
					this.modalCliente.close();
				}
			}else{
				this.modalCliente.close();
			}
			
		});
		
		
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
  }

  abrirBusquedaProducto(){
    this.modalCliente = this.modalService.open(BusquedaProductoComponent, {windowClass:'busqueda-producto', ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg',scrollable:true});
    this.modalCliente.componentInstance.sucursal = this.pedido.sucursal;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
        this.producto_busqueda=res;
        this.establecerProducto({item:res});
			}
      this.modalCliente.close();
			
		});
		
		
		if(this.usuario.empresa.usar_pantalla_cliente){
			//socket.emit('comenzarVenta',$rootScope.venta);
		}
  }


  async establecerVenta(){
    if(this.pedido.cobrar_pedido){
      this.calcularSaldo();
      this.venta.pagado=this.pedido.total;
      this.calcularCambio();
      this.venta.sucursal=this.pedido.sucursal;
      this.obtenerTiposPago(this.nombre_corto_tipo_pago);
      this.obtenerMovimientosEgreso(this.nombre_corto_transaccion);
      this.obtenerEstados(this.nombre_corto_estado_saneado);
      await this.obtenerCanales(GlobalVariable.Dictionary.CANAL_VENTA_DIRECTA);
    }else{
      this.venta=null;
    }
  }

  establecerDescuentoGlobal(){
    let descuento_detalles=0,descuento=0;
    for(let i=0;i<this.pedido.detallesPedido.length;i++){
      descuento_detalles=descuento_detalles+(this.pedido.detallesPedido[i].tipo_descuento?(this.pedido.detallesPedido[i].importe*(this.pedido.detallesPedido[i].descuento/100)):this.pedido.detallesPedido[i].descuento);
    }
    if(this.pedido.tipo_descuento){
      descuento=(this.pedido.importe-descuento_detalles)*(this.pedido.descuento/100);
    }else{
      descuento=this.pedido.descuento;
    }
    this.pedido.total=this.pedido.importe-descuento_detalles-descuento;
    this.pedido.usar_descuento_global=true;
  }

}
