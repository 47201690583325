<div class="modal-header">
    <div class="col-md-2 col-xs-2">
        <span class="widget-title text-blue text-125">Libro Mayor</span>
    </div>
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Cuenta: {{cuenta.nombre}}</h4>
    </div>
    <div class="col-md-2 col-xs-2">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
      <div class="bootstrap-table bootstrap4">
        <div class="fixed-table-toolbar">
            <div class="row">
                <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="feini">Desde </label>&nbsp;
                    <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                    </div>
                </div>  
                </div>
                <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="fefin">Hasta </label>&nbsp;
                    <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>¿Bimonetario? </label>
                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1 form-control form-control-sm" type="checkbox" [(ngModel)]="filter.bimonetario"/>
                </div>
                <div class="col-4">
                    <button (click)="buscarAsientosLibroMayor()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>&nbsp;
                    <button (click)="generarPdf()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Pdf">
                        <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                    </button>&nbsp;
                    <button (click)="generarExcel()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Excel">
                        <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                    </button>
                </div>
              </div>
        </div>
        <div class="fixed-table-container" style="padding-bottom: 0px;">
          <div class="fixed-table-header" style="display: none;">
            <table></table>
          </div>
          <div class="table-responsive table-fixed-header" style="height: 450px;">
            <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
              <span class="loading-wrap">
                <span class="animation-wrap">
                  <span class="animation-dot"></span>
                </span>
              </span>
            </div>
  
            <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                <thead fix-head class="table-head-color sticky-nav">
                    <tr class="text-white text-center">
                        <th COLSPAN=3 class="bgc-grey-d1">Datos Comprobante</th>
                        <th COLSPAN=3 class="bgc-primary-d1">Bolivianos</th>
                        <th *ngIf="filter.bimonetario" COLSPAN=3 class="bgc-success-d1">Dolares</th>
                    </tr>
                    <tr class="text-white text-center">
                        <th class="bgc-grey-d1">Fecha</th>
                        <th class="bgc-grey-d1">Nº</th>
                        <th class="bgc-grey-d1">Descripción</th>
                        <th class="bgc-primary-d1">Debe</th>
                        <th class="bgc-primary-d1">Haber</th>
                        <th class="bgc-primary-d1">Deudor Acreedor</th>
                        <th class="bgc-success-d1" *ngIf="filter.bimonetario">Debe</th>
                        <th class="bgc-success-d1" *ngIf="filter.bimonetario">Haber</th>
                        <th class="bgc-success-d1" *ngIf="filter.bimonetario">Deudor Acreedor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let asiento of asientos_mayor">
                        <td>{{asiento.comprobante.fecha | date:"dd/MM/yyyy"}}</td>
                        <td>{{asiento.comprobante.numero}}</td>
                        <td>{{asiento.comprobante.glosa}}</td>
                        <td>{{asiento.debe_bs.toFixed(2)}}</td>
                        <td>{{asiento.haber_bs.toFixed(2)}}</td>
                        <td>{{asiento.saldo.toFixed(2)}}</td>
                        <td *ngIf="filter.bimonetario">{{asiento.debe_sus.toFixed(2) }}</td>
                        <td *ngIf="filter.bimonetario">{{asiento.haber_sus.toFixed(2)}}</td>
                        <td *ngIf="filter.bimonetario">{{asiento.saldo_sus.toFixed(2)}}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{totales_asiento.total_debe_bs.toFixed(2)}}</td>
                        <td>{{totales_asiento.total_haber_bs.toFixed(2)}}</td>
                        <td></td>
                        <td *ngIf="filter.bimonetario">{{totales_asiento.total_debe_sus.toFixed(2)}}</td>
                        <td *ngIf="filter.bimonetario">{{totales_asiento.total_haber_sus.toFixed(2)}}</td>
                        <td *ngIf="filter.bimonetario"></td>
                    </tr>
                </tbody>
            </table>  
              
          </div>
          <div class="fixed-table-footer">
            <table><thead><tr></tr></thead></table>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
</div>