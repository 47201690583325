import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  constructor(private http: HttpClient) { }

  public obtenerListaPedidos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido-restaurante/"+paginator.filter.ids_sucursales_usuario+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/usuario/"+paginator.filter.usuario.id+"/estado/"+paginator.filter.estado.id+"/cliente/"+((paginator.filter.cliente=="" || paginator.filter.cliente==undefined)?0:paginator.filter.cliente)+"/producto/"+((paginator.filter.producto=="" || paginator.filter.producto==undefined)?0:paginator.filter.producto)+"/validez/"+paginator.filter.validez.id+"/tipo-despacho/"+paginator.filter.tipo_despacho.id+"/tipo-pago/"+paginator.filter.tipo_pago.id+"/solo-obsequios/"+(paginator.filter.solo_obsequios!=undefined && paginator.filter.solo_obsequios?1:0)+"/columna/"+paginator.configuracion_pagina.getOrden().orden_por_columna+"/direccion/"+paginator.configuracion_pagina.getOrden().orden_direccion, GlobalVariable.httpOptions);
  }

  public guardarPedido(pedido){
    return this.http.post(GlobalVariable.API_SERVER_URL+"pedido-restaurante/mesa",pedido,GlobalVariable.httpOptions);
  }

  public inactivarPedido(pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inactivacion-pedido-restaurante/"+pedido.id,pedido,GlobalVariable.httpOptions);
  }  

  public obtenerListaPedidosNotificacion(id_sucursales,id_usuario,columna,direccion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedidos-notificacion/sucursal/"+id_sucursales+"/usuario/"+id_usuario+"/columna/"+columna+"/direccion/"+direccion,GlobalVariable.httpOptions);
  }

  public inactivarPedidoMesa(pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inactivacion-mesa-pedido-restaurante/"+pedido.id,pedido,GlobalVariable.httpOptions);
  }  

  public obtenerPedidoMesa(id_mesa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido-restaurante/mesa/"+id_mesa,GlobalVariable.httpOptions);
  }

  public actualizarMesa(mesa){
    return this.http.put(GlobalVariable.API_SERVER_URL+"mesa/"+mesa.id,mesa,GlobalVariable.httpOptions);
  }  

  public obtenerPedido(id_pedido){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido/"+id_pedido,GlobalVariable.httpOptions);
  }

  public obtenerPedidoDespacho(id_pedido){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido-despacho/"+id_pedido,GlobalVariable.httpOptions);
  }

  public obtenerPedidoRestaurante(id_pedido){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedido-restaurante/"+id_pedido,GlobalVariable.httpOptions);
  }

  public actualizarEstadoPedido(pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"pedido-actualizacion-estado/"+pedido.id,pedido,GlobalVariable.httpOptions);
  }  

  public obtenerInventarioPedidosMesas(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pedidos-mesas-control-inventario/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerProductosPanelConInventarioActivado(id_empresa,id_sucursal,id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-panel-inventario-activado/empresa/"+id_empresa+"/sucursal/"+id_sucursal+"/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }

  /*public obtenerProductosPanelConInventarioActivadoProduccion(id_empresa,id_sucursal,id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-panel-inventario-activado-produccion/empresa/"+id_empresa+"/sucursal/"+id_sucursal+"/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }*/

  public obtenerProductoFinal(id_producto,id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"producto-final/"+id_producto+"/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }

  public obtenerProductosPanelSinInventarioActivado(id_empresa,id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-panel-inventario-inactivado/empresa/"+id_empresa+"/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public registrarDespacho(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"despacho-pedido-notificacion",datos,GlobalVariable.httpOptions);
  }

  public obtenerDespacho(id_despacho){
    return this.http.get(GlobalVariable.API_SERVER_URL+"despacho/"+id_despacho,GlobalVariable.httpOptions);
  }

  public actualizarPedidoRestaurante(pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"pedido-restaurante/"+pedido.id,pedido,GlobalVariable.httpOptions);
  }

  public obtenerSalasSucursal(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sala/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerPedidosPantallaNoEntregados(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"restaurante-pedidos-no-entregados/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public subirActualizacionEstadoPedido(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"sync/subir-actualizacion-estado-pedido",datos,GlobalVariable.httpOptions);
  }

  public actualizarDescripcionDetallePedido(detalle_pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"detalle-pedido/"+detalle_pedido.id,detalle_pedido,GlobalVariable.httpOptions);
  }

  public agregarMesaPedido(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"agregar-mesa-pedido",datos,GlobalVariable.httpOptions);
  }

  public actualizarDetallePedido(detalle_pedido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"detalle-pedido/"+detalle_pedido.id,detalle_pedido,GlobalVariable.httpOptions);
  }

  public obtenerComandaPedido(pedido){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(GlobalVariable.API_SERVER_URL+"impresion-comanda-pedido/"+pedido.id,pedido,{ headers: headers, responseType: 'blob' });
  }

  
    
}
