import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class InventarioExternoService {

  constructor(private http: HttpClient) { }

  public obtenerListaInventariosExternos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"filtrar-inventario-externo/empresa/"+paginator.filter.id_empresa+"/sucursal/"+paginator.filter.sucursal.id+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin,GlobalVariable.httpOptions);
  }

  public obtenerInventarioExterno(id_inventario_externo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inventario-externo/"+id_inventario_externo,GlobalVariable.httpOptions);
  }

  public guardarInventarioExterno(inventario_externo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"inventario-externo/",inventario_externo,GlobalVariable.httpOptions);
  }

  public actualizarInventarioExterno(inventario_externo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inventario-externo/"+inventario_externo.id,inventario_externo,GlobalVariable.httpOptions);
  }

  public eliminarInventarioExterno(inventario_externo){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"inventario-externo/"+inventario_externo.id,GlobalVariable.httpOptions);
  }
}
