import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoEstudianteService {

  constructor(private http: HttpClient) { }

  public guardarSeguimientoEstudiante(seguimiento){
    return this.http.post(GlobalVariable.API_SERVER_URL+"seguimiento-estudiante",seguimiento,GlobalVariable.httpOptions);
  }

  public actualizarSeguimientoEstudiante(seguimiento){
    return this.http.put(GlobalVariable.API_SERVER_URL+"seguimiento-estudiante/"+seguimiento.id,seguimiento,GlobalVariable.httpOptions);
  }

  public eliminarSeguimientoEstudiante(seguimiento){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"seguimiento-estudiante/"+seguimiento.id,GlobalVariable.httpOptions);
  }

  public obtenerListaSeguimientos(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"seguimientos-estudiantes/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/usuario/"+paginator.filter.usuario.id+"/curso/"+paginator.filter.registro.curso.id+"/materia/"+paginator.filter.registro.materia.id, GlobalVariable.httpOptions);
  }
}
