<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Viajes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Viajes
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoViaje()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
			  <div class="keep-open btn-group show" title="Columns">
				
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="viajes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().vehiculo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='vehiculo' && this.direction=='asc','desc':this.column=='vehiculo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('vehiculo')">Vehiculo</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().origen.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='punto_origen' && this.direction=='asc','desc':this.column=='punto_origen' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('punto_origen')">Origen</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().destino.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='punto_destino' && this.direction=='asc','desc':this.column=='punto_destino' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('punto_destino')">Destino</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().tiempo_salida.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='tiempo_salida' && this.direction=='asc','desc':this.column=='tiempo_salida' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('tiempo_salida')">Tiempo Salida</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().tiempo_llegada.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='tiempo_llegada' && this.direction=='asc','desc':this.column=='tiempo_llegada' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('tiempo_llegada')">Tiempo Llegada</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().kilometraje_inicial.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='kilometraje_inicial' && this.direction=='asc','desc':this.column=='kilometraje_inicial' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('kilometraje_inicial')">Kilometraje Inicial</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().kilometraje_final.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='kilometraje_final' && this.direction=='asc','desc':this.column=='kilometraje_final' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('kilometraje_final')">Kilometraje Final</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #viajes *ngFor="let viaje of viajes; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().vehiculo.show">{{viaje.vehiculo}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().origen.show">{{viaje.origen}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().destino.show">{{viaje.destino}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().tiempo_salida.show">{{viaje.tiempo_salida | date:'dd/MM/yyyy'}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().tiempo_llegada.show">{{viaje.tiempo_llegada}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().kilometraje_inicial.show">{{viaje.kilometraje_inicial}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().kilometraje_final.show">{{viaje.kilometraje_final}}</td>

                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(viaje)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="viajes.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #edicion_viaje let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Viaje</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarViaje()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-2">
                <label>Vehiculo</label>
            </div>
            <div class="col-sm-12 col-md-5">
                <select (change)="calcularKilometraje()" [compareWith]="compararObjectos" class="form-control" name="vehiculo" [(ngModel)]="viaje.vehiculo">
                    <option [ngValue]="vehiculo" *ngFor="let vehiculo of vehiculos">{{vehiculo.placa}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-3" *ngIf="viaje.vehiculo">
              <label>Kilometraje Actual</label>
            </div>
            <div class="col-sm-12 col-md-2" *ngIf="viaje.vehiculo">
              <label>{{viaje.vehiculo.kilometraje_actual}}</label>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-2">
                <label>Ruta</label>
            </div>
            <div class="col-sm-12 col-md-5">
                <select (change)="calcularKilometraje()" [compareWith]="compararObjectos" class="form-control" name="ruta" [(ngModel)]="viaje.ruta">
                    <option [ngValue]="ruta" *ngFor="let ruta of rutas">{{ruta.origen.nombre}} - {{ruta.destino.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-3" *ngIf="viaje.ruta">
              <label>Distancia (Km)</label>
            </div>
            <div class="col-sm-12 col-md-2" *ngIf="viaje.ruta">
              <label>{{viaje.ruta.distancia_kilometros}}</label>
            </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-12 col-md-2">
              <label>Fecha</label>
          </div>
          <div class="col-sm-12 col-md-5">
            <div class="input-group">
              <input name="tiempo_salida" class="form-control" placeholder="dd/mm/yyyy"
                      name="dp" [(ngModel)]="viaje.tiempo_salida_texto" ngbDatepicker #fi="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
              </div>
          </div>
          </div>
          <div class="col-sm-12 col-md-3" *ngIf="viaje.ruta">
            <label>Kilometraje Actualizado</label>
          </div>
          <div class="col-sm-12 col-md-2" *ngIf="viaje.ruta">
            <label>{{viaje.kilometraje_final}}</label>
          </div>
        </div>
        <hr>
        
    </div>
</ng-template>