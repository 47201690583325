import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { ToastrService } from 'ngx-toastr';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CombosService } from '../../services/combos/combos.service';
import { Observable, of } from 'rxjs';
import { ClientesService } from '../../services/clientes/clientes.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';

@Component({
  selector: 'app-lista-devoluciones-ventas',
  templateUrl: './lista-devoluciones-ventas.component.html',
  styleUrls: ['./lista-devoluciones-ventas.component.css']
})
export class ListaDevolucionesVentasComponent extends BaseComponent implements OnInit {

  lista_devoluciones_ventas:any[]=[];
  venta:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  popupConfirmacion:NgbModalRef;
  devolucion_venta:any;
  devolucion_ventas_global:any;
  sucursales:any[]=[];
  detalle_venta:any;

  devolucion_venta_modal:NgbModalRef;
  @ViewChild('devolucion_venta_modal')
  private devoluciones_venta_ref: TemplateRef<any>;

  registro_devolucion_modal:NgbModalRef;
  @ViewChild('registro_devolucion_modal')
  private registro_devolucion_ref: TemplateRef<any>;

  devolucion_combo_producto:any;
  razon_busqueda:any;
  search:any=null;
  searching:any;
  searchFailed:any;
  tipos_despacho:any[]=[];

  combo_producto_devolucion_modal:NgbModalRef;
  @ViewChild('combo_producto_devolucion')
  private combo_producto_devolucion_ref: TemplateRef<any>;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public ventasService:VentasService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public pdfService:PdfService,
    public combosService:CombosService,
    public usuarioService:UsuarioService,
    public clientesService:ClientesService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    let fecha_actual=new Date();
    let fecha_inicio=this.venta?new Date(this.venta.fecha):fecha_actual;
    this.filter={
      id_empresa:this.usuario.id_empresa,
      fecha_inicio:{
        year: fecha_inicio.getFullYear(), 
        month: (fecha_inicio.getMonth()+1),
        day:fecha_inicio.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      id_venta:this.venta?this.venta.id:0
    }
    this.getItems();
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.ventasService.obtenerListaDevolucionesVenta(this).subscribe((lista_pagos_ventas:any) => {
			this.setPages(1);
			this.lista_devoluciones_ventas=lista_pagos_ventas;
      if(this.venta){
        this.venta=this.lista_devoluciones_ventas[0]?this.lista_devoluciones_ventas[0].venta:this.venta;
      }
			this.blockUI.stop();
		});
  }

  sumarImporte(){
		var suma=0;
		for(var i=0;i<this.lista_devoluciones_ventas.length;i++){
			suma=suma+this.lista_devoluciones_ventas[i].total;
		}
		return Math.round(suma*100)/100;
	}

  cerrarModal(){
    this.alTerminar.emit();
  }

  async abrirModalDevolucionVentaVista(devolucion_venta){    
    await this.obtenerDevolucionVenta(devolucion_venta);
		this.devolucion_venta_modal=this.modalService.open(this.devoluciones_venta_ref, {windowClass:"ventas-borrador",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.devolucion_venta_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async obtenerDevolucionVenta(devolucion_venta){
    this.blockUI.start();
    let devolucion_venta_consultado:any=await this.ventasService.obtenerDevolucionVenta(devolucion_venta.id).toPromise();
    this.blockUI.stop();
    this.devolucion_venta=devolucion_venta_consultado;
  }

  async crearNuevaDevolucion(){
		this.blockUI.start();
    if(this.venta){
      this.venta=await this.ventasService.obtenerVenta(this.venta.id).toPromise();
      for(let i=0;i<this.lista_devoluciones_ventas.length;i++){
        for(let j=0;j<this.lista_devoluciones_ventas[i].detalles_devolucion.length;j++){
          let detalle_venta;
          if(this.lista_devoluciones_ventas[i].detalles_devolucion[j].id_producto){
            detalle_venta=this.venta.detallesVenta.filter(d => d.id_producto==this.lista_devoluciones_ventas[i].detalles_devolucion[j].id_producto);
          }else if(this.lista_devoluciones_ventas[i].detalles_devolucion[j].id_combo){
            detalle_venta=this.venta.detallesVenta.filter(d => d.id_combo==this.lista_devoluciones_ventas[i].detalles_devolucion[j].id_combo);
          }
          if(detalle_venta.length>0){
            if(detalle_venta[0].devoluciones==undefined){
              detalle_venta[0].devoluciones=0;
            }
            detalle_venta[0].devoluciones=detalle_venta[0].devoluciones+this.lista_devoluciones_ventas[i].detalles_devolucion[j].cantidad;
          }else{//producto que se devolvio unitariamente de un combo
            for(let k=0;k<this.venta.detallesVenta.length;k++){
              if(this.venta.detallesVenta[k].combo){
                detalle_venta=this.venta.detallesVenta[k];
                let detalle_combo=this.venta.detallesVenta[k].combo.detallesCombo.filter(d => d.id_producto==this.lista_devoluciones_ventas[i].detalles_devolucion[j].id_producto);
                if(detalle_combo.length>0){
                  if(detalle_venta.devoluciones==undefined){
                    detalle_venta.devoluciones=0;
                  }
                  detalle_venta.devoluciones=Math.max(detalle_venta.devoluciones,Math.ceil(this.lista_devoluciones_ventas[i].detalles_devolucion[j].cantidad/this.venta.detallesVenta[k].cantidad));
                }
              }
            }
          }
        }
      }
    }else{
      this.obtenerTiposDespachos();
      this.devolucion_ventas_global={
        sucursal:this.sucursales[0],
        devoluciones:[],
        tipoDespacho:GlobalVariable.Dictionary.SELECCION_TODOS,
        contacto_cliente:{id:0,persona:{nombre_completo:"TODOS"}},
      }
    }

    this.devolucion_venta={
      id_venta:this.venta?this.venta.id:null,
      id_almacen:this.venta?this.venta.id_almacen:null,
      id_sucursal:this.venta?this.venta.id_sucursal:null,
      id_usuario:this.usuario.id,
      usuario:this.usuario,
      fecha:new Date(),
      devolucion_venta_detalles:[]
    }
    this.blockUI.stop();
    this.registro_devolucion_modal=this.modalService.open(this.registro_devolucion_ref, {windowClass:"registro-devolucion",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.registro_devolucion_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
	}

  buscarDatosDevolucionGlobal(){
    this.blockUI.start();
    this.ventasService.obtenerDatosDevolucionGlobal(this.devolucion_ventas_global.cliente.id,this.devolucion_ventas_global.contacto_cliente.id,this.devolucion_ventas_global.tipoDespacho.id).subscribe((res:any)=>{
      this.devolucion_ventas_global.detalles_venta=res.productos.concat(res.combos);
      this.blockUI.stop();
    });
  }

  agregarDetalleDevolucion(detalleVenta){
    let devolucion_venta_detalle={
     producto:detalleVenta.producto,
     codigo_producto:detalleVenta.codigo_producto,
     combo:detalleVenta.combo,
     codigo_combo:detalleVenta.codigo_combo,
     cantidad:1,
     cantidad_venta:detalleVenta.cantidad,
     descuento_venta:detalleVenta.descuento,
     devoluciones:detalleVenta.devoluciones,
     precio_unitario_venta:detalleVenta.precio_unitario,
     id_venta:detalleVenta.id_venta,
     id_almacen:detalleVenta.id_almacen,
     id_sucursal:detalleVenta.id_sucursal,
     id_producto:detalleVenta.id_producto,
     id_combo:detalleVenta.id_combo,
     activar_inventario:detalleVenta.activar_inventario
   }
   let detalle_devolucion=this.devolucion_venta.devolucion_venta_detalles.filter(dv => ((dv.producto && devolucion_venta_detalle.producto && dv.producto.id==devolucion_venta_detalle.producto.id) || (dv.combo && devolucion_venta_detalle.combo && dv.combo.id==devolucion_venta_detalle.combo.id)));
   if(detalle_devolucion.length==0){
    this.devolucion_venta.devolucion_venta_detalles.push(devolucion_venta_detalle);
   }
 }

  eliminarDetalleDevolucion(devolucion_venta_detalle){
    this.devolucion_venta.devolucion_venta_detalles.splice(this.devolucion_venta.devolucion_venta_detalles.indexOf(devolucion_venta_detalle),1);
  }

  validarDevolucionVenta(){
    let res=true;
    if(this.devolucion_venta.devolucion_venta_detalles.length==0){
      res=res && false;
      this.toastr.error("Debe existir al menos un item en la devolución!");
    }else{
      for(let i=0;i<this.devolucion_venta.devolucion_venta_detalles.length;i++){
        if((this.devolucion_venta.devolucion_venta_detalles[i].cantidad+this.devolucion_venta.devolucion_venta_detalles[i].devoluciones)>this.devolucion_venta.devolucion_venta_detalles[i].cantidad_venta){
          res=res && false;
          this.toastr.error("La cantidad de devolucion del "+(this.devolucion_venta.devolucion_venta_detalles[i].producto?"producto "+(this.devolucion_venta.devolucion_venta_detalles[i].producto.nombre?this.devolucion_venta.devolucion_venta_detalles[i].producto.nombre:this.devolucion_venta.devolucion_venta_detalles[i].producto):"combo "+(this.devolucion_venta.devolucion_venta_detalles[i].combo.nombre?this.devolucion_venta.devolucion_venta_detalles[i].combo.nombre:this.devolucion_venta.devolucion_venta_detalles[i].combo))+" no debe exceder el valor de: "+(this.devolucion_venta.devolucion_venta_detalles[i].cantidad_venta-this.devolucion_venta.devolucion_venta_detalles[i].devoluciones));   
        }
      }
    }
    return res;
  }

  async guardarDevolucionVenta(){
    if(this.validarDevolucionVenta()){
      this.blockUI.start();
      if(this.venta){
        this.devolucion_venta.total=0;
        for(var i=0;i<this.devolucion_venta.devolucion_venta_detalles.length;i++){
          var item_precio=this.devolucion_venta.devolucion_venta_detalles[i].precio_unitario_venta;
          this.devolucion_venta.total=this.devolucion_venta.total+(item_precio*this.devolucion_venta.devolucion_venta_detalles[i].cantidad);
        }
        this.ventasService.guardarDevolucionVenta(this.devolucion_venta).subscribe((res:any) => {
          if(!res.tiene_error){
            this.imprimirNotaDevolucion(res.devolucion_venta);
            this.registro_devolucion_modal.close();
            this.getItems();
            //this.devoluciones_venta_modal.close();
            //this.verDevolucionesVenta(this.venta);
            this.toastr.success(res.mensaje);
          }else{
            this.toastr.error(res.mensaje);
          }
          this.blockUI.stop();
        });
      }else{
        let tiene_error=false;
        for(let j=0;j<this.devolucion_venta.devolucion_venta_detalles.length;j++){
          let devolucion_venta={
            id_venta:this.devolucion_venta.devolucion_venta_detalles[j].id_venta,
            id_almacen:this.devolucion_venta.devolucion_venta_detalles[j].id_almacen,
            id_sucursal:this.devolucion_venta.devolucion_venta_detalles[j].id_sucursal,
            id_usuario:this.usuario.id,
            fecha:new Date(),
            devolucion_venta_detalles:[],
            total:0
          }
          devolucion_venta.devolucion_venta_detalles.push(this.devolucion_venta.devolucion_venta_detalles[j]);
          for(let i=0;i<devolucion_venta.devolucion_venta_detalles.length;i++){
            devolucion_venta.devolucion_venta_detalles[i].producto={
              id:devolucion_venta.devolucion_venta_detalles[i].id_producto
            }
            if(devolucion_venta.devolucion_venta_detalles[i].id_combo){
              devolucion_venta.devolucion_venta_detalles[i].combo=await this.combosService.obtenerCombo({id:devolucion_venta.devolucion_venta_detalles[i].id_combo}).toPromise();
            }
            var item_precio=devolucion_venta.devolucion_venta_detalles[i].precio_unitario_venta;
            devolucion_venta.total=devolucion_venta.total+(item_precio*devolucion_venta.devolucion_venta_detalles[i].cantidad);
          }
          let res:any=await this.ventasService.guardarDevolucionVenta(devolucion_venta).toPromise();
          if(!res.tiene_error){
            this.imprimirNotaDevolucion(res.devolucion_venta);
            this.toastr.success(res.mensaje);
          }else{
            tiene_error=true;
            this.toastr.error(res.mensaje);
          }
        }
        this.blockUI.stop();
        if(!tiene_error){
          this.getItems();
          this.registro_devolucion_modal.close();
        }
      }
    }
  }

  agregarDetalleDevolucionProducto(detalle_venta){
    this.blockUI.start();
    this.detalle_venta=detalle_venta;
    this.combosService.obtenerCombo({id:(detalle_venta.id_combo)}).subscribe((combo:any)=>{
      this.blockUI.stop();
      this.devolucion_combo_producto=combo;
      this.combo_producto_devolucion_modal=this.modalService.open(this.combo_producto_devolucion_ref, {ariaLabelledBy: 'modal-basic-title', size:'md', backdrop: 'static'});
      this.combo_producto_devolucion_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  agregarDetalleDevolucionComboProducto(detalle_combo,producto){
    let devoluciones=0;
    for(let i=0;i<this.lista_devoluciones_ventas.length;i++){
      let detalles_devolucion=this.lista_devoluciones_ventas[i].detalles_devolucion.filter(dd => dd.id_producto==producto.id);
      if(detalles_devolucion.length>0){
        devoluciones=devoluciones+detalles_devolucion[0].cantidad
      }
    }
    let devolucion_venta_detalle={
      producto:producto,
      codigo_producto:producto.codigo,
      combo:null,
      cantidad:1,
      cantidad_venta:this.detalle_venta.cantidad*detalle_combo.cantidad,
      descuento_venta:this.detalle_venta.descuento,
      devoluciones:devoluciones,//(this.detalle_venta.devoluciones==undefined?0:this.detalle_venta.devoluciones)*detalle_combo.cantidad,
      precio_unitario_venta:producto.precio_unitario,
      id_venta:this.detalle_venta.id_venta,
      id_almacen:this.detalle_venta.id_almacen,
      id_sucursal:this.detalle_venta.id_sucursal,
      id_producto:producto.id,
      activar_inventario:producto.activar_inventario
    }
    let detalle_devolucion=this.devolucion_venta.devolucion_venta_detalles.filter(dv => ((dv.producto && devolucion_venta_detalle.producto && dv.producto.id==devolucion_venta_detalle.producto.id) || (dv.combo && devolucion_venta_detalle.combo && dv.combo.id==devolucion_venta_detalle.combo.id)));
    if(detalle_devolucion.length==0){
      this.devolucion_venta.devolucion_venta_detalles.push(devolucion_venta_detalle);
    }console.log(this.devolucion_venta.devolucion_venta_detalles);
    this.combo_producto_devolucion_modal.close();
  }

  async cambiarSucursal(sucursal){
    this.reiniciarCliente();
  }

  reiniciarCliente(){
    this.devolucion_ventas_global.cliente={};
    this.razon_busqueda=null;
  }

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClientePedido(this.usuario.id_empresa,(this.usuario.empresa.usar_clientes_sucursal?this.devolucion_ventas_global.sucursal.id:0),0,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

  establecerClientePedido(event){
    this.devolucion_ventas_global.cliente=event.item?event.item:event;
    this.razon_busqueda=this.devolucion_ventas_global.cliente;
    this.devolucion_ventas_global.cliente.contactos.unshift({id:0,persona:{nombre_completo:"TODOS"}});
    this.buscarDatosDevolucionGlobal();
  }

  async obtenerTiposDespachos(){
    this.tipos_despacho=[GlobalVariable.Dictionary.SELECCION_TODOS];
    if(this.usuario.empresa.usar_pedidos_en_linea){
      let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"REST_DESP").toPromise();
      this.tipos_despacho=this.tipos_despacho.concat(entidad.clases?entidad.clases:[]);
    }else if(this.usuario.empresa.usar_clientes_contactos){
      let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
      this.tipos_despacho=this.tipos_despacho.concat(entidad.clases);
    }else{
      let entidad:any=await this.generalService.obtenerClases("REST_DESP").toPromise();
      this.tipos_despacho=this.tipos_despacho.concat(entidad.clases);
    }
  }

  async imprimirNotaDevolucion(devolucion_venta){
    this.blockUI.start();
    let devolucion_venta_consultado:any=await this.ventasService.obtenerDevolucionVenta(devolucion_venta.id).toPromise();
    this.pdfService.imprimirNotaDevolucionCartaOficio(devolucion_venta_consultado,{tamanoPapelNotaVenta:{nombre_corto:GlobalVariable.Dictionary.PAPEL_CARTA}},this.usuario);
    this.blockUI.stop();
  }

}
