import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';

@Component({
  selector: 'app-tutores',
  templateUrl: './tutores.component.html',
  styleUrls: ['./tutores.component.css']
})
export class TutoresComponent extends BaseComponent implements OnInit {

  personas_tutores:any[]=[];
  escuela:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private toastr: ToastrService,
    private estudiantesService:EstudiantesService,
    public usuarioService:UsuarioService,
    public empresasService:EmpresaService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.obtenerEscuela
    this.filter={
      escuela:this.escuela
    }
    this.column = "apellido_paterno";
		this.getSearch(this.text_search,null);
  }

  getItems(){
		this.blockUI.start();
		this.estudiantesService.obtenerListaTutoresPaginador(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.personas_tutores=dato.personas;
			this.blockUI.stop();
		});
	}

  seleccionarTutor(persona_seleccionada){
    this.alTerminar.emit(persona_seleccionada);
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  abrirPopupConfirmacionEliminacionTutor(persona){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al tutor?";
		this.popupConfirmacion.componentInstance.data = persona;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarTutor($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

	eliminarTutor(persona){
		this.blockUI.start();
		this.estudiantesService.eliminarTutor(persona.id).subscribe((res:any) => {
			this.blockUI.stop();
			this.getItems()
			this.toastr.success(res.mensaje);
		});
	}

}
