import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-vista-movimiento-inventario',
  templateUrl: './vista-movimiento-inventario.component.html',
  styleUrls: ['./vista-movimiento-inventario.component.css']
})
export class VistaMovimientoInventarioComponent extends BaseComponent implements OnInit {

  @Input() movimiento_inventario:any;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
  }

}
