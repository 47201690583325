<div class="d-none content-nav mb-1 bgc-grey-l4">
    <div class="d-flex justify-content-between align-items-center">
      <ol class="breadcrumb pl-2">
        <li class="breadcrumb-item active text-grey">
          <i class="fa fa-home text-dark-m3 mr-1"></i>
          <a class="text-blue" href="#">
            Home
          </a>
        </li>

        <li class="breadcrumb-item active text-grey-d1">Inicio</li>
      </ol>

      <div class="nav-search">
        <form class="form-search">
          <span class="d-inline-flex align-items-center">
                   <input type="text" placeholder="Search ..." class="form-control pr-4 form-control-sm radius-1 brc-info-m2 text-grey" autocomplete="off" />
                   <i class="fa fa-search text-info-m1 ml-n4"></i>
               </span>
        </form>
      </div><!-- /.nav-search -->
    </div>
  </div><!-- breadcrumbs -->

  <div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Inicio
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          resumen
        </small>
      </h1>

      <!--<div class="page-tools">
        <button type="button" class="btn btn-sm btn-lighter-default btn-h-lighter-purple btn-a-lighter-purple border-b-2">
          <i class="fa fa-save text-120 text-purple-m1"></i>
        </button>
        <button type="button" class="btn btn-sm btn-lighter-default btn-h-lighter-success btn-a-lighter-success border-b-2">
          <i class="fa fa-undo text-110 text-success-m1"></i>
        </button>

        <div class="btn-group dropdown dd-backdrop dd-backdrop-none-md">
          <button type="button" class="btn btn-sm btn-lighter-info btn-a-outline-info dropdown-toggle border-b-2" data-display="static" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-search text-110"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right dropdown-caret dropdown-animated animated-2 dd-slide-up dd-slide-none-md">
            <div class="dropdown-inner">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Separated link</a>
            </div>
          </div>
        </div>
      </div>-->
    </div>

    <!-- the welcmome to Ace message -->
    <div class="d-none fade show alert alert-lg bgc-success-l2 border-0 border-l-4 brc-success-m1 m-2 mx-lg-4 py-3 text-105 text-dark-tp3">
      <button type="button" class="close" data-dismiss="alert">
        <i class="text-70 fa fa-times align-top text-grey"></i>
      </button>

      <i class="fas fa-check mr-1 text-120 text-success-m1"></i>

      Bienvenido al
      <span class="text-success-d2 font-bolder text-105">
Sistema Snapquick
<small>v2</small>
</span>
      , una excitante experiencia para poder administrar tu negocio.
    </div>


    <div class="h-1 my-1"></div>



    <div class="row" *ngIf="usuario.id_empresa && aplicacion_empresa.length>0 && aplicacion_empresa[0].puede_ver">
      <div class="col-12">
        <div class="row px-3 px-lg-4">


          <div class="col-12 mb1-25">
            <div class="row  h-100 l5 border-2 border-b-0 bgc-white brc-default-l2 radius-t-1 d-flex py-35">
              <div class="col-12 col-sm-4 px-lg-4 text-center text-sm-left">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-success-d1 py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fas fa-dollar-sign text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>
                <span class="text-95">Ventas del dia</span>
                <div class="text-secondary-d3">
                  <span class="text-110">Bs </span> <span class="text-180">{{notificacion.contador_total_ventas.toFixed(2)}}</span>
                  <!--<span class="text-blue">+5.25% <i class="fa fa-caret-up"></i></span>-->
                </div>

                <button type="button" class="btn btn-blue btn-bold px-4 py-1 mt-45">Ver Reporte</button>
              </div>

              <!--<div class="col-12 col-sm-4 px-4 pos-rel mt-3 mt-sm-0 pt-3 pt-sm-0 text-center">

                <div class="d-none d-sm-block position-lc h-90 border-l-1 brc-default-l1"></div>
                <div class="d-sm-none position-tc w-90 border-t-1 brc-default-l1"></div>

                <div class="d-flex flex-column">
                  <div class="d-flex text-center">
                    <div class="flex-grow-1 mb-3">
                      <div class="text-nowrap text-95">Page views</div>




                      <div>
                        <span class="text-170 text-secondary-d2">729,351</span>
                        <span class="text-blue text-nowrap">+7.2% <i class="fa fa-caret-up"></i></span>
                      </div>
                    </div>
                  </div>

                  <div class="align-self-center w-95 text-warning-d2">
                    <canvas class="info-chart ml-n15 mt-n2" style="height: 64px; width: 100%;" data-values="[1000,800,1800,400,1500,1000,1050,1300,2100,1400,1500,1350]"></canvas>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 px-4 pos-rel mt-3 mt-sm-0 pt-3 pt-sm-0 text-center">

                <div class="d-none d-sm-block position-lc h-90 border-l-1 brc-default-l1"></div>
                <div class="d-sm-none position-tc w-90 border-t-1 brc-default-l1"></div>

                <div class="d-flex flex-column">
                  <div class="d-flex text-center">
                    <div class="flex-grow-1 mb-3">
                      <div class="text-nowrap text-95">Task progress</div>



                      <div class="text-nowrap text-120 text-secondary-d2">~ 42% remaining</div>


                    </div>
                  </div>

                  <div class="align-self-center pos-rel text-blue">
                    <canvas class="info-pie" style="width: 150px;" data-percent="58"></canvas>
                    <span class="position-center text-95 font-bolder">58%</span>
                  </div>

                </div>
              </div>-->
            </div>
          </div>


          <div *ngIf="usuario.id_empresa" class="row w-100 border-2 border-t-1 brc-default-l2 bgc-white mx-0 radius-b-1 overflow-hidden">
            <div class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-danger py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fa fa-envelope text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_mensajes}}</div>


                  <div class="text-nowrap text-grey-d2">Mensajes</div>
                </div>

                <div class="text-success-m1 pr-1 font-bolder">
                  8%
                  <i class="fas fa-level-up-alt"></i>
                </div>


              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_vencimientos" class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-blue-d1 py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fab fa-twitter text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_vencimiento_productos_notificacion}}</div>


                  <div class="text-nowrap text-grey-d2">Vencimiento productos</div>
                </div>


                <div class="pr-1">
                  <span class="badge badge-success radius-1">
 32% <i class="ml-1 fa fa-arrow-up"></i>
</span>
                </div>

              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_vencimiento_economico_ventas" class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-success-d1 py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fas fa-money-bill-alt text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_ventas_credito_notificacion}}</div>


                  <div class="text-nowrap text-grey-d2">Vencimiento creditos</div>
                </div>

                <div class="text-danger-m2 pr-1 font-bolder">
                  4%
                  <i class="fas fa-long-arrow-alt-down text-120"></i>
                </div>


              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_vencimiento_economico_compras" class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-success-d1 py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fas fa-money-bill-alt text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_compras_credito_notificacion}}</div>


                  <div class="text-nowrap text-grey-d2">Vencimiento deudas</div>
                </div>

                <div class="text-danger-m2 pr-1 font-bolder">
                  4%
                  <i class="fas fa-long-arrow-alt-down text-120"></i>
                </div>


              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea" class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-purple-tp1 py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="fa fa-shopping-cart text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_pedidos_notificacion}}</div>
                  <div class="text-nowrap text-grey-d2">Nuevos pedidos</div>
                </div>
              </div>
            </div>
            <div *ngIf="usuario.empresa.usar_inventario_minimo" class="col-12 col-sm-6 px-0">
              <div class="py-25 px-2 d-flex bgc-white border-1 brc-default-l2 m-n2px">
                <div class="pl-1 align-self-center align-self-md-start">
                  <i class="bgc-primary py-25 px-3 radius-round icon-glossy brc-black-tp10 border-1">
                    <i class="far fa-clock text-white w-3 mx-n3px mb-1 text-140"></i>
                  </i>
                </div>

                <div class="flex-grow-1 pl-25">
                  <div class="text-grey-d2 text-160">{{notificacion.contador_productos_inventario_minimo}}</div>


                  <div class="text-nowrap text-grey-d2">Inventario Mínimo</div>
                </div>



              </div>
            </div>
          </div>


        </div>
      </div>
      <hr class="brc-white my-3 my-lg-4" />
      <div class="col-12 pt-4">
        <div class="card border-0 h-100">
          <div class="card-header border-0 bgc-primary-d1 card-header-sm">
            <div class="col-2 p-0">
              <h6 class="card-title pt-0">
                <i class="mr-1 fa fa-signal text-white-tp2"></i>
                <span class="text-110 text-white">Estado del negocio</span>
              </h6>
              <div class="dropdown dd-backdrop dd-backdrop-none-md">
                <a class="btn btn-white border-0 btn-bold radius-1 btn-xs dropdown-toggle text-80" href="#" role="button" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                  Periodo
                  <i class="fa fa-caret-down"></i>
                </a>

                <div class="dropdown-menu shadow-sm dropdown-menu-right dropdown-caret dropdown-animated dd-slide-up dd-slide-none-md radius-1 brc-secondary-l1">
                  <div class="dropdown-inner">
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='hoy'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('hoy')">Hoy</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='ayer'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('ayer')">Ayer</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='esta_semana'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('esta_semana')">Esta semana</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='anterior_semana'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('anterior_semana')">La anterior semana</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='este_mes'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('este_mes')">Este mes</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='anterior_mes'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('anterior_mes')">El anterior mes</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='esta_gestion'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('esta_gestion')">Este año</a>
                    <a [ngClass]="{'active':filtro_estado.periodo_estado_negocio=='pasada_gestion'}" class="dropdown-item btn btn-a-lighter-blue text-secondary mx-1" href="javascript:void(0)" (click)="cambiarPeriodo('pasada_gestion')">El año pasado</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="card-toolbar no-border">
                
                <div class="col-6 p-0">
                    <div class="input-group">
                        <label for="feini" class="text-white">Desde </label>&nbsp;
                        <input required id="feini" name="feini" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filtro_estado.fecha_inicio" ngbDatepicker #fi="ngbDatepicker" (ngModelChange)="establecerPeriodoFechas($event)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                  </div>
                  <div class="col-6 p-0">
                    <div class="input-group date datepicker">
                        <label for="fefin" class="text-white">Hasta </label>&nbsp;
                        <input required id="fefin" name="fefin" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filtro_estado.fecha_fin" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="establecerPeriodoFechas($event)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                </div>
              </div>
            </div>
            <div class="col-2">
              <select [compareWith]="compararObjectos" class="form-control" required name="costos" [(ngModel)]="filtro_estado.tipo_costo">
                <option [ngValue]="tipo_costo" *ngFor="let tipo_costo of filtro_estado.tipos_costo">{{tipo_costo.nombre}}</option>
              </select>
            </div>
            <div class="col-2">
              <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filtro_estado.usuario">
                <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filtro_estado.usuarios">{{usuario_filtro.nombre_usuario}}</option>
              </select>
            </div>
            <div class="col-2">
              <button type="button" class="btn btn-sm btn-lighter-info btn-a-outline-info dropdown-toggle border-b-2" (click)="generarEstadoNegocio()">
                <i class="fa fa-search text-110"></i>
              </button>&nbsp;
              <button type="button" class="btn btn-sm btn-lighter-danger btn-a-outline-danger dropdown-toggle border-b-2" (click)="generarPdfEstadoNegocio()">
                <i class="fa fa-file-pdf text-110"></i>
              </button>
            </div>
          </div>

          <div class="card-body px-0 bgc-white border-2 border-t-0 brc-primary-m4 pb-0 flex-grow-1 d-flex flex-column">
            <div class="flex-grow-1 d-flex align-items-center px-2">
              <canvas class="mw-100" id="piechart" height="110"></canvas>
            </div>
            <div>
              <hr class="brc-default-l1 w-95 mt-3 mb-0" />
            </div>
            <div class="row mx-0 pb-2 pt-1">
              <div class="col-12 col-sm-4 d-inline-flex">
                <div class="h-100 d-flex align-items-center px-2">
                  <i class="fab fa-facebook-square fa-2x text-blue"></i>
                </div>
                <div class="pl-1 pt-2">
                  <span class="text-150 text-grey-d2">626</span>
                  <br />
                  <span class="text-grey-m1">likes en Facebook</span>
                </div>

                <div class="position-rc h-75 border-l-1 brc-default-l1 d-none d-sm-block"></div>
                <div class="position-bc w-90 border-t-1 brc-default-l2 d-sm-none mb-n1"></div>
              </div>

              <div class="col-12 col-sm-4 d-inline-flex">
                <div class="h-100 d-flex align-items-center px-2">
                  <i class="fab fa-twitter-square fa-2x text-blue2-m3"></i>
                </div>
                <div class="pl-1 pt-2">
                  <span class="text-150 text-grey-d2">260</span>
                  <br />
                  <span class="text-grey-m1">retweets</span>
                </div>

                <div class="position-rc h-75 border-l-1 brc-default-l1 d-none d-sm-block"></div>
                <div class="position-bc w-90 border-t-1 brc-default-l2 d-sm-none mb-n1"></div>
              </div>

              <div class="col-12 col-sm-4 d-inline-flex">
                <div class="h-100 d-flex align-items-center px-2">
                  <i class="fab fa-instagram fa-2x text-purple-m2"></i>
                </div>
                <div class="pl-1 pt-2">
                  <span class="text-150 text-grey-d2">401</span>
                  <br />
                  <span class="text-grey-m1">likes en Instagram</span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <hr class="brc-white my-3 my-lg-4" />
      <div class="col-12 pt-4">
        <div class="card border-0 h-100">
          <div class="card-header border-0 bgc-primary-d1 card-header-sm">
            <div class="col-12 p-0">
              <h6 class="card-title pt-0">
                <i class="mr-1 fa fa-folder text-white-tp2"></i>
                <span class="text-110 text-white">Datos de carpetas y archivos </span>
              </h6>
            </div>
          </div>

          <div class="card-body px-0 bgc-white border-2 border-t-0 brc-primary-m4 pb-0 flex-grow-1 d-flex flex-column">
            <div class="flex-grow-1 d-flex align-items-center px-2">
              <div class="col-6 col-sm-6 col-lg-6 px-2 mb-2 mb-lg-0">
                <div class="jqtree tree-dotted" id="id-carpetas"></div>
              </div>
              <div class="col-6 col-sm-6 col-lg-6 px-2 mb-2 mb-lg-0">
                <div class="bcard h-100 d-flex align-items-center p-3">


                  <div class="text-center pos-rel ml-1">
                    <canvas class="infobox-piechart" style="height: 75px; width: 75px; max-height: 75px; max-width: 75px;"></canvas>
                    <span class="position-center text-dark-tp3 text-600">
                      {{datos_archivos.porcentaje_ocupado}} %
             </span>
                  </div>
        
        
                  <div class="ml-3 flex-grow-1">
        
                    <div>
                      <span class="text-dark-tp3 text-125">
                     Almacenamiento
                 </span>
                    </div>
        
                    <div class="text-dark-tp4 text-110">
                      {{datos_archivos.espacio_ocupado}} Mb de {{datos_archivos.espacio_asignado}} Mb
                    </div>
                  </div>
        
        
        
        
        
                  
        
                </div><!-- /.bcard -->  
              </div>
              
              
            </div>
            <div>
              <hr class="brc-default-l1 w-95 mt-3 mb-0" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<iframe [src]="url_game" width="600" height="400"></iframe>-->

    <hr class="brc-white my-15 d-none d-lg-block" />
    <hr class="brc-white my-3 my-lg-4" />

    <div class="row d-none">
      <div class="col-lg-5 order-last order-lg-first">
        <div class="card border-0">
          <div class="card-header bg-transparent border-0 pl-1">
            <h5 class="card-title mb-2 mb-md-0 text-120">
              <i class="fa fa-star mr-1 text-warning text-90"></i>
              <span class="text-105">Popular Domains</span>
            </h5>

            <div class="card-toolbar align-self-center">
              <a href="#" data-action="toggle" class="card-toolbar-btn text-grey text-110"><i class="fa fa-chevron-up"></i></a>
            </div>
          </div>
          <div class="card-body p-0 border-t-2 brc-default-l2">
            <table class="table brc-secondary-l4">
              <thead class="border-0">
                <tr class="border-0 bg-transparent text-dark-tp4 te1xt-95">
                  <th class="border-0 font-normal">name</th>
                  <th class="border-0 font-normal">price</th>
                  <th class="border-0 font-normal">status</th>
                </tr>
              </thead>

              <tbody>
                <tr class="bgc-h-primary-l5">
                  <td class="text-dark-m1">internet.com</td>
                  <td>
                    <small><s class="text-danger-m1">$29.99</s></small>
                    <span class="text-success-m1 font-bolder">$19.99</span>
                  </td>
                  <td>
                    <span class="badge text-75 badge-info arrowed-in arrowed-in-right">on sale</span>
                  </td>
                </tr>
                <tr class="bgc-h-primary-l5">
                  <td class="text-dark-m1">online.com</td>
                  <td>
                    <span class="text-blue-m1 font-bolder">$16.45</span>
                  </td>
                  <td>
                    <span class="badge text-75 border-l-3 brc-black-tp8 bgc-success text-white">approved</span>
                  </td>
                </tr>
                <tr class="bgc-h-primary-l5">
                  <td class="text-dark-m1">newnet.com</td>
                  <td>
                    <span class="text-blue-m1 font-bolder">$15.00</span>
                  </td>
                  <td>
                    <span class="badge text-75 border-l-3 brc-black-tp8 bgc-danger text-white">pending</span>
                  </td>
                </tr>
                <tr class="bgc-h-primary-l5">
                  <td class="text-dark-m1">web.com</td>
                  <td>
                    <small><s class="text-danger-m1">$24.99</s></small>
                    <span class="text-success-m1 font-bolder">$19.95</span>
                  </td>
                  <td>
                    <span class="badge bgc-secondary-l1 text-dark-tp4 border-1 brc-black-tp10"><s>out of stock</s></span>
                  </td>
                </tr>
                <tr class="bgc-h-primary-l5">
                  <td class="text-dark-m1">domain.com</td>
                  <td>
                    <span class="text-blue-m1 font-bolder">$12.00</span>
                  </td>
                  <td>
                    <span class="badge text-75 border-l-3 brc-black-tp8 bgc-warning text-white">SOLD</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-lg-7 mb-4 mb-lg-0">
        <div class="card border-0 h-100">
          <div class="card-header border-0 bg-transparent pl-1">
            <h5 class="card-title mb-2 mb-md-0 text-120">
              <i class="fas fa-chart-line text-purple-m2 mr-1 text-105"></i>
              <span class="text-110">Sale Stats</span>
            </h5>

            <div class="card-toolbar no-border dd-backdrop dd-backdrop-none-md">
              <a href="#" class="btn btn-xs btn-light-secondary border-1 text-600 px-4 radius-round dropdown-toggle" role="button" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                2020
              </a>

              <div class="dropdown-menu shadow-sm dropdown-menu-right dropdown-caret dropdown-animated dd-slide-center dd-slide-none-md radius-1 brc-secondary-l1 px-1 mw-auto">
                <div class="dropdown-inner">
                  <a class="dropdown-item active" href="#">2020</a>
                  <a class="dropdown-item" href="#">2019</a>
                  <a class="dropdown-item" href="#">2018</a>
                </div>
              </div>
            </div>

            <div class="card-toolbar align-self-center">
              <a href="#" data-action="reload" class="card-toolbar-btn text-success-m2 text-100"><i class="fas fa-sync-alt"></i></a>
            </div>
          </div>
          <div class="card-body p-0 bg-white border-t-2 brc-default-l2 px-1 mx-n2 mx-md-0 h-100 d-flex align-items-center">
            <div class="mx-n2 px-0 mx-md-0 my-2 flex-grow-1">
              <canvas id="saleschart" height="115"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="border-dotted my-3 mt-lg-4 mb-lg-4" />

    <div class="row d-none">
      <div class="col-12 col-lg-6">
        <div class="card border-0">
          <div class="card-header bg-transparent border-b-1 brc-primary-m4 px-1 flex-column flex-md-row">
            <h5 class="card-title mb-2 mb-md-0">
              <i class="fa fa-rss text-orange mr-1 text-105"></i>
              <span class="text-105">Recent Activity</span>
            </h5>

            <div class="card-toolbar no-border tabs-above pl-0 pl-md-2">
              <ul class="nav nav-tabs card-header-tabs mr-0 flex-nowrap">
                <li class="nav-item">
                  <a class="nav-link active radius-0" data-toggle="tab" href="#tab-1" role="tab">Tasks</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link radius-0" data-toggle="tab" href="#tab-2" role="tab">Members</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link radius-0" data-toggle="tab" href="#tab-3" role="tab">Comments</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-body bg-white p-0">
            <div class="tab-content border-0 card-inner px-0 py-3">
              <div class="tab-pane show active fade px-1" id="tab-1">
                <h5 class="font-light text-success mb-3">
                  <i class="fa fa-list"></i>
                  Sortable Lists <span class="text-75 text-grey-m2">drag &amp; drop</span>
                </h5>

                <div class="row">
                  <div class="col-12 px-3" id="tasks">

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-purple-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Answering customer questions</span>
                        </label>
                      </div>


                      <div class="py-1 align-self-center position-relative text-purple">
                        <canvas class="task-progress" style="width:48px; height:48px;" data-percent=35></canvas>
                        <span class="position-center text-85 text-600">35%</span>
                      </div>


                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-danger-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Fixing bugs</span>
                        </label>
                      </div>

                      <div class="py-1">
                        <div class="action-buttons">
                          <a class="text-primary-m1" href="#">
                            <i class="fa fa-search-plus"></i>
                          </a>

                          <a class="text-success-m1" href="#">
                            <i class="fa fa-pencil-alt"></i>
                          </a>

                          <a class="text-danger-m1" href="#">
                            <i class="fa fa-trash-alt"></i>
                          </a>
                        </div>
                      </div>



                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-secondary-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Adding new features</span>
                        </label>
                      </div>



                      <div class="py-1">
                        <div class="dropdown">
                          <a class="btn btn-outline-primary border-b-2 btn-xs dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-cog"></i>
                          </a>
                          <div class="dropdown-menu mw-auto dropdown-caret dropdown-menu-right dropdown-animated animated-1">
                            <a class="dropdown-item" href="#" title="Mark as done" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                              <i class="fa fa-check text-success-m1 mx-1"></i>
                              <span class="d-md-none">Mark as done</span>
                            </a>
                            <a class="dropdown-item" href="#" title="Delete task" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                              <i class="fa fa-trash text-danger mx-1"></i>
                              <span class="d-md-none">Delete task</span>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-info-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Upgrading scripts used in template</span>
                        </label>
                      </div>




                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-grey-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Adding new skins</span>
                        </label>
                      </div>




                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-success-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Updating server software up</span>
                        </label>
                      </div>




                    </div>

                    <div class="task-item mt-n1px border-1 bgc-h-success-l3 brc-secondary-l1 p-2 d-flex pos-rel">
                      <div class="h-100 position-tl border-l-4 brc-warning-tp2 ml-n1px"></div>
                      <div class="flex-grow-1 pt-2 pb-1">
                        <label>
                          <input type="checkbox" class="align-middle input-sm mx-2px" autocomplete="off" />
                          <span class="align-middle">Cleaning up</span>
                        </label>
                      </div>




                    </div>
                  </div>
                </div>

                <div class="text-center px-1px pos-rel">
                  <button type="button" class="mt-n1px btn btn-outline-info btn-sm btn-block border-b-2 radius-0">
                    <i class="fa fa-plus mr-1"></i>
                    Add A Task
                  </button>
                </div>
              </div>

              <div class="tab-pane fade px-2" id="tab-2">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <img src="assets/template/assets/image/avatar/user.jpg" class="radius-round border-2 p-1px brc-primary-m2 w-6 h-6" />

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              Bob Doe
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> 20 min
                          </div>

                          <div>
                            <span class="badge btn-light-secondary">pending</span>
                            <div class="dropdown d-inline-block dd-backdrop dd-backdrop-none-md">
                              <button class="btn py-0 shadow-none btn-xs btn-outline-secondary border-0 dropdown-toggle" data-toggle="dropdown">
                                <i class="fa fa-angle-down"></i>
                              </button>
                              <div class="dropdown-menu mw-auto dropdown-caret dropdown-menu-right dropdown-animated dd-slide-up dd-slide-none-md">
                                <div class="dropdown-inner">
                                  <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                                    <i class="fa fa-check text-success-m1 mx-1 w-2"></i>
                                    <span class="d-md-none">Approve</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                                    <i class="fa fa-times text-orange mx-1 w-2"></i>
                                    <span class="d-md-none">Reject</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                                    <i class="fa fa-trash text-danger mx-1 w-2"></i>
                                    <span class="d-md-none">Delete</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">157</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">50</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">49</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <span class="d-inline-flex align-items-center justify-content-center radius-round bgc-purple text-white font-bolder w-6 h-6">
                                   <span>JS</span>
                          </span>

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              John Smith
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> 1 hour
                          </div>

                          <div>
                            <span class="badge btn-light-secondary">pending</span>
                            <div class="dropdown d-inline-block dd-backdrop dd-backdrop-none-md">
                              <button class="btn py-0 shadow-none btn-xs btn-outline-secondary border-0 dropdown-toggle" data-toggle="dropdown">
                                <i class="fa fa-angle-down"></i>
                              </button>
                              <div class="dropdown-menu mw-auto dropdown-caret dropdown-menu-right dropdown-animated dd-slide-up dd-slide-none-md">
                                <div class="dropdown-inner">
                                  <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                                    <i class="fa fa-check text-success-m1 mx-1 w-2"></i>
                                    <span class="d-md-none">Approve</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                                    <i class="fa fa-times text-orange mx-1 w-2"></i>
                                    <span class="d-md-none">Reject</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                                    <i class="fa fa-trash text-danger mx-1 w-2"></i>
                                    <span class="d-md-none">Delete</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">113</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">12</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">6</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <img src="assets/template/assets/image/avatar/avatar.png" class="radius-round border-2 p-1px brc-primary-m2 w-6 h-6" />

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              Jim Doe
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> 2 hour
                          </div>

                          <div>
                            <span class="badge btn-light-secondary">pending</span>
                            <div class="dropdown d-inline-block dd-backdrop dd-backdrop-none-md">
                              <button class="btn py-0 shadow-none btn-xs btn-outline-secondary border-0 dropdown-toggle" data-toggle="dropdown">
                                <i class="fa fa-angle-down"></i>
                              </button>
                              <div class="dropdown-menu mw-auto dropdown-caret dropdown-menu-right dropdown-animated dd-slide-up dd-slide-none-md">
                                <div class="dropdown-inner">
                                  <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                                    <i class="fa fa-check text-success-m1 mx-1 w-2"></i>
                                    <span class="d-md-none">Approve</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                                    <i class="fa fa-times text-orange mx-1 w-2"></i>
                                    <span class="d-md-none">Reject</span>
                                  </a>
                                  <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="top" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                                    <i class="fa fa-trash text-danger mx-1 w-2"></i>
                                    <span class="d-md-none">Delete</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">49</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">35</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">42</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <img src="assets/template/assets/image/avatar/avatar5.png" class="radius-round border-2 p-1px brc-primary-m2 w-6 h-6" />

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              Alex Doe
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> 3 hour
                          </div>

                          <div>
                            <span class="badge badge-danger badge-sm">blocked</span>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">143</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">24</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">26</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <span class="d-inline-flex align-items-center justify-content-center radius-round bgc-success text-white font-bolder w-6 h-6">
                                   <span>BD</span>
                          </span>

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              Bob Doe
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> 6 hour
                          </div>

                          <div>
                            <span class="badge btn-light-success">approved</span>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">186</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">37</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">9</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="d-block my-1 mx-n2 border-1 brc-secondary-l1 radius-1 p-0">
                      <div class="d-flex p-2">
                        <div class="pos-rel mr-3">
                          <img src="assets/template/assets/image/avatar/avatar3.png" class="radius-round border-2 p-1px brc-primary-m2 w-6 h-6" />

                          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr"></span>
                        </div>


                        <div>
                          <div>
                            <a class="mt-0 text-blue-m1 text-90 font-bolder" href="#">
                              Susan
                            </a>
                          </div>

                          <div class="text-success-m2 text-80 font-bolder">
                            <i class="far fa-clock text-grey"></i> yesterday
                          </div>

                          <div>
                            <span class="badge btn-light-success">approved</span>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 mt-2">

                        <div class="row mb-n1px mx-n1px text-secondary">
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 pb-0 text-center">
                              <i class="fab fa-twitter text-blue-m3"></i>
                              <span class="text-95">58</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1 border-x-0">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-instagram text-purple-m1"></i>
                              <span class="text-95">26</span>
                            </div>
                          </div>
                          <div class="col-4 card radius-0 brc-secondary-l1">
                            <div class="card-body px-1 py-2 text-center">
                              <i class="fab fa-facebook text-primary-m2"></i>
                              <span class="text-95">37</span>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <hr class="border-double" />
                <div class="text-center">
                  <button type="button" class="btn btn-outline-info btn-sm btn-wide">
                    See All Members
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </div>

              </div>

              <div class="tab-pane fade px-0" id="tab-3">
                <div ace-scroll='{"height": 280, "smooth":true}'>
                  <div class="media mx-15">
                    <div class="d-flex flex-grow-1">
                      <div class="mr-3">
                        <img src="assets/template/assets/image/avatar/avatar.png" class="radius-round border-2 p-1px brc-primary-m3 w-6 h-6" />
                      </div>

                      <div class="media-body pb-2 border-b-1 brc-default-l2">
                        <div class="d-flex justify-content-between">
                          <span class="text-blue text-95">Bob Doe</span>
                          <span class="text-90">
                                       <i class="far fa-clock text-grey mr-1"></i><span class="text-success-m2 font-bolder">6 min</span>
                          </span>
                        </div>
                        <div class="text-dark-m3 text-90">
                          <i class="fa fa-quote-left text-90 text-secondary-m1 mr-1"></i>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="media mx-15">
                    <div class="d-flex flex-grow-1">
                      <div class="mr-3">
                        <img src="assets/template/assets/image/avatar/avatar1.png" class="radius-round border-2 p-1px brc-primary-m3 w-6 h-6" />
                      </div>

                      <div class="media-body pb-2 border-b-1 brc-default-l2">
                        <div class="d-flex justify-content-between">
                          <span class="text-blue text-95">Jennifer</span>
                          <span class="text-90">
                                       <i class="far fa-clock text-grey mr-1"></i><span class="text-blue-m2 font-bolder">15 min</span>
                          </span>
                        </div>
                        <div class="text-dark-m3 text-90">
                          <i class="fa fa-quote-left text-90 text-secondary-m1 mr-1"></i>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="media mx-15">
                    <div class="d-flex flex-grow-1">
                      <div class="mr-3">
                        <span class="d-inline-flex align-items-center justify-content-center radius-round bgc-purple text-white font-bolder w-6 h-6">
                                   <span>JM</span>
                        </span>
                      </div>

                      <div class="media-body pb-2 border-b-1 brc-default-l2">
                        <div class="d-flex justify-content-between">
                          <span class="text-blue text-95">Joe Smith</span>
                          <span class="text-90">
                                       <i class="far fa-clock text-grey mr-1"></i><span class="text-orange -m2 font-bolder">22 min</span>
                          </span>
                        </div>
                        <div class="text-dark-m3 text-90">
                          <i class="fa fa-quote-left text-90 text-secondary-m1 mr-1"></i>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="media mx-15">
                    <div class="d-flex flex-grow-1">
                      <div class="mr-3">
                        <img src="assets/template/assets/image/avatar/avatar3.png" class="radius-round border-2 p-1px brc-primary-m3 w-6 h-6" />
                      </div>

                      <div class="media-body pb-2 border-b-1 brc-default-l2">
                        <div class="d-flex justify-content-between">
                          <span class="text-blue text-95">Rita</span>
                          <span class="text-90">
                                       <i class="far fa-clock text-grey mr-1"></i><span class="text-danger-m2 font-bolder">50 min</span>
                          </span>
                        </div>
                        <div class="text-dark-m3 text-90">
                          <i class="fa fa-quote-left text-90 text-secondary-m1 mr-1"></i>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <hr class="border-double" />
                <div class="text-center">
                  <button type="button" class="btn btn-outline-info btn-sm btn-wide">
                    See All Comments
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </div>
              </div>

            </div>
          </div><!-- /.card-body -->

        </div>
      </div>



      <div class="col-12 col-lg-6">
        <div class="card border-0 mt-3">

          <div class="card-header bgc-blue-tp1">
            <h6 class="card-title text-white font-normal">
              <i class="far fa-comment-dots text-130 mr-1"></i>
              <span class="text-110">Conversation</span>
              <span class="text-95 d-block d-sm-inline text-center">(6 online)</span>
            </h6>

            <div class="card-toolbar align-self-center text-white text-90 no-border p-2px">
              Alexa is typing
              <div class="typing-dots text-160 text-white mx-md-1">
                <span class="typing-dot">.</span>
                <span class="typing-dot">.</span>
                <span class="typing-dot">.</span>
              </div>
            </div>
            <div class="card-toolbar align-self-center">
              <a href="#" data-action="reload" class="card-toolbar-btn text-white "><i class="fas fa-sync-alt"></i></a>
            </div>
          </div>

          <div class="card-body border-x-1 border-b-1 brc-blue-l1 bgc-default-l4 px-1px py-0" ace-scroll='{"height": 450, "smooth":true}'>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <img src="assets/template/assets/image/avatar/avatar1.png" class="radius-round border-2 p-1px brc-primary-m1 w-6 h-6" />

                    <span class="bgc-success-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">Alexa</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-success-m1 font-bolder">4 sec</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis.
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <img src="assets/template/assets/image/avatar/avatar.png" class="radius-round border-2 p-1px brc-primary-m1 w-6 h-6" />

                    <span class="bgc-success-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">John</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-blue-m1 font-bolder">38 sec</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      <span class='fa-stack w-auto'> <i class='fas fa-circle text-dark fa-stack-1x text-100'></i> <i class='fas fa-smile-beam text-warning-m3 text-125 fa-stack-1x pos-rel'></i> </span> Raw denim you probably haven't heard of them jean shorts Austin.
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <span class="d-inline-flex align-items-center justify-content-center radius-round bgc-warning text-white font-bolder w-6 h-6">
                        <span>BD</span>
                    </span>

                    <span class="bgc-warning-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">Bob Doe</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-orange-m1 font-bolder">2 min</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque commodo massa sed ipsum porttitor facilisis.
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <img src="assets/template/assets/image/avatar/avatar4.png" class="radius-round border-2 p-1px brc-primary-m1 w-6 h-6" />

                    <span class="bgc-info-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">Jim</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-grey-m1 font-bolder">3 min</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      Raw denim you probably haven't heard of them jean shorts Austin.
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <img src="assets/template/assets/image/avatar/avatar1.png" class="radius-round border-2 p-1px brc-primary-m1 w-6 h-6" />

                    <span class="bgc-secondary-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">Alexa</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-success-m1 font-bolder">4 min</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      Lorem ipsum dolor sit amet. <span class='fa-stack w-auto'> <i class='fas fa-circle text-dark fa-stack-1x text-100'></i> <i class='fas fa-grin-alt text-warning-m3 text-125 fa-stack-1x pos-rel'></i> </span>
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div class="px-3">
              <div class="media mt-2 mb-3">
                <div class="d-flex flex-grow-1">
                  <div class="mr-3 align-self-start align-self-sm-center mt-2 mt-sm-0 pos-rel">
                    <span class="d-inline-flex align-items-center justify-content-center radius-round bgc-purple text-white font-bolder w-6 h-6">
                        <span>GG</span>
                    </span>

                    <span class="bgc-grey-tp2 radius-round border-2 brc-white p-1 position-tr"></span>


                    <div class="dropdown d-inline-block  position-br mb-n3">
                      <button class="shadow-none btn btn-xs btn-outline-secondary btn-h-outline-yellow  btn-a-yellow border-0 py-0 px-1 action-btn dropdown-toggle" data-toggle="dropdown">
                        <i class="fas fa-ellipsis-h"></i>
                      </button>
                      <div class="dropdown-menu mw-auto dropdown-menu-right">
                        <a class="dropdown-item" href="#" title="Approve" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-success"></div><div class="tooltip-inner text-110 pb-2 bgc-success"></div></div>'>
                          <i class="fa fa-check text-success mx-1"></i>
                          <span class="d-md-none">Approve</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Reject" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-warning"></div><div class="tooltip-inner text-110 pb-2 bgc-warning"></div></div>'>
                          <i class="fa fa-times text-orange mx-1"></i>
                          <span class="d-md-none">Reject</span>
                        </a>
                        <a class="dropdown-item" href="#" title="Delete" data-toggle="tooltip" data-placement="left" data-template='<div class="tooltip" role="tooltip"><div class="arrow brc-danger"></div><div class="tooltip-inner text-110 pb-2 bgc-danger"></div></div>'>
                          <i class="fa fa-trash text-danger mx-1"></i>
                          <span class="d-md-none">Delete</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="media-body py-2 px-3 radius-r-1 flex-grow-1 bgc-white brc-default-l2 border-1 border-l-0 pos-rel">
                    <div class="position-tl h-102 border-l-2 brc-blue-m3 mt-n1px"></div>
                    <div class="d-flex justify-content-between">
                      <span class="text-info-d2 text-600 text-95">Gary</span>
                      <span class="text-90">
                            <i class="far fa-clock text-grey mr-1"></i><span class="text-purple-m1 font-bolder">5 min</span>
                      </span>
                    </div>
                    <div class="text-dark-m2 text-90">
                      Quisque commodo massa sed ipsum porttitor facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>

          <div class="card-footer p-0 bgc-white border-1 border-t-0 brc-blue-l1 pos-rel" style="box-shadow: 0 -1px 2px 0px rgba(221, 223, 228, 0.45);">
            <div class="p-3">
              <div class="input-group">
                <input type="text" class="form-control form-control-lg radius-l-round brc-blue-m4" placeholder="Type something ..." />
                <div class="input-group-append">
                  <button class="btn btn-blue btn-md radius-r-round px-4" type="button"><i class="fas fa-forward"></i></button>
                </div>

                <div class="btn-group ml-2 dropup">
                  <button type="button" class="btn btn-xs px-1 border-0 btn-lighter-warning btn-bgc-tp dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="fa-stack">
                            <i class="fas fa-circle text-grey-l1 fa-stack-1x text-160"></i>
                            <i class="fas fa-circle text-dark fa-stack-1x text-120"></i>                                    
                            <i class="fas fa-smile text-warning-m3 text-150 fa-stack-1x"></i>
                        </span>
                  </button>

                  <div class="dropdown-menu dropdown-menu-right p-1">
                    <div class="d-flex ">
                      <a class="dropdown-item px-25" href="#">
                        <span class="fa-stack w-auto">
                                    <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                                    <i class="fas fa-smile text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                                </span>
                      </a>

                      <a class="dropdown-item px-25" href="#">
                        <span class="fa-stack w-auto">
                                    <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                                    <i class="fas fa-smile-beam text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                                </span>
                      </a>

                      <a class="dropdown-item px-25" href="#">
                        <span class="fa-stack w-auto">
                                    <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
                                    <i class="fas fa-smile-wink text-warning-m3 text-125 fa-stack-1x pos-rel"></i>
                                </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</div><!-- /.page-content -->

<div *ngIf="url_visor_pdf" class="modal modal-nb ace-aside aside-bottom aside-r aside-fade aside-offset aside-shown-above-nav" id="aside-pdf-visor" tabindex="7" role="dialog" aria-hidden="false">

  <div id="visor_pdf" class="modal-dialog mr-2" style="width: 80%; height:100vh;" role="document">
    <div class="modal-content border-0 bgc-primary mb-4 radius-1 shadow">
      <div class="modal-header bgc-primary">
        <div class="col-md-3 col-3">
          <h4 class="widget-title">{{nombre_pdf}}</h4>
        </div>
        <div class="col-md-9 col-9">
          <button class="btn btn-purple" type="button" (click)="anteriorPagina()">
            <i class="ace-icon fas fa-step-backward red2 align-top bigger-110"></i>
          </button>
          <input style="display:inline;-moz-appearance: textfield;-webkit-appearance: none;width:40px !important;padding: 0px;" type="number" step="0" name="pagina" [(ngModel)]="filter.currentPage" (keyup)="establecerPagina()" min="1" max="{{filter.pages}}" class="form-control"/>
          <button class="btn btn-purple" type="button" (click)="siguientePagina()">
            <i class="ace-icon fas fa-step-forward red2 align-top bigger-110"></i>
          </button>&nbsp;
          <label class="text-white">Línea</label>&nbsp;
          <select style="display:inline;width:50px" class="form-control" name="ancho_linea" [(ngModel)]="filter.ancho_linea">
            <option [ngValue]="ancho_linea" *ngFor="let ancho_linea of [1,3,5,7,9,11,13,15,17]">{{ancho_linea}}</option>
          </select>&nbsp;
          <label class="text-white">Lapicero</label>&nbsp;
          <input type="color" name="favcolor" [value]="filter.color" [(ngModel)]="filter.color" (change)="cambiarColor()">&nbsp;
          <button *ngIf="!escribiendo" class="btn btn-secondary" type="button" (click)="activarEscritura()">
            <i class="ace-icon fas fa-pen-alt red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button *ngIf="escribiendo" class="btn btn-warning" type="button" (click)="desactivarEscritura()">
            <i class="ace-icon fas fa-edit red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="borrarEscritura()">
            <i class="ace-icon fas fa-eraser red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-success" type="button" (click)="aumentarZoom()">
            <i class="ace-icon fa fa-search-plus red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-success" type="button" (click)="disminuirZoom()">
            <i class="ace-icon fa fa-search-minus red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-purple" type="button" *ngIf="!filter.maximizado" (click)="maximizarPdf()">
            <i class="ace-icon fas fa-window-maximize red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-purple" type="button" *ngIf="filter.maximizado" (click)="restaurarPdf()">
            <i class="ace-icon fas fa-minus-square red2 align-top bigger-110"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="cerrarVisorPdf()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
          </button>
        </div>
      </div>
      <div class="modal-body bg-transparent p-0">
        <div *ngIf="!filter.pdf_cargado" class="alert fade show bgc-white text-dark-tp3 border-1 brc-secondary-l2 shadow-sm radius-0 py-3 d-flex align-items-start">
          <div class="position-tl w-102 m-n1px border-t-4 brc-success"></div>
          <div class="bgc-success-l4 px-3 py-3 radius-round mr-4 border-2 brc-success-m2">
            <i class="fa fa-spinner text-140 text-success mx-2px"></i>
          </div>

          <div class="text-dark-tp3">
            <h3 class="text-success-d1 text-130">Cargando el archivo!</h3>
            Si no carga, vuelva a intentar cerrando y volviendo a abrir el archivo pdf.
          </div>          
        </div>
        <pdf-viewer [src]="url_visor_pdf" 
              [render-text]="true"
              style="display: block;"
              [zoom]="pdf_visor_zoom"
              [original-size]="true"
              [fit-to-page]="true"
              [(page)]="filter.currentPage"
              [show-all]="false"
              (after-load-complete)="afterLoadComplete($event)"
        ></pdf-viewer>
      </div>
    </div>

    <!-- the button that toggle modal -->
    <!--<div class="aside-header fade position-br mb-5 d-flex align-items-center">
      <div style="cursor:pointer;" class="animation-appear d-none d-md-block shadow py-2 pl-3 pr-4 radius-round bg-white border-2 brc-info-tp1" data-toggle="modal" data-target="#aside-support">
        <div style="max-width: 220px;" class="text-95 pt-1 text-secondary-d3">
          <img src="assets/image/user.jpg" class="mr-2 float-left radius-round border-1 shadow-sm brc-primary-tp2" alt="User avatar" />
          Hey there, if you want to talk, I'm available
          <span class="fa-stack w-auto mt-n2px ">
    <i class="fas fa-circle text-dark fa-stack-1x text-100"></i>
    <i class="fas fa-smile-wink text-warning-m2 text-125 fa-stack-1x"></i>
 </span>
        </div>
      </div>

      <button type="button" class="shadow ml-2 btn btn-info radius-round mw-auto p-3" data-toggle="modal" data-target="#aside-pdf-visor">
        <i class="fas fa-headset text-150 px-2px"></i>
      </button>
    </div>-->
  </div>

</div>