import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProveedoresService } from '../../services/proveedores/proveedores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-registro-proveedor',
  templateUrl: './registro-proveedor.component.html',
  styleUrls: ['./registro-proveedor.component.css']
})
export class RegistroProveedorComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  proveedor:any;
  usuario:any;
  sucursal:any;
  sucursales:any[];
  global_variable:any=GlobalVariable;
  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public proveedorService:ProveedoresService,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
    if(this.proveedor.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PROVEEDOR);
    }else{
      this.proveedor.id_empresa=this.usuario.id_empresa;
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PROVEEDOR);
      this.proveedor.codigo=codigo_nuevo;
    }
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  establecerRazonSocial(){
    if(!this.proveedor.razon_social){
      this.proveedor.razon_social=this.proveedor.identificacion;
    }
  }

  validarProveedor(){
    let res=true;
    if(!this.proveedor.identificacion){
      res=res && false;
      this.toastr.error("Debe especificar la identificacion del proveedor!");
    }
    if(!this.proveedor.razon_social){
      res=res && false;
      this.toastr.error("Debe especificar la razon social del proveedor!");
    }
    if(!this.proveedor.nit){
      res=res && false;
      this.toastr.error("Debe especificar el nit/ci del proveedor!");
    }
    return res;
  }

  async guardarRegistroProveedor(){
    if(this.validarProveedor()){
      this.blockUI.start();
      if (this.proveedor.id) {
        this.proveedorService.actualizarProveedor(this.proveedor).subscribe((res:any) => {
          this.blockUI.stop();
          res.proveedor=this.proveedor;
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      } else {
        this.proveedor.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PROVEEDOR):this.proveedor.codigo;
        this.proveedorService.guardarProveedor(this.proveedor).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_PROVEEDOR);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

}
