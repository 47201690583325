<div class="modal-header py-2 bgc-danger-tp1 border-0  radius-t-1">
    <h5 class="modal-title text-white-tp1 text-110 pl-2 font-bolder" id="dangerModalLabel">{{title}}</h5>
    <button (click)="doNo()" type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body bgc-white-tp2 p-md-4 pl-md-5">
    <div class="d-flex align-items-top mr-2 mr-md-5">
        <i class="fas fa-exclamation-triangle fa-2x text-warning-d2 float-rigt mr-4 mt-1"></i>
        <div class="text-muted text-105">
            {{message}}
        </div>
    </div>
    <div class="d-flex align-items-top mr-2 mr-md-5" *ngIf="es_venta && (data.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || data.transaccion_nombre_corto==global_variable.Dictionary.EGRE_FACTURACION_MANUAL)">
        <div class="text-muted text-105">
            Motivo anulacion: 
        </div>
        <div class="text-muted text-105">
            <select class="form-control" required name="motivo_anulacion" [(ngModel)]="data.motivo_anulacion">
                <option [ngValue]="motivo_anulacion" *ngFor="let motivo_anulacion of sin_motivos_anulacion">{{motivo_anulacion.nombre}}</option>
            </select>
        </div>
    </div>
</div>
<div class="modal-footer bgc-white-tp2 border-0">
    <button type="button" class="btn btn-md btn-wide btn-light-grey" data-dismiss="modal" (click)="doNo()">No</button>
    <button type="button" class="btn btn-md btn-danger" data-dismiss="modal" (click)="doYes()">Si</button>
  </div>