<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Paciente</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarRegistroPaciente()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="paciente.cliente.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Apellido Paterno</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="apellido_paterno" required type="text" [(ngModel)]="paciente.cliente.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Apellido Materno</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="apellido_materno" required type="text" [(ngModel)]="paciente.cliente.persona.apellido_materno" placeholder="Apellido Materno" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombres</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="nombres" required type="text" [(ngModel)]="paciente.cliente.persona.nombres" placeholder="Nombres" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Fecha Nacimiento</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <div class="input-group">
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="paciente.cliente.persona.fecha_nacimiento_texto" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Telefono</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="telefono" [(ngModel)]="paciente.cliente.persona.telefono" placeholder="Telefono" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Teléfono Móvil</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="telefono_movil" [(ngModel)]="paciente.cliente.persona.telefono_movil" placeholder="Telefono Móvil" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}}</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" id="categoria" name="categoria" class="form-control" [(ngModel)]="paciente.cliente.categoria">
                <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
            </select>
        </div>
        <div class="col-sm-12 col-md-1">
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('CATEGORIAS CLIENTES','obtenerCategoriasClientesEmpresa')"></span>
        </div>
    </div>
</div>