<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Archivos Banco
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Archivos Banco
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="fefin">Hasta </label>&nbsp;
                        <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>  
                  </div>
                  <div class="col-md-1 col-sm-12"><label>Gestiones </label></div>
                  <div class="col-md-3 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.gestion">
                          <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                  </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoArchivoBanco()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="archivos_banco.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center"><a href="javascript:void(0)">Gestión <span class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Fecha <span class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Nombre Archivo <span class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Numero Documento <span class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Total (Bs) <span class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">¿Consolidado? <span class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #archivo_banco *ngFor="let archivo_banco of archivos_banco; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td>{{archivo_banco.gestion.nombre}}</td>
                <td>{{archivo_banco.fecha | date:'dd/MM/yyyy'}}</td>
                <td><a title="archivo" class="btn btn-md btn-purple" target="_blank" href="{{rest_server+archivo_banco.nombre_archivo}}">
                      <i class="fas fa-file-archive"></i>
                    </a> &nbsp;{{archivo_banco.nombre_archivo.split('/')[archivo_banco.nombre_archivo.split('/').length-1]}}</td>
                <td>{{archivo_banco.numero_documento}}</td>
                <td>{{archivo_banco.total.toFixed(2)}}</td>
                <td *ngIf="archivo_banco.consolidado"><span class="badge badge-md badge-success">SÍ</span></td>
                <td *ngIf="!archivo_banco.consolidado"><span class="badge badge-md badge-danger">NO</span></td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar && !archivo_banco.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="relacionarConceptosPagosEstudiantes(archivo_banco)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_ver && archivo_banco.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verArchivoBanco(archivo_banco)">          
                      <i class="fa fa-search text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_eliminar && !archivo_banco.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(archivo_banco)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="archivos_banco.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #registro_archivo_banco_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Archivo Banco</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarArchivoBanco()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-1">
              <label>Gestión</label>
            </div>
            <div class="col-2">
              <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="archivo_banco.gestion">
                  <option [ngValue]="gestion" *ngFor="let gestion of gestiones">{{gestion.nombre}}</option>
              </select>
            </div>
            <div class="col-1">
                <label>Fecha</label>
              </div>
            <div class="col-2">
                <div class="input-group">
                  <input name="fecha_inicial_pago" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="archivo_banco.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div> 
            </div> 
            <div class="col-2">
                <label>Nombre Archivo</label>
            </div>
            <div class="col-1">
              <button (click)="clickBotonSubir('subir-archivo-banco')" class="fileUpload btn btn-success" aria-label="Export" type="button" title="Subir excel estudiantes" aria-expanded="false">
                  <i class="fa fa-upload align-top bigger-110 fileUpload-button"></i>
                  <input id="subir-archivo-banco" (change)="subirDetalleArchivoBanco($event)" style="display:none" type="file" class="form-control" placeholder="Upload file">
                </button>
            </div>
            <div class="col-3">
                <input disabled type="text" name="nombre_archivo" [(ngModel)]="archivo_banco.nombre_archivo" placeholder="Nombre Archivo" class="form-control"/>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
              <table *ngIf="archivo_banco.detalles_archivo.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                <thead class="bgc-white text-grey text-uppercase text-80">
                  <tr>
                    <th>N°</th>
                    <th class="center">Código Colegio</th>
                    <th class="center">Código Sucursal Banco</th>
                    <th class="center">Código Estudiante</th>
                    <th class="center">Nombre Estudiante</th>
                    <th class="center">Conceptos</th>
                    <th class="center">Importe</th>
                    <th class="center">Fecha Pago</th>
                    <th class="center">Cuotas</th>
                    <th class="center">Número Factura</th>
                    <th class="center">Código Control Factura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr #archivo_banco *ngFor="let detalle_archivo of archivo_banco.detalles_archivo; let i=index;">
                    <td>
                      {{(i+1)}}
                    </td>
                    <td>{{detalle_archivo.codigo_colegio}}</td>
                    <td>{{detalle_archivo.codigo_sucursal_banco}}</td>
                    <td>{{detalle_archivo.codigo_estudiante}}</td>
                    <td>{{detalle_archivo.nombre_estudiante}}</td>
                    <td>{{detalle_archivo.concepto}}</td>
                    <td>{{detalle_archivo.importe}}</td>
                    <td>{{detalle_archivo.fecha_pago | date:'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{detalle_archivo.cuota_hasta}}</td>
                    <td>{{detalle_archivo.numero_factura}}</td>
                    <td>{{detalle_archivo.codigo_control_factura}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>  
</ng-template>

<ng-template #asignacion_pago_concepto_estudiante_modal let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Relación de Pagos Banco</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="consolidarArchivoBanco()" class="btn btn-success"  >
              <i class="fas fa-check-circle text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-3">
            <label>Gestión: {{archivo_banco.gestion.nombre}}</label>
          </div>
          <div class="col-3">
              <label>Fecha: {{archivo_banco.fecha | date:"dd/MM/yyyy"}}</label>
          </div>
          <div class="col-6">
              <label>Nombre Archivo: {{archivo_banco.nombre_archivo}}</label>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-12">
            <table *ngIf="archivo_banco.detalles_archivo.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
              <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                  <th>N°</th>
                  <th class="center">Código Estudiante</th>
                  <th class="center">Nombre Estudiante</th>
                  <th class="center">Conceptos</th>
                  <th class="center">Importe</th>
                  <th class="center">Cuotas</th>
                  <th class="center">Datos Factura</th>
                  <th class="center">Datos de Relación</th>
                  <th style="text-align: center;" data-field="tools">
                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                    <div class="fht-cell"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let detalle_archivo of archivo_banco.detalles_archivo; let i=index;">
                  <tr [ngClass]="{'bgc-success-d1':detalle_archivo.consolidado,'bgc-warning-d1':!detalle_archivo.consolidado}" class="text-white">
                    <td >
                      {{(i+1)}}
                    </td>
                    <td>{{detalle_archivo.codigo_estudiante}}</td>
                    <td>{{detalle_archivo.nombre_estudiante}}</td>
                    <td>{{detalle_archivo.concepto}}</td>
                    <td>{{detalle_archivo.importe}}</td>
                    <td >{{detalle_archivo.cuota_hasta}}</td>
                    <td >Fecha: {{detalle_archivo.fecha_pago | date:'dd/MM/yyyy HH:mm'}}<br>
                      Número: {{detalle_archivo.numero_factura}} <br>
                      Código de Control: {{detalle_archivo.codigo_control_factura}}
                    </td>
                    <td>
                      <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                        <thead class="bgc-white text-grey text-uppercase text-80">
                          <tr>
                            <th class="center">Estudiante</th>
                            <th class="center">Concepto</th>
                            <th class="center">Cuota</th>
                            <th class="center" *ngIf="!detalle_archivo.consolidado">A Cuenta</th>
                            <th class="center">Pagado</th>
                            <th style="text-align: center;" data-field="tools">
                              <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                              <div class="fht-cell"></div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let detalle_venta of detalle_archivo.detalles_venta">
                            <td>{{detalle_venta.estudiante_concepto_pago.estudiante.codigo}} - {{detalle_venta.estudiante_concepto_pago.estudiante.persona.nombre_completo}}</td>
                            <td>{{detalle_venta.estudiante_concepto_pago.concepto_pago.concepto.nombre}}</td>
                            <td>{{detalle_venta.estudiante_concepto_pago.cuota}}</td>
                            <td *ngIf="!detalle_archivo.consolidado">{{detalle_venta.estudiante_concepto_pago.pagado_ingresado}}</td>
                            <td>{{detalle_venta.estudiante_concepto_pago.pagado}}</td>
                            <td>
                              <div class="action-buttons">        
                                <a *ngIf="!detalle_archivo.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarCuotaEstudiante(detalle_archivo,detalle_venta.estudiante_concepto_pago)">          
                                  <i class="fa fa-trash-alt text-105"></i>        
                                </a>      
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td *ngIf="!detalle_archivo.consolidado">{{sumarPagadoIngresado(detalle_archivo.detalles_venta)}}</td>
                            <td>{{sumarImportesDetalleConceptosEstudiante(detalle_archivo.detalles_venta)}}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <div class="action-buttons">        
                        <a *ngIf="!detalle_archivo.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="establecerPagosConceptosEstudiante(detalle_archivo)">          
                          <i class="fas fa-check-circle text-105"></i>        
                        </a>
                        <a *ngIf="!detalle_archivo.consolidado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="agregarEstudianteConceptoPago(detalle_archivo)">          
                          <i class="fas fa-plus text-105"></i>        
                        </a>   
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
      </div>
  </div>  
</ng-template>

<ng-template #asignacion_registro_concepto_estudiante_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Asignación Cuota de Pago Estudiante</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="agregarConceptoPagoEstudiante()" class="btn btn-primary"  >
            <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-8 col-sm-8 col-xs-8 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Estudiante</label>&nbsp;
            <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" *ngIf="busqueda_concepto_pago.estudiante_busqueda.id" (click)="reiniciarBusquedaCliente()">
                <i class="fa fa-trash bigger-130 red2"></i>
            </a>&nbsp;
            <a *ngIf="busqueda_concepto_pago.estudiante_busqueda.id" class="card-toolbar-btn text-success-m1" href="javascript:void(0)" (click)="abrirFichaEconomica()">
              <i class="fas fa-money-check-alt red2 text-110"></i>
            </a>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
            <input name="estudiante_codigo" [disabled]="busqueda_concepto_pago.estudiante_busqueda.id" 
                [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerEstudiante($event)"
                type="text" class="form-control" [(ngModel)]="busqueda_concepto_pago.estudiante_busqueda" 
                [ngbTypeahead]="buscarEstudiante" placeholder="Buscar estudiante" 
                 autocomplete="off"
                />

            <ng-template #rt let-r="result" let-t="term">
                <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                <ngb-highlight [result]="r.codigo+' - '+r.apellido_paterno+' '+r.apellido_materno+' '+r.nombres" [term]="t"></ngb-highlight>
              </ng-template>
        </div>
      </div>
      <div *ngIf="busqueda_concepto_pago.estudiante_busqueda.id" class="col-4 col-sm-4 col-xs-4 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Gestión</label>
            <a *ngIf="busqueda_concepto_pago.gestion" class="card-toolbar-btn text-primary-m1" href="javascript:void(0)" (click)="abrirAsignacionConceptoPagoEstudiante()">
              <i class="fas fa-hand-holding-usd red2 text-110"></i>
            </a>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="busqueda_concepto_pago.gestion" (change)="buscarPagosEstudiante()">
            <option [ngValue]="gestion" *ngFor="let gestion of gestiones">{{gestion.nombre}}</option>
          </select>
        </div>
      </div>
        
    </div>
    <hr>
    <div class="row" *ngIf="busqueda_concepto_pago.gestion">
      <div class="col-12 col-sm-12 col-xs-12 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Cuota de pago</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <select [compareWith]="compararObjectos" name="cuota" class="form-control" [(ngModel)]="busqueda_concepto_pago.cuota">
            <option [ngValue]="cuota" *ngFor="let cuota of busqueda_concepto_pago.cuotas_estudiante">Gestión:{{cuota.concepto_pago.gestion.nombre}}-Cuota:{{cuota.cuota}}-{{cuota.concepto_pago.concepto.nombre}}</option>
          </select>
        </div>
      </div>
    </div>
    <hr>
    <div class="row" *ngIf="busqueda_concepto_pago.cuota">
      <div class="col-3 col-sm-3 col-xs-3 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Importe</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <input disabled class="form-control" id="importe" name="importe" type="number" step="0.01" min="1" [(ngModel)]="busqueda_concepto_pago.cuota.importe"/>
        </div>
      </div>
      <div class="col-3 col-sm-3 col-xs-3 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Descuento</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <input disabled class="form-control" id="descuento" name="descuento" type="number" step="0.01" min="1" [(ngModel)]="busqueda_concepto_pago.cuota.descuento"/>
        </div>
      </div>
      <div class="col-3 col-sm-3 col-xs-3 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Pagado</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <input disabled class="form-control" name="pagado" type="number" step="0.01" min="1" [(ngModel)]="busqueda_concepto_pago.cuota.pagado"/>
        </div>
      </div>
      <div class="col-3 col-sm-3 col-xs-3 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>A Cuenta</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <input (keyup)="calcularSaldo()" class="form-control" name="pagado_ingresado" type="number" step="0.01" min="1" [(ngModel)]="busqueda_concepto_pago.cuota.pagado_ingresado"/>
        </div>
      </div>
      <div class="col-3 col-sm-3 col-xs-3 pr-0">
        <div class="col-md-12 col-sm-12 col-xs-12" >
            <label>Saldo</label>&nbsp;
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
          <input disabled class="form-control" id="saldo" name="saldo" type="number" step="0.01" min="1" [(ngModel)]="busqueda_concepto_pago.cuota.saldo"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #vista_archivo_banco_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Archivo Banco</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-3">
          <label>Gestión: {{archivo_banco.gestion.nombre}}</label>
        </div>
        <div class="col-3">
            <label>Fecha: {{archivo_banco.fecha | date:"dd/MM/yyyy"}}</label>
        </div>
        <div class="col-6">
            <label>Nombre Archivo: {{archivo_banco.nombre_archivo}}</label>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
          <table *ngIf="archivo_banco.detalles_archivo.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center">Código Estudiante</th>
                <th class="center">Nombre Estudiante</th>
                <th class="center">Conceptos</th>
                <th class="center">Importe</th>
                <th class="center">Cuotas</th>
                <th class="center">Datos Factura</th>
                <th class="center">Datos de Relación</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let detalle_archivo of archivo_banco.detalles_archivo; let i=index;">
                <tr [ngClass]="{'bgc-success-d1':detalle_archivo.consolidado,'bgc-warning-d1':!detalle_archivo.consolidado}" class="text-white">
                  <td >
                    {{(i+1)}}
                  </td>
                  <td>{{detalle_archivo.codigo_estudiante}}</td>
                  <td>{{detalle_archivo.nombre_estudiante}}</td>
                  <td>{{detalle_archivo.concepto}}</td>
                  <td>{{detalle_archivo.importe}}</td>
                  <td >{{detalle_archivo.cuota_hasta}}</td>
                  <td >Fecha: {{detalle_archivo.fecha_pago | date:'dd/MM/yyyy HH:mm'}}<br>
                    Número: {{detalle_archivo.numero_factura}} <br>
                    Código de Control: {{detalle_archivo.codigo_control_factura}}
                  </td>
                  <td>
                    <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
                      <thead class="bgc-white text-grey text-uppercase text-80">
                        <tr>
                          <th class="center">Estudiante</th>
                          <th class="center">Concepto</th>
                          <th class="center">Cuota</th>
                          <th class="center">Pagado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let detalle_venta of detalle_archivo.detalles_venta">
                          <td>{{detalle_venta.estudiante_concepto_pago.estudiante.codigo}} - {{detalle_venta.estudiante_concepto_pago.estudiante.persona.nombre_completo}}</td>
                          <td>{{detalle_venta.estudiante_concepto_pago.concepto_pago.concepto.nombre}}</td>
                          <td>{{detalle_venta.estudiante_concepto_pago.cuota}}</td>
                          <td>{{detalle_venta.estudiante_concepto_pago.pagado}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{{sumarImportesDetalleConceptosEstudiante(detalle_archivo.detalles_venta)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
    </div>
  </div>  
</ng-template>
