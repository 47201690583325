import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { CarrerasService } from 'src/app/institute/services/carreras/carreras.service';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-reporte-pagos-instituto',
  templateUrl: './reporte-pagos-instituto.component.html',
  styleUrls: ['./reporte-pagos-instituto.component.css']
})
export class ReportePagosInstitutoComponent extends BaseComponent implements OnInit {

  @Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();

  estudiantes:any[]=[];

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private carrerasService:CarrerasService,
    private conceptosIngresos:ConceptosIngresosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  async ngOnInit(): Promise<void> {
    this.filter={
      escuela:null,
			gestion:GlobalVariable.Dictionary.SELECCION_TODOS,
      gestiones:[GlobalVariable.Dictionary.SELECCION_TODOS],
      semestre:GlobalVariable.Dictionary.SELECCION_TODOS,
      semestres:[GlobalVariable.Dictionary.SELECCION_TODOS],
      carrera:GlobalVariable.Dictionary.SELECCION_TODOS,
      carreras:[GlobalVariable.Dictionary.SELECCION_TODOS]
		}
    await this.obtenerEscuela();
    await this.obtenerCarreras();
    this.obtenerGestiones();
    this.obtenerSemestresGestion();
  }

  async obtenerEscuela(){
    this.blockUI.start();
    this.filter.escuela=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
    this.blockUI.stop();
  }

  async obtenerCarreras(){
    let carreras:any=await this.carrerasService.obtenerCarreras(this.filter.escuela.id).toPromise();
    this.filter.carreras=this.filter.carreras.concat(carreras);
  }

  obtenerGestiones(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.filter.gestiones=this.filter.gestiones.concat(entidad.clases);
    });
  }

  obtenerSemestresGestion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_SEMESTRES_GESTION).subscribe((entidad:any)=>{
      this.filter.semestres=this.filter.semestres.concat(entidad.clases);
    });
  }

  cerrarComponente(){
    this.onConfirm.emit({confirm:false});
  }

  obtenerReportePagosInstituto(){
    this.conceptosIngresos.obtenerReportePagosInstituto(this.filter).subscribe((datos:any)=>{
      this.estudiantes=datos;
    });
  }

  generarPdfReportePagos(){

  }

  generarExcelReportePagos(){/*
		this.blockUI.start();

		var suma_importe=0;
		var data = [];
		var header=["N°"];
		if(this.configuracion_pagina.getConfiguration().transaccion.show){
			header.push("Tipo de Transaccion");
		}
		if(this.configuracion_pagina.getConfiguration().factura.show){
			header.push("Número Doc.");
		}
		if(this.configuracion_pagina.getConfiguration().sucursal.show){
			header.push("Sucursal");
		}
		if(this.configuracion_pagina.getConfiguration().cliente.show){
			header.push("Cliente");
		}
		if(this.configuracion_pagina.getConfiguration().razon_social.show){
			header.push("Razón social");
		}
		if(this.configuracion_pagina.getConfiguration().nit.show){
			header.push("Nit");
		}
		if(this.configuracion_pagina.getConfiguration().cliente_categoria.show){
			header.push("Categoria Cliente");
		}
		if(this.configuracion_pagina.getConfiguration().fecha.show){
			header.push("Fecha Doc.");
		}
		if(this.configuracion_pagina.getConfiguration().createdAt.show){
			header.push("Hora Fecha Registro");
		}
		if(this.configuracion_pagina.getConfiguration().importe.show){
			header.push("Importe");
		}
		if(this.configuracion_pagina.getConfiguration().cobrado.show){
			header.push("Cobrado");
		}
		if(this.configuracion_pagina.getConfiguration().saldo.show){
			header.push("Saldo");
		}
		if(this.configuracion_pagina.getConfiguration().dias_credito.show){
			header.push("Dias Credito");
		}
		if(this.configuracion_pagina.getConfiguration().dias_mora.show){
			header.push("Dias Mora");
		}
		if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
			header.push("Tipo de Pago");
		}
		if(this.configuracion_pagina.getConfiguration().usuario.show){
			header.push("Usuario");
		}
		if(this.configuracion_pagina.getConfiguration().canal.show){
			header.push("Canal");
		}
		if(this.configuracion_pagina.getConfiguration().estado.show){
			header.push("Estado");
		}
		if(this.configuracion_pagina.getConfiguration().activa.show){
			header.push("Validez");
		}
		if(this.configuracion_pagina.getConfiguration().observacion.show){
			header.push("Observación");
		}

		header.push("Fecha Pago");
		header.push("Observación");

		data.push(header);
		for(var i=0;i<this.ventas.length;i++){
			var columns=[];
			columns.push(i+1);

			if(this.configuracion_pagina.getConfiguration().transaccion.show){
				columns.push(this.ventas[i].transaccion.nombre);
			}
			if(this.configuracion_pagina.getConfiguration().factura.show){
				columns.push(this.ventas[i].factura);
			}
			if(this.configuracion_pagina.getConfiguration().sucursal.show){
				columns.push(this.ventas[i].sucursal.nombre);
			}
			if(this.configuracion_pagina.getConfiguration().cliente.show){
				columns.push(this.ventas[i].cliente.identificacion);
			}
			if(this.configuracion_pagina.getConfiguration().razon_social.show){
				columns.push(this.ventas[i].razon_social);
			}
			if(this.configuracion_pagina.getConfiguration().nit.show){
				columns.push(this.ventas[i].nit_ci);
			}
			if(this.configuracion_pagina.getConfiguration().cliente_categoria.show){
				columns.push(this.ventas[i].cliente.categoria?this.ventas[i].cliente.categoria.nombre:"");
			}
			if(this.configuracion_pagina.getConfiguration().fecha.show){
				this.ventas[i].fecha=new Date(this.ventas[i].fecha);
				columns.push(this.ventas[i].fecha.getDate()+"/"+(this.ventas[i].fecha.getMonth()+1)+"/"+this.ventas[i].fecha.getFullYear());
			}
			if(this.configuracion_pagina.getConfiguration().createdAt.show){
				this.ventas[i].createdAt=new Date(this.ventas[i].createdAt);
				columns.push(this.ventas[i].createdAt.getHours()+":"+this.ventas[i].createdAt.getMinutes()+"-"+this.ventas[i].createdAt.getDate()+"/"+(this.ventas[i].createdAt.getMonth()+1)+"/"+this.ventas[i].createdAt.getFullYear());
			}
			if(this.configuracion_pagina.getConfiguration().importe.show){
				columns.push(this.ventas[i].total);
			}
			if(this.configuracion_pagina.getConfiguration().cobrado.show){
				columns.push(this.ventas[i].a_cuenta);
			}
			if(this.configuracion_pagina.getConfiguration().saldo.show){
				columns.push(this.ventas[i].saldo);
			}
			if(this.configuracion_pagina.getConfiguration().dias_credito.show){
				columns.push(this.ventas[i].dias_credito);
			}
			if(this.configuracion_pagina.getConfiguration().dias_mora.show){
				columns.push(this.calcularDiasMora(this.ventas[i]));
			}
			if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
				columns.push(this.ventas[i].tipoPago.nombre);
			}
			if(this.configuracion_pagina.getConfiguration().usuario.show){
				columns.push(this.ventas[i].usuario.nombre_usuario);
			}
			if(this.configuracion_pagina.getConfiguration().canal.show){
				columns.push(this.ventas[i].canal?this.ventas[i].canal.nombre:"");
			}
			if(this.configuracion_pagina.getConfiguration().estado.show){
				columns.push(this.ventas[i].estado?this.ventas[i].estado.nombre:"");
			}
			if(this.configuracion_pagina.getConfiguration().activa.show){
				columns.push(this.ventas[i].activa?"ACTIVO":"ANULADO");
			}
			if(this.configuracion_pagina.getConfiguration().observacion.show){
				columns.push(this.ventas[i].observacion);
			}

			var fechas_pago="",observaciones="";
			for(var j=0; j<this.ventas[i].pagosVenta.length;j++){
				var fecha_pago=new Date(this.ventas[i].pagosVenta[j].createdAt);
				fechas_pago=fechas_pago+fecha_pago.getDate()+"/"+(fecha_pago.getMonth()+1)+"/"+fecha_pago.getFullYear();
				observaciones=observaciones+this.ventas[i].pagosVenta[j].observacion;
			}
			columns.push(fechas_pago);
			columns.push(observaciones);

			if(this.ventas[i].activa){
				suma_importe=suma_importe+this.ventas[i].total;
			}

			data.push(columns);
			if(this.datos_con_detalle){
				data.push(["","","","","N°","Nombre","Codigo Item","Unidad de Med","Cantidad","Importe"]);
				for(var j=0; j<this.ventas[i].detallesVenta.length;j++){
					columns=[];
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push(j+1);
					columns.push(this.ventas[i].detallesVenta[j].producto?this.ventas[i].detallesVenta[j].producto.nombre:this.ventas[i].detallesVenta[j].descripcion?this.ventas[i].detallesVenta[j].descripcion:"");
					columns.push(this.ventas[i].detallesVenta[j].producto?this.ventas[i].detallesVenta[j].producto.codigo:"");
					columns.push(this.ventas[i].detallesVenta[j].producto?this.ventas[i].detallesVenta[j].producto.unidad_medida:"UND");
					columns.push(this.ventas[i].detallesVenta[j].cantidad);
					columns.push(this.ventas[i].detallesVenta[j].importe);
					
					data.push(columns);
					}
			}
			if(i+1==this.ventas.length){
				columns=[];
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("");
				columns.push("TOTALES");
				columns.push(suma_importe);
				columns.push("");
			
				data.push(columns);
			}
			
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("VENTAS");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "Reporte-ventas.xlsx");
		})
		this.blockUI.stop();*/
	}

}
