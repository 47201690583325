import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { ExcelService } from 'src/app/base/services/excel/excel.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { Util } from 'src/app/utils/utils';
import { OrdenesTrabajosService } from '../../services/ordenes-trabajos/ordenes-trabajos.service';
import { RegistroOrdenTrabajoComponent } from '../registro-orden-trabajo/registro-orden-trabajo.component';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-ordenes-trabajos',
  templateUrl: './ordenes-trabajos.component.html',
  styleUrls: ['./ordenes-trabajos.component.css']
})
export class OrdenesTrabajosComponent extends BaseComponent implements OnInit {

  ordenes_trabajo:any[]=[];
  registro_orden_trabajo_modal:NgbModalRef;
  orden_trabajo:any={};
  producto_ot:any={producto:{}};
  producto:any;
  producto_busqueda:any;
  searching = false;
  searchFailed = false;  

  lista_productos_ot:NgbModalRef;
  @ViewChild('lista_productos_ot')
  private lista_productos_ot_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
	public excelService:ExcelService,
	public pdfService:PdfService,
    public modalService: NgbModal,
	private toastr: ToastrService,
	private ordenTrabajoService:OrdenesTrabajosService,
	private productosService:ProductosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

	async ngOnInit(): Promise<void> {
		this.init();
		this.buscarAplicacion(this.router.url.substring(1));

		let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
			sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			activas:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVAS"},{id:2,nombre:"ANULADAS"}],
			activa: GlobalVariable.Dictionary.SELECCION_TODOS,
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		}

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}

		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
		
		this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
	}

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  crearNuevaOrdenTrabajo(){
	this.registro_orden_trabajo_modal = this.modalService.open(RegistroOrdenTrabajoComponent, {scrollable:true,windowClass : "wizard_edicion_orden_trabajo",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
	//this.registro_orden_trabajo_modal.componentInstance.venta.usuario = this.usuario;
	
	this.registro_orden_trabajo_modal.componentInstance.alTerminar.subscribe(async (res) => {
		if(res){
			if(res.tiene_error){
				this.toastr.error(res.stack.substring(0,500));
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.registro_orden_trabajo_modal.close();
			}
		}else{
			this.registro_orden_trabajo_modal.close();
		}
	});
  }

  	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.ordenTrabajoService.obtenerListaOrdenesTrabajo(this).subscribe((dato:any) => {
			this.setPages(1);
			this.ordenes_trabajo=dato.ordenes_trabajo;
			this.blockUI.stop();
		});
	}

	abrirModalRegistroRepuestos(orden_trabajo){
		this.blockUI.start();
		this.iniciarProductoOt();
		this.ordenTrabajoService.obtenerOrdenTrabajo(orden_trabajo.id).subscribe((res:any)=>{
			this.blockUI.stop();
			this.orden_trabajo=res;
			this.lista_productos_ot=this.modalService.open(this.lista_productos_ot_ref, {windowClass:'lista-productos-ot',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
			this.lista_productos_ot.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  	formatterProducto = (x: any) => (x?(x.codigo+" - "+x.nombre+ " - "+ x.descripcion):"");

	establecerProducto($event){
		this.producto=$event.item
		this.producto.tipoProducto=this.producto['tipoProducto']==null?{id:this.producto['tipoProducto.id'],nombre:this.producto['tipoProducto.nombre'],nombre_corto:this.producto['tipoProducto.nombre_corto']}:this.producto.tipoProducto;

		this.productosService.obtenerPreciosProductos(this.producto.id).subscribe(async (precios_datos:any[]) => {
			let precios=[];
			if(precios.length==0){//Sin ninguno de los anteriores tiene precio
				precios=precios_datos.filter(p => (p.id_sucursal==null && p.id_cliente==null && p.id_usuario==null));
			}
			this.producto.precios=precios;

			this.producto_ot.producto=this.producto;
			this.producto_ot.precio_unitario=this.producto.precio_unitario;
			this.producto_ot.cantidad=1;
			setTimeout(()=>{
				aplicarVisorImagenes("imagen-producto-vista");
			}, 200);
		});
	}

	iniciarProductoOt(){
		this.producto_busqueda="";
		this.producto_ot={
		  cantidad:1,
		  producto:{},
		  activo:true
		}
	}

	agregarProductoOt(){
		if(this.validarProductoOt()){
			let producto_ot_a_agregar=this.producto_ot;
			let producto_ot_existente=this.orden_trabajo.productos.filter(e => e.activo && e.producto && (e.producto.id == producto_ot_a_agregar.producto.id));
			if(producto_ot_existente.length>0){
				producto_ot_existente=producto_ot_existente[0];
				producto_ot_existente.cantidad=producto_ot_existente.cantidad+producto_ot_a_agregar.cantidad;
				this.orden_trabajo.productos[this.orden_trabajo.productos.indexOf(producto_ot_existente)]=producto_ot_existente;
			}else{
				this.orden_trabajo.productos.push(this.producto_ot);
			}
			this.iniciarProductoOt();
		}
	}

	validarProductoOt(){
		let res=true;
		if(!this.producto_ot.producto || !this.producto_ot.producto.id){
			res=res && false;
			this.toastr.error("Debe especificar el producto!");
		}
		if(!this.producto_ot.cantidad){
		  res=res && false;
		  this.toastr.error("Debe especificar la cantidad!");
		}
		if(!this.producto_ot.precio_unitario){
		  res=res && false;
		  this.toastr.error("Debe especificar el precio unitario!");
		}
		return res;
	}

	guardarProductosOt(){
		this.blockUI.start();
		this.ordenTrabajoService.guardarProductosOrdenTrabajo(this.orden_trabajo).subscribe((res:any)=>{
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
			this.lista_productos_ot.close();
		});
	}

	abrirPopupConfirmacionEliminacion(producto_ot){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el producto de la orden de trabajo?";
		this.popupConfirmacion.componentInstance.data = producto_ot;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarProducto($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	eliminarProducto(producto_ot){
		producto_ot.activo=false;
	}
	

}
