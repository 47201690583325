<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Cuentas por cobrar
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Cuentas por cobrar
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                  <label for="fefin">Hasta </label>&nbsp;
                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
              </div>  
            </div>
                        <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                        <div class="col-md-3 col-sm-12">
                            <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} </label>
                            <input class="form-control form-control-sm search-input" type="text" [(ngModel)]="filter.cliente">
                        </div>
                        <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_clientes_contactos">
                            <label>Contacto Cliente </label>
                            <input class="form-control form-control-sm search-input" type="text" [(ngModel)]="filter.contacto_cliente">
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <label>Usuario </label>&nbsp;
                              <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                                <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                              </select>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <label>¿Cuentas Liquidadas? </label>
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.cuentas_liquidadas"/>
                            </label>
                          </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="row">
                <div class="col-12">
                    <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                        <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>
                    <div class="columns columns-right btn-group float-right">
                    <div class="export btn-group">
                        <button (click)="generarPdfEstadoCuentasClientes()" class="btn btn-outline-danger btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1" aria-label="Export"  type="button" title="Descargar pdf" aria-expanded="false">
                        <i class="fa fa-file-pdf text-danger-d1"></i>
                        <span class="caret"></span>
                        </button>
                        <button (click)="generarExcelEstadoCuentasClientes()"  class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel" aria-expanded="false">
                            <i class="fa fa-file-excel text-green-d1"></i>
                            <span class="caret"></span>
                        </button>
                        <button (click)="verPagos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista Pagos Ventas" aria-expanded="true">
                            <i class="fa fa-list-alt text-blue-d1"></i>
                            <span class="caret"></span>
                        </button>
                        <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                          <i class="fa fa-wrench text-blue-d1"></i>
                          <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                          <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                            <div class="card bgc-primary brc-primary radius-0">
                              <div class="card-header">
                              <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                              </div>
                    
                              <div class="card-body p-0 bg-white">
                              <table class="table table-striped table-hover mb-0">
                                <tbody>
                                  <tr>
                                    <td class="text-dark-m2">
                                    Configuración Aplicación
                                    </td>
                                    <td class="text-dark-m2">
                                      <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                    </div>
                </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="clientes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('cliente.codigo')">
                            Código <span id="codigo" class="sort fa fa-sort icon-only"></span>
                        </a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().identificacion.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('cliente.identificacion')">Identificación <span id="identificacion" class="sort fa fa-sort icon-only"></span></a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('cliente.razon_social')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} <span id="razon_social" class="sort fa fa-sort icon-only"></span></a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().nit.show" class="center"> <a href="javascript:void(0)" (click)="this.sortColumn('cliente.nit')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}} <span id="nit" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().total.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('total_cliente')">Total <span id="total_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_devoluciones_ventas && configuracion_pagina.getConfiguration().devoluciones.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('devoluciones')">Devolucion <span id="devoluciones" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().a_cuenta.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('a_cuenta_cliente')">A Cuenta <span id="a_cuenta_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().saldo.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('saldo_cliente')">Saldo <span id="saldo_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show" class="center">Contactos Cliente</th>
                <th *ngIf="configuracion_pagina.getConfiguration().direccion.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('cliente.direccion')">Dirección <span id="direccion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().telefono1.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('cliente.telefono1')">Telefono 1 <span id="telefono1" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #cliente *ngFor="let cliente of clientes; let i=index;">
                <td>
                    {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                  </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{cliente.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().identificacion.show">
                    {{cliente.identificacion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">
                    {{cliente.razon_social}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{cliente.nit}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{cliente.total_cliente}}</td>
                <td *ngIf="usuario.empresa.usar_devoluciones_ventas && configuracion_pagina.getConfiguration().devoluciones.show">{{cliente.devoluciones}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().a_cuenta.show">{{cliente.a_cuenta_cliente}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().saldo.show">{{cliente.saldo_cliente}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show">{{cliente.contactos_cliente}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().direccion.show">{{cliente.direccion}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().telefono1.show">
                    {{cliente.telefono1}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfResumidoEstadoCuentasCliente(cliente)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>  
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="obtenerExcelEstadoCuentaCliente(cliente)">          
                        <i class="fa fa-file-excel text-105"></i>        
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirEstadoCuentaCliente(cliente)">          
                        <i class="fa fa-filter text-105"></i>        
                    </a>  
                    <a *ngIf="usuario.empresa.usar_clientes_contactos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirEstadoContactosCliente(cliente)">          
                        <i class="fa fa-user text-105"></i>        
                    </a> 
                    <a *ngIf="usuario.empresa.id==6" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioCorreo(cliente)">          
                        <i class="fa fa-envelope text-105"></i>        
                    </a>    
                    <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioWhatsapp(cliente)">
                      <i class="ace-icon fab fa-whatsapp bigger-130"></i>
                    </a>        
					<!--<a *ngIf="usuario.empresa.usar_kardex_inventario_productos && usuario.empresa.usar_inventario && producto.activar_inventario" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verKardexProducto(producto)">          
						<i class="fa fa-eye text-105"></i>        
					</a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(producto)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>    -->  
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="clientes.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #cuenta_contacto_cliente_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Cuenta por cobrar Contactos Cliente: {{cliente.identificacion}}</h4>
		</div>
		<div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
            </button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Contacto</th>
                                        <th>Telefono</th>
                                        <th>Importe</th>
                                        <th *ngIf="usuario.empresa.usar_devoluciones_ventas">Devoluciones</th>
                                        <th>A Cuenta</th>
                                        <th>Saldo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                    
                                <tbody>
                                    <tr *ngFor="let contacto of cliente.contactos; let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{contacto.nombre_completo}} </td>
                                        <td>{{contacto.telefono}} </td>
                                        <td>{{contacto.total}}</td>
                                        <td *ngIf="usuario.empresa.usar_devoluciones_ventas">{{contacto.devoluciones}}</td>
                                        <td>{{contacto.a_cuenta}}</td>
                                        <td>{{contacto.saldo}}</td>
                                        <td>
                                            <div  class="hidden-sm hidden-xs action-buttons">
                                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verDetalleCuentaContactoCliente(contacto)">          
                                                    <i class="fa fa-search text-105"></i>        
                                                </a>
                                            </div>							
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Total General</th>
                                        <th>{{total_importe}}</th>
                                        <th *ngIf="usuario.empresa.usar_devoluciones_ventas">{{total_devoluciones}}</th>
                                        <th>{{total_a_cuenta}}</th>
                                        <th>{{total_saldo}}</th>
                                        <th></th>
                                    </tr>
                                </tbody>					
                            </table>
                        </div>
                    </div>
    
                    <div class="row" *ngIf="cliente_contacto">
                        <div class="col-md-4">
                            <h4>Contacto Cliente : </h4>
                            <h4>{{cliente_contacto.nombre_completo}}</h4>
                        </div>
                        <div class="col-md-2">
                            <a  class="blue" href="javascript:void(0)" ng-click="click(cliente_contacto.ventas)">
                            <i class="ace-icon fa fa-eye bigger-200"></i>
                            </a>
                        </div>
                        <div class="col-md-2">
                            &nbsp;
                            <button class="btn btn-danger" type="button" (click)="imprimirDetalleContacto(cliente_contacto)">
                                <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row" *ngIf="cliente_contacto">
                        <div class="col-md-12">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Fecha</th>
                                        <th>Transaccion</th>
                                        <th>Nro. Doc.</th>
                                        <th>Descripcion</th>
                                        <th>Importe</th>
                                        <th *ngIf="usuario.empresa.usar_devoluciones_ventas">Devoluciones</th>
                                        <th>A Cuenta</th>
                                        <th>Saldo</th>
                                        <th>Usuario</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                    
                                <tbody>
                                    <tr *ngFor="let venta of cliente_contacto.ventas;let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{venta.fecha | date:"dd/MM/yyyy"}}</td>
                                        <td>{{venta.transaccion}} </td>
                                        <td>{{venta.factura}} </td>
                                        <td >{{venta.observacion}}</td>
                    
                                        <td>{{venta.total}}</td>
                                        <td *ngIf="usuario.empresa.usar_devoluciones_ventas">{{venta.devoluciones}}</td>
                                        <td>{{venta.a_cuenta}}</td>
                                        <td>{{venta.saldo}}</td>
                                        <td>{{venta.nombre_usuario}}</td>
                                        <td>
                                            <div  class="hidden-sm hidden-xs action-buttons">
                                                <a *ngIf="venta.saldo>0" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupPagoCreditosVenta(venta)">          
                                                    <i class="fa fa-money-bill-alt text-105"></i>        
                                                </a>
                                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(venta)">          
                                                    <i class="fa fa-search text-105"></i>        
                                                </a>
                                            </div>							
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>Total General</th>
                                        <th>{{total_importe}}</th>
                                        <th *ngIf="usuario.empresa.usar_devoluciones_ventas">{{total_devoluciones}}</th>
                                        <th>{{total_a_cuenta}}</th>
                                        <th>{{total_saldo}}</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tbody>					
                            </table>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</ng-template>