import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class CajasService {

  constructor(private http: HttpClient) { }

  public obtenerListaCajas(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cajas/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarCaja(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cajas",datos,GlobalVariable.httpOptions);
  }

  public actualizarCaja(caja){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cajas/"+caja.id,caja,GlobalVariable.httpOptions);
  }

  public eliminarCaja(caja){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"cajas/"+caja.id,GlobalVariable.httpOptions);
  }

  public obtenerCajasEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cajas/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarMovimientoCaja(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"movimiento-caja",datos,GlobalVariable.httpOptions);
  }

  public obtenerListaMovimientosCaja(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"movimiento-caja/"+paginator.filter.ids_sucursales_usuario+"/inicio/"+fecha_inicial+"/fin/"+fecha_fin+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/caja/"+paginator.filter.caja.id+"/usuario/"+paginator.filter.usuario.id+"/tipo-movimiento/"+paginator.filter.tipo_movimiento.id+"/concepto/"+paginator.filter.concepto.id+"/transaccion/"+paginator.filter.transaccion.id+"/validez/"+paginator.filter.activo.id,GlobalVariable.httpOptions);
  }

  public guardarCheque(cheque){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cheques",cheque,GlobalVariable.httpOptions);
  }

  public actualizarCheque(cheque){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cheques/"+cheque.id,cheque,GlobalVariable.httpOptions);
  }

  public obtenerListaCheques(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cheques/caja/"+paginator.filter.id_caja+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public eliminarCheque(cheque){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"cheques/"+cheque.id,GlobalVariable.httpOptions);
  }

  public obtenerListaChequesCaja(id_caja){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cheques/caja/"+id_caja,GlobalVariable.httpOptions);
  }

  public obtenerMovimientoCaja(id_movimiento_caja){
    return this.http.get(GlobalVariable.API_SERVER_URL+"movimiento-caja/"+id_movimiento_caja,GlobalVariable.httpOptions);
  }

  public anularMovimiento(movimiento_caja){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"movimiento-caja/"+movimiento_caja.id,GlobalVariable.httpOptions);
  }
}
