import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepuestosComponent } from './components/repuestos/repuestos.component';
import { LlantasComponent } from './components/llantas/llantas.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { ComputadorasComponent } from './components/computadoras/computadoras.component';
import { OrdenesTrabajosComponent } from './components/ordenes-trabajos/ordenes-trabajos.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistroOrdenTrabajoComponent } from './components/registro-orden-trabajo/registro-orden-trabajo.component';
import { FilterPipeModule } from 'ngx-filter-pipe';


@NgModule({
  declarations: [
    RepuestosComponent,
    LlantasComponent,
    ComputadorasComponent,
    OrdenesTrabajosComponent,
    RegistroOrdenTrabajoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BaseModule,
    NgbModule,
    FilterPipeModule
  ],
  exports:[
    RepuestosComponent,
    LlantasComponent,
    OrdenesTrabajosComponent
  ]
})
export class MantenimientoModule { }
