<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Seguimiento
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Estudiantes
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Lista de</span>
          Seguimientos
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button [disabled]="!filter.registro || filter.registro.id==0" *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoSeguimiento()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-purple-d1"></i>
            </button>
            <button [disabled]="seguimientos.length==0" *ngIf="aplicacion.puede_ver" title="Reporte pdf" (click)="generarPdfSeguimiento()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-file-pdf text-danger-d1"></i>
            </button>
            <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                  <div class="col-md-3 col-sm-12">
                    
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <label>Gestión </label>&nbsp;
                      <select [compareWith]="compararObjectos" class="form-control" required name="gestion" [(ngModel)]="filter.gestion" (change)="obtenerRegistrosSeguimiento()">
                        <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <label>Registro </label>&nbsp;
                      <select [compareWith]="compararObjectos" class="form-control" required name="registro" [(ngModel)]="filter.registro">
                        <option [ngValue]="registro" *ngFor="let registro of filter.registros">{{registro.curso.nombre}} - {{registro.materia.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label>Usuario </label>&nbsp;
                      <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="seguimientos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th>Fecha</th>
                    <th>Curso</th>
                    <th>Materia</th>
                    <th>Estudiante</th>
                    <th>Descripcion</th>
                    <th>Categoria</th>
                    <th>Usuario</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let seguimiento of seguimientos; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{seguimiento.fecha | date:'dd/MM/yyyy - HH:mm'}}</td>
                    <td>{{seguimiento.curso.nombre}}</td>
                    <td>{{seguimiento.materia?seguimiento.materia.nombre:''}}</td>
                    <td>{{seguimiento.estudiante.persona.nombre_completo}}</td>
                    <td>{{seguimiento.descripcion}}</td>
                    <td>{{seguimiento.categoria.nombre}}</td>
                    <td>{{seguimiento.usuario.nombre_usuario}}</td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="d-lg-inline text-muted">
                            <a *ngIf="usuario.id==seguimiento.id_usuario" title="Modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarSeguimientoEstudiante(seguimiento)">
                                <i class="fas fa-edit"></i>
                            </a>
                            <a *ngIf="usuario.id==seguimiento.id_usuario" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(seguimiento)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="seguimientos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #lista_estudiantes_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Estudiantes</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
          <i class="fa fa-times red2 text-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-2 col-sm-12">
            <div class="input-group">
                <label for="feini">Gestión: {{filter.gestion.nombre}} </label>&nbsp;
            </div>  
        </div>
        <div class="col-md-4 col-sm-12">
            <div class="input-group">
                <label for="feini">Nivel: {{filter.nivel.nombre}} </label>&nbsp;
            </div>  
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Curso: {{filter.curso.nombre}} </label>&nbsp;
            </div>  
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="input-group">
              <label for="feini">Materia: {{filter.registro.materia.id?filter.registro.materia.nombre:''}} </label>&nbsp;
          </div>  
      </div>
    </div>
    <hr>
    <div class="row" *ngIf="estudiantes.length>0" >
        <div class="col-12">
          <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center">Fotografía </th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código </a></th>
                <th class="center"><a href="javascript:void(0)" (click)="this.sortColumn('apellido_paterno')">Estudiante</a></th>
                <th style="text-align: center;" data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #estudiantes *ngFor="let estudiante of estudiantes; let i=index;">
                <td>
                  {{(i+1)}}
                </td>
                <td>
                    <div class="imagen-estudiante-vista clearfix">
                        <a href="{{rest_server+estudiante.persona.imagen}}" data-rel="colorbox" title="{{estudiante.persona.nombre_completo}}">
                            <img alt="sin imagen" src="{{rest_server+estudiante.persona.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td>
                    {{estudiante.codigo}}
                </td>
                <td>
                    {{estudiante.persona.nombre_completo}}
                </td>
                <td style="text-align: center; ">
                  <div class="action-buttons">
                    <a title="Ficha Económica" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevoSeguimientoEstudiante(estudiante)">
                      <i class="ace-icon fas fa-plus bigger-130"></i>
                    </a>
                    <a title="Ficha Económica" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirFichaEconomica(estudiante)">
                      <i class="ace-icon fas fa-eye bigger-130"></i>
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="imprimirRude(estudiante)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</div>
</ng-template>