<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Pagos de Compras</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">

    <div class="row" *ngIf="!compra">
        <div class="col-md-5 col-sm-12">
            <div class="input-group">
                <label for="feini">Desde </label>&nbsp;
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="input-group">
                <label for="fefin">Hasta </label>&nbsp;
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>  
         </div>
        <div class="col-md-2 col-xs-3 col-sm-3">
            <button (click)="getItems()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <div class="row p-1" *ngIf="compra">
        <div class="col-md-7">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.tipo_documento.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Proveedor : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.proveedor.razon_social}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Num. Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.numero_documento}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Plazo (Días) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.total}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.a_cuenta}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.saldo}} 
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Número Documento</th>
                    <th>Proveedor</th>
                    <th>A Cuenta Anterior</th>
                    <th>Saldo Anterior</th>
                    <th>Importe Pagado</th>
                    <th>Saldo a la Fecha</th>							
                    <th>Observación</th>							
                    <th>Usuario</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pago_compra of lista_pagos_compras; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{pago_compra.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{pago_compra.numero_documento}}</td>
                    <td>{{pago_compra.compra.proveedor.identificacion}}</td>
                    <td>{{pago_compra.a_cuenta_anterior}}</td>
                    <td>{{pago_compra.saldo_anterior}}</td>
                    <td><i class="eva eva-paper-plane-outline text-160 radius-round bgc-success-l4 text-success p-2 border-2 brc-success-m3">{{pago_compra.monto_pagado}}</i></td>
                    <td>{{pago_compra.saldo_anterior-pago_compra.monto_pagado}}</td>
                    <td>{{pago_compra.observacion}}</td>
                    <td>{{pago_compra.usuario.nombre_usuario}}</td>
                    <td *ngIf="pago_compra.activo"><span class="badge badge-sm badge-success">Activo</span></td>
                    <td *ngIf="!pago_compra.activo"><span class="badge badge-sm badge-danger">Anulado</span></td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirPagoCompra(pago_compra)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="pago_compra.activo && pago_compra.comprobante" title="comprobante" class="btn btn-md" [ngClass]="{'btn-purple':(pago_compra.comprobante.includes('.zip') || pago_compra.comprobante.includes('.rar')),'btn-danger':pago_compra.comprobante.includes('pdf'),'btn-success':(pago_compra.comprobante.includes('xls') || pago_compra.comprobante.includes('xlsx')),'btn-primary':(pago_compra.comprobante.includes('doc') || pago_compra.comprobante.includes('docx') || pago_compra.comprobante.includes('jpg') || pago_compra.comprobante.includes('png') || pago_compra.comprobante.includes('gif') || pago_compra.comprobante.includes('jpeg') || pago_compra.comprobante.includes('mp3') || pago_compra.comprobante.includes('mpeg') || pago_compra.comprobante.includes('mp4'))}" target="_blank" href="{{rest_server+pago_compra.comprobante}}">
                                <i class="fas" [ngClass]="{'fa-file-archive':(pago_compra.comprobante.includes('.zip') || pago_compra.comprobante.includes('.rar')),'fa-file-pdf':pago_compra.comprobante.includes('pdf'),'fa-file-excel':(pago_compra.comprobante.includes('xls') || pago_compra.comprobante.includes('xlsx')),'fa-file-word':(pago_compra.comprobante.includes('doc') || pago_compra.comprobante.includes('docx')),'fa-file-image':(pago_compra.comprobante.includes('jpg') || pago_compra.comprobante.includes('png') || pago_compra.comprobante.includes('gif') || pago_compra.comprobante.includes('jpeg')),'fa-file-audio':(pago_compra.comprobante.includes('mp3') || pago_compra.comprobante.includes('mpeg')),'fa-file-video':(pago_compra.comprobante.includes('mp4'))}"></i>
                            </a>
                            <a *ngIf="pago_compra.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pago_compra)">
                              <i class="fa fa-trash-alt"></i>
                            </a>                       
                        </div>
                    </td>
                </tr>
                <tr *ngIf="lista_pagos_compras.length>0">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><b>TOTAL</b>: {{sumarImporte().toFixed(2) }}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>											
                </tr>
            </tbody>
        </table>
    </div>
</div>