<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Carreras
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Carreras
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                  <div class="col-md-2 col-sm-12"><label>Periodicidad </label></div>
                  <div class="col-md-4 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.periodicidad">
                          <option [ngValue]="periodicidad" *ngFor="let periodicidad of filter.periodicidades">{{periodicidad.nombre}}</option>
                      </select>
                  </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevaCarrera()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <button title="Periodos" (click)="verPeriodosClase()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-warning btn-a-outline-warning py-1 bs-print" type="button">
                    <i class="fas fa-hourglass-half text-warning-d1"></i>
                  </button>
                  <div class="keep-open btn-group show" title="Columns">
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="keep-open btn-group show" title="Columns">
                    <button *ngIf="aplicacion.puede_ver && usuario.empresa.usar_georeferenciacion_clientes" title="Mapa Clientes" (click)="abrirMapaClientes()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                      <i class="fas fa-map text-danger-d1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="carreras.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().nombre.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('identificacion')">Nombre <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().descripcion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('descripcion')">Descripción <span id="descripcion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().carga_horaria.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('carga_horaria')">Carga Horaria <span id="carga_horaria" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().periodicidad.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('contacto')">Periodicidad <span id="periodicidad" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().duracion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('duracion')">Duración <span id="duracion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().activo.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('activo')">Activo <span id="activo" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #carreras *ngFor="let carrera of carreras; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{carrera.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombre.show">
                    {{carrera.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().descripcion.show">
                    {{carrera.descripcion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().carga_horaria.show">
                  {{carrera.carga_horaria}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().periodicidad.show">
                    {{carrera.periodicidad.nombre}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().duracion.show">
                  {{carrera.duracion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().activo.show">
                  <span *ngIf="carrera.activo" class="badge badge-sm badge-success">SÍ</span>
                  <span *ngIf="!carrera.activo" class="badge badge-sm badge-danger">NO</span>
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCarrera(carrera)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a title="Lista Materias" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirListaMaterias(carrera)">
                      <i class="fa fa-list-alt bigger-130"></i>
                    </a> 
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(carrera)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="carreras.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #registro_carrera_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Carrera</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarCarrera()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input type="text" id="codigo" name="codigo" [(ngModel)]="carrera.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="nombre" type="text" [(ngModel)]="carrera.nombre" placeholder="Nombre" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="descripcion" [(ngModel)]="carrera.descripcion" placeholder="descripcion" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Carga Horaria</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="number" step="0" name="carga_horaria" [(ngModel)]="carrera.carga_horaria" placeholder="Carga horaria" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Periodicidad</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" name="periodicidad" class="form-control" [(ngModel)]="carrera.periodicidad">
                <option [ngValue]="periodicidad" *ngFor="let periodicidad of periodicidades">{{periodicidad.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Duración</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="duracion" [(ngModel)]="carrera.duracion" placeholder="Duración" class="form-control"/>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #lista_materias_modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Materias: {{carrera.nombre}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-warning" type="button" (click)="crearNuevaMateria()">
          <i class="fa fa-plus red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarListaMaterias()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
  </div>
  <div class="modal-body card-body">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Código</th>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Horas</th>
                    <th>Periodo</th>
                    <th>Pre requisito</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let materia of materias; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{materia.codigo}}</td>
                    <td>{{materia.nombre}}</td>
                    <td>{{materia.descripcion}}</td>
                    <td>{{materia.horas}}</td>
                    <td>{{materia.periodo.nombre}}</td>
                    <td>{{materia.pre_requisito?materia.pre_requisito.nombre:''}}</td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarMateria(materia)">
                              <i class="fa fa-pencil-alt bigger-260 red2"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionMateria(materia)">
                              <i class="fa fa-trash-alt"></i>
                            </a>                            
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</ng-template>

<ng-template #registro_materia_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Materia</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarMateria()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input type="text" id="codigo" name="codigo" [(ngModel)]="materia.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="nombre" type="text" [(ngModel)]="materia.nombre" placeholder="Nombre" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" name="descripcion" [(ngModel)]="materia.descripcion" placeholder="descripcion" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Horas</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="number" step="0" name="horas" [(ngModel)]="materia.horas" placeholder="Horas" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Periodo</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" name="periodo" class="form-control" [(ngModel)]="materia.periodo">
                <option [ngValue]="periodo" *ngFor="let periodo of periodos">{{periodo.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Pre Requisito</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" name="pre_requisito" class="form-control" [(ngModel)]="materia.pre_requisito">
              <option [ngValue]="pre_requisito" *ngFor="let pre_requisito of materias_pre_requisito">{{pre_requisito.nombre}}</option>
          </select>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #lista_periodos_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de periodos</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="crearNuevoPeriodo()" class="btn btn-primary"  >
            <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
      <table class="table table-striped table-bordered table-hover">
          <thead>
              <tr>
                  <th>Nº</th>
                  <th>Nombre</th>
                  <th>Hora Inicial</th>
                  <th>Hora Final</th>
                  <th>Numero Orden</th>
                  <th>Descripcion</th>
                  <th style="text-align: center; width: 140px; " data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let periodo of periodos_clase; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{periodo.nombre}}</td>
                  <td>{{periodo.hora_inicio+":"+periodo.minuto_inicio}}</td>
                  <td>{{periodo.hora_fin+":"+periodo.minuto_fin}}</td>
                  <td>{{periodo.numero_orden}}</td>
                  <td>{{periodo.descripcion}}</td>
                  <td style="text-align: center; width: 140px; ">
                      <div class="action-buttons">        
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarPeriodo(periodo)">          
                          <i class="fa fa-pencil-alt text-105"></i>        
                        </a>       
                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionPeriodo(periodo)">          
                          <i class="fa fa-trash-alt text-105"></i>        
                        </a>      
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>  
  </div>
</ng-template>

<ng-template #registro_periodo_modal let-modal>
<div class="modal-header card-header">
  <div class="col-md-8 col-xs-8">
      <span class="text-blue text-125">Registro Periodo</span>
  </div>
  <div class="col-md-6 col-xs-6">
      <button (click)="guardarPeriodo()" class="btn btn-primary"  >
          <i class="fa fa-save text-110 mr-1"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.close('')">
          <i class="fa fa-times red2 text-110 mr-1"></i>
      </button>
  </div>
</div>
<div class="modal-body card-body">
  <div class="row">
      <div class="col-sm-12 col-md-2">
          <label>Nombre</label>
      </div>
      <div class="col-sm-12 col-md-4">
          <input required type="text" name="nombre" [(ngModel)]="periodo.nombre" placeholder="Nombre" class="form-control"/>
      </div>
      <div class="col-sm-12 col-md-3">
          <label>Número Orden</label>
      </div>
      <div class="col-sm-12 col-md-3">
          <input required type="number" name="numero_orden" [(ngModel)]="periodo.numero_orden" placeholder="Número Orden" class="form-control"/>
      </div>
  </div>
  <hr>
  <div class="row">
      <div class="col-sm-6 col-md-6">
          <div class="row">
              <div class="col-sm-6 col-md-6">
                  <label class="text-success-d1">Hora Inicio</label>
              </div>
              <div class="col-sm-6 col-md-6">
                  <label class="text-success-d1">Minuto Inicio</label>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-6 col-md-6">
                  <input name="hora_inicio" required type="number"[(ngModel)]="periodo.hora_inicio" placeholder="Hora Inicio" class="form-control" />
              </div>
              <div class="col-sm-6 col-md-6">
                  <input name="minuto_inicio" required type="number"[(ngModel)]="periodo.minuto_inicio" placeholder="Minuto Inicio" class="form-control" />
              </div>
          </div>
      </div>
      <div class="col-sm-6 col-md-6">
          <div class="row">
              <div class="col-sm-6 col-md-6">
                  <label class="text-success-d1">Hora Fin</label>
              </div>
              <div class="col-sm-6 col-md-6">
                  <label class="text-success-d1">Minuto Fin</label>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-6 col-md-6">
                  <input name="hora_fin" required type="number"[(ngModel)]="periodo.hora_fin" placeholder="Hora Fin" class="form-control" />
              </div>
              <div class="col-sm-6 col-md-6">
                  <input name="minuto_fin" required type="number"[(ngModel)]="periodo.minuto_fin" placeholder="Minuto Fin" class="form-control" />
              </div>
          </div>
      </div>
      
  </div>
  <hr>
  <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>Descripción</label>
      </div>
      <div class="col-sm-12 col-md-9">
          <input name="descripcion" type="text" [(ngModel)]="periodo.descripcion" placeholder="Descripción" class="form-control"/>
      </div>
  </div>
  <hr>
  <div class="row">
      <div class="col-sm-12 col-md-3">
          <label>Días</label>
      </div>
      <div class="col-sm-12 col-md-9">
          <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
              <ngx-bootstrap-multiselect *ngIf="dias" [options]="dias" [(ngModel)]="periodo.dias"></ngx-bootstrap-multiselect>
          </div>
      </div>
  </div>
</div>
</ng-template>