<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Lectura Vecino</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button [disabled]="lecturas_medidor.length==0" (click)="guardarDatosLecturacion()" class="btn btn-primary" >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-2">
            <label for="form-field-select-1">Código Vecino: {{vecino.cliente.codigo}}</label>
        </div>
        <div class="col-3">
            <label for="form-field-select-1">Vecino: {{vecino.cliente.persona.apellido_paterno}} {{vecino.cliente.persona.apellido_materno}} {{vecino.cliente.persona.nombres}}</label>
        </div>
        <div class="col-3">
            <label for="form-field-select-1">Acción agua</label>
            <select *ngIf="vecino" [compareWith]="compararObjectos" class="form-control" required name="accion_agua" [(ngModel)]="filter.accion_agua" >
                <option [ngValue]="accion_agua" *ngFor="let accion_agua of filter.acciones_agua">{{accion_agua.codigo_medidor+' - '+accion_agua.categoria.nombre}}</option>
            </select>
        </div>
        <div class="col-3">
            <label for="form-field-select-11">Gestión</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="periodo_registro" [(ngModel)]="filter.gestion">
                <option [ngValue]="periodo" *ngFor="let periodo of filter.gestiones">{{periodo.nombre}}</option>
            </select>
        </div>
        <div class="col-1 col-xs-1">
            <button [disabled]="!filter.gestion" class="btn btn-primary" type="button" (click)="buscarLecturasMedidor()">
                <i class="ace-icon fa fa-search red2 align-top bigger-110"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
            <thead fix-head class="table-head-color sticky-nav">
                <tr>
                    <th>Nº</th>
                    <th>Mes</th>
                    <th>Lect. Ant.</th>
                    <th>Lect. Act.</th>
                    <th>Consumo</th>
                    <th>Observación</th>
                    <th>Datos de pago</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let lectura_medidor of lecturas_medidor; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{lectura_medidor.mes.nombre}}</td>
                    <td>{{lectura_medidor.lectura_anterior}}</td>
                    <td class="input-cal">
                        <input id="sc{{i}}" (keyup)="calcularConsumo(lectura_medidor)" (change)="actualizarUltimaLecturaSiguiente(lectura_medidor)" (keydown)="enfocarLectura($event,i)" name="lecact" class="form-control text-center" type="number" [(ngModel)]="lectura_medidor.lectura_actual" step="0" min="0" (focus)="sincronizarLecturaAnterior(lectura_medidor)">
                    </td>
                    <td>{{lectura_medidor.consumo_cubos}}</td>
                    <td>
                        <input (focus)="establecerlecturaMedidor(lectura_medidor)" (change)="guardarLecturaMedidorRegistro()" name="lecact" class="form-control text-center" type="text" [(ngModel)]="lectura_medidor.observacion">
                    </td>
                    <td>
                        <a *ngIf="lectura_medidor.id_detalle_venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(lectura_medidor.detalle_venta.id_venta)">
                            <i class="fa fa-print bigger-260 red2"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>