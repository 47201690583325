<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Asuntos Administrativos</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover ">
          <thead class="bgc-primary-d1 text-white sticky-nav">
              <tr class="sticky-nav">
                  <th>Nº</th>
                  <th *ngIf="usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto">Código</th>
                  <th *ngIf="usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto">Estudiante</th>
                  <th>Asunto</th>
                  <th>Descripción</th>
                  <th>Estado</th>
                  <th>Acciones</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let asunto_administrativo of asuntos_administrativos; let i=index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto">{{asunto_administrativo.estudiante.codigo}}</td>
                  <td *ngIf="usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto">{{asunto_administrativo.estudiante.persona.nombre_completo}}</td>
                  <td>{{asunto_administrativo.asunto}}</td>
                  <td>{{asunto_administrativo.descripcion}}</td>
                  <td><span class="badge badge-sm" [ngClass]="{'badge-danger':asunto_administrativo.estado.nombre_corto=='OBSERVADO','badge-success':asunto_administrativo.estado.nombre_corto=='ATENDIDO','badge-warning':asunto_administrativo.estado.nombre_corto=='PORCOMPLETAR'}">{{asunto_administrativo.estado.nombre}}</span></td>
                  <td>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoAsuntoAdministrativo(asunto_administrativo)">
                        <i class="fa fa-exchange-alt"></i>
                    </a>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>

  <ng-template #modal_cambio_estado let-modal>
    <div class="modal-header">
      <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Cambio de estado</h4>
      </div>
      <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="cambiarEstado()">
          <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
          <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div class="card card-info card-outline">
        <div class="box box-primary">
          <div class="box-body">
            <div class="row">
                <div class="col-12">
                  <label>Estado </label>
                  <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="asunto_administrativo.estado">
                    <option [ngValue]="estado" *ngFor="let estado of estados_cambio">{{estado.nombre}}</option>
                  </select>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>