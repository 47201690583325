<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Inventario
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Externo
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Inventario </span>
      Externo
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="feini">Desde </label>&nbsp;
                            <input required id="feini" name="feini" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="fefin">Hasta </label>&nbsp;
                            <input required id="fefin" name="fefin" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Usuario </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                        </select>
                    </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
                <div class="columns columns-right btn-group float-right">
                    <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoInventarioExterno()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                        <i class="fa fa-plus text-purple-d1"></i>
                    </button>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="inventarios_externos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th class="detail" rowspan="1">
                        <div class="th-inner ">Nº</div>
                    </th>
                    <th>Sucursal</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Fotografía</th>
                    <th>Usuario</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr #inventario_externo_lectura *ngFor="let inventario_externo of inventarios_externos; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{inventario_externo.cliente.sucursal.nombre}}</td>
                    <td>{{inventario_externo.cliente.identificacion}}</td>
                    <td>{{inventario_externo.fecha_inventario | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td>
                        <div class="imagen-inv-ext-vista clearfix">
                            <a href="{{rest_server+inventario_externo.fotografia}}" title="{{rest_server+inventario_externo.fotografia}}" data-rel="colorbox">
                                <img width="50" height="50" alt="sin imagen" src="{{rest_server+inventario_externo.fotografia}}" />
                            </a>
                        </div>
                    </td>
                    <td>{{inventario_externo.usuario.persona.nombre_completo}}</td>
                    <td *ngIf="!inventario_externo.eliminado"><span class="badge btn-success">Activo</span></td>
                    <td *ngIf="inventario_externo.eliminado"><span class="badge btn-danger">Anulado</span></td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verInvExterno(inventario_externo)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarInventarioExterno(inventario_externo)">          
                                <i class="fa fa-pencil-alt text-105"></i>        
                            </a>
                            <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(inventario_externo)">          
                                <i class="fa fa-trash-alt text-105"></i>        
                            </a>  
                        </div>

                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a class="dropdown-item" href="javascript:void(0)" (click)="verInvExterno(inventario_externo)">
                                  <i class="fa fa-search-plus text-blue-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="modificarInventarioExterno(inventario_externo)">
                                <i class="fa fa-pencil-alt text-green-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Modificar
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(inventario_externo)">
                                <i class="fa fa-trash-alt text-danger-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                            </a>
                            </div>
                          </div>
                        </div>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="inventarios_externos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #vista_inventario_externo let-modal>
	<div class="modal-header">
		<div class="col-6 col-6">
			<h4 class="widget-title">Datos Inventario Externo</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="row p-1">
            <div class="col-12 col-md-8">
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 alert alert-info">
                                <label class="text-primary">
                                    <i class="fas fa-money-bill-alt"></i> Sucursal : 
                                </label>    
                            </div>
                            <div class="col-6">
                                <label>
                                    {{inventario_externo.cliente.sucursal.nombre}}
                                </label>    
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 alert alert-info">
                                <label class="text-primary">
                                    <i class="fa fa-user red2 text-110"></i> Cliente : 
                                </label>    
                            </div>
                            <div class="col-6">
                                <label>
                                    {{inventario_externo.cliente.identificacion}}
                                </label>    
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 alert alert-info ">  
                                <label class="text-primary">
                                    <i class="fa fa-calendar red2 text-110"></i> Fecha  : 
                                </label>  
                            </div>
                            <div class="col-6">
                                <label>
                                    {{inventario_externo.fecha_inventario | date:"dd/MM/yyyy"}}
                                </label>    
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 alert alert-info">  
                                <label class="text-primary">
                                    <i class="fa fa-user red2 text-110"></i> Usuario  : 
                                </label>  
                            </div>
                            <div class="col-6">
                                <label>
                                    {{inventario_externo.usuario.persona.nombre_completo}}
                                </label>    
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="profile-info-row">
                            <div class="imagen-inv-ext-vista clearfix">
                                <a href="{{rest_server+inventario_externo.fotografia}}" title="{{rest_server+inventario_externo.fotografia}}" data-rel="colorbox">
                                    <img width="200" height="200" alt="sin imagen" src="{{rest_server+inventario_externo.fotografia}}" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-fixed-header" *ngIf="inventario_externo.detalles_inventario_externo.length>0">
                        <h5 class="text-blue-d2 mb-3">Detalle Inventario Externo</h5>
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <label>Código</label>
                                    </th>
                                    <th>
                                        <label>Producto</label>
                                    </th>
                                    <th>
                                        <label>Cantidad</label>
                                    </th>
                                    <th>
                                        <label>Fecha Producción</label>
                                    </th>
                                    <th>
                                        <label>Observación</label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let detalle_inv_exte of inventario_externo.detalles_inventario_externo">
                                    <td>
                                        {{detalle_inv_exte.producto?detalle_inv_exte.producto.codigo:""}}
                                    </td>
                                    <td>
                                        {{detalle_inv_exte.producto?detalle_inv_exte.producto.nombre:""}}
                                    </td>
                                    <td>
                                        {{detalle_inv_exte.cantidad}}
                                    </td>
                                    <td>
                                        {{detalle_inv_exte.fecha_produccion | date:"dd/MM/yyyy"}}
                                    </td>
                                    <td>
                                        {{detalle_inv_exte.observacion}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-fixed-header" *ngIf="inventario_externo.detalles_analisis.length>0">
                        <h5 class="text-blue-d2 mb-3">Detalle Analisis</h5>
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <label>Marca</label>
                                    </th>
                                    <th>
                                        <label>Nivel</label>
                                    </th>
                                    <th>
                                        <label>Peso</label>
                                    </th>
                                    <th>
                                        <label>Precio Unitario</label>
                                    </th>
                                    <th>
                                        <label>Observación</label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let detalle_analisis of inventario_externo.detalles_analisis">
                                    <td>
                                        {{detalle_analisis.nivel}}
                                    </td>
                                    <td>
                                        {{detalle_analisis.marca}}
                                    </td>
                                    <td>
                                        {{detalle_analisis.peso}}
                                    </td>
                                    <td>
                                        {{detalle_analisis.precio_unitario}}
                                    </td>
                                    <td>
                                        {{detalle_analisis.observacion}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div *ngIf="inventario_externo">
                    <google-map #mapa 
                        width="100%"
                        height="450px"
                        [center]="center"
                        [zoom]="zoom"
                        [options]="options">
                        <map-marker
                            #markerElem
                            *ngFor="let marker of markers"
                            [position]="marker.position"
                            [label]="marker.label"
                            [title]="marker.title"
                            [options]="marker.options"
                            (mapClick)="clickMarcador(marker)"
                            >
                        </map-marker>
                    </google-map>
                </div>
            </div>
	    </div>
    </div>
</ng-template>

<ng-template #registro_inventario_externo let-modal>
	<div class="modal-header">
		<div class="col-10">
			<h4 class="widget-title">Registro Inventario Externo</h4>
		</div>
		<div class="col-2">
            <button (click)="guardarInventarioExterno()" class="btn btn-primary">
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
		<div class="row p-1">
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-4 alert alert-info">
                                <label class="text-primary">
                                    <i class="fas fa-money-bill-alt"></i> Sucursal : 
                                </label>    
                            </div>
                            <div class="col-8">
                                <select [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="inventario_externo.sucursal">
                                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales_registro">{{sucursal.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 alert alert-info">
                                <label class="text-primary">
                                    <i class="fa fa-user red2 text-110"></i> Cliente : 
                                </label>    
                            </div>
                            <div class="col-8">
                                <input id="razon_social" name="razon_social" [disabled]="inventario_externo.cliente.id"
                                    [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                    type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                                    [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" autocomplete="off"
                                    />
                                <ng-template #rt let-r="result" let-t="term">
                                    <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                </ng-template>
                                <a href="javascript:void(0)" *ngIf="inventario_externo.cliente && inventario_externo.cliente.id" (click)="iniciarCliente()" class="card-toolbar-btn text-danger-m1">
                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 alert alert-info ">  
                                <label class="text-primary">
                                    <i class="fa fa-calendar red2 text-110"></i> Fecha  : 
                                </label>  
                            </div>
                            <div class="col-8">
                                <div class="input-group">
                                    <input id="fefin" name="feinv" class="form-control" placeholder="dd/mm/yyyy"
                                            name="dp" [(ngModel)]="inventario_externo.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="profile-info-row">
                            <div class="imagen-inv-ext-vista clearfix">
                                <webcam width="200" *ngIf="!inventario_externo.fotografia" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
                                <a *ngIf="inventario_externo.fotografia" href="{{rest_server+inventario_externo.fotografia}}" title="{{rest_server+inventario_externo.fotografia}}" data-rel="colorbox">
                                    <img width="200" height="200" alt="sin imagen" src="{{rest_server+inventario_externo.fotografia}}" />
                                </a>
                                <a (click)="capturarImagen()" class="btn radius-1 btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                                    <i class="fa fa-camera bigger-110 red2"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-fixed-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="text-blue-d2 mb-3">Producto</h5>
                            </div>
                            <div class="col-6">
                                <button id="agregar_detalle_venta" type="button" (click)="crearProductoInventarioExterno()" class="btn btn-success btn-sm">
                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-fixed-header" *ngIf="inventario_externo.detalles_inventario_externo.length>0">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <label>Producto</label>
                                    </th>
                                    <th>
                                        <label>Cantidad</label>
                                    </th>
                                    <th>
                                        <label>Fecha Producción</label>
                                    </th>
                                    <th>
                                        <label>Observación</label>
                                    </th>
                                    <th>
                                        <label>Acc.</label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let detalle_inv_exte of inventario_externo.detalles_inventario_externo">
                                    <tr *ngIf="!detalle_inv_exte.eliminado">
                                        <td>
                                            {{detalle_inv_exte.producto.nombre}}
                                        </td>
                                        <td>
                                            {{detalle_inv_exte.cantidad}}
                                        </td>
                                        <td>
                                            {{detalle_inv_exte.fecha_produccion | date:"dd/MM/yyyy"}}
                                        </td>
                                        <td>
                                            {{detalle_inv_exte.observacion}}
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="eliminarProductoInventarioExterno(detalle_inv_exte)" class="card-toolbar-btn text-danger-m1">
                                                <i class="ace-icon fa fa-trash bigger-130"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="table-fixed-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="text-blue-d2 mb-3">Detalle Análisis</h5>
                            </div>
                            <div class="col-6">
                                <button type="button" (click)="crearDetalleAnalisis()" class="btn btn-success btn-sm">
                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-fixed-header" *ngIf="inventario_externo.detalles_analisis.length>0">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <label>Marca</label>
                                    </th>
                                    <th>
                                        <label>Nivel</label>
                                    </th>
                                    <th>
                                        <label>Peso</label>
                                    </th>
                                    <th>
                                        <label>Precio Unitario</label>
                                    </th>
                                    <th>
                                        <label>Observación</label>
                                    </th>
                                    <th>
                                        <label>Acc.</label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let detalle_analisis of inventario_externo.detalles_analisis">
                                    <tr *ngIf="!detalle_analisis.eliminado">
                                        <td>
                                            {{detalle_analisis.nivel}}
                                        </td>
                                        <td>
                                            {{detalle_analisis.marca}}
                                        </td>
                                        <td>
                                            {{detalle_analisis.peso}}
                                        </td>
                                        <td>
                                            {{detalle_analisis.precio_unitario}}
                                        </td>
                                        <td>
                                            {{detalle_analisis.observacion}}
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="eliminarDetalleAnalisis(detalle_analisis)" class="card-toolbar-btn text-danger-m1">
                                                <i class="ace-icon fa fa-trash bigger-130"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
	    </div>
    </div>
</ng-template>

<ng-template #registro_producto_inventario_externo let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Prodcuto Inv. externo</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="agregarProductoInventarioExterno()" class="btn btn-primary">
                <i class="fa fa-plus text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Producto</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input  autocomplete="off" [(ngModel)]="producto_busqueda" placeholder="Buscar producto" type="text" class="form-control"
                [ngbTypeahead]="buscarProducto"  (selectItem)="establecerProducto($event)" [resultTemplate]="rtp" [inputFormatter]="formatterProducto"
                [disabled]="(producto_inventario_externo.producto && producto_inventario_externo.producto.id)" />

                <ng-template #rtp let-r="result" let-t="term">
                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                </ng-template>

                <a href="javascript:void(0)" *ngIf="producto_inventario_externo.producto && producto_inventario_externo.producto.id" (click)="iniciarProductoInventarioExterno()" class="card-toolbar-btn text-danger-m1">
                    <i class="ace-icon fa fa-trash bigger-130"></i>
                </a>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Cantidad</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="number" name="cantidad" [(ngModel)]="producto_inventario_externo.cantidad" placeholder="cantidad" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Fecha Producción</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <div class="input-group">
                    <input name="fecha_produccion" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="producto_inventario_externo.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button  class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div> 
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Observación</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="text" name="observacion" [(ngModel)]="producto_inventario_externo.observacion" placeholder="Observación" class="form-control"/>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #registro_detalle_analisis let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Detalle Análisis</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="agregarDetalleAnalisis()" class="btn btn-primary">
                <i class="fa fa-plus text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Nivel</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="text" name="nivel" [(ngModel)]="detalle_analisis.nivel" placeholder="nivel" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Marca</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="text" name="marca" [(ngModel)]="detalle_analisis.marca" placeholder="marca" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Peso</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="text" name="peso" [(ngModel)]="detalle_analisis.peso" placeholder="peso" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Precio Unitario</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="number" name="precio_unitario" [(ngModel)]="detalle_analisis.precio_unitario" placeholder="precio unitario" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Observación</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="text" name="observacion" [(ngModel)]="detalle_analisis.observacion" placeholder="Observación" class="form-control"/>
            </div>
        </div>
    </div>
</ng-template>