import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CarrerasService } from '../../services/carreras/carreras.service';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-carreras',
  templateUrl: './carreras.component.html',
  styleUrls: ['./carreras.component.css']
})
export class CarrerasComponent extends BaseComponent implements OnInit {

  carreras:any[]=[];
  materias:any[]=[];
  materias_pre_requisito:any[]=[];
  periodicidades:any[];
  periodos:any[]=[];
  periodos_clase:any[]=[];
  carrera:any;
  materia:any;
  escuela:any;
  dias:any=[];
  periodo:any;

  registro_carrera_modal:NgbModalRef;
  @ViewChild('registro_carrera_modal')
  private registro_carrera_modal_ref: TemplateRef<any>;

  lista_materias_modal:NgbModalRef;
  @ViewChild('lista_materias_modal')
  private lista_materias_modal_ref: TemplateRef<any>;

  registro_materia_modal:NgbModalRef;
  @ViewChild('registro_materia_modal')
  private registro_materia_modal_ref: TemplateRef<any>;

  lista_periodos_modal:NgbModalRef;
  @ViewChild('lista_periodos_modal')
  private lista_periodos_modal_ref: TemplateRef<any>;

  registro_periodo_modal:NgbModalRef;
  @ViewChild('registro_periodo_modal')
  private registro_periodo_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public carrerasService:CarrerasService,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_CARRERAS);
		this.filter={
			escuela:null,
      periodicidad:{id:0,nombre:"TODOS"},
      periodicidades:[{id:0,nombre:"TODOS"}]
		}
    await this.obtenerEscuela();
    this.obtenerPeriodicidades();
    this.obtenerPeriodos();
    this.column = "nombre";
		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerPeriodicidades(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_PERIODICIDAD).subscribe((entidad:any)=>{
      this.filter.periodicidades=this.filter.periodicidades.concat(entidad.clases);
      this.periodicidades=entidad.clases;
			this.blockUI.stop();
    });
  }

  obtenerPeriodos(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_PERIODO_INSTITUTO).subscribe((entidad:any)=>{
      this.periodos=entidad.clases;
			this.blockUI.stop();
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.carrerasService.obtenerListaCarreras(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.carreras=dato.carreras;
			this.blockUI.stop();
		});
	}

  crearNuevaCarrera(){
    this.carrera={
      id_escuela:this.filter.escuela.id
    }
    this.abriRegistroCarrera();
  }

  modificarCarrera(carrera){
    this.carrera=carrera;
    this.abriRegistroCarrera();
  }

  abriRegistroCarrera(){
    this.registro_carrera_modal=this.modalService.open(this.registro_carrera_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_carrera_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  validarCarrera(){
    let res=true;
    if(!this.carrera.codigo){
      res=res && false;
      this.toastr.error("Debe especificar el código de la carrera!");
    }
    if(!this.carrera.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la carrera!");
    }
    if(!this.carrera.periodicidad){
      res=res && false;
      this.toastr.error("Debe especificar la periodicidad de la carrera!");
    }
    return res;
  }

  guardarCarrera(){
    this.blockUI.start();
    if(this.validarCarrera()){
			if(this.carrera.id){
				this.carrerasService.actualizarCarrera(this.carrera).subscribe((res:any) => {
					this.blockUI.stop();
          this.getItems();
					this.toastr.success(res.mensaje);
					this.registro_carrera_modal.close();
				});
			}else{
				this.carrerasService.guardarCarrera(this.carrera).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.getItems();
					this.registro_carrera_modal.close();
				});
			}
		}else{
			this.blockUI.stop();
		}
  }

  abrirPopupConfirmacionEliminacion(carrera){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar la carrera?";
		this.popupConfirmacion.componentInstance.data = carrera;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCarrera($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarCarrera(carrera){
    this.blockUI.start();
    this.carrerasService.eliminarCarrera(carrera).subscribe((res:any)=>{
      this.blockUI.stop();
      this.getItems();
      this.toastr.success(res.mensaje);
    });
  }

  abrirListaMaterias(carrera){
    this.carrera=carrera;
    this.obtenerMateriasCarrera();
    this.lista_materias_modal=this.modalService.open(this.lista_materias_modal_ref, {windowClass:'lista-materias', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.lista_materias_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cerrarListaMaterias(){
    this.lista_materias_modal.close();
  }

  obtenerMateriasCarrera(){
    this.carrerasService.obtenerMaterias(this.carrera.id).subscribe((materias:any[])=>{
      this.materias=materias;
      this.materias_pre_requisito=materias;
    });
  }

  crearNuevaMateria(){
    this.materia={
      id_carrera:this.carrera.id
    };
    this.abriRegistroMateria();
  }

  abriRegistroMateria(){
    this.registro_materia_modal=this.modalService.open(this.registro_materia_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_materia_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  modificarMateria(materia){
    this.blockUI.start();
    this.carrerasService.obtenerMateria(materia).subscribe((mat:any)=>{
      this.blockUI.stop();
      this.materia=mat;
      this.abriRegistroMateria();
    });
  }

  validarMateria(){
    let res=true;
    if(!this.materia.codigo){
      res=res && false;
      this.toastr.error("Debe especificar el código de la materia!");
    }
    if(!this.materia.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la materia!");
    }
    if(!this.materia.periodo){
      res=res && false;
      this.toastr.error("Debe especificar el periodo de la materia!");
    }
    return res;
  }

  guardarMateria(){
    this.blockUI.start();
    if(this.validarMateria()){
			if(this.materia.id){
				this.carrerasService.actualizarMateria(this.materia).subscribe((res:any) => {
					this.blockUI.stop();
          this.obtenerMateriasCarrera();
					this.toastr.success(res.mensaje);
					this.registro_materia_modal.close();
				});
			}else{
				this.carrerasService.guardarMateria(this.materia).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.obtenerMateriasCarrera();
					this.registro_materia_modal.close();
				});
			}
		}else{
			this.blockUI.stop();
		}
  }

  abrirPopupConfirmacionEliminacionMateria(materia){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar la materia?";
		this.popupConfirmacion.componentInstance.data = materia;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarMateria($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarMateria(materia){
    this.blockUI.start();
    this.carrerasService.eliminarMateria(materia).subscribe((res:any)=>{
      this.blockUI.stop();
      this.obtenerMateriasCarrera();
      this.toastr.success(res.mensaje);
    });
  }

  async verPeriodosClase(){
    this.generalService.obtenerClases("DIAS").subscribe((entidad:any)=>{
      this.llenarDias(entidad.clases);
    });
    await this.verListaPeriodos();
    this.abrirListaPeriodos();
  }

  async verListaPeriodos(){
    this.blockUI.start();
    let turnos:any=await this.carrerasService.obtenerListaPeriodosClase(this.usuario.id_empresa).toPromise();
    this.periodos_clase=turnos;
    this.blockUI.stop();
  }

  abrirListaPeriodos(){
    this.lista_periodos_modal=this.modalService.open(this.lista_periodos_modal_ref, {windowClass:'periodos-modal', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.lista_periodos_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  llenarDias(dias){
    this.dias=[];
    for(var i=0;i<dias.length;i++){
        var dia={
            name:dias[i].nombre,
            maker: "",
            ticked:false,
            id:dias[i].id
        }
        this.dias.push(dia);
    }
  }

  crearNuevoPeriodo(){
    this.periodo={
      id_empresa:this.usuario.id_empresa,
      dias:[]
    }
    this.abrirRegistroPeriodo();
  }

  abrirRegistroPeriodo(){
    this.registro_periodo_modal=this.modalService.open(this.registro_periodo_modal_ref, {windowClass:'registro-periodo-modal', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_periodo_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarPeriodo(){
    this.blockUI.start();
    if(this.validarRegistroPeriodo()){
      if(this.periodo.id){
        this.carrerasService.actualizarPeriodo(this.periodo).subscribe((res:any)=>{
          if(!res.tiene_error){
            this.toastr.success(res.mensaje);
            this.registro_periodo_modal.close();
            this.verListaPeriodos();
          }
          this.blockUI.stop();
        });
      }else{
        this.carrerasService.guardarPeriodo(this.periodo).subscribe((res:any)=>{
          if(!res.tiene_error){
            this.toastr.success(res.mensaje);
            this.registro_periodo_modal.close();
            this.verListaPeriodos();
          }
          this.blockUI.stop();
        });
      }
    }else{
      this.blockUI.stop();
    }
  }

  validarRegistroPeriodo(){
		let res=true;
    if(!this.periodo.nombre){
			this.toastr.error("Debe ingresar el nombre del periodo!");
			res=false;
		}
		if(!this.periodo.numero_orden){
			this.toastr.error("Debe ingresar el numero de orden del periodo!");
			res=false;
		}
		if(this.periodo.hora_inicio==null){
			this.toastr.error("Debe ingresar la hora de inicio del periodo!");
			res=false;
		}
    if(this.periodo.minuto_inicio==null){
			this.toastr.error("Debe ingresar el minuto de inicio del periodo!");
			res=false;
		}
    
    if(this.periodo.hora_fin==null){
			this.toastr.error("Debe ingresar la hora de finalización del periodo!");
			res=false;
		}
    if(this.periodo.minuto_fin==null){
			this.toastr.error("Debe ingresar el minuto de finalización del periodo!");
			res=false;
		}
    if(this.periodo.dias.length==0){
			this.toastr.error("Debe ingresar al menos un dia al periodo!");
			res=false;
		}
		return res;
	}

  modificarPeriodo(periodo){
    this.blockUI.start();
    this.carrerasService.obtenerPeriodo(periodo).subscribe((turno_consultado:any)=>{
      this.periodo=turno_consultado;
      this.periodo.dias=this.seleccionarDias(this.periodo.dias);
      this.blockUI.stop();
      this.abrirRegistroPeriodo();
    });
  }

  abrirPopupConfirmacionEliminacionPeriodo(periodo){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el periodo?";
		this.popupConfirmacion.componentInstance.data = periodo;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarPeriodo($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarPeriodo(periodo){
    this.blockUI.start();
    this.carrerasService.eliminarPeriodo(periodo).subscribe((res:any)=>{
      this.verListaPeriodos();
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  seleccionarDias(dias){
		var dias_seleccionados=[];
		for(var j=0;j<dias.length;j++){
			dias_seleccionados.push(dias[j].dia.id);
		}
		return dias_seleccionados;
  }

}
