import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InventariosService {

  constructor(private http: HttpClient) { }

  public obtenerInventariosProducto(id_producto,id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inventarios/producto/"+id_producto+"/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }
  
  public obtenerListaInventarios(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inventarios/empresa/"+paginator.filter.id_empresa+"/almacen/"+paginator.filter.almacen.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/grupo/"+paginator.filter.grupo.id+"/subgrupo/"+paginator.filter.subgrupo.id+"/codigo/"+((paginator.filter.codigo!=null && paginator.filter.codigo!="")?paginator.filter.codigo:"0"),GlobalVariable.httpOptions);
  }

  public actualizarInventario(inventario){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inventario/"+inventario.id,inventario,GlobalVariable.httpOptions);
  }

  public guardarListaInventarios(inventarios){
    return this.http.post(GlobalVariable.API_SERVER_URL+"inventarios",inventarios,GlobalVariable.httpOptions);
  }

  public reiniciarCantidadesInventario(dato){
    return this.http.post(GlobalVariable.API_SERVER_URL+"inventario-reinicializacion",dato,GlobalVariable.httpOptions);
  }

  public obtenerListaProductosInventarioMinimo(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"datos-inventario-minimo/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerListaVencimientoProductos(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vencimientos-productos/"+id_empresa,GlobalVariable.httpOptions);
  }

  public actualizarCantidadInventario(inventario){
    return this.http.put(GlobalVariable.API_SERVER_URL+"inventario/"+inventario.id,inventario,GlobalVariable.httpOptions);
  }

  public actualizarCantidadInventarioProducto(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"inventario-reinicializacion-productos",datos,GlobalVariable.httpOptions);
  }

  public obtenerKardexInventario(id_almacen,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"kardex-inventario/almacen/"+id_almacen+"/fecha-final/"+fecha_fin,GlobalVariable.httpOptions);
  }

  

  

  

  
}
