<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Pantalla
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Despacho
        </small>
      </h1>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="abrirPantallaDespacho(global_variable.Dictionary.TIPO_FUENTE_DESPACHO_COCINA)" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-desktop text-150"></i>
                </button> &nbsp;
                <button (click)="abrirPantallaDespacho(global_variable.Dictionary.TIPO_FUENTE_DESPACHO_BAR)" class="btn btn-xs btn-app btn-warning my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-desktop text-150"></i>
                  </button>                
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                      <i class="fa fa-wrench text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                        <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                          <div class="card bgc-primary brc-primary radius-0">
                            <div class="card-header">
                            <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                            </div>
                  
                            <div class="card-body p-0 bg-white">
                            <table class="table table-striped table-hover mb-0">
                              <tbody>
                                <tr *ngIf="aplicacion.puede_modificar">
                                  <td class="text-dark-m2">
                                  Configuración Vista
                                  </td>
                                  <td class="text-dark-m2">
                                    <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                          </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>


<ng-template #pantalla_despacho let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Pedidos Pendientes</h4>
		</div>
		<div class="col-md-6 col-xs-6">
            <button class="btn btn-primary" type="button" (click)="abrirPopupConfirmacionEntregaMultiple()">
				<i class="fa fa-paper-plane align-top bigger-110"></i>
            </button>&nbsp;
            <button class="btn btn-primary" type="button" (click)="cargarPedidos()">
				<i class="fa fa-sync align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
        <div class="row">
            <div style="height:320px;overflow-y:auto !important;" *ngFor="let pedido of pedidos" class="col-12 col-sm-6 col-md-4 col-lg-3 pos-rel d-style pl-3 pr-3 radius-1 shadow-sm overflow-hidden bgc-brown-m3 text-90">
                <a href="#" class="show-lightbox">
                <img alt="Gallery Image 1" src="assets/template/assets/image/gallery/thumb9.jpg" height="140" class="w-100 d-zoom-2 " data-size="1200x800" />
                </a>
                <div class="v-hover position-center h-100 w-100 bgc-dark-grad"></div>

                <div class="position-tl text-left opacity-1 mr-2px mb-2px">

                    <span>
                        <h2 *ngIf="pedido.cliente.id_empresa" style="margin-top:0px;margin-bottom:1px" class="header smaller lighter white" [ngClass]="{'badge-success':pedido.id_venta,'badge-warning':!pedido.id_venta}">Pedido:{{pedido.numero_pedido}}</h2>
                        <h2 *ngIf="!pedido.cliente.id_empresa" style="margin-top:0px;margin-bottom:1px" class="header smaller lighter white badge" [ngClass]="{'badge-danger':!pedido.cliente.id_empresa}">Pedido:{{pedido.numero_pedido}}</h2>
                    </span>
                    <br />
                    <span class="badge badge-warning arrowed-in">
                        {{usuario.empresa.nombre}}
                    </span>
                    <br />
                    <span class="badge badge-primary arrowed-in">
                        {{pedido.tiempo_ingreso | date:"HH:mm - dd/MM/yyyy"}}
                    </span>
                    <br />
                    <span class="badge badge-danger arrowed-in">
                        {{pedido.cliente.identificacion}}
                    </span>
                    <br />
                    <span class="badge badge-danger arrowed-in" *ngIf="pedido.cliente.telefono1">
                        {{pedido.cliente.telefono1}}
                    </span>
                    <span class="badge badge-success arrowed-in">
                        {{pedido.tipoDespacho.nombre}} <span *ngFor="let mesaPedido of pedido.mesasPedidoRestaurante">{{mesaPedido.mesa.numero}}</span>
                    </span>
                </div>
                <div class="position-tr text-left opacity-1 mr-2px mb-2px">
                    <a (click)="emitirSonido(pedido)" href="#" class="btn btn-white btn-h-yellow btn-a-yellow mt-1 mr-1 py-0 radius-1 border-0">
                        <i class="text-140 text-600 ace-icon fas fa-volume-up bigger-130"></i>
                    </a>
                    <a (click)="entregarPedido(pedido)" href="#" class="btn btn-white btn-h-yellow btn-a-yellow mt-1 mr-1 py-0 radius-1 border-0">
                        <i class="text-140 text-600 ace-icon fas fa-paper-plane bigger-130"></i>
                    </a>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped" >
                            <thead>
                                    <tr>
                                        <th>
                                            <label>#</label>
                                        </th>
                                        <th>
                                            <label>Cant.</label>
                                        </th>
                                        <th>
                                            <label>Producto <span class="badge badge-danger">{{pedido.numero_productos}}</span></label>
                                        </th>
                                        <th>
                                            <label>Acc.</label>
                                        </th>
                                    </tr>
                            </thead>
                            <tbody *ngFor="let detallePedido of pedido.detallesPedido; let i=index;">
                                <ng-container *ngIf="detallePedido.producto.fuente_despacho.nombre_corto==filter.tipo_fuente_despacho">
                                    <tr>
                                        <td rowspan="{{1+pedido.detallesPedido.length}}">
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{detallePedido.cantidad}}
                                        </td>
                                        <td>
                                            {{detallePedido.producto.nombre}}
                                            <span *ngIf="detallePedido.descripcion">({{detallePedido.descripcion}})</span>
                                        </td>
                                        <td>
                                            <div>
                                                <a  class="blue" href="javascript:void(0)" (click)="agregarDescripcionDetallePedido(detallePedido)">
                                                    <i class="ace-icon fa fa-edit bigger-130"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let detallePedidoExtra of detallePedido.detallesPedido">
                                        <td>
                                            {{detallePedidoExtra.cantidad}}
                                        </td>
                                        <td>
                                            {{detallePedidoExtra.producto.nombre}}
                                            <span *ngIf="detallePedidoExtra.descripcion">({{detallePedidoExtra.descripcion}})</span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
	</div>
</ng-template>

<ng-template #descripcionitem let-modal>
    <div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Agregar Descripción</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="!detallePedidoDescripcion.descripcion" (click)="agregarDetallePedidoDescripcion()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="row">
                        <div class="col-12">
                            <label>Descripcion/Observacion/Comentario</label>
                            <input class="form-control" [(ngModel)]="detallePedidoDescripcion.descripcion" type="text" placeholder="descripcion" />
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</ng-template>

<ng-template #configuracion_aplicacion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Configuración Aplicación</h4>
		</div>
		<div class="col-md-6 col-xs-6">
      <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
        <div class="row">
            <div class="col-3">
                <label>¿Despachar sin cobrar?</label>
            </div>
            <div class="col-3">
            <label>
                <input name="despachar_sin_cobrar" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.despachar_sin_cobrar" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
            </label>
            </div>
            <div class="col-3">
              <label>¿Emitir Sonido al recepcionar Pedido?</label>
          </div>
          <div class="col-3">
          <label>
              <input name="despachar_sin_cobrar" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.emitir_sonido_recepcion" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
          </label>
          </div>
        </div>
    </div>
</ng-template>