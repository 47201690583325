<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Lista de Pagos de Ventas</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row" *ngIf="!venta">
        <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="feini">Desde </label>&nbsp;
                <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="input-group">
                <label for="fefin">Hasta </label>&nbsp;
                <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>  
         </div>
         <div class="col-md-4 col-sm-12">
            <div class="input-group">
                <label for="fefin">Cliente </label>&nbsp;
                <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
            </div>  
         </div>
        <div class="col-md-2 col-xs-3 col-sm-3">
            <button (click)="getItems()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
            </button>
        </div>
    </div>
    <div class="row p-1" *ngIf="venta">
        <div class="col-md-7">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Transacción : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.transaccion.nombre?venta.transaccion.nombre:venta.transaccion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.cliente?venta.cliente:venta.cliente.identificacion}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Num. Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{venta.factura}}
                    </label>    
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Plazo (Días) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.total}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.a_cuenta}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{venta.saldo}} 
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Número Doc.</th>
                    <th>Cliente</th>
                    <th>A Cuenta Anterior</th>
                    <th>Saldo Anterior</th>
                    <th>Importe Pagado</th>
                    <th>Saldo a la Fecha</th>							
                    <th>Obs.</th>							
                    <th>Usuario</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pago_venta of lista_pagos_ventas; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{pago_venta.fecha | date:"dd/MM/yyyy"}}</td>
                    <td>{{pago_venta.numero_documento}}</td>
                    <td>{{pago_venta.venta.cliente.razon_social}}</td>
                    <td>{{pago_venta.a_cuenta_anterior}}</td>
                    <td>{{pago_venta.saldo_anterior}}</td>
                    <td><i class="eva eva-paper-plane-outline text-160 radius-round bgc-success-l4 text-success p-2 border-2 brc-success-m3">{{pago_venta.monto_pagado}}</i></td>
                    <td>{{pago_venta.saldo_anterior-pago_venta.monto_pagado}}</td>
                    <td>{{pago_venta.observacion}}</td>
                    <td>{{pago_venta.usuario.nombre_usuario}}</td>
                    <td *ngIf="pago_venta.activo"><span class="badge badge-sm badge-success">Activo</span></td>
                    <td *ngIf="!pago_venta.activo"><span class="badge badge-sm badge-danger">Anulado</span></td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirPagoVenta(pago_venta)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="pago_venta.activo && pago_venta.comprobante" title="comprobante" class="btn btn-md" [ngClass]="{'btn-purple':(pago_venta.comprobante.includes('.zip') || pago_venta.comprobante.includes('.rar')),'btn-danger':pago_venta.comprobante.includes('pdf'),'btn-success':(pago_venta.comprobante.includes('xls') || pago_venta.comprobante.includes('xlsx')),'btn-primary':(pago_venta.comprobante.includes('doc') || pago_venta.comprobante.includes('docx') || pago_venta.comprobante.includes('jpg') || pago_venta.comprobante.includes('png') || pago_venta.comprobante.includes('gif') || pago_venta.comprobante.includes('jpeg') || pago_venta.comprobante.includes('mp3') || pago_venta.comprobante.includes('mpeg') || pago_venta.comprobante.includes('mp4'))}" target="_blank" href="{{rest_server+pago_venta.comprobante}}">
                                <i class="fas" [ngClass]="{'fa-file-archive':(pago_venta.comprobante.includes('.zip') || pago_venta.comprobante.includes('.rar')),'fa-file-pdf':pago_venta.comprobante.includes('pdf'),'fa-file-excel':(pago_venta.comprobante.includes('xls') || pago_venta.comprobante.includes('xlsx')),'fa-file-word':(pago_venta.comprobante.includes('doc') || pago_venta.comprobante.includes('docx')),'fa-file-image':(pago_venta.comprobante.includes('jpg') || pago_venta.comprobante.includes('png') || pago_venta.comprobante.includes('gif') || pago_venta.comprobante.includes('jpeg')),'fa-file-audio':(pago_venta.comprobante.includes('mp3') || pago_venta.comprobante.includes('mpeg')),'fa-file-video':(pago_venta.comprobante.includes('mp4'))}"></i>
                            </a>
                            <a *ngIf="pago_venta.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pago_venta)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                            <a *ngIf="pago_venta.activo && usuario.empresa.id==6" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioCorreo(pago_venta)">
                                <i class="fa fa-envelope"></i>
                            </a>   
                            <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales && usuario.empresa.id==6" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionHabilitacionWhatsapp(pago_venta.venta.cliente)">
                                <i class="ace-icon fab fa-whatsapp bigger-130"></i>
                            </a>                          
                        </div>
                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                            <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                            <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                              <div class="dropdown-inner">
                                <a class="dropdown-item" href="javascript:void(0)" (click)="imprimirPagoVenta(pago_venta)">
                                    <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                                </a>
                                <a *ngIf="pago_venta.activo && pago_venta.comprobante" title="comprobante" class="dropdown-item" target="_blank" href="{{rest_server+pago_venta.comprobante}}">
                                    <i class="fas mr-1 bgc-white-tp1 radius-round p-2 w-4" [ngClass]="{'fa-file-archive text-purple':(pago_venta.comprobante.includes('.zip') || pago_venta.comprobante.includes('.rar')),'fa-file-pdf text-danger':pago_venta.comprobante.includes('pdf'),'fa-file-excel text-success':(pago_venta.comprobante.includes('xls') || pago_venta.comprobante.includes('xlsx')),'fa-file-word text-primary':(pago_venta.comprobante.includes('doc') || pago_venta.comprobante.includes('docx')),'fa-file-image':(pago_venta.comprobante.includes('jpg') || pago_venta.comprobante.includes('png') || pago_venta.comprobante.includes('gif') || pago_venta.comprobante.includes('jpeg')),'fa-file-audio text-primary':(pago_venta.comprobante.includes('mp3') || pago_venta.comprobante.includes('mpeg')),'fa-file-video':(pago_venta.comprobante.includes('mp4'))}"></i>Ver Archivo
                                </a>
                                <a *ngIf="pago_venta.activo" class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pago_venta)">
                                <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                                </a>
                                <a *ngIf="pago_venta.activo && usuario.empresa.id==6" class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioCorreo(pago_venta)">
                                    <i class="fa fa-envelope text-purple mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Enviar Correo
                                </a>   
                                <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales && usuario.empresa.id==6" class="dropdown-item" href="javascript:void(0)" (click)="abrirPopupConfirmacionHabilitacionWhatsapp(pago_venta.venta.cliente)">
                                    <i class="fab fa-whatsapp text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Enviar Whatsapp
                                </a>  
                              </div>
                            </div>
                          </div>
                    </td>
                </tr>
                <tr *ngIf="lista_pagos_ventas.length>0">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><b>TOTAL</b>: {{sumarImporte().toFixed(2) }}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>											
                </tr>
            </tbody>
        </table>
    </div>
</div>