import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CajasService } from '../../services/cajas/cajas.service';

@Component({
  selector: 'app-lista-cheques',
  templateUrl: './lista-cheques.component.html',
  styleUrls: ['./lista-cheques.component.css']
})
export class ListaChequesComponent extends BaseComponent implements OnInit {

  cheque:any;
  caja:any;
  cheques:any[]=[];

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  
  modal_registro_cheque:NgbModalRef;
  @ViewChild('modal_registro_cheque')
  private modal_registro_cheque_ref: TemplateRef<any>;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private cajasService:CajasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

    async ngOnInit(): Promise<void> {
      this.init();
        this.filter={
          id_caja:this.caja.id
        }
    
        this.column = "codigo";
        this.direction="asc";
        this.getSearch(this.text_search,null);
    }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
    this.cajasService.obtenerListaCheques(this).subscribe((dato:any) => {
      this.setPages(dato.paginas);
      this.cheques=dato.cheques;
      this.blockUI.stop();
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  async crearNuevoCheque(){
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CHEQUE);
    this.cheque={
      caja:this.caja,
      codigo:codigo_nuevo,
      inicio_correlativo:1,
      correlativo_actual:1
    };
    this.modal_registro_cheque=this.modalService.open(this.modal_registro_cheque_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
    this.modal_registro_cheque.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async guardarCheque(){
    if(this.validarRegistroCheque()){
      this.blockUI.start();
      if (this.cheque.id) {
        this.cajasService.actualizarCheque(this.cheque).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_registro_cheque.close();
          this.getItems();
        });
      } else {
        this.cheque.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CHEQUE):this.cheque.codigo;
        this.cajasService.guardarCheque(this.cheque).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CHEQUE);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_registro_cheque.close();
          this.getItems();
        });
      }
    }
  }

  validarRegistroCheque(){
    let res=true;
    if(!this.cheque.inicio_correlativo){
      res=res && false;
      this.toastr.error("Debe especificar el inicio del correlativo del cheque!");
    }
    if(!this.cheque.fin_correlativo){
      res=res && false;
      this.toastr.error("Debe especificar la finalizacion del correlativo del cheque!");
    }
    if(!this.cheque.correlativo_actual){
      res=res && false;
      this.toastr.error("Debe especificar el correlativo actual del cheque!");
    }
    return res;
  }

  abrirPopupConfirmacionEliminacion(cheque){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el cheque?";
		this.popupConfirmacion.componentInstance.data = cheque;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCheque($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
    eliminarCheque(cheque){
		this.blockUI.start();
		this.cajasService.eliminarCheque(cheque).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
			}
      this.blockUI.stop();
		});
	}

  async modificarCheque(cheque){
    this.cheque=cheque;
    this.modal_registro_cheque=this.modalService.open(this.modal_registro_cheque_ref, {ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
    this.modal_registro_cheque.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
