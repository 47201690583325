import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { InventariosService } from '../../services/inventarios/inventarios.service';
import { MovimientoInventarioService } from '../../services/movimiento-inventario/movimiento-inventario.service';
import { ProductosService } from '../../services/productos/productos.service';

declare const enfocarElemento:any;
declare const aplicarVisorImagenes:any;
declare const $:any;

@Component({
  selector: 'app-inventario-conversion',
  templateUrl: './inventario-conversion.component.html',
  styleUrls: ['./inventario-conversion.component.css']
})
export class InventarioConversionComponent extends BaseComponent implements OnInit {

  sucursal_fuente:any;
  almacen_fuente:any;
  producto_fuente:any;
  movimientos_fuente:any[]=[];
  movimiento_conversion:any={};

  movimiento_inventario_fuente:any={detallesMovimientoInventario:[]};
  movimiento_inventario_destino:any={detallesMovimientoInventario:[]};
  detalleMovimientoInventario:any={producto:{}};
  inventariosDisponibleProducto:any[];
  sucursales:any[];
  almacenes:any[];
  sucursalesDiferente:any[];
  almacenesDiferente:any[];
  converiones_movimiento_inventario:any[];
  sucursalesUsuario:any;
  conversion_movimiento_inventario:any={};
  sucursales_empresa:any;
  id_almacen_seleccionado:any=null;
  
  tipoEgreso:any;
  porcentaje:any;
  color:any;
  producto_busqueda:any;
  producto_fuente_busqueda:any;
  producto_destino_busqueda:any;
  movimiento_ingreso_tipo:any={};
  movimiento_salida_tipo:any={};
  searching:any;
  searchFailed:any;

  aplicacion_producto:any;

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  
  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public movimientoInventarioService:MovimientoInventarioService,
    private toastr: ToastrService,
    private productosService:ProductosService,
    public empresaService:EmpresaService,
    public inventariosService:InventariosService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    await this.obtenerSucursalesEmpresa();
    this.movimiento_conversion={
      id_empresa:this.usuario.id_empresa,
      id_usuario:this.usuario.id,
			detallesMovimientoInventario:[],
      clase:{},
      movimiento_destino:{}
    }
    this.movimiento_conversion.fecha=new Date();
    this.movimiento_conversion.fecha_texto={
			year: this.movimiento_conversion.fecha.getFullYear(), 
			month: (this.movimiento_conversion.fecha.getMonth()+1),
			day:this.movimiento_conversion.fecha.getDate()
		}
    this.movimiento_conversion.sucursal=this.sucursales[0];
    await this.obtenerAlmacenesSucursalesDiferente(this.movimiento_conversion.sucursal.id);
		this.obtenerAlmacenesActividades(this.sucursales[0].id);
    this.obtenerAccionesMovimientoInventario();

    this.obtenerMovimientos();
    this.aplicacion_producto= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS)[0];
  }

  obtenerAlmacenesActividades(idSucursal){
		this.obtenerAlmacenes(idSucursal);
		this.reiniciarDetalleMovimientoInventario();
  }

  async obtenerSucursalesEmpresa(){
    this.sucursales_empresa=await this.empresaService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
  }

  async obtenerAlmacenesSucursalesDiferente(idSucursalOrigen){
		this.movimiento_conversion.sucursalDestino=null;
		await this.obtenerAlmacenes(idSucursalOrigen);
		this.obtenerSucursalesDiferente(idSucursalOrigen);
		this.reiniciarDetalleMovimientoInventario();
  }
  
  async obtenerAlmacenes(idSucursal){
    let datos_sucursal:any=await this.empresaService.obtenerSucursal(idSucursal).toPromise();
    this.almacenes=datos_sucursal.almacenes;
    if(this.usuario.empresa.usar_almacenes_usuario){
      let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
      this.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
    }
    this.movimiento_conversion.almacen=(this.id_almacen_seleccionado?this.almacenes.filter(a => a.id==this.id_almacen_seleccionado)[0]:(this.almacenes.length>0?this.almacenes[0]:null));	
  }

  reiniciarDetalleMovimientoInventario(){
		this.producto_busqueda=null;
		if(this.detalleMovimientoInventario.producto && this.detalleMovimientoInventario.producto.id){
			this.enfocarElemento({which:13},"id_producto");
		}else if(this.detalleMovimientoInventario.combo && this.detalleMovimientoInventario.combo.id){
			this.enfocarElemento({which:13},"id_combo");
		}else{
			this.enfocarElemento({which:13},"id_descripcion");
		}
		this.detalleMovimientoInventario={
			producto:{activar_inventario:true},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
			fechaVencimientoTexto:this.movimiento_conversion.fecha_texto
		}
	}

  enfocarElemento(keyEvent,id_elemento){
		if (keyEvent.which === 13){
		  enfocarElemento(id_elemento);
		}
	  }

  obtenerSucursalesDiferente(idSucursal){
		var sucursalOrigen=this.sucursales_empresa.filter(e => e.id == idSucursal)[0];
		this.sucursalesDiferente=this.sucursales_empresa.filter(e => e.id != idSucursal);
		if(sucursalOrigen.almacenes.length>1){
			this.sucursalesDiferente.push(sucursalOrigen);
    }
    //this.almacenesDiferente=this.sucursalesDiferente.almacenes;
  }

  async obtenerAlmacenesDiferente(sucursal_destino){
	  if(sucursal_destino){
		var idSucursalDestino=sucursal_destino.id;
		let datos_sucursal:any=await this.empresaService.obtenerSucursal(idSucursalDestino).toPromise();
		this.almacenesDiferente=datos_sucursal.almacenes.filter(e => e.id!=this.movimiento_conversion.almacen.id);;
		this.movimiento_conversion.almacenDestino=this.almacenesDiferente.length>0?this.almacenesDiferente[0]:null;
		this.detalleMovimientoInventario={producto:{activar_inventario:true},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false}
		this.movimiento_conversion.detallesMovimientoInventario=[];
	  }
	}

  establecerAccion(){
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MIGRACION){
      let movimiento_salida_ajuste=this.movimiento_salida_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.EGRE_AJUSTE);
      this.movimiento_conversion.clase=movimiento_salida_ajuste.length>0?movimiento_salida_ajuste[0]:null;
      this.movimiento_conversion.tipo=this.movimiento_salida_tipo;
      let movimiento_ingreso_ajuste=this.movimiento_ingreso_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.ING_AJUSTE);
      this.movimiento_conversion.movimiento_destino.clase=movimiento_ingreso_ajuste.length>0?movimiento_ingreso_ajuste[0]:null;
      this.movimiento_conversion.movimiento_destino.tipo=this.movimiento_ingreso_tipo;
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION){
      let movimiento_ingreso_produccion=this.movimiento_ingreso_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.ING_PRODUCCION);
      this.movimiento_conversion.clase=movimiento_ingreso_produccion.length>0?movimiento_ingreso_produccion[0]:null;
      this.movimiento_conversion.tipo=this.movimiento_ingreso_tipo;
      let movimiento_salida_produccion=this.movimiento_salida_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.EGRE_PRODUCCION);
      this.movimiento_conversion.movimiento_destino.clase=movimiento_salida_produccion.length>0?movimiento_salida_produccion[0]:null;
      this.movimiento_conversion.movimiento_destino.tipo=this.movimiento_salida_tipo;
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO){
      let movimiento_salida_traspaso=this.movimiento_salida_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO);
      this.movimiento_conversion.clase=movimiento_salida_traspaso.length>0?movimiento_salida_traspaso[0]:null;
      this.movimiento_conversion.tipo=this.movimiento_salida_tipo;
      let movimiento_ingreso_traspaso=this.movimiento_ingreso_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.ING_TRASPASO);
      this.movimiento_conversion.movimiento_destino.clase=movimiento_ingreso_traspaso.length>0?movimiento_ingreso_traspaso[0]:null;
      this.movimiento_conversion.movimiento_destino.tipo=this.movimiento_ingreso_tipo;
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA){
      let movimiento_salida=this.movimiento_salida_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.EGRE_PUNTO_VENTA);
      this.movimiento_conversion.clase=movimiento_salida.length>0?movimiento_salida[0]:null;
      this.movimiento_conversion.tipo=this.movimiento_salida_tipo;
      let movimiento_ingreso=this.movimiento_ingreso_tipo.clases.filter(m => m.nombre_corto==GlobalVariable.Dictionary.ING_INV_INICIAL);
      this.movimiento_conversion.movimiento_destino.clase=movimiento_ingreso.length>0?movimiento_ingreso[0]:null;
      this.movimiento_conversion.movimiento_destino.tipo=this.movimiento_ingreso_tipo;
    }
  }

  validarRegistro(){
    let res=true;
    if(!this.conversion_movimiento_inventario.id){
		  res=res && false;
		  this.toastr.error("Debe especificar la accion a realizar!");
		}
    if(!this.movimiento_conversion.fecha_texto || !this.movimiento_conversion.fecha_texto.year || !this.movimiento_conversion.fecha_texto.month || !this.movimiento_conversion.fecha_texto.day){
		  res=res && false;
		  this.toastr.error("Debe especificar la fecha completa para realizar el movimiento!");
		}
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MIGRACION){
      if(!this.movimiento_conversion.producto_fuente){
        res=res && false;
        this.toastr.error("El producto fuente debe ser especificado!");
      }
      if(!this.movimiento_conversion.producto_destino){
        res=res && false;
        this.toastr.error("El producto destino debe ser especificado!");
      }
      if(!this.movimiento_conversion.cantidad || this.movimiento_conversion.cantidad<=0){
        res=res && false;
        this.toastr.error("La cantidad debe ser un número válido!");
      }
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION){
      if(!this.movimiento_conversion.producto_fuente){
        res=res && false;
        this.toastr.error("El producto a producir debe ser especificado!");
      }
      if(!this.movimiento_conversion.cantidad || this.movimiento_conversion.cantidad<=0){
        res=res && false;
        this.toastr.error("La cantidad debe ser un número válido!");
      }
      if(!this.movimiento_conversion.producto_fuente || this.movimiento_conversion.producto_fuente.productosBase.length==0){
        res=res && false;
        this.toastr.error("El producto a producir debe tener al menos un ingrediente en la receta!");
      }
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO){
      if(!this.movimiento_conversion.sucursalDestino || !this.movimiento_conversion.almacenDestino){
				res=res && false;
		  		this.toastr.error("Debe especificar la sucursal y el almacen de destino!");
			}
      if(this.movimiento_conversion.detallesMovimientoInventario.length==0){
        res=res && false;
        this.toastr.error("Debe agregar al menos un item al movimiento!");
      }
    }
    if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA){
      if(this.movimiento_conversion.detallesMovimientoInventario.length==0){
        res=res && false;
        this.toastr.error("Debe agregar al menos un item al movimiento!");
      }
      if(!this.movimiento_conversion.producto_destino){
        res=res && false;
        this.toastr.error("El producto destino debe ser especificado!");
      }
      if(!this.movimiento_conversion.cantidad || this.movimiento_conversion.cantidad<=0){
        res=res && false;
        this.toastr.error("La cantidad debe ser un número válido!");
      }
    }
    return res;
  }

  guardarConversion(){
    if(this.validarRegistro()){
      this.blockUI.start();
      let movimiento_inventario;
      if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MIGRACION){
        movimiento_inventario=this.generarMovimientoAccionMigrar();
      }
      if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION){
        movimiento_inventario=this.generarMovimientoAccionProducir();
      }
      if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO){
        movimiento_inventario=this.generarMovimientoAccionTraspasar();
      }
      if(this.conversion_movimiento_inventario.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA){
        movimiento_inventario=this.generarMovimientoAccionMezclar();
      }
      this.movimientoInventarioService.guardarMovimientoInventario(movimiento_inventario).subscribe((res:any)=>{
        this.blockUI.stop();
        this.alTerminar.emit(res);
      });
    }
  }

  generarMovimientoAccionMigrar(){
    let movimiento_inventario={
      id_empresa:this.usuario.id_empresa,
      id_usuario:this.usuario.id,
      fecha:this.movimiento_conversion.fecha,
      tipo:this.movimiento_conversion.tipo,
      clase:this.movimiento_conversion.clase,
      observacion:this.movimiento_conversion.observacion,
      sucursal:this.movimiento_conversion.sucursal,
      almacen:this.movimiento_conversion.almacen,
      detallesMovimientoInventario:[{
        producto:this.movimiento_conversion.producto_fuente,
        activar_inventario:this.movimiento_conversion.producto_fuente.activar_inventario,
        cantidad:this.movimiento_conversion.cantidad,
        costo_unitario:this.movimiento_conversion.producto_fuente.costo_unitario,
        total:this.movimiento_conversion.cantidad*this.movimiento_conversion.producto_fuente.costo_unitario
      }],
      movimiento_inventario_destino:{
        id_empresa:this.usuario.id_empresa,
        id_usuario:this.usuario.id,
        fecha:this.movimiento_conversion.fecha,
        tipo:this.movimiento_conversion.movimiento_destino.tipo,
        clase:this.movimiento_conversion.movimiento_destino.clase,
        observacion:this.movimiento_conversion.observacion,
        sucursal:this.movimiento_conversion.sucursal,
        almacen:this.movimiento_conversion.almacen,
        detallesMovimientoInventario:[{
          producto:this.movimiento_conversion.producto_destino,
          activar_inventario:this.movimiento_conversion.producto_destino.activar_inventario,
          cantidad:this.movimiento_conversion.cantidad,
          costo_unitario:this.movimiento_conversion.producto_destino.costo_unitario,
          total:this.movimiento_conversion.cantidad*this.movimiento_conversion.producto_destino.costo_unitario
        }],
      }
    }
    this.sumarTotal(movimiento_inventario);
    this.sumarTotal(movimiento_inventario.movimiento_inventario_destino);
    return movimiento_inventario;
  }

  generarMovimientoAccionProducir(){
    let movimiento_inventario={
      id_empresa:this.usuario.id_empresa,
      id_usuario:this.usuario.id,
      fecha:this.movimiento_conversion.fecha,
      tipo:this.movimiento_conversion.tipo,
      clase:this.movimiento_conversion.clase,
      observacion:this.movimiento_conversion.observacion,
      sucursal:this.movimiento_conversion.sucursal,
      almacen:this.movimiento_conversion.almacen,
      detallesMovimientoInventario:[{
        producto:this.movimiento_conversion.producto_fuente,
        activar_inventario:this.movimiento_conversion.producto_fuente.activar_inventario,
        cantidad:this.movimiento_conversion.cantidad,
        costo_unitario:this.movimiento_conversion.producto_fuente.costo_unitario,
        total:this.movimiento_conversion.cantidad*this.movimiento_conversion.producto_fuente.costo_unitario
      }],
      movimiento_inventario_destino:{
        id_empresa:this.usuario.id_empresa,
        id_usuario:this.usuario.id,
        fecha:this.movimiento_conversion.fecha,
        tipo:this.movimiento_conversion.movimiento_destino.tipo,
        clase:this.movimiento_conversion.movimiento_destino.clase,
        observacion:this.movimiento_conversion.observacion,
        sucursal:this.movimiento_conversion.sucursal,
        almacen:this.movimiento_conversion.almacen,
        detallesMovimientoInventario:[],
        total:0
      }
    }

    for(var j=0;j<this.movimiento_conversion.producto_fuente.productosBase.length;j++){
      let cantidad=this.movimiento_conversion.cantidad*(this.movimiento_conversion.producto_fuente.productosBase[j].formulacion/this.movimiento_conversion.producto_fuente.productosBase[j].productoBase.equivalencia);
      let costo_unitario=this.movimiento_conversion.producto_fuente.productosBase[j].productoBase.costo_unitario;
      movimiento_inventario.movimiento_inventario_destino.detallesMovimientoInventario.push({
        producto:this.movimiento_conversion.producto_fuente.productosBase[j].productoBase,
        activar_inventario:this.movimiento_conversion.producto_fuente.productosBase[j].productoBase.activar_inventario,
        cantidad:cantidad,
        costo_unitario:costo_unitario,
        total:cantidad*costo_unitario,
      });
      movimiento_inventario.movimiento_inventario_destino.total=movimiento_inventario.movimiento_inventario_destino.total+(cantidad*costo_unitario)
    }

    this.sumarTotal(movimiento_inventario);
    this.sumarTotal(movimiento_inventario.movimiento_inventario_destino);
    return movimiento_inventario;
  }

  generarMovimientoAccionTraspasar(){
    let movimiento_inventario={
      id_empresa:this.usuario.id_empresa,
      id_usuario:this.usuario.id,
      fecha:this.movimiento_conversion.fecha,
      tipo:this.movimiento_conversion.tipo,
      clase:this.movimiento_conversion.clase,
      observacion:this.movimiento_conversion.observacion,
      sucursal:this.movimiento_conversion.sucursal,
      almacen:this.movimiento_conversion.almacen,
      almacenDestino:this.movimiento_conversion.almacenDestino,
      detallesMovimientoInventario:this.movimiento_conversion.detallesMovimientoInventario,
      movimiento_inventario_destino:{
        id_empresa:this.usuario.id_empresa,
        id_usuario:this.usuario.id,
        fecha:this.movimiento_conversion.fecha,
        tipo:this.movimiento_conversion.movimiento_destino.tipo,
        clase:this.movimiento_conversion.movimiento_destino.clase,
        observacion:this.movimiento_conversion.observacion,
        sucursal:this.movimiento_conversion.sucursalDestino,
        almacen:this.movimiento_conversion.almacenDestino,
        detallesMovimientoInventario:this.movimiento_conversion.detallesMovimientoInventario,
        total:0
      }
    }

    this.sumarTotal(movimiento_inventario);
    this.sumarTotal(movimiento_inventario.movimiento_inventario_destino);
    return movimiento_inventario;
  }

  generarMovimientoAccionMezclar(){
    let movimiento_inventario={
      id_empresa:this.usuario.id_empresa,
      id_usuario:this.usuario.id,
      fecha:this.movimiento_conversion.fecha,
      tipo:this.movimiento_conversion.tipo,
      clase:this.movimiento_conversion.clase,
      observacion:this.movimiento_conversion.observacion,
      sucursal:this.movimiento_conversion.sucursal,
      almacen:this.movimiento_conversion.almacen,
      detallesMovimientoInventario:this.movimiento_conversion.detallesMovimientoInventario,
      movimiento_inventario_destino:{
        id_empresa:this.usuario.id_empresa,
        id_usuario:this.usuario.id,
        fecha:this.movimiento_conversion.fecha,
        tipo:this.movimiento_conversion.movimiento_destino.tipo,
        clase:this.movimiento_conversion.movimiento_destino.clase,
        observacion:this.movimiento_conversion.observacion,
        sucursal:this.movimiento_conversion.sucursal,
        almacen:this.movimiento_conversion.almacen,
        detallesMovimientoInventario:[{
          producto:this.movimiento_conversion.producto_destino,
          activar_inventario:this.movimiento_conversion.producto_destino.activar_inventario,
          cantidad:this.movimiento_conversion.cantidad,
          costo_unitario:this.movimiento_conversion.producto_destino.costo_unitario,
          total:this.movimiento_conversion.cantidad*this.movimiento_conversion.producto_destino.costo_unitario
        }]
      }
    }

    this.sumarTotal(movimiento_inventario);
    this.sumarTotal(movimiento_inventario.movimiento_inventario_destino);
    return movimiento_inventario;
  }

  sumarTotal(movimiento_inventario){
		let sumaTotal=0;
		for(var i=0;i<movimiento_inventario.detallesMovimientoInventario.length;i++){
			sumaTotal=sumaTotal+movimiento_inventario.detallesMovimientoInventario[i].total;
		}		
		movimiento_inventario.total=Math.round((sumaTotal)*1000)/1000;
  }

  cerrarComponente(){
		this.alTerminar.emit();
	}

  obtenerAccionesMovimientoInventario(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.CONVERSIONES_MOVIMIENTO_INVENTARIO).subscribe((entidad:any)=>{
      this.converiones_movimiento_inventario=entidad.clases;
      if(!this.usuario.empresa.usar_sucursales && !this.usuario.empresa.usar_almacenes){
        let accion_traspasar=this.converiones_movimiento_inventario.filter(mc => mc.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_TRASPASO)[0];
        this.converiones_movimiento_inventario.splice(this.converiones_movimiento_inventario.indexOf(accion_traspasar),1);
      }
      if(!this.usuario.empresa.usar_produccion){
        let accion_produccion=this.converiones_movimiento_inventario.filter(mc => mc.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_PRODUCCION)[0];
        let accion_mezcla=this.converiones_movimiento_inventario.filter(mc => mc.nombre_corto==GlobalVariable.Dictionary.CONVERSION_MOVIMIENTO_INVENTARIO_MEZCLA)[0];
        this.converiones_movimiento_inventario.splice(this.converiones_movimiento_inventario.indexOf(accion_produccion),1);
        this.converiones_movimiento_inventario.splice(this.converiones_movimiento_inventario.indexOf(accion_mezcla),1);
      }
      this.blockUI.stop();
    });
  }

  async obtenerMovimientos(){
    this.blockUI.start();
    let entidad:any=await this.generalService.obtenerClases("MOVEGR").toPromise();
    let entidadIng:any=await this.generalService.obtenerClases("MOVING").toPromise();
    this.movimiento_ingreso_tipo=entidadIng;
    this.movimiento_salida_tipo=entidad;
    this.blockUI.stop();
	}

  buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatterProducto = (x: any) => (x.nombre);

  establecerProductoFuente($event){
    this.movimiento_conversion.producto_fuente=$event.item;
  }

  establecerProductoDestino($event){
    this.movimiento_conversion.producto_destino=$event.item;
  }

  reiniciarProductoFuente(){
    this.producto_fuente_busqueda="";
    this.movimiento_conversion.producto_fuente=null;
  }

  reiniciarProductoDestino(){
    this.producto_destino_busqueda="";
    this.movimiento_conversion.producto_destino=null;
  }

  establecerProductoTraspaso(event){
	  let producto=event.item;
		producto.tipoProducto=producto['tipoProducto']==null?{id:producto['tipoProducto.id'],nombre:producto['tipoProducto.nombre'],nombre_corto:producto['tipoProducto.nombre_corto']}:producto.tipoProducto;
		this.inventariosService.obtenerInventariosProducto(producto.id,this.movimiento_conversion.almacen.id).subscribe(async (inventarios:any[])=>{
			if(this.usuario.empresa.usar_productos_sucursal){
				let producto_sucursal:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,producto.id,this.movimiento_conversion.sucursal.id).toPromise();
				producto.activar_inventario=producto_sucursal.productos_sucursal[0].activar_inventario;
				producto.activar_vencimiento=producto_sucursal.productos_sucursal[0].activar_vencimiento;
				producto.producto_sucursal=producto_sucursal;

        let producto_sucursal_destino:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,producto.id,this.movimiento_conversion.sucursalDestino.id).toPromise();
        producto.activar_inventario_producto_sucursal_destino=producto_sucursal_destino.productos_sucursal[0].activar_inventario;
			}
			producto.inventarios=inventarios;
			this.inventariosDisponibleProducto=[];
			this.inventariosDisponibleProducto.push({id:0,fecha_vencimiento:"TODOS",fechaVencimientoTexto:"TODOS"});
			this.inventariosDisponibleProducto=this.inventariosDisponibleProducto.concat(producto.inventarios);
			var inventarioDisponible=this.obtenerInventarioTotal(producto);
			this.detalleMovimientoInventario={
				producto:producto,precio_unitario:producto.precio_unitario,
        activar_inventario:producto.activar_inventario,
				costo_unitario:(producto.costo_unitario==null || producto.costo_unitario==undefined)?1:producto.costo_unitario,
				inventarioProducto:this.inventariosDisponibleProducto[0],
				inventario_disponible:inventarioDisponible,
				costos:producto.activar_inventario?producto.inventarios:[],
				cantidad:1,descuento:producto.descuento,recargo:0,ice:0,excento:0,
				tipo_descuento:(producto.descuento>0?true:false),tipo_recargo:false,
				fechaVencimientoTexto:this.movimiento_conversion.fecha_texto
			}

			if(!this.usuario.empresa.usar_vencimientos || !producto.activar_vencimiento){
				this.detalleMovimientoInventario.fechaVencimientoTexto=null;
			}
			
			this.colorearInventarioDisponible(inventarioDisponible,producto);
			this.calcularImporte(this.detalleMovimientoInventario);
			this.enfocarElemento({which:13},"cantidad");
			setTimeout(()=>{
				aplicarVisorImagenes("imagen-producto-vista");
			}, 200);
    });
  }

  obtenerInventarioTotal(producto){
		var cantidadTotal = 0;
		if(producto.activar_inventario){
			for(var i = 0; i < producto.inventarios.length; i++){
				cantidadTotal += (producto.inventarios[i].cantidad);
			}
			for(var j=0;j<this.movimiento_conversion.detallesMovimientoInventario.length;j++){
				if(this.movimiento_conversion.detallesMovimientoInventario[j].producto.id==producto.id){
					cantidadTotal=cantidadTotal-this.movimiento_conversion.detallesMovimientoInventario[j].cantidad;
				}
			}
		}else{
			cantidadTotal=500000;
		}
		return cantidadTotal;
  }

  colorearInventarioDisponible(inventarioDisponible,producto){
		if(inventarioDisponible==0){
			this.porcentaje="100";
			this.color="red";
		}else if(inventarioDisponible>((producto.inventario_minimo*3)+1)){
			this.porcentaje="100";
			this.color="green";
		}else if(inventarioDisponible>((producto.inventario_minimo*2)+1)){
			this.porcentaje="75";
			this.color="green";
		}else if(inventarioDisponible>((producto.inventario_minimo*1.5)+1)){
			this.porcentaje="50";
			this.color="green"
		}else if(inventarioDisponible==(producto.inventario_minimo+1)){
			this.porcentaje="38";
			this.color="yellow";
		}else if(inventarioDisponible==producto.inventario_minimo){
			this.porcentaje="25";
			this.color="red";
		}else if(inventarioDisponible<producto.inventario_minimo && inventarioDisponible>0){
			this.porcentaje="12";
			this.color="red";
		}
  }

  calcularImporte(detalleMovimientoInventario){
		detalleMovimientoInventario.total=Math.round((detalleMovimientoInventario.cantidad*detalleMovimientoInventario.costo_unitario)*1000)/1000;
  }

  agregarDetalleMovimientoInventario(detalleMovimientoInventario){
    if(detalleMovimientoInventario.producto.activar_inventario){
      if(this.usuario.empresa.usar_productos_sucursal){
        if(!detalleMovimientoInventario.producto.activar_inventario_producto_sucursal_destino){
          this.toastr.warning("El producto tiene el inventario desactivado en la sucursal destino!");
          return;  
        }
      }
      if(detalleMovimientoInventario.producto.activar_inventario && detalleMovimientoInventario.cantidad>detalleMovimientoInventario.inventario_disponible){
        this.toastr.error("Cantidad insuficiente en inventario!");
        return;
      }
      if(detalleMovimientoInventario.fechaVencimientoTexto){
        detalleMovimientoInventario.fecha_vencimiento=Util.convertirObjetoAfecha(detalleMovimientoInventario.fechaVencimientoTexto);
      }
  
      let detalleVentaAAgregar=detalleMovimientoInventario;
      var detalleVentaProductoExistente=this.movimiento_conversion.detallesMovimientoInventario.filter(e => e.producto.id == detalleVentaAAgregar.producto.id);
      if(detalleVentaProductoExistente.length>0){
        detalleVentaProductoExistente=detalleVentaProductoExistente[0];
        detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
        this.detalleMovimientoInventario=detalleVentaProductoExistente;
        this.calcularImporte(this.detalleMovimientoInventario);
        this.movimiento_conversion.detallesMovimientoInventario[this.movimiento_conversion.detallesMovimientoInventario.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
      }else{
        this.calcularImporte(detalleMovimientoInventario);
        this.movimiento_conversion.detallesMovimientoInventario.push(detalleMovimientoInventario);
      }
  
      this.inventariosDisponibleProducto=[];
      this.sumarTotal(this.movimiento_conversion);
      this.detalleMovimientoInventario={producto:{activar_inventario:true},cantidad:1}
      this.reiniciarDetalleMovimientoInventario();
    }else{
      this.toastr.warning("El producto tiene el inventario desactivado!");
    }
  }

  actualizarAtributoProducto(producto,atributo,valor){
		this.blockUI.start();
    this.detalleMovimientoInventario.activar_inventario=valor;
		if(this.usuario.empresa.usar_productos_sucursal){
			let producto_sucursal:any=producto.producto_sucursal;
			producto_sucursal.productos_sucursal[0][atributo]=valor;
			this.productosService.actualizarProductoSucursal(producto_sucursal).subscribe((res:any)=>{
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}else{
			let datos:any={
				atributo:atributo,
				valor:valor
			};
			if(producto){
				datos.producto=producto;
			}else{
				datos.id_empresa=this.usuario.id_empresa;
			}
			this.productosService.actualizarAtributoProducto(datos).subscribe((res:any) => {
				this.getItems();
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}

		if(atributo=="activar_inventario" && valor){
			var inventarioDisponible=this.obtenerInventarioTotal(producto);
			this.detalleMovimientoInventario.inventario_disponible=inventarioDisponible;
		}
	}

  interceptarTecla(keyEvent,elemento,esEnfocar){
		if (keyEvent.which === 13){
			if(esEnfocar){
				$("#"+elemento).focus();
			}else{
				setTimeout(()=>{
					$('#'+elemento).trigger('click');
				  }, 0);
			}
		}
	}

  eliminarDetalleMovimiento(detalleMovimiento){
		this.movimiento_conversion.detallesMovimientoInventario.splice(this.movimiento_conversion.detallesMovimientoInventario.indexOf(detalleMovimiento),1);
		this.sumarTotal(this.movimiento_conversion);
	}

  agregarDetalleMovimientoMezcla(detalleMovimientoInventario){
	  if(detalleMovimientoInventario.producto.activar_inventario && detalleMovimientoInventario.cantidad>detalleMovimientoInventario.inventario_disponible){
			this.toastr.error("Cantidad insuficiente en inventario!");
			return;
		}
		if(detalleMovimientoInventario.fechaVencimientoTexto){
			detalleMovimientoInventario.fecha_vencimiento=Util.convertirObjetoAfecha(detalleMovimientoInventario.fechaVencimientoTexto);
		}

		let detalleVentaAAgregar=detalleMovimientoInventario;
		var detalleVentaProductoExistente=this.movimiento_conversion.detallesMovimientoInventario.filter(e => e.producto.id == detalleVentaAAgregar.producto.id);
		if(detalleVentaProductoExistente.length>0){
			detalleVentaProductoExistente=detalleVentaProductoExistente[0];
			detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
			this.detalleMovimientoInventario=detalleVentaProductoExistente;
			this.calcularImporte(this.detalleMovimientoInventario);
			this.movimiento_conversion.detallesMovimientoInventario[this.movimiento_conversion.detallesMovimientoInventario.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
		}else{
      var detalleVentaUnidad=this.movimiento_conversion.detallesMovimientoInventario.filter(e => e.producto.unidad_medida == detalleVentaAAgregar.producto.unidad_medida);
      if(this.movimiento_conversion.detallesMovimientoInventario.length==0 || detalleVentaUnidad.length>0){
        this.calcularImporte(detalleMovimientoInventario);
			  this.movimiento_conversion.detallesMovimientoInventario.push(detalleMovimientoInventario);
      }else{
        this.toastr.error("El producto a agregar debe tener la misma unidad de medida que los detalles!");
			  return;
      }
      
		}

		this.inventariosDisponibleProducto=[];
		this.sumarTotal(this.movimiento_conversion);
		this.detalleMovimientoInventario={producto:{activar_inventario:true},cantidad:1}
		this.reiniciarDetalleMovimientoInventario();
    this.sumarCantidadMezcla();
  }

  sumarCantidadMezcla(){
    this.movimiento_conversion.cantidad=0;
    for(let i=0;i<this.movimiento_conversion.detallesMovimientoInventario.length;i++){
      this.movimiento_conversion.cantidad=this.movimiento_conversion.cantidad+this.movimiento_conversion.detallesMovimientoInventario[i].cantidad;
    }
  }

  

}
