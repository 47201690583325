import { Component, OnInit, Input } from '@angular/core';
import { Paginator } from 'src/app/models/paginator';

@Component({
  selector: 'app-paginator-header',
  templateUrl: './paginator-header.component.html',
  styleUrls: ['./paginator-header.component.css']
})
export class PaginatorHeaderComponent implements OnInit {

  @Input()paginator:Paginator;

  constructor() { }

  ngOnInit(): void {
  }

}
