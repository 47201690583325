export class Paginator {

    constructor(
            public column:string="",
            public direction:string="asc",
            public direction_value:boolean=true,
			public currentPage:number=1,
            public itemsPerPage:number=10,
            public numberOfPages:number=1,
			public search:number=0,
			public filter:any=null,
			public pages:number[]=[],
			public selectedItems:any=[],
            public allItemsSelected:boolean=false,
            public text_search:string=null,
    ){

    }

    setPages(numberOfPages){
        this.numberOfPages=numberOfPages;
        this.pages=[];
        for(let i=1;i<=numberOfPages;i++){
            this.pages.push(i);
        }
    }

    getSearch(search,event){
        this.selectedItems=[];
        this.allItemsSelected=false;
        if(event==null || event==undefined || event.keyCode===13){ //enter pressed
            //search processing
            if(search=="" || search==null){
                this.search=0;
            }else{
                this.search=search;
            }
            //filter processing
            if(this.filter!=null){
                this.filter=JSON.parse(JSON.stringify(this.filter));
                var keys=Object.keys(this.filter);
                for (var index = 0; index < keys.length; index++) {
                    var key = keys[index];
                    if(this.filter[key] == null || this.filter[key] == undefined || this.filter[key] == ""){
                        this.filter[key]=0;
                    }else if(this.filter[key]==true){
                        this.filter[key]=1;
                    }
                }
            }
            this.getFirstPage();
        }
    }

    getFirstPage(){
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.currentPage=1;
        this.getItems();
    }

    getPreviousPage(){
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.currentPage=this.currentPage-1;
        this.getItems();
    }

    getPage(page){
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.currentPage=page;
        this.getItems();
    }

    getNextPage(){
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.currentPage=this.currentPage+1;
        this.getItems();
    }

    getLastPage(){
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.currentPage=this.pages.length;
        this.getItems();
    }

    sortColumn (column) {
        this.direction_value=!this.direction_value;
        if(this.direction_value){
            this.direction="asc";
        }else{
            this.direction="desc";
        }
        //this.selectedItems=[];
        //this.allItemsSelected=false;
        this.column = column;
        this.getItems();
    }
    
    addItemToSelected(item){
        if(item.selected){
            this.selectedItems.push(item);
        }else{
            this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        }
    }

    checkItem(item){
        var found=false,i=0;
        while(!found && i<this.selectedItems.length){
            if(this.selectedItems[i].id==item.id){
                found=true;
            }
            i++;
        }
        return found;
    }

    selectAllItems(items){
        this.selectedItems=[];
        for(var i=0;i<items.length;i++){
            if(this.allItemsSelected){
                items[i].selected=true;
                this.selectedItems.push(items[i]);
            }else{
                items[i].selected=false;
            }
        }
    }

    abs(value){
        return Math.abs(value);
    }

    getItems(){

    }

}
