<div class="modal-header card-header">
    <div class="col-12 col-md-4">
        <div class="row">
            <div class="col-3 col-md-6">
                <span class="text-blue text-125">Datos Pedido</span>
            </div>
            <div class="col-9 col-md-6">
                <button type="button" class="btn btn-primary" (click)="guardar()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8" *ngIf="!inhabilitar_busqueda_item">
        <div class="row">
            <div class="col-12">
                <h4 class="widget-title" *ngIf="pedido.usuario.empresa.usar_servicios">
                    <label>
                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-servicio bgc-blue-d1" type="checkbox" [(ngModel)]="detallePedido.es_servicio" (change)="cambiarServicio(detallePedido.es_servicio)"/>
                    </label>
                </h4>
                <span class="text-blue text-125" *ngIf="!pedido.usuario.empresa.usar_servicios">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                        <div class="input-group">
                                            <input [disabled]="inhabilitar_fecha" required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="pedido.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-7 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="pedido.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="usuario.empresa.usar_sucursales || (pedido.usuario.empresa.usar_inventario && usuario.empresa.usar_almacenes)">
                                <hr>
                                <div class="row">
                                    <div *ngIf="usuario.empresa.usar_sucursales" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Sucursal</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_sucursal" name="sucursal" [disabled]="inhabilitar_sucursal" class="form-control" [(ngModel)]="pedido.sucursal" (change)="establecerSucursal(pedido.sucursal)">
                                                <option [ngValue]="sucursal" *ngFor="let sucursal of pedido.sucursales">{{sucursal.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div *ngIf="pedido.usuario.empresa.usar_inventario && usuario.empresa.usar_almacenes" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_almacen" name="almacen" [disabled]="inhabilitar_almacen" class="form-control" [(ngModel)]="pedido.almacen">
                                                <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" [disabled]="inhabilitar_cliente || pedido.cliente.id" (keypress)="buscarNit($event,pedido.cliente.nit)" id="venta_nit" name="venta_nit" required type="text" [(ngModel)]="pedido.cliente.nit" placeholder="Ingrese el NIT">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-10 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">                                        
                                        <input *ngIf="clientes_nit.length==0" required id="venta_razon_social" name="venta_razon_social" [disabled]="inhabilitar_cliente || pedido.cliente.id"
                                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                            type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                                            [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" 
                                            (keypress)="enfocarElemento($event,'venta_id_producto')" autocomplete="off"
                                            />
                                        <select *ngIf="clientes_nit.length>0" [compareWith]="compararObjectos" name="venta_cliente" class="form-control" [(ngModel)]="pedido.cliente" (change)="establecerCliente(pedido.cliente)">
                                            <option [ngValue]="cliente" *ngFor="let cliente of clientes_nit">{{(cliente.razon_social +'-'+(cliente.identificacion?cliente.identificacion:''))}}</option>
                                        </select>

                                        <ng-template #rt let-r="result" let-t="term">
                                            <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                          </ng-template>
                                    </div>
                                </div>
                                <div class="col-md-1 col-sm-12 col-2 p-0">
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [disabled]="inhabilitar_cliente" href="javascript:void(0)" *ngIf="pedido.cliente.id" (click)="reiniciarCliente()">
                                            <i class="fa fa-trash bigger-130 red2"></i>
                                        </a>
                                    </div>
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a *ngIf="!pedido.cliente.id" [disabled]="inhabilitar_cliente" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                            <i class="fa fa-plus bigger-130"></i>
                                        </a>
                                        <a *ngIf="pedido.cliente.id" [disabled]="inhabilitar_cliente" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                            <i class="fa fa-edit bigger-130"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="pedido.cobrar_pedido">
                                <hr>
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Movimiento</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_movimiento" name="movimiento" [disabled]="inhabilitar_movimiento" class="form-control" [(ngModel)]="venta.transaccion">
                                                <option [ngValue]="transaccion" *ngFor="let transaccion of movimientosEgreso">{{transaccion.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Tipo de Pago</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_pago" name="pago" [disabled]="inhabilitar_tipo_pago" class="form-control" [(ngModel)]="venta.tipoPago" (change)="calcularCambio()">
                                                <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION">
                                <div class="row" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION">
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <label>Actividad</label>
                                    </div>
                                    <div class="col-md-9 col-sm-12 col-xs-12">
                                    <select style="width:300px;" [compareWith]="compararObjectos" id="venta_actividad" name="actividad" class="form-control" [(ngModel)]="venta.actividad">
                                            <option [ngValue]="actividad" *ngFor="let actividad of actividades">{{actividad.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <hr>
                                <div class="row" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONT">
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >Pagado (Bs):</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" id="venta_pagado" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Cambio (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.01" [(ngModel)]="venta.cambio"/>
                                        </div>
                                    </div>
                                </div>
                                <hr *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                <div class="row" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >Dias:</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" required type="number" min="1" name="dias" [(ngModel)]="venta.dias_credito" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Sujeto a CF:</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="venta.total" />
                                        </div>
                                    </div>
                                </div>
                                <hr *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                <div class="row" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >A cuenta (Bs):</label>
                                            <span class="red">(*)</span>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" [disabled]="inhabilitar_a_cuenta" min="0" name="acuenta" required type="number" step="0.001" [(ngModel)]="venta.a_cuenta" value="0.0" (change)="calcularSaldo()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Saldo (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="venta.saldo"/>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row">
                                <button type="button" (click)="mostrarOcultarDatosAdicionales()" class="btn btn-xs pos-rel pull-left">
                                    <i *ngIf="!pedido.mostrar_datos_adicionales" class="ace-icon fa fa-angle-double-down bigger-110"></i>
                                    <i *ngIf="pedido.mostrar_datos_adicionales" class="ace-icon fa fa-angle-double-up bigger-110"></i>
                                </button>
                                <ng-container *ngIf="pedido.mostrar_datos_adicionales" class="datos-adicionales">
                                    <div class="col-md-2 col-sm-2 col-2">
                                        <label>¿Cobrar?</label>
                                    </div>
                                    <div class="col-md-2 col-sm-2 col-2">
                                        <label>
                                            <input [disabled]="inhabilitar_cobrar_pedido" (change)="establecerVenta()" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="pedido.cobrar_pedido" />
                                        </label>
                                    </div>
                                    <ng-container *ngIf="pedido.usuario.empresa.usar_inventario">
                                        <div class="col-md-2 col-sm-2 col-2">
                                            <label>Descontar Almacen?</label>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-2">
                                            <label>
                                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="pedido.descontar_almacen" />
                                            </label>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="pedido.cobrar_pedido">
                                        <div class="col-md-6 col-sm-6 col-6">
                                            <label>Canal</label>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-6">
                                            <select [compareWith]="compararObjectos" name="canal" class="form-control" [(ngModel)]="venta.canal">
                                                <option [ngValue]="canal" *ngFor="let canal of canales">{{canal.nombre}}</option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 no-padding">
                <div class="widget-box" *ngIf="!inhabilitar_busqueda_item">
                    <div class="widget-body">
                        <div class="widget-main">
                            <form id="formularioBusquedaProducto" name="formularioBusquedaProducto" novalidate>
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !pedido.usuario.empresa.usar_combos">PRODUCTO</label>
                                                <label *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_SERVICIO">SERVICIO</label>
                                                <label *ngIf="pedido.usuario.empresa.usar_combos && (detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO)">
                                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-item bgc-blue-d1" type="checkbox" [(ngModel)]="detallePedido.es_combo" (change)="cambiarCombo(detallePedido.es_combo)" />
                                                </label>
                                                &nbsp;
                                                <button *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-warning btn-sm">
                                                    <i class="ace-icon fa fa-barcode bigger-110"></i>
                                                </button>
                                                <button *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-gift bigger-110"></i>
                                                </button>
                                            </th>
                                            <th *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>CÓD. ITEM</label>
                                            </th>
                                            <th *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>UNID. MED.</label>
                                            </th>
                                            <th *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && pedido.usuario.empresa.usar_vencimientos && detallePedido.producto && detallePedido.producto.id && detallePedido.producto.activar_vencimiento">
                                                <label>VENCIMIENTO</label>
                                            </th>
                                            <th *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>INVENTARIO DISPONIBLE</label>
                                            </th>
                                            <th>
                                                <label>P.U. (Bs/u)</label>
                                            </th>
                                            <th>
                                                <label>CANT. (u)</label>
                                            </th>
                                            <th>
                                                <label>IMP. (Bs)</label>
                                            </th>
                                            <th>
                                                <label>ACC.</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="p-0" id="gallery" *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO">
                                                <input *ngIf="!busqueda_codigo_barra" autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                [disabled]="!pedido.almacen || (detallePedido.producto && detallePedido.producto.id)" id="venta_id_producto" [(ngModel)]="producto_busqueda"
                                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtp let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                </ng-template>

                                                <a href="javascript:void(0)" *ngIf="detallePedido.producto && detallePedido.producto.id" (click)="iniciarDetalleVenta()" class="card-toolbar-btn text-danger-m1">
                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                </a>
                                                <input name="busqueda_codigo_barra" class="form-control" *ngIf="busqueda_codigo_barra && (!detallePedido.producto || !detallePedido.producto.id)" autocomplete="off" (keypress)="buscarCodigoBarraProducto($event,producto_busqueda)" id="venta_id_producto" required [disabled]="!pedido.almacen" type="text" [(ngModel)]="producto_busqueda" placeholder="Esperando al lector..." >
                                                <input name="busqueda_codigo_barra_d" class="form-control" *ngIf="busqueda_codigo_barra && (detallePedido.producto && detallePedido.producto.id)" [disabled]="true" type="text" [(ngModel)]="detallePedido.producto.nombre">
                                                <hr>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <button *ngIf="pedido.usuario.empresa.usar_inventario" [disabled]="!pedido.almacen" type="button" (click)="abrirBusquedaProducto()" class="btn btn-info btn-sm">
                                                            <i class="ace-icon fa fa-search-plus bigger-110"></i>
                                                        </button>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="imagen-producto-vista clearfix" *ngIf="detallePedido.producto && detallePedido.producto.id">
                                                            <a href="{{rest_server+detallePedido.producto.imagen}}" data-rel="colorbox" title="{{detallePedido.producto.nombre}}">
                                                                <img alt="sin imagen" src="{{rest_server+detallePedido.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_SERVICIO">
                                                <input required autocomplete="off" (keypress)="enfocarElemento($event,'venta_precio_unitario')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detallePedido.descripcion" type="text" />
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">

                                                <input autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                [disabled]="!pedido.almacen" id="venta_id_combo" [(ngModel)]="combo_buqueda"
                                                [ngbTypeahead]="buscarCombo" placeholder="Buscar combo" [resultTemplate]="rtc" name="combo_buqueda"
                                                [inputFormatter]="formatterCombo"  (selectItem)="establecerCombo($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtc let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre" [term]="t"></ngb-highlight>
                                                </ng-template>
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detallePedido.producto?detallePedido.producto.codigo:''}}</td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">{{detallePedido.combo?detallePedido.combo.codigo:''}}</td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detallePedido.producto?detallePedido.producto.unidad_medida:''}}</td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">{{detallePedido.combo?detallePedido.combo.unidad_medida:''}}</td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && pedido.usuario.empresa.usar_vencimientos && detallePedido.producto && detallePedido.producto.id && detallePedido.producto.activar_vencimiento">
                                                <select *ngIf="detallePedido.producto.activar_inventario" name="fechaVencimiento" style="width:95px;" id="venta_fechaVencimiento" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="detallePedido.inventarioProducto" (change)="actualizarInventarioDisponibleFechaVencimiento()">
                                                    <option [ngValue]="inventario" *ngFor="let inventario of inventariosDisponibleProducto">{{('FV:'+inventario.fechaVencimientoTexto+'-'+'F:'+inventario.detallesMovimiento[0].movimiento.fechaTexto+'-'+inventario.detallesMovimiento[0].movimiento.clase.nombre)}}</option>
                                                </select>
                                                <label>Lote: {{detallePedido.lote}}</label>
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <div class="c100 p{{inventario_porcentaje}} small {{inventario_color}}" *ngIf="(detallePedido.combo && detallePedido.combo.id) || (detallePedido.producto && detallePedido.producto.id && detallePedido.producto.activar_inventario)">
                                                    <span id="venta_CantProd" style="color:black !important;">{{detallePedido.inventario_disponible}}</span>
                                                    <div class="slice">
                                                        <div class="bar"></div>
                                                        <div class="fill"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="!pedido.usuario.empresa.usar_precios_productos_sucursal && !pedido.usuario.empresa.usar_precios_productos && !editar_precio">
                                                <div clas="row">
                                                    <div id="venta_Precio" class="col-12">
                                                        {{detallePedido.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-sm-12">
                                                        <a *ngIf="configuracion_pagina.getOrden().cambiar_precios" class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_SERVICIO || (!pedido.usuario.empresa.usar_precios_productos_sucursal && !pedido.usuario.empresa.usar_precios_productos && editar_precio)">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <input id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required class="form-control" (change)="calcularImporte()" [(ngModel)]="detallePedido.precio_unitario" type="number" (keypress)="enfocarElemento($event,'venta_cantidad')"/>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <a class="card-toolbar-btn text-primary" href="javascript:void(0)" (click)="establecerPrecio()">
                                                            <i class="fa fa-check-square bigger-130"></i>
                                                        </a>
                                                    </div>
                                                </div>		
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO && (pedido.usuario.empresa.usar_precios_productos || pedido.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-8">
                                                        {{detallePedido.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-12 p-0">
                                                        <select *ngIf="detallePedido.producto" class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detallePedido.producto_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detallePedido.producto.precios">{{precio.concepto?precio.concepto.nombre:''}}</option>
                                                        </select>
                                                    </div>                                               
                                                </div>
                                                <div class="row" > <!--*ngIf="es_administrador"-->
                                                    <div class="col-12" *ngIf="editar_precio">
                                                        <input class="form-control" id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required (change)="calcularImporte()" (keyup)="calcularImporte()" [(ngModel)]="detallePedido.precio_unitario" type="number" />
                                                        <a class="card-toolbar-btn text-primary" href="javascript:void(0)" (click)="establecerPrecio()">
                                                            <i class="fa fa-check-square bigger-130"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-12" *ngIf="!editar_precio">
                                                        <a *ngIf="configuracion_pagina.getOrden().cambiar_precios" class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div> 
                                                </div>
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO && (pedido.usuario.empresa.usar_precios_productos || pedido.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-md-12">
                                                        {{detallePedido.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-md-12 no-padding">
                                                        <select class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detallePedido.combo_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detallePedido.combo.precios">{{precio.concepto.nombre}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input *ngIf="!cantidad_decimal" onfocus="this.select();" (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" name="cantidad" step="1" 
                                                min="1" max="{{detallePedido.inventario_disponible}}" required class="form-control" 
                                                (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detallePedido.cantidad" type="number" 
                                                onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"/>
                                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                                    <input class="form-control" *ngIf="cantidad_decimal" onfocus="this.select();" 
                                                    (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" 
                                                    name="cantidad" step="0.001" min="0.1" max="{{detallePedido.inventario_disponible}}" 
                                                    required (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detallePedido.cantidad" type="number" />
                                                </div>
                                            </td>
                                            <td>
                                                {{detallePedido.importe}}
                                            </td>
                                            <td>
                                                <button id="agregar_detalle_venta" type="button" (click)="agregarDetallePedido()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                                </button>
                                                <hr>
                                                <button id="mostrar_desc" type="button" (click)="mostrarDescuentos()" class="btn btn-info btn-sm">
                                                    <i class="fas fa-expand-alt bigger-110"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <th>
                                                <label>DESCUENTOS (-)</label>
                                                <label>
                                                    <input (change)="calcularImporte()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="detallePedido.tipo_descuento"/>
                                                </label>
                                            </th>
                                            <!--<th>
                                                <label>RECARGOS (+)</label>
                                                <label>
                                                    <input class="ace ace-switch ace-switch-4" type="checkbox" [(ngModel)]="detallePedido.tipo_recargo" (change)="calcularImporte()"/>
                                                    <span class="lbl check-compra"></span>
                                                </label>
                                            </th>
                                            <th>
                                                <label>ICE (-)</label>
                                            </th>
                                            <th>
                                                <label>EXCENTOS (-)</label>
                                            </th>-->
                                            <th>
                                                <label>SUJETO A DF</label>
                                            </th>
                                            <th *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>Observación</label>
                                            </th>
                                            <!--<th *ngIf="detallePedido.es_servicio">
                                                <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)}} <span class="glyphicon glyphicon-pencil point-edit blue" (click)="abrirDialogTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1,obtenerTextosEmpresa)"></span></label>
                                            </th>-->
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <td >
                                                <input name="desc" required (keyup)="calcularImporte()" [(ngModel)]="detallePedido.descuento" step="0.001" class="form-control" type="number" value="0"/>
                                            </td>
                                            <!--<td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detallePedido.recargo" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detallePedido.ice" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detallePedido.excento" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>-->
                                            <td>
                                                {{detallePedido.total}}
                                            </td>
                                            <td *ngIf="detallePedido.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detallePedido.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <input (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detallePedido.descripcion" type="text" />
                                            </td>
                                            <!--<td *ngIf="detallePedido.es_servicio">
                                                <input id="venta_id_campo1" name="campo1" class="form-control" [(ngModel)]="detallePedido.campo_personalizado_1" type="text" />
                                            </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="pedido.detallesPedido.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE PEDIDO</h5>
                            <div class="card-toolbar">
                                <div class="keep-open btn-group show" title="Columnas">
                                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Visualización columnas" aria-expanded="true">
                                        <i class="fa fa-th-list text-orange-d1"></i>
                                        <span class="caret"></span>
                                    </button>
                                
                                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Nombre</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_codigo_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Cod. Item</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_unidad_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Und. Med.</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_precio_unitario" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>P.U. (Bs/u)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_cantidad" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Cant. (u)</span>
                                        </label>
                                        <label *ngIf="pedido.usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_fecha_vencimiento" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Venc.</span>
                                        </label>
                                        <label *ngIf="pedido.usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_lote" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Lote</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_importe" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Imp. (Bs)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_descuento" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Desc. (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_recargo" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Rec. (+)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_ice" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Ice (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_excento" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Exc. (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_total" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Sujeto a D.F.</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="pedido.detallesPedido.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_producto">
                                            <label>NOMBRE</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_precio_unitario">
                                            <label>P.U. (Bs/u)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_cantidad">
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && pedido.usuario.empresa.usar_vencimientos">
                                            <label>VENC.</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_lote && pedido.usuario.empresa.usar_vencimientos">
                                            <label>LOTE</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_importe">
                                            <label>IMP. (Bs)</label>
                                        </th>
                                        <th>
                                            <label>DESC. (-)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_total">
                                            <label>SUJETO A DF</label>
                                        </th>
                                        <th>
                                            <label>ACCIONES</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detallePedido of pedido.detallesPedido; let i=index;">
                                        <tr  *ngIf="!detallePedido.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_producto">
                                                <span *ngIf="!pedido.id">{{(detallePedido.producto && detallePedido.producto.id)?detallePedido.producto.nombre:(detallePedido.combo && detallePedido.combo.id)?detallePedido.combo.nombre:detallePedido.descripcion}} {{detallePedido.campo_personalizado_1? (obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)+':'+detallePedido.campo_personalizado_1):''}}</span>
                                                <span *ngIf="pedido.id">{{(detallePedido.producto && detallePedido.producto.id)?detallePedido.producto.nombre:(detallePedido.combo && detallePedido.combo.id)?detallePedido.combo.nombre:detallePedido.descripcion}} {{detallePedido.campo_personalizado_1? obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1):''}} <input *ngIf="detallePedido.campo_personalizado_1" class="form-control" style="width:50px;display:inline;" type="text" [(ngModel)]="detallePedido.campo_personalizado_1"></span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                                <span *ngIf="detallePedido.producto">{{detallePedido.producto.codigo}}</span>
                                                <span *ngIf="detallePedido.combo">{{detallePedido.combo.codigo}}</span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                                <span *ngIf="detallePedido.producto">{{detallePedido.producto.unidad_medida}}</span>
                                                <span *ngIf="detallePedido.combo">{{detallePedido.combo.unidad_medida}}</span>
                                            </td>
                                            <td *ngIf="!id && configuracionVentaVista.mostrar_precio_unitario">
                                                {{detallePedido.precio_unitario}}
                                            </td>
                                            <td *ngIf="id && configuracionVentaVista.mostrar_precio_unitario">
                                                <input type="number" step="0.001" min="0" [(ngModel)]="detallePedido.precio_unitario" (change)="cambiarPrecioUnitarioDetalleVenta(detallePedido)">
                                            </td>
                                            <td class="cantidad-pedido" *ngIf="configuracionVentaVista.mostrar_cantidad">
                                                <input oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" class="form-control" type="number" step="0" min="0" [(ngModel)]="detallePedido.cantidad" (change)="actualizarCantidadDetallePedido(detallePedido)" (keyup)="actualizarCantidadDetallePedido(detallePedido)">
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && pedido.usuario.empresa.usar_vencimientos">
                                                {{detallePedido.fecha_vencimiento | date:"dd/MM/yyyy"}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_lote && pedido.usuario.empresa.usar_vencimientos">
                                                {{detallePedido.lote}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_importe">
                                                {{detallePedido.importe}}
                                            </td>
                                            <td>
                                                {{detallePedido.descuento}} <i *ngIf="detallePedido.tipo_descuento" class="fas fa-percent text-primary"></i> <i *ngIf="!detallePedido.tipo_descuento" class="fas fa-dollar-sign text-primary"></i>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_total">
                                                {{detallePedido.total}}
                                            </td>
                                            <td>
                                                <a class="card-toolbar-btn text-danger-m1" href="javascript:void(0)" (click)="eliminarDetallePedido(detallePedido)">
                                                    <i class="fa fa-trash bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_producto">
                                            <b>TOTALES</b>
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_precio_unitario">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_cantidad">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && pedido.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_lote && pedido.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td class="total-pedido" *ngIf="configuracionVentaVista.mostrar_importe">
                                            {{pedido.importe.toFixed(2)}}
                                        </td>
                                        <td>
                                            <ng-container *ngIf="usuario.empresa.usar_descuento_global">
                                                <input  (change)="establecerDescuentoGlobal()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="pedido.tipo_descuento"/>
                                                <input class="form-control" type="number" step="0" min="0" [(ngModel)]="pedido.descuento" (change)="establecerDescuentoGlobal()" (keyup)="establecerDescuentoGlobal()">  
                                            </ng-container>
                                            <span *ngIf="!usuario.empresa.usar_descuento_global">{{pedido.descuento.toFixed(2)}}</span>
                                        </td>
                                        <td class="text-nowrap" *ngIf="configuracionVentaVista.mostrar_total">
                                            <span class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                                {{pedido.total.toFixed(2)}}
                                            </span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>
  