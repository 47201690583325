import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { PostulacionesService } from '../../services/postulaciones/postulaciones.service';

declare const $:any;

@Component({
  selector: 'app-formulario-postulacion',
  templateUrl: './formulario-postulacion.component.html',
  styleUrls: ['./formulario-postulacion.component.css']
})
export class FormularioPostulacionComponent extends BaseComponent implements OnInit {

  codigo_formulario:any;
  postulacion:any={};
  formRenderInstance:any;

  constructor(private _Activatedroute:ActivatedRoute,
    private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private postulacionesService:PostulacionesService ) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.codigo_formulario=this._Activatedroute.snapshot.paramMap.get("codigo_formulario");
    this.obtenerPostulacion();
  }

  obtenerPostulacion(){
    this.postulacionesService.obtenerPostulacionPorCodigo(this.codigo_formulario).subscribe((postulacion:any)=>{
      this.postulacion=postulacion;
      var formRenderOptions = {
        formData: JSON.parse(this.postulacion.formulario)
      }
      this.formRenderInstance = $('#render-container').formRender(formRenderOptions);
    });
  }

  enviarFormulario(){
    this.blockUI.start();
    this.postulacionesService.enviarDatosPostulacion({id_postulacion:this.postulacion.id,formulario:this.formRenderInstance.userData}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.obtenerPostulacion();
      this.blockUI.stop();
    });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
