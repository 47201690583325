import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  constructor(private http: HttpClient) { }

  public obtenerListaCombos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"combos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarCombo(combo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"combos/",combo,GlobalVariable.httpOptions);
  }

  public actualizarCombo(combo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"combos/"+combo.id,combo,GlobalVariable.httpOptions);
  }

  public obtenerCombo(combo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"combos/"+combo.id,GlobalVariable.httpOptions);
  }

  public eliminarCombo(combo){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"combos/"+combo.id,GlobalVariable.httpOptions);
  }

  public buscarCombo(id_empresa,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"combos/empresa/"+id_empresa+"/texto/"+texto,GlobalVariable.httpOptions);
  }

  public obtenerPreciosCombo(id_combo,id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"combos-precios/"+id_combo+"/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerCombos(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"combos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  

  
}
