import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-configuracion-aplicacion-pedidos',
  templateUrl: './configuracion-aplicacion-pedidos.component.html',
  styleUrls: ['./configuracion-aplicacion-pedidos.component.css']
})
export class ConfiguracionAplicacionPedidosComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  es_usuario_administrador:boolean=false;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  transacciones:any[]=[];
  tipos_despacho:any[]=[];
  tipos_pago:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerTransacciones();
    this.obtenerTiposDespachos();
    this.obtenerTiposPago();
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    this.verTab("tabla_pedidos");
  }

  obtenerTransacciones(){
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any) => {
      let movimientosEgresoOriginal=entidad.clases;
      this.transacciones=movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_PROFORMA);
      if(this.usuario.empresa.usar_facturacion){
        this.transacciones=this.transacciones.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION));
      }
      if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
        this.transacciones=this.transacciones.concat(movimientosEgresoOriginal.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA));
      }
    });
  }

  obtenerTiposPago(){
    this.blockUI.start();
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.tipos_pago=entidad.clases;
      this.blockUI.stop();
    });
  }

  async obtenerTiposDespachos(){
    /*if(this.usuario.empresa.usar_pedidos_en_linea){
      let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"REST_DESP").toPromise();
      this.tipos_despacho=entidad.clases?entidad.clases:[];
    }else{*/
      let entidad:any=await this.generalService.obtenerClases("REST_DESP").toPromise();
      this.tipos_despacho=entidad.clases;
    //}
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

}