import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { ExcelService } from 'src/app/base/services/excel/excel.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { CursosService } from '../../services/cursos/cursos.service';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { ReporteCursoService } from '../../services/reporte-curso/reporte-curso.service';
import { SeguimientoEstudianteService } from '../../services/seguimiento-estudiante/seguimiento-estudiante.service';
import { RegistroSeguimientoComponent } from '../registro-seguimiento/registro-seguimiento.component';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-seguimiento-estudiantes',
  templateUrl: './seguimiento-estudiantes.component.html',
  styleUrls: ['./seguimiento-estudiantes.component.css']
})
export class SeguimientoEstudiantesComponent extends BaseComponent implements OnInit {

  seguimientos:any[]=[];
  estudiantes:any[]=[];
  es_usuario_profesor:boolean=true;

  lista_estudiantes_modal:NgbModalRef;
  @ViewChild('lista_estudiantes_modal')
  private lista_estudiantes_modal_ref: TemplateRef<any>;

  registro_seguimiento_modal:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public excelService:ExcelService,
    public pdfService:PdfService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public profesoresService:ProfesoresService,
    public reporteCursoService:ReporteCursoService,
    public seguimientoEstudianteService:SeguimientoEstudianteService,
    public cursosService:CursosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));

		let fecha_actual=new Date();
		this.filter={
      id_empresa:this.usuario.id_empresa,
      gestion:null,
      gestiones:[],
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      registros:[{id:0,curso:{id:0,nombre:"TODOS"},materia:{id:0}}],
      registro: {id:0,curso:{id:0,nombre:"TODOS"},materia:{id:0}},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
      estados:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVOS"},{id:2,nombre:"ANULADOS"}],
			estado: {id:1,nombre:"ACTIVOS"},
		}
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_SEGUIMIENTO_ESTUDIANTES);
    await this.obtenerEscuela();  
    await this.obtenerGestiones();

    this.es_usuario_profesor=Util.esRolUsuario(GlobalVariable.Dictionary.ROL_PROFESOR,this.usuario);
    if(this.usuario.empresa.usar_control_por_usuarios){
      let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
      if(es_usuario_administrador){	
        this.obtenerUsuarios();
      }else{
        this.filter.usuarios=[this.usuario]
        this.filter.usuario=this.usuario;
      }
    }else{
      if(this.es_usuario_profesor){
        this.filter.usuarios=[this.usuario]
        this.filter.usuario=this.usuario;
      }else{
        this.obtenerUsuarios(); 
      }
    }

    await this.obtenerRegistrosSeguimiento();
		
		//await this.obtenerCanales();
		
		this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
    this.getItems();
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  async obtenerRegistrosSeguimiento(){
    if(this.es_usuario_profesor){
      await this.obtenerRegistrosProfesor();
    }else{
      await this.obtenerRegistrosCursos();
    }
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.seguimientoEstudianteService.obtenerListaSeguimientos(this).subscribe((dato:any) => {
			this.setPages(1);
      this.seguimientos=dato.seguimientos;
			this.blockUI.stop();
		});
  }

  async obtenerGestiones(){
    let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    this.filter.gestiones=entidad.clases;
    this.filter.gestion=entidad.clases[entidad.clases.length-1];
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  async obtenerRegistrosProfesor(){
    let registros_profesor:any=await this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,this.filter.gestion.id).toPromise();
    this.filter.registros=registros_profesor;
    this.filter.registros.unshift({id:0,curso:{id:0,nombre:"TODOS"},materia:{id:0}});
		this.filter.registro=this.filter.registros[0];
  }

  async obtenerRegistrosCursos(){
    let registros:any=await this.cursosService.obtenerCursosColegio(this.usuario.id_empresa).toPromise();
    this.filter.registros=[];
    for(let i=0;i<registros.length;i++){
      this.filter.registros.push({id:registros[i].id,curso:registros[i],materia:{id:0}});
    }
    this.filter.registros.unshift({id:0,curso:{id:0,nombre:"TODOS"},materia:{id:0}});
		this.filter.registro=this.filter.registros[0];
  }

  crearNuevoSeguimiento(){
    this.blockUI.start();
    this.filter.nivel=this.filter.registro.nivel_escolar?this.filter.registro.nivel_escolar:this.filter.registro.curso.nivel;
    this.filter.curso=this.filter.registro.curso;
    this.reporteCursoService.obtenerListaEstudiantes(this).subscribe((estudiantes:any[])=>{
      this.estudiantes=estudiantes;
      this.blockUI.stop();
    });
    this.lista_estudiantes_modal = this.modalService.open(this.lista_estudiantes_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.lista_estudiantes_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  crearNuevoSeguimientoEstudiante(estudiante){
    this.lista_estudiantes_modal.close();
    this.registro_seguimiento_modal = this.modalService.open(RegistroSeguimientoComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.registro_seguimiento_modal.componentInstance.usuario = this.usuario;
    this.registro_seguimiento_modal.componentInstance.seguimiento.estudiante = estudiante;
    this.registro_seguimiento_modal.componentInstance.seguimiento.curso = this.filter.registro.curso;
    this.registro_seguimiento_modal.componentInstance.seguimiento.materia = this.filter.registro.materia;
		
		this.registro_seguimiento_modal.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				this.getItems();
			}
      this.registro_seguimiento_modal.close();
			
		});
  }

  modificarSeguimientoEstudiante(seguimiento){
    this.registro_seguimiento_modal = this.modalService.open(RegistroSeguimientoComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.registro_seguimiento_modal.componentInstance.usuario = this.usuario;
    this.registro_seguimiento_modal.componentInstance.seguimiento = seguimiento;
    this.registro_seguimiento_modal.componentInstance.seguimiento.fecha_texto = Util.convertirFechaAObjectoFecha(new Date(seguimiento.fecha));
		
		this.registro_seguimiento_modal.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				this.getItems();
			}
      this.registro_seguimiento_modal.close();
			
		});
  }

  abrirPopupConfirmacionEliminacion(seguimiento){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el registro de seguimiento?";
		this.popupConfirmacion.componentInstance.data = seguimiento;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarSeguimiento($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarSeguimiento(seguimiento){
		this.blockUI.start();
    this.seguimientoEstudianteService.eliminarSeguimientoEstudiante(seguimiento).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
      this.getItems();
    });
	}

  async generarPdfSeguimiento(){
    var doc = new PDFDocument({ size: [612, 792], margin: 10 });
		var stream = doc.pipe(blobStream());
		var fecha_reporte=new Date();
		let itemsPorPagina = 20,y_pie=750;
    let titulo="Reporte de Seguimiento de Estudiantes";
		var y = 140, items = 0, pagina = 1, totalPaginas = Math.ceil(this.seguimientos.length / itemsPorPagina);
    doc.font('Helvetica', 8);
    this.escribirCabeceraSeguimiento(doc);
    this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
      this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
      (this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
    (this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
    (this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
    fecha_reporte,{imprimir_usuario:false});
    doc.font('Helvetica', 7);
    
    //doc.roundedRect(50,130,540,itemsPorPagina*30,5).fillOpacity(0.8).stroke();

    for (let i = 0; i < this.seguimientos.length && items <= itemsPorPagina; i++) {
      this.seguimientos[i].fecha=new Date(this.seguimientos[i].fecha);
      doc.text(i+1, 55, y);
      doc.text(this.seguimientos[i].fecha.getDate() + "/" + (this.seguimientos[i].fecha.getMonth() + 1) + "/" + this.seguimientos[i].fecha.getFullYear(), 70, y);
      doc.text(this.seguimientos[i].curso.nombre_corto, 110, y,{width:30});
      doc.text(this.seguimientos[i].materia?this.seguimientos[i].materia.nombre:"", 140, y,{width:60});
      doc.text(this.seguimientos[i].estudiante.persona.nombre_completo.toUpperCase(),200, y-4,{width:80});
      doc.text(this.seguimientos[i].descripcion,280, y-9,{width:200});
      doc.text(this.seguimientos[i].categoria.nombre,480, y-5,{width:100});
      doc.text(this.seguimientos[i].usuario.nombre_usuario,480, y+10,{width:100});
      doc.roundedRect(50,y - 10,540,30,5).fillOpacity(0.8).stroke();
      y = y+30;
      items++;

      if (items == itemsPorPagina && (i+1) < this.seguimientos.length) {
        doc.addPage({ size: [612, 792], margin: 10 });
        //doc.roundedRect(50,130,540,itemsPorPagina*30,5).fillOpacity(0.8).stroke();
        y = 140;
        items = 0;
        pagina = pagina + 1;
        this.escribirCabeceraSeguimiento(doc);
        this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
          this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
          (this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
        (this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
        (this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
        fecha_reporte,{imprimir_usuario:false});
        doc.font('Helvetica', 7);
      }
    }

		doc.end();
		return await new Promise(function (resolve, reject) {
			stream.on('finish', function () {
				var fileURL = stream.toBlobURL('application/pdf');
				var w = window.open(fileURL, '_blank', 'location=no');
				setTimeout(function () {
					w.print();
				}, 500);
				resolve(stream.toBlob());
			});
			stream.on('error', reject)
		});
  }

  escribirCabeceraSeguimiento(doc){
    doc.font('Helvetica-Bold',8);
		doc.roundedRect(350,68,235,30,5).stroke();
		doc.text("DESDE : ",355,75,{width:35,align:"right"});
		doc.text("HASTA : ",470,75,{width:35,align:"right"});
		doc.font('Helvetica', 8);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,400,75);
		doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,510,75);
		
		
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		doc.text("Nº", 55, 115);
    doc.text("FECHA", 70, 115);
    doc.text("CURSO", 110, 115);
    doc.text("MATERIA", 150, 115);
    doc.text("ESTUDIANTE", 200, 115);
    doc.text("DESCRIPCIÓN", 280, 115);
    doc.text("CATEGORIA", 480, 115);
    doc.text("USUARIO", 540, 115);
  }

}
