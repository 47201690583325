import { Component,EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-participantes-grupos-whatsapp',
  templateUrl: './participantes-grupos-whatsapp.component.html',
  styleUrls: ['./participantes-grupos-whatsapp.component.css']
})
export class ParticipantesGruposWhatsappComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  registro_grupo_whatsapp:any={};

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
    this.filter={
			id_empresa:this.usuario.id_empresa,
			sucursales:Util.obtenerSucursalesUsuario(this.usuario)
		}
  }

  async guardarParticipantes(){
    this.blockUI.start();
    let res:any=await this.generalService.agregarParticipantesGrupoWhatsapp(this.registro_grupo_whatsapp).toPromise();
    this.blockUI.stop();
    if(res.tiene_error){
      this.toastr.error(res.mensaje);
    }else{
      this.toastr.success(res.mensaje);
      this.cerrarComponente();
    }
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

}
