import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dosificaciones',
  templateUrl: './dosificaciones.component.html',
  styleUrls: ['./dosificaciones.component.css']
})
export class DosificacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
