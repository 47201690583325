import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ComprobantesService } from 'src/app/contabilidad/services/comprobantes/comprobantes.service';
import { PlanDeCuentasService } from 'src/app/contabilidad/services/plan-de-cuentas/plan-de-cuentas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroComprobanteComponent } from '../../registro-comprobante/registro-comprobante.component';

@Component({
  selector: 'app-pagos-ventas-no-contabilizados',
  templateUrl: './pagos-ventas-no-contabilizados.component.html',
  styleUrls: ['./pagos-ventas-no-contabilizados.component.css']
})
export class PagosVentasNoContabilizadosComponent extends BaseComponent implements OnInit {

  pagos_ventas_no_contabilizados:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  configuracion_cuentas:any;
  registro_comprobante_modal:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private planDeCuentasService:PlanDeCuentasService,
    private pdfService:PdfService,
    private comprobantesService:ComprobantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
    this.obtenerPagosVentasNoContabilizadas();
    this.obtenerConfiguracionCuentas();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerPagosVentasNoContabilizadas(){
    this.blockUI.start();
    this.generalService.obtenerPagosVentasNoContabilizadas(this.usuario.id_empresa).subscribe((pagos_ventas_no_contabilizados:any)=>{
      this.blockUI.stop();
      this.pagos_ventas_no_contabilizados=pagos_ventas_no_contabilizados;
    });
  }

  abrirPopupConfirmacionEliminacion(pago_venta_no_contabilizado){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.message = "¿Esta seguro de dejar de contabilizar el pago de venta?";
    this.popupConfirmacion.componentInstance.data = pago_venta_no_contabilizado;
    
    this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
      if($e.confirm){
        this.dejarContabilizarPagoVenta($e.data);
      }
      this.popupConfirmacion.close();
        });
  }

  dejarContabilizarPagoVenta(pago_venta_no_contabilizado){
    this.blockUI.start();
    this.planDeCuentasService.contabilizarPagoVenta({id_pago_venta:pago_venta_no_contabilizado.id,id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.obtenerPagosVentasNoContabilizadas();
      this.blockUI.stop();
    });
  }

  obtenerConfiguracionCuentas(){
    this.planDeCuentasService.obtenerConfiguracionCuentas(this.usuario.id_empresa).subscribe((configuracion_cuentas:any)=>{
      this.configuracion_cuentas={
        cuenta_ventas:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_VENTAS)[0].cuenta,
        cuenta_cuentas_por_cobrar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CUENTAS_X_COBRAR)[0].cuenta,
        cuenta_caja_dinero:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_CAJA_BANCOS)[0].cuenta,
        cuenta_iva_debito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_DF)[0].cuenta,
        cuenta_it_por_pagar:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT_POR_PAGAR)[0].cuenta,
        cuenta_impuesto_a_las_transacciones:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IT)[0].cuenta,
        cuenta_iva_credito_fiscal:configuracion_cuentas.filter(c => c.nombre==GlobalVariable.Dictionary.CONT.NOMBRE_CUENTA_IVA_CF)[0].cuenta
      }
    });
  }

  async generarNuevoComprobantePagoVenta(pago_venta_no_contabilizado){
    let res=true;
    if(this.configuracion_cuentas.cuenta_caja_dinero==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Caja/Bancos!");
    }
    if(this.configuracion_cuentas.cuenta_cuentas_por_cobrar==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Cuentas Por Cobrar!");
    }
    if(this.configuracion_cuentas.cuenta_impuesto_a_las_transacciones==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Impuesto a las transacciones!");
    }
    if(this.configuracion_cuentas.cuenta_ventas==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Ventas!");
    }
    if(this.configuracion_cuentas.cuenta_iva_debito_fiscal==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de Iva Debito Fiscal!");
    }
    if(this.configuracion_cuentas.cuenta_it_por_pagar==null){
      res=res && false; 
      this.toastr.error("Debe configurar la plantilla de I.T. por pagar!");
    }
    if(res){
      this.blockUI.start();
      let fuente_comprobante:any=await this.generalService.obtenerClases("TIPFUENTECOMP").toPromise();
      let texto_documento=pago_venta_no_contabilizado.transaccion_nombre_corto==GlobalVariable.Dictionary.EGRE_PROFORMA?"Nota de Venta":"Factura";
      let comprobante:any={
        sucursal:{id:pago_venta_no_contabilizado.id_sucursal,nombre:pago_venta_no_contabilizado.sucursal,id_empresa:this.usuario.id_empresa},
        usuario:this.usuario,
        importe:pago_venta_no_contabilizado.monto_pagado,
        fecha:pago_venta_no_contabilizado.fecha,
        asientos:[],
        fuente:fuente_comprobante.clases.filter(e => e.nombre_corto == GlobalVariable.Dictionary.TIPO_FUENTE_COMPROBANTE_SEMIAUTOMATICA_VENTA)[0],
        datos_fuente:pago_venta_no_contabilizado.id+"",
        abierto:false,
        eliminado:false,
        opcion_bimonetario:false,
        pago_venta:pago_venta_no_contabilizado
      }
      this.blockUI.stop();
      let importe=pago_venta_no_contabilizado.monto_pagado;
      comprobante.asientos.push({ tipo:{},glosa: "",cuenta:this.configuracion_cuentas.cuenta_caja_dinero, debe_bs:importe, haber_bs:0, debe_sus:0, haber_sus:0, eliminado:false });
      let cuenta_cliente:any=await this.planDeCuentasService.obtenerCuentaAuxiliarCliente(pago_venta_no_contabilizado.id_cliente).toPromise();
      this.configuracion_cuentas.cuenta_cuentas_por_cobrar.cuenta_auxiliar=cuenta_cliente;
      comprobante.asientos.push({ tipo:{},glosa: "", cuenta:this.configuracion_cuentas.cuenta_cuentas_por_cobrar, debe_bs:0, haber_bs:importe, debe_sus:0, haber_sus:0, eliminado:false });

      comprobante.glosa="Por pago de "+texto_documento+" Nº "+pago_venta_no_contabilizado.factura+", cliente "+pago_venta_no_contabilizado.cliente;

      this.registro_comprobante_modal = this.modalService.open(RegistroComprobanteComponent, {windowClass:"comprobante", ariaLabelledBy: 'modal-basic-title',scrollable:true, backdrop: 'static',size:"lg"});
      this.registro_comprobante_modal.componentInstance.usuario = this.usuario;
      this.registro_comprobante_modal.componentInstance.comprobante = comprobante;
      
      this.registro_comprobante_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.registro_comprobante_modal.close();
            this.comprobantesService.obtenerComprobante(res.comprobante.id).subscribe((datos_comprobante:any)=>{
              this.pdfService.imprimirComprobanteContabilidad(datos_comprobante.comprobante,false,this.usuario);
              this.obtenerPagosVentasNoContabilizadas();
            });  
          }
        }else{
          this.registro_comprobante_modal.close();
        }
        
      });
    }
  }

}
