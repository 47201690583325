<div class="modal-header card-header">
    <div class="col-4">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Datos de Movimiento</span>
            </div>
            <div class="col-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardarMovimientoInventario()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-8">
        <div class="row">
            <div class="col-12">
                <span class="text-blue text-125" >Búsqueda Producto</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-6 col-sm-6 col-xs-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Tipo</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="tipo" name="tipo" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.tipo" (change)="establecerTipo()">
                                            <option [ngValue]="tipo" *ngFor="let tipo of tipos_movimiento">{{tipo.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6 col-xs-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Movimiento</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [disabled]="inhabilitar_movimiento" [compareWith]="compararObjectos" id="clase" name="clase" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.clase" (change)="establecerClaseSeleccion()">
                                            <option [ngValue]="clase" *ngFor="let clase of clases">{{clase.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row" *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO">
                                <div class="col-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Sucursal Origen</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="sucursal_origen" name="sucursal_origen" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.sucursal" (change)="obtenerAlmacenesSucursalesDiferente(movimiento_inventario.sucursal.id)">
                                            <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Sucursal Destino</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="sucursal_destino" name="sucursal_destino" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.sucursalDestino" (change)="obtenerAlmacenesDiferente(movimiento_inventario.sucursalDestino)">
                                            <option [ngValue]="null" [selected]="true">Seleccionar</option>
                                            <option [ngValue]="sucursalDiferente" *ngFor="let sucursalDiferente of sucursalesDiferente">{{sucursalDiferente.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO">
                            <div class="row" *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO">
                                <div class="col-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Almacen Origen</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="almacen_origen" name="almacen_origen" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.almacen" (change)="obtenerAlmacenesDiferente(movimiento_inventario.sucursalDestino)">
                                            <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Almacen Destino</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="almacen_destino" name="almacen_destino" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.almacenDestino" >
                                        <option [ngValue]="null" [selected]="true">Seleccionar</option>
                                            <option [ngValue]="almacenDiferente" *ngFor="let almacenDiferente of almacenesDiferente">{{almacenDiferente.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO">
                            <div class="row" *ngIf="movimiento_inventario.clase.nombre_corto!=global_variable.Dictionary.EGRE_TRASPASO">
                                <div class="col-6" *ngIf="usuario.empresa.usar_sucursales">
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <label>Sucursal</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <select [disabled]="inhabilitar_sucursal" [compareWith]="compararObjectos" id="sucursal" name="sucursal" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.sucursal" (change)="obtenerAlmacenesActividades(movimiento_inventario.sucursal.id)">
                                            <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6" *ngIf="usuario.empresa.usar_almacenes || usuario.empresa.usar_almacenes_empleado">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Almacen</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [disabled]="inhabilitar_almacen" [compareWith]="compararObjectos" id="almacen" name="almacen" class="form-control" 
                                        [(ngModel)]="movimiento_inventario.almacen" >
                                            <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr *ngIf="movimiento_inventario.clase.nombre_corto!=global_variable.Dictionary.EGRE_TRASPASO">
                            <div class="row">
                                <div class="col-5">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Fecha</label>
                                        <span class="red">(*)</span>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="input-group">
                                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="movimiento_inventario.fechaTexto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="movimiento_inventario.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>PRODUCTO</label>&nbsp;
                                            <button *ngIf="!busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-warning btn-sm">
                                                <i class="ace-icon fa fa-barcode bigger-110"></i>
                                            </button>
                                            <button *ngIf="busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-gift bigger-110"></i>
                                            </button>
                                            &nbsp;
                                            <button *ngIf="aplicacion_producto.puede_crear" type="button" (click)="agregarNuevoProducto()" class="btn btn-primary btn-sm">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                        </th>
                                        <th>
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th>
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <label>VENCIMIENTO</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <label>LOTE</label>
                                        </th>
                                        <th *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_BAJA || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_PRODUCCION || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_CONSUMO || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_PUNTO_VENTA || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_VENTA_PERSONAL">
                                            <label>INVENTARIO DISPONIBLE</label>
                                        </th>
                                        <th *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA">
                                            <label>C.U. (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th>
                                            <label>ACC.</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="gallery">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input *ngIf="!busqueda_codigo_barra" autocomplete="off" (keypress)="enfocarElemento($event,'cantidad')"
                                                    [disabled]="!movimiento_inventario.almacen || detalleMovimientoInventario.producto.id" id="id_producto" [(ngModel)]="producto_busqueda"
                                                    [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                    [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                                    type="text" class="form-control"/>
                                                    <ng-template #rtp let-r="result" let-t="term">
                                                        <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                        <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                    </ng-template>
                                                    <a href="javascript:void(0)" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id" (click)="reiniciarDetalleMovimientoInventario()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger">
                                                        <i class="ace-icon fa fa-trash bigger-130"></i>
                                                    </a>
                                                    <input name="busqueda_codigo_barra" class="form-control" *ngIf="busqueda_codigo_barra && (!detalleMovimientoInventario.producto || !detalleMovimientoInventario.producto.id)" autocomplete="off" (keypress)="buscarCodigoBarraProducto($event,producto_busqueda)" id="movimiento_id_producto" required [disabled]="!movimiento_inventario.almacen" type="text" [(ngModel)]="producto_busqueda" placeholder="Esperando al lector..." >
                                                    <input name="busqueda_codigo_barra_d" class="form-control" *ngIf="busqueda_codigo_barra && (detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id)" [disabled]="true" type="text" [(ngModel)]="detalleMovimientoInventario.producto.nombre">
                                                    <hr>                                                 
                                                </div>
                                                <div class="col-md-3" *ngIf="detalleMovimientoInventario.producto.id">
                                                    <div class="imagen-producto-vista clearfix" *ngIf="detalleMovimientoInventario.producto && detalleMovimientoInventario.producto.id">
                                                        <a href="{{rest_server+detalleMovimientoInventario.producto.imagen}}" data-rel="colorbox" title="{{detalleMovimientoInventario.producto.nombre}}">
                                                            <img alt="sin imagen" src="{{rest_server+detalleMovimientoInventario.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                        </a>
                                                    </div> 
                                                </div>
                                                <div class="col-md-9" *ngIf="detalleMovimientoInventario.producto.id && aplicacion_producto.puede_modificar">
                                                    <label *ngIf="usuario.empresa.usar_inventario">¿Activar Inventario?
                                                        <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_inventario',detalleMovimientoInventario.producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_inventario"/>
                                                    </label>
                                                    <label *ngIf="usuario.empresa.usar_vencimientos">¿Activar Vencimiento?
                                                        <input (change)="actualizarAtributoProducto(detalleMovimientoInventario.producto,'activar_vencimiento',detalleMovimientoInventario.producto.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="detalleMovimientoInventario.producto.activar_vencimiento"/>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.codigo}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <div class="input-group">
                                                <input id="mov_fecha_vencimiento" name="mov_fecha_vencimiento" class="form-control" placeholder="dd/mm/yyyy"
                                                        name="dp" [(ngModel)]="detalleMovimientoInventario.fechaVencimientoTexto" ngbDatepicker #dv="ngbDatepicker"
                                                        (keypress)="interceptarTecla($event,'mov_lote',true)">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="dv.toggle()" type="button"></button>
                                                </div>
                                            </div> 
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && detalleMovimientoInventario.producto.activar_vencimiento && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <input id="mov_lote" (keypress)="interceptarTecla($event,'id_costo_unitario',true)" type="text" [(ngModel)]="detalleMovimientoInventario.lote" class="form-control">
                                        </td>
                                        <td *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_BAJA || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_PRODUCCION || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_CONSUMO || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_PUNTO_VENTA || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_VENTA_PERSONAL">
                                            <div class="c100 p{{porcentaje}} small {{color}}" *ngIf="detalleMovimientoInventario.producto.activar_inventario">
                                                <span id="CantProd" style="color:black !important;">{{detalleMovimientoInventario.inventario_disponible}}</span>
                                                <div class="slice">
                                                    <div class="bar"></div>
                                                    <div class="fill"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <input id="id_costo_unitario" name="costo_unitario" step="0.001" min="0.1" (keypress)="interceptarTecla($event,'cantidad',true)" class="form-control" (change)="calcularImporte()" [(ngModel)]="detalleMovimientoInventario.costo_unitario" type="number" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input (keypress)="interceptarTecla($event,'agregar_detalle_venta',false)" id="cantidad" name="cantidad" step="0.001" min="0.1" max="{{(movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_TRASPASO || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.EGRE_BAJA)?detalleMovimientoInventario.inventario_disponible:900000}}" class="form-control" (change)="calcularImporte()" [(ngModel)]="detalleMovimientoInventario.cantidad" type="number" />
                                        </td>
                                        <td>
                                            <button id="agregar_detalle_venta" type="button" [disabled]="!detalleMovimientoInventario.producto.id" (click)="agregarDetalleMovimientoInventario(detalleMovimientoInventario)" class="btn btn-success btn-sm">
                                                <i class="ace-icon fa fa-plus bigger-110"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="movimiento_inventario.detallesMovimientoInventario.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE MOVIMIENTO DE INVENTARIO</h5>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="movimiento_inventario.detallesMovimientoInventario.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th>
                                            <label>NOMBRE</label>
                                        </th>
                                        <th>
                                            <label>CÓD. ITEM</label>
                                        </th>
                                        <th>
                                            <label>UNID. MED.</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <label>VENCIMIENTO</label>
                                        </th>
                                        <th *ngIf="usuario.empresa.usar_vencimientos && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            <label>LOTE</label>
                                        </th>
                                        <th *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA">
                                            <label>C.U. (Bs/u)</label>
                                        </th>
                                        <th>
                                            <label>CANT. (u)</label>
                                        </th>
                                        <th>
                                            <label>ACCIONES</label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalleMovimientoInventario of movimiento_inventario.detallesMovimientoInventario; let i=index;">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td >
                                            {{detalleMovimientoInventario.producto.nombre}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.codigo}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.producto.unidad_medida}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            {{detalleMovimientoInventario.fecha_vencimiento | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td *ngIf="usuario.empresa.usar_vencimientos && (movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA)">
                                            {{detalleMovimientoInventario.lote}}
                                        </td>
                                        <td *ngIf="movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_AJUSTE || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_INV_INICIAL || movimiento_inventario.clase.nombre_corto==global_variable.Dictionary.ING_COMPRA">
                                            {{detalleMovimientoInventario.costo_unitario}}
                                        </td>
                                        <td>
                                            {{detalleMovimientoInventario.cantidad}}
                                        </td>
                                        <td>
                                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarDetalleMovimiento(detalleMovimientoInventario)">
                                                <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td>
                                            <b>TOTALES</b>
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td >
                                            
                                        </td>
                                        <td>
                                            
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>
  