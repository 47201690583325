<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Reporte de Ventas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Productos
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Reporte de Ventas</span>
      Productos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-md-3 col-sm-12">
                    <div class="input-group">
                      <a (click)="cambiarPresentacionPeriodo()" href="javascript:void(0)" class="text-blue"><i class="fas fa-exchange-alt"></i></a>&nbsp;
                        <label for="feini">Desde </label>&nbsp;
                        <ng-container *ngIf="configuracion_pagina && filter.presentacion_periodos=='fechas'">
                          <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                          </div>
                        </ng-container>
                        <select (change)="establecerFechaInicioMes(filter.mes_inicial)" *ngIf="configuracion_pagina && filter.presentacion_periodos=='meses'" [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes_inicial">
                          <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
                        </select>
                    </div>  
                  </div>
                  <div class="col-md-3 col-sm-12">
                      <div class="input-group">
                          <label for="fefin">Hasta </label>&nbsp;
                          <ng-container *ngIf="configuracion_pagina && filter.presentacion_periodos=='fechas'">
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                          </ng-container>
                          <select (change)="establecerFechaFinMes(filter.mes_final)" *ngIf="configuracion_pagina && filter.presentacion_periodos=='meses'" [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes_final">
                            <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
                          </select>
                      </div>  
                  </div>
                  <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                  <div class="col-md-2 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal" (change)="establecerSucursal()">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </div>
                  <ng-container *ngIf="usuario.empresa.usar_almacenes || usuario.empresa.usar_almacenes_usuario">
                    <div class="col-md-1 col-sm-12"><label>Almacen </label></div>
                    <div class="col-md-2 col-sm-12">
                      <select [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="filter.almacen">
                          <option [ngValue]="almacen" *ngFor="let almacen of filter.almacenes">{{almacen.nombre}}</option>
                      </select>
                    </div>
                  </ng-container>
                  <div class="col-md-3 col-sm-12">
                    <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Grupo </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="grupo_producto" [(ngModel)]="filter.grupo_producto">
                      <option [ngValue]="grupo_producto" *ngFor="let grupo_producto of filter.grupos_producto">{{grupo_producto.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Subgrupo </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo_producto" [(ngModel)]="filter.subgrupo_producto">
                      <option [ngValue]="subgrupo_producto" *ngFor="let subgrupo_producto of filter.subgrupos_producto">{{subgrupo_producto.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Canal </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="canal" [(ngModel)]="filter.canal">
                      <option [ngValue]="canal" *ngFor="let canal of filter.canales">{{canal.nombre}}</option>
                    </select>
                  </div>
                  
                            <div class="col-md-3 col-sm-12">
                  <label>Cliente </label> &nbsp; <a (click)="cambiarPresentacionCliente()" href="javascript:void(0)" class="text-blue"><i class="fas fa-exchange-alt"></i></a>&nbsp;
                  <input *ngIf="configuracion_pagina && filter.presentacion_cliente=='campo_texto'" class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente_texto">
                  <select *ngIf="configuracion_pagina && filter.presentacion_cliente=='lista'" [compareWith]="compararObjectos" class="form-control" required name="canal" [(ngModel)]="filter.cliente">
                    <option [ngValue]="cliente" *ngFor="let cliente of filter.clientes">{{cliente.identificacion}}</option>
                  </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                  <label>Producto </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.producto">
                            </div>
                            <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_clientes_contactos">
                  <label>Contacto Cliente </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.contacto_cliente">
                  </div>
                  <div class="col-md-2 col-sm-12" *ngIf="usuario.empresa.usar_obsequios">
                  <label>¿Solo Obsequios? </label>
                              <label>
                                  <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.solo_obsequios"/>
                              </label>
                  </div>
                  <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_combos">
                    <label>Tipo Item </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_item" [(ngModel)]="filter.tipo_item">
                      <option [ngValue]="tipo_item" *ngFor="let tipo_item of filter.tipos_items">{{tipo_item.nombre}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr *ngIf="aplicacion.puede_modificar">
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-2">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Histórico
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-6 text-center">
                <button (click)="generarPdfVentasProductos()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                </button>
              </div>
              <div class="col-6 text-center">
                <button (click)="generarExcelVentasProductos()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Mas Vendidos
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-4 text-center">
                <button (click)="generarPdfMasVendidos()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                </button>
              </div>
              <div class="col-4 text-center">
                <button (click)="generarExcelMasVendidos()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
              <div class="col-4 text-center">
                <button (click)="generarGraficoMasVendidos()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fas fa-chart-bar text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Agrupados por Grupo
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-12 text-center">
                <button (click)="generarExcelVentasProductosAgrupacionGrupos()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Agrupados por Producto
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-12 text-center">
                <button (click)="generarExcelVentasProductosAgrupacionProductos()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="usuario.empresa.usar_consumos">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                        Recetario
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-12 text-center">
                <button (click)="generarExcelVentasProductosConsumo()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="usuario.empresa.usar_inventario_externo">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                      Rotación de Inventarios
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-12 text-center">
                <button (click)="generarReporteRotacionInventarios()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="usuario.empresa.usar_inventario_externo">
            <div class="row">
                <div class="col-12 text-center">
                    <h5 class="page-title text-primary-d2">
                      Caducidad de Productos
                    </h5>
                </div>
            </div>  
            <div class="row">
              <div class="col-12 text-center">
                <button (click)="generarReporteCaducidadProductos()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <canvas id="myChart" width="400" height="400">
            </canvas>
          </div>
        </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #reporte_rotacion_inventarios let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Rotación de inventario</h4>
		</div>
		<div class="col-4">
			<button class="btn btn-danger" type="button" (click)="generarPdfRotacionInventarios()">
				<i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
			</button>&nbsp;
      <button class="btn btn-success" type="button" (click)="generarExcelRotacionInventarios()">
				<i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
			</button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-md-4">
        <h5 class="widget-title">Sucursal: {{filter.sucursal.nombre}}</h5>
      </div>
      <div class="col-md-4">
        <h5 class="widget-title">Cliente: {{filter.cliente.identificacion}}</h5>
      </div>
      <div class="col-md-4">
        <h5 class="widget-title" *ngIf="configuracion_pagina && filter.presentacion_periodos=='meses'">Periodos: de {{filter.mes_inicial.nombre}} a {{filter.mes_final.nombre}}</h5>
        <h5 class="widget-title" *ngIf="configuracion_pagina && filter.presentacion_periodos=='fechas'">Periodos: de {{filter.fecha_inicio.day}}/{{filter.fecha_inicio.month}}/{{filter.fecha_inicio.year}} a {{filter.fecha_fin.day}}/{{filter.fecha_fin.month}}/{{filter.fecha_fin.year}}</h5>
      </div>
    </div>
		<div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th rowspan="2">Nº</th>
            <th rowspan="2">Código Producto</th>
            <th rowspan="2">Producto</th>
            <th [attr.colspan]="datos_rotacion_inventario.meses.length" class="text-success">Ventas en Unidades</th>
            <th rowspan="2">Total</th>
            <th [attr.colspan]="datos_rotacion_inventario.meses.length" class="text-primary">Inventario Externo</th>
            <th rowspan="2">Total</th>
            <th rowspan="2">IR Mensual</th>
          </tr>
          <tr>
            <th *ngFor="let mes of datos_rotacion_inventario.meses" class="text-success">{{mes.nombre.substring(0,3)}}</th>
            <th *ngFor="let mes of datos_rotacion_inventario.meses" class="text-primary">{{mes.nombre.substring(0,3)}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let producto of datos_rotacion_inventario.productos;let i=index;">
            <td>{{i+1}}</td>
            <td>{{producto.codigo}}</td>
            <td>{{producto.nombre}}</td>
            <td *ngFor="let dato of producto.ventas" class="text-success">{{dato}}</td>
            <td class="text-success">{{producto.total_ventas}}</td>
            <td *ngFor="let dato of producto.inventarios" class="text-primary">{{dato}}</td>
            <td class="text-primary">{{producto.total_inventarios}}</td>
            <td>{{producto.ir}}</td>
          </tr>
          <tr>
            <td [attr.colspan]="datos_rotacion_inventario.meses.length*2+5">Totales</td>
            <td>{{datos_rotacion_inventario.sumatoria_ir}}</td>
          </tr>
        </tbody>
      </table>
    </div>
	</div>
</ng-template>

<ng-template #reporte_caducidad_productos let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Caducidad de productos</h4>
		</div>
		<div class="col-4">
			<button class="btn btn-danger" type="button" (click)="generarPdfCaducidadProductos()">
				<i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
			</button>&nbsp;
      <button class="btn btn-success" type="button" (click)="generarExcelCaducidadProductos()">
				<i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
			</button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-md-4">
        <h5 class="widget-title">Sucursal: {{filter.sucursal.nombre}}</h5>
      </div>
      <div class="col-md-4">
        <h5 class="widget-title">Cliente: {{filter.cliente.identificacion}}</h5>
      </div>
      <div class="col-md-4">
        <h5 class="widget-title">Fecha Hoy: {{filter.fecha_inicio.day}}/{{filter.fecha_inicio.month}}/{{filter.fecha_inicio.year}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="table-fixed-header table-responsive">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th >Nº</th>
                <th >Código</th>
                <th >Producto</th>
                <ng-container *ngFor="let number of [].constructor(datos_caducidad_productos.mas_fechas)">
                  <th>Fecha Prod.</th>
                  <th>Cantidad</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let producto of datos_caducidad_productos.productos;let i=index;">
                <td>{{i+1}}</td>
                <td>{{producto.codigo}}</td>
                <td>{{producto.nombre}}</td>
                <ng-container *ngFor="let fecha of producto.fechas">
                  <td>{{fecha.fecha | date:"dd/MM/yyyy"}}</td>
                  <td class="bg-{{fecha.obsolescencia_color}} text-white">{{fecha.cantidad}}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-3">
        <div class="table-fixed-header">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>Nº</th>
                <th class="bg-success text-white">Satisfactorio</th>
                <th class="bg-warning text-white">Alerta</th>
                <th class="bg-danger text-white">Riesgo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let alerta of datos_caducidad_productos.alertas;let i=index;">
                <td>{{i+1}}</td>
                <td>{{alerta.satisfactorio}}</td>
                <td>{{alerta.alerta}}</td>
                <td>{{alerta.riesgo}}</td>
              </tr>
              <tr>
                <td>Totales</td>
                <td>{{datos_caducidad_productos.sumatoria_satisfactorio}}</td>
                <td>{{datos_caducidad_productos.sumatoria_alerta}}</td>
                <td>{{datos_caducidad_productos.sumatoria_riesgo}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
	</div>
</ng-template>