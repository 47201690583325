import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PersistenciaService } from './base/services/persistencia/persistencia.service';

@Injectable({
  providedIn: 'root'
})
export class HabilitadoGuard implements CanActivate {

  constructor(private persistenciaService:PersistenciaService, private router: Router) {};

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      let usuario=localStorage.getItem('usuario');
      if(usuario){
        return true;
      }else{
        this.router.navigateByUrl('/');
        return false;
      }
  }
  
}
