import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../models/base-component';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ConceptoEmpresaComponent } from '../../components/concepto-empresa/concepto-empresa.component';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-conceptos',
  templateUrl: './conceptos.component.html',
  styleUrls: ['./conceptos.component.css']
})
export class ConceptosComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  tipos:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  
      ngOnInit(): void {
      this.init();
      this.buscarSubAplicacion(this.router.url.substring(1));
      this.filter={
        id_empresa:this.usuario.id_empresa?this.usuario.id_empresa:0
      }
  
      this.obtenerConceptos();
    }

    obtenerConceptos(){
      this.column = "nombre";
      this.getSearch(this.text_search,null);
    }
    
    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.generalService.obtenerTipos(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.tipos=dato.tipos;
        this.blockUI.stop();
      });
    }

    crearNuevoConcepto(){
      this.conceptoEdicion=this.modalService.open(ConceptoEmpresaComponent, {ariaLabelledBy: 'modal-basic-title',size:'md', backdrop: 'static'});
      this.conceptoEdicion.componentInstance.id_empresa = this.usuario.id_empresa?this.usuario.id_empresa:0;
        
      this.conceptoEdicion.componentInstance.onTerminate.subscribe(($e) => {
          if($e.save){
              this.getItems(); 
          }
          this.conceptoEdicion.close();
      });
    }

    descargarDatosSin(){
      this.blockUI.start();
      let id_empresa=this.usuario.id_empresa?this.usuario.id_empresa:0;
      this.generalService.descargarDatosSin(id_empresa).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
        }
      });
    }

}
