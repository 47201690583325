import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { ClientesService } from '../../../services/clientes/clientes.service';
import { GeneralService } from '../../../../base/services/general/general.service';
import { PdfService } from '../../../../base/services/pdf/pdf.service';
import { PedidosService } from '../../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PagoVentaComponent } from '../../pago-venta/pago-venta.component';
import { RegistroDespachoComponent } from '../../registro-despacho/registro-despacho.component';
import { VistaPedidoComponent } from '../../vista-pedido/vista-pedido.component';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';
import { GlobalVariable } from 'src/app/global';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

@Component({
  selector: 'app-pedidos-notificacion',
  templateUrl: './pedidos-notificacion.component.html',
  styleUrls: ['./pedidos-notificacion.component.css']
})
export class PedidosNotificacionComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  modal_pago_venta:NgbModalRef;
  modalPedido:NgbModalRef;
  registroDespacho:NgbModalRef;
  modalVenta:NgbModalRef;

  usuario:any;
  pedidos:any[]=[];
  pedidos_filtrados:any[]=[];
  filtro_pedido: any ={};
  estados:any[]=[];

  constructor(
    public persistenciaService:PersistenciaService,
    public pedidosService:PedidosService,
    public clientesService:ClientesService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    public ventasService:VentasService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  ngOnInit(): void {
    this.obtenerListaPedidos();
    this.obtenerEstadosPedido();
  }

  obtenerEstadosPedido(){
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
	  this.estados=entidad.clases;
    });
  }	

  obtenerListaPedidos(){
    this.filtro_pedido.valor_direccion=true;
    this.filtro_pedido.direccion="asc";
    this.filtro_pedido.columna="tiempo_ingreso";
    this.obtenerListaPedidosFiltro(this.filtro_pedido.columna);
  }

  clasificarColumnaPedidos(columna){
    this.filtro_pedido.valor_direccion=!this.filtro_pedido.valor_direccion;
    if(this.filtro_pedido.valor_direccion){
        this.filtro_pedido.direccion="asc";
    }else{
        this.filtro_pedido.direccion="desc";
    }
    this.obtenerListaPedidosFiltro(columna);
  }

  obtenerListaPedidosFiltro(columna){
    this.blockUI.start();
    let id_usuario=0;
    if(this.usuario.empresa.usar_control_por_usuarios  && !Util.esUsuarioAdministrador(this.usuario)){
      id_usuario=this.usuario.id;
    }
    this.pedidosService.obtenerListaPedidosNotificacion(Util.obtenerIdsSucursales(this.usuario),id_usuario,columna, this.filtro_pedido.direccion).subscribe((pedidos:any[])=>{
      this.pedidos = pedidos;
      this.pedidos_filtrados = pedidos;
      this.blockUI.stop();
    });
  }

  sumarImporte(){
		var suma=0;
		for(var i=0;i<this.pedidos_filtrados.length;i++){
			if(this.pedidos_filtrados[i].activo){
				suma=suma+this.pedidos_filtrados[i].total;
			}
		}
		return Math.round(suma*100)/100;
  }
  
  cerrarComponente(){
    this.alTerminar.emit();
   }

   crearPagoVenta(pedido){
		this.modal_pago_venta = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_pago_venta.componentInstance.venta = pedido.venta;
		this.modal_pago_venta.componentInstance.usuario = this.usuario;
		this.modal_pago_venta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.obtenerListaPedidos();
					this.modal_pago_venta.close();
				}
			}else{
				this.modal_pago_venta.close();
			}
			
		});
  }

  verPedido(pedido){
    this.modalPedido = this.modalService.open(VistaPedidoComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-pedido', backdrop: 'static'});
		this.modalPedido.componentInstance.pedido = pedido;
		this.modalPedido.componentInstance.usuario = this.usuario;
		this.modalPedido.componentInstance.alTerminar.subscribe((res) => {
			this.modalPedido.close();
		});
  }

  async despacharPedidoNotificacion(pedidoP){
    this.blockUI.start();
		var total_deuda_cliente=0;
		if(this.usuario.empresa.usar_vencimiento_economico_ventas){
      let dato_deuda:any=this.clientesService.obtenerValorLimiteDeudaCliente(pedidoP.cliente.id).toPromise();
			total_deuda_cliente=dato_deuda.total_deuda?dato_deuda.total_deuda:0;
		}

		if(total_deuda_cliente>pedidoP.cliente.valor_limite_credito){
			this.toastr.error("No se puede despachar el pedido, cliente en mora!");
		}else{
      this.pedidosService.obtenerPedidoDespacho(pedidoP.id).subscribe((pedido:any)=>{
        this.blockUI.stop();
        this.registroDespacho = this.modalService.open(RegistroDespachoComponent, {windowClass : "registro-despacho",ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
        this.registroDespacho.componentInstance.pedido = pedido;
        this.registroDespacho.componentInstance.usuario = this.usuario;
        this.registroDespacho.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
        
        this.registroDespacho.componentInstance.alTerminar.subscribe(($e) => {
          if($e){
            this.obtenerListaPedidos();
          }
          this.registroDespacho.close();
        });
      });
		}
	}

  async generarVentaPedido(pedidoP){
		this.blockUI.start();
		let configuracion_aplicacion_ventas:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.pedidosService.obtenerPedido(pedidoP.id).subscribe( (pedido:any)=>{
			pedido.usuario=pedido.usuario?pedido.usuario:this.usuario;
			pedido.usuario.empresa=this.usuario.empresa;

			this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.modalVenta.componentInstance.venta.usuario = pedido.usuario;
			this.modalVenta.componentInstance.usuario = this.usuario;
			this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
			this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
			this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);
			let sucursal_pedido=pedido.sucursal;
			this.modalVenta.componentInstance.venta.cliente=pedido.cliente;
			this.modalVenta.componentInstance.venta.sucursal=sucursal_pedido;
			this.modalVenta.componentInstance.nombre_corto_tipo_pago=this.global_variable.Dictionary.TIPO_PAGO_CRE;
			this.modalVenta.componentInstance.nombre_corto_canal_directo=pedido.publico?this.global_variable.Dictionary.CANAL_VENTA_ECOMERCE:this.global_variable.Dictionary.CANAL_VENTA_DIRECTA;
      this.modalVenta.componentInstance.venta.descontar_almacen=this.usuario.empresa.usar_inventario?true:false;
      this.modalVenta.componentInstance.venta.detallesVenta=pedido.detallesPedido;		
			this.modalVenta.componentInstance.inhabilitar_busqueda_item=true;
			this.modalVenta.componentInstance.inhabilitar_sucursal=true;
			this.modalVenta.componentInstance.inhabilitar_almacen=true;
			this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
			this.modalVenta.componentInstance.venta.pedido=pedido;
			this.modalVenta.componentInstance.venta.id_contacto_cliente=pedidoP.id_contacto_cliente;
			this.blockUI.stop();
			this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error){
						this.toastr.error(res.mensaje);
						this.toastr.error(res.stack.substring(0,500));
					}else{
						this.toastr.success(res.mensaje);
						this.imprimirVenta(res.venta.id);
						this.modalVenta.close();
						this.obtenerListaPedidos();
					}
				}else{
					this.modalVenta.close();
				}
				
			});
		});	
  }

  imprimirVenta(id_venta){
    this.pdfService.imprimirVenta({id:id_venta});
  }

  abrirPopupConfirmacionCambioEstadoPedido(pedido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.title = "Confirmación de cambio de estado de Pedido!";
		this.popupConfirmacion.componentInstance.message = pedido.mensaje;
		this.popupConfirmacion.componentInstance.data = pedido;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.cambiarEstadoPedido($e.data);
			}
			this.popupConfirmacion.close();
    });
	}
  
  cambiarEstadoPedido(dato){
    this.blockUI.start();
    var nuevo_estado=this.estados.filter(e => e.nombre_corto == dato.nombre_corto_estado)[0];
    dato.pedido.estado=nuevo_estado;
    dato.pedido.id_empresa=this.usuario.id_empresa;
    this.pedidosService.actualizarEstadoPedido(dato.pedido).subscribe((resP:any)=>{
		this.blockUI.stop();
      this.toastr.success(resP.mensaje);
      this.obtenerListaPedidos();
    });
  }

}
