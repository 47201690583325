<div class="modal-header">
    <div class="col-6 col-xs-6">
        <h4 class="widget-title">Datos Facturación Electrónica</h4>
    </div>
    <div class="col-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="guardarDatosFacturacion()">
            <i class="ace-icon fa fa-save red2 align-top text-180"></i>
  </button>&nbsp;
  <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top text-180"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="box box-primary px-2" >
        <div class="box-body">
        <div class="row">
        <div class="col-3 col-sm-3">
            <label>Razón Social </label>&nbsp;
            <input [(ngModel)]="empresa.razon_social" placeholder="Razón social" type="text" class="form-control border-l-2" />
        </div>
        <div class="col-3 col-sm-3">
            <label>N.I.T. </label>&nbsp;
            <input [(ngModel)]="empresa.nit" placeholder="N.I.T." type="text" class="form-control border-l-2" />
        </div>
                    <div class="col-3 col-sm-3">
            <label>Código Sistema S.I.N. </label>&nbsp;
            <input [(ngModel)]="empresa.sin_codigo_sistema" placeholder="codigo sistema S.I.N." type="text" class="form-control border-l-2" />
        </div>
                </div>
        <hr>
        <div class="row">
            <div class="col-3 col-sm-3">
                <label>Modalidad Facturación S.I.N. </label>&nbsp;
                <select [compareWith]="compararObjectos" class="form-control" required name="sin_modalidad_facturacion_r" [(ngModel)]="empresa.sin_modalidad_facturacion">
                    <option [ngValue]="sin_modalidad_facturacion" *ngFor="let sin_modalidad_facturacion of tipoSinModalidadesFacturacion">{{sin_modalidad_facturacion.nombre}}</option>
                </select>
            </div>
            <div class="col-3 col-sm-3">
                <label>Ambiente S.I.N. </label>&nbsp;
                <select [compareWith]="compararObjectos" class="form-control" required name="sin_ambiente" [(ngModel)]="empresa.sin_ambiente">
                    <option [ngValue]="sin_ambiente" *ngFor="let sin_ambiente of tipoSinAmbientes">{{sin_ambiente.nombre}}</option>
                </select>
            </div>
            <div class="col-3 col-sm-3">
                <label>Token S.I.N. </label>&nbsp;
                <input type="text" [(ngModel)]="empresa.sin_token" placeholder="Token S.I.N." class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <ng-container *ngIf="empresa.sin_modalidad_facturacion && empresa.sin_modalidad_facturacion.nombre_corto=='1'">
                <div class="col-3 col-sm-3">
                    <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                        <div class="fallback d-none">
                          <input name="file" type="file" multiple (change)="subirArchivo($event)" accept=".p12"/>
                        </div>
              
                        <div class="dz-default dz-message">
                          <span class="text-150  text-grey-d2">
                          <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                          para subir
                          <span class="text-90 text-grey-m1">(o click)</span>
                          <br />
                          <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                          </span>
                        </div>
                      </form>
              
                      <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                      <div id="preview-template" class="d-none">
                        <div class="dz-preview dz-file-preview">
                          <div class="dz-image">
                            <img alt="Archivo" data-dz-thumbnail="" />
                          </div>
              
                          <div class="dz-details">
                            <div class="dz-size">
                              <span data-dz-size=""></span>
                            </div>
              
                            <div class="dz-filename">
                              <span data-dz-name=""></span>
                            </div>
                          </div>
              
                          <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                            <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                          </div>
              
                          <div class="dz-error-message">
                            <span data-dz-errormessage=""></span>
                          </div>
              
                          <div class="dz-success-mark">
                            <span class="fa-stack fa-lg text-150">
                              <i class="fa fa-circle fa-stack-2x text-white"></i>
                              <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                          </span>
                          </div>
              
                          <div class="dz-error-mark">
                            <span class="fa-stack fa-lg text-150">
                              <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                              <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                          </span>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 col-sm-3">
                    <label>Clave Firma Digital </label>&nbsp;
                    <input type="text" [(ngModel)]="empresa.sin_clave_firma_digital" placeholder="Clave Firma" class="form-control" />
                </div>
            </ng-container>
            <div class="col-3 col-sm-3">
                <label>Documentos Facturación S.I.N. </label>&nbsp;
                <ngx-bootstrap-multiselect *ngIf="tipoSinDocumentosFacturacion" [options]="documentos_facturacion" [(ngModel)]="empresa.sin_documentos_facturacion"></ngx-bootstrap-multiselect>
            </div>
        </div>
        </div>
    </div>
</div>