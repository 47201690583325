import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { MateriasService } from '../../services/materias/materias.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const Winwheel:any;
declare var audio:any;

@Component({
  selector: 'app-ruleta',
  templateUrl: './ruleta.component.html',
  styleUrls: ['./ruleta.component.css']
})
export class RuletaComponent extends BaseComponent implements OnInit {

  theWheel:any;
  wheelPower:any= 1;
  wheelSpinning:any=false;
  gestion_actual:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public modalService: NgbModal,
    public generalService:GeneralService,
    public materiasService:MateriasService,
    public profesoresService:ProfesoresService,
    public estudiantesService:EstudiantesService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.filter={
      niveles:[{id:1,nombre:'Alto',valor:3},{id:2,nombre:'Medio',valor:2},{id:3,nombre:'Bajo',valor:1}],
      nivel:{id:3,nombre:'Bajo',valor:1}
    }
    await this.obtenerGestionActual();
    this.obtenerRegistrosProfesor();
  }

  construirRuleta(){
    let segmentos=[];
    for(let i=0;i<this.filter.estudiantes.length;i++){
      segmentos.push({'fillStyle' : "#"+Math.floor(Math.random()*16777215).toString(16).toString(), 'text' :this.filter.estudiantes[i].persona.apellido_paterno+" "+this.filter.estudiantes[i].persona.nombres});
    }
    this.theWheel = new Winwheel({
        'numSegments'  : this.filter.estudiantes.length,     // Specify number of segments.
        'outerRadius'  : 216,   // Set outer radius so wheel fits inside the background.
        'textFontSize' : 12,    // Set font size as desired.
        'segments'     :        // Define segments including colour and text.
        segmentos,
        'animation' :           // Specify the animation to use.
        {
            'type'     : 'spinToStop',
            'duration' : 15,
            'spins'    : this.filter.estudiantes.length,
            'callbackFinished' : this.alertPrize,
            'callbackSound'    : this.playSound,   // Function to call when the tick sound is to be triggered.
            'soundTrigger'     : 'pin'        // Specify pins are to trigger the sound, the other option is 'segment'.
        },
        'pins' :
        {
            'number' : this.filter.estudiantes.length*2   // Number of pins. They space evenly around the wheel.
        }
    });
  }

  async obtenerGestionActual(){
    this.blockUI.start();
    let gestion_actual=(new Date().getFullYear()).toString();
    let tipo_gestion:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    this.gestion_actual=(tipo_gestion && tipo_gestion.clases)?tipo_gestion.clases.filter(g => g.nombre==gestion_actual).length>0?tipo_gestion.clases.filter(g => g.nombre==gestion_actual)[0]:null:null;
    this.filter.gestion=this.gestion_actual;
    this.blockUI.stop();
  }

  obtenerRegistrosProfesor(){
    if(this.gestion_actual){
      this.blockUI.start();
      this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,this.gestion_actual.id).subscribe((registros_profesor:any[])=>{
        this.filter.registros_profesor=registros_profesor;
        this.blockUI.stop();
      }); 
    }
  }

// -----------------------------------------------------------------
// This function is called when the segment under the prize pointer changes
// we can play a small tick sound here like you would expect on real prizewheels.
// -----------------------------------------------------------------


  playSound()
  {
      let audio = new Audio('assets/js/ruleta/tick.mp3');  // Create audio object and load tick.mp3 file.
      // Stop and rewind the sound if it already happens to be playing.
      audio.pause();
      audio.currentTime = 0;

      // Play the sound.
      audio.play();
  }

// -------------------------------------------------------
// Called when the spin animation has finished by the callback feature of the wheel because I specified callback in the parameters
// note the indicated segment is passed in as a parmeter as 99% of the time you will want to know this to inform the user of their prize.
// -------------------------------------------------------
  alertPrize(indicatedSegment)
  {
      // Do basic alert of the segment text.
      // You would probably want to do something more interesting with this information.
      alert(indicatedSegment.text);
  }

// =======================================================================================================================
// Code below for the power controls etc which is entirely optional. You can spin the wheel simply by
// calling theWheel.startAnimation();
// =======================================================================================================================


// -------------------------------------------------------
// Function to handle the onClick on the power buttons.
// -------------------------------------------------------
  powerSelected(powerLevel)
  {
    this.wheelPower = powerLevel;
  }

// -------------------------------------------------------
// Click handler for spin button.
// -------------------------------------------------------
  startSpin()
  {
      // Ensure that spinning can't be clicked again while already running.
      if (this.wheelSpinning == false) {
          // Based on the power level selected adjust the number of spins for the wheel, the more times is has
          // to rotate with the duration of the animation the quicker the wheel spins.
          if (this.wheelPower == 1) {
            this.theWheel.animation.spins = 3;
          } else if (this.wheelPower == 2) {
            this.theWheel.animation.spins = 8;
          } else if (this.wheelPower == 3) {
            this.theWheel.animation.spins = 15;
          }

          // Begin the spin animation by calling startAnimation on the wheel object.
          this.theWheel.startAnimation();

          // Set to true so that power can't be changed and spin button re-enabled during
          // the current animation. The user will have to reset before spinning again.
          this.wheelSpinning = true;
      }
  }

// -------------------------------------------------------
// Function for reset button.
// -------------------------------------------------------
  resetWheel()
  {
      this.theWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.
      this.theWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
      this.theWheel.draw();                // Call draw to render changes to the wheel.

      this.wheelSpinning = false;          // Reset to false to power buttons and spin can be clicked again.
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

   obtenerListaEstudiantes(registro_profesor){
    this.blockUI.start();
    this.estudiantesService.obtenerListaEstudiantes(this.gestion_actual.id,registro_profesor.id_curso).subscribe((estudiantes:any[])=>{
      this.blockUI.stop();
      this.filter.estudiantes=estudiantes;
      this.construirRuleta();
    });
  }

}
