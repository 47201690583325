<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Reporte
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Ventas
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Reporte de Ventas</span>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                  <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                        <label for="feini">Desde </label>&nbsp;
                        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                  name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                        </div>
                    </div>  
                  </div>
                  <div class="col-md-4 col-sm-12">
                      <div class="input-group">
                          <label for="fefin">Hasta </label>&nbsp;
                          <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                      </div>  
                  </div>
                  <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                  <div class="col-md-3 col-sm-12">
                    <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal" (change)="establecerSucursal()">
                        <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Transacción </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="transaccion" [(ngModel)]="filter.transaccion">
                      <option [ngValue]="transaccion" *ngFor="let transaccion of filter.transacciones">{{transaccion.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                  </div>
                  
                  <div class="col-md-3 col-sm-12">
                  <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} </label> &nbsp; <a (click)="cambiarPresentacionCliente()" href="javascript:void(0)" class="text-blue"><i class="fas fa-exchange-alt"></i></a>&nbsp;
                  <input *ngIf="configuracion_pagina && filter.presentacion_cliente=='campo_texto'" class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente_texto">
                  <select *ngIf="configuracion_pagina && filter.presentacion_cliente=='lista'" [compareWith]="compararObjectos" class="form-control" required name="canal" [(ngModel)]="filter.cliente">
                    <option [ngValue]="cliente" *ngFor="let cliente of filter.clientes">{{cliente.identificacion}}</option>
                  </select>
                </div>
                <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_clientes_contactos">
                  <label>Contacto Cliente </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.contacto_cliente">
                </div>

                <div class="col-md-3 col-sm-12">
                    <label>Agrupador 1</label>
                    <select (change)="cambiarAgrupador1()" [compareWith]="compararObjectos" class="form-control" required name="agrupador_1" [(ngModel)]="filter.agrupador_1">
                      <option [ngValue]="agrupador_1" *ngFor="let agrupador_1 of filter.agrupadores_1">{{agrupador_1.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-12">
                    <label>Agrupador 2</label>
                    <select (change)="cambiarAgrupador2()" [compareWith]="compararObjectos" class="form-control" required name="agrupador_2" [(ngModel)]="filter.agrupador_2">
                      <option [ngValue]="agrupador_2" *ngFor="let agrupador_2 of filter.agrupadores_2">{{agrupador_2.nombre}}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-12">
                    <label>Agrupador 3</label>
                    <select (change)="cambiarAgrupador3()" [compareWith]="compararObjectos" class="form-control" required name="agrupador_3" [(ngModel)]="filter.agrupador_3">
                      <option [ngValue]="agrupador_3" *ngFor="let agrupador_3 of filter.agrupadores_3">{{agrupador_3.nombre}}</option>
                    </select>
                </div>


                </div>
              </div>
            </div>
          </div>
          <div class="col-3 col-md-3">
            <div class="row">
                <div class="col-12">
                  <button (click)="generarReporte()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-search text-150"></i>
                  </button>&nbsp;
                  <button [disabled]="datos_reporte.length==0" (click)="generarPdfReporteVentas()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Pdf">
                    <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                  </button>
                </div>
              </div>
              <hr>
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr *ngIf="aplicacion.puede_modificar">
                              <td class="text-dark-m2">
                              Configuración Aplicación
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>

          <div class="row" id="datos">
            <div class="col-12 cards-container mt-3 mt-sm-0" *ngFor="let dato of datos_reporte;let i=index;">
                <div class="card dcard p-1 bgc-green-l1">
                  <div class="card-body p-1 p-sm-2 p-lg-3">
                    <div class="card">
                      <div class="card-header bgc-green-l1">
                        <h5 class="card-title text-120 text-default-d3">
                            {{dato.nombre_agrupador_1}}
                        </h5>
                        <div class="card-toolbar" *ngIf="dato.array_agrupadores_2">
                            <table style="border: 1px solid black;" class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Totales</th>
                                        <th>Importe</th>
                                        <th>Descuento</th>
                                        <th>Total</th>
                                        <th>A Cuenta</th>
                                        <th>Saldo</th>
                                        <th>Devoluciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td>{{dato.suma_importe}}</td>
                                        <td>{{dato.suma_descuento}}</td>
                                        <td>{{dato.suma_total}}</td>
                                        <td>{{dato.suma_a_cuenta}}</td>
                                        <td>{{dato.suma_saldo}}</td>
                                        <td>{{dato.suma_devoluciones}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </div><!-- /.card-header -->
                      <div class="card-body p-0">
                        <div class="p-3">
                            <ng-container *ngIf="dato.array_agrupadores_2">
                                <div class="row">
                                    <div class="col-12 cards-container mt-3 mt-sm-0" *ngFor="let dato_2 of dato.array_agrupadores_2;let i_2=index;">
                                        <div class="card dcard p-1 bgc-purple-l1">
                                          <div class="card-body p-1 p-sm-2 p-lg-3">
                                            <div class="card">
                                              <div class="card-header bgc-purple-l1">
                                                <h5 class="card-title text-120 text-default-d3">
                                                    {{dato_2.nombre_agrupador_2}}
                                                </h5>
                                                <div class="card-toolbar" *ngIf="dato_2.array_agrupadores_3">
                                                    <table style="border: 1px solid black;" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Totales</th>
                                                                <th>Importe</th>
                                                                <th>Descuento</th>
                                                                <th>Total</th>
                                                                <th>A Cuenta</th>
                                                                <th>Saldo</th>
                                                                <th>Devoluciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td>{{dato_2.suma_importe}}</td>
                                                                <td>{{dato_2.suma_descuento}}</td>
                                                                <td>{{dato_2.suma_total}}</td>
                                                                <td>{{dato_2.suma_a_cuenta}}</td>
                                                                <td>{{dato_2.suma_saldo}}</td>
                                                                <td>{{dato_2.suma_devoluciones}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                              </div><!-- /.card-header -->
                        
                                              <div class="card-body p-0">
                                                <div class="p-3">
                                                    
                                                    <ng-container *ngIf="dato_2.array_agrupadores_3">
                                                        <div class="row">
                                                            <div class="col-12 cards-container mt-3 mt-sm-0" *ngFor="let dato_3 of dato_2.array_agrupadores_3;let i_3=index;">
                                                                <div class="card dcard p-1 bgc-primary-l1">
                                                                  <div class="card-body p-1 p-sm-2 p-lg-3">
                                                                    <div class="card">
                                                                      <div class="card-header bgc-primary-l1">
                                                                        <h5 class="card-title text-120 text-default-d3">
                                                                            {{dato_3.nombre_agrupador_3}}
                                                                        </h5>
                                                                      </div><!-- /.card-header -->
                                                
                                                                      <div class="card-body p-0">
                                                                        <div class="p-3">
                                                                            <table style="border: 1px solid black;" class="table table-striped table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Nº</th>
                                                                                        <th>Importe</th>
                                                                                        <th>Descuento</th>
                                                                                        <th>Total</th>
                                                                                        <th>A Cuenta</th>
                                                                                        <th>Saldo</th>
                                                                                        <th>Devoluciones</th>
                                                                                    </tr>
                                                                                </thead>
                                                                
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>{{i_3+1}}</td>
                                                                                        <td>{{dato_3.importe}}</td>
                                                                                        <td>{{dato_3.descuento}}</td>
                                                                                        <td>{{dato_3.total}}</td>
                                                                                        <td>{{dato_3.a_cuenta}}</td>
                                                                                        <td>{{dato_3.saldo}}</td>
                                                                                        <td>{{dato_3.devoluciones}}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <table style="border: 1px solid black;" *ngIf="!dato_2.array_agrupadores_3;let i=index;" class="table table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Nº</th>
                                                                <th>Importe</th>
                                                                <th>Descuento</th>
                                                                <th>Total</th>
                                                                <th>A Cuenta</th>
                                                                <th>Saldo</th>
                                                                <th>Devoluciones</th>
                                                            </tr>
                                                        </thead>
                                        
                                                        <tbody>
                                                            <tr>
                                                                <td>{{i_2+1}}</td>
                                                                <td>{{dato_2.importe}}</td>
                                                                <td>{{dato_2.descuento}}</td>
                                                                <td>{{dato_2.total}}</td>
                                                                <td>{{dato_2.a_cuenta}}</td>
                                                                <td>{{dato_2.saldo}}</td>
                                                                <td>{{dato_2.devoluciones}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <table style="border: 1px solid black;" *ngIf="!dato.array_agrupadores_2" class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Nº</th>
                                        <th>Importe</th>
                                        <th>Descuento</th>
                                        <th>Total</th>
                                        <th>A Cuenta</th>
                                        <th>Saldo</th>
                                        <th>Devoluciones</th>
                                    </tr>
                                </thead>
                
                                <tbody>
                                    <tr>
                                        <td>{{i+1}}</td>
                                        <td>{{dato.importe}}</td>
                                        <td>{{dato.descuento}}</td>
                                        <td>{{dato.total}}</td>
                                        <td>{{dato.a_cuenta}}</td>
                                        <td>{{dato.saldo}}</td>
                                        <td>{{dato.devoluciones}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </div><!-- /.card-body -->
                    </div>
                  </div>
                </div>
              </div><!-- /.col -->
          </div>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>