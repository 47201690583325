import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-configuracion-aplicacion-reportevp',
  templateUrl: './configuracion-aplicacion-reportevp.component.html',
  styleUrls: ['./configuracion-aplicacion-reportevp.component.css']
})
export class ConfiguracionAplicacionReportevpComponent extends BaseComponent implements OnInit {

  @Input() configuracion_pagina:any;
  @Input() usuario:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  disenios_logo_impresion:any[]=[];

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  ngOnInit(): void {
    this.obtenerDiseniosLogoImpresion();
    
  }

  guardarConfiguracionVista(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.alTerminar.emit();
	}

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerDiseniosLogoImpresion(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_DISENIOS_LOGO_IMPRESION).subscribe((entidad:any) => {
      this.disenios_logo_impresion=entidad.clases;
    });
  }

}
