import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-computadoras',
  templateUrl: './computadoras.component.html',
  styleUrls: ['./computadoras.component.css']
})
export class ComputadorasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
