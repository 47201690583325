import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { CajasService } from '../../services/cajas/cajas.service';

declare const enfocarElemento:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-pago-venta',
  templateUrl: './pago-venta.component.html',
  styleUrls: ['./pago-venta.component.css']
})
export class PagoVentaComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;
  venta:any={
    transaccion:{}
  };
  pago_venta:any={
    monto_pagado:1
  };
  global_variable=GlobalVariable;
  cajas:any[]=[];
  transacciones:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public ventasService:VentasService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public pdfService: PdfService,
    public usuarioService:UsuarioService,
    public toastr: ToastrService,
    public cajasService:CajasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.blockUI.start();
    let fecha_actual=new Date();
    this.ventasService.obtenerDatosVentaCredito(this.venta.id).subscribe((datos:any) => {
      this.venta=datos;
      this.pago_venta.sucursal={id:this.venta.id_sucursal};
      this.blockUI.stop();
    });
    this.pago_venta.id_usuario_cajero=this.usuario.id;
    this.pago_venta.id_empresa=this.usuario.id_empresa;
    this.pago_venta.fecha_texto={
      year: fecha_actual.getFullYear(), 
      month: (fecha_actual.getMonth()+1),
      day:fecha_actual.getDate()
    };

    if(this.usuario.empresa.usar_caja_general){
      this.obtenerCajasEmpresa();
      this.obtenerTransacciones();
    }
    aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    enfocarElemento("importe");
  }

  ngAfterViewInit() {
		this.obtenerTextosEmpresa();
	}

  cerrarModal(){
    this.alTerminar.emit();
  }

  obtenerCajasEmpresa(){
    this.blockUI.start();
    this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).subscribe((cajas:any)=>{
      this.cajas=cajas;
      this.blockUI.stop();
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_PAGOS).subscribe((transacciones:any)=>{
		this.transacciones=transacciones.clases;
    this.pago_venta.transaccion=this.transacciones[0];
		this.blockUI.stop();
	});	
  }

  validarPagoVenta(){
    let res=true;
    if(!this.pago_venta.monto_pagado || this.pago_venta.monto_pagado<=0 || this.pago_venta.monto_pagado>this.venta.saldo){
      res=res && false;
      this.toastr.error("El valor del pago debe ser mayor a cero y menor o igual al saldo, intente con otro valor!");
    }
    if(!this.pago_venta.fecha_texto || !this.pago_venta.fecha_texto.year || !this.pago_venta.fecha_texto.month || !this.pago_venta.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el pago!");
    }
    return res;
  }

  efectuarPagoVencimientoCredito(){
    this.blockUI.start();
    if(this.validarPagoVenta()){
      this.pago_venta.fecha=Util.convertirObjetoAfecha(this.pago_venta.fecha_texto);
      this.ventasService.guardarPagoVenta(this.venta.id,this.usuario.id_empresa,this.pago_venta).subscribe((res:any) => {
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.pdfService.imprimirReciboPagoVenta(res.pago,this.usuario);
          this.alTerminar.emit(res);
        }
				/*if($scope.usuario.empresa.usar_contabilidad_automatica){
					$scope.contabilizarPago(pago_venta.monto_pagado,$scope.venta_pago.cliente,"Por pago de factura Nº "+$scope.venta_pago.factura,data.pago.id);
				}*/
				this.blockUI.stop();
      });
    }else{
      this.blockUI.stop();
    }
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.pago_venta.comprobante=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.pago_venta.comprobante=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

}
