<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        {{aplicacion.aplicacion.titulos.length>0?aplicacion.aplicacion.titulos[0].titulo:aplicacion.aplicacion.titulo}}
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      {{aplicacion.aplicacion.titulos.length>0?aplicacion.aplicacion.titulos[0].titulo:aplicacion.aplicacion.titulo}}
    </div>
    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
					  <div class="col-md-2 col-sm-12">
						<label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}} </label>&nbsp;
						<span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)"></span>
					</div>
					  <div class="col-md-3 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="filter.grupo">
							  <option [ngValue]="grupo" *ngFor="let grupo of filter.grupos_filtro">{{grupo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-md-1 col-sm-12 pl-0 ml-0">
						<span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_GRUPOS,'alActualizarGrupos')"></span>
					  </div>
					  <div class="col-md-2 col-sm-12">
						<label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)}} </label>&nbsp;
						<span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)"></span>
					</div>
					  <div class="col-md-3 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="subgrupo" [(ngModel)]="filter.subgrupo">
							  <option [ngValue]="subgrupo" *ngFor="let subgrupo of filter.subgrupos_filtro">{{subgrupo.nombre}}</option>
						  </select>
					  </div>
					  <div class="col-md-1 col-sm-12 pl-0 ml-0">
						<span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_SUBGRUPOS,'obtenerSubGruposProductosEmpresa')"></span>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoProducto()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
                <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				</button>
				<button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-productos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-productos" style="display:none" type="file" class="form-control" (change)="subirExcelProductos($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
                <!--<div class="dropdown-menu dropdown-menu-right" style="">
                  <a class="dropdown-item " href="#" data-type="json">JSON</a>
                  <a class="dropdown-item " href="#" data-type="xml">XML</a>
                  <a class="dropdown-item " href="#" data-type="csv">CSV</a>
                  <a class="dropdown-item " href="#" data-type="txt">TXT</a>
                  <a class="dropdown-item " href="#" data-type="sql">SQL</a>
                  <a class="dropdown-item " href="#" data-type="excel">MS-Excel</a>
                </div>-->
			  </div>
			  <div class="keep-open btn-group show" title="Columns">
				<button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
					<i class="fa fa-wrench text-blue-d1"></i>
					<span class="caret"></span>
				</button>

				<div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
					<div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
						<div class="card bgc-primary brc-primary radius-0">
						  <div class="card-header">
							<h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
						  </div>
	  
						  <div class="card-body p-0 bg-white">
							<table class="table table-striped table-hover mb-0">
							  <tbody>
								<tr *ngIf="aplicacion.puede_modificar">
									<td class="text-dark-m2">
									Configuración Aplicación
									</td>
									<td class="text-dark-m2">
										<a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
									</td>
								</tr>
								<tr *ngIf="usuario.empresa.usar_precios_productos || usuario.empresa.usar_precios_productos_sucursal">
								  <td class="text-dark-m2">
									Importación precios
								  </td>
								  <td class="text-dark-m2">
									<a (click)="descargarExcelProductosPrecios()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
								  </td>
								  <td>
									<button (click)="clickBotonSubir('subir-excel-precios')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel precios" aria-expanded="false">
										<i class="fa fa-upload text-blue fileUpload-button"></i>
										<input id="subir-excel-precios" (change)="subirExcelProductosPrecios($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".xlsx">
									</button>
								  </td>
								</tr>
								<tr>
									<td class="text-dark-m2">
										Descarga catálogo
									</td>
									<td class="text-dark-m2">
										<a (click)="descargarExcelProductos()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
									</td>
									<td>
										<a (click)="descargarCatalogoPdfProductos()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
									</td>
								</tr>
								<tr *ngIf="usuario.empresa.usar_generador_codigos_barras">
									<td class="text-dark-m2">
										Generador de Códigos de barras
									</td>
									<td class="text-dark-m2">
									  <a title="Generador" href="javascript:void(0)" (click)="convertirSvgACanvas()"  class="text-primary"><i class="fa fa-barcode text-primary"></i></a>
									</td>
								</tr>
								<tr *ngIf="usuario.empresa.usar_sucursales && usuario.empresa.usar_productos_sucursal">
									<td class="text-dark-m2">
										Generador de valores iniciales Productos Sucursales
									</td>
									<td class="text-dark-m2">
										<a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirPopupConfirmacionGeneradorValoresInicialesProductosSucursales()">
											<i class="fas fa-store text-105"></i>        
										</a>
									</td>
								</tr>
								<tr *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
									<td class="text-dark-m2">
										Productos-Servicios S.I.N.
									</td>
									<td class="text-dark-m2">
									  <a href="javascript:void(0)" class="text-primary" (click)="abrirListaProductosSin()"><i class="fa fa-gift text-primary"></i></a>
									</td>
								</tr>
								<tr *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
									<td class="text-dark-m2">
										Relacion Productos S.I.N.
									</td>
									<td class="text-dark-m2">
										<a href="javascript:void(0)" class="text-orange" (click)="abrirListaProductosRelacionSin()"><i class="fa fa-sync text-orange"></i></a>
									</td>
								</tr>
							  </tbody>
							</table>
						  </div>
						</div>
					  </div>
				</div>
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="productos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th *ngIf="!usuario.empresa.usar_productos_sucursal && configuracion_pagina.getConfiguration().publicado_tienda.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('publicar_tienda')">¿Publicado Tienda? 
						<span id="publicar_tienda" class="sort fa fa-sort icon-only"></span>
					</a>
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(null,'publicar_tienda',tabla_producto_general.publicar_tienda)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.publicar_tienda"/>
					</label>
				</th>
				<th *ngIf="!usuario.empresa.usar_productos_sucursal && configuracion_pagina.getConfiguration().publicado_panel.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('publicar_panel')">¿Publicado Panel? 
						<span id="publicar_panel" class="sort fa fa-sort icon-only"></span>
					</a>
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(null,'publicar_panel',tabla_producto_general.publicar_panel)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.publicar_panel"/>
					</label>
				</th>
				<th *ngIf="!usuario.empresa.usar_productos_sucursal && usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_activado.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('activar_inventario')">¿Inventario Activado? 
						<span id="activar_inventario" class="sort fa fa-sort icon-only"></span>
					</a>
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(null,'activar_inventario',tabla_producto_general.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.activar_inventario"/>
					</label>
				</th>
				<th *ngIf="!usuario.empresa.usar_productos_sucursal && usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().vencimiento_activado.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('activar_vencimiento')">
						¿Vencimiento Activado? <span id="activar_vencimiento" class="sort fa fa-sort icon-only"></span>
					</a>
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(null,'activar_vencimiento',tabla_producto_general.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.activar_vencimiento"/>
					</label>
				</th>
				<th *ngIf="usuario.empresa.usar_produccion && configuracion_pagina.getConfiguration().producible.show" class="center">
					<a href="javascript:void(0)" (click)="this.sortColumn('producible')">
						¿Producible? <span id="producible" class="sort fa fa-sort icon-only"></span>
					</a>
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(null,'producible',tabla_producto_general.producible)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="tabla_producto_general.producible"/>
					</label>
				</th>
								<th id="cabecera_codigo" class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().codigo.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                    					<a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                  					</div><div class="fht-cell"></div>
								</th>
								<th id="cabecera_nombre" class="center" *ngIf="configuracion_pagina.getConfiguration().nombre.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
										<span *ngIf="aplicacion.puede_modificar" class="fa fa-edit point-edit blue" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_NOMBRE)"></span> &nbsp;
										<a href="javascript:void(0)" (click)="this.sortColumn('nombre')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_NOMBRE)}}</a>
									</div><div class="fht-cell"></div>
								</th>
								<th *ngIf="configuracion_pagina.getConfiguration().imagen.show">
									<div class="th-inner ">
										Imagen
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().unidad_medida.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='unidad_medida' && this.direction=='asc','desc':this.column=='unidad_medida' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('unidad_medida')">Unid. de Med.</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='precio_unitario' && this.direction=='asc','desc':this.column=='precio_unitario' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('precio_unitario')">P. Unit.</a>
									</div><div class="fht-cell"></div>
								</th>
								<th *ngIf="usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_minimo.show" class="center">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='inventario_minimo' && this.direction=='asc','desc':this.column=='inventario_minimo' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('inventario_minimo')">Inventario Mínimo</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones || usuario.empresa.usar_hoteles) && configuracion_pagina.getConfiguration().descripcion.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='descripcion' && this.direction=='asc','desc':this.column=='descripcion' && this.direction=='desc'}">
										<span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)"></span>&nbsp;
										<a href="javascript:void(0)" (click)="this.sortColumn('descripcion')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_DESCRIPCION)}}</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="usuario.empresa.usar_consumos && configuracion_pagina.getConfiguration().tipo_producto.show"><a href="javascript:void(0)" (click)="paginator.sortColumn('tipo_producto')">Tipo <span id="tipo_producto" class="sort fa fa-sort icon-only"></span></a></th>
								<th class="center" *ngIf="configuracion_pagina.getConfiguration().grupo.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='grupo' && this.direction=='asc','desc':this.column=='grupo' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('grupo')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}}</a>&nbsp;
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones || usuario.empresa.usar_consumo_de_agua || usuario.empresa.usar_hoteles) && configuracion_pagina.getConfiguration().sub_grupo.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='subgrupo' && this.direction=='asc','desc':this.column=='subgrupo' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('subgrupo')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_SUBGRUPO)}}</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="configuracion_pagina.getConfiguration().codigo_fabrica.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo_fabrica' && this.direction=='asc','desc':this.column=='codigo_fabrica' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('codigo_fabrica')">Código Fábrica</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="usuario.empresa.usar_pantalla_despacho && configuracion_pagina.getConfiguration().fuente_despacho.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='fuente_despacho' && this.direction=='asc','desc':this.column=='fuente_despacho' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('fuente_despacho')">Fuente Despacho</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && configuracion_pagina.getConfiguration().producto_sin.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='producto_sin' && this.direction=='asc','desc':this.column=='producto_sin' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('producto_sin')">Producto S.I.N.</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && configuracion_pagina.getConfiguration().sin_unidad_medida.show">
									<div class="th-inner sortable both " [ngClass]="{'asc':this.column=='producto_sin' && this.direction=='asc','desc':this.column=='sin_unidad_medida' && this.direction=='desc'}">
										<a href="javascript:void(0)" (click)="this.sortColumn('sin_unidad_medida')">Unid. Med. S.I.N.</a>
									</div><div class="fht-cell"></div>
								</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">UND-MD</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Kcal</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Hum(%)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Prot(g)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Lip.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Glu.(g)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Fib.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Ca.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-P.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Fe.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-No.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-k.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-Zn.(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-A.(mcg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-B1(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-B2(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-PP(mg)</th>
								<th class="center" *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">MD-C(mg)</th>
								<th style="text-align: center; width: 140px; " data-field="tools">
									<div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
									<div class="fht-cell"></div>
								</th>
              </tr>
            </thead>
            <tbody>
              <tr #productos *ngFor="let producto of productos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
				<td *ngIf="!usuario.empresa.usar_productos_sucursal && configuracion_pagina.getConfiguration().publicado_tienda.show">
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'publicar_tienda',producto.publicar_tienda)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.publicar_tienda"/>
					</label>
				</td>
				<td *ngIf="!usuario.empresa.usar_productos_sucursal && configuracion_pagina.getConfiguration().publicado_panel.show">
					<label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'publicar_panel',producto.publicar_panel)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.publicar_panel"/>
					</label>
				</td>
				<td *ngIf="!usuario.empresa.usar_productos_sucursal && usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_activado.show"><label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'activar_inventario',producto.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_inventario"/>
					</label>
				</td>
				<td *ngIf="!usuario.empresa.usar_productos_sucursal && usuario.empresa.usar_vencimientos && configuracion_pagina.getConfiguration().vencimiento_activado.show"><label>
						<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'activar_vencimiento',producto.activar_vencimiento)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.activar_vencimiento"/>
					</label>
				</td>
				<td *ngIf="usuario.empresa.usar_produccion && configuracion_pagina.getConfiguration().producible.show"><label>
					<input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoProducto(producto,'producible',producto.producible)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto.producible"/>
					</label>
				</td>
								<td rel="cabecera_codigo" *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().codigo.show">
									{{producto.codigo}}
								</td>
								<td rel="cabecera_nombre" *ngIf="configuracion_pagina.getConfiguration().nombre.show">
									{{producto.nombre}}
								</td>
								<td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
									<div class="imagen-producto-vista clearfix">
										<a href="{{rest_server+producto.imagen}}" data-rel="colorbox" title="{{producto.nombre}}">
											<img alt="sin imagen" src="{{rest_server+producto.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
										</a>
									</div>
								</td>
								<td *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones) && configuracion_pagina.getConfiguration().unidad_medida.show">{{producto.unidad_medida}}</td>
								<td *ngIf="configuracion_pagina.getConfiguration().precio_unitario.show">{{producto.precio_unitario}}</td>
	
								<td *ngIf="usuario.empresa.usar_inventario && configuracion_pagina.getConfiguration().inventario_minimo.show">
									{{producto.inventario_minimo}}
								</td>
								<td *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones || usuario.empresa.usar_hoteles) && configuracion_pagina.getConfiguration().descripcion.show">
									{{producto.descripcion}}
								</td>
								<td *ngIf="usuario.empresa.usar_consumos && configuracion_pagina.getConfiguration().tipo_producto.show">
									{{producto.tipo_producto}}
								</td>
								<td *ngIf="configuracion_pagina.getConfiguration().grupo.show">
									{{producto.grupo}}
								</td>
								<td *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones || usuario.empresa.usar_consumo_de_agua || usuario.empresa.usar_hoteles) && configuracion_pagina.getConfiguration().sub_grupo.show">
									{{producto.subgrupo}}
								</td>
								<td *ngIf="configuracion_pagina.getConfiguration().codigo_fabrica.show">
									{{producto.codigo_fabrica}}
								</td>
								<td *ngIf="usuario.empresa.usar_pantalla_despacho && configuracion_pagina.getConfiguration().fuente_despacho.show">
									{{producto.fuente_despacho}}
								</td>
								<td *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && configuracion_pagina.getConfiguration().producto_sin.show">
									{{producto.producto_sin}}
								</td>
								<td *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea && configuracion_pagina.getConfiguration().sin_unidad_medida.show">
									{{producto.sin_unidad_medida}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_und_md}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_kcal}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_hum}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_prot}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_lip}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_glu}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_fib}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_ca}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_p}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_fe}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_no}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_k}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_zn}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_a}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_b1}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_b2}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_pp}}
								</td>
								<td *ngIf="usuario.empresa.usar_medicina_dietas && configuracion_pagina.getConfiguration().propiedades.show">
									{{producto.dieta_c}}
								</td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarProducto(producto)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					</a>       
					<a *ngIf="usuario.empresa.usar_kardex_inventario_productos && usuario.empresa.usar_inventario && producto.activar_inventario" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="generarKardexProducto(producto)">          
						<i class="fa fa-eye text-105"></i>        
					</a>
					<a title="Asignación Ingredientes" *ngIf="usuario.empresa.usar_pedidos_en_linea" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="asignarIngredientes(producto)">          
						<i class="fas fa-list-ol text-105"></i>        
					</a>
					<a *ngIf="usuario.empresa.usar_sucursales && usuario.empresa.usar_productos_sucursal" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirRegistroProductoSucursal(producto)">          
						<i class="fas fa-store text-105"></i>        
					</a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(producto)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="productos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<ng-template #importacionproductos let-modal>
	<div class="modal-header">
		<div class="col-md-3 col-3">
			<h4 class="widget-title">Productos a importar</h4>
		</div>
		<ng-container *ngIf="usuario.empresa.usar_sucursales && usuario.empresa.usar_inventario">
			<div class="col-md-3 col-3">
				<label>Sucursal </label>
				<select [compareWith]="compararObjectos" (change)="obtenerAlmacenesSucursal(filter.sucursal)" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
					<option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
				</select>
			</div>
			<div class="col-md-3 col-3">
				<label>Almacen </label>
				<select [compareWith]="compararObjectos" class="form-control" required name="almacen" [(ngModel)]="filter.almacen">
					<option [ngValue]="almacen" *ngFor="let almacen of filter.almacenes">{{almacen.nombre}}</option>
				</select>
			</div>
		</ng-container>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || productos_importacion.length==0" (click)="guardarProductos()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color">
								<tr>
									<th>Nº</th>
									<th>Código</th>
									<th>Producto</th>
									<th *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">Unidad de Medida</th>
									<th *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">Precio Unitario</th>
									<th *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">Descripción</th>
									<th *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">Código Fábrica</th>
									<th>Grupo</th>
									<th>Subgrupo</th>
									<th *ngIf="usuario.empresa.usar_inventario_minimo">Inv. Mínimo</th>
									<th *ngIf="usuario.empresa.usar_inventario">Costo Unitario</th>
									<th *ngIf="usuario.empresa.usar_inventario">Cantidad</th>
									<th *ngIf="usuario.empresa.usar_vencimientos">Fecha Venc.</th>
									<th *ngIf="usuario.empresa.usar_vencimientos">Lote</th>
									<th *ngIf="usuario.empresa.usar_vencimientos">Reg. Sanitario</th>
									<th *ngIf="usuario.empresa.usar_vencimientos">Grupo 3</th>
								</tr>
							</thead>
		
							<tbody>
								<tr *ngFor="let producto of productos_importacion; let i=index;">
									<td>{{i+1}}</td>
									<td>{{producto.codigo}}</td>
									<td>{{producto.nombre}}</td>
									<td *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">{{producto.unidad_medida}}</td>
									<td *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">{{producto.precio_unitario.toFixed(2)}}</td>
									<td *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">{{producto.descripcion}}</td>
									<td *ngIf="usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones">{{producto.codigo_fabrica}}</td>
									<td>{{producto.grupo}}</td>
									<td>{{producto.subgrupo}}</td>
									<td *ngIf="usuario.empresa.usar_inventario_minimo">{{producto.inventario_minimo}}</td>
									<td *ngIf="usuario.empresa.usar_inventario">{{producto.costo_unitario}}</td>
									<td *ngIf="usuario.empresa.usar_inventario">{{producto.cantidad}}</td>
									<td *ngIf="usuario.empresa.usar_vencimientos">{{producto.fecha_vencimiento | date:'dd/MM/yyyy'}}</td>
									<td *ngIf="usuario.empresa.usar_vencimientos">{{producto.lote}}</td>
									<td *ngIf="usuario.empresa.usar_vencimientos">{{producto.registro_sanitario}}</td>
									<td *ngIf="usuario.empresa.usar_vencimientos">{{producto.grupo_3}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #importacion_productos_precios let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Precios de Productos a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || precios_productos.length==0" (click)="guardarPreciosProductos()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color sticky-nav">
								<tr>
									<th>Nº</th>
									<th>Código</th>
									<th>Producto</th>
									<th>Unidad de Medida</th>
									<th>Concepto de precio</th>
									<th>Precio Unitario</th>
									<th>Por Defecto</th>
								</tr>
							</thead>
		
							<tbody>
								<tr *ngFor="let producto of precios_productos; let i=index;">
									<td>{{i+1}}</td>
									<td>{{producto.codigo}}</td>
									<td>{{producto.nombre}}</td>
									<td>{{producto.unidad_medida}}</td>
									<td>{{producto.concepto}}</td>
									<td>{{producto.precio_unitario}}</td>
									<td>{{producto.por_defecto?'SI':'NO'}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #modal_registro_producto_sucursal let-modal>
	<div class="modal-header">
		<div class="col-8">
			<h4 class="widget-title">Registro Producto Sucursal: {{producto.nombre}}</h4>
		</div>
		<div class="col-4">
			<button class="btn btn-primary" type="button" (click)="actualizarProductoSucursal()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-sm-6 col-md-6">
				<label>Sucursal</label>
			</div>
			<div class="col-sm-6 col-md-6">
				<select (change)="obtenerDatosProductoSucursal(producto)" [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
					<option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
				</select>
			</div>
		</div>
		<ng-container *ngIf="usuario.empresa.usar_precios_productos_sucursal">
			<hr>
			<div class="row">
				<div class="col-4">
					<label>Concepto</label>
					<select [compareWith]="compararObjectos" class="form-control" required name="id_concepto" [(ngModel)]="producto_sucursal.precios[0].concepto">
						<option [ngValue]="precios_producto" *ngFor="let precios_producto of tipo_producto_precio.clases">{{precios_producto.nombre}}</option>
					</select>
				</div>
				<div class="col-4">
					<label>Precio Unitario</label>
					<input type="number" name="precio_unitario_sucursal" [(ngModel)]="producto_sucursal.precios[0].precio_unitario" placeholder="Precio Unitario" [ngClass]="{'brc-danger-m1':(!producto.precio_unitario)}" step="0.001" min="0" class="form-control form-control-lg col-12 pl-25"  />
				</div>
				<div class="col-4">
					<label>¿Por Defecto?</label>
					<label>
						<input name="switch-field-6" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_sucursal.precios[0].por_defecto"/>
					</label>
				</div>
			</div>
		</ng-container>
		<hr>
		<div class="row">
			<div class="col-3">
				<label>¿Activar Inventario?</label>
			</div>
			<div class="col-3">
				<label>
					<input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_sucursal.productos_sucursal[0].activar_inventario"/>
				</label>
			</div>
			<ng-container *ngIf="usuario.empresa.usar_vencimientos">
				<div class="col-3">
					<label>¿Activar Vencimiento?</label>
				</div>
				<div class="col-3">
					<label>
						<input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_sucursal.productos_sucursal[0].activar_vencimiento"/>
					</label>
				</div>
			</ng-container>
			<div class="col-3">
				<label>¿Publicar Panel?</label>
			</div>
			<div class="col-3">
				<label>
					<input name="switch-field-3" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_sucursal.productos_sucursal[0].publicar_panel"/>
				</label>
			</div>
			<!--<div class="col-3">
				<label>¿Publicar Tienda?</label>
			</div>
			<div class="col-3">
				<label>
					<input name="switch-field-4" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_sucursal.productos_sucursal[0].publicar_tienda"/>
				</label>
			</div>-->
		</div>
	</div>
</ng-template>

<ng-template #lista_productos_sin let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Productos Servicios S.I.N.</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="sincronizarProductosSin()">
				<i class="fa fa-sync align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<!--<div class="row">
			<div class="col-sm-3 col-md-3">
				<label>Descripción</label>
			</div>
			<div class="col-sm-6 col-md-6">
				<input [(ngModel)]="producto_servicio_sin.descripcion" type="text" class="form-control" name="desc" placeholder="Descripción">
			</div>
			<div class="col-sm-3 col-md-3">
				<button class="btn btn-primary" type="button" (click)="guardarProductoServicioSin()">
					<i class="fa fa-save align-top bigger-110"></i>
				</button>
			</div>
		</div>
		<hr>-->
		<div class="table-fixed-header">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>Nº</th>
						<th>Codigo Actividad</th>
						<th>Codigo S.I.N.</th>
						<th>Descripción</th>
						<th>Código Solicitud</th>
						<th>Estado</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let producto_servicio_sin of productos_servicios_sin | orderBy:'updatedAt':true; let i=index;">
						<th>{{i+1}}</th>
						<th>{{producto_servicio_sin.codigo_actividad}}</th>
						<td>{{producto_servicio_sin.codigo_producto_servicio}}</td>
						<td>{{producto_servicio_sin.descripcion}}</td>
						<td>{{producto_servicio_sin.codigo_solicitud}}</td>
						<td *ngIf="producto_servicio_sin.activo">
							<span class="badge badge-sm badge-success">Activo</span>
						</td>
						<td *ngIf="!producto_servicio_sin.activo">
							<span class="badge badge-sm badge-danger">Inactivo</span>
						</td>
						<td>
							<a ng-if="!producto_servicio_sin.activo" class="blue" href="javascript:void(0)" ng-click="consultarSolicitud(producto_servicio_sin)">
								<i class="ace-icon fa fa-refresh bigger-130"></i>
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>

<ng-template #relacion_productos_sin let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Productos Servicios Relación S.I.N.</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="guardarRelacionProductosSin()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="table-fixed-header">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>Nº</th>
						<th>Codigo</th>
						<th>Nombre</th>
						<th>Descripción</th>
						<th>Relación</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let producto of productos_relacion; let i=index;">
						<th>{{i+1}}</th>
						<th>{{producto.codigo}}</th>
						<td>{{producto.nombre}}</td>
						<td>{{producto.descripcion}}</td>
						<td>
							<select [attr.name]="producto.id" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="producto.producto_sin">
								<option [value]="producto_servicio_sin" [attr.selected]="(producto.producto_sin && producto.producto_sin.id==producto_servicio_sin.id )? true : null" [ngValue]="producto_servicio_sin" *ngFor="let producto_servicio_sin of productos_servicios_sin">{{(producto_servicio_sin.codigo_actividad+'-'+producto_servicio_sin.codigo_producto_servicio+'-'+producto_servicio_sin.descripcion)}}</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>

<ng-template #modal_registro_ingredientes let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Asignación de Suplementos</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="guardarSuplementosProducto()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="form-row">
            <div class="col-sm-5">
                <div class=" ">
                    <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}}</label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="grupo" [(ngModel)]="suplemento_producto.grupo">
						<option [ngValue]="grupo" *ngFor="let grupo of tipo_grupo.clases">{{grupo.nombre}}</option>
					</select>
                </div>
            </div>
            <div class="col-sm-2">
                <br>
                <div class=" ">
                    <button type="button" class="btn btn-primary" (click)="agregarSuplementoProducto()">
                        <span class="fa fa-arrow-down" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th>Nº</th>
					<th>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PRODUCTO_GRUPO)}}</th>
					<th>Cantidad Maxima Pedido</th>
					<th data-field="tools">
						<div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
						<div class="fht-cell"></div>
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let suplemento of suplementos_producto; let i=index;">
					<tr *ngIf="!suplemento.eliminado">
						<td>{{i+1}}</td>
						<td>{{suplemento.grupo.nombre}}</td>
						<td>
							<input class="form-control" type="number" name="cantidad_maxima_pedido" [(ngModel)]="suplemento.cantidad_maxima_pedido"/>
						</td>
						<td>
							<a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarSuplementoProducto(suplemento)">          
								<i class="fa fa-trash-alt text-105"></i>        
							  </a> 
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>
</ng-template>

<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>