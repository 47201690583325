<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Campeonatos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
        <div class="row">
          <div class="col-md-6 col-12">
            <span class="text-90">Lista de</span>
            Campeonatos
          </div>
          <div class="col-md-6 col-12">
            <div class="columns columns-right btn-group float-right">
              <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoCampeonato()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
                <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                  <i class="fa fa-wrench text-blue-d1"></i>
                  <span class="caret"></span>
                </button>
                <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                  <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                    <div class="card bgc-primary brc-primary radius-0">
                      <div class="card-header">
                      <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                      </div>
            
                      <div class="card-body p-0 bg-white">
                      <table class="table table-striped table-hover mb-0">
                        <tbody>
                          <tr>
                            <td class="text-dark-m2">
                            Configuración Aplicación
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                            </td>
                          </tr>
                          <tr *ngIf="usuario.empresa.usar_mensajeria_redes_sociales">
                            <td class="text-dark-m2">
                            Grupos Whatsapp
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="abrirParticipantesGrupoWhatsapp()" href="javascript:void(0)" class="text-success"><i class="fas fa-user-friends text-purple-d1"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
		  </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="campeonatos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th class="center"><a href="javascript:void(0)">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Logo <span id="imagen" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Nombre <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Fecha Inicio <span id="fecha_inicio" class="sort fa fa-sort icon-only"></span></a></th>
                <th class="center"><a href="javascript:void(0)">Fecha Fin <span id="fecha_inicio" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #campeonatos *ngFor="let campeonato of campeonatos; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td>
                    {{campeonato.codigo}}
                </td>
                <td >
                  <div class="imagen-campeonato-vista clearfix">
                      <a href="{{rest_server+campeonato.logo}}" title="{{campeonato.nombre}}" data-rel="colorbox">
                          <img width="50" height="50" alt="sin imagen" src="{{rest_server+campeonato.logo}}" />
                      </a>
                  </div>
              </td>
                <td>
                    {{campeonato.nombre}}
                </td>
                <td>
                    {{campeonato.fecha_inicio | date:"dd/MM/yyyy"}}
                </td>
                <td>
                    {{campeonato.fecha_fin | date:"dd/MM/yyyy"}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCampeonato(campeonato)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verEquipos(campeonato)">          
                        <i class="fas fa-users-cog text-105"></i>        
                      </a>
                      <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirModalConfiguracionTarjetaJugador(campeonato)">          
                        <i class="fas fa-id-card-alt text-105"></i>        
                      </a>
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirModalGeneracionPdfTarjetasJugador(campeonato)">          
                      <i class="fas fa-id-card text-105"></i>        
                    </a>
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="abrirModalConfiguracionPdfRolPartido(campeonato)">          
                      <i class="fas fa-images text-105"></i>        
                    </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(campeonato)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="campeonatos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modal_campeonato_modificacion let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Campeonato</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarCampeonato()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <label>Código</label>
            </div>
            <div class="col-sm-12 col-md-6">
                <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="campeonato.codigo" placeholder="Código" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Nombre</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input name="nombre" required type="text" [(ngModel)]="campeonato.nombre" placeholder="Nombre" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Fecha Inicio</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <div class="input-group">
                    <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="campeonato.fecha_inicio_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div> 
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Fecha Fin</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <div class="input-group">
                    <input name="fecha_f" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="campeonato.fecha_fin_texto" ngbDatepicker #f="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="f.toggle()" type="button"></button>
                    </div>
                </div> 
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Logo</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                    <div class="fallback d-none">
                      <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                    </div>
    
                    <div class="dz-default dz-message">
                      <span class="text-150  text-grey-d2">
                      <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                      para subir
                      <span class="text-90 text-grey-m1">(o click)</span>
                      <br />
                      <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                      </span>
                    </div>
                  </form>
    
                  <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                  <div id="preview-template" class="d-none">
                    <div class="dz-preview dz-file-preview">
                      <div class="dz-image">
                        <img alt="Archivo" data-dz-thumbnail="" />
                      </div>
    
                      <div class="dz-details">
                        <div class="dz-size">
                          <span data-dz-size=""></span>
                        </div>
    
                        <div class="dz-filename">
                          <span data-dz-name=""></span>
                        </div>
                      </div>
    
                      <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                      </div>
    
                      <div class="dz-error-message">
                        <span data-dz-errormessage=""></span>
                      </div>
    
                      <div class="dz-success-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-white"></i>
                          <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                      </span>
                      </div>
    
                      <div class="dz-error-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                          <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                      </span>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Fondo Tabla Posiciones</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <form id="fondo-tabla" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                    <div class="fallback d-none">
                      <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                    </div>
    
                    <div class="dz-default dz-message">
                      <span class="text-150  text-grey-d2">
                      <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                      para subir
                      <span class="text-90 text-grey-m1">(o click)</span>
                      <br />
                      <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                      </span>
                    </div>
                  </form>
    
                  <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                  <div id="fondo-tabla-template" class="d-none">
                    <div class="dz-preview dz-file-preview">
                      <div class="dz-image">
                        <img alt="Archivo" data-dz-thumbnail="" />
                      </div>
    
                      <div class="dz-details">
                        <div class="dz-size">
                          <span data-dz-size=""></span>
                        </div>
    
                        <div class="dz-filename">
                          <span data-dz-name=""></span>
                        </div>
                      </div>
    
                      <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                      </div>
    
                      <div class="dz-error-message">
                        <span data-dz-errormessage=""></span>
                      </div>
    
                      <div class="dz-success-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-white"></i>
                          <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                      </span>
                      </div>
    
                      <div class="dz-error-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                          <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                      </span>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_lista_equipos let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">{{campeonato.nombre}}: Lista de Equipos</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="crearNuevoEquipo(campeonato)" class="btn btn-success"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-12">
            <table *ngIf="equipos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
              <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                  <th>N°</th>
                  <th class="center">Código</th>
                  <th class="center">Logo</th>
                  <th class="center">Nombre</th>
                  <th class="center">Disciplina</th>
                  <th class="center">Nivel</th>
                  <th class="center">Categoria</th>
                  <th style="text-align: center; width: 140px; " data-field="tools">
                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                    <div class="fht-cell"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr #campeonatos *ngFor="let equipo of equipos; let i=index;">
                  <td>
                    {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                  </td>
                  <td>
                      {{equipo.codigo}}
                  </td>
                  <td >
                    <img width="50" height="50" alt="sin imagen" src="{{rest_server+equipo.logo}}" />
                  </td>
                  <td>
                      {{equipo.nombre}}
                  </td>
                  <td>
                      {{equipo.disciplina?equipo.disciplina.nombre:''}}
                  </td>
                  <td>
                    {{equipo.nivel.nombre}}
                  </td>
                  <td>
                    {{equipo.categoria.nombre}}
                  </td>
                  <td style="text-align: center; width: 140px; ">
                    <div class="action-buttons">        
                      <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEquipo(equipo)">          
                        <i class="fa fa-pencil-alt text-105"></i>        
                      </a>
                      <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verJugadores(equipo)">          
                          <i class="fas fa-walking text-105"></i>        
                        </a>
                      <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" type="button" (click)="enviarPendientesEconomicosEquipo(equipo)">
                          <i class="fab fa-whatsapp red2 text-110"></i>
                      </a>
                      <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(campeonato)">          
                        <i class="fa fa-trash-alt text-105"></i>        
                      </a>      
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_equipo_modificacion let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Registro Equipo</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="guardarEquipo()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-sm-12 col-md-6">
              <label>Código</label>
          </div>
          <div class="col-sm-12 col-md-6">
              <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="equipo.codigo" placeholder="Código" class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Nombre</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input name="nombre" required type="text" [(ngModel)]="equipo.nombre" placeholder="Nombre" class="form-control" />
          </div>
      </div>
      <hr>
      <ng-container *ngIf="cliente_registrado">
        <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Buscar Cliente</label>
          </div>
          <div class="col-sm-12 col-md-6">
            <ng-container *ngIf="equipo.cliente.id">
              <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{equipo.cliente.razon_social}}</span><br>
              <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{equipo.cliente.identificacion}}</span>
            </ng-container>
            <input *ngIf="!equipo.cliente.id" name="cliente_razon_social" [inputFormatter]="formatter" [resultTemplate]="rt" 
            (selectItem)="establecerCliente($event)" type="text" class="form-control" autocomplete="off"
            [(ngModel)]="razon_busqueda" [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" />
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
            </ng-template>
          </div>
          <div class="col-sm-12 col-md-1">
            <a *ngIf="equipo.cliente.id" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
              <i class="fa fa-edit bigger-130"></i>
            </a>
            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" *ngIf="equipo.cliente.id" (click)="reiniciarCliente()">
              <i class="fa fa-trash bigger-130 red2"></i>
            </a>
          </div>
          <div class="col-sm-12 col-md-1">
            <a (click)="cambiarClienteRegistrado()" href="javascript:void(0)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
              <i class="fas fa-exchange-alt"></i>
            </a>
          </div>
        </div>
        <hr>
      </ng-container>

      <ng-container *ngIf="!cliente_registrado">
        <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Representante</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <input name="representante" required type="text" [(ngModel)]="equipo.cliente.razon_social" placeholder="Delegado" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-1">
            <a (click)="cambiarClienteRegistrado()" href="javascript:void(0)" class="text-blue"><i class="fas fa-exchange-alt"></i></a>
          </div>
        </div>
        <hr>
      </ng-container>
      <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Teléfono Móvil</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="telefono movil" required type="text" [(ngModel)]="equipo.cliente.telefono1" placeholder="Teléfono Móvil" class="form-control" />
        </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Disciplina</label>
          </div>
          <div class="col-sm-12 col-md-7">
            <select [compareWith]="compararObjectos" class="form-control" required name="disciplina" [(ngModel)]="equipo.disciplina">
              <option [ngValue]="disciplina" *ngFor="let disciplina of tipo_disciplina.clases">{{disciplina.nombre}}</option>
            </select> 
          </div>
          <div class="col-sm-12 col-md-1">
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_EQUIPO_DISCIPLINAS,'obtenerDisciplinasEquiposEmpresa')"></span>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Nivel</label>
          </div>
          <div class="col-sm-12 col-md-7">
            <select [compareWith]="compararObjectos" class="form-control" required name="nivel" [(ngModel)]="equipo.nivel">
              <option [ngValue]="nivel" *ngFor="let nivel of tipo_nivel.clases">{{nivel.nombre}}</option>
            </select> 
          </div>
          <div class="col-sm-12 col-md-1">
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_EQUIPO_NIVELES,'obtenerNivelesEquiposEmpresa')"></span>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Categoría</label>
          </div>
          <div class="col-sm-12 col-md-7">
            <select [compareWith]="compararObjectos" class="form-control" required name="categoria" [(ngModel)]="equipo.categoria">
              <option [ngValue]="categoria" *ngFor="let categoria of tipo_categoria.clases">{{categoria.nombre}}</option>
            </select> 
          </div>
          <div class="col-sm-12 col-md-1">
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_EQUIPO_CATEGORIAS,'obtenerCategoriasEquiposEmpresa')"></span>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Logo</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <form id="logo-equipo" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                  <div class="fallback d-none">
                    <input name="file" type="file" multiple (change)="subirArchivoLogo($event)"/>
                  </div>
  
                  <div class="dz-default dz-message">
                    <span class="text-150  text-grey-d2">
                    <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                    para subir
                    <span class="text-90 text-grey-m1">(o click)</span>
                    <br />
                    <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                    </span>
                  </div>
                </form>
  
                <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                <div id="preview-template-logo" class="d-none">
                  <div class="dz-preview dz-file-preview">
                    <div class="dz-image">
                      <img alt="Archivo" data-dz-thumbnail="" />
                    </div>
  
                    <div class="dz-details">
                      <div class="dz-size">
                        <span data-dz-size=""></span>
                      </div>
  
                      <div class="dz-filename">
                        <span data-dz-name=""></span>
                      </div>
                    </div>
  
                    <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                      <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                    </div>
  
                    <div class="dz-error-message">
                      <span data-dz-errormessage=""></span>
                    </div>
  
                    <div class="dz-success-mark">
                      <span class="fa-stack fa-lg text-150">
                        <i class="fa fa-circle fa-stack-2x text-white"></i>
                        <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                    </span>
                    </div>
  
                    <div class="dz-error-mark">
                      <span class="fa-stack fa-lg text-150">
                        <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                        <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                    </span>
                    </div>
                  </div>
                </div>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_lista_jugadores let-modal>
  <div class="modal-header card-header">
      <div class="col-7">
          <span class="text-blue text-125">{{equipo.nombre}}: Lista de Jugadores</span>
      </div>
      <div class="col-5">
          <button (click)="crearNuevoJugador(equipo)" class="btn btn-success"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button [disabled]="selectedItems.length==0" (click)="generarTarjetaIdentificacion(selectedItems)" class="btn btn-warning"  >
            <i class="fas fa-id-card text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-12">
            <table *ngIf="jugadores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
              <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                  <th class="text-center pr-0">
                    <label class="py-0">
                      <input [(ngModel)]="allItemsSelected" (change)="selectAllItems(jugadores)" type="checkbox" class="align-bottom mb-n1 border-2 text-dark-m3" />
                    </label>
                  </th>
                  <th>N°</th>
                  <th class="center">Código</th>
                  <th class="center">Foto</th>
                  <th class="center">Nombre</th>
                  <th class="center">Posición</th>
                  <th style="text-align: center; width: 140px; " data-field="tools">
                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                    <div class="fht-cell"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr #campeonatos *ngFor="let jugador of jugadores; let i=index;">
                  <td class='text-center pr-0 pos-rel'>
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-orange-m1 v-hover">
                      <!-- border shown on hover -->
                    </div>
                    <div class="position-tl h-100 ml-n1px border-l-4 brc-success-m1">
                      <!-- border shown when row is selected -->
                    </div>
  
                    <label>
                      <input [(ngModel)]="jugador.selected" (change)="addItemToSelected(jugador)" type="checkbox" class="align-middle" />
                    </label>
                  </td>
                  <td>
                    {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                  </td>
                  <td>
                      {{jugador.codigo}}
                  </td>
                  <td >
                    <img width="50" height="50" alt="sin imagen" src="{{rest_server+jugador.persona.imagen}}" />
                  </td>
                  <td>
                      {{jugador.persona.nombre_completo}}
                  </td>
                  <td>
                      {{jugador.posicion}}
                  </td>
                  <td style="text-align: center; width: 140px; ">
                    <div class="action-buttons">        
                      <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarJugador(jugador)">          
                        <i class="fa fa-pencil-alt text-105"></i>        
                      </a>
                      <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionJugador(jugador)">          
                        <i class="fa fa-trash-alt text-105"></i>        
                      </a>      
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #modal_jugador_modificacion let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Registro Jugador</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="guardarJugador()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-sm-12 col-md-6">
              <label>Código</label>
          </div>
          <div class="col-sm-12 col-md-6">
              <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="jugador.codigo" placeholder="Código" class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Nombre</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input name="nombre" required type="text" [(ngModel)]="jugador.persona.nombre_completo" placeholder="Nombre" class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>C.I.</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input name="ci" required type="text" [(ngModel)]="jugador.persona.ci" placeholder="C.I." class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Fecha de Nacimiento</label>
          </div>
          <div class="col-sm-12 col-md-9">
            <div class="input-group">
              <input name="fecha_nacimiento_texto" class="form-control" placeholder="dd/mm/yyyy"
                      name="dp" [(ngModel)]="jugador.persona.fecha_nacimiento_texto" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
          </div> 
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Posición</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <input name="posicion" required type="text" [(ngModel)]="jugador.posicion" placeholder="Posición" class="form-control" />
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-3">
              <label>Foto</label>
          </div>
          <div class="col-sm-12 col-md-9">
              <form id="foto-jugador" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                  <div class="fallback d-none">
                    <input name="file" type="file" multiple (change)="subirArchivoLogo($event)"/>
                  </div>
  
                  <div class="dz-default dz-message">
                    <span class="text-150  text-grey-d2">
                    <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                    para subir
                    <span class="text-90 text-grey-m1">(o click)</span>
                    <br />
                    <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                    </span>
                  </div>
                </form>
  
                <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                <div id="preview-template-foto" class="d-none">
                  <div class="dz-preview dz-file-preview">
                    <div class="dz-image">
                      <img alt="Archivo" data-dz-thumbnail="" />
                    </div>
  
                    <div class="dz-details">
                      <div class="dz-size">
                        <span data-dz-size=""></span>
                      </div>
  
                      <div class="dz-filename">
                        <span data-dz-name=""></span>
                      </div>
                    </div>
  
                    <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                      <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                    </div>
  
                    <div class="dz-error-message">
                      <span data-dz-errormessage=""></span>
                    </div>
  
                    <div class="dz-success-mark">
                      <span class="fa-stack fa-lg text-150">
                        <i class="fa fa-circle fa-stack-2x text-white"></i>
                        <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                    </span>
                    </div>
  
                    <div class="dz-error-mark">
                      <span class="fa-stack fa-lg text-150">
                        <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                        <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                    </span>
                    </div>
                  </div>
                </div>
          </div>
      </div>
  </div>
</ng-template>
<canvas id="qr-code" style="display:none" width="100" height="100"></canvas>

<ng-template #modal_configuracion_tarjeta_jugador let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Configuración Tarjeta Jugador</span>
      </div>
      <div class="col-md-4 col-xs-4">
          <button (click)="guardarConfiguracionTarjetaJugador()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-2">Fondo</div>
      <div class="col-2">Titulo Liga</div>
      <div class="col-2">Tarjeta Jugador</div>
      <div class="col-2">Enunciados</div>
      <div class="col-2">Datos</div>
    </div>
    <div class="row">
      <div class="col-2">
        <input type="color" name="fondo_color_tarjeta" [(ngModel)]="campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta" class="form-control" />
      </div>
      <div class="col-2">
        <input type="color" name="titulo_color_liga" [(ngModel)]="campeonato.configuracion_tarjeta_jugador.titulo_color_liga" class="form-control" />
      </div>
      <div class="col-2">
        <input type="color" name="titulo_color_jugador" [(ngModel)]="campeonato.configuracion_tarjeta_jugador.titulo_color_jugador" class="form-control" />
      </div>
      <div class="col-2">
        <input type="color" name="enunciados_color" [(ngModel)]="campeonato.configuracion_tarjeta_jugador.enunciados_color" class="form-control" />
      </div>
      <div class="col-2">
        <input type="color" name="datos_color" [(ngModel)]="campeonato.configuracion_tarjeta_jugador.datos_color" class="form-control" />
      </div>
    </div>
    <hr>
    <div class="row p-3" [ngStyle]="{'background-color': campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta}">
      <div class="col-9">
        <label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.titulo_color_liga,'font-size.px': '30'}">{{usuario.empresa.nombre}}</label>
      </div>
      <div class="col-3">
        <img width="80" height="80" alt="sin imagen" src="{{usuario.empresa.imagen}}" />
      </div>
      <div class="col-12 text-center">
        <label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.titulo_color_jugador,'font-size.px': '25'}">TARJETA DE JUGADOR</label>
      </div>
      <div class="col-3">
        <img style="display:block; float: left;" width="100" height="100" alt="sin imagen" src="{{rest_server+'./img/icon-user-default.png'}}" />
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-4"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.enunciados_color,'font-size.px': '20'}">NOMBRE:</label></div>
          <div class="col-8"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.datos_color,'font-size.px': '20'}">JUAN PEREZ</label></div>
        </div>
        <div class="row">
          <div class="col-4"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.enunciados_color,'font-size.px': '20'}">EQUIPO:</label></div>
          <div class="col-8"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.datos_color,'font-size.px': '20'}">TU EQUIPO</label></div>
        </div>
        <div class="row">
          <div class="col-4"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.enunciados_color,'font-size.px': '20'}">CATEGORIA:</label></div>
          <div class="col-8"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.datos_color,'font-size.px': '20'}">SUB-10</label></div>
        </div>
        <div class="row">
          <div class="col-4"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.enunciados_color,'font-size.px': '20'}">TORNEO:</label></div>
          <div class="col-8"><label [ngStyle]="{'color':campeonato.configuracion_tarjeta_jugador.datos_color,'font-size.px': '20'}">COPA ASFINBOL</label></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal_configuracion_pdf_rol_partido let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Configuración Pdf Rol Partido</span>
      </div>
      <div class="col-md-4 col-xs-4">
          <button (click)="guardarConfiguracionPdfRolPartido()" class="btn btn-primary"  >
              <i class="fa fa-save text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-3">Distancia entre partidos</div>
      <div class="col-3">¿Mostrar categoria?</div>
      <div class="col-3">Longitud Logo</div>
      <div class="col-3">Distancia Logo Vertical</div>
    </div>
    <div class="row">
      <div class="col-3">
        <input type="number" step="0" name="distancia" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.distancia_partidos" class="form-control" />
      </div>
      <div class="col-3">
        <label>
          <input name="mostrar_categoria" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.mostrar_categoria"/>
        </label>
      </div>
      <div class="col-3">
        <input type="number" step="0" name="logo_longitud" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.logo_longitud" class="form-control" />
      </div>
      <div class="col-3">
        <input type="number" step="0" name="logo_vertical" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.logo_vertical" class="form-control" />
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">¿Mostrar escenario?</div>
      <div class="col-3">¿Mostrar disciplina?</div>
      <div class="col-3">¿Usar Version Rol?</div>
      <div class="col-3">¿Campeonato Propio?</div>
    </div>
    <div class="row">
      <div class="col-3">
        <label>
          <input name="mostrar_escenario" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.mostrar_escenario"/>
        </label>
      </div>
      <div class="col-3">
        <label>
          <input name="mostrar_disciplina" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.mostrar_disciplina"/>
        </label>
      </div>
      <div class="col-3">
        <select  class="form-control" name="usar_version_rol" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.usar_rol_version">
          <option [ngValue]="version" *ngFor="let version of [1,2]" >{{version}}</option>
        </select>
      </div>
      <div class="col-3">
        <label>
          <input name="campeonato_propio" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="campeonato.configuracion_pdf_rol_partido.campeonato_propio"/>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modal_impresion_tarjetas_jugador let-modal>
  <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Tarjetas de Jugador</span>
      </div>
      <div class="col-md-4 col-xs-4">
          <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-2">
        <label>Jugador</label>
      </div>
      <div class="col-6">
        <ng-container *ngIf="impresion_tarjetas_jugador.jugador.id">
          <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{impresion_tarjetas_jugador.jugador.persona.nombre_completo}}</span><br>
        </ng-container>
        <input id="jugador_texto" name="jugador_texto" *ngIf="!impresion_tarjetas_jugador.jugador.id"
        [inputFormatter]="formatterJugador" [resultTemplate]="rt" (selectItem)="establecerJugador($event)"
        type="text" class="form-control" [(ngModel)]="impresion_tarjetas_jugador.jugador_texto" 
        [ngbTypeahead]="buscarJugador" placeholder="Buscar jugador" autocomplete="off"
        />
        <ng-template #rt let-r="result" let-t="term">
          <ngb-highlight [result]="r.persona.nombre_completo" [term]="t"></ngb-highlight>
        </ng-template>
      </div>
      <div class="col-4 p-0">
        <button *ngIf="impresion_tarjetas_jugador.jugador.id" (click)="reiniciarJugador()" class="btn btn-danger"  >
          <i class="fa fa-trash text-110 mr-1"></i>
        </button>&nbsp;
        <button (click)="agregarJugadorImpresion()" class="btn btn-success"  >
          <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button *ngIf="impresion_tarjetas_jugador.lista_jugadores.length>0" (click)="generarTarjetaIdentificacion(impresion_tarjetas_jugador.lista_jugadores)" class="btn btn-danger"  >
          <i class="fa fa-file-pdf text-110 mr-1"></i>
        </button>
      </div>
    </div>
    <hr>
    <div class="row">
      <table *ngIf="impresion_tarjetas_jugador.lista_jugadores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
        <thead class="bgc-white text-grey text-uppercase text-80">
          <tr>
            <th>N°</th>
            <th class="center">Código</th>
            <th class="center">Foto</th>
            <th class="center">Nombre</th>
            <th class="center">Posición</th>
            <th style="text-align: center; width: 140px; " data-field="tools">
              <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
              <div class="fht-cell"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr #campeonatos *ngFor="let jugador of impresion_tarjetas_jugador.lista_jugadores; let i=index;">
            <td>
              {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
            </td>
            <td>
                {{jugador.codigo}}
            </td>
            <td >
              <img width="50" height="50" alt="sin imagen" src="{{rest_server+jugador.persona.imagen}}" />
            </td>
            <td>
                {{jugador.persona.nombre_completo}}
            </td>
            <td>
                {{jugador.posicion}}
            </td>
            <td style="text-align: center; width: 140px; ">
              <div class="action-buttons">        
                <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarJugadorImpresion(jugador)">          
                  <i class="fa fa-trash-alt text-105"></i>        
                </a>      
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>