import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { CajasService } from '../../services/cajas/cajas.service';
import { RegistroMovimientoCajaComponent } from '../registro-movimiento-caja/registro-movimiento-caja.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-movimientos-caja',
  templateUrl: './movimientos-caja.component.html',
  styleUrls: ['./movimientos-caja.component.css']
})
export class MovimientosCajaComponent extends BaseComponent implements OnInit {

  con_detalle:boolean=true;
  movimientos_caja:any[]=[];
  movimiento_caja:any;

  popup_registro_movimiento:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
  private toastr: ToastrService,
  private pdfService:PdfService,
  public cajasService:CajasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));

    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      cajas:[{nombre:"TODOS",id:0}],
      caja: {nombre:"TODOS",id:0},
      tipos_movimiento:[{nombre:"TODOS",id:0}],
      tipo_movimiento: {nombre:"TODOS",id:0},
      conceptos:[{nombre:"TODOS",id:0}],
      concepto: {nombre:"TODOS",id:0},
      transacciones:[{nombre:"TODOS",id:0}],
      transaccion: {nombre:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
	  activos:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVOS"},{id:2,nombre:"ANULADOS"}],
	  activo: GlobalVariable.Dictionary.SELECCION_TODOS,
    }
	this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_CAJA);

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }

	this.obtenerCajasEmpresa();
	this.obtenerTransacciones();
	this.obtenerTiposMovimientos();
	this.obtenerConceptosMovimiento();

    //this.obtenerTransacciones();
    
    this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
    this.getItems();
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  async obtenerCajasEmpresa(){
    this.blockUI.start();
    let cajas:any=await this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).toPromise();
	this.filter.cajas=this.filter.cajas.concat(cajas);
	this.blockUI.stop();
  }

  obtenerConceptosMovimiento(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_MOVIMIENTOS_CAJA).subscribe((conceptos_movimiento:any)=>{
      this.filter.conceptos=this.filter.conceptos.concat(conceptos_movimiento.clases);
      this.blockUI.stop();
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_PAGOS).subscribe((transacciones:any)=>{
		this.filter.transacciones=this.filter.transacciones.concat(transacciones.clases);
		this.blockUI.stop();
	});	
  }

  async obtenerTiposMovimientos(){
    this.blockUI.start();
    await this.filter.tipos_movimiento.push(await this.generalService.obtenerClases("MOVING").toPromise());
    await this.filter.tipos_movimiento.push(await this.generalService.obtenerClases("MOVEGR").toPromise());
    this.blockUI.stop();
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.cajasService.obtenerListaMovimientosCaja(this).subscribe((entidades:any[]) => {
			this.setPages(1);
			this.movimientos_caja=entidades;
			this.blockUI.stop();
		});
  }

  crearNuevoMovimiento(){
    this.popup_registro_movimiento=this.modalService.open(RegistroMovimientoCajaComponent, {windowClass:'registro-movimiento-caja',ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md'});
    this.popup_registro_movimiento.componentInstance.usuario = this.usuario;
    this.popup_registro_movimiento.componentInstance.alTerminar.subscribe((res) => {
		if(res){
			this.imprimirMovimientoCaja(res.movimiento_caja);
			this.getItems();
		}
      this.popup_registro_movimiento.close();
    });   
  }

  generarPdfMovimientos(){
	this.blockUI.start();
	var titulo="MOVIMIENTOS DE CAJA";
	var fecha_reporte=new Date();
	var doc = new PDFDocument({size:[612,792],margin:0});
	var stream = doc.pipe(blobStream());
	var itemsPorPagina=20;
	var totalPaginas=Math.ceil(this.movimientos_caja.length/itemsPorPagina);	
	var y=150,items=0,pagina=1;
	this.generarCabeceraPdfMovimientos(doc);
	this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
		this.usuario.empresa.imagen,this.usuario.empresa.nombre,"",this.usuario.empresa.direccion?this.usuario.empresa.direccion:"",
		(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
	(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
	(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
	fecha_reporte,{imprimir_usuario:true});
	
	for(var i=0;i<this.movimientos_caja.length;i++){
		doc.font('Helvetica',8);
		doc.rect(50,y-10,540,30).stroke();
		doc.text(i+1,55,y);
		this.movimientos_caja[i].fecha=new Date(this.movimientos_caja[i].fecha);
		doc.text(this.movimientos_caja[i].fecha.getDate()+"/"+(this.movimientos_caja[i].fecha.getMonth()+1)+"/"+this.movimientos_caja[i].fecha.getFullYear(),70,y);
		doc.text(this.movimientos_caja[i].numero_documento,110,y,{width:50});
		if(this.movimientos_caja[i].tipo_movimiento.nombre_corto==GlobalVariable.Dictionary.MOV_ING){
			doc.fillColor('blue');
		}else{
			doc.fillColor('red');
		}
		doc.text(this.movimientos_caja[i].tipo_movimiento.nombre,135,y-3,{width:55});
		doc.fillColor('black');
		let cheque=(this.movimientos_caja[i].transaccion.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE)?(this.movimientos_caja[i].numero_cheque?"Nro:"+this.movimientos_caja[i].numero_cheque:""):"";
		let trans=(this.movimientos_caja[i].concepto.nombre_corto=="TRANSCJ")?(this.movimientos_caja[i].movimiento_caja_origen?"Caja Origen:"+this.movimientos_caja[i].movimiento_caja_origen.caja.nombre:""):"";
		doc.text(this.movimientos_caja[i].concepto.nombre+" "+trans+" "+(this.movimientos_caja[i].concepto.nombre_corto=="TRANSCJ"?"":"("+this.movimientos_caja[i].transaccion.nombre+")")+" "+cheque+")",195,y-9,{width:400});
		let nombre=this.movimientos_caja[i].nombre?(this.movimientos_caja[i].nombre+" CI: "+(this.movimientos_caja[i].ci?this.movimientos_caja[i].ci:"")):"";
		doc.text(nombre,195,y-2,{width:275});
		doc.font('Helvetica',7);
		doc.text(this.movimientos_caja[i].glosa,195,y+5,{width:400});
		doc.font('Helvetica',8);
		doc.text(this.movimientos_caja[i].saldo_anterior.toFixed(2),460,y-8,{width:40,align:"right"});
		if(this.movimientos_caja[i].tipo_movimiento.nombre_corto==GlobalVariable.Dictionary.MOV_ING){
			doc.fillColor('blue');
		}else{
			doc.fillColor('red');
		}
		doc.text(this.movimientos_caja[i].importe.toFixed(2),500,y-8,{width:40,align:"right"});
		doc.fillColor('black');
		doc.text(this.movimientos_caja[i].saldo_posterior.toFixed(2),540,y-8,{width:40,align:"right"});
		y=y+30;
		items++;
	
		if(items==itemsPorPagina){
			doc.addPage({size:[612,792],margin:10});
			y=150;
			items=0;
			pagina=pagina+1;
			this.generarCabeceraPdfMovimientos(doc);
			this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
				this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
				(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
			(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
			(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.global_variable.Dictionary.PAPEL_CARTA,
			fecha_reporte,{imprimir_usuario:true});
		}
	}
	doc.end();
	let me=this;
	stream.on('finish', function() {
	me.blockUI.stop();
		var fileURL = stream.toBlobURL('application/pdf');
		window.open(fileURL,'_blank','location=no');
	});
  }

	generarCabeceraPdfMovimientos(doc){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(360,50,230,45,5).stroke();
		doc.text("DEL : ",385,55);
		doc.text(this.filter.fecha_inicio.day+"/"+this.filter.fecha_inicio.month+"/"+this.filter.fecha_inicio.year,435,55);		
		doc.text("AL : ",490,55);
		doc.text(this.filter.fecha_fin.day+"/"+this.filter.fecha_fin.month+"/"+this.filter.fecha_fin.year,540,55);		
		doc.text("CAJA : "+this.filter.caja.nombre,385,65);
		doc.text("USUARIO : "+this.filter.usuario.nombre_usuario,385,75);
		
		doc.roundedRect(50,110,540,30,5).fillOpacity(0.8).stroke();			
		doc.font('Helvetica-Bold',8);
		doc.text("Nº",55,120);
		doc.text("Fecha",70,120,{width:30});
		doc.text("Nro. Doc",110,115,{width:20});
		doc.text("Movimiento",135,120);
		doc.text("Concepto",200,120);
		doc.text("Saldo Anterior",470,117,{width:55});
		doc.text("Importe",510,117,{width:55});
		doc.text("Saldo Posterior",550,117,{width:55});
	}

	async imprimirMovimientoCaja(movimiento_caja){
		movimiento_caja=await this.cajasService.obtenerMovimientoCaja(movimiento_caja.id).toPromise();
		this.blockUI.start();
		this.pdfService.imprimirMovimientoCaja(movimiento_caja);
		this.blockUI.stop();
	}

	abrirPopupConfirmacionEliminacion(movimiento_caja){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el movimiento?";
		this.popupConfirmacion.componentInstance.data = movimiento_caja;		
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarMovimiento($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
	  
	eliminarMovimiento(movimiento_caja){
		this.blockUI.start();
		this.cajasService.anularMovimiento(movimiento_caja).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
			}
			this.blockUI.stop();
		});
	}

	generarExcelMovimientos(){
		this.blockUI.start();
		var data = [["N°","FECHA","N° DOCUMENTO","CAJA","TIPO DE MOVIMIENTO","CONCEPTO","SALDO ANTERIOR","INGRESO","EGRESO","SALDO POSTERIOR","TIPO DE PAGO","CHEQUE","ESTADO"]]
		for(var i=0;i<this.movimientos_caja.length;i++){
			var columns=[];
			this.movimientos_caja[i].fecha=new Date(this.movimientos_caja[i].fecha);
			columns.push(i+1);
			columns.push(this.movimientos_caja[i].fecha.getDate()+"/"+(this.movimientos_caja[i].fecha.getMonth()+1)+"/"+this.movimientos_caja[i].fecha.getFullYear());
			columns.push(this.movimientos_caja[i].numero_documento);
			columns.push(this.movimientos_caja[i].caja.nombre);
			columns.push(this.movimientos_caja[i].tipo_movimiento.nombre);
			columns.push(this.movimientos_caja[i].concepto.nombre);
			columns.push(this.movimientos_caja[i].saldo_anterior);
			if(this.movimientos_caja[i].tipo_movimiento.nombre_corto==GlobalVariable.Dictionary.MOV_ING){
				columns.push(this.movimientos_caja[i].importe);
				columns.push(0);
			}else{
				columns.push(0);
				columns.push(this.movimientos_caja[i].importe);
			}
			columns.push(this.movimientos_caja[i].saldo_posterior);	
			columns.push(this.movimientos_caja[i].transaccion.nombre);
			if(this.movimientos_caja[i].transaccion.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE){
				columns.push((this.movimientos_caja[i].cheque?this.movimientos_caja[i].cheque.codigo+" - ":"")+(this.movimientos_caja[i].numero_cheque?"Nº "+this.movimientos_caja[i].numero_cheque:""));
			}else{
				columns.push("");
			}
			columns.push((this.movimientos_caja[i].activo?"ACTIVO":"ANULADO"));
			data.push(columns);		
      	}
      let nombre_archivo="movimientos_caja";
			
		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet(nombre_archivo);
		data.forEach(d => {
			let row = worksheet.addRow(d);
		});

		workbook.xlsx.writeBuffer().then((data) => {
			let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, nombre_archivo+".xlsx");
		})
		this.blockUI.stop();
	}
}
