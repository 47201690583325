import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const aplicarPluginTreeView:any;
declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.css']
})
export class MultimediaComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
    public generalService:GeneralService,
	public persistenciaService:PersistenciaService,
	public modalService: NgbModal,
  public usuarioService:UsuarioService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    
  }

}
