import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { ReservasComponent } from './components/reservas/reservas.component';

const routes: Routes = [
  { path: 'reservas',component: ReservasComponent,canActivate:[HabilitadoGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelRoutingModule { }
