<div class="modal-header card-header">
    <div class="col-md-6 col-xs-6">
        <span class="text-blue text-125">Registro Asignación Pago Estudiante</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="asignarPago()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Gestión</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input [disabled]="true" type="text" name="gestion" [(ngModel)]="gestion.nombre" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Concepto de Pago</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" id="concepto_ingreso" name="concepto_ingreso" class="form-control" [(ngModel)]="asignacion_concepto_pago_estudiante.concepto_pago">
                <option [ngValue]="concepto_ingreso" *ngFor="let concepto_ingreso of conceptos_ingreso">{{concepto_ingreso.concepto.nombre}}</option>
            </select>
        </div>
    </div>
</div>