import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { GeceService } from '../../services/gece/gece.service';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { RegistroRecursoMultimediaComponent } from '../../../base/components/registro-recurso-multimedia/registro-recurso-multimedia.component';
import { RecursoMultimediaService } from '../../../base/services/recurso-multimedia/recurso-multimedia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const aplicarPluginTreeView:any;
declare const resaltarPestañaMenu:any;
declare const aplicarDibujadoCanvas:any;
declare const $:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-gece',
  templateUrl: './gece.component.html',
  styleUrls: ['./gece.component.css']
})
export class GeceComponent extends BaseComponent implements OnInit {

  visor_pdf:NgbModalRef;
  @ViewChild('visor_pdf')
  private visor_pdf_ref: TemplateRef<any>;
  url_visor_pdf:string='';
  nombre_pdf:string='';
  visor_abierto:boolean=false;
  pdf_visor_zoom:number=1;
  escribiendo:boolean=false;
  recurso:any={};
  canvas_image:any;
  canvas_original_data_url:any;

  ctx:any;
  coord:any;
  paint:any;

  adicion_recurso:NgbModalRef;
  @ViewChild('adicion_recurso')
  private adicion_recurso_ref: TemplateRef<any>;

  carpeta_actual:any={};
  ids_administradores:any[]=[4,53,54,71,55];
  editor:boolean=false;
  canvas:any;
  canvas_arreglo:any[]=[];
  canvas_paso:number=-1;

  modal_registro_recurso_multimedia:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
	public persistenciaService:PersistenciaService,
	public modalService: NgbModal,
  public geceService:GeceService,
  public toastr:ToastrService,
  public recursoMultimediaService:RecursoMultimediaService,
  public usuarioService:UsuarioService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.filter={
      color:"#000000",
      currentPage:1,
      maximizado:false,
      pdf_cargado:false,
      ancho_linea:5
    };
    this.editor=this.ids_administradores.filter(id => id==this.usuario.id).length>0;
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
    this.blockUI.start();
    this.generalService.obtenerDatosGece().subscribe((datos:any)=>{
      let datos_textos=datos.datos_textos;
      let datos_solucionarios=/*this.filtrarPermisosLibros(*/datos.datos_solucionarios/*)*/;
      let datos_planificadores=/*this.filtrarPermisosLibros(*/datos.datos_planificadores/*)*/;
      let datos_laminas=datos.datos_laminas;
      if(this.usuario.datos){
        this.usuario.datos=JSON.parse(this.usuario.datos);
        this.usuario.codigos_libros=this.usuario.datos.codigos_libros.join();
        datos_textos=this.filtrarPermisosLibros(datos.datos_textos);
      }
      aplicarPluginTreeView("id-textos",datos_textos,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-solucionarios",datos_solucionarios,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-planificadores",datos_planificadores,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-laminas",datos_laminas,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-videos",datos.datos_videos,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-cartillas",datos.datos_cartillas,this,this.verTexto,GlobalVariable.SERVER_URL);
      aplicarPluginTreeView("id-adicionales",datos.datos_adicionales,this,this.verTexto,GlobalVariable.SERVER_URL);
      this.blockUI.stop();
    });
  }

  filtrarPermisosLibros(datos){
    for(let i=0;i<datos[0].children.length;i++){//niveles
      for(let j=0;datos[0].children[i].children && j<datos[0].children[i].children.length;j++){//cursos
        let recursos=[];
        for(let k=0;datos[0].children[i].children[j].children && k<datos[0].children[i].children[j].children.length;k++){//recursos por curso
          if(this.usuario.codigos_libros.includes(datos[0].children[i].children[j].children[k].codigo)){
            recursos.push(datos[0].children[i].children[j].children[k]);
          }
        }
        datos[0].children[i].children[j].children=recursos;
      } 
    }
    return datos;
  }

  verTexto(me,nombre_recurso,url_texto){
    me.filter.maximizado=false;
    me.url_visor_pdf= url_texto;
    me.nombre_pdf=nombre_recurso;
    if(me.visor_abierto){
      me.clickVisor();  
    }else{
      me.visor_abierto=true;
    }
    me.clickVisor();
    /*me.visor_pdf=me.modalService.open(me.visor_pdf_ref, {windowClass:'visor-pdf',ariaLabelledBy: 'modal-basic-title',scrollable:true,size: 'lg', backdrop: 'static'});
    me.visor_pdf.result.then((result) => {
      me.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
    });*/
  }

  cerrarVisorPdf(){
    this.filter.pdf_cargado=false;
    this.restaurarPdf();
    this.visor_abierto=false;
    this.clickVisor();
  }

  clickVisor(){
    let me=this;
    setTimeout(function(){
      var element = document.createElement('button');
      element.setAttribute('data-target', '#aside-pdf-visor');
      element.setAttribute('type', 'button');
      element.setAttribute('data-toggle', 'modal');
      element.setAttribute('class', 'shadow ml-2 btn btn-info radius-round mw-auto p-3');
      element.style.display = 'none';
      document.getElementById("aside-pdf-visor").appendChild(element);
      element.click();console.log(element);
      document.getElementById("aside-pdf-visor").removeChild(element);
      if(me.visor_abierto){
        //me.blockUI.start();
      }
    },500);
  }

  afterLoadComplete(pdfData: any) {
    this.filter.pdf_cargado=true;
    this.filter.pages = pdfData.numPages;
    console.log(this.filter.pages);
    //this.isLoaded = true; 
    this.blockUI.stop();
    //this.aplicarDibujoCanvas();
    this.iniciarPagina();
  }

  aumentarZoom(){
    this.pdf_visor_zoom=this.pdf_visor_zoom+0.1;
    this.borrarEscritura();
  }

  disminuirZoom(){
    this.pdf_visor_zoom=this.pdf_visor_zoom-0.1;
    this.borrarEscritura();
  }

  aplicarDibujoCanvas(){
    let child_elements=$( ".textLayer" );
    for(let i=0;i<child_elements.length;i++){
        this.canvas=child_elements[i].parentElement.getElementsByTagName("canvas")[0];
        this.canvas_original_data_url=this.canvas.toDataURL();
        this.adicionarPasoCanvas();
        this.aplicarDibujadoCanvas(child_elements[i].parentElement.getElementsByTagName("canvas")[0]);
    }
  }

  activarEscritura(){
    this.escribiendo=true;
    if(!this.canvas){console.log("aplicado");
      this.aplicarDibujoCanvas();
    }
  }

  desactivarEscritura(){
    this.escribiendo=false;
  }

  borrarEscritura(){
    this.desactivarEscritura();
    this.paint = false; 

    if (this.canvas_paso > 0) {
      this.canvas_paso--;      
      this.repintarCanvas(this.canvas_arreglo[this.canvas_paso]);
    }
  }

  repintarCanvas(data){
    let image=new Image();
    image.src=data;
    let me=this;
    image.onload = function(){
      me.ctx.drawImage(image,0,0);   
    }
  }

  adicionarPasoCanvas() {console.log(this.escribiendo);
    if(this.escribiendo){
      this.canvas_paso++;
      if (this.canvas_paso < this.canvas_arreglo.length) { this.canvas_arreglo.length = this.canvas_paso; }
      this.canvas_arreglo.push(this.canvas.toDataURL());
    }
  }

  cambiarColor(){
    
    this.ctx.strokeStyle = this.filter.color; 
  }

  aplicarDibujadoCanvas(canvas){
    let me=this;
    document.getElementsByClassName("page")[0].addEventListener('mousedown', (event)=>{ 
      me.paint = me.escribiendo; 
      me.getPosition(event,me); 
    } ); 
    document.getElementsByClassName("page")[0].addEventListener('mouseup', ()=>{ console.log(me.paint);
      if(me.paint){
        me.paint = false; 
        me.adicionarPasoCanvas();console.log("mouseup");
      }
    } ); 
    document.getElementsByClassName("page")[0].addEventListener('mouseleave', ()=>{ console.log("mouseleave");
      if(me.paint){
        me.paint = false; 
        me.adicionarPasoCanvas();
      }
    } ); 
    document.addEventListener('mousemove',(event)=>{ 
      if (!me.paint) return; 
      me.ctx.beginPath(); 
        
      me.ctx.lineWidth = me.filter.ancho_linea; 
       
      // Sets the end of the lines drawn 
      // to a round shape. 
      me.ctx.lineCap = 'round'; 
        
      me.ctx.strokeStyle = me.filter.color; 
          
      // The cursor to start drawing 
      // moves to this coordinate 
      me.ctx.moveTo(me.coord.x, me.coord.y); 
       
      // The position of the cursor 
      // gets updated as we move the 
      // mouse around. 
      me.getPosition(event,me); 
       
      // A line is traced from start 
      // coordinate to this coordinate 
      me.ctx.lineTo(me.coord.x , me.coord.y); 
        
      // Draws the line. 
      me.ctx.stroke(); 
    }); 
    // Context for the canvas for 2 dimensional operations 
    this.ctx=canvas.getContext('2d'); 
      
    // Stores the initial position of the cursor 
    this.coord = {x:0 , y:0};  
      
    // This is the flag that we are going to use to  
    // trigger drawing 
    this.paint = false; 
      
  // Updates the coordianates of the cursor when  
  // an event e is triggered to the coordinates where  
  // the said event is triggered.  
  }

  getPosition(event,me){ 
    me.ctx.strokeStyle = me.filter.color; 
    let ele:any=document.getElementsByClassName("page")[0];
    //console.log(document.getElementById("aside-pdf-visor").getElementsByClassName("modal-dialog")[0].clientWidth);
    //console.log(document.getElementById("aside-pdf-visor").getElementsByClassName("modal-dialog")[0].clientHeight);
    me.coord.x = event.clientX-(ele.getBoundingClientRect().left);// - (window.innerWidth-document.getElementsByClassName("page")[0].clientWidth-30); 
    me.coord.y = event.clientY-(ele.getBoundingClientRect().top);// - (window.innerHeight-document.getElementsByClassName("page")[0].clientHeight-66); 
  }   

  iniciarPagina(){
    if(this.canvas_original_data_url){
      this.repintarCanvas(this.canvas_original_data_url);
    }
    this.canvas=null;
    this.canvas_arreglo=[];
    this.canvas_paso=-1;
    this.desactivarEscritura();
  }

  siguientePagina(){
    this.filter.currentPage=this.filter.currentPage+1;
    this.iniciarPagina();
  }

  anteriorPagina(){
    this.filter.currentPage=this.filter.currentPage-1;
    this.iniciarPagina();
  }

  establecerPagina(){
    this.iniciarPagina();
  }

  maximizarPdf(){
    this.filter.maximizado=true;
    var element = document.querySelector("#visor_pdf");

    element.requestFullscreen()
    .then(function() {
      // element has entered fullscreen mode successfully
    })
    .catch(function(error) {
      // element could not enter fullscreen mode
      // error message
      console.log(error.message);
    });
  }

  restaurarPdf(){
    this.filter.maximizado=false;
    document.exitFullscreen()
    .then(function() {
      // element has exited fullscreen mode
    })
    .catch(function(error) {
      // element could not exit fullscreen mode
      // error message
      console.log(error.message);
    });
  }

  crearNuevoRecurso(carpeta){
    this.modal_registro_recurso_multimedia = this.modalService.open(RegistroRecursoMultimediaComponent, {scrollable:true, ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'md'});
    this.modal_registro_recurso_multimedia.componentInstance.pre_fijo_ruta = "gece/"+carpeta.directorio.toLowerCase();
    this.modal_registro_recurso_multimedia.componentInstance.usuario = this.usuario;
    
    this.modal_registro_recurso_multimedia.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          
        }else{
          let nodo:any={
            id:res.recurso.id,
            name:res.recurso.nombre,
            rel:res.recurso.ruta_url,
            type:res.tipo,
            icons:{default:["<i class='"+res.recurso.icono+" text-125'></i>", res.recurso.color]}
          };
          var nodeExample = $('#'+carpeta.id).tree('getNodeByName',carpeta.directorio);
          $('#'+carpeta.id).tree(
            'appendNode',
            nodo,
            nodeExample
          );
          this.guardarEstadoCarpeta(this,carpeta.directorio);
          this.modal_registro_recurso_multimedia.close();
        }
      }else{
        this.modal_registro_recurso_multimedia.close();
      }
    });
  }
  
  guardarEstadoCarpeta(me,directorio){
    me.geceService.guardarEstadoCarpeta({directorio:directorio,tree_json:$('#id-'+directorio.toLowerCase()).tree('toJson')}).subscribe((res:any)=>{
      me.toastr.success(res.mensaje);
    });
  }

  abrirPopupConfirmacionEliminacion(me,directorio,node){
		me.popupConfirmacion = me.modalService.open(PopupConfirmacionComponent);
		me.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar el recurso?";
		
		me.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
        $('#id-'+directorio.toLowerCase()).tree('removeNode', node);
        me.guardarEstadoCarpeta(me,directorio);
        //let directorio_servidor=directorio+"/"+node.name+"."+extension;
        if(node.id){
          me.recursoMultimediaService.eliminarRecursoMultimedia(node.id).subscribe((res:any)=>{
            this.toastr.success(res.mensaje);
          });
        }
			}
			me.popupConfirmacion.close();
    });
	}
}
