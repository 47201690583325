import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../../../../app/global';

@Injectable({
  providedIn: 'root'
})
export class CursosService {

  constructor(private http: HttpClient) { }

  public obtenerCursosNivel(id_empresa,id_nivel_escolar){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cursos/empresa/"+id_empresa+"/nivel/"+id_nivel_escolar,GlobalVariable.httpOptions);
  }

  public obtenerCursosColegio(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cursos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarCurso(curso){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cursos",curso,GlobalVariable.httpOptions);
  }

  public actualizarCurso(curso){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cursos/"+curso.id,curso,GlobalVariable.httpOptions);
  }

  public eliminarCurso(curso){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"cursos/"+curso.id,GlobalVariable.httpOptions);
  }
}
