import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { EmpleadosComponent } from './components/empleados/empleados.component';
import { PostulacionesComponent } from './components/postulaciones/postulaciones.component';
import { FormularioPostulacionComponent } from './components/formulario-postulacion/formulario-postulacion.component';

const routes: Routes = [
  { path: 'postulaciones',component: PostulacionesComponent,canActivate:[HabilitadoGuard]},
  { path: 'empleados',component: EmpleadosComponent,canActivate:[HabilitadoGuard]},
  { path: 'formulario-postulacion/:codigo_formulario',component: FormularioPostulacionComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RrhhRoutingModule { }
