import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';

@Component({
  selector: 'app-migracion-inscripcion',
  templateUrl: './migracion-inscripcion.component.html',
  styleUrls: ['./migracion-inscripcion.component.css']
})
export class MigracionInscripcionComponent extends BaseComponent implements OnInit {

  inscripciones_destino:any[]=[];
  migracion_inscripcion:any={inscripcion_anterior:{}};
  migraciones_inscripcion:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private estudiantesService:EstudiantesService,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerMigracionesInscripcion(){
    this.blockUI.start();
    this.migraciones_inscripcion.push({ 
      inscripcion_anterior:this.migracion_inscripcion.inscripcion_anterior,
      inscripcion_nueva:this.migracion_inscripcion.inscripcion_nueva,
      datos:"CALIFICACIONES" 
    });
    this.migraciones_inscripcion.push({ 
      inscripcion_anterior:this.migracion_inscripcion.inscripcion_anterior,
      inscripcion_nueva:this.migracion_inscripcion.inscripcion_nueva,
      datos:"ASISTENCIAS" 
    });
    this.estudiantesService.obtenerMigracionesInscripcion({
      inscripcion_anterior:this.migracion_inscripcion.inscripcion_anterior,
      inscripcion_nueva:this.migracion_inscripcion.inscripcion_nueva,
      migraciones_inscripcion:this.migraciones_inscripcion
    }).subscribe((res:any)=>{
      this.migraciones_inscripcion=res;
      this.blockUI.stop();
    });
  }

  migrarInscripcion(){
    if(this.validarMigracion()){
      this.blockUI.start();
      this.estudiantesService.migrarInscripcion(this.migraciones_inscripcion).subscribe((res:any)=>{
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.cerrarComponente();
        }
        this.blockUI.stop();
      });
    }
  }

  validarMigracion(){
    let res=true;
    let contador=0;
    for(let i=0;i<this.migraciones_inscripcion.length;i++){
      if(this.migraciones_inscripcion[i].migrado && !this.migraciones_inscripcion[i].consolidado){
        contador=contador+1;
      }
    }
    if(contador==0){
      res=false;
      this.toastr.error("Debe seleccionar al menos un registro!");
    }
    return res;
  }

}
