import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { PacientesService } from '../../services/pacientes/pacientes.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-recetas',
  templateUrl: './recetas.component.html',
  styleUrls: ['./recetas.component.css']
})
export class RecetasComponent extends BaseComponent implements OnInit {

  receta:any;
  historia_paciente:any;
  paciente:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public pacientesService:PacientesService,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
  }

  agregarReceta(receta){
    if(this.validarReceta(receta)){
      this.historia_paciente.recetas=this.historia_paciente.recetas?this.historia_paciente.recetas:[];
      this.historia_paciente.recetas.push(receta);
      this.receta={
        id_historia_paciente:this.historia_paciente.id
      } 
    }
  }

  validarReceta(receta){
    let res=true;
    if(!receta.medicamento){
      res=res && false;
      this.toastr.error("Debe especificar el medicamento!");
    }
    if(!receta.cantidad){
      res=res && false;
      this.toastr.error("Debe especificar la cantidad!");
    }
    if(!receta.indicacion){
      res=res && false;
      this.toastr.error("Debe especificar la indicacion!");
    }
    return res;
  }

  guardarReceta(){
    if(this.historia_paciente.recetas.length>0){
      this.blockUI.start();
      this.pacientesService.guardarReceta(this.historia_paciente.id,this.historia_paciente).subscribe((res:any)=>{
        this.blockUI.stop();
        this.cerrarComponente();
        this.toastr.success(res.mensaje);
      })
    }else{
      this.toastr.error("Debe existir al menos una receta!");
    }
  }

  eliminarReceta(receta){
    if(receta.id){
      receta.eliminado=true;
    }else{
      this.historia_paciente.recetas.splice(this.historia_paciente.recetas.indexOf(receta),1);
    }
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  generarPdfReceta(){
    var doc = new PDFDocument({margin:10,size:[451, 572]});
    var stream = doc.pipe(blobStream());
    let fecha_inicio=new Date();
    let fecha_fin=new Date();
    fecha_fin.setDate(fecha_inicio.getDate()+365-1);
    /*doc.rect(0,0,451,100).fill("#2811bd").stroke();
    doc.font('Helvetica',10);
    doc.fillColor("white");
    doc.text("DR. HENRY MORUNO CRUZ MEDICO REUMATOLOGO",0,30,{width:451,align:"center"});
    doc.font('Helvetica-Bold',23);
    doc.text("RECETA",0,50,{align:"center"});*/
    doc.font('Helvetica-Bold',12);
    doc.text("NOMBRE:",50,110);
    doc.font('Helvetica',12);
    let texto_nombre=(this.paciente.cliente.persona.nombres+" "+(this.paciente.cliente.persona.apellido_paterno?this.paciente.cliente.persona.apellido_paterno:"")+" "+(this.paciente.cliente.persona.apellido_materno?this.paciente.cliente.persona.apellido_materno:""));
    doc.text(texto_nombre.toUpperCase(),120,110);
    doc.font('Helvetica',10);
    doc.text(this.historia_paciente.descripcion,120,130);
    let y=180;
    doc.fillColor("black");
    for(let i=0;i<this.historia_paciente.recetas.length;i++){
      doc.text((i+1)+".-",60,y);
      doc.text(this.historia_paciente.recetas[i].medicamento,80,y);
      doc.text(this.historia_paciente.recetas[i].presentacion?this.historia_paciente.recetas[i].presentacion:"",80,y+15);
      doc.text(this.historia_paciente.recetas[i].indicacion,80,y+30,{width:330});
      doc.text(this.historia_paciente.recetas[i].cantidad,350,y);
      y=y+70;
    }
    let fecha_hoy=new Date();
    doc.text("COCHABAMBA, "+fecha_hoy.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase()+", "+fecha_hoy.getDate()+" "+fecha_hoy.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" DEL "+fecha_hoy.getFullYear(),120,470);
    doc.end();

    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      var w = window.open(fileURL, '_blank', 'location=no');
      setTimeout(function () {
        w.print();
      }, 500);
    });
  }

}
