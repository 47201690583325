<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Compras
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row">
        <div class="col-md-6 col-12">
          <span class="text-90">Lista de</span>
          Compras
        </div>
        <div class="col-md-6 col-12">
          <div class="columns columns-right btn-group float-right">
            <button *ngIf="usuario.empresa.usar_inventario" title="Nuevo" (click)="crearNuevaCompra(true)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
              <i class="fa fa-plus text-primary"></i>
            </button>
            <button title="Nuevo" (click)="crearNuevaCompra(false)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1 bs-print" type="button">
              <i class="fa fa-plus text-success"></i>
            </button>
            <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
              <i class="fa fa-wrench text-blue-d1"></i>
              <span class="caret"></span>
            </button>
            <button (click)="verPagosCompra(null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista Pagos Compras" aria-expanded="true">
              <i class="fa fa-list-alt text-blue-d1"></i>
              <span class="caret"></span>
            </button>
            <button (click)="verComprasBorrador()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" type="button" title="Lista de compras borrador" aria-expanded="true">
              <i class="fas fa-eraser text-success"></i>
              <span class="caret"></span>
            </button>
            <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
              <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                <div class="card bgc-primary brc-primary radius-0">
                  <div class="card-header">
                  <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                  </div>
        
                  <div class="card-body p-0 bg-white">
                  <table class="table table-striped table-hover mb-0">
                    <tbody>
                      <tr>
                        <td class="text-dark-m2">
                        Configuración Aplicación
                        </td>
                        <td class="text-dark-m2">
                          <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-dark-m2">
                          Descarga Compras
                        </td>
                        <td>
                          ¿Con Detalle?
                          <label>
                            <input name="datos_con_detalle" [(ngModel)]="datos_con_detalle" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                          </label>
                        </td>
                        <td class="text-dark-m2">
                          <a (click)="generarExcelFiltroCompras()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
                </div>
            </div>
            <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
          </div>
        </div>
      </div>
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <div class="row">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xs-12 col-sm-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                          <ng-container *ngIf="usuario.empresa.usar_sucursales">
                            <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                            <div class="col-md-3 col-sm-12">
                              <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                  <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                              </select>
                            </div>
                          </ng-container>
                  <div class="col-md-3 col-sm-12">
                  <label>Usuario </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                      <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                  <label>Tipo de Doc. </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_documento" [(ngModel)]="filter.tipo_documento">
                      <option [ngValue]="tipo_documento" *ngFor="let tipo_documento of filter.tipos_documentos">{{tipo_documento.nombre}}</option>
                    </select>
                            </div>
                  <div class="col-md-3 col-sm-12">
                  <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Proveedor </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.proveedor.razon_social">
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Nit </label>
                              <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.proveedor.nit">
                            </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="compras.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th>Nº</th>
						<th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">Num. Doc.</th>
						<th *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">Sucursal</th>
            <th *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes">Almacen</th>
						<th *ngIf="configuracion_pagina.getConfiguration().razon_social.show">Razón Social Proveedor</th>
						<th *ngIf="configuracion_pagina.getConfiguration().nit.show">Nit Proveedor</th>
						<th *ngIf="configuracion_pagina.getConfiguration().tipo_documento.show">Tipo Doc.</th>
						<th *ngIf="configuracion_pagina.getConfiguration().factura.show">Factura</th>
						<th *ngIf="configuracion_pagina.getConfiguration().fecha_documento.show">Fecha Fact.</th>
						<th *ngIf="configuracion_pagina.getConfiguration().hora_fecha.show">Hora - Fecha</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().descuento.show">Descuento</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().total.show">Total</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().pagado.show">Pagado</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().saldo.show">Saldo</th>
						<th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">Tipo de Pago</th>
            <th *ngIf="usuario.empresa.usar_caja_general && configuracion_pagina.getConfiguration().caja.show">Caja</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                        <th *ngIf="configuracion_pagina.getConfiguration().activa.show">Estado</th>
						<th>Acciones</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let compra of compras; let i=index;">
                    <td>{{i+1}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">{{compra.numero_documento}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">{{compra.sucursal}}</td>
            <td *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes">{{compra.almacen}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{compra.proveedor}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{compra.nit}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().tipo_documento.show">{{compra.tipo_documento}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().factura.show">{{compra.factura}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().fecha_documento.show">{{compra.fecha | date:"dd/MM/yyyy"}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().hora_fecha.show">{{compra.createdAt | date:"HH:mm - dd/MM/yyyy"}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().importe.show">{{compra.importe.toFixed(2)}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().descuento.show">{{compra.descuento.toFixed(2)}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{compra.total.toFixed(2)}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().pagado.show">{{compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO?compra.a_cuenta:compra.total.toFixed(2)}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().saldo.show">{{(compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO?compra.saldo:0).toFixed(2)}}</td>
                        <td *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">
                            <span *ngIf="compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="badge btn-purple">{{compra.tipo_pago}}</span>
                            <span *ngIf="compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CONTADO" class="badge btn-pink">{{compra.tipo_pago}}</span>
                            <span *ngIf="compra.tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CONTADO && compra.tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CREDITO" class="label label-sm label-pink ">{{compra.tipo_pago}}</span>
                        </td>
                        <td *ngIf="usuario.empresa.usar_caja_general && configuracion_pagina.getConfiguration().caja.show">{{compra.caja}}</td>
						<td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{compra.usuario}}</td>
                        <td *ngIf="compra.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                        <td *ngIf="!compra.activa && configuracion_pagina.getConfiguration().activa.show"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirCompra(compra)">
                                <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="compra.id_movimiento_caja" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="imprimirMovimientoCaja(compra.id_movimiento_caja)">
                              <i class="fa fa-print bigger-260 red2"></i>
                          </a>
                            <a title="Pagar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO && compra.saldo>0 && compra.activa" (click)="crearPagoCompra(compra)">
                                <i class="fas fa-money-bill-alt"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verCompra(compra)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="!compra.activa && compra.id_almacen" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="copiarCompra(compra)">
                                <i class="ace-icon fa fa-copy bigger-130"></i>
                            </a>
                            <a *ngIf="compra.activa" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(compra)">
                                <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>

                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a class="dropdown-item" href="javascript:void(0)" (click)="imprimirCompra(compra)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a *ngIf="compra.id_movimiento_caja" class="dropdown-item" href="javascript:void(0)" (click)="imprimirMovimientoCaja(compra.id_movimiento_caja)">
                                <i class="fa fa-print text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a title="Pagar" class="dropdown-item" href="javascript:void(0)" *ngIf="compra.tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO && compra.saldo>0 && compra.activa" (click)="crearPagoCompra(compra)">
                                  <i class="fas fa-money-bill-alt text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Pagar
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="verCompra(compra)">
                                  <i class="ace-icon fa fa-search-plus text-primary mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a *ngIf="!compra.activa && compra.id_almacen" class="dropdown-item" href="javascript:void(0)" (click)="copiarCompra(compra)">
                                  <i class="ace-icon fa fa-copy text-purple mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Copiar
                              </a>
                              <a *ngIf="compra.activa" class="dropdown-item" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(compra)">
                                  <i class="fa fa-trash-alt text-danger mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Eliminar
                              </a>
                            </div>
                          </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="compras.length>0">
                    <th></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().almacen.show && usuario.empresa.usar_almacenes"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().nit.show"></th>
                    <td *ngIf="configuracion_pagina.getConfiguration().tipo_documento.show"></td>
                    <th *ngIf="configuracion_pagina.getConfiguration().factura.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha_documento.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().hora_fecha.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show"><b>TOTAL</b>: {{sumarImporte().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().descuento.show"><b>TOTAL</b>: {{sumarDescuento().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show"><b>TOTAL</b>: {{sumarTotal().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show"></th>
                    <th *ngIf="usuario.empresa.usar_caja_general && configuracion_pagina.getConfiguration().caja.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activa.show"></th>
                    <th></th>									
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="compras.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #compras_borrador_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Lista de compras en borrador</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="table-fixed-header">
						<table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">Num. Doc.</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show">Sucursal</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show">Razón Social Proveedor</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().nit.show">Nit Proveedor</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().tipo_documento.show">Tipo Doc.</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().factura.show">Factura</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().fecha_documento.show">Fecha Fact.</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">Tipo de Pago</th>
                  <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let compra_borrador of compras_borrador; let i=index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">{{compra_borrador.factura}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().sucursal.show">{{compra_borrador.sucursal.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{compra_borrador.proveedor.razon_social}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{compra_borrador.proveedor.nit}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().tipo_documento.show">{{compra_borrador.tipo_documento.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().factura.show">{{compra_borrador.factura}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().fecha_documento.show">{{compra_borrador.fecha | date:"dd/MM/yyyy"}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().importe.show">{{compra_borrador.total.toFixed(2)}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">{{compra_borrador.tipoPago.nombre}}</td>
                  <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{compra_borrador.usuario.nombre_usuario}}</td>
                  <td>
                    <div class="d-none d-lg-inline text-muted">
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCompraBorrador(compra_borrador)">
                        <i class="fa fa-edit bigger-130"></i>
                      </a>
                      <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionBorrador(compra_borrador)">
                        <i class="fa fa-trash-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>