<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Clientes
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>



    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Clientes
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="row">
            <div class="col-12 col-md-12">
              <app-paginator-header [paginator]="this"></app-paginator-header>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-12">
              <div class="row">
                            <div *ngIf="usuario.empresa.usar_clientes_sucursal" class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                            <div *ngIf="usuario.empresa.usar_clientes_sucursal" class="col-md-3 col-sm-12">
                                <select [compareWith]="compararObjectos" class="form-control" name="sucursal" [(ngModel)]="filter.sucursal">
                                    <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-md-2 col-sm-12"><label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}} </label></div>
                            <div class="col-md-4 col-sm-12">
                                <select [compareWith]="compararObjectos" class="form-control" name="grupo" [(ngModel)]="filter.categoria">
                                    <option [ngValue]="categoria" *ngFor="let categoria of filter.categorias">{{categoria.nombre}}</option>
                                </select>
                            </div>
              </div>
            </div>
          </div>
		      </div>
          <div class="col-12 col-md-3">
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                  </button>
                  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoCliente()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <div class="export btn-group">
                    <button (click)="descargarExcelEjemploImportacion()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                              <i class="fa fa-download text-green-d1"></i>
                              <span class="caret"></span>
                    </button>
                    <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-clientes')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                      <i class="fa fa-upload text-red fileUpload-button"></i>
                      <input id="subir-excel-clientes" style="display:none" type="file" class="form-control" (change)="subirExcelClientes($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </button>
                  </div>
                  <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                      <i class="fa fa-wrench text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>

                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                      <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                        <div class="card bgc-primary brc-primary radius-0">
                          <div class="card-header">
                          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                          </div>

                          <div class="card-body p-0 bg-white">
                          <table class="table table-striped table-hover mb-0">
                            <tbody>
                              <tr *ngIf="aplicacion.puede_modificar">
                                <td class="text-dark-m2">
                                Configuración Vista
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                </td>
                              </tr>
                            <tr>
                              <td class="text-dark-m2">
                                Descarga
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="descargarExcelClientes()" [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_excel" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                              </td>
                              <!--<td>
                                <a [class.disabled]="!configuracion_pagina.object.datos.opciones_permisos.ver_pdf" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                              </td>-->
                            </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        </div>
                    </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="keep-open btn-group show" title="Columns">
                    <button *ngIf="aplicacion.puede_ver && usuario.empresa.usar_georeferenciacion_clientes" title="Mapa Clientes" (click)="abrirMapaClientes()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                      <i class="fas fa-map text-danger-d1"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="clientes.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th>N°</th>
                <th *ngIf="configuracion_pagina.getConfiguration().bloqueado.show" class="center"><a href="javascript:void(0)">Bloqueado <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('codigo')">Código <span id="codigo" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().identificacion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('identificacion')">Identificación <span id="identificacion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().descripcion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('descripcion')">Descripción <span id="descripcion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().contacto.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('contacto')">Contacto <span id="contacto" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="(usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea) && configuracion_pagina.getConfiguration().razon_social.show" class="center"><span *ngIf="aplicacion.puede_modificar" class="fa fa-edit point-edit blue" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)"></span> &nbsp;<a href="javascript:void(0)" ng-click="paginator.sortColumn('razon_social')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} <span id="razon_social" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().nit.show" class="center"> <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit point-edit blue" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)"></span> &nbsp; <a href="javascript:void(0)" ng-click="paginator.sortColumn('nit')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}} <span id="nit" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().direccion.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('direccion')">Dirección <span id="direccion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().telefono1.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('telefono1')">Telefono 1 <span id="telefono1" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().imagen.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('telefono1')">Imagen <span id="imagen" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().categoria.show" class="center">
                    <span *ngIf="aplicacion.puede_modificar" class="fa fa-edit" (click)="abrirTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)"></span>&nbsp;
                    <a href="javascript:void(0)" ng-click="paginator.sortColumn('categoria')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}}</a>
                </th>
                <th *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().subcategoria.show" class="center"><span class="glyphicon glyphicon-pencil point-edit blue" ng-click="abrirDialogTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA,obtenerTextosEmpresa)"></span> <a href="javascript:void(0)" ng-click="paginator.sortColumn('subcategoria')">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA)}}</a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().email.show" class="center"><a href="javascript:void(0)" ng-click="paginator.sortColumn('email')">E-mail</a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #clientes *ngFor="let cliente of clientes; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>

                <td *ngIf="usuario.empresa.usar_bloqueo_clientes && configuracion_pagina.getConfiguration().bloqueado.show">
                  <label>
                    <input [disabled]="!aplicacion.puede_modificar" (change)="actualizarAtributoCliente(cliente, 'bloqueado', cliente.bloqueado)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="cliente.bloqueado"/>
                  </label>
                </td>

                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{cliente.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().identificacion.show">
                    {{cliente.identificacion}}
                </td>
                <td *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().descripcion.show">
                    {{cliente.descripcion}}
                </td>
                <td *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().contacto.show">
                    {{cliente.contacto}}
                </td>
                <td *ngIf="(usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea) && configuracion_pagina.getConfiguration().razon_social.show">
                    {{cliente.razon_social}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{cliente.nit}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().direccion.show">{{cliente.direccion}}</td>

                <td *ngIf="configuracion_pagina.getConfiguration().telefono1.show">
                    {{cliente.telefono1}}
                </td>
                <td *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().imagen.show">
                    <div class="imagen-cliente-vista clearfix">
                        <a href="{{rest_server+cliente.imagen}}" title="{{cliente.nombre}}" data-rel="colorbox">
                            <img width="50" height="50" alt="sin imagen" src="{{rest_server+cliente.imagen}}" />
                        </a>
                    </div>
                </td>
                <td *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().categoria.show">
                    {{cliente.categoria?cliente.categoria.nombre:''}}
                </td>
                <td *ngIf="usuario.empresa.usar_ventas && configuracion_pagina.getConfiguration().subcategoria.show">
                    {{cliente.subcategoria?cliente.subcategoria.nombre:''}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().email.show">
                    {{cliente.email}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarCliente(cliente)">
                      <i class="fa fa-pencil-alt text-105"></i>
					          </a>
                    <a *ngIf="usuario.empresa.usar_clientes_contactos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="obtenerContactosCliente(cliente)">
                      <i class="fa fa-users text-105"></i>
					          </a>
                    <a title="Precios de productos por cliente" *ngIf="usuario.empresa.usar_precios_productos_clientes" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirPopupPreciosProductos(cliente)">
                      <i class="ace-icon fa fa-credit-card bigger-130"></i>
                    </a>
                    <a title="Historico de ventas cliente" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupHistoricoVentas(cliente)">
                      <i class="ace-icon fas fa-search-dollar bigger-130"></i>
                    </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(cliente)">
                      <i class="fa fa-trash-alt text-105"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="clientes.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <ng-template #modal_cliente_modificacion let-modal>
    <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <span class="text-blue text-125">Registro Cliente</span>

              <div class="card-toolbar ml-auto no-border pr-2 d-none">
                <label>
                  <span class="align-middle d-block d-sm-inline">Validation:</span>
                  <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
                </label>
              </div>
              <div class="card-toolbar pl-3">
                <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
                  <i class="fa fa-chevron-left"></i>
                </button>
                <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
                  <i class="fa fa-chevron-right"></i>
                </button>
                <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
                  <i class="fa fa-arrow-right"></i>
                </button>
                <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                    <i class="fas fa-times-circle"></i>
                  </button>
              </div>
            </div>

            <div class="card-body px-2">
              <div id="{{wizard_edicion_cliente}}" class="d-none">
                <ul class="mx-auto">
                  <li class="wizard-progressbar"></li>
                  <li>
                    <a href="#step-1">
                      <span class="step-title">
                          1
                      </span>

                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos del Cliente
                    </span>
                  </li>
                  <li>
                    <a href="#step-2">
                      <span class="step-title">
                          2
                      </span>

                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos Adicionales
                    </span>
                  </li>
                  <li *ngIf="usuario.empresa.usar_georeferenciacion_clientes">
                    <a href="#step-3">
                      <span class="step-title">
                          3
                      </span>

                      <span class="step-title-done">
                          <i class="fa fa-check text-success-m1"></i>
                      </span>
                    </a>
                    <span class="step-description">
                            Datos de Georeferenciacion
                    </span>
                  </li>
                </ul>

                <div class="px-2 py-2 mb-4">
                  <div id="step-1" class="">
                    <div class="  form-row">
                        <div class="col-sm-2 col-form-label text-sm-right text-grey">
                                Código
                        </div>
                        <div class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input [disabled]="codigo_automatico" required type="text" name="codigo" id="codigo" [(ngModel)]="cliente.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                                <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                            </div>

                            <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                        </div>
                        <div [ngClass]="{'text-danger-m1':(!cliente.identificacion)}" class="col-sm-2 col-form-label text-sm-right">
                            Identificación
                        </div>

                        <div class="col-sm-6">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <input (blur)="establecerRazonSocial()" required type="text" id="identificacion" name="identificacion" [(ngModel)]="cliente.identificacion" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!cliente.identificacion)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                <i *ngIf="!cliente.identificacion" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!cliente.identificacion" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!cliente.identificacion" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                    </div>
                    <div *ngIf="usuario.empresa.usar_ventas" class="  form-row">
                        <div *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)" class="col-sm-3 col-form-label text-sm-right">
                            Descripción
                        </div>

                        <div *ngIf="(usuario.empresa.usar_ventas || usuario.empresa.usar_cotizaciones)" class="col-sm-5">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="descripcion" name="descripcion" [(ngModel)]="cliente.descripcion" placeholder="Descripción" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                    <div class="  form-row">
                        <ng-container *ngIf="usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea">
                            <div [ngClass]="{'text-danger-m1':(!cliente.razon_social)}" class="col-1 col-form-label text-sm-right">
                                {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}
                            </div>
                            <div class="col-3">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input required type="text" id="und" name="und" [(ngModel)]="cliente.razon_social" placeholder="Razón Social" [ngClass]="{'brc-danger-m1':(!cliente.razon_social)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />

                                    <i *ngIf="!cliente.razon_social" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>


                                <div *ngIf="!cliente.razon_social" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>

                                <span *ngIf="!cliente.razon_social" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </ng-container>
                        <div [ngClass]="{'text-danger-m1':(!cliente.nit)}" class="col-1 col-form-label text-sm-right">
                          {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}
                        </div>
                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input required type="text" id="und" name="und" [(ngModel)]="cliente.nit" placeholder="Nit" [ngClass]="{'brc-danger-m1':(!cliente.nit)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />

                                <i *ngIf="!cliente.nit" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                            </div>


                            <div *ngIf="!cliente.nit" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                <div class="arrow brc-danger-m1 mt-0"></div>
                                <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                            </div>

                            <span *ngIf="!cliente.nit" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                        </div>
                        <ng-container *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
                          <div [ngClass]="{'text-danger-m1':(!cliente.tipo_documento)}" class="col-1 col-form-label text-sm-right">
                            Tipo Documento
                          </div>
                          <div class="col-2">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <select [compareWith]="compararObjectos" class="form-control" required name="tipo_documento" [(ngModel)]="cliente.tipo_documento">
                                  <option [ngValue]="tipo_documento" *ngFor="let tipo_documento of sin_documentos_identidad">{{tipo_documento.nombre}}</option>
                                </select>
                                  <i *ngIf="!cliente.tipo_documento" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                              </div>


                              <div *ngIf="!cliente.tipo_documento" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                  <div class="arrow brc-danger-m1 mt-0"></div>
                                  <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                              </div>

                              <span *ngIf="!cliente.tipo_documento" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                          </div>
                          <div class="col-1 col-form-label text-sm-right">
                            Complemento
                          </div>
                          <div class="col-1">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input type="text" id="complemento" name="complemento" [(ngModel)]="cliente.complemento" placeholder="Complemento" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              </div>
                          </div>
                        </ng-container>
                    </div>
                    <div class="  form-row" *ngIf="usuario.empresa.usar_clientes_sucursal">
                        <ng-container >
                            <div class="col-1 col-form-label text-sm-right" >
                                Sucursal
                            </div>
                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <select [compareWith]="compararObjectos" class="form-control" name="sucursal_cliente" [(ngModel)]="cliente.sucursal">
                                        <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales_registro">{{sucursal.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="  form-row">
                        <div class="col-1 col-form-label text-sm-right">
                            Dirección
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="direccion" name="direccion" [(ngModel)]="cliente.direccion" placeholder="Dirección" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <div class="col-1 col-form-label text-sm-right">
                            Teléfono 1
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono1" name="telefono1" [(ngModel)]="cliente.telefono1" placeholder="Teléfono 1" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <div class="col-1 col-form-label text-sm-right">
                            Teléfono 2
                        </div>

                        <div class="col-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="telefono2" name="telefono2" [(ngModel)]="cliente.telefono2" placeholder="Teléfono 2" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                    </div>
                  </div>

                  <div id="step-2">
                    <div class="  form-row">
                        <div class="col-1 col-form-label text-sm-right">
                            Correo electrónico
                        </div>

                        <div class="col-2" >
                            <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                <input type="text" id="email" name="email" [(ngModel)]="cliente.email" placeholder="Correo Eletrónico" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            </div>
                        </div>
                        <ng-container *ngIf="usuario.empresa.usar_ventas">
                            <div class="col-1 col-form-label text-sm-right">
                                Contacto
                            </div>

                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="contacto" name="contacto" [(ngModel)]="cliente.contacto" placeholder="Contacto" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                            <div class="col-1 col-form-label text-sm-right">
                                Ubic. Geo.
                            </div>

                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="ubicacion_geografica" name="ubicacion_geografica" [(ngModel)]="cliente.ubicacion_geografica" placeholder="Ubicacion Geografica" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="  form-row">
                        <ng-container *ngIf="usuario.empresa.usar_ventas">
                            <div class="col-sm-1 col-form-label text-sm-right">
                                {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_CATEGORIA)}}
                                <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('CATEGORIAS CLIENTES','obtenerCategoriasClientesEmpresa')"></span>
                            </div>
                            <div class="col-sm-3">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <select [compareWith]="compararObjectos" class="form-control" name="categoria" [(ngModel)]="cliente.categoria">
                                        <option [ngValue]="categoria" *ngFor="let categoria of filter.categorias | filterBy:{habilitado:true}">{{categoria.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="usuario.empresa.usar_ventas">
                            <div class="col-sm-1 col-form-label text-sm-right">
                                {{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_SUBCATEGORIA)}}
                                <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('SUBCATEGORIAS CLIENTES','obtenerSubCategoriasClientesEmpresa')"></span>
                            </div>
                            <div class="col-sm-3">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <select [compareWith]="compararObjectos" class="form-control" name="subcategoria" [(ngModel)]="cliente.subcategoria">
                                        <option [ngValue]="subcategoria" *ngFor="let subcategoria of filter.subcategorias | filterBy:{habilitado:true}">{{subcategoria.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="usuario.empresa.usar_vencimiento_economico_ventas">
                            <div class="col-1 col-form-label text-sm-right">
                                Valor límite crédito
                            </div>

                            <div class="col-2">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="number" id="valor_limite_credito" name="valor_limite_credito" [(ngModel)]="cliente.valor_limite_credito" placeholder="Valor Límite crédito" step="0.001" min="0" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </ng-container>
                    </div>
                  </div>

                  <div id="step-3" *ngIf="usuario.empresa.usar_georeferenciacion_clientes">
                    <div class="  row">
                        <div class="col-3 col-form-label text-sm-right">
                            Ubicación en el mapa
                          </div>
                          <div class="col-9">
                            <google-map *ngIf="conexion_google_map" #mapa
                                width="100%"
                                height="300px"
                                [center]="center"
                                [zoom]="zoom"
                                [options]="options">
                                <map-marker
                                    #markerElem
                                    *ngFor="let marker of markers"
                                    [position]="marker.position"
                                    [label]="marker.label"
                                    [title]="marker.title"
                                    [options]="marker.options"
                                    (mapDragend)="arrastarPinMapa($event)"
                                    >
                                </map-marker>
                            </google-map>
                          </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div> <!-- .card -->

        </div>
      </div>
  </ng-template>

<ng-template #mapa_clientes let-modal>
    <div class="modal-header card-header">
        <div class="col-4">
            <span class="text-blue text-125">Clientes</span>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-primary btn-primary" (click)="imprimirMapaClientes()" >
                <i class="fa fa-print red2 align-top bigger-125"></i>
            </button>&nbsp;
            <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
              <i class="fa fa-times red2 align-top bigger-125"></i>
          </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="widget-body">
            <div class="row">
                <div class="col-12" id="map-view">
                    <google-map #mapa
                        width="100%"
                        height="80vh"
                        [center]="center"
                        [zoom]="zoom"
                        [options]="options">
                        <map-marker
                            #markerElem
                            *ngFor="let marker of markers"
                            [position]="marker.position"
                            [label]="marker.label"
                            [title]="marker.title"
                            [options]="marker.options"
                            (mapClick)="clickMarcador(marker)"
                            >
                        </map-marker>
                    </google-map>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_cliente_contactos let-modal>
  <div class="modal-header card-header">
      <div class="col-4">
          <span class="text-blue text-125">Lista de Contactos</span>
      </div>
      <div class="col-4">
          <button type="button" class="btn btn-primary btn-primary" (click)="crearNuevoContacto()" >
              <i class="fa fa-plus red2 align-top bigger-125"></i>
          </button>&nbsp;
          <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="widget-body">
        <table class="table table-striped table-bordered table-hover">
					<thead>
						<tr>
							<th>Nº</th>
							<th>Nombre</th>
							<th>C.I.</th>
							<th>Dirección</th>
							<th>Teléfono</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let contacto of cliente.contactos; let i=index;">
							<td>{{i + 1}}</td>
							<td>{{contacto.persona.nombre_completo}}</td>
							<td>{{contacto.persona.ci}}</td>
							<td>{{contacto.persona.direccion}}</td>
							<td>{{contacto.persona.telefono}}</td>
							<td>
                <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionContactoCliente(contacto)">
                  <i class="fa fa-trash-alt text-105"></i>
                </a>
							</td>
						</tr>
					</tbody>
				</table>
      </div>
  </div>
</ng-template>

<ng-template #modal_contacto_cliente let-modal>
  <div class="modal-header card-header">
      <div class="col-4">
          <span class="text-blue text-125">Registro Contacto</span>
      </div>
      <div class="col-4">
          <button type="button" class="btn btn-primary btn-primary" (click)="guardarRegistroContacto()" >
              <i class="fa fa-save red2 align-top bigger-125"></i>
          </button>&nbsp;
          <button type="button" class="btn btn-primary btn-danger" (click)="modal.dismiss('Cross click')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm">
                <a (click)="verRegistroContactoNuevo(true)" [ngClass]="{'active':ver_registro_contacto_nuevo}" class="nav-link text-left py-3" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                  Nuevo
                </a>
              </li>
              <li class="nav-item brc-success shadow-sm">
                <a (click)="verRegistroContactoNuevo(false)" [ngClass]="{'active':!ver_registro_contacto_nuevo}" class="nav-link text-left py-3" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                  Existente
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="text-primary-d3 text-120 mb-2" *ngIf="ver_registro_contacto_nuevo">
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Nombres</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input required type="text" id="con_nombres" [(ngModel)]="contacto.persona.nombres" placeholder="nombres" class="form-control" />
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Apellido paterno</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input type="text" id="con_apellido_paterno" [(ngModel)]="contacto.persona.apellido_paterno" placeholder="apellido paterno" class="form-control" />
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Apellido materno</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input type="text" id="con_apellido_materno" [(ngModel)]="contacto.persona.apellido_materno" placeholder="apellido materno" class="form-control" />
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>C.I.</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input required type="text" id="con_ci" [(ngModel)]="contacto.persona.ci" placeholder="C.I." class="form-control"/>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Direccion</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input type="text" id="con_direccion" [(ngModel)]="contacto.persona.direccion" placeholder="Direccion" class="form-control"/>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Telefono</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input type="text" id="con_telefono" [(ngModel)]="contacto.persona.telefono" placeholder="Telefono" class="form-control"/>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Correo electrónico</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input type="text" id="con_email" [(ngModel)]="contacto.persona.correo_electronico" placeholder="Correo electrónico" class="form-control"/>
                    </div>
                  </div>
                </div>
                <div class="text-primary-d3 text-120 mb-2" *ngIf="!ver_registro_contacto_nuevo">
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <label>Nombre completo</label>
                    </div>
                    <div class="col-sm-12 col-md-9">
                      <input autocomplete="off" id="regcontactcli"
                      [disabled]="contacto.persona && contacto.persona.id" [(ngModel)]="persona_busqueda"
                      [ngbTypeahead]="buscarContactoCliente" placeholder="Buscar contacto" [resultTemplate]="rtp" name="persona_busqueda"
                      [inputFormatter]="formatterContactoCliente"  (selectItem)="establecerContactoCliente($event)"
                      type="text" class="form-control"/>

                      <ng-template #rtp let-r="result" let-t="term">
                        <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                        <ngb-highlight [result]="r.nombre_completo+'-'+r.ci" [term]="t"></ngb-highlight>
                      </ng-template>

                      <a href="javascript:void(0)" *ngIf="contacto.persona && contacto.persona.id" (click)="reiniciarPersona()" class="card-toolbar-btn text-danger-m1">
                          <i class="ace-icon fa fa-trash bigger-130"></i>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
  </div>
</ng-template>

<ng-template #cliente_precios_productos let-modal>
  <div class="modal-header card-header">
    <div class="col-8">
        <span class="text-blue text-125">Precios Productos por Cliente : {{cliente.identificacion}}</span>
    </div>
    <div class="col-4">
        <button  type="button" class="btn btn-primary" (click)="guardarPreciosProductosCliente()">
            <i class="fa fa-save align-top bigger-125"></i>
        </button>&nbsp;
        <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="modal.close('')" >
            <i class="fa fa-times red2 align-top bigger-125"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
  <div class="  form-row">
    <div class="col-12 col-form-label text-sm-center text-grey">
      <div class="card bgc-primary brc-primary radius-0">
        <div class="card-header">
          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Conceptos de precios</h5>
        </div>

        <div class="card-body p-0 bg-white">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">Sucursal</th>
                <th>Producto</th>
                <th>Concepto</th>
                <th>Precio Unitario</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                  <select [compareWith]="compararObjectos" class="form-control" name="id_sucursal" [(ngModel)]="cliente.producto_precio.sucursal">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales_precios">{{sucursal.nombre}}</option>
                  </select>
                </td>
                <td>
                  <input autocomplete="off" [disabled]="(cliente.producto_precio.producto && cliente.producto_precio.producto.id)"
                   [(ngModel)]="producto_busqueda" [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                  [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                  type="text" class="form-control"/>

                  <ng-template #rtp let-r="result" let-t="term">
                      <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                      <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                  </ng-template>

                  <a href="javascript:void(0)" *ngIf="cliente.producto_precio.producto && cliente.producto_precio.producto.id" (click)="iniciarProductoPrecio()" class="card-toolbar-btn text-danger-m1">
                      <i class="ace-icon fa fa-trash bigger-130"></i>
                  </a>
                </td>
                <td>
                  <select [compareWith]="compararObjectos" class="form-control" required name="id_concepto" [(ngModel)]="cliente.producto_precio.concepto">
                    <option [ngValue]="precios_producto" *ngFor="let precios_producto of filter.tipo_producto_precio.clases">{{precios_producto.nombre}}</option>
                  </select>
                  <span *ngIf="usuario.empresa.usar_precios_productos" class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRODUCTO_PRECIOS,'obtenerConceptosPreciosProductos')"></span> &nbsp;
                </td>
                <td>
                  <input name="id_precio" id="id_precio" class="form-control text-center" type="number" [(ngModel)]="cliente.producto_precio.precio_unitario" step="0.001"
                  min="0">
                </td>
                <td>
                  <button *ngIf="usuario.empresa.usar_precios_productos" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(cliente.producto_precio)"
                      class="btn btn-success btn-sm">
                    <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                  </button>
                  <button *ngIf="usuario.empresa.usar_precios_productos_sucursal" id="agregar_detalle_producto_precio" type="submit" (click)="agregarProductoPrecio(cliente.producto_precio)"
                      class="btn btn-success btn-sm">
                    <i class="ace-icon fa fa-arrow-down bigger-110"></i>
                  </button>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 col-form-label text-sm-center text-grey">
      <div class="card bgc-primary brc-primary radius-0">
        <div class="card-header">
          <h5 class="card-title text-white font-light">
            <i class="fa fa-table mr-2px"></i> Precios
            <a title="Importar precios" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionsincronizacion()">
              <i class="ace-icon fa fa-sync bigger-130"></i>
            </a>
          </h5>
        </div>

        <div class="card-body p-0 bg-white">
          <table class="table table-striped table-hover mb-0">
            <thead>
              <tr>
                <th>
                  Nº
                </th>
                <th *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                  Sucursal
                </th>
                <th>
                  Producto
                </th>
                <th>
                  Concepto
                </th>
                <th>
                  Precio Unitario
                </th>
                <th>
                  Por Defecto
                </th>
                <th>
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let producto_precio of cliente.productos_precios; let i=index;">
                <tr *ngIf="!producto_precio.eliminado">
                  <td>
                    {{i+1}}
                  </td>
                  <td *ngIf="usuario.empresa.usar_precios_productos_sucursal">
                    {{producto_precio.sucursal.nombre}}
                  </td>
                  <td>
                    {{producto_precio.producto.nombre}}
                  </td>
                  <td>
                    {{producto_precio.concepto.nombre}}
                  </td>
                  <td>
                    <input class="form-control text-center" type="number" [(ngModel)]="producto_precio.precio_unitario" step="0.001" min="0">
                  </td>
                  <td>
                    <label>
                      <input (change)="establecerPrecioPorDefecto(producto_precio)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_precio.por_defecto"/>
                    </label>
                  </td>
                  <td>
                    <a class="text-danger-m1 mx-2px" href="javascript:void(0)" (click)="eliminarDetalleProductosPrecio(producto_precio)">
                      <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #clientes_importacion_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Clientes a importar</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || clientes_importacion.length==0" (click)="guardarClientes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="alert alert-danger" *ngIf="mensaje_importacion">
						<strong>
							<i class="ace-icon fa fa-times"></i>
							Oh no!
						</strong>
						{{mensaje_importacion}}.
						<br>
					</div>
					<div class="table-fixed-header">
						<table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
							<thead fix-head class="table-head-color sticky-nav">
								<tr>
									<th>Nº</th>
									<th>Código</th>
									<th>Identificación</th>
                  <th>Descripción</th>
									<th>Razón Social</th>
									<th>Nit</th>
                  <th>Dirección</th>
                  <th>Categoria</th>
                  <th>Subcategoria</th>
                  <th>Categoria 2</th>
                  <th>Telefono 1</th>
                  <th>Telefono 2</th>
                  <th>Correo electronico</th>
                  <th *ngIf="usuario.empresa.usar_georeferenciacion_clientes">Geo Latitud</th>
                  <th *ngIf="usuario.empresa.usar_georeferenciacion_clientes">Geo Longitud</th>
								</tr>
							</thead>

							<tbody>
								<tr *ngFor="let cliente of clientes_importacion; let i=index;">
									<td>{{i+1}}</td>
									<td>{{cliente.codigo}}</td>
									<td>{{cliente.identificacion}}</td>
                  <td>{{cliente.descripcion}}</td>
                  <td>{{cliente.razon_social}}</td>
                  <td>{{cliente.nit}}</td>
                  <td>{{cliente.direccion}}</td>
                  <td>{{cliente.categoria}}</td>
                  <td>{{cliente.subcategoria}}</td>
                  <td>{{cliente.categoria_2}}</td>
                  <td>{{cliente.telefono1}}</td>
                  <td>{{cliente.telefono2}}</td>
                  <td>{{cliente.email}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
