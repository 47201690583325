import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { RedesSocialesService } from '../../services/redes-sociales/redes-sociales.service';

declare const $:any;

@Component({
  selector: 'app-conversacion-whatsapp',
  templateUrl: './conversacion-whatsapp.component.html',
  styleUrls: ['./conversacion-whatsapp.component.css']
})
export class ConversacionWhatsappComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  mensajes:any=[];
  mensaje:any={name:"",mensajes:[]};
  sucursal:any;
  mensaje_seleccionado:boolean=false;
  @ViewChildren('chat_mensajes') chats_lectura: QueryList<any>;

  constructor(public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    private socket: Socket,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    public redesSocialesService:RedesSocialesService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    let me=this;
    this.loadScript('../assets/chat-template/dark-version/js/bootstrap.bundle.min.js');
    this.loadScript('../assets/chat-template/dark-version/vendor/slimscroll/slimscroll.min.js');
    this.loadScript('../assets/chat-template/dark-version/vendor/slimscroll/custom-scrollbar.js');
    this.loadScript('../assets/chat-template/dark-version/js/main.js');
    this.obtenerContactosWhatsapp();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerContactosWhatsapp(){
    this.blockUI.start();
    this.redesSocialesService.obtenerListaContactosWhatsapp(this.sucursal.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.mensajes=res;
    });
  }

  mostrarConversacion(mensaje){
    this.blockUI.start();
    this.mensaje=mensaje;
    this.mensaje.mensajes=[];
    this.redesSocialesService.obtenerListaMensajesContactoWhatsapp(this.sucursal.id,mensaje).subscribe((res:any)=>{
      this.blockUI.stop();
      this.mensaje_seleccionado=true;
      console.log(res);
      this.mensaje.mensajes=res.mensajes;
      this.chats_lectura.changes.subscribe(t => {
        $('.chatContainerScroll').slimScroll({
          height: "calc(100vh - 220px)",
          color: '#d6dbe6',
          alwaysVisible: false,
          size: "5px",
          distance: '0px',
          railVisible: false,
          railColor: "#d6dbe6",
          start: 'bottom'
        });
      });
    });
  }

}
