<div class="modal-header card-header">
    <div class="col-12 col-md-4">
        <div class="row">
            <div class="col-3 col-md-6">
                <span class="text-blue text-125">Datos Venta</span>
            </div>
            <div class="col-9 col-md-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardar()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
                <button type="button" (click)="guardarVentaBorrador()" [disabled]="venta.detallesVenta.length==0" class="btn btn-success">
                    <i class="fa fa-bookmark align-top bigger-125"></i>   
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="abrirPopupConfirmacionCierreVentana()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-8" *ngIf="!inhabilitar_busqueda_item">
        <div class="row">
            <div class="col-12">
                <h4 class="widget-title" *ngIf="venta.usuario.empresa.usar_servicios">
                    <label>
                        <input name="switch-field-1" class="ace-switch input-lg ace-switch-servicio bgc-blue-d1" type="checkbox" [(ngModel)]="detalleVenta.es_servicio" (change)="cambiarServicio(detalleVenta.es_servicio)"/>
                    </label>
                </h4>
                <span class="text-blue text-125" *ngIf="!venta.usuario.empresa.usar_servicios">Búsqueda Item</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="row">
            <div class="col-md-4 col-xs-12 no-padding">
                <div class="widget-box">
                    <div class="widget-body">
                        <div class="widget-main">
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Movimiento</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select (change)="establecerTransaccion()" [compareWith]="compararObjectos" id="venta_movimiento" name="movimiento" [disabled]="inhabilitar_movimiento" class="form-control" [(ngModel)]="venta.transaccion">
                                            <option [ngValue]="transaccion" *ngFor="let transaccion of movimientosEgreso"><ng-container *ngIf="transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_PROFORMA">{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_RECIBO_NOTA_VENTA)}}</ng-container><ng-container *ngIf="transaccion.nombre_corto!=gloval_variable.Dictionary.EGRE_PROFORMA">{{transaccion.nombre}}</ng-container></option>
                                        </select>
                                    </div>
                                </div>
                                <div *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL" class="col-md-2 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <label>Nº Factura</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                        <input onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" class="form-control" name="factura" type="number" step="0" min="1" [(ngModel)]="venta.factura"/>
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>Tipo de Pago</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <select [compareWith]="compararObjectos" id="venta_pago" name="pago" [disabled]="inhabilitar_tipo_pago" class="form-control" [(ngModel)]="venta.tipoPago" (change)="cambiarTipoPago()">
                                            <option [ngValue]="tipoPago" *ngFor="let tipoPago of tiposPago">{{tipoPago.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="venta.usuario.empresa.usar_sucursales || (venta.usuario.empresa.usar_inventario && (venta.usuario.empresa.usar_almacenes || venta.usuario.empresa.usar_almacenes_empleado))">
                                <hr>
                                <div class="row">
                                    <div *ngIf="venta.usuario.empresa.usar_sucursales" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Sucursal</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_sucursal" name="sucursal" [disabled]="inhabilitar_sucursal" class="form-control" [(ngModel)]="venta.sucursal" (change)="establecerSucursal(venta.sucursal)">
                                                <option [ngValue]="sucursal" *ngFor="let sucursal of venta.sucursales">{{sucursal.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div *ngIf="venta.usuario.empresa.usar_inventario && (venta.usuario.empresa.usar_almacenes || venta.usuario.empresa.usar_almacenes_empleado)" class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Almacen</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <select [compareWith]="compararObjectos" id="venta_almacen" name="almacen" [disabled]="inhabilitar_almacen" class="form-control" [(ngModel)]="venta.almacen">
                                                <option [ngValue]="almacen" *ngFor="let almacen of almacenes">{{almacen.nombre}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL">
                                <hr >
                                <div class="row">
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <label>Actividad</label>
                                    </div>
                                    <div class="col-md-9 col-sm-12 col-xs-12">
                                    <select style="width:300px;" [compareWith]="compararObjectos" (change)="establecerActividad(venta.actividad)" name="actividad" class="form-control" [(ngModel)]="venta.actividad">
                                            <option [ngValue]="actividad" *ngFor="let actividad of actividades">{{actividad.nombre}}</option>
                                        </select>
                                    </div>
                                </div>&nbsp;
                                <div class="row" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL">
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <label>Documento Sector</label>
                                    </div>
                                    <div class="col-md-9 col-sm-12 col-xs-12">
                                    <select style="width:300px;" [compareWith]="compararObjectos" name="sin_documento" class="form-control" [(ngModel)]="venta.sin_documento">
                                            <option [ngValue]="sin_documento" *ngFor="let sin_documento of documentos_sector">{{sin_documento.nombre}}</option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row" *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL">
                                <div class="col-md-2 col-sm-6 col-xs-6">
                                    <label>Tipo Doc.</label>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <select [disabled]="inhabilitar_cliente || venta.cliente.id" [compareWith]="compararObjectos" class="form-control" required name="tipo_documento" [(ngModel)]="venta.cliente.tipo_documento">
                                        <option [ngValue]="tipo_documento" *ngFor="let tipo_documento of sin_documentos_identidad">{{tipo_documento.nombre}}</option>
                                    </select>
                                </div>
                                <ng-container *ngIf="venta.cliente.tipo_documento && venta.cliente.tipo_documento.nombre_corto=='1'">
                                    <div class="col-md-3 col-sm-6 col-xs-6 p-0">
                                        <label>Complemento</label>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-xs-6">
                                        <input [disabled]="inhabilitar_cliente || venta.cliente.id" required type="text" id="complemento" name="complemento" [(ngModel)]="venta.cliente.complemento" placeholder="Complemento" class="form-control" />
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="venta.cliente.tipo_documento && venta.cliente.tipo_documento.nombre_corto=='5'">
                                    <div class="col-md-3 col-sm-6 col-xs-6 p-0">
                                        <label>¿Excepción?</label>
                                    </div>
                                    <div class="col-md-3 col-sm-6 col-xs-6">
                                        <input name="excepcion" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="venta.cliente.excepcion"/>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" [disabled]="inhabilitar_cliente || venta.cliente.id" (keypress)="buscarNit($event,venta.cliente.nit)" id="venta_nit" name="venta_nit" required type="text" [(ngModel)]="venta.cliente.nit" placeholder="Ingrese el NIT">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-10 p-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <ng-container *ngIf="clientes_nit.length==0" >
                                            <ng-container *ngIf="inhabilitar_cliente || venta.cliente.id">
                                                <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{venta.cliente.identificacion}}</span><br>
                                                <span *ngIf="(venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL) && venta.cliente.razon_social" class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{venta.cliente.razon_social}}</span>
                                            </ng-container>
                                            <input required id="venta_razon_social" name="venta_razon_social" *ngIf="!inhabilitar_cliente && !venta.cliente.id"
                                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerCliente($event)"
                                            type="text" class="form-control" [(ngModel)]="razon_busqueda" 
                                            [ngbTypeahead]="buscarCliente" placeholder="Buscar cliente" 
                                            (keypress)="enfocarElemento($event,'venta_id_producto')" autocomplete="off"
                                            />
                                        </ng-container>                                      
                                        <select *ngIf="clientes_nit.length>0" [compareWith]="compararObjectos" name="venta_cliente" class="form-control" [(ngModel)]="venta.cliente" (change)="establecerCliente(venta.cliente)">
                                            <option [ngValue]="cliente" *ngFor="let cliente of clientes_nit">{{(cliente.razon_social +'-'+(cliente.identificacion?cliente.identificacion:''))}}</option>
                                        </select>

                                        <ng-template #rt let-r="result" let-t="term">
                                            <ngb-highlight [result]="r.identificacion+' '+r.razon_social+' '+r.nit" [term]="t"></ngb-highlight>
                                          </ng-template>
                                    </div>
                                </div>
                                <div class="col-md-1 col-sm-12 col-2 p-0">
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" [class.disabled]="inhabilitar_cliente" href="javascript:void(0)" *ngIf="venta.cliente.id" (click)="reiniciarCliente()">
                                            <i class="fa fa-trash bigger-130 red2"></i>
                                        </a>
                                    </div>
                                    <div class="col-12 col-sm-12 col-xs-12">
                                        <a *ngIf="!venta.cliente.id" [class.disabled]="inhabilitar_cliente" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary">
                                            <i class="fa fa-plus bigger-130"></i>
                                        </a>
                                        <a *ngIf="venta.cliente.id" [class.disabled]="inhabilitar_cliente" id="creacion_cliente" href="javascript:void(0)" (click)="abrirPopupRegistroRapidoCliente()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
                                            <i class="fa fa-edit bigger-130"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 pr-0 pl-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label for="fefin">Fecha </label>&nbsp;
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 pr-0">
                                        <div class="input-group">
                                            <input [disabled]="inhabilitar_fecha" required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                                    name="dp" [(ngModel)]="venta.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <ng-container *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL">
                                    <div class="col-md-2">
                                        <label>Hora</label>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="input-group">
                                            <ngb-timepicker [(ngModel)]="venta.hora"></ngb-timepicker>
                                        </div>  
                                    </div>
                                </ng-container>
                                <div class="col-md-7 col-sm-12 col-xs-12 pr-0">
                                    <div class="col-md-12 col-sm-12 col-xs-12" >
                                        <label>Observación</label>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <input class="form-control" autocomplete="off" id="venta_observacion" name="observacion" type="text" [(ngModel)]="venta.observacion" placeholder="Observacion">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_TARJ || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('TARJETA'))">
                                <hr >
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <label>Número de Tarjeta:</label>
                                    </div>
                                    <div class="col-md-7 col-sm-7 col-xs-7">
                                        <input [hiddenInput]="true" mask="0000-XXXX-XXXX-0000" class="form-control" placeholder="xxxx-xxxx-xxxx-xxxx" [(ngModel)]="venta.numero_tarjeta" />
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CONT || venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_GFTC || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('GIFT'))">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_GFTC || (venta.tipoPago.nombre && venta.tipoPago.nombre.includes('GIFT'))">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >Gift Card (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" name="importe_gift_card" required type="number" step="0.01" min="{{pagoMinimo}}" [(ngModel)]="venta.importe_gift_card" (keyup)="calcularCambio()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >Pagado (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" id="venta_pagado" name="pagado" required type="number" step="0.01" min="{{pagoMinimo}}" [(ngModel)]="venta.pagado" (keyup)="calcularCambio()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12 col-xs-12 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Cambio (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" disabled type="number" step="0.01" [(ngModel)]="venta.cambio"/>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                <hr >
                                <div class="row">
                                    <div class="col-md-2 col-sm-2 col-xs-2 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >Dias:</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12 descuento-global">
                                            <input class="form-control" required type="number" min="1" name="dias" [(ngModel)]="venta.dias_credito" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-3 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Total (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12 descuento-global">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="venta.total" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-4 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label >A cuenta (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input class="form-control" [disabled]="inhabilitar_a_cuenta" min="0" name="acuenta" required type="number" step="0.001" [(ngModel)]="venta.a_cuenta" value="0.0" (change)="calcularSaldo()" (keyup)="calcularSaldo()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-3 col-xs-3 p-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12" >
                                            <label>Saldo (Bs):</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12 descuento-global">
                                            <input class="form-control" disabled type="number" step="0.001" [(ngModel)]="venta.saldo"/>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <hr>
                            <div class="row">
                                <div class="col-md-5 col-sm-12 col-xs-12 p-0" >
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label class="text-primary p-0">
                                            <i class="fas fa-file-alt"></i> Comprobante Pago : 
                                        </label>  
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <form id="form-pago-comprobante" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                            <div class="fallback d-none">
                                                <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                            </div>
                            
                                            <div class="dz-default dz-message" style="margin: 0px !important;">
                                                <span class="text-150  text-grey-d2">
                                                <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                                </span>
                                            </div>
                                            </form>
                            
                                            <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                                            <div id="template-pago-comprobante" class="d-none">
                                            <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                                <div class="dz-image">
                                                <img alt="Archivo" data-dz-thumbnail="" />
                                                </div>
                            
                                                <div class="dz-details">
                                                <div class="dz-size">
                                                    <span data-dz-size=""></span>
                                                </div>
                            
                                                <div class="dz-filename">
                                                    <span data-dz-name=""></span>
                                                </div>
                                                </div>
                            
                                                <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                                <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                                </div>
                            
                                                <div class="dz-error-message">
                                                <span data-dz-errormessage=""></span>
                                                </div>
                            
                                                <div class="dz-success-mark">
                                                <span class="fa-stack fa-lg text-150">
                                                    <i class="fa fa-circle fa-stack-2x text-white"></i>
                                                    <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                                </span>
                                                </div>
                            
                                                <div class="dz-error-mark">
                                                <span class="fa-stack fa-lg text-150">
                                                    <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                                    <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                                </span>
                                                </div>
                                            </div>
                                            </div> 
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general && (venta.tipoPago.nombre_corto==gloval_variable.Dictionary.TIPO_PAGO_CRE && (venta.a_cuenta && venta.a_cuenta>0))">
                                    <ng-container *ngTemplateOutlet="caja"></ng-container>
                                </div>
                                <div class="col-md-4 col-sm-12 col-xs-12 p-0" *ngIf="usuario.empresa.usar_caja_general && venta.tipoPago.nombre_corto!=gloval_variable.Dictionary.TIPO_PAGO_CRE">
                                    <ng-container *ngTemplateOutlet="caja"></ng-container>
                                </div>
                            </div>
                            
                            <hr class="datos-adicionales" style="display:none;">
                            <div class="row">
                                <button type="button" (click)="mostrarOcultarDatosAdicionales()" class="btn btn-xs pos-rel pull-left">
                                    <i class="ace-icon fa fa-angle-double-down bigger-110"></i>
                                </button>
                                <div class="datos-adicionales col-md-12 col-sm-12 col-xs-12 no-padding" style="display:none;">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 no-padding">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <label>Canal</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <select [compareWith]="compararObjectos" name="canal" class="form-control" [(ngModel)]="venta.canal">
                                                    <option [ngValue]="canal" *ngFor="let canal of canales">{{canal.nombre}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="configuracion_pagina.datos.opciones_permisos.ver_subida_comprobante_adjunto">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <label class="text-primary p-0">
                                                    <i class="fas fa-file-alt"></i> Documentos Venta : 
                                                </label>  
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <form id="form-documentos-venta" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                                    <div class="fallback d-none">
                                                      <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                                                    </div>
                                    
                                                    <div class="dz-default dz-message" style="margin: 0px !important;">
                                                      <span class="text-150  text-grey-d2">
                                                      <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                                      </span>
                                                    </div>
                                                  </form>
                                    
                                                  <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                                                  <div id="template-documentos-venta" class="d-none">
                                                    <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                                      <div class="dz-image">
                                                        <img alt="Archivo" data-dz-thumbnail="" />
                                                      </div>
                                    
                                                      <div class="dz-details">
                                                        <div class="dz-size">
                                                          <span data-dz-size=""></span>
                                                        </div>
                                    
                                                        <div class="dz-filename">
                                                          <span data-dz-name=""></span>
                                                        </div>
                                                      </div>
                                    
                                                      <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                                      </div>
                                    
                                                      <div class="dz-error-message">
                                                        <span data-dz-errormessage=""></span>
                                                      </div>
                                    
                                                      <div class="dz-success-mark">
                                                        <span class="fa-stack fa-lg text-150">
                                                          <i class="fa fa-circle fa-stack-2x text-white"></i>
                                                          <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                                      </span>
                                                      </div>
                                    
                                                      <div class="dz-error-mark">
                                                        <span class="fa-stack fa-lg text-150">
                                                          <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                                          <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                                      </span>
                                                      </div>
                                                    </div>
                                                  </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 no-padding" *ngIf="venta.usuario.empresa.usar_inventario && configuracion_pagina.datos.opciones_permisos.ver_descuento_almacen">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <label>¿Descontar Almacen?</label>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <label>
                                                    <input [disabled]="inhabilitar_descontar_almacen"  name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="venta.descontar_almacen" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container>
                                <hr [ngClass]="{'d-none':(!venta.usuario.empresa.usar_pedidos_produccion || venta.pedido.id)}">
                                <div class="row" [ngClass]="{'d-none':(!venta.usuario.empresa.usar_pedidos_produccion || venta.pedido.id)}">
                                    <div class="col-md-3 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>¿Generar Pedido?</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <input [disabled]="inhabilitar_generar_pedido" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="venta.es_pedido"/>
                                        </div>
                                    </div>
                                    <div [ngClass]="{'d-none':!venta.es_pedido}" class="col-md-5 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Fecha Entrega</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <div class="input-group" *ngIf="venta.pedido">
                                                <input [disabled]="inhabilitar_generar_pedido" name="fecha_entrega_pedido" class="form-control" placeholder="dd/mm/yyyy"
                                                        [(ngModel)]="venta.pedido.tiempo_despacho_texto" ngbDatepicker #j="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary calendar" (click)="j.toggle()" type="button"></button>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div [ngClass]="{'d-none':!venta.es_pedido}" class="col-md-4 col-sm-12 col-xs-12 no-padding">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <label>Foto</label>
                                        </div>
                                        <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
                                            <form id="form-pedido-imagen" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                                                <div class="fallback d-none">
                                                  <input [disabled]="inhabilitar_generar_pedido" name="file_pedido" type="file" multiple (change)="subirArchivoImagenPedido($event)"/>
                                                </div>
                                
                                                <div class="dz-default dz-message" style="margin: 0px !important;">
                                                  <span class="text-150  text-grey-d2">
                                                  <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                                                  </span>
                                                </div>
                                              </form>
                                
                                              <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                                            <div id="template-pedido-imagen" class="d-none">
                                                <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                                                  <div class="dz-image">
                                                    <img alt="Archivo" data-dz-thumbnail="" />
                                                  </div>
                                
                                                  <div class="dz-details">
                                                    <div class="dz-size">
                                                      <span data-dz-size=""></span>
                                                    </div>
                                
                                                    <div class="dz-filename">
                                                      <span data-dz-name=""></span>
                                                    </div>
                                                  </div>
                                
                                                  <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                                                    <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                                                  </div>
                                
                                                  <div class="dz-error-message">
                                                    <span data-dz-errormessage=""></span>
                                                  </div>
                                
                                                  <div class="dz-success-mark">
                                                    <span class="fa-stack fa-lg text-150">
                                                      <i class="fa fa-circle fa-stack-2x text-white"></i>
                                                      <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                                                  </span>
                                                  </div>
                                
                                                  <div class="dz-error-mark">
                                                    <span class="fa-stack fa-lg text-150">
                                                      <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                                                      <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                                                  </span>
                                                  </div>
                                                </div>
                                            </div> 




                                            <!--<div class="col-md-6 col-sm-6 col-xs-6">
                                                <div class="fileUpload btn btn-primary">
                                                    <span class="glyphicon glyphicon-picture align-top bigger-125"></span>
                                                    <input class="upload" type="file" id="venta-imagen-pedido" onchange="uploadImage(this,'imagen-pedido-oculto')"/>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-xs-6">
                                                <img *ngIf="pedido.imagen" src="{{pedido.imagen}}" width="50" height="50">
                                                <input id="imagen-pedido-oculto" [(ngModel)]="venta.pedido.imagen" name="imagen" type="hidden" ng-update-hidden>
                                            </div>-->


                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-xs-12 no-padding">
                <div class="widget-box" *ngIf="!inhabilitar_busqueda_item">
                    <div class="widget-body">
                        <div class="widget-main">
                            <form id="formularioBusquedaProducto" name="formularioBusquedaProducto" novalidate>
                                <table class="table table-striped table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <label *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !venta.usuario.empresa.usar_combos">PRODUCTO</label>
                                                <label *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO">SERVICIO</label>
                                                <label *ngIf="venta.usuario.empresa.usar_combos && (detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO)">
                                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-item bgc-blue-d1" type="checkbox" [(ngModel)]="detalleVenta.es_combo" (change)="cambiarCombo(detalleVenta.es_combo)" />
                                                </label>
                                                &nbsp;
                                                <button *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && !busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-warning btn-sm">
                                                    <i class="ace-icon fa fa-barcode bigger-110"></i>
                                                </button>
                                                <button *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && busqueda_codigo_barra" type="button" (click)="activarDesactivarBusquedaCodigoBarra()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-gift bigger-110"></i>
                                                </button>
                                            </th>
                                            <th *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>CÓD. ITEM</label>
                                            </th>
                                            <th *ngIf="venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA || detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>UNID. MED.</label>
                                            </th>
                                            <th *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && venta.usuario.empresa.usar_vencimientos && detalleVenta.producto && detalleVenta.producto.id && detalleVenta.producto.activar_vencimiento">
                                                <label>VENCIMIENTO</label>
                                            </th>
                                            <th *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO  || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>INVENTARIO DISPONIBLE</label>
                                            </th>
                                            <th>
                                                <label>P.U. (Bs/u)</label>
                                            </th>
                                            <th>
                                                <label>CANT. (u)</label>
                                            </th>
                                            <th>
                                                <label>IMP. (Bs)</label>
                                            </th>
                                            <th>
                                                <label>ACC.</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="p-0" id="gallery" *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO">
                                                <input *ngIf="!busqueda_codigo_barra" autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                [disabled]="!venta.almacen || (detalleVenta.producto && detalleVenta.producto.id)" id="venta_id_producto" [(ngModel)]="producto_busqueda"
                                                [ngbTypeahead]="buscarProducto" placeholder="Buscar producto" [resultTemplate]="rtp" name="producto_busqueda"
                                                [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtp let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
                                                </ng-template>

                                                <a href="javascript:void(0)" *ngIf="detalleVenta.producto && detalleVenta.producto.id" (click)="iniciarDetalleVenta()" class="card-toolbar-btn text-danger-m1">
                                                    <i class="ace-icon fa fa-trash bigger-130"></i>
                                                </a>
                                                <input name="busqueda_codigo_barra" class="form-control" *ngIf="busqueda_codigo_barra && (!detalleVenta.producto || !detalleVenta.producto.id)" autocomplete="off" (keypress)="buscarCodigoBarraProducto($event,producto_busqueda)" id="venta_id_producto" required [disabled]="!venta.almacen" type="text" [(ngModel)]="producto_busqueda" placeholder="Esperando al lector..." >
                                                <input name="busqueda_codigo_barra_d" class="form-control" *ngIf="busqueda_codigo_barra && (detalleVenta.producto && detalleVenta.producto.id)" [disabled]="true" type="text" [(ngModel)]="detalleVenta.producto.nombre">
                                                <hr>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <button *ngIf="venta.usuario.empresa.usar_inventario" [disabled]="!venta.almacen" type="button" (click)="abrirBusquedaProducto()" class="btn btn-info btn-sm">
                                                            <i class="ace-icon fa fa-search-plus bigger-110"></i>
                                                        </button>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="imagen-producto-vista clearfix" *ngIf="detalleVenta.producto && detalleVenta.producto.id">
                                                            <a href="{{rest_server+detalleVenta.producto.imagen}}" data-rel="colorbox" title="{{detalleVenta.producto.nombre}}">
                                                                <img alt="sin imagen" src="{{rest_server+detalleVenta.producto.imagen}}" width="30" height="30" class="d-zoom-3" data-size="1000x700" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO">
                                                <input required autocomplete="off" (keypress)="enfocarElemento($event,'venta_precio_unitario')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detalleVenta.descripcion" type="text" />
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">

                                                <input autocomplete="off" (keypress)="enfocarElemento($event,'venta_cantidad')"
                                                [disabled]="!venta.almacen" id="venta_id_combo" [(ngModel)]="combo_buqueda"
                                                [ngbTypeahead]="buscarCombo" placeholder="Buscar combo" [resultTemplate]="rtc" name="combo_buqueda"
                                                [inputFormatter]="formatterCombo"  (selectItem)="establecerCombo($event)"
                                                type="text" class="form-control"/>

                                                <ng-template #rtc let-r="result" let-t="term">
                                                    <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                                                    <ngb-highlight [result]="r.codigo+'-'+r.nombre" [term]="t"></ngb-highlight>
                                                </ng-template>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detalleVenta.producto?detalleVenta.producto.codigo:''}}</td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">{{detalleVenta.combo?detalleVenta.combo.codigo:''}}</td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO && (venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)">
                                                <select class="form-control" style="width:130px;" name="sin_unidad_medida" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleVenta.producto_sin">
                                                    <option [ngValue]="sin_producto_servicio" *ngFor="let sin_producto_servicio of sin_productos_servicios">{{sin_producto_servicio.descripcion}}</option>
                                                </select>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO">{{detalleVenta.producto?detalleVenta.producto.unidad_medida:''}}</td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">{{detalleVenta.combo?detalleVenta.combo.unidad_medida:''}}</td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO && (venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)">
                                                <select class="form-control" style="width:130px;" name="sin_unidad_medida" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleVenta.sin_unidad_medida">
                                                    <option [ngValue]="sin_unidad_medida" *ngFor="let sin_unidad_medida of sin_unidades_medidas">{{sin_unidad_medida.nombre}}</option>
                                                </select>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && venta.usuario.empresa.usar_vencimientos && detalleVenta.producto && detalleVenta.producto.id && detalleVenta.producto.activar_vencimiento">
                                                <select *ngIf="detalleVenta.producto.activar_inventario" name="fechaVencimiento" style="width:95px;" id="venta_fechaVencimiento" [compareWith]="compararObjectos" class="form-control" [(ngModel)]="detalleVenta.inventarioProducto" (change)="actualizarInventarioDisponibleFechaVencimiento()">
                                                    <option [ngValue]="inventario" *ngFor="let inventario of inventariosDisponibleProducto">{{'FV:'+inventario.fechaVencimientoTexto}}</option>
                                                </select>
                                                <label>Lote: {{detalleVenta.lote}}</label>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <div class="c100 p{{inventario_porcentaje}} small {{inventario_color}}" *ngIf="(detalleVenta.combo && detalleVenta.combo.id) || (detalleVenta.producto && detalleVenta.producto.id && detalleVenta.producto.activar_inventario)">
                                                    <span id="venta_CantProd" style="color:black !important;">{{detalleVenta.inventario_disponible}}</span>
                                                    <div class="slice">
                                                        <div class="bar"></div>
                                                        <div class="fill"></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="!venta.usuario.empresa.usar_precios_productos_sucursal && !venta.usuario.empresa.usar_precios_productos && !editar_precio">
                                                <div clas="row">
                                                    <div id="venta_Precio" class="col-12">
                                                        {{detalleVenta.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="aplicacion_productos.puede_modificar">
                                                    <div class="col-12">
                                                        <a class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO || (!venta.usuario.empresa.usar_precios_productos_sucursal && !venta.usuario.empresa.usar_precios_productos && editar_precio)">
                                                <input id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required class="form-control" (change)="calcularImporte()" [(ngModel)]="detalleVenta.precio_unitario" type="number" (keypress)="enfocarElemento($event,'venta_cantidad')"/>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO && (venta.usuario.empresa.usar_precios_productos || venta.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-8">
                                                        {{detalleVenta.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-12 p-0">
                                                        <select *ngIf="detalleVenta.producto" class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleVenta.producto_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detalleVenta.producto.precios">{{precio.concepto?precio.concepto.nombre:''}}</option>
                                                        </select>
                                                    </div>                                               
                                                </div>
                                                <div class="row" *ngIf="aplicacion_productos.puede_modificar"> <!--*ngIf="es_administrador"-->
                                                    <div class="col-12" *ngIf="editar_precio">
                                                        <input class="form-control" id="venta_precio_unitario" name="precio_unitario" step="0.001" min="0.1" required (change)="calcularImporte()" (keyup)="calcularImporte()" [(ngModel)]="detalleVenta.precio_unitario" type="number" />
                                                        <a class="card-toolbar-btn text-primary" href="javascript:void(0)" (click)="establecerPrecio()">
                                                            <i class="fa fa-check-square bigger-130"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-12" *ngIf="!editar_precio">
                                                        <a class="card-toolbar-btn text-success" href="javascript:void(0)" (click)="modificarPrecio()">
                                                            <i class="fa fa-edit bigger-130"></i>
                                                        </a>
                                                    </div> 
                                                </div>
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO && (venta.usuario.empresa.usar_precios_productos || venta.usuario.empresa.usar_precios_productos_sucursal)">
                                                <div clas="row">
                                                    <div class="col-md-12">
                                                        {{detalleVenta.precio_unitario}}
                                                    </div>
                                                </div>
                                                <div clas="row">
                                                    <div class="col-md-12 no-padding">
                                                        <select class="form-control" style="width:130px;" id="venta_precio_seleccion" name="precio_seleccion" 
                                                        [compareWith]="compararObjectos" [(ngModel)]="detalleVenta.combo_precio" (change)="establecerPrecioDetalle()">
                                                            <option [ngValue]="precio" *ngFor="let precio of detalleVenta.combo.precios">{{precio.concepto.nombre}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <input [disabled]="!configuracion_pagina.datos.opciones_permisos.cantidad_habilitado_busqueda" *ngIf="!cantidad_decimal" onfocus="this.select();" (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" name="cantidad" step="1" 
                                                min="1" max="{{detalleVenta.inventario_disponible}}" required class="form-control" 
                                                (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detalleVenta.cantidad" type="number" 
                                                onkeydown="if(event.key==='.'){event.preventDefault();}"  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"/>
                                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                                    <input [disabled]="!configuracion_pagina.datos.opciones_permisos.cantidad_habilitado_busqueda" class="form-control" *ngIf="cantidad_decimal" onfocus="this.select();" 
                                                    (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_cantidad" 
                                                    name="cantidad" step="0.001" min="0.1" max="{{detalleVenta.inventario_disponible}}" 
                                                    required (keyup)="calcularImporte()" (change)="calcularImporte()" [(ngModel)]="detalleVenta.cantidad" type="number" />
                                                </div>
                                            </td>
                                            <td>
                                                {{detalleVenta.importe}}
                                            </td>
                                            <td>
                                                <button id="agregar_detalle_venta" type="button" (click)="agregarDetalleVenta()" class="btn btn-success btn-sm">
                                                    <i class="ace-icon fa fa-plus bigger-110"></i>
                                                </button>
                                                <hr>
                                                <button id="mostrar_desc" type="button" (click)="mostrarDescuentos()" class="btn btn-info btn-sm">
                                                    <i class="fas fa-expand-alt bigger-110"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <th>
                                                <label>DESCUENTOS (-)</label>
                                                <label>
                                                    <input (change)="calcularImporte()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="detalleVenta.tipo_descuento"/>
                                                </label>
                                            </th>
                                            <!--<th>
                                                <label>RECARGOS (+)</label>
                                                <label>
                                                    <input class="ace ace-switch ace-switch-4" type="checkbox" [(ngModel)]="detalleVenta.tipo_recargo" (change)="calcularImporte()"/>
                                                    <span class="lbl check-compra"></span>
                                                </label>
                                            </th>
                                            <th>
                                                <label>ICE (-)</label>
                                            </th>
                                            <th>
                                                <label>EXCENTOS (-)</label>
                                            </th>-->
                                            <th>
                                                <label>TOTAL (Bs):</label>
                                            </th>
                                            <th *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <label>Observación</label>
                                            </th>
                                            <th *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO">
                                                <label>{{obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)}} <span class="fa fa-edit point-edit blue" (click)="abrirTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)"></span></label>
                                            </th>
                                        </tr>
                                        <tr class="des-datos" style="display:none;">
                                            <td >
                                                <input name="desc" required (keyup)="calcularImporte()" [(ngModel)]="detalleVenta.descuento" step="0.001" class="form-control" type="number" value="0"/>
                                            </td>
                                            <!--<td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleVenta.recargo" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleVenta.ice" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>
                                            <td>
                                                <input required (change)="calcularImporte()" [(ngModel)]="detalleVenta.excento" step="0.001" class="col-xs-12" type="number" value="0"/>
                                            </td>-->
                                            <td>
                                                {{detalleVenta.total}}
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_PRODUCTO || detalleVenta.item==gloval_variable.Dictionary.ITEM_COMBO">
                                                <input (keypress)="clickearElemento($event,'agregar_detalle_venta')" id="venta_id_descripcion" name="descripcion" class="form-control" [(ngModel)]="detalleVenta.descripcion" type="text" />
                                            </td>
                                            <td *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO">
                                                <input id="venta_id_campo1" name="campo1" class="form-control" [(ngModel)]="detalleVenta.campo_personalizado_1" type="text" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="widget-box">
                    <div class="card bgc-dark" *ngIf="venta.detallesVenta.length>0">
                        <div class="card-header card-header-sm">
                            <h5 class="card-title text-110 text-white font-light pt-1">DETALLE DE VENTA</h5>
                            <div class="card-toolbar">
                                <div class="keep-open btn-group show" title="Columnas">
                                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Visualización columnas" aria-expanded="true">
                                        <i class="fa fa-th-list text-orange-d1"></i>
                                        <span class="caret"></span>
                                    </button>
                                
                                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Nombre</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_codigo_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Cod. Item</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_unidad_producto" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Und. Med.</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_precio_unitario" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>P.U. (Bs/u)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_cantidad" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Cant. (u)</span>
                                        </label>
                                        <label *ngIf="venta.usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_fecha_vencimiento" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Venc.</span>
                                        </label>
                                        <label *ngIf="venta.usuario.empresa.usar_vencimientos" class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_lote" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Lote</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_importe" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Imp. (Bs)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_descuento" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Desc. (-)</span>
                                        </label>
                                        <label class="dropdown-item dropdown-item-marker">
                                            <input class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="configuracionVentaVista.mostrar_total" (change)="guardarConfiguracionVentaVista()"/>
                                            <span>Total (Bs)</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body bg-white p-0" *ngIf="venta.detallesVenta.length>0">
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <label>Nº</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_producto">
                                            <label>Nombre</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                            <label>Cod. Item</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                            <label>Unid. Med.</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_precio_unitario">
                                            <label>P.U. (Bs/u)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_cantidad">
                                            <label>Cant. (u)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && venta.usuario.empresa.usar_vencimientos">
                                            <label>Venc.</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_lote && venta.usuario.empresa.usar_vencimientos">
                                            <label>Lote</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_importe">
                                            <label>Imp. (Bs)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_descuento">
                                            <label>Desc. (-)</label>
                                        </th>
                                        <th *ngIf="configuracionVentaVista.mostrar_total">
                                            <label>Total (Bs)</label>
                                        </th>
                                        <th style="text-align: center;" data-field="tools">
                                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                                            <div class="fht-cell"></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let detalleVenta of venta.detallesVenta; let i=index;">
                                        <tr  *ngIf="!detalleVenta.eliminado">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_producto">
                                                <span *ngIf="!venta.id">{{(detalleVenta.producto && detalleVenta.producto.id)?detalleVenta.producto.nombre:(detalleVenta.combo && detalleVenta.combo.id)?detalleVenta.combo.nombre:""}} {{detalleVenta.descripcion?detalleVenta.descripcion:""}} {{detalleVenta.campo_personalizado_1? (obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1)+':'+detalleVenta.campo_personalizado_1):''}}</span>
                                                <span *ngIf="venta.id">{{(detalleVenta.producto && detalleVenta.producto.id)?detalleVenta.producto.nombre:(detalleVenta.combo && detalleVenta.combo.id)?detalleVenta.combo.nombre:detalleVenta.descripcion}} {{detalleVenta.campo_personalizado_1? obtenerTextoEmpresa(gloval_variable.Dictionary.TEXTO_DETVENTA_CAMPO1):''}} <input class="form-control" style="width:50px;display:inline;" type="text" [(ngModel)]="detalleVenta.campo_personalizado_1"></span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                                <span *ngIf="detalleVenta.producto">{{detalleVenta.producto.codigo}}</span>
                                                <span *ngIf="detalleVenta.combo">{{detalleVenta.combo.codigo}}</span>
                                                <span *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO && (venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)">{{detalleVenta.producto_sin.codigo_producto_servicio}}</span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                                <span *ngIf="detalleVenta.producto">{{detalleVenta.producto.unidad_medida}}</span>
                                                <span *ngIf="detalleVenta.combo">{{detalleVenta.combo.unidad_medida}}</span>
                                                <span *ngIf="detalleVenta.item==gloval_variable.Dictionary.ITEM_SERVICIO && (venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_MANUAL || venta.transaccion.nombre_corto==gloval_variable.Dictionary.EGRE_FACTURACION_COMPUTARIZADA_EN_LINEA)">{{detalleVenta.sin_unidad_medida.nombre}}</span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_precio_unitario">
                                                <input *ngIf="configuracion_pagina.datos.opciones_permisos.modificar_precio_unitario" type="number" step="0.001" min="0" [(ngModel)]="detalleVenta.precio_unitario" class="form-control" (keyup)="actualizarDetalleVenta(detalleVenta)">
                                                <span *ngIf="!configuracion_pagina.datos.opciones_permisos.modificar_precio_unitario">{{detalleVenta.precio_unitario.toFixed(2)}}</span>
                                            </td>
                                            <td class="cantidad-venta" *ngIf="configuracionVentaVista.mostrar_cantidad">
                                                <input *ngIf="venta.id || configuracion_pagina.datos.opciones_permisos.modificar_cantidad" class="form-control" type="number" step="0" min="0" [(ngModel)]="detalleVenta.cantidad" (change)="actualizarDetalleVenta(detalleVenta)" (keyup)="actualizarDetalleVenta(detalleVenta)">
                                                <span *ngIf="!configuracion_pagina.datos.opciones_permisos.modificar_cantidad">{{detalleVenta.cantidad}}</span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && venta.usuario.empresa.usar_vencimientos">
                                                {{detalleVenta.fecha_vencimiento | date:"dd/MM/yyyy"}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_lote && venta.usuario.empresa.usar_vencimientos">
                                                {{detalleVenta.lote}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_importe">
                                                {{detalleVenta.importe.toFixed(2)}}
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_descuento">
                                                <input *ngIf="venta.id" class="form-control" type="number" step="0" min="0" [(ngModel)]="detalleVenta.descuento" (change)="actualizarDetalleVenta(detalleVenta)" (keyup)="actualizarDetalleVenta(detalleVenta)">
                                                <span *ngIf="!venta.id">{{detalleVenta.descuento}} <i *ngIf="detalleVenta.tipo_descuento" class="fas fa-percent text-primary"></i> <i *ngIf="!detalleVenta.tipo_descuento" class="fas fa-dollar-sign text-primary"></i></span>
                                            </td>
                                            <td *ngIf="configuracionVentaVista.mostrar_total">
                                                {{detalleVenta.total.toFixed(2)}}
                                            </td>
                                            <td>
                                                <a class="card-toolbar-btn text-danger-m1" [class.disabled]="inhabilitar_eliminacion_detalle_venta" href="javascript:void(0)" (click)="eliminarDetalleVenta(detalleVenta)">
                                                    <i class="fa fa-trash bigger-130"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_producto">
                                            <b>TOTALES</b>
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_codigo_producto">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_unidad_producto">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_precio_unitario">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_cantidad">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_fecha_vencimiento && venta.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_lote && venta.usuario.empresa.usar_vencimientos">
                                            
                                        </td>
                                        <td class="total-venta" *ngIf="configuracionVentaVista.mostrar_importe">
                                            {{venta.importe.toFixed(2)}}
                                        </td>
                                        <td class="descuento-global" *ngIf="configuracionVentaVista.mostrar_descuento">
                                            <ng-container *ngIf="usuario.empresa.usar_descuento_global">
                                                <input  (change)="establecerDescuentoGlobal()" name="switch-field-1" class="ace-switch input-lg ace-switch-desc bgc-blue-d1" type="checkbox" [(ngModel)]="venta.tipo_descuento"/>
                                                <input class="form-control" type="number" step="0" min="0" [(ngModel)]="venta.descuento" (change)="establecerDescuentoGlobal()" (keyup)="establecerDescuentoGlobal()">  
                                            </ng-container>
                                            <span *ngIf="!usuario.empresa.usar_descuento_global">{{venta.descuento.toFixed(2)}}</span>
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_recargo">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_ice">
                                            
                                        </td>
                                        <td *ngIf="configuracionVentaVista.mostrar_excento">
                                        </td>
                                        <td class="text-nowrap" *ngIf="configuracionVentaVista.mostrar_total">
                                            <span class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                                {{venta.total.toFixed(2)}}
                                            </span>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>
  

  <ng-template #caja>
    <div class="col-md-12 col-sm-12 col-xs-12" >
        <label>Caja:</label>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <select [compareWith]="compararObjectos" name="caja" class="form-control" [(ngModel)]="venta.caja">
            <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
        </select>
    </div>
  </ng-template>