import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrerasComponent } from './components/carreras/carreras.component';
import { ConceptosIngresosComponent } from './components/conceptos-ingresos/conceptos-ingresos.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VentaEstudianteComponent } from './components/venta-estudiante/venta-estudiante.component';
import { OrderModule } from 'ngx-order-pipe';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { IncripcionMateriaComponent } from './components/incripcion-materia/incripcion-materia.component';
import { ConfiguracionAplicacionConceptosIngresosComponent } from './components/configuracion-aplicacion-conceptos-ingresos/configuracion-aplicacion-conceptos-ingresos.component';



@NgModule({
  declarations: [CarrerasComponent, ConceptosIngresosComponent, VentaEstudianteComponent, IncripcionMateriaComponent, ConfiguracionAplicacionConceptosIngresosComponent],
  imports: [
    CommonModule,
    FormsModule,
    BaseModule,
    FilterPipeModule,
    NgbModule,
    OrderModule,
    NgxBootstrapMultiselectModule
  ],
  exports:[
    CarrerasComponent,
    ConceptosIngresosComponent
  ]
})
export class InstituteModule { }
