<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Noticias
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Noticias
    </div>
    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
					  <div class="col-md-2 col-sm-12">
						<label>Fuente </label>&nbsp;
					</div>
					  <div class="col-md-3 col-sm-12">
						  <select [compareWith]="compararObjectos" class="form-control" required name="fuente" [(ngModel)]="filter.fuente">
							  <option [ngValue]="fuente" *ngFor="let fuente of filter.fuentes">{{fuente.nombre}}</option>
						  </select>
					  </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button title="Nuevo" (click)="abrirParticipantesGrupoWhatsapp()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fas fa-user-friends text-purple-d1"></i>
              </button>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="noticias.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th>
                    <div class="th-inner ">
                        Imagen
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both ">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Fecha Hora</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both ">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Categoria</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both ">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Titulo</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both ">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Sumario</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both ">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombre')">Url</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #noticias *ngFor="let noticia of noticias; let i=index;">
                <td>
                  {{(i+1)}}
                </td>
                <td>
                    <div class="imagen-noticia-vista clearfix">
                        <a href="{{noticia.imagen}}" data-rel="colorbox" title="{{noticia.titulo}}">
                            <img alt="sin imagen" src="{{noticia.imagen}}" width="50" height="50" class="d-zoom-3" data-size="1000x700" />
                        </a>
                    </div>
                </td>
                <td>
                    {{noticia.fecha_hora}}
                </td>
                <td>
                    {{noticia.categoria}}
                </td>
                <td>
                    {{noticia.titulo}}
                </td>
                <td>
                    {{noticia.sumario}}
                </td>
                <td>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="{{noticia.url}}" target="_blank">
                        {{noticia.url}}        
                    </a>
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="registrarEnWordpress(noticia)">          
                      <i class="fab fa-wordpress text-105"></i>        
					          </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="enviarNoticiaWhatsapp(noticia)">          
                        <i class="fab fa-whatsapp text-105"></i>        
                      </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="enviarmeNoticiaWhatsapp(noticia)">          
                        <i class="fab fa-whatsapp-square text-105"></i>        
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="noticias.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

	<!-- Background of PhotoSwipe. 
It's a separate element as animating opacity is faster than rgba(). -->
	<div class="pswp__bg"></div>

	<!-- Slides wrapper with overflow:hidden. -->
	<div class="pswp__scroll-wrap">

	  <!-- Container that holds slides. 
  PhotoSwipe keeps only 3 of them in the DOM to save memory.
  Don't modify these 3 pswp__item elements, data is added later on. -->
	  <div class="pswp__container">
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
		<div class="pswp__item"></div>
	  </div>

	  <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
	  <div class="pswp__ui pswp__ui--hidden">

		<div class="pswp__top-bar">

		  <!--  Controls are self-explanatory. Order can be changed. -->

		  <div class="pswp__counter"></div>

		  <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

		  <button class="pswp__button pswp__button--share" title="Share"></button>

		  <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

		  <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

		  <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
		  <!-- element will get class pswp__preloader--active when preloader is running -->
		  <div class="pswp__preloader">
			<div class="pswp__preloader__icn">
			  <div class="pswp__preloader__cut">
				<div class="pswp__preloader__donut"></div>
			  </div>
			</div>
		  </div>
		</div>

		<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
		  <div class="pswp__share-tooltip"></div>
		</div>

		<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
		</button>

		<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
		</button>

		<div class="pswp__caption">
		  <div class="pswp__caption__center"></div>
		</div>

	  </div>

	</div>

  </div>
