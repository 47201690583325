<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Cuentas por pagar
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Cuentas por pagar
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
			  <div class="row">
				<div class="col-12 col-md-12">
					<div class="row">
                        <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                        <div class="col-md-3 col-sm-12">
                            <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} </label>
                            <input class="form-control form-control-sm search-input" type="text" [(ngModel)]="filter.proveedor">
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <label>¿Cuentas Liquidadas? </label>
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="filter.cuentas_liquidadas"/>
                            </label>
                          </div>
					</div>
				</div>
			</div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="row">
                <div class="col-12">
                    <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                        <i class="d-block h-4 fa fa fa-search text-150"></i>
                    </button>
                    <div class="columns columns-right btn-group float-right">
                    <div class="export btn-group">
                        <!--<button (click)="generarPdfEstadoCuentasClientes()" class="btn btn-outline-danger btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                        <i class="fa fa-file-pdf text-green-d1"></i>
                        <span class="caret"></span>
                        </button>
                        <button (click)="generarExcelEstadoCuentasClientes()"  class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                            <i class="fa fa-file-excel text-green-d1"></i>
                            <span class="caret"></span>
                        </button>-->
                        <button (click)="verPagos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista Pagos Compras" aria-expanded="true">
                            <i class="fa fa-list-alt text-blue-d1"></i>
                            <span class="caret"></span>
                        </button>
                    </div>
                    <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                    </div>
                </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="proveedores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().codigo.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('.codigo')">
                            Código <span id="codigo" class="sort fa fa-sort icon-only"></span>
                        </a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().identificacion.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('proveedor.identificacion')">Identificación <span id="identificacion" class="sort fa fa-sort icon-only"></span></a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().razon_social.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombre' && this.direction=='asc','desc':this.column=='nombre' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('proveedor.razon_social')">Razón Social <span id="razon_social" class="sort fa fa-sort icon-only"></span></a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().nit.show" class="center"> <a href="javascript:void(0)" (click)="this.sortColumn('proveedor.nit')">N.I.T. <span id="nit" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().total.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('total_cliente')">Total <span id="total_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().a_cuenta.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('a_cuenta_cliente')">A Cuenta <span id="a_cuenta_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().saldo.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('saldo_cliente')">Saldo <span id="saldo_cliente" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().direccion.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('proveedor.direccion')">Dirección <span id="direccion" class="sort fa fa-sort icon-only"></span></a></th>
                <th *ngIf="configuracion_pagina.getConfiguration().telefono1.show" class="center"><a href="javascript:void(0)" (click)="this.sortColumn('proveedor.telefono1')">Telefono 1 <span id="telefono1" class="sort fa fa-sort icon-only"></span></a></th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #proveedor *ngFor="let proveedor of proveedores; let i=index;">
                <td>
                    {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                  </td>
                <td *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{proveedor.codigo}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().identificacion.show">
                    {{proveedor.identificacion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">
                    {{proveedor.razon_social}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{proveedor.nit}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{proveedor.total_proveedor}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().a_cuenta.show">{{proveedor.a_cuenta_proveedor}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().saldo.show">{{proveedor.saldo_proveedor}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().direccion.show">{{proveedor.direccion}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().telefono1.show">
                    {{proveedor.telefono1}}
                </td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <!--<a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfResumidoEstadoCuentasCliente(proveedor)">          
                      <i class="fa fa-file-pdf text-105"></i>        
                    </a>  
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="obtenerExcelEstadoCuentaCliente(proveedor)">          
                        <i class="fa fa-file-excel text-105"></i>        
                    </a>-->
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="abrirEstadoCuentaProveedor(proveedor)">          
                        <i class="fa fa-filter text-105"></i>        
                    </a>  
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="proveedores.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>