import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { InventariosService } from '../../services/inventarios/inventarios.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { GlobalVariable } from 'src/app/global';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { ConfiguracionAplicacionInventariosComponent } from 'src/app/base/components/configuracion-aplicacion-inventarios/configuracion-aplicacion-inventarios.component';
import { InventarioConversionComponent } from '../inventario-conversion/inventario-conversion.component';

declare const PDFDocument:any;
declare const blobStream:any;

declare const $:any;
declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  productos:any[]=[];
  producto:any;
  detalles_vista_previa:any[]=[];
  mensaje_importacion:any;
  inventario:any;
  kardex_productos:any[]=[];

  actualizacion_observacion:NgbModalRef;
  @ViewChild('actualizacionobservacion')
  private actualizacion_observacion_ref: TemplateRef<any>;

  importacion_inventarios:NgbModalRef;
  @ViewChild('importacioninventarios')
  private importacion_inventarios_ref: TemplateRef<any>;

  modificacion_cantidad:NgbModalRef;
  @ViewChild('modificacion_cantidad')
  private modificacion_cantidad_ref: TemplateRef<any>;

  modificacion_fecha_lote:NgbModalRef;
  @ViewChild('modificacion_fecha_lote')
  private modificacion_fecha_lote_ref: TemplateRef<any>;

  transferencia_inventario:NgbModalRef;

  kardex_inventario:NgbModalRef;
  @ViewChild('kardex_inventario')
  private kardex_inventario_ref: TemplateRef<any>;

  constructor(
    private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
	public inventariosService:InventariosService,
	private toastr: ToastrService,
	public productosService:ProductosService,
	public pdfService:PdfService,
	public empresasService:EmpresaService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  async ngOnInit(): Promise<void> {
    this.init();
	this.buscarAplicacion(this.router.url.substring(1));
	this.filter={
		id_empresa:this.usuario.id_empresa,
		grupo: {nombre:"TODOS",id:0},
		subgrupo:{nombre:"TODOS",id:0},
		grupos_filtro:[{nombre:"TODOS",id:0}],
		subgrupos_filtro:[{nombre:"TODOS",id:0}],
		sucursales:Util.obtenerSucursalesUsuario(this.usuario),
		sucursal: Util.obtenerSucursalesUsuario(this.usuario)[0],
		almacenes:[],
		almacen: {},
	}
	await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_INVENTARIOS);
	await this.obtenerAlmacenesSucursal(this.filter.sucursal);
	this.obtenerInventarios();
	this.obtenerGruposProductosEmpresa();
	this.obtenerSubGruposProductosEmpresa();
  }

  ngAfterViewInit() {
	//aplicarScriptPaginaProductos();	
	resaltarPestañaMenu(this.router.url.substring(1)+"1");
}
	async obtenerAlmacenesSucursal(sucursal){
		let datos_sucursal:any=await this.empresasService.obtenerSucursal(sucursal.id).toPromise();
		this.filter.almacenes=datos_sucursal.almacenes;
		if(this.usuario.empresa.usar_almacenes_usuario){
			let almacenes:any=await this.empresasService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
			this.filter.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
		}
		this.filter.almacen=this.filter.almacenes.length>0?this.filter.almacenes[0]:{};
	}

  obtenerInventarios(){
    this.column = "nombre";
    this.direction = "asc";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.inventariosService.obtenerListaInventarios(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			for (var i = 0; i < dato.productos.length; i++) {
				dato.productos[i].tipoProducto=dato.productos[i]['tipoProducto.nombre_corto'];
				let arreglo=Util.colorearInventario(dato.productos[i].cantidad, dato.productos[i]);
				dato.productos[i].porcentaje=arreglo[0];
				dato.productos[i].color=arreglo[1];
				dato.productos[i].color=dato.productos[i].activar_vencimiento==1?true:false;
				dato.productos[i].inventarios=[];
			}
			this.productos=dato.productos;
			this.blockUI.stop();
		});
	}  

  obtenerAlmacenes () {
		this.filter.almacenes = [];
		this.filter.almacenes = this.filter.sucursal.almacenes;
		if(this.filter.almacenes.length>=1){
			this.filter.almacen=this.filter.almacenes[0];
		}
		this.cambiarAlmacen();
	}
  
  obtenerGruposProductosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((tipoGrupoEmpresa:any) => {
			this.filter.grupos_filtro=this.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
		});
	}

	obtenerSubGruposProductosEmpresa(){
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((tipoSubgrupoEmpresa:any) => {
			this.filter.subgrupos_filtro=this.filter.subgrupos_filtro.concat(tipoSubgrupoEmpresa.clases);
		});
	}

	sumarCantidadAlmacen(inventarios, sucursal, almacen) {
		//inventarios = $filter('filter')(inventarios, sucursal);
		//inventarios = $filter('filter')(inventarios, almacen);
		var suma = 0;
		for (var i = 0; i < inventarios.length; i++) {
			suma = suma + inventarios[i].cantidad;
		}
		return suma;
	}

	sumarCostoTotalAlmacen (inventarios, sucursal, almacen) {
		//inventarios = $filter('filter')(inventarios, sucursal);
		//inventarios = $filter('filter')(inventarios, almacen);
		var suma = 0;
		for (var i = 0; i < inventarios.length; i++) {
			suma = suma + inventarios[i].costo_total;
		}
		return suma;
	}

	verDetalleInventario(producto) {
		this.inventariosService.obtenerInventariosProducto(producto.id,this.filter.almacen.id).subscribe((inventarios:any[]) => {
			producto.inventarios = inventarios
		});
		var style = $("#" + producto.id).css("display");
		if (style == "none") {
			$("#" + producto.id).css("display", "");
		} else {
			$("#" + producto.id).css("display", "none");
		}

	}

	abrirPopupActualizacionObservacion(producto){
		this.producto=producto;
		this.actualizacion_observacion=this.modalService.open(this.actualizacion_observacion_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.actualizacion_observacion.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	guardarInventarioObservacion(){
		this.blockUI.start();
		this.inventariosService.obtenerInventariosProducto(this.producto.id,this.filter.almacen.id).subscribe( async (inventarios:any[]) => {
			let data:any;
			for(var i=0;i<inventarios.length;i++){
				var inventario=inventarios[i];
				inventario.observacion=this.producto.observacion;
				data=await this.inventariosService.actualizarInventario(inventario).toPromise();
			}
			this.actualizacion_observacion.close();
			this.toastr.success(data.message);
			this.getItems();
			this.blockUI.stop();
		});
	}

	bajarExcelInventarios () {
		this.blockUI.start();
		this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).subscribe((productos:any[]) =>{
			var data = [];
			var cabecera=["Codigo", "Nombre o Descripción", "Unidad de medida","Grupo","Subgrupo", "Costo Unitario", "Cantidad"];

			if(this.usuario.empresa.usar_vencimientos){
				cabecera.push("Fecha de vencimiento (dia/mes/año)");
				cabecera.push("Lote");
			}
			
			for (var i = 0; i < productos.length; i++) {
				if(productos[i].activar_inventario){
					var columns = [];
					columns.push(productos[i].codigo);
					columns.push(productos[i].nombre);
					columns.push(productos[i].unidad_medida);
					columns.push(productos[i].grupo?productos[i].grupo.nombre:"");
					columns.push(productos[i].subgrupo?productos[i].subgrupo.nombre:"");
					data.push(columns);
				}
			}

          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet("INVENTARIO");

          let headerRow = worksheet.addRow(cabecera);
          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '4167B8' },
              bgColor: { argb: '' }
            }
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            }
          })

          data.forEach(d => {
            let row = worksheet.addRow(d);
          });

          const dobCol3 = worksheet.getColumn(3);
          dobCol3.width = 40;

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "EJEMPLO-DATOS-INVENTARIOS.xlsx");
			this.blockUI.stop();
		  })
		});
	}

	subirExcelInventarios(event){
		this.blockUI.start();
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
				let detalles=[],sumatoria=0;
				me.mensaje_importacion="";
				do {
					let detalle:any = {producto:{}};
					detalle.producto.codigo = worksheet['A' + row] != undefined && worksheet['A' + row] != "" ? worksheet['A' + row].v.toString() : null;
					detalle.producto.nombre = worksheet['B' + row] != undefined && worksheet['B' + row] != "" ? worksheet['B' + row].v.toString() : null;
					detalle.producto.unidad_medida = worksheet['C' + row] != undefined && worksheet['C' + row] != "" ? worksheet['C' + row].v.toString() : null;
					detalle.costo_unitario = worksheet['F' + row] != undefined && worksheet['F' + row] != "" ? parseFloat(worksheet['F' + row].v.toString()) : null;
					detalle.activar_inventario=true;
					detalle.cantidad = worksheet['G' + row] != undefined && worksheet['G' + row] != "" ? parseFloat(worksheet['G' + row].v.toString()) : null;
					if(!detalle.cantidad){
						if(detalle.cantidad!=0){
							me.mensaje_importacion=me.mensaje_importacion+" "+detalle.producto.nombre+" sin datos!,";
						}
					}
					var fecha_vencimiento = null,fecha_vencimiento_texto="";
					if (worksheet['H' + row] != undefined && worksheet['H' + row] != "") {
						if (typeof worksheet['H' + row].v === 'number') {
							if (worksheet['H' + row].v % 1 === 0) {
								fecha_vencimiento = new Date((worksheet['H' + row].v - (25567 + 1)) * 86400 * 1000);
							}
						} else {
							fecha_vencimiento = Util.convertirTextoAfecha(worksheet['H' + row].v);
							fecha_vencimiento_texto=worksheet['H' + row].v;
						}
						fecha_vencimiento_texto=worksheet['H' + row].v;
					}
					detalle.fecha_vencimiento = fecha_vencimiento;
					detalle.fecha_vencimiento_texto = fecha_vencimiento_texto;
					detalle.lote = worksheet['I' + row] != undefined && worksheet['I' + row] != "" ? worksheet['I' + row].v.toString() : null;
					detalles.push(detalle);
					sumatoria=sumatoria+(detalle.costo_unitario*detalle.cantidad);
					row++;
					i++;
				} while (worksheet['A'+row]!=undefined);
				//if(!me.usuario.empresa.usar_pedidos && productos.length>500){
				//	me.mensaje_importacion="Número de productos máximo permitido: 50";
				//}
				me.filter.total=sumatoria;
				me.detalles_vista_previa=detalles;
				me.importacion_inventarios=me.modalService.open(me.importacion_inventarios_ref, {windowClass:"importacion-inventarios",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
				me.importacion_inventarios.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
					//this.guardarInventario(detalles, id_almacen);
				me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
	    }  
	}

	guardarInventario() {
		this.blockUI.start();
		this.inventariosService.guardarListaInventarios({ detalles: this.detalles_vista_previa,id_usuario:this.usuario.id, id_empresa: this.usuario.id_empresa, id_almacen: this.filter.almacen.id , id_sucursal: this.filter.sucursal.id,total:this.filter.total }).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.getItems();
			this.importacion_inventarios.close();
			this.blockUI.stop();
		});
	}

	establecerCantidadesCero(){
		this.blockUI.start();
		this.inventariosService.reiniciarCantidadesInventario({id_almacen:this.filter.almacen.id}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.getItems();
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionReinicializacion(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de establecer las cantidades a 0 de todos los productos del almacen?";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.establecerCantidadesCero();
			}
			this.popupConfirmacion.close();
        });
	}

	generarExcelFiltroInventarios(){
		this.blockUI.start();
		var inventarios = this.productos;
		let cabecera=["Código","Nombre","Unidad de Medida","Precio Unitario","Descripción","Inventario Mínimo",
		"Grupo","Sub-Grupo","Carac. Esp. 1","Carac. Esp. 2","Codigo de fabrica","Cant.","Costo Unitario",
	   "Total Costo","Precio Unitario","Total Precio","Fecha Vencimiento","Lote","Sucursal","Almacen"];
			var data = []
			for(var i=0;i<inventarios.length;i++){
				inventarios[i].fecha_vencimiento=(inventarios[i].fecha_vencimiento?new Date(inventarios[i].fecha_vencimiento):null);
				inventarios[i].fechaVentimientoTexto=(inventarios[i].fecha_vencimiento?inventarios[i].fecha_vencimiento.getDate()+"/"+(inventarios[i].fecha_vencimiento.getMonth()+1)+"/"+inventarios[i].fecha_vencimiento.getFullYear():"");
				var columns=[];
				columns.push(inventarios[i].codigo);
				columns.push(inventarios[i].nombre);
				columns.push(inventarios[i].unidad_medida);
				columns.push(inventarios[i].precio_unitario);
				columns.push(inventarios[i].descripcion);
				columns.push(inventarios[i].inventario_minimo);
				columns.push(inventarios[i].grupo);
				columns.push(inventarios[i].subgrupo);
				columns.push(inventarios[i].caracteristica_especial1);
				columns.push(inventarios[i].caracteristica_especial2);
				columns.push(inventarios[i].codigo_fabrica);
				columns.push(inventarios[i].cantidad);
				columns.push(inventarios[i].costo_unitario);
				columns.push((inventarios[i].cantidad*inventarios[i].costo_unitario));
				columns.push(inventarios[i].precio_unitario);
				columns.push((inventarios[i].cantidad*inventarios[i].precio_unitario));
				columns.push(inventarios[i].fechaVentimientoTexto);
				columns.push(inventarios[i].lote);
				columns.push(inventarios[i].nombre_sucursal);
				columns.push(inventarios[i].nombre_almacen);
				data.push(columns);
			}

			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet("REPORTE-ALMACENES");
  
			let headerRow = worksheet.addRow(cabecera);
			headerRow.eachCell((cell, number) => {
			  cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: '4167B8' },
				bgColor: { argb: '' }
			  }
			  cell.font = {
				bold: true,
				color: { argb: 'FFFFFF' },
				size: 12
			  }
			})
  
			data.forEach(d => {
			  let row = worksheet.addRow(d);
			});
  
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 40;
  
			workbook.xlsx.writeBuffer().then((data) => {
			  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			  fs.saveAs(blob, "REPORTE-ALMACENES.xlsx");
			  this.blockUI.stop();
			})
			this.blockUI.stop();
		
	}

	async generarPdfFiltroInventarios(){
		let papel, doc, stream,productos=this.productos;
		if(!this.configuracion_pagina.getOrden().reporte_almacenes_cantidad_0){
			productos=this.productos.filter(p => p.cantidad>0);
		}
		if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_ROLLO) {
			var alto;
			if (productos.length <= 2) {
				alto = 250;
			} else {
				alto = 250 + (20 * (productos.length - 2))
			}
			papel = [227, alto];

			doc = new PDFDocument({ size: papel, margins: { top: 0, bottom: 0, left: 20, right: 20 } });
			stream = doc.pipe(blobStream());
			this.imprimirReporteAlmacenesRollo(doc,productos);

			if (doc && stream) {
				doc.end();
				return await new Promise(function (resolve, reject) {
					stream.on('finish', function () {
						var fileURL = stream.toBlobURL('application/pdf');
						var w = window.open(fileURL, '_blank', 'location=no');
						setTimeout(function () {
							w.print();
						}, 500);
						resolve(stream.toBlob());
					});
					stream.on('error', reject)
				});
			}
		}else{
			if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_OFICIO) {
				papel = [612, 936];
			} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_CARTA) {
				papel = [612, 792];
			} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
				papel = [612, 792];
			} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
				papel = [612, 936];
			}
			return this.imprimirReporteAlmacenesCartaOficio(papel,productos);
		}
	}

	imprimirReporteAlmacenesCartaOficio(papel,productos){
		var titulo="REPORTE DE ALMACENES";
		var fecha_reporte=new Date();
		var inventarios=productos;
		var doc = new PDFDocument({ size: papel, margin: 10 });
		var stream = doc.pipe(blobStream());
		// draw some text
		var totalCosto=0,totalPrecio=0;
		var itemsPorPagina = 0;
		if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_OFICIO) {
			itemsPorPagina = 35;
		} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_CARTA) {
			itemsPorPagina = 30;
		} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_MEDIOOFICIO) {
			itemsPorPagina = 18;
		} else if (this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes == GlobalVariable.Dictionary.PAPEL_MEDIACARTA) {
			itemsPorPagina = 15;
		}
		var y=150,items=0,pagina=1,totalPaginas=Math.ceil(inventarios.length/itemsPorPagina);
		this.dibujarCabeceraPDFAlmacenes(doc);
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
			(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
		(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
		(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes,
		fecha_reporte,{imprimir_usuario:true});
		doc.font('Helvetica',7);
		for(var i=0;i<inventarios.length && items<=itemsPorPagina;i++){
			doc.rect(50,y-15,540,20).stroke();
			doc.text(inventarios[i].codigo,55,y-7);
			doc.text(inventarios[i].cantidad,110,y-7);
			doc.text(inventarios[i].unidad_medida,140,y-11,{width:32});
			if(this.usuario.empresa.usar_vencimientos){
				if(inventarios[i].nombre.length>35){
					doc.text(inventarios[i].nombre,210,y-6,{width:170});
				}else{
					doc.text(inventarios[i].nombre,210,y,{width:170});
				}
				inventarios[i].fecha_vencimiento=inventarios[i].fecha_vencimiento?new Date(inventarios[i].fecha_vencimiento):null;
				let texto_fv=inventarios[i].fecha_vencimiento?inventarios[i].fecha_vencimiento.getDate()+"/"+(inventarios[i].fecha_vencimiento.getMonth()+1)+"/"+inventarios[i].fecha_vencimiento.getFullYear():'';
				doc.text(texto_fv,380,y);
				doc.text(inventarios[i].lote?inventarios[i].lote:'',430,y);
			}else{
				doc.text(inventarios[i].nombre,180,y-7);
			}	
			doc.text(inventarios[i].costo_unitario?inventarios[i].costo_unitario.toFixed(2):0,425,y-7);
			doc.text((inventarios[i].cantidad*inventarios[i].costo_unitario).toFixed(2),460,y-7);
			doc.text(inventarios[i].precio_unitario.toFixed(2),495,y-7);
			doc.text((inventarios[i].cantidad*inventarios[i].precio_unitario).toFixed(2),530,y-7);
			y=y+20;
			items++;
			totalCosto=totalCosto+(inventarios[i].cantidad*inventarios[i].costo_unitario);
			totalPrecio=totalPrecio+(inventarios[i].cantidad*inventarios[i].precio_unitario);
			if(items==itemsPorPagina){
				doc.addPage({margin:0,bufferPages: true});
				y=150;
				items=0;
				pagina=pagina+1;					
				this.dibujarCabeceraPDFAlmacenes(doc);
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,titulo,pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.filter.sucursal.nombre,this.filter.sucursal.direccion?this.filter.sucursal.direccion:"",
					(this.filter.sucursal.telefono1!=null?this.filter.sucursal.telefono1:"")+
				(this.filter.sucursal.telefono2!=null?"-"+this.filter.sucursal.telefono2:"")+
				(this.filter.sucursal.telefono3!=null?"-"+this.filter.sucursal.telefono3:""),"COCHABAMBA - BOLIVIA",this.configuracion_pagina.getOrden().impresion.papel_reporte_almacenes,
				fecha_reporte,{imprimir_usuario:true});
				doc.font('Helvetica',7);
			}
		}
		doc.rect(50,y-15,540,20).stroke();
		doc.font('Helvetica-Bold',8);
		doc.text("Total General",400,y-7);
		doc.text(Math.round(totalCosto*100)/100,460,y-7);
		doc.text(Math.round(totalPrecio*100)/100,530,y-7);
		doc.end();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}

	imprimirReporteAlmacenesRollo(doc,productos) {
		doc.moveDown(2);
		doc.font('Helvetica-Bold', 8);
		doc.text(this.usuario.empresa.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.font('Helvetica', 7);
		doc.text("SUCURSAL: "+this.filter.sucursal.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text(this.filter.sucursal.direccion.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		doc.text("ALMACEN: "+this.filter.almacen.nombre.toUpperCase(), { align: 'center' });
		doc.moveDown(0.4);
		var telefono = (this.filter.sucursal.telefono1 != null ? this.filter.sucursal.telefono1 : "") +
			(this.filter.sucursal.telefono2 != null ? "-" + this.filter.sucursal.telefono2 : "") +
			(this.filter.sucursal.telefono3 != null ? "-" + this.filter.sucursal.telefono3 : "");
		doc.text("TELF.: " + telefono, { align: 'center' });
		doc.moveDown(0.4);
		doc.text("COCHABAMBA - BOLIVIA", { align: 'center' });
		doc.moveDown(0.5);
		doc.font('Helvetica-Bold', 8);
		doc.text("REPORTE DE ALMACENES", { align: 'center' });
		doc.font('Helvetica', 7);
		doc.moveDown(0.4);
		doc.text("------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		doc.moveDown(0.2);
		doc.text("CANT.     UND             PRODUCTO", { align: 'left' });
		doc.moveDown(0.2);
		doc.text("--------------------------------------------------------------------------------", { align: 'center' });
		doc.moveDown(0.4);
		var y = doc.y, sumaDescuento = 0, sumaRecargo = 0, sumaIce = 0, sumaExcento = 0;
		for (var i = 0; i < productos.length; i++) {
			doc.text(productos[i].cantidad, 20, y);
			doc.text(productos[i].unidad_medida, 45, y,{ width: 40 });
			if (productos[i].nombre.length > 40) {
				doc.fontSize(6);
			}
			doc.text(productos[i].nombre, 95, y, { width: 100 });
			doc.fontSize(7);
			y = y + 20;
		}
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		doc.moveDown(0.4);
		
		var fechaActual = new Date();
		let min:any = fechaActual.getMinutes();
		if (min < 10) {
			min = "0" + min;
		}
		doc.x=0;
		doc.text("  Usuario : " + this.usuario.nombre_usuario + " -- Fecha : " + fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear() + "  " + fechaActual.getHours() + ":" + min + "  ", { align: 'center' });
	}

	dibujarCabeceraPDFAlmacenes(doc){
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(380,55,195,43,5).stroke();
		doc.text("SUCURSAL : "+ this.filter.sucursal.nombre,390,65);
		doc.text("ALMACEN : "+this.filter.almacen.nombre,390,75);
		
		doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();			
		doc.font('Helvetica-Bold',8);

		doc.text("Código",55,120);
		doc.text("Cant.",110,120,{width:50});
		doc.text("Unid.",140,120,{width:50});
		doc.text("Descripción",170,120,{width:170});
		if(this.usuario.empresa.usar_vencimientos){
			doc.text("Venc.",380,120);
			doc.text("Lote",430,120);
		}
		doc.text("Costo Unitario",425,115,{width:50});
		doc.text("Costo Total",460,115,{width:30});	
		doc.text("Precio Unitario",495,115,{width:50});
		doc.text("Precio Total",530,115,{width:30});			
	}

	abrirPopupActualizacionInventario(producto,inventario) {
		this.producto=producto;
		this.inventario = inventario;
		this.modificacion_cantidad=this.modalService.open(this.modificacion_cantidad_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modificacion_cantidad.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	modificarCantidadInventario () {
		this.blockUI.start();
		this.inventariosService.actualizarCantidadInventario(this.inventario).subscribe((data:any)=>{
			this.blockUI.stop();
			this.modificacion_cantidad.close();
			this.toastr.success(data.message);
			this.getItems();
		});
	}

	abrirPopupActualizacionFechaLote(producto,inventario) {
		this.producto=producto;
		this.inventario = inventario;
		this.inventario.fecha_vencimiento=new Date(this.inventario.fecha_vencimiento);
		this.inventario.fecha_vencimiento_texto = Util.convertirTextoAObjectoFecha(this.inventario.fecha_vencimiento.getDate()+"/"+(this.inventario.fecha_vencimiento.getMonth()+1)+"/"+this.inventario.fecha_vencimiento.getFullYear());
		this.modificacion_fecha_lote=this.modalService.open(this.modificacion_fecha_lote_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modificacion_fecha_lote.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	modificarFechaLoteInventario () {
		this.blockUI.start();
		if(this.producto.activar_inventario){
			this.inventario.fecha_vencimiento=Util.convertirObjetoAfecha(this.inventario.fecha_vencimiento_texto);
		}
		this.inventariosService.actualizarCantidadInventario(this.inventario).subscribe((data:any)=>{
			this.blockUI.stop();
			this.modificacion_fecha_lote.close();
			this.toastr.success(data.message);
			this.getItems();
		});
	}

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionInventariosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
		  this.configuracion_aplicacion_modal.close();
		});
	}

	transferirCantidadesProducto(producto){
		this.blockUI.start();
		this.productosService.obtenerProducto(producto.id).subscribe((res_producto:any)=>{
			this.transferencia_inventario=this.modalService.open(InventarioConversionComponent, {ariaLabelledBy: 'modal-basic-title',size:'md', backdrop: 'static',scrollable:true});
			res_producto.cantidad=producto.cantidad;
			this.transferencia_inventario.componentInstance.producto_fuente=res_producto;
			this.transferencia_inventario.componentInstance.sucursal_fuente=this.filter.sucursal;
			this.transferencia_inventario.componentInstance.almacen_fuente=this.filter.almacen;
			this.transferencia_inventario.componentInstance.usuario=this.usuario;
			this.transferencia_inventario.componentInstance.alTerminar.subscribe((res) => {
				if(res){
					if(res.tiene_error || res.hasError){
						this.toastr.error(res.message);
						this.toastr.error(res.stack.substring(0,500));
					}else{
						this.toastr.success('Conversion de inventario registrado exitosamente!');
						this.getItems();
						this.transferencia_inventario.close();
					}
				}else{
					this.transferencia_inventario.close();
				}
			});
			this.blockUI.stop();
		});
	}

	abrirModalKardexInventario(){
		let fecha_actual=new Date();
		this.filter.fecha_fin={
		  year: fecha_actual.getFullYear(), 
		  month: (fecha_actual.getMonth()+1),
		  day:fecha_actual.getDate()
		}
		this.kardex_inventario=this.modalService.open(this.kardex_inventario_ref, {windowClass:'kardex-inventarios',size:"lg",scrollable:true,ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.kardex_inventario.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	obtenerKardexInventario(){
		let fecha_fin=Util.convertirObjetoAfecha(this.filter.fecha_fin);
		this.blockUI.start();
		this.inventariosService.obtenerKardexInventario(this.filter.almacen.id,fecha_fin).subscribe((data:any)=>{
			this.blockUI.stop();
			this.kardex_productos=data;
		});
	}

	abrirPopupConfirmacionActualizacionInventario(producto){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de actualizar el inventario?";
		this.popupConfirmacion.componentInstance.data = producto;
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.actualizarInventario($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	actualizarInventario(producto){
		this.blockUI.start();
		let productos=[{id:producto.id,costo_unitario:producto.costo_unitario,cantidad:Math.round((producto.suma_ingreso-producto.suma_salida)*100)/100}];
		this.inventariosService.actualizarCantidadInventarioProducto({id_almacen:this.filter.almacen.id,productos:productos}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.getItems();
			this.blockUI.stop();
		});
	}

	abrirPopupConfirmacionActualizacionInventarios(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de actualizar todos los inventarios?";
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.actualizarInventarios();
			}
			this.popupConfirmacion.close();
        });
	  }

	actualizarInventarios(){
		this.blockUI.start();
		let productos=[];
		for(let i=0;i<this.kardex_productos.length;i++){
			productos.push({id:this.kardex_productos[i].id,costo_unitario:this.kardex_productos[i].costo_unitario,cantidad:Math.round((this.kardex_productos[i].suma_ingreso-this.kardex_productos[i].suma_salida)*100)/100});
		}
		this.inventariosService.actualizarCantidadInventarioProducto({id_almacen:this.filter.almacen.id,productos:productos}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.getItems();
			this.blockUI.stop();
		});
	}

	cambiarAlmacen(){
		this.kardex_productos=[];
	}

}
