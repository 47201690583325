import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { PostulacionesService } from 'src/app/rrhh/services/postulaciones/postulaciones.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const resaltarPestañaMenu:any;
declare const aplicarWizard:any;
declare const $:any;

@Component({
  selector: 'app-postulaciones',
  templateUrl: './postulaciones.component.html',
  styleUrls: ['./postulaciones.component.css']
})
export class PostulacionesComponent extends BaseComponent implements OnInit {

  postulaciones:any[]=[];
  wizard_registro_postulacion:string="wizard_registro_postulacion";

  postulacion:any;
  formulario:any;

  registro_postulacion:NgbModalRef;
  @ViewChild('registro_postulacion')
  private registro_postulacion_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private postulacionesService:PostulacionesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
    this.filter={
      id_empresa:this.usuario.id_empresa
    }
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_EMPLEADOS);

    this.column = "codigo";
    this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  getItems(){
    this.blockUI.start('Recuperando... espere por favor!');
    this.postulacionesService.obtenerListaPostulaciones(this).subscribe((dato:any) => {
      this.setPages(dato.paginas);
      this.postulaciones=dato.postulaciones;
      this.blockUI.stop();
    });
  }

  crearNuevoFormulario(){
    this.postulacion={
      id_empresa:this.usuario.id_empresa
    }
    this.registro_postulacion=this.modalService.open(this.registro_postulacion_ref, {windowClass:'postulacion',scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_postulacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.formulario=$(document.getElementById('formulario')).formBuilder({ i18n: { locale: 'es-ES' }});
    aplicarWizard(this.wizard_registro_postulacion,this,'guardar');
  }

  guardar(){
    this.blockUI.start();
    this.postulacion.formulario=this.formulario.actions.getData('json');
    if(this.validarPostulacion()){
      this.postulacionesService.guardarPostulacion(this.postulacion).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.getItems();
        this.registro_postulacion.close();
        this.blockUI.stop();
      });
    }else{
      this.blockUI.stop();
    }
  }

  validarPostulacion(){
    let res=true;
    if(!this.postulacion.codigo){
      res=res && false;
      this.toastr.error("Debe especificar el codigo de la postulacion!");
    }
    if(!this.postulacion.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la postulacion!");
    }
    return res;
  }

}
