import { Component,EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../services/usuario/usuario.service';

@Component({
  selector: 'app-actualizacion-clave-usuario',
  templateUrl: './actualizacion-clave-usuario.component.html',
  styleUrls: ['./actualizacion-clave-usuario.component.css']
})
export class ActualizacionClaveUsuarioComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  usuario_datos:any;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.usuario_datos.clave=null;
  }

  verificarClaveRepeticion(usuario){
		var res=true;
		if(usuario.nueva_clave!=usuario.repeticion_clave){
			res=false;
			this.toastr.error("Las repeticion de la nueva clave ingresada no coincide, vuelva a intentarlo!");
		}
		return res;
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  async guardarActualizacion(){
    if(await this.validarActualizacionClaveUsuario()){
      this.blockUI.start();
      this.usuarioService.actualizarUsuarioClave(this.usuario_datos).subscribe((res:any)=>{
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.alTerminar.emit();
        }
        this.blockUI.stop();
      });
    }
  }

  async validarActualizacionClaveUsuario(){
		let res=true;
		if(!this.usuario_datos.clave){
			this.toastr.error("Debe ingresar su clave actual!");
			res=false;
		}
    if(!this.usuario_datos.nueva_clave){
			this.toastr.error("Debe ingresar la nueva clave!");
			res=false;
		}
		if(!this.usuario_datos.repeticion_clave){
			this.toastr.error("Debe ingresar la repetición de la clave!");
			res=false;
		}
    if(this.usuario_datos.nombre_usuario.toUpperCase()==this.usuario_datos.nueva_clave.toUpperCase()){
			this.toastr.error("La clave debe ser diferente del nombre de usuario!");
			res=false;
		}
    if(this.usuario_datos.nueva_clave.length<6){
			this.toastr.error("La clave debe tener al menos una longitud de 6 caracteres alfanumericos!");
			res=false;
		}
		res = res && this.verificarClaveRepeticion(this.usuario_datos);
		return res;
  }

}
