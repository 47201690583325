import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesService {

  constructor(private http: HttpClient) { }

  public actualizarCotizacionVenta(cotizacion){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cotizacion-venta/"+cotizacion.id,cotizacion,GlobalVariable.httpOptions);
  }

  public obtenerListaCotizaciones(paginator){
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"cotizaciones/sucursales/"+paginator.filter.ids_sucursales_usuario+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarCotizacion(cotizacion){
    return this.http.post(GlobalVariable.API_SERVER_URL+"cotizacion",cotizacion,GlobalVariable.httpOptions);
  }

  public obtenerCotizacion(id_cotizacion){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cotizacion/"+id_cotizacion,GlobalVariable.httpOptions);
  }

  public actualizarCotizacion(cotizacion){
    return this.http.put(GlobalVariable.API_SERVER_URL+"cotizacion/"+cotizacion.id,cotizacion,GlobalVariable.httpOptions);
  }

  public anularCotizacion(cotizacion){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"cotizacion/"+cotizacion.id,GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionImpresion(id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-impresion-cotizacion/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public obtenerCotizacionesSucursal(sucursales){
    return this.http.get(GlobalVariable.API_SERVER_URL+"cotizaciones/sucursales/"+sucursales,GlobalVariable.httpOptions);
  }
}
