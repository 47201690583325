<div class="modal-header">
    <div class="col-8 col-xs-6">
        <h4 class="widget-title">Productos con Inventario Mínimo</h4>
    </div>
    <div class="col-4 col-xs-6">
        <button class="btn btn-danger btn-lg" type="button" (click)="imprimirProductosInventarioMinimo()">
            <i class="ace-icon fa fa-file-pdf align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body p-0">
    <div class="table-fixed-header">
        <table class="table table-striped table-bordered table-hover ">
            <thead class="bgc-primary-d1 text-white sticky-nav">
                <tr class="sticky-nav">
                    <th>Nº</th>
                    <th>¿Inventario Activado?</th>
                    <th>Codigo</th>
                    <th>Producto
                        <input name="filtro-ident" class="form-control text-center" type="text" [(ngModel)]="filtro_inventario.nombre" (keyup)="filtrarProductos(filtro_inventario.nombre)">
                    </th>
                    <th>Inventario Actual</th>
                    <th>Inventario Mínimo</th>
                    <th>Costo Unitario</th>
                    <th style="text-align: center; " data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let producto_inventario_minimo of productos_inventario_minimo_filtrados; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_inventario">
                        <label>
						    <input (change)="actualizarAtributoProducto(producto_inventario_minimo,'activar_inventario',producto_inventario_minimo.activar_inventario)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="producto_inventario_minimo.activar_inventario"/>
					    </label>
				    </td>
                    <td>{{producto_inventario_minimo.codigo}}</td>
                    <td>{{producto_inventario_minimo.nombre}}</td>
                    <td>{{producto_inventario_minimo.total_inventario?producto_inventario_minimo.total_inventario:0}}</td>
                    <td>{{producto_inventario_minimo.inventario_minimo}}</td>
                    <td>{{producto_inventario_minimo.costo_unitario}}</td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="action-buttons">        
                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(producto_inventario_minimo)">          
                            <i class="fa fa-trash-alt text-105"></i>        
                          </a>      
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>