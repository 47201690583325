<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Movimientos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Caja
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Movimientos de Caja
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="feini">Desde </label>&nbsp;
                                  <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                                  </div>
                              </div>  
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div class="input-group">
                                  <label for="fefin">Hasta </label>&nbsp;
                                  <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                          name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                                  <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                  </div>
                              </div>  
                          </div>
                          <ng-container *ngIf="usuario.empresa.usar_sucursales">
                              <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                              <div class="col-md-3 col-sm-12">
                                <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                                    <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                                </select>
                              </div>
                            </ng-container>
                            <div class="col-md-3 col-sm-12">
                                <label>Usuario </label>&nbsp;
                                <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                                <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                                </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Caja </label>&nbsp;
                                    <select [compareWith]="compararObjectos" class="form-control" required name="caja" [(ngModel)]="filter.caja">
                                    <option [ngValue]="caja" *ngFor="let caja of filter.cajas">{{caja.nombre}}</option>
                                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Tipo Movimiento </label>&nbsp;
                                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_movimiento" [(ngModel)]="filter.tipo_movimiento">
                                    <option [ngValue]="tipo_movimiento" *ngFor="let tipo_movimiento of filter.tipos_movimiento">{{tipo_movimiento.nombre}}</option>
                                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Concepto </label>&nbsp;
                                    <select [compareWith]="compararObjectos" class="form-control" required name="concepto" [(ngModel)]="filter.concepto">
                                    <option [ngValue]="concepto" *ngFor="let concepto of filter.conceptos">{{concepto.nombre}}</option>
                                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <label>Transacción </label>&nbsp;
                                    <select [compareWith]="compararObjectos" class="form-control" required name="transaccion" [(ngModel)]="filter.transaccion">
                                    <option [ngValue]="transaccion" *ngFor="let transaccion of filter.transacciones">{{transaccion.nombre}}</option>
                                    </select>
                            </div>
                            <div class="col-md-3 col-sm-12">
                              <label>Validez </label>
                              <select [compareWith]="compararObjectos" class="form-control" required name="activo" [(ngModel)]="filter.activo">
                                <option [ngValue]="activo" *ngFor="let activo of filter.activos">{{activo.nombre}}</option>
                              </select>
                            </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
                <div class="columns columns-right btn-group float-right">
                  <button (click)="crearNuevoMovimiento()" title="Nuevo"  class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-purple-d1"></i>
                  </button>
                  <button (click)="generarPdfMovimientos()" class="btn btn-outline-danger btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1" aria-label="Export"  type="button" title="Descargar pdf" aria-expanded="false">
                    <i class="fa fa-file-pdf text-danger-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <button (click)="generarExcelMovimientos()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel" aria-expanded="false">
                    <i class="fa fa-file-excel text-success-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                </div>
              </div>
            </div>
          </div>
		    </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="movimientos_caja.length>0" id="tabla-ventas" class="table table-striped table-bordered table-hover table-responsive">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_movimiento.show">Tipo Movimiento</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">Sucursal</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().caja.show">Caja</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().caja_origen.show">Caja Origen</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha.show">Fecha Doc.</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">Num. Doc.</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().saldo_anterior.show">Saldo Anterior</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().importe.show">Importe</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().saldo_posterior.show">Saldo Posterior</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().concepto.show">Concepto</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().transaccion.show">Transaccion</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().glosa.show">Glosa</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show">Estado</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let movimiento_caja of movimientos_caja; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tipo_movimiento.show && movimiento_caja.tipo_movimiento.nombre_corto=='MOVING'"><span class="badge badge-sm bgc-purple brc-purple text-white arrowed-right">{{movimiento_caja.tipo_movimiento.nombre}}</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tipo_movimiento.show && movimiento_caja.tipo_movimiento.nombre_corto=='MOVEGR'"><span class="badge btn-pink badge-sm arrowed arrowed-in-right">{{movimiento_caja.tipo_movimiento.nombre}}</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().sucursal.show && usuario.empresa.usar_sucursales">{{movimiento_caja.sucursal.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().caja.show">{{movimiento_caja.caja.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().caja_origen.show">{{movimiento_caja.movimiento_caja_origen?movimiento_caja.movimiento_caja_origen.caja.nombre:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha.show">{{movimiento_caja.fecha | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().numero_documento.show">{{movimiento_caja.numero_documento}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().saldo_anterior.show">{{movimiento_caja.saldo_anterior.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().importe.show">
                        <span *ngIf="movimiento_caja.tipo_movimiento.nombre_corto=='MOVING'" class="m-1 bgc-success-l3 border-1 brc-success-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                            {{movimiento_caja.importe.toFixed(2)}}
                        </span>
                        <span *ngIf="movimiento_caja.tipo_movimiento.nombre_corto=='MOVEGR'" class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                            {{movimiento_caja.importe.toFixed(2)}}
                        </span>
                    </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().saldo_posterior.show">{{movimiento_caja.saldo_posterior.toFixed(2)}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().concepto.show">{{movimiento_caja.concepto.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().transaccion.show">{{movimiento_caja.transaccion.nombre}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{movimiento_caja.usuario.nombre_usuario}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().glosa.show">{{movimiento_caja.glosa}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().estado.show && movimiento_caja.activo"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().estado.show && !movimiento_caja.activo"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td>
                        <div class="hidden-sm hidden-xs action-buttons">
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirMovimientoCaja(movimiento_caja)">
                                <i class="ace-icon fa fa-print bigger-130"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verMovimientoInventario(movimiento_caja)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="movimiento_caja.activo && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(movimiento_caja)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="movimientos_caja.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>