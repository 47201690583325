<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Postulaciones
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Postulaciones
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoFormulario()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
                <a href="{{global_variable.SERVER_URL+'recursos/EJEMPLO-DATOS-postulaciones.xlsx'}}"  class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				</a>
				<button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-postulaciones')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
					<i class="fa fa-upload text-red fileUpload-button"></i>
					<input id="subir-excel-postulaciones" style="display:none" type="file" class="form-control" (change)="subirExcelempleados($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
				</button>
                <!--<div class="dropdown-menu dropdown-menu-right" style="">
                  <a class="dropdown-item " href="#" data-type="json">JSON</a>
                  <a class="dropdown-item " href="#" data-type="xml">XML</a>
                  <a class="dropdown-item " href="#" data-type="csv">CSV</a>
                  <a class="dropdown-item " href="#" data-type="txt">TXT</a>
                  <a class="dropdown-item " href="#" data-type="sql">SQL</a>
                  <a class="dropdown-item " href="#" data-type="excel">MS-Excel</a>
                </div>-->
			  </div>
			  <div class="keep-open btn-group show" title="Columns">
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="postulaciones.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th>
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_paterno' && this.direction=='asc','desc':this.column=='apellido_paterno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('identificacion')">Nombre</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_materno' && this.direction=='asc','desc':this.column=='apellido_materno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('apellido_materno')">Descripcion</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().activo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='activo' && this.direction=='asc','desc':this.column=='ci' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('activo')">Activo</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #postulaciones *ngFor="let postulacion of postulaciones; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td>
                    {{postulacion.codigo}}
                </td>
                <td>
                    {{postulacion.nombre}}
                </td>
                <td >{{postulacion.descripcion}}</td>
                <td *ngIf="postulacion.activo"><span class="badge badge-sm badge-success">SI</span></td>
                <td *ngIf="!postulacion.activo"><span class="badge badge-sm badge-danger">NO</span></td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarProveedor(proveedor)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>       
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(proveedor)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="postulaciones.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #registro_postulacion let-modal>
  <div class="modal-body">
    <div class="box box-primary px-2" >
      <div class="box-body">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span class="text-blue text-125">Registro Postulacion</span>
      
                <div class="card-toolbar ml-auto no-border pr-2 d-none">
                  <label>
                    <span class="align-middle d-block d-sm-inline">Validation:</span>
                    <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
                  </label>
                </div>
                <div class="card-toolbar pl-3">
                  <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
                    <i class="fa fa-chevron-left"></i>
                  </button>
                  <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
                    <i class="fa fa-chevron-right"></i>
                  </button>
                  <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
                    <i class="fa fa-arrow-right"></i>
                  </button>
                  <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                      <i class="fas fa-times-circle"></i>
                    </button>
                </div>
              </div>
      
              <div class="card-body px-2">
                <div id="{{wizard_registro_postulacion}}" class="d-none">
                  <ul class="mx-auto">
                    <li class="wizard-progressbar"></li>
                    <li>
                      <a href="#step-1">
                        <span class="step-title">
                            1
                        </span>
      
                        <span class="step-title-done">
                            <i class="fa fa-check text-success-m1"></i>
                        </span>
                      </a>
                      <span class="step-description">
                              Datos de la Postulación
                      </span>
                    </li>
                    <li>
                      <a href="#step-2">
                        <span class="step-title">
                            2
                        </span>
      
                        <span class="step-title-done">
                            <i class="fa fa-check text-success-m1"></i>
                        </span>
                      </a>
                      <span class="step-description">
                              Datos del Formulario
                      </span>
                    </li>
                  </ul>
      
                  <div class="px-2 py-2 mb-4">
                    <div id="step-1" class="">
                      <div class="  form-row">
                          <div class="col-sm-2 col-form-label text-sm-right text-grey">
                              Código
                          </div>
                          <div class="col-sm-2">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input required type="text" name="codigo" id="codigo" [(ngModel)]="postulacion.codigo" placeholder="Código" class="form-control form-control col-12 text-dark-m2 brc-warning pr-45 pl-2"  />
                                  <i class="fas fa-exclamation-triangle text-orange text-125 ml-n425 pos-rel"></i>
                              </div>
      
                              <span class="form-text d-inline-block text-orange-d2 text-600 text-95 ml-sm-2"> Código! </span>
                          </div>
                          <div [ngClass]="{'text-danger-m1':(!postulacion.nombre)}" class="col-sm-2 col-form-label text-sm-right">
                              Nombre
                          </div>
      
                          <div class="col-sm-6">
                              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                  <input required type="text" id="nombre" name="nombre" [(ngModel)]="postulacion.nombre" placeholder="Nombre" [ngClass]="{'brc-danger-m1':(!postulacion.nombre)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                  <i *ngIf="!postulacion.nombre" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                              </div>
      
      
                              <div *ngIf="!postulacion.nombre" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                  <div class="arrow brc-danger-m1 mt-0"></div>
                                  <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                              </div>
      
                              <span *ngIf="!postulacion.nombre" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                          </div>
                      </div>
                      <div class="  form-row">
                          <div class="col-sm-3 col-form-label text-sm-right">
                              Descripción
                          </div>
      
                          <div class="col-sm-5">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input type="text" id="descripcion" name="descripcion" [(ngModel)]="postulacion.descripcion" placeholder="Descripción" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              </div>
                          </div>
                      </div>
                    </div>
      
                    <div id="step-2">
                      <div id="formulario"></div>
                    </div>
                  </div>
                </div>
      
              </div>
            </div> <!-- .card -->
      
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>