<div class="page-content container container-plus">
    <div class="page-header border-0 mb-3">
      <h1 class="page-title text-primary-d2 text-150">
        Calendario
        <small class="page-info text-secondary-d4">
          <i class="fa fa-angle-double-right text-80 opacity-1"></i>
          Eventos
        </small>
      </h1>
    </div>


    <!-- message to be displayed on touch devices -->
    <div id="alert-1" class="d-none alert bgc-white border-l-4 brc-purple-m1 shadow-sm" role="alert">
      Touch a date slot and hold down to add a new event
    </div>

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <div class="row" *ngIf="usuario.empresa.usar_calendario_cursos">
        <div class="col-2"><label>Curso - Materia </label></div>
        <div class="col-6">
            <select [compareWith]="compararObjectos" class="form-control" required name="registro_profesor" [(ngModel)]="filter.registro_profesor">
                <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
            </select>
        </div>
        <div class="col-2">
            <button (click)="obtenerEventos()" type="button" class="btn btn-sm btn-lighter-info btn-a-outline-info dropdown-toggle border-b-2">
                <i class="fa fa-search text-180"></i>
            </button>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12 col-md-9" id='calendar-container'>
        <div class="card acard">
          <div class="card-body p-lg-4">
            <div id='calendar' class="text-blue-d1"></div>
          </div>
        </div>
      </div>


      <div class="col-12 col-md-3 mt-3 mt-md-4" id='external-events'>
        <div class="bgc-secondary-l4 border-1 brc-secondary-l2 shadow-sm p-35 radius-1">
          <p class="text-120 text-primary-d2">
            Eventos Frecuentes
          </p>

          <p id="alert-2" class="alert bgc-white border-none border-l-4 brc-purple-m1">
            Selecciona una de las categorias de eventos para el calendario.
          </p>

          <div><!--//todas las cuentas //solo usuario-->
            <div class='fc-event badge bgc-blue-d1 text-white border-0 py-2 text-90 mb-1 radius-2px' data-class="bgc-blue-d1 text-white text-95">
              Recordatorio personal  
            </div><!--// Solo Colegio Institucional-->
            <div class='fc-event badge bgc-green-d2 text-white border-0 py-2 text-90 mb-1 radius-2px' data-class="bgc-green-d2 text-white text-95">
              Recordatorio grupal  
            </div><!--// Colegio Institucional y profesor-->
            <div class='fc-event badge bgc-red-d1 text-white border-0 py-2 text-90 mb-1 radius-2px' data-class="bgc-red-d1 text-white text-95">
              Examen 
            </div><!--// Colegio Institucional y profesor-->
            <div class='fc-event badge bgc-purple-d1 text-white border-0 py-2 text-90 mb-1 radius-2px' data-class="bgc-purple-d1 text-white text-95">
              Reunion 
            </div>
            <div class='fc-event badge bgc-orange-d1 text-white border-0 py-2 text-90 mb-1 radius-2px' data-class="bgc-orange-d1 text-white text-95">
              Cumpleaño
            </div>
          </div>

          <!--<label class="mt-2">
            <input type="checkbox" id='drop-remove' class="mr-1" />
            Remove after drop
          </label>-->
        </div>

      </div>
    </div>
  </div>


  <ng-template #registroevento let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Evento</span>
      </div>
      <div class="col-md-6 col-xs-6">
        <button (click)="guardarEvento()" class="btn btn-primary">
          <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button *ngIf="evento.id" class="btn btn-danger" type="button" (click)="abrirPopupConfirmacionEliminacion(evento)">
          <i class="fa fa-trash-alt red2 text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
          <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
      </div>
    </div>
    <div class="modal-body card-body">
      <div class="row">
        <div class="col-12">
          <label for="form-field-select-1">Fecha : {{evento.fecha | date:'dd/MM/yyyy'}}</label>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-7">
          <label for="form-field-select-1">Título</label>
          <input type="text" class="form-control" [(ngModel)]="evento.titulo" placeholder="Título"/>
        </div>
        <div class="col-5">
          <label for="form-field-select-11">Tipo</label>
          <select [compareWith]="compararObjectos" class="form-control" name="tipo" [(ngModel)]="evento.tipo">
            <option [ngValue]="tipo" *ngFor="let tipo of filter.tipos_eventos" >{{tipo.nombre}}</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="filter.registros_profesor.length>0 && usuario.empresa.usar_calendario_cursos">
        <hr>
        <div class="row">
          <div class="col-12">
            <label for="form-field-select-11">Curso - Materia</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="tipo" [(ngModel)]="evento.registro_profesor">
              <option [ngValue]="registro_profesor" *ngFor="let registro_profesor of filter.registros_profesor">{{registro_profesor.curso.nombre}} - {{registro_profesor.materia.nombre}}</option>
            </select>
          </div>
        </div>
      </ng-container>
      <hr>
      <div class="row">
        <div class="col-6">
          <label for="form-field-select-11">Hora de inicio</label>
          <ngb-timepicker [(ngModel)]="evento.inicio_texto"></ngb-timepicker>
        </div>
        <div class="col-6">
          <label for="form-field-select-11">Hora de finalización</label>
          <ngb-timepicker [(ngModel)]="evento.fin_texto"></ngb-timepicker>
        </div>
      </div>
    </div>
  </ng-template>