<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Libro
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Compras
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Libro de</span>
      Compras
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-8 col-md-8">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                            <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-1 col-sm-12"><label>Gestión </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="gestion" [(ngModel)]="filter.gestion">
                            <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-md-1 col-sm-12"><label>Mes </label></div>
                    <div class="col-md-3 col-sm-12">
                        <select [compareWith]="compararObjectos" class="form-control" required name="mes" [(ngModel)]="filter.mes">
                            <option [ngValue]="mes" *ngFor="let mes of filter.meses">{{mes.nombre}}</option>
                        </select>
                    </div>
                  
                </div>
              </div>
            </div>
		  </div>
          <div class="col-4 col-md-4">
            <fieldset>
              <legend>Version 1</legend>
              <div class="row">
                <div class="col-12">
                  <button [disabled]="!filter.gestion || !filter.mes" (click)="generarPdfLibroCompras()" class="btn btn-xs btn-app btn-danger my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="d-block h-4 fa fa fa-file-pdf text-150"></i>
                  </button>
                  <button [disabled]="!filter.gestion || !filter.mes" (click)="generarExcelLibroCompras()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                  </button>
                  <button [disabled]="!filter.gestion || !filter.mes" (click)="generarTXTLibroCompras()" class="btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-file-alt text-150"></i>
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset *ngIf="usuario.empresa.usar_libro_ventas_v2">
              <legend>Version 2</legend>
              <div class="row">
                <div class="col-12">
                  <button [disabled]="!filter.gestion || !filter.mes" (click)="generarExcelLibroCompras2()" class="btn btn-xs btn-app btn-success my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                      <i class="d-block h-4 fa fa fa-file-excel text-150"></i>
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
</div>