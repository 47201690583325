import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { CalificacionesService } from '../../services/calificaciones/calificaciones.service';
import { CursosService } from '../../services/cursos/cursos.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { OrderPipe } from 'ngx-order-pipe';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-centralizador-calificaciones',
  templateUrl: './centralizador-calificaciones.component.html',
  styleUrls: ['./centralizador-calificaciones.component.css']
})
export class CentralizadorCalificacionesComponent extends BaseComponent implements OnInit {

  estudiantes:any[]=[];
  subjects:any[]=[];

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    private cursosService:CursosService,
    private calificacionesService:CalificacionesService,
    private orderPipe: OrderPipe) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
    this.filter={
			escuela:null,
      niveles:[],
      gestion:null,
      gestiones:[],
      curso:null,
      cursos:[],
      intervalo_escolar:null,
      intervalos_escolares:[],
      texto_parametro:'MATERIAS',
      parametro:"materia"
		}
    await this.obtenerEscuela();  
    this.obtenerGestiones();
    this.obtenerNivelesEscolares();
    this.obtenerIntervalosEscolares();
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerGestiones(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.filter.gestiones=entidad.clases
    });
  }

  async obtenerNivelesEscolares(){
    this.filter.niveles=[GlobalVariable.Dictionary.SELECCION_TODOS];
    let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
    this.filter.niveles=this.filter.niveles.concat(niveles_escolares.clases);
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    this.filter.cursos=[];
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=this.filter.cursos.concat(cursos);
    this.filter.curso=null;
  }

  async obtenerIntervalosEscolares(){
    this.blockUI.start();
    let tipo_intervalo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_INTERVALOS_ESCOLAR).toPromise();
    this.filter.intervalos_escolares=tipo_intervalo?tipo_intervalo.clases:[];
    this.blockUI.stop();
  }

  obtenerCantidadTotalCasillas(dimensiones){
    dimensiones=this.orderPipe.transform(dimensiones, 'orden',false);
    let cantidad_total_casillas:number=1;
    for(let i=0;i<dimensiones.length;i++){
      cantidad_total_casillas=cantidad_total_casillas+parseInt(dimensiones[i].numero_casillas.toString())+2;
    }
    return cantidad_total_casillas;
  }

  generarCentralizador(){
    this.blockUI.start();
    this.calificacionesService.obtenerDatosCentralizador(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,this.filter.intervalo_escolar.id).subscribe((estudiantes:any[]) => {
      this.calificacionesService.obtenerMateriasRegistroProfesor(this.filter.gestion.id,this.filter.curso.id).subscribe((subjects:any[]) => {
        this.subjects=subjects;
        for(var i=0;i<estudiantes.length;i++){
          var scores=[],scores_student=[];
          for(var j=0;j<estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
            //let dimensiones=JSON.parse(estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia.registros_profesor[0].configuracion).dimensiones;
            //let cantidad_total_casillas=this.obtenerCantidadTotalCasillas(dimensiones);
            scores=estudiantes[i].inscripciones_colegio[0].calificaciones[j].registro;
            let calificacion_final_materia;
            if(this.filter.nivel.nombre_corto==GlobalVariable.Dictionary.SCH.CLASE_NIVEL_INICIAL){
              calificacion_final_materia = scores[0];
            }else{
              calificacion_final_materia = scores.reduce((prev, current) => (prev.orden > current.orden) ? prev : current);
            }
            //scores=scores.filter(e => e.orden == cantidad_total_casillas);
            scores_student.push(calificacion_final_materia);
          }
          estudiantes[i].inscripciones_colegio[0].calificaciones=scores_student;
          for(var j=0;j<estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
            estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.subjects.filter(e => e.id==parseInt(estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia))[0];
          }
        }
        if(this.filter.parametro=='materia'){
          this.estudiantes=estudiantes;
          this.filter.texto_parametro="MATERIAS"
        }else{
          this.estudiantes=this.generateAreaData(estudiantes);
          this.filter.texto_parametro="AREAS"
        }
        this.blockUI.stop();
      });
    });
  }
  
  generateAreaData(estudiantes){
		for(var i=0;i<estudiantes.length;i++){
			var areas=[];
			Util.ordernarMaterias(estudiantes[i].inscripciones_colegio[0].calificaciones);
			for(var j=0;j<estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
				estudiantes[i].inscripciones_colegio[0].calificaciones=this.searchAndDropArea(j,estudiantes[i].inscripciones_colegio[0].calificaciones);
			}
		}
		return estudiantes;
  }

  searchAndDropArea(i,scores){
		var foundAreas=[];
		for(let k=i+1;k<scores.length;k++){
			if(i!=k && scores[i].materia.area && scores[k].materia.area && scores[i].materia.area.id==scores[k].materia.area.id){
				foundAreas.push(scores[k]);
			}
		}

		if(foundAreas.length>0){
			var sum=0;
			for(var k=0;k<foundAreas.length;k++){
				sum=sum+foundAreas[k].valor;
			}
			scores[i].valor=Math.round((scores[i].valor+sum)/(1+foundAreas.length));
			scores[i].materia=scores[i].materia.area;
			scores[i].materia.orden=scores[i].materia.orden;
			for(var k=0;k<foundAreas.length;k++){
				scores.splice(scores.indexOf(foundAreas[k]), 1);
			}
		}else{
			if(scores[i].materia.area){
				scores[i].materia=scores[i].materia.area;
				scores[i].materia.orden=scores[i].materia.orden;
			}
		}
		return scores;
	}

  generarExcelCentralizador(){
		var documentName='CENTRALIZADOR POR '+this.filter.texto_parametro+'-'+this.filter.gestion.name+'-'+this.filter.escuela.nombre+'-'+this.filter.curso.nombre+'-'+this.filter.intervalo_escolar.nombre+'.xlsx';
		var data = [["","CENTRALIZADOR DE NOTAS POR "+this.filter.texto_parametro],
					["",""],
					["","GESTIÓN:"+this.filter.gestion.name],
					["","UNIDAD EDUCATIVA:"+this.filter.escuela.nombre],
					["","CURSO:"+this.filter.curso.nombre],
					["","TRIMESTRE:"+this.filter.intervalo_escolar.nombre],
					["",""]];
		var headerColumns=[];
		headerColumns.push("Nº");			
		headerColumns.push("APELLIDOS Y NOMBRES");	
		headerColumns.push("CODIGO RUDE");	
		Util.ordernarMaterias(this.estudiantes[0].inscripciones_colegio[0].calificaciones);			
		for(var j=0;j<this.estudiantes[0].inscripciones_colegio[0].calificaciones.length;j++){
			headerColumns.push(this.estudiantes[0].inscripciones_colegio[0].calificaciones[j].materia.nombre_corto);
		}
		data.push(headerColumns);
		
		for(var j=0;j<this.estudiantes.length;j++){
			Util.ordernarMaterias(this.estudiantes[j].inscripciones_colegio[0].calificaciones);
			var columns=[];
			columns.push(j+1);
			columns.push(this.estudiantes[j].persona.nombre_completo.toUpperCase());
			columns.push(this.estudiantes[j].codigo_rude);
			for(var k=0;k<this.estudiantes[j].inscripciones_colegio[0].calificaciones.length;k++){
        if(this.filter.nivel.nombre_corto==GlobalVariable.Dictionary.SCH.CLASE_NIVEL_INICIAL){
          columns.push(this.estudiantes[j].inscripciones_colegio[0].calificaciones[k].descripcion);
        }else{
          columns.push(this.estudiantes[j].inscripciones_colegio[0].calificaciones[k].valor);
        }
			}
			data.push(columns);
    }
    

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("CENTRALIZADOR");
    data.forEach(d => {
      let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, documentName);
    })
		this.blockUI.stop();
  }
  
  generarPdfCentralizador(){
		var results=this.estudiantes;
		//PDF CENTRALIZADOR
		var documentName='CENTRALIZADOR POR '+this.filter.texto_parametro+'-'+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.curso.nombre+'-'+this.filter.intervalo_escolar.nombre+'.pdf';
		var docCentralization=new PDFDocument({margin:0,bufferPages: true,layout: 'landscape'});
		var stream = docCentralization.pipe(blobStream());
		docCentralization.fontSize(8);	
		
		var itemsPerPage=35,items=0,page=1;
		var x=250;
    var y=110,distancia=12,ancho=35;
    if(this.filter.nivel.nombre_corto==GlobalVariable.Dictionary.SCH.CLASE_NIVEL_INICIAL){
      distancia=40;
      itemsPerPage=11;
      ancho=450;
    }
		this.dibujarCabeceraPdf(results[0].inscripciones_colegio[0].calificaciones,docCentralization,page,ancho);
		for(var i=0;i<results.length;i++){
			docCentralization.text((i+1),50,(10+y));
			docCentralization.text(results[i].persona.nombre_completo.toUpperCase(),65,(10+y));
			docCentralization.rect(45,(7+y),220,distancia).stroke();
			x=265;
			Util.ordernarMaterias(results[i].inscripciones_colegio[0].calificaciones);
			for(var j=0;j<results[i].inscripciones_colegio[0].calificaciones.length;j++){
        if(this.filter.nivel.nombre_corto==GlobalVariable.Dictionary.SCH.CLASE_NIVEL_INICIAL){
          docCentralization.text(results[i].inscripciones_colegio[0].calificaciones[j].descripcion,(x+8),(10+y),{width:ancho});
        }else{
          if(results[i].inscripciones_colegio[0].calificaciones[j].valor<51){
            docCentralization.fillColor('red');
          }else{
            docCentralization.fillColor('black');
          }
          docCentralization.text(results[i].inscripciones_colegio[0].calificaciones[j].valor?results[i].inscripciones_colegio[0].calificaciones[j].valor:"",(x+12),(10+y));
        }
				docCentralization.rect(x,(7+y),ancho,distancia).stroke();
				x=x+35;
				docCentralization.fillColor('black');
			}
			y=y+distancia;
			items++;
			
			if(items==itemsPerPage){
				docCentralization.addPage({margin:0,bufferPages: true,layout: 'landscape'});
				y=110;
				items=0;
				page=page+1;
				this.dibujarCabeceraPdf(results[0].inscripciones_colegio[0].calificaciones,docCentralization,page,ancho);			
			}
			
		}
		
		docCentralization.end();
		this.blockUI.stop();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			Util.descargarDocumento(documentName,fileURL);
		});
  }
  
  dibujarCabeceraPdf(headerArray,docCentralization,page,ancho){
		docCentralization.image(this.usuario.empresa.imagen,700, 30, {width:50, height:50});			
		docCentralization.text('CENTRALIZADOR DE NOTAS POR '+this.filter.texto_parametro,50,50,{width:100});
		docCentralization.text("GESTIÓN : "+this.filter.gestion.nombre,200,50);
		docCentralization.text("UNIDAD EDUCATIVA : "+this.filter.escuela.nombre,400,50);
		docCentralization.text("CURSO : "+this.filter.curso.nombre,200,70);
		docCentralization.text("TRIMESTRE : "+this.filter.intervalo_escolar.nombre,400,70);
		
		docCentralization.text('Nº    APELLIDOS Y NOMBRES',50,108);
		docCentralization.rect(45,105,220,12).stroke();
		Util.ordernarMaterias(headerArray);
		var x=260;
		for(var j=0;j<headerArray.length;j++){
			docCentralization.text(headerArray[j].materia.nombre_corto,(x+15),108);
			docCentralization.rect((x+5),105,ancho,12).stroke();
			x=x+35;
		}
		docCentralization.text('PÁGINA '+page,700,580);
	}
  
  /*sortSubjects(array){
		array.sort(function(a, b){
			var sortStatus = 0;
			if (a.subject.subject_order < b.subject.subject_order) {
				sortStatus = -1;
			} else if (a.subject.subject_order > b.subject.subject_order) {
					sortStatus = 1;
			}
			return sortStatus;
		});
  }

  sortSubjectsExcel(array){
		array.sort(function(a, b){
			var sortStatus = 0;
			if (a.subject_order < b.subject_order) {
				sortStatus = -1;
			} else if (a.subject_order > b.subject_order) {
					sortStatus = 1;
			}
			return sortStatus;
		});
  }
  
  descargarFormatoExcelCentralizador(){
    this.estudianteservice.obtenerDatosCurso(this.filter.management.id,this.filter.school.id,this.filter.grade.id).subscribe((datos:any) => {
      this.estudianteservice.obtenerDatosMateriasEstudiantes(this.filter.management.id,this.filter.school.id,this.filter.grade.id).subscribe((subjects:any[]) => {
          this.sortSubjectsExcel(subjects);
          let students=datos.students;

          let data=[];
          let cabecera1=[];
          let cabecera=[];
          cabecera1.push("");
          cabecera1.push("");
          cabecera1.push("");
          cabecera.push("Nro");
          cabecera.push("Código");
          cabecera.push("Apellidos y Nombres");

          for (let i = 0; i < subjects.length; i++) {
            cabecera1.push(subjects[i].id);
            cabecera.push(subjects[i].name);
          }
          
          for (let i = 0; i < students.length; i++) {
            let columns = [];
            columns.push((i+1)+"");
            columns.push(students[i].inscriptions[0].id);
            columns.push(students[i].persona.nombre_completo);
            data.push(columns);
          }

          const title = "EJEMPLO-DATOS-PRODUCTOS";
          const header = cabecera
          const header1 = cabecera1
          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet("CENTRALIZADOR");
          let headerRow1 = worksheet.addRow(header1);
          headerRow1.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '4167B8' },
              bgColor: { argb: '' }
            }
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            }
          })

          let headerRow = worksheet.addRow(header);
          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '4167B8' },
              bgColor: { argb: '' }
            }
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            }
          })

          data.forEach(d => {
            let row = worksheet.addRow(d);

            /*let sales = row.getCell(6);
            let color = 'FF99FF99';
            
          }
          );

          const dobCol3 = worksheet.getColumn(3);
          dobCol3.width = 40;

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, "CENTRALIZADOR "+this.filter.curso.nombre + '.xlsx');
          })
      });
    });
  }

  subirExcelCentralizador(event){
		this.blockUI.start("Subiendo calificaciones, espere por favor...");
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=3,i=0,j=3;
				let worksheet = workbook.Sheets[first_sheet_name];
        let students=[],subjects=[];
        var letters=["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG","AH","AI","AJ","AK","AL","AM","AN","AO","AP","AQ","AR","AS","AT"];
        do{
          let subject={
            id:worksheet[letters[j]+"1"]!=undefined && worksheet[letters[j]+"1"]!=""?worksheet[letters[j]+"1"].v.toString():null,
            nombre_corto:worksheet[letters[j]+"2"]!=undefined && worksheet[letters[j]+"2"]!=""?worksheet[letters[j]+"2"].v.toString():null,
          }
          subjects.push(subject);
          j++;
        }while(worksheet[letters[j]+"2"]!=undefined);
        
				do {
					let student:any={
            id:worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null,
            name:worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null,
            subjects:[]
          }
          
          j=3;
          for(let k=0;k<subjects.length;k++){
            if(worksheet[letters[j]+row]!=undefined && worksheet[letters[j]+row]!=""){
              student.subjects.push({
                id:subjects[k].id,
                nombre_corto:subjects[k].nombre_corto,
                valor:parseInt(worksheet[letters[j]+row].v.toString())
              });
            }
            j++;
          }

          students.push(student);
					
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);
        console.log(students);
        me.studentService.guardarDatosCentralizador({
          management_id:me.filter.management.id,
          school_id:me.filter.school.id,
          bimester_id:me.filter.bimester.id,
          students:students
        }).subscribe((dato:any) => {
          me.blockUI.stop();
          me.toastr.success(dato.mensaje);
          me.generateReport();
        });
			};
			reader.readAsBinaryString(f);
	    }  
	}

  
  clickBotonSubir(id){
    $("#"+id).trigger('click');
  }

  */

}
