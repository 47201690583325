import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EstudiantesService } from 'src/app/school/services/estudiantes/estudiantes.service';

@Component({
  selector: 'app-asuntos-administrativos',
  templateUrl: './asuntos-administrativos.component.html',
  styleUrls: ['./asuntos-administrativos.component.css']
})
export class AsuntosAdministrativosComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  asuntos_administrativos:any[]=[];
  estados_cambio:any[]=[];
  asunto_administrativo:any;

  modal_cambio_estado:NgbModalRef;
  @ViewChild('modal_cambio_estado')
  private modal_cambio_estado_ref: TemplateRef<any>;

  constructor(
    public persistenciaService:PersistenciaService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    public estudiantesService:EstudiantesService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

   async ngOnInit(): Promise<void> {
    this.filter={
      estados:[{id:0,nombre:"TODOS"}]
    }
    await this.obtenerAsuntosAdministrativos();
    this.obtenerEstadosAsuntosAdministrativos();
  }

  async obtenerAsuntosAdministrativos(){
    this.blockUI.start();
    let pagos_estudiantes:any=await this.estudiantesService.obtenerAsuntosAdmnistrativosPendientes(this.usuario.id_empresa).toPromise();
    this.asuntos_administrativos = pagos_estudiantes;
    this.blockUI.stop();
  }

  async obtenerEstadosAsuntosAdministrativos(){
    let tipo_estado:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPO_ESTADO_ASUNTO_ADMINISTRATIVO).toPromise();
    this.estados_cambio=tipo_estado.clases;
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

   abrirPopupConfirmacionCambioEstadoAsuntoAdministrativo(asunto_administrativo){
     this.asunto_administrativo=asunto_administrativo;
    this.modal_cambio_estado=this.modalService.open(this.modal_cambio_estado_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modal_cambio_estado.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
	}
  
  cambiarEstado(){
    this.blockUI.start();
    this.estudiantesService.cambiarEstadoAsuntoAdmnistrativo(this.asunto_administrativo).subscribe((resP:any)=>{
      this.blockUI.stop();
        this.toastr.success(resP.mensaje);
        this.modal_cambio_estado.close();
        this.obtenerAsuntosAdministrativos();
      });
  }

}
