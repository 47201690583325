import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { CajasService } from '../../services/cajas/cajas.service';

@Component({
  selector: 'app-registro-movimiento-caja',
  templateUrl: './registro-movimiento-caja.component.html',
  styleUrls: ['./registro-movimiento-caja.component.css']
})
export class RegistroMovimientoCajaComponent extends BaseComponent implements OnInit {

  movimiento_caja:any={

  };
  tipos_movimiento:any[]=[];
  cajas:any[]=[];
  conceptos:any[]=[];
  todas_transacciones:any[]=[];
  transacciones:any[]=[];
  cheques:any[]=[];
  sucursales:any[];
  sucursalesUsuario:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  inhabilitar_sucursal:boolean=false;
  inhabilitar_concepto:boolean=false;
  inhabilitar_tipo_movimiento:boolean=false;
  inhabilitar_fecha:boolean=false;
  inhabilitar_importe:boolean=false;
  inhabilitar_glosa:boolean=false;

  nombre_corto_tipo_movimiento:string=GlobalVariable.Dictionary.MOV_ING;
  nombre_corto_concepto:string=GlobalVariable.Dictionary.CONCEPTO_MOVIMIENTO_CAJA_OTROS_INGRESOS;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
	public usuarioService:UsuarioService,
  public cajasService:CajasService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
		this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
		this.sucursalesUsuario=Util.obtenerIdsSucursales(this.usuario);
    
    this.movimiento_caja.id_usuario=this.usuario.id;
    this.movimiento_caja.sucursal=this.movimiento_caja.sucursal?this.movimiento_caja.sucursal:this.sucursales[0];
    this.movimiento_caja.id_empresa=this.usuario.id_empresa;
    //this.movimiento_inventario.sucursal=this.sucursales.length==1?this.sucursales[0]:null;
    var fechaActual=new Date();
    this.movimiento_caja.fecha_texto={
      year: fechaActual.getFullYear(), 
      month: (fechaActual.getMonth()+1),
      day:fechaActual.getDate()
    }	

	await this.obtenerTransacciones();
	this.movimiento_caja.transaccion=this.todas_transacciones[0];
    this.obtenerTiposMovimientos();
    await this.obtenerCajasEmpresa();
	this.establecerCaja(this.cajas.length>0?this.cajas[0]:null);
    this.obtenerConceptosMovimiento();
  }

  establecerCaja(caja){
	this.movimiento_caja.caja=caja;
	  if(caja && caja.tipo_caja.nombre_corto==GlobalVariable.Dictionary.TIPO_CAJA_CUENTA_BANCARIA){
		this.transacciones=this.todas_transacciones.filter(t => t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT || t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CODQR || t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE || t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_TARJ || t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_TRANS);
	  }else{
		this.transacciones=this.todas_transacciones.filter(t => t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CONT || t.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE);
	  }
	  this.establecerTransaccion(this.transacciones[0]);
  }

  establecerTransaccion(transaccion){
	this.movimiento_caja.transaccion=transaccion;
	if(transaccion.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE){
		this.obtenerCheques(this.movimiento_caja.caja.id);
	}
  }

  obtenerCheques(id_caja){
	  this.blockUI.start();
	  this.cajasService.obtenerListaChequesCaja(id_caja).subscribe((cheques:any)=>{
		this.cheques=cheques;
		this.movimiento_caja.cheque=null;
		this.blockUI.stop();
	  });
  }

  async obtenerTiposMovimientos(){
    this.blockUI.start();
    this.tipos_movimiento=[];
    await this.tipos_movimiento.push(await this.generalService.obtenerClases("MOVING").toPromise());
    await this.tipos_movimiento.push(await this.generalService.obtenerClases("MOVEGR").toPromise());
    this.movimiento_caja.tipo_movimiento=this.tipos_movimiento.filter(t => t.nombre_corto==this.nombre_corto_tipo_movimiento)[0];
    this.blockUI.stop();
  }

  async obtenerCajasEmpresa(){
    this.blockUI.start();
    let cajas:any=await this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).toPromise();
    this.cajas=cajas;
    this.blockUI.stop();
  }

  obtenerConceptosMovimiento(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_MOVIMIENTOS_CAJA).subscribe((conceptos_movimiento:any)=>{
      this.conceptos=conceptos_movimiento.clases;
      this.movimiento_caja.concepto=this.conceptos.length>0?this.conceptos.filter(c => c.nombre_corto==this.nombre_corto_concepto)[0]:null;
      this.blockUI.stop();
    });
  }

  async obtenerTransacciones(){
    this.blockUI.start();
    let transacciones:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_PAGOS).toPromise();
	this.todas_transacciones=transacciones.clases;
	this.blockUI.stop();
  }

  cerrarComponente(){
		this.alTerminar.emit();
	}

  validarRegistroMovimientoCaja(){
    let res=true;
    if(!this.movimiento_caja.caja){
      res=res && false;
      this.toastr.error("Debe especificar una caja!");
    }
    if(!this.movimiento_caja.fecha_texto || !this.movimiento_caja.fecha_texto.year || !this.movimiento_caja.fecha_texto.month || !this.movimiento_caja.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el movimiento!");
    }
    if(!this.movimiento_caja.concepto){
      res=res && false;
      this.toastr.error("Debe especificar un concepto!");
    }
    if(this.movimiento_caja.importe==null || this.movimiento_caja.importe<=0){
      res=res && false;
      this.toastr.error("Debe especificar el importe!");
    }
    if(!this.movimiento_caja.glosa){
      res=res && false;
      this.toastr.error("Debe especificar la glosa!");
    }
	if(this.movimiento_caja.concepto.nombre_corto=="TRANSCJ"){
		if(!this.movimiento_caja.caja_destino){
			res=res && false;
			this.toastr.error("Debe especificar la caja destino!");
		}
	}
	if(this.movimiento_caja.tipo_movimiento.nombre_corto==GlobalVariable.Dictionary.MOV_EGR && this.movimiento_caja.transaccion.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CHEQUE){
		if(!this.movimiento_caja.cheque){
			res=res && false;
			this.toastr.error("Debe especificar el cheque!");
		}
		if(!this.movimiento_caja.numero_cheque){
			res=res && false;
			this.toastr.error("Debe especificar el numero del cheque!");
		}
	}
    return res;
  }

  guardarMovimientoCaja(){
    if(this.validarRegistroMovimientoCaja()){
      this.blockUI.start();
      this.movimiento_caja.fecha=Util.convertirObjetoAfecha(this.movimiento_caja.fecha_texto);
      this.cajasService.guardarMovimientoCaja(this.movimiento_caja).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.alTerminar.emit(res);
        this.blockUI.stop();
      }); 
    }
  }

  establecerChequeBanco(){
    this.movimiento_caja.numero_cheque=this.movimiento_caja.cheque.correlativo_actual;
  }

  
}
