<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Concepto Pago</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarConceptoIngreso()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-1">
            <label>Código</label>
        </div>
        <div class="col-2">
            <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="concepto_ingreso.codigo" placeholder="Código" class="form-control" />
        </div>
        <div class="col-1">
          <label>Gestión</label>
        </div>
        <div class="col-2">
          <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="concepto_ingreso.gestion">
              <option [ngValue]="gestion" *ngFor="let gestion of gestiones | filterBy:{habilitado:true}">{{gestion.nombre}}</option>
          </select>
        </div>
        <div class="col-2">
            <label>Concepto <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_CONCEPTO_PAGOS,'obtenerConceptosPagoEscuela')"></span></label>
        </div>
        <div class="col-4">
            <select [compareWith]="compararObjectos" class="form-control" required name="concepto" [(ngModel)]="concepto_ingreso.concepto">
                <option [ngValue]="concepto" *ngFor="let concepto of tipo_concepto_pago.clases | filterBy:{habilitado:true}">{{concepto.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-1">
            <label>Importe</label>
        </div>
        <div class="col-2">
            <input type="number" step="0.00" name="importe" [(ngModel)]="concepto_ingreso.importe" placeholder="Importe" class="form-control"/>
        </div>
        <div class="col-1">
          <label>Cuotas</label>
        </div>
        <div class="col-2">
            <input type="number" step="0" name="cuotas" [(ngModel)]="concepto_ingreso.cuotas" placeholder="Cuotas" class="form-control"/>
        </div>
        <div class="col-2">
          <label>¿Obligado?</label>
        </div>
        <div class="col-1">
            <label>
                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="concepto_ingreso.obligado"/>
            </label>
        </div>
        <div class="col-1">
            <label>Descripción</label>
        </div>
        <div class="col-2">
            <select [compareWith]="compararObjectos" class="form-control" required name="descripcion" [(ngModel)]="concepto_ingreso.descripcion">
                <option [ngValue]="descripcion" *ngFor="let descripcion of tipo_descripcion_pago">{{descripcion.nombre}}</option>
            </select>
        </div>
    </div>
  </div>