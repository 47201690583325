import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import * as XLSX from 'xlsx';
import { ReportesService } from '../../services/reportes/reportes.service';

@Component({
  selector: 'app-certificacion-sin',
  templateUrl: './certificacion-sin.component.html',
  styleUrls: ['./certificacion-sin.component.css']
})
export class CertificacionSinComponent extends BaseComponent implements OnInit {
  
  pruebasCodigoControl:any;
  formatoPruebaExcel:any;
  ejemploPruebaExcel:any;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
  public usuarioService:UsuarioService,
  public reportesService:ReportesService,
  private toastr: ToastrService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));
    this.ejemploPruebaExcel=GlobalVariable.SERVER_URL+'recursos/EJEMPLO-PRUEBAS-CODIGO-CONTROL.xlsx';
    this.formatoPruebaExcel=GlobalVariable.API_SERVER_URL+'downloadReport/PRUEBAS-CODIGO-CONTROL.xlsx';
  }

  subirExcelPruebasDosificaciones(event){
    let me=this;
		var files = event.target.files;
		var i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			var reader = new FileReader();
			var name = f.name;
			reader.onload = function(e) {
				me.blockUI.start();
			  var data = e.target.result;

			  var workbook = XLSX.read(data, {type: 'binary'});
				var first_sheet_name = workbook.SheetNames[0];
				var row=2,i=0;
				var worksheet = workbook.Sheets[first_sheet_name];
				var pruebas=[];
				do {
					let prueba:any={};
					prueba.autorizacion=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
					prueba.factura=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
					prueba.nit=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
					prueba.fecha=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?me.formatearFecha(worksheet['E'+row].v.toString()):null;
					prueba.monto=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
					prueba.llave_digital=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString():null;
					prueba.codigo_control_esperado=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null;
					pruebas.push(prueba);
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);
				me.generarPruebas(pruebas);
				me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
	    }  
	}

  formatearFecha(fecha){
		var fechaArreglo=fecha.split('/');
		var fechaFormateada=fechaArreglo[0]+fechaArreglo[1]+fechaArreglo[2];
		return fechaFormateada;
	}

  generarPruebas(pruebas){
    this.blockUI.start();
    this.reportesService.generarPruebasCodigoControl({pruebas:pruebas}).subscribe((res:any)=>{
      this.pruebasCodigoControl=res.resultados;
			this.blockUI.stop();
			this.toastr.success('Pruebas generadas Exitosamente!');
    });
	}

}
