<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Asignación de Cursos</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" (click)="guardarCursosConceptoPago()">
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-4">
            <label for="form-field-select-1">Gestión: {{concepto_pago.gestion.nombre}}</label>
        </div>
        <div class="col-8">
            <label for="form-field-select-1">Concepto: {{concepto_pago.concepto.nombre}}</label>
        </div>
        <div class="col-4">
            <label for="form-field-select-1">Nivel</label>
            <select (change)="obtenerCursosPorNivel(filter.nivel.id)" [compareWith]="compararObjectos" class="form-control" required name="nivel" [(ngModel)]="filter.nivel" >
                <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles">{{nivel.nombre}}</option>
            </select>
        </div>
        <div class="col-4">
            <label for="form-field-select-11">Curso</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="curso" [(ngModel)]="filter.curso">
                <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
            </select>
        </div>
        <div class="col-4">
            <button class="btn btn-primary" type="button" (click)="agregarCurso()">
                <i class="ace-icon fa fa-plus red2 align-top bigger-110"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
            <thead fix-head class="table-head-color sticky-nav">
                <tr>
                    <th>Nº</th>
                    <th>Nivel</th>
                    <th>Curso</th>
                    <th>
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let dato_curso of concepto_pago.cursos; let i=index;">
                    <tr *ngIf="!dato_curso.eliminado">
                        <td>{{i+1}}</td>
                        <td>{{dato_curso.curso.nivel.nombre}}</td>
                        <td>{{dato_curso.curso.nombre}}</td>
                        <td>
                            <a *ngIf="dato_curso.id" (click)="sincronizarCursoConceptoPago(dato_curso)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                                <i class="fa fa-sync bigger-260 red2"></i>
                            </a>
                            <a (click)="eliminarCursoConceptoPago(dato_curso)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                                <i class="fa fa-trash-alt bigger-260 red2"></i>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>