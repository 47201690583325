import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { PlanDeCuentasService } from '../../services/plan-de-cuentas/plan-de-cuentas.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-libro-mayor',
  templateUrl: './libro-mayor.component.html',
  styleUrls: ['./libro-mayor.component.css']
})
export class LibroMayorComponent extends BaseComponent implements OnInit {

  cuenta:any={};
  totales_asiento:any={
    total_debe_bs:0,
    total_haber_bs:0,
    total_debe_sus:0,
    total_haber_sus:0
  }
  asientos_mayor:any[]=[];

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private planDeCuentasService:PlanDeCuentasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();

    let fecha_actual=new Date();
		this.filter={
		  id_empresa:this.usuario.id_empresa,
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: 1,
        day:1
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
		  bimonetario:false
		}
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  buscarAsientosLibroMayor(){
    this.blockUI.start();
    this.totales_asiento.total_debe_bs=0;
    this.totales_asiento.total_haber_bs=0;
    this.planDeCuentasService.obtenerLibroMayorCuenta(this).subscribe((res:any)=>{
      this.asientos_mayor=res.asientos;
      if(this.filter.fecha_inicio!=null){
        this.asientos_mayor.unshift({saldo:res.saldo_anterior.suma_debe-res.saldo_anterior.suma_haber,comprobante:{glosa:"Saldo anterior al",numero:0,fecha:Util.convertirObjetoAfecha(this.filter.fecha_inicio)},debe_bs:res.saldo_anterior.suma_debe?res.saldo_anterior.suma_debe:0,haber_bs:res.saldo_anterior.suma_haber?res.saldo_anterior.suma_haber:0,debe_sus:0,haber_sus:0,saldo_sus:res.saldo_anterior.suma_debe_sus-res.saldo_anterior.suma_haber_sus});
      }
      this.totales_asiento.total_debe_bs=this.totales_asiento.total_debe_bs+this.asientos_mayor[0].debe_bs;
      this.totales_asiento.total_haber_bs=this.totales_asiento.total_haber_bs+this.asientos_mayor[0].haber_bs;
      this.totales_asiento.total_debe_sus=this.totales_asiento.total_debe_sus+this.asientos_mayor[0].debe_sus;
      this.totales_asiento.total_haber_sus=this.totales_asiento.total_haber_sus+this.asientos_mayor[0].haber_sus;
      for(var i=1;i<this.asientos_mayor.length;i++){
        this.asientos_mayor[i-1].saldo=this.asientos_mayor[i-1].saldo!=null?this.asientos_mayor[i-1].saldo:this.asientos_mayor[i-1].debe_bs-this.asientos_mayor[i-1].haber_bs;
        this.asientos_mayor[i].saldo=this.asientos_mayor[i-1].saldo+this.asientos_mayor[i].debe_bs-this.asientos_mayor[i].haber_bs;
        this.totales_asiento.total_debe_bs=this.totales_asiento.total_debe_bs+this.asientos_mayor[i].debe_bs;
        this.totales_asiento.total_haber_bs=this.totales_asiento.total_haber_bs+this.asientos_mayor[i].haber_bs;
        this.asientos_mayor[i-1].saldo_sus=this.asientos_mayor[i-1].saldo_sus!=null?this.asientos_mayor[i-1].saldo_sus:this.asientos_mayor[i-1].debe_sus-this.asientos_mayor[i-1].haber_sus;
        this.asientos_mayor[i].saldo_sus=this.asientos_mayor[i-1].saldo_sus+this.asientos_mayor[i].debe_sus-this.asientos_mayor[i].haber_sus;
        this.totales_asiento.total_debe_sus=this.totales_asiento.total_debe_sus+this.asientos_mayor[i].debe_sus;
        this.totales_asiento.total_haber_sus=this.totales_asiento.total_haber_sus+this.asientos_mayor[i].haber_sus;
      }
      this.blockUI.stop();
    });
  }

  generarPdf(){
    this.blockUI.start();
    var doc = new PDFDocument({size:[612, 792],margin:20});
    var stream = doc.pipe(blobStream());
    var y = 185
    
    // draw some text

    doc.moveDown(2);
    doc.font('Helvetica-Bold', 8);
    doc.text(this.cuenta.descripcion.toUpperCase(), { align: 'center' });

    doc.moveDown(2);
    var currentDate = new Date();
    doc.text('Periodo: '+ currentDate.getDate() + "/" +(currentDate.getMonth() + 1) + "/" + currentDate.getUTCFullYear(), 55);
    
    doc.rect(50, 150, 520, 25).stroke();
        doc.text("FECHA", 55, 160);
        doc.text("Nº", 150, 160);
        doc.text("DESCRIPCIÓN", 215, 160);
        doc.text("DEBE", 315, 160);
        doc.text("HABER.", 400, 160);
        doc.text("DEUDOR ACREEDOR", 470, 160);
    
    
    //doc.rect(50, 150, 520, 50).stroke();

    
    
    for (var i = 0; i < this.asientos_mayor.length; i++){
      doc.font('Helvetica', 7);
      doc.text(this.asientos_mayor[i].comprobante.fecha, 55, y, {width:50});
      doc.text(this.asientos_mayor[i].comprobante.numero,150, y);	
      doc.text(this.asientos_mayor[i].comprobante.glosa, 215, y, { width: 100 });
      doc.text(this.asientos_mayor[i].debe_bs,315, y);
      doc.text(this.asientos_mayor[i].haber_bs,400, y);
      doc.text(this.asientos_mayor[i].comprobante.importe,470, y);
      //doc.moveDown(0.4)
      //doc.rect(doc.x, 150, 410, doc.y).stroke();
      doc.rect(50, y - 10, 520, 30).stroke();
      y = y + 30;
      

    }

    //doc.text(pagina + " de " + totalPaginas, 540, y+25);
    doc.end();
    stream.on('finish', function() {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');
    });
    this.blockUI.stop();
  }

  generarExcel(){
    this.blockUI.start();
    var data=[];
    var cabecera=["","",this.cuenta.descripcion];
    data.push(cabecera);
    

    var fechas=["FECHA"];
    data.push(fechas);
    var cabecera_fecha=["FECHA", "N°", "DESCRIPCIÓN", "DEBE","HABER","DEUDOR ACREEDOR"];
    data.push(cabecera_fecha);
    //inicio de for
    for (var i = 0; i < this.asientos_mayor.length; i ++){
      var columns = [];
      var fecha1=this.asientos_mayor[i].fecha?new Date(this.asientos_mayor[i].fecha).getDate()+"/"+(new Date(this.asientos_mayor[i].fecha).getMonth()+1)+"/"+new Date(this.asientos_mayor[i].fecha).getFullYear():""
      columns.push(fecha1);
      //columns.push(asientos[i].comprobante.fecha);
      columns.push(this.asientos_mayor[i].comprobante.numero);
      columns.push(this.asientos_mayor[i].comprobante.glosa);
      columns.push(this.asientos_mayor[i].debe_bs);
      columns.push(this.asientos_mayor[i].haber_bs);
      columns.push(this.asientos_mayor[i].comprobante.importe);
      data.push(columns);
    }

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("LIBRO MAYOR");
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, "LIBRO MAYOR.xlsx");
    })
    this.blockUI.stop();
  }

}
