import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { CajasService } from '../../services/cajas/cajas.service';

declare const enfocarElemento:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-pago-compra',
  templateUrl: './pago-compra.component.html',
  styleUrls: ['./pago-compra.component.css']
})
export class PagoCompraComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;
  compra:any={
    tipo_documento:{}
  };
  pago_compra:any={
    monto_pagado:1
  };
  global_variable=GlobalVariable;
  cajas:any[]=[];
  transacciones:any[]=[];

  constructor(public generalService:GeneralService,
    public comprasService:ComprasService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public pdfService: PdfService,
    public toastr: ToastrService,
    public usuarioService:UsuarioService,
    public cajasService:CajasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    let fecha_actual=new Date();
    this.comprasService.obtenerCompra(this.compra.id).subscribe((datos:any) => {
      this.compra=datos;
      this.pago_compra.sucursal={id:this.compra.id_sucursal};
    });
    this.pago_compra.id_usuario_cajero=this.usuario.id;
    this.pago_compra.id_empresa=this.usuario.id_empresa;
    this.pago_compra.fecha_texto={
      year: fecha_actual.getFullYear(), 
      month: (fecha_actual.getMonth()+1),
      day:fecha_actual.getDate()
    };
    if(this.usuario.empresa.usar_caja_general){
      this.obtenerCajasEmpresa();
      this.obtenerTransacciones();
    }
    aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:2});
    enfocarElemento("importe");
  }

  obtenerCajasEmpresa(){
    this.blockUI.start();
    this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).subscribe((cajas:any)=>{
      this.cajas=cajas;
      this.blockUI.stop();
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_PAGOS).subscribe((transacciones:any)=>{
      this.transacciones=transacciones.clases;
      this.pago_compra.transaccion=this.transacciones[0];
      this.blockUI.stop();
	  } );	
  }

  cerrarModal(){
    this.alTerminar.emit();
  }

  validarPagoCompra(){
    let res=true;
    if(!this.pago_compra.monto_pagado || this.pago_compra.monto_pagado<=0 || this.pago_compra.monto_pagado>this.compra.saldo){
      res=res && false;
      this.toastr.error("El valor del pago debe ser mayor a cero y menor o igual al saldo, intente con otro valor!");
    }
    if(!this.pago_compra.fecha_texto || !this.pago_compra.fecha_texto.year || !this.pago_compra.fecha_texto.month || !this.pago_compra.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el pago!");
    }
    return res;
  }

  efectuarPagoVencimientoCredito(){
    if(this.validarPagoCompra()){
      this.blockUI.start();
      this.pago_compra.fecha=Util.convertirObjetoAfecha(this.pago_compra.fecha_texto);
      this.comprasService.guardarPagoCompra(this.compra.id,this.pago_compra).subscribe((res:any) => {
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.pdfService.imprimirReciboPagoCompra(res.pago,this.usuario);
          this.alTerminar.emit(res);
        }
				this.blockUI.stop();
      });
    }
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.pago_compra.comprobante=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.pago_compra.comprobante=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

}
