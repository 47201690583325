import { PersistenciaService } from '../base/services/persistencia/persistencia.service';
import { Paginator } from './paginator';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild, TemplateRef } from '@angular/core';
import { GeneralService } from '../base/services/general/general.service';
import { UsuarioService } from '../base/services/usuario/usuario.service';
import { GlobalVariable } from '../global';
import { TextoEmpresaComponent } from '../base/components/texto-empresa/texto-empresa.component';
import { ConceptoEmpresaComponent } from '../base/components/concepto-empresa/concepto-empresa.component';
import { PageConfiguration } from './page-configuration';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Util } from '../utils/utils';

declare const $:any;
declare const desplegarSubMenu:any;
declare const ocultarSubMenus:any;
declare const aplicarPluginsGeneral:any;

export class BaseComponent extends Paginator {

    public usuario:any;
    public aplicacion:any;
    tipo_textos:any={};

    rest_server=GlobalVariable.SERVER_URL;
    global_variable=GlobalVariable;

    public configuracion_pagina:any;

    closeResult='';

    textoEdicion:NgbModalRef;
    conceptoEdicion:NgbModalRef;

    popupConfirmacion:NgbModalRef;
    configuracion_aplicacion_modal:NgbModalRef;

    @BlockUI() blockUI: NgBlockUI;

    datos_app:any={
      nombre:"SnapQuick",
      logo:'icon-empresa-default.png',
      canal_youtube:'https://www.youtube.com/channel/UCnfAlzr1NXqfsOp6_7osgJw',
      crear_cuenta:false
    }

    codigo_automatico:boolean=true;

    constructor(public persistenciaService:PersistenciaService,
        public modalService: NgbModal,
        public generalService:GeneralService,
        public usuarioService:UsuarioService){
        super();
    }

    init(){
        this.usuario=this.persistenciaService.get("usuario");
        aplicarPluginsGeneral();
        this.establecerDatosApp();
    }

    buscarAplicacion (url) {
        this.aplicacion=this.usuario.aplicaciones.filter(e => e.aplicacion.url == url)[0];
        ocultarSubMenus();
        if(this.aplicacion.aplicacion.subaplicaciones.length>0){
          desplegarSubMenu(this.aplicacion.aplicacion.url+"1");
        }
    }

    buscarSubAplicacion (url) {
      for(let j=0;j<this.usuario.aplicaciones.length;j++){
        let app=this.usuario.aplicaciones[j].subaplicaciones.filter(e => e.aplicacion.url == url);
        if(app.length>0){
          this.aplicacion=app[0];
        }
      }
      
  }

    clickBotonSubir(id){
        $("#"+id).trigger('click');
    }

    compararObjectos(c1, c2): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
    }

    obtenerTextoEmpresa(nombre_corto){
        let texto={nombre:""};
        if(this.tipo_textos && this.tipo_textos.clases && this.tipo_textos.clases.length>0){
          var clase=this.tipo_textos.clases.filter(e => e.nombre_corto == nombre_corto)[0];
          if(clase){
            texto=clase;
          }else{
            texto.nombre=nombre_corto.split("_")[1];
          }
        }else{
          texto.nombre=nombre_corto.split("_")[1];
        }
        return texto.nombre;
      }

    abrirTextoEmpresa(clase_nombre_corto){
        this.textoEdicion=this.modalService.open(TextoEmpresaComponent, {ariaLabelledBy: 'modal-basic-title',size:'sm', backdrop: 'static'});
        this.textoEdicion.componentInstance.id_empresa = this.usuario.id_empresa;
        this.textoEdicion.componentInstance.clase_nombre_corto = clase_nombre_corto;
        
        this.textoEdicion.componentInstance.onTerminate.subscribe(($e) => {
            if($e.save){
                this.obtenerTextosEmpresa();   
            }
			this.textoEdicion.close();
        });
    }

    public getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return 'by clicking on a backdrop';
		} else {
		  return `with: ${reason}`;
		}
      }
      
    obtenerTextosEmpresa(){
        if(this.usuario.id_empresa){
            this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"TEXTOS_SISTEMA_EMPRESA").subscribe(tipoTextos => {
                this.tipo_textos=tipoTextos;
            });
        }
    }

    abrirConceptoEmpresa(clase_nombre_corto,funcion){
      this.conceptoEdicion=this.modalService.open(ConceptoEmpresaComponent, {ariaLabelledBy: 'modal-basic-title',size:'md', backdrop: 'static'});
      this.conceptoEdicion.componentInstance.id_empresa = this.usuario.id_empresa?this.usuario.id_empresa:0;
      this.conceptoEdicion.componentInstance.clase_nombre_corto = clase_nombre_corto;
        
      this.conceptoEdicion.componentInstance.onTerminate.subscribe(($e) => {
          if($e.save){
              this[funcion](); 
          }
          this.conceptoEdicion.close();
      });
    }

    establecerDatosApp(){
      this.datos_app.nombre=localStorage.getItem("app_name")==null?this.datos_app.nombre:localStorage.getItem("app_name");
      this.datos_app.logo=localStorage.getItem("app_logo")==null?this.datos_app.nombre:localStorage.getItem("app_logo");
      this.datos_app.canal_youtube=localStorage.getItem("canal_youtube")==null?this.datos_app.nombre:localStorage.getItem("canal_youtube");
      this.datos_app.canal_facebook=localStorage.getItem("canal_facebook")==null?this.datos_app.nombre:localStorage.getItem("canal_facebook");
      this.datos_app.canal_whatsapp=localStorage.getItem("canal_whatsapp")==null?this.datos_app.nombre:localStorage.getItem("canal_whatsapp");
      this.datos_app.canal_web=localStorage.getItem("canal_web")==null?this.datos_app.nombre:localStorage.getItem("canal_web");
      document.title = this.datos_app.nombre.toUpperCase();
      let favicon = document.querySelector('link[rel="icon"]');
      favicon.setAttribute('type', 'image/png');
      favicon.setAttribute('href',GlobalVariable.SERVER_URL+"img/"+ this.datos_app.logo);
    }

    async obtenerConfiguracionPagina (codigo_aplicacion) {
      let configuracion_aplicacion:any=Util.obtenerConfiguracionAplicacion(codigo_aplicacion,this);
      this.configuracion_pagina=new PageConfiguration({
        crear: true,
        id_empresa: this.usuario.id_empresa,
        id_usuario:this.usuario.empresa?(this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:null):null,
        datos: configuracion_aplicacion
      },this.aplicacion.aplicacion.id,
      this.usuarioService);
      await this.configuracion_pagina.compareAndUpdateConfigurations(this.usuario.id_empresa,this.usuario.empresa?(this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0):0);
    }

    async obtenerEscuela(){
      this.blockUI.start();
      this.filter.escuela=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
      this.blockUI.stop();
    }

    async obtenerUltimoCodigo(nombre_corto_correlativo){
      let tipo_codigo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,nombre_corto_correlativo).toPromise();
      if(!tipo_codigo.clases || tipo_codigo.clases.length==0){
        tipo_codigo.clases=[{nombre:"COD",nombre_corto:"1",habilitado:true}];
        await this.generalService.actualizarTipo(tipo_codigo).toPromise();
      }
      this.codigo_automatico=tipo_codigo.clases[0].habilitado;
      return tipo_codigo.clases[0].nombre+tipo_codigo.clases[0].nombre_corto;
    }

    async actualizarUltimoCodigo(nombre_corto_correlativo){
      let tipo_codigo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,nombre_corto_correlativo).toPromise();
      let ultimo_numero=parseInt(tipo_codigo.clases[0].nombre_corto);
      ultimo_numero=ultimo_numero+1;
      tipo_codigo.clases[0].nombre_corto=ultimo_numero.toString();
      await this.generalService.actualizarTipo(tipo_codigo).toPromise();
    }

    async obtenerCodigoAutomatico(nombre_corto_correlativo){
      let tipo_codigo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,nombre_corto_correlativo).toPromise();
      if(!tipo_codigo.clases || tipo_codigo.clases.length==0){
        tipo_codigo.clases=[{nombre:"COD",nombre_corto:"1",habilitado:true}];
        await this.generalService.actualizarTipo(tipo_codigo).toPromise();
      }
      this.codigo_automatico=tipo_codigo.clases[0].habilitado;
    }

    public loadScript(url: string) {
      const body = <HTMLDivElement> document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }

    verTab(tab_seleccionado){
      $(".contenido_tab").css('display','none');
      $(".link_tab").removeClass('active');
      $("."+tab_seleccionado).addClass('active');
      $("."+tab_seleccionado).css('display','block');
    }

    obtenerNombreArchivo(ruta){
      return ruta.split("/")[ruta.split("/").length-1];
    }
}
