<div class="modal-header">
    <div class="col-6 col-xs-6">
        <h4 class="widget-title">Datos facturación Sucursal: {{sucursal_modificacion.nombre}}</h4>
    </div>
    <div class="col-6 col-xs-6">
        <button *ngIf="aplicacion_empresa.puede_modificar" class="btn btn-primary" type="button" (click)="guardarDatosFacturacionSucursal()">
            <i class="ace-icon fa fa-save red2 align-top text-180"></i>
  </button>&nbsp;
  <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top text-180"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="box box-primary px-2" >
  <div class="box-body">
    <div class="row">
                <div class="col-2 col-sm-2">
          <label>Nombre </label> 
          <input [(ngModel)]="sucursal_modificacion.nombre_sin" placeholder="Nombre" type="text" class="form-control border-l-2" />
      </div>
      <div class="col-1 col-sm-1">
        <label>Número </label> 
        <input disabled [(ngModel)]="sucursal_modificacion.numero" placeholder="Nombre" type="number" class="form-control border-l-2" />
      </div>
                <div class="col-9 col-sm-9">
          <label>Dirección </label>&nbsp;
          <input [(ngModel)]="sucursal_modificacion.direccion_sin" placeholder="Dirección" type="text" class="form-control border-l-2" />
      </div>
            </div>
    <hr>
    <div class="row">
      <div class="col-3 col-sm-3">
        <label>Teléfono Fijo </label>&nbsp;
        <input [(ngModel)]="sucursal_modificacion.telefono_fijo_sin" placeholder="Teléfono Fijo" type="text" class="form-control border-l-2" />
      </div>
      <div class="col-3 col-sm-3">
        <label>Teléfono Móvil  </label>&nbsp;
        <input [(ngModel)]="sucursal_modificacion.telefono_movil_sin" placeholder="Teléfono Móvil" type="text" class="form-control border-l-2" />
      </div>
      <div class="col-3 col-sm-3">
        <label>Ciudad </label>&nbsp;
        <select [compareWith]="compararObjectos" class="form-control" name="departamento" [(ngModel)]="sucursal_modificacion.departamento_sin" (change)="establecerDepartamento()">
          <option [ngValue]="departamento" *ngFor="let departamento of departamentos">{{departamento.nombre}}</option>
        </select>
      </div>
      <div class="col-3 col-sm-3">
        <label>Municipio </label>&nbsp;
        <select [compareWith]="compararObjectos" class="form-control" name="municipio" [(ngModel)]="sucursal_modificacion.municipio_sin">
          <option [ngValue]="municipio" *ngFor="let municipio of municipios">{{municipio.nombre}}</option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="usuario.empresa.usar_facturacion">
      <hr>
      <div class="row">
        <div class="col-6 col-xs-6">
          <h4 class="widget-title">Actividad y Dosificación</h4>
        </div>
        <div class="col-6 col-xs-6">
          <h4 class="widget-title">Dosificaciones Registradas</h4>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-2 col-sm-2">
          <label>Actividad </label>&nbsp; 
          <select [compareWith]="compararObjectos" class="form-control" name="actividad" [(ngModel)]="actividad_sucursal.actividad">
            <option [ngValue]="actividad" *ngFor="let actividad of actividades_economicas">{{actividad.nombre}}</option>
          </select>
        </div>
        <div class="col-3 col-sm-3">
          <label>Dosificación </label>&nbsp; <span class="fa fa-plus btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" (click)="crearNuevaDosificacion()"></span>
          <select [compareWith]="compararObjectos" class="form-control" name="dosificacion" [(ngModel)]="actividad_sucursal.dosificacion">
            <option [ngValue]="dosificacion" *ngFor="let dosificacion of dosificaciones">{{dosificacion.autorizacion}} - {{dosificacion.correlativo}} - {{dosificacion.fecha_limite_emision | date:'dd/MM/yyyy'}}</option>
          </select>
        </div>
        <div class="col-1 col-sm-1">
          <button class="btn btn-primary" type="button" (click)="agregarDosificacion(actividad_sucursal)">
            <i class="ace-icon fa fa-arrow-right red2 align-top bigger-110"></i>
          </button>
        </div>
        <div class="col-5 col-sm-5">
          <div class="table-fixed-header">
            <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                <thead fix-head class="table-head-color sticky-nav">
                    <tr>
                      <th>Nº</th>
                      <th>Actividad</th>
                      <th>Dosificacion</th>
                      <th style="text-align: center;" data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let actividad_dosificacion of sucursal_modificacion.actividadesDosificaciones let i=index;">
                      <tr *ngIf="!actividad_dosificacion.eliminado">
                          <td>{{i+1}}</td>
                          <td>{{actividad_dosificacion.actividad.nombre}}</td>
                          <td>{{actividad_dosificacion.dosificacion.autorizacion}} - {{actividad_dosificacion.dosificacion.correlativo}} - {{actividad_dosificacion.dosificacion.fecha_limite_emision | date:'dd/MM/yyyy'}}</td>
                          <td>
                            <a (click)="removerActividadDosificacion(actividad_dosificacion)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                          </td>
                      </tr>
                    </ng-container>
                  </tbody>
            </table>
          </div>
        </div>
      </div>    
    </ng-container>
    <ng-container *ngIf="(usuario.empresa.usar_facturacion_computarizada_en_linea && !usuario.empresa.usar_sucursales) || 
                        (usuario.empresa.usar_sucursales && usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal) || 
                        (usuario.empresa.usar_sucursales && usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa) || 
                        (usuario.empresa.usar_sucursales && usuario.empresa.usar_facturacion_computarizada_en_linea_por_empresa && sucursal_modificacion.numero==0)">
      
    <ng-container *ngIf="usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa">
      <hr>
      <div class="row">
          <div class="col-4 col-sm-4">
            <label>Razón Social </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.razon_social" placeholder="Razón social" type="text" class="form-control border-l-2" />
          </div>
          <div class="col-4 col-sm-4">
            <label>N.I.T. </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.nit" placeholder="N.I.T." type="text" class="form-control border-l-2" />
          </div>
          <div class="col-4 col-sm-4">
            <label>Código Sistema S.I.N. </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.sin_codigo_sistema" placeholder="codigo sistema S.I.N." type="text" class="form-control border-l-2" />
          </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-3 col-sm-3">
            <label>Modalidad Facturación S.I.N. </label>&nbsp;
            <select [compareWith]="compararObjectos" class="form-control" required name="sin_modalidad_facturacion_r" [(ngModel)]="sucursal_modificacion.sin_modalidad_facturacion">
                <option [ngValue]="sin_modalidad_facturacion" *ngFor="let sin_modalidad_facturacion of tipoSinModalidadesFacturacion">{{sin_modalidad_facturacion.nombre}}</option>
            </select>
        </div>
        <div class="col-3 col-sm-3">
            <label>Ambiente S.I.N. </label>&nbsp;
            <select [compareWith]="compararObjectos" class="form-control" required name="sin_ambiente" [(ngModel)]="sucursal_modificacion.sin_ambiente">
                <option [ngValue]="sin_ambiente" *ngFor="let sin_ambiente of tipoSinAmbientes">{{sin_ambiente.nombre}}</option>
            </select>
        </div>
        <div class="col-3 col-sm-3">
            <label>Token S.I.N. </label>&nbsp;
            <input type="text" [(ngModel)]="sucursal_modificacion.sin_token" placeholder="Token S.I.N." class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <ng-container *ngIf="sucursal_modificacion.sin_modalidad_facturacion && sucursal_modificacion.sin_modalidad_facturacion.nombre_corto=='1'">
            <div class="col-3 col-sm-3">
                <form id="dropzone" action="/upload" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1 mt-3">
                    <div class="fallback d-none">
                      <input name="file" type="file" multiple (change)="subirArchivo($event)" accept=".p12"/>
                    </div>
          
                    <div class="dz-default dz-message">
                      <span class="text-150  text-grey-d2">
                      <span class="text-130 font-bolder"><i class="fa fa-caret-right text-danger-m1"></i> Arrastra archivos</span>
                      para subir
                      <span class="text-90 text-grey-m1">(o click)</span>
                      <br />
                      <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-3x mt-4"></i>
                      </span>
                    </div>
                  </form>
          
                  <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                  <div id="preview-template" class="d-none">
                    <div class="dz-preview dz-file-preview">
                      <div class="dz-image">
                        <img alt="Archivo" data-dz-thumbnail="" />
                      </div>
          
                      <div class="dz-details">
                        <div class="dz-size">
                          <span data-dz-size=""></span>
                        </div>
          
                        <div class="dz-filename">
                          <span data-dz-name=""></span>
                        </div>
                      </div>
          
                      <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                      </div>
          
                      <div class="dz-error-message">
                        <span data-dz-errormessage=""></span>
                      </div>
          
                      <div class="dz-success-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-white"></i>
                          <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                      </span>
                      </div>
          
                      <div class="dz-error-mark">
                        <span class="fa-stack fa-lg text-150">
                          <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                          <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                      </span>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-3 col-sm-3">
                <label>Clave Firma Digital </label>&nbsp;
                <input type="text" [(ngModel)]="sucursal_modificacion.sin_clave_firma_digital" placeholder="Clave Firma" class="form-control" />
            </div>
        </ng-container>
        <div class="col-3 col-sm-3">
            <label>Documentos Facturación S.I.N. </label>&nbsp;
            <ngx-bootstrap-multiselect *ngIf="tipoSinDocumentosFacturacion" [options]="documentos_facturacion" [(ngModel)]="sucursal_modificacion.sin_documentos_facturacion"></ngx-bootstrap-multiselect>
        </div>
      </div>
    </ng-container>
      <hr>
      <div class="row">
        <div class="col-2">
          <label>Código Único Inicio Sistema (C.U.I.S.) S.I.N.</label> 
        </div>

        <div class="col-1">
          <a title="Reiniciar Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="obtenerCuis()">
            <i class="ace-icon fa fa-sync bigger-130"></i>
          </a>
        </div>
        <div class="col-2">
          <input disabled type="text" id="sin_codigo_unico_inicio_sistema" [(ngModel)]="sucursal_modificacion.sin_codigo_unico_inicio_sistema" placeholder="Código Único Inicio Sistema (C.U.I.S.) S.I.N." class="form-control" />
        </div>
        <div class="col-2">
          <label>Código Único Facturación Diaria (C.U.F.D.) S.I.N.</label> 
        </div>

        <div class="col-1">
          <a title="Reiniciar Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="obtenerCufd()">
            <i class="ace-icon fa fa-sync bigger-130"></i>
          </a>
        </div>
        <div class="col-2" *ngIf="sucursal_modificacion.sin_cufd">
          <input disabled type="text" [(ngModel)]="sucursal_modificacion.sin_cufd.codigo_cufd" placeholder="Código Único Facturación Diaria (C.U.F.D.) S.I.N." class="form-control" />
        </div>
      </div>
    </ng-container>
  </div>
</div>
</div>