<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Comprobante</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarRegistroComprobante()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-4">
            <div class="col-12">
                <label>Sucursal</label>
            </div>
            <div class="col-12">
                <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="comprobante.sucursal">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="col-12">
                <label>Tipo Comprobante</label>
            </div>
            <div class="col-12">
                <select [compareWith]="compararObjectos" class="form-control" required name="tipo_comprobante" [(ngModel)]="comprobante.tipo">
                    <option [ngValue]="tipo_comprobante" *ngFor="let tipo_comprobante of tipos_comprobantes">{{tipo_comprobante.nombre}}</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="col-12">
                <label>Fecha</label>
            </div>
            <div class="col-12">
                <div class="input-group">
                    <input required id="fecha_texto" name="fecha_texto" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="comprobante.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Glosa General</label>
        </div>
        <div class="col-12">
            <textarea class="form-control" style="overflow:auto;resize:none" rows="2" cols="70" maxlength="200" [(ngModel)]="comprobante.glosa"></textarea>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th rowspan="2" style="text-align: center">Nº</th>
                        <th rowspan="2" style="text-align: center">
                            <div class="col-12">Cuenta
                                <button title="Nuevo" (click)="agregarAsiento()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                                    <i class="fa fa-plus text-primary"></i>
                                </button>
                            </div>
                        </th>
                        <th rowspan="2">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                        <th colspan="2" style="text-align: center">Bolivianos</th>
                        <th class="red" style="text-align: center" *ngIf="comprobante.opcion_bimonetario" colspan="2">Dólares</th>
                    </tr>
                    <tr>

                        <th style="text-align: center">Debe</th>
                        <th style="text-align: center">Haber</th>
                        <th style="text-align: center" class="red" *ngIf="comprobante.opcion_bimonetario">Debe</th>
                        <th style="text-align: center" class="red" *ngIf="comprobante.opcion_bimonetario">Haber</th>
                    </tr>
                </thead>

                <tbody *ngFor="let asiento of comprobante.asientos; let i=index;">
                    <ng-container *ngIf="!asiento.eliminado" >
                        <tr>
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input name="{{i}}-As" [disabled]="asiento.id" [inputFormatter]="formatter" *ngIf="asiento.cuenta_busqueda" 
                                        [resultTemplate]="rt" type="text" class="form-control" [(ngModel)]="asiento.cuenta_a_buscar" 
                                        [ngbTypeahead]="buscarCuenta" placeholder="Buscar cuenta" autocomplete="off" (selectItem)="establecerCuentaActual($event,asiento,i)"/>
                                        <ng-template #rt let-r="result" let-t="term">
                                            <ngb-highlight [result]="r.codigo+' - '+r.nombre" [term]="t"></ngb-highlight>
                                        </ng-template>

                                        <select *ngIf="!asiento.cuenta_busqueda" (change)="establecerCuentaActual(asiento.cuenta,asiento,i)" [compareWith]="compararObjectos" [disabled]="asiento.id" class="form-control" required name="sucursal" [(ngModel)]="asiento.cuenta">
                                            <optgroup *ngFor = 'let cuenta_padre of cuentas_apropiacion' label="{{cuenta_padre.nombre}}">
                                                <option *ngFor = 'let cuenta of cuenta_padre.cuentas' [ngValue]="cuenta">{{cuenta.codigo}} - {{cuenta.nombre}}</option> 
                                            </optgroup>
                                        </select>
                                    </div>
                                
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" [(ngModel)]="asiento.glosa"
                                        placeholder="Agregar glosa individial">
                                    </div>
                                </div>
                                <span *ngIf="asiento.cuenta && asiento.cuenta.cuenta_auxiliar" class="text-danger" (click)="abrirLibroMayorCuenta(asiento.cuenta.cuenta_auxiliar)">{{asiento.cuenta.cuenta_auxiliar.codigo}} - {{asiento.cuenta.cuenta_auxiliar.nombre}}</span>
                            </td>
                            <td>
                                <a [disabled]="asiento.id" title="Buscar Cuenta" *ngIf="!asiento.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(asiento,asiento.cuenta_busqueda)" >
                                    <i class="fas fa-search"></i>
                                </a>
                                <a [disabled]="asiento.id" title="Seleccionar Cuenta" *ngIf="asiento.cuenta_busqueda" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="cambiarAsientoCuentaBusqueda(asiento,asiento.cuenta_busqueda)" >
                                    <i class="fas fa-list-alt"></i>
                                </a>
                                <a *ngIf="asiento.cuenta && asiento.cuenta.id" title="Libro Mayor" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirLibroMayorCuenta(asiento.cuenta)" >
                                    <i class="fas fa-atlas"></i>
                                </a>
                                <button [ngbPopover]="popContent" [popoverTitle]="popTitle" [hidden]="!asiento.cuenta || !asiento.cuenta.id || (asiento.id && asiento.cuenta.cuenta_auxiliar && asiento.cuenta.cuenta_auxiliar.id)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" data-placement="right" title="Title" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                                    <i class="fas fa-external-link-square-alt"></i>
                                </button>
                                <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarAsiento(asiento)">
                                  <i class="fa fa-trash-alt"></i>
                                </a>
                                <ng-template #popContent>
                                    <select size="8" [compareWith]="compararObjectos" class="form-control" required name="cuenta_auxiliar" [(ngModel)]="asiento.cuenta.cuenta_auxiliar">
                                        <option [ngValue]="cuenta_auxiliar" *ngFor="let cuenta_auxiliar of asiento.cuenta.cuentas_auxiliares">{{cuenta_auxiliar.nombre}}</option>
                                    </select>
                                </ng-template>
                                <ng-template #popTitle><b>Cuentas Auxiliares</b></ng-template>
                            </td>
                            <td class="input-number">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="establecerCuentaVista(asiento.cuenta)" id="{{i}}-Bs" type="number" [(ngModel)]="asiento.debe_bs"
                                    (change)="convertirMonedaAdolares(asiento)" class="form-control" step="0.001" min="0.1">
                            </td>
                            <td class="input-number">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="establecerCuentaVista(asiento.cuenta)" id="{{i}}-Cs" type="number" [(ngModel)]="asiento.haber_bs"
                                (change)="convertirMonedaAdolares(asiento)" class="form-control" step="0.001" min="0.1">
                            </td>
                            <td class="input-number" *ngIf="comprobante.opcion_bimonetario">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="comprobante.establecerCuentaVista(asiento.cuenta)" type="number" [(ngModel)]="asiento.debe_sus"
                                (change)="convertirDolaresAmoneda(asiento)" step="0.001" min="0.1">
                            </td>
                            <td class="input-number" *ngIf="comprobante.opcion_bimonetario">
                                <input [disabled]="asiento.id" onfocus="this.select();" style="width: 80px;" (focus)="comprobante.establecerCuentaVista(asiento.cuenta)" type="number" [(ngModel)]="asiento.haber_sus"
                                (change)="convertirDolaresAmoneda(asiento)" step="0.001" min="0.1">
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr *ngIf="comprobante.asientos.length>0">
                        <td colspan="3" style="text-align: right">Totales</td>
                        <td style="text-align: center">{{total_debe.toFixed(2)}}</td>
                        <td style="text-align: center">{{total_haber.toFixed(2)}}</td>
                        <td *ngIf="comprobante.opcion_bimonetario" style="text-align: center">{{total_debe_sus}}</td>
                        <td *ngIf="comprobante.opcion_bimonetario" style="text-align: center">{{total_haber_sus}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>