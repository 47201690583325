import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { MateriasService } from '../../services/materias/materias.service';

@Component({
  selector: 'app-materias',
  templateUrl: './materias.component.html',
  styleUrls: ['./materias.component.css']
})
export class MateriasComponent extends BaseComponent implements OnInit {

  materias:any[]=[];
  areas:any[]=[];
  areas_nivel:any[]=[];
  niveles:any[]=[];
  materia:any={};
  escuela:any;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  modal_modificacion_materia:NgbModalRef;
  @ViewChild('modal_modificacion_materia')
  private modal_modificacion_materia_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
    public router:Router,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public usuarioService:UsuarioService,
    private materiasService:MateriasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.obtenerMaterias();
    this.obtenerNiveles();
    this.escuela=await this.generalService.obtenerEscuela(this.usuario.id_empresa).toPromise();
    await this.obtenerAreasMaterias();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  obtenerMaterias(){
    this.blockUI.start();
    this.materiasService.obtenerMateriasColegio(this.usuario.id_empresa).subscribe((materias:any)=>{
      this.blockUI.stop();
      this.materias=materias;
    });
  }

  obtenerNiveles(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).subscribe((entidad:any)=>{
      this.blockUI.stop();
      this.niveles=entidad.clases;
    });
  }

  async crearNuevaMateria(){
    this.materia={};
    this.materia.id_escuela = this.escuela.id;
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_MATERIA);
    this.materia.codigo = codigo_nuevo;
    this.modal_modificacion_materia=this.modalService.open(this.modal_modificacion_materia_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_modificacion_materia.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  async validarDatosMateria(){
    let res=true;
		if(!this.materia.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de materia!");
    }
    if(!this.materia.nombre_corto){
      res=res && false;
      this.toastr.error("Debe especificar el nombre corto de la materia!");
    }
    if(this.materia.orden==null || this.materia.orden==undefined){
      res=res && false;
      this.toastr.error("Debe especificar el orden de la materia!");
    }
    if(!this.materia.nivel){
      res=res && false;
      this.toastr.error("Debe especificar el nivel escolar de la materia!");
    }
    if(!this.materia.area || !this.materia.area.id){
      res=res && false;
      this.toastr.error("Debe especificar el area de la materia!");
    }
		return res;
  }

  async guardarMateria(){
    if(await this.validarDatosMateria()){
      this.blockUI.start();
      if (this.materia.id) {
        this.materiasService.actualizarMateria(this.materia).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_modificacion_materia.close();
          this.obtenerMaterias();
        });
      } else {
        this.materia.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_MATERIA):this.materia.codigo;
        this.materiasService.guardarMateria(this.materia).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_MATERIA);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.modal_modificacion_materia.close();
          this.obtenerMaterias();
        });
      }
    }
  }

  async modificarMateria(materia){
    this.materia=materia;
    this.areas_nivel=this.areas.filter(a => a.id_nivel==this.materia.id_nivel);
    this.modal_modificacion_materia=this.modalService.open(this.modal_modificacion_materia_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.modal_modificacion_materia.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  abrirPopupConfirmacionEliminacion(materia){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar la materia?";
		this.popupConfirmacion.componentInstance.data = materia;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarMateria($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  eliminarMateria(materia){
    this.blockUI.start();
    this.materiasService.eliminarMateria(materia).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
		  this.obtenerMaterias();
    });
  }

  sincronizarAreasMaterias(){
    this.blockUI.start();
    this.materiasService.sincronizarAreasMaterias({empresa:this.usuario.empresa,escuela:this.escuela}).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.blockUI.stop();
      this.obtenerAreasMaterias();
    });
  }

  async obtenerAreasMaterias(){
    this.blockUI.start();
    let areas:any=await this.materiasService.obtenerAreasMaterias(this.escuela.id).toPromise();
    this.areas=areas;
    this.blockUI.stop();
  }

  cambiarNivel(nivel){
    this.materia.area=null;
    this.areas_nivel=this.areas.filter(a => a.id_nivel==nivel.id);
  }

}
