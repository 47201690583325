<div class="modal-header">
    
        <div class="col-3">
            <h4 class="widget-title">Registro de Notas: </h4>
        </div>
        <div class="col-5">
            <table class="table table-bordered table-hover table-striped">
                <tbody>
                    <tr>
                    <td><b>Gestión:</b> {{filter.gestion.nombre}}</td>
                    <td><b>Colegio:</b> {{escuela.nombre}} </td>
                    <td><b>Trimestre:</b> {{filter.intervalo_escolar.nombre}}</td>
                    <td><b>Curso:</b> {{filter.registro_profesor.curso.nombre_corto}}</td>
                    <td>
                        <b>Matéria:</b> {{filter.registro_profesor.materia.nombre_corto}}
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-4">
            <button title="Limpiar calificaciones" (click)="limpiarCalificaciones()" *ngIf="filter.planilla_habilitada" class="btn btn-warning" type="button" >
                <i class="ace-icon fas fa-broom red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button *ngIf="filter.planilla_habilitada" (click)="clickBotonSubir('subir-excel-calificaciones')" class="btn btn-success" aria-label="Export" type="button" title="Subir excel calificaciones" aria-expanded="false">
                <i class="ace-icon fas fa-upload red2 align-top bigger-110"></i>
                <input id="subir-excel-calificaciones" style="display:none" type="file" class="form-control" (change)="subirExcelCalificaciones($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
            </button>&nbsp;
            <button title="Guardar calificaciones" *ngIf="filter.guardado_inhabilitado" class="btn btn-primary" (click)="guardarCalificaciones()" type="button" >
                <i class="ace-icon fa fa-save red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button title="Reinicializar planilla" *ngIf="filter.planilla_habilitada" class="btn btn-danger" (click)="abrirPopupConfirmacionReinicializacion()" type="button">
                <i class="ace-icon fa fa-power-off red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button title="Descargar Excel" class="btn btn-success" (click)="descargarExcelCalificaciones()" type="button">
                <i class="ace-icon fa fa-file-excel red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button title="Descargar Pdf" class="btn btn-danger" (click)="descargarPdfCalificaciones()" type="button">
                <i class="ace-icon fa fa-file-pdf red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button title="Configuración planilla calificaciones" *ngIf="filter.guardado_inhabilitado" class="btn btn-warning" (click)="abrirConfiguracionPlanilla()" type="button">
                <i class="ace-icon fa fa-cog red2 align-top bigger-110"></i>
            </button>&nbsp;
            <button title="Cerrar sin guardar" class="btn btn-danger" type="button" (click)="cerrarRegistroCalificaciones()">
                <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
            </button>
        </div>
    
</div>
<div class="modal-body">
    <div class="card card-info card-outline">
        <div class="box box-primary">
            <div class="box-body">
                <table class="table text-dark-m2 text-95 table-bordered table-hover table-striped">
                    <thead class="bgc-white text-grey text-uppercase text-80">
                        <tr>
                            <th rowspan="2" class="bgc-primary-d1 text-white text-center">Nº</th>
                            <th rowspan="2" class="bgc-primary-d1 text-white text-center">ESTUDIANTE</th>
                            <th [ngClass]="{'bgc-primary-l2':data.orden==1,'bgc-secondary-l2':data.orden==2,'bgc-success-l2':data.orden==3,'bgc-danger-l2':data.orden==4,'bgc-warning-l2':data.orden==5,'bgc-purple-l2':data.orden==6}" *ngFor="let data of filter.registro_profesor.configuracion | orderBy:'configuracion.orden'" [attr.colspan]="obtenerCantidadDimensionCalificaciones(data.dimension.nombre)">
                                {{data.dimension.nombre}} ({{data.dimension.nombre_corto}}%)
                            </th>
                            <th class="cabecera-vertical" rowspan="2">PROM. TRIM.</th>
                            <th rowspan="2">LITERAL</th>
                            <th class="cabecera-vertical" rowspan="2">PROM. ANUAL</th>
                        </tr>
                        <tr>
                            <th *ngFor="let cabecera of cabecera_planilla; let i=index;" class="cabecera-vertical column-score-header">
                                <!--<a *ngIf="!filter.guardado_inhabilitado" e-disabled="filter.option!='1' || !filter.guardado_inhabilitado" href="javascript:void(0)" ng-click="openScoreRegistryHeadForm(score_head)"><i ng-if="filter.option=='1'" class="fas fa-pencil-alt blue"></i> {{cabecera.descripcion.substring(0,10)}} </a>-->
                                
                                <a id="{{cabecera.id}}-c" title="{{cabecera.descripcion}}" class="text-blue mx-2px"  href="javascript:void(0)" (click)="modificarDescripcionCabecera(cabecera)">          
                                    <i class="fas fa-pencil-alt text-105"></i> {{cabecera.descripcion.substring(0,10)}}    
                                </a> 
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let estudiante of estudiantes; let i=index;" ng-init="rowindex = $index">
                            <td>
                                {{i+1}}
                                <a *ngIf="filter.guardado_inhabilitado" title="Reiniciar planilla estudiante" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionReinicializacionEstudiante(estudiante)">          
                                    <i class="fas fa-power-off text-105"></i>        
                                </a>
                            </td>
                            <td>{{estudiante.persona.nombre_completo}}</td>
                            <ng-container *ngFor="let data of filter.registro_profesor.configuracion | orderBy:'configuracion.orden';let j=index;">
                                <td class="input-score" [ngClass]="{'bgc-primary-l2':data.configuracion.orden==1,'bgc-secondary-l2':data.configuracion.orden==2,'bgc-success-l2':data.configuracion.orden==3,'bgc-danger-l2':data.configuracion.orden==4,'bgc-warning-l2':data.configuracion.orden==5,'bgc-purple-l2':data.configuracion.orden==6}" *ngFor="let score of obtenerEdicionCalificacionesDimension(estudiante.inscripciones_colegio[0].calificaciones,data.dimension.nombre,data.configuracion.orden)">
                                    <input class="form-control" id="sc{{i}}-{{score.orden}}" [disabled]="(!data.configuracion.casilla_habilitada_padre || !data.configuracion.casilla_habilitada) && !es_usuario_administrador" only-num type="number" maxlength="3" min="1" max="{{data.valor_maximo}}" (keydown)="enfocarCalificacion($event,i,score.orden)" (change)="validarValorCalificacion(score,data.valor_maximo)" [(ngModel)]="score.valor"/>
                                </td>
                                <td [ngClass]="{'bgc-primary-l2':data.configuracion.orden==1,'bgc-secondary-l2':data.configuracion.orden==2,'bgc-success-l2':data.configuracion.orden==3,'bgc-danger-l2':data.configuracion.orden==4,'bgc-warning-l2':data.configuracion.orden==5,'bgc-purple-l2':data.configuracion.orden==6}" ng-class="{'bad-score': verificarCalificacionReprobada(calcularPromedioDimension(estudiante.inscripciones_colegio[0].calificaciones,data.dimension.nombre),data.dimension.nombre)}">
                                    {{calcularPromedioDimension(estudiante.inscripciones_colegio[0].calificaciones,data.dimension.nombre)}}
                                </td>
                                <td [ngClass]="{'bgc-primary-l2':data.configuracion.orden==1,'bgc-secondary-l2':data.configuracion.orden==2,'bgc-success-l2':data.configuracion.orden==3,'bgc-danger-l2':data.configuracion.orden==4,'bgc-warning-l2':data.configuracion.orden==5,'bgc-purple-l2':data.configuracion.orden==6}">
                                    {{calcularPorcentajePromedioDimension(estudiante.inscripciones_colegio[0].calificaciones,data.dimension.nombre_corto,data.dimension.nombre)}}
                                </td>
                            </ng-container>
                            
                            <td>
                                <span [ngClass]="{'bgc-h-danger-l3 brc-danger-m1': sumarCalificaciones(estudiante.inscripciones_colegio[0].calificaciones,estudiante.inscripciones_colegio[0].calificaciones[obtenerIndiceFinalCalificacion(estudiante.inscripciones_colegio[0].calificaciones)])<=50}" class="badge bgc-white border-2 text-dark-tp3 badge-pill badge-lg pb-4 px-3">{{sumarCalificaciones(estudiante.inscripciones_colegio[0].calificaciones,estudiante.inscripciones_colegio[0].calificaciones[obtenerIndiceFinalCalificacion(estudiante.inscripciones_colegio[0].calificaciones)])}}</span>
                                <input type="hidden" ng-model="estudiante.inscripciones_colegio[0].calificaciones[getIndexFinalScore(estudiante.inscripciones_colegio[0].calificaciones)].value" ng-update-hidden/>
                            </td>
                            <td>
                                <span [ngClass]="{'bgc-h-danger-l3 brc-danger-m1': sumarCalificaciones(estudiante.inscripciones_colegio[0].calificaciones,estudiante.inscripciones_colegio[0].calificaciones[obtenerIndiceFinalCalificacion(estudiante.inscripciones_colegio[0].calificaciones)])<=50}" class="badge bgc-white border-2 text-dark-tp3 badge-pill badge-lg pb-4 px-3">{{obtenerLiteralCalificacion(sumarCalificaciones(estudiante.inscripciones_colegio[0].calificaciones,estudiante.inscripciones_colegio[0].calificaciones[obtenerIndiceFinalCalificacion(estudiante.inscripciones_colegio[0].calificaciones)]))}}</span>
                            </td>
                            <td>
                                <span [ngClass]="{'bgc-h-danger-l3 brc-danger-m1': calcularPromedioAnual(estudiante.id)<=50}" class="badge bgc-white border-2 text-dark-tp3 badge-pill badge-lg pb-4 px-3">{{calcularPromedioAnual(estudiante.id)}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #descripcion_cabecera let-modal>
    <div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Cabecera Registro</h4>
		</div>
		<div class="col-md-6 col-xs-6">
			<button class="btn btn-primary" type="button" [disabled]="!cabecera_registro.descripcion" (click)="guardarCabeceraRegistro()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
					<div class="row">
                        <div class="col-12">
                            <label>Descripción</label>
                            <input class="form-control" [(ngModel)]="cabecera_registro.descripcion" type="text" placeholder="descripcion" />
                        </div>
                    </div>
				</div>
			</div>
		</div>
    </div>
</ng-template>

<ng-template #configuracion_planilla_modal let-modal>
	<div class="modal-header">
		<div class="col-md-6 col-xs-6">
			<h4 class="widget-title">Configuración Planilla Calificaciones</h4>
		</div>
		<div class="col-md-6 col-xs-6">
      <button class="btn btn-primary" type="button" (click)="guardarConfiguracionPlanilla()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
        <div class="row">
            <div class="col-3">
                <label>Tipo de Cálculo Calificación</label>
            </div>
            <div class="col-9">
              <select [compareWith]="compararObjectos" class="form-control" name="tipo_calculo" [(ngModel)]="filter.registro_profesor.tipo_calculo">
                <option [ngValue]="tipo_calculo" *ngFor="let tipo_calculo of tipo_calculo_calificacion.clases" >{{tipo_calculo.nombre}}</option>
              </select>
            </div>
          </div>
          <hr>
        <ng-container *ngFor="let configuracion of filter.registro_profesor.configuracion_total | orderBy:'configuracion.orden' | filterBy:{id_intervalo_escolar:filter.intervalo_escolar.id}">
            <div class="row">
                <div class="col-3">
                    <label>{{configuracion.dimension.nombre}}</label>
                </div>
                <div class="col-9">
                  <select class="form-control" [(ngModel)]="configuracion.configuracion.numero_casillas">
                    <option [ngValue]=1>1</option>
                    <option [ngValue]=2>2</option>
                    <option [ngValue]=3>3</option>
                    <option [ngValue]="4">4</option>
                    <option [ngValue]="5">5</option>
                    <option [ngValue]="6">6</option>
                    <option [ngValue]="7">7</option>
                    <option [ngValue]="8">8</option>
                    <option [ngValue]="9">9</option>
                    <option [ngValue]="10">10</option>
                  </select>
                </div>
            </div>
            <hr>
        </ng-container>
	</div>
</ng-template>