import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { TareasService } from '../../services/tareas/tareas.service';
import { ProfesoresService } from '../../services/profesores/profesores.service';
import { ToastrService } from 'ngx-toastr';
import { Util } from 'src/app/utils/utils';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { GlobalVariable } from 'src/app/global';

declare const resaltarPestañaMenu:any;
declare const aplicarEditor:any;
declare const aplicarSubidaArchivo:any;

@Component({
  selector: 'app-tareas-profesor',
  templateUrl: './tareas-profesor.component.html',
  styleUrls: ['./tareas-profesor.component.css']
})
export class TareasProfesorComponent extends BaseComponent implements OnInit {

  tareas:any[]=[];
  tarea:any={};

  registro_tarea_modal:NgbModalRef;
  @ViewChild('registro_tarea_modal')
  private registro_tarea_modal_ref: TemplateRef<any>;

  vista_tarea_modal:NgbModalRef;
  @ViewChild('vista_tarea_modal')
  private vista_tarea_modal_ref: TemplateRef<any>;

  registros_profesor:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    public tareasService:TareasService,
    public profesoresService:ProfesoresService,
    private toastr: ToastrService,
	private estudiantesService:EstudiantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    this.configuracion_pagina={};
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_TAREAS);
		this.filter={
			id_empresa:this.usuario.id_empresa,
      id_persona:this.usuario.id_persona,
      registros_profesor:[{id:0,curso:{nombre:"TODOS"},materia:{nombre:"TODOS"}}],
			registro_profesor: {id:0,curso:{nombre:"TODOS"},materia:{nombre:"TODOS"}}
		}
    this.obtenerRegistrosProfesor();
		this.obtenerTareas();
    
  }

  ngAfterViewInit(){
    resaltarPestañaMenu(this.router.url.substring(1)+"1");
  }

  obtenerRegistrosProfesor(){
	  let gestion_actual_anio=new Date().getFullYear().toString();
	  this.profesoresService.obtenerGestionActual(this.usuario.id_empresa,gestion_actual_anio).subscribe((gestion_actual:any)=>{
		  if(gestion_actual){
			this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,gestion_actual.id).subscribe((registros_profesor:any[])=>{
				this.filter.registros_profesor=this.filter.registros_profesor.concat(registros_profesor);
				this.registros_profesor=registros_profesor;
			});
		  }
	  });
  }

  obtenerTareas(){
		this.column = "createdAt";
    this.direction = "DESC";
		this.getSearch(this.text_search,null);
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.tareasService.obtenerListaTareas(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.tareas=dato.tareas;
			this.blockUI.stop();
		});
	}

  abrirRegistroTarea(){
		this.registro_tarea_modal = this.modalService.open(this.registro_tarea_modal_ref, {windowClass:'edicion-tarea', scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.registro_tarea_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
	}

	crearNuevaTarea(){
		this.tarea={
      archivos:[],
	  id_empresa:this.usuario.id_empresa
    };
		this.abrirRegistroTarea();
    aplicarEditor(this);
	aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:10});
	}

  establecerDescripcion(dato){
    this.tarea.descripcion=dato;
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.tarea.archivos.push(base64EncodedImage);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.tarea.archivos.splice(me.tarea.archivos.indexOf(base64EncodedImage),1);
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  validarFormularioRegistroTarea(){
		let res=true;
		if(!this.tarea.registro_profesor){
			this.toastr.error("Debe ingresar el curso y materia!");
			res=false;
		}
    if(!this.tarea.nombre){
			this.toastr.error("Debe ingresar el nombre de la tarea!");
			res=false;
		}
    if(!this.tarea.descripcion){
			this.toastr.error("Debe ingresar la descripcion de la tarea!");
			res=false;
		}
		if(!this.tarea.fecha_entrega_texto || !this.tarea.fecha_entrega_texto.year || !this.tarea.fecha_entrega_texto.month || !this.tarea.fecha_entrega_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de entrega completa para la tarea!");
    }
		return res;
	}

	guardarTarea(){
		if(this.validarFormularioRegistroTarea()){
			this.blockUI.start();
      this.tarea.fecha_entrega=Util.convertirObjetoAfecha(this.tarea.fecha_entrega_texto);
			if(this.tarea.id){
				this.tareasService.actualizarTarea(this.tarea).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Tarea registrada satisfactoriamente");
          this.getItems();
					this.registro_tarea_modal.close();
				});
			}else{
				this.tareasService.guardarTarea(this.tarea).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Tarea registrada satisfactoriamente");
          this.getItems();
					this.registro_tarea_modal.close();
				});
			}
		}
		
	}

	abrirPopupConfirmacionEnvioCorreo(tarea){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de enviar la tarea a los estudiantes por correo electrónico?";
		this.popupConfirmacion.componentInstance.data = tarea;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.enviarCorreoElectronico($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	async enviarCorreoElectronico(tarea){
		this.blockUI.start();
		let gestion_actual_anio=new Date().getFullYear().toString();
		this.profesoresService.obtenerGestionActual(this.usuario.id_empresa,gestion_actual_anio).subscribe((gestion_actual:any)=>{
			if(gestion_actual){
				this.estudiantesService.obtenerListaEstudiantes(gestion_actual.id,tarea.registro_profesor.id_curso).subscribe((estudiantes:any[])=>{
					if(estudiantes.length>0){
						this.tareasService.enviarCorreoTarea({tarea:tarea,estudiantes:estudiantes}).subscribe((res:any)=>{
							if(res.tiene_error){
								this.toastr.error(res.mensaje);
							}else{
								this.toastr.success(res.mensaje);
							}
							this.blockUI.stop();
						});
					}else{
						this.blockUI.stop();
						this.toastr.error("Ningun estudiante inscrito en el curso");
					}
				});	
			}
		});
	}

	abrirPopupConfirmacionEliminacion(tarea){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar la tarea?";
		this.popupConfirmacion.componentInstance.data = tarea;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarTarea($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	eliminarTarea(tarea){
		this.blockUI.start();
		this.tareasService.eliminarTarea(tarea).subscribe((res:any) => {
			this.toastr.info(res.mensaje);
			this.blockUI.stop();
			this.getItems();
		});
	}

	verTarea(tarea){
		this.blockUI.start();
		this.tareasService.obtenerTarea(tarea.id).subscribe((res:any)=>{
			this.tarea=res;
			this.blockUI.stop();
			this.vista_tarea_modal = this.modalService.open(this.vista_tarea_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
			this.vista_tarea_modal.result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				});
			});
	}

}
