import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class GeceService {

  constructor(private http: HttpClient) { }

  public guardarEstadoCarpeta(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"gece/guardar-estado-carpeta",datos,GlobalVariable.httpOptions);
  }
}
