import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../../base/services/general/general.service';
import { InventariosService } from '../../../services/inventarios/inventarios.service';
import { PdfService } from '../../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../../base/services/usuario/usuario.service';
import { ProductosService } from 'src/app/snapquick/services/productos/productos.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-inventario-minimo',
  templateUrl: './inventario-minimo.component.html',
  styleUrls: ['./inventario-minimo.component.css']
})
export class InventarioMinimoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  usuario:any;
  producto:any={};
  productos_inventario_minimo:any[]=[];
  productos_inventario_minimo_filtrados:any[]=[];
  filtro_inventario: any ={};

  constructor(
    public persistenciaService:PersistenciaService,
    public inventariosService:InventariosService,
    public generalService:GeneralService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public pdfService:PdfService,
    public usuarioService:UsuarioService,
    public productosService:ProductosService
  ) {
    super(persistenciaService,modalService,generalService,usuarioService)
   }

  ngOnInit(): void {
    this.obtenerListaProductosInventarioMinimo();
  }

  obtenerListaProductosInventarioMinimo(){
    this.blockUI.start();
    this.inventariosService.obtenerListaProductosInventarioMinimo(this.usuario.id_empresa).subscribe((datos:any)=>{
      this.productos_inventario_minimo = datos.datos;
      this.productos_inventario_minimo_filtrados = this.productos_inventario_minimo;
      for(let i=0;i<this.productos_inventario_minimo.length;i++){
				this.productos_inventario_minimo[i].activar_inventario=this.productos_inventario_minimo[i].activar_inventario==1?true:false;
        this.productos_inventario_minimo_filtrados[i].activar_inventario=this.productos_inventario_minimo_filtrados[i].activar_inventario==1?true:false;
			}
      this.blockUI.stop();
    });
  }

  filtrarProductos(texto: string) {
		let texto_minus = texto.toLowerCase();
		if(texto === '' ) {
			this.productos_inventario_minimo_filtrados=this.productos_inventario_minimo;
		} else {
		  this.productos_inventario_minimo_filtrados = this.productos_inventario_minimo.filter((producto) => ((producto.nombre).toLowerCase().includes(texto_minus)));
		}
   }

   imprimirProductosInventarioMinimo(){
    this.blockUI.start();
    let productos_inventario_minimo=this.productos_inventario_minimo_filtrados;

    var doc = new PDFDocument({ size: 'letter', margin: 10 });
    var stream = doc.pipe(blobStream());
    var fecha_reporte=new Date();

    var y = 150, itemsPorPagina = 30, items = 0, pagina = 1, totalPaginas = Math.ceil(productos_inventario_minimo.length / itemsPorPagina);
    this.dibujarCabeceraPDFInventarioMinimo(doc);
    this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"INVENTARIO MINIMO",pagina,totalPaginas,this.usuario,
    this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,this.usuario.empresa.telefono1,
    "",GlobalVariable.Dictionary.PAPEL_CARTA,fecha_reporte,{imprimir_usuario:true});
    for (var i = 0; i < productos_inventario_minimo.length && items <= itemsPorPagina; i++) {
      doc.rect(50,y-15,540,20).stroke();
      //doc.rect(30, y - 10, 555, 20).stroke();
      doc.font('Helvetica', 7);
      doc.text(i+1,55,y-7, { width: 140 });
      doc.text(productos_inventario_minimo[i].codigo, 100, y - 6, { width: 140 });
      doc.text(productos_inventario_minimo[i].nombre, 230, y - 6, { width: 140 });
      doc.text(productos_inventario_minimo[i].total_inventario?productos_inventario_minimo[i].total_inventario:0, 400, y - 6, { width: 140 });
      doc.text(productos_inventario_minimo[i].inventario_minimo, 463, y - 6, { width: 140 });
      doc.text(productos_inventario_minimo[i].costo_unitario, 525, y - 6, { width: 140 });
      y = y + 20;
      items++;

      if (items == itemsPorPagina) {
        doc.addPage({ margin: 0, bufferPages: true });
        y = 150;
        items = 0;
        pagina = pagina + 1;

        this.dibujarCabeceraPDFInventarioMinimo(doc);
        this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"INVENTARIO MINIMO",pagina,totalPaginas,this.usuario,
        this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,this.usuario.empresa.telefono1,
      "",GlobalVariable.Dictionary.PAPEL_CARTA,fecha_reporte,{imprimir_usuario:true});
        doc.font('Helvetica', 7);
      }
    }
    doc.end();
    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL, '_blank', 'location=no');
    });
    this.blockUI.stop();
    
    
  }

  dibujarCabeceraPDFInventarioMinimo (doc) {
    doc.font('Helvetica-Bold', 8);
    doc.text("Nº", 55, 120);
    doc.text("Código", 100, 120, { width: 50 });
    doc.text("Producto", 250, 120, { width: 60 });
    doc.text("Inventario Actual", 400, 117, { width: 50 });
    doc.text("Inventario Minimo", 463, 117, { width: 50 });
    doc.text("Costo Unitario", 525, 117, { width: 50 });
    doc.roundedRect(50,110,540,25,5).fillOpacity(0.8).stroke();
  }

  cerrarComponente(){
    this.alTerminar.emit();
   }

   abrirPopupConfirmacionEliminacion(producto){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el producto?";
		this.popupConfirmacion.componentInstance.data = producto;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarProducto($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

  eliminarProducto(producto){
		this.blockUI.start();
		this.productosService.eliminarProducto(producto).subscribe((dato:any) => {
			this.obtenerListaProductosInventarioMinimo();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

  actualizarAtributoProducto(producto,atributo,valor){
		this.blockUI.start();
		this.productosService.actualizarAtributoProducto({
			atributo:atributo,
			valor:valor,
      producto:producto
		}).subscribe((res:any) => {
			this.obtenerListaProductosInventarioMinimo();
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}

}
