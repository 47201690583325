import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class ViajesService {

  constructor(private http: HttpClient) { }

  public obtenerListaViajes(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"viajes/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public guardarViaje(viaje){
    return this.http.post(GlobalVariable.API_SERVER_URL+"viajes",viaje,GlobalVariable.httpOptions);
  }

  public actualizarViaje(viaje){
    return this.http.put(GlobalVariable.API_SERVER_URL+"viajes/"+viaje.id,viaje,GlobalVariable.httpOptions);
  }

  public obtenerViaje(id_ruta){
    return this.http.get(GlobalVariable.API_SERVER_URL+"viajes/"+id_ruta,GlobalVariable.httpOptions);
  }

  public eliminarViaje(viaje){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"viajes/"+viaje.id,GlobalVariable.httpOptions);
  }

  public obtenerRutasEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rutas-empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerVehiculosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vehiculos-empresa/"+id_empresa,GlobalVariable.httpOptions);
  }
}
