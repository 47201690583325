import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { CompraComponent } from '../compra/compra.component';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { ListaPagosComprasComponent } from '../lista-pagos-compras/lista-pagos-compras.component';
import { PagoCompraComponent } from '../pago-compra/pago-compra.component';
import { VistaCompraComponent } from '../vista-compra/vista-compra.component';
import { ConfiguracionAplicacionComprasComponent } from 'src/app/base/components/configuracion-aplicacion-compras/configuracion-aplicacion-compras.component';
import { CajasService } from '../../services/cajas/cajas.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.css']
})
export class ComprasComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  compras:any[]=[];
  compra:any;
  modal_pago_compra:NgbModalRef;

  devolucion_venta:any;
  global_variable=GlobalVariable;
  popupConfirmacion:NgbModalRef;
  modalCompra:NgbModalRef;

  compras_borrador:any[]=[];
  compras_borrador_modal:NgbModalRef;
  @ViewChild('compras_borrador_modal')
  private compras_borrador_ref: TemplateRef<any>;

  configuracion_aplicacion_modal:NgbModalRef;

  datos_con_detalle:boolean=false;
  importacion:any={};
  mensaje_importacion:any;
  ventas_importacion:any[]=[];

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
	public pdfService:PdfService,
    public modalService: NgbModal,
    public comprasService:ComprasService,
	private toastr: ToastrService,
  public cajasService:CajasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

   async ngOnInit(): Promise<void> {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      tipos_pago:[{nombre:"TODOS",id:0}],
      tipo_pago: {nombre:"TODOS",id:0},
      tipos_documentos:[{nombre:"TODOS",id:0}],
      tipo_documento: {nombre:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      proveedor:{},
      con_detalle:false
    }
    await this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_COMPRAS);

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
        this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }
    
    this.obtenerTiposDePago();
    this.obtenerTiposDocumento();
    
    this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
  }

  ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerTiposDePago(){
    this.generalService.obtenerClases("TIPA").subscribe((entidad:any) => {
      this.filter.tipos_pago=this.filter.tipos_pago.concat(entidad.clases);
    });
  }	

  obtenerTiposDocumento(){
    this.generalService.obtenerClases("TIPDOC").subscribe((entidad:any) => {
      this.filter.tipos_documentos=this.filter.tipos_documentos.concat(entidad.clases);
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.comprasService.obtenerListaCompras(this).subscribe((dato:any) => {
			this.setPages(1);
      if(this.filter.con_detalle){
				this.compras=this.procesarCompras(dato.compras);
			}else{
				this.compras=dato.compras;
			}
			this.blockUI.stop();
		});
  }

  procesarCompras(compras){
		let compras_procesadas=[];
		//this.items_resumen=[];
		//var id_clientes=$filter('groupBy')(clientes,'id_cliente');

		let id_compras = compras.filter((compra, i, arr) => arr.findIndex(t => t.id === compra.id) === i);

		for(let i=0;i<id_compras.length;i++){
			let items=compras.filter(e => e.id == id_compras[i].id);
			let compra=items[0];
			compras_procesadas.push(compra);
		}

		for(var i=0;i<compras_procesadas.length;i++){
			compras_procesadas[i].detallesCompra=compras.filter(v => v.id==compras_procesadas[i].id);
		}
		return compras_procesadas;
	}

  sumarMonto(compras){
		let suma=0;
		for(let i=0;i<compras.length;i++){
			if(compras[i].activa){
				suma=suma+compras[i].total;
			}
		}
		return Math.round(suma*100)/100;
  }

  crearPagoCompra(compra){
		this.modal_pago_compra = this.modalService.open(PagoCompraComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_pago_compra.componentInstance.compra = compra;
		this.modal_pago_compra.componentInstance.usuario = this.usuario;
		this.modal_pago_compra.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.getItems();
					this.modal_pago_compra.close();
				}
			}else{
				this.modal_pago_compra.close();
			}
			
		});
  }

  sumarAcuenta(){
		var suma=0;
		for(var i=0;i<this.compras.length;i++){
			if(this.compras[i].activa){
				suma=suma+(this.compras[i].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE?this.compras[i].a_cuenta:this.compras[i].total);
			}
		}
		return Math.round(suma*100)/100;
  }

  sumarSaldo(){
	var suma=0;
	for(var i=0;i<this.compras.length;i++){
		if(this.compras[i].activa){
			suma=suma+(this.compras[i].tipoPago.nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE?this.compras[i].saldo:0);
		}
	}
	return Math.round(suma*100)/100;
  }

  abrirPopupConfirmacionEliminacion(compra){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la compra?";
		this.popupConfirmacion.componentInstance.data = compra;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCompra($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }
  
  eliminarCompra(compra){
    this.blockUI.start();
    this.comprasService.anularCompra(compra).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);  
      }else{
        this.toastr.success(res.mensaje);
        this.getItems();
      }
      this.blockUI.stop();
    });
  }
  
  imprimirCompra(compra){
    this.blockUI.start();
    this.comprasService.obtenerCompra(compra.id).subscribe((compra:any)=>{
      
			//compra.fecha=new Date(compra.fecha);
			let fecha =new Date(compra.fecha);
			compra.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
      this.pdfService.imprimirNotaCompra(compra,this.configuracion_pagina.getOrden().impresion,this.usuario);
      this.blockUI.stop();
    });
  }
  
  crearNuevaCompra(es_almacen){
    this.modalCompra = this.modalService.open(CompraComponent, {windowClass:'compra-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.modalCompra.componentInstance.compra.usuario = this.usuario;
    this.modalCompra.componentInstance.usuario = this.usuario;
    this.modalCompra.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
    this.modalCompra.componentInstance.es_almacen = es_almacen;
		this.modalCompra.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		this.modalCompra.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;
		
		this.modalCompra.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.imprimirCompra(res.compra);
          if(this.usuario.empresa.usar_caja_general && res.compra.id_movimiento_caja){
            this.imprimirMovimientoCaja(res.compra.id_movimiento_caja);
          }
					this.modalCompra.close();
				}
			}else{
				this.modalCompra.close();
			}
			
		});

  }

  async imprimirMovimientoCaja(id_movimiento_caja){
		let movimiento_caja=await this.cajasService.obtenerMovimientoCaja(id_movimiento_caja).toPromise();
		this.blockUI.start();
		this.pdfService.imprimirMovimientoCaja(movimiento_caja);
		this.blockUI.stop();
	}

  copiarCompra(compra){
    this.blockUI.start();
    this.comprasService.obtenerCompra(compra.id).subscribe((compraConsultada:any)=>{
      this.modalCompra = this.modalService.open(CompraComponent, {windowClass:'compra-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.modalCompra.componentInstance.compra = compraConsultada;
      this.modalCompra.componentInstance.compra.usuario = this.usuario;
      this.modalCompra.componentInstance.usuario = this.usuario;
      this.modalCompra.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
      this.modalCompra.componentInstance.es_almacen = compraConsultada.detallesCompra[0].centroCosto.nombre_corto=="CEALM";
      this.modalCompra.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
      this.modalCompra.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;
      this.modalCompra.componentInstance.nombre_corto_tipo_documento=compraConsultada.tipo_documento.nombre_corto;
      this.modalCompra.componentInstance.nombre_corto_tipo_pago=compraConsultada.tipoPago.nombre_corto;
      this.blockUI.stop();
      this.modalCompra.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.toastr.success(res.mensaje);
            this.imprimirCompra(res.compra);
            this.modalCompra.close();
          }
        }else{
          this.modalCompra.close();
        }
        
      });
    });
  }
  
  verComprasBorrador(){
		this.compras_borrador=this.persistenciaService.get("compras")?this.persistenciaService.get("compras"):[];
		this.compras_borrador_modal=this.modalService.open(this.compras_borrador_ref, {windowClass:"compras-borrador",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.compras_borrador_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	abrirPopupConfirmacionEliminacionBorrador(compra){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el borrador de la compra?";
		this.popupConfirmacion.componentInstance.data = compra;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarCompraBorrador($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

	  eliminarCompraBorrador(compraBorrador){
      this.blockUI.start();
      this.compras_borrador.splice(this.compras_borrador.indexOf(compraBorrador),1);
      this.persistenciaService.set("compras",this.compras_borrador);
      this.toastr.info("Compra borrador eliminada satisfactoriamente!");
      this.blockUI.stop();
    }
    
    modificarCompraBorrador(compra_borrador){
      this.blockUI.start();
      this.compras_borrador_modal.close();
      this.modalCompra = this.modalService.open(CompraComponent, {windowClass:'compra-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.modalCompra.componentInstance.compra = compra_borrador;
      this.modalCompra.componentInstance.compra.usuario = this.usuario;
      this.modalCompra.componentInstance.usuario = this.usuario;
      this.modalCompra.componentInstance.es_almacen = compra_borrador.detallesCompra[0].centroCosto.nombre_corto=="CEALM";
      this.modalCompra.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
      this.modalCompra.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
      this.modalCompra.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;
      this.modalCompra.componentInstance.nombre_corto_tipo_documento=compra_borrador.tipo_documento.nombre_corto;
      this.modalCompra.componentInstance.nombre_corto_tipo_pago=compra_borrador.tipoPago.nombre_corto;
      this.blockUI.stop();
      this.modalCompra.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.toastr.success(res.mensaje);
            this.imprimirCompra(res.compra);
            this.modalCompra.close();
          }
        }else{
          this.modalCompra.close();
        }
        
      });
    }

    verCompra(compra){
      this.modalCompra = this.modalService.open(VistaCompraComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-compra', backdrop: 'static'});
      this.modalCompra.componentInstance.compra = compra;
      this.modalCompra.componentInstance.usuario = this.usuario;
      this.modalCompra.componentInstance.alTerminar.subscribe((res) => {
        this.modalCompra.close();
      });
    }

    abrirConfiguracionAplicacion(){
      this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionComprasComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
      this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
      this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
      this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
        this.configuracion_aplicacion_modal.close();
      });
    }

    verPagosCompra(compra){
      this.modalCompra = this.modalService.open(ListaPagosComprasComponent, {windowClass:'lista-pagos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
      this.modalCompra.componentInstance.compra = compra?compra:null;
      this.modalCompra.componentInstance.usuario = this.usuario;
      this.modalCompra.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            this.toastr.success(res.mensaje);
            this.modalCompra.close();
          }
        }else{
          this.modalCompra.close();
        }
      });
    }

    async generarExcelFiltroCompras(){
      this.blockUI.start();
      let compras=this.compras;
      if(this.datos_con_detalle){
        this.filter.con_detalle=true;
        let dato:any=await this.comprasService.obtenerListaCompras(this).toPromise();
        this.filter.con_detalle=false;
        compras=this.procesarCompras(dato.compras);
      }

      var suma_importe=0,suma_total=0,suma_descuento=0;
      var data = [];
      var header=["N°"];
      if(this.configuracion_pagina.getConfiguration().tipo_documento.show){
        header.push("Tipo de Documento");
      }
      if(this.configuracion_pagina.getConfiguration().numero_documento.show){
        header.push("Número Doc.");
      }
      if(this.configuracion_pagina.getConfiguration().sucursal.show){
        header.push("Sucursal");
      }
      if(this.configuracion_pagina.getConfiguration().razon_social.show){
        header.push("Proveedor");
      }
      if(this.configuracion_pagina.getConfiguration().nit.show){
        header.push("Nit");
      }
      if(this.configuracion_pagina.getConfiguration().fecha_documento.show){
        header.push("Fecha Doc.");
      }
      if(this.configuracion_pagina.getConfiguration().hora_fecha.show){
        header.push("Hora Fecha Registro");
      }
      if(this.configuracion_pagina.getConfiguration().importe.show){
        header.push("Importe");
      }
      if(this.configuracion_pagina.getConfiguration().descuento.show){
        header.push("Descuento");
      }
      if(this.configuracion_pagina.getConfiguration().total.show){
        header.push("Total");
      }
      if(this.configuracion_pagina.getConfiguration().pagado.show){
        header.push("Pagado");
      }
      if(this.configuracion_pagina.getConfiguration().saldo.show){
        header.push("Saldo");
      }
      if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
        header.push("Tipo de Pago");
      }
      if(this.usuario.empresa.usar_caja_general && this.configuracion_pagina.getConfiguration().caja.show){
        header.push("Caja");
      }
      if(this.configuracion_pagina.getConfiguration().usuario.show){
        header.push("Usuario");
      }
      if(this.configuracion_pagina.getConfiguration().activa.show){
        header.push("Validez");
      }
  
      data.push(header);
      /*var sumaImporte=0,sumaImporteNo=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;*/
      for(var i=0;i<compras.length;i++){
        var columns=[];
        columns.push(i+1);
  
        if(this.configuracion_pagina.getConfiguration().tipo_documento.show){
          columns.push(compras[i].tipo_documento);
        }
        if(this.configuracion_pagina.getConfiguration().numero_documento.show){
          columns.push(compras[i].numero_documento);
        }
        if(this.configuracion_pagina.getConfiguration().sucursal.show){
          columns.push(compras[i].sucursal);
        }
        if(this.configuracion_pagina.getConfiguration().razon_social.show){
          columns.push(compras[i].proveedor);
        }
        if(this.configuracion_pagina.getConfiguration().nit.show){
          columns.push(compras[i].nit);
        }
        if(this.configuracion_pagina.getConfiguration().fecha_documento.show){
          compras[i].fecha=new Date(compras[i].fecha);
          columns.push(compras[i].fecha.getDate()+"/"+(compras[i].fecha.getMonth()+1)+"/"+compras[i].fecha.getFullYear());
        }
        if(this.configuracion_pagina.getConfiguration().hora_fecha.show){
          compras[i].createdAt=new Date(compras[i].createdAt);
          columns.push(compras[i].createdAt.getHours()+":"+compras[i].createdAt.getMinutes()+"-"+compras[i].createdAt.getDate()+"/"+(compras[i].createdAt.getMonth()+1)+"/"+compras[i].createdAt.getFullYear());
        }
        if(this.configuracion_pagina.getConfiguration().importe.show){
          columns.push(compras[i].importe);
        }
        if(this.configuracion_pagina.getConfiguration().descuento.show){
          columns.push(compras[i].descuento);
        }
        if(this.configuracion_pagina.getConfiguration().total.show){
          columns.push(compras[i].total);
        }
        if(this.configuracion_pagina.getConfiguration().pagado.show){
          columns.push(compras[i].a_cuenta);
        }
        if(this.configuracion_pagina.getConfiguration().saldo.show){
          columns.push(compras[i].saldo);
        }
        if(this.configuracion_pagina.getConfiguration().tipo_pago.show){
          columns.push(compras[i].tipo_pago);
        }
        if(this.usuario.empresa.usar_caja_general && this.configuracion_pagina.getConfiguration().caja.show){
          columns.push(compras[i].caja);
        }
        if(this.configuracion_pagina.getConfiguration().usuario.show){
          columns.push(compras[i].usuario);
        }
        if(this.configuracion_pagina.getConfiguration().activa.show){
          columns.push(compras[i].activa?"ACTIVO":"ANULADO");
        }
  
        if(compras[i].activa){
          suma_importe=suma_importe+compras[i].importe;
          suma_descuento=suma_descuento+compras[i].descuento;
          suma_total=suma_total+compras[i].total;
        }
  
        data.push(columns);

        if(this.datos_con_detalle){
          data.push(["","","","","N°","Nombre","Codigo Item","Unidad de Med","Cantidad","Importe","Descuento","Total"]);
          for(var j=0; j<compras[i].detallesCompra.length;j++){
            columns=[];
            columns.push("");
            columns.push("");
            columns.push("");
            columns.push("");
            columns.push(j+1);
            columns.push(compras[i].detallesCompra[j].producto?compras[i].detallesCompra[j].producto:compras[i].detallesCompra[j].descripcion?compras[i].detallesCompra[j].descripcion:"");
            columns.push(compras[i].detallesCompra[j].producto?compras[i].detallesCompra[j].codigo_producto:"");
            columns.push(compras[i].detallesCompra[j].producto?compras[i].detallesCompra[j].unidad_medida:"UND");
            columns.push(compras[i].detallesCompra[j].cantidad);
            columns.push(compras[i].detallesCompra[j].importe_detalle);
            columns.push(compras[i].detallesCompra[j].descuento_detalle);
            columns.push(compras[i].detallesCompra[j].total_detalle);
            
            data.push(columns);
            }
        }

        if(i+1==this.compras.length){
          columns=[];
          columns.push("");
          if(this.configuracion_pagina.getConfiguration().tipo_documento.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().numero_documento.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().sucursal.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().razon_social.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().nit.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().fecha_documento.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().hora_fecha.show){
            columns.push("");
          }
          if(this.configuracion_pagina.getConfiguration().importe.show){
            columns.push(suma_importe);
          }
          if(this.configuracion_pagina.getConfiguration().descuento.show){
            columns.push(suma_descuento);
          }
          if(this.configuracion_pagina.getConfiguration().total.show){
            columns.push(suma_total);
          }
        
          data.push(columns);
        }
        
      }
  
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("COMPRAS");
      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );
  
      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 15;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 10;
  
      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Reporte-compras.xlsx");
      })
      this.blockUI.stop();
    }

    sumarImporte(){
      var suma=0;
      for(var i=0;i<this.compras.length;i++){
        if(this.compras[i].activa){
          suma=suma+this.compras[i].importe;
        }
      }
      return Math.round(suma*100)/100;
    }
  
    sumarDescuento(){
      var suma=0;
      for(var i=0;i<this.compras.length;i++){
        if(this.compras[i].activa){
          suma=suma+this.compras[i].descuento;
        }
      }
      return Math.round(suma*100)/100;
    }
  
    sumarTotal(){
      var suma=0;
      for(var i=0;i<this.compras.length;i++){
        if(this.compras[i].activa){
          suma=suma+this.compras[i].total;
        }
      }
      return Math.round(suma*100)/100;
    }


}
