import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { ArchivosBancoService } from 'src/app/school/services/archivos-banco/archivos-banco.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import * as XLSX from 'xlsx';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { AsignacionPagoEstudianteComponent } from '../asignacion-pago-estudiante/asignacion-pago-estudiante.component';
import { FichaEconomicaEstudianteComponent } from '../ficha-economica-estudiante/ficha-economica-estudiante.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-archivos-banco',
  templateUrl: './archivos-banco.component.html',
  styleUrls: ['./archivos-banco.component.css']
})
export class ArchivosBancoComponent extends BaseComponent implements OnInit {

  gestiones:any[]=[];
  archivos_banco:any[]=[];
  archivo_banco:any={};
  detalle_archivo:any;

  searching = false;
  searchFailed = false;  

  busqueda_concepto_pago:any={
    estudiante_busqueda:{},
    gestion:null
  };
  asignacion_pago_estudiante_modal:NgbModalRef;
  ficha_economica_modal:NgbModalRef;

  registro_archivo_banco_modal:NgbModalRef;
  @ViewChild('registro_archivo_banco_modal')
  private registro_archivo_banco_modal_ref: TemplateRef<any>;

  asignacion_pago_concepto_estudiante_modal:NgbModalRef;
  @ViewChild('asignacion_pago_concepto_estudiante_modal')
  private asignacion_pago_concepto_estudiante_modal_ref: TemplateRef<any>;

  vista_archivo_banco_modal:NgbModalRef;
  @ViewChild('vista_archivo_banco_modal')
  private vista_archivo_banco_modal_ref: TemplateRef<any>;

  asignacion_registro_concepto_estudiante_modal:NgbModalRef;
  @ViewChild('asignacion_registro_concepto_estudiante_modal')
  private asignacion_registro_concepto_estudiante_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    private archivosBancoService:ArchivosBancoService,
    private estudiantesService:EstudiantesService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.init();
		this.buscarSubAplicacion(this.router.url.substring(1));
    let fecha_actual=new Date();
    this.filter={
      escuela:null,
			gestion:GlobalVariable.Dictionary.SELECCION_TODOS,
      gestiones:[GlobalVariable.Dictionary.SELECCION_TODOS],
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
		}
    await this.obtenerEscuela();
    await this.obtenerGestiones();
    this.column = "createdAt";
    this.direction = "desc";
		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
	}

  async obtenerGestiones(){
    let entidad:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
      if(this.usuario.empresa.usar_acceso_gestion_escolar){
        if(this.usuario.datos && this.usuario.datos.gestiones){
          this.filter.gestiones=entidad.clases.filter(gt => this.usuario.datos.gestiones.includes(gt.id));
          this.filter.gestion=this.filter.gestiones[0];
        }
      }else{
        this.filter.gestiones=this.filter.gestiones.concat(entidad.clases);
      }
      this.gestiones=entidad.clases.filter(gt => this.usuario.datos.gestiones.includes(gt.id));
  }

  crearNuevoArchivoBanco(){
    let fecha_actual=new Date();
    this.archivo_banco={
      id_empresa:this.usuario.id_empresa,
      id_escuela:this.filter.escuela.id,
      detalles_archivo:[],
      fecha_texto:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      }
    }
    this.abriRegistroArchivoBanco();
  }

  abriRegistroArchivoBanco(){
    this.registro_archivo_banco_modal=this.modalService.open(this.registro_archivo_banco_modal_ref, {scrollable:true, windowClass:'registro-archivo-banco', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.registro_archivo_banco_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  subirDetalleArchivoBanco(event){
    this.blockUI.start();
    let me=this;
	  let files = event.target.files;
	  let i,f;
      for (i = 0, f = files[i]; i != files.length; ++i) {
        var reader = new FileReader();
        var reader_d = new FileReader();
        var name = f.name;
        me.archivo_banco.nombre_archivo=name;
        reader.onload = function(e) {
          try{
            me.archivo_banco.detalles_archivo=[];
            var data = e.target.result;
            console.log(data);
            var filas=data.toString().split('\n');me.archivo_banco.total=0;
            for(let i=0;i<filas.length;i++){
              let datos=filas[i].split('|');console.log(datos[6]);
              if(datos.length==10){
                let pago={
                  codigo_colegio:datos[0],
                  codigo_sucursal_banco:datos[1],
                  nombre_estudiante:datos[2],
                  codigo_estudiante:datos[3],
                  concepto:datos[4],
                  importe:parseFloat(datos[5]),
                  fecha_pago:new Date(datos[6].toString().replace(" ","T")),
                  cuota_hasta:datos[7],
                  numero_factura:datos[8],
                  codigo_control_factura:datos[9],
                }
                me.archivo_banco.total=me.archivo_banco.total+pago.importe;
                me.archivo_banco.detalles_archivo.push(pago);
              }        
            }
            me.blockUI.stop();
          }catch(e){
            me.toastr.error(e);
            me.archivo_banco.con_error=true;
          }	
        };
        reader.readAsBinaryString(f);
  
        reader_d.onloadend = function() 
        {
          let fileBase64 = reader_d.result; // <--- data: base64
          me.archivo_banco.archivo=fileBase64;
        }
        reader_d.readAsDataURL(f);
      }
	}

  validarRegistroArchivoBanco(){
    let res=true;
    if(this.archivo_banco.con_error){
      res=res && false;
      this.toastr.error("Debe corregir el archivo!");
    }
    if(!this.archivo_banco.gestion){
      res=res && false;
      this.toastr.error("Debe especificar la gestion!");
    }
    if(!this.archivo_banco.fecha_texto || !this.archivo_banco.fecha_texto.year || !this.archivo_banco.fecha_texto.month || !this.archivo_banco.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para la venta!");
    }
    if(this.archivo_banco.detalles_archivo.length==0){
      res=res && false;
      this.toastr.error("Debe existir al menos un pago a importar!");
    }
    return res;
  }

  guardarArchivoBanco(){
    if(this.validarRegistroArchivoBanco()){
      this.blockUI.start();
      this.archivo_banco.fecha=Util.convertirObjetoAfecha(this.archivo_banco.fecha_texto);
      this.archivosBancoService.guardarArchivoBanco(this.archivo_banco).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.registro_archivo_banco_modal.close();
          this.getItems();
        }
      });
    }
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.archivosBancoService.obtenerListaArchivos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.archivos_banco=dato.archivos_banco;
			this.blockUI.stop();
		});
	}

  abrirPopupConfirmacionEliminacion(archivo_banco){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el archivo de banco?";
		this.popupConfirmacion.componentInstance.data = archivo_banco;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarArchivo($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  async eliminarArchivo(archivo_banco){
    this.blockUI.start();
    let archivo_banco_respuesta:any=await this.archivosBancoService.obtenerArchivoBancoRelacion(archivo_banco).toPromise();
    let detalles_consolidados=archivo_banco_respuesta.detalles_archivo.filter(dab => dab.consolidado);
    if(detalles_consolidados.length>0){
      this.toastr.error("No se puede eliminar el archivo porque existen detalles del archivo que ya estan consolidados!");
      this.blockUI.stop();
    }else{
      this.archivosBancoService.eliminarArchivoBanco(archivo_banco).subscribe((res:any)=>{
        this.blockUI.stop();
        this.toastr.success(res.mensaje);
        this.getItems();
      });
    }
  }

  async relacionarConceptosPagosEstudiantes(archivo_banco){
    this.blockUI.start();
    let archivo_banco_respuesta:any=await this.archivosBancoService.obtenerArchivoBancoRelacion(archivo_banco).toPromise();
    let codigos_estudiantes="";
    for(let i=0;i<archivo_banco_respuesta.detalles_archivo.length;i++){
      if(i+1<archivo_banco_respuesta.detalles_archivo.length){
        codigos_estudiantes=codigos_estudiantes+archivo_banco_respuesta.detalles_archivo[i].codigo_estudiante+",";
      }else{
        codigos_estudiantes=codigos_estudiantes+archivo_banco_respuesta.detalles_archivo[i].codigo_estudiante;
      }
    }
    let conceptos_pagos_estudiante:any=await this.archivosBancoService.obtenerConceptosPagoEstudiante({codigos_estudiante:codigos_estudiantes}).toPromise();
    for(let i=0;i<archivo_banco_respuesta.detalles_archivo.length;i++){
      if(!archivo_banco_respuesta.detalles_archivo[i].consolidado && archivo_banco_respuesta.detalles_archivo[i].detalles_venta.length==0){
        let conceptos_pago=archivo_banco_respuesta.detalles_archivo[i].concepto.split(","),cuotas=archivo_banco_respuesta.detalles_archivo[i].cuota_hasta.split(","),pagado_ingresado=archivo_banco_respuesta.detalles_archivo[i].importe/cuotas.length;
        for(let k=0;k<conceptos_pago.length;k++){
          for(let j=0;j<cuotas.length;j++){
            let concepto_pago_estudiante=conceptos_pagos_estudiante.filter(ce => ce.estudiante.codigo==archivo_banco_respuesta.detalles_archivo[i].codigo_estudiante && conceptos_pago[k].includes(ce.concepto_pago.concepto.nombre) && ce.cuota.toString()==cuotas[j].trim());
            if(concepto_pago_estudiante.length>0 && concepto_pago_estudiante[0].saldo>0){
              if(archivo_banco_respuesta.detalles_archivo[i].detalles_venta.filter( ce => ce.estudiante_concepto_pago && ce.estudiante_concepto_pago.id==concepto_pago_estudiante[0].id).length==0){
                concepto_pago_estudiante[0].pagado=pagado_ingresado;
                concepto_pago_estudiante[0].pagado_ingresado=pagado_ingresado;
                concepto_pago_estudiante[0].saldo=0;
                archivo_banco_respuesta.detalles_archivo[i].detalles_venta.push({estudiante_concepto_pago:concepto_pago_estudiante[0]});
              }
            }
          }
        }
      }
    }
    this.archivo_banco=archivo_banco_respuesta;
    this.blockUI.stop();
    this.asignacion_pago_concepto_estudiante_modal=this.modalService.open(this.asignacion_pago_concepto_estudiante_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.asignacion_pago_concepto_estudiante_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async establecerPagosConceptosEstudiante(detalle_archivo){
    if(this.validarEstablecimientoPagosConceptosEstudiante(detalle_archivo)){
      detalle_archivo.id_usuario=this.usuario.id;
      detalle_archivo.id_sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0].id;
      detalle_archivo.gestion=this.archivo_banco.gestion;
      this.blockUI.start();
      let res:any=await this.archivosBancoService.establecerPagosConceptosEstudiante(detalle_archivo).toPromise();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
        detalle_archivo.consolidado=true;
      }
      this.blockUI.stop();
    }
  }

  validarEstablecimientoPagosConceptosEstudiante(detalle_archivo){
    let res=true;
    if(detalle_archivo.detalles_venta.length==0){
      res=res && false;
      this.toastr.error("Debe agregar conceptos de pago en el detalle!");
    }
    if(detalle_archivo.importe!=this.sumarPagadoIngresado(detalle_archivo.detalles_venta)){
      res=res && false;
      this.toastr.error("El importe debe ser igual al valor de las cuotas de los conceptos!");
    }
    return res;
  }

  sumarImportesDetalleConceptosEstudiante(detalles_venta){
    let suma=0;
    for(let i=0;i<detalles_venta.length;i++){
      suma=suma+detalles_venta[i].estudiante_concepto_pago.pagado;
    }
    return Math.round(suma*100)/100;
  }

  sumarPagadoIngresado(detalles_venta){
    let suma=0;
    for(let i=0;i<detalles_venta.length;i++){
      suma=suma+detalles_venta[i].estudiante_concepto_pago.pagado_ingresado;
    }
    return Math.round(suma*100)/100;
  }

  consolidarArchivoBanco(){
    let detalles_no_consolidados=this.archivo_banco.detalles_archivo.filter(dab => !dab.consolidado);
    if(detalles_no_consolidados.length>0){
      this.toastr.error("Debe consolidar todos los detalles del archivo!");
    }else{
      this.blockUI.start();
      this.archivosBancoService.consolidarArchivoBanco(this.archivo_banco).subscribe((res:any)=>{
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.asignacion_pago_concepto_estudiante_modal.close();
          this.getItems();
        }
        this.blockUI.stop();
      });
    }
  }

  agregarEstudianteConceptoPago(detalle_archivo){
    this.detalle_archivo=detalle_archivo;
    this.reiniciarBusquedaCliente();
    this.asignacion_registro_concepto_estudiante_modal=this.modalService.open(this.asignacion_registro_concepto_estudiante_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.asignacion_registro_concepto_estudiante_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  buscarEstudiante = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.estudiantesService.buscarEstudiante(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x:any) => (x.id?(x.codigo+" - "+x.apellido_paterno+" "+x.apellido_materno+" "+x.nombres):"");

  async establecerEstudiante($event){
    this.busqueda_concepto_pago.estudiante_busqueda=$event.item;
    this.busqueda_concepto_pago.gestion=null;
  }

  reiniciarBusquedaCliente(){
    this.busqueda_concepto_pago.estudiante_busqueda={};
    this.busqueda_concepto_pago.gestion=null;
    this.busqueda_concepto_pago.cuota=null;
  }

  buscarPagosEstudiante(){
    this.blockUI.start();
    this.busqueda_concepto_pago.cuota=null;
    this.busqueda_concepto_pago.cuotas_estudiante=[];
    this.archivosBancoService.obtenerCuotasEstudiante(this.busqueda_concepto_pago.estudiante_busqueda.id,this.busqueda_concepto_pago.gestion.id).subscribe((res:any)=>{
      this.busqueda_concepto_pago.cuotas_estudiante=res.estudiante_conceptos_pago;
      this.blockUI.stop();
    });
  }

  agregarConceptoPagoEstudiante(){
    if(this.validarPagoEstudiante()){
      this.busqueda_concepto_pago.cuota.saldo=this.busqueda_concepto_pago.cuota.importe-this.busqueda_concepto_pago.cuota.descuento-this.busqueda_concepto_pago.cuota.pagado-this.busqueda_concepto_pago.cuota.pagado_ingresado;
      this.busqueda_concepto_pago.cuota.pagado=this.busqueda_concepto_pago.cuota.importe-this.busqueda_concepto_pago.cuota.descuento-this.busqueda_concepto_pago.cuota.saldo;
      this.detalle_archivo.detalles_venta.push({estudiante_concepto_pago:this.busqueda_concepto_pago.cuota});
      this.asignacion_registro_concepto_estudiante_modal.close();
    }
  }

  eliminarCuotaEstudiante(detalle_archivo,cuota){
    detalle_archivo.detalles_venta.splice(detalle_archivo.detalles_venta.indexOf(cuota),1);
  }

  calcularSaldo(){
    this.busqueda_concepto_pago.cuota.saldo=this.busqueda_concepto_pago.cuota.importe-this.busqueda_concepto_pago.cuota.descuento-this.busqueda_concepto_pago.cuota.pagado-this.busqueda_concepto_pago.cuota.pagado_ingresado;
  }

  validarPagoEstudiante(){
    let res=true;
    if(!this.busqueda_concepto_pago.cuota){
      res=res && false;
      this.toastr.error("Debe especificar la cuota!");
    }else{
      if(!this.busqueda_concepto_pago.cuota.pagado_ingresado){
        res=res && false;
        this.toastr.error("Debe especificar el campo A Cuenta!");
      }else{
        if(this.busqueda_concepto_pago.cuota.pagado_ingresado>this.busqueda_concepto_pago.cuota.importe){
          res=res && false;
          this.toastr.error("El pago no debe exceder al importe de la cuota!");
        }
        if(this.busqueda_concepto_pago.cuota.pagado_ingresado<=0){
          res=res && false;
          this.toastr.error("El pago debe ser mayor a cero!");
        }
      }
    }
    return res;
  }

  abrirAsignacionConceptoPagoEstudiante(){
    this.asignacion_pago_estudiante_modal = this.modalService.open(AsignacionPagoEstudianteComponent, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
		this.asignacion_pago_estudiante_modal.componentInstance.gestion = this.busqueda_concepto_pago.gestion;
    this.asignacion_pago_estudiante_modal.componentInstance.id_estudiante = this.busqueda_concepto_pago.estudiante_busqueda.id;
    this.asignacion_pago_estudiante_modal.componentInstance.usuario = this.usuario;
    this.asignacion_pago_estudiante_modal.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				this.buscarPagosEstudiante();
			}
			this.asignacion_pago_estudiante_modal.close();
    });
  }

  abrirFichaEconomica(){
    this.ficha_economica_modal = this.modalService.open(FichaEconomicaEstudianteComponent, {scrollable:true, windowClass:'ficha-economica', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_economica_modal.componentInstance.estudiante = this.busqueda_concepto_pago.estudiante_busqueda;
    this.ficha_economica_modal.componentInstance.usuario = this.usuario;
		
		this.ficha_economica_modal.componentInstance.onConfirm.subscribe(($e) => {
			this.buscarPagosEstudiante();
			this.ficha_economica_modal.close();
    });
  }

  async verArchivoBanco(archivo_banco){
    this.blockUI.start();
    let archivo_banco_respuesta:any=await this.archivosBancoService.obtenerArchivoBancoRelacion(archivo_banco).toPromise();
    this.archivo_banco=archivo_banco_respuesta;
    this.blockUI.stop();
    this.vista_archivo_banco_modal=this.modalService.open(this.vista_archivo_banco_modal_ref, {scrollable:true, ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
    this.vista_archivo_banco_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
