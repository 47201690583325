import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import * as XLSX from 'xlsx';
import { ComprobantesService } from '../../services/comprobantes/comprobantes.service';
import { BalanceSumasSaldosComponent } from '../balance-sumas-saldos/balance-sumas-saldos.component';
import { RegistroComprobanteComponent } from '../registro-comprobante/registro-comprobante.component';

declare const resaltarPestañaMenu:any;

@Component({
  selector: 'app-comprobantes',
  templateUrl: './comprobantes.component.html',
  styleUrls: ['./comprobantes.component.css']
})
export class ComprobantesComponent extends BaseComponent implements OnInit {

  comprobantes:any[]=[];
  comprobantes_importacion:any[]=[];
  comprobante:any;

  comprobantes_importacion_modal:NgbModalRef;
  @ViewChild('comprobantes_importacion_modal')
  private comprobantes_importacion_modal_ref: TemplateRef<any>;

  registro_comprobante_modal:NgbModalRef;
  balance_comprobacion_sumas_saldos_modal:NgbModalRef;

  vista_comprobante_modal:NgbModalRef;
  @ViewChild('vista_comprobante_modal')
  private vista_comprobante_modal_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
	  private toastr: ToastrService,
    private comprobanteService:ComprobantesService,
    private pdfService:PdfService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
    let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
			usuarios:[{nombre_usuario:"TODOS",id:0}],
			usuario: {nombre_usuario:"TODOS",id:0},
			tipos:[GlobalVariable.Dictionary.SELECCION_TODOS],
			tipo: GlobalVariable.Dictionary.SELECCION_TODOS,
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		}

		let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
		}

		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
		this.obtenerTiposComprobante();
		this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
	}

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerTiposComprobante () {
    this.blockUI.start();
    this.generalService.obtenerClases("TCMC").subscribe((entidad:any)=>{
      this.filter.tipos=this.filter.tipos.concat(entidad.clases);
      this.blockUI.stop();
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.comprobanteService.obtenerComprobantes(this).subscribe((comprobantes:any) => {
			this.setPages(1);
			this.comprobantes=comprobantes;
			this.blockUI.stop();
		});
  }

  descargarExcelEjemploImportacion(){
    Util.descargarDocumento("EJEMPLO-DATOS-COMPROBANTES",GlobalVariable.SERVER_URL+"recursos/EJEMPLO-DATOS-COMPROBANTES.xlsx");
  }

  subirExcelComprobantes(event){
    this.blockUI.start();
    let me=this;
    var files = event.target.files;
    var i, f;
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader();
      var name = f.name;
      reader.onload = function (e) {
        
        var data = e.target.result;
  
        var workbook = XLSX.read(data, { type: 'binary' });
        var first_sheet_name = workbook.SheetNames[0];
        var row = 2, i = 0;
        var worksheet = workbook.Sheets[first_sheet_name];
        let comprobantes = [];
        do {
          let comprobante:any={asientos:[]};
          comprobante.numero=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
          comprobante.codigo_sucursal=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
          comprobante.tipo_comprobante=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
          let fecha = null;
          if (worksheet['D' + row] != undefined && worksheet['D' + row] != "") {
            if (typeof worksheet['D' + row].v === 'number') {
              if (worksheet['D' + row].v % 1 === 0) {
                fecha = new Date((worksheet['D' + row].v - (25567 + 1)) * 86400 * 1000);
              }
            } else {
              fecha = new Date(Util.convertirTextoAfecha(worksheet['D' + row].v));
            }
          }
          comprobante.fecha = fecha;
          comprobante.glosa=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
          let asiento:any={};
          asiento.codigo_cuenta=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
          asiento.cuenta=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString():null;
          asiento.codigo_cuenta_auxiliar=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null;
          asiento.cuenta_auxiliar=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?worksheet['I'+row].v.toString():null;
          asiento.debe_bs=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?parseFloat(worksheet['J'+row].v.toString()):null;
          asiento.haber_bs=worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?parseFloat(worksheet['K'+row].v.toString()):null;
          asiento.glosa=worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?worksheet['L'+row].v.toString():null;
          comprobante.asientos.push(asiento);
          comprobantes.push(comprobante);
          row++;	
          i++;				
        } while (worksheet['A'+row]!=undefined);
          //$scope.guardarClientes(clientes);
          me.comprobantes_importacion=comprobantes;console.log(comprobantes);
          me.comprobantes_importacion_modal=me.modalService.open(me.comprobantes_importacion_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
          me.comprobantes_importacion_modal.result.then((result) => {
            me.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
          });
          me.blockUI.stop();
        };
        reader.readAsBinaryString(f);
      }
    }

    crearNuevoComprobante(){
      this.registro_comprobante_modal = this.modalService.open(RegistroComprobanteComponent, {windowClass:"comprobante", ariaLabelledBy: 'modal-basic-title',scrollable:true, backdrop: 'static',size:"lg"});
      this.registro_comprobante_modal.componentInstance.usuario = this.usuario;
      
      this.registro_comprobante_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            //this.establecerCliente(res.cliente);
            this.registro_comprobante_modal.close();
            this.getItems();
          }
        }else{
          this.registro_comprobante_modal.close();
        }
        
      });
    }

    modificarComprobante(comprobante){
      this.registro_comprobante_modal = this.modalService.open(RegistroComprobanteComponent, {windowClass:"comprobante", ariaLabelledBy: 'modal-basic-title',scrollable:true, backdrop: 'static',size:"lg"});
      this.registro_comprobante_modal.componentInstance.usuario = this.usuario;
      this.registro_comprobante_modal.componentInstance.comprobante = comprobante;
      
      this.registro_comprobante_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            //this.establecerCliente(res.cliente);
            this.registro_comprobante_modal.close();
            this.getItems();
          }
        }else{
          this.registro_comprobante_modal.close();
        }
        
      });
    }

    verComprobante(comprobante){
      this.comprobante=comprobante;
      this.comprobante.asientos=Util.construirAsientos(this.comprobante.asientos);
      this.vista_comprobante_modal=this.modalService.open(this.vista_comprobante_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.vista_comprobante_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    abrirPopupConfirmacionEliminacion(comprobante){
      this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
      this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular el comprobante?";
      this.popupConfirmacion.componentInstance.data = comprobante;
      
      this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
        if($e.confirm){
          this.eliminarComprobante($e.data);
        }
        this.popupConfirmacion.close();
          });
    }
    
    eliminarComprobante(comprobante){
      this.blockUI.start();
      this.comprobanteService.anularComprobante(comprobante).subscribe((res:any)=>{
        this.toastr.success(res.mensaje);
        this.getItems();
        this.blockUI.stop();
      });
    }

    abrirBalanceComprobacionSumasSaldos(){
      this.balance_comprobacion_sumas_saldos_modal = this.modalService.open(BalanceSumasSaldosComponent, {windowClass:"balance-sumas-saldos", ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"lg"});
      this.balance_comprobacion_sumas_saldos_modal.componentInstance.usuario = this.usuario;
      
      this.balance_comprobacion_sumas_saldos_modal.componentInstance.alTerminar.subscribe((res) => {
        if(res){
          if(res.tiene_error){
            this.toastr.error(res.mensaje);
            this.toastr.error(res.stack.substring(0,500));
          }else{
            //this.establecerCliente(res.cliente);
            this.balance_comprobacion_sumas_saldos_modal.close();
          }
        }else{
          this.balance_comprobacion_sumas_saldos_modal.close();
        }
        
      });
    }

    imprimirComprobante(comprobante){
      this.blockUI.start();
      this.comprobanteService.obtenerComprobante(comprobante.id).subscribe((res:any)=>{
        this.blockUI.stop();
        this.pdfService.imprimirComprobanteContabilidad(res.comprobante,false,this.usuario);
      })
    }

}
