<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-8 col-xs-8">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Presentación Filtros</legend>
        <div class="row">
            <div class="col-2">
                <label>Cliente</label>
            </div>
            <div class="col-4">
                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.presentacion_cliente">
                    <option value="campo_texto">Campo de texto</option>
                    <option value="lista">Lista</option>
                </select>
            </div>
            <div class="col-2">
                <label>Periodos</label>
            </div>
            <div class="col-4">
                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.presentacion_periodos">
                    <option value="fechas">Fechas</option>
                    <option value="meses">Meses</option>
                </select>
            </div>
        </div>
    </fieldset>
</div>