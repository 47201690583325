import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LecturaMedidorService } from '../../../neighborhood/services/lectura-medidor/lectura-medidor.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { OrderPipe } from 'ngx-order-pipe';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';

declare const $:any;

@Component({
  selector: 'app-vecino-lecturacion',
  templateUrl: './vecino-lecturacion.component.html',
  styleUrls: ['./vecino-lecturacion.component.css']
})
export class VecinoLecturacionComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  vecino:any;
  lecturas_medidor:any[]=[];
  lectura_medidor:any;
  usuario:any;

  @BlockUI() blockUI: NgBlockUI;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService: GeneralService,
    public lecturaMedidorService: LecturaMedidorService,
    private orderPipe: OrderPipe,
    public pdfService:PdfService,
    public ventasService:VentasService,
    public usuarioService:UsuarioService,
    private toastr: ToastrService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.filter={

    }
    this.blockUI.start();
    this.lecturaMedidorService.obtenerAccionesAguaVecino(this.vecino.id).subscribe((acciones_agua:any[]) => {
      this.filter.acciones_agua=acciones_agua;
      this.filter.accion_agua=this.filter.acciones_agua.length>0?this.filter.acciones_agua[0]:null;
      this.blockUI.stop();
    });

    this.obtenerGestiones();
  }

  obtenerGestiones(){
    this.blockUI.start();
    this.generalService.obtenerClases("GTN").subscribe((entidad:any) => {
      this.filter.gestiones=entidad.clases;
      let fecha_actual=new Date();
      this.filter.gestion=this.filter.gestiones.filter(e => e.nombre_corto.toString()==(fecha_actual.getFullYear()).toString())[0];
      this.blockUI.stop();
    });
  }

  buscarLecturasMedidor(){
    this.blockUI.start();
    this.lecturaMedidorService.obtenerRegistroLecturacionAccionAgua(this.filter.gestion.id,this.filter.accion_agua.id).subscribe((lecturas_medidor:any[]) => {
      this.lecturas_medidor=this.orderPipe.transform(lecturas_medidor,null,null,null,(a, b) => { 
        if(parseInt(a.mes.nombre_corto) > parseInt(b.mes.nombre_corto)){
          return 1;
        }else{
          return -1;
        } 
      });
      this.blockUI.stop();
    });
  }

  cerrarModal(){
    this.alTerminar.emit({});
  }

  guardarDatosLecturacion(){
    this.blockUI.start();
    this.lecturaMedidorService.guardarRegistroLecturacionAccionAgua({lecturas_medidor:this.lecturas_medidor}).subscribe((res:any) => {
      this.alTerminar.emit(res);
      this.blockUI.stop();
    });
  }

  async sincronizarLecturaAnterior(lectura_medidor){
    if(parseInt(lectura_medidor.mes.nombre_corto)!=1){
      let anterior_lectura_medidor=this.lecturas_medidor.filter(e => parseInt(e.mes.nombre_corto) == (parseInt(lectura_medidor.mes.nombre_corto)-1))[0];
      lectura_medidor.lectura_anterior=anterior_lectura_medidor.lectura_actual;
		}else{
      if(parseInt(lectura_medidor.mes.nombre_corto)==1){
        this.blockUI.start();
        let lectura_medidor_diciembre:any=await this.lecturaMedidorService.obtenerLecturaDiciembreGestionAnterior(this.filter.accion_agua.id,(parseInt(this.filter.gestion.nombre_corto)-1)).toPromise();
        lectura_medidor.lectura_anterior=lectura_medidor_diciembre.lectura_actual;
        this.blockUI.stop();
      }
    }
  }

  async actualizarUltimaLecturaSiguiente(lectura_medidor){
		if(parseInt(lectura_medidor.mes.nombre_corto)!=12){
      let siguiente_lectura_medidor=this.lecturas_medidor.filter(e => parseInt(e.mes.nombre_corto) == (parseInt(lectura_medidor.mes.nombre_corto)+1))[0];
      siguiente_lectura_medidor.lectura_anterior=lectura_medidor.lectura_actual;
		}
  }

  calcularConsumo(lectura_medidor){
		lectura_medidor.consumo_cubos=(lectura_medidor.lectura_actual-lectura_medidor.lectura_anterior>0)?lectura_medidor.lectura_actual-lectura_medidor.lectura_anterior:0;
  }
  
  establecerlecturaMedidor(lectura_medidor){
		this.lectura_medidor=lectura_medidor;
		this.lectura_medidor.accion_agua={id:this.lectura_medidor.id_accion_agua};
  }
  
  enfocarLectura=function(keyEvent,fila){
		if (keyEvent.which == '38') {
			// up arrow
			keyEvent.preventDefault();
			setTimeout(function() {
				$("#sc"+(fila-1)).focus();
			},0);
		}
		else if (keyEvent.which == '40') {
			// down arrow
			keyEvent.preventDefault();
			setTimeout(function() {
				$("#sc"+(fila+1)).focus();
			},0);
		}
  }
  
  imprimirVentaConsumoAgua(id_venta){
    this.blockUI.start();
		this.ventasService.obtenerVenta(id_venta).subscribe((ventaConsultada:any) => {
			var fecha=new Date(ventaConsultada.fecha);
			ventaConsultada.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();

      this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).subscribe(async (conf:any)=>{
				let configuracion_aplicacion_ventas=conf;
        this.pdfService.imprimirReciboConsumoAgua(ventaConsultada,configuracion_aplicacion_ventas.datos.opciones_permisos.impresion,false,this.usuario,this.tipo_textos);
				this.blockUI.stop();
			});
		});
	}

}
