import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { PagoVentaComponent } from '../pago-venta/pago-venta.component';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { EmpleadosService } from 'src/app/rrhh/services/empleados/empleados.service';
import { VentaComponent } from 'src/app/base/components/venta/venta.component';
import { Util } from 'src/app/utils/utils';

declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-vista-pedido',
  templateUrl: './vista-pedido.component.html',
  styleUrls: ['./vista-pedido.component.css']
})
export class VistaPedidoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  pedido:any={
    sucursal:{},
    cliente:{}
  };
  estados:any[]=[];
  empleados:any[]=[];
  detalle_pedido:any;
  modalPagoVenta:NgbModalRef;
  usuario:any;
  global_variable=GlobalVariable;
  @BlockUI() blockUI: NgBlockUI;

  aplicacion_ventas:any;

  empleado_asignacion_modal:NgbModalRef;
  @ViewChild('empleado_asignacion_modal')
  private empleado_asignacion_modal_ref: TemplateRef<any>;

  modalVenta:NgbModalRef;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public pedidosService:PedidosService,
    public modalService: NgbModal,
    public pdfService:PdfService,
    public ventasService:VentasService,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    public empleadosService:EmpleadosService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.aplicacion_ventas= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_VENTAS);
    this.aplicacion_ventas=this.aplicacion_ventas.length>0?this.aplicacion_ventas[0]:{puede_modificar:false};
    this.obtenerPedido();
    this.obtenerEstadosPedido();
  }

  ngAfterViewInit(){
    aplicarVisorImagenes("imagen-pedido-vista");
    this.obtenerTextosEmpresa();
  }

  obtenerEstadosPedido(){
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any) => {
	  this.estados=entidad.clases;
    });
  }

  obtenerPedido(){
    this.blockUI.start();
    this.pedidosService.obtenerPedido(this.pedido.id).subscribe((datos:any) => {
      this.pedido=datos;
      this.blockUI.stop();
		});
  }

  cerrarModal(){
    this.alTerminar.emit({recargar_calendario:true});
  }

  esNotaObsequio(){
		var respuesta=true;
		for(var i=0;i<this.pedido.detallesPedido.length;i++){
			respuesta=respuesta && this.pedido.detallesPedido[i].es_obsequio;
		}
		return respuesta;
  }

  imprimirDespacho(despacho){
    this.blockUI.start();
    this.pedidosService.obtenerDespacho(despacho.id).subscribe((despachoConsultado:any)=>{
      this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_PEDIDOS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).subscribe(async (conf:any)=>{
				let configuracion_aplicacion_pedidos=conf;
        despachoConsultado.detallesVenta=despachoConsultado.detallesDespacho;
        if(this.esNotaObsequio()){
          this.pdfService.imprimirNotaObsequioCartaOficio(despachoConsultado,configuracion_aplicacion_pedidos.datos.opciones_permisos.impresion,this.usuario);
        }else{
          this.pdfService.imprimirNotaDespachoCartaOficio(despachoConsultado,configuracion_aplicacion_pedidos.datos.opciones_permisos.impresion,this.usuario);
        }
        this.blockUI.stop();
			});
    });
  }

  crearPagoVenta(pedido){
		this.modalPagoVenta = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalPagoVenta.componentInstance.venta = {id:pedido.id_venta};
		this.modalPagoVenta.componentInstance.usuario = this.usuario;
		this.modalPagoVenta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.obtenerPedido();
					this.modalPagoVenta.close();
				}
			}else{
				this.modalPagoVenta.close();
			}
			
		});
  }

  abrirPopupConfirmacionCambioEstadoPedido(pedido){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.title = "Confirmación de cambio de estado de Pedido!";
    this.popupConfirmacion.componentInstance.message = pedido.mensaje;
		this.popupConfirmacion.componentInstance.data = pedido;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.cambiarEstadoPedido($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

    cambiarEstadoPedido(dato){
      this.blockUI.start();
      var nuevo_estado=this.estados.filter(e => e.nombre_corto == dato.nombre_corto_estado)[0];
      dato.pedido.estado=nuevo_estado;
      dato.pedido.id_empresa=this.usuario.id_empresa;
        this.pedidosService.actualizarEstadoPedido(dato.pedido).subscribe((resP:any)=>{
        this.blockUI.stop();
          this.toastr.success(resP.mensaje);
          this.obtenerPedido();
        });
    }

  verEmpleados(detallePedido){
    this.blockUI.start();
      this.detalle_pedido=detallePedido;
      this.empleadosService.obtenerTodosEmpleadosEmpresa(this.usuario.id_empresa).subscribe((empleados:any)=>{
        this.blockUI.stop();
        this.empleados=empleados;
        this.empleado_asignacion_modal=this.modalService.open(this.empleado_asignacion_modal_ref, {size:'md',ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
        this.empleado_asignacion_modal.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
  }

  asignarEmpleado(empleado){
    this.blockUI.start();
      this.detalle_pedido.empleado_asignado=empleado;
      let estado_por_completar=this.estados.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_PCOMP)[0];
      this.pedido.estado=estado_por_completar;
      this.pedidosService.actualizarEstadoPedido(this.pedido).subscribe((res:any)=>{
        this.pedidosService.actualizarDetallePedido(this.detalle_pedido).subscribe((res1:any)=>{
          this.empleado_asignacion_modal.close();
          this.blockUI.stop();
          this.obtenerPedido();
          this.toastr.success(res1.mensaje);
        });
      });
  }

  async modificarVenta(pedidoP){
		this.blockUI.start();
		let configuracion_aplicacion_ventas=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_VENTAS,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
		this.ventasService.obtenerVenta(pedidoP.id_venta).subscribe( (venta:any)=>{
      venta.pedido=pedidoP;
      venta.usuario.empresa=this.usuario.empresa;
        this.modalVenta = this.modalService.open(VentaComponent, {windowClass:'venta-popup', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
        this.modalVenta.componentInstance.venta = venta;
        this.modalVenta.componentInstance.usuario = this.usuario;
        this.modalVenta.componentInstance.configuracion_pagina = configuracion_aplicacion_ventas;
        this.modalVenta.componentInstance.venta.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
        this.modalVenta.componentInstance.es_administrador=Util.esUsuarioAdministrador(this.usuario);

        this.modalVenta.componentInstance.venta.cliente=venta.cliente;
        this.modalVenta.componentInstance.venta.sucursal=venta.sucursal;
        this.modalVenta.componentInstance.nombre_corto_tipo_pago=venta.tipoPago.nombre_corto;

        this.modalVenta.componentInstance.inhabilitar_busqueda_item=true;
        this.modalVenta.componentInstance.inhabilitar_sucursal=true;
        this.modalVenta.componentInstance.inhabilitar_almacen=true;
        this.modalVenta.componentInstance.inhabilitar_descontar_almacen=true;
        this.modalVenta.componentInstance.inhabilitar_cliente=true;
        this.modalVenta.componentInstance.inhabilitar_movimiento=true;
        this.modalVenta.componentInstance.inhabilitar_tipo_pago=true;
        this.modalVenta.componentInstance.inhabilitar_fecha=true;
        this.modalVenta.componentInstance.inhabilitar_generar_pedido=true;
        this.modalVenta.componentInstance.inhabilitar_a_cuenta=true;
        this.modalVenta.componentInstance.inhabilitar_eliminacion_detalle_venta=true;

        this.modalVenta.componentInstance.venta.es_pedido=true;
        this.blockUI.stop();
			  this.modalVenta.componentInstance.alTerminar.subscribe((res) => {
          if(res){
            if(res.tiene_error){
              this.toastr.error(res.mensaje);
              this.toastr.error(res.stack.substring(0,500));
            }else{
              this.toastr.success(res.mensaje);
              //this.imprimirVenta(res.venta.id);
              this.modalVenta.close();
              this.obtenerPedido();
            }
          }else{
            this.modalVenta.close();
          }
				
			  });
		  });
  	}

}
