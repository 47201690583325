<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">{{tipo_edicion.nombre}}:</h4>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" type="button" (click)="guardarConceptoEdicion()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarVentana(false)">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="card-body">
        <div class="form-row">
            <div class="col-6">
                <div class=" ">
                    <label>Tipo de Dato</label>
                    <input [disabled]="tipo_edicion.id" [(ngModel)]="tipo_edicion.nombre" type="text" class="form-control" name="tipo_edicionnombre" placeholder="Concepto">
                </div>
            </div>
            <div class="col-6">
                <div class=" ">
                    <label>Acrónimo</label>
                    <input [disabled]="tipo_edicion.id" [(ngModel)]="tipo_edicion.nombre_corto" type="text" class="form-control" name="tipo_edicionacronimo" placeholder="Acrónimo">
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-sm-5">
                <div class=" ">
                    <label>Nombre</label>
                    <input [(ngModel)]="clase.nombre" type="text" class="form-control" name="ciudad" placeholder="Concepto">
                </div>
            </div>
            <div class="col-sm-5">
                <div class=" ">
                    <label>Nombre Corto</label>
                    <input [(ngModel)]="clase.nombre_corto" type="text" class="form-control" name="ciudad" placeholder="nombre corto">
                </div>
            </div>
            <div class="col-sm-2">
                <br>
                <div class=" ">
                    <button type="button" class="btn btn-primary" (click)="agregarConceptoEdicion(clase)">
                        <span class="fa fa-arrow-down" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Nombre Corto</th>
                    <th>Habilitar</th>
                    <th data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let clase of tipo_edicion.clases">
                    <tr *ngIf="!clase.eliminado" >
                        <th>{{clase.nombre}}</th>
                        <td>{{clase.nombre_corto}}</td>
                        <td>
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="clase.habilitado"/>
                            </label>
                        </td>
                        <td>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarConceptoEdicion(clase)">
                                <i class="fa fa-edit bigger-130"></i>
                            </a>
                            <a *ngIf="usuario.empresa && usuario.empresa.usar_pedidos_en_linea && tipo_edicion.nombre_corto=='GRUPOS PRODUCTOS'" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="abrirRegistroCategoriaTienda(clase)">
                                <i class="fas fa-pencil-ruler bigger-130"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="removerConceptoEdicion(clase)">
                                <i class="fa fa-trash bigger-130"></i>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>