import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprobantesService } from '../../services/comprobantes/comprobantes.service';
import { LibroMayorComponent } from '../libro-mayor/libro-mayor.component';

@Component({
  selector: 'app-balance-sumas-saldos',
  templateUrl: './balance-sumas-saldos.component.html',
  styleUrls: ['./balance-sumas-saldos.component.css']
})
export class BalanceSumasSaldosComponent extends BaseComponent implements OnInit {

  libroMayorModal:NgbModalRef;
  cuentas:any[]=[];
  suma_debe:number=0;
  suma_haber:number=0;
  suma_acreedor:number=0;
  suma_deudor:number=0;

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
	  private toastr: ToastrService,
    private comprobanteService:ComprobantesService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    let fecha_actual=new Date();
		this.filter={
			id_empresa:this.usuario.id_empresa,
			fecha_inicio:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			},
			fecha_fin:{
				year: fecha_actual.getFullYear(), 
				month: (fecha_actual.getMonth()+1),
				day:fecha_actual.getDate()
			}
		}
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  generarBalanceComprobacionSumasSaldos(){
    this.blockUI.start();
    this.comprobanteService.generarBalanceComprobacionSumasSaldos(this).subscribe((res:any)=>{
      this.cuentas=res.cuentas;
      this.suma_debe=0,this.suma_haber=0,this.suma_deudor=0,this.suma_acreedor=0;
      for(let i=0;i<this.cuentas.length;i++){
        this.cuentas[i].deudor=this.cuentas[i].debe>this.cuentas[i].haber?this.cuentas[i].debe-this.cuentas[i].haber:0;
        this.cuentas[i].acreedor=this.cuentas[i].haber>this.cuentas[i].debe?this.cuentas[i].haber-this.cuentas[i].debe:0;
        this.suma_debe=this.suma_debe+this.cuentas[i].debe;
        this.suma_haber=this.suma_haber+this.cuentas[i].haber;
        this.suma_deudor=this.suma_deudor+this.cuentas[i].deudor;
        this.suma_acreedor=this.suma_acreedor+this.cuentas[i].acreedor;
      }
      this.suma_debe=Math.round(this.suma_debe*100)/100;
      this.suma_haber=Math.round(this.suma_haber*100)/100;
      this.suma_deudor=Math.round(this.suma_deudor*100)/100;
      this.suma_acreedor=Math.round(this.suma_acreedor*100)/100;
      this.blockUI.stop();
    });
  }

  abrirLibroMayorCuenta(cuenta){
    this.libroMayorModal = this.modalService.open(LibroMayorComponent, {windowClass:"libro-mayor", ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:"lg"});
    this.libroMayorModal.componentInstance.cuenta = cuenta;
    this.libroMayorModal.componentInstance.usuario = this.usuario;
    
    this.libroMayorModal.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          //this.establecerCliente(res.cliente);
          this.libroMayorModal.close();
        }
      }else{
        this.libroMayorModal.close();
      }
      
    });
  }

}
