import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import {GoogleMapsModule} from '@angular/google-maps'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorTokenService } from './base/services/interceptor-token/interceptor-token.service';
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { GlobalVariable } from './global';
import { NgbDateCustomParserFormatter } from './utils/dateformat';
import { MenuHorizontalComponent } from './components/menu-horizontal/menu-horizontal.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { WebcamModule } from 'ngx-webcam';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { BaseModule } from './base/base.module';
import { RrhhModule } from './rrhh/rrhh.module';
import { SchoolModule } from './school/school.module';
import { NeighborhoodModule } from './neighborhood/neighborhood.module';
import { TransporteModule } from './transporte/transporte.module';
import { MantenimientoModule } from './mantenimiento/mantenimiento.module';
import { SnapquickModule } from './snapquick/snapquick.module';
import { GeceModule } from './gece/gece.module';
import { InstituteModule } from './institute/institute.module';
import { HotelModule } from './hotel/hotel.module';
import { PaginaNoEncontradaComponent } from './components/pagina-no-encontrada/pagina-no-encontrada.component';
import { MensajesEmpresaComponent } from './marketing/components/mensajes-empresa/mensajes-empresa.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PeriodismoModule } from './periodismo/periodismo.module';
import { ContabilidadModule } from './contabilidad/contabilidad.module';
import { TorneoModule } from './torneo/torneo.module';
import { MedicinaModule } from './medicina/medicina.module';


const config: SocketIoConfig = { url: GlobalVariable.SERVER_URL, options: {} };
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    MenuHorizontalComponent,
    PaginaNoEncontradaComponent,
    MensajesEmpresaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BlockUIModule.forRoot({
      message: 'Procesando...'
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    OrderModule,
    NgbModule,
    FilterPipeModule,
    SocketIoModule.forRoot(config),
    NgxBootstrapMultiselectModule,
    GoogleMapsModule,
    RecaptchaModule,
    PdfViewerModule,
    WebcamModule,
    YouTubePlayerModule,
    BaseModule,
    RrhhModule,
    SchoolModule,
    NeighborhoodModule,
    TransporteModule,
    MantenimientoModule,
    SnapquickModule,
    GeceModule,
    InstituteModule,
    HotelModule,
    PeriodismoModule,
    ContabilidadModule,
    TorneoModule,
    MedicinaModule,
    NgxMaskModule.forRoot()
  ],
  providers: [{provide:NgbDateParserFormatter,useClass:NgbDateCustomParserFormatter},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
