import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalVariable } from '../../../global';
import { ToastrService } from 'ngx-toastr';
import { Util } from 'src/app/utils/utils';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(public http: HttpClient,private toastr: ToastrService,private persistenciaService:PersistenciaService) { }

  public iniciarSesion(usuario){
    return this.http.post(GlobalVariable.API_SERVER_URL+"autenticar",usuario, GlobalVariable.httpOptions);
  }

  public async iniciarSesionUsuario(usuario,blockUI){
    let usuario_respuesta=null;
    let res:any=await this.iniciarSesion(usuario).toPromise();
      if (res.type == false) {
        this.toastr.error(res.data);
        blockUI.stop();
      } else {
        let usuarioSucursales:any=await this.autenticarSucursalesUsuario(res.data).toPromise();
          let usuario=res.data;
          usuario.sucursalesUsuario = usuarioSucursales;
          Util.generarMenus(usuario);
          if(usuario.empresa){
            usuario=Util.establecerPermisos(usuario);
            if(usuario.empresa.usar_consumo_de_agua){
              let vecindario=await this.autenticarVecindario(usuario.id_empresa).toPromise();
              usuario.empresa.vecindario=vecindario;
            }
          }
          //document.title = 'SNAPQUICK - ' + this.usuario.nombre_usuario;
          try{
            if(usuario.empresa){
              let imagenEmpresa=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+usuario.empresa.imagen);
              usuario.empresa.imagen=imagenEmpresa;
            }
          }catch(err){
            console.log(err);
          }
          this.persistenciaService.setUsuario(usuario);
          this.persistenciaService.set("token",res.token);
          //localStorage.setItem("ngStorage-token", JSON.stringify(res.token));
          //localStorage.setItem("ngStorage-usuario", JSON.stringify(JSON.stringify(usuario)));
          usuario_respuesta=usuario;
          blockUI.stop();
      }
      return usuario_respuesta;
  }

  public autenticarSucursalesUsuario(usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"autenticar-sucursales/"+usuario.id, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionPagina(id_empresa,id_aplicacion,id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"columnnas-aplicacion/empresa/"+id_empresa+"/aplicacion/"+id_aplicacion+"/usuario/"+id_usuario, GlobalVariable.httpOptions);
  }

  public obtenerConfiguracionPaginaPorCodigo(id_empresa,codigo_aplicacion,id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"columnnas-aplicacion/empresa/"+id_empresa+"/codigo-aplicacion/"+codigo_aplicacion+"/usuario/"+id_usuario, GlobalVariable.httpOptions);
  }

  public actualizarConfiguracionPagina(datos,id_aplicacion){
    return this.http.put(GlobalVariable.API_SERVER_URL+"columnnas-aplicacion/aplicacion/"+id_aplicacion,datos, GlobalVariable.httpOptions);
  }

  public obtenerUsuariosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"usuarios/empresa/"+id_empresa, GlobalVariable.httpOptions);
  }

  public autenticarVecindario(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"autenticar-vecindario/"+id_empresa, GlobalVariable.httpOptions);
  }

  public obtenerListaUsuarios(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"usuario/empresa/"+paginator.filter.empresa.id+"/rol/"+paginator.filter.rol.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public verificarUsuario(id_usuario,nombre_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"verificar-usuario-existente/"+id_usuario+"/nombre-usuario/"+nombre_usuario,GlobalVariable.httpOptions);
  }

  public obtenerUsuario(id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"usuarios/"+id_usuario,GlobalVariable.httpOptions);
  }

  public guardarUsuario(usuario){
    return this.http.post(GlobalVariable.API_SERVER_URL+"usuarios",usuario,GlobalVariable.httpOptions);
  }

  public actualizarUsuario(usuario){
    return this.http.put(GlobalVariable.API_SERVER_URL+"usuarios/"+usuario.id,usuario,GlobalVariable.httpOptions);
  }

  public eliminarUsuario(usuario){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"usuarios/"+usuario.id,GlobalVariable.httpOptions);
  }

  public enviarCuentaUsuario(usuario){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-correo-usuario",usuario,GlobalVariable.httpOptions);
  }

  public guardarPosicionUsuario(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"posicion-geo-usuario",datos,GlobalVariable.httpOptions);
  }

  public buscarPosicionesUsuarios(id_empresa,fecha){
    return this.http.get(GlobalVariable.API_SERVER_URL+"posicion-geo-usuario/empresa/"+id_empresa+"/fecha/"+fecha,GlobalVariable.httpOptions);
  }

  public eliminarPosicionImagen(id_imagen){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"posicion-imagen/"+id_imagen,GlobalVariable.httpOptions);
  }

  public actualizarAtributoUsuario(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"actualizar-atributo-usuarios",datos,GlobalVariable.httpOptions);
  }

  public verificarUsuarioExsistente(id_usuario,nombre_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"verificar-usuario-existente/"+id_usuario+"/nombre-usuario/"+nombre_usuario,GlobalVariable.httpOptions);
  }

  public enviarEnlaceRecuperacionClave(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"enviar-enlace-recuperacion-clave",datos,GlobalVariable.httpOptions);
  }

  public recuperarClave(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"recuperar-clave",datos,GlobalVariable.httpOptions);
  }

  public crearCuentaEmpresa(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"creacion-cuenta-empresa-snq",datos,GlobalVariable.httpOptions);
  }

  public obtenerPlanesRubro(id_rubro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rubro-planes/"+id_rubro,GlobalVariable.httpOptions);
  }

  public actualizarMensajeLeido(mensaje){
    return this.http.put(GlobalVariable.API_SERVER_URL+"mensaje-receptor-leido/"+mensaje.id,mensaje,GlobalVariable.httpOptions);
  }

  public obtenerPreciosProductosUsuario(id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"usuario/"+id_usuario+"/productos-precios",GlobalVariable.httpOptions);
  }

  public guardarPreciosProductosUsuario(precios){
    return this.http.post(GlobalVariable.API_SERVER_URL+"usuario-productos-precios",precios,GlobalVariable.httpOptions);
  }

  public actualizarUsuarioClave(usuario){
    return this.http.put(GlobalVariable.API_SERVER_URL+"usuario-cambio-clave/"+usuario.id,usuario,GlobalVariable.httpOptions);
  }
  
}
