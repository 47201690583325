import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/models/base-component';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../../../base/services/general/general.service';
import { Util } from 'src/app/utils/utils';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent extends BaseComponent implements OnInit {

	@BlockUI() blockUI: NgBlockUI;
  clientes:any[]=[];
  items_resumen:any[]=[];
  es_usuario_administrador:boolean=false;

  constructor(private router: Router,
    public usuarioService:UsuarioService,
    public generalService:GeneralService,
    public reportesService:ReportesService,
	public persistenciaService:PersistenciaService,
	public pdfService:PdfService,
	public excelService:ExcelService,
    public modalService: NgbModal) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarAplicacion(this.router.url.substring(1));
	let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:Util.obtenerSucursalesUsuario(this.usuario),
      cliente:"",
      sucursal:this.usuario.empresa.usar_clientes_sucursal?Util.obtenerSucursalesUsuario(this.usuario)[0]:{id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
	  fecha_inicio:{
		year: fecha_actual.getFullYear(), 
		month: (fecha_actual.getMonth()+1),
		day:fecha_actual.getDate()
	  },
	  fecha_fin:{
			year: fecha_actual.getFullYear(), 
			month: (fecha_actual.getMonth()+1),
			day:fecha_actual.getDate()
		}
    }

    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(this.es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }

		//this.generarReporteVentaCreditoProductosCliente();
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

    generarReporteVentaCreditoProductosCliente(){
		this.blockUI.start();
		this.reportesService.obtenerDatosReporteVentasCreditoProductosPorCliente(this.filter).subscribe((clientes:any[]) => {
			this.procesarClientes(clientes);
			this.blockUI.stop();
		});
	}

	procesarClientes(clientes){
		this.clientes=[];
		this.items_resumen=[];
    	//var id_clientes=$filter('groupBy')(clientes,'id_cliente');

    	let id_clientes = clientes.filter((cliente, i, arr) => arr.findIndex(t => t.id_cliente === cliente.id_cliente) === i);

		for(var i=0;i<id_clientes.length;i++){
			var items=clientes.filter(e => e.id_cliente == id_clientes[i].id_cliente);
			var cliente={
				id:items[0].id_cliente,
				identificacion:items[0].cliente,
				items:items,
				total:items[0].total,
				a_cuenta:items[0].a_cuenta,
				saldo:items[0].saldo,
				devoluciones:items[0].total_devoluciones,
			}
			this.clientes.push(cliente);
		}

		for(var i=0;i<this.clientes.length;i++){
			for(var j=0;j<this.clientes[i].items.length;j++){
				var encontrado_producto=this.items_resumen.filter(e => e.id_producto == this.clientes[i].items[j].id_producto);
				var encontrado_combo=this.items_resumen.filter(e => e.id_combo == this.clientes[i].items[j].id_combo);
				if(this.clientes[i].items[j].id_producto){
					if(encontrado_producto.length>0){
						encontrado_producto[0].vendidos=encontrado_producto[0].vendidos+this.clientes[i].items[j].vendidos;
						encontrado_producto[0].obsequios_productos=encontrado_producto[0].obsequios_productos+this.clientes[i].items[j].obsequios_productos;
						encontrado_producto[0].devoluciones=encontrado_producto[0].devoluciones+this.clientes[i].items[j].devoluciones;
					}else{
						this.items_resumen.push(JSON.parse(JSON.stringify(this.clientes[i].items[j])));
					}
				}
				if(this.clientes[i].items[j].id_combo){
					if(encontrado_combo.length>0){
						encontrado_combo[0].vendidos=encontrado_combo[0].vendidos+this.clientes[i].items[j].vendidos;
						encontrado_combo[0].obsequios_combos=encontrado_combo[0].obsequios_combos+this.clientes[i].items[j].obsequios_combos;
						encontrado_combo[0].devoluciones_combos=encontrado_combo[0].devoluciones_combos+this.clientes[i].items[j].devoluciones_combos;
					}else{
						this.items_resumen.push(JSON.parse(JSON.stringify(this.clientes[i].items[j])));
					}
				}
			}
		}
	}

	generarPdf(){
		//this.clientes=[];
		//this.items_resumen=[];
		console.log("array clientes: ");
		console.log(this.clientes);
		console.log("array usuario: ");
		console.log(this.usuario);
		

		this.pdfService.imprimirReporteVentasCreditoPoCliente(this.clientes, this.items_resumen, this.usuario);
		
		//Para probar 2do tipo de reporte mas parecido al html // Aun no funciona bien
		//this.pdfService.imprimirReporteVentasCreditoPoCliente2(this.clientes, this.items_resumen);
		
	}

	generarExcel(){
		let data=[];
		let cabecera=[];
		cabecera.push("CÓDIGO VECINO");
		cabecera.push("NOMBRE");
		cabecera.push("CODIGO MEDIDOR");
		
		for (let i = 0; i < 5; i++) {
			let columns = [];
			columns.push(['Nombre de cliente','Total','Devoluciones', 'A cuenta', 'Saldo']);
			columns.push(['P.E.P.E.S.', '7000', '13680', '20680']);
			
			data.push(columns);
		}

		let reportData = {
			title: "Titulo de excel",
			data: data,
			headers: cabecera
		}

		this.excelService.descargarReporteExcel(reportData, 'Libro no 1', 'Hoja no 1', this.clientes);
	}

}
