import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PedidosService } from '../../services/pedidos/pedidos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';

@Component({
  selector: 'app-pantalla-despacho',
  templateUrl: './pantalla-despacho.component.html',
  styleUrls: ['./pantalla-despacho.component.css']
})
export class PantallaDespachoComponent extends BaseComponent implements OnInit {

  pantalla_despacho:NgbModalRef;
  @ViewChild('pantalla_despacho')
  private pantalla_despacho_ref: TemplateRef<any>;
  detallePedidoDescripcion:any;

  descripcionitem:NgbModalRef;
  @ViewChild('descripcionitem')
  private descripcionitemref: TemplateRef<any>;

  pedidos:any[]=[];
  estados_pedido:any[]=[];

  configuracion_aplicacion_modal:NgbModalRef;
  @ViewChild('configuracion_aplicacion_modal')
  private configuracion_aplicacion_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public pedidosService:PedidosService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private socket: Socket,
    public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));
    this.configuracionSockets();
    this.obtenerEstadosPedidos();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:(Util.obtenerSucursalesUsuario(this.usuario))
    }
    this.filter.sucursal=this.filter.sucursales[0];
    this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PANTALLA_DESPACHO);
  }

  abrirPantallaDespacho(tipo_fuente_despacho){
		this.filter.tipo_fuente_despacho=tipo_fuente_despacho;
		this.pantalla_despacho=this.modalService.open(this.pantalla_despacho_ref, {windowClass:'pantalla-despacho',ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.pantalla_despacho.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
		this.cargarPedidos();
		//setInterval($scope.resetTimers, 300000);
  }
  
  cargarPedidos(){
    this.blockUI.start();
    this.pedidosService.obtenerPedidosPantallaNoEntregados(this.filter.sucursal.id).subscribe((pedidos:any[])=>{
      /*this.pedidos=[];
			for (var i = 0; i < pedidos.length; i++) {
				pedidos[i].numero_productos=0;
				var detallesPedido=[];
				for (var j = 0; j < pedidos[i].detallesPedido.length; j++) {
					if(pedidos[i].detallesPedido[j].producto.fuente_despacho.nombre_corto==this.filter.tipo_fuente_despacho){
						detallesPedido.push(pedidos[i].detallesPedido[j]);
						pedidos[i].numero_productos=pedidos[i].numero_productos+1;
					}
				}
				if(detallesPedido.length>0){
					pedidos[i].detallesPedido=detallesPedido;
					this.pedidos.push(pedidos[i]);
				}
      }*/

      this.pedidos=[];
    	let id_pedidos = pedidos.filter((pedido, i, arr) => arr.findIndex(t => t.id_pedido === pedido.id_pedido) === i);

      for(var i=0;i<id_pedidos.length;i++){
        var items=pedidos.filter(e => e.id_pedido == id_pedidos[i].id_pedido);
        let pedido:any={
          id:items[0].id_pedido,
          cliente:{
            identificacion:items[0].identificacion,
            razon_social:items[0].razon_social,
            telefono1:items[0].telefono1,
          },
          numero_pedido:items[0].numero_pedido,
          tiempo_ingreso:items[0].tiempo_ingreso,
          tipoDespacho:{
            nombre:items[0].tipo_despacho
          },
          numero_productos:items.length
        }
        for(let j=0;j<items.length;j++){
          items[j]={
            producto:{
              nombre:items[j].producto,
              fuente_despacho:{
                nombre_corto:items[j].fuente_despacho_nombre_corto
              }
            },
            cantidad:items[j].cantidad,
            descripcion:items[j].descripcion,
            id:items[j].id_detalle_pedido
          }
        }
        pedido.detallesPedido=items;
        this.pedidos.push(pedido);
      }

      this.blockUI.stop();
    });
  }
  
  emitirSonido(pedido){
		var audio = new Audio(GlobalVariable.SERVER_URL+'sound/'+pedido.numero_pedido+'.m4a');
    audio.play();
  }
  
  entregarPedido(pedido){
		var estado_entregado=this.estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_ENTGD)[0];
		pedido.estado=estado_entregado;
		pedido.tiempo_despacho=new Date();
    this.blockUI.start();
    this.pedidosService.actualizarPedidoRestaurante(pedido).subscribe((res:any)=>{
      this.blockUI.stop();
			this.toastr.success('Pedido entregado Exitosamente!');
			this.cargarPedidos();
			if(this.usuario.empresa.punto_venta_offline){
        this.pedidosService.subirActualizacionEstadoPedido({id_pedido:pedido.id}).subscribe((resact:any)=>{

        });
			}
    });
  }
  
  obtenerEstadosPedidos(){
    this.blockUI.start();
    this.generalService.obtenerClases("ESTPEDI").subscribe((entidad:any)=>{
      this.estados_pedido=entidad.clases;
      this.blockUI.stop();
    });
  }

  configuracionSockets(){
    let me=this;
    this.socket.on('actualizarPedidos', (dato) => {
      if(dato.id_sucursal==this.filter.sucursal.id){
				if(dato.pedido.activo){
          if(me.configuracion_pagina.object.datos.opciones_permisos.emitir_sonido_recepcion){
            me.emitirSonido(dato.pedido);
          }
					if(dato.estado_pedido && dato.estado_pedido.nombre_corto!=GlobalVariable.Dictionary.ESTPED_ENTGD){
						//responsiveVoice.speak("preparar el pedido "+dato.pedido.numero_pedido, "Spanish Female");		
					}
				}else{
					//responsiveVoice.speak("el pedido "+dato.pedido.numero_pedido+" fue anulado!", "Spanish Female");		
				}
				me.cargarPedidos();
			}
    });
  }

  agregarDescripcionDetallePedido(detalleVenta){
    this.detallePedidoDescripcion=detalleVenta;
    this.descripcionitem=this.modalService.open(this.descripcionitemref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.descripcionitem.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  agregarDetallePedidoDescripcion(){
    this.blockUI.start();
    this.pedidosService.actualizarDescripcionDetallePedido(this.detallePedidoDescripcion).subscribe((res:any)=>{
      this.blockUI.stop();
      this.descripcionitem.close();
    });
  }

  abrirPopupConfirmacionEntregaMultiple(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) realizar la entrega de todos los pedidos?";		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.accionarEntregar();
			}
			this.popupConfirmacion.close();
        });
	  }

  accionarEntregar(){
    this.entregarPedidos(this.pedidos);    
  }
  
  async entregarPedidos(pedidos){
		this.blockUI.start();
		var estado_entregado=this.estados_pedido.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ESTPED_ENTGD)[0];
		for(var i=0;i<pedidos.length;i++){
			pedidos[i].estado=estado_entregado;
      pedidos[i].tiempo_despacho=new Date();
      let pedAct:any=await this.pedidosService.actualizarPedidoRestaurante(pedidos[i]).toPromise();
			if(this.usuario.empresa.punto_venta_offline){
        this.pedidosService.subirActualizacionEstadoPedido({id_pedido:pedidos[i].id}).subscribe((resact:any)=>{

        });
			}
		}
		this.cargarPedidos();
		this.toastr.success('Pedidos entregados Exitosamente!');
		this.blockUI.stop();
  }
  
  abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(this.configuracion_aplicacion_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.configuracion_aplicacion_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});

	}

	guardarConfiguracionVista=function(){
		this.configuracion_pagina.updateObject();
		this.toastr.success("Configuracion actualizada satisfactoriamente!");
		this.configuracion_aplicacion_modal.close();
  }

}
