<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Pago Credito Compra</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="efectuarPagoVencimientoCredito()" class="btn btn-primary" >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarModal()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row p-1">
        <div class="col-md-7">
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Tipo Documento : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.tipo_documento.nombre}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fa fa-user red2 text-110 mr-1"></i> Proveedor : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.proveedor.razon_social}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Num. Documento Interno : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.numero_documento}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Num. Factura : 
                    </label>    
                </div>
                <div class="col-6">
                    <label>
                        {{compra.factura}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-6 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-file-alt"></i> Comprobante : 
                    </label>    
                </div>
                <div class="col-6">
                    <form id="dropzone" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                        <div class="fallback d-none">
                          <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                        </div>
        
                        <div class="dz-default dz-message" style="margin: 0px !important;">
                          <span class="text-150  text-grey-d2">
                          <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                          </span>
                        </div>
                      </form>
        
                      <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                      <div id="preview-template" class="d-none">
                        <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                          <div class="dz-image">
                            <img alt="Archivo" data-dz-thumbnail="" />
                          </div>
        
                          <div class="dz-details">
                            <div class="dz-size">
                              <span data-dz-size=""></span>
                            </div>
        
                            <div class="dz-filename">
                              <span data-dz-name=""></span>
                            </div>
                          </div>
        
                          <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                            <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                          </div>
        
                          <div class="dz-error-message">
                            <span data-dz-errormessage=""></span>
                          </div>
        
                          <div class="dz-success-mark">
                            <span class="fa-stack fa-lg text-150">
                              <i class="fa fa-circle fa-stack-2x text-white"></i>
                              <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                          </span>
                          </div>
        
                          <div class="dz-error-mark">
                            <span class="fa-stack fa-lg text-150">
                              <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                              <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                          </span>
                          </div>
                        </div>
                      </div> 
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Plazo (Días) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.dias_credito}}
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Total (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.total}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> A cuenta (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.a_cuenta}} 
                    </label>    
                </div>
            </div>
            <div class="row">
                <div class="col-7 alert alert-info pr-0 mb-0">
                    <label class="text-primary p-0">
                        <i class="fas fa-money-bill-alt"></i> Saldo (Bs) : 
                    </label>    
                </div>
                <div class="col-5">
                    <label>
                        {{compra.saldo}} 
                    </label>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-2">
            <label for="fefin">Fecha </label>&nbsp;
        </div>
        <div class="col-4">
            <div class="input-group">
                <input name="fecha" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="pago_compra.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div> 
        </div>
        <div class="col-3">
            <label>¿Ingrese el monto a cobrar?</label>
        </div>
        <div class="col-3">
            <input id="importe" onfocus="this.select();" name="pago_compra" class="form-control" required [(ngModel)]="pago_compra.monto_pagado" min="0.01" max="{{compra.saldo}}" type="number" step="0.001">
        </div>
    </div>
    <ng-container *ngIf="usuario.empresa.usar_caja_general">
        <hr>
        <div class="row">
            <div class="col-2">
                <label for="fefin">Transaccion: </label>&nbsp;
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" id="transaccion" name="transaccion" class="form-control" [(ngModel)]="pago_compra.transaccion">
                    <option [ngValue]="transaccion" *ngFor="let transaccion of transacciones">{{transaccion.nombre}}</option>
                </select>
            </div>
            <div class="col-3">
                <label>Caja:</label>
            </div>
            <div class="col-3">
                <select [compareWith]="compararObjectos" name="caja" class="form-control" [(ngModel)]="pago_compra.caja">
                    <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
                </select>
            </div>
        </div>
    </ng-container>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Observacion:</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input class="form-control" [(ngModel)]="pago_compra.observacion"  type="text">
        </div>
    </div>
</div>