<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">{{titulo_ventana}}</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-success btn-lg" type="button" (click)="generarExcelVencimientoPagosEstudiantes()">
            <i class="ace-icon fa fa-file-excel align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger btn-lg" type="button" (click)="imprimirListaVencimientoPagosEstudiantes()">
            <i class="ace-icon fa fa-file-pdf align-top bigger-125"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover ">
          <thead class="bgc-primary-d1 text-white sticky-nav">
              <tr class="sticky-nav">
                  <th>Nº</th>
                  <th *ngIf="usuario.empresa.usar_sucursales">
                    Sucursal
                    <select (change)="cambiarFiltro()" [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="filter.sucursal">
                      <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                    </select>
                  </th>
                  <th>
                      Gestión
                      <select (change)="cambiarFiltro()" [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="filter.gestion">
                        <option [ngValue]="gestion" *ngFor="let gestion of filter.gestiones">{{gestion.nombre}}</option>
                      </select>
                    </th>
                  <th>
                      Curso
                      <select (change)="cambiarFiltro()" [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="filter.curso">
                        <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
                      </select>
                    </th>
                  <th>Código</th>
                  <th>Estudiante</th>
                  <th>Teléfono</th>
                  <th>Concepto de Pago
                    <select (change)="cambiarFiltro()" [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="filter.conceptoPago">
                      <option [ngValue]="conceptoPago" *ngFor="let conceptoPago of filter.conceptoPagos">{{conceptoPago.concepto.nombre}}</option>
                    </select>
                  </th>
                  <th>Cuota</th>
                  <th>Importe</th>
                  <th>Descuento</th>
                  <th>Pagado</th>
                  <th>Saldo</th>
                  <th>Fecha de Vencimiento</th>
                  <th>
                    <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                    <div class="fht-cell"></div>
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let pago_estudiante of pagos_estudiantes_filtrados; let i=index;">
                  <td>{{i+1}}</td>
                  <td *ngIf="usuario.empresa.usar_sucursales">{{pago_estudiante.sucursal}}</td>
                  <td>{{pago_estudiante.gestion}}</td>
                  <td>{{pago_estudiante.curso}}</td>
                  <td>{{pago_estudiante.codigo_estudiante}}</td>
                  <td>{{pago_estudiante.estudiante}}</td>
                  <td>{{pago_estudiante.telefono_movil}} - {{pago_estudiante.telefono}}</td>
                  <td>{{pago_estudiante.concepto}}</td>
                  <td>{{pago_estudiante.cuota}}</td>
                  <td>{{pago_estudiante.importe}}</td>
                  <td>{{pago_estudiante.descuento}}</td>
                  <td>{{pago_estudiante.pagado}}</td>
                  <td>{{pago_estudiante.saldo}}</td>
                  <td>{{pago_estudiante.fecha_vencimiento | date:"dd/MM/yyyy"}}</td>
                  <td>
                    <a *ngIf="usuario.empresa.usar_mensajeria_redes_sociales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupConfirmacionEnvioWhatsapp(pago_estudiante)">
                        <i class="ace-icon fab fa-whatsapp bigger-130"></i>
                    </a>
                    <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pago_estudiante)">
                        <i class="fa fa-trash-alt text-105"></i>
                    </a>
                  </td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
