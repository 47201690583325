import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { PeriodismoRoutingModule } from './periodismo-routing.module';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { BrowserModule } from '@angular/platform-browser';
import { BlockUIModule } from 'ng-block-ui';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OrderModule } from 'ngx-order-pipe';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { GoogleMapsModule } from '@angular/google-maps';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RecaptchaModule } from 'ng-recaptcha';
import { WebcamModule } from 'ngx-webcam';
import { GlobalVariable } from '../global';
import { InterceptorTokenService } from '../base/services/interceptor-token/interceptor-token.service';
import { NgbDateCustomParserFormatter } from '../utils/dateformat';

const config: SocketIoConfig = { url: GlobalVariable.SERVER_URL, options: {} };

@NgModule({
  declarations: [
    NoticiasComponent
  ],
  imports: [
    PeriodismoRoutingModule,
    CommonModule,
    FormsModule,
    BaseModule,
    BrowserModule,
    BlockUIModule.forRoot({
      message: 'Procesando...'
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    OrderModule,
    NgbModule,
    FilterPipeModule,
    SocketIoModule.forRoot(config),
    NgxBootstrapMultiselectModule,
    GoogleMapsModule,
    RecaptchaModule,
    PdfViewerModule,
    WebcamModule
  ],
  providers: [{provide:NgbDateParserFormatter,useClass:NgbDateCustomParserFormatter},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true}],
  exports:[
    NoticiasComponent
  ]
})
export class PeriodismoModule { }
