import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/models/base-component';
import { ComprasService } from '../../services/compras/compras.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { Util } from 'src/app/utils/utils';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

declare const resaltarPestañaMenu:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-libro-compras',
  templateUrl: './libro-compras.component.html',
  styleUrls: ['./libro-compras.component.css']
})
export class LibroComprasComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
	  public pdfService:PdfService,
    public modalService: NgbModal,
    public comprasService: ComprasService,
	public usuarioService:UsuarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));

    this.filter={
      id_empresa:this.usuario.id_empresa,
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      gestiones:[],
      gestion: null,
      meses:[],
      mes: null
    }

    this.obtenerGestiones();
    this.obtenerMeses();
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.obtenerTextosEmpresa();
  }
  
  obtenerGestiones(){
    this.blockUI.start();
    this.generalService.obtenerClases("GTN").subscribe((entidad:any)=>{
      this.filter.gestiones=entidad.clases;
      this.blockUI.stop();
    });
  }

  obtenerMeses(){
    this.blockUI.start();
    this.generalService.obtenerClases("MESES").subscribe((entidad:any)=>{
      this.filter.meses=entidad.clases;
      this.blockUI.stop();
    });
  }

  generarTXTLibroCompras(){
    this.blockUI.start();
    this.comprasService.obtenerDatosLibroCompras(this.filter).subscribe((datos:any)=>{
      var compras=datos.compras;
			var linea="";
			for(var i=0;i<compras.length;i++){
				compras[i].fecha=new Date(compras[i].fecha);
				linea=linea+"1|";
				linea=linea+(i+1)+"|";
				var fecha=(compras[i].fecha.getDate()<10)?"0"+compras[i].fecha.getDate():compras[i].fecha.getDate();
				var mes=((compras[i].fecha.getMonth()+1)<10)?"0"+(compras[i].fecha.getMonth()+1):(compras[i].fecha.getMonth()+1);
				linea=linea+fecha+"/"+mes+"/"+compras[i].fecha.getFullYear()+"\|";
				linea=linea+compras[i].proveedor.nit+"|";
				linea=linea+compras[i].proveedor.razon_social+"|";
				linea=linea+compras[i].factura+"|";
				linea=linea+"0"+"|";
				linea=linea+compras[i].autorizacion+"|";
				linea=linea+compras[i].importe+"|";
				linea=linea+(compras[i].ice)+"|";
				linea=linea+(Math.round((compras[i].importe-compras[i].ice)*100)/100)+"|";
				var descuento=compras[i].descuento-compras[i].recargo+compras[i].excento;
				linea=linea+descuento+"|";
				linea=linea+compras[i].sujeto_a_iva+"|";
				linea=linea+(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100)+"|";
				linea=linea+compras[i].codigo_control+"|";
				linea=linea+"1"+"\n"
			}
      var file = new Blob([linea.replace(/\n/g, "\r\n")], {type: 'text/plain'});
      let nombre_archivo="compras_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit)+".txt"
		  fs.saveAs(file, nombre_archivo);
			this.blockUI.stop();
    });
  }
  
  generarExcelLibroCompras(){
		this.blockUI.start();
		this.comprasService.obtenerDatosLibroCompras(this.filter).subscribe((datos:any)=>{
			var compras=datos.compras;
			var data = [["N°","FECHA DE LA FACTURA O DUI","NIT PROVEEDOR","NOMBRE Y APELLIDOS/RAZON SOCIAL","N° DE LA FACTURA","N° DE DUI","N° DE AUTORIZACION","IMPORTE TOTAL DE LA COMPRA","IMPORTE NO SUJETO A CREDITO FISCAL","SUBTOTAL","DESCUENTOS, BONIFICACIONES Y REBAJAS OBTENIDAS","IMPORTE BASE PARA CREDITO FISCAL","CREDITO FISCAL","CODIGO DE CONTROL","TIPO DE COMPRA"]]
			var sumaImporte=0,sumaImporteNo=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			for(var i=0;i<compras.length;i++){
				var columns=[];
				compras[i].fecha=new Date(compras[i].fecha);
				columns.push(i+1);
				columns.push(compras[i].fecha.getDate()+"/"+(compras[i].fecha.getMonth()+1)+"/"+compras[i].fecha.getFullYear());
				columns.push(compras[i].proveedor.nit);
				columns.push(compras[i].proveedor.razon_social);
				columns.push(compras[i].factura);
				columns.push(0);
				columns.push(compras[i].autorizacion);
				columns.push(compras[i].importe);
				columns.push(compras[i].ice);
				columns.push(compras[i].importe-compras[i].ice);
				var descuento=compras[i].descuento-compras[i].recargo+compras[i].excento;
				columns.push(descuento);
				columns.push(compras[i].sujeto_a_iva);
				columns.push(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100);
				columns.push(compras[i].codigo_control);
				columns.push(1);
				sumaImporte=sumaImporte+compras[i].importe;
				sumaImporteNo=sumaImporteNo+compras[i].ice;
				sumaTotal=sumaTotal+(compras[i].importe-compras[i].ice);
				sumaDescuentos=sumaDescuentos+descuento;
				sumaImporteBase=sumaImporteBase+compras[i].sujeto_a_iva;
				sumaCredito=sumaCredito+(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100);
				data.push(columns);
				if(i+1==compras.length){
					columns=[];
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("TOTALES");
					columns.push(Math.round((sumaImporte)*100)/100);
					columns.push( Math.round((sumaImporteNo)*100)/100);
					columns.push( Math.round((sumaTotal)*100)/100);
					columns.push( Math.round((sumaDescuentos)*100)/100);
					columns.push( Math.round((sumaImporteBase)*100)/100);
					columns.push( Math.round((sumaCredito)*100)/100);
					data.push(columns);
				}
			}
      let nombre_archivo="compras_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
			
			let workbook = new Workbook();
			let worksheet = workbook.addWorksheet(nombre_archivo);
			data.forEach(d => {
				let row = worksheet.addRow(d);
			});

			const dobCol2 = worksheet.getColumn(2);
			dobCol2.width = 40;
			const dobCol3 = worksheet.getColumn(3);
			dobCol3.width = 20;

			workbook.xlsx.writeBuffer().then((data) => {
				let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				fs.saveAs(blob, nombre_archivo+".xlsx");
			})
			this.blockUI.stop();
    });
  }

  generarPdfLibroCompras(){
		this.blockUI.start();
		this.comprasService.obtenerDatosLibroCompras(this.filter).subscribe((datos:any)=>{
			var compras=datos.compras;
			var doc = new PDFDocument({margin:10,layout: 'landscape'});
			var stream = doc.pipe(blobStream());
			// draw some text
			this.dibujarCabeceraPDFLibroCompras(doc,datos,1);
			doc.font('Helvetica',8);
			var y=170,itemsPorPagina=12,items=0,pagina=1;
			var sumaImporte=0,sumaImporteNo=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
			var sumaSubImporte=0,sumaSubImporteNo=0,sumaSubTotal=0,sumaSubDescuentos=0,sumaSubImporteBase=0,sumaSubCredito=0;
			for(var i=0;i<compras.length && items<=itemsPorPagina;i++){
				doc.rect(40,y-10,720,30).stroke();
				compras[i].fecha=new Date(compras[i].fecha);
				doc.text(i+1,45,y);
				doc.text(compras[i].fecha.getDate()+"/"+(compras[i].fecha.getMonth()+1)+"/"+compras[i].fecha.getFullYear(),65,y);
				doc.text(compras[i].proveedor.nit,110,y);
				doc.text(compras[i].proveedor.razon_social,165,y-6,{width:100});
				doc.text(compras[i].factura,275,y);
				doc.text(0,320,y);
				doc.text(compras[i].autorizacion,335,y);
				doc.text(compras[i].importe,410,y);
				doc.text(compras[i].ice,450,y);
				doc.text(Math.round((compras[i].importe-compras[i].ice)*100)/100,480,y);
				var descuento=compras[i].descuento-compras[i].recargo+compras[i].excento;
				doc.text(descuento,520,y);
				doc.text(compras[i].sujeto_a_iva,565,y);
				doc.text(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100,605,y);
				doc.text(compras[i].codigo_control,640,y);
				doc.text(1,740,y);
				y=y+30;
				sumaSubImporte=sumaSubImporte+compras[i].importe;
				sumaSubImporteNo=sumaSubImporteNo+compras[i].ice;
				sumaSubTotal=sumaSubTotal+(compras[i].importe-compras[i].ice);
				sumaSubDescuentos=sumaSubDescuentos+descuento;
				sumaSubImporteBase=sumaSubImporteBase+compras[i].sujeto_a_iva;
				sumaSubCredito=sumaSubCredito+(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100);
				sumaImporte=sumaImporte+compras[i].importe;
				sumaImporteNo=sumaImporteNo+compras[i].ice;
				sumaTotal=sumaTotal+(compras[i].importe-compras[i].ice);
				sumaDescuentos=sumaDescuentos+descuento;
				sumaImporteBase=sumaImporteBase+compras[i].sujeto_a_iva;
				sumaCredito=sumaCredito+(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100);
				items++;
				
				if(items==itemsPorPagina || i+1==compras.length){
					doc.font('Helvetica-Bold',8);
					doc.text("SUBTOTALES",320,y);
					doc.text(Math.round((sumaSubImporte)*100)/100,400,y);
					doc.text(Math.round((sumaSubImporteNo)*100)/100,450,y);
					doc.text(Math.round((sumaSubTotal)*100)/100,480,y);
					doc.text(Math.round((sumaSubDescuentos)*100)/100,520,y);
					doc.text(Math.round((sumaSubImporteBase)*100)/100,565,y);
					doc.text(Math.round((sumaSubCredito)*100)/100,605,y);
					doc.rect(40,y-10,720,30).stroke();
					doc.font('Helvetica',8);
					sumaSubImporte=0;sumaSubImporteNo=0;sumaSubTotal=0;sumaSubDescuentos=0;sumaSubImporteBase=0;sumaSubCredito=0;
					
					if(i+1==compras.length){
						doc.font('Helvetica-Bold',8);
						doc.text("TOTALES",320,y+30);
						doc.text(Math.round((sumaImporte)*100)/100,400,y+30);
						doc.text( Math.round((sumaImporteNo)*100)/100,450,y+30);
						doc.text( Math.round((sumaTotal)*100)/100,480,y+30);
						doc.text( Math.round((sumaDescuentos)*100)/100,520,y+30);
						doc.text( Math.round((sumaImporteBase)*100)/100,565,y+30);
						doc.text( Math.round((sumaCredito)*100)/100,605,y+30);
						doc.rect(40,y-10+30,720,30).stroke();
						doc.font('Helvetica',8);
					}else{
						doc.addPage({margin:0,bufferPages: true,layout: 'landscape'});
						y=170;
						items=0;
						pagina=pagina+1;
						this.dibujarCabeceraPDFLibroCompras(doc,datos,pagina);
						doc.font('Helvetica',8);
					}
				}
			}
			doc.end();
			stream.on('finish', function() {
				var fileURL = stream.toBlobURL('application/pdf');
				window.open(fileURL,'_blank','location=no');
			});
			this.blockUI.stop();
		});
  }
  
  dibujarCabeceraPDFLibroCompras(doc,datos,pagina){
    doc.font('Helvetica-Bold',12);
		doc.text("LIBRO DE COMPRAS IVA ESTÁNDAR",0,25,{align:"center"});
		doc.font('Helvetica-Bold',8);
		doc.text("FOLIO "+pagina,720,25);
		doc.rect(40,60,720,40).stroke();
		doc.text("PERIÓDO FISCAL : ",65,70);
		doc.text("NOMBRE O RAZÓN SOCIAL : ",65,85);
		doc.text("NIT : ",440,85);
		doc.font('Helvetica',8);
		doc.text("AÑO "+this.filter.gestion.nombre,140,70);
    doc.text("MES "+this.filter.mes.nombre,200,70);
    let razon_social=(this.filter.sucursal.id==0?datos.empresa.razon_social:this.filter.sucursal.razon_social),nit=(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
		doc.text(razon_social,195,85);
		doc.text(nit,460,85);
		
		doc.rect(40,100,720,60).stroke();
		doc.font('Helvetica-Bold',8);
		doc.text("Nº",45,110);
		doc.text("Fecha de la Factura o DUI",65,110,{width:40});
		doc.text("NIT Proveedor",110,110,{width:50});
		doc.text("Nombre o Razón Social",165,110);
		doc.text("Nº de la Factura",275,110,{width:50});
		doc.text("Nº de DUI",320,110,{width:30});
		doc.text("Nº de Autorización",345,110,{width:50});
		doc.text("Importe Total de la Compra",400,110,{width:35});
		doc.text("Importe No Sujeto a Crédito Fiscal",440,110,{width:35});
		doc.text("Subtotal",480,110);
		doc.text("Descuentos, Bonificaciones y Rebajas",520,110,{width:40});
		doc.text("Importe Base para Crédito Fiscal",565,110,{width:35});
		doc.text("Crédito Fiscal I.V.A.",605,110,{width:35});
		doc.text("Código de Control",640,110);
		doc.text("Tipo de Compra",720,110,{width:35});
	}

	generarExcelLibroCompras2(){
			this.blockUI.start();
			this.comprasService.obtenerDatosLibroCompras(this.filter).subscribe((datos:any)=>{
				var compras=datos.compras;
				var data = [];
				let cabecera=["N°","ESPECIFICACION","NIT PROVEEDOR","NOMBRE O RAZON SOCIAL","CODIGO DE AUTORIZACION",
				"N° DE LA FACTURA","N° DUI/DIM","FECHA DE LA FACTURA/DUI/DIM","IMPORTE TOTAL DE LA COMPRA","IMPORTE ICE",
				"IMPORTE IEHD","IMPORTE IPJ","TASAS","OTROS NO SUJETOS AL IVA","IMPORTES EXENTOS",
				"IMPORTE COMPRAS GRABADAS A TASA CERO","SUBTOTAL","DESCUENTOS, BONIFICACIONES Y REBAJAS SUJETAS AL IVA",
				"IMPORTE GIFT CARD","IMPORTE BASE PARA CF","CREDITO FISCAL","TIPO COMPRA","CODIGO DE CONTROL"];
				//data.push(cabecera);
				var sumaImporte=0,sumaImporteIce=0,sumaImporteExp=0,sumaImporteGrab=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;
				for(var i=0;i<compras.length && compras[i].activa;i++){
					var columns=[];
					compras[i].fecha=new Date(compras[i].fecha);
					columns.push(i+1);
					columns.push(1);
					columns.push(compras[i].proveedor.nit);
					columns.push(compras[i].proveedor.razon_social);
					columns.push(compras[i].autorizacion);
					columns.push(compras[i].factura);
					columns.push(0);
					columns.push(compras[i].fecha.getDate()+"/"+(compras[i].fecha.getMonth()+1)+"/"+compras[i].fecha.getFullYear());
					columns.push(compras[i].importe);
					columns.push(compras[i].ice);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(0);
					columns.push(compras[i].total);
					columns.push(compras[i].descuento);
					columns.push(0);
					columns.push(compras[i].sujeto_a_iva);
					columns.push(Math.round((compras[i].sujeto_a_iva*0.13)*100)/100);
					columns.push(1);
					columns.push(compras[i].codigo_control);
					if(compras[i].activa){
						sumaImporte=sumaImporte+compras[i].importe;
						sumaImporteIce=0;
						sumaImporteExp=0;
						sumaImporteGrab=0;
						sumaDescuentos=sumaDescuentos+compras[i].descuento;
						sumaImporteBase=sumaImporteBase+compras[i].total;
						sumaCredito=sumaCredito+(Math.round((compras[i].total*0.13)*100)/100);
					}
					data.push(columns);
		}
		let nombre_archivo="compras_"+this.filter.mes.nombre_corto+this.filter.gestion.nombre_corto+"_"+(this.filter.sucursal.id==0?datos.empresa.nit:this.filter.sucursal.nit);
				
				let workbook = new Workbook();
				let worksheet = workbook.addWorksheet(nombre_archivo);
				let headerRow = worksheet.addRow(cabecera);
				headerRow.eachCell((cell, number) => {
					cell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: '4167B8' },
						bgColor: { argb: '' }
					}
					cell.font = {
						bold: true,
						color: { argb: 'FFFFFF' },
						size: 12
					}
					cell.alignment ={ wrapText: true };
				})
				data.forEach(d => {
					let row = worksheet.addRow(d);
				});

				for(let i=1;i<=cabecera.length;i++){
					worksheet.getColumn(i).width = 15;
					worksheet.getColumn(i).alignment = { wrapText: true,vertical: 'middle', horizontal: 'center' };
				}

				workbook.xlsx.writeBuffer().then((data) => {
					let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					fs.saveAs(blob, nombre_archivo+".xlsx");
				})
				this.blockUI.stop();
		});
  	}

}
