<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Proveedores
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Proveedores
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			        </button>
			        <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoProveedor()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <div class="export btn-group">
                <a href="{{global_variable.SERVER_URL+'recursos/EJEMPLO-DATOS-PROVEEDORES.xlsx'}}"  class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                  <i class="fa fa-download text-green-d1"></i>
                  <span class="caret"></span>
				        </a>
                <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-proveedores')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                  <i class="fa fa-upload text-red fileUpload-button"></i>
                  <input id="subir-excel-proveedores" style="display:none" type="file" class="form-control" (change)="subirExcelProveedores($event)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </button>
                <!--<div class="dropdown-menu dropdown-menu-right" style="">
                  <a class="dropdown-item " href="#" data-type="json">JSON</a>
                  <a class="dropdown-item " href="#" data-type="xml">XML</a>
                  <a class="dropdown-item " href="#" data-type="csv">CSV</a>
                  <a class="dropdown-item " href="#" data-type="txt">TXT</a>
                  <a class="dropdown-item " href="#" data-type="sql">SQL</a>
                  <a class="dropdown-item " href="#" data-type="excel">MS-Excel</a>
                </div>-->
			        </div>
              <div class="keep-open btn-group show" title="Columns">
                <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
                  <i class="fa fa-wrench text-blue-d1"></i>
                  <span class="caret"></span>
                </button>
      
                <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                  <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                    <div class="card bgc-primary brc-primary radius-0">
                      <div class="card-header">
                      <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                      </div>
            
                      <div class="card-body p-0 bg-white">
                      <table class="table table-striped table-hover mb-0">
                        <tbody>
                          <tr *ngIf="aplicacion.puede_modificar">
                            <td class="text-dark-m2">
                            Configuración Vista
                            </td>
                            <td class="text-dark-m2">
                              <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                    </div>
                </div>
                <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
              </div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="proveedores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th id="cabecera_codigo" class="center" *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th id="cabecera_identificacion" class="center" *ngIf="configuracion_pagina.getConfiguration().identificacion.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='identificacion' && this.direction=='asc','desc':this.column=='identificacion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('identificacion')">Identificación</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().razon_social.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='razon_social' && this.direction=='asc','desc':this.column=='razon_social' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('razon_social')">Razón Social</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().nit.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nit' && this.direction=='asc','desc':this.column=='nit' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nit')">Nit</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().direccion.show" class="center">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='direccion' && this.direction=='asc','desc':this.column=='direccion' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('direccion')">Dirección</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().telefono.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='telefono' && this.direction=='asc','desc':this.column=='telefono' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('telefono')">Teléfono</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #proveedores *ngFor="let proveedor of proveedores; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td rel="cabecera_codigo" *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{proveedor.codigo}}
                </td>
                <td rel="cabecera_nombre" *ngIf="configuracion_pagina.getConfiguration().identificacion.show">
                    {{proveedor.identificacion}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().razon_social.show">{{proveedor.razon_social}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().nit.show">{{proveedor.nit}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().direccion.show">{{proveedor.direccion}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().telefono.show">{{proveedor.telefono}}</td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarProveedor(proveedor)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					          </a>   
                    <a title="Historico de compras proveedor" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="abrirPopupHistoricoCompras(proveedor)">
                      <i class="ace-icon fas fa-search-dollar bigger-130"></i>
                    </a>    
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(proveedor)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="proveedores.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>