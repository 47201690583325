<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Cliente</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarRegistroCliente()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="cliente.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input (blur)="establecerRazonSocial()" name="nombre" required type="text" id="reg_nit" [(ngModel)]="cliente.identificacion" placeholder="identificacion" class="form-control" />
        </div>
    </div>
    <ng-container *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea">
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Tipo Documento</label>
            </div>
            <div class="col-sm-12 col-md-3">
                <select [compareWith]="compararObjectos" class="form-control" required name="tipo_documento" [(ngModel)]="cliente.tipo_documento">
					<option [ngValue]="tipo_documento" *ngFor="let tipo_documento of sin_documentos_identidad">{{tipo_documento.nombre}}</option>
				</select>
            </div>
            <div class="col-sm-12 col-md-3">
                <label>Complemento</label>
            </div>
            <div class="col-sm-12 col-md-3">
                <input required type="text" id="complemento" name="complemento" [(ngModel)]="cliente.complemento" placeholder="Complemento" class="form-control" />
            </div>
        </div>
    </ng-container>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_NIT)}}</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input required type="text" id="reg_nit" name="nit" [(ngModel)]="cliente.nit" placeholder="nit" class="form-control" />
            </div>
        </div>
    <ng-container *ngIf="usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea">
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}}</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input required type="text" id="reg_razon_social" name="razon_social" [(ngModel)]="cliente.razon_social" placeholder="Razón Social" class="form-control"/>
            </div>
        </div>
    </ng-container>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Telefono</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="reg_telefono" name="telefono1" [(ngModel)]="cliente.telefono1" placeholder="Telefono" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Correo electrónico</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="email" id="correo_electronico" name="correo_electronico" [(ngModel)]="cliente.email" placeholder="Correo electrónico" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Direccion</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input type="text" id="reg_direccion" name="direccion" [(ngModel)]="cliente.direccion" placeholder="Direccion" class="form-control"/>
        </div>
    </div>
    <hr *ngIf="usuario.empresa.usar_clientes_sucursal">
    <div class="row" *ngIf="usuario.empresa.usar_clientes_sucursal">
        <div class="col-sm-12 col-md-3">
            <label>Sucursal</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <select [compareWith]="compararObjectos" id="cliente_sucursal" name="clientesucursal" class="form-control" [(ngModel)]="cliente.sucursal">
                <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
            </select>
        </div>
    </div>
</div>