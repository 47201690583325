import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from 'ngx-order-pipe';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';
import { ComprasService } from '../../services/compras/compras.service';
import { ProveedoresService } from '../../services/proveedores/proveedores.service';
import { PagoCompraComponent } from '../pago-compra/pago-compra.component';
import { VistaCompraComponent } from '../vista-compra/vista-compra.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

declare const $:any;

@Component({
  selector: 'app-proveedores-historico-compras',
  templateUrl: './proveedores-historico-compras.component.html',
  styleUrls: ['./proveedores-historico-compras.component.css']
})
export class ProveedoresHistoricoComprasComponent extends BaseComponent implements OnInit {

  proveedor:any={compras_filtradas:[],compras:[]};
  mostrar_filtro_fechas:boolean=true;
  mostrar_boton_busqueda:boolean=true;
  titulo_reporte:string="HISTÓRICO DE COMPRAS PROVEEDOR";
  totalgeneral:number=0;
  total_importe:number=0;
  total_a_cuenta:number=0;
  total_saldo:number=0;
  cuentas_liquidadas:boolean=false;

  aplicacion_cuentas_por_pagar:any;
  modalPagos:NgbModalRef;

  pago_general:any={};
  cliente_contacto:any=null;
  busqueda_contacto:any={};

  compras_filtradas:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  pago_general_modal:NgbModalRef;
  @ViewChild('pago_general_modal')
  private pago_general_modal_ref: TemplateRef<any>;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private proveedoresService:ProveedoresService,
    public usuarioService:UsuarioService,
    private pdfService:PdfService,
    private toastr: ToastrService,
    public ventaService:VentasService,
    private orderPipe: OrderPipe,
    private compraService:ComprasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

   ngOnInit(): void {
    let fecha_actual=new Date();
    this.filter={
      id_proveedor:this.proveedor.id,
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      cuentas_liquidadas:this.cuentas_liquidadas
    }

    this.aplicacion_cuentas_por_pagar= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_PAGAR)[0];

    if(!this.mostrar_filtro_fechas){
      this.obtenerCuentaProveedor();
    }
  }

  calcularTotales(){
    this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0;
    for(var i=0;i<this.proveedor.compras_filtradas.length;i++){
      this.total_importe=this.total_importe+this.proveedor.compras_filtradas[i].total;
      this.total_a_cuenta=this.total_a_cuenta+this.proveedor.compras_filtradas[i].a_cuenta;
      this.total_saldo=this.total_saldo+this.proveedor.compras_filtradas[i].saldo;
    }
  }

  async obtenerCuentaProveedor(){
    this.blockUI.start();
    let datosProveedor:any=await this.proveedoresService.obtenerCuentaProveedor(this.proveedor.id,this.filter.cuentas_liquidadas).toPromise();
    this.proveedor.compras=datosProveedor?datosProveedor.compras:[];
    this.proveedor.compras_filtradas=datosProveedor?datosProveedor.compras:[];
    this.proveedor.sucursal=this.proveedor.sucursal?this.proveedor.sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0];

    for(var i=0;i<this.proveedor.compras.length;i++){
      var fecha = new Date(this.proveedor.compras[i].fecha);
      this.proveedor.compras[i].totalgeneral=0;
      this.proveedor.compras[i].totalPago=0;
      if(i==0){
        this.proveedor.compras[i].totalgeneral = this.proveedor.compras[i].totalgeneral+ this.proveedor.compras[i].saldo;
      }else{
        this.proveedor.compras[i].totalgeneral = this.proveedor.compras[i-1].totalgeneral+ this.proveedor.compras[i].saldo;	
      }
      for(var f=0;f<this.proveedor.compras[i].pagosCompra.length;f++){
        this.proveedor.compras[i].pagosCompra[f].total = this.proveedor.compras[i].pagosCompra[f].saldo_anterior-this.proveedor.compras[i].pagosCompra[f].monto_pagado;			
      
        this.proveedor.compras[i].totalPago=this.proveedor.compras[i].totalPago+this.proveedor.compras[i].pagosCompra[f].monto_pagado;				
      }
      this.proveedor.compras[i].fecha_vencimiento=Util.sumaFecha(this.proveedor.compras[i].dias_credito,fecha);
    }

    var i=this.proveedor.compras.length>0?this.proveedor.compras.length-1:0;		
    this.totalgeneral = this.proveedor.compras.length>0?this.proveedor.compras[i].totalgeneral:0;		
    this.calcularTotales();
    this.blockUI.stop();
  }

  verDetallePagos(compra){
    var style=$("#"+compra.id).css("display");
    if(style=="none"){
      $("#"+compra.id).css("display","");
    }else{
      $("#"+compra.id).css("display","none");
    }
  }

  verCompra(compra){
    this.modalPagos = this.modalService.open(VistaCompraComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-compra', backdrop: 'static'});
    this.modalPagos.componentInstance.compra = compra;
    this.modalPagos.componentInstance.usuario = this.usuario;
    this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
      this.modalPagos.close();
    });
  }

  abrirPopupPagoCreditosCompra(compra){
    compra.proveedor=this.proveedor;
    this.modalPagos = this.modalService.open(PagoCompraComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalPagos.componentInstance.compra = compra;
    this.modalPagos.componentInstance.usuario = this.usuario;
    this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
      if(res){
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
          this.toastr.error(res.stack.substring(0,500));
        }else{
          this.toastr.success(res.mensaje);
          this.obtenerCuentaProveedor();
          this.getItems();
          this.modalPagos.close();
        }
      }else{
        this.modalPagos.close();
      }
      
    });
  }

  validarPago(){
    let res=true;
    if(!this.pago_general.importe || this.pago_general.importe<=0 || this.pago_general.importe>this.total_saldo){
      res=res && false;
      this.toastr.error("El valor del pago debe ser mayor a cero y menor o igual al saldo, intente con otro valor!");
    }
    if(!this.pago_general.fecha_texto || !this.pago_general.fecha_texto.year || !this.pago_general.fecha_texto.month || !this.pago_general.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el pago!");
    }
    return res;
  }

  async guardarPagoGeneral(){
    if(this.validarPago()){
      this.blockUI.start();
      var total_acumulado=this.pago_general.importe,compras_a_pagar=[];
      for(var i=0;i<this.proveedor.compras.length;i++){
        if(this.proveedor.compras[i].saldo>0 && (total_acumulado>=this.proveedor.compras[i].saldo || total_acumulado>0)){
          this.proveedor.compras[i].a_pagar=(total_acumulado>=this.proveedor.compras[i].saldo)?this.proveedor.compras[i].saldo:total_acumulado;
          compras_a_pagar.push(this.proveedor.compras[i]);
          total_acumulado=total_acumulado-this.proveedor.compras[i].saldo;
        }
      }
      var promesas=[];
      for(var i=0;i<compras_a_pagar.length;i++){
        promesas.push(this.compraService.guardarPagoCompra(compras_a_pagar[i].id,{ monto_pagado: compras_a_pagar[i].a_pagar,fecha:Util.convertirObjetoAfecha(this.pago_general.fecha_texto),observacion:"Pago en conjunto", id_usuario_cajero: this.usuario.id }).toPromise());
      }
      
      let me=this;
      Promise.all(promesas).then(function(datos){
        me.toastr.success("Pagos en conjunto registrados satisfactoriamente!");
        me.obtenerCuentaProveedor();
        me.getItems();
        me.pago_general_modal.close();
        me.blockUI.stop();
        if(me.usuario.empresa.usar_contabilidad){
          datos.forEach(function(dato, index){
            //me.contabilizarPago(dato.pago.monto_pagado,this.cliente,"Por pago de facturas en conjunto "+dato.venta.factura,dato.pago.id);
          });
        }
      });
    }
  }

  abrirPopupConfirmacionAnulacionPago(pago_compra){
    this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
    this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular el pago?";
    this.popupConfirmacion.componentInstance.data = pago_compra;
    
    this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
      if($e.confirm){
        this.anularPagoCompra($e.data);
      }
      this.popupConfirmacion.close();
        });
  }

  anularPagoCompra(pago_compra){
    this.compraService.anularPagoCompra(pago_compra.id).subscribe((res:any)=>{
      this.toastr.success(res.mensaje);
      this.obtenerCuentaProveedor();
      this.getItems();
      if(this.usuario.empresa.usar_contabilidad){
        
      }
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  abrirPopupPagoCreditosProveedor(){
    this.pago_general.importe=1;
    let fecha_actual=new Date();
    this.pago_general.fecha_texto={
      year: fecha_actual.getFullYear(), 
      month: (fecha_actual.getMonth()+1),
      day:fecha_actual.getDate()
    };
    this.pago_general_modal=this.modalService.open(this.pago_general_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.pago_general_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  calcularSaldoActualizado(){
    this.pago_general.saldo_actualizado=this.total_saldo-this.pago_general.importe;
  }

  imprimirPagoCompra(pago_compra){
    this.blockUI.start('Recuperando... espere por favor!');
    this.pdfService.imprimirReciboPagoCompra(pago_compra,this.usuario);
    this.blockUI.stop();
  }

  generarPdf(){
    this.blockUI.start();
    this.pdfService.imprimirHistoricoComprasProveedor(this.proveedor,this.usuario,this.titulo_reporte);
    this.blockUI.stop();
  }

  generarExcel(){		
    var data=this.generarExcelProveedor(this.proveedor,0);
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.titulo_reporte+"-"+this.proveedor.identificacion);
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, this.titulo_reporte+"-"+this.proveedor.identificacion.toUpperCase()+".xlsx");
    })
    this.blockUI.stop();
  }

  generarExcelProveedor(proveedor,tipoImprecion){
    var data = [["","",this.titulo_reporte],["PROVEEDOR :"+proveedor.identificacion.toUpperCase()],["FECHA","TRANSACCION","NRO. DOCUMENTO","DESCRIPCIÓN","IMPORTE","A CUENTA","SALDO"]]
    var total_importe=0,total_a_cuenta=0,total_saldo=0;

    for(var i=0;i<proveedor.compras.length;i++){
      var columns=[];
      
      total_importe= total_importe+proveedor.compras[i].total;
      total_a_cuenta= total_a_cuenta+proveedor.compras[i].a_cuenta;
      total_saldo= total_saldo+proveedor.compras[i].saldo;
      proveedor.compras[i].fecha=new Date(proveedor.compras[i].fecha);
      columns.push(proveedor.compras[i].fecha.getDate()+"/"+(proveedor.compras[i].fecha.getMonth()+1)+"/"+proveedor.compras[i].fecha.getFullYear());
      columns.push(proveedor.compras[i].tipo_documento.nombre);			
      columns.push(proveedor.compras[i].factura);
      var detalle="";
      for(var j=0;j<proveedor.compras[i].detallesCompra.length;j++){
        detalle=detalle+(proveedor.compras[i].detallesCompra[j].producto?proveedor.compras[i].detallesCompra[j].producto.nombre:proveedor.compras[i].detallesCompra[j].combo?proveedor.compras[i].detallesCompra[j].combo.nombre:proveedor.compras[i].detallesCompra[j].descripcion)+","
      }
      columns.push(detalle);
      columns.push(proveedor.compras[i].total);				
      columns.push(proveedor.compras[i].a_cuenta);				
      columns.push(proveedor.compras[i].saldo);				
      data.push(columns);
        if(tipoImprecion!=0 &&proveedor.compras[i].pagosCompra.length!=0)
        {
          for (var j = 0; j < proveedor.compras[i].pagosCompra.length; j++) {
            var columns2=[];
            let date=new Date(proveedor.compras[i].pagosCompra[j].createdAt);
            columns2.push("");
            columns2.push(date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear());
            columns2.push(proveedor.compras[i].pagosCompra[j].numero_documento);
            columns2.push("Pago a/c Factura nro.  "+proveedor.compras[i].factura+"("+proveedor.compras[i].pagosCompra[j].total+" -)");
            columns2.push(proveedor.compras[i].pagosCompra[j].total);
            data.push(columns2);
          }
        }
      
    }

    data.push(['','','TOTAL GENERAL',null,total_importe+"",total_a_cuenta+"",total_saldo+""]);
    return data;
  }

  generarExcelMayores(){
    var data = [["","",this.titulo_reporte],["PROVEEDOR :"+this.proveedor.identificacion.toUpperCase()],["FECHA","TRANSACCION","DESCRIPCIÓN","NRO. DOCUMENTO","DEBE","HABER","SALDO"]]
    var total_debe=0,total_haber=0,total_saldo=0;

    let mayores=[];
    for(var i=0;i<this.proveedor.compras.length;i++){
      mayores.push({
        fecha:this.proveedor.compras[i].fecha,
        transaccion:this.proveedor.compras[i].tipo_documento.nombre,
        numero_documento:this.proveedor.compras[i].factura,
        debe:this.proveedor.compras[i].total,
        haber:0,
        descripcion:this.proveedor.compras[i].observacion
      });
      for(let j=0;j<this.proveedor.compras[i].pagosCompra.length;j++){
        if(this.proveedor.compras[i].pagosCompra[j].activo){
          mayores.push({
            fecha:this.proveedor.compras[i].pagosCompra[j].fecha,
            transaccion:"PAGO",
            numero_documento:this.proveedor.compras[i].pagosCompra[j].numero_documento,
            debe:0,
            haber:this.proveedor.compras[i].pagosCompra[j].monto_pagado,
            descripcion:this.proveedor.compras[i].pagosCompra[j].observacion
          });
        }
      }
    }

    mayores=this.orderPipe.transform(mayores, 'fecha',false);

    for(var i=0;i<mayores.length;i++){
      var columns=[];
      total_debe=total_debe+mayores[i].debe;
      total_haber=total_haber+mayores[i].haber;
      total_saldo=total_debe-total_haber;
      /*total_importe= total_importe+ this.proveedor.compras[i].total;
      total_a_cuenta= total_a_cuenta+proveedor.compras[i].a_cuenta;
      total_saldo= total_saldo+proveedor.compras[i].saldo;*/
      mayores[i].fecha=new Date(mayores[i].fecha);
      columns.push(mayores[i].fecha.getDate()+"/"+(mayores[i].fecha.getMonth()+1)+"/"+mayores[i].fecha.getFullYear());
      columns.push(mayores[i].transaccion);			
      columns.push(mayores[i].descripcion);			
      columns.push(mayores[i].numero_documento);
      columns.push(mayores[i].debe);
      columns.push(mayores[i].haber);				
      columns.push(total_saldo);				
      data.push(columns);
    }

    data.push(['','','TOTAL GENERAL',null,total_debe+"",total_haber+"",(total_debe-total_haber)+""]);

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.titulo_reporte+"-"+this.proveedor.identificacion);
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, this.titulo_reporte+" MAYORES "+"-"+this.proveedor.identificacion.toUpperCase()+".xlsx");
    })
    this.blockUI.stop();
  }

  buscarHistorico(){
    this.blockUI.start();
    this.proveedoresService.obtenerHistoricoCompras(this).subscribe((proveedor:any)=>{
      this.proveedor=proveedor?proveedor:this.proveedor;
      this.proveedor.compras_filtradas=this.proveedor.compras?this.proveedor.compras:[];
      this.calcularTotales();
      this.proveedor.sucursal=this.proveedor.sucursal?this.proveedor.sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0];
      //this.pdfService.imprimirHistoricoVentasCliente(cliente,this.usuario);
      this.blockUI.stop();
    });
  }

}
