<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Pedidos
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Pedidos
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-9 col-md-9">
            <div class="row">
              <div class="col-12 col-md-12">
                <app-paginator-header [paginator]="this"></app-paginator-header>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="feini">Desde </label>&nbsp;
                            <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_inicio" ngbDatepicker #fi="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="input-group">
                            <label for="fefin">Hasta </label>&nbsp;
                            <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                    name="dp" [(ngModel)]="filter.fecha_fin" ngbDatepicker #d="ngbDatepicker">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>  
                    </div>
                    <ng-container *ngIf="usuario.empresa.usar_sucursales">
                      <div class="col-md-1 col-sm-12"><label>Sucursal </label></div>
                      <div class="col-md-3 col-sm-12">
                          <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="filter.sucursal">
                              <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                          </select>
                      </div>
                    </ng-container>
                    <div class="col-md-3 col-sm-12">
                        <label>Usuario </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="usuario_filtro" [(ngModel)]="filter.usuario">
                        <option [ngValue]="usuario_filtro" *ngFor="let usuario_filtro of filter.usuarios">{{usuario_filtro.nombre_usuario}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}}</label>
                      <select [compareWith]="compararObjectos" class="form-control" required name="tipo_despacho" [(ngModel)]="filter.tipo_despacho">
                        <option [ngValue]="tipo_despacho" *ngFor="let tipo_despacho of filter.tipos_despacho">{{tipo_despacho.nombre}}</option>
                      </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Validez </label>&nbsp;
                        <select [compareWith]="compararObjectos" class="form-control" required name="validez" [(ngModel)]="filter.validez">
                        <option [ngValue]="validez" *ngFor="let validez of filter.estados_validez">{{validez.nombre}}</option>
                        </select>
                    </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Estado </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="estado" [(ngModel)]="filter.estado">
                      <option [ngValue]="estado" *ngFor="let estado of filter.estados">{{estado.nombre}}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label>Tipo de Pago </label>
                    <select [compareWith]="compararObjectos" class="form-control" required name="tipo_pago" [(ngModel)]="filter.tipo_pago">
                      <option [ngValue]="tipo_pago" *ngFor="let tipo_pago of filter.tipos_pago">{{tipo_pago.nombre}}</option>
                    </select>
                  </div>
                    <div class="col-md-3 col-sm-12">
                        <label>{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_CLIENTE_RAZON_SOCIAL)}} </label>
                        <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.cliente">
                    </div>
                    <div class="col-md-3 col-sm-12">
                        <label>Producto </label>
                        <input class="form-control form-control-sm search-input" type="search" [(ngModel)]="filter.producto">
                    </div>
                    <div class="col-md-3 col-sm-12" *ngIf="usuario.empresa.usar_obsequios">
                      <label>¿Solo Obsequios? </label>
                      <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1 form-control form-control-sm" type="checkbox" [(ngModel)]="filter.solo_obsequios"/>
                    </div>
                </div>
              </div>
            </div>
		      </div>
          <div class="col-3 col-md-3">
            <div class="row">
              <div class="col-12">
                <button (click)="getSearch(text_search,null)" class="d-none d-sm-block btn btn-xs btn-app btn-primary my-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                  <i class="d-block h-4 fa fa fa-search text-150"></i>
                </button>
                <div class="columns columns-right btn-group float-right d-none d-sm-block">
                  <button *ngIf="usuario.empresa.usar_pedidos_restaurante" title="Nuevo" (click)="crearNuevoPedidoPanel()" class=" btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-primary"></i>
                  </button>
                  <button *ngIf="usuario.empresa.usar_pedidos_distribucion" title="Nuevo" (click)="crearNuevoPedido()" class=" btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                    <i class="fa fa-plus text-success"></i>
                  </button>
                  <div class="export btn-group" *ngIf="false">
                    <button class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" aria-label="Export"  type="button" title="Descargar excel importación" aria-expanded="false">
                      <i class="fa fa-download text-green-d1"></i>
                      <span class="caret"></span>
                    </button>
                    <button *ngIf="aplicacion.puede_crear" (click)="clickBotonSubir('subir-excel-productos')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                      <i class="fa fa-upload text-red fileUpload-button"></i>
                      <input id="subir-excel-productos" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </button>
                  </div>
                  <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                    <i class="fa fa-wrench text-blue-d1"></i>
                    <span class="caret"></span>
                  </button>
                  <button *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea" (click)="verEventosSignificativos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-purple btn-a-outline-purple py-1" type="button" title="Lista Eventos Significativos" aria-expanded="true">
                    <i class="fas fa-cloud-meatball text-purple-d1"></i>
                    <span class="caret"></span>
                  </button>
  
                  <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                    <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                      <div class="card bgc-primary brc-primary radius-0">
                        <div class="card-header">
                        <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                        </div>
              
                        <div class="card-body p-0 bg-white">
                        <table class="table table-striped table-hover mb-0">
                          <tbody>
                            <tr>
                              <td class="text-dark-m2">
                              Configuración Aplicacion
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                              </td>
                              <td class="text-dark-m2" *ngIf="usuario.empresa.punto_venta_offline">
                                <a (click)="abrirEliminacionItemsServidor()" href="javascript:void(0)" class="text-blue"><i class="fa fa-calendar text-danger"></i></a>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-dark-m2">
                                Resumen Por Clientes
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="descargarExcelResumenDespachoPorClientes()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                              </td>
                              <td>
                                <a (click)="descargarPdfResumenDespacho()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-dark-m2">
                                Resumen Por Usuarios
                              </td>
                              <td class="text-dark-m2">
                                <a (click)="descargarExcelResumenDespachoPorUsuarios()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                      </div>
                  </div>
                  <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
                </div>
              </div>
            </div>
            <hr>
            <div class="row" *ngIf="false">
              <div class="col-12">
                <div class="columns columns-right btn-group float-right">
                  <div class="keep-open btn-group show" title="Columns">
                    <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Opciones" title="Acciones" aria-expanded="true">
                      <i class="fa fa-wrench text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>
                    <button (click)="verPagosVenta(null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" title="Lista Pagos Ventas" aria-expanded="true">
                      <i class="fa fa-list-alt text-blue-d1"></i>
                      <span class="caret"></span>
                    </button>
                    <button (click)="verVentasBorrador()" class="btn btn-outline-success btn-smd bgc-white btn-h-light-success btn-a-outline-success py-1" type="button" title="Lista de ventas borrador" aria-expanded="true">
                      <i class="fas fa-eraser text-success"></i>
                      <span class="caret"></span>
                    </button>
    
                    <div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
                      <div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
                        <div class="card bgc-primary brc-primary radius-0">
                          <div class="card-header">
                          <h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
                          </div>
                
                          <div class="card-body p-0 bg-white">
                          <table class="table table-striped table-hover mb-0">
                            <tbody>
                              <tr>
                                <td class="text-dark-m2">
                                Configuración Aplicación
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-dark-m2">
                                  Descarga Ventas
                                </td>
                                <td>
                                  ¿Con Detalle?
                                  <label>
                                    <input name="datos_con_detalle" [(ngModel)]="datos_con_detalle" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                  </label>
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="generarExcelFiltroVentas()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                                </td>
                                <td>
                                  <a (click)="generarPdfFiltroVentas()" href="javascript:void(0)" class="text-red"><i class="fa fa-file-pdf text-danger"></i></a>
                                </td>
                              </tr>
                              <tr>
                                <td class="text-dark-m2">
                                  Ventas Productos por Grupo
                                </td>
                                <td class="text-dark-m2">
                                  <a (click)="generarExcelVentasGrupos()" href="javascript:void(0)" class="text-green"><i class="fa fa-file-excel text-success"></i></a>
                                </td>
                              </tr>
                              <tr *ngIf="aplicacion.puede_crear">
                                <td class="text-dark-m2">
                                  Subida ventas App
                                </td>
                                <td class="text-dark-m2">
                                  <button (click)="clickBotonSubir('subir-datos-app')" class="fileUpload btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                                    <i class="fa fa-upload text-red fileUpload-button"></i>
                                    <input id="subir-datos-app" (change)="subirVentasApp($event)" style="display:none" type="file" class="form-control" placeholder="Upload file" accept=".snapquick">
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="usuario.empresa.usar_mesas">
          <div class="col-lg-2 col-md-2 col-sm-2 col-4 p-1" *ngFor="let mesa of mesas | orderBy:'numero'">
            <div  class="pos-rel d-style my-1 radius-3 shadow-sm overflow-hidden bgc-brown-m3">
              <a href="javascript:void(0)" class="show-lightbox">
                <img  src="{{global_variable.SERVER_URL+'img/table.png'}}" class="w-100 d-zoom-2" data-size="1200x800" />
              </a>
              <div class="v-hover position-center h-100 w-100 bgc-dark-grad" (click)="verMesa(mesa)"></div>

              <div class="position-tl text-right opacity-1 mr-2px mb-2px text-180">
                <span class="badge badge-primary">{{mesa.numero}}</span><br />
              </div>
              <div class="mr-hover position-tr h-100 bgc-black-tp4 p-25">
                <div class="d-flex flex-md-column justify-content-center action-buttons text-110">
                  <a title="Liberar Mesa" href="javascript:void(0)" (click)="liberarMesa(mesa)"><i class="fas fa-external-link-square-alt text-blue-m2"></i></a>
                  <a title="Cambiar Mesa" href="javascript:void(0)" (click)="abrirPopupCambioMesa(mesa)" *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_OCUPADO"><i class="fas fa-exchange-alt text-success-m2"></i></a>
                  <a title="Anular pedido" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionMesaPedido(mesa)" *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_OCUPADO" ><i class="fa fa-trash text-danger-m3"></i></a>
                </div>
              </div>
              <div class="position-bl text-right opacity-1 mr-2px mb-2px">
                <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_OCUPADO" class="badge badge-danger">{{mesa.estado.nombre}}</span>
                <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_DISPONIBLE" class="badge badge-success">{{mesa.estado.nombre}}</span>
                <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_RESERVADO" class="badge badge-warning">{{mesa.estado.nombre}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header">
          <table></table>
        </div>
        <div class="fixed-table-body table-responsive">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="pedidos.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
                <tr>
                    <th>Nº</th>
                    <th *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal.show">Sucursal</th>
                    <th *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show">{{obtenerTextoEmpresa(global_variable.Dictionary.TEXTO_PEDIDO_CATEGORIA)}}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tiempo_ingreso.show">Fecha Hora Ingreso</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha_salida.show">Fecha Hora Entrega</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_pedido.show">Num. Pedido</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().identificacion_cliente.show">Cliente</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show">Contacto Cliente</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().mesa.show && usuario.empresa.usar_mesas">Mesa</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show">Total</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show">Usuario</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activo.show">Validez</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show">Estado</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tiempo_despacho.show">Tiempo Despacho</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">Tipo de Pago</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show">Observación</th>
                    <th data-field="tools">
                      <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                      <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let pedido of pedidos; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal.show">{{pedido.sucursal}}</td>
                    <td *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show">{{pedido.tipo_despacho}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tiempo_ingreso.show">{{pedido.tiempo_ingreso | date:"dd/MM/yyyy HH:mm"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().fecha_salida.show">{{pedido.tiempo_salida | date:"dd/MM/yyyy HH:mm"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().numero_pedido.show">{{pedido.numero_pedido}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().identificacion_cliente.show">{{(pedido.cliente && pedido.cliente==pedido.cliente_razon_social)?pedido.cliente:(pedido.cliente?pedido.cliente:"") + " "+ pedido.cliente_razon_social}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show">{{pedido.contacto_cliente?pedido.contacto_cliente:''}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().mesa.show && usuario.empresa.usar_mesas">{{pedido.mesas}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().total.show">{{pedido.total}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().usuario.show">{{pedido.usuario?pedido.usuario:''}}</td>
                    <td *ngIf="pedido.activo==1 && configuracion_pagina.getConfiguration().activo.show"><span class="badge badge-sm badge-success">ACTIVO</span></td>
                    <td *ngIf="pedido.activo==0 && configuracion_pagina.getConfiguration().activo.show"><span class="badge badge-sm badge-danger">ANULADO</span></td>
                    <td *ngIf="configuracion_pagina.getConfiguration().estado.show"><span [ngClass]="{'badge-danger':pedido.estado_nombre_corto=='PENDIENTE','badge-success':pedido.estado_nombre_corto=='ENTREGADO','badge-warning':pedido.estado_nombre_corto=='PORCOMPLETAR','btn-pink':pedido.estado_nombre_corto=='ENTREGADOINC'}" class="badge badge-sm">{{pedido.estado_nombre_corto}}</span> <i *ngIf="!pedido.id_venta && pedido.activo==1" class="fa fa-question-circle"></i> </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tiempo_despacho.show">{{pedido.tiempo_despacho | date:"HH:mm - dd/MM/yyyy"}}</td>
                    <td *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show">
                      <span *ngIf="pedido.nombre_corto_tipo_pago==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="badge btn-purple">{{pedido.tipo_pago?pedido.tipo_pago:''}}</span>
                      <span *ngIf="pedido.nombre_corto_tipo_pago==global_variable.Dictionary.TIPO_PAGO_CONTADO" class="badge btn-pink">{{pedido.tipo_pago?pedido.tipo_pago:''}}</span>
                      <span *ngIf="pedido.nombre_corto_tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CONTADO && pedido.nombre_corto_tipo_pago!=global_variable.Dictionary.TIPO_PAGO_CREDITO" class="badge btn-pink ">{{pedido.tipo_pago?pedido.tipo_pago:''}}</span>
                  </td>
                    <td *ngIf="configuracion_pagina.getConfiguration().observacion.show">{{pedido.observacion}}</td>
                    <td>
                        <div class="d-none d-lg-inline text-muted">
                          <a [disabled]="!aplicacion.puede_modificar" *ngIf="(!pedido.id_venta && pedido.activo==1 && usuario.empresa.usar_pedidos_restaurante)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarPedido(pedido)">
                                <i class="ace-icon fa fa-edit bigger-130"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verPedido(pedido)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a title="Generar Venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="pedido.activo==1  && !pedido.id_venta && !usuario.empresa.usar_despachos" (click)="generarVentaPedido(pedido)">
                                <i class="fas fa-money-bill-alt"></i>
                            </a>
                            <a title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="pedido.activo==1 && pedido.id_venta && pedido.nombre_corto_tipo_pago==global_variable.Dictionary.TIPO_PAGO_CRE && pedido.saldo>0 && !usuario.empresa.usar_despachos" (click)="crearPagoVenta(pedido)">
                              <i class="fas fa-dollar-sign"></i>
                            </a>
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="imprimirPedido(pedido)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="pedido.id_venta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="imprimirVenta(pedido.id_venta,false)">
                              <i class="fa fa-print bigger-260 red2"></i>
                            </a>
                            <a *ngIf="pedido.activo==1 && usuario.empresa.usar_despachos && pedido.estado_nombre_corto==global_variable.Dictionary.ESTPED_PCOMP" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_ENTGDINC,mensaje:'Esta acción cambiara el estado del pedido de POR COMPLETAR a ENTREGADO INCOMPLETO, ¿Esta seguro de realizarlo?'})">
                                <i class="fa fa-exchange-alt"></i>
                            </a>
                            <a *ngIf="pedido.activo==1 && pedido.estado_nombre_corto==global_variable.Dictionary.ESTPED_PEND" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionCambioEstadoPedido({pedido:pedido,nombre_corto_estado:global_variable.Dictionary.ESTPED_ENTGD,mensaje:'Esta acción cambiara el estado del pedido de PENDIENTE a ENTREGADO, ¿Esta seguro de realizarlo?'})">
                              <i class="fa fa-exchange-alt"></i>
                            </a>
                            <a *ngIf="pedido.id_venta && pedido.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioCorreo({id:pedido.id_venta,cliente:{email:pedido.cliente_email}})">
                              <i class="ace-icon fa fa-envelope bigger-130"></i>
                            </a>
                            <a *ngIf="pedido.activo==1 && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pedido)">
                                <i class="fa fa-trash-alt"></i>
                            </a>
                            <!--<a title="Cobrar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" *ngIf="pedido.tipoPago.nombre==global_variable.Dictionary.TIPO_PAGO_CREDITO && pedido.saldo>0 && pedido.activo" (click)="crearPagoVenta(pedido)">
                                <i class="fas fa-money-bill-alt"></i>
                            </a>
                            
                            <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verVenta(pedido)">
                                <i class="ace-icon fa fa-search-plus bigger-130"></i>
                            </a>
                            <a *ngIf="pedido.tipoPago.nombre==global_variable.Dictionary.TIPO_PAGO_CREDITO" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="verPagosVenta(pedido)">
                                <i class="fa fa-list-alt bigger-130"></i>
                            </a>
                            <a *ngIf="pedido.activo && usuario.empresa.usar_devoluciones_ventas" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="verDevolucionesVenta(pedido)">
                                <i class="fa fa-list bigger-130"></i>
                            </a>
                            <a *ngIf="pedido.pedido==null && usuario.empresa.usar_inventario && pedido.activo && !pedido.descontar_almacen" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" e-disabled="!aplicacion.puede_modificar" (click)="abrirPopupConfirmacionExtraccion(pedido)">
                                <i class="ace-icon fa fa-gift bigger-130"></i>
                            </a>
                            <a *ngIf="!pedido.activo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" (click)="copiarVenta(pedido)">
                                <i class="ace-icon fa fa-copy bigger-130"></i>
                            </a>
                            <a *ngIf="pedido.activo && aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pedido)">
                              <i class="fa fa-trash-alt"></i>
                            </a>
                            <a *ngIf="pedido.activo && usuario.empresa.id==6" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)" e-disabled="!aplicacion.puede_crear" (click)="abrirPopupConfirmacionEnvioCorreo(pedido)">
                                <i class="ace-icon fa fa-envelope bigger-130"></i>
                            </a>-->
                        </div>

                        <div class='dropdown d-inline-block d-lg-none dd-backdrop dd-backdrop-none-md'>
                          <a href='#' class='btn btn-outline-warning btn-xs radius-round dropdown-toggle' data-toggle="dropdown"><i class="fa fa-cog text-110"></i></a>
                          <div class="dropdown-menu dd-slide-up dd-slide-none-md">
                            <div class="dropdown-inner">
                              <a *ngIf="usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVentaConsumoAgua(pedido)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a *ngIf="!usuario.empresa.usar_consumo_de_agua" class="dropdown-item" href="javascript:void(0)" (click)="imprimirVenta(pedido.id_venta,false)">
                                <i class="fa fa-print text-success mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Imprimir
                              </a>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="verPedido(pedido)">
                                  <i class="fa fa-search-plus text-blue-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Ver
                              </a>
                              <a *ngIf="pedido.activo" class="dropdown-item" [disabled]="!aplicacion.puede_eliminar" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(pedido)">
                                <i class="fa fa-trash text-danger-m1 mr-1 bgc-white-tp1 radius-round p-2 w-4"></i>Anular
                              </a>
                            </div>
                          </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="pedidos.length>0">
                    <th></th>
                    <td *ngIf="usuario.empresa.usar_sucursales && configuracion_pagina.getConfiguration().sucursal.show"></td>
                    <th *ngIf="(usuario.empresa.usar_pedidos_restaurante || usuario.empresa.usar_pedidos_en_linea) && configuracion_pagina.getConfiguration().despacho.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tiempo_ingreso.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().fecha_salida.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().numero_pedido.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().identificacion_cliente.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().contacto_cliente.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().mesa.show && usuario.empresa.usar_mesas"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().total.show"><b>TOTAL</b>: {{sumarMonto().toFixed(2) }}</th>
                    <th *ngIf="configuracion_pagina.getConfiguration().usuario.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().activo.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().estado.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tiempo_despacho.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().tipo_pago.show"></th>
                    <th *ngIf="configuracion_pagina.getConfiguration().observacion.show"></th>
                    <th></th>											
                </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="pedidos.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>
<footer class="d-sm-none footer footer-sm footer-fixed">
  <div class="footer-inner">
    <div class="btn-group d-flex h-100 mx-2 border-x-1 border-t-2 brc-primary-m3 bgc-default-l4 radius-t-1 shadow">
      <!--<button class="btn btn-outline-primary btn-h-lighter-primary btn-a-lighter-primary border-0">
        <i class="fas fa-sliders-h text-blue-m1 text-120"></i>
      </button>-->

      <button (click)="getSearch(text_search,null)" class="btn btn-outline-warning btn-h-lighter-warning btn-a-lighter-warning border-0" data-toggle="collapse" data-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle navbar search">
        <i class="fa fa-search text-warning text-120"></i>
      </button>

      <button *ngIf="usuario.empresa.usar_pedidos_distribucion" (click)="crearNuevoPedido()" class="btn btn-outline-purple btn-h-lighter-purple btn-a-lighter-purple border-0">
        <i class="fa fa-plus-circle text-purple-m2 text-120"></i>
      </button>

      <button *ngIf="usuario.empresa.usar_pedidos_restaurante" (click)="crearNuevoPedidoPanel()" class="btn btn-outline-purple btn-h-lighter-purple btn-a-lighter-purple border-0">
        <i class="fa fa-plus-circle text-purple-m2 text-120"></i>
      </button>

      

      <app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>

      <!--<button class="btn btn-outline-brown btn-h-lighter-brown btn-a-lighter-brown border-0 mr-0">
        <i class="fa fa-bell text-brown-m1 text-120"></i>
      </button>-->
    </div>
  </div>
</footer>


<canvas id="qr-code" style="display:none" width="100" height="100"></canvas>

<ng-template #cambiomesa let-modal>
  <div class="modal-header">
  <div class="col-md-6 col-xs-6">
    <h4 class="widget-title">Cambio de Mesa</h4>
  </div>
  <div class="col-md-6 col-xs-6">
    <button [disabled]="!mesaAcambiar" class="btn btn-primary" type="button" (click)="cambiarMesa(mesaAcambiar)">
      <i class="fa fa-save align-top bigger-110"></i>
    </button>&nbsp;
    <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
      <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-1">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label>De Mesa Nro: </label>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
        <div  class="pos-rel d-style my-1 radius-3 shadow-sm overflow-hidden bgc-brown-m3">
          <a href="javascript:void(0)" class="show-lightbox">
            <img src="{{global_variable.SERVER_URL+'img/table.png'}}" class="w-100 d-zoom-2" data-size="1200x800" />
          </a>
          <div class="position-tl text-right opacity-1 mr-2px mb-2px text-180">
            <span class="badge badge-primary">{{mesa.numero}}</span><br />
          </div>
          <div class="position-bl text-right opacity-1 mr-2px mb-2px">
            <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_OCUPADO" class="badge badge-danger">{{mesa.estado.nombre}}</span>
            <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_DISPONIBLE" class="badge badge-success">{{mesa.estado.nombre}}</span>
            <span *ngIf="mesa.estado.nombre_corto==global_variable.Dictionary.ESTADO_MESA_RESERVADO" class="badge badge-warning">{{mesa.estado.nombre}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-6 p-1">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <label>Cambiar a Mesa Nro: </label>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-12 p-1">
        <div  class="pos-rel d-style my-1 radius-3 shadow-sm overflow-hidden bgc-brown-m3">
          <a href="javascript:void(0)" class="show-lightbox">
            <img  src="{{global_variable.SERVER_URL+'img/table.png'}}" class="w-100 d-zoom-2" data-size="1200x800" />
          </a>
          <div class="position-tl text-right opacity-1 mr-2px mb-2px text-180">
            <select class="badge badge-primary" [compareWith]="compararObjectos" id="cambiomesa" name="cambiomesa" class="form-control" [(ngModel)]="mesaAcambiar">
              <option [ngValue]="mesa" *ngFor="let mesa of obtenerMesasLibres()">{{mesa.numero}}</option>
            </select>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</ng-template>

<ng-template #limipeza_datos_servidor let-modal>
  <div class="modal-header">
  <div class="col-md-6 col-xs-6">
    <h4 class="widget-title">Limpiar Datos Servidor</h4>
  </div>
  <div class="col-md-6 col-xs-6">
    <button [disabled]="!filter.fecha_limpieza" class="btn btn-danger" type="button" (click)="eliminarDatosServidor()">
      <i class="fa fa-trash align-top bigger-110"></i>
    </button>&nbsp;
    <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
      <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="input-group">
        <label for="feini">Fecha </label>&nbsp;
        <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                name="dp" [(ngModel)]="filter.fecha_limpieza" ngbDatepicker #fl="ngbDatepicker">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="fl.toggle()" type="button"></button>
        </div>
      </div> 
    </div>
  </div>

</div>
</ng-template>