import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { GeneralService } from '../../../base/services/general/general.service';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { RepuestosService } from '../../services/repuestos/repuestos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { Util } from 'src/app/utils/utils';
import * as XLSX from 'xlsx';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-repuestos',
  templateUrl: './repuestos.component.html',
  styleUrls: ['./repuestos.component.css']
})
export class RepuestosComponent extends BaseComponent implements OnInit {

  tipo_grupo:any;
  tipo_subgrupo:any;
  repuesto:any;
  repuestos:any[]=[];
  productos_importacion:any[];

  productosImportacion:NgbModalRef;
  @ViewChild('importacionproductos')
  private importacionproductos: TemplateRef<any>;

  @ViewChildren('productos') productos_lectura: QueryList<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    private repuestosService:RepuestosService,
    public usuarioService:UsuarioService,
    public excelService:ExcelService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private empresaService:EmpresaService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }
  
      ngOnInit(): void {
      this.init();
      this.buscarAplicacion(this.router.url.substring(1));
      this.configuracion_pagina={};
      this.filter={
        id_empresa:this.usuario.id_empresa,
        grupo: {nombre:"TODOS",id:0},//this.grupos_filtro[0],
        subgrupo:{nombre:"TODOS",id:0},//this.subgrupos_filtro[0],
        grupos_filtro:[{nombre:"TODOS",id:0}],
        subgrupos_filtro:[{nombre:"TODOS",id:0}]
      }
      this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_REPUESTOS);
      this.obtenerLlantas();
      this.obtenerGruposProductosEmpresa();
      this.obtenerSubGruposProductosEmpresa();
      /*if(this.usuario.empresa.usar_facturacion){
        this.obtenerListaProductosSin();
      }*/
  
      /*this.tabla_producto_general={
        publicar_panel:false,
        actilet_inventario:false
      }*/
      
    }
  
    ngAfterViewInit() {
      //aplicarScriptPaginaProductos();	
      resaltarPestañaMenu(this.router.url.substring(1)+"1");
      this.productos_lectura.changes.subscribe(t => {
        aplicarVisorImagenes("imagen-producto-vista");
      });
      this.obtenerTextosEmpresa();
    }
    
    obtenerLlantas(){
      this.column = "codigo";
      this.getSearch(this.text_search,null);
    }
    
    getItems(){
      this.blockUI.start('Recuperando... espere por favor!');
      this.repuestosService.obtenerListaRepuestos(this).subscribe((dato:any) => {
        this.setPages(dato.paginas);
        this.repuestos=dato.productos;
        for(let i=0;i<this.repuestos.length;i++){
          this.repuestos[i].con_aro=this.repuestos[i].con_aro==1?true:false;
        }
        this.blockUI.stop();
      });
    }

    obtenerGruposProductosEmpresa(){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").subscribe((tipoGrupoEmpresa:any) => {
        this.tipo_grupo=tipoGrupoEmpresa;
        this.filter.grupos_filtro=this.filter.grupos_filtro.concat(tipoGrupoEmpresa.clases);
      });
    }
  
    obtenerSubGruposProductosEmpresa(){
      this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((tipoSubgrupoEmpresa:any) => {
        this.tipo_subgrupo=tipoSubgrupoEmpresa;
        this.filter.subgrupos_filtro=this.filter.subgrupos_filtro.concat(tipoSubgrupoEmpresa.clases);
      });
    }

    descargarExcelEjemploImportacion () {
      let data=[];
      let cabecera=[];
      cabecera.push("CÓDIGO");
      cabecera.push("NOMBRE");
      cabecera.push("GRUPO");
      cabecera.push("SUBGRUPO");
      cabecera.push("DESCRIPCIÓN");
      cabecera.push("VIDA UTIL DÍAS");

      if(this.usuario.empresa.usar_inventario){
        cabecera.push("COSTO UNIT.");
        cabecera.push("CANTIDAD");
      }

      for (let i = 1; i <= 3; i++) {
        let columns = [];
        columns.push("COD"+i);
        columns.push("REPUESTO "+i);
        columns.push("RODAMIENTOS");
        columns.push("MARCA");
        columns.push("descripcion");
        columns.push("365");
        if(this.usuario.empresa.usar_inventario){
          columns.push("1");
          columns.push("1");
        }
        data.push(columns);
      }

      let reportData = {
        title: "EJEMPLO-DATOS-REPUESTOS",
        data: data,
        headers: cabecera
      }
  
      this.excelService.descargarFormatoProdcutosExcel(reportData,"EJEMPLO-DATOS-REPUESTOS","REPUESTOS");
    }

    subirExcelImportacion(event){
      this.blockUI.start();
      //this.mensaje_importacion=null;
      let me=this;
      let files = event.target.files;
      let i,f;
      for (i = 0, f = files[i]; i != files.length; ++i) {
        let reader = new FileReader();
        let name = f.name;
        reader.onload = function(e) {
          //this.blockUI.start();
          let data = e.target.result;
          let workbook = XLSX.read(data, {type: 'binary'});
          let first_sheet_name = workbook.SheetNames[0];
          let row=2,i=0;
          let worksheet = workbook.Sheets[first_sheet_name];
          let repuestos=[];
          do {
            let repuesto:any={};
            repuesto.activar_inventario=me.usuario.empresa.usar_inventario;
            repuesto.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
            repuesto.nombre=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
            repuesto.grupo=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
            repuesto.subgrupo=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
            repuesto.descripcion=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
            repuesto.vida_util_dias=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?parseInt(worksheet['F'+row].v.toString()):null;
            if(me.usuario.empresa.usar_inventario){
              repuesto.costo_unitario=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?parseFloat(worksheet['G'+row].v.toString()):0;
              repuesto.cantidad=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?parseInt(worksheet['H'+row].v.toString()):0;   
            }
            repuestos.push(repuesto);
            row++;	
            i++;				
          } while (worksheet['A'+row]!=undefined);
          //if(!me.usuario.empresa.usar_pedidos && productos.length>500){
          //	me.mensaje_importacion="Número de productos máximo permitido: 50";
          //}
          me.blockUI.stop();
          me.productos_importacion=repuestos;
          me.productosImportacion=me.modalService.open(me.importacionproductos, {windowClass:"importacion-productos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
          me.productosImportacion.result.then((result) => {
            me.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
          });
          //aplicarCongeladorCabecera();
          //this.abrirPopup(this.idModalVistaPreviaImportacion);
          //this.guardarProductos(productos);
          //blockUI.stop();*/
        };
        reader.readAsBinaryString(f);
        }  
    }

    async guardarRepuestos(){
      this.blockUI.start();let id_almacen=null;
      if(this.usuario.empresa.usar_inventario){
        let sucursal:any=await this.empresaService.obtenerSucursal(Util.obtenerSucursalesUsuario(this.usuario)[0].id).toPromise();
        id_almacen=sucursal.almacenes[0].id;
      }
      this.repuestosService.guardarRepuestos({
        repuestos:this.productos_importacion,
        id_empresa:this.usuario.id_empresa,
        id_almacen:id_almacen
      }).subscribe((res:any) => {
        this.productosImportacion.close();
        this.getItems();
        this.blockUI.stop();
        this.toastr.success(res.mensaje);
      });
    }


}
