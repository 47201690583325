<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Rutas
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Rutas
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevaRuta()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
			  <div class="keep-open btn-group show" title="Columns">
				
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="rutas.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().punto_origen.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='punto_origen' && this.direction=='asc','desc':this.column=='punto_origen' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('punto_origen')">Punto Origen</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().punto_destino.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='punto_destino' && this.direction=='asc','desc':this.column=='punto_destino' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('punto_destino')">Punto Destino</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().horas.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='horas' && this.direction=='asc','desc':this.column=='horas' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('horas')">Horas</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().minutos.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='minutos' && this.direction=='asc','desc':this.column=='minutos' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('minutos')">Minutos</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().distancia_kilometros.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='distancia_kilometros' && this.direction=='asc','desc':this.column=='distancia_kilometros' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('distancia_kilometros')">Distancia Kilometros</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #rutas *ngFor="let ruta of rutas; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().punto_origen.show">{{ruta.origen}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().punto_destino.show">{{ruta.destino}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().horas.show">{{ruta.horas}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().minutos.show">{{ruta.minutos}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().distancia_kilometros.show">{{ruta.distancia_kilometros}}</td>

                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">  
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarRuta(ruta)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
                    </a>       
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(ruta)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="rutas.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #edicion_ruta let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Registro Ruta</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarRuta()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Origen</label>&nbsp;
                <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('TRANSPUNTORUTA','obtenerPuntosRuta')"></span>
            </div>
            <div class="col-sm-12 col-md-9">
                <select [compareWith]="compararObjectos" class="form-control" name="origen" [(ngModel)]="ruta.origen">
                    <option [ngValue]="origen" *ngFor="let origen of tipo_punto_ruta.clases">{{origen.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Destino</label>&nbsp;
                <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa('TRANSPUNTORUTA','obtenerPuntosRuta')"></span>
            </div>
            <div class="col-sm-12 col-md-9">
                <select [compareWith]="compararObjectos" class="form-control" name="destino" [(ngModel)]="ruta.destino">
                    <option [ngValue]="destino" *ngFor="let destino of tipo_punto_ruta.clases">{{destino.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Horas</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="number" name="horas" [(ngModel)]="ruta.horas" placeholder="Horas" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Minutos</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input name="minutos" type="number" [(ngModel)]="ruta.minutos" placeholder="Minutos" class="form-control" />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <label>Distancia (Km)</label>
            </div>
            <div class="col-sm-12 col-md-9">
                <input type="number" name="distancia_kilometros" [(ngModel)]="ruta.distancia_kilometros" placeholder="Distancia (Km)" class="form-control" />
            </div>
        </div>
    </div>
</ng-template>