<div class="modal-body card-body p-0" *ngIf="estudiante">
    <div class="row pt-2 pl-1 pr-1 bgc-danger-d3">
        <div class="col-4 text-center">
            <img class="pull-left" src="{{rest_server+estudiante.escuela.empresa.imagen}}" width="120" height="120" />
        </div>
        <div class="col-8 text-center">
            <span class="text-yellow text-150 ">{{estudiante.escuela.nombre}}</span>
        </div>
    </div>
    <div class="row pt-2 pl-1 pr-1 bgc-danger-d3">
        <hr>
        <div class="col-12 text-center">
            <span class="text-white text-130 ">Ficha Económica de Estudiante</span>
        </div>
        <div class="col-12 text-center">
            <span class="text-white text-110">{{estudiante.persona.nombre_completo}}</span>
        </div>
    </div>
    <div class="row pb-5 pl-1 pr-1 text-white bgc-danger-d3">
        <div class="col-12">
            <div class="card bcard bgc-transparent shadow-none">
                <div class="card-body p-0">
                    <ul class="nav nav-tabs align-self-start" role="tablist">
                    <li class="nav-item brc-warning shadow-sm" *ngFor="let inscripcion of estudiante.inscripciones_colegio | orderBy:'gestion.nombre':true">
                        <a (click)="establecerInscripcionTab(inscripcion)" [ngClass]="{'active':inscripcion_tab.gestion.nombre==inscripcion.gestion.nombre}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                        {{inscripcion.gestion.nombre}}
                        </a>
                    </li>
                    </ul>
        
                    <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
                        <div *ngFor="let inscripcion of estudiante.inscripciones_colegio" [ngClass]="{'active':inscripcion_tab.gestion.nombre==inscripcion.gestion.nombre}" class="tab-pane fade show text-95" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                            <div class="row">
                                <div class="col-12 col-md-6 p-1" *ngFor="let concepto_pago of conceptos_pago">
                                    <div class="card bcard overflow-hidden shadow text-center">
                                        <div class="card-body p-3 px bgc-orange-d2">
                                        <div class="row">
                                            <div class="col-12">
                                            <span class="text-white text-125">{{concepto_pago.concepto.nombre}}</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="card bcard overflow-hidden shadow">
                                    <div class="card-body p-3px bgc-orange-d2">
                                        <div class="radius-1 table-responsive">
                                        <table class="table table-striped table-bordered table-hover brc-black-tp10 mb-0 text-grey">
                                            <thead class="brc-transparent">
                                            <tr class="bgc-orange-d2 text-white">
                                                <th>
                                                Cuota
                                                </th>
                                                <th>
                                                Datos de Pago
                                                </th>
                                            </tr>
                                            </thead>
                                
                                            <tbody>
                                            <tr class="bgc-h-yellow-l3" [ngClass]="{'bgc-success-l2':concepto_pago_estudiante.saldo==0, 'bgc-danger-l2': concepto_pago_estudiante.saldo>0}" *ngFor="let concepto_pago_estudiante of concepto_pago.conceptos_pagos_estudiante;let i=index;">
                                                <ng-container *ngIf="concepto_pago_estudiante.activo">
                                                <td class="text-600 text-dark text-center">
                                                    {{concepto_pago_estudiante.cuota}}
                                                    {{concepto_pago_estudiante.descripcion?concepto_pago_estudiante.descripcion:''}}
                                                </td>
                                                <td class="text-110" >
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <span class="text-dark">Importe:</span> <span class="text-green-m1"><span class="text-dark-tp4 text-md">Bs</span>{{concepto_pago_estudiante.importe}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="concepto_pago_estudiante.descuento>0">
                                                        <div class="col-12">
                                                            <span class="text-dark">Descuento:</span> <span class="text-light-m1"><span class="text-dark-tp4 text-md">Bs</span>{{concepto_pago_estudiante.descuento}}</span>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <span class="text-dark">Pagado:</span>
                                                    <div [ngClass]="{'text-info-m1': concepto_pago_estudiante.saldo==0, 'text-danger-m1': concepto_pago_estudiante.saldo>0}" class="text-180">
                                                        <ng-container *ngIf="concepto_pago_estudiante.detalles_venta">
                                                        <ng-container *ngIf="concepto_pago_estudiante.detalles_venta.length>0">
                                                            <div class="row" *ngFor="let detalle_venta of concepto_pago_estudiante.detalles_venta">
                                                            <div class="col-12">
                                                                <span class="text-dark-tp4 text-md">Bs</span>{{detalle_venta.detalle_venta.total}} <span class="text-blue-m1 text-md">{{detalle_venta.detalle_venta.venta.fecha | date:"dd/MM/yyyy"}}</span> 
                                                            </div>
                                                            </div>
                                                        </ng-container>
                                                        <span *ngIf="concepto_pago_estudiante.detalles_venta.length==0 && (concepto_pago_estudiante.importe==concepto_pago_estudiante.pagado || concepto_pago_estudiante.pagado>0)" >{{concepto_pago_estudiante.pagado}}</span>
                                                        <span *ngIf="concepto_pago_estudiante.detalles_venta.length==0 && concepto_pago_estudiante.importe>concepto_pago_estudiante.pagado && concepto_pago_estudiante.pagado==0" >0</span>
                                                        </ng-container>
                                                    </div>
                                                     
                                                    <div class="row" *ngIf="concepto_pago_estudiante.fecha_vencimiento && concepto_pago_estudiante.saldo>0">
                                                        <div class="col-12">
                                                            Fecha de Vencimiento : <span class="text-danger-m1"> {{concepto_pago_estudiante.fecha_vencimiento | date:"dd/MM/yyyy"}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row" *ngIf="concepto_pago_estudiante.observacion">
                                                        <div class="col-12">
                                                            Observación: {{concepto_pago_estudiante.observacion}}
                                                        </div>
                                                    </div>
                                                </td>
                                                </ng-container>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div><!-- ./table-responsive -->
                                    </div><!-- /.card-body -->
                                    </div><!-- /.card -->
                                
                                </div><!-- /.col -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>