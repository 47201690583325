import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HabilitadoGuard} from '../habilitado.guard';
import { DesarrolloCurricularComponent } from './components/desarrollo-curricular/desarrollo-curricular.component';
import { AsistenciaComponent } from './components/asistencia/asistencia.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { CalificacionesComponent } from './components/calificaciones/calificaciones.component';
import { MultimediaComponent } from './components/multimedia/multimedia.component';
import { HorarioProfesorComponent } from './components/horario-profesor/horario-profesor.component';
import { TareasProfesorComponent } from './components/tareas-profesor/tareas-profesor.component';
import { ExamenesComponent } from './components/examenes/examenes.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { ArchivosBancoComponent } from './components/archivos-banco/archivos-banco.component';
import { CentralizadorCalificacionesComponent } from './components/centralizador-calificaciones/centralizador-calificaciones.component';
import { ConsultaKardexEconomicoEstudianteComponent } from './components/consulta-kardex-economico-estudiante/consulta-kardex-economico-estudiante.component';
import { SeguimientoEstudiantesComponent } from './components/seguimiento-estudiantes/seguimiento-estudiantes.component';
import { PaginaNoEncontradaComponent } from '../components/pagina-no-encontrada/pagina-no-encontrada.component';

const routes: Routes = [
  { path: 'desarrollo-curricular',component: DesarrolloCurricularComponent,canActivate:[HabilitadoGuard]},
  { path: 'asistencia',component: AsistenciaComponent,canActivate:[HabilitadoGuard]},
  { path: 'calendario',component: CalendarioComponent,canActivate:[HabilitadoGuard]},
  { path: 'calificaciones',component: CalificacionesComponent,canActivate:[HabilitadoGuard]},
  { path: 'multimedia',component: MultimediaComponent,canActivate:[HabilitadoGuard]},
  { path: 'horario-profesor',component: HorarioProfesorComponent,canActivate:[HabilitadoGuard]},
  { path: 'seguimiento-estudiantes',component: SeguimientoEstudiantesComponent,canActivate:[HabilitadoGuard]},
  { path: 'tareas-profesor',component: TareasProfesorComponent,canActivate:[HabilitadoGuard]},
  { path: 'examenes',component: ExamenesComponent,canActivate:[HabilitadoGuard]},
  { path: 'estudiantes',component: EstudiantesComponent,canActivate:[HabilitadoGuard]},
  { path: 'archivos-banco',component: ArchivosBancoComponent,canActivate:[HabilitadoGuard]},
  { path: 'centralizador-calificaciones',component: CentralizadorCalificacionesComponent,canActivate:[HabilitadoGuard]},
  { path: 'consulta-kardex-economico-estudiante/empresa/:codigo_empresa/codigo-estudiante/:codigo_estudiante',component: ConsultaKardexEconomicoEstudianteComponent},
  { path: 'mis-pagos',component: PaginaNoEncontradaComponent,canActivate:[HabilitadoGuard]},
  { path: 'mi-calendario',component: PaginaNoEncontradaComponent,canActivate:[HabilitadoGuard]},
  { path: 'mi-asistencia',component: PaginaNoEncontradaComponent,canActivate:[HabilitadoGuard]},
  { path: 'mis-calificaciones',component: PaginaNoEncontradaComponent,canActivate:[HabilitadoGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchoolRoutingModule { }
