<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Añadir participantes Grupo Whatsapp</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarParticipantes()" class="btn btn-primary"  >
            <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <ng-container *ngIf="usuario.empresa.usar_sucursales">
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <label>Sucursal</label>
            </div>
            <div class="col-sm-12 col-md-8">
                <select [compareWith]="compararObjectos" class="form-control" required name="sucursal" [(ngModel)]="registro_grupo_whatsapp.sucursal">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of filter.sucursales">{{sucursal.nombre}}</option>
                </select>
            </div>
        </div>
        <hr>
    </ng-container>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Grupo</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" name="grupo" class="form-control" [(ngModel)]="registro_grupo_whatsapp.grupo">
                <option [ngValue]="grupo" *ngFor="let grupo of usuario.empresa.datos.grupos_whatsapp">{{grupo}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Numeros</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <input type="text" class="form-control" [(ngModel)]="registro_grupo_whatsapp.numeros">
        </div>
    </div>
  </div>