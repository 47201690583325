import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class RolesPartidosService {

  constructor(private http: HttpClient) { }

  public obtenerEscenarios(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"escenarios/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerArbitros(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"arbitros/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerListaRolesPartidos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rol-partido/empresa/"+paginator.filter.id_empresa+"/campeonato/"+paginator.filter.campeonato.id+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerRolPartido(id_rol_partido){
    return this.http.get(GlobalVariable.API_SERVER_URL+"rol-partido/"+id_rol_partido,GlobalVariable.httpOptions);
  }

  public guardarRolPartido(rol_partido){
    return this.http.post(GlobalVariable.API_SERVER_URL+"rol-partido",rol_partido,GlobalVariable.httpOptions);
  }

  public actualizarRolPartido(rol_partido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"rol-partido/"+rol_partido.id,rol_partido,GlobalVariable.httpOptions);
  }

  public eliminarRolPartido(rol_partido){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"rol-partido/"+rol_partido.id,GlobalVariable.httpOptions);
  }

  public actualizarResultadoPartido(partido){
    return this.http.put(GlobalVariable.API_SERVER_URL+"partido/"+partido.id,partido,GlobalVariable.httpOptions);
  }

  public obtenerDetallesVentaPendientes(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"equipo/cliente/"+id_cliente+"/ventas-pendientes",GlobalVariable.httpOptions);
  }

  public obtenerTablaPosiciones(datos){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tabla-posiciones/campeonato/"+datos.campeonato.id+"/disciplina/"+datos.disciplina.id+"/"+datos.disciplina.nombre+"/categoria/"+datos.categoria.id+"/fase/"+datos.fase.id,GlobalVariable.httpOptions);
  }

  public validarDuplicidadPartido(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"validar-partido",datos,GlobalVariable.httpOptions);
  }

  public obtenerPartidosArbitro(rol_partido_arbitro){
    let fecha_inicial=new Date(rol_partido_arbitro.fecha_inicio.year,rol_partido_arbitro.fecha_inicio.month-1,rol_partido_arbitro.fecha_inicio.day,0,0);
    let fecha_fin=new Date(rol_partido_arbitro.fecha_fin.year,rol_partido_arbitro.fecha_fin.month-1,rol_partido_arbitro.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"partidos-arbitro/campeonato/"+rol_partido_arbitro.campeonato.id+"/arbitro/"+rol_partido_arbitro.arbitro.id+"/fecha-inicio/"+fecha_inicial+"/fecha-fin/"+fecha_fin,GlobalVariable.httpOptions);
  }
}
