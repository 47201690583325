import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { SeguimientoEstudianteService } from 'src/app/school/services/seguimiento-estudiante/seguimiento-estudiante.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { Util } from 'src/app/utils/utils';

@Component({
  selector: 'app-registro-seguimiento',
  templateUrl: './registro-seguimiento.component.html',
  styleUrls: ['./registro-seguimiento.component.css']
})
export class RegistroSeguimientoComponent extends BaseComponent implements OnInit {

  seguimiento:any={};
  tipo_categorias:any={clases:[]};
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public toastr:ToastrService,
    public seguimientoEstudianteService:SeguimientoEstudianteService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    if(!this.seguimiento.id){
      this.seguimiento.fecha=this.seguimiento.fecha?new Date(this.seguimiento.fecha):new Date();
      this.seguimiento.fecha_texto={
        year: this.seguimiento.fecha.getFullYear(), 
        month: (this.seguimiento.fecha.getMonth()+1),
        day:this.seguimiento.fecha.getDate()
      }
      this.seguimiento.id_usuario=this.usuario.id;
    }
    this.obtenerCategoriasSeguimiento();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  guardarSeguimiento(){
    if(this.validarRegistroSeguimiento()){
      this.blockUI.start();
      this.seguimiento.fecha=Util.convertirObjetoAfecha(this.seguimiento.fecha_texto);
      if(this.seguimiento.id){
        this.seguimientoEstudianteService.actualizarSeguimientoEstudiante(this.seguimiento).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }else{
        this.seguimientoEstudianteService.guardarSeguimientoEstudiante(this.seguimiento).subscribe((res:any)=>{
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

  async obtenerCategoriasSeguimiento(){
    this.tipo_categorias=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CATEGORIA_SEGUIMIENTO_ESTUDIANTE).toPromise();
  }

  validarRegistroSeguimiento(){
    let res=true;
    if(!this.seguimiento.categoria){
      res=res && false;
      this.toastr.error("Debe especificar la categoria!");
    }
    if(!this.seguimiento.fecha_texto || !this.seguimiento.fecha_texto.year || !this.seguimiento.fecha_texto.month || !this.seguimiento.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el registro de seguimiento!");
    }
    return res;
  }

}
