<div class="modal-header card-header">
    <div class="col-md-8 col-8">
        <span class="text-blue text-125">Cambio de clave</span>
    </div>
    <div class="col-md-6 col-6">
        <button (click)="guardarActualizacion()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-12">
            <label>Usuario</label>
        </div>
        <div class="col-12">
            <input [disabled]="true" required id="nombre_usuario" name="nombre_usuario" [(ngModel)]="usuario_datos.nombre_usuario" placeholder="Nombre de usuario" class="form-control form-control-lg col-12 pr-45 pl-25"  />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Clave actual</label>
        </div>
        <div class="col-12">
            <input required type="password" name="clave" [(ngModel)]="usuario_datos.clave" placeholder="Clave" [ngClass]="{'brc-danger-m1':(!usuario_datos.clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Nueva clave</label>
        </div>
        <div class="col-12">
            <input required type="password" id="nueva_clave" name="nueva_clave" [(ngModel)]="usuario_datos.nueva_clave" placeholder="Nueva Clave" [ngClass]="{'brc-danger-m1':(!usuario_datos.nueva_clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <label>Repeticion nueva Clave</label>
        </div>
        <div class="col-12">
            <input required type="password" id="repeticion_clave" name="repeticion_clave" [(ngModel)]="usuario_datos.repeticion_clave" placeholder="Repeticion Clave" [ngClass]="{'brc-danger-m1':(!usuario_datos.repeticion_clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
        </div>
    </div>
</div>