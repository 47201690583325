<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-danger-l3 text-danger-d4 px-3 py-15 radius-1">
        <legend >Codigo de Productos</legend>
        <div class="row">
            <div class="col-3">
                <label>Acrónimo</label>
            </div>
            <div class="col-3">
                <input type="text" id="acronimo" name="acronimo" [(ngModel)]="tipo_codigo.clases[0].nombre" placeholder="Acrónimo" class="form-control form-control-lg col-12"  />
            </div>
            <div class="col-3">
                <label>Correlativo</label>
            </div>
            <div class="col-3">
                <input type="number" step="0" min="1" id="correlativo" name="correlativo" [(ngModel)]="tipo_codigo.clases[0].nombre_corto" placeholder="Correlativo" class="form-control form-control-lg col-12"  />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-3">
                <label>¿Automático?</label>
            </div>
            <div class="col-3">
                <label>
                    <input name="automatico" [(ngModel)]="tipo_codigo.clases[0].habilitado" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
        </div>
    </fieldset>
    <fieldset *ngIf="usuario.empresa.usar_generador_codigos_barras" class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Generación Código de Barras</legend>
        <div class="row">
            <div class="col-3">
                <label>¿Usar Código Sistema?</label>
            </div>
            <div class="col-3">
                <label>
                    <input name="usar_codigos_sistema" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.usar_codigos_sistema" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                </label>
            </div>
            <div class="col-3">
                <label>Tamaño papel impresión</label>
            </div>
            <div class="col-3">
                <select  class="form-control" name="papel_codigos" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_codigo_barras">
                    <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                </select>
            </div>
        </div>
    </fieldset>
</div>