<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
        <legend >Tabla de Cierres de Caja</legend>
        <div class="row">
            <div class="col-2">
                <label>Ordenar por</label>
            </div>
            <div class="col-4">
                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                </select>
            </div>
            <div class="col-2">
                <label>Dirección</label>
            </div>
            <div class="col-4">
                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
                    <option value="asc">Ascendente</option>
                    <option value="desc">Descendente</option>
                </select>
            </div>
        </div>
    </fieldset>
    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
        <legend >Impresión</legend>
        <ng-container>
            <hr>
            <div class="row">
                <div class="col-3">
                    <label>Tamaño papel cierre</label>
                </div>
                <div class="col-3">
                    <select  class="form-control" name="papel_factura" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_cierre">
                    <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </fieldset>
</div>