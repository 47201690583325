import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageConfigurationComponent } from './components/page-configuration/page-configuration.component';
import { PaginatorHeaderComponent } from './components/paginator-header/paginator-header.component';
import { PaginatorFooterComponent } from './components/paginator-footer/paginator-footer.component';
import { FormsModule } from '@angular/forms';
import { ConceptoEmpresaComponent } from './components/concepto-empresa/concepto-empresa.component';
import { PopupConfirmacionComponent } from './components/popup-confirmacion/popup-confirmacion.component';
import { TextoEmpresaComponent } from './components/texto-empresa/texto-empresa.component';
import { RegistroUsuarioComponent } from './components/registro-usuario/registro-usuario.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { ConfiguracionesComponent } from './components/configuraciones/configuraciones.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { ConceptosComponent } from './components/conceptos/conceptos.component';
import { RegistroRecursoMultimediaComponent } from './components/registro-recurso-multimedia/registro-recurso-multimedia.component';
import { VentaComponent } from './components/venta/venta.component';
import { RegistroClienteComponent } from './components/registro-cliente/registro-cliente.component';
import { MensajeVistaComponent } from './components/mensaje-vista/mensaje-vista.component';
import { InterceptorTokenService } from './services/interceptor-token/interceptor-token.service';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { NgbDateCustomParserFormatter } from '../utils/dateformat';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { MensajeriaComponent } from './components/mensajeria/mensajeria.component';
import { VistaMovimientoInventarioComponent } from './components/vista-movimiento-inventario/vista-movimiento-inventario.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfiguracionAplicacionProductosComponent } from '../base/components/configuracion-aplicacion-productos/configuracion-aplicacion-productos.component';
import { ConfiguracionAplicacionCotizacionesComponent } from '../base/components/configuracion-aplicacion-cotizaciones/configuracion-aplicacion-cotizaciones.component';
import { ConfiguracionAplicacionMovimientoInventarioComponent } from '../base/components/configuracion-aplicacion-movimiento-inventario/configuracion-aplicacion-movimiento-inventario.component';
import { ConfiguracionAplicacionComprasComponent } from '../base/components/configuracion-aplicacion-compras/configuracion-aplicacion-compras.component';
import { ConfiguracionAplicacionPedidosComponent } from '../base/components/configuracion-aplicacion-pedidos/configuracion-aplicacion-pedidos.component';
import { ConfiguracionAplicacionVentasComponent } from '../base/components/configuracion-aplicacion-ventas/configuracion-aplicacion-ventas.component';
import { ConfiguracionAplicacionClientesComponent } from '../base/components/configuracion-aplicacion-clientes/configuracion-aplicacion-clientes.component';
import { ConfiguracionAplicacionInventariosComponent } from '../base/components/configuracion-aplicacion-inventarios/configuracion-aplicacion-inventarios.component';
import { ConfiguracionAplicacionCierresCajaComponent } from './components/configuracion-aplicacion-cierres-caja/configuracion-aplicacion-cierres-caja.component';
import { BusquedaProductoComponent } from './components/busqueda-producto/busqueda-producto.component';
import { ConfiguracionAplicacionVecinosComponent } from './components/configuracion-aplicacion-vecinos/configuracion-aplicacion-vecinos.component';
import { ConfiguracionAplicacionProveedoresComponent } from './components/configuracion-aplicacion-proveedores/configuracion-aplicacion-proveedores.component';
import { EmpresaFacturacionComponent } from './components/empresa-facturacion/empresa-facturacion.component';
import { RegistroAsuntoAdministrativoComponent } from './components/registro-asunto-administrativo/registro-asunto-administrativo.component';
import { ConfiguracionAplicacionCuentasPorCobrarComponent } from './components/configuracion-aplicacion-cuentas-por-cobrar/configuracion-aplicacion-cuentas-por-cobrar.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ActualizacionClaveUsuarioComponent } from './components/actualizacion-clave-usuario/actualizacion-clave-usuario.component';
import { ParticipantesGruposWhatsappComponent } from './components/participantes-grupos-whatsapp/participantes-grupos-whatsapp.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { SucursalFacturacionComponent } from './components/sucursal-facturacion/sucursal-facturacion.component';
import { ConfiguracionAplicacionCitasMedicasComponent } from './components/configuracion-aplicacion-citas-medicas/configuracion-aplicacion-citas-medicas.component';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    PageConfigurationComponent,
    PaginatorHeaderComponent,
    PaginatorFooterComponent,
    ConceptoEmpresaComponent,
    PopupConfirmacionComponent,
  TextoEmpresaComponent,
  UsuariosComponent,
  RegistroUsuarioComponent,
  ConfiguracionesComponent,
  EmpresasComponent,
  ConceptosComponent,
  RegistroRecursoMultimediaComponent,
  VentaComponent,
  RegistroClienteComponent,
  MensajeVistaComponent,
  MensajeriaComponent,
  VistaMovimientoInventarioComponent,
  ConfiguracionAplicacionVentasComponent,
    ConfiguracionAplicacionClientesComponent,
    ConfiguracionAplicacionInventariosComponent,
    ConfiguracionAplicacionPedidosComponent,
    ConfiguracionAplicacionComprasComponent,
    ConfiguracionAplicacionMovimientoInventarioComponent,
    ConfiguracionAplicacionCotizacionesComponent,
    ConfiguracionAplicacionProductosComponent,
    ConfiguracionAplicacionCierresCajaComponent,
    BusquedaProductoComponent,
    ConfiguracionAplicacionVecinosComponent,
    ConfiguracionAplicacionProveedoresComponent,
    EmpresaFacturacionComponent,
    RegistroAsuntoAdministrativoComponent,
    ConfiguracionAplicacionCuentasPorCobrarComponent,
    ActualizacionClaveUsuarioComponent,
    ParticipantesGruposWhatsappComponent,
    SucursalFacturacionComponent,
    ConfiguracionAplicacionCitasMedicasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    OrderModule,
    FilterPipeModule,
    NgxBootstrapMultiselectModule,
    GoogleMapsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    KeyboardShortcutsModule.forRoot()
  ],
  exports:[
    PageConfigurationComponent,PaginatorHeaderComponent,PaginatorFooterComponent,
    ConceptoEmpresaComponent,
    PopupConfirmacionComponent,
    TextoEmpresaComponent,
    UsuariosComponent,
    RegistroUsuarioComponent,
    ConfiguracionesComponent,
    EmpresasComponent,
    ConceptosComponent,
    RegistroRecursoMultimediaComponent,
    VentaComponent,
    RegistroClienteComponent,
    MensajeVistaComponent,
    MensajeriaComponent,
    VistaMovimientoInventarioComponent,
    ConfiguracionAplicacionVentasComponent,
    ConfiguracionAplicacionClientesComponent,
    ConfiguracionAplicacionInventariosComponent,
    ConfiguracionAplicacionPedidosComponent,
    ConfiguracionAplicacionComprasComponent,
    ConfiguracionAplicacionMovimientoInventarioComponent,
    ConfiguracionAplicacionCotizacionesComponent
  ],
  providers: [{provide:NgbDateParserFormatter,useClass:NgbDateCustomParserFormatter},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true}],
})
export class BaseModule { }
