import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { PlanDeCuentasService } from '../../services/plan-de-cuentas/plan-de-cuentas.service';

@Component({
  selector: 'app-asignacion-cuenta-cliente',
  templateUrl: './asignacion-cuenta-cliente.component.html',
  styleUrls: ['./asignacion-cuenta-cliente.component.css']
})
export class AsignacionCuentaClienteComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  searching:any;
  searchFailed:any;
  cuenta_busqueda:any;
  asignacion_cuenta:any={crear_cuenta_contabilidad:true};
  cliente:any;
  cuenta_por_cobrar:any;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private planDeCuentasService:PlanDeCuentasService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  reiniciarCuenta(){
    this.asignacion_cuenta.cuenta=null;
    this.cuenta_busqueda=null;
  }

  buscarCuenta = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
    switchMap( (term) =>  this.planDeCuentasService.buscarCuenta(this.usuario.id_empresa,term,0)),
    catchError(() => {
    this.searchFailed = true;
    return of([]);
    })  ,
    tap(() => this.searching = false)
  )

  formatter = (x: any) => (x.id?(x.codigo)+" "+(x.nombre):"");

  establecerCuentaActual(event){
    let cuenta=(event.id)?event:event.item;
    this.cuenta_busqueda=cuenta.codigo+" - "+cuenta.nombre;
    this.asignacion_cuenta.cuenta=cuenta;
  }

  guardarAsignacionCuentaCliente(){
    if(this.asignacion_cuenta.crear_cuenta_contabilidad){
      let codigo_cuenta_padre=this.cuenta_por_cobrar.codigo;//aqui debe ir el codigo de cuenta padre CUENTAS POR COBRAR
      this.blockUI.start();
      this.planDeCuentasService.obtenerCuentaPadreUltimoCodigo(codigo_cuenta_padre).subscribe((res:any)=>{
        let cuenta={
          cliente:this.cliente,
          nombre:this.cliente.razon_social,
          descripcion:this.cliente.razon_social,
          codigo:codigo_cuenta_padre+"."+(res.cuenta_codigo+1),
          id_cuenta_padre:this.cuenta_por_cobrar.id, //aqui debe ir el ID de cuenta padre CUENTAS POR COBRAR
          debe:0,
          haber:0,
          saldo:0,
          id_empresa: this.usuario.id_empresa, 
          id_usuario: this.usuario.id, 
          tipoCuenta: {id:142}, clasificacion: {id:1}, eliminado: false, bimonetaria: false, aplicar_calculo: false
        }

        this.planDeCuentasService.guardarCuenta(cuenta).subscribe((res_c:any)=>{
          this.blockUI.stop();
          this.toastr.success(res_c.mensaje);
          this.alTerminar.emit({tiene_error:false});
        });
      });
    }else{
      if (this.asignacion_cuenta.cuenta && this.asignacion_cuenta.cuenta.id) {
        this.asignacion_cuenta.cliente=this.cliente;
        this.blockUI.start();
        this.planDeCuentasService.asignarCuentaCliente(this.asignacion_cuenta).subscribe((res_c:any)=>{
          this.blockUI.stop();
          this.toastr.success(res_c.mensaje);
          this.alTerminar.emit({tiene_error:false});
        });
      } else {
        this.toastr.error("Debe especificar una cuenta!");
      }
    }
  }

}
