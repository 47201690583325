import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { GeneralService } from '../../services/general/general.service';
import { GlobalVariable } from 'src/app/global';
import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-texto-empresa',
  templateUrl: './texto-empresa.component.html',
  styleUrls: ['./texto-empresa.component.css']
})
export class TextoEmpresaComponent implements OnInit {

  //@Output() onConfirm:EventEmitter<any>  = new EventEmitter<any>();
  clase_texto:any={};
  id_empresa:any;
  clase_nombre_corto:any;
  @Output() onTerminate:EventEmitter<any>  = new EventEmitter<any>();
  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.generalService.obtenerTipoClaseDato(this.id_empresa,GlobalVariable.Dictionary.TEXTOS_SISTEMA_EMPRESA,this.clase_nombre_corto).subscribe((texto:any) => {
      this.clase_texto=texto;     
    });
  }

  guardarTextoEdicion(){
    this.blockUI.start();
    this.generalService.actualizarClase(this.clase_texto).subscribe((res:any) => {
      this.toastr.info(res.mensaje);
      this.cerrarVentana(true);     
      this.blockUI.stop();
    });
  }

  cerrarVentana(save){
    this.onTerminate.emit({save:save});
  }

}
