<div class="modal-header card-header">
    <div class="col-12">
        <div class="row">
            <div class="col-6">
                <span class="text-blue text-125">Registro Despacho</span>
            </div>
            <div class="col-6">
                <button  id="venta_guardar" type="button" class="btn btn-primary" (click)="guardarDespacho()">
                    <i class="fa fa-save align-top bigger-125"></i>
                </button>&nbsp;
                <button id="cerrarV" type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
                    <i class="fa fa-times red2 align-top bigger-125"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-3 col-12">
            <label for="form-field-select-1">Almacen Origen:</label>
            <select [compareWith]="compararObjectos" class="form-control" required name="almacen_origen" [(ngModel)]="despacho.almacen_origen"
            (change)="obtenerAlmacenesDiferente(despacho.almacen_origen)" >
                <option [ngValue]="almacen" *ngFor="let almacen of sucursal.almacenes">{{almacen.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="table-fixed-header">
        <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
            <thead fix-head class="table-head-color sticky-nav">
                <tr>
                    <th>Nº</th>
                    <th>Item</th>
                    <th>Cantidad Pedido</th>
                    <th>Cantidad Despachado</th>
                    <th>Cantidad Requerida</th>
                    <th>Cantidad Disponible</th>
                    <th>Cantidad Despacho</th>
                    <th>Saldo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalleDespacho of despacho.detallesDespacho; let i=index;">
                    <td>{{i+1}}</td>
                    <td *ngIf="detalleDespacho.producto">
                        <div class="red" *ngIf="!detalleDespacho.producto.activar_inventario">(Inventario Desactivado)</div>
                        {{detalleDespacho.producto.nombre}} 
                    </td>
                    <td *ngIf="detalleDespacho.combo">{{detalleDespacho.combo.nombre}}</td>
                    <td>{{detalleDespacho.cantidad}}</td>
                    <td>{{detalleDespacho.cantidad_despachado}}</td>
                    <td>{{detalleDespacho.cantidad_requerida}}</td>
                    <td>{{detalleDespacho.cantidad_disponible}}</td>
                    <td><input required (change)="calcularCantidades(i)" class="col-md-8 form-control" type="number" min="0" max="{{detalleDespacho.cantidad_maximo}}" step="0.001" [(ngModel)]="detalleDespacho.cantidad_despacho"></td>
                    <td>{{calcularSaldo(detalleDespacho)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>