<div class="modal-header card-header">
    <div class="col-8">
        <span class="text-blue text-125">Registro Movimiento Caja</span>
    </div>
    <div class="col-4">
        <button type="button" class="btn btn-primary btn-primary" (click)="guardarMovimientoCaja()" >
            <i class="fa fa-save red2 align-top bigger-125"></i>
        </button>&nbsp;
        <button type="button" class="btn btn-primary btn-danger" (click)="cerrarComponente()" >
          <i class="fa fa-times red2 align-top bigger-125"></i>
      </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <ng-container *ngIf="usuario.empresa.usar_sucursales">
            <div class="col-1">
                <label>Sucursal</label>
            </div>
            <div class="col-3">
                <select [disabled]="inhabilitar_sucursal" [compareWith]="compararObjectos" name="sucursal" class="form-control" [(ngModel)]="movimiento_caja.sucursal">
                    <option [ngValue]="sucursal" *ngFor="let sucursal of sucursales">{{sucursal.nombre}}</option>
                </select>
            </div>
        </ng-container>
        <div class="col-1">
            <label>Concepto</label>
        </div>
        <div class="col-3">
            <select [disabled]="inhabilitar_concepto" [compareWith]="compararObjectos" class="form-control" name="concepto" [(ngModel)]="movimiento_caja.concepto">
                <option [ngValue]="concepto" *ngFor="let concepto of conceptos">{{concepto.nombre}}</option>
            </select>
        </div>
        <ng-container *ngIf="movimiento_caja.concepto.nombre_corto!='TRANSCJ'">
            <div class="col-1">
                <label>Tipo de Movimiento</label>
            </div>
            <div class="col-3">
                <select [disabled]="inhabilitar_tipo_movimiento" [compareWith]="compararObjectos" class="form-control" name="tipo_movimiento" [(ngModel)]="movimiento_caja.tipo_movimiento">
                    <option [ngValue]="tipo_movimiento" *ngFor="let tipo_movimiento of tipos_movimiento">{{tipo_movimiento.nombre}}</option>
                </select>
            </div>
        </ng-container>
    </div>
    <hr>
    <div class="row">
        <div class="col-1">
            <label>Fecha</label>
        </div>
        <div class="col-2">
            <div class="input-group">
                <input [disabled]="inhabilitar_fecha" required name="fecha_texto" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="movimiento_caja.fecha_texto" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button  [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>
        </div>
        <div class="col-1">
            <label *ngIf="movimiento_caja.concepto.nombre_corto!='TRANSCJ'">Caja</label>
            <label *ngIf="movimiento_caja.concepto.nombre_corto=='TRANSCJ'">Caja Origen</label>
        </div>
        <div class="col-2">
            <select [compareWith]="compararObjectos" name="caja" class="form-control" [(ngModel)]="movimiento_caja.caja" (change)="establecerCaja(movimiento_caja.caja)">
                <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
            </select>
        </div>
        <ng-container *ngIf="movimiento_caja.concepto.nombre_corto=='TRANSCJ'">
            <div class="col-1">
                <label >Caja Destino</label>
            </div>
            <div class="col-2">
                <select [compareWith]="compararObjectos" name="caja_destino" class="form-control" [(ngModel)]="movimiento_caja.caja_destino">
                    <option [ngValue]="caja" *ngFor="let caja of cajas">{{caja.nombre}}-{{caja.moneda.nombre}}</option>
                </select>
            </div>
        </ng-container>
        <div class="col-1">
            <label>Importe</label>
        </div>
        <div class="col-2">
            <input [disabled]="inhabilitar_importe" name="importe" class="form-control" [(ngModel)]="movimiento_caja.importe" type="number" step="0.001">
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-2">
            <label>Glosa</label>
        </div>
        <div class="col-10">
            <textarea [disabled]="inhabilitar_glosa" class="form-control" style="overflow:auto;resize:none" rows="5" cols="70" maxlength="190" name="glosa" [(ngModel)]="movimiento_caja.glosa" placeholder="Glosa"></textarea>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-2">
            <label>Transacción</label>
        </div>
        <div class="col-2">
            <select [compareWith]="compararObjectos" class="form-control" name="transaccion" [(ngModel)]="movimiento_caja.transaccion" (change)="establecerTransaccion(movimiento_caja.transaccion)">
                <option [ngValue]="transaccion" *ngFor="let transaccion of transacciones">{{transaccion.nombre}}</option>
            </select>
        </div>
        <div class="col-1">
            <label>Nombre</label>
        </div>
        <div class="col-3">
            <input name="nombre" class="form-control" [(ngModel)]="movimiento_caja.nombre" type="text">
        </div>
        <div class="col-1">
            <label>C.I.</label>
        </div>
        <div class="col-3">
            <input name="ci" class="form-control" [(ngModel)]="movimiento_caja.ci" type="text">
        </div>
    </div>
    <ng-container *ngIf="movimiento_caja.tipo_movimiento.nombre_corto==global_variable.Dictionary.MOV_EGR && movimiento_caja.transaccion.nombre_corto==global_variable.Dictionary.TIPO_PAGO_CHEQUE">
        <hr>
        <div class="row">
            <div class="col-2">
                <label>Cheque</label>
            </div>
            <div class="col-4">
                <select [compareWith]="compararObjectos" class="form-control" name="cheque" [(ngModel)]="movimiento_caja.cheque" (change)="establecerChequeBanco()">
                    <option [ngValue]="cheque" *ngFor="let cheque of cheques">{{movimiento_caja.caja.banco.nombre}}-{{movimiento_caja.caja.numero_cuenta}}-{{cheque.codigo}}-{{cheque.correlativo_actual}}</option>
                </select>
            </div>
            <div class="col-1">
                <label>Número Cheque</label>
            </div>
            <div class="col-3">
                <input name="numero_cheque" class="form-control" [(ngModel)]="movimiento_caja.numero_cheque" type="number" step="1">
            </div>
        </div>
    </ng-container>
</div>