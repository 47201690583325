import { Component, OnInit } from '@angular/core';
import { PersistenciaService } from '../../base/services/persistencia/persistencia.service';
import { Util } from 'src/app/utils/utils';
import { GeneralService } from '../../base/services/general/general.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ReportesService } from '../../snapquick/services/reportes/reportes.service';
import { GlobalVariable } from 'src/app/global';
import { PdfService } from '../../base/services/pdf/pdf.service';
import { BaseComponent } from 'src/app/models/base-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UsuarioService } from '../../base/services/usuario/usuario.service';
import { Socket } from 'ngx-socket-io';
import { EmpresaService } from 'src/app/base/services/empresas/empresa.service';


declare const aplicarScriptPaginaInicio:any;
declare const aplicarPluginTreeView:any;
declare const aplicarPorcentajeDatos:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  aplicaciones:any[];
  usuario:any;
  datos_archivos:any={
    espacio_ocupado:0
  };

  notificacion:any={
    contador_ventas_credito_notificacion:0,
    contador_compras_credito_notificacion:0,
    contador_pedidos_notificacion:0,
    contador_ventas_no_contabilizadas:0,
    contador_productos_inventario_minimo:0,
    contador_vencimiento_productos_notificacion:0,
    contador_total_ventas:0,
    contador_mensajes:0
  }

  es_usuario_administrador:boolean=false;
  ids_sucursales:string="0";
  filtro:any={};
  filtro_estado:any;
  aplicacion_empresa:any={};

  url_game:SafeUrl="";
  url_visor_pdf:string='';
  canvas_original_data_url:any;
  carpeta_actual:any={};
  editor:boolean=false;
  canvas:any;
  canvas_arreglo:any[]=[];
  canvas_paso:number=-1;
  ctx:any;
  pdf_visor_zoom:number=1;
  visor_abierto:boolean=false;

  constructor(public persistenciaService:PersistenciaService,
            public generalService:GeneralService,
            public modalService:NgbModal,
            public reportesService:ReportesService,
            public pdfService:PdfService,
            private sanitizer: DomSanitizer,
            public usuarioService:UsuarioService,
            public empresaService:EmpresaService,
            private socket: Socket) {
              super(persistenciaService,modalService,generalService,usuarioService)
             }

  ngOnInit(): void {
    this.init();
    this.url_game=this.sanitizer.bypassSecurityTrustResourceUrl(GlobalVariable.SERVER_URL+'games/dropblock/index.html');
    this.usuario=this.persistenciaService.get("usuario");
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    if(this.usuario.id_empresa){
      this.ids_sucursales=Util.obtenerIdsSucursales(this.usuario);
      let id_usuario=0;
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(this.es_usuario_administrador){	
          id_usuario=0;
        }else{
          id_usuario=this.usuario.id;
        }
      }

      this.filtro={
        id_empresa:this.usuario.id_empresa,
        id_sucursal:this.ids_sucursales,
        id_usuario:id_usuario
      }

      this.filter={
        color:"#000000",
        currentPage:1,
        maximizado:false,
        pdf_cargado:false,
        ancho_linea:5
      };

      if(this.usuario.empresa.usar_vencimientos){
        this.obtenerContadorVencimientoProductos();
      }

      if(this.usuario.empresa.usar_vencimiento_economico_ventas){
        this.obtenerContadorVencimientoCreditos();
      }

      if(this.usuario.empresa.usar_vencimiento_economico_compras){
        this.obtenerContadorVencimientoDeudas();
      }

      if(this.usuario.empresa.usar_pedidos_distribucion || this.usuario.empresa.usar_pedidos_en_linea){
        this.obtenerContadorPedidosNotificacion(); 
      }

      if(this.usuario.empresa.usar_inventario_minimo){
        this.obtenerContadorInventarioMinimo();  
      }

      this.obtenerContadorMensajes();
      
      this.obtenerContadorResumenVentas();

      this.filtro_estado={
        id_empresa:this.usuario.id_empresa,
        periodo_estado_negocio:"esta_gestion",
        usuario:{nombre_usuario:'TODOS',id:0},
        usuarios:[{nombre_usuario:'TODOS',id:0}],
        tipo_costo:{nombre:"COSTO ESTABLECIDO",id:0},
        tipos_costo:[{nombre:"COSTO ESTABLECIDO",id:0}],
      }
      if(this.usuario.empresa.usar_inventario){
        let costo_calculado={nombre:"COSTO CALCULADO",id:1};
        this.filtro_estado.tipos_costo.push(costo_calculado);
        this.filtro_estado.tipo_costo=costo_calculado
      }
      let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(es_usuario_administrador){	
          this.obtenerUsuarios();
        }else{
          this.filtro_estado.usuarios=[this.usuario]
          this.filtro_estado.usuario=this.usuario;
        }
      }else{
        this.obtenerUsuarios();
      }
      this.cambiarPeriodo('esta_gestion');
    }
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filtro_estado.usuarios=this.filtro_estado.usuarios.concat(usuarios_empresa);
    });
  }

  ngAfterViewInit() {
    if(this.usuario.id_empresa){
      this.aplicacion_empresa= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_EMPRESAS);
      if(this.aplicacion_empresa.length>0 && this.aplicacion_empresa[0].puede_ver){
        this.generarEstadoNegocio();
        this.empresaService.obtenerCarpetasArchivosEmpresa(this.usuario.id_empresa).subscribe((datos:any)=>{
          this.datos_archivos={
            espacio_ocupado:Math.round(((datos.directorios_empresa.size+datos.directorio_compartido.size)/1024/1024)*100)/100,
            espacio_asignado:datos.espacio_asignado
          }
          this.datos_archivos.porcentaje_ocupado=Math.round(this.datos_archivos.espacio_ocupado*100/this.datos_archivos.espacio_asignado);
          aplicarPluginTreeView("id-carpetas",datos.directorios_empresa.children.concat(datos.directorio_compartido.children),this,this.verTexto,GlobalVariable.SERVER_URL);
          aplicarPorcentajeDatos(this.datos_archivos.porcentaje_ocupado);
        });
      }
    }
  }

  verTexto(me,nombre_recurso,url_texto){
    me.filter.maximizado=false;
    me.url_visor_pdf= url_texto;
    me.nombre_pdf=nombre_recurso;
    if(me.visor_abierto){
      me.clickVisor();  
    }else{
      me.visor_abierto=true;
    }
    me.clickVisor();
    /*me.visor_pdf=me.modalService.open(me.visor_pdf_ref, {windowClass:'visor-pdf',ariaLabelledBy: 'modal-basic-title',scrollable:true,size: 'lg', backdrop: 'static'});
    me.visor_pdf.result.then((result) => {
      me.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
    });*/
  }

  clickVisor(){
    let me=this;
    setTimeout(function(){
      var element = document.createElement('button');
      element.setAttribute('data-target', '#aside-pdf-visor');
      element.setAttribute('type', 'button');
      element.setAttribute('data-toggle', 'modal');
      element.setAttribute('class', 'shadow ml-2 btn btn-info radius-round mw-auto p-3');
      element.style.display = 'none';
      document.getElementById("aside-pdf-visor").appendChild(element);
      element.click();console.log(element);
      document.getElementById("aside-pdf-visor").removeChild(element);
    },500);
  }

  afterLoadComplete(pdfData: any) {
    this.filter.pdf_cargado=true;
    this.filter.pages = pdfData.numPages;
    console.log(this.filter.pages);
    //this.isLoaded = true; 
    this.blockUI.stop();
    //this.aplicarDibujoCanvas();
    this.iniciarPagina();
  }

  iniciarPagina(){
    if(this.canvas_original_data_url){
      this.repintarCanvas(this.canvas_original_data_url);
    }
    this.canvas=null;
    this.canvas_arreglo=[];
    this.canvas_paso=-1;
    //this.desactivarEscritura();
  }

  repintarCanvas(data){
    let image=new Image();
    image.src=data;
    let me=this;
    image.onload = function(){
      me.ctx.drawImage(image,0,0);   
    }
  }

  cerrarVisorPdf(){
    this.filter.pdf_cargado=false;
    this.restaurarPdf();
    this.visor_abierto=false;
    this.clickVisor();
  }

  restaurarPdf(){
    this.filter.maximizado=false;
    document.exitFullscreen()
    .then(function() {
      // element has exited fullscreen mode
    })
    .catch(function(error) {
      // element could not exit fullscreen mode
      // error message
      console.log(error.message);
    });
  }

  obtenerContadorVencimientoProductos(){
    this.generalService.obtenerContadorVencimientoProductos(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_vencimiento_productos_notificacion=dato.contador;
    });
  }

  obtenerContadorVencimientoCreditos(){
    this.generalService.obtenerContadorVencimientoCreditos(this.filtro).subscribe((vencimientosCreditos:any)=>{
      this.notificacion.contador_ventas_credito_notificacion=vencimientosCreditos.contador;
    });
  }

  obtenerContadorVencimientoDeudas(){
    this.generalService.obtenerContadorVencimientoDeudas(this.filtro).subscribe((vencimientosDeudas:any)=>{
      this.notificacion.contador_compras_credito_notificacion=vencimientosDeudas.contador;
    });
  }

  obtenerContadorPedidosNotificacion(){
    this.generalService.obtenerContadorPedidosNotificacion(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_pedidos_notificacion=dato.contador;
    });
  }

  obtenerContadorInventarioMinimo(){
    this.generalService.obtenerContadorInventarioMinimo(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_productos_inventario_minimo=dato.contador;
    });
  }

  obtenerContadorResumenVentas(){
    this.generalService.obtenerContadorResumenVentas(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_total_ventas=dato.total_ventas;
    });
  }

  obtenerContadorMensajes(){
    this.generalService.obtenerContadorMensajes({id_usuario:this.usuario.id}).subscribe((dato:any)=>{
      this.notificacion.contador_mensajes=dato.contador;
    });
  }

  cambiarPeriodo(periodo){
    let fecha_inicio=new Date();
    let fecha_fin=new Date();
    if(periodo=='hoy'){
      
    }else if(periodo=='ayer'){
      fecha_inicio.setDate(fecha_inicio.getDate()-1);
      fecha_fin.setDate(fecha_fin.getDate()-1);

    }else if(periodo=='esta_semana'){
      let first_day=fecha_inicio.getDate()-fecha_inicio.getDay()+1;
      let last_day=first_day+6;
      fecha_inicio=new Date(fecha_inicio.setDate(first_day));
      fecha_fin=new Date(fecha_fin.setDate(last_day));
    }else if(periodo=='anterior_semana'){
      let first_day=fecha_inicio.getDate()-fecha_inicio.getDay()+1-7;
      let last_day=first_day+6;
      fecha_inicio=new Date(fecha_inicio.setDate(first_day));
      fecha_fin=new Date(fecha_fin.setDate(last_day));
    }else if(periodo=='este_mes'){
      fecha_inicio=new Date(fecha_inicio.getFullYear(),fecha_inicio.getMonth(),1);
      fecha_fin=new Date(fecha_fin.getFullYear(),fecha_fin.getMonth()+1,0);
    }else if(periodo=='anterior_mes'){
      fecha_inicio.setMonth(fecha_inicio.getMonth()-1);
      fecha_fin.setMonth(fecha_fin.getMonth()-1);
      fecha_inicio=new Date(fecha_inicio.getFullYear(),fecha_inicio.getMonth(),1);
      fecha_fin=new Date(fecha_fin.getFullYear(),fecha_fin.getMonth()+1,0);
    }else if(periodo=='esta_gestion'){
      fecha_inicio.setMonth(0);
      fecha_fin.setMonth(11);
      fecha_inicio=new Date(fecha_inicio.getFullYear(),fecha_inicio.getMonth(),1);
      fecha_fin=new Date(fecha_fin.getFullYear(),fecha_fin.getMonth()+1,0);
    }else if(periodo=='pasada_gestion'){
      fecha_inicio.setMonth(0);
      fecha_inicio.setFullYear(fecha_inicio.getFullYear()-1);
      fecha_fin.setMonth(11);
      fecha_fin.setFullYear(fecha_fin.getFullYear()-1);
      fecha_inicio=new Date(fecha_inicio.getFullYear(),fecha_inicio.getMonth(),1);
      fecha_fin=new Date(fecha_fin.getFullYear(),fecha_fin.getMonth()+1,0);
    }

    this.filtro_estado.fecha_inicio={
      year: fecha_inicio.getFullYear(), 
      month: (fecha_inicio.getMonth()+1),
      day:fecha_inicio.getDate()
    }
    this.filtro_estado.fecha_fin={
      year: fecha_fin.getFullYear(), 
      month: (fecha_fin.getMonth()+1),
      day:fecha_fin.getDate()
    }

    this.filtro_estado.periodo_estado_negocio=periodo;
  }

  async generarEstadoNegocio(){
    let inicio=new Date(this.filtro_estado.fecha_inicio.year,this.filtro_estado.fecha_inicio.month-1,this.filtro_estado.fecha_inicio.day,0,0);
    let fin=new Date(this.filtro_estado.fecha_fin.year,this.filtro_estado.fecha_fin.month-1,this.filtro_estado.fecha_fin.day,0,0);

    let nombres;
    let ventas=[],costos=[];

    let ventasEmpresa:any;
    let gastosVariosEmpresa:any;
    if(this.filtro_estado.periodo_estado_negocio=="hoy" || this.filtro_estado.periodo_estado_negocio=="ayer"){
      nombres=[inicio.getDate()+"/"+(inicio.getMonth()+1)+"/"+inicio.getFullYear()];
      ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,inicio,fin).toPromise();
      gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,inicio,fin).toPromise();
      this.generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos);
    }else if(this.filtro_estado.periodo_estado_negocio=="esta_semana" ||
    this.filtro_estado.periodo_estado_negocio=="anterior_semana" ||
    this.filtro_estado.periodo_estado_negocio=="este_mes" ||
    this.filtro_estado.periodo_estado_negocio=="anterior_mes" ||
    this.filtro_estado.periodo_estado_negocio=="fechas"){
      nombres=[];
      for(let nombre_inicio=inicio;nombre_inicio<=fin;nombre_inicio.setDate(nombre_inicio.getDate()+1)){
        nombres.push(nombre_inicio.getDate()+"/"+(nombre_inicio.getMonth()+1)+"/"+nombre_inicio.getFullYear());
        let fecha_inicio=nombre_inicio;
        let fecha_fin=nombre_inicio;
        ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        this.generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos);
      }
      ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,inicio,fin).toPromise();
      gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,inicio,fin).toPromise();
      this.generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos);
    }else if(this.filtro_estado.periodo_estado_negocio=="esta_gestion"){
      nombres=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
      let tiempo_actual=new Date();
      let mes_actual=tiempo_actual.getMonth();
      for(let mes=0;mes<=mes_actual;mes++){
        let fecha_inicio=new Date(tiempo_actual.getFullYear(),mes,1);
        let fecha_fin=new Date(tiempo_actual.getFullYear(),mes+1,0);
        ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        this.generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos);
      }
    }else if(this.filtro_estado.periodo_estado_negocio=="pasada_gestion"){
      nombres=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
      let tiempo_actual=new Date();
      tiempo_actual.setFullYear(tiempo_actual.getFullYear()-1);
      for(let mes=0;mes<=11;mes++){
        let fecha_inicio=new Date(tiempo_actual.getFullYear(),mes,1);
        let fecha_fin=new Date(tiempo_actual.getFullYear(),mes+1,0);
        ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,fecha_inicio,fecha_fin).toPromise();
        this.generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos);
      }
    }
    aplicarScriptPaginaInicio({meses:nombres,ventas:ventas,costos:costos});
  }

  generarInformacionVentasyCostos(ventasEmpresa,gastosVariosEmpresa,ventas,costos){
    var suma_ventas_total=ventasEmpresa.ventas[0].total;
    var suma_gastos=0;
    for(var i=0;i<gastosVariosEmpresa.gastos.length;i++){
      suma_gastos=suma_gastos+gastosVariosEmpresa.gastos[i].total;
    }
    var suma_costos_total=suma_gastos;
    ventas.push(Math.round(suma_ventas_total*100)/100);
    costos.push(Math.round(suma_costos_total*100)/100);
  }

  async generarPdfEstadoNegocio(){
    this.blockUI.start();
    let inicio=new Date(this.filtro_estado.fecha_inicio.year,this.filtro_estado.fecha_inicio.month-1,this.filtro_estado.fecha_inicio.day,0,0);
    let fin=new Date(this.filtro_estado.fecha_fin.year,this.filtro_estado.fecha_fin.month-1,this.filtro_estado.fecha_fin.day,0,0);
    let ventasEmpresa=await this.reportesService.obtenerEstadoNegocioVentas(this.filtro_estado,inicio,fin).toPromise();
    let gastosVariosEmpresa=await this.reportesService.obtenerEstadoNegocioGastos(this.filtro_estado,inicio,fin).toPromise();
    this.pdfService.imprimirEstadoNegocio(ventasEmpresa,gastosVariosEmpresa,this.usuario,inicio,fin);
    this.blockUI.stop();
  }

  establecerPeriodoFechas(event){
    this.filtro_estado.periodo_estado_negocio="fechas";
  }
}
