import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ClientesService } from '../../services/clientes/clientes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { PagoVentaComponent } from '../pago-venta/pago-venta.component';
import { VistaVentaComponent } from '../vista-venta/vista-venta.component';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { OrderPipe } from 'ngx-order-pipe';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { ListaPagosVentasComponent } from '../lista-pagos-ventas/lista-pagos-ventas.component';
import { CajasService } from '../../services/cajas/cajas.service';

declare const $:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-clientes-historico-ventas',
  templateUrl: './clientes-historico-ventas.component.html',
  styleUrls: ['./clientes-historico-ventas.component.css']
})
export class ClientesHistoricoVentasComponent extends BaseComponent implements OnInit {

  cliente:any={ventas_filtradas:[],ventas:[]};
  cajas:any[]=[];
  transacciones:any[]=[];
  mostrar_filtro_fechas:boolean=true;
  mostrar_boton_busqueda:boolean=true;
  titulo_reporte:string="HISTÓRICO DE VENTAS CLIENTE";
  totalgeneral:number=0;
  total_importe:number=0;
  total_a_cuenta:number=0;
  total_saldo:number=0;
  total_importe_seleccionado:number=0;
  total_a_cuenta_seleccionado:number=0;
  total_saldo_seleccionado:number=0;
  cuentas_liquidadas:boolean=false;

  aplicacion_cuentas_por_cobrar:any;
  modalPagos:NgbModalRef;

  pago_general:any={};
  cliente_contacto:any=null;
  busqueda_contacto:any={};

  ventas_filtradas:any[]=[];
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  pago_general_modal:NgbModalRef;
  @ViewChild('pago_general_modal')
  private pago_general_modal_ref: TemplateRef<any>;

  modal_lista_pagos_venta:NgbModalRef;

  constructor(public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public generalService:GeneralService,
    private clientesService:ClientesService,
    public usuarioService:UsuarioService,
    private pdfService:PdfService,
    private toastr: ToastrService,
    public ventaService:VentasService,
    private orderPipe: OrderPipe,
    private cajasService:CajasService) {
    super(persistenciaService,modalService,generalService,usuarioService);
   }

  ngOnInit(): void {
    let fecha_actual=new Date();
    this.filter={
      id_cliente:this.cliente.id,
      fecha_inicio:null,
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      cuentas_liquidadas:this.cuentas_liquidadas
    }

    this.aplicacion_cuentas_por_cobrar= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_CUENTAS_POR_COBRAR)[0];

    if(!this.mostrar_filtro_fechas){
      this.obtenerCuentaCliente();
    }
  }

  generarPdf(){
    this.blockUI.start();
    this.pdfService.imprimirHistoricoVentasCliente(this.cliente,this.usuario,this.titulo_reporte);
    this.blockUI.stop();
  }

  buscarHistorico(){
    this.blockUI.start();
    this.clientesService.obtenerHistoricoVentas(this).subscribe((reporte:any)=>{
      this.cliente.ventas=reporte.ventas;
      this.cliente.ventas_filtradas=this.cliente.ventas?this.cliente.ventas:[];
      this.calcularTotales();
      this.cliente.sucursal=this.cliente.sucursal?this.cliente.sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0];
      //this.pdfService.imprimirHistoricoVentasCliente(cliente,this.usuario);
      this.blockUI.stop();
    });
  }

  calcularTotales(){
		this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0;
		for(var i=0;i<this.cliente.ventas_filtradas.length;i++){
			this.total_importe=this.total_importe+Math.round((this.cliente.ventas_filtradas[i].total)*100)/100;
      this.total_a_cuenta=this.total_a_cuenta+((this.cliente.ventas_filtradas[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE)?this.cliente.ventas_filtradas[i].a_cuenta:this.cliente.ventas_filtradas[i].total);
      this.total_saldo=this.total_saldo+((this.cliente.ventas_filtradas[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE)?this.cliente.ventas_filtradas[i].saldo:0);
		}
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  verVenta(venta){
		this.modalPagos = this.modalService.open(VistaVentaComponent, {ariaLabelledBy: 'modal-basic-title',windowClass:'vista-venta', backdrop: 'static'});
		this.modalPagos.componentInstance.venta = venta;
		this.modalPagos.componentInstance.usuario = this.usuario;
		this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
			this.modalPagos.close();
		});
  }
  
  verDetallesPago() {
    setTimeout(function() {
        //$('#'+id_elemento).trigger('click');
        $(".verDetallePago").trigger('click');
    }, 0);
    
  }

  verDetallePagos(venta){
		var style=$("#"+venta.id).css("display");
		if(style=="none"){
			$("#"+venta.id).css("display","");
		}else{
			$("#"+venta.id).css("display","none");
		}
  }

  abrirPopupPagoCreditosVenta(venta){
		venta.cliente=this.cliente;
    this.modalPagos = this.modalService.open(PagoVentaComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modalPagos.componentInstance.venta = venta;
		this.modalPagos.componentInstance.usuario = this.usuario;
		this.modalPagos.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
          if(this.mostrar_filtro_fechas){
            this.buscarHistorico();
          }else{
            this.obtenerCuentaCliente();
          }
          this.getItems();
					this.modalPagos.close();
				}
			}else{
				this.modalPagos.close();
			}
			
		});
  }

  async obtenerCuentaCliente(){
    this.blockUI.start();
    let reporte:any=await this.clientesService.obtenerCuentaCliente(this.cliente.id,this.filter.cuentas_liquidadas).toPromise();
    this.cliente.ventas=reporte.ventas;
    this.cliente.ventas_filtradas=reporte.ventas;
    this.cliente.sucursal=this.cliente.sucursal?this.cliente.sucursal:Util.obtenerSucursalesUsuario(this.usuario)[0];
    this.calcularTotales();
    this.blockUI.stop();
  }

  imprimirPagoVenta(pago_venta){
    this.blockUI.start('Recuperando... espere por favor!');
    this.pdfService.imprimirReciboPagoVenta(pago_venta,this.usuario);
    this.blockUI.stop();
  }

  generarExcel(){		
    var data=this.generarExcelCliente(this.cliente);
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.titulo_reporte+"-"+this.cliente.razon_social);
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, this.titulo_reporte+"-"+this.cliente.identificacion.toUpperCase()+".xlsx");
    })
    this.blockUI.stop();
  }

  generarExcelCliente(cliente){
    var data = [["","",this.titulo_reporte],["CLIENTE :"+cliente.identificacion.toUpperCase()],["FECHA","TRANSACCION","NRO. DOCUMENTO","DESCRIPCIÓN","IMPORTE","A CUENTA","SALDO"]]
    var total_importe=0,total_a_cuenta=0,total_saldo=0;

    for(var i=0;i<cliente.ventas.length;i++){
      var columns=[];
      
      total_importe= total_importe+cliente.ventas[i].total;
      total_a_cuenta= total_a_cuenta+cliente.ventas[i].a_cuenta;
      total_saldo= total_saldo+cliente.ventas[i].saldo;
      cliente.ventas[i].fecha=new Date(cliente.ventas[i].fecha);
      columns.push(cliente.ventas[i].fecha.getDate()+"/"+(cliente.ventas[i].fecha.getMonth()+1)+"/"+cliente.ventas[i].fecha.getFullYear());
      columns.push(cliente.ventas[i].transaccion);			
      columns.push(cliente.ventas[i].factura);
      var detalle="";
      /*for(var j=0;j<cliente.ventas[i].detallesVenta.length;j++){
        detalle=detalle+(cliente.ventas[i].detallesVenta[j].producto?cliente.ventas[i].detallesVenta[j].producto.nombre:cliente.ventas[i].detallesVenta[j].combo?cliente.ventas[i].detallesVenta[j].combo.nombre:cliente.ventas[i].detallesVenta[j].descripcion)+","
      }*/
      columns.push(detalle);
      columns.push(cliente.ventas[i].total);				
      columns.push(cliente.ventas[i].a_cuenta);				
      columns.push(cliente.ventas[i].saldo);				
      data.push(columns);
    }

    data.push(['','','TOTAL GENERAL',null,total_importe+"",total_a_cuenta+"",total_saldo+""]);
    return data;
  }

  calcularTotalesSeleccionados(){
		this.total_importe_seleccionado=0,this.total_a_cuenta_seleccionado=0,this.total_saldo_seleccionado=0;
		for(var i=0;i<this.selectedItems.length;i++){
			this.total_importe_seleccionado=this.total_importe_seleccionado+Math.round((this.selectedItems[i].total)*100)/100;
      this.total_a_cuenta_seleccionado=this.total_a_cuenta_seleccionado+((this.selectedItems[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE)?this.selectedItems[i].a_cuenta:this.selectedItems[i].total);
      this.total_saldo_seleccionado=this.total_saldo_seleccionado+((this.selectedItems[i].tipo_pago_nombre_corto==GlobalVariable.Dictionary.TIPO_PAGO_CRE)?this.selectedItems[i].saldo:0);
		}
  }

  obtenerCajasEmpresa(){
    this.blockUI.start();
    this.cajasService.obtenerCajasEmpresa(this.usuario.id_empresa).subscribe((cajas:any)=>{
      this.cajas=cajas;
      this.blockUI.stop();
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases(GlobalVariable.Dictionary.TIPOS_PAGOS).subscribe((transacciones:any)=>{
      this.transacciones=transacciones.clases;
      this.pago_general.transaccion=this.transacciones[0];
      this.blockUI.stop();
	  });	
  }

  abrirPopupPagoCreditosCliente(){
    this.pago_general.importe=1;
    this.obtenerCajasEmpresa();
    this.obtenerTransacciones();
    this.calcularTotalesSeleccionados();
    this.calcularSaldoActualizado();
    let fecha_actual=new Date();
    this.pago_general.fecha_texto={
      year: fecha_actual.getFullYear(), 
      month: (fecha_actual.getMonth()+1),
      day:fecha_actual.getDate()
    };
    this.pago_general_modal=this.modalService.open(this.pago_general_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.pago_general_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  validarPago(){
    let res=true;
    if(!this.pago_general.importe || this.pago_general.importe<=0 || this.pago_general.importe>this.total_saldo_seleccionado){
      res=res && false;
      this.toastr.error("El valor del pago debe ser mayor a cero y menor o igual al saldo, intente con otro valor!");
    }
    if(!this.pago_general.fecha_texto || !this.pago_general.fecha_texto.year || !this.pago_general.fecha_texto.month || !this.pago_general.fecha_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha completa para el pago!");
    }
    return res;
  }

  async guardarPagoGeneral(){
    if(this.validarPago()){
      this.blockUI.start();
      var total_acumulado=this.pago_general.importe,ventas_a_pagar=[];
      for(var i=0;i<this.selectedItems.length;i++){
        if(this.selectedItems[i].saldo>0 && (total_acumulado>=this.selectedItems[i].saldo || total_acumulado>0)){
          this.selectedItems[i].a_pagar=(total_acumulado>=this.selectedItems[i].saldo)?this.selectedItems[i].saldo:total_acumulado;
          ventas_a_pagar.push(this.selectedItems[i]);
          total_acumulado=total_acumulado-this.selectedItems[i].saldo;
        }
      }
      
      for(var i=0;i<ventas_a_pagar.length;i++){
        await this.ventaService.guardarPagoVenta(ventas_a_pagar[i].id,this.usuario.id_empresa,
          { 
            monto_pagado: ventas_a_pagar[i].a_pagar,
            fecha:Util.convertirObjetoAfecha(this.pago_general.fecha_texto),
            observacion:"Pago en conjunto", 
            id_usuario_cajero: this.usuario.id,
            transaccion:this.pago_general.transaccion,
            caja:this.pago_general.caja,
            sucursal:{id:ventas_a_pagar[i].id_sucursal}
          }).toPromise();
      }
      
      this.toastr.success("Pagos en conjunto registrados satisfactoriamente!");
        if(this.mostrar_filtro_fechas){
          this.buscarHistorico();
        }else{
          this.obtenerCuentaCliente();
        }
        this.getItems();
        this.selectedItems=[];
        this.allItemsSelected=false;
        this.pago_general_modal.close();
        this.blockUI.stop();
        /*if(this.usuario.empresa.usar_contabilidad){
          datos.forEach(function(dato, index){
            //me.contabilizarPago(dato.pago.monto_pagado,this.cliente,"Por pago de facturas en conjunto "+dato.venta.factura,dato.pago.id);
          });
        }*/
      
    }
  }	

  calcularSaldoActualizado(){
		this.pago_general.saldo_actualizado=Math.round((this.total_saldo_seleccionado-this.pago_general.importe)*100)/100;
  }
  
  buscarContacto(texto){
		if(texto==""){
			this.cliente.ventas_filtradas=this.cliente.ventas;
		}else{
			this.cliente.ventas_filtradas=this.cliente.ventas_filtradas.filter(e => e.contacto_cliente && e.contacto_cliente.persona.nombre_completo.toLowerCase().search(texto.toLowerCase()) >-1);
		}
		this.calcularTotales();
  }

  generarExcelMayores(){
    var data = [["","",this.titulo_reporte],["CLIENTE :"+this.cliente.identificacion.toUpperCase()],["FECHA","TRANSACCION","DESCRIPCIÓN","NRO. DOCUMENTO","DEBE","HABER","SALDO"]]
    var total_debe=0,total_haber=0,total_saldo=0;

    let mayores=[];
    for(var i=0;i<this.cliente.ventas.length;i++){
      mayores.push({
        fecha:this.cliente.ventas[i].fecha,
        transaccion:this.cliente.ventas[i].transaccion,
        numero_documento:this.cliente.ventas[i].factura,
        debe:this.cliente.ventas[i].total,
        haber:0,
        descripcion:this.cliente.ventas[i].observacion
      });
      if(this.cliente.ventas[i].pagos_venta){
        let pagos_venta=this.cliente.ventas[i].pagos_venta.split(",");
        for(let j=0;j<pagos_venta.length;j++){
          mayores.push({
            fecha:pagos_venta[j].split('|')[0],
            transaccion:"PAGO",
            numero_documento:parseInt(pagos_venta[j].split('|')[1]),
            debe:0,
            haber:parseFloat(pagos_venta[j].split('|')[2]),
            descripcion:""
          });
        }
      }
    }

    mayores=this.orderPipe.transform(mayores, 'fecha',false);

    for(var i=0;i<mayores.length;i++){
      var columns=[];
      total_debe=total_debe+mayores[i].debe;
      total_haber=total_haber+mayores[i].haber;
      total_saldo=total_debe-total_haber;
      /*total_importe= total_importe+ this.cliente.ventas[i].total;
      total_a_cuenta= total_a_cuenta+cliente.ventas[i].a_cuenta;
      total_saldo= total_saldo+cliente.ventas[i].saldo;*/
      mayores[i].fecha=new Date(mayores[i].fecha);
      columns.push(mayores[i].fecha.getDate()+"/"+(mayores[i].fecha.getMonth()+1)+"/"+mayores[i].fecha.getFullYear());
      columns.push(mayores[i].transaccion);			
      columns.push(mayores[i].descripcion);			
      columns.push(mayores[i].numero_documento);
      columns.push(mayores[i].debe);
      columns.push(mayores[i].haber);				
      columns.push(total_saldo);				
      data.push(columns);
    }

    data.push(['','','TOTAL GENERAL',null,total_debe+"",total_haber+"",(total_debe-total_haber)+""]);

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.titulo_reporte+"-"+this.cliente.identificacion);
    data.forEach(d => {
    let row = worksheet.addRow(d);
    }
    );

    const dobCol2 = worksheet.getColumn(2);
    dobCol2.width = 40;
    const dobCol3 = worksheet.getColumn(3);
    dobCol3.width = 20;

    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, this.titulo_reporte+" MAYORES "+"-"+this.cliente.identificacion.toUpperCase()+".xlsx");
    })
    this.blockUI.stop();
  }

  generarPdfMayores(){

  }

  abrirPopupConfirmacionAnulacionPago(pago_venta){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de anular el pago?";
		this.popupConfirmacion.componentInstance.data = pago_venta;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.anularPagoVenta($e.data);
			}
			this.popupConfirmacion.close();
        });
	  }

  anularPagoVenta(pago_venta){
        this.ventaService.anularPagoVenta(pago_venta.id).subscribe((res:any)=>{
          this.toastr.success(res.mensaje);
          this.obtenerCuentaCliente();
          this.verDetalleCuentaContactoCliente(this.cliente_contacto.id);
          this.getItems();
					if(this.usuario.empresa.usar_contabilidad){
						/*var promesa=ClasesTipo("TIPFUENTECOMP");
						promesa.then(function(tipoFuentesComprobante){
							var fuentes_comprobantes=tipoFuentesComprobante.clases;
							var datos_fuente=new AnulacionComprobanteAutomatizado({
								datos_fuente:pago_venta.id,
								fuente:$.grep(fuentes_comprobantes, function (e) { return e.nombre_corto == Diccionario.TIPO_FUENTE_COMPROBANTE_AUTOMATICA_PAGO_VENTA; })[0]
							});
							datos_fuente.$save(function(resAnu){
								MostrarMensaje(resAnu.mensaje);
							})
						});*/
					}
        });
	}

  verDetalleCuentaContactoCliente(id_cliente_contacto){
    this.blockUI.start();
    this.clientesService.obtenerCuentaContactoCliente(id_cliente_contacto,this.filter.cuentas_liquidadas).subscribe((datos:any) =>{
      this.cliente_contacto=datos;
      this.calcularTotalesContactoCliente();
      this.blockUI.stop();
    });
  }

  calcularTotalesContactoCliente(){
		this.total_importe=0,this.total_a_cuenta=0,this.total_saldo=0;
		for(var i=0;i<this.cliente_contacto.ventas.length;i++){
			this.total_importe=this.total_importe+this.cliente_contacto.ventas[i].total;
			this.total_a_cuenta=this.total_a_cuenta+this.cliente_contacto.ventas[i].a_cuenta;
			this.total_saldo=this.total_saldo+this.cliente_contacto.ventas[i].saldo;
		}
  }

  verPagosVenta(venta){
		this.modal_lista_pagos_venta = this.modalService.open(ListaPagosVentasComponent, {windowClass:'lista-pagos', ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_lista_pagos_venta.componentInstance.venta = venta?venta:null;
		this.modal_lista_pagos_venta.componentInstance.usuario = this.usuario;
		this.modal_lista_pagos_venta.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modal_lista_pagos_venta.close();
				}
			}else{
				this.modal_lista_pagos_venta.close();
			}
			
		});
	}

}
