import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class MensajesEmpresaService {

  constructor(private http: HttpClient) { }

  public obtenerMensajesEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"mkt-mensajes-empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerPlantillasMensajes(){
    return this.http.get(GlobalVariable.API_SERVER_URL+"mkt-plantillas-mensajes",GlobalVariable.httpOptions);
  }

  public guardarMensajesEmpresa(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"mkt-mensajes-empresa",datos,GlobalVariable.httpOptions);
  }

  public obtenerMensajeEmpresa(id_empresa,nombre_corto_clase){
    return this.http.get(GlobalVariable.API_SERVER_URL+"mkt-mensaje-empresa/"+id_empresa+"/clase/"+nombre_corto_clase,GlobalVariable.httpOptions);
  }
}
