<div class="modal-header">
    <div class="col-md-6 col-xs-6">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-6 col-xs-6">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
        <legend >Visualizacion Vista Día</legend>
        <div class="row">
            <div class="col-6">
                <label>Mostrar Horas Desde</label>
            </div>
            <div class="col-6">
                <ngb-timepicker [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_desde"></ngb-timepicker>
            </div>
            <div class="col-6">
                <label>Mostrar Horas Hasta</label>
            </div>
            <div class="col-6">
                <ngb-timepicker [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.mostrar_horas_hasta"></ngb-timepicker>
            </div>
            <div class="col-6">
                <label>Duración Cita</label>
            </div>
            <div class="col-6">
                <ngb-timepicker [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.duracion_cita"></ngb-timepicker>
            </div>
            <div class="col-6">
                <label>Hora Trabajo Inicio</label>
            </div>
            <div class="col-6">
                <ngb-timepicker [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_inicio"></ngb-timepicker>
            </div>
            <div class="col-6">
                <label>Hora Trabajo Fin</label>
            </div>
            <div class="col-6">
                <ngb-timepicker [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.hora_trabajo_fin"></ngb-timepicker>
            </div>
        </div>
    </fieldset>
</div>