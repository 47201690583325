import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LecturaMedidorService {

  constructor(private http: HttpClient) { }

  public obtenerLecturaAnterior(lectura_medidor){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lectura-medidor-anterior/gestion/"+lectura_medidor.gestion.id+"/mes/"+lectura_medidor.mes.id+"/accion-agua/"+lectura_medidor.accion_agua.id,GlobalVariable.httpOptions);
  }

  public guardarLecturaMedidor(lectura_medidor){
    return this.http.put(GlobalVariable.API_SERVER_URL+"lectura-medidor/gestion/"+lectura_medidor.gestion.id+"/mes/"+lectura_medidor.mes.id+"/accion-agua/"+lectura_medidor.accion_agua.id,lectura_medidor,GlobalVariable.httpOptions);
  }

  public obtenerListaLecturasMedidores(paginator){console.log(paginator);
    let fecha_inicial=new Date(paginator.filter.fecha_inicio.year,paginator.filter.fecha_inicio.month-1,paginator.filter.fecha_inicio.day,0,0);
    let fecha_fin=new Date(paginator.filter.fecha_fin.year,paginator.filter.fecha_fin.month-1,paginator.filter.fecha_fin.day,0,0);
    return this.http.get(GlobalVariable.API_SERVER_URL+"lecturas-medidores/empresa/"+paginator.filter.id_empresa+
    "/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+
    paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+
    "/fecha-inicial/"+fecha_inicial+"/fecha-final/"+fecha_fin+"/vecino/"+(paginator.filter.vecino==null?0:paginator.filter.vecino)+"/gestion/"+paginator.filter.periodo.id+"/mes/"+paginator.filter.mes.id+"/codigo-medidor/"+(paginator.filter.codigo_medidor?paginator.filter.codigo_medidor:0)+"/categoria/"+paginator.filter.categoria.id+"/inferido/"+(paginator.filter.inferido?1:0),
    GlobalVariable.httpOptions);
  }

  public obtenerVecinosLecturacionExportacion(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"vecinos-lecturacion-exportacion/empresa/"+filtro.id_empresa+"/categoria/"+filtro.categoria.id,GlobalVariable.httpOptions);
  }

  public obtenerPagosPendientes(id_accion_agua){
    return this.http.get(GlobalVariable.API_SERVER_URL+"servicios-pendientes/accion-agua/"+id_accion_agua,GlobalVariable.httpOptions);
  }

  public guardarLecturacionHistorica(lecturas_historicas,id_empresa){
    return this.http.post(GlobalVariable.API_SERVER_URL+"lecturacion-historica/empresa",{vecinos:lecturas_historicas,id_empresa:id_empresa},GlobalVariable.httpOptions);
  }

  public obtenerLecturasRegistro(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lecturas-registro/gestion/"+filtro.gestion.id+"/mes/"+filtro.mes.id+"/categoria/"+filtro.categoria.id,GlobalVariable.httpOptions);
  }

  public obtenerAccionesAguaVecino(id_vecino){
    return this.http.get(GlobalVariable.API_SERVER_URL+"acciones-agua/vecino/"+id_vecino,GlobalVariable.httpOptions);
  }

  public obtenerRegistroLecturacionAccionAgua(id_gestion,id_accion_agua){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lecturas-registro/gestion/"+id_gestion+"/accion-agua/"+id_accion_agua,GlobalVariable.httpOptions);
  }

  public guardarRegistroLecturacionAccionAgua(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"guardar-lecturas-registro-accion-agua",datos,GlobalVariable.httpOptions);
  }

  public eliminarLecturaMedidor(id_lectura_medidor){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"lectura-medidor/"+id_lectura_medidor,GlobalVariable.httpOptions);
  }

  public inferirLecturaActual(lectura_medidor){
    return this.http.get(GlobalVariable.API_SERVER_URL+"inferenciar-lectura/gestion/"+lectura_medidor.gestion.id+"/mes/"+lectura_medidor.mes.id+"/lectura-medidor/"+lectura_medidor.id_lectura_medidor+"/accion-agua/"+lectura_medidor.id_accion_agua,GlobalVariable.httpOptions);
  }
  
  public obtenerConfiguracionImpresion(filtro){
    return this.http.get(GlobalVariable.API_SERVER_URL+"configuracion-impresion/"+filtro, GlobalVariable.httpOptions);
  }

  public obtenerLecturaDiciembreGestionAnterior(id_accion_agua,nombre_corto_gestion_anterior){
    return this.http.get(GlobalVariable.API_SERVER_URL+"lectura-diciembre-gestion-anterior/accion-agua/"+id_accion_agua+"/gestion-anterior/"+nombre_corto_gestion_anterior, GlobalVariable.httpOptions);
  }

}
